import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

@Component({
  selector: 'gc-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoComponent implements OnInit, AfterViewChecked {
  @Input() id: string;
  @Input() isMute: boolean;
  @Input() showControls: 0 | 1 = 1;
  @Input() showYouTubeLogo: 0 | 1 = 1;
  @Input() disabledKeyboardControls: 0 | 1 = 1;
  @Input() autoplay = false;
  @Input() set play(play: boolean) {
    if (play && this.player) {
      this.player.playVideo();
    }
  }
  @Input() set stop(stop: boolean) {
    this.isStopped = stop;

    if (stop && this.player?.stopVideo) {
      this.player.stopVideo();
    }
  }

  @Output() playerReady = new EventEmitter();
  @Output() playerStateChange = new EventEmitter();

  YT: any;
  player;
  isStopped: boolean;
  iframe;

  constructor() {}

  ngAfterViewChecked() {
    this.init();
  }

  ngOnInit() {}

  init() {
    if (window['YT']) {
      this.startPlayer();
      return;
    }

    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = () => this.startPlayer();
  }

  startPlayer() {
    if (this.player) {
      return;
    }
    window['YT'].ready(() => {
      this.player = new window['YT'].Player('player' + this.id, {
        videoId: this.id,
        height: '100%',
        width: '100%',
        playerVars: {
          autoplay: this.autoplay ? 1 : 0,
          modestbranding: this.showYouTubeLogo,
          controls: this.showControls,
          disablekb: this.disabledKeyboardControls,
          rel: 0,
          playsinline: 1,
        },
        events: {
          onReady: e => {
            this.iframe = e.target.i;

            if (this.isMute) {
              this.player.mute();
            }

            this.playerReady.emit();

            if (this.isStopped) {
              this.player.stopVideo();
            }
          },
          onStateChange: event => {
            this.playerStateChange.emit(event);
          },
        },
      });
    });
  }

  playFullscreen() {
    this.player.playVideo();

    const requestFullScreen =
      this.iframe.requestFullScreen || this.iframe.mozRequestFullScreen || this.iframe.webkitRequestFullScreen;
    if (requestFullScreen) {
      requestFullScreen.bind(this.iframe)();
    }
  }
}
