import { PrintAreaNameEnum } from '@gelato-api-ui/core/print-sides/print-side-name.enum';

export const PrintAreaNames = {
  [PrintAreaNameEnum.Front]: 'txt_apparel_print_area_front',
  [PrintAreaNameEnum.Back]: 'txt_apparel_print_area_back',
  [PrintAreaNameEnum.SleeveLeft]: 'txt_apparel_print_area_left_sleeve',
  [PrintAreaNameEnum.SleeveRight]: 'txt_apparel_print_area_right_sleeve',
  [PrintAreaNameEnum.NeckInner]: 'txt_apparel_print_area_inner_label',
  [PrintAreaNameEnum.NeckOuter]: 'txt_apparel_print_area_back_label',
};
