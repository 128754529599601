import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';
import { StoreProductMode } from '@product-catalogue/src/lib/product-catalogue/lib/store-product-mode.enum';
import { getVisibleWizardSteps } from '@api-ui-app/src/app/e-commerce-stores/lib/helpers/getVisibleWizardSteps';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';

@Component({
  selector: 'gc-ecommerce-product-wizard-actions-panel-product-selection',
  templateUrl: './ecommerce-product-wizard-actions-panel-product-selection.component.html',
  styleUrls: ['./ecommerce-product-wizard-actions-panel-product-selection.component.scss'],
})
export class EcommerceProductWizardActionsPanelProductSelectionComponent {
  @Input() storeProductMode: StoreProductMode;
  @Input() storeType: EStoreType;
  @Output() returnToStoreButtonClick = new EventEmitter<void>();

  activeStep = ECommerceProductWizardStep.PRODUCT;

  get backButtonLabelTranslationKey(): string {
    return this.storeProductMode === StoreProductMode.PRODUCT_TEMPLATE_WIZARD
      ? 'txt_back_to_dashboard'
      : 'txt_return_to_store';
  }

  get visibleSteps(): ECommerceProductWizardStep[] {
    return getVisibleWizardSteps(
      this.storeProductMode === StoreProductMode.PRODUCT_WIZARD_BULK_CONNECT
        ? EProductWizardMode.BULK_CONNECT
        : EProductWizardMode.CREATE_PRODUCT,
      this.storeType,
    );
  }

  get disabledSteps(): ECommerceProductWizardStep[] {
    return this.visibleSteps.filter((loopStep: ECommerceProductWizardStep): boolean => loopStep !== this.activeStep);
  }

  onReturnToStoreButtonClick() {
    this.returnToStoreButtonClick.emit();
  }
}
