import * as R from 'ramda';
import { SubscriptionResponse } from '@api-ui-app/src/app/subscriptions/types/subscriptions';
import { getServicePlanIdsFromSubscription } from '@api-ui-app/src/app/subscriptions/lib/helpers/getServicePlanIdsFromSubscription';

export const getServicePlanIdsFromSubscriptions = (subscriptions: SubscriptionResponse[]): string[] =>
  (subscriptions || []).reduce(
    (acc: string[], loopSubscription: SubscriptionResponse): string[] =>
      R.uniq([...acc, ...getServicePlanIdsFromSubscription(loopSubscription)]),
    [],
  );
