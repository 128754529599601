import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { WARNING_GELATO_PLUS_DAYS_LEFT } from '@api-ui-app/src/app/subscriptions/constants/warning-trial-days-left';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { GelatoPlusPopupLocations } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-popup-locations';

@Component({
  selector: 'gc-gelato-plus-days-left-counter',
  templateUrl: './gelato-plus-days-left-counter.component.html',
  styleUrls: ['./gelato-plus-days-left-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GelatoPlusTrialCounterComponent {
  @Input() daysLeft: number;
  @Input() text: string;
  @Input() redirectOnClick = false;
  @Input() showTextInBrackets = false;
  @Input() textFontSize = '16px';
  @Input() floatingMode: boolean;
  @Input() isMenuItem: boolean;

  WARNING_GELATO_PLUS_DAYS_LEFT = WARNING_GELATO_PLUS_DAYS_LEFT;

  constructor(private readonly router: Router) {}

  openPlusModal() {
    logEvent('counterClicked', {
      locationPopup: GelatoPlusPopupLocations.counter,
    });
    if (this.redirectOnClick) {
      this.router.navigate(['growth-plans/list'], { state: { featurePopUp: GelatoPlusPopupLocations.counter } });
    }
  }
}
