import { EProduct } from './e-product';
import { EProductVariant } from './e-product-variant';
import { EProductImage } from './e-product-image';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';

export class EProductData {
  constructor(
    public product: EProduct,
    public productVariants: EProductVariant[],
    public productImages: EProductImage[],
    public wizardStep?: ECommerceProductWizardStep,
  ) {}
}
