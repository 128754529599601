<div class="wizard-actions-panel">
  <gc-ecommerce-product-wizard-back-button [text]="backButtonText" (click)="onBackButtonClick()">
  </gc-ecommerce-product-wizard-back-button>

  <ng-container [ngSwitch]="showSteps && !(isMobileOrTablet$ | async)">
    <gc-ecommerce-product-wizard-breadcrumbs
      *ngSwitchCase="true"
      [activeStep]="activeStep"
      [visibleSteps]="visibleSteps"
      [disabledSteps]="disabledSteps"
      (stepButtonClick)="onStepButtonClick($event)"
    >
    </gc-ecommerce-product-wizard-breadcrumbs>

    <div *ngSwitchCase="false"></div>
  </ng-container>

  <div class="right-side-content">
    <ng-content></ng-content>
  </div>
</div>

<div *ngIf="includeSpacer" class="spacer"></div>
