import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

@Component({
  selector: 'gd-standard-layout-ghost',
  templateUrl: './standard-layout-ghost.component.html',
  styleUrls: ['./standard-layout-ghost.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardLayoutGhostComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
