import { Injectable } from '@angular/core';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { SanityProductControl, SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { SanityProductVariation } from '@gelato-api-ui/core/sanity/sanity-product-variation';
import { CustomTrim } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { SanityProductControlOption } from '@gelato-api-ui/core/sanity/sanity-product-control-option';
import { CustomTrimTitleService } from '@gelato-api-ui/core/e-commerce/services/custom-trim-title.service';
import { SanityProductVariantWallpaperType } from '@gelato-api-ui/core/sanity/sanity-product-variant';
import { getBaseUid } from '@gelato-api-ui/core/sanity/helpers/getBaseUid';

interface FormattedProductControlOption {
  key: SanityProductControlKey;
  title: string;
  value: string;
}

@Injectable({ providedIn: 'root' })
export class SanityProductVariationTitleService {
  constructor(private readonly customTrimTitleService: CustomTrimTitleService) {}

  get(
    sanityProduct: SanityProduct,
    productUid: string,
    customTrim: CustomTrim,
    productControlKeysToIgnore: SanityProductControlKey[] = null,
    joinSign = ' - ',
  ): string {
    const baseProductUid = getBaseUid(productUid); // GPS-3145: Fix for Apparel DTG areas
    const formattedOptions: FormattedProductControlOption[] = this.getFormattedOptions(
      sanityProduct,
      baseProductUid,
      customTrim,
      productControlKeysToIgnore,
    );

    if (!formattedOptions || !formattedOptions.length) {
      return null;
    }

    const sortedFormattedOptions = this.sortFormattedOptions(formattedOptions);

    let titleSegments: string[] = sortedFormattedOptions.map(
      (loopAttribute: FormattedProductControlOption): string => loopAttribute.value,
    );

    if (customTrim?.width?.value && customTrim?.height?.value) {
      titleSegments = [this.customTrimTitleService.get(customTrim), ...titleSegments];
    }

    return titleSegments.join(joinSign);
  }

  private getFormattedOptions(
    sanityProduct: SanityProduct,
    productUid: string,
    customTrim: CustomTrim,
    productControlKeysToIgnore: SanityProductControlKey[],
  ): FormattedProductControlOption[] {
    if (!sanityProduct?.productControls || !sanityProduct?.productVariations || !productUid) {
      return null;
    }

    const variation: SanityProductVariation = sanityProduct.productVariations.find(
      (loopVariation: SanityProductVariation): boolean => loopVariation.productUid === productUid,
    );

    if (!variation) {
      return null;
    }

    const keysToIgnore = [
      SanityProductControlKey.garmentPrint,
      SanityProductControlKey.bagPrint,
      ...(productControlKeysToIgnore || []),
    ];

    return sanityProduct.productControls
      .map((loopProductControl: SanityProductControl): FormattedProductControlOption => {
        if (
          !variation?.[loopProductControl?.key] ||
          !loopProductControl?.options?.length ||
          keysToIgnore.includes(loopProductControl?.key as SanityProductControlKey)
        ) {
          return null;
        }

        const optionValue: string = variation[loopProductControl.key];

        if (
          loopProductControl.key === SanityProductControlKey.wallpaperType &&
          optionValue === SanityProductVariantWallpaperType.fullWall &&
          customTrim
        ) {
          return null;
        }

        const option = loopProductControl.options.find(
          (loopOption: SanityProductControlOption): boolean => loopOption.value === optionValue,
        );

        if (!option) {
          return null;
        }

        return {
          key: loopProductControl.key as SanityProductControlKey,
          title: loopProductControl.title,
          value: option.title,
        };
      })
      .filter((loopFormattedProductControlOption: FormattedProductControlOption): boolean =>
        Boolean(loopFormattedProductControlOption),
      );
  }

  private sortFormattedOptions(formattedOptions: FormattedProductControlOption[]): FormattedProductControlOption[] {
    const sortingOrder = [
      SanityProductControlKey.garmentColor,
      SanityProductControlKey.garmentSize,
      SanityProductControlKey.bagColor,
      SanityProductControlKey.bagSize,
      SanityProductControlKey.mugMaterial,
      SanityProductControlKey.formatSize,
      SanityProductControlKey.orientation,
      SanityProductControlKey.frameColor,
      SanityProductControlKey.frameAssembling,
      SanityProductControlKey.foamColor,
      SanityProductControlKey.foamThickness,
      SanityProductControlKey.woodThickness,
      SanityProductControlKey.phoneBrand,
      SanityProductControlKey.phoneModel,
      SanityProductControlKey.wallpaperType,
      SanityProductControlKey.wallpaperMaterial,
    ];

    const attributesWithSortingOrder = [...formattedOptions].filter(
      (loopAttribute: FormattedProductControlOption): boolean => sortingOrder.includes(loopAttribute.key),
    );
    const attributesWithoutSortingOrder = [...formattedOptions].filter(
      (loopFormattedProductControlOption: FormattedProductControlOption): boolean =>
        !sortingOrder.includes(loopFormattedProductControlOption.key),
    );

    return [
      ...attributesWithSortingOrder.sort(
        (a: FormattedProductControlOption, b: FormattedProductControlOption): number =>
          sortingOrder.indexOf(a.key) - sortingOrder.indexOf(b.key),
      ),
      ...attributesWithoutSortingOrder,
    ];
  }
}
