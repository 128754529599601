import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '../app.state';
import { PricePlan } from '../price-plans/lib/price-plan';
import * as actions from './price-plan-selection-list.actions';

export interface State {
  isLoading: boolean;
  payload: PricePlan[];
}

export const initialState: State = {
  isLoading: false,
  payload: null,
};

export const getState = (state: AppState): State => state.pricePlanSelectionList;

export const getPricePlans = createSelector(getState, (state: State): PricePlan[] => state.payload || []);

export const getPricePlanOptions = createSelector(getPricePlans, (pricePlans: PricePlan[]): any => {
  const options = R.map(
    (pricePlan: PricePlan) => ({
      id: pricePlan.id,
      name: pricePlan.name,
    }),
    pricePlans,
  ).sort((a, b) => (b.name > a.name ? -1 : 1));

  return options;
});

export const getPricePlanNames = createSelector(getPricePlans, (pricePlans: PricePlan[]): any => {
  if (!pricePlans) {
    return {};
  }

  const names = {};

  pricePlans.forEach((pricePlan: PricePlan) => {
    names[pricePlan.id] = pricePlan.name;
  });

  return names;
});

export function reducer(state = initialState, action: actions.PricePlanSelectionListActions): State {
  switch (action.type) {
    case actions.PricePlanSelectionListActionTypes.SetState:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
