<div class="header">
  {{ modal.context.title }}
</div>
<div class="content">
  <p>{{ modal.context.description }}</p>

  <div class="ui form">
    <div class="field">
      <input type="text" name="templateName" [id]="'templateName'" [(ngModel)]="templateName" />
    </div>
  </div>
</div>
<div class="actions">
  <button class="ui secondary button" (click)="close()">
    {{ 'txt_cancel' | translate }}
  </button>
  <button
    class="ui primary button"
    [class.disabled]="!templateName || (!acceptInitialTemplateName && templateName === initialTemplateName)"
    (click)="approve()"
  >
    {{ modal.context.actionButtonText }}
  </button>
</div>
