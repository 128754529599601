import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonPrettyPrint',
})
export class JsonPrettyPrintPipe implements PipeTransform {
  transform(val) {
    if (typeof val === 'string') {
      val = JSON.parse(decodeURIComponent(val));
    }

    return JSON.stringify(val, null, 2);
  }
}
