import { Action } from '@ngrx/store';
import { NotificationData } from './notification-data';

export enum NotificationActionType {
  ShowNotification = '[Notification] ShowNotification',
  ShowGeneralErrorNotification = '[Notification] ShowGeneralErrorNotification',
}

export class ShowNotification implements Action {
  readonly type = NotificationActionType.ShowNotification;
  constructor(public payload: Partial<NotificationData>) {}
}

export class ShowGeneralErrorNotification implements Action {
  readonly type = NotificationActionType.ShowGeneralErrorNotification;
}
