import * as R from 'ramda';
import { EProductWithVariants } from '../e-product-with-variants';
import { EProductVariant } from '../e-product-variant';
import { EProductVariantConnectionStatus } from '../e-product-variant-connection-status.enum';
import { PRODUCT_UID_DELIMITER } from '@gelato-api-ui/core/e-commerce/constants';
import { getSanityProductIdFromEProduct } from '@gelato-api-ui/core/e-commerce/helpers/getSanityProductIdFromEProduct';
import { getSanityCategoryIdFromEProduct } from '@gelato-api-ui/core/e-commerce/helpers/getSanityCategoryIdFromEProduct';
import { getProductVariantKey } from '@gelato-api-ui/core/e-commerce/helpers/getProductVariantKey';
import { EProductStatus } from '@gelato-api-ui/core/e-commerce/e-product-status.enum';

export const isBulkEditingAllowed = (product: EProductWithVariants): boolean => {
  if (product.status === EProductStatus.UPDATING) {
    return false;
  }

  const sanityProductId = getSanityProductIdFromEProduct(product);
  const sanityCategoryId = getSanityCategoryIdFromEProduct(product);

  if (!sanityProductId || !sanityCategoryId) {
    return false;
  }

  const productVariantKeys: string[] = product.variants.map((loopVariant: EProductVariant): string =>
    getProductVariantKey(loopVariant),
  );

  if (R.uniq(productVariantKeys).length !== productVariantKeys.length) {
    return false;
  }

  return product.variants.reduce((acc: boolean, loopVariant: EProductVariant): boolean => {
    if (!acc) {
      return false;
    }

    const loopSanityProductId = loopVariant.sanityProductId;

    const hasCombinedProductUid = (loopVariant.productUid || '').split(PRODUCT_UID_DELIMITER).length > 1;

    return (
      loopSanityProductId === sanityProductId &&
      !hasCombinedProductUid &&
      [EProductVariantConnectionStatus.CREATED, EProductVariantConnectionStatus.CONNECTED].includes(
        loopVariant.connectionStatus,
      ) &&
      Boolean(loopVariant.designId || loopVariant.designStructureJson)
    );
  }, true);
};
