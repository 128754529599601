import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SanityProductSimplified } from '@gelato-api-ui/core/sanity/sanity-product-simplified';

@Component({
  selector: 'gd-category-product-segment',
  templateUrl: './category-product-segment.component.html',
  styleUrls: ['./category-product-segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryProductSegmentComponent {
  @Input() product: SanityProductSimplified;
  @Input() currency: string;
  @Input() productPrice: number;
  @Input() productPreviewVariants = true;
  @Input() withBorders = false;
}
