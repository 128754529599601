import { Component, EventEmitter, Input, Output, Type } from '@angular/core';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { getSubscriptionClassName } from '../../lib/subscription-branding-helpers';
import { Promotion } from '../../shared/voucher/voucher';

@Component({
  selector: 'gd-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class StandardHeaderMobileComponent {
  @Input() headerComponent: Type<Component>;
  sidebarVisible = false;

  @Input() currentLang: string;
  @Input() currentCurrency: string;
  @Input() isGelatoPlusGoldActive: boolean;
  @Input() isGelatoPlusActive: boolean;
  @Input() isHelloBarVisible: boolean;
  @Input() isNonRenewingSubscriptionEnding: boolean;
  @Input() nonRenewingGelatoPlusDaysLeft: number;
  @Input() userWithFirstOrder: boolean;
  @Input() discounts: Promotion[];

  @Output() useLocale = new EventEmitter<LocaleCode>();
  @Output() useCurrency = new EventEmitter<string>();

  get isGelatoPlusSubscriptionEnding() {
    return !this.isGelatoPlusGoldActive && this.isNonRenewingSubscriptionEnding;
  }

  get isBrandingColorsLoading() {
    return this.isGelatoPlusActive == null && this.isGelatoPlusGoldActive == null;
  }

  get userWrapperClass() {
    return getSubscriptionClassName(this.isGelatoPlusActive, this.isGelatoPlusGoldActive);
  }

  toggleSidebar() {
    this.sidebarVisible = !this.sidebarVisible;
  }

  onLanguageChange(value: LocaleCode): void {
    this.useLocale.emit(value);
  }

  onCurrencyChange(value: string): void {
    this.useCurrency.emit(value);
  }
}
