import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { AppState } from '../../app.state';
import { getProductCatalogueOptions } from '../../ngrx/product-catalogue-selection-list.reducer';

@Component({
  selector: 'gd-product-catalogue-selector',
  templateUrl: './product-catalogue-selector.component.html',
  styleUrls: ['./product-catalogue-selector.component.scss'],
})
export class ProductCatalogueSelectorComponent implements OnInit {
  @Input() valueField: string;
  @Input() value: string;
  @Input() placeholder = '';
  @Input() showAnyValueOption = false;
  @Input() anyValueOptionText = '';
  @Input() isSearchable = true;
  @Input() isDisabled = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  options$ = this.store.select(getProductCatalogueOptions).pipe(
    map((options: any[]) => {
      if (this.showAnyValueOption) {
        return [
          {
            id: '',
            name: this.anyValueOptionText,
          },
          ...options,
        ];
      }

      return options;
    }),
  );

  constructor(private store: Store<AppState>, private translate: TranslateService) {}

  ngOnInit() {}

  onSelect(value: string) {
    this.valueChange.emit(value);
  }
}
