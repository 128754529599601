import { ClientType } from './client-type.enum';
import { ClientStatus } from './client-status.enum';
import { BillingType } from './billing-type.enum';
import { ShippingPriceType } from './shipping-price-type.enum';

export class Client {
  constructor(
    public id: string = null,
    public name: string = null,
    public description: string = null,
    public status: ClientStatus = ClientStatus.CREATED,
    public type: ClientType = null,
    public preferredCurrency: string = null,
    public billingType: BillingType = null,
    public shippingPriceType: ShippingPriceType = null,
    public shippingPriceEconomyCatalogUid: string = null,
    public shippingPriceExpressCatalogUid: string = null,
    public contactEmail: string = null,
    public billingEmail: string = null,
    public supportEmail: string = null,
    public createdAt: string = null,
  ) {}
}

export enum ClientFormEnum {
  id = 'id',
  name = 'name',
  description = 'description',
  status = 'status',
  type = 'type',
  preferredCurrency = 'preferredCurrency',
  billingType = 'billingType',
  shippingPriceType = 'shippingPriceType',
  shippingPriceEconomyCatalogUid = 'shippingPriceEconomyCatalogUid',
  shippingPriceExpressCatalogUid = 'shippingPriceExpressCatalogUid',
  contactEmail = 'contactEmail',
  billingEmail = 'billingEmail',
  supportEmail = 'supportEmail',
}
