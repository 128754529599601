import * as R from 'ramda';
import { SanityProductVariation } from '../sanity-product-variation';
import { SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';

export const findMatchingProductVariation = (
  productVariations: SanityProductVariation[],
  currentProductVariation: SanityProductVariation,
  key: string,
  value: string,
  exactMatchOnly = false,
): SanityProductVariation => {
  if (!productVariations?.length) {
    return null;
  }

  const sourceProductVariation = currentProductVariation ? currentProductVariation : productVariations[0];
  const notRelevantFieldNames = getNotRelevantFields(sourceProductVariation.productUid);
  let sourceProductVariationToCompare = {
    ...R.omit(notRelevantFieldNames, sourceProductVariation),
    [key]: value,
  };

  let foundProductVariation = productVariations.find(loopProductVariation => {
    const loopProductVariationToCompare = R.omit(notRelevantFieldNames, loopProductVariation);
    return R.equals(loopProductVariationToCompare, sourceProductVariationToCompare);
  });

  if (!foundProductVariation && !exactMatchOnly) {
    const keys = R.without(key, [
      SanityProductControlKey.wallpaperType,
      SanityProductControlKey.coatingType,
      SanityProductControlKey.coatingSides,
      SanityProductControlKey.paperType,
      SanityProductControlKey.colorType,
      SanityProductControlKey.orientation,
      SanityProductControlKey.bindingType,
      SanityProductControlKey.phoneModel,
    ]);

    const loopKeys = [];
    keys.forEach((loopKey: string) => {
      if (foundProductVariation) {
        return;
      }
      loopKeys.push(loopKey);

      sourceProductVariationToCompare = {
        ...R.omit(loopKeys, sourceProductVariationToCompare),
        [key]: value,
      };

      foundProductVariation = productVariations.find(loopProductVariation => {
        const loopProductVariationToCompare = R.omit([...notRelevantFieldNames, ...loopKeys], loopProductVariation);
        return R.equals(loopProductVariationToCompare, sourceProductVariationToCompare);
      });
    });
  }

  return foundProductVariation || null;
};

function getNotRelevantFields(productUid: string) {
  const notRelevantFieldNames = [
    'productUid',
    'preview',
    'imperial',
    'unified',
    'canvasframe',
    'digitalDimensions',
    'hasFlatShippingPriceType',
  ];

  if (productUid.includes('wallpaper')) {
    notRelevantFieldNames.push('formatsize');
  }

  return notRelevantFieldNames;
}
