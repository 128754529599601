import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { EProductSearchResponse } from '@gelato-api-ui/core/e-commerce/e-product-search-response';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EProductVariantConnectionStatus } from '@gelato-api-ui/core/e-commerce/e-product-variant-connection-status.enum';
import { EProductTreeItem } from '../types/e-product-tree-item';
import { ECommerceProductsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-products-api.service';
import { ECommerceProductGraphQlService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-graph-ql.service';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductSearchRequest } from '@gelato-api-ui/core/e-commerce/e-product-search-request';
import { ECommerceProductsTreeMappingService } from '@api-ui-app/src/app/product/product-add/services/e-commerce-products-tree-mapping.service';

@Injectable()
export class ECommerceProductsTreeService {
  private readonly connectionStatus = EProductVariantConnectionStatus.CONNECTED;

  constructor(
    private readonly eCommerceProductsApiService: ECommerceProductsApiService,
    private readonly eCommerceProductGraphQlService: ECommerceProductGraphQlService,
    private readonly eCommerceProductsTreeMappingService: ECommerceProductsTreeMappingService,
  ) {}

  getProductsList(store: EStore, title: string, limit: number, offset: number): Observable<EProductTreeItem[]> {
    return this.getProductsTree(store, {
      title,
      offset,
      limit,
    });
  }

  getSingleProduct(store: EStore, productId: string): Observable<EProductTreeItem[]> {
    return this.getProductsTree(store, {
      ids: [productId],
      offset: 0,
      limit: 1,
    });
  }

  private getProductsTree(store: EStore, request: Partial<EProductSearchRequest>): Observable<EProductTreeItem[]> {
    const storeId = store.id;

    return this.getProducts({
      ...request,
      storeId,
      connectionStatus: this.connectionStatus,
    } as EProductSearchRequest).pipe(
      catchError(() => {
        return of(null);
      }),
      switchMap((products: EProductAbbridged[]) => {
        if (!products) {
          return of(null);
        }

        return this.getProductVariants(store, products).pipe(
          map((productVariants: EProductVariant[]): EProductTreeItem[] =>
            productVariants
              ? this.eCommerceProductsTreeMappingService.getProductsTree(products, productVariants)
              : null,
          ),
        );
      }),
    );
  }

  private getProducts(request: EProductSearchRequest): Observable<EProductAbbridged[]> {
    return this.eCommerceProductsApiService.search(request).pipe(
      catchError(() => of(null)),
      map((response: EProductSearchResponse): EProductAbbridged[] => (response ? response.products : null)),
    );
  }

  private getProductVariants(store: EStore, products: EProductAbbridged[]): Observable<EProductVariant[]> {
    const productIds: string[] = products.map((loopProduct: EProductAbbridged): string => loopProduct.id);

    return this.getProductVariantsBatchRecursive(store, productIds, []);
  }

  private getProductVariantsBatchRecursive(
    store: EStore,
    productIds: string[],
    productVariants: EProductVariant[],
  ): Observable<EProductVariant[]> {
    const batchSize = 500;
    const offset = productVariants.length;

    return this.getProductVariantsBatch(store, productIds, batchSize, offset).pipe(
      switchMap((batchProductVariants: EProductVariant[]) => {
        const result = [...productVariants, ...(batchProductVariants || [])];

        if (batchProductVariants.length === batchSize) {
          return this.getProductVariantsBatchRecursive(store, productIds, result);
        } else {
          return of(result);
        }
      }),
    );
  }

  private getProductVariantsBatch(
    store: EStore,
    productIds: string[],
    limit: number,
    offset: number,
  ): Observable<EProductVariant[]> {
    const storeId = store.id;

    return this.eCommerceProductGraphQlService
      .searchProductVariants(storeId, {
        productIds,
        connectionStatuses: [this.connectionStatus],
        limit,
        offset,
      })
      .pipe(catchError(() => of(null)));
  }
}
