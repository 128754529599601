import { createAction, props } from '@ngrx/store';
import { PriceList } from '../product-catalogue/lib/price-list';

export const loadPriceList = createAction(
  '[PriceList] Load PriceList',
  props<{
    productUids: string[];
    country: string;
    currency: string;
    pageCount: number;
    clientId: string;
    hasFlatRates?: boolean;
  }>(),
);

export const loadPriceListStart = createAction(
  '[PriceList] Load PriceList Start',
  props<{ productUids: string[]; country: string; currency: string; pageCount: number; clientId: string }>(),
);

export const loadPriceListComplete = createAction('[PriceList] Load PriceList Complete');

export const setPriceList = createAction('[PriceList] Set PriceList', props<{ priceList: PriceList }>());

export const resetState = createAction('[PriceList] Reset State');
