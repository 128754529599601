import * as R from 'ramda';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { COUNTRIES_WITH_STATE } from '@api-ui-app/src/app/lib/constants';

const mandatoryCompanyDetailsFields = [
  'recipientName',
  'companyName',
  'address',
  'city',
  'postCode',
  'countryIsoCode',
  'email',
];

export function validateBillingAddress(customerEntity: CompanyDetails): boolean {
  const { countryIsoCode } = customerEntity;

  if (countryIsoCode && COUNTRIES_WITH_STATE.includes(countryIsoCode)) {
    mandatoryCompanyDetailsFields.push('stateCode');
  }

  const nullishFields = R.filter(R.isNil, customerEntity);
  return !Object.keys(nullishFields || {}).filter(nullishBillingKey =>
    mandatoryCompanyDetailsFields.includes(nullishBillingKey),
  )?.length;
}
