import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as R from 'ramda';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { AnalyticsGraphRequest } from '../types/analytics-graph-request';
import { AnalyticsGraphResponse } from '../types/analytics-graph-response';
import { ApolloQueryResult } from '@apollo/client/core';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import * as gql from 'gql-query-builder';
import { SavedShippingCostRequest } from '../types/saved-shipping-cost-request';

@Injectable({ providedIn: 'root' })
export class DashboardApiService {
  constructor(private apiRequest: ApiRequestService, private graphQlRequestService: GraphQlRequestService) {}

  getGraph(request: AnalyticsGraphRequest): Observable<AnalyticsGraphResponse> {
    if (!request) {
      return of(null);
    }

    const { graphName } = request;
    const body = R.omit(['graphName'], request);

    return this.apiRequest
      .get<AnalyticsGraphResponse>(`/api/v3/analytics/graphs/${graphName}`, {
        body,
      })
      .pipe(map((data: any): AnalyticsGraphResponse => data));
  }

  getSavedShippingCost(request: SavedShippingCostRequest): Observable<number> {
    const body = {
      clientId: request.clientId,
      startDate: request.startDate,
      endDate: request.endDate,
      currency: request.currency,
      groupBy: request.groupBy,
    };
    const query = this.getQuery(body);

    return this.graphQlRequestService
      .query(query, 'savedShippingCost')
      .pipe(map((data: ApolloQueryResult<{ savedShippingCost: number }>) => data?.data.savedShippingCost));
  }

  private getQuery(request: SavedShippingCostRequest) {
    return gql.query({
      operation: 'savedShippingCost',
      variables: {
        savedShippingCostDetails: { type: 'JSONObject!', value: request },
      },
    });
  }
}
