<gd-client-selector
  [showAllClientsOption]="showAllClientsOption"
  [allClientsOptionText]="'txt_any' | translate"
  [placeholder]="placeholder"
  [displayedField]="'description'"
  [valueField]="'name'"
  [value]="clientDescription"
  (valueChange)="onClientDescriptionChange($event)"
  [isSearchable]="isSearchable"
  [isDisabled]="isDisabled"
>
</gd-client-selector>
