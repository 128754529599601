import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gd-checkbox, gc-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements OnInit {
  @Input() partial: boolean;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  toggleChecked(event) {
    this.checkedChange.emit(!this.checked);
    event.stopPropagation();
  }
}
