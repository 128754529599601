import { ChangeDetectionStrategy, Component, ContentChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'gd-segment-header, gc-segment-header',
  templateUrl: './segment-header.component.html',
  styleUrls: ['./segment-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SegmentHeaderComponent {
  @Input() text = '';
  @Input() iconUrl: string = null;
  @Input() iconUrlAfterTitle: string = null;
  @Input() showDivider = false;
  @Input() mobileFlexColumn = false;
  @Input() iconSize = 32;

  @ContentChild('content') content: ElementRef;
}
