import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Media } from '@gelato-api-ui/core/sanity/media';
import { ContentNavigationType } from './lib/content-navigation-type';

@Component({
  selector: 'gc-product-previews-carousel',
  templateUrl: './product-previews-carousel.component.html',
  styleUrls: ['./product-previews-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPreviewsCarouselComponent {
  @Input() isMobile = false;
  @Input() media: Media[] = [];
  @Input() allowFullscreen = true;
  @Input() contentNavigationType: ContentNavigationType = ContentNavigationType.Tiles;
  @Input() allowSticky = false;
  @Input() topOffset = 0;
  @Input() slidesPerView = 9;
  @Input() showNavigation: boolean;
  @Input() productPreviewWrapperHeight: number;

  activeIndex$ = new BehaviorSubject(0);
  isModalOpened$ = new BehaviorSubject(false);

  constructor() {}

  onImageClick(indexFromClick: number) {
    this.activeIndex$.next(indexFromClick);
    this.isModalOpened$.next(true);
  }

  onModalsClose(indexFromModal: number) {
    this.activeIndex$.next(indexFromModal);
    this.isModalOpened$.next(false);
  }
}
