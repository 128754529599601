import { Component, Input, OnInit } from '@angular/core';
import { TooltipPositions } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.enum';

@Component({
  selector: 'gd-design-fee-label',
  templateUrl: './design-fee-label.component.html',
  styleUrls: ['./design-fee-label.component.scss'],
})
export class DesignFeeLabelComponent implements OnInit {
  @Input() totalFee: number;
  @Input() currency: string;
  @Input() tooltipText: string;

  tooltipPosition = TooltipPositions.topCenter;

  constructor() {}

  ngOnInit(): void {}
}
