import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';

@Component({
  selector: 'gd-radio-option-container',
  templateUrl: './radio-option-container.component.html',
  styleUrls: ['./radio-option-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioOptionContainerComponent {
  @Input() selected = false;
  @Input() value: any;

  @Output() select = new EventEmitter();

  @HostBinding('class.selected') get isSelected() {
    return this.selected;
  }

  @HostListener('click', ['$event']) onSelect(e: Event) {
    e.preventDefault();
    e.stopPropagation();

    if (!this.selected) {
      this.select.emit(this.value);
    }
  }
}
