<header class="stepper-wrapper">
  <ng-container *ngFor="let step of steps; let i = index">
    <div class="stepper-item" [ngClass]="selectedIndex === i ? activeClass : ''">
      <div class="stepper-header" [ngClass]="{ success: step.state === 'done', skipped: step.state === 'skipped' }">
        <div class="stepper-icon">
          <span *ngIf="showStepperCount">{{ i + 1 }}</span>
        </div>
        <a class="stepper-header-title" (click)="setSelectedIndex(i, step)">
          <ng-container *ngIf="step.stepLabel; else showLabelText" [ngTemplateOutlet]="step.stepLabel.template">
          </ng-container>
          <ng-template #showLabelText>
            {{ step.label }}
          </ng-template>
        </a>
      </div>

      <div class="body">
        <ng-container *ngIf="selectedIndex === i" [ngTemplateOutlet]="step.content"></ng-container>
      </div>
    </div>
  </ng-container>
</header>
