import { ProductExpansionCategoryUids } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/product-expansion-category-uids';
import { SelectedPreviewScenesCollection } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/selected-preview-scenes-collection';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';

export const getSelectedPreviewScenesFromSourceProduct = (
  sourceProduct: EProductWithVariants,
): SelectedPreviewScenesCollection => {
  if (!sourceProduct) {
    return {};
  }

  return ProductExpansionCategoryUids.reduce(
    (acc, productUid) => ({
      ...acc,
      [productUid]: sourceProduct.productVariantPreviewScene,
    }),
    {},
  );
};
