import { Environment } from './environment.interface';

export const environment: Environment = {
  production: true,
  version: '__GELATO_UI_APP_VERSION__',
  env: '@@env',
  showTranslationKeys: false,
  apparelS3Bucket: '@@apparel_s3_bucket',
  configCatKey: '@@config_cat_key',
  rutterKey: '@@rutter_api_key',
  api: {
    host: '',
  },
  uploaderCompanionUrl: '@@uploader_companion_url',
  keycloak: {
    url: '@@keycloak_url',
    realm: 'gelato-api',
  },
  cdn: '@@main_app_cdn_url',
  microFrontendServiceUrls: {
    designEditor: '@@mf_service_url_design_editor',
  },
  adyenEnvironment: '@@adyen_environment',
  paymentServiceEnvironment: '@@payment_service_environment',
  baseUrls: {
    gelatoNetwork: '@@gn_base_url',
    logisticsRouting: '@@logistics_base_url',
    sanity: '@@sanity_base_url',
    preflight: '@@preflight_base_url',
    preflightCdn: '@@preflight_cdn_base_url',
    imaginary: '@@imaginary_base_url',
    i18n: '@@i18n_base_url_dashboard',
    graphql: '@@graphql_base_url',
  },
  shutterstock: {
    keys: {
      essentials: 'G1PTOJzNrrvaESrDlGCeGrGpu7XdSICQ',
      full: 'nNJwspGEM8GtBdqnU2FyGk1Qcr8fQXf0',
    },
  },
  chameleonEnabled: Boolean(Number('@@chameleon_enabled')),
  integrations: {
    wix_url: '@@wix_url',
  },
  chargebeeSiteName: '@@chargebee_site_name',
  freeReorderVoucher: '@@free_reorder_voucher',
  vertex: {
    clientCode: 'JRnQQmClvR',
    wizardPath: 'https://ecwportal.vertexsmb.com/wizard',
  },
  chargebee: {
    customersLink: 'https://gelato.chargebee.com/d/customers/',
  },
};
