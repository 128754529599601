import { OrderDetailsActions, OrderDetailsActionTypes } from './order-details.actions';
import { OrderDetailsExtended } from '../../lib/order-details-extended';
import { OrderReceiptType } from '../../lib/order-receipt-type.enum';
import { Client } from '@gelato-api-ui/core/clients/client';

export interface State {
  isLoading: boolean;
  orderDetails: OrderDetailsExtended;
  activeOrderReceiptType: OrderReceiptType;
  client: Client;
}

export const initialState: State = {
  isLoading: false,
  orderDetails: null,
  client: null,
  activeOrderReceiptType: OrderReceiptType.CONTRACT,
};

export function reducer(state: State = initialState, action: OrderDetailsActions): State {
  switch (action.type) {
    case OrderDetailsActionTypes.LoadOrderDetailsStart:
      return {
        ...state,
        // isLoading: true,
      };
    case OrderDetailsActionTypes.LoadOrderDetailsSuccess:
      return {
        ...state,
        isLoading: false,
        orderDetails: action.payload,
      };
    case OrderDetailsActionTypes.LoadOrderDetailsFailure:
      return {
        ...state,
        isLoading: false,
      };
    case OrderDetailsActionTypes.ResetOrderDetails:
      return {
        ...state,
        isLoading: false,
        orderDetails: null,
      };
    case OrderDetailsActionTypes.LoadClientStart:
      return {
        ...state,
        isLoading: true,
      };
    case OrderDetailsActionTypes.LoadClientSuccess:
      return {
        ...state,
        isLoading: false,
        client: <Client>action.payload,
      };
    case OrderDetailsActionTypes.ToggleLoader:
      return {
        ...state,
        isLoading: action.payload,
      };
    case OrderDetailsActionTypes.SetActiveOrderReceiptType:
      return {
        ...state,
        activeOrderReceiptType: action.payload || OrderReceiptType.CONTRACT,
      };
    default:
      return state;
  }
}
