<div class="ui segment box-shadow-new" [ngClass]="{ loading: loading }">
  <section class="card-header">
    <div class="header-title">{{ headerTitle | translate }}</div>
    <ng-content select=".card-header"></ng-content>
  </section>

  <section class="card-body" [ngClass]="{ 'default-paddings': defaultPaddings }">
    <ng-content select=".card-body"></ng-content>
  </section>

  <section class="card-footer">
    <ng-content select=".card-footer"></ng-content>
  </section>
</div>
