import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import * as actions from './orders.actions';
import { OrderSearchRequest } from '../../lib/order-search-request';
import { OrderSearchResponse } from '../../lib/order-search-response';
import { OrderTotals } from '../../lib/order-totals';
import { InjectionToken } from '@angular/core';
import { isSyncOrdersActive } from '@api-ui-app/src/app/orders/lib/helpers/isSyncOrdersActive';
import { SyncOrdersState } from '@api-ui-app/src/app/orders/lib/sync-orders-state';

export interface State {
  isLoading: boolean;
  request: OrderSearchRequest;
  payload: OrderSearchResponse;
  totals: OrderTotals;
  totalsForPastMonth: OrderTotals;
  syncOrders: SyncOrdersState;
}

export const initialState: State = {
  isLoading: false,
  request: null,
  payload: null,
  totals: null,
  totalsForPastMonth: null,
  syncOrders: {
    canSync: false,
    inProgress: false,
    progressPercentage: 0,
  },
};

export const reducer: ActionReducer<State> = (state = initialState, action: actions.OrdersActions): State => {
  switch (action.type) {
    case actions.OrdersAction.ResetPayload:
      return {
        ...state,
        payload: null,
        totals: null,
        totalsForPastMonth: null,
      };
    case actions.loadStart.type:
      return {
        ...state,
        isLoading: true,
        request: action.request,
        payload: null,
      };
    case actions.loadFailure.type:
      return {
        ...state,
        isLoading: false,
      };
    case actions.loadSuccess.type:
      return {
        ...state,
        isLoading: false,
        request: action.request,
        payload: action.payload,
      };
    case actions.loadTotalsSuccess.type:
      return {
        ...state,
        totals: action.payload,
      };
    case actions.loadTotalsForPastMonthSuccess.type:
      return {
        ...state,
        totalsForPastMonth: action.payload,
      };
    case actions.deleteOrder.type:
      return {
        ...state,
        isLoading: true,
      };
    case actions.deleteOrderFail.type:
      return {
        ...state,
        isLoading: false,
      };
    case actions.setCanSyncOrdersFlag.type:
      return {
        ...state,
        syncOrders: {
          ...state.syncOrders,
          canSync: action.payload,
        },
      };
    case actions.syncOrdersStart.type:
      return {
        ...state,
        syncOrders: {
          ...state.syncOrders,
          inProgress: true,
          progressPercentage: 0,
        },
      };
    case actions.syncOrdersSuccess.type:
    case actions.syncOrdersFailure.type:
      return {
        ...state,
        syncOrders: {
          ...state.syncOrders,
          inProgress: false,
          progressPercentage: 0,
        },
      };
    case actions.syncOrdersProgressUpdate.type:
      return {
        ...state,
        syncOrders: {
          ...state.syncOrders,
          inProgress: isSyncOrdersActive(action.payload),
          progressPercentage: action?.payload?.data?.percent || 0,
        },
      };
    default:
      return state;
  }
};

export const ordersReducerToken = new InjectionToken<ActionReducerMap<State>>('orders reducer');
