import * as R from 'ramda';
import { OrderDetails } from '../../lib/order-details';
import { OrderReceiptType } from '../../lib/order-receipt-type.enum';
import { OrderReceiptTransactionType } from '../../lib/order-receipt-transaction-type.enum';
import { OrderReceipt } from '../../lib/order-receipt';

export const getOrderReceipt = (
  orderDetails: OrderDetails,
  receiptType: OrderReceiptType,
  receiptTransactionType: OrderReceiptTransactionType,
) => {
  if (!orderDetails || !orderDetails.receipts) {
    return null;
  }

  const foundReceipt: OrderReceipt = R.find(
    (receipt: OrderReceipt): boolean =>
      receipt.type === receiptType && receipt.transactionType === receiptTransactionType && receipt.items.length > 0,
    orderDetails.receipts,
  );

  return foundReceipt;
};
