import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'gc-ecommerce-product-wizard-autosave-status',
  templateUrl: './ecommerce-product-wizard-autosave-status.component.html',
  styleUrls: ['./ecommerce-product-wizard-autosave-status.component.scss'],
})
export class EcommerceProductWizardAutosaveStatusComponent implements OnInit, OnChanges {
  @Input() isSaving: boolean;
  @Input() isSaved: boolean;

  isSavedRecently = false;
  autoSaveCompleteSuccessMessageTimer = null;
  autoSaveCompleteSuccessMessageTimeout = 5000; // 5 seconds

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.isSaving) {
      if (changes.isSaving.currentValue) {
        this.isSavedRecently = false;
        this.clearAutoSaveCompleteSuccessMessageTimer();
      }

      if (!changes.isSaving.currentValue && changes.isSaving.previousValue) {
        this.isSavedRecently = true;
        this.setAutoSaveCompleteSuccessMessageTimer();
      }
    }
  }

  clearAutoSaveCompleteSuccessMessageTimer() {
    if (this.autoSaveCompleteSuccessMessageTimer) {
      clearTimeout(this.autoSaveCompleteSuccessMessageTimer);
    }

    this.autoSaveCompleteSuccessMessageTimer = null;
  }

  setAutoSaveCompleteSuccessMessageTimer() {
    this.clearAutoSaveCompleteSuccessMessageTimer();

    setTimeout(() => {
      this.isSavedRecently = false;
      this.clearAutoSaveCompleteSuccessMessageTimer();
    }, this.autoSaveCompleteSuccessMessageTimeout);
  }
}
