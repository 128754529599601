/**
 * https://gelato.atlassian.net/wiki/spaces/GAPI/pages/5529010251/2.2.9.9+Remove+Background+Tool
 * Endpoints that gets an image/asset and returns the image/asset with the transparent background
 */
import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  CreatePreviewRequest,
  CreatePreviewResponse,
  CreateAssetRequest,
  AssetTransformerUid,
} from '@api-ui-app/src/app/subscriptions/types/background-remove';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import * as Sentry from '@sentry/angular';

@Injectable({
  providedIn: 'root',
})
export class BackgroundRemoveApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  /**
   * Create preview for asset with background removed (temporary URL lasting 24h)
   * @param assetId: The asset that you want to remove its background
   */
  createPreviewForAsset(assetId: string): Observable<CreatePreviewResponse> {
    return this.apiRequest
      .post<CreatePreviewRequest>('/api/design/v1/asset-transformer/preview', {
        body: {
          assetId,
          transformers: [
            {
              assetTransformerUid: AssetTransformerUid.REMOVE_BG_LOW_RES,
            },
          ],
        },
      })
      .pipe(map((data: any) => data.data));
  }

  /**
   * Create asset with background removed
   * @param assetId: The asset that you want to remove its background
   */
  createAssetWithoutBackground(assetId: string): Observable<Asset> {
    return this.apiRequest
      .post<CreateAssetRequest>('/api/design/v1/asset-transformer/transform', {
        body: {
          assetId,
          transformers: [
            {
              assetTransformerUid: AssetTransformerUid.REMOVE_BG,
            },
          ],
        },
      })
      .pipe(map((data: any) => data.data));
  }
}
