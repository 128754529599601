import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gc-app-banner-promotion',
  templateUrl: './app-banner-promotion.component.html',
  styleUrls: ['./app-banner-promotion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppBannerPromotionComponent {
  @Input() imageUrl: string;
  @Input() text: string;
  @Input() buttonText: string;

  @Output() buttonAction = new EventEmitter();
  @Output() dismissAction = new EventEmitter();

  constructor() {}
}
