import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { ProductDetails } from '@gelato-api-ui/core/product-details/product-details';
import { ProductDetailsGraphQlService } from '@gelato-api-ui/core/product-details/services/product-details-graph-ql.service';

@Injectable({
  providedIn: 'root',
})
export class ProductTypeUidService {
  constructor(private readonly productDetailsGraphQlService: ProductDetailsGraphQlService) {}

  getByProductUid(productUid: string): Observable<ProductTypeUid> {
    return this.productDetailsGraphQlService
      .get(productUid)
      .pipe(
        map(
          (productDetails: ProductDetails): ProductTypeUid =>
            productDetails && productDetails.naming ? productDetails.naming.productTypeUid : null,
        ),
      );
  }
}
