<div
  class="thumbnail"
  [class.ghost-active-fluid]="isLoading"
  [class.with-border]="showBorder"
  [style.width.px]="width"
  [style.height.px]="height"
>
  <img *ngIf="loadedImageUrl" [src]="loadedImageUrl" alt="" />
  <div *ngIf="isLoading" class="loading-placeholder"></div>
  <ng-content></ng-content>
</div>
