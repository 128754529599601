import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';

@Pipe({ name: 'timePeriod' })
export class TimePeriodPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(timePeriodDuration: number, timePeriodUnit: string): Observable<string> {
    const translationKey = this.getTranslationKey(timePeriodDuration, timePeriodUnit);

    if (!translationKey) {
      return null;
    }

    return this.translateService.get(translationKey, { duration: timePeriodDuration });
  }

  private getTranslationKey(timePeriodDuration: number, timePeriodUnit: string): string {
    switch (timePeriodUnit) {
      case TimePeriodUnit.DAY:
        return this.isSingular(timePeriodDuration)
          ? 'txt_time_period_label_day_singular'
          : 'txt_time_period_label_day_plural';
      case TimePeriodUnit.WEEK:
        return this.isSingular(timePeriodDuration)
          ? 'txt_time_period_label_week_singular'
          : 'txt_time_period_label_week_plural';
      case TimePeriodUnit.MONTH:
        return this.isSingular(timePeriodDuration)
          ? 'txt_time_period_label_month_singular'
          : 'txt_time_period_label_month_plural';
      case TimePeriodUnit.YEAR:
        return this.isSingular(timePeriodDuration)
          ? 'txt_time_period_label_year_singular'
          : 'txt_time_period_label_year_plural';
      default:
        return null;
    }
  }

  private isSingular(timePeriodDuration: number): boolean {
    return Number(timePeriodDuration) === 1;
  }
}
