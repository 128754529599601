<div
  class="ui checkbox"
  [class.checked]="checked"
  [class.partial]="partial"
  [class.disabled]="disabled"
  (click)="toggleChecked($event)"
>
  <input type="checkbox" class="hidden" [checked]="checked" />
  <label><ng-content></ng-content></label>
</div>
