import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, first, map, tap } from 'rxjs/operators';

type RequestData = any;
type ResponseData = any;

interface CachedRequest {
  requestData: RequestData;
  responseData: ResponseData;
}

interface ActiveRequest {
  requestData: RequestData;
  observable: Observable<ResponseData>;
}

type Callback = () => Observable<ResponseData>;

@Injectable({ providedIn: 'root' })
export class CachedRequestsService {
  private cachedRequests: CachedRequest[] = [];
  private activeRequests: ActiveRequest[] = [];

  get(requestData: RequestData, callback: Callback): Observable<ResponseData> {
    const clonedRequestData = { ...requestData };
    const activeRequest: ActiveRequest = this.getActiveRequest(clonedRequestData);

    if (activeRequest?.observable) {
      return activeRequest.observable;
    }

    const cachedRequest: CachedRequest = this.getCachedRequest(clonedRequestData);

    if (cachedRequest?.responseData) {
      return of(cachedRequest?.responseData);
    }

    const observable = callback().pipe(
      first(),
      catchError(() => of(null)),
      map((responseData: ResponseData): ResponseData => {
        if (responseData) {
          this.saveToCache(clonedRequestData, responseData);
        }

        this.removeActiveRequest(clonedRequestData);

        return responseData;
      }),
    );

    this.addActiveRequest(clonedRequestData, observable);

    return observable;
  }

  private getCachedRequest(requestData: RequestData): CachedRequest {
    return this.cachedRequests.find((loopCachedRequest: CachedRequest): boolean =>
      this.isEqual(loopCachedRequest.requestData, requestData),
    );
  }

  private saveToCache(requestData: RequestData, responseData: ResponseData) {
    this.cachedRequests.push({ requestData, responseData });
  }

  private getActiveRequest(requestData: RequestData): ActiveRequest {
    return this.activeRequests.find((loopRequest: ActiveRequest): boolean =>
      this.isEqual(loopRequest.requestData, requestData),
    );
  }

  private addActiveRequest(requestData: RequestData, observable: Observable<ResponseData>) {
    this.activeRequests.push({ requestData, observable });
  }

  private removeActiveRequest(requestData: RequestData) {
    this.activeRequests = this.activeRequests.filter(
      (loopRequest: ActiveRequest): boolean => !this.isEqual(loopRequest.requestData, requestData),
    );
  }

  private isEqual(a: RequestData, b: RequestData): boolean {
    return JSON.stringify(a) === JSON.stringify(b);
  }
}
