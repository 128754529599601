import {
  SanityProductVariantFrameAssembling,
  SanityProductVariantWallpaperType,
} from '@gelato-api-ui/core/sanity/sanity-product-variant';

export interface SanityProductVariation {
  formatsize?: string;
  coatingsides?: SanityProductVariationCoatingSides;
  coatingtype?: string;
  colortype?: string;
  orientation?: string;
  papertype?: string;
  pagescount?: string;
  framecolor?: string;
  frameassembling?: SanityProductVariantFrameAssembling;
  wallhangercolor?: string;
  wallhangermaterial?: string;
  wallpaperMaterial?: string;
  wallpaperType?: SanityProductVariantWallpaperType;
  wallhangerproperties?: string;
  wallhangersize?: string;
  garmentcolor?: string;
  garmentprint?: string;
  garmentsize?: string;
  productUid: string;
  preview?: string;
  canvasframe?: string;
  canvasthicknesstype?: string;
  garmentcategory?: string;
  garmentcut?: string;
  garmentquality?: string;
  garmentsubcategory?: string;
  bindingtype?: string;
  mugmaterial?: string;
  foamcolor?: string;
  foamthickness?: string;
  woodthickness?: string;
  bagcolor?: string;
  bagprint?: string;
  bagquality?: string;
  bagsize?: string;
  bagsubcategory?: string;
  phonebrand?: string;
  phonemodel?: string;
  hasFlatShippingPriceType?: boolean;
  digitalDimensions?: Record<string, string>;
}

export enum SanityProductVariationCoatingSides {
  none = '0-0',
  first = '1-0',
}
