import { OverlayRef } from '@angular/cdk/overlay';
import { merge, Observable, Subject } from 'rxjs';
import { DropdownContentType } from '@gelato-api-ui/ui-kit/src/lib/dropdown/types';

export class DropdownRef<T = DropdownContentType> {
  private readonly beforeClosed = new Subject<boolean>();
  private readonly beforeClosed$: Observable<boolean>;
  private readonly backdropClick$: Observable<MouseEvent>;
  public readonly close$: Observable<boolean | MouseEvent>;

  constructor(private readonly overlayRef: OverlayRef, public readonly content: DropdownContentType) {
    this.backdropClick$ = overlayRef.backdropClick();
    this.beforeClosed$ = this.beforeClosed.asObservable();
    this.close$ = merge(this.beforeClosed$, this.backdropClick$);
  }

  public triggerCloseDropdown(): void {
    this.beforeClosed.next(true);
  }

  public close(): void {
    this._close();
  }

  private _close(): void {
    this.overlayRef.detach();
    this.overlayRef.dispose();
    this.beforeClosed.complete();
  }
}
