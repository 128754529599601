export enum KeyType {
  UNKNOWN = 0,
  CLIENT_REQUEST = 1,
  CLIENT_CALLBACK = 2,
  GELATO_NETWORK_CLIENT = 3,
  GELATO_SHIPMENT_CLIENT = 4,
  GELATO_API_ADMIN = 5,
  GELATO_API_CUSTOMER_SUPPORT = 6,
  SHOPIFY_WEBHOOK = 7,
}
