<div class="content-wrapper">
  <gd-sidebar-nav
    [hovered]="hovered"
    [minimise]="isMinimised"
    [navigationTree]="navigationTree$ | async"
    [isGelatoPlusSubscriptionEnding]="isGelatoPlusSubscriptionEnding"
    [endingSubscriptionDaysLeft]="nonRenewingGelatoPlusDaysLeft"
  >
  </gd-sidebar-nav>
</div>
