<div *ngIf="orderAddress">
  <ng-container *ngTemplateOutlet="isJapan(orderAddress.country) ? japanOrder : baseOrder"></ng-container>
</div>

<ng-template #baseOrder>
  <p>{{ orderAddress.firstName }} {{ orderAddress.lastName }}</p>
  <p *ngIf="orderAddress.companyName">{{ orderAddress.companyName }}</p>
  <p>{{ orderAddress.addressLine1 }}</p>
  <p *ngIf="orderAddress.addressLine2">{{ orderAddress.addressLine2 }}</p>
  <p>{{ orderAddress.postCode }} {{ orderAddress.city }}</p>
  <p *ngIf="orderAddress.state">
    {{ orderAddress.state | countryStateName: orderAddress.country:true | async }}
  </p>
  <p>{{ orderAddress.country | countryName | async }}</p>

  <p *ngIf="orderAddress.federalTaxId">
    <ng-container *ngIf="!orderAddress.isBusiness"> {{ 'txt_address_cpf_field_label' | translate }}: </ng-container>
    <ng-container *ngIf="orderAddress.isBusiness"> {{ 'txt_address_cnpj_field_label' | translate }}: </ng-container>
    {{ orderAddress.federalTaxId }}
  </p>

  <p *ngIf="orderAddress.registrationState">
    {{ 'txt_address_registration_state_field_label' | translate }}:
    {{ orderAddress.registrationState | brazilianStateName: true | async }}
  </p>

  <p *ngIf="orderAddress.stateTaxId">
    {{ 'txt_address_state_tax_id_field_label' | translate }}:
    {{ orderAddress.stateTaxId }}
  </p>

  <p>
    <a href="mailto:{{ orderAddress.email }}">{{ orderAddress.email }}</a>
  </p>
  <p>{{ phone }}</p>
</ng-template>

<ng-template #japanOrder>
  <p>{{ orderAddress.lastName }} {{ orderAddress.firstName }}</p>
  <p *ngIf="orderAddress.companyName">{{ orderAddress.companyName }}</p>
  <p>{{ orderAddress.postCode }}</p>
  <p>
    <ng-container *ngIf="orderAddress.state">
      {{ orderAddress.state | countryStateName: orderAddress.country:true | async }},&nbsp;
    </ng-container>
    {{ orderAddress.city }}
  </p>
  <p>{{ orderAddress.addressLine1 }}</p>
  <p *ngIf="orderAddress.addressLine2">{{ orderAddress.addressLine2 }}</p>
  <p>{{ orderAddress.country | countryName | async }}</p>
  <p>
    <a href="mailto:{{ orderAddress.email }}">{{ orderAddress.email }}</a>
  </p>
  <p>{{ phone }}</p>
</ng-template>
