export class OrderAddress {
  constructor(
    public id: string = null,
    public orderId: string = null,
    public country: string = null,
    public firstName: string = null,
    public lastName: string = null,
    public companyName: string = null,
    public addressLine1: string = null,
    public addressLine2: string = null,
    public city: string = null,
    public state: string = null,
    public postCode: string = null,
    public isBusiness: boolean = false,
    public federalTaxId: string = null,
    public registrationState: string = null,
    public stateTaxId: string = null,
    public email: string = null,
    public phone: string = null,
  ) {}
}

export interface ReturnAddress {
  country: string;
  firstName: string;
  lastName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  postcode: string;
  state: string;
  email: string;
  phone: string;
  companyName: string;
  isBusiness: boolean;
  federalTaxId: string;
  stateTaxId: string;
  registrationState: string;
}
