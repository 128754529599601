import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { Asset } from '../asset';
import { AssetCreateRequest, AssetGetRequest } from '../asset-api';
import { AssetImportFromShutterstockRequest } from '../asset-import-from-shutterstock-request';
import { AssetImportRequest } from '@gelato-api-ui/core/designs/asset-import-request';
import { AssetColorReplacementRequest } from '@gelato-api-ui/core/designs/asset-color-replacement-request';
import { AssetColorReplacementResponse } from '@gelato-api-ui/core/designs/asset-color-replacement-response';

@Injectable({ providedIn: 'root' })
export class AssetsApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  get(request: AssetGetRequest): Observable<Asset[]> {
    return this.apiRequest
      .get<{ assets: Asset[] }>('/api/design/v1/assets', { body: request })
      .pipe(map(response => response.data?.assets || []));
  }

  create(request: AssetCreateRequest): Observable<Asset> {
    return this.apiRequest
      .post<Asset>('/api/design/v1/assets', {
        body: request,
      })
      .pipe(map(response => response.data));
  }

  delete(id: string): Observable<Asset> {
    return this.apiRequest.delete<Asset>(`/api/design/v1/assets/${id}`).pipe(map(response => response.data));
  }

  import(request: AssetImportRequest): Observable<Asset> {
    return this.apiRequest
      .post<Asset>('/api/design/v1/assets:import', {
        body: request,
      })
      .pipe(map(response => response.data));
  }

  importFromShutterstock(request: AssetImportFromShutterstockRequest): Observable<Asset> {
    return this.apiRequest
      .post<Asset>('/api/design/v1/assets:shutterstock-import', {
        body: request,
      })
      .pipe(map(response => response.data));
  }

  getAssetUrlWithReplacedColors(
    assetId: string,
    req: AssetColorReplacementRequest,
  ): Observable<AssetColorReplacementResponse> {
    return this.apiRequest
      .post<AssetColorReplacementResponse>(`/api/v1/assets/${assetId}/previews:color`, {
        body: req,
      })
      .pipe(map(response => response.data));
  }
}
