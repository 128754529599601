import { Inject, Injectable, Optional } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ProductPrice } from '../product-price';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { API_HOST_CONFIG } from '@gelato-api-ui/sdk/src/lib/api/api.tokens';
import { ApiHostConfig } from '@gelato-api-ui/sdk/src/lib/api/api-host-config';
import { RecommendedRetailPriceResponse } from '@product-catalogue/src/lib/product-catalogue/types/product-recommeded-retail-prices';

@Injectable({
  providedIn: 'root',
})
export class ProductPriceApiService {
  private hostConfig: ApiHostConfig;
  constructor(private apiRequest: ApiRequestService, @Inject(API_HOST_CONFIG) @Optional() hostConfig: ApiHostConfig) {
    this.hostConfig = { productPrice: '/api/v3/products', ...hostConfig };
  }

  getPrices(
    productUid: string,
    country: string = null,
    currency: string = null,
    pageCount: number = null,
    clientId: string = null,
  ): Observable<ProductPrice[]> {
    const urlParams = [];

    if (country) {
      urlParams.push(`country=${country}`);
    }

    if (currency) {
      urlParams.push(`currency=${currency}`);
    }

    if (pageCount) {
      urlParams.push(`pageCount=${pageCount}`);
    }

    if (clientId) {
      urlParams.push(`clientId=${clientId}`);
    }

    const urlParamsStr = urlParams.length ? `?${urlParams.join('&')}` : '';

    return this.apiRequest
      .get<ProductPrice[]>(`${this.hostConfig.productPrice}/${encodeURIComponent(productUid)}/prices${urlParamsStr}`)
      .pipe(
        map((data: any) => data.data),
        map((data: any) => [...data].sort((a: ProductPrice, b: ProductPrice): number => a.quantity - b.quantity)),
        catchError((error): Observable<ProductPrice[]> => {
          return of(null);
        }),
      );
  }

  getRecommendedPrices(productUids: string[], countries: string[]): Observable<RecommendedRetailPriceResponse> {
    return this.apiRequest
      .get<RecommendedRetailPriceResponse>(`/api/v3/recommended-retail-prices`, { body: { productUids, countries } })
      .pipe(
        map((data: any) => data.data),
        catchError((error): Observable<ProductPrice[]> => {
          return of(null);
        }),
      );
  }
}
