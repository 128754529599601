import * as R from 'ramda';
import { SanityProductControlOption } from '@gelato-api-ui/core/sanity/sanity-product-control-option';

export const getSortedFormatSizeControlOptions = (
  options: SanityProductControlOption[],
): SanityProductControlOption[] => {
  return R.sort((a: SanityProductControlOption, b: SanityProductControlOption) => {
    if (Boolean(a.imperial) === Boolean(b.imperial)) {
      return 0;
    }

    return !a.imperial ? 1 : -1;
  }, options);
};
