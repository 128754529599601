import { Injectable } from '@angular/core';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EProductVariantsUpdateSummary } from '@api-ui-app/src/app/e-commerce-stores/lib/e-product-variants-update-summary';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { StoreProductVariantsCollection } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { StoreProductVariantsUpdateModal } from '@api-ui-app/src/app/e-commerce-stores/store-product-variants-update-modal/store-product-variants-update-modal.component';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { ECommerceProductVariantUpdateSummaryService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-variant-update-summary.service';

@Injectable({ providedIn: 'root' })
export class ECommerceProductVariantUpdateConfirmationService {
  constructor(
    private readonly modalService: SuiModalService,
    private readonly productVariantUpdateSummaryService: ECommerceProductVariantUpdateSummaryService,
  ) {}

  confirm(
    mode: EProductWizardMode,
    originalStoreProductVariantsCollection: StoreProductVariantsCollection,
    sanityProductCategoryName: SanityProductCategoryName,
    sanityProduct: SanityProduct,
    storeProductVariantsCollection: StoreProductVariantsCollection,
  ): Observable<boolean> {
    const productVariantsUpdateSummary: EProductVariantsUpdateSummary = this.productVariantUpdateSummaryService.get(
      mode,
      storeProductVariantsCollection,
      originalStoreProductVariantsCollection,
      sanityProductCategoryName,
      sanityProduct,
    );

    if (this.mustShowModal(productVariantsUpdateSummary)) {
      const subject = new BehaviorSubject<boolean>(null);

      this.modalService
        .open(new StoreProductVariantsUpdateModal(productVariantsUpdateSummary))
        .onApprove(() => {
          subject.next(true);
        })
        .onDeny(() => {
          subject.next(false);
        });

      return subject.pipe(filter(isConfirmed => isConfirmed !== null));
    }

    return of(true);
  }

  private mustShowModal(productVariantsUpdateSummary: EProductVariantsUpdateSummary): boolean {
    if (!productVariantsUpdateSummary) {
      return false;
    }

    if (productVariantsUpdateSummary.added && productVariantsUpdateSummary.added.length) {
      return true;
    }

    if (productVariantsUpdateSummary.removed && productVariantsUpdateSummary.removed.length) {
      return true;
    }

    return false;
  }
}
