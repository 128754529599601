<div [class.sticky-wrapper]="allowSticky">
  <gc-product-previews
    [isMobile]="isMobile"
    [media]="media"
    [activeIndex]="activeIndex$ | async"
    [contentNavigationType]="contentNavigationType"
    [allowFullscreen]="allowFullscreen"
    [wrapperHeight]="productPreviewWrapperHeight"
    [showNavigation]="showNavigation"
    (imageClick)="allowFullscreen && onImageClick($event)"
  ></gc-product-previews>
</div>

<gc-product-previews-fullscreen
  *ngIf="allowFullscreen && (isModalOpened$ | async)"
  [isMobile]="isMobile"
  [media]="media"
  [activeIndex]="activeIndex$ | async"
  [topOffset]="topOffset"
  [slidesPerView]="slidesPerView"
  [isOpened]="isModalOpened$ | async"
  (close)="onModalsClose($event)"
></gc-product-previews-fullscreen>
