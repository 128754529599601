import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EProductPublishingErrorCode } from '@gelato-api-ui/core/e-commerce/e-product-publishing-errors.enum';
import { EProductPublishingStateMessageParams } from '@api-ui-app/src/app/e-commerce-stores/types/e-product-publishing-state-message-params';
import { ECommerceProductPublishingStateMessageFormattingService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-publishing-state-message-formatting.service';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { HelpCenterArticles } from '@gelato-api-ui/core/help-center/help-center-articles';

@Injectable({ providedIn: 'root' })
export class ECommerceProductPublishingErrorService {
  private readonly translationKeysMapping = {
    [EProductPublishingErrorCode.GENERAL_ERROR]: 'txt_publishing_error_general_error',
    [EProductPublishingErrorCode.GENERAL_PRODUCT_DUPLICATION_ERROR]: 'txt_publishing_error_general_error',
    [EProductPublishingErrorCode.CONNECTION_ERROR]: 'txt_publishing_error_connection_error',
    [EProductPublishingErrorCode.ACCESS_SCOPE_ERROR]: 'txt_publishing_error_access_scope_error',
    [EProductPublishingErrorCode.ACCESS_ERROR]: 'txt_publishing_error_access_error',
    [EProductPublishingErrorCode.VALIDATION_ERROR]: 'txt_publishing_error_validation_error',
    [EProductPublishingErrorCode.UNKNOWN_VALIDATION_ERROR]: 'txt_publishing_error_unknown_validation_error',
    [EProductPublishingErrorCode.QUOTA_EXCEEDED_ERROR]: 'txt_publishing_error_quota_exceeded_error_auto_handling',
    [EProductPublishingErrorCode.STORE_UNAVAILABLE_ERROR]: 'txt_publishing_error_store_unavailable_error',
    [EProductPublishingErrorCode.PAYMENT_REQUIRED_ERROR]: 'txt_publishing_error_payment_required_error',
    [EProductPublishingErrorCode.PRODUCT_NOT_FOUND_ERROR]: 'txt_publishing_error_product_not_found_error',
    [EProductPublishingErrorCode.PRODUCT_NOT_EDITABLE_ERROR]: 'txt_publishing_error_product_not_editable_error',
    [EProductPublishingErrorCode.TIMEOUT_ERROR]: 'txt_publishing_error_timeout_error',
    [EProductPublishingErrorCode.DELIVERY_PROFILE_UPDATE_ERROR]: 'txt_publishing_error_delivery_profile_error',
    [EProductPublishingErrorCode.QUOTA_TEMP_EXCEEDED_ERROR]: 'txt_publishing_error_quota_temp_exceeded_error',
    [EProductPublishingErrorCode.INVALID_EXPAND_OPERATION_ERROR]: 'txt_publishing_error_invalid_expand_operation_error',
    [EProductPublishingErrorCode.WOO_ACCESS_SCOPE_ERROR]: 'txt_publishing_error_woo_access_scope_error',
    [EProductPublishingErrorCode.SHOPIFY_VARIANT_CREATE_LIMIT_ERROR]:
      'txt_publishing_error_shopify_variant_create_limit_error',
    [EProductPublishingErrorCode.ETSY_STORE_IN_VACATION_MODE]: 'txt_publishing_error_etsy_store_in_vacation_mode',
    [EProductPublishingErrorCode.ETSY_DOMESTIC_AND_GLOBAL_PRICING_ERROR]:
      'txt_publishing_error_etsy_domestic_and_global_pricing_error',
  };

  constructor(
    private readonly eCommerceProductPublishingStateMessageFormattingService: ECommerceProductPublishingStateMessageFormattingService,
    private readonly translateService: TranslateService,
  ) {}

  getErrorMessage(
    publishingErrorCode: EProductPublishingErrorCode,
    params: EProductPublishingStateMessageParams,
  ): string {
    const translationKey =
      this.getTranslationKey(publishingErrorCode) || this.getTranslationKey(EProductPublishingErrorCode.GENERAL_ERROR);

    let message = this.eCommerceProductPublishingStateMessageFormattingService.get(translationKey, params);

    if (params.errorDetails) {
      message += `<br>${this.translateService.instant('txt_details')}: ${params.errorDetails}.<br>`;
    }

    return message;
  }

  getLearnMoreLinkUrl(publishingErrorCode: EProductPublishingErrorCode, storeType: EStoreType): string {
    if (publishingErrorCode === EProductPublishingErrorCode.QUOTA_EXCEEDED_ERROR && storeType === EStoreType.ETSY) {
      return 'https://developer.etsy.com/documentation/essentials/rate-limits/';
    }

    switch (publishingErrorCode) {
      case EProductPublishingErrorCode.PRODUCT_NOT_FOUND_ERROR:
        return 'https://apisupport.gelato.com/hc/en-us/articles/7499688147474-My-product-fails-to-publish-as-it-does-not-exist-in-my-store-anymore-What-should-I-do-';
      case EProductPublishingErrorCode.ETSY_STORE_IN_VACATION_MODE:
        return 'https://help.etsy.com/hc/en-us/articles/115015662947-How-to-Turn-On-Vacation-Mode?segment=selling';
      case EProductPublishingErrorCode.ETSY_DOMESTIC_AND_GLOBAL_PRICING_ERROR:
        return 'https://help.etsy.com/hc/en-us/articles/4403156582039-How-to-Add-Domestic-Global-Pricing-to-Your-Listings?segment=selling';
      default:
        return HelpCenterArticles.helpCenter;
    }
  }

  canRetry(publishingErrorCode: EProductPublishingErrorCode): boolean {
    return publishingErrorCode === EProductPublishingErrorCode.GENERAL_ERROR;
  }

  private getTranslationKey(publishingErrorCode: EProductPublishingErrorCode): string {
    return this.translationKeysMapping[publishingErrorCode];
  }
}
