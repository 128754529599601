import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';
import { catchError, map, mergeMap, takeWhile } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { environment } from '@api-ui-app/src/environments/environment';
import { caller } from '@gelato-api-ui/core/api/caller';
import { callerBlob } from '@gelato-api-ui/core/api/caller-blob';
import { callerText } from '@gelato-api-ui/core/api/caller-text';
import { callWithRetries } from '@gelato-api-ui/core/api/helpers/callWithRetries';
import { serializeObject } from '../helpers/serializeObject';
import { isPreflightTaskActive } from '../helpers/isPreflightTaskActive';
import { PreflightPdfInfoRequest } from '../preflight-pdf-info-request';
import { PreflightPdfInfoResponse } from '../preflight-pdf-info-response';
import { PreflightPdfPreviewRequest } from '../preflight-pdf-preview-request';
import { PreflightPdfPreflightRequest } from '../preflight-pdf-preflight-request';
import { PreflightPdfPreflightResponse } from '../preflight-pdf-preflight-response';
import { PreflightTaskStatusResponse } from '../preflight-task-status-response';
import { PreflightProductPreviewRequest } from '../preflight-product-preview-request';
import { PreflightProductMatchRequest } from '../preflight-product-match-request';
import { PreflightProductMatchResponse } from '../preflight-product-match-response';
import {
  PreflightProductFitImagesRequest,
  PreflightProductFitPdfRequest,
} from '../preflight-product-fit-images-request';
import { PreflightProductFitImagesResponse } from '../preflight-product-fit-images-response';
import { PreflightImageFromJsonResponse } from '../preflight-image-from-json-response';
import { PreviewFileType } from '../preview-file-type.enum';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import {
  PreflightPreviewsArchiveRequest,
  PreflightPreviewsArchiveResponse,
} from '@gelato-api-ui/core/preflight/preflight-previews-download';
import { PreflightProductResolutionRequest } from '@gelato-api-ui/core/preflight/preflight-product-resolution-request';
import { PreflightProductResolutionResponse } from '@gelato-api-ui/core/preflight/preflight-product-resolution-response';

@Injectable({ providedIn: 'root' })
export class PreflightApiService {
  private get: <T>(url: string, options?: AjaxRequest) => Observable<any>;
  private post: <T>(url: string, options?: AjaxRequest) => Observable<any>;
  private getBlob: <T>(url: string, options?: AjaxRequest) => Observable<Blob>;
  private getText: <T>(url: string, options?: AjaxRequest) => Observable<string>;

  constructor(private authService: AuthService) {
    this.get = caller(this.baseUrl, 'GET', () => this.authService.getHttpHeaders());
    this.post = caller(this.baseUrl, 'POST', () => this.authService.getHttpHeaders());
    this.getBlob = callerBlob(this.baseUrl, 'GET', () => this.authService.getHttpHeaders());
    this.getText = callerText(this.baseUrl, 'GET', () => this.authService.getHttpHeaders());
  }

  get baseUrl(): string {
    return environment.baseUrls.preflight;
  }

  getPdfInfo(request: PreflightPdfInfoRequest): Observable<PreflightPdfInfoResponse> {
    return this.get<PreflightPdfInfoResponse>('/pdf/info', {
      body: request,
    }).pipe(map(data => data.data));
  }

  getProductResolution(request: PreflightProductResolutionRequest): Observable<PreflightProductResolutionResponse> {
    return this.get<PreflightProductResolutionResponse>('/product/resolution', {
      body: request,
    }).pipe(map(data => data.data));
  }

  getPdfPreviewUrl(request: PreflightPdfPreviewRequest): string {
    const query: string = serializeObject(request);

    return `${this.baseUrl}/pdf/preview?${query}`;
  }

  getPdfPreviewUrls(fileUrl: string, pageCount: number, dpi: number = 75): string[] {
    const urls: string[] = [];

    for (let i = 0; i < pageCount; i++) {
      const request: PreflightPdfPreviewRequest = {
        pdf_url: fileUrl,
        page_nr: i + 1,
        dpi,
      };

      urls.push(this.getPdfPreviewUrl(request));
    }

    return urls;
  }

  getPdfPreflightAsync(request: PreflightPdfPreflightRequest): Observable<PreflightPdfPreflightResponse> {
    return this.get<PreflightPdfPreflightResponse>('/pdf/preflight', {
      body: {
        ...request,
        wait: 0,
      },
    }).pipe(map(data => data.data));
  }

  getTaskStatus(taskId: string): Observable<PreflightTaskStatusResponse> {
    return this.get<PreflightPdfPreflightResponse>(`/task/${taskId}`).pipe(map(data => data.data));
  }

  getTaskStatusUntilFinished(taskId: string): Observable<PreflightTaskStatusResponse> {
    return timer(0, 500).pipe(
      mergeMap(() => this.getTaskStatus(taskId)),
      takeWhile((response: PreflightTaskStatusResponse): boolean => isPreflightTaskActive(response.state), true),
    );
  }

  getProductPreviewUrl(request: PreflightProductPreviewRequest): Observable<string> {
    if (!request) {
      return of(null);
    }

    const body = {
      type: 'scene',
      ...request,
    };

    if (request.image_url) {
      body.image_url = encodeURIComponent(request.image_url);
    }

    if (request.images_urls) {
      body.images_urls = encodeURIComponent(request.images_urls);
    }

    return this.get<Record<'url', string>>('/v1/product/preview', {
      body,
      headers: { 'Content-Type': 'application/json' },
    }).pipe(map(r => r.data.url));
  }

  // deprecated: should be removed during GAPI-13663
  getProductPreviewUrlOld(request: PreflightProductPreviewRequest): string {
    if (!request) {
      return null;
    }

    const query: string = serializeObject({
      type: 'scene',
      ...request,
    });

    return `${this.baseUrl}/product/preview?${query}`;
  }

  getProductMatch(request: PreflightProductMatchRequest): Observable<PreflightProductMatchResponse> {
    return this.get<PreflightProductMatchResponse>('/product/match', {
      body: request,
    }).pipe(map(data => data.data));
  }

  getProductFitImages(request: PreflightProductFitImagesRequest): Observable<PreflightProductFitImagesResponse> {
    return this.get<PreflightProductFitImagesResponse>('/product/fit/images', {
      body: request,
    }).pipe(map(data => data.data));
  }

  getProductFitPdf(request: PreflightProductFitPdfRequest): Observable<PreflightProductFitImagesResponse> {
    return this.get<PreflightProductFitImagesResponse>('/product/fit/pdf', {
      body: request,
    }).pipe(map(data => data.data));
  }

  getImageFromJson(
    designStructure: DesignStructure,
    pageNr: number,
    width: number,
    height: number,
    format: PreviewFileType,
    removeSamples: boolean,
  ): Observable<PreflightImageFromJsonResponse> {
    return callWithRetries(() => {
      const query: string = serializeObject({
        page_nr: pageNr,
        width,
        height,
        format,
        remove_samples: Number(Boolean(removeSamples)),
      });

      return this.post<any>(`/product/image_from_json?${query}`, {
        body: designStructure,
      }).pipe(map(data => data.data));
    }).pipe(
      catchError(error => {
        Sentry.captureException(new Error('GetImageFromJsonFailed'), scope => {
          scope.setExtras({
            designStructure: JSON.stringify(designStructure),
            width,
            height,
            format,
            error,
          });

          return scope;
        });

        return of(null);
      }),
    );
  }

  requestPreviewsDownload(request: PreflightPreviewsArchiveRequest): Observable<PreflightPreviewsArchiveResponse> {
    return this.post<PreflightPreviewsArchiveResponse>('/product/previews/download', { body: request }).pipe(
      map(data => data.data),
    );
  }

  checkPreviewsDownloadTask(task_id: string): Observable<PreflightTaskStatusResponse> {
    return this.get<PreflightTaskStatusResponse>(`/task/${task_id}`).pipe(map(data => data.data));
  }
}
