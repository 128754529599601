import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { map, take, takeUntil, tap } from 'rxjs/operators';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { ECommerceStoreReconnectionService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-store-reconnection.service';

@Component({
  selector: 'gd-store-reconnection-notification',
  templateUrl: './store-reconnection-notification.component.html',
  styleUrls: ['./store-reconnection-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreReconnectionNotificationComponent implements OnInit, OnDestroy {
  @Input()
  set store(store: EStore) {
    this.store$.next(store);
  }

  private readonly store$ = new BehaviorSubject<EStore>(null);
  private readonly lang$ = new BehaviorSubject<string>(null);

  isReconnectionRequired$ = this.store$.pipe(
    map((store: EStore): boolean => this.eCommerceStoreReconnectionService.isReconnectionRequired(store)),
  );

  canReconnect$ = this.store$.pipe(
    map((store: EStore): boolean => this.eCommerceStoreReconnectionService.canReconnectStore(store)),
  );

  title$ = combineLatest([this.store$, this.lang$]).pipe(
    map(([store]): string => this.eCommerceStoreReconnectionService.getNotificationTitle(store?.type)),
  );

  description$ = combineLatest([this.store$, this.lang$]).pipe(
    map(([store]): string => this.eCommerceStoreReconnectionService.getNotificationDescription(store)),
  );

  private readonly ngOnDestroy$ = new Subject<boolean>();

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly eCommerceStoreReconnectionService: ECommerceStoreReconnectionService,
  ) {}

  ngOnInit() {
    this.lang$.next(this.translateService.currentLang);
    this.translateService.onLangChange
      .pipe(
        takeUntil(this.ngOnDestroy$),
        tap((langChangeEvent: LangChangeEvent) => {
          this.lang$.next(langChangeEvent.lang);
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }

  onReconnectButtonClick() {
    this.store$
      .pipe(
        take(1),
        tap((store: EStore) => {
          this.eCommerceStoreReconnectionService.reconnectStore(store);
        }),
      )
      .subscribe();
  }
}
