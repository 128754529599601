import { KeyType } from './key-type.enum';
import { KeyStatus } from './key-status.enum';

export class KeyCreateRequest {
  constructor(
    public clientId: string,
    public name: string,
    public key: string,
    public type: KeyType,
    public accessScopes: string[],
    public status: KeyStatus,
  ) {}
}
