import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductTreeItem } from '@api-ui-app/src/app/product/product-add/types/e-product-tree-item';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { internalStoreTypes } from '@gelato-api-ui/core/e-commerce/constants/internal-store-types';

export const getProductThumbnailUrl = (
  product: EProductWithVariants | EProductTreeItem,
  storeType: EStoreType,
): string => {
  if (!product) {
    return null;
  }

  if (internalStoreTypes.includes(storeType) || !product.externalThumbnailUrl) {
    return product.previewUrl;
  }

  return product.externalThumbnailUrl;
};
