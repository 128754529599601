import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { CustomSerializer } from '@gelato-api-ui/sdk/src/lib/router/custom-serializer';
import { routes } from './app.routes';

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
  ],
  exports: [RouterModule],
  providers: [{ provide: RouterStateSerializer, useClass: CustomSerializer }],
})
export class AppRoutingModule {}
