<div class="carousel-preview" [class.swiper-zoom-container]="addZoomClasses">
  <ng-container *ngTemplateOutlet="useLoading ? withLoading : withoutLoading"></ng-container>
  <ng-content *ngIf="url"></ng-content>
</div>

<ng-template #withLoading>
  <!--  <div *ngIf="isLoading$ | async" class="ghost-active-fluid"></div>-->
  <div *ngIf="isLoading$ | async" class="ui active loader"></div>
  <img
    *ngIf="loadedImageUrl"
    class="with-animation"
    [class.swiper-zoom-target]="addZoomClasses"
    [src]="loadedImageUrl"
    alt=""
  />
</ng-template>

<ng-template #withoutLoading>
  <img [class.swiper-zoom-target]="addZoomClasses" [src]="url" alt="" />
</ng-template>
