import { createReducer, on } from '@ngrx/store';
import * as actions from './price-list.actions';
import { PriceList } from '../product-catalogue/lib/price-list';

export interface State {
  isLoading: boolean;
  productUids: string[];
  country: string;
  currency: string;
  pageCount: number;
  clientId: string;
  priceList: PriceList;
}

export const initialState: State = {
  isLoading: false,
  productUids: null,
  country: null,
  currency: null,
  pageCount: undefined,
  clientId: null,
  priceList: null,
};

export const reducer = createReducer(
  initialState,
  on(actions.resetState, () => initialState),
  on(actions.loadPriceListStart, (state, action) => ({
    isLoading: true,
    productUids: action.productUids,
    country: action.country,
    currency: action.currency,
    pageCount: action.pageCount,
    clientId: action.clientId,
    priceList: null,
  })),
  on(actions.loadPriceListComplete, (state, action) => ({
    ...state,
    isLoading: false,
  })),
  on(actions.setPriceList, (state, action) => ({
    ...state,
    priceList: action.priceList,
  })),
);
