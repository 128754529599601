import { Injectable, Injector } from '@angular/core';
import { ComponentType, Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DialogRef } from './dialog-ref';
import { DIALOG_DATA } from '@gelato-api-ui/sdk/src/lib/shared/dialog/dialog-tokens';

export interface DialogConfig<T = any> {
  data?: T;
  overlayConfig?: OverlayConfig;
}

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(private readonly overlay: Overlay, private readonly injector: Injector) {}

  open<T>(component: ComponentType<T>, config: DialogConfig = {}) {
    const { data, overlayConfig = {} } = config;
    const positionStrategy = this.overlay.position().global().centerVertically().centerHorizontally();

    const overlayRef = this.overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'overlay-backdrop',
      panelClass: 'overlay-panel',
      ...overlayConfig,
    });

    const dialogRef = new DialogRef(overlayRef, positionStrategy);
    const injector = Injector.create({
      parent: this.injector,
      providers: [
        { provide: DialogRef, useValue: dialogRef },
        { provide: DIALOG_DATA, useValue: data },
      ],
    });
    const portal = new ComponentPortal(component, null, injector);

    overlayRef.attach(portal);

    return dialogRef;
  }
}
