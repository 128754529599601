<ng-container *ngIf="isItemVisible$ | async">
  <div *ngIf="(item$ | async)?.href; then href; else route"></div>
</ng-container>

<ng-template #newItemLabel>
  <div class="new-item-label">
    {{ 'txt_new_sidebar_item_label' | translate }}
  </div>
</ng-template>

<ng-template #href>
  <div
    class="container level-{{ level }} {{ itemId$ | async }}"
    [class.minimised]="minimise && !hovered"
    [class.active-sibling]="hasActiveChild$ | async"
  >
    <div class="active-item-indicator"></div>
    <a
      id="sidebar-nav-item-{{ itemId$ | async }}"
      class="name"
      gcLogEvent
      [analyticsKey]="(item$ | async).analyticKey"
      [class.no-icon]="noIcon$ | async"
      [href]="(item$ | async)?.href"
      (click)="onClick()"
      target="_blank"
      rel="noreferrer noopener"
    >
      <i *ngIf="(item$ | async)?.icon" class="ui icon {{ (item$ | async)?.icon }}"></i>
      <div *ngIf="(item$ | async)?.iconSvg" class="svg-icon-wrapper">
        <img src="/assets/sidebar-icons/{{ item.iconSvg }}.svg" />
      </div>

      <span [class.minimise]="minimise" [class.hovered]="hovered">
        {{ item?.name | translate: (i18nParams$ | async) }}
      </span>
    </a>
  </div>
</ng-template>

<ng-template #route>
  <ng-container *ngIf="!item.hasChild || (hasItemVisibleChild$ | async)">
    <a
      id="sidebar-nav-item-{{ item.id }}"
      class="container level-{{ level }}"
      gcLogEvent
      queryParamsHandling="merge"
      [analyticsKey]="(item$ | async).analyticKey"
      [class.collapsed]="!isExpanded"
      [class.minimised]="minimise && !hovered"
      [class.has-child]="item.hasChild"
      [class.active]="!item.route && (hasActiveChild$ | async)"
      [class.active-sibling]="hasActiveSibling"
      [routerLink]="route$ | async"
      (click)="onClick()"
      [queryParams]="item.queryParams"
      [routerLinkActive]="item.route ? 'active' : ''"
      [routerLinkActiveOptions]="{
        exact: item.routerOptions && item.routerOptions.exact === true
      }"
    >
      <div class="active-item-indicator" [class.active]="isExpanded"></div>
      <div class="name" [class.no-icon]="!item.icon && !item.iconSvg">
        <i *ngIf="item.icon" class="ui icon {{ item.icon }}"></i>
        <div *ngIf="item.iconSvg" class="svg-icon-wrapper">
          <img [src]="'sidebar-icons/' + item.iconSvg + '.svg' | assetsPath | async" />
        </div>

        <span [class.minimise]="minimise" [class.hovered]="hovered">{{
          item.name | translate: (i18nParams$ | async)
        }}</span>
      </div>
      <gc-gelato-plus-days-left-counter
        *ngIf="hasSubscriptionCounter"
        [floatingMode]="minimise && !hovered"
        class="subscription-counter"
        textFontSize="9px"
        [text]="endingSubscriptionDaysLeft === 1 ? ('txt_day_left' | translate) : ('txt_days_left' | translate)"
        [daysLeft]="endingSubscriptionDaysLeft"
        [isMenuItem]="true"
      >
      </gc-gelato-plus-days-left-counter>

      <div class="expander" *ngIf="item.hasChild && (!minimise || !!hovered)">
        <i class="icon chevron" [class.down]="!isExpanded" [class.up]="isExpanded"> </i>
      </div>

      <ng-container *ngIf="(item$ | async)?.isNew && (!minimise || !!hovered)">
        <ng-container *ngTemplateOutlet="newItemLabel"></ng-container>
      </ng-container>
    </a>

    <ng-container *ngIf="isExpanded && (!minimise || !!hovered)">
      <ng-container *ngFor="let subItem of item.children">
        <gd-sidebar-nav-item
          *ngIf="subItem"
          [hasActiveSibling]="hasActiveChild$ | async"
          [item]="subItem"
          [level]="level + 1"
          [counters]="counters"
        >
        </gd-sidebar-nav-item>
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>
