import { UserStatus } from './user-status.enum';

export class UserUpdateRequest {
  constructor(
    public clientId: string,
    public firstName: string,
    public lastName: string,
    public email: string,
    public phone: string,
    public details: string,
    public roles: string[],
    public accessScopes: string[],
    public locale: string,
    public status: UserStatus,
  ) {}
}
