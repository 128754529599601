import { Injectable } from '@angular/core';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { EProductVariantConnectionSettings } from '@gelato-api-ui/sdk/src/lib/shared/e-product-variant-connection-settings-modal/types/e-product-variant-connection-settings';
import { EProductVariantConnectionSettingsModal } from '@gelato-api-ui/sdk/src/lib/shared/e-product-variant-connection-settings-modal/e-product-variant-connection-settings-modal.component';
import { DefaultEProductVariantConnectionSettings } from '@gelato-api-ui/sdk/src/lib/shared/e-product-variant-connection-settings-modal/types/default-e-product-variant-connection-settings';
import { toPriceNumber } from '@gelato-api-ui/core/product-prices/helpers/toPriceNumber';

@Injectable()
export class EProductVariantConnectionSettingsModalService {
  constructor(private readonly modalService: SuiModalService) {}

  open(
    clientId: string,
    storeId: string,
    isInternalStoreType: boolean,
    currency: string,
    price: number,
    oldCost: number,
    newCost: number,
    isGelatoUser: boolean,
  ): Promise<EProductVariantConnectionSettings> {
    return new Promise(resolve => {
      const hasCost = oldCost > 0;
      const isCostChanged = toPriceNumber(newCost) !== toPriceNumber(oldCost);

      if (!isInternalStoreType && (isGelatoUser || !hasCost || isCostChanged)) {
        this.modalService
          .open(new EProductVariantConnectionSettingsModal(clientId, storeId, currency, price, oldCost, newCost))
          .onApprove((settings: EProductVariantConnectionSettings) => resolve(settings));
      } else {
        let updatePrice = false;
        let cost = oldCost;

        if (isInternalStoreType) {
          updatePrice = true;
          cost = newCost;
        }

        resolve({
          ...DefaultEProductVariantConnectionSettings,
          updatePrice,
          currency,
          price,
          cost,
        });
      }
    });
  }
}
