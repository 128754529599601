import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StandardLayoutComponent } from './standard-layout/standard-layout.component';
import { SidebarNavComponent } from './sidebar-nav/sidebar-nav.component';
import { SharedModule } from '../shared/shared.module';
import { SidebarNavItemComponent } from './sidebar-nav-item/sidebar-nav-item.component';
import { StandardHeaderDesktopComponent } from './header-desktop/header-desktop.component';
import { StandardHeaderMobileComponent } from './header-mobile/header-mobile.component';
import { SidebarDesktopComponent } from './sidebar-desktop/sidebar-desktop.component';
import { SidebarMobileComponent } from './sidebar-mobile/sidebar-mobile.component';
import { AuthButtonDesktopComponent } from './auth-button-desktop/auth-button-desktop.component';
import { AuthButtonMobileComponent } from './auth-button-mobile/auth-button-mobile.component';
import { ActiveClientInfoComponent } from '@api-ui-app/src/app/layout/active-client-info/active-client-info.component';
import { PreviewsArchiveModule } from '@api-ui-app/src/app/previews-archive/previews-archive.module';
import { StandardLayoutGhostComponent } from './standard-layout-ghost/standard-layout-ghost.component';
import { DashboardGhostComponent } from './dashboard-ghost/dashboard-ghost.component';
import { DiscountModule } from '../discount/discount.module';
import { UserDiscountsMenuComponent } from '../shared/user-discounts-menu/user-discounts-menu.component';
import { CustomWidgetComponent } from '@api-ui-app/src/app/layout/custom-widget/custom-widget.component';
import { WidgetSegmentComponent } from '@api-ui-app/src/app/layout/custom-widget/widget-segment/widget-segment.component';
import { CustomWidgetContainerComponent } from '@api-ui-app/src/app/layout/containers/custom-widget-container/custom-widget-container.component';
import { HeaderCreateMenuModule } from '@api-ui-app/src/app/header-create-menu/header-create-menu.module';

const exportedComponents = [
  StandardLayoutComponent,
  SidebarNavComponent,
  SidebarNavItemComponent,
  StandardHeaderDesktopComponent,
  StandardHeaderMobileComponent,
  SidebarDesktopComponent,
  SidebarMobileComponent,
  AuthButtonDesktopComponent,
  AuthButtonMobileComponent,
  ActiveClientInfoComponent,
  StandardLayoutGhostComponent,
  DashboardGhostComponent,
  UserDiscountsMenuComponent,
  CustomWidgetComponent,
  WidgetSegmentComponent,
  CustomWidgetContainerComponent,
];

@NgModule({
  imports: [CommonModule, SharedModule, DiscountModule, PreviewsArchiveModule, HeaderCreateMenuModule],
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
})
export class LayoutModule {}
