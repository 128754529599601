import * as R from 'ramda';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductSearchRequest } from '@gelato-api-ui/core/e-commerce/e-product-search-request';
import { EProductSearchResponse } from '@gelato-api-ui/core/e-commerce/e-product-search-response';
import { InitialProductVariantConnectionData } from '@gelato-api-ui/core/e-commerce/initial-product-variant-connection-data';
import * as actions from './e-commerce-products.actions';
import { EProductCollection } from '@gelato-api-ui/core/e-commerce/e-product-collection';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { EProductStatus } from '@gelato-api-ui/core/e-commerce/e-product-status.enum';
import { Tag } from '@gelato-api-ui/ui-kit/src/lib/tag/tag';
import { EProductData } from '@gelato-api-ui/core/e-commerce/e-product-data';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { EProductPublishingDetailsFlag } from '@gelato-api-ui/core/e-commerce/e-product-publishing-details-flag.enum';
import { EProductPublishingErrorCode } from '@gelato-api-ui/core/e-commerce/e-product-publishing-errors.enum';

export interface State {
  productsList: ProductsListState;
  product: ProductState;
  initialProductVariantConnectionData: InitialProductVariantConnectionData;
  tags: { [storeId: string]: Tag[] };
  collections: { [storeId: string]: EProductCollection[] };
  publishState: PublishingState[];
  saveProduct: boolean;
  expandProduct: {
    productData: EProductData;
    mode: EProductWizardMode;
    publishWithFreeShipping?: boolean;
    publishImmediately?: boolean;
  };
  productDuplicationAvailability: ProductDuplicationAvailabilityFlagsCollection;
  isPublishingProcessMonitoringActive: boolean;
}

export interface PublishingState {
  productId: string;
  productTitle: string;
  detailsFlags?: EProductPublishingDetailsFlag[];
  errorCode?: EProductPublishingErrorCode;
  errorDetails?: string;
}

export interface ProductsListState {
  isLoading: boolean;
  request: EProductSearchRequest;
  payload: EProductSearchResponse;
}

export interface ProductState {
  isLoading: boolean;
  payload: EProductWithVariants;
}

export interface ProductDuplicationAvailabilityFlagsCollection {
  [productId: string]: boolean;
}

export const initialState: State = {
  productsList: {
    isLoading: false,
    request: null,
    payload: null,
  },
  product: {
    isLoading: false,
    payload: null,
  },
  initialProductVariantConnectionData: null,
  tags: null,
  collections: null,
  publishState: [],
  saveProduct: false,
  expandProduct: null,
  productDuplicationAvailability: {},
  isPublishingProcessMonitoringActive: false,
};

export function reducer(state = initialState, action: actions.ECommerceProductsActions): State {
  switch (action.type) {
    case actions.ECommerceProductsActionTypes.ResetProductsListState:
      return {
        ...state,
        productsList: { ...initialState.productsList },
      };
    case actions.ECommerceProductsActionTypes.ResetProductsListPayload:
      return {
        ...state,
        productsList: {
          ...state.productsList,
          payload: null,
        },
      };
    case actions.ECommerceProductsActionTypes.ResetProductState:
      return {
        ...state,
        product: { ...initialState.product },
      };
    case actions.ECommerceProductsActionTypes.SetProductsListState:
      return {
        ...state,
        productsList: {
          ...action.payload,
          payload: {
            ...action.payload.payload,
            products: action.payload.payload
              ? action.payload.payload.products.map(pr => ({
                  ...pr,
                  externalThumbnailUrl: pr.externalThumbnailUrl || pr.previewUrl,
                }))
              : null,
          },
        },
      };
    case actions.ECommerceProductsActionTypes.SetProductState:
      return {
        ...state,
        product: action.payload,
      };
    case actions.ECommerceProductsActionTypes.SetInitialProductVariantConnectionData:
      return {
        ...state,
        initialProductVariantConnectionData: action.payload,
      };
    case actions.loadProductTagsSuccess.type:
      const _tags: Tag[] = [];
      for (const [key, value] of Object.entries(action.tags)) {
        _tags.push({
          id: key,
          title: value,
        });
      }
      return {
        ...state,
        tags: {
          ...state.tags,
          [action.storeId]: _tags,
        },
      };
    case actions.loadProductCollectionsSuccess.type:
      return {
        ...state,
        collections: {
          ...state.collections,
          [action.storeId]: action.collections,
        },
      };
    case actions.addPublishingProducts.type: {
      const storeProducts = state?.productsList?.payload?.products || [];

      return {
        ...state,
        productsList: {
          ...state.productsList,
          payload: {
            products: [...(action.products as EProductAbbridged[]), ...storeProducts],
          },
        },
      };
    }
    case actions.updatePublishingProduct.type: {
      const { product, forcePreviewUpdate } = action;
      const storeProducts = state?.productsList?.payload?.products || [];
      const updatedProducts = storeProducts.map((loopProduct: EProductAbbridged): EProductAbbridged => {
        if (loopProduct?.id && loopProduct.id !== product.id) {
          return loopProduct;
        }

        // Fix for blinking previews
        let externalThumbnailUrl = loopProduct.externalThumbnailUrl;

        if (forcePreviewUpdate || !externalThumbnailUrl) {
          externalThumbnailUrl = product.externalThumbnailUrl;
        }

        return {
          ...R.merge(loopProduct, product),
          externalThumbnailUrl,
        };
      });

      return {
        ...state,
        productsList: {
          ...state.productsList,
          payload: {
            products: updatedProducts || state.productsList?.payload?.products || [],
          },
        },
      };
    }
    case actions.removePublishingProducts.type: {
      const { productIds } = action;
      const storeProducts = state?.productsList?.payload?.products || [];

      return {
        ...state,
        productsList: {
          ...state.productsList,
          payload: {
            products: storeProducts.filter(
              (loopProduct: EProductAbbridged): boolean => !productIds.includes(loopProduct?.id),
            ),
          },
        },
      };
    }
    case actions.setPublishingState.type:
      const productState = state.publishState.find(pr => pr.productId === action.productId);
      return {
        ...state,
        publishState: productState
          ? state.publishState.map(pr => (pr.productId === action.productId ? action : pr))
          : [...state.publishState, action],
      };

    case actions.saveProduct.type:
      return {
        ...state,
        saveProduct: true,
      };

    case actions.expandProduct.type:
      return {
        ...state,
        expandProduct: action,
      };

    case actions.saveProductStart.type:
    case actions.saveProductStatelessStart.type:
      return {
        ...state,
        saveProduct: false,
        expandProduct: null,
      };

    case actions.clearCanDuplicateProductAsDraftFlags.type:
      return {
        ...state,
        productDuplicationAvailability: initialState.productDuplicationAvailability,
      };
    case actions.setProductDuplicationAvailabilityFlag.type:
      return {
        ...state,
        productDuplicationAvailability: {
          ...state.productDuplicationAvailability,
          [action.productId]: action.payload,
        },
      };
    case actions.initiatePublishingProcessMonitoring.type:
      return {
        ...state,
        isPublishingProcessMonitoringActive: true,
      };
    case actions.cancelPublishingProcessMonitoring.type:
      return {
        ...state,
        isPublishingProcessMonitoringActive: false,
      };
    default:
      return state;
  }
}

export function createEmptyPublishingProduct(): EProductAbbridged {
  return {
    previewUrl: null,
    externalPreviewUrl: null,
    externalThumbnailUrl: null,
    isReadyToPublish: null,
    publishedAt: null,
    variants: null,
    createdAt: null,
    updatedAt: null,
    id: null,
    externalId: null,
    storeId: null,
    title: null,
    description: null,
    previewFileType: null,
    productVariantPreviewScene: null,
    productVariantOptions: null,
    tags: null,
    collectionsIds: null,
    metadata: [],
    publishingErrorCode: null,
    publishingErrorDetails: null,
    publishingDetailsFlags: null,
    status: EProductStatus.PUBLISHING,
    hasDraft: false,
    templateName: null,
  };
}
