import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonToArray',
})
export class JsonToArrayPipe implements PipeTransform {
  transform(val) {
    if (typeof val === 'string') {
      val = JSON.parse(decodeURIComponent(val));
    }

    return val;
  }
}
