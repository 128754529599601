import { UploadedImageColors } from '@gelato-api-ui/core/designs/uploaded-image-data';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import { getMetadataItemValue } from '@gelato-api-ui/core/metadata/helpers/getMetadataItemValue';

export const getUploadedImageColors = (asset: Asset): UploadedImageColors => {
  if (!asset || !asset.metadata) {
    return null;
  }

  const fillColors: string[] = getMetadataItemValue(asset.metadata, 'fillColors') as string[];
  const strokeColors: string[] = getMetadataItemValue(asset.metadata, 'strokeColors') as string[];

  if (!fillColors && !strokeColors) {
    return null;
  }

  return {
    fill: fillColors || null,
    stroke: strokeColors || null,
  };
};
