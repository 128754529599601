<tr [class.section-group-ab]="subscriptionPageEnabledAB">
  <th colspan="4" (click)="onToggleExpandClick()">
    <div class="header">
      <div class="toggle-icon">
        <img [src]="isExpanded ? 'assets/icons/minus-black.svg' : 'assets/icons/plus-black.svg'" alt="" />
      </div>
      <h3 class="title">
        {{ title }}
      </h3>
    </div>
  </th>
</tr>
<ng-container *ngIf="isExpanded">
  <ng-content></ng-content>
</ng-container>
