import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CurrencyRatesApiService } from './currency-rates-api.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyRateService {
  cachedCurrencyRates = {};
  loading = new BehaviorSubject(false);
  constructor(private currencyRatesApiService: CurrencyRatesApiService) {}

  get(sourceCurrencyIsoCode: string, destinationCurrencyIsoCode: string): Observable<number> {
    if (!sourceCurrencyIsoCode || !destinationCurrencyIsoCode) {
      return of(null);
    }

    if (sourceCurrencyIsoCode === destinationCurrencyIsoCode) {
      return of(1);
    }

    const cacheKey = `${sourceCurrencyIsoCode}-${destinationCurrencyIsoCode}`;

    if (this.cachedCurrencyRates[cacheKey]) {
      return of(this.cachedCurrencyRates[cacheKey]);
    }

    return this.currencyRatesApiService.get(sourceCurrencyIsoCode, destinationCurrencyIsoCode).pipe(
      tap((currencyRate: number) => {
        this.loading.next(false);
        this.cachedCurrencyRates[cacheKey] = currencyRate;
      }),
    );
  }
}
