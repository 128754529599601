import * as R from 'ramda';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';

export const getDesignFamilyIdsFromProductVariants = (productVariants: EProductVariant[]): number[] => {
  if (!productVariants || !productVariants.length) {
    return [];
  }

  let designTemplateIds: string[] = [];

  productVariants.forEach((loopProductVariant: EProductVariant) => {
    if (!loopProductVariant.designStructureJson) {
      return;
    }

    let designData = null;

    try {
      designData = JSON.parse(loopProductVariant.designStructureJson);
    } catch (e) {}

    if (!designData || !designData.design_family_id) {
      return;
    }

    designTemplateIds.push(designData.design_family_id);
  });

  designTemplateIds = R.sort((a, b) => Number(a) - Number(b), designTemplateIds);

  return R.uniq(designTemplateIds);
};
