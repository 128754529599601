import { createReducer, on } from '@ngrx/store';
import {
  cancelPreviewsArchiveRequest,
  requestPreviewsArchive,
  setPreviewsArchiveTaskId,
  setPreviewsArchiveUrl,
} from './previews-archive.actions';

export interface State {
  isLoading: boolean;
  taskIds: string[];
  productTitles: string[];
  previewsAmounts: number[];
  url: string;
}

export const initialState: State = {
  isLoading: false,
  taskIds: [],
  productTitles: [],
  previewsAmounts: [],
  url: null,
};

export const reducer = createReducer(
  initialState,
  on(requestPreviewsArchive, state => ({
    ...state,
    isLoading: true,
  })),
  on(setPreviewsArchiveTaskId, (state, { payload: { taskId, productTitle, previewsAmount } }) => ({
    ...state,
    taskIds: [...state.taskIds, taskId],
    productTitles: [...state.productTitles, productTitle],
    previewsAmounts: [...state.previewsAmounts, previewsAmount],
  })),
  on(setPreviewsArchiveUrl, (state, { payload: { taskId, url } }) => {
    const { taskIds, productTitles, previewsAmounts } = getNewTasksState(state, taskId);
    return {
      ...state,
      isLoading: Boolean(taskIds.length),
      taskIds,
      productTitles,
      previewsAmounts,
      url,
    };
  }),
  on(cancelPreviewsArchiveRequest, (state, { payload }) => {
    const { taskIds, productTitles, previewsAmounts } = getNewTasksState(state, payload);
    return {
      ...state,
      isLoading: Boolean(taskIds.length),
      taskIds,
      productTitles,
      previewsAmounts,
    };
  }),
);

function getNewTasksState({ taskIds, productTitles, previewsAmounts }: Partial<State>, taskId: string): Partial<State> {
  const indexToEmpty = taskIds.findIndex(id => id === taskId);
  const newTasks = [...taskIds];
  const newTitles = [...productTitles];
  const newAmounts = [...previewsAmounts];
  newTasks[indexToEmpty] = '';
  newTitles[indexToEmpty] = '';
  newAmounts[indexToEmpty] = 0;

  return {
    taskIds: newTasks.every(t => !t) ? [] : newTasks,
    productTitles: newTitles.every(t => !t) ? [] : newTitles,
    previewsAmounts: newAmounts.every(t => !t) ? [] : newAmounts,
  };
}
