import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NguCarouselModule } from '@ngu/carousel';
import { StaticPathModule } from '@gelato-api-ui/sdk/src/lib/shared/static-path/static-path.module';
import { StoreLogoModule } from '@gelato-api-ui/sdk/src/lib/shared/store-logo/store-logo.module';
import { MessageModule } from '@gelato-api-ui/sdk/src/lib/shared/message/message.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgPipesModule } from 'ngx-pipes';
import { ProductPreviewComponent } from '@gelato-api-ui/sdk/src/lib/shared/product-preview/product-preview.component';
import { ProductPreviewsCarouselComponent } from '@gelato-api-ui/sdk/src/lib/shared/product-previews-carousel/product-previews-carousel.component';
import { RouterModule } from '@angular/router';
import { SegmentHeaderComponent } from '@gelato-api-ui/sdk/src/lib/shared/segment-header/segment-header.component';
import { FaqSegmentComponent } from '@gelato-api-ui/sdk/src/lib/shared/faq-segment/faq-segment.component';
import { SwiperModule } from 'swiper/angular';
import { ProductPreviewsComponent } from './product-previews-carousel/product-previews/product-previews.component';
import { ProductPreviewsFullscreenComponent } from './product-previews-fullscreen/product-previews-fullscreen.component';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';
import { CarouselPreviewComponent } from '@gelato-api-ui/sdk/src/lib/shared/product-previews-carousel/carousel-preview/carousel-preview.component';
import { SingleVideoComponent } from './single-video/single-video.component';
import { DropdownMenuModule } from '@gelato-api-ui/sdk/src/lib/shared/dropdown-menu/dropdown-menu.module';
import { SingleVideoFullscreenModule } from '@gelato-api-ui/sdk/src/lib/shared/single-video-fullscreen/single-video-fullscreen.module';
import { PlayButtonModule } from '@gelato-api-ui/sdk/src/lib/shared/play-button/play-button.module';
import { VideoModule } from '@gelato-api-ui/sdk/src/lib/shared/video/video.module';
import { SubscriptionsSdkSharedModule } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/subscriptions-sdk-shared.module';
import { PlusLabelComponent } from './plus-label/plus-label.component';
import { GelatoPlusTrialCounterComponent } from './gelato-plus-days-left-counter/gelato-plus-days-left-counter.component';
import { AppBannerPromotionComponent } from './app-banner-promotion/app-banner-promotion.component';
import { GelatoPlusPromptComponent } from './gelato-plus-prompt/gelato-plus-prompt.component';
import { MissinGelatoPlusWarningComponent } from './missing-gelato-plus-warning/missing-gelato-plus-warning.component';
import { AlertModalComponent } from '@gelato-api-ui/sdk/src/lib/shared/alert-modal/alert-modal.component';
import { AlertModalService } from '@gelato-api-ui/sdk/src/lib/shared/services/alert-modal.service';
import { ConfirmModalComponent } from '@gelato-api-ui/sdk/src/lib/shared/confirm-modal/confirm-modal.component';
import { ConfirmModalService } from '@gelato-api-ui/sdk/src/lib/shared/services/confirm-modal.service';
import { PriceSdkModule } from './price-sdk/price-sdk.module';
import { CircleCrownIconComponent } from '@gelato-api-ui/sdk/src/lib/shared/circle-crown-icon/circle-crown-icon.component';
import { StepperComponent } from '@gelato-api-ui/sdk/src/lib/shared/stepper/stepper.component';
import { CardBaseComponent } from '@gelato-api-ui/sdk/src/lib/shared/card-base/card-base.component';
import { SyncProgressDimmerComponent } from '@gelato-api-ui/sdk/src/lib/shared/sync-progress-dimmer/sync-progress-dimmer.component';
import { DialogContainerComponent } from '@gelato-api-ui/sdk/src/lib/shared/dialog/dialog-container.component';
import { TooltipModule } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.module';
import { UiKitModule } from '@gelato-api-ui/ui-kit/src';
import { LayoutService } from '@gelato-api-ui/sdk/src/lib/shared/services/layout.service';
import { FormsModule } from '@angular/forms';
import { PriceInputComponent } from '@gelato-api-ui/sdk/src/lib/shared/price-input/price-input.component';
import { EProductVariantConnectionSettingsModalService } from '@gelato-api-ui/sdk/src/lib/shared/services/e-product-variant-connection-settings-modal.service';
import { EProductVariantConnectionSettingsModalFacade } from '@gelato-api-ui/sdk/src/lib/shared/services/e-product-variant-connection-settings-modal-facade.service';
import { EProductVariantConnectionSettingsModalComponent } from '@gelato-api-ui/sdk/src/lib/shared/e-product-variant-connection-settings-modal/e-product-variant-connection-settings-modal.component';
import { EProductVariantConnectionSettingsService } from '@gelato-api-ui/sdk/src/lib/shared/services/e-product-variant-connection-settings.service';
import { FormMessageComponent } from '@gelato-api-ui/sdk/src/lib/shared/form-message/form-message.component';
import { SuiMessageModule } from '@giomamaladze/ng2-semantic-ui';
import { FormErrorMessageComponent } from '@gelato-api-ui/sdk/src/lib/shared/form-error-message/form-error-message.component';
import { FormFieldErrorMessageModule } from '@gelato-api-ui/sdk/src/lib/shared/form-field-error-message/form-field-error-message.module';
import { PaginationComponent } from '@gelato-api-ui/sdk/src/lib/shared/pagination/pagination.component';
import { PaginationActivePageLabelComponent } from '@gelato-api-ui/sdk/src/lib/shared/pagination-active-page-label/pagination-active-page-label.component';
import { PaginationPageSelectorComponent } from '@gelato-api-ui/sdk/src/lib/shared/pagination-page-selector/pagination-page-selector.component';
import { FixedWidthContainerComponent } from '@gelato-api-ui/sdk/src/lib/shared/fixed-width-container/fixed-width-container.component';
import { StatusLabelComponent } from '@gelato-api-ui/sdk/src/lib/shared/status-label/status-label.component';
import { CopyUidLinkComponent } from '@gelato-api-ui/sdk/src/lib/shared/copy-uid-link/copy-uid-link.component';
import { DownloadNativeAppModalComponent } from '@gelato-api-ui/sdk/src/lib/shared/download-native-app-modal/download-native-app-modal.component';
import { ClipboardModule } from 'ngx-clipboard';
import { CurrencySelectorComponent } from '@gelato-api-ui/sdk/src/lib/shared/currency-selector/currency-selector.component';
import { HeaderClientSelectorComponent } from '@gelato-api-ui/sdk/src/lib/shared/header-client-selector/header-client-selector.component';
import { ClientSelectorModule } from '@gelato-api-ui/core/client-selector/client-selector.module';
import { NgSelectModule } from '@ng-select/ng-select';

const components = [
  ProductPreviewComponent,
  SegmentHeaderComponent,
  FaqSegmentComponent,
  ProductPreviewsCarouselComponent,
  ProductPreviewsComponent,
  CarouselPreviewComponent,
  ProductPreviewsFullscreenComponent,
  SingleVideoComponent,
  PlusLabelComponent,
  GelatoPlusPromptComponent,
  MissinGelatoPlusWarningComponent,
  GelatoPlusTrialCounterComponent,
  AppBannerPromotionComponent,
  AlertModalComponent,
  ConfirmModalComponent,
  CircleCrownIconComponent,
  CardBaseComponent,
  StepperComponent,
  SyncProgressDimmerComponent,
  DialogContainerComponent,
  PriceInputComponent,
  EProductVariantConnectionSettingsModalComponent,
  FormMessageComponent,
  FormErrorMessageComponent,
  PaginationComponent,
  PaginationPageSelectorComponent,
  PaginationActivePageLabelComponent,
  FixedWidthContainerComponent,
  StatusLabelComponent,
  CopyUidLinkComponent,
  CurrencySelectorComponent,
  HeaderClientSelectorComponent,
  DownloadNativeAppModalComponent,
];
const modules = [
  CommonModule,
  TranslateModule,
  NgPipesModule,
  StaticPathModule,
  StoreLogoModule,
  MessageModule,
  NguCarouselModule,
  RouterModule,
  SwiperModule,
  DropdownMenuModule,
  PlayButtonModule,
  VideoModule,
  SingleVideoFullscreenModule,
  SubscriptionsSdkSharedModule,
  PriceSdkModule,
  TooltipModule,
  UiKitModule,
  FormFieldErrorMessageModule,
  ClipboardModule,
  ClientSelectorModule,
  NgSelectModule,
];

@NgModule({
  imports: [...modules, SdkPipesModule, FormsModule, SuiMessageModule],
  declarations: [...components],
  exports: [...components, ...modules],
  providers: [
    AlertModalService,
    ConfirmModalService,
    LayoutService,
    EProductVariantConnectionSettingsModalService,
    EProductVariantConnectionSettingsModalFacade,
    EProductVariantConnectionSettingsService,
  ],
})
export class SdkSharedModule {}
