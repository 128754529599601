import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AjaxRequest } from 'rxjs/ajax';
import { environment } from '@api-ui-app/src/environments/environment';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { caller } from '@gelato-api-ui/core/api/caller';
import * as Sentry from '@sentry/angular';
import { I18N_BASE_URL } from '@gelato-api-ui/sdk/src/lib/translations/i18n.token';

export const I18N_STORAGE = 'i18n';

@Injectable()
export class I18nService {
  private get: (url: string, options?: AjaxRequest) => Observable<any>;

  constructor(@Inject(I18N_BASE_URL) private readonly baseUrl: string) {
    this.get = caller(this.baseUrl, 'GET', () => of({ authorization: 1 }));
  }

  fetchTranslations(locale: LocaleCode) {
    return this.get(`/${locale}.json?refresh=1`).pipe(
      map(data => {
        if (!data || !data.data) {
          Sentry.captureException(new Error('TranslationsLoadFailure'), scope => {
            scope.setExtras({ locale });
            return scope;
          });
        }

        return data.data;
      }),
    );
  }

  fetchStoredKeys(locale: LocaleCode) {
    return this.fetchTranslations(locale);
  }
}
