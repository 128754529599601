import { Injectable } from '@angular/core';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, filter, first, tap } from 'rxjs/operators';
import {
  ChameleonCompanyIdentificationData,
  ChameleonUserIdentification,
  ChameleonUserIdentificationData,
} from '@gelato-api-ui/core/chameleon/chameleon-user-identification';
import { ChameleonGraphQlService } from '@gelato-api-ui/core/chameleon/services/chameleon-graph-ql.service';
import { environment } from '@api-ui-app/src/environments/environment';
import { Question } from '@gelato-api-ui/core/quick-questions/types/question';
import { User } from '@api-ui-app/src/app/shared/lib/user';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { convertTimestampToIso8601Format } from '@api-ui-app/src/app/lib/convertTimestampToIso8601Format';
import { QuickQuestionsSteps } from '@gelato-api-ui/core/quick-questions/types/quick-questions';

declare global {
  interface Window {
    chmln: any;
  }
}

@Injectable({ providedIn: 'root' })
export class ChameleonFacade {
  identify$ = new BehaviorSubject<ChameleonUserIdentification>(null);

  constructor(private readonly chameleonGraphQlService: ChameleonGraphQlService) {}

  identify(chameleonUserIdentification: ChameleonUserIdentification): void {
    if (!chameleonUserIdentification || !chameleonUserIdentification.userId || !chameleonUserIdentification.data) {
      return;
    }

    this.chameleonGraphQlService
      .fetchUidHash(chameleonUserIdentification.userId)
      .pipe(
        first(),
        catchError(() => of(null)),
        filter((uidHash: string): boolean => Boolean(uidHash)),
        tap((uidHash: string) => {
          const userId = chameleonUserIdentification.userId;
          const data: ChameleonUserIdentificationData = {
            ...chameleonUserIdentification.data,
            uid_hash: uidHash,
          };

          this.identify$.next({ userId, data });
          window.chmln.identify(userId, data);
        }),
      )
      .subscribe();
  }

  getUserIdentificationData(user: User, companyDetails: CompanyDetails): ChameleonUserIdentification {
    const { userId, email } = user;

    const quickQuestionsMapping = {
      [QuickQuestionsSteps.printSpend]: 'print_spend',
      [QuickQuestionsSteps.ecommercePlatform]: 'ecommerce_platform',
      [QuickQuestionsSteps.storeLink]: 'store_link',
      [QuickQuestionsSteps.customEcommercePlatform]: 'other_ecommerce_platform',
      [QuickQuestionsSteps.isAlreadySelling]: 'is_already_selling',
      [QuickQuestionsSteps.isAlreadySellingPrintOnDemandProducts]: 'is_already_selling_print_on_demand_products',
      [QuickQuestionsSteps.sellingProductsToday]: 'selling_products_today',
      [QuickQuestionsSteps.followers]: 'follower_count',
      [QuickQuestionsSteps.onlinePresence]: 'online_presence',
      [QuickQuestionsSteps.customOnlinePresence]: 'other_online_presence',
    };

    const quickQuestions: Partial<ChameleonCompanyIdentificationData> = (companyDetails.questionnaire || []).reduce(
      (
        acc: Partial<ChameleonCompanyIdentificationData>,
        loopQuestion: Question,
      ): Partial<ChameleonCompanyIdentificationData> => {
        if (!quickQuestionsMapping[loopQuestion.question]) {
          return acc;
        }

        const key: string = quickQuestionsMapping[loopQuestion.question];

        return {
          ...acc,
          [key]: String(loopQuestion.answer),
        };
      },
      {},
    );

    const company: ChameleonCompanyIdentificationData = {
      uid: companyDetails.clientId,
      name: companyDetails.companyName,
      created: convertTimestampToIso8601Format(companyDetails.createdAt * 1000),
      ...quickQuestions,
    };

    const data: ChameleonUserIdentificationData = {
      email,
      company,
      env: environment.env,
    };

    return {
      userId,
      data,
    };
  }

  getAccountToken(): string {
    return window.chmln.accountToken;
  }
}
