import * as R from 'ramda';
import { ResaleCertificate } from '../../resale-certificates/lib/resale-certificate';
import { State } from '../resale-certificates.reducer';

export const findResaleCertificateInState = (state: State, id: string): ResaleCertificate => {
  if (!state || !id) {
    return null;
  }

  if (state.entity && state.entity.payload && state.entity.payload.id === id) {
    return { ...state.entity.payload };
  }

  if (state.list && state.list.payload && state.list.payload.resaleCertificates) {
    return {
      ...R.find(R.propEq('id', id))(state.list.payload.resaleCertificates),
    };
  }

  return null;
};
