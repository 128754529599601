import { Injectable } from '@angular/core';
import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';
import { SanityAppsListPage } from '@api-ui-app/src/app/subscriptions/types/sanity-apps-list-page';
import {
  PlansComparisonRawRow,
  PlansComparisonResponse,
  PlansComparisonTable,
  PlansComparisonTableSectionName,
  PlansComparisonTableSections,
} from '@api-ui-app/src/app/subscriptions/types/compare-table';
import { differenceInCalendarDays, parseISO } from 'date-fns';
import * as R from 'ramda';

@Injectable({
  providedIn: 'root',
})
export class AppStoreService {
  static getDaysLeft = (endDate: string) => {
    if (!endDate) {
      return null;
    }

    return differenceInCalendarDays(parseISO(endDate), new Date());
  };

  getPlansComparisonTableData(sanityContent: SanityAppsListPage) {
    const rows = sanityContent?.plansComparisonTableNew?.rows?.slice(1)?.map(({ cells }) => cells);

    if (!rows?.length) {
      return [];
    }

    const NO_GROUP_KEY = 'no-group';

    const groupedRows = this.mapToTableRow(rows)?.reduce((acc, currentValue) => {
      const [cell] = currentValue;
      const { sectionGroup } = cell;
      const newKey = !!sectionGroup ? sectionGroup : NO_GROUP_KEY;

      return {
        ...acc,
        [newKey]: [...(acc[newKey] || []), currentValue],
      };
    }, {});

    return [...(groupedRows[NO_GROUP_KEY] || []), ...Object.values(R.omit([NO_GROUP_KEY])(groupedRows))];
  }

  mapPlansComparisonTable(sanityContent: SanityAppsListPage, priceRows: PlansComparisonRawRow[]) {
    const rows = sanityContent?.plansComparisonTable?.rows
      ?.slice(1)
      ?.map(({ cells }) => cells?.map(cell => ({ value: cell })));

    if (!rows?.length) {
      return [];
    }

    const overallDescription = rows.slice(0, 1);
    const restRows = rows.slice(1);

    const allRows = [...overallDescription, ...priceRows, ...restRows];

    return allRows?.map(cells => {
      const planMap = [
        ServicePlanUniqueNames.gelato_platform_free,
        ServicePlanUniqueNames.gelato_platform_plus,
        ServicePlanUniqueNames.gelato_platform_gold,
      ];
      const [, , , , type, sectionGroup, link, tooltip] = cells;

      const rowNames = cells.slice(0, 1)?.map((cell, idx) => {
        return {
          ...cell,
          value: cell?.value,
          plan: planMap[idx],
          sectionGroup: sectionGroup?.value,
          link: link?.value,
          tooltip: tooltip?.value,
        };
      });
      const [freePlan, plusPlan, goldPlan] = cells.slice(1, 4)?.map((cell, idx) => {
        return {
          ...cell,
          value: cell?.value,
          plan: planMap[idx],
          type: type?.value,
        };
      });

      return [...rowNames, freePlan, plusPlan, goldPlan];
    });
  }

  mapPlansComparisonTableToSections(plansComparisonTableRows: PlansComparisonTable): PlansComparisonTableSections {
    const {
      noSectionRows,
      discountsSection,
      teamAndStoresSection,
      premiumDesignToolsAndMockupsSection,
      automationToolsSection,
      migrationProductsSection,
    } = this.getSections(plansComparisonTableRows);

    return {
      noSectionRows,
      sections: [
        discountsSection,
        teamAndStoresSection,
        premiumDesignToolsAndMockupsSection,
        automationToolsSection,
        migrationProductsSection,
      ],
    };
  }

  private getSections(plansComparisonTableRows: PlansComparisonTable) {
    const noSectionRows = plansComparisonTableRows.filter(
      row => !row[0]?.sectionGroup || row[0]?.sectionGroup === PlansComparisonTableSectionName.NONE,
    );
    const teamAndStoresSection = {
      sectionTitleKey: 'txt_team_and_stores_section_title',
      tableRows: [],
    };
    const premiumDesignToolsAndMockupsSection = {
      sectionTitleKey: 'txt_premium_design_tools_and_mockups_section_title',
      tableRows: [],
    };
    const automationToolsSection = {
      sectionTitleKey: 'txt_automation_tools_section_title',
      tableRows: [],
    };
    const discountsSection = {
      sectionTitleKey: 'txt_discounts_section_title',
      tableRows: [],
    };
    const migrationProductsSection = {
      sectionTitleKey: 'txt_migrating_products_title',
      tableRows: [],
    };

    plansComparisonTableRows.forEach(tableRow => {
      switch (tableRow[0].sectionGroup) {
        case PlansComparisonTableSectionName.TEAMS_AND_STORES:
          teamAndStoresSection.tableRows.push(tableRow);
          break;
        case PlansComparisonTableSectionName.PREMIUM_DESIGN_TOOAL_AND_MOCKUPS:
          premiumDesignToolsAndMockupsSection.tableRows.push(tableRow);
          break;
        case PlansComparisonTableSectionName.AUTOMATION_TOOLS:
          automationToolsSection.tableRows.push(tableRow);
          break;
        case PlansComparisonTableSectionName.DISCOUNTS:
          discountsSection.tableRows.push(tableRow);
          break;
        case PlansComparisonTableSectionName.MIGRATION_PRODUCTS:
          migrationProductsSection.tableRows.push(tableRow);
          break;
        default:
          break;
      }
    });

    return {
      noSectionRows,
      discountsSection,
      teamAndStoresSection,
      premiumDesignToolsAndMockupsSection,
      automationToolsSection,
      migrationProductsSection,
    };
  }

  private mapToTableRow(rows: PlansComparisonResponse[]) {
    const TABLES_ROWS = 4;
    return rows.map(row => {
      const data = row.slice(0, TABLES_ROWS);
      const metadata = row.slice(TABLES_ROWS);
      const [type, sectionGroup, link, tooltip, sectionTitle] = metadata;

      return data.map((val, idx) => {
        const isHeader = idx === 0;

        return {
          value: val,
          tooltip: isHeader && tooltip,
          link: isHeader && link,
          type,
          sectionGroup,
          sectionTitle,
        };
      });
    });
  }
}
