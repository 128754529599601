import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { UserSearchRequest } from '../lib/user-search-request';
import { UserSearchResponse } from '../lib/user-search-response';
import { User } from '../lib/user';
import * as actions from './users.actions';

export const getState = (state: AppState): State => state.users;

export const getUserListState = createSelector(getState, (state: State): UserListState => state.userList);

export const getUserListResponse = createSelector(
  getUserListState,
  (userListState: UserListState): UserSearchResponse => userListState.payload,
);

export const getUserState = createSelector(getState, (state: State): UserState => state.user);

export const getUser = createSelector(getUserState, (userState: UserState): User => userState.payload);

export interface State {
  userList: UserListState;
  user: UserState;
}

export interface UserListState {
  isLoading: boolean;
  request: UserSearchRequest;
  payload: UserSearchResponse;
}

export interface UserState {
  isLoading: boolean;
  payload: User;
}

export const initialState: State = {
  userList: {
    isLoading: false,
    request: null,
    payload: null,
  },
  user: {
    isLoading: false,
    payload: null,
  },
};

export function reducer(state = initialState, action: actions.UsersActions): State {
  switch (action.type) {
    case actions.UsersActionTypes.ResetUserListState:
      return {
        ...state,
        userList: { ...initialState.userList },
      };
    case actions.UsersActionTypes.ResetUserListPayload:
      return {
        ...state,
        userList: {
          ...state.userList,
          payload: null,
        },
      };
    case actions.UsersActionTypes.SetUserListState:
      return {
        ...state,
        userList: action.payload,
      };
    case actions.UsersActionTypes.ResetUserState:
      return {
        ...state,
        user: { ...initialState.user },
      };
    case actions.UsersActionTypes.SetUserState:
      return {
        ...state,
        user: action.payload,
      };
    default:
      return state;
  }
}
