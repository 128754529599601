import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ProductAddButtonComponent } from '@api-ui-app/src/app/product/product-add/components/product-add-button/product-add-button.component';
import { SharedModule } from '@api-ui-app/src/app/shared/shared.module';
import { ExistingProductSelectionModalComponent } from '@api-ui-app/src/app/product/product-add/containers/existing-product-selection-modal/existing-product-selection-modal.component';
import { StoresListModalContentComponent } from '@api-ui-app/src/app/product/product-add/components/stores-list-modal-content/stores-list-modal-content.component';
import { StoreProductsListModalContentComponent } from '@api-ui-app/src/app/product/product-add/components/store-products-list-modal-content/store-products-list-modal-content.component';
import { StoreProductsListSearchInputComponent } from '@api-ui-app/src/app/product/product-add/components/store-products-list-search-input/store-products-list-search-input.component';
import { StoreProductsListComponent } from '@api-ui-app/src/app/product/product-add/components/store-products-list/store-products-list.component';
import { StoresListEmptyStateComponent } from '@api-ui-app/src/app/product/product-add/components/stores-list-empty-state/stores-list-empty-state.component';
import { StoreProductsListItemComponent } from '@api-ui-app/src/app/product/product-add/components/store-products-list-item/store-products-list-item.component';
import { StoreProductVariantsListItemComponent } from '@api-ui-app/src/app/product/product-add/components/store-product-variants-list-item/store-product-variants-list-item.component';
import { StoresListItemComponent } from '@api-ui-app/src/app/product/product-add/components/stores-list-item/stores-list-item.component';
import { StoresListComponent } from '@api-ui-app/src/app/product/product-add/components/stores-list/stores-list.component';
import { ProductAddService } from '@api-ui-app/src/app/product/product-add/services/product-add.service';
import { ECommerceStoresSharedModule } from '@api-ui-app/src/app/e-commerce-stores/e-commerce-stores-shared.module';
import { SingleProductSelectionModalComponent } from '@api-ui-app/src/app/product/product-add/containers/single-product-selection-modal/single-product-selection-modal.component';
import { ECommerceProductsTreeService } from '@api-ui-app/src/app/product/product-add/services/e-commerce-products-tree.service';
import { ECommerceProductsTreeMappingService } from '@api-ui-app/src/app/product/product-add/services/e-commerce-products-tree-mapping.service';
import { ECommerceProductsTreeCheckedProductVariantsService } from '@api-ui-app/src/app/product/product-add/services/e-commerce-products-tree-checked-product-variants.service';
import * as fromProductsTree from '@api-ui-app/src/app/product/product-add/+state/e-commerce-products-tree.reducer';
import { ECommerceProductsTreeEffects } from '@api-ui-app/src/app/product/product-add/+state/e-commerce-products-tree.effects';

const components = [
  ProductAddButtonComponent,
  ExistingProductSelectionModalComponent,
  StoresListModalContentComponent,
  StoreProductsListModalContentComponent,
  StoreProductsListSearchInputComponent,
  StoreProductsListComponent,
  StoresListEmptyStateComponent,
  StoreProductsListItemComponent,
  StoresListComponent,
  StoresListItemComponent,
  StoreProductVariantsListItemComponent,
  SingleProductSelectionModalComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    SharedModule,
    ECommerceStoresSharedModule,
    StoreModule.forFeature(
      fromProductsTree.eCommerceProductsTreeFeatureKey,
      fromProductsTree.eCommerceProductsTreeReducerToken,
    ),
    EffectsModule.forFeature([ECommerceProductsTreeEffects]),
  ],
  providers: [
    {
      provide: fromProductsTree.eCommerceProductsTreeReducerToken,
      useValue: fromProductsTree.reducer,
    },
    ProductAddService,
    ECommerceProductsTreeService,
    ECommerceProductsTreeMappingService,
    ECommerceProductsTreeCheckedProductVariantsService,
  ],
})
export class ProductAddModule {}
