import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  fetchFail,
  fetchIntent,
  fetchStart,
  fetchSuccess,
  presetStart,
  topUpFail,
  topUpInternalFail,
  topUpInternalStart,
  topUpInternalSuccess,
  topUpStart,
  topUpSuccess,
  updateFail,
  updateStart,
  updateSuccess,
  withdrawFail,
  withdrawStart,
  withdrawSuccess,
} from './wallet.actions';
import { of } from 'rxjs';
import { WalletFacade } from './wallet.facade';

@Injectable()
export class WalletEffects {
  fetchIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIntent),
      withLatestFrom(this.facade.isFetchingToBeDone$),
      filter(([action, toBeDone]) => action.force || toBeDone),
      map(([action]) => action),
      map(action => fetchStart({ clientId: action.clientId })),
    ),
  );

  fetchStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchStart),
      switchMap(action =>
        this.facade.search(action.clientId).pipe(
          map(res => fetchSuccess({ list: res.wallets, clientId: action.clientId })),
          catchError(err => of(fetchFail({ err, clientId: action.clientId }))),
        ),
      ),
    ),
  );

  presetStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(presetStart),
      switchMap(action =>
        this.facade.preset(action.clientId).pipe(
          map(res => fetchStart({ clientId: action.clientId })),
          catchError(err => of(fetchFail({ err, clientId: action.clientId }))),
        ),
      ),
    ),
  );

  updateStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateStart),
      switchMap(action =>
        this.facade.update(action.wallet, action.change).pipe(
          map(wallet => updateSuccess({ wallet })),
          catchError(err => of(updateFail({ err }))),
        ),
      ),
    ),
  );

  topUpStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(topUpStart),
      switchMap(action =>
        this.facade.topUp(action.wallet, action.amountInMinorUnits).pipe(
          map(wallet => topUpSuccess({ wallet })),
          catchError(err => of(topUpFail({ err }))),
        ),
      ),
    ),
  );

  topUpInternalStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(topUpInternalStart),
      switchMap(action => this.facade.topUpInternal(action.wallet, action.amountInMinorUnits, action.clientId)),
      map(wallet => topUpInternalSuccess({ wallet })),
      catchError(err => of(topUpInternalFail({ err }))),
    ),
  );

  withdrawStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(withdrawStart),
      switchMap(action =>
        this.facade.withdraw(action.wallet, action.amountInMinorUnits, action.clientId).pipe(
          map(wallet => withdrawSuccess({ wallet, clientId: action.clientId })),
          catchError(err => of(withdrawFail({ err, clientId: action.clientId }))),
        ),
      ),
    ),
  );

  constructor(private readonly actions$: Actions, private readonly facade: WalletFacade) {}
}
