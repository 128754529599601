import { ProductCatalogueStatus } from './product-catalogue-status.enum';
import { ProductTypeUid } from './product-type-uid.enum';

export class ProductCatalogueUpdateRequest {
  constructor(
    public title: string,
    public alias: string,
    public clientId: string,
    public rootNodeId: string,
    public isPublic: boolean,
    public productTypeUid: ProductTypeUid,
    public status: ProductCatalogueStatus,
  ) {}
}
