import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DiscountsComponent } from '@api-ui-app/src/app/discount/discounts/discounts.component';
import { DiscountItemComponent } from '@api-ui-app/src/app/discount/discount-item/discount-item.component';
import { DiscountItemDynamicTimeLabelComponent } from '@api-ui-app/src/app/discount/discount-item-dynamic-time-label/discount-item-dynamic-time-label.component';
import { SharedModule } from '@product-catalogue/src/lib/shared/shared.module';
import { DiscountGhostItemComponent } from '@api-ui-app/src/app/discount/discount-item/discount-ghost-item/discount-ghost-item.component';
import { OrderDiscountItemComponent } from '@api-ui-app/src/app/discount/order-discount-item/order-discount-item.component';

@NgModule({
  declarations: [
    DiscountsComponent,
    DiscountItemComponent,
    DiscountItemDynamicTimeLabelComponent,
    DiscountGhostItemComponent,
    OrderDiscountItemComponent,
  ],
  imports: [CommonModule, SharedModule],
  exports: [
    DiscountsComponent,
    DiscountItemComponent,
    DiscountItemDynamicTimeLabelComponent,
    DiscountGhostItemComponent,
    OrderDiscountItemComponent,
  ],
})
export class DiscountModule {}
