import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import { getSelectedClientPreferredCurrency, getUserId } from '@api-ui-app/src/app/ngrx/auth.reducer';

@Injectable({ providedIn: 'root' })
export class UserCurrencyService {
  private userId$ = this.store.select(getUserId);
  private localStorageKey$ = this.userId$.pipe(
    filter((userId: string): boolean => Boolean(userId)),
    map((userId: string): string => `user_${userId}_currency`),
  );
  private clientCurrency$ = this.store.select(getSelectedClientPreferredCurrency);
  private userCurrency$ = new BehaviorSubject<string>(null);
  private fetchFromLocalStorageDone$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly store: Store<AppState>) {
    this.fetchFromLocalStorage();
  }

  get(): Observable<string> {
    return combineLatest([this.clientCurrency$, this.userCurrency$]).pipe(
      map(([clientCurrency, userCurrency]): string => {
        return userCurrency || clientCurrency;
      }),
      distinctUntilChanged(),
    );
  }

  set(currency: string) {
    this.save(currency);
  }

  private fetchFromLocalStorage() {
    this.localStorageKey$
      .pipe(
        first(),
        tap((localStorageKey: string) => {
          try {
            const currency = localStorage.getItem(localStorageKey);

            this.userCurrency$.next(currency || null);
            this.fetchFromLocalStorageDone$.next(true);
          } catch (e) {
            // Ignore
          }
        }),
      )
      .subscribe();
  }

  private save(currency: string) {
    this.localStorageKey$
      .pipe(
        filter(() => this.fetchFromLocalStorageDone$.value),
        first(),
        tap((localStorageKey: string) => {
          try {
            if (currency) {
              localStorage.setItem(localStorageKey, currency);
              this.userCurrency$.next(currency);
            } else {
              localStorage.removeItem(localStorageKey);
            }
          } catch (e) {
            // Ignore
          }
        }),
      )
      .subscribe();
  }
}
