import { createAction, props } from '@ngrx/store';
import { AnalyticsGraphGroupType } from '../types/analytics-graph-group-type.enum';
import { AnalyticsGraphName } from '../types/analytics-graph-name.enum';
import { AnalyticsGraphRequest } from '../types/analytics-graph-request';
import { AnalyticsGraphState } from './analytics.reducer';

export enum AnalyticsActionTypes {
  LoadAll = '[Analytics] Load All',
  LoadGraph = '[Analytics] Load Graph',
  SetDateRange = '[Analytics] Set Date Range',
  ResetGraphState = '[Analytics] Reset Graph State',
  SetGraphState = '[Analytics] Set Graph State',
  SetSelectedGraphGroupType = '[Analytics] Set Selected Graph Group Type',
  SelectGraphGroupType = '[Analytics] Select Graph Group Type',
  SetSavedShippingCostDateRange = '[Analytics] Set Saved Shipping Cost Date Range',
  LoadSavedShippingCost = '[Analytics] Load Saved Shipping Cost',
  SetSavedShippingCost = '[Analytics] Set Saved Shipping Cost',
}

export const loadAll = createAction(
  AnalyticsActionTypes.LoadAll,
  props<{ startDate: string; endDate: string; forced: boolean }>(),
);

export const loadGraph = createAction(
  AnalyticsActionTypes.LoadGraph,
  props<{ request: AnalyticsGraphRequest; forced: boolean }>(),
);

export const setDateRange = createAction(
  AnalyticsActionTypes.SetDateRange,
  props<{ startDate: string; endDate: string }>(),
);

export const resetGraphState = createAction(
  AnalyticsActionTypes.ResetGraphState,
  props<{ graphName: AnalyticsGraphName; graphGroupType: AnalyticsGraphGroupType }>(),
);

export const setGraphState = createAction(
  AnalyticsActionTypes.SetGraphState,
  props<{ graphName: AnalyticsGraphName; graphGroupType: AnalyticsGraphGroupType; graphState: AnalyticsGraphState }>(),
);

export const setSelectedGraphGroupType = createAction(
  AnalyticsActionTypes.SetSelectedGraphGroupType,
  props<{ graphName: AnalyticsGraphName; graphGroupType: AnalyticsGraphGroupType }>(),
);

export const selectGraphGroupType = createAction(
  AnalyticsActionTypes.SelectGraphGroupType,
  props<{ graphName: AnalyticsGraphName; graphGroupType: AnalyticsGraphGroupType }>(),
);

export const loadSavedShippingCost = createAction(AnalyticsActionTypes.LoadSavedShippingCost);

export const setSavedShippingCostDateRange = createAction(
  AnalyticsActionTypes.SetSavedShippingCostDateRange,
  props<{ startDate: string; endDate: string }>(),
);

export const setSavedShippingCost = createAction(AnalyticsActionTypes.SetSavedShippingCost, props<{ value: number }>());
