import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class PermissionsGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot) {
    if (!route.data.accessRequirements) {
      return true;
    }

    const { permission, scopeType, scopeName } = route.data.accessRequirements;

    switch (permission) {
      case 'read':
        return this.authService.requireAuthorisedUserWithReadPermission(scopeType, scopeName, route);
      case 'write':
        return this.authService.requireAuthorisedUserWithWritePermission(scopeType, scopeName, route);
      default:
        throw new Error(`Undefined permission required: ${permission}`);
    }
  }
}
