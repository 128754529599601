import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gd-store-products-list-search-input',
  templateUrl: './store-products-list-search-input.component.html',
  styleUrls: ['./store-products-list-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreProductsListSearchInputComponent {
  @Input() value: string = null;
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  onValueChange(value: string) {
    this.valueChange.emit(value);
  }
}
