import { NgModule } from '@angular/core';
import { StoreTypeIconComponent } from './store-type-icon/store-type-icon.component';
import { StoreTypePipe } from './pipes/store-type.pipe';
import { StoreIconNamePipe } from './pipes/store-icon-name.pipe';
import { CommonModule } from '@angular/common';
import { StoreReconnectionNotificationComponent } from '@api-ui-app/src/app/e-commerce-stores/components/store-reconnection-notification/store-reconnection-notification.component';
import { SharedModule } from '@product-catalogue/src/lib/shared/shared.module';
import { ECommerceProductWizardLeaveConfirmationModalComponent } from '@api-ui-app/src/app/e-commerce-stores/e-commerce-product-wizard-leave-confirmation-modal/e-commerce-product-wizard-leave-confirmation-modal.component';
import { ECommerceProductWizardLeaveConfirmationGuard } from '@api-ui-app/src/app/e-commerce-stores/guards/e-commerce-product-wizard-leave-confirmation.guard';
import { ECommerceProductWizardLeaveConfirmationModalService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard-leave-confirmation-modal.service';
import { ECommerceEmptyProductVariantCreationService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-empty-product-variant-creation.service';
import { ECommerceProductVariantOptionsService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-variant-options.service';
import { ECommerceStoreConnectionErrorMessageService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-store-connection-error-message.service';
import { ECommerceStoreReconnectionService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-store-reconnection.service';
import { RutterStoreReconnectionWindowService } from '@api-ui-app/src/app/e-commerce-stores/services/rutter-store-reconnection-window.service';
import { ShopifyIntegrationService } from '@api-ui-app/src/app/e-commerce-stores/services/shopify-integration.service';
import { EtsyIntegrationService } from '@api-ui-app/src/app/e-commerce-stores/services/etsy-integration.service';
import { EtsyIntegrationV1Service } from '@api-ui-app/src/app/e-commerce-stores/services/etsy-integration-v1.service';
import { EtsyIntegrationV2Service } from '@api-ui-app/src/app/e-commerce-stores/services/etsy-integration-v2.service';

const components = [
  StoreTypeIconComponent,
  StoreReconnectionNotificationComponent,
  ECommerceProductWizardLeaveConfirmationModalComponent,
];

const pipes = [StoreTypePipe, StoreIconNamePipe];

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [...components, ...pipes],
  declarations: [...components, ...pipes],
  providers: [
    ECommerceProductWizardLeaveConfirmationGuard,
    ECommerceProductWizardLeaveConfirmationModalService,
    ECommerceEmptyProductVariantCreationService,
    ECommerceProductVariantOptionsService,
    ShopifyIntegrationService,
    EtsyIntegrationService,
    EtsyIntegrationV1Service,
    EtsyIntegrationV2Service,
    ECommerceStoreConnectionErrorMessageService,
    ECommerceStoreReconnectionService,
    RutterStoreReconnectionWindowService,
  ],
})
export class ECommerceStoresSharedModule {}
