import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyRateService } from '@gelato-api-ui/core/currency-rate/services/currency-rate.service';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Pipe({ name: 'currencyRate' })
export class CurrencyRatePipe implements PipeTransform {
  constructor(private readonly currencyRateService: CurrencyRateService) {}

  transform(priceValue: number, sourceCurrency: string, destinationCurrency: string): Observable<string> {
    if (!sourceCurrency || !destinationCurrency) {
      return of(String(priceValue));
    }
    return this.currencyRateService
      .get(sourceCurrency, destinationCurrency)
      .pipe(map(currencyRate => String(currencyRate * Number(priceValue))));
  }
}
