import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, first, map, switchMap, tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { ETSY_API_VERSION_1, ETSY_API_VERSION_2 } from '@api-ui-app/src/app/e-commerce-stores/constants/constants';
import { StorageService } from '@gelato-api-ui/sdk/src/lib/storage/storage.service';
import { AppState } from '@api-ui-app/src/app/app.state';
import { WINDOW } from '@gelato-api-ui/core/window/window';
import * as accountActivationActions from '@api-ui-app/src/app/ngrx/account-activation-steps.actions';
import { EtsyIntegrationV1Service } from '@api-ui-app/src/app/e-commerce-stores/services/etsy-integration-v1.service';
import { EtsyIntegrationV2Service } from '@api-ui-app/src/app/e-commerce-stores/services/etsy-integration-v2.service';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { AbstractEtsyIntegrationVersionService } from '@api-ui-app/src/app/e-commerce-stores/services/abstract-etsy-integration-version.service';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { ECommerceStoreConnectionErrorMessageService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-store-connection-error-message.service';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { logStoreConnectionSuccessEvent } from '@gelato-api-ui/core/e-commerce/helpers/logStoreConnectionSuccessEvent';
import { logStoreConnectionFailureEvent } from '@gelato-api-ui/core/e-commerce/helpers/logStoreConnectionFailureEvent';
import { ResetStoresListState } from '@api-ui-app/src/app/ngrx/e-commerce-stores.actions';

@Injectable()
export class EtsyIntegrationService {
  private readonly apiVersion$ = this.featureSwitcherService
    .isFeatureEnabledForCurrentUser(FeatureFlagEnum.etsy_connect_api_v2)
    .pipe(map((isV2ApiEnabled: boolean): number => (isV2ApiEnabled ? ETSY_API_VERSION_2 : ETSY_API_VERSION_1)));

  constructor(
    private readonly etsyIntegrationV1Service: EtsyIntegrationV1Service,
    private readonly etsyIntegrationV2Service: EtsyIntegrationV2Service,
    private readonly eCommerceStoreConnectionErrorMessageService: ECommerceStoreConnectionErrorMessageService,
    private readonly storageService: StorageService,
    private readonly featureSwitcherService: FeatureSwitcherService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    @Inject(WINDOW) private window: Window,
  ) {}

  initiateConnection(): Observable<string> {
    return this.apiVersion$.pipe(
      first(),
      switchMap((apiVersion: number) =>
        this.factory(apiVersion)
          .initiateConnection()
          .pipe(
            first(),
            catchError(() => {
              this.store.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((redirectUrl: string) => {
              this.store.dispatch(new ResetStoresListState());

              if (redirectUrl) {
                this.window.location.href = this.getValidUrl(redirectUrl);
              } else {
                this.navigateToHome();
              }
            }),
          ),
      ),
    );
  }

  connect(activatedRoute: ActivatedRoute): Observable<EStore> {
    const apiVersion = this.getApiVersionByActivatedRoute(activatedRoute);

    return activatedRoute.queryParams.pipe(
      first(),
      switchMap(
        (params: Params): Observable<EStore> =>
          this.factory(apiVersion)
            .connect(params)
            .pipe(
              first(),
              catchError(error => {
                logStoreConnectionFailureEvent(error);
                this.eCommerceStoreConnectionErrorMessageService.show(error);

                return of(null);
              }),
              tap((connectedStore: EStore) => {
                if (connectedStore) {
                  logStoreConnectionSuccessEvent(connectedStore);
                  this.store.dispatch(new accountActivationActions.SetFirstAvailableStore(connectedStore));
                }

                this.navigateToHome();
              }),
            ),
      ),
    );
  }

  private factory(apiVersion: number): AbstractEtsyIntegrationVersionService {
    return apiVersion === ETSY_API_VERSION_2 ? this.etsyIntegrationV2Service : this.etsyIntegrationV1Service;
  }

  private getApiVersionByActivatedRoute(activatedRoute: ActivatedRoute): number {
    return activatedRoute?.snapshot?.data?.apiVersion;
  }

  private getValidUrl(url: string): string {
    return (url || '').toLowerCase().startsWith('https://') ? url : `https://${url}`;
  }

  private navigateToHome() {
    this.router.navigate(['/stores/list']);
  }
}
