import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PhoneMaskDirectiveModule } from '@gelato-api-ui/ui-kit/src/lib/phone-field/phone-mask.directive';
import { PhoneFieldComponent } from '@gelato-api-ui/ui-kit/src/lib/phone-field/phone-field.component';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormsModule,
    SdkPipesModule,
    PhoneMaskDirectiveModule,
  ],
  exports: [PhoneMaskDirectiveModule, PhoneFieldComponent],
  declarations: [PhoneFieldComponent],
})
export class PhoneFieldModule {}
