import { EProductVariantConnectionStatus } from './e-product-variant-connection-status.enum';
import { EVariantOption } from './e-variant-option';
import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';

export interface EProductVariant {
  id: string;
  clientId: string;
  productId: string;
  productName: string;
  title: string;
  preview: string;
  externalPreviewUrl: string;
  externalThumbnailUrl: string;
  sku: string;
  productTypeUid: string;
  productUid: string;
  designId: string;
  designStructureJson?: string;
  isCustomizable: boolean;
  fileUrl: string;
  fileMimeType: string;
  price: number;
  cost: number;
  currency: string;
  externalVariantId: string;
  connectionStatus: EProductVariantConnectionStatus;
  variantOptions: EVariantOption[];
  pageCount?: number;
  customTrim?: CustomTrim;
  metadata: MetadataItem[];
  position: number;
  sanityProductId?: string;
  sanityProductTitle?: string;
  sanityProductVariantTitle?: string;
  sanityProductVariantsCount?: number;
  sanityCategoryId?: string;
  isDeprecated?: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface CustomTrim {
  width: CustomTrimValue;
  height: CustomTrimValue;
}

export interface CustomTrimValue {
  value: number;
  unit: CustomTrimUnit;
}

export enum CustomTrimUnit {
  mm = 'mm',
  inch = 'inch',
}
