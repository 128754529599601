import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';
import { isStandaloneEditMode } from '@api-ui-app/src/app/e-commerce-stores/lib/helpers/isStandaloneEditMode';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { manualStoreTypes } from '@gelato-api-ui/core/e-commerce/constants/manual-store-types';

export const getVisibleWizardSteps = (
  mode: EProductWizardMode,
  storeType: EStoreType,
): ECommerceProductWizardStep[] => {
  const steps = getWizardStepsByMode(mode);

  if (manualStoreTypes.includes(storeType)) {
    return steps.filter(
      (loopStep: ECommerceProductWizardStep): boolean =>
        ![ECommerceProductWizardStep.MEDIA, ECommerceProductWizardStep.PRICES].includes(loopStep),
    );
  }

  return steps;
};

const getWizardStepsByMode = (mode: EProductWizardMode): ECommerceProductWizardStep[] => {
  if (isStandaloneEditMode(mode)) {
    return [];
  }

  switch (mode) {
    case EProductWizardMode.EDIT_DESIGNS:
      return [
        ECommerceProductWizardStep.PRODUCT,
        ECommerceProductWizardStep.DESIGN,
        ECommerceProductWizardStep.MEDIA,
        ECommerceProductWizardStep.PRICES,
      ];
    case EProductWizardMode.BULK_CONNECT:
      return [
        ECommerceProductWizardStep.DESIGN,
        ECommerceProductWizardStep.MEDIA,
        ECommerceProductWizardStep.CONNECT,
        ECommerceProductWizardStep.PRICES,
      ];
    default:
      return [
        ECommerceProductWizardStep.PRODUCT,
        ECommerceProductWizardStep.DESIGN,
        ECommerceProductWizardStep.MEDIA,
        ECommerceProductWizardStep.DETAILS,
        ECommerceProductWizardStep.PRICES,
      ];
  }
};
