import { OrderDetailsExtended } from '../order-details-extended';
import { OrderItem } from '../order-item';
import { NotConnectedOrderItem } from '../not-connected-order-item';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { getProductVariantIdFromMetadata } from './getProductVariantIdFromMetadata';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';

export const getNotConnectedOrderItemsFromOrderDetails = (
  orderDetails: OrderDetailsExtended,
): NotConnectedOrderItem[] => {
  if (!orderDetails) {
    return [];
  }

  const { items, products, productVariants } = orderDetails;

  if (!items) {
    return [];
  }

  const notConnectedItems: NotConnectedOrderItem[] = [];

  items.forEach((loopItem: OrderItem) => {
    if (
      loopItem.productUid &&
      (!loopItem.isIgnored || loopItem?.productCategoryUid === SanityProductCategoryName.BRANDING)
    ) {
      return;
    }

    const productVariantId: string = getProductVariantIdFromMetadata(loopItem.metadata);

    const productVariant: EProductVariant = (productVariants || []).find(
      (loopProductVariant: EProductVariant): boolean => loopProductVariant.id === productVariantId,
    );

    let title = null;

    if (productVariant) {
      title = productVariant.title;

      const product: EProductAbbridged = (products || []).find(
        (loopProduct: EProductAbbridged): boolean => loopProduct.id === productVariant.productId,
      );

      if (product) {
        title = `${product.title}, ${productVariant.title}`;
      }
    }

    notConnectedItems.push({
      id: loopItem.id,
      title,
      quantity: loopItem.quantity,
      retailPrice: loopItem.retailPriceInclVat,
      isIgnored: loopItem.isIgnored,
      productVariant,
    });
  });

  return notConnectedItems;
};
