import { Injectable } from '@angular/core';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import { Observable } from 'rxjs';
import { OrderDetails } from '@api-ui-app/src/app/orders/lib/order-details';
import * as gql from 'gql-query-builder';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable({
  providedIn: 'root',
})
export class OrderGraphQlService {
  private readonly operation = 'order';

  constructor(private readonly graphQlRequestService: GraphQlRequestService) {}

  get(id: string, includePackageCheckpoints: boolean = false): Observable<OrderDetails> {
    const fieldOperation = 'orderDetails';
    const fields = [fieldOperation];

    const query = gql.query({
      operation: this.operation,
      variables: {
        orderId: { type: 'String!', value: id },
        includePackageCheckpoints: { type: 'Boolean!', value: includePackageCheckpoints },
      },
      fields,
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`, true)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }
}
