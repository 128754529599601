import { ApparelExtraPrintAreas } from '@gelato-api-ui/core/print-sides/extra-print-areas';
import { PrintSide } from '@gelato-api-ui/core/print-sides/print-side.enum';

// we do not have ALL "new print areas variations" passed with the product
// so if we receive new print area uid -> convert it to the simplest one (Front)
// so we can check if product have this variant or use sanity product information
export const getBaseUid = (uid: string) => {
  if (!uid) {
    return uid;
  }

  const isUidWithNewPrintAreas = Object.values(ApparelExtraPrintAreas).some(areaCode => uid.includes(areaCode));

  if (!isUidWithNewPrintAreas) {
    return uid;
  }

  const bareUid = Object.values(ApparelExtraPrintAreas).reduce((acc, areaCode) => acc.replace(`_${areaCode}`, ''), uid);
  const isReady = Object.values(PrintSide).some(areaCode => bareUid.includes(areaCode));
  return isReady ? bareUid : `${bareUid}_${PrintSide.Front}`;
};
