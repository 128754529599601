import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { getFormFieldError, removeFormFieldError } from '@api-ui-app/src/app/lib/getFormFieldError';
import { ErrorData } from '@api-ui-app/src/app/shared/lib/error-data';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { BillingEntityUpdateRequest } from '@api-ui-app/src/app/billing-entities/lib/billing-entity-update-request';
import { COUNTRIES_WITH_STATE } from '@api-ui-app/src/app/lib/constants';
import { SubscriptionBillingAddressEnum } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/subscription-billing-address-form/billingAddressEnum';

@Component({
  selector: 'gc-subscription-billing-form',
  templateUrl: './subscription-billing-form.component.html',
  styleUrls: ['./subscription-billing-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionBillingFormComponent implements OnInit, OnDestroy {
  @Input() set companyDetails(company: CompanyDetails) {
    this.form.patchValue(company, { emitEvent: false });
  }
  @Input() set errorDetails(error: ErrorData) {
    this.error = error;
  }
  @Output() update = new EventEmitter<BillingEntityUpdateRequest>();

  ngOnDestroy$ = new Subject();
  address = SubscriptionBillingAddressEnum;
  controls = {
    [SubscriptionBillingAddressEnum.recipientName]: new UntypedFormControl(''),
    [SubscriptionBillingAddressEnum.companyName]: new UntypedFormControl(''),
    [SubscriptionBillingAddressEnum.address]: new UntypedFormControl(''),
    [SubscriptionBillingAddressEnum.city]: new UntypedFormControl(''),
    [SubscriptionBillingAddressEnum.postCode]: new UntypedFormControl(''),
    [SubscriptionBillingAddressEnum.countryIsoCode]: new UntypedFormControl(''),
    [SubscriptionBillingAddressEnum.stateCode]: new UntypedFormControl(''),
    [SubscriptionBillingAddressEnum.contactEmail]: new UntypedFormControl(''),
    [SubscriptionBillingAddressEnum.phone]: new UntypedFormControl(''),
  };
  form: UntypedFormGroup = this.fb.group(this.controls);
  error: ErrorData = null;

  constructor(private readonly fb: UntypedFormBuilder) {}

  ngOnInit(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngOnDestroy$),
        tap(value => this.update.emit(value)),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }

  get isShowStateOptionalField() {
    return COUNTRIES_WITH_STATE.indexOf(this.form.get(this.address.countryIsoCode).value) === -1;
  }

  fieldValue(field: SubscriptionBillingAddressEnum) {
    return this.form.get(field).value;
  }

  hasError(fieldName: string): boolean {
    return Boolean(getFormFieldError(this.error, fieldName));
  }

  onCountryIdChange(countryIsoCode: string) {
    if (countryIsoCode !== this.fieldValue(SubscriptionBillingAddressEnum.countryIsoCode)) {
      this.onCountryStateChange('');
    }

    this.form.get(SubscriptionBillingAddressEnum.countryIsoCode).patchValue(countryIsoCode);
  }

  onCountryStateChange(state: string) {
    this.form.get(SubscriptionBillingAddressEnum.stateCode).patchValue(state);
  }

  public onPhoneChange(phone: string) {
    this.error = removeFormFieldError(this.error, 'phone');
    this.form.get(SubscriptionBillingAddressEnum.phone).patchValue(phone);
  }
}
