import { Injectable } from '@angular/core';
import { GelatoPlusFeatures } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-features';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { GelatoPlusPopupLocations } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-popup-locations';
import { GelatoSubscriptionModal } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/containers/gelato-plus-subscription-modal/gelato-plus-subscription-modal.component';
import { GelatoSubscriptionModalType } from '../lib/gelato-subscription-modal';

@Injectable({ providedIn: 'root' })
export class GelatoPlusModalService {
  constructor(private readonly modalService: SuiModalService) {}

  public open(
    gelatoPlusFeature: GelatoPlusFeatures,
    gelatoPlusPopupLocation: GelatoPlusPopupLocations,
    gelatoSubscriptionModal: GelatoSubscriptionModalType,
  ) {
    this.modalService
      .open(new GelatoSubscriptionModal(gelatoPlusFeature, gelatoPlusPopupLocation, gelatoSubscriptionModal))
      .onDeny(r => {
        this.logCloseModalEvent(gelatoPlusFeature);
      });
  }

  public logCloseModalEvent(gelatoPlusFeature: GelatoPlusFeatures) {
    logEvent('gelatoPlusPopupClose', {
      featurePopUp: gelatoPlusFeature,
    });
  }
}
