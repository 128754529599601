import { Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { ConfirmModalSettings } from '@api-ui-app/src/app/shared/lib/confirm-modal-settings';

@Component({
  selector: 'gd-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnInit {
  constructor(public modal: SuiModal<ConfirmModalSettings, void, void>) {}

  ngOnInit() {}

  get title(): string {
    return this.modal?.context?.title;
  }

  get titleIconUrl(): string {
    return this.modal?.context?.titleIconUrl;
  }

  get body(): string {
    return this.modal?.context?.body;
  }

  get approveButtonText(): string {
    return this.modal?.context?.approveButtonText;
  }

  get approveButtonIconUrl(): string {
    return this.modal?.context?.approveButtonIconUrl;
  }

  get approveButtonStyle(): string {
    return this.modal?.context?.approveButtonStyle || 'primary';
  }

  get denyButtonText(): string {
    return this.modal?.context?.denyButtonText;
  }

  get denyButtonIconUrl(): string {
    return this.modal?.context?.denyButtonIconUrl;
  }

  get denyButtonStyle(): string {
    return this.modal?.context?.denyButtonStyle || 'secondary';
  }

  get hideApplyButton(): boolean {
    return this.modal?.context?.hideApplyButton;
  }

  get hideDenyButton(): boolean {
    return this.modal?.context?.hideDenyButton;
  }

  onApproveButtonClick() {
    this.modal.approve(undefined);
  }

  onDenyButtonClick() {
    this.modal.deny(undefined);
  }
}

export class ConfirmModal extends ComponentModalConfig<ConfirmModalSettings, void, void> {
  constructor(settings: ConfirmModalSettings) {
    super(ConfirmModalComponent, settings);

    this.isClosable = settings.isClosable || false;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}
