import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { SanityProductVariation } from '@gelato-api-ui/core/sanity/sanity-product-variation';
import { SanityProductVariantWallpaperType } from '@gelato-api-ui/core/sanity/sanity-product-variant';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { StoreProductVariantsCollection } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { getBaseUid } from '@gelato-api-ui/core/sanity/helpers/getBaseUid';

export const isProductVariantApplicableForMockup = (
  productVariant: EProductVariant,
  sanityProduct: SanityProduct,
): boolean => {
  if (!productVariant) {
    return false;
  }

  const preparedUid = getBaseUid(productVariant.productUid);
  const productVariation: SanityProductVariation = (sanityProduct?.productVariations || []).find(
    (v: SanityProductVariation): boolean => v.productUid === preparedUid,
  );

  return productVariation.wallpaperType !== SanityProductVariantWallpaperType.sample;
};

export const findFirstApplicableProductVariantKey = (
  productVariantCollections: StoreProductVariantsCollection,
  sanityProduct: SanityProduct,
): string =>
  Object.keys(productVariantCollections).find((loopProductVariantKey: string): boolean =>
    isProductVariantApplicableForMockup(productVariantCollections[loopProductVariantKey], sanityProduct),
  );

export const isProductVariantKeyApplicableForMockup = (
  productVariantKey: string,
  productVariantsCollection: StoreProductVariantsCollection,
  sanityProduct: SanityProduct,
): boolean => {
  if (!productVariantsCollection[productVariantKey]) {
    return false;
  }

  if (isProductVariantApplicableForMockup(productVariantsCollection[productVariantKey], sanityProduct)) {
    return true;
  }

  const applicableProductVariantKey = findFirstApplicableProductVariantKey(productVariantsCollection, sanityProduct);

  // Return true if no other applicable keys found
  return !applicableProductVariantKey;
};
