import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import { ScrollingService } from '@gelato-api-ui/core/scrolling/scrolling.service';
import { takeUntil } from 'rxjs/operators';
import { ResponsiveService } from '@gelato-api-ui/core/responsive/responsive.service';
import { sidebarStorageKey } from '@api-ui-app/src/app/ngrx/layout';

@Directive({
  selector: '[gcStickyScroll]',
})
export class ScrollEventDirective implements OnInit, OnDestroy {
  @Input() elementToTriggerSticky: HTMLElement;
  @Input() elementToHideWhenStickyShown: HTMLElement;

  @Output() scrollPosition: EventEmitter<number> = new EventEmitter<number>();

  ngOnDestroy$ = new Subject();
  observer = null;
  MOBILE_TOP_OFFSET_GAP = 500;
  DESKTOP_SIDEBAR_WIDTH = 240;
  DESKTOP_SIDEBAR_WIDTH_MINIMISED = 54;
  TABLET_WIDTH_BREAKPOINT = 769;

  constructor(
    private el: ElementRef,
    private readonly scrollingService: ScrollingService,
    private readonly responsiveService: ResponsiveService,
  ) {}

  public ngOnInit() {
    this.scrollingService.onWindowScrollChanges();
    this.responsiveService.onScreenSizeChanges();
    this.elementToHideWhenStickyShown.style.transition = 'opacity 0.1s ease-in-out';

    const options = {
      root: this.scrollingService.scrollElement.nativeElement,
      rootMargin: '0px',
      threshold: 1.0,
    };

    /**
     * The Intersection Observer API provides a way to asynchronously observe changes in the intersection
     * of a target element with an ancestor element or with a top-level document's
     * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
     */
    this.observer = new IntersectionObserver(this.intersectionCallback, options);
    this.observer.observe(this.elementToTriggerSticky);
  }

  /**
   * Whenever the target meets a threshold specified for the IntersectionObserver, the callback is invoked.
   * @param entries
   * @param _observer
   */
  private intersectionCallback = (entries, _observer) => {
    combineLatest([this.scrollingService.windowYScroll$, this.responsiveService.screenSize$])
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(([windowYScroll, screenSize]) => {
        const isSidebarMinimised = localStorage.getItem(sidebarStorageKey);

        const isMobileOrTablet = screenSize < this.TABLET_WIDTH_BREAKPOINT;
        this.handleStickyBarCenterAlignment(isMobileOrTablet, Boolean(Number(isSidebarMinimised)));

        entries.forEach(entry => {
          /**
           * Trigger elements visibility
           * 1. windowYScroll > this.MOBILE_TOP_OFFSET_GAP condition needs for app details mobile version page when
           * we have image goes first and at some point #elementToTriggerSticky can be hidden at that point
           */
          if (!isMobileOrTablet || (isMobileOrTablet && windowYScroll > this.MOBILE_TOP_OFFSET_GAP)) {
            this.triggerElementVisibility(this.elementToHideWhenStickyShown, !entry.isIntersecting);
            this.triggerStickyElementVisibility(entry.isIntersecting);
          } else if (isMobileOrTablet && windowYScroll < this.MOBILE_TOP_OFFSET_GAP) {
            // Force show/hide
            this.triggerStickyElementVisibility(true);
            this.triggerElementVisibility(this.elementToHideWhenStickyShown, false);
          }
        });
      });
  };

  /**
   * Handle sticky bar center alignment
   * 1. Desktop: need to center sticky element on center of the layout content. So, need to extract width of the sidebar
   * 2. Mobile: No need to extract additional width, as we hide sidebar on the mobile and tablet
   */
  private handleStickyBarCenterAlignment(isMobile: boolean, isSidebarMinimised: boolean) {
    const widthToBeExtractedForAlignment = isSidebarMinimised
      ? this.DESKTOP_SIDEBAR_WIDTH_MINIMISED / 2
      : this.DESKTOP_SIDEBAR_WIDTH / 2;

    this.el.nativeElement.style.transform = `translateX(calc(-1 * (50% - ${
      !isMobile ? widthToBeExtractedForAlignment : '0'
    }px)))`;
  }

  private triggerElementVisibility(element: HTMLElement, hide: boolean) {
    element.style.opacity = !hide ? '1' : '0';
    element.style.zIndex = !hide ? '1' : '-1';
  }

  /**
   * Trigger sticky element visibility
   * @param isIntersecting
   * @private
   */
  private triggerStickyElementVisibility(isIntersecting: boolean) {
    if (isIntersecting) {
      this.el.nativeElement.classList.remove('visible');
    } else {
      this.el.nativeElement.classList.add('visible');
    }
  }

  /**
   * Unsubscribe all the observers
   */
  ngOnDestroy() {
    this.responsiveService.onRemoveScreenSizeChanges();
    this.scrollingService.onRemoveWindowScrollChanges();
    this.observer.unobserve(this.elementToTriggerSticky);
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }
}
