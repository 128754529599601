import { ProductExpansionCategoryUid } from './product-expansion-category-uid.enum';

export const ProductExpansionCategoryUids = [
  ProductExpansionCategoryUid.POSTER,
  ProductExpansionCategoryUid.FRAMED_POSTER_BLACK_WOOD_FRAME,
  ProductExpansionCategoryUid.FRAMED_POSTER_WHITE_WOOD_FRAME,
  ProductExpansionCategoryUid.FRAMED_POSTER_NATURAL_WOOD_FRAME,
  ProductExpansionCategoryUid.FRAMED_POSTER_DARK_WOOD_FRAME,
  ProductExpansionCategoryUid.FRAMED_POSTER_BLACK_METAL_FRAME,
  ProductExpansionCategoryUid.HANGING_POSTER_BLACK_HANGER,
  ProductExpansionCategoryUid.HANGING_POSTER_WHITE_HANGER,
  ProductExpansionCategoryUid.HANGING_POSTER_NATURAL_WOOD_HANGER,
  ProductExpansionCategoryUid.HANGING_POSTER_DARK_WOOD_HANGER,
  ProductExpansionCategoryUid.METALLIC_PRINT,
  ProductExpansionCategoryUid.ACRYLIC_PRINT,
  ProductExpansionCategoryUid.FOAM_PRINT,
  ProductExpansionCategoryUid.WOOD_PRINT,
  ProductExpansionCategoryUid.CANVAS,
];
