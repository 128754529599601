import { createSelector } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { State } from './stock-availability-summary.reducer';
import {
  OutOfStockSummary,
  StockAvailabilityPayload,
  StockAvailabilitySummary,
  StockDateAvailabilitySummary,
} from '@product-catalogue/src/lib/product-catalogue/types/stock-availability-payload';
import { getSelectedCountry, getSelectedProductUid } from '@product-catalogue/src/lib/ngrx/product-catalogue.reducer';
import { getCountriesToRegions } from '@api-ui-app/src/app/shared/countries/+state/countries.selector';
import { ProductAvailabilityStatus } from '@gelato-api-ui/core/stock/types/product-availability-status.enum';

export const getState = (state: AppState): State => state.productCatalogue.stockAvailabilitySummary;

export const getIsLoading = createSelector(getState, (state: State): boolean => state.isLoading);

export const getPayload = createSelector(getState, (state: State): StockAvailabilityPayload => state.payload);

export const getStockAvailabilitySummary = createSelector(
  getPayload,
  (payload: StockAvailabilityPayload): StockAvailabilitySummary => payload?.stockAvailabilitySummary,
);

export const getOutOfStockSummary = createSelector(
  getPayload,
  (payload: StockAvailabilityPayload, defaultValue = {}): OutOfStockSummary =>
    payload?.outOfStockSummary || defaultValue,
);

export const getOutOfStockReplenishmentDatesSummary = createSelector(
  getPayload,
  (payload: StockAvailabilityPayload, defaultValue = {}): StockDateAvailabilitySummary =>
    payload?.replenishmentDatesSummary || defaultValue,
);

export const getIsSelectedProductOutOfStock = createSelector(
  getStockAvailabilitySummary,
  getSelectedCountry,
  getSelectedProductUid,
  getCountriesToRegions,
  (summary, country, productUid, countries2regions) => {
    if (countries2regions && summary && country && productUid) {
      const region = countries2regions[country];
      const oosStates = [ProductAvailabilityStatus.OUT_OF_STOCK, ProductAvailabilityStatus.OUT_OF_STOCK_REPLENISHABLE];
      return oosStates.includes(summary[productUid] ? summary[productUid][region] : null);
    }
    return false;
  },
);
