import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { EProductVariantCreateRequest } from '@gelato-api-ui/core/e-commerce/e-product-variant-create-request';
import { EProductVariantUpdateRequest } from '@gelato-api-ui/core/e-commerce/e-product-variant-update-request';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EProductVariantMappingRequest } from '@gelato-api-ui/core/e-commerce/e-product-variant-mapping-request';
import { EProductVariantMappingResponse } from '@gelato-api-ui/core/e-commerce/e-product-variant-mapping-response';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductVariantsApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  get(storeId: string, id: string): Observable<EProductVariant> {
    if (!storeId || !id) {
      return of(null);
    }

    return this.apiRequest
      .get<EProductVariant>(`/api/ecommerce/v1/stores/${storeId}/products/*/variants/${id}`)
      .pipe(map(data => data.data));
  }

  getMapping(storeId: string, request: EProductVariantMappingRequest): Observable<EProductVariantMappingResponse> {
    if (!storeId || !request) {
      return of(null);
    }

    return this.apiRequest
      .post<EProductVariantMappingResponse>(`/api/ecommerce/v1/stores/${storeId}/products/*/variants/mapping`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  save(storeProductVariant: EProductVariant): Observable<EProductVariant> {
    if (!storeProductVariant) {
      return of(null);
    }

    if (storeProductVariant.id) {
      const request: EProductVariantUpdateRequest = {
        id: storeProductVariant.id,
        externalVariantId: storeProductVariant.externalVariantId,
        title: storeProductVariant.title,
        productUid: storeProductVariant.productUid,
        pageCount: storeProductVariant.pageCount ? storeProductVariant.pageCount : null,
        customTrim: storeProductVariant.customTrim ? storeProductVariant.customTrim : null,
        fileUrl: storeProductVariant.fileUrl,
        designId: storeProductVariant.designId,
        isCustomizable: storeProductVariant.isCustomizable,
        metadata: storeProductVariant.metadata,
        price: storeProductVariant.price,
        cost: storeProductVariant.cost,
        currency: storeProductVariant.currency,
        connectionStatus: storeProductVariant.connectionStatus,
        variantOptions: storeProductVariant.variantOptions,
        position: storeProductVariant.position,
      };

      return this.update(storeProductVariant.id, request);
    } else {
      const request: EProductVariantCreateRequest = {
        externalVariantId: storeProductVariant.externalVariantId,
        productId: storeProductVariant.productId,
        title: storeProductVariant.title,
        productUid: storeProductVariant.productUid,
        pageCount: storeProductVariant.pageCount,
        customTrim: storeProductVariant.customTrim,
        fileUrl: storeProductVariant.fileUrl,
        designId: storeProductVariant.designId,
        isCustomizable: storeProductVariant.isCustomizable,
        metadata: storeProductVariant.metadata,
        price: storeProductVariant.price,
        cost: storeProductVariant.cost,
        currency: storeProductVariant.currency,
        connectionStatus: storeProductVariant.connectionStatus,
        variantOptions: storeProductVariant.variantOptions,
        position: storeProductVariant.position,
      };

      return this.create(request);
    }
  }

  create(request: EProductVariantCreateRequest): Observable<EProductVariant> {
    if (!request) {
      return of(null);
    }

    return this.apiRequest
      .post<EProductVariant>('/api/ecommerce/v1/stores/*/products/*/variants', {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  update(id: string, request: EProductVariantUpdateRequest): Observable<EProductVariant> {
    if (!request) {
      return of(null);
    }

    return this.apiRequest
      .patch<EProductVariant>(`/api/ecommerce/v1/stores/*/products/*/variants/${id}`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }
}
