import * as R from 'ramda';
import { AjaxResponse } from 'rxjs/ajax';
import { ApiResponse } from '../api-response.interface';
import { isRequestFailed } from './isRequestFailed';

export const getResponsePayloadObject = <T>(ajaxResponse: AjaxResponse): ApiResponse<T> => {
  const res: ApiResponse<T> | any =
    typeof ajaxResponse.response === 'object' ? ajaxResponse.response : JSON.parse(ajaxResponse.response);

  const requestFailed = isRequestFailed(ajaxResponse);
  const legacyFormatResponse = res && !res.data && !res.error;

  if (legacyFormatResponse) {
    if (requestFailed) {
      return { data: null, error: R.clone(res) };
    } else {
      return { data: R.clone(res), error: { code: 0, message: '' } };
    }
  }

  return res;
};
