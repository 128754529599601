import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gd-client-id-selector',
  templateUrl: './client-id-selector.component.html',
  styleUrls: ['./client-id-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClientIdSelectorComponent {
  @Input() clientId: string;
  @Input() showAllClientsOption = false;
  @Input() hideGelatoAdminClient = false;
  @Input() allClientsOptionText = '';
  @Input() isSearchable = true;
  @Input() isDisabled = false;
  @Input() placeholder = null;
  @Output() clientIdChange: EventEmitter<string> = new EventEmitter();

  onClientIdChange(clientId: string) {
    this.clientIdChange.emit(clientId);
  }
}
