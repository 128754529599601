import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { BillingEntitySearchRequest } from '../lib/billing-entity-search-request';
import { BillingEntitySearchResponse } from '../lib/billing-entity-search-response';
import { BillingEntity } from '../lib/billing-entity';
import { BillingEntityCreateRequest } from '../lib/billing-entity-create-request';
import { BillingEntityUpdateRequest } from '../lib/billing-entity-update-request';

@Injectable({
  providedIn: 'root',
})
export class BillingEntityApiService {
  constructor(private apiRequest: ApiRequestService) {}

  search(request: BillingEntitySearchRequest): Observable<BillingEntitySearchResponse> {
    return this.apiRequest
      .post<BillingEntitySearchResponse>('/api/v3/billing-entities:search', {
        body: request,
      })
      .pipe(
        map((data: any) => ({
          data: data.data,
          pagination: data.pagination,
        })),
      );
  }

  save(billingEntity: BillingEntity): Observable<BillingEntity> {
    if (!billingEntity) {
      return of(null);
    }

    if (billingEntity.id) {
      const request = new BillingEntityUpdateRequest(
        billingEntity.companyName,
        billingEntity.companyNumber,
        billingEntity.companyVatNumber,
        billingEntity.country,
        billingEntity.recipientName,
        billingEntity.addressLine1,
        billingEntity.addressLine2,
        billingEntity.city,
        billingEntity.postCode,
        billingEntity.stateCode,
        billingEntity.email,
        billingEntity.phone,
        billingEntity.currency,
        billingEntity.status,
      );

      return this.update(billingEntity.id, request);
    } else {
      const request = new BillingEntityCreateRequest(
        billingEntity.clientId,
        billingEntity.companyName,
        billingEntity.companyNumber,
        billingEntity.companyVatNumber,
        billingEntity.country,
        billingEntity.recipientName,
        billingEntity.addressLine1,
        billingEntity.addressLine2,
        billingEntity.city,
        billingEntity.postCode,
        billingEntity.stateCode,
        billingEntity.email,
        billingEntity.phone,
        billingEntity.currency,
      );

      return this.create(request);
    }
  }

  get(id: number): Observable<BillingEntity> {
    if (!id) {
      return of(null);
    }

    return this.apiRequest.get<BillingEntity>(`/api/v3/billing-entities/${id}`).pipe(map(data => data.data));
  }

  create(request: BillingEntityCreateRequest): Observable<BillingEntity> {
    return this.apiRequest
      .post<BillingEntity>('/api/v3/billing-entities', { body: request })
      .pipe(map(data => data.data));
  }

  update(id: number, request: BillingEntityUpdateRequest): Observable<BillingEntity> {
    return this.apiRequest
      .put<BillingEntity>(`/api/v3/billing-entities/${id}`, { body: request })
      .pipe(map(data => data.data));
  }

  delete(id: number): Observable<BillingEntity> {
    return this.apiRequest.delete<BillingEntity>(`/api/v3/billing-entities/${id}`).pipe(map(data => data.data));
  }
}
