<gd-standard-layout-ghost
  *ngIf="('txt_welcome_header' | translate) === 'txt_welcome_header'"
></gd-standard-layout-ghost>

<div class="layout-container flex-column flex-justify-start flex-items-stretch" [class.checkout]="isCheckout$ | async">
  <!--HEADER-->
  <ng-container *ngIf="!(fullScreenMode$ | async)">
    <gd-header-desktop
      [sidebarVisible]="!(sidebarMinimised$ | async)"
      [headerComponent]="headerComponent$ | async"
      [currentLang]="translations.currentLang"
      [currentCurrency]="userCurrency$ | async"
      [isGelatoPlusActive]="isGelatoPlusActive$ | async"
      [isGelatoPlusGoldActive]="isGelatoPlusGoldActive$ | async"
      [isGelatoPlusPage]="isGelatoPlusPage$ | async"
      [userWithFirstOrder]="userWithFirstOrder$ | async"
      [discounts]="discounts$ | async"
      (sidebarVisibilityChange)="toggleSidebar()"
      (useLocale)="onUseLocale($event)"
      (useCurrency)="onUseCurrency($event)"
      (gelatoPlusLabelClick)="onGelatoPlusLabelClick()"
    ></gd-header-desktop>

    <gd-header-mobile
      [class.checkout]="isCheckout$ | async"
      [headerComponent]="headerComponent$ | async"
      [currentLang]="translations.currentLang"
      [currentCurrency]="userCurrency$ | async"
      [isGelatoPlusGoldActive]="isGelatoPlusGoldActive$ | async"
      [isGelatoPlusActive]="isGelatoPlusActive$ | async"
      [isHelloBarVisible]="isHelloBarVisible$ | async"
      [isNonRenewingSubscriptionEnding]="isNonRenewingSubscriptionEnding$ | async"
      [nonRenewingGelatoPlusDaysLeft]="nonRenewingGelatoPlusDaysLeft$ | async"
      [userWithFirstOrder]="userWithFirstOrder$ | async"
      [discounts]="discounts$ | async"
      (useLocale)="onUseLocale($event)"
      (useCurrency)="onUseCurrency($event)"
    ></gd-header-mobile>
  </ng-container>

  <div
    class="layout-content-wrap flex-row flex-justify-start flex-items-stretch"
    [class.no-header]="fullScreenMode$ | async"
    [class.with-hello-bar]="isHelloBarVisible$ | async"
  >
    <gd-sidebar-desktop
      *ngIf="!(fullScreenMode$ | async)"
      [minimise]="sidebarMinimised$ | async"
      [isNonRenewingSubscriptionEnding]="isNonRenewingSubscriptionEnding$ | async"
      [isGelatoPlusGoldActive]="isGelatoPlusGoldActive$ | async"
      [nonRenewingGelatoPlusDaysLeft]="nonRenewingGelatoPlusDaysLeft$ | async"
    ></gd-sidebar-desktop>

    <!--CONTENT-->
    <div
      class="layout-content flex-1"
      id="layout-content"
      #layoutElRef
      [class.no-bottom-padding]="noBottomPadding$ | async"
      [class.no-background]="noBackground$ | async"
    >
      <gd-custom-widget-container></gd-custom-widget-container>
      <router-outlet></router-outlet>
    </div>
  </div>

  <gc-previews-archive-loading-message></gc-previews-archive-loading-message>
</div>
