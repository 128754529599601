import { Inject, Injectable } from '@angular/core';
import * as configCat from 'configcat-js';
import { IConfigCatClient, IConfigCatLogger, LogLevel } from 'configcat-common';

import { from, Observable, of } from 'rxjs';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { CONFIG_CAT_KEY } from '@gelato-api-ui/sdk/src/lib/feature-switcher/config-cat/config-cat-key.token';
import { User } from 'configcat-common/lib/RolloutEvaluator';
import { environment } from '@api-ui-app/src/environments/environment';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { filter, first, tap } from 'rxjs/operators';

interface SettingKeyValue {
  settingKey: string;
  settingValue: boolean;
}

interface SettingLogResults {
  [key: string]: boolean;
}

@Injectable({ providedIn: 'root' })
export class ConfigCatProvider {
  private configCatClient: IConfigCatClient;
  private pollIntervalSeconds = 120;

  constructor(@Inject(CONFIG_CAT_KEY) private configCatKey: string, private readonly authService: AuthService) {
    if (this.configCatKey) {
      let logger: IConfigCatLogger = configCat.createConsoleLogger(LogLevel.Info);
      if (environment.env === 'live') {
        logger = configCat.createConsoleLogger(LogLevel.Off);
      }
      this.configCatClient = configCat.createClientWithAutoPoll(this.configCatKey, {
        logger,
        pollIntervalSeconds: this.pollIntervalSeconds,
        configChanged: this.configHasChanged.bind(this),
      });

      this.trackConfigCatValues();
    }
  }

  public isFeatureEnabled(featureName: FeatureFlagEnum, defaultValue: boolean): Observable<any> {
    return this.configCatClient ? from(this.configCatClient.getValueAsync(featureName, defaultValue)) : of(false);
  }

  public isFeatureEnabledForUser(featureName: FeatureFlagEnum, defaultValue: boolean, userId: string): Observable<any> {
    const user = { identifier: userId } as User;
    return this.configCatClient ? from(this.configCatClient.getValueAsync(featureName, defaultValue, user)) : of(false);
  }

  private trackConfigCatValues() {
    this.authService
      .getClientId()
      .pipe(
        filter(userId => Boolean(userId)),
        first(),
        tap((userId: string) => {
          const user = { identifier: userId } as User;
          this.configCatClient
            .getAllValuesAsync(user)
            .then((values: SettingKeyValue[]) =>
              logEvent('experiments', { experiments: this.mapValuesToTrackingFormat(values) }),
            );
        }),
      )
      .subscribe();
  }

  private mapValuesToTrackingFormat(values: SettingKeyValue[]) {
    return values.reduce(
      (obj, { settingKey, settingValue }) => ({
        ...obj,
        [settingKey]: settingValue,
      }),
      {} as SettingLogResults,
    );
  }

  private configHasChanged() {
    this.trackConfigCatValues();
  }
}
