import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { Asset } from '@gelato-api-ui/core/designs/asset';

export const mapAssetsToCollection = (assets: Asset[]): AssetsCollection => {
  if (!assets) {
    return {};
  }

  return assets.reduce((acc: AssetsCollection, loopAsset: Asset): AssetsCollection => {
    const assetId: string = loopAsset.id;

    return {
      ...acc,
      [assetId]: { ...loopAsset },
    };
  }, {});
};
