export enum EStoreFeature {
  HTML_DESCRIPTION = 'html_description',
  TAGS = 'tags',
  COLLECTIONS = 'collections',
  OUT_OF_STOCK_SYNC = 'out_of_stock_sync',
  DESIGN_PERSONALIZATION = 'design_personalization',
  ADVANCED_DESIGN_PERSONALIZATION_EMAIL = 'advanced_design_personalization_email',
  DELAYED_PREVIEW_GENERATION = 'delayed_preview_generation',
  IMMEDIATE_PUBLISH = 'immediate_publish',
  FREE_SHIPPING_STOREFRONT = 'free_shipping_storefront',
  BULK_EDITING = 'bulk_editing',
  PRODUCT_VARIANT_SET_MODIFICATION = 'product_variant_set_modification',
  SHIPPING_ORIGIN = 'shipping_origin',
  IOSS_NUMBER = 'ioss_number',
  EDITABLE_DOMAIN = 'editable_domain',
  PRODUCT_DELETION = 'product_deletion',
  PRODUCT_VARIANT_DELETION = 'product_deletion',
  SYNC_PRODUCTS = 'sync_products',
  IMPORT_EXPORT_PRODUCTS = 'import_export_products',
  FRAMED_POSTERS_UPGRADE = 'framed_posters_upgrade',
}
