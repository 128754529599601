import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { internalStoreTypes } from '@gelato-api-ui/core/e-commerce/constants/internal-store-types';

export const getProductVariantThumbnailUrl = (productVariant: EProductVariant, storeType: EStoreType): string => {
  if (!productVariant) {
    return null;
  }

  if (internalStoreTypes.includes(storeType) || !productVariant.externalThumbnailUrl) {
    return productVariant.preview;
  }

  return productVariant.externalThumbnailUrl;
};
