import { Action } from '@ngrx/store';
import { ProductPricesState, ShipmentMethodsState, ShipmentPricesState } from './product-prices.reducer';
import { ShipmentPriceValuePropertyName } from '../product-catalogue/lib/shipment-price-value-property-name.enum';
import { ShipmentPriceValueType } from '../product-catalogue/lib/shipment-price-value-type.enum';
import { ShipmentFlatPricesItem } from '@gelato-api-ui/core/shipment/shipment-prices-request';
import { Dimensions } from '@product-catalogue/src/lib/product-catalogue/lib/dimentions';
import { RecommendedRetailPrices } from '@product-catalogue/src/lib/product-catalogue/types/product-recommeded-retail-prices';

export enum ProductPricesActionTypes {
  LoadProductPrices = '[ProductPrices] Load ProductPrices',
  LoadShipmentMethods = '[ProductPrices] Load ShipmentMethods',
  LoadShipmentPrices = '[ProductPrices] Load ShipmentPrices',
  LoadRecommendedPrices = '[ProductPrices] Load RecommendedPrices',
  LoadExtendedProductPricesPageData = '[ProductPrices] Load ExtendedProductPricesPageData',

  ResetProductPricesState = '[ProductPrices] Reset ProductPrices State',
  ResetShipmentMethodsState = '[ProductPrices] Reset ShipmentMethods State',
  ResetShipmentPricesState = '[ProductPrices] Reset ShipmentPrices State',
  SetProductPricesState = '[ProductPrices] Set ProductPrices State',
  SetRecommendedPricesState = '[ProductPrices] Set RecommendedPrices State',
  SetProductPricesLoading = '[ProductPrices] Set ProductPrices Loading',
  SetShipmentMethodsState = '[ProductPrices] Set ShipmentMethods State',
  SetShipmentPricesState = '[ProductPrices] Set ShipmentPrices State',
  SetFlatShipmentPricesState = '[ProductPrices] Set FlatShipmentPrices State',
  SetShipmentPriceValuePropertyName = '[ProductPrices] Set ShipmentPriceValuePropertyName',
  SetShipmentPriceValueType = '[ProductPrices] Set ShipmentPriceValueType',
  SetSelectedPageCount = '[ProductPrices] Set SelectedPageCount',
}

export class LoadProductPrices implements Action {
  readonly type = ProductPricesActionTypes.LoadProductPrices;

  constructor(
    public productUid: string,
    public countries: string[],
    public currency: string,
    public pagesCount: number,
    public dimensions: Dimensions = null,
    public forced: boolean = false,
  ) {}
}

export class LoadShipmentPrices implements Action {
  readonly type = ProductPricesActionTypes.LoadShipmentPrices;

  constructor(
    public productUid: string,
    public countries: string[],
    public currency: string,
    public quantities: number[],
    public forced: boolean = false,
  ) {}
}

export class LoadRecommendedPrices implements Action {
  readonly type = ProductPricesActionTypes.LoadRecommendedPrices;

  constructor(public productUid: string, public countries: string[]) {}
}

export class LoadShipmentMethods implements Action {
  readonly type = ProductPricesActionTypes.LoadShipmentMethods;

  constructor(public forced: boolean = false) {}
}

export class LoadExtendedProductPricesPageData implements Action {
  readonly type = ProductPricesActionTypes.LoadExtendedProductPricesPageData;

  constructor(
    public productUid: string,
    public catalogueAlias: string,
    public countries: string[],
    public currency: string,
    public pageCount: number,
    public dimensions: Dimensions = null,
    public forceDynamicShippingPrices: boolean = false,
    public forced: boolean = false,
  ) {}
}

export class ResetProductPricesState implements Action {
  readonly type = ProductPricesActionTypes.ResetProductPricesState;
}

export class ResetShipmentMethodsState implements Action {
  readonly type = ProductPricesActionTypes.ResetShipmentMethodsState;
}

export class ResetShipmentPricesState implements Action {
  readonly type = ProductPricesActionTypes.ResetShipmentPricesState;
}

export class SetProductPricesState implements Action {
  readonly type = ProductPricesActionTypes.SetProductPricesState;

  constructor(public payload: ProductPricesState) {}
}

export class SetRecommendedPricesState implements Action {
  readonly type = ProductPricesActionTypes.SetRecommendedPricesState;

  constructor(public payload: RecommendedRetailPrices) {}
}

export class SetProductPricesLoading implements Action {
  readonly type = ProductPricesActionTypes.SetProductPricesLoading;
}

export class SetShipmentMethodsState implements Action {
  readonly type = ProductPricesActionTypes.SetShipmentMethodsState;

  constructor(public payload: ShipmentMethodsState) {}
}

export class SetShipmentPricesState implements Action {
  readonly type = ProductPricesActionTypes.SetShipmentPricesState;

  constructor(public payload: ShipmentPricesState) {}
}

export class SetFlatShipmentPricesState implements Action {
  readonly type = ProductPricesActionTypes.SetFlatShipmentPricesState;

  constructor(public payload: ShipmentFlatPricesItem) {}
}

export class SetShipmentPriceValuePropertyName implements Action {
  readonly type = ProductPricesActionTypes.SetShipmentPriceValuePropertyName;

  constructor(public payload: ShipmentPriceValuePropertyName) {}
}

export class SetShipmentPriceValueType implements Action {
  readonly type = ProductPricesActionTypes.SetShipmentPriceValueType;

  constructor(public payload: ShipmentPriceValueType) {}
}

export class SetSelectedPageCount implements Action {
  readonly type = ProductPricesActionTypes.SetSelectedPageCount;

  constructor(public payload: number) {}
}

export type ProductPricesActions =
  | LoadProductPrices
  | LoadShipmentMethods
  | LoadShipmentPrices
  | ResetProductPricesState
  | ResetShipmentMethodsState
  | ResetShipmentPricesState
  | SetProductPricesState
  | SetProductPricesLoading
  | SetShipmentMethodsState
  | SetShipmentPricesState
  | SetFlatShipmentPricesState
  | SetShipmentPriceValueType
  | SetShipmentPriceValuePropertyName
  | SetRecommendedPricesState
  | SetSelectedPageCount;
