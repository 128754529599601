import { Pipe, PipeTransform } from '@angular/core';
import { GarmentSizesSorted } from '@gelato-api-ui/core/garment/garment-sizes-sorted';

@Pipe({
  name: 'sortApparelSizes',
})
export class SortApparelSizesPipe implements PipeTransform {
  transform(value: string[] | Set<string>): string[] {
    const set = Array.isArray(value) ? new Set(value) : value;
    return GarmentSizesSorted.filter(size => set.has(size));
  }
}
