<div class="header-mobile flex-row flex-justify-between flex-items-center">
  <div class="sidebar-toggle-wrapper">
    <button class="ui icon sidebar-toggle-button" [class.active]="sidebarVisible" (click)="toggleSidebar()">
      <img src="/assets/header/hamburger.svg" alt="menu icon" />
    </button>
    <ng-container *ngTemplateOutlet="logo"></ng-container>
  </div>

  <div class="user-info-wrapper">
    <gd-user-preferences-menu
      [currentLocaleCode]="currentLang"
      [currentCurrency]="currentCurrency"
      [isMobile]="true"
      (changeLocaleCode)="onLanguageChange($event)"
      (changeCurrency)="onCurrencyChange($event)"
    ></gd-user-preferences-menu>

    <gd-user-discounts-menu *ngIf="discounts?.length" [discounts]="discounts" [isMobile]="true">
    </gd-user-discounts-menu>

    <gd-header-create-menu [isMobile]="true"></gd-header-create-menu>

    <gd-active-client-info
      [isMobile]="true"
      [userWrapperClass]="userWrapperClass"
      [userInitialsBackgroundLoading]="isBrandingColorsLoading"
    ></gd-active-client-info>
  </div>

  <ng-container *ngIf="headerComponent">
    <ng-container *ngComponentOutlet="headerComponent"></ng-container>
  </ng-container>
</div>

<ng-template #logo>
  <div class="logo">
    <a routerLink="/"><gd-store-logo></gd-store-logo></a>
  </div>
</ng-template>

<gd-sidebar-mobile
  [class.with-hello-bar]="isHelloBarVisible"
  [isVisible]="sidebarVisible"
  [isGelatoPlusSubscriptionEnding]="isGelatoPlusSubscriptionEnding"
  [endingSubscriptionDaysLeft]="nonRenewingGelatoPlusDaysLeft"
  (toggle)="toggleSidebar()"
></gd-sidebar-mobile>
