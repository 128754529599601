import { createAction, props } from '@ngrx/store';
import { FilterBaseControl } from '@gelato-api-ui/core/apps-and-services/types/getty-filters-response';

export const loadSanityGettyFilters = createAction('[GalleryCollections] Load Sanity Getty Filters');

export const loadSanityGettyFiltersSuccess = createAction(
  '[GalleryCollections] Load Sanity Getty Filters Success',
  props<{ payload: FilterBaseControl[] }>(),
);

export const loadSanityGettyFiltersError = createAction('[GalleryCollections] Load Sanity Getty Filters Error');
