import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '../../../app.state';
import { OrderDetailsExtended } from '../../lib/order-details-extended';
import { OrderShipment } from '../../lib/order-shipment';
import { OrderItem } from '../../lib/order-item';
import { NotConnectedOrderItem } from '../../lib/not-connected-order-item';
import { OrderAddress } from '../../lib/order-address';
import { OrderReceipt } from '../../lib/order-receipt';
import { OrderReceiptType } from '../../lib/order-receipt-type.enum';
import { OrderReceiptTransactionType } from '../../lib/order-receipt-transaction-type.enum';
import { OrderReceiptItem } from '../../types/order-receipt-item';
import { OrderReceiptItemType } from '../../lib/order-receipt-item-type.enum';
import { OrderStatus } from '../../lib/order-status.enum';
import { OrderType } from '../../../shared/lib/order-type.enum';
import { canDeleteOrder as canDeleteOrderHelper } from '../../../shared/lib/helpers/canDeleteOrder';
import { getOrderReceipt } from '../../lib/helpers/getOrderReceipt';
import { getNotConnectedOrderItemsFromOrderDetails } from '../../lib/helpers/getNotConnectedOrderItemsFromOrderDetails';
import { getPricePlanListNames } from '@api-ui-app/src/app/price-plans/+state/price-plans.selector';
import { Client } from '@gelato-api-ui/core/clients/client';
import { State } from './order-details.reducer';
import { FinancialOrderStatus } from '@api-ui-app/src/app/orders/lib/financial-order-status.enum';
import { OrderPackage } from '@api-ui-app/src/app/orders/lib/order-packages';
import {
  OrderDetailsPricePlans,
  OrderDetailsPricePlanSelectorModel,
} from '@api-ui-app/src/app/orders/+state/order-details/order-details.model';

export const getOrderDetailsState = (state: AppState): State => state.orderDetails;

export const getOrderDetails = createSelector(
  getOrderDetailsState,
  (orderDetailsState: State): OrderDetailsExtended => orderDetailsState.orderDetails,
);

export const getOrderId = createSelector(getOrderDetails, (orderDetails: OrderDetailsExtended): string =>
  orderDetails ? orderDetails.id : null,
);

export const getOrderType = createSelector(
  getOrderDetails,
  (orderDetails: OrderDetailsExtended): OrderType => (orderDetails ? orderDetails.orderType : null),
);

export const getOrderStatus = createSelector(
  getOrderDetails,
  (orderDetails: OrderDetailsExtended): OrderStatus => (orderDetails ? orderDetails.fulfillmentStatus : null),
);

export const getOrderFinancialStatus = createSelector(
  getOrderDetails,
  (orderDetails: OrderDetailsExtended): FinancialOrderStatus => (orderDetails ? orderDetails.financialStatus : null),
);

export const getActiveOrderReceiptType = createSelector(
  getOrderDetailsState,
  (orderDetailsState: State): OrderReceiptType => orderDetailsState.activeOrderReceiptType,
);

export const getCanCancelOrderFlag = createSelector(getOrderStatus, (orderStatus: OrderStatus): boolean => {
  if (!orderStatus) {
    return false;
  }

  return [
    OrderStatus.CREATED,
    OrderStatus.UPLOADING,
    OrderStatus.PASSED,
    OrderStatus.PENDING_APPROVAL,
    OrderStatus.PENDING_PERSONALIZATION,
    OrderStatus.FAILED,
  ].includes(orderStatus);
});

export const getCanEditOrderFlag = createSelector(
  getOrderFinancialStatus,
  (financialStatus: FinancialOrderStatus): boolean => {
    return [FinancialOrderStatus.CANCELED, FinancialOrderStatus.REFUSED].includes(financialStatus);
  },
);

export const getCanApproveOrderFlag = createSelector(getOrderStatus, (orderStatus: OrderStatus): boolean => {
  return [OrderStatus.PENDING_APPROVAL, OrderStatus.PENDING_PERSONALIZATION].includes(orderStatus);
});

export const getCanConnectOrderFlag = createSelector(getOrderStatus, (orderStatus: OrderStatus): boolean => {
  return [OrderStatus.ON_HOLD, OrderStatus.NOT_CONNECTED].includes(orderStatus);
});

export const getCanReorderFlag = createSelector(
  getOrderStatus,
  getOrderFinancialStatus,
  (orderStatus: OrderStatus, financialStatus: FinancialOrderStatus): boolean => {
    if (!orderStatus) {
      return false;
    }

    if (orderStatus === OrderStatus.FAILED && financialStatus === FinancialOrderStatus.REFUNDED) {
      return true;
    }

    return ![
      OrderStatus.FAILED,
      OrderStatus.ON_HOLD,
      OrderStatus.NOT_CONNECTED,
      OrderStatus.PENDING_APPROVAL,
      OrderStatus.PENDING_PERSONALIZATION,
    ].includes(orderStatus);
  },
);

export const getCanDeleteOrderFlag = createSelector(getOrderType, (orderType: OrderType): boolean =>
  canDeleteOrderHelper(orderType),
);

export const getOrderReferenceId = createSelector(getOrderDetails, (orderDetails: OrderDetailsExtended): string => {
  return !orderDetails ? null : orderDetails.orderReferenceId;
});

export const getOrderItems = createSelector(getOrderDetails, (orderDetails: OrderDetailsExtended): OrderItem[] => {
  return !orderDetails || !orderDetails.items ? [] : orderDetails.items;
});

export const getConnectedOrderItems = createSelector(getOrderItems, (orderItems: OrderItem[]): OrderItem[] =>
  R.filter((loopItem: OrderItem): boolean => Boolean(loopItem.productUid) && !loopItem.isIgnored, orderItems),
);

export const getNotConnectedOrderItems = createSelector(
  getOrderDetails,
  (orderDetails: OrderDetailsExtended): NotConnectedOrderItem[] =>
    getNotConnectedOrderItemsFromOrderDetails(orderDetails),
);

export const getOrderShipment = createSelector(getOrderDetails, (orderDetails: OrderDetailsExtended): OrderShipment => {
  return !orderDetails ? null : orderDetails.shipment;
});

export const getShowOrderShipmentSection = createSelector(getOrderShipment, (orderShipment: OrderShipment): boolean => {
  return !orderShipment
    ? false
    : Boolean(
        orderShipment.shipmentMethodName ||
          orderShipment.minDeliveryDate ||
          orderShipment.maxDeliveryDate ||
          (orderShipment.packages && orderShipment.packages.length),
      );
});

export const getOrderShipmentAddress = createSelector(getOrderDetails, (orderDetails): OrderAddress => {
  return orderDetails?.shippingAddress || null;
});

export const getOrderMinDeliveryDate = createSelector(getOrderShipment, (orderShipment: OrderShipment): string => {
  return !orderShipment ? null : orderShipment.minDeliveryDate;
});

export const getOrderMaxDeliveryDate = createSelector(getOrderShipment, (orderShipment: OrderShipment): string => {
  return !orderShipment ? null : orderShipment.maxDeliveryDate;
});

export const getOrderPackages = createSelector(
  getOrderShipment,
  (orderShipment: OrderShipment): OrderPackage[] | null => (!orderShipment ? null : orderShipment.packages),
);

export const getOrderPackagesExtended = createSelector(
  getOrderDetailsState,
  (state: State): OrderPackage[] => state.orderDetails.shipment.packages,
);

export const getActiveOrderReceipt = createSelector(
  getOrderDetails,
  getActiveOrderReceiptType,
  (orderDetails: OrderDetailsExtended, activeOrderReceiptType: OrderReceiptType): OrderReceipt =>
    getOrderReceipt(orderDetails, activeOrderReceiptType, OrderReceiptTransactionType.PURCHASE),
);

export const getDefaultOrderReceipt = createSelector(
  getOrderDetails,
  (orderDetails: OrderDetailsExtended): OrderReceipt =>
    getOrderReceipt(orderDetails, OrderReceiptType.CONTRACT, OrderReceiptTransactionType.PURCHASE),
);

export const getOrderReceiptCurrencies = createSelector(
  getOrderDetails,
  getActiveOrderReceiptType,
  (orderDetails: OrderDetailsExtended): any => {
    if (!orderDetails || !orderDetails.receipts) {
      return {};
    }

    const receiptCurrencies = {};

    orderDetails.receipts.forEach((receipt: OrderReceipt) => {
      if (!receipt || !receipt.type || !receipt.currency) {
        return;
      }

      receiptCurrencies[receipt.type] = receipt.currency;
    });

    return receiptCurrencies;
  },
);

export const getDefaultOrderCurrency = createSelector(
  getDefaultOrderReceipt,
  (defaultOrderReceipt: OrderReceipt): string => {
    if (!defaultOrderReceipt) {
      return null;
    }

    return defaultOrderReceipt.currency;
  },
);

export const getOrderCurrency = createSelector(getActiveOrderReceipt, (activeOrderReceipt: OrderReceipt): string => {
  if (!activeOrderReceipt) {
    return null;
  }

  return activeOrderReceipt.currency;
});

export const getOrderRetailCurrency = createSelector(
  getOrderDetails,
  getOrderCurrency,
  (orderDetails: OrderDetailsExtended, orderCurrency: string): string => {
    if (!orderDetails) {
      return null;
    }

    return orderDetails.retailCurrency || orderCurrency;
  },
);

export const getItemPrices = createSelector(getActiveOrderReceipt, (activeOrderReceipt: OrderReceipt): any => {
  if (!activeOrderReceipt) {
    return {};
  }

  const itemPriceCollection = {};

  activeOrderReceipt.items.forEach((item: OrderReceiptItem) => {
    if (![OrderReceiptItemType.PRODUCT, OrderReceiptItemType.DESIGN, OrderReceiptItemType.OPTION].includes(item.type)) {
      return;
    }

    const key = item.referenceId;

    itemPriceCollection[key] = (itemPriceCollection[key] || 0) + item.priceInitial;
  });

  return itemPriceCollection;
});

export const getItemPricePlans = createSelector(
  getActiveOrderReceipt,
  getPricePlanListNames,
  (activeOrderReceipt: OrderReceipt, pricePlanNames: {}): OrderDetailsPricePlans =>
    new OrderDetailsPricePlanSelectorModel(activeOrderReceipt, pricePlanNames).pricePlans,
);

export const getItemDesignFees = createSelector(getActiveOrderReceipt, (activeOrderReceipt: OrderReceipt): any => {
  if (!activeOrderReceipt) {
    return {};
  }

  return activeOrderReceipt.items.reduce((acc, currentItem) => {
    if (OrderReceiptItemType.DESIGN !== currentItem.type) {
      return acc;
    }

    const key = currentItem.referenceId;
    const accDesignFee = (acc[key] || 0) + currentItem.priceInitial;

    return {
      ...acc,
      [key]: accDesignFee,
    };
  }, {});
});

export const getItemDesignFeeTooltips = createSelector(
  getActiveOrderReceipt,
  (activeOrderReceipt: OrderReceipt): any => {
    if (!activeOrderReceipt) {
      return {};
    }

    return activeOrderReceipt.items.reduce((acc, currentItem) => {
      if (OrderReceiptItemType.DESIGN !== currentItem.type) {
        return acc;
      }

      return {
        ...acc,
        [currentItem.referenceId]: currentItem.title,
      };
    }, {});
  },
);

export const getFirstOrderItemIdHavingPrintJob = createSelector(getOrderItems, (orderItems: OrderItem[]): string => {
  if (!orderItems || !orderItems.length) {
    return null;
  }

  const foundOrderItem: OrderItem = R.find(
    (orderProduct: OrderItem) => R.path(['printJobs', 'length'], orderProduct) > 0,
    orderItems,
  );

  if (!foundOrderItem) {
    return null;
  }

  return foundOrderItem.id;
});

export const getClient = createSelector(
  getOrderDetailsState,
  (orderDetailsState: State): Client => orderDetailsState.client,
);

export const isLoading = createSelector(
  getOrderDetailsState,
  (orderDetailsState: State): boolean => orderDetailsState.isLoading,
);
