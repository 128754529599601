<p>
  <strong>{{ 'txt_access_presets' | translate }}</strong>
</p>

<div class="user-role-buttons">
  <ng-container *ngFor="let userRoleObject of userRoles">
    <button
      *ngIf="userRoleObject?.accessScopes?.length"
      type="button"
      class="ui button"
      [class.active]="selectedRoles && selectedRoles.includes(userRoleObject.role)"
      (click)="toggleRole(userRoleObject?.role)"
    >
      {{ userRoleObject.roleName }}
    </button>
  </ng-container>
  <button *ngIf="!selectedRoles?.length" type="button" class="ui button active no-pointer-events">
    {{ 'txt_user_role_custom_permissions' | translate }}
  </button>
</div>
