export enum EProductMetadataKey {
  PREVIEW_FILE_TYPE = 'previewFileType',
  PREVIEW_SCENES = 'previewScenes',
  PRIMARY_PREVIEW_PRODUCT_UID = 'primaryPreviewProductUid',
  PRIMARY_PREVIEW_PRODUCT_VARIANT_KEY = 'primaryPreviewProductVariantKey',
  PRIMARY_PREVIEW_SCENE = 'primaryPreviewScene',
  ASSETS = 'assets',
  ATTACH_SIZE_GUIDE_CM_TABLE = 'attachSizeGuideCmTable',
  ATTACH_SIZE_GUIDE_INCH_TABLE = 'attachSizeGuideInchTable',
  ATTACH_PRODUCT_CARE_INSTRUCTIONS = 'attachProductCareInstructions',
  PUBLISH_IMMEDIATELY = 'publishImmediately',
  PUBLISH_WITH_FREE_SHIPPING = 'publishWithFreeShipping',
  PUBLISH_MODE = 'publishMode',
  PUBLISH_SCOPES = 'publishScopes',
  TEMPLATE_ID = 'templateId',
}
