import { Injectable } from '@angular/core';

@Injectable()
export class BeforeUnloadEventWarningService {
  eventHandler = event => {
    event.preventDefault();
    event.returnValue = '';
  };

  constructor() {}

  init() {
    window.addEventListener('beforeunload', this.eventHandler);
  }

  destroy() {
    window.removeEventListener('beforeunload', this.eventHandler);
  }
}
