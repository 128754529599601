<div class="navigation-links" [class.hovered]="!!minimise && hovered">
  <ng-container *ngFor="let item of navigationTree">
    <gd-sidebar-nav-item
      *ngIf="item"
      [item]="item"
      [counters]="counters"
      [hovered]="!!minimise && hovered"
      [minimise]="minimise"
      [isGelatoPlusSubscriptionEnding]="isGelatoPlusSubscriptionEnding"
      [endingSubscriptionDaysLeft]="endingSubscriptionDaysLeft"
    >
    </gd-sidebar-nav-item>

    <div *ngIf="!item" class="separator"></div>
  </ng-container>
</div>
