export enum GelatoPlusFeatures {
  // E-Store
  storesList = 'storesList',
  storeProductsList = 'storeProductsList',
  liveRates = 'liveRates',
  autoApprove = 'autoApprove',
  shippingProfitMargin = 'shippingProfitMargin',
  printFileFieldName = 'printFileFieldName',
  premiumMockups = 'premiumMockups',
  mockupsDownload = 'mockupsDownload',
  personalization = 'personalization',
  productExpansion = 'productExpansion',

  // Users
  usersList = 'usersList',

  // Apps
  appsList = 'appsList',
  appFilter = 'app-filter',
  appGraphics = 'app-graphics',
  appPersonalization = 'app-advanced-personalization',
  appShutterstock = 'app-shutterstock',
  appShutterstockPremium = 'app-shutterstock-full',
  appShutterstockEssential = 'app-shutterstock-essential',

  // Other
  default = 'default',
  counter = 'counter',
  editor_paywall = 'editor_paywall',
  editorPriceBreakdown = 'editorPriceBreakdown',
  homeScreenCard = 'homescreen-card',
  homeScreenTrialEndingPopUp = 'homeScreenTrialEndingPopUp',
  homeScreenUpsellWidget = 'homeScreenUpsellWidget',
  homeScreenGrowChecklist = 'homeScreenGrowChecklist',
  homeScreenGelatoRedirect = 'homeScreenGelatoRedirect',
  ordersList = 'orders-list',
  orderDetails = 'orders-details',
  prolongation = 'prolongation',
  checkoutShippingStep = 'checkout-shipping-step',
  checkoutReviewStep = 'checkout-review-step',
  checkoutSubscriptionPostPurchase = 'checkout-subscription-post-purchase',
  checkoutSubscriptionDiscountProductItem = 'checkout-subscription-discount-product-item',
  successOrderPlacementModal = 'success-order-placement-modal',
  productDescriptionPage = 'product-description-page',
  brandingPackaging = 'branding-packaging',
}
