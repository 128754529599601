<img src="{{ 'subscriptions/crown.svg' | assetsPath | async }}" />
<div class="message">
  <p class="title">
    {{ title | translate }}
  </p>
  <p>
    {{ message | translate }}
  </p>
</div>
<div class="action" *ngIf="showButton">
  <a class="cta-try-gelato-plus" (click)="onClick()">{{ 'txt_subscribe' | translate }}</a>
</div>
