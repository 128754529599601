<div class="header">
  <div class="header-content">
    <a (click)="onBackButtonClick()">
      <img width="32" height="32" [src]="'icons/arrow-left-black.svg' | assetsPath | async" />
    </a>
    <div class="header-title">
      {{ selectedStore?.name }}
    </div>
  </div>
</div>

<div class="content">
  <gc-full-screen-spinner *ngIf="isLoading"></gc-full-screen-spinner>

  <ng-container *ngIf="!isLoading && !productsTree?.length && !delayedProductSearchQuery">
    <div class="empty-state-wrapper">
      <p>
        {{ 'txt_store_products_list_modal_empty_state_description' | translate }}
      </p>
    </div>
  </ng-container>

  <div *ngIf="productsTree?.length || delayedProductSearchQuery" class="product-list">
    <gd-splash-message *ngIf="splashMessageId" [id]="splashMessageId"></gd-splash-message>
    <gd-store-products-list-search-input
      [value]="productSearchQuery"
      (valueChange)="onProductSearchQueryChange($event)"
    >
    </gd-store-products-list-search-input>

    <div *ngIf="!productsTree?.length" class="no-matching-results-wrapper">
      <p>
        {{ 'txt_store_products_list_modal_no_mathching_products' | translate }}
      </p>
    </div>

    <ng-container *ngIf="productsTree?.length">
      <gd-store-products-list
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onProductsListScrollDown()"
        [items]="productsTree"
        [storeType]="selectedStore?.type"
        [isSelectVariants]="isSelectVariants"
        [multipleSelectionEnabled]="multipleSelectionEnabled"
        [checkedProductVariantIds]="checkedProductVariantIds"
        [checkedProductIds]="checkedProducts"
        (checkedProductVariantIdsChange)="onCheckedProductVariantIdsChange($event)"
        (checkedProductsChange)="onCheckedProductsChange($event)"
      >
      </gd-store-products-list>
    </ng-container>
  </div>
</div>

<div class="actions">
  <ng-container *ngIf="isLoading || productsTree?.length || delayedProductSearchQuery">
    <div class="actions-content">
      <p class="variants-count-label">
        <span [class.exceeded-limit]="showLimitExceededLabel">
          {{ footerLabelTextKey | translate: { count: checkedProductVariantsCount, maxCount: maxCheckedItemsCount } }}
        </span>
      </p>
      <div class="action-buttons">
        <button class="ui secondary button" [class.disabled]="isLoading" (click)="requestModalClose()">
          {{ 'txt_cancel' | translate }}
        </button>

        <button
          class="ui primary button disabled"
          [class.disabled]="!canSubmit"
          (click)="onChooseProductsButtonClick()"
        >
          {{ 'txt_choose' | translate }}
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!isLoading && !productsTree?.length && !delayedProductSearchQuery">
    <div class="flex-row flex-justify-end flex-items-center">
      <button class="ui secondary button" [class.disabled]="isLoading" (click)="requestModalClose()">
        {{ 'txt_close' | translate }}
      </button>

      <button class="ui primary button" [class.disabled]="!selectedStore" (click)="addStoreProduct()">
        {{ 'txt_store_products_list_modal_empty_state_primary_button' | translate }}
      </button>
    </div>
  </ng-container>
</div>
