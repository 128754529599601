import { EventEmitter, Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { FeaturesService } from '../../shared/services/features.service';
import { SidebarItem } from './sidebar-item';
import { SidebarNavItemIdEnum } from './sidebar-nav-item-id.enum';
import { isPrepaidAccount } from '@api-ui-app/src/app/ngrx/company-details.reducer';
import { select, Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { ChargeBeeService } from '@api-ui-app/src/app/subscriptions/services/charge-bee.service';
import { trackEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { getPaidOrdersCount } from '@api-ui-app/src/app/ngrx/account-activation-steps.selector';
import { HelpCenterArticles } from '@gelato-api-ui/core/help-center/help-center-articles';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';
import { DestygoWebChatService } from '@api-ui-app/src/app/app-store/services/destygo-web-chat.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationTreeService {
  toggleMobileSidebarTrigger = new EventEmitter<void>();

  private userWithFirstOrder$: Observable<boolean> = this.store.pipe(select(getPaidOrdersCount), map(Boolean));

  private readonly billingTree = [
    new SidebarItem({
      id: SidebarNavItemIdEnum.BILLING_ENTITIES,
      name: 'txt_sidebar_billing',
      iconSvg: 'billing',
      children: [
        new SidebarItem({
          id: SidebarNavItemIdEnum.BILLING_ENTITIES,
          name: 'txt_sidebar_billing_entities',
          route: ['/billing-entities'],
          isVisible: () => this.authService.hasReadPermission('finance', 'billing-entity'),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.PRICE_PLANS,
          name: 'txt_sidebar_price_plans',
          route: ['/price-plans'],
          isVisible: () => this.authService.hasReadPermission('finance', 'price-plan'),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.PRICE_PLAN_SUBSCRIPTIONS,
          name: 'txt_sidebar_price_plan_subscriptions',
          route: ['/price-plan-subscriptions'],
          isVisible: () => this.authService.hasReadPermission('finance', 'price-plan-subscription'),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.SETTINGS_TAX_CENTER,
          name: 'txt_sidebar_tax_center',
          route: ['/tax-center'],
          isVisible: () => this.authService.hasReadPermission('billing', 'resale-certificate'),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.SETTINGS_CURRENCY,
          name: 'txt_sidebar_currency',
          route: ['/currency'],
          isVisible: () =>
            combineLatest([
              this.authService.isGelatoUser(),
              this.authService.hasReadPermission('billing', 'stored-payment-method'),
            ]).pipe(map(([isGelatoUser, hasPermission]) => !isGelatoUser && hasPermission)),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.SETTINGS_PAYMENT_METHODS,
          name: 'txt_sidebar_payment_methods',
          route: ['/payment-methods'],
          isVisible: () =>
            combineLatest([
              this.authService.hasWritePermission('billing', 'stored-payment-method'),
              this.store.select(isPrepaidAccount),
            ]).pipe(map(([hasPermission, prepaidAccount]) => Boolean(hasPermission) && Boolean(prepaidAccount))),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.SUBSCRIPTION_PLAN,
          name: 'txt_sidebar_subscription_plan',
          action: () => this.chargeBeeApiService.openPortal(),
          isVisible: () => this.authService.isGelatoUser().pipe(map((isGelatoUser: boolean): boolean => !isGelatoUser)),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.PAYMENT_TRANSACTIONS,
          name: 'txt_sidebar_payment_transactions',
          route: ['/payment-transactions'],
          isVisible: () =>
            combineLatest([
              this.authService.isGelatoUser(),
              this.authService.hasReadPermission('finance', 'price-plan'),
            ]).pipe(map(([isGelatoUser, hasPermission]) => isGelatoUser && hasPermission)),
        }),
      ],
    }),
  ];

  private readonly settingsTree = [
    new SidebarItem({
      id: SidebarNavItemIdEnum.SETTINGS,
      name: 'txt_sidebar_settings',
      iconSvg: 'settings',
      children: [
        new SidebarItem({
          id: SidebarNavItemIdEnum.SETTINGS_USERS,
          name: 'txt_sidebar_users',
          route: ['/users'],
          isVisible: () =>
            combineLatest([
              this.authService.isGelatoUser(),
              this.authService.hasReadPermission('identity', 'user'),
            ]).pipe(map(([isGelatoUser, hasPermission]) => !isGelatoUser && hasPermission)),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.SETTINGS_COMPANY_INFO,
          name: 'txt_sidebar_company_info',
          route: ['/company-info'],
          isVisible: () =>
            combineLatest([
              this.authService.isGelatoUser(),
              this.authService.hasWritePermission('billing', 'company-details'),
            ]).pipe(map(([isGelatoUser, hasPermission]) => !isGelatoUser && hasPermission)),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.PRODUCTION_CONFIGURATIONS,
          name: 'txt_sidebar_production_configurations',
          route: ['/production-configurations'],
          isVisible: () => this.authService.hasReadPermission('production', 'configuration'),
        }),
      ],
    }),
  ];

  private readonly developerTree = [
    new SidebarItem({
      id: SidebarNavItemIdEnum.DEVELOPER,
      name: 'txt_sidebar_developer',
      iconSvg: 'developer',
      children: [
        new SidebarItem({
          id: SidebarNavItemIdEnum.API_DOCUMENTATION,
          name: 'txt_navigation_api_documentation',
          href: 'https://dashboard.gelato.com/docs/',
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.SETTINGS_API_KEYS,
          name: 'txt_sidebar_api_keys',
          route: ['/keys'],
          isVisible: () => this.authService.hasReadPermission('identity', 'key'),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.SETTINGS_NOTIFICATIONS,
          name: 'txt_sidebar_webhooks',
          route: ['/webhooks'],
          isVisible: () => this.authService.hasReadPermission('notification', 'subscription'),
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.AUDIT_LOGS,
          name: 'txt_sidebar_audit_logs',
          route: ['/audit-logs'],
          isVisible: () =>
            combineLatest([this.authService.isGelatoUser(), this.authService.hasReadPermission('ecommerce', '*')]).pipe(
              map(([isGelatoUser, hasPermission]) => isGelatoUser && hasPermission),
            ),
        }),
      ],
    }),
  ];

  private readonly brandingTree = [
    new SidebarItem({
      id: SidebarNavItemIdEnum.BRANDING,
      name: 'txt_sidebar_branding',
      iconSvg: 'branding',
      isVisible: () =>
        combineLatest([
          this.featureSwitcherService.isFeatureEnabledForCurrentUser(FeatureFlagEnum.branded_packaging),
          this.authService.isGelatoUser(),
        ]).pipe(map(([isFeatureEnabled, isGelatoUser]) => isFeatureEnabled && !isGelatoUser)),
      children: [
        new SidebarItem({
          id: SidebarNavItemIdEnum.BRANDING_CONFIGURE,
          name: 'txt_sidebar_your_configure',
          route: ['/branding/configure'],
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.BRANDING_DESIGNS,
          name: 'txt_sidebar_your_projects',
          route: ['/branding/projects'],
        }),
      ],
    }),
  ];

  private readonly supportTree = [
    new SidebarItem({
      id: SidebarNavItemIdEnum.SUPPORT,
      name: 'txt_navigation_support',
      iconSvg: 'support',
      children: [
        new SidebarItem({
          id: SidebarNavItemIdEnum.CHAT_BOT,
          name: 'txt_sidebar_chat',
          action: () => {
            this.destygoWebChatService.toggleChat();
            this.toggleMobileSidebarTrigger.emit();
          },
          isVisible: () => this.destygoWebChatService.isWebChatEnabled$,
        }),
        new SidebarItem({
          id: SidebarNavItemIdEnum.HELP_CENTER,
          name: 'txt_sidebar_help_center',
          href: HelpCenterArticles.helpCenter,
        }),
      ],
    }),
  ];

  readonly tree: Observable<SidebarItem[]> = combineLatest([this.userWithFirstOrder$]).pipe(
    map(([isUserWithFirstOrder]) => [
      new SidebarItem({
        id: SidebarNavItemIdEnum.HOME,
        name: 'txt_sidebar_home',
        iconSvg: 'home',
        route: ['/home'],
        isVisible: () => this.authService.hasReadPermission('order', 'analytics'),
      }),
      new SidebarItem({
        id: SidebarNavItemIdEnum.PRODUCT_CATALOGUE,
        name: 'txt_sidebar_product_catalogue',
        iconSvg: 'product-catalog',
        route: ['/catalogue'],
        isVisible: () => this.authService.hasReadPermission('product', 'catalogue'),
        analyticKey: 'catalogueNavigationStep',
      }),
      new SidebarItem({
        id: SidebarNavItemIdEnum.CLIENTS,
        name: 'txt_sidebar_clients',
        iconSvg: 'building',
        route: ['/clients'],
        isVisible: () => this.authService.hasReadPermission('identity', 'client'),
      }),
      new SidebarItem({
        id: SidebarNavItemIdEnum.USERS,
        name: 'txt_sidebar_users',
        iconSvg: 'user',
        route: ['/users'],
        isVisible: () =>
          combineLatest([this.authService.hasReadPermission('identity', 'user'), this.authService.isGelatoUser()]).pipe(
            map(([hasPermission, isGelatoUser]) => hasPermission && isGelatoUser),
          ),
      }),
      new SidebarItem({
        id: SidebarNavItemIdEnum.ORDERS,
        name: 'txt_sidebar_orders',
        iconSvg: 'basket',
        route: ['/orders'],
        isVisible: () => this.authService.hasReadPermission('order', 'order'),
      }),
      new SidebarItem({
        id: SidebarNavItemIdEnum.E_COMMERCE_STORES,
        name: 'txt_sidebar_stores',
        iconSvg: 'stores',
        route: ['/stores'],
        isVisible: () => this.authService.hasReadPermission('ecommerce', '*'),
      }),
      new SidebarItem({
        id: SidebarNavItemIdEnum.E_COMMERCE_PRODUCT_TEMPLATES,
        name: 'txt_sidebar_templates',
        iconSvg: 'templates',
        route: ['/templates'],
        isVisible: () =>
          combineLatest([
            this.featureSwitcherService.isFeatureEnabledForCurrentUser(FeatureFlagEnum.product_templates),
            this.authService.isGelatoUser(),
            this.authService.hasReadPermission('ecommerce', '*'),
          ]).pipe(
            map(
              ([isFeatureEnabled, isGelatoUser, hasPermission]): boolean =>
                isFeatureEnabled && !isGelatoUser && hasPermission,
            ),
          ),
      }),
      new SidebarItem({
        id: SidebarNavItemIdEnum.ANALYTICS,
        name: 'txt_sidebar_analytics',
        iconSvg: 'analytics',
        route: ['/analytics'],
        isVisible: () => this.authService.hasReadPermission('order', 'analytics'),
      }),
      new SidebarItem({
        id: SidebarNavItemIdEnum.APPS,
        name: 'txt_sidebar_growth_plans',
        iconSvg: isUserWithFirstOrder ? 'gelato-plus' : 'gelato-plus-gray',
        route: ['/growth-plans'],
        action: () => {
          trackEvent({
            event: 'logEvent',
            eventType: 'appStoreListVisit',
          });
        },
        isVisible: () => this.authService.isGelatoUser().pipe(map((isGelatoUser: boolean): boolean => !isGelatoUser)),
      }),

      ...this.brandingTree,

      new SidebarItem({
        id: SidebarNavItemIdEnum.MOCKUP_GALLERY,
        name: 'txt_sidebar_mockup_gallery',
        iconSvg: 'mockup_gallery',
        route: ['/mockup-gallery/projects'],
        isVisible: () =>
          combineLatest([
            this.featureSwitcherService.isFeatureEnabledForCurrentUser(FeatureFlagEnum.mockup_gallery),
            this.authService.isGelatoUser(),
          ]).pipe(map(([isFeatureEnabled, isGelatoUser]) => isFeatureEnabled && !isGelatoUser)),
      }),

      new SidebarItem({
        id: SidebarNavItemIdEnum.MOCKUP_STUDIO,
        name: 'txt_sidebar_mockup_studio',
        iconSvg: 'mockup_gallery',
        route: ['/mockup-studio'],
        isVisible: () =>
          combineLatest([
            this.featureSwitcherService.isFeatureEnabledForCurrentUser(FeatureFlagEnum.mockup_studio),
            this.authService.isGelatoUser(),
          ]).pipe(map(([isFeatureEnabled, isGelatoUser]) => isFeatureEnabled && !isGelatoUser)),
      }),

      // ---- BILLING TREE ----
      ...this.billingTree,
      ...this.settingsTree,
      ...this.developerTree,

      new SidebarItem({
        id: SidebarNavItemIdEnum.FEATURES,
        name: 'txt_sidebar_features',
        iconSvg: 'puzzle',
        route: ['/features'],
        isVisible: () => of(this.featureService.isEnabled(this.featureService.FEATURE_MANAGEMENT)),
      }),

      null, // null values are used as separators

      new SidebarItem({
        id: SidebarNavItemIdEnum.WHATS_NEW,
        name: 'txt_navigation_whats_new',
        iconSvg: 'whats_new',
        href: '#beamerTrigger',
      }),

      ...this.supportTree,
    ]),
  );

  constructor(
    private readonly featureService: FeaturesService,
    private readonly featureSwitcherService: FeatureSwitcherService,
    private readonly authService: AuthService,
    private readonly chargeBeeApiService: ChargeBeeService,
    private readonly destygoWebChatService: DestygoWebChatService,
    private readonly store: Store<AppState>,
  ) {}
}
