import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { DesignStructure, PrintAreasToUidVariant } from '@gelato-api-ui/core/designs/design-structure';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { getSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { mapAssetsToCollection } from '@gelato-api-ui/core/designs/helpers/mapAssetsToCollection';
import { EditorInboundEventType } from '@gelato-api-ui/core/design-editor/editor-inbound-event-type.enum';
import { AssetsApiService } from '@gelato-api-ui/core/designs/services/assets-api.service';
import * as assetsMappingActions from '@product-catalogue/src/lib/ngrx/assets.actions';
import { DesignEditorCommunicationService } from '@product-catalogue/src/lib/product-catalogue/services/design-editor-communication.service';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { getUsedAssets } from '@gelato-api-ui/core/designs/helpers/getUsedAssets';
import { getDesignMedia } from '@gelato-api-ui/core/designs/helpers/media/getDesignMedia';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { UploadedImageDataService } from '@gelato-api-ui/core/designs/services/uploaded-image-data.service';
import { DesignEditorApparelService } from '@product-catalogue/src/lib/product-catalogue/services/design-editor-apparel.service';
import { DesignEditorPrintAreaConflictsService } from '@product-catalogue/src/lib/product-catalogue/services/design-editor-print-area-conflicts.service';
import * as R from 'ramda';

@Injectable({ providedIn: 'root' })
export class DesignEditorSharedService {
  selectedClientId$ = this.store$.select(getSelectedClientId);

  fetchedAssetIds = [];
  ASSETS_AMOUNT_TO_FETCH = 100;

  constructor(
    private store$: Store<AppState>,
    private readonly modalService: SuiModalService,
    private readonly assetsApiService: AssetsApiService,
    private readonly subscriptionsFacade: SubscriptionsFacade,
    private readonly designEditorCommunicationService: DesignEditorCommunicationService,
    private readonly designEditorApparelService: DesignEditorApparelService,
    private readonly uploadedImageDataService: UploadedImageDataService,
    private readonly designEditorPrintAreaConflictsService: DesignEditorPrintAreaConflictsService,
  ) {}

  addUploadedAssetsToEditor() {
    return this.selectedClientId$.pipe(switchMap(clientId => this.fetchAssets(clientId)));
  }

  fetchAssets(clientId: string, getAssets = false) {
    return combineLatest([
      this.assetsApiService.get({ clientId, limit: this.ASSETS_AMOUNT_TO_FETCH }),
      this.subscriptionsFacade.gelatoPlusOrGoldIsActive$,
      this.subscriptionsFacade.pricePlansLoading$,
    ]).pipe(
      filter(([, , pricePlansLoading]) => !pricePlansLoading),
      take(1),
      tap(([assets]) => (this.fetchedAssetIds = assets.map(a => a.id))),
      map(([assets, gelatoPlusOrGoldIsActive]) =>
        getAssets ? assets : this.setAssets(assets, gelatoPlusOrGoldIsActive),
      ),
    );
  }

  trackImageAddedToDesign(asset: Asset) {
    if (!asset) {
      return;
    }
    const { assetProviderUid, id } = asset;
    logEvent('editorClickAddImage', {
      assetProviderUid,
      addedThisSession: !this.fetchedAssetIds.includes(id),
    });
  }

  /*
   * Should return true if no change is needed
   * */
  checkDesignChangeForPrice(prev, next) {
    const [prevDS, prevAssets, , , prevDimensions] = prev;
    const [nextDS, nextAssets, , , nextDimensions] = next;
    const toString = (arr: Asset[]) => arr.map(e => e.id).join(',');
    const usedPrevAssets = toString(getUsedAssets(prevAssets, prevDS));
    const usedNextAssets = toString(getUsedAssets(nextAssets, nextDS));
    const sameAmountOfMedia = getDesignMedia(prevDS).length === getDesignMedia(nextDS).length;
    const sameAssetsUsed = usedPrevAssets === usedNextAssets;
    const isSizeWasNotChanged = R.equals(prevDimensions, nextDimensions);

    return sameAmountOfMedia && sameAssetsUsed && isSizeWasNotChanged;
  }

  disablePrintAreasWithConflicts(designData: DesignStructure, printAreasToUidMap: PrintAreasToUidVariant[] = []) {
    const printAreasToDisable = this.designEditorPrintAreaConflictsService.getPrintAreasToDisable(
      designData,
      printAreasToUidMap,
    );
    this.designEditorCommunicationService.sendPostMessage(
      EditorInboundEventType.setDisabledSpreads,
      printAreasToDisable,
    );
  }

  private setAssets(assets: Asset[], gelatoPlusOrGoldIsActive: boolean) {
    const collection = mapAssetsToCollection(assets);

    // to store
    this.store$.dispatch(new assetsMappingActions.SetAssets(collection));

    // to editor
    const uploadedImagesData = this.uploadedImageDataService.mapAssetsToUploadedImagesData(
      assets,
      gelatoPlusOrGoldIsActive,
    );
    this.designEditorCommunicationService.sendPostMessage(
      EditorInboundEventType.loadUploadedImages,
      uploadedImagesData,
    );

    return assets;
  }
}
