import { Observable, Subject } from 'rxjs';
import { ESCAPE, hasModifierKey } from '@angular/cdk/keycodes';
import { filter } from 'rxjs/operators';
import { GlobalPositionStrategy, OverlayRef } from '@angular/cdk/overlay';

export class DialogRef<T = unknown> {
  private afterClosed$: Subject<T> = new Subject<T>();

  constructor(private readonly overlayRef: OverlayRef, public readonly positionStrategy: GlobalPositionStrategy) {
    overlayRef
      .keydownEvents()
      .pipe(filter(event => event.keyCode === ESCAPE && !hasModifierKey(event)))
      .subscribe(event => {
        event.preventDefault();

        this.close();
      });

    overlayRef.backdropClick().subscribe(() => {
      this.close();
    });
  }

  public close(result?: T): void {
    this.overlayRef.dispose();
    this.afterClosed$.next(result);
    this.afterClosed$.complete();
  }

  public moveToBottom(): this {
    this.positionStrategy.bottom();
    this.overlayRef.updatePosition();

    return this;
  }

  updateSize(width: string = '', height: string = ''): this {
    this.overlayRef.updateSize({ width, height });
    this.overlayRef.updatePosition();

    return this;
  }

  public afterClosed(): Observable<T> {
    return this.afterClosed$.asObservable();
  }
}
