<div class="header">
  <img [src]="headerIconPath | assetsPath | async" width="" height="" alt="" />
  {{ headerText }}
</div>
<div class="content">
  <div class="main-content">
    <p [innerHTML]="text"></p>
    <div class="action-btn">
      <button class="ui primary button" (click)="onActionClick()">
        {{ buttonText }}
      </button>
    </div>
  </div>
  <div class="image">
    <img [src]="imagePath | assetsPath | async" width="" height="" alt="" />
  </div>
</div>
