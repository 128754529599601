import { createAction, props, union } from '@ngrx/store';
import { Promotion } from '@api-ui-app/src/app/shared/voucher/voucher';

export const applyPromotion = createAction(
  '[Discounts] Apply Promotion',
  props<{ clientId: string; voucherId: string; voucherEvent: string }>(),
);

export const setLoading = createAction('[Discounts] Set Loading');

export const loadPromotions = createAction('[Discounts] Load Promotions', props<{ clientId: string }>());

export const loadPromotionsSuccess = createAction(
  '[Discounts] Load Promotions Success',
  props<{ payload: Promotion[] }>(),
);

const actions = union({
  applyPromotion,
  setLoading,
  loadPromotions,
  loadPromotionsSuccess,
});
export type DiscountsActions = typeof actions;
