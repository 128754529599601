import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceInterface } from './auth-service-interface';
import { KeycloakAuthService } from '@api-ui-app/src/app/shared/services/keycloak-auth.service';
import { HttpHeaders } from '@gelato-api-ui/core/api/http-headers';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements AuthServiceInterface {
  constructor(private keycloakAuthService: KeycloakAuthService) {
    this.appBoot().then();
  }

  appBoot() {
    return this.getService().appBoot();
  }

  getService() {
    return this.keycloakAuthService;
  }

  isAuthorised(): Observable<boolean> {
    return this.getService().isAuthorised();
  }

  getUserRoles(): Observable<string[]> {
    return this.getService().getUserRoles();
  }

  hasUserRole(userRole: string): Observable<boolean> {
    return this.getService().hasUserRole(userRole);
  }

  getClientId(): Observable<string> {
    return this.getService().getClientId();
  }

  getIdToken(): string {
    return this.getService().getIdToken();
  }

  hasClientUserRolesOnly(): Observable<boolean> {
    return this.getService().hasClientUserRolesOnly();
  }

  isGelatoUser(): Observable<boolean> {
    return this.getService().isGelatoUser();
  }

  hasReadPermission(scopeType: string, scopeName: string): Observable<boolean> {
    return this.getService().hasReadPermission(scopeType, scopeName);
  }

  hasWritePermission(scopeType: string, scopeName: string): Observable<boolean> {
    return this.getService().hasWritePermission(scopeType, scopeName);
  }

  hasPermission(scopeType: string, scopeName: string, scopeAccessLevel: 'read' | 'write'): Observable<boolean> {
    return this.getService().hasPermission(scopeType, scopeName, scopeAccessLevel);
  }

  requireAuthorisedUserWithUserRole(userRole: string, route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.getService().requireAuthorisedUserWithUserRole(userRole, route);
  }

  requireAuthorisedUserWithReadPermission(
    scopeType: string,
    scopeName: string,
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    return this.getService().requireAuthorisedUserWithReadPermission(scopeType, scopeName, route);
  }

  requireAuthorisedUserWithWritePermission(
    scopeType: string,
    scopeName: string,
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    return this.getService().requireAuthorisedUserWithWritePermission(scopeType, scopeName, route);
  }

  requireAuthorisedUserWithPermission(
    scopeType: string,
    scopeName: string,
    scopeAccessLevel: 'read' | 'write',
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    return this.getService().requireAuthorisedUserWithPermission(scopeType, scopeName, scopeAccessLevel, route);
  }

  requireAuthorisedUser(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.getService().requireAuthorisedUser(route);
  }

  getHttpHeaders(): Observable<HttpHeaders> {
    return this.getService().getHttpHeaders();
  }

  redirectToSignIn() {
    this.getService().redirectToSignIn();
  }

  redirectToSignUp() {
    this.getService().redirectToSignUp();
  }

  signOut() {
    this.getService().signOut();
  }

  getTokenSessionId(): string {
    return this.getService().getTokenSessionId();
  }

  getAuthUrl(redirectUrl: string): string {
    return this.getService().getKeycloakAuthUrl(redirectUrl);
  }
}
