import { ProductSearchCategory } from './product-search-category';

export const homeCategoryId = '/';

export const ProductSearchCategories: ProductSearchCategory[] = [
  {
    title: 'Apparel',
    catalogueAlias: 'apparel',
    iconUrl: 'catalogue/apparel.jpg',
  },
  {
    title: 'Posters',
    catalogueAlias: 'posters',
    iconUrl: 'catalogue/posters.jpg',
  },
  {
    title: 'Framed posters',
    catalogueAlias: 'framed-posters',
    iconUrl: 'catalogue/framed-posters.jpg',
  },
  {
    title: 'Canvas',
    catalogueAlias: 'canvas',
    iconUrl: 'catalogue/canvas.jpg',
  },
  {
    title: 'Acrylic',
    catalogueAlias: 'acrylic',
    iconUrl: 'catalogue/acrylic.jpg',
  },
  {
    title: 'Metallic',
    catalogueAlias: 'metallic',
    iconUrl: 'catalogue/metallic.jpg',
  },
  {
    title: 'Mugs',
    catalogueAlias: 'mugs',
    iconUrl: 'catalogue/mugs.jpg',
  },
  {
    title: 'Frames',
    catalogueAlias: 'frames',
    iconUrl: 'catalogue/frames.jpg',
  },
  {
    title: 'Hangers',
    catalogueAlias: 'hangers',
    iconUrl: 'catalogue/hangers.jpg',
  },
  {
    title: 'Photobooks',
    catalogueAlias: 'photo-books',
    iconUrl: 'catalogue/hard-cover-photobooks.jpg',
  },
  {
    title: 'Soft cover Photobooks',
    catalogueAlias: 'soft-cover-photobooks',
    iconUrl: 'catalogue/soft-cover-photobooks.jpg',
  },
  {
    title: 'Hard cover Photobooks',
    catalogueAlias: 'hard-cover-photobooks',
    iconUrl: 'catalogue/hard-cover-photobooks.jpg',
  },
  {
    title: 'Cards',
    catalogueAlias: 'cards',
    iconUrl: 'catalogue/cards.jpg',
  },
  {
    title: 'Folded Cards',
    catalogueAlias: 'folded-cards',
    iconUrl: 'single-fold-brochures.svg',
  },
  {
    title: 'Business Cards',
    catalogueAlias: 'business-cards',
    iconUrl: 'business-cards.svg',
  },
  {
    title: 'Notebooks',
    catalogueAlias: 'notebooks',
    iconUrl: 'catalogue/notebooks.jpg',
  },
  {
    title: 'Flyers',
    catalogueAlias: 'flyers',
    iconUrl: 'flyers.svg',
  },
  {
    title: 'Calendars',
    catalogueAlias: 'calendars',
    iconUrl: 'catalogue/calendars.jpg',
  },
  {
    title: 'Single fold brochures',
    catalogueAlias: 'single-fold-brochures',
    iconUrl: 'single-fold-brochures.svg',
  },
  {
    title: 'Multipage Brochures',
    catalogueAlias: 'multipage-brochures',
    iconUrl: 'multipage-brochures.svg',
  },
  {
    title: 'Roll folded brochures',
    catalogueAlias: 'roll-folded-brochures',
    iconUrl: 'roll-folded-brochures.svg',
  },
  {
    title: 'Accordion fold brochures',
    catalogueAlias: 'accordion-fold-brochures',
    iconUrl: 'accordion-fold-brochures.svg',
  },
  {
    title: 'Folders',
    catalogueAlias: 'folders',
    iconUrl: 'folders.svg',
  },
  {
    title: 'Letterheads',
    catalogueAlias: 'letterheads',
    iconUrl: 'letterheads.svg',
  },
  {
    title: 'Roll ups',
    catalogueAlias: 'roll-ups',
    iconUrl: 'roll-ups.svg',
  },
];
