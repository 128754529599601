import { OrderReceiptItemType } from '@api-ui-app/src/app/orders/lib/order-receipt-item-type.enum';
import { OrderReceiptItem } from '@api-ui-app/src/app/orders/types/order-receipt-item';
import { OrderReceipt } from '@api-ui-app/src/app/orders/lib/order-receipt';

export type OrderDetailsPricePlans = Record<string, { id: string; name: string }>;

export class OrderDetailsPricePlanSelectorModel {
  pricePlans: OrderDetailsPricePlans = {};

  constructor(activeOrderReceipt: OrderReceipt, pricePlanNames: {}) {
    if (!activeOrderReceipt || !Object.keys(pricePlanNames).length) {
      return this;
    }

    activeOrderReceipt.items
      .filter(item => [OrderReceiptItemType.PRODUCT].includes(item.type))
      .forEach((item: OrderReceiptItem) => {
        this.pricePlans[item.referenceId] = {
          id: item.pricePlanId,
          name: pricePlanNames[item.pricePlanId],
        };
      });
  }
}
