import { Dictionary } from '@ngrx/entity';

export interface CreatePreviewRequest {
  assetId: string; // The asset that you want to remove its background
  transformers: Transformation[];
}

export interface Transformation {
  assetTransformerUid: AssetTransformerUid;
  params?: { [key: string]: any }; // The transformer parameters
}

/*
  The UID of the asset transformer
 */
export enum AssetTransformerUid {
  REMOVE_BG_LOW_RES = 'remove-bg-lowres',
  REMOVE_BG = 'remove-bg', // full image quality
}

export interface CreatePreviewResponse {
  url: string; // Temporary URL for the preview of removed background image
}

export interface CreateAssetRequest {
  assetId: string; // The asset that you want to remove its background
  transformers: Transformation[];
}
