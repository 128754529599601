import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { getClientDescriptions } from '@api-ui-app/src/app/ngrx/client-selection-list.reducer';

@Pipe({
  name: 'clientDescription',
})
export class ClientDescriptionPipe implements PipeTransform {
  clientDescriptions$ = this.store.pipe(select(getClientDescriptions));

  constructor(private store: Store<AppState>) {}

  transform(value: any, args?: any): any {
    return this.clientDescriptions$.pipe(map(clientDescriptions => clientDescriptions[value] || ''));
  }
}
