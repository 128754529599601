import { createAction, props } from '@ngrx/store';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { Order } from '../../orders/lib/order';
import { CustomerSegment } from '../types/customer-segment';
import { GrowChecklistStepState } from '../types/grow-checklist-step-state';
import { GrowChecklistStep } from '../types/grow-checklist-steps';

export const initiateState = createAction('[Customer Onboarding] Initiate State');
export const loadCreatedProducts = createAction('[Customer Onboarding] Load Created Products');
export const loadCreatedProductsSuccess = createAction(
  '[Customer Onboarding] Load Created Products Success',
  props<{ products: EProductAbbridged[] }>(),
);
export const loadCustomerMadeOrder = createAction('[Customer Onboarding] Load Customer Made Order');
export const loadCustomerMadeOrderSuccess = createAction(
  '[Customer Onboarding] Load Customer Made Order Success',
  props<{ orders: Order[] }>(),
);
export const loadCustomerOrdersCount = createAction('[Customer Onboarding] Load Customer Orders Count');
export const loadCustomerOrdersCountSuccess = createAction(
  '[Customer Onboarding] Load Customer Orders Count Success',
  props<{ ordersCount: number }>(),
);
export const setCustomerSegment = createAction(
  '[Customer Onboarding] Set Customer Segment',
  props<{ segment: CustomerSegment }>(),
);

export const setGrowChecklistStepState = createAction(
  '[Customer Onboarding] Set Grow Checklist Step State',
  props<{ stepId: GrowChecklistStep; stepState: GrowChecklistStepState }>(),
);
