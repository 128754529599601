import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SidebarItem } from './sidebar-item';

@Component({
  selector: 'gd-sidebar-nav',
  templateUrl: './sidebar-nav.component.html',
  styleUrls: ['./sidebar-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarNavComponent {
  @Input() navigationTree: SidebarItem[] = [];
  @Input() counters: any = {};
  @Input() isGelatoPlusSubscriptionEnding: boolean;
  @Input() endingSubscriptionDaysLeft: number;
  @Input() minimise: boolean;
  @Input() hovered: boolean;
}
