import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { ProductTypeUidService } from '@gelato-api-ui/core/product-catalogue/services/product-type-uid.service';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { hasAppliedDesignTemplate } from '@gelato-api-ui/core/designs/helpers/hasAppliedDesignTemplate';
import {
  DesignAssetCostsCollection,
  PriceCollection,
} from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { DesignGraphQlService } from '@gelato-api-ui/core/designs/services/design-graph-ql.service';
import { DesignCostService } from '@gelato-api-ui/core/designs/services/design-cost.service';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductWizardPricesService {
  constructor(
    private readonly productTypeUidService: ProductTypeUidService,
    private readonly designGraphQlService: DesignGraphQlService,
    private readonly designCostService: DesignCostService,
  ) {}

  fetchAssetCosts(
    clientId: string,
    assetsCollection: AssetsCollection,
    sortedProductVariants: EProductVariant[],
    currency: string,
    productCosts: PriceCollection,
  ): Observable<DesignAssetCostsCollection> {
    if (!currency) {
      return of({});
    }

    const firstProductUid = sortedProductVariants[0]?.productUid;

    return this.productTypeUidService.getByProductUid(firstProductUid).pipe(
      catchError(() => of(null)),
      switchMap((productTypeUid: ProductTypeUid): Observable<DesignAssetCostsCollection> => {
        if (!productTypeUid) {
          return of({});
        }

        const observables = sortedProductVariants.map((loopProductVariant: EProductVariant): Observable<number[]> => {
          if (!loopProductVariant.designStructureJson) {
            return of(null);
          }

          const { productUid, designStructureJson } = loopProductVariant;
          const productCost: number = productCosts[productUid];

          if (!productCost) {
            return of(null);
          }

          const designStructure: DesignStructure = designStructureJson ? JSON.parse(designStructureJson) : null;

          return this.designCostService
            .getDesignAssetPrices(clientId, designStructure, assetsCollection, productTypeUid, currency, productCost)
            .pipe(catchError(() => of(null)));
        });

        return forkJoin(observables).pipe(
          map((responses: number[][]): DesignAssetCostsCollection => {
            const designAssetCosts: DesignAssetCostsCollection = {};

            responses.forEach((loopAssetCostsArray: number[], index: number) => {
              const { productUid } = sortedProductVariants[index];

              designAssetCosts[productUid] = loopAssetCostsArray;
            });

            return designAssetCosts;
          }),
        );
      }),
    );
  }

  fetchDesignTemplateCosts(sortedProductVariants: EProductVariant[], currency: string): Observable<PriceCollection> {
    const designStructures = sortedProductVariants.map((loopProductVariant: EProductVariant): DesignStructure => {
      if (!loopProductVariant.designStructureJson) {
        return;
      }

      return JSON.parse(loopProductVariant.designStructureJson);
    });

    const someVariantsHasAppliedDesignTemplates = designStructures.some(
      (loopDesignStructure: DesignStructure): boolean => hasAppliedDesignTemplate(loopDesignStructure),
    );

    if (!someVariantsHasAppliedDesignTemplates) {
      return of({});
    }

    return this.designGraphQlService.getDesignTemplatePrice(currency).pipe(
      map((designTemplatePrice: number): PriceCollection => {
        const designTemplateCosts: PriceCollection = {};

        sortedProductVariants.forEach((loopProductVariant: EProductVariant, index: number) => {
          if (hasAppliedDesignTemplate(designStructures[index])) {
            designTemplateCosts[loopProductVariant.productUid] = designTemplatePrice;
          }
        });

        return designTemplateCosts;
      }),
    );
  }
}
