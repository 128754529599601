import { createReducer, on } from '@ngrx/store';
import * as actions from './stock-availability-summary.actions';
import { StockAvailabilityPayload } from '@product-catalogue/src/lib/product-catalogue/types/stock-availability-payload';

export interface State {
  isLoading: boolean;
  payload: StockAvailabilityPayload;
}

export const initialState: State = {
  isLoading: false,
  payload: null,
};

export const reducer = createReducer(
  initialState,
  on(actions.resetState, () => initialState),
  on(actions.loadStockAvailabilitySummaryStart, (state, action) => ({
    ...state,
    isLoading: true,
    payload: null,
  })),
  on(actions.loadStockAvailabilitySummarySuccess, (state, action) => ({
    ...state,
    isLoading: false,
    payload: action.payload,
  })),
  on(actions.loadStockAvailabilitySummaryFailure, state => ({
    ...state,
    isLoading: false,
    payload: null,
  })),
);
