<div class="ui sidebar" [class.visible]="isVisible">
  <gd-sidebar-nav
    [navigationTree]="navigationTree$ | async"
    [isGelatoPlusSubscriptionEnding]="isGelatoPlusSubscriptionEnding"
    [endingSubscriptionDaysLeft]="endingSubscriptionDaysLeft"
  ></gd-sidebar-nav>

  <gd-auth-button-mobile *ngIf="false"></gd-auth-button-mobile>
</div>
<div class="ui dimmer" [class.active]="isVisible" (click)="toggleSidebar()">
  <button class="ui button icon basic close-button">
    <i class="ui icon close"></i>
  </button>
</div>
