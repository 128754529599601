import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { ResaleCertificateSearchRequest } from '../lib/resale-certificate-search-request';
import { ResaleCertificateSearchResponse } from '../lib/resale-certificate-search-response';
import { ResaleCertificateCreateRequest } from '../lib/resale-certificate-create-request';
import { ResaleCertificateUpdateRequest } from '../lib/resale-certificate-update-request';
import { ResaleCertificate } from '../lib/resale-certificate';

@Injectable({
  providedIn: 'root',
})
export class ResaleCertificatesApiService {
  constructor(private apiRequest: ApiRequestService) {}

  search(request: ResaleCertificateSearchRequest): Observable<ResaleCertificateSearchResponse> {
    return this.apiRequest
      .get<ResaleCertificateSearchResponse>('/api/v3/resale-certificates', {
        body: request,
      })
      .pipe(map((data: any) => data.data));
  }

  save(resaleCertificate: ResaleCertificate): Observable<ResaleCertificate> {
    if (!resaleCertificate) {
      return of(null);
    }

    if (resaleCertificate.id) {
      const request = new ResaleCertificateUpdateRequest(
        resaleCertificate.id,
        resaleCertificate.clientId,
        resaleCertificate.vendorName,
        resaleCertificate.sellerPermitId,
        resaleCertificate.categories,
        resaleCertificate.status,
        resaleCertificate.stateCodes,
        resaleCertificate.fileUrl,
        resaleCertificate.activeUntil,
      );

      return this.update(resaleCertificate.id, request);
    } else {
      const request = new ResaleCertificateCreateRequest(
        resaleCertificate.clientId,
        resaleCertificate.vendorName,
        resaleCertificate.sellerPermitId,
        resaleCertificate.categories,
        resaleCertificate.stateCodes,
        resaleCertificate.fileUrl,
        resaleCertificate.activeUntil,
      );

      return this.create(request);
    }
  }

  get(id: string): Observable<ResaleCertificate> {
    if (!id) {
      return of(null);
    }

    return this.apiRequest.get<ResaleCertificate>(`/api/v3/resale-certificates/${id}`).pipe(map(data => data.data));
  }

  create(request: ResaleCertificateCreateRequest): Observable<ResaleCertificate> {
    return this.apiRequest
      .post<ResaleCertificate>('/api/v3/resale-certificates', { body: request })
      .pipe(map(data => data.data));
  }

  update(id: string, request: ResaleCertificateUpdateRequest): Observable<ResaleCertificate> {
    return this.apiRequest
      .put<ResaleCertificate>(`/api/v3/resale-certificates/${id}`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  delete(id: string): Observable<ResaleCertificate> {
    return this.apiRequest.delete<ResaleCertificate>(`/api/v3/resale-certificates/${id}`).pipe(map(data => data.data));
  }
}
