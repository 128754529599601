import * as R from 'ramda';
import { CommunicationSubscription } from '../../notifications/lib/communication-subscription';
import { State } from '../notifications.reducer';

export const findCommunicationSubscriptionInState = (state: State, id: string): CommunicationSubscription => {
  if (!state || !id) {
    return null;
  }

  if (state.entity && state.entity.payload && state.entity.payload.id === id) {
    return { ...state.entity.payload };
  }

  if (state.list && state.list.payload && state.list.payload.data) {
    return { ...R.find(R.propEq('id', id))(state.list.payload.data) };
  }

  return null;
};
