import { Pipe, PipeTransform } from '@angular/core';
import { AsYouType } from 'libphonenumber-js';
import { CountryCode } from 'libphonenumber-js/types';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(phoneValue: number | string, countryCode: CountryCode): string | number {
    if (!phoneValue) {
      return '';
    }
    const ayt = new AsYouType(countryCode);
    try {
      return ayt.input(phoneValue + '');
    } catch (error) {
      console.log(error);
      return phoneValue;
    }
  }
}
