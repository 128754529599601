import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DropdownRefComponent } from '@gelato-api-ui/ui-kit/src/lib/dropdown/components/dropdown-ref/dropdown-ref.component';

@NgModule({
  declarations: [DropdownRefComponent],
  imports: [CommonModule],
  exports: [CommonModule],
})
export class DropdownCoreModule {}
