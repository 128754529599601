import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';

export const getProductVariantKey = (variant: Partial<EProductVariant>): string => {
  if (!variant?.productUid) {
    return null;
  }

  let key = variant.productUid;

  if (variant.pageCount) {
    key += `+pageCount=${variant.pageCount}`;
  }

  if (variant.customTrim) {
    const { width, height } = variant.customTrim;

    key += `+width=${width?.value}${width?.unit}+height=${height?.value}${height?.unit}`;
  }

  return key;
};
