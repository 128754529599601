import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { PageLeaveConfirmationService } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation.service';
import { ECommerceProductWizardLeaveConfirmationModalService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard-leave-confirmation-modal.service';
import { ECommerceProductWizardFacade } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard.facade';
import { first } from 'rxjs/operators';

@Injectable()
export class ECommerceProductWizardLeaveConfirmationGuard implements CanDeactivate<any> {
  constructor(
    private readonly pageLeaveConfirmationService: PageLeaveConfirmationService,
    private readonly confirmationModalService: ECommerceProductWizardLeaveConfirmationModalService,
    private readonly facade: ECommerceProductWizardFacade,
  ) {}

  async canDeactivate(component, currentRoute, currentState, nextState): Promise<boolean> {
    const isProductDraftAutoSaveEnabled = await this.facade.isProductDraftAutoSaveEnabled$.pipe(first()).toPromise();
    const hasProductVariants = await this.facade.hasProductVariants$.pipe(first()).toPromise();

    if (!hasProductVariants) {
      return true;
    }

    return this.pageLeaveConfirmationService.canDeactivate(currentState, nextState, () => {
      return this.confirmationModalService.show(isProductDraftAutoSaveEnabled).onDeny(() => {
        if (isProductDraftAutoSaveEnabled) {
          this.facade.initProductDraftAutoSaveTimer();
        }
      });
    });
  }
}
