import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SanityProductBadge } from '@gelato-api-ui/core/sanity/sanity-product-badge';
import { TooltipPositions } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.enum';

@Component({
  selector: 'lib-mounted-framed-poster-available-label',
  templateUrl: './mounted-framed-poster-available-label.component.html',
  styleUrls: ['./mounted-framed-poster-available-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MountedFramedPosterAvailableLabelComponent {
  tooltipPosition = TooltipPositions.topCenter;
}
