import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { PricePlanSubscriptionSearchRequest } from '../lib/price-plan-subscription-search-request';
import { PricePlanSubscriptionSearchResponse } from '../lib/price-plan-subscription-search-response';
import { PricePlanSubscriptionCreateRequest } from '../lib/price-plan-subscription-create-request';
import { PricePlanSubscriptionUpdateRequest } from '../lib/price-plan-subscription-update-request';
import { PricePlanSubscription } from '../lib/price-plan-subscription';

@Injectable({
  providedIn: 'root',
})
export class PricePlanSubscriptionsApiService {
  constructor(private apiRequestService: ApiRequestService) {}

  search(request: PricePlanSubscriptionSearchRequest = null): Observable<PricePlanSubscriptionSearchResponse> {
    return this.apiRequestService
      .post<PricePlanSubscriptionSearchResponse>('/api/v3/price-plans/*/subscriptions:search', { body: request })
      .pipe(
        map((data: any) => ({
          data: data.data,
          pagination: data.pagination,
        })),
      );
  }

  get(id: string): Observable<PricePlanSubscription> {
    if (!id) {
      return of(null);
    }

    return this.apiRequestService
      .get<PricePlanSubscription>(`/api/v3/price-plans/*/subscriptions/${id}`)
      .pipe(map(data => data.data));
  }

  create(request: PricePlanSubscriptionCreateRequest): Observable<PricePlanSubscription> {
    return this.apiRequestService
      .post<PricePlanSubscription>('/api/v3/price-plans/*/subscriptions', {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  update(id: string, request: PricePlanSubscriptionUpdateRequest): Observable<PricePlanSubscription> {
    return this.apiRequestService
      .put<PricePlanSubscription>(`/api/v3/price-plans/*/subscriptions/${id}`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  save(pricePlanSubscription: PricePlanSubscription): Observable<PricePlanSubscription> {
    if (pricePlanSubscription.id) {
      const updateRequest: PricePlanSubscriptionUpdateRequest = new PricePlanSubscriptionUpdateRequest(
        pricePlanSubscription.clientId,
        pricePlanSubscription.pricePlanId,
        pricePlanSubscription.status,
        pricePlanSubscription.activeFrom,
        pricePlanSubscription.activeTo,
      );

      return this.update(pricePlanSubscription.id, updateRequest);
    }

    const createRequest: PricePlanSubscriptionCreateRequest = new PricePlanSubscriptionCreateRequest(
      pricePlanSubscription.clientId,
      pricePlanSubscription.pricePlanId,
      pricePlanSubscription.status,
      pricePlanSubscription.activeFrom,
      pricePlanSubscription.activeTo,
    );

    return this.create(createRequest);
  }

  delete(id: string): Observable<PricePlanSubscription> {
    return this.apiRequestService
      .delete<PricePlanSubscription>(`/api/v3/price-plans/*/subscriptions/${id}`)
      .pipe(map(data => data.data));
  }
}
