import { Component, Input } from '@angular/core';

@Component({
  selector: 'gd-pagination-active-page-label, gc-pagination-active-page-label',
  templateUrl: './pagination-active-page-label.component.html',
  styleUrls: ['./pagination-active-page-label.component.scss'],
})
export class PaginationActivePageLabelComponent {
  @Input() offset: number = null;

  @Input() limit: number = null;

  @Input() total: number = null;

  get translationParams() {
    return {
      startNumber: this.offset + 1,
      endNumber: Math.min(this.offset + this.limit, this.total),
      total: this.total,
    };
  }
}
