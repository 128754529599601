import { SanityProductVariation } from '../sanity-product-variation';

export const findProductVariationByProductUid = (
  productVariations: SanityProductVariation[],
  productUid: string,
): SanityProductVariation => {
  if (!productVariations?.length) {
    return null;
  }

  return productVariations.find(
    (loopProductVariation: SanityProductVariation) => loopProductVariation.productUid === productUid,
  );
};
