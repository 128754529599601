import { SanitySubscriptionPlan } from '@gelato-api-ui/core/app-store/sanity-subscription-plan';
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';
import * as R from 'ramda';
import { PricePlansListContent } from '../../types/price-plans-list-content';
import { PricePlansListItem } from '../../types/price-plans-list-item';
import { ServicePlanUniqueNames } from '../../types/service-plan-unique-names';
import { ServicePlanResponse } from '../../types/subscription-plans';
import { DEFAULT_SERVICE_PLAN_PRICE_CURRENCY, ServicePlanPrice } from '../../types/subscription-price-plans';
import { SubscriptionResponse } from '../../types/subscriptions';
import { getServicePlanIdsFromSubscriptions } from './getServicePlanIdsFromSubscriptions';

const DEFAULT_CURRENCY = 'USD';

const MULTIPLY_VALUE = 100;

const NON_SUPPORTED_CHARGEBEE_CURRENCIES = ['JPY'];

export function getPricePlansListItems(
  servicePlans: ServicePlanResponse[],
  servicePlansPrices: ServicePlanPrice[],
  subscriptionPlans: SanitySubscriptionPlan[],
  periodUnit: TimePeriodUnit,
  userCurrency: string,
  activeServicePlans: ServicePlanResponse[],
  activeSubscriptions: SubscriptionResponse[],
) {
  return R.sort(
    (a: ServicePlanResponse, b: ServicePlanResponse): number => a.sortingIndex - b.sortingIndex,
    servicePlans,
  )
    .map(
      (servicePlan: ServicePlanResponse): PricePlansListItem =>
        getPricePlansListItem(
          servicePlan,
          servicePlansPrices,
          subscriptionPlans,
          periodUnit,
          userCurrency,
          activeServicePlans,
          activeSubscriptions,
        ),
    )
    .filter((pricePlansListItem: PricePlansListItem) =>
      Boolean(pricePlansListItem && pricePlansListItem.chargeBeeInstance && pricePlansListItem.price),
    );
}

export function getPricePlansListItem(
  servicePlan: ServicePlanResponse,
  servicePlansPrices: ServicePlanPrice[],
  subscriptionPlans: SanitySubscriptionPlan[],
  periodUnit: TimePeriodUnit,
  userCurrency: string,
  activeServicePlans: ServicePlanResponse[],
  activeSubscriptions: SubscriptionResponse[],
): PricePlansListItem {
  const subscriptionPlan: SanitySubscriptionPlan = subscriptionPlans.find(
    (loopSubscriptionPlan: SanitySubscriptionPlan): boolean =>
      loopSubscriptionPlan.uniqueName === servicePlan.uniqueName,
  );

  if (!subscriptionPlan) {
    return null;
  }

  return {
    ...subscriptionPlan,
    chargeBeeInstance: servicePlan,
    price: getPricePlanListItemPrice(servicePlan, servicePlansPrices, periodUnit, userCurrency),
    isActive: isServicePlanActive(servicePlan, activeSubscriptions, periodUnit),
    canBePurchased: canServicePlanBePurchased(servicePlan, activeServicePlans),
    periodUnit,
  };
}

export function getPricePlanListItemPrice(
  servicePlan: ServicePlanResponse,
  servicePlansPrices: ServicePlanPrice[],
  activePeriodUnit: TimePeriodUnit,
  userCurrency: string,
) {
  let price;

  for (const loopCurrency of [userCurrency, DEFAULT_SERVICE_PLAN_PRICE_CURRENCY]) {
    if (price) {
      break;
    }

    price = servicePlansPrices.find(
      planPrice =>
        planPrice.servicePlanUniqueName === servicePlan.uniqueName &&
        planPrice.periodUnit === activePeriodUnit &&
        planPrice.currency === loopCurrency,
    );
  }

  return validateNonSupportedCurrency(price);
}

function validateNonSupportedCurrency(pricePlan: ServicePlanPrice): ServicePlanPrice {
  if (!pricePlan) {
    return null;
  }

  return {
    ...pricePlan,
    price:
      pricePlan && NON_SUPPORTED_CHARGEBEE_CURRENCIES.includes(pricePlan?.currency)
        ? pricePlan?.price * MULTIPLY_VALUE
        : pricePlan?.price,
  };
}

export function getActiveServicePlanIdsForActivePeriod(
  activeSubscriptions: SubscriptionResponse[],
  activePeriodUnit: TimePeriodUnit,
): string[] {
  const activePeriodSubscriptions: SubscriptionResponse[] = (activeSubscriptions || []).filter(
    (loopSubscription: SubscriptionResponse) => loopSubscription.billingPeriodUnit === activePeriodUnit,
  );

  return getServicePlanIdsFromSubscriptions(activePeriodSubscriptions);
}

export function isServicePlanActive(
  servicePlan: ServicePlanResponse,
  activeSubscriptions: SubscriptionResponse[],
  activePeriodUnit: TimePeriodUnit,
): boolean {
  return getActiveServicePlanIdsForActivePeriod(activeSubscriptions, activePeriodUnit).includes(servicePlan.id);
}

export function canServicePlanBePurchased(
  servicePlan: ServicePlanResponse,
  activeServicePlans: ServicePlanResponse[],
): boolean {
  const matchingActiveServicePlans: ServicePlanResponse[] = activeServicePlans.filter(
    (loopActiveServicePlan: ServicePlanResponse): boolean =>
      loopActiveServicePlan.serviceUniqueName === servicePlan.serviceUniqueName,
  );

  if (matchingActiveServicePlans.length) {
    const sortedMatchingActiveServicePlans = R.sort(
      (a: ServicePlanResponse, b: ServicePlanResponse) => b.sortingIndex - a.sortingIndex,
      matchingActiveServicePlans,
    );

    return servicePlan.sortingIndex > sortedMatchingActiveServicePlans[0].sortingIndex;
  }

  return true;
}

export function getPricePlanByPeriod(
  pricePlansListContent: PricePlansListContent,
  userCurrency: string,
  period: TimePeriodUnit,
  servicePlanUniqueName: ServicePlanUniqueNames,
): ServicePlanPrice {
  const pricePlanByUserCurrency = pricePlansListContent[period].find(
    priceListItem =>
      priceListItem.uniqueName === servicePlanUniqueName &&
      priceListItem.price.price > 0 &&
      priceListItem.price.currency === userCurrency,
  )?.price;
  const pricePlanByDefaultCurrency = pricePlansListContent[period].find(
    priceListItem =>
      priceListItem.uniqueName === servicePlanUniqueName &&
      priceListItem.price.price > 0 &&
      priceListItem.price.currency === DEFAULT_CURRENCY,
  )?.price;

  return pricePlanByUserCurrency || pricePlanByDefaultCurrency;
}

export function applyPlusSubscriptionCurrencyToFreeSubscription(
  pricePlansByPeriod: PricePlansListItem[],
): PricePlansListItem[] {
  if (!pricePlansByPeriod) {
    return null;
  }
  const plusPricePlan = pricePlansByPeriod.find(pricePlanByPeriod =>
    [ServicePlanUniqueNames.gelato_platform_plus, ServicePlanUniqueNames.gelato_platform_plus_legacy].includes(
      pricePlanByPeriod.uniqueName as ServicePlanUniqueNames,
    ),
  );
  return pricePlansByPeriod.map(pricePlanByPeriod => {
    const isFreePlan = [
      ServicePlanUniqueNames.gelato_platform_free,
      ServicePlanUniqueNames.gelato_platform_free_legacy,
    ].includes(pricePlanByPeriod.uniqueName as ServicePlanUniqueNames);

    return {
      ...pricePlanByPeriod,
      price: {
        ...pricePlanByPeriod.price,
        currency: isFreePlan ? plusPricePlan?.price?.currency || DEFAULT_CURRENCY : plusPricePlan?.price?.currency,
      },
    };
  });
}
