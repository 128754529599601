import * as queryString from 'qs';
import { AjaxRequest } from 'rxjs/ajax';
import { strictUriEncode } from '@gelato-api-ui/utils/strict-uri-encode';
import { ApiResponseType } from '@gelato-api-ui/core/api/api-response-type.enum';
import { HttpHeaders } from '@gelato-api-ui/core/api/http-headers';

export function encoder(value: any) {
  return typeof value === 'boolean' ? strictUriEncode(+value) : strictUriEncode(value);
}

export function getCallerConfig(
  host: string,
  url: string,
  method: string,
  responseType: ApiResponseType,
  options: AjaxRequest = {},
  defaultHeaders: HttpHeaders = {},
): AjaxRequest {
  const { headers: customHeaders, body } = options;

  let requestQuery = '';
  let requestBody = {};
  let requestHeaders = {};

  if (method === 'GET' && !!body) {
    const prefix = url.indexOf('?') > -1 ? '&' : '?';
    const requestString = queryString.stringify(body, { encode: false });
    requestQuery = `${prefix}${requestString}`;
  }

  if (['POST', 'PUT', 'PATCH'].indexOf(method) !== -1) {
    requestBody = body ? JSON.stringify(body) : {};
    requestHeaders = { 'Content-Type': 'application/json' };
  } else {
    requestBody = body ? queryString.stringify(body, { encode: true, encoder }) : {};
  }

  if (customHeaders && customHeaders['Content-Type'] === 'application/json') {
    requestBody = body ? JSON.stringify(body) : {};
  }

  const config = {
    url: `${host || ''}${url}${requestQuery}`,
    method,
    ...options,
    ...{
      body: requestBody,
    },
    headers: {
      ...defaultHeaders,
      ...(customHeaders ? customHeaders : {}),
      ...requestHeaders,
    },
  };

  if (responseType) {
    config.responseType = responseType;
  }

  return config;
}
