import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gd-empty-state-description',
  templateUrl: './empty-state-description.component.html',
  styleUrls: ['./empty-state-description.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyStateDescriptionComponent implements OnInit {
  @Input()
  isLoading = false;

  @Input()
  canCreate = false;

  @Input()
  creationRouterLink = null;

  @Input()
  descriptionText: string = null;

  @Input()
  creationButtonText: string = null;

  constructor() {}

  ngOnInit() {}
}
