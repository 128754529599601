import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { State, StoresListState, StoreState } from './e-commerce-stores.reducer';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { EStoreSearchRequest } from '@gelato-api-ui/core/e-commerce/e-store-search-request';
import { EStoreSearchResponse } from '@gelato-api-ui/core/e-commerce/e-store-search-response';
import { getCollections, getSelectedOrderId, getSelectedProductId, getTags } from './e-commerce-products.selector';
import { OrderApprovalType } from '@gelato-api-ui/core/e-commerce/order-approval-type.enum';
import { manualStoreTypes } from '@gelato-api-ui/core/e-commerce/constants/manual-store-types';
import { internalStoreTypes } from '@gelato-api-ui/core/e-commerce/constants/internal-store-types';

export const getState = (state: AppState): State => state.eCommerceStores;

export const getStoresListState = createSelector(getState, (state: State): StoresListState => state.storesList);

export const getStoresListLoadingFlag = createSelector(
  getStoresListState,
  (state: StoresListState): boolean => state.isLoading,
);

export const getStoresListRequest = createSelector(
  getStoresListState,
  (storesListState: StoresListState): EStoreSearchRequest => storesListState.request,
);

export const getStoresListResponse = createSelector(
  getStoresListState,
  (storesListState: StoresListState): EStoreSearchResponse => storesListState.payload,
);

export const getStoresList = createSelector(getStoresListResponse, (response: EStoreSearchResponse): EStore[] => {
  return !response || !response.stores ? [] : response.stores;
});

export const getExternalStoresList = createSelector(getStoresList, (stores: EStore[]): EStore[] =>
  (stores || []).filter((loopStore: EStore): boolean => !internalStoreTypes.includes(loopStore.type)),
);

export const getStoresCount = createSelector(getStoresList, (stores: EStore[]): number => (stores ? stores.length : 0));

export const getExternalStoresCount = createSelector(getExternalStoresList, (stores: EStore[]): number =>
  stores ? stores.length : 0,
);

export const getHasStores = createSelector(getStoresCount, (storesCount: number): boolean => storesCount > 0);

export const getShopifyStoresList = createSelector(getStoresList, (eStores: EStore[]): EStore[] => {
  if (!eStores && !eStores?.length) {
    return [];
  }

  return eStores.filter(
    eStore => eStore.type === EStoreType.SHOPIFY && eStore.orderApproval === OrderApprovalType.MANUAL,
  );
});

export const getCanLoadMoreStores = createSelector(
  getStoresListRequest,
  getStoresCount,
  (request: EStoreSearchRequest, storesCount: number): boolean => {
    if (!storesCount || !request?.limit) {
      return false;
    }

    return storesCount % request?.limit === 0;
  },
);

export const getStoreState = createSelector(getState, (state: State): StoreState => state.store);

export const getStore = createSelector(getStoreState, (storeState: StoreState): EStore => storeState.payload);

export const getStoreName = createSelector(getStore, (store: EStore): string => (store ? store.name : null));

export const getStoreCurrency = createSelector(getStore, (store: EStore): string => (store ? store.currency : null));

export const getStoreType = createSelector(getStore, (store: EStore): EStoreType => (store ? store.type : null));

export const getIsManualStoreType = createSelector(getStoreType, (storeType: EStoreType): boolean =>
  manualStoreTypes.includes(storeType),
);

export const getIsInternalStoreType = createSelector(getStoreType, (storeType: EStoreType): boolean =>
  internalStoreTypes.includes(storeType),
);

export const getSelectedStoreId = createSelector(getState, (state: State): string => state.selectedStoreId);

export const canAddExistingProduct = createSelector(
  getStoresCount,
  getExternalStoresCount,
  getIsInternalStoreType,
  (storesCount: number, notManualStoresCount: number, isInternalStoreTypeFlag: boolean): boolean =>
    storesCount > 1 && notManualStoresCount > 0 && !isInternalStoreTypeFlag,
);

export const getProductVariantConnectionBackRoute = createSelector(
  getSelectedStoreId,
  getSelectedProductId,
  getSelectedOrderId,
  (storeId: string, productId: string, orderId: string) => {
    if (orderId) {
      return ['/checkout/', orderId];
    } else if (productId) {
      return [`/stores/${storeId}/products/edit/${productId}`];
    } else {
      return [`/stores/${storeId}/products`];
    }
  },
);

export const getSelectedStoreTags = createSelector(getSelectedStoreId, getTags, (storeId, tags) =>
  storeId && tags ? tags[storeId] : [],
);

export const getSelectedStoreCollections = createSelector(getSelectedStoreId, getCollections, (storeId, collections) =>
  storeId && collections ? collections[storeId] : [],
);
