<div class="header">
  <div class="header-content">
    <div class="header-title">
      {{ 'txt_new_order' | translate }}
    </div>
  </div>
</div>

<div class="content">
  <gc-full-screen-spinner *ngIf="productsTreeLoading$ | async"></gc-full-screen-spinner>

  <ng-container *ngIf="!(productsTreeLoading$ | async) && !(productsTree$ | async)?.length">
    <div class="empty-state-wrapper">
      <p>
        {{ 'txt_store_products_list_modal_empty_state_description' | translate }}
      </p>
    </div>
  </ng-container>

  <div *ngIf="(productsTree$ | async)?.length" class="product-list">
    <div *ngIf="!(productsTree$ | async)?.length" class="no-matching-results-wrapper">
      <p>
        {{ 'txt_store_products_list_modal_no_mathching_products' | translate }}
      </p>
    </div>

    <ng-container *ngIf="(productsTree$ | async)?.length">
      <gd-store-products-list
        [items]="productsTree$ | async"
        [storeType]="storeType$ | async"
        [isSelectVariants]="true"
        [multipleSelectionEnabled]="true"
        [checkedProductVariantIds]="checkedProductVariantIds$ | async"
        (checkedProductVariantIdsChange)="onCheckedProductVariantIdsChange($event)"
      >
      </gd-store-products-list>
    </ng-container>
  </div>
</div>

<div class="actions">
  <ng-container *ngIf="(productsTreeLoading$ | async) || (productsTree$ | async)?.length">
    <div class="actions-content">
      <p class="variants-count-label">
        <span>
          {{ footerLabelTextKey$ | async | translate: { count: checkedProductVariantsCount$ | async } }}
        </span>
      </p>
      <div class="action-buttons">
        <button class="ui secondary button" [class.disabled]="productsTreeLoading$ | async" (click)="cancel()">
          {{ 'txt_cancel' | translate }}
        </button>

        <button
          class="ui primary button disabled"
          [class.disabled]="!(checkedProductVariantsCount$ | async)"
          (click)="submit()"
        >
          {{ 'txt_choose' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</div>
