<div
  #origin
  [class.user-preferences-menu-desktop]="!isMobile"
  [class.user-preferences-menu-mobile]="isMobile"
  (click)="onOpenMenu()"
>
  {{ showLanguageOnly ? currentLocale.nameShort : currentLocale.nameShort + ' / ' + currentCurrency }}
</div>

<ng-template #backButton>
  <div class="back-button-wrapper">
    <div class="menu-item" (click)="onOpenSwitcher(rootListContent, origin)">
      <img src="assets/icons/arrow-left-black.svg" width="32" height="32" />
      {{ 'txt_back' | translate }}
    </div>
  </div>
</ng-template>

<ng-template #rootListContent>
  <div class="menu-content" [class.mobile]="isMobile">
    <div *ngIf="isMobile" class="deck-header">
      <h3>
        <img src="assets/icons/globe-small.svg" width="32" height="32" />
        {{ 'txt_preferences' | translate }}
      </h3>
      <img (click)="onCloseSwitcher()" class="close" src="assets/checkout/close.svg" alt="" width="" height="" />
    </div>
    <div class="menu-items" [class.mobile]="isMobile">
      <div class="menu-item" data-item-name="language-switcher" (click)="onOpenSwitcher(langListContent, origin)">
        {{ 'txt_language_switcher' | translate }}
        <ng-container *ngIf="currentLocale">- {{ currentLocale.nameShort }}</ng-container>
      </div>
      <div class="menu-item" data-item-name="currency-switcher" (click)="onOpenSwitcher(currencyListContent, origin)">
        {{ 'txt_currency_switcher' | translate }}
        <ng-container *ngIf="currentCurrency">- {{ currentCurrency }}</ng-container>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #langListContent>
  <div class="menu-content" [class.mobile]="isMobile">
    <div *ngIf="isMobile" class="deck-header">
      <h3>
        <img src="assets/icons/text-bubble-black.svg" width="32" height="32" />
        {{ 'txt_choose_language' | translate }}
      </h3>
      <img (click)="onCloseSwitcher()" class="close" src="assets/checkout/close.svg" alt="" width="" height="" />
    </div>
    <div class="menu-items" [class.mobile]="isMobile">
      <div
        *ngFor="let lang of languagesList"
        class="menu-item"
        [attr.data-item-name]="lang.code"
        (click)="onChangeLocaleCode(lang.code)"
        [class.selected]="currentLocale.code === lang.code"
      >
        {{ lang.name }}
      </div>
    </div>
    <ng-container *ngIf="!showLanguageOnly">
      <ng-container *ngTemplateOutlet="backButton"></ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #currencyListContent>
  <div class="menu-content" [class.mobile]="isMobile">
    <div *ngIf="isMobile" class="deck-header">
      <h3>
        <img src="assets/icons/pricing.svg" width="32" height="32" />
        {{ 'txt_choose_display_currency' | translate }}
      </h3>
      <img (click)="onCloseSwitcher()" class="close" src="assets/checkout/close.svg" alt="" width="" height="" />
    </div>
    <div class="menu-items" [class.mobile]="isMobile">
      <div
        *ngFor="let currency of currenciesList"
        class="menu-item"
        [attr.data-item-name]="currency.code"
        (click)="onChangeCurrency(currency.code)"
        [class.selected]="currentCurrency === currency.code"
      >
        {{ currency.title }} - {{ currency.code }}
      </div>
    </div>
    <ng-container *ngTemplateOutlet="backButton"></ng-container>
  </div>
</ng-template>
