<gc-dialog-container>
  <div class="modal-body-container">
    <div class="close-icon" (click)="closeModal(false)">
      <img src="/assets/checkout/close.svg" alt="close icon" />
    </div>
    <div class="modal-banner"></div>
    <div class="modal-body">
      <h2 class="modal-title">
        {{ 'txt_activate_subscription' | translate }}
      </h2>

      <h3 class="modal-sub-title">
        {{ dialogData?.subtitle | translate }}
      </h3>

      <div class="inner-body">
        <div class="features">
          <div *ngFor="let feature of dialogData?.features" class="features-item">
            <img class="feature-icon" [src]="feature?.icon" />
            <div>
              <p class="feature-title">{{ feature.title | translate }}</p>
              <p class="feature-subtitle">{{ feature.subtitle | translate }}</p>
            </div>
          </div>
        </div>

        <div class="price-wrapper">
          <h3 class="modal-title">
            {{ 'txt_due_today' | translate }}
          </h3>

          <span *ngIf="pricePlan$ | async as pricePlan" class="price">
            {{ pricePlan?.price?.toString() | money: pricePlan?.currency:false:false }}
          </span>
        </div>
        <div class="price-info">
          {{ 'txt_you_will_be_charged_immediately' | translate }}
        </div>
      </div>

      <div class="control-buttons">
        <button class="ui primary button" (click)="closeModal()">
          {{ 'txt_cancel' | translate }}
        </button>
        <button class="ui primary button" (click)="activate()">
          {{ 'txt_activate_subscription' | translate }}
        </button>
      </div>
    </div>
  </div>
</gc-dialog-container>
