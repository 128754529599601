import { ProductTypeUid } from './product-type-uid.enum';

export const ApparelProductTypeUids = [
  ProductTypeUid.HOODIE,
  ProductTypeUid.ONESIE,
  ProductTypeUid.SWEATSHIRT,
  ProductTypeUid.T_SHIRT,
  ProductTypeUid.TANK_TOP,
];

export const WallArtProductTypeUids = [
  ProductTypeUid.ACRYLIC_PRINT,
  ProductTypeUid.ALUMINUM_PRINT,
  ProductTypeUid.CANVAS,
  ProductTypeUid.FOAM_PRINT,
  ProductTypeUid.FRAME,
  ProductTypeUid.FRAMED_POSTER,
  ProductTypeUid.HANGER,
  ProductTypeUid.POSTER,
  ProductTypeUid.POSTER_WITH_HANGER,
  ProductTypeUid.WOOD_PRINT,
];

export const BagProductTypeUids = [ProductTypeUid.TOTE_BAG];

export const ApparelAndAccessoriesProductTypeUids = [...ApparelProductTypeUids, ...BagProductTypeUids];

export const CardProductTypeUids = [ProductTypeUid.CARDS, ProductTypeUid.PACK_OF_CARDS];
