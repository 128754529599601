import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { Client } from '../client';
import { ShippingPriceType } from '../shipping-price-type.enum';
import { ClientSearchRequest } from '../client-search-request';
import { ClientSearchResponse } from '../client-search-response';
import { ClientCreateRequest } from '../client-create-request';
import { ClientUpdateRequest } from '../client-update-request';

@Injectable({
  providedIn: 'root',
})
export class ClientsApiService {
  constructor(private apiRequest: ApiRequestService) {}

  search(request: ClientSearchRequest = null): Observable<ClientSearchResponse> {
    return this.apiRequest.post<ClientSearchResponse>('/api/identity/v3/clients:search', { body: request }).pipe(
      map((data: any) => ({
        data: data.data,
        pagination: data.pagination,
      })),
    );
  }

  getAllClientsList(request: ClientSearchRequest = null): Observable<Client[]> {
    return this.search(request).pipe(
      map((response: ClientSearchResponse): Client[] => {
        if (!response || !response.data) {
          return [];
        }

        return response.data;
      }),
    );
  }

  getClient(clientId: string): Observable<Client> {
    if (!clientId) {
      return of(null);
    }

    return this.apiRequest.get<Client>(`/api/identity/v3/clients/${clientId}`).pipe(map(data => data.data));
  }

  createClient(request: ClientCreateRequest): Observable<Client> {
    return this.apiRequest.post<Client>('/api/identity/v3/clients', { body: request }).pipe(map(data => data.data));
  }

  updateClient(clientId: string, request: ClientUpdateRequest): Observable<Client> {
    return this.apiRequest
      .put<Client>(`/api/identity/v3/clients/${clientId}`, { body: request })
      .pipe(map(data => data.data));
  }

  saveClient(client: Client): Observable<Client> {
    const isFlatType = client.shippingPriceType === ShippingPriceType.FLAT;
    const shippingPriceEconomyCatalogUid: string = isFlatType ? client.shippingPriceEconomyCatalogUid : null;
    const shippingPriceExpressCatalogUid: string = isFlatType ? client.shippingPriceExpressCatalogUid : null;

    if (client.id) {
      const clientUpdateRequest: ClientUpdateRequest = new ClientUpdateRequest(
        client.name,
        client.description,
        client.type,
        client.preferredCurrency,
        client.billingType,
        client.shippingPriceType,
        shippingPriceEconomyCatalogUid,
        shippingPriceExpressCatalogUid,
        client.status,
        client.contactEmail,
        client.billingEmail,
        client.supportEmail,
      );

      return this.updateClient(client.id, clientUpdateRequest);
    }

    const clientCreateRequest: ClientCreateRequest = new ClientCreateRequest(
      client.name,
      client.description,
      client.type,
      client.preferredCurrency,
      client.billingType,
      client.shippingPriceType,
      shippingPriceEconomyCatalogUid,
      shippingPriceExpressCatalogUid,
      client.contactEmail,
      client.billingEmail,
      client.supportEmail,
    );

    return this.createClient(clientCreateRequest);
  }

  deleteClient(clientId: string): Observable<Client> {
    return this.apiRequest.delete<Client>(`/api/identity/v3/clients/${clientId}`).pipe(map(data => data.data));
  }
}
