import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { BillingEntityApiService } from '../billing-entities/services/billing-entity-api.service';
import { BillingEntity } from '../billing-entities/lib/billing-entity';
import { BillingEntitySearchResponse } from '../billing-entities/lib/billing-entity-search-response';
import * as actions from './billing-entities.actions';
import { getBillingEntityListState, getState } from './billing-entities.reducer';
import { findBillingEntityInState } from './helpers/findBillingEntityInState';
import { LoadClientsByIds } from '@api-ui-app/src/app/ngrx/client-selection-list.actions';

@Injectable()
export class BillingEntitiesEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private billingEntityApiService: BillingEntityApiService,
  ) {}

  loadBillingEntityListStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadBillingEntityListStart>(actions.BillingEntitiesActionTypes.LoadBillingEntityListStart),
        withLatestFrom(this.store$.select(getBillingEntityListState)),
        switchMap(([action, billingEntityListState]) => {
          const { searchRequest, forced } = action;
          const isDataRelevant =
            R.equals(searchRequest, billingEntityListState.request) && billingEntityListState.payload;

          let observable: Observable<BillingEntitySearchResponse> = this.billingEntityApiService.search(searchRequest);

          if (!forced && isDataRelevant) {
            observable = of({ ...billingEntityListState.payload });
          }

          this.store$.dispatch(
            new actions.SetBillingEntityListState({
              isLoading: true,
              request: searchRequest,
              payload: isDataRelevant ? billingEntityListState.payload : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<BillingEntitySearchResponse> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: BillingEntitySearchResponse) => {
              this.store$.dispatch(
                new actions.SetBillingEntityListState({
                  isLoading: false,
                  request: searchRequest,
                  payload: response,
                }),
              );

              if (response && response.data) {
                const ids = response.data.map(user => user.clientId);
                this.store$.dispatch(new LoadClientsByIds(ids));
              }
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadBillingEntityStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadBillingEntityStart>(actions.BillingEntitiesActionTypes.LoadBillingEntityStart),
        withLatestFrom(this.store$.select(getState)),
        switchMap(([action, state]) => {
          const { id, forced } = action;
          const userFromState: BillingEntity = findBillingEntityInState(state, id);
          const isDataRelevant = Boolean(userFromState);

          let observable: Observable<BillingEntity> = this.billingEntityApiService.get(id);

          if (!forced && isDataRelevant) {
            observable = of({ ...userFromState });
          }

          this.store$.dispatch(
            new actions.SetBillingEntityState({
              isLoading: true,
              payload: isDataRelevant ? userFromState : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<BillingEntity> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((billingEntity: BillingEntity) => {
              this.store$.dispatch(
                new actions.SetBillingEntityState({
                  isLoading: false,
                  payload: billingEntity,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
