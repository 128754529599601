import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CountrySelectorComponent } from '@gelato-api-ui/sdk/src/lib/shared/country-selector/country-selector.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryFlagModule } from '@gelato-api-ui/sdk/src/lib/shared/country-flag/country-flag.module';

@NgModule({
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule, TranslateModule, CountryFlagModule],
  declarations: [CountrySelectorComponent],
  exports: [CountrySelectorComponent],
})
export class CountrySelectorModule {}
