import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { OrderRefundSearchRequest } from '../lib/order-refund-search-request';
import { OrderRefundSearchResponse } from '../lib/order-refund-search-response';
import { OrderRefund } from '../lib/order-refund';
import { OrderRefundCreateRequest } from '../lib/order-refund-create-request';

@Injectable({
  providedIn: 'root',
})
export class RefundOrderApiService {
  constructor(private apiRequest: ApiRequestService) {}

  search(request: OrderRefundSearchRequest = null): Observable<OrderRefundSearchResponse> {
    return this.apiRequest
      .get<OrderRefundSearchResponse>('/api/order/v3/orders/*/refunds', {
        body: request,
      })
      .pipe(
        map((data: any) => ({
          data: data.data,
          pagination: data.pagination,
        })),
      );
  }

  create(request: OrderRefundCreateRequest): Observable<OrderRefund> {
    return this.apiRequest
      .post<OrderRefund>(`/api/order/v3/orders/${request.orderId}/refunds`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  delete(orderId: string, refundId: string): Observable<OrderRefund> {
    return this.apiRequest
      .delete<OrderRefund>(`/api/order/v3/orders/${orderId}/refunds/${refundId}`)
      .pipe(map(data => data.data));
  }
}
