import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { ServiceUniqueName } from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';
import { SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { distinctUntilChanged, filter, map, take, takeUntil, tap } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { PricePlansListItem } from '@api-ui-app/src/app/subscriptions/types/price-plans-list-item';
import * as R from 'ramda';
import { UntypedFormControl } from '@angular/forms';
import { GelatoSubscriptionModalType } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-subscription-modal';
import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';

@Component({
  selector: 'gd-price-plans-radio-group',
  templateUrl: './price-plans-radio-group.component.html',
  styleUrls: ['./price-plans-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePlansRadioGroupComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() modalRef: SuiModal<any, void, void>;
  @Input() selectedPricePlan: PricePlansListItem;
  @Input() gelatoSubscriptionModalType: GelatoSubscriptionModalType;

  @Output() selectPricePlan = new EventEmitter<PricePlansListItem>();

  control = new UntypedFormControl();
  selectedPeriod = TimePeriodUnit.YEAR;
  timePeriodUnitEnum = TimePeriodUnit;

  pricePlansItems$ = this.facade.pricePlansListContent$.pipe(
    map((pricePlansListContent): PricePlansListItem[] =>
      [...pricePlansListContent[TimePeriodUnit.MONTH], ...pricePlansListContent[TimePeriodUnit.YEAR]].filter(
        pricePlan => {
          const servicePlans =
            this.gelatoSubscriptionModalType === GelatoSubscriptionModalType.GELATO_PLUS
              ? [ServicePlanUniqueNames.gelato_platform_plus, ServicePlanUniqueNames.gelato_platform_plus_legacy]
              : [ServicePlanUniqueNames.gelato_platform_gold];
          return servicePlans.includes(pricePlan.uniqueName as ServicePlanUniqueNames);
        },
      ),
    ),
  );

  subscriptionPlanItems$ = this.pricePlansItems$.pipe(
    map(items =>
      R.sort((a: PricePlansListItem, b: PricePlansListItem): number => b.price.price - a.price.price, items),
    ),
  );
  gelatoPlusGoldYearPrice$ = this.subscriptionPlanItems$.pipe(
    map(subscriptionPlanItems => {
      const periodPricePlan = [...(subscriptionPlanItems || [])].find(
        planItem => planItem.periodUnit === TimePeriodUnit.YEAR,
      );

      return periodPricePlan?.price?.price || null;
    }),
  );

  gelatoPlusGoldMonthlyPrice$ = this.subscriptionPlanItems$.pipe(
    map(subscriptionPlanItems => {
      const periodPricePlan = (subscriptionPlanItems || []).find(
        planItem => planItem.periodUnit === TimePeriodUnit.MONTH,
      );

      return periodPricePlan?.price?.price || null;
    }),
  );

  yearlyDiscountPrice$ = this.subscriptionPlanItems$.pipe(
    map(subscriptionPlanItems => {
      const monthlyPricePlan = [...(subscriptionPlanItems || [])].find(
        planItem => planItem.periodUnit === TimePeriodUnit.MONTH,
      );
      const yearlyPricePlan = [...(subscriptionPlanItems || [])].find(
        planItem => planItem.periodUnit === TimePeriodUnit.YEAR,
      );
      const monthPrice = monthlyPricePlan?.price?.price || null;
      const yearPrice = yearlyPricePlan?.price?.price || null;

      return monthPrice && yearPrice ? String(monthPrice * 12 - yearPrice) : null;
    }),
  );

  gelatoPlusSubscriptionsStatuses$ = this.facade.gelatoPlusSubscriptionsStatuses$;

  ngOnDestroy$ = new Subject();

  get isGelatoPlusGoldModal() {
    return this.gelatoSubscriptionModalType === GelatoSubscriptionModalType.GELATO_GOLD;
  }

  constructor(private readonly facade: SubscriptionsFacade) {}

  ngOnInit(): void {
    this.facade.loadSubscriptionPlans(ServiceUniqueName.GELATO_PLATFORM);

    this.facade.servicePlans$
      .pipe(
        takeUntil(this.ngOnDestroy$),
        filter(plans => !!plans && !!plans.length),
        distinctUntilChanged(),
        map(plans => plans.map(plan => plan.uniqueName)),
        tap(plans => this.facade.loadSubscriptionPlansListFromSanity(plans)),
      )
      .subscribe();
  }

  ngAfterViewInit() {
    this.subscriptionPlanItems$
      .pipe(
        takeUntil(this.ngOnDestroy$),
        filter(plans => !!plans && !!plans.length),
        take(1),
        tap(plans => {
          this.onSelectPricePlan(plans[0]);
        }),
      )
      .subscribe();
  }

  public onSelectPricePlan(pricePlan: PricePlansListItem): void {
    this.selectPricePlan.emit(pricePlan);
  }

  getYearlyPriceWithoutDiscount$(subscriptionModalType: GelatoSubscriptionModalType): Observable<string> {
    return this.subscriptionPlanItems$.pipe(
      map(subscriptionPlanItems => {
        const pricePlan = [...(subscriptionPlanItems || [])].find(
          planItem => planItem.periodUnit === TimePeriodUnit.MONTH,
        );
        const price = pricePlan?.price?.price || null;

        return price;
      }),
    );
  }

  isGoldMonthPrice(subscriptionPlan: PricePlansListItem): boolean {
    return (
      subscriptionPlan?.price?.periodUnit === TimePeriodUnit.MONTH &&
      subscriptionPlan?.price?.servicePlanUniqueName === ServicePlanUniqueNames.gelato_platform_gold
    );
  }

  getPriceWithoutDiscount$(forYear: boolean): Observable<string> {
    const period = forYear ? TimePeriodUnit.YEAR : TimePeriodUnit.MONTH;

    return this.subscriptionPlanItems$.pipe(
      map(subscriptionPlanItems => {
        const periodPricePlan = [...(subscriptionPlanItems || [])].find(planItem => planItem.periodUnit === period);
        const price = periodPricePlan?.price?.price || null;

        return forYear ? this.convertYearToMonthPrice(price) : price;
      }),
    );
  }

  convertYearToMonthPrice(price: number) {
    // 100 - to get correct rounding for 2 digits
    return Math.round((price * 100) / 12) / 100;
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }
}
