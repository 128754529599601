import * as R from 'ramda';
import { AccessGroup } from '../access-group';

const findAccessGroup = (accessGroupName: string, accessGroups: AccessGroup[]) => {
  let foundAccessGroup = null;

  accessGroups.forEach((accessGroup: AccessGroup) => {
    if (foundAccessGroup) {
      return;
    }

    if (accessGroup.name === accessGroupName) {
      foundAccessGroup = accessGroup;
      return;
    }

    if (accessGroup.childGroups && accessGroup.childGroups.length) {
      foundAccessGroup = findAccessGroup(accessGroupName, accessGroup.childGroups);
      return;
    }
  });

  return foundAccessGroup;
};

const isScopeIntersectionDetected = (accessScopes: string[], accessGroupScopes: string[]) =>
  R.intersection(accessScopes, accessGroupScopes).length > 0;

export const isAccessGroupGranted = (
  accessGroupName: string,
  accessScopes: string[],
  accessGroups: AccessGroup[],
): boolean => {
  if (!accessGroupName || !accessScopes || !accessScopes.length || !accessGroups || !accessGroups.length) {
    return false;
  }

  const foundAccessGroup = findAccessGroup(accessGroupName, accessGroups);

  if (!foundAccessGroup) {
    return false;
  }

  if (foundAccessGroup.childGroups && foundAccessGroup.childGroups.length) {
    return R.reduce(
      (acc: boolean, childGroup: AccessGroup) =>
        acc || isScopeIntersectionDetected(accessScopes, childGroup.accessScopes),
      false,
      foundAccessGroup.childGroups,
    );
  }

  return isScopeIntersectionDetected(accessScopes, foundAccessGroup.accessScopes);
};
