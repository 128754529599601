import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { GelatoPlusFeatures } from '../subscriptions-shared/lib/gelato-plus-features';
import { SubscriptionType } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/subscription-types';
import { GelatoSubscriptionModalType } from '../subscriptions-shared/lib/gelato-subscription-modal';

@Component({
  selector: 'gc-gelato-plus-prompt',
  templateUrl: './gelato-plus-prompt.component.html',
  styleUrls: ['./gelato-plus-prompt.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GelatoPlusPromptComponent implements AfterViewInit {
  @Input() headerText: string;
  @Input() descriptionText: string;
  @Input() featureName: GelatoPlusFeatures;
  @Input() isExpandable: boolean;
  @Input() subscriptionType = SubscriptionType.plus;
  @Input() tooltipText: string;
  @Input() plusLabelText: string;
  @Input() showOnlyHeader: boolean;
  @Input() hideHeaderIcon?: boolean;
  @Input() buttonText?: string;
  @Input() set isExpandedByDefault(isExpanded: boolean) {
    this.isExpanded = isExpanded;
  }
  // for prompt in editor
  @Input() isMinimized = false;

  @HostBinding('class')
  get hostClassName() {
    return `${this.subscriptionType}`;
  }

  @Output() subscribeGelatoPlusButtonClick = new EventEmitter<{
    featureName: GelatoPlusFeatures;
    subscriptionModalType: GelatoSubscriptionModalType;
  }>();
  @Output() gelatoPlusLabelClick = new EventEmitter<void>();
  @Output() promptVisible = new EventEmitter<void>();

  isExpanded = false;
  subscriptionTypes = SubscriptionType;

  ngAfterViewInit(): void {
    this.promptVisible.emit();
  }

  onSubscribeButtonClick() {
    const subscriptionModalType =
      this.subscriptionType === SubscriptionType.plus
        ? GelatoSubscriptionModalType.GELATO_PLUS
        : GelatoSubscriptionModalType.GELATO_GOLD;
    this.subscribeGelatoPlusButtonClick.emit({
      featureName: this.featureName,
      subscriptionModalType,
    });
  }

  onGelatoPlusLabelClick() {
    this.gelatoPlusLabelClick.emit();
  }

  onToggleExpandClick() {
    this.isExpanded = !this.isExpanded;
  }
}
