import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import * as gql from 'gql-query-builder';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import { PreflightImageFromJsonResponse } from '@gelato-api-ui/core/preflight/preflight-image-from-json-response';
import { DesignProductPreviewUrlParams } from '@gelato-api-ui/core/preflight/types/design-product-preview-url-params';

@Injectable({ providedIn: 'root' })
export class PreflightGraphQlService {
  private readonly operation = 'preflight';

  constructor(private readonly graphQlRequestService: GraphQlRequestService) {}

  fetchProductPreviewTypes(productUid: string): Observable<string[]> {
    const fieldOperation = 'productPreviewTypes';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            productUid: { type: 'String!', value: productUid },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  fetchProductPreviewScenes(productUid: string): Observable<string[]> {
    const fieldOperation = 'productPreviewScenes';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            productUid: { type: 'String!', value: productUid },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  getImageFromJson(
    designStructure: DesignStructure,
    pageNr: number,
    width: number,
    height: number,
    format: PreviewFileType,
    removeSamples: boolean,
  ): Observable<PreflightImageFromJsonResponse> {
    const fieldOperation = 'imageFromJson';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            designStructure: { type: 'String!', value: JSON.stringify(designStructure) },
            pageNr: { type: 'Int!', value: pageNr },
            width: { type: 'Int!', value: width },
            height: { type: 'Int!', value: height },
            format: { type: 'String!', value: format },
            removeSamples: { type: 'Boolean!', value: removeSamples },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService.query(query, `${this.operation}:${fieldOperation}`).pipe(
      map(
        (data: ApolloQueryResult<any>): PreflightImageFromJsonResponse => ({
          url: data?.data?.[this.operation]?.[fieldOperation],
        }),
      ),
    );
  }

  splitPdf(pdfUrl: string): Observable<string[]> {
    const fieldOperation = 'splitPdf';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            pdfUrl: { type: 'String!', value: pdfUrl },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`)
      .pipe(map((response: ApolloQueryResult<any>): string[] => response?.data?.[this.operation]?.[fieldOperation]));
  }

  fetchDesignProductPreviewUrlParams(
    productUid: string,
    designStructure: DesignStructure,
    designPreviewFileType: PreviewFileType,
    width: number,
    height: number,
    removeSamples: boolean,
  ): Observable<DesignProductPreviewUrlParams> {
    const fieldOperation = 'designProductPreviewUrlParams';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            productUid: { type: 'String!', value: productUid },
            designStructure: { type: 'JSONObject!', value: designStructure },
            designPreviewFileType: { type: 'String!', value: designPreviewFileType },
            width: { type: 'Int!', value: width },
            height: { type: 'Int!', value: height },
            removeSamples: { type: 'Boolean!', value: removeSamples },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`)
      .pipe(
        map(
          (response: ApolloQueryResult<any>): DesignProductPreviewUrlParams =>
            response?.data?.[this.operation]?.[fieldOperation],
        ),
      );
  }
}
