import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';

const maxRetriesCount = 2;

export const callWithRetries = <T = any>(callback: () => Observable<T>, retry: number = 0): Observable<T> => {
  const lastRetry = retry >= maxRetriesCount;

  return callback().pipe(
    catchError(error => {
      if (lastRetry) {
        return throwError(error);
      }

      return of(null);
    }),
    switchMap((response): Observable<T> => {
      if (!response) {
        if (lastRetry) {
          return of(null);
        }

        return of(true).pipe(
          delay(1000),
          switchMap(() => callWithRetries(callback, ++retry)),
        );
      }

      return of(response);
    }),
  );
};
