import { PremiumIconCrownedFeatures } from '@api-ui-app/src/app/subscriptions/types/icon-crowned-features.enum';
import { DesignEditorPlugin } from '../design-editor-plugin.enum';

export const mapAppNameToIconCrownedFeatureName = (appName: DesignEditorPlugin): PremiumIconCrownedFeatures => {
  const map = {
    [DesignEditorPlugin.APP_GRAPHICS]: PremiumIconCrownedFeatures.ADDONS,
    [DesignEditorPlugin.APP_TEXT_EFFECTS]: PremiumIconCrownedFeatures.TEXT_EFFECTS,
    [DesignEditorPlugin.APP_GETTY_IMAGES]: PremiumIconCrownedFeatures.GETTY_IMAGES,
    [DesignEditorPlugin.APP_SHUTTERSTOCK]: PremiumIconCrownedFeatures.SHUTTERSTOCK,
    [DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION]: PremiumIconCrownedFeatures.COLLECTIONS,
    [DesignEditorPlugin.APP_IMAGE_FILTERS]: PremiumIconCrownedFeatures.IMAGE_FILTERS,
    [DesignEditorPlugin.APP_FONTS]: PremiumIconCrownedFeatures.PREMIUM_FONTS,
  };

  return map[appName];
};
