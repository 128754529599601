import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { map, tap } from 'rxjs/operators';
import { NotificationActionType, ShowNotification } from './notification.actions';

@Injectable()
export class NotificationEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService,
  ) {}

  notify$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ShowNotification>(NotificationActionType.ShowNotification),
        tap((action: ShowNotification) => {
          const duration = 5000;

          this.toastrService.show(
            action.payload.message,
            action.payload.title || null,
            {
              enableHtml: true,
              tapToDismiss: action.payload.tapToDismiss || true,
              closeButton: action.payload.enableClose,
              titleClass: action.payload.titleClass || '',
              timeOut: action.payload.timeout || duration,
              extendedTimeOut: action.payload.extendedTimeOut || duration,
              toastClass: `ui message ${action.payload.type || ''} ngx-toastr`,
              positionClass: action.payload.position || 'toast-top-right',
              messageClass: action.payload.messageClass || ' toast-message',
            },
            action.payload ? action.payload.type : 'success',
          );
        }),
      ),
    { dispatch: false },
  );

  showGeneralError$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShowNotification>(NotificationActionType.ShowGeneralErrorNotification),
      map(
        () =>
          new ShowNotification({
            message: this.translateService.instant('txt_general_error_message'),
            type: 'error',
          }),
      ),
    ),
  );
}
