import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WalletTransactionsState } from './wallet-transactions.adapter';
import { walletTransactionsFeatureName } from './wallet-transactions.feature';

const getWalletTransactionsState = createFeatureSelector<WalletTransactionsState>(walletTransactionsFeatureName);

const isFetchingComplete = createSelector(getWalletTransactionsState, state => state.isFetched === true);

const isFetchingStarted = createSelector(getWalletTransactionsState, state => state.isFetched === false);

const isFetchingToBeDone = createSelector(
  isFetchingComplete,
  isFetchingStarted,
  (isComplete, isStarted) => !isComplete && !isStarted,
);

const getAll = createSelector(getWalletTransactionsState, state => Object.values(state.entities));

const getFilter = createSelector(getWalletTransactionsState, state => state.filter);

const getTotal = createSelector(getFilter, state => state.total);

const getOffset = createSelector(getFilter, state => state.offset);

const getLimit = createSelector(getFilter, state => state.limit);

const getIds = createSelector(getWalletTransactionsState, state => Object.keys(state.entities));

export const query = {
  getAll,
  getIds,
  isFetchingComplete,
  isFetchingStarted,
  getFilter,
  getLimit,
  getOffset,
  getTotal,
  isFetchingToBeDone,
};
