import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as R from 'ramda';
import { CountryListService } from '@gelato-api-ui/sdk/src/lib/shared/country-selector/country-list.service';

@Component({
  selector: 'gd-country-multiselect',
  templateUrl: './country-multiselect.component.html',
  styleUrls: ['./country-multiselect.component.scss'],
})
export class CountryMultiselectComponent implements OnInit {
  @Input() disabled = false;
  @Input() values: string[];
  @Input() isSearchable = true;
  @Output() valuesChange: EventEmitter<string[]> = new EventEmitter();

  options$ = this.countryListService.getOptions();

  constructor(private countryListService: CountryListService) {}

  ngOnInit() {}

  optionsFilter(options, query) {
    return R.filter((option: any) => R.startsWith(R.toLower(query), R.toLower(option.name)), options);
  }

  onSelect(countryId) {
    this.valuesChange.emit(countryId);
  }

  preventFormSubmit(event) {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  }
}
