import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DialogRef } from '@gelato-api-ui/sdk/src/lib/shared/dialog/dialog-ref';
import { DIALOG_DATA } from '@gelato-api-ui/sdk/src/lib/shared/dialog/dialog-tokens';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { combineLatest } from 'rxjs';
import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';
import { map } from 'rxjs/operators';
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';
import { AppsListFacade } from '@api-ui-app/src/app/app-store/services/apps-list.facade';
import { SubscriptionPlanType } from '@api-ui-app/src/app/app-store/types/price-plan-items';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';

interface SubscriptionFeature {
  title: string;
  subtitle: string;
  icon: string;
}

interface ActivationDialogData {
  subtitle: string;
  features: SubscriptionFeature[];
  modalType: ServicePlanUniqueNames;
  subscriptionType: SubscriptionPlanType;
}

@Component({
  selector: 'gd-base-activation-modal',
  templateUrl: './base-activation-modal.component.html',
  styleUrls: ['./base-activation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseActivationModalComponent {
  pricePlan$ = combineLatest([
    this.subscriptionsFacade.pricePlansListContent$,
    this.subscriptionsFacade.getGelatoPlusTrialSubscriptionData$,
  ]).pipe(
    map(([plans, gelatoPlusTrialSubscriptionData]) => {
      const billingPeriodUnit =
        this.dialogData.modalType === ServicePlanUniqueNames.gelato_platform_plus
          ? gelatoPlusTrialSubscriptionData.billingPeriodUnit
          : TimePeriodUnit.MONTH;

      return plans?.[billingPeriodUnit]?.find(
        plan => plan.uniqueName !== ServicePlanUniqueNames.gelato_platform_free && plan.isActive,
      )?.price;
    }),
  );
  constructor(
    @Inject(DialogRef) private dialogReg: DialogRef<boolean>,
    @Inject(DIALOG_DATA) public dialogData: ActivationDialogData,
    private subscriptionsFacade: SubscriptionsFacade,
    private readonly facade: AppsListFacade,
  ) {}

  closeModal(result?: boolean): void {
    this.dialogReg.close(result);
  }

  activate(): void {
    const { subscriptionType } = this.dialogData;

    this.facade.activateSubscription(subscriptionType);

    if (subscriptionType === SubscriptionPlanType.PLUS) {
      logEvent('ActivateSubscriptionClick');
    }

    if (subscriptionType === SubscriptionPlanType.GOLD) {
      logEvent('ActivateGoldSubscriptionClick');
    }
    this.closeModal(true);
  }
}
