import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { dialogContainerAnimation } from './dialog-animation';

@Component({
  selector: 'gc-dialog-container',
  template: `<ng-content></ng-content>`,
  styleUrls: ['./dialog-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [dialogContainerAnimation],
})
export class DialogContainerComponent {
  @HostBinding('@dialogContainer') get fadeInDesktop(): string {
    return 'enter';
  }
}
