import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { query } from './splash-message.selector';
import { add, hide } from './splash-message.actions';
import { SplashMessageState } from './splash-message.adapter';
import { SplashMessage } from '../../lib/splash-message';

@Injectable()
export class SplashMessageFacade {
  all$ = this.store$.select(query.getAll);

  constructor(private readonly store$: Store<SplashMessageState>) {}

  public triggerHide(id: string) {
    this.store$.dispatch(hide({ id }));
  }

  public triggerAdd(message: SplashMessage) {
    this.store$.dispatch(add({ message }));
  }

  public byId(id: string) {
    return this.store$.select(query.byId(id));
  }
}
