import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { ResaleCertificate } from '../resale-certificates/lib/resale-certificate';
import { ResaleCertificateSearchRequest } from '../resale-certificates/lib/resale-certificate-search-request';
import { ResaleCertificateSearchResponse } from '../resale-certificates/lib/resale-certificate-search-response';
import * as actions from './resale-certificates.actions';

export const getState = (state: AppState): State => state.resaleCertificates;

export const getListState = createSelector(getState, (state: State): ListState => state.list);

export const getListRequest = createSelector(
  getListState,
  (keysListState: ListState): ResaleCertificateSearchRequest => keysListState.request,
);

export const getListResponse = createSelector(
  getListState,
  (keysListState: ListState): ResaleCertificateSearchResponse => keysListState.payload,
);

export const getList = createSelector(
  getListResponse,
  (response: ResaleCertificateSearchResponse): ResaleCertificate[] => {
    if (!response || !response.resaleCertificates) {
      return [];
    }

    return response.resaleCertificates;
  },
);

export const getEntityState = createSelector(getState, (state: State): EntityState => state.entity);

export const getEntity = createSelector(
  getEntityState,
  (entityState: EntityState): ResaleCertificate => entityState.payload,
);

export interface ListState {
  isLoading: boolean;
  request: ResaleCertificateSearchRequest;
  payload: ResaleCertificateSearchResponse;
}

export interface EntityState {
  isLoading: boolean;
  payload: ResaleCertificate;
}

export interface State {
  list: ListState;
  entity: EntityState;
}

export const initialState: State = {
  list: {
    isLoading: false,
    request: null,
    payload: null,
  },
  entity: {
    isLoading: false,
    payload: null,
  },
};

export function reducer(state = initialState, action: actions.ResaleCertificatesActions): State {
  switch (action.type) {
    case actions.ResaleCertificatesActionTypes.ResetListState:
      return {
        ...state,
        list: { ...initialState.list },
      };
    case actions.ResaleCertificatesActionTypes.ResetListPayload:
      return {
        ...state,
        list: {
          ...state.list,
          payload: null,
        },
      };
    case actions.ResaleCertificatesActionTypes.SetListState:
      return {
        ...state,
        list: action.payload,
      };
    case actions.ResaleCertificatesActionTypes.ResetEntityState:
      return {
        ...state,
        entity: { ...initialState.entity },
      };
    case actions.ResaleCertificatesActionTypes.SetEntityState:
      return {
        ...state,
        entity: action.payload,
      };
    default:
      return state;
  }
}
