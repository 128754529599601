import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import {
  getIsInternalStoreType,
  getSelectedStoreId,
  getStoreCurrency,
} from '@api-ui-app/src/app/ngrx/e-commerce-stores.selector';
import { EProductVariantConnectionSettingsModalService } from '@gelato-api-ui/sdk/src/lib/shared/services/e-product-variant-connection-settings-modal.service';
import { getInitialProductVariantConnectionData } from '@api-ui-app/src/app/ngrx/e-commerce-products.selector';
import { EProductVariantConnectionSettings } from '@gelato-api-ui/sdk/src/lib/shared/e-product-variant-connection-settings-modal/types/e-product-variant-connection-settings';
import { InitialProductVariantConnectionData } from '@gelato-api-ui/core/e-commerce/initial-product-variant-connection-data';
import { getSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { DefaultEProductVariantConnectionSettings } from '@gelato-api-ui/sdk/src/lib/shared/e-product-variant-connection-settings-modal/types/default-e-product-variant-connection-settings';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';

@Injectable()
export class EProductVariantConnectionSettingsModalFacade {
  clientId$ = this.store.select(getSelectedClientId);
  storeId$ = this.store.select(getSelectedStoreId);
  storeCurrency$ = this.store.select(getStoreCurrency);
  isInternalStoreType$ = this.store.select(getIsInternalStoreType);
  initialProductVariantConnectionData$ = this.store.select(getInitialProductVariantConnectionData);
  isGelatoUser$ = this.authService.isGelatoUser();

  constructor(
    private readonly store: Store<AppState>,
    private readonly service: EProductVariantConnectionSettingsModalService,
    private readonly authService: AuthService,
  ) {}

  async open(cost: number): Promise<EProductVariantConnectionSettings> {
    const clientId: string = await this.clientId$.pipe(first()).toPromise();
    const storeId: string = await this.storeId$.pipe(first()).toPromise();
    const storeCurrency: string = await this.storeCurrency$.pipe(first()).toPromise();
    const isInternalStoreType: boolean = await this.isInternalStoreType$.pipe(first()).toPromise();
    const isGelatoUser: boolean = await this.isGelatoUser$.pipe(first()).toPromise();
    const initialProductVariantConnectionData: InitialProductVariantConnectionData =
      await this.initialProductVariantConnectionData$.pipe(first()).toPromise();

    if (!initialProductVariantConnectionData?.productVariant?.id) {
      return DefaultEProductVariantConnectionSettings;
    }

    return this.service.open(
      clientId,
      storeId,
      isInternalStoreType,
      initialProductVariantConnectionData?.productVariant?.currency || storeCurrency,
      initialProductVariantConnectionData?.productVariant?.price,
      initialProductVariantConnectionData?.productVariant?.cost,
      cost,
      isGelatoUser,
    );
  }
}
