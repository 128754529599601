import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TooltipPositions } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.enum';
import { getColorBackground } from '@product-catalogue/src/lib/product-details-shared/lib/get-color-background';

@Component({
  selector: 'gc-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorComponent implements OnInit {
  @Input() selected: boolean;
  @Input() title: string;
  @Input() hex: string;
  @Input() texture: string;
  @Input() colorSelectedIcon: string;
  @Input() size = 32;
  @Input() hideTooltip = false;
  @Input() hideBorder = false;
  @Input() showOnlyTitle = false;
  @Output() select = new EventEmitter<void>();

  tooltipPosition = TooltipPositions.top;
  colorBackground = getColorBackground;
  constructor() {}

  ngOnInit() {}

  onSelect() {
    this.select.emit();
  }

  get tooltipText() {
    if (this.showOnlyTitle) {
      return this.title;
    }
    return this.title ? `${this.title}, ${this.hex}` : this.hex;
  }
}
