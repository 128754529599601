import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { Injectable, InjectionToken, Injector } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  InstallingGelatoPlusOverlayComponent,
  SubscriptionModalContentData,
  SubscriptionState,
} from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/installing-gelato-plus-overlay/installing-gelato-plus-overlay.component';

export const CONTAINER_DATA = new InjectionToken<{}>('CONTAINER_DATA');

@Injectable()
export class InstallingGelatoPlusService {
  private overlayRef: OverlayRef = null;
  constructor(private readonly overlay: Overlay, private readonly injector: Injector) {}

  open(subscriptionState: SubscriptionState) {
    this.overlayRef = this.overlay.create({ hasBackdrop: true });

    const filePreviewPortal = new ComponentPortal(
      InstallingGelatoPlusOverlayComponent,
      null,
      this.createInjector({
        data: subscriptionState,
      }),
    );

    this.overlayRef.attach(filePreviewPortal);
  }

  createInjector(dataToPass: SubscriptionModalContentData): Injector {
    return Injector.create({
      parent: this.injector,
      providers: [{ provide: CONTAINER_DATA, useValue: dataToPass }],
    });
  }

  close() {
    this.overlayRef.dispose();
    this.overlayRef.detach();
  }
}
