import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import * as R from 'ramda';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { getProductVariantThumbnailUrl } from '@gelato-api-ui/core/e-commerce/helpers/getProductVariantThumbnailUrl';

@Component({
  selector: 'gd-store-product-variants-list-item',
  templateUrl: './store-product-variants-list-item.component.html',
  styleUrls: ['./store-product-variants-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreProductVariantsListItemComponent {
  @Input() item: EProductVariant = null;
  @Input() isChecked = false;
  @Input() storeType: EStoreType;
  @Input() multipleSelectionEnabled: boolean;
  @Output() isCheckedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  get thumbnailUrl(): string {
    return getProductVariantThumbnailUrl(this.item, this.storeType);
  }

  toggleItem(isChecked: boolean) {
    if (R.isNil(isChecked)) {
      this.isCheckedChange.emit(!this.isChecked);
    } else {
      this.isCheckedChange.emit(isChecked);
    }
  }
}
