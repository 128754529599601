<div class="ui segment product-wrapper" [ngClass]="{ 'with-borders': withBorders }">
  <div *ngIf="product.icon" class="product-icon">
    <img src="{{ product.icon }}" />
  </div>

  <ng-container *ngIf="product.productImages?.length">
    <div class="product-preview-wrapper">
      <lib-product-badge *ngIf="product.productBadge" [productBadge]="product.productBadge"></lib-product-badge>
      <img src="{{ product.productImages[0] }}" />
    </div>
  </ng-container>

  <div class="product-text-wrapper">
    <div *ngIf="product.subtitle" class="product-subtitle">{{ product.subtitle }}</div>

    <div class="product-title">{{ product.title }}</div>

    <div *ngIf="product.descriptionShort" class="product-subtitle">{{ product.descriptionShort }}</div>

    <lib-mounted-framed-poster-available-label
      *ngIf="product.hasMountedFramedPosters"
    ></lib-mounted-framed-poster-available-label>
  </div>

  <gd-catalog-product-preview-variants
    *ngIf="productPreviewVariants"
    [product]="product"
  ></gd-catalog-product-preview-variants>
  <ng-container *ngIf="productPrice && currency">
    <div class="product-price">
      {{ 'txt_from' | translate }}
      <span class="price-label">{{ productPrice.toString() | money: currency }}</span>
      {{ 'txt_excl_vat' | translate }}
    </div>
  </ng-container>
</div>
