import * as R from 'ramda';
import { DesignStructure } from '../design-structure';
import { hasAppliedDesignTemplate } from './hasAppliedDesignTemplate';

export const validateDesignStructure = (designStructure: DesignStructure): DesignStructure => {
  if (!designStructure) {
    return designStructure;
  }

  return hasAppliedDesignTemplate(designStructure)
    ? designStructure
    : R.omit(['design_family_id', 'design_template_id'], designStructure);
};
