import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { intervalToDuration, parseISO } from 'date-fns';

@Component({
  selector: 'gd-discount-item-dynamic-time-label',
  templateUrl: './discount-item-dynamic-time-label.component.html',
  styleUrls: ['./discount-item-dynamic-time-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountItemDynamicTimeLabelComponent implements OnInit, OnDestroy {
  @Input()
  set expirationDate(expirationDate) {
    this._expirationDate = expirationDate;
    this.updateLabel();
    this.initTimer();
  }
  get expirationDate() {
    return this._expirationDate;
  }

  timeLeftLabel$ = new BehaviorSubject<string>(null);

  private _expirationDate;
  private timer;
  private timeout = 1000;

  constructor() {}

  ngOnInit() {}

  getTimeLeft(time: string): string {
    const start = new Date();
    const end = parseISO(time);

    const duration = intervalToDuration({
      start: end,
      end: start,
    });

    const { years, months, days, hours, minutes } = duration;

    const arr = [
      years > 0 ? `${years}y` : null,
      months > 0 ? `${months}M` : null,
      days > 0 ? `${days}d` : null,
      `${days === 0 ? '0' : hours}h`,
      `${days === 0 ? '0' : minutes}m`,
      'left',
    ];

    return arr.join(' ');
  }

  initTimer() {
    this.destroyTimer();

    if (this.expirationDate) {
      this.timer = setInterval(() => {
        this.updateLabel();
      }, this.timeout);
    }
  }

  destroyTimer() {
    clearInterval(this.timer);
  }

  updateLabel() {
    const timeLeftLabel = this.expirationDate ? this.getTimeLeft(this.expirationDate) : null;

    this.timeLeftLabel$.next(timeLeftLabel);
  }

  ngOnDestroy() {
    this.destroyTimer();
  }
}
