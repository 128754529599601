import * as R from 'ramda';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { getUsedAssets } from '@gelato-api-ui/core/designs/helpers/getUsedAssets';
import { getContentIdFromAsset } from './getContentIdFromAsset';
import { AssetProviderUid } from '@gelato-api-ui/core/designs/asset-provider-uid.enum';

export const getContentIdsFromDesignData = (
  assetsCollection: AssetsCollection,
  designData: DesignStructure,
  assetProviderUids: AssetProviderUid[],
): string[] => {
  if (!assetsCollection || !designData || !assetProviderUids) {
    return [];
  }

  const assets: Asset[] = getUsedAssets(assetsCollection, designData);

  let ids: string[] = R.map((loopAsset: Asset): string => {
    if (!assetProviderUids.includes(loopAsset.assetProviderUid)) {
      return null;
    }

    return getContentIdFromAsset(loopAsset);
  }, assets);

  ids = R.filter((loopId: string): boolean => Boolean(loopId), ids);
  ids = R.sort((a, b) => a.localeCompare(b), ids);

  return R.uniq(ids);
};
