import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { CommunicationSubscription } from '../lib/communication-subscription';
import { CommunicationSubscriptionSearchRequest } from '../lib/communication-subscription-search-request';
import { CommunicationSubscriptionSearchResponse } from '../lib/communication-subscription-search-response';
import { CommunicationSubscriptionTestRequest } from '../lib/communication-subscription-test-request';
import { CommunicationSubscriptionTestResponse } from '../lib/communication-subscription-test-response';

@Injectable({
  providedIn: 'root',
})
export class CommunicationSubscriptionsApiService {
  constructor(private apiRequest: ApiRequestService) {}

  search(request: CommunicationSubscriptionSearchRequest): Observable<CommunicationSubscriptionSearchResponse> {
    return this.apiRequest
      .post<CommunicationSubscriptionSearchResponse>('/api/v3/communication/subscriptions:search', { body: request })
      .pipe(
        map((data: any) => ({
          data: data.data,
          pagination: data.pagination,
        })),
      );
  }

  get(id: string): Observable<CommunicationSubscription> {
    if (!id) {
      return of(null);
    }

    return this.apiRequest
      .get<CommunicationSubscription>(`/api/v3/communication/subscriptions/${id}`)
      .pipe(map(data => data.data));
  }

  create(communicationSubscription: CommunicationSubscription): Observable<CommunicationSubscription> {
    return this.apiRequest
      .post<CommunicationSubscription>('/api/v3/communication/subscriptions', {
        body: communicationSubscription,
      })
      .pipe(map(data => data.data));
  }

  update(id: string, communicationSubscription: CommunicationSubscription): Observable<CommunicationSubscription> {
    return this.apiRequest
      .put<CommunicationSubscription>(`/api/v3/communication/subscriptions/${id}`, {
        body: communicationSubscription,
      })
      .pipe(map(data => data.data));
  }

  save(communicationSubscription: CommunicationSubscription): Observable<CommunicationSubscription> {
    if (communicationSubscription.id) {
      return this.update(communicationSubscription.id, communicationSubscription);
    }

    return this.create(communicationSubscription);
  }

  test(request: CommunicationSubscriptionTestRequest): Observable<CommunicationSubscriptionTestResponse> {
    return this.apiRequest
      .post<CommunicationSubscriptionTestResponse>(`/api/v3/communication/subscriptions:test`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  delete(id: string): Observable<CommunicationSubscription> {
    return this.apiRequest
      .delete<CommunicationSubscription>(`/api/v3/communication/subscriptions/${id}`)
      .pipe(map(data => data.data));
  }
}
