import { Injectable } from '@angular/core';

export const LOCAL_STORAGE_PRESERVE_QUERY_PARAMS_KEY = 'preserveQueryParams';

@Injectable({
  providedIn: 'root',
})
export class PreserveQueryParamsService {
  saveParams(params): void {
    if (params) {
      this.removeParams();
      localStorage.setItem(LOCAL_STORAGE_PRESERVE_QUERY_PARAMS_KEY, JSON.stringify(params));
    }
  }

  getParams(): object {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_PRESERVE_QUERY_PARAMS_KEY));
  }

  removeParams(): void {
    localStorage.removeItem(LOCAL_STORAGE_PRESERVE_QUERY_PARAMS_KEY);
  }
}
