import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gc-sync-progress-dimmer',
  templateUrl: './sync-progress-dimmer.component.html',
  styleUrls: ['./sync-progress-dimmer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SyncProgressDimmerComponent implements OnInit {
  @Input() percent: number;
  @Input() label: string;

  constructor() {}

  ngOnInit(): void {}
}
