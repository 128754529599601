<gd-breadcrumbs *ngIf="breadcrumbs?.length" [items]="breadcrumbs"></gd-breadcrumbs>

<div class="title-row" [class.mobile]="isMobile">
  <div class="title-row-content" [class.ghost-active-fluid]="isLoading">
    <ng-container *ngTemplateOutlet="useH1Tag ? h1Tmpl : h2Tmpl"></ng-container>
    <gd-refresh-button *ngIf="showRefreshButton" (click)="onRefreshClick()"></gd-refresh-button>
  </div>
  <div class="additional-content-wrapper">
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf="showDivider" class="ui divider"></div>

<ng-template #h1Tmpl>
  <h1 *ngIf="text">{{ text }}</h1>
  <h1 *ngIf="!text">&nbsp;</h1>
</ng-template>

<ng-template #h2Tmpl>
  <h2 *ngIf="text">{{ text }}</h2>
  <h2 *ngIf="!text">&nbsp;</h2>
</ng-template>
