import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashMessageComponent } from './splash-message/splash-message.component';
import { SplashMessageStateModule } from './+state/splash-message-state.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [SplashMessageComponent],
  imports: [CommonModule, SplashMessageStateModule, TranslateModule],
  exports: [SplashMessageComponent],
})
export class SplashMessageModule {}
