import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { TranslateService } from '@ngx-translate/core';
import { ShopifyIntegrationService } from '@api-ui-app/src/app/e-commerce-stores/services/shopify-integration.service';
import { EtsyIntegrationService } from '@api-ui-app/src/app/e-commerce-stores/services/etsy-integration.service';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { RefreshStoresList, ResetStoreState } from '@api-ui-app/src/app/ngrx/e-commerce-stores.actions';
import { RutterStoreReconnectionWindowService } from '@api-ui-app/src/app/e-commerce-stores/services/rutter-store-reconnection-window.service';
import { first } from 'rxjs/operators';
import { rutterStoreTypes } from '@gelato-api-ui/core/e-commerce/constants/rutter-store-types';
import { EStoreStatus } from '@gelato-api-ui/core/e-commerce/e-store-status.enum';
import { TranslatedStoreTypeService } from '@api-ui-app/src/app/e-commerce-stores/services/translated-store-type.service';

@Injectable()
export class ECommerceStoreReconnectionService {
  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly translateService: TranslateService,
    private readonly shopifyIntegrationService: ShopifyIntegrationService,
    private readonly etsyIntegrationService: EtsyIntegrationService,
    private readonly rutterStoreReconnectionWindowService: RutterStoreReconnectionWindowService,
    private readonly translatedStoreTypeService: TranslatedStoreTypeService,
  ) {}

  reconnectStore(eStore: EStore) {
    const storeType = eStore?.type;

    if (storeType === EStoreType.SHOPIFY) {
      this.reconnectShopifyStore();
    } else if (storeType === EStoreType.ETSY) {
      this.reconnectEtsyStore();
    } else if (rutterStoreTypes.includes(storeType)) {
      this.reconnectRutterStore(eStore);
    }
  }

  isReconnectionRequired(eStore: EStore): boolean {
    return eStore?.status === EStoreStatus.REQUIRES_RECONNECTION || this.isIntegrationUpgradeRequired(eStore);
  }

  canReconnectStore(eStore: EStore): boolean {
    return this.isReconnectionRequired(eStore) && this.isReconnectionActionSupported(eStore);
  }

  getNotificationTitle(storeType: EStoreType): string {
    return this.translateService.instant('txt_store_reconnection_notification_title', {
      storeName: this.translatedStoreTypeService.instant(storeType),
    });
  }

  getNotificationDescription(store: EStore): string {
    const storeType = store?.type;

    if (store?.status === EStoreStatus.REQUIRES_RECONNECTION) {
      const reconnectionReasonCode = store?.reconnectionReasonCode;
      const translationKey = reconnectionReasonCode
        ? `txt_store_reconnection_reason_${reconnectionReasonCode.toLowerCase()}`
        : 'txt_store_reconnection_notification_description_default';

      return this.translateService.instant(translationKey, {
        storeName: this.translatedStoreTypeService.instant(storeType),
      });
    }

    if (store?.integrationUpgradeRequired) {
      return this.translateService.instant('txt_update_etsy_store_connection_warning_message_description', {
        storeName: this.translatedStoreTypeService.instant(storeType),
        link: 'https://apisupport.gelato.com/hc/en-us/articles/8040404501522',
      });
    }

    return null;
  }

  private isIntegrationUpgradeRequired(eStore: EStore): boolean {
    return eStore?.type === EStoreType.ETSY && eStore?.integrationUpgradeRequired;
  }

  private isReconnectionActionSupported(eStore: EStore): boolean {
    return (
      [EStoreType.SHOPIFY, EStoreType.ETSY].includes(eStore?.type) ||
      (rutterStoreTypes.includes(eStore?.type) && Boolean(eStore?.storeReconnectURL))
    );
  }

  private reconnectShopifyStore() {
    this.shopifyIntegrationService.openAppPage();
  }

  private reconnectEtsyStore() {
    this.etsyIntegrationService.initiateConnection().pipe(first()).subscribe();
  }

  private reconnectRutterStore(eStore: EStore) {
    this.rutterStoreReconnectionWindowService.open(eStore.storeReconnectURL, () => {
      this.store.dispatch(new ResetStoreState());
      this.store.dispatch(new RefreshStoresList());
    });
  }
}
