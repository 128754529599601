import { ActionReducer, createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import * as actions from './clients.actions';
import { Client } from '@gelato-api-ui/core/clients/client';
import { ClientSearchRequest } from '@gelato-api-ui/core/clients/client-search-request';
import { ClientSearchResponse } from '@gelato-api-ui/core/clients/client-search-response';

export const getState = (state: AppState): State => state.clients;

export const getClientListState = createSelector(getState, (state: State): ClientListState => state.clientList);

export const getClientListResponse = createSelector(
  getClientListState,
  (clientListState: ClientListState): ClientSearchResponse => clientListState.payload,
);

export const getClientState = createSelector(getState, (state: State): ClientState => state.client);

export const getClient = createSelector(getClientState, (clientState: ClientState): Client => clientState.payload);

export interface State {
  clientList: ClientListState;
  client: ClientState;
}

export interface ClientListState {
  isLoading: boolean;
  request: ClientSearchRequest;
  payload: ClientSearchResponse;
}

export interface ClientState {
  isLoading: boolean;
  payload: Client;
}

export const getStateState = (state: AppState): State => state.clients;

export const initialState: State = {
  clientList: {
    isLoading: false,
    request: null,
    payload: null,
  },
  client: {
    isLoading: false,
    payload: null,
  },
};

export const reducer: ActionReducer<State> = (state = initialState, action: actions.ClientsActions): State => {
  switch (action.type) {
    case actions.ClientsActionTypes.ResetClientListState:
      return {
        ...state,
        clientList: { ...initialState.clientList },
      };
    case actions.ClientsActionTypes.ResetClientListPayload:
      return {
        ...state,
        clientList: {
          ...state.clientList,
          payload: null,
        },
      };
    case actions.ClientsActionTypes.ResetClientState:
      return {
        ...state,
        client: { ...initialState.client },
      };
    case actions.ClientsActionTypes.SetClientListState:
      return {
        ...state,
        clientList: action.payload,
      };
    case actions.ClientsActionTypes.SetClientState:
      return {
        ...state,
        client: action.payload,
      };
    default:
      return state;
  }
};
