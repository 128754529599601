import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import SwiperCore, {
  FreeMode,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Swiper,
  SwiperOptions,
  Thumbs,
  Zoom,
} from 'swiper';
import { Media } from '@gelato-api-ui/core/sanity/media';
import { BehaviorSubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ContentNavigationType } from '../lib/content-navigation-type';

SwiperCore.use([FreeMode, Navigation, Thumbs, Zoom, Mousewheel, Keyboard, Pagination]);

enum SwiperDirectionEnum {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

@Component({
  selector: 'gc-product-previews',
  templateUrl: './product-previews.component.html',
  styleUrls: ['./product-previews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPreviewsComponent implements OnChanges {
  @Input() media: Media[];
  @Input() isMobile = false;
  @Input() activeIndex = 0;
  @Input() allowFullscreen = true;
  @Input() wrapperHeight: number;
  @Input() showNavigation: boolean;
  @Input() contentNavigationType: ContentNavigationType;
  @Output() imageClick = new EventEmitter<number>();

  thumbnailsSwiperConfig: SwiperOptions = {
    freeMode: true,
    slidesPerView: 5,
    spaceBetween: 10,
    threshold: 10,
    mousewheel: true,
  };
  previewSwiperConfig: SwiperOptions = {
    threshold: 10,
    keyboard: true,
  };
  swiper$ = new BehaviorSubject<Swiper>(null);
  thumbsSwiper$ = new BehaviorSubject<Swiper>(null);
  activeIndex$ = new BehaviorSubject<number>(null);
  contentNavigationOptions: typeof ContentNavigationType = ContentNavigationType;

  get direction() {
    return this.isMobile ? SwiperDirectionEnum.HORIZONTAL : SwiperDirectionEnum.VERTICAL;
  }

  get navigation() {
    return Boolean(!this.isMobile);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeIndex) {
      this.swiper$.pipe(take(1), tap(this.changeSlide(changes.activeIndex))).subscribe();
    }

    if (changes.isMobile) {
      this.thumbsSwiper$.pipe(take(1), tap(this.mobileToDesktopChange)).subscribe();
    }
  }

  setSwiper(swiper: Swiper) {
    this.swiper$.next(swiper);
    this.onActiveIndexChange(swiper);
  }

  setThumbsSwiper(swiper: Swiper) {
    this.thumbsSwiper$.next(swiper);
  }

  onActiveIndexChange(swiper: Swiper) {
    this.activeIndex$.next(swiper.activeIndex);
  }

  mobileToDesktopChange(thumbSwiper) {
    if (thumbSwiper) {
      thumbSwiper.changeDirection(this.direction);
      if (this.isMobile) {
        thumbSwiper.navigation.destroy();
      } else {
        thumbSwiper.navigation.init();
      }
      thumbSwiper.updateSize();
    }
  }

  changeSlide({ currentValue }: SimpleChange) {
    return swiper => {
      if (swiper) {
        swiper.slideTo(currentValue);
      }
    };
  }

  onClick(imageIndex) {
    if (imageIndex >= 0) {
      this.imageClick.emit(imageIndex);
    }
  }

  onClickSwiperPreview(swiper: Swiper) {
    this.onClick(swiper.activeIndex);
  }
}
