import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { EProduct } from '@gelato-api-ui/core/e-commerce/e-product';
import { EProductImage } from '@gelato-api-ui/core/e-commerce/e-product-image';
import { EProductImageStatus } from '@gelato-api-ui/core/e-commerce/e-product-image-status.enum';
import { EProductData } from '@gelato-api-ui/core/e-commerce/e-product-data';
import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import {
  SelectedPreviewScenesCollection,
  SelectedProductVariantKeysCollection,
  StoreProductVariantsCollection,
} from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { key2SceneName } from '@gelato-api-ui/core/e-commerce/helpers/key2SceneName';
import { sceneName2Key } from '@gelato-api-ui/core/e-commerce/helpers/sceneName2Key';
import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';
import { updateMetadataItems } from '@gelato-api-ui/core/metadata/helpers/updateMetadataItems';
import { EProductMetadataKey } from '@gelato-api-ui/core/e-commerce/e-product-metadata-key.enum';
import { EProductImageMetadataKey } from '@gelato-api-ui/core/e-commerce/e-product-image-metadata-key.enum';
import { ECommerceProductVariantPreviewService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-variant-preview.service';
import { updateMetadataItem } from '@gelato-api-ui/core/metadata/helpers/updateMetadataItem';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { DesignPreviewFileTypeService } from '@gelato-api-ui/core/preflight/services/design-preview-file-type.service';
import { SharedProductVariantPreviewsService } from '@product-catalogue/src/lib/product-catalogue/services/shared-product-variant-previews.service';
import { ECommerceProductDetailsService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-details.service';
import { ECommerceProductPreviewSize } from '@api-ui-app/src/app/e-commerce-stores/types/e-commerce-product-preview-size';

export interface StoreProductVariantPreviewsCollection {
  [productVariantKey: string]: string;
}

export interface StoreProductPreviewsCollection {
  [scene: string]: string;
}

@Injectable({ providedIn: 'root' })
export class StoreProductVariantsService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly designPreviewFileTypeService: DesignPreviewFileTypeService,
    private readonly eCommerceProductVariantPreviewService: ECommerceProductVariantPreviewService,
    private readonly eCommerceProductDetailsService: ECommerceProductDetailsService,
    private readonly sharedProductVariantPreviewsService: SharedProductVariantPreviewsService,
  ) {}

  getProductPreviewUrl(
    productVariant: EProductVariant,
    previewScene: string,
    previewFileType: PreviewFileType,
    designPreviewFileType: PreviewFileType,
    previewSize: ECommerceProductPreviewSize,
  ): Observable<string> {
    return this.eCommerceProductVariantPreviewService
      .getProductPreviewUrl(productVariant, previewScene, previewFileType, designPreviewFileType, previewSize)
      .pipe(
        tap((productPreviewUrl: string) => {
          if (productPreviewUrl) {
            return;
          }

          Sentry.captureException(new Error('GetProductPreviewUrlEmptyResult'), scope => {
            scope.setExtras({
              productVariant,
              previewScene,
              previewFileType,
              designPreviewFileType,
              previewSize,
            });

            return scope;
          });
        }),
      );
  }

  getProductVariantPreviewsCollection(
    productVariantsCollection: StoreProductVariantsCollection,
    productCategoryName: string,
    product: SanityProduct,
    previewScene: string,
    previewFileType: PreviewFileType,
    mode: EProductWizardMode,
    productPreviewSize: ECommerceProductPreviewSize,
  ): Observable<StoreProductVariantPreviewsCollection> {
    if (
      !product ||
      !product.productVariations ||
      !productVariantsCollection ||
      // EIN-657: No need to generate product variant specific previews for wallpapers
      (productCategoryName === SanityProductCategoryName.WALLPAPER && mode !== EProductWizardMode.BULK_CONNECT)
    ) {
      return of({});
    }

    const productVariantKeys = Object.keys(productVariantsCollection);

    if (!productVariantKeys?.length) {
      return of({});
    }

    const mapper = (productVariantKey: string): Observable<string> => {
      const productVariantKeyForPreview = this.getProductVariantKeyApplicableForPreview(
        productVariantsCollection,
        productVariantKey,
        productCategoryName,
        product,
      );

      const productVariant: EProductVariant = productVariantsCollection[productVariantKeyForPreview];

      if (!productVariant) {
        return of(null);
      }

      const designPreviewFileType: PreviewFileType = this.designPreviewFileTypeService.getBySanityProductCategoryName(
        productCategoryName as SanityProductCategoryName,
      );

      return this.getProductPreviewUrl(
        productVariant,
        previewScene,
        previewFileType,
        designPreviewFileType,
        productPreviewSize,
      );
    };

    return combineLatest([...(productVariantKeys || []).map(mapper)]).pipe(
      switchMap((previewUrls: string[]) => {
        const previewsCollection: StoreProductVariantPreviewsCollection = {};

        previewUrls.forEach((previewUrl: string, index: number) => {
          const productVariantKey = productVariantKeys[index];

          previewsCollection[productVariantKey] = previewUrl;
        });

        return of(previewsCollection);
      }),
    );
  }

  getProductPreviewsCollection(
    productVariantsCollection: StoreProductVariantsCollection,
    selectedPreviewScenesCollection: SelectedPreviewScenesCollection,
    primaryPreviewProductVariantKey: string,
    previewFileType: PreviewFileType,
    productCategoryName: string,
    product: SanityProduct,
    productPreviewSize: ECommerceProductPreviewSize,
  ): Observable<StoreProductPreviewsCollection> {
    if (!productVariantsCollection || !Object.keys(productVariantsCollection)) {
      return of({});
    }

    const mapper = (previewScene: string): Observable<string> =>
      this.getProductPreviewsCollectionItem(
        productVariantsCollection,
        primaryPreviewProductVariantKey,
        previewScene,
        previewFileType,
        productCategoryName,
        product,
        productPreviewSize,
      );

    const previewScenes = this.getSelectedPreviewScenes(selectedPreviewScenesCollection);

    if (!primaryPreviewProductVariantKey || !previewScenes.length) {
      return of({});
    }

    return combineLatest([...(previewScenes || []).map(mapper)]).pipe(
      switchMap((previewUrls: string[]) => {
        const previewsCollection: StoreProductVariantPreviewsCollection = {};

        previewUrls.forEach((previewUrl: string, index: number) => {
          const previewScene = previewScenes[index];
          const key = sceneName2Key(previewScene);

          previewsCollection[key] = previewUrl;
        });

        return of(previewsCollection);
      }),
    );
  }

  getProductPreviewsCollectionItem(
    productVariantsCollection: StoreProductVariantsCollection,
    productVariantKey: string,
    previewScene: string,
    previewFileType: PreviewFileType,
    productCategoryName: string,
    product: SanityProduct,
    productPreviewSize: ECommerceProductPreviewSize,
  ): Observable<string> {
    const productVariantKeyForPreview: string = this.getProductVariantKeyApplicableForPreview(
      productVariantsCollection,
      productVariantKey,
      productCategoryName,
      product,
    );

    const productVariant = productVariantsCollection[productVariantKeyForPreview];

    if (!productVariant) {
      return of(null);
    }

    const designPreviewFileType: PreviewFileType = this.designPreviewFileTypeService.getBySanityProductCategoryName(
      productCategoryName as SanityProductCategoryName,
    );

    return this.getProductPreviewUrl(
      productVariant,
      previewScene,
      previewFileType,
      designPreviewFileType,
      productPreviewSize,
    );
  }

  getStoreProduct(
    sourceStoreProduct: EProduct,
    selectedPreviewFileType: PreviewFileType,
    selectedPreviewScenes: SelectedPreviewScenesCollection,
    primaryPreviewProductVariantKey: string,
    primaryPreviewScene: string,
    sanityProductCategory: SanityProductCategory,
    sanityProduct: SanityProduct,
    store: EStore,
  ): EProduct {
    const title =
      sourceStoreProduct?.title ||
      this.eCommerceProductDetailsService.getProductTitle(sanityProductCategory, sanityProduct);
    const description =
      this.eCommerceProductDetailsService.normalizeProductDescription(sourceStoreProduct?.description, store?.type) ||
      this.eCommerceProductDetailsService.getProductDescription(sanityProduct, store?.type);

    const previewScenes: string[] = this.getSelectedPreviewScenes(selectedPreviewScenes);

    const metadata: MetadataItem[] = updateMetadataItems(sourceStoreProduct?.metadata, [
      { key: EProductMetadataKey.PREVIEW_FILE_TYPE, value: selectedPreviewFileType },
      { key: EProductMetadataKey.PREVIEW_SCENES, value: JSON.stringify(previewScenes) },
      { key: EProductMetadataKey.PRIMARY_PREVIEW_SCENE, value: primaryPreviewScene },
      { key: EProductMetadataKey.PRIMARY_PREVIEW_PRODUCT_VARIANT_KEY, value: primaryPreviewProductVariantKey },
    ]);

    return new EProduct(
      sourceStoreProduct?.id,
      sourceStoreProduct?.externalId,
      store?.id,
      title,
      description,
      sanityProductCategory?.productModel,
      sanityProduct?.id,
      selectedPreviewFileType,
      primaryPreviewScene,
      // Product variant options sent to Shopify & Etsy stores
      // to configure variant selection dropdown controls
      sourceStoreProduct?.productVariantOptions,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      sourceStoreProduct?.tags,
      sourceStoreProduct?.collections,
      metadata,
      sourceStoreProduct?.hasDraft || false,
    );
  }

  getSortedProductVariants(
    productVariantsCollection: StoreProductVariantsCollection,
    selectedProductVariantKeysArray: string[],
  ): EProductVariant[] {
    return selectedProductVariantKeysArray
      .map((key: string): EProductVariant => productVariantsCollection[key])
      .filter((variant: EProductVariant): boolean => !!variant && this.isValidVariant(variant))
      .map(
        (variant: EProductVariant, index: number): EProductVariant => ({
          ...variant,
          position: index + 1,
        }),
      );
  }

  getProductImages(
    productPreviewsCollection: StoreProductPreviewsCollection,
    primaryPreviewScene: string,
  ): EProductImage[] {
    const productImages: EProductImage[] = [];

    Object.keys(productPreviewsCollection).forEach((key: string) => {
      const sceneName = key2SceneName(key);
      const isPrimary = (sceneName || '') === (primaryPreviewScene || '');
      const productPreviewUrl = productPreviewsCollection[key];

      if (!productPreviewUrl) {
        Sentry.captureException(new Error('EmptyProductImageUrl'), scope => {
          scope.setExtras({
            sceneName,
            isPrimary,
          });

          return scope;
        });
      }

      productImages.push({
        id: null,
        productId: null, // the field is populated later
        productVariantIds: null, // the field is populated later
        productVariantKeys: [],
        fileUrl: productPreviewUrl,
        metadata: updateMetadataItem([], EProductImageMetadataKey.SCENE_NAME, sceneName),
        isPrimary,
        status: EProductImageStatus.CREATED,
        createdAt: null,
        updatedAt: null,
      });
    });

    return productImages;
  }

  getProductVariantImages(
    productVariantPreviewsCollection: StoreProductVariantPreviewsCollection,
    primaryPreviewScene: string,
  ): EProductImage[] {
    const productVariantImages: EProductImage[] = [];
    const uniqueProductVariantPreviewsCollection = {};

    Object.keys(productVariantPreviewsCollection).forEach((productVariantKey: string) => {
      const previewUrl = productVariantPreviewsCollection[productVariantKey];

      if (!previewUrl) {
        return;
      }

      if (!uniqueProductVariantPreviewsCollection[previewUrl]) {
        uniqueProductVariantPreviewsCollection[previewUrl] = [];
      }

      uniqueProductVariantPreviewsCollection[previewUrl].push(productVariantKey);
    });

    Object.keys(uniqueProductVariantPreviewsCollection).forEach((previewUrl: string) => {
      const productVariantKeys = uniqueProductVariantPreviewsCollection[previewUrl];

      productVariantImages.push({
        id: null,
        productId: null, // the field is populated later
        productVariantIds: null, // the field is populated later
        productVariantKeys,
        fileUrl: previewUrl,
        metadata: updateMetadataItem([], EProductImageMetadataKey.SCENE_NAME, primaryPreviewScene),
        isPrimary: false,
        status: EProductImageStatus.CREATED,
        createdAt: null,
        updatedAt: null,
      });
    });

    return productVariantImages;
  }

  getProductData(
    storeProduct: EProduct,
    productUidsArray: string[],
    productVariantsCollection: StoreProductVariantsCollection,
    productVariantPreviewsCollection: StoreProductVariantPreviewsCollection,
    productPreviewsCollection: StoreProductPreviewsCollection,
    selectedPreviewFileType: PreviewFileType,
    selectedPreviewScenes: SelectedPreviewScenesCollection,
    primaryPreviewProductVariantKey: string,
    primaryPreviewScene: string,
    sanityProductCategory: SanityProductCategory,
    sanityProduct: SanityProduct,
    store: EStore,
  ): EProductData {
    const product = this.getStoreProduct(
      storeProduct,
      selectedPreviewFileType,
      selectedPreviewScenes,
      primaryPreviewProductVariantKey,
      primaryPreviewScene,
      sanityProductCategory,
      sanityProduct,
      store,
    );

    const productVariants: EProductVariant[] = this.getSortedProductVariants(
      productVariantsCollection,
      productUidsArray,
    );

    const productImages: EProductImage[] = [
      ...this.getProductImages(productPreviewsCollection, primaryPreviewScene),
      ...this.getProductVariantImages(productVariantPreviewsCollection, primaryPreviewScene),
    ];

    return new EProductData(product, productVariants, productImages);
  }

  validateData(
    selectedProductVariantKeysCollection: SelectedProductVariantKeysCollection,
    productVariantsCollection: StoreProductVariantsCollection,
  ): boolean {
    let selectedProductVariantsCount = 0;

    Object.keys(selectedProductVariantKeysCollection).forEach((key: string) => {
      if (selectedProductVariantKeysCollection[key] && productVariantsCollection[key]) {
        selectedProductVariantsCount++;
      }
    });

    return selectedProductVariantsCount > 0;
  }

  private isValidVariant(variant: EProductVariant): boolean {
    return Boolean(variant.fileUrl || variant.designStructureJson || variant.designId);
  }

  private getProductVariantKeyApplicableForPreview(
    productVariantsCollection: StoreProductVariantsCollection,
    productVariantKey: string,
    productCategoryName: string,
    product: SanityProduct,
  ): string {
    const productVariantKeyForSharedPreview =
      this.sharedProductVariantPreviewsService.getProductVariantKeyForSharedPreview(
        productVariantsCollection,
        productVariantKey,
        productCategoryName as SanityProductCategoryName,
        product,
      );

    return productVariantKeyForSharedPreview || productVariantKey;
  }

  private getSelectedPreviewScenes(selectedPreviewScenesCollection: SelectedPreviewScenesCollection): string[] {
    const selectedPreviewScenes: string[] = [];

    if (selectedPreviewScenesCollection) {
      Object.keys(selectedPreviewScenesCollection).forEach((key: string) => {
        if (selectedPreviewScenesCollection[key]) {
          selectedPreviewScenes.push(key2SceneName(key));
        }
      });
    }

    return selectedPreviewScenes;
  }
}
