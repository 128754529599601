import { getSortedProductControlOptions } from '@gelato-api-ui/core/sanity/helpers/getSortedProductControlOptions';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { ApparelSanityProductCategoryNames } from '@gelato-api-ui/core/sanity/apparel-sanity-product-category-names';
import { SanityProductControl, SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { SanityProductControlOption } from '@gelato-api-ui/core/sanity/sanity-product-control-option';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { ApparelProductTypeUids } from '@gelato-api-ui/core/product-catalogue/product-type-uids';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { findProductVariation } from '@gelato-api-ui/core/sanity/helpers/findProductVariation';

export function getControlKeyGroupPerCategory(sanityProductCategoryName: string): string[] {
  if (ApparelSanityProductCategoryNames.includes(sanityProductCategoryName as SanityProductCategoryName)) {
    return [SanityProductControlKey.garmentColor];
  } else if (
    [SanityProductCategoryName.FRAMED_POSTERS, SanityProductCategoryName.POSTERS_WITH_HANGERS].includes(
      sanityProductCategoryName as SanityProductCategoryName,
    )
  ) {
    return [SanityProductControlKey.paperFormat, SanityProductControlKey.orientation];
  } else if (sanityProductCategoryName === SanityProductCategoryName.FOAM_BOARD_PRINT) {
    return [
      SanityProductControlKey.paperFormat,
      SanityProductControlKey.orientation,
      SanityProductControlKey.foamThickness,
    ];
  } else if (sanityProductCategoryName === SanityProductCategoryName.WOOD_PRINT) {
    return [SanityProductControlKey.paperFormat, SanityProductControlKey.orientation];
  } else {
    return [];
  }
}

export function getControlKeyGroupPerProductType(productTypeUid: ProductTypeUid): string[] {
  if (ApparelProductTypeUids.includes(productTypeUid)) {
    return [SanityProductControlKey.garmentColor];
  } else if (
    [ProductTypeUid.FRAMED_POSTER, ProductTypeUid.MOUNTED_FRAMED_POSTER, ProductTypeUid.POSTER_WITH_HANGER].includes(
      productTypeUid,
    )
  ) {
    return [SanityProductControlKey.paperFormat, SanityProductControlKey.orientation];
  } else if (productTypeUid === ProductTypeUid.FOAM_PRINT) {
    return [
      SanityProductControlKey.paperFormat,
      SanityProductControlKey.orientation,
      SanityProductControlKey.foamThickness,
    ];
  } else if (productTypeUid === ProductTypeUid.WOOD_PRINT) {
    return [SanityProductControlKey.paperFormat, SanityProductControlKey.orientation];
  } else {
    return [];
  }
}

export const PRODUCT_SIZE_CONTROL_KEY = 'product-size';

export type ControlType = 'single' | 'multi';

export type ControlUIType = 'checkbox' | 'button' | 'color' | 'size' | 'dropdown';

export type Unit = 'cm' | 'inch';

export interface DefaultSizeCalculationParameterRecord {
  dimensionIncrement: number;
  dimensionMax: number;
  referenceDimension: 'width' | 'height';
  otherDimensionValues: number[];
}

export type DefaultSizeCalculationParameters = {
  [key in Unit]: DefaultSizeCalculationParameterRecord;
};

interface ProductSizeProductControlOption {
  value: string;
  width: number;
  height: number;
}

interface ProductSizeProductControl {
  type: string;
  key: string;
  title: string;
  options: ProductSizeProductControlOption[];
  // TODO remove after it will be removed in the editor
  appliesToValues?: { key: string; value: string }[];
  defaultSizeCalculationParameters?: DefaultSizeCalculationParameters;
}

export type ExternalProductControl = ProductSizeProductControl;
export type VariantProductControl = SanityProductControl | ExternalProductControl;

const MULTI_CONTROL_KEYS = new Set([
  SanityProductControlKey.paperFormat,
  SanityProductControlKey.frameColor,
  SanityProductControlKey.frameAssembling,
  SanityProductControlKey.wallHangerColor,
  SanityProductControlKey.garmentSize,
  SanityProductControlKey.garmentColor,
  SanityProductControlKey.mugMaterial,
  SanityProductControlKey.foamColor,
  SanityProductControlKey.woodThickness,
  SanityProductControlKey.bagColor,
  SanityProductControlKey.wallpaperMaterial,
  SanityProductControlKey.wallpaperType,
  SanityProductControlKey.phoneModel,
]);

const MULTI_CONTROL_KEYS_WHEN_ALONE = new Set([
  SanityProductControlKey.coatingType,
  SanityProductControlKey.paperType,
  SanityProductControlKey.orientation,
]);

export function getControlType(controlKey: SanityProductControlKey, hasSingleControl: boolean): ControlType {
  if (hasSingleControl && MULTI_CONTROL_KEYS_WHEN_ALONE.has(controlKey)) {
    return 'multi';
  }

  return MULTI_CONTROL_KEYS.has(controlKey) ? 'multi' : 'single';
}

export function getControlUIType(
  controlKey: string,
  options: SanityProductControlOption[],
  controlType: ControlType,
): ControlUIType {
  switch (controlKey) {
    case SanityProductControlKey.garmentColor:
    case SanityProductControlKey.mugMaterial:
    case SanityProductControlKey.bagColor:
      return 'color';
    case SanityProductControlKey.coatingType:
    case SanityProductControlKey.paperType:
      return controlType === 'multi' ? 'checkbox' : 'dropdown';
    case SanityProductControlKey.paperFormat:
      const showUnifiedSizeControls = options.reduce((acc, option) => acc || option.unified, false);
      return showUnifiedSizeControls ? 'checkbox' : 'size';
    default:
      return controlType === 'multi' ? 'checkbox' : 'button';
  }
}

const ORDERED_FIELDS = new Set([
  SanityProductControlKey.frameColor,
  SanityProductControlKey.frameAssembling,
  SanityProductControlKey.wallHangerColor,
  SanityProductControlKey.foamThickness,
  SanityProductControlKey.foamColor,
  SanityProductControlKey.orientation,
  SanityProductControlKey.paperFormat,
  SanityProductControlKey.garmentColor,
  SanityProductControlKey.garmentSize,
  SanityProductControlKey.bagColor,
  PRODUCT_SIZE_CONTROL_KEY,
  SanityProductControlKey.phoneBrand,
  SanityProductControlKey.phoneModel,
]);

export function getControlsUIOrder(productControls: VariantProductControl[]): string[] {
  // remove sorted field.
  const orderedControls = productControls
    .filter(control => !ORDERED_FIELDS.has(control.key))
    .map(control => control.key);

  // add sorted fields after, in order
  ORDERED_FIELDS.forEach(key => {
    const productControl = productControls.find(control => control.key === key);

    if (productControl) {
      orderedControls.push(productControl.key);
    }
  });

  return orderedControls;
}

export function getProductControls(sanityProduct: SanityProduct): SanityProductControl[] {
  const productControls = sanityProduct?.productControls || [];
  const productVariations = sanityProduct?.productVariations || [];

  const productControlsWithFilteredOptions: SanityProductControl[] = productControls.map(loopProductControl => ({
    ...loopProductControl,
    options: (loopProductControl.options || []).filter((loopOption: SanityProductControlOption): boolean =>
      Boolean(findProductVariation(productVariations, { [loopProductControl.key]: loopOption.value })),
    ),
  }));

  const visibleProductControls = productControlsWithFilteredOptions.filter(
    loopProductControl =>
      ![SanityProductControlKey.garmentPrint, SanityProductControlKey.bagPrint].includes(loopProductControl.key) &&
      loopProductControl?.options?.length > 1,
  );
  const hasSingleControl = visibleProductControls?.length === 1;

  return visibleProductControls
    .filter(
      control =>
        ![SanityProductControlKey.garmentPrint, SanityProductControlKey.bagPrint].includes(control.key) &&
        control?.options?.length,
    )
    .map(control => {
      const type = getControlType(control.key, hasSingleControl);
      const options = getSortedProductControlOptions(control.key, control.options);

      return {
        ...control,
        options,
        type,
        dependsOnSingleControls: control.key === SanityProductControlKey.paperFormat,
        uiType: getControlUIType(control.key, options, type),
      };
    });
}
