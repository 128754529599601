import { Injectable } from '@angular/core';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { SanityProductCareInstructionGroup } from '@gelato-api-ui/core/sanity/sanity-product-care-instruction-group';
import { SanityProductVariation } from '@gelato-api-ui/core/sanity/sanity-product-variation';
import { SanityFaqItem } from '@gelato-api-ui/core/sanity/sanity-faq-item';
import { SanityProductPackaging } from '@gelato-api-ui/core/sanity/sanity-product-packaging';
import { getRelevantProductControls } from '@gelato-api-ui/core/sanity/helpers/getRelevantProductControls';
import { SanityProductControl, SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { SanityProductFulfillment } from '@gelato-api-ui/core/sanity/sanity-product-fulfillment';
import { SanityProductVariationSpecificContentItem } from '@gelato-api-ui/core/sanity/sanity-product-variation-specific-content-item';
import { SanityProductPages } from '@gelato-api-ui/core/sanity/sanity-product-pages';
import { findProductVariation } from '@gelato-api-ui/core/sanity/helpers/findProductVariation';
import { SanityProductVariantFrameAssembling } from '@gelato-api-ui/core/sanity/sanity-product-variant';

@Injectable({ providedIn: 'root' })
export class SanityProductDetailsService {
  getDescription(product: SanityProduct, selectedProductVariation: SanityProductVariation): string {
    const variationSpecificContent = this.getProductVariationSpecificContentItem(product, selectedProductVariation);

    return variationSpecificContent?.description || product?.description;
  }

  getProductControls(product: SanityProduct, selectedProductVariation: SanityProductVariation): SanityProductControl[] {
    return getRelevantProductControls(product?.productControls, product?.productVariations, selectedProductVariation);
  }

  getProductPages(product: SanityProduct): SanityProductPages {
    return product?.pages;
  }

  getFulfilment(product: SanityProduct, selectedProductVariation: SanityProductVariation): SanityProductFulfillment {
    const variationSpecificContent = this.getProductVariationSpecificContentItem(product, selectedProductVariation);

    return variationSpecificContent?.fulfilment || product?.fulfilment;
  }

  getFulfillmentDescription(product: SanityProduct, selectedProductVariation: SanityProductVariation): string {
    return this.getFulfilment(product, selectedProductVariation)?.description;
  }

  getFulfillmentCountriesCount(product: SanityProduct, selectedProductVariation: SanityProductVariation): number {
    return this.getFulfillmentCountriesList(product, selectedProductVariation)?.length;
  }

  getFulfillmentCountriesMapData(product: SanityProduct, selectedProductVariation: SanityProductVariation) {
    const fulfilmentCountries = this.getFulfillmentCountriesList(product, selectedProductVariation);

    return fulfilmentCountries.reduce((result, countryCode) => {
      result[countryCode.trim()] = { value: 1 };

      return result;
    }, {});
  }

  getProductCareInstructions(product: SanityProduct): SanityProductCareInstructionGroup[] {
    return product?.productCareInstructions?.instructionGroups;
  }

  getFaq(product: SanityProduct, selectedProductVariation: SanityProductVariation): SanityFaqItem[] {
    const variationSpecificContent = this.getProductVariationSpecificContentItem(product, selectedProductVariation);

    return variationSpecificContent?.faq || product?.faq;
  }

  getPackaging(product: SanityProduct, selectedProductVariation: SanityProductVariation): SanityProductPackaging {
    const variationSpecificContent = this.getProductVariationSpecificContentItem(product, selectedProductVariation);

    return variationSpecificContent?.packaging || product?.packaging;
  }

  hasMountedFramedPosters(product: SanityProduct): boolean {
    return Boolean(
      findProductVariation(product?.productVariations, {
        frameassembling: SanityProductVariantFrameAssembling.mounted,
      }),
    );
  }

  private getFulfillmentCountriesList(
    product: SanityProduct,
    selectedProductVariation: SanityProductVariation,
  ): string[] {
    const countriesListString = this.getFulfilment(product, selectedProductVariation)?.countriesList;

    if (countriesListString?.length <= 1) {
      return null;
    }

    return countriesListString.split(',');
  }

  private getProductVariationSpecificContentItem(
    product: SanityProduct,
    selectedProductVariation: SanityProductVariation,
  ): SanityProductVariationSpecificContentItem {
    return (product?.productVariationSpecificContent || []).find(
      (loopItem: SanityProductVariationSpecificContentItem): boolean => {
        if (!loopItem?.productControlKey || !loopItem?.productOptionValue) {
          return false;
        }

        const key: SanityProductControlKey = loopItem?.productControlKey;
        const value: string = loopItem?.productOptionValue;

        return selectedProductVariation[key] === value;
      },
    );
  }
}
