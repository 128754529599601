import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { SanityProductCategoryName } from '../sanity-product-category-name.enum';

const productTabCategories = new Set([SanityProductCategoryName.CANVAS, SanityProductCategoryName.CALENDARS]);
function isProductTabSupported(category: string | SanityProductCategoryName) {
  return productTabCategories.has(category as SanityProductCategoryName);
}

const productTabProductTypeUids = new Set([ProductTypeUid.CANVAS, ProductTypeUid.CALENDAR]);
export function isProductTabSupportedPerProductTypeUid(productTypeUid: ProductTypeUid) {
  return productTabProductTypeUids.has(productTypeUid);
}

export default isProductTabSupported;
