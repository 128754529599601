import { createAction, props } from '@ngrx/store';
import { WalletTransaction, WalletTransactionsFilter } from '../../lib/api';

export const fetchStart = createAction(
  '[Wallet Transactions] FetchStart',
  props<{ walletUid: string; clientId: string }>(),
);

export const fetchSuccess = createAction(
  '[Wallet Transactions] FetchSuccess',
  props<{ list: WalletTransaction[]; filter: WalletTransactionsFilter; clientId: string }>(),
);

export const fetchFail = createAction('[Wallet Transactions] FetchFail', props<{ err: any; clientId: string }>());

export const setFilter = createAction('[Wallet Transactions] SetFilter', props<{ filter: WalletTransactionsFilter }>());
