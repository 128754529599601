import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '../app.state';
import { ProductCatalogue } from '@gelato-api-ui/core/product-catalogue/product-catalogue';
import * as actions from './product-catalogue-selection-list.actions';

export interface State {
  isLoading: boolean;
  payload: ProductCatalogue[];
}

export const initialState: State = {
  isLoading: false,
  payload: null,
};

export const getState = (state: AppState): State => state.productCatalogueSelectionList;

export const getProductCatalogues = createSelector(getState, (state: State): ProductCatalogue[] => state.payload || []);

export const getProductCatalogueOptions = createSelector(
  getProductCatalogues,
  (productCatalogues: ProductCatalogue[]): any => {
    const options = R.map(
      (productCatalogue: ProductCatalogue) => ({
        id: productCatalogue.id,
        name: productCatalogue.title,
      }),
      productCatalogues,
    ).sort((a, b) => (b.name > a.name ? -1 : 1));

    return options;
  },
);

export const getProductCatalogueTitles = createSelector(
  getProductCatalogues,
  (productCatalogues: ProductCatalogue[]): any => {
    if (!productCatalogues) {
      return {};
    }

    const titles = {};

    productCatalogues.forEach((productCatalogue: ProductCatalogue) => {
      titles[productCatalogue.id] = productCatalogue.title;
    });

    return titles;
  },
);

export function reducer(state = initialState, action: actions.ProductCatalogueSelectionListActions): State {
  switch (action.type) {
    case actions.ProductCatalogueSelectionListActionTypes.SetState:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
