import { KeycloakLocaleCode, LocaleCode } from '../locales.constant';

const mapping = {
  [LocaleCode.EN]: KeycloakLocaleCode.EN,
  [LocaleCode.DE]: KeycloakLocaleCode.DE,
  [LocaleCode.FR]: KeycloakLocaleCode.FR,
  [LocaleCode.ES]: KeycloakLocaleCode.ES,
  [LocaleCode.IT]: KeycloakLocaleCode.IT,
  [LocaleCode.SE]: KeycloakLocaleCode.SE,
  [LocaleCode.NO]: KeycloakLocaleCode.NO,
  [LocaleCode.JP]: KeycloakLocaleCode.JP,
  [LocaleCode.VI]: KeycloakLocaleCode.VI,
  [LocaleCode.NL]: KeycloakLocaleCode.NL,
  [LocaleCode.DA]: KeycloakLocaleCode.DA,
};

export const toKeycloakLocale = (localeCode: LocaleCode): KeycloakLocaleCode => {
  if (mapping[localeCode]) {
    return mapping[localeCode];
  }

  return KeycloakLocaleCode.EN;
};
