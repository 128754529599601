import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import { ECommerceProductWizardPricesService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard-prices.service';
import * as actions from './e-commerce-product-wizard.actions';
import {
  createProductTemplateFailure,
  createProductTemplateSuccess,
  loadBulkConnectDefaultProductVariantMappingFailure,
  loadBulkConnectDefaultProductVariantMappingStart,
  loadBulkConnectDefaultProductVariantMappingSuccess,
  saveProductTemplateFailure,
  saveProductTemplateSuccess,
} from './e-commerce-product-wizard.actions';
import { getSelectedStoreId } from '@api-ui-app/src/app/ngrx/e-commerce-stores.selector';
import {
  getDestinationProductId,
  getDestinationProductVariants,
  getMode,
  getStoreProductVariantsCollection,
} from './e-commerce-product-wizard.selector';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { getSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { UserCurrencyService } from '@api-ui-app/src/app/shared/services/user-currency.service';
import { BulkConnectVariantsMappingPayload } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { EProductVariantMappingResponse } from '@gelato-api-ui/core/e-commerce/e-product-variant-mapping-response';
import {
  EProductVariantMappingRequest,
  EProductVariantMappingRequestVariant,
} from '@gelato-api-ui/core/e-commerce/e-product-variant-mapping-request';
import { ECommerceProductVariantsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-variants-api.service';
import { mapResponseToBulkConnectProductVariantsMappingPayload } from '@gelato-api-ui/core/e-commerce/helpers/mapResponseToBulkConnectProductVariantsMappingPayload';
import { ECommerceProductVariantOptionsService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-variant-options.service';
import {
  ShowGeneralErrorNotification,
  ShowNotification,
} from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ECommerceProductWizardEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly translateService: TranslateService,
    private readonly eCommerceProductWizardPricesService: ECommerceProductWizardPricesService,
    private readonly userCurrencyService: UserCurrencyService,
    private readonly eCommerceProductVariantsApiService: ECommerceProductVariantsApiService,
    private readonly eCommerceProductVariantOptionsService: ECommerceProductVariantOptionsService,
  ) {}

  updateSelectedProductAndVariantOptions$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.UpdateSelectedProductAndVariantOptions>(
        actions.ECommerceProductWizardActionTypes.UpdateSelectedProductAndVariantOptions,
      ),
      withLatestFrom(this.store$.select(getStoreProductVariantsCollection), this.store$.select(getMode)),
      filter(([, , mode]) =>
        [
          EProductWizardMode.CREATE_PRODUCT,
          EProductWizardMode.CREATE_PRODUCT_TEMPLATE,
          EProductWizardMode.EDIT_PRODUCT_TEMPLATE,
          EProductWizardMode.EDIT_DESIGNS,
          EProductWizardMode.EDIT_DRAFT,
        ].includes(mode),
      ),
      map(([, variantsCollection]) => {
        const usedOptions = this.eCommerceProductVariantOptionsService.extractUsedOptionsFromVariants(
          Object.values(variantsCollection),
        );

        return new actions.SetStoreProductVariantOptions(usedOptions);
      }),
    ),
  );

  loadDefaultBulkConnectProductVariantMapping$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadBulkConnectDefaultProductVariantMappingStart),
      withLatestFrom(
        this.store$.select(getSelectedClientId),
        this.store$.select(getSelectedStoreId),
        this.store$.select(getStoreProductVariantsCollection),
        this.store$.select(getDestinationProductId),
        this.store$.select(getDestinationProductVariants),
      ),
      switchMap(
        ([
          ,
          clientId,
          selectedStoreId,
          storeProductVariantsCollection,
          destinationProductId,
          destinationProductVariants,
        ]) => {
          const variants: EProductVariantMappingRequestVariant[] = Object.values(storeProductVariantsCollection).map(
            (loopProductVariant: EProductVariant): EProductVariantMappingRequestVariant => ({
              id: loopProductVariant.productUid,
              title: loopProductVariant.title,
              variantOptions: loopProductVariant.variantOptions,
            }),
          );

          const request: EProductVariantMappingRequest = { clientId, variants, productId: destinationProductId };

          return this.eCommerceProductVariantsApiService.getMapping(selectedStoreId, request).pipe(
            catchError(() => {
              this.store$.dispatch(loadBulkConnectDefaultProductVariantMappingFailure());
              return of(null);
            }),
            filter((response: EProductVariantMappingResponse): boolean => Boolean(response)),
            map(
              (response: EProductVariantMappingResponse): BulkConnectVariantsMappingPayload =>
                mapResponseToBulkConnectProductVariantsMappingPayload(response, destinationProductVariants),
            ),
            map((mapping: BulkConnectVariantsMappingPayload) =>
              loadBulkConnectDefaultProductVariantMappingSuccess({ payload: mapping }),
            ),
          );
        },
      ),
    ),
  );

  saveProductTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveProductTemplateSuccess),
      map(
        () =>
          new ShowNotification({
            message: this.translateService.instant('txt_product_save_as_template_success_message'),
            type: 'success',
          }),
      ),
    ),
  );

  createProductTemplateSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(createProductTemplateSuccess),
      map(
        () =>
          new ShowNotification({
            message: this.translateService.instant('txt_product_template_creation_success_message'),
            type: 'success',
          }),
      ),
    ),
  );

  showGeneralErrorNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveProductTemplateFailure, createProductTemplateFailure),
      map(() => new ShowGeneralErrorNotification()),
    ),
  );
}
