import { convertIso8601ToGmtDate } from './convertIso8601ToGmtDate';
import { convertDateToGmtTimestamp } from './convertDateToGmtTimestamp';

export const getTimestampFromString = (value: string): number => {
  if (value) {
    if (!isNaN(Number(value))) {
      return Number(value) * 1000;
    }

    const date = convertIso8601ToGmtDate(value, false);

    if (date) {
      return convertDateToGmtTimestamp(date);
    }
  }

  return 0;
};
