import { Injectable } from '@angular/core';
import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { CustomTrim } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { SanityProductTitleService } from '@gelato-api-ui/core/sanity/services/sanity-product-title.service';
import { SanityProductVariationTitleService } from '@gelato-api-ui/core/sanity/services/sanity-product-variation-title.service';
import { SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';

@Injectable({ providedIn: 'root' })
export class ECommerceProductVariantTitleService {
  private readonly UNTITLED = 'Untitled';

  constructor(
    private readonly sanityProductTitleService: SanityProductTitleService,
    private readonly sanityProductVariationTitleService: SanityProductVariationTitleService,
  ) {}

  get(
    sanityProductCategory: SanityProductCategory,
    sanityProduct: SanityProduct,
    productUid: string,
    customTrim: CustomTrim,
    productControlKeysToIgnore?: SanityProductControlKey[],
  ): string {
    const productVariationSpecificTitle = this.sanityProductVariationTitleService.get(
      sanityProduct,
      productUid,
      customTrim,
      productControlKeysToIgnore,
    );

    if (productVariationSpecificTitle) {
      return productVariationSpecificTitle;
    }

    const productSpecificTitle = this.sanityProductTitleService.get(sanityProductCategory, sanityProduct);

    if (productSpecificTitle) {
      return productSpecificTitle;
    }

    return this.UNTITLED;
  }
}
