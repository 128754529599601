import { Action } from '@ngrx/store';
import { ProductionConfigurationSearchRequest } from '../production-configurations/lib/production-configuration-search-request';
import { EntityState, ListState } from './production-configurations.reducer';

export enum ProductionConfigurationsActionTypes {
  LoadList = '[ProductionConfigurations] Load List',
  LoadEntity = '[ProductionConfigurations] Load Entity',

  ResetListState = '[ProductionConfigurations] Reset List State',
  ResetListPayload = '[ProductionConfigurations] Reset List Payload',
  ResetEntityState = '[ProductionConfigurations] Reset Entity State',

  SetListState = '[ProductionConfigurations] Set List State',
  SetEntityState = '[ProductionConfigurations] Set Entity State',
}

export class LoadList implements Action {
  readonly type = ProductionConfigurationsActionTypes.LoadList;

  constructor(public searchRequest: ProductionConfigurationSearchRequest, public forced: boolean = false) {}
}

export class LoadEntity implements Action {
  readonly type = ProductionConfigurationsActionTypes.LoadEntity;

  constructor(public id: string, public forced: boolean = false) {}
}

export class ResetListState implements Action {
  readonly type = ProductionConfigurationsActionTypes.ResetListState;
}

export class ResetListPayload implements Action {
  readonly type = ProductionConfigurationsActionTypes.ResetListPayload;
}

export class SetListState implements Action {
  readonly type = ProductionConfigurationsActionTypes.SetListState;

  constructor(public payload: ListState) {}
}

export class ResetEntityState implements Action {
  readonly type = ProductionConfigurationsActionTypes.ResetEntityState;
}

export class SetEntityState implements Action {
  readonly type = ProductionConfigurationsActionTypes.SetEntityState;

  constructor(public payload: EntityState) {}
}

export type ProductionConfigurationsActions =
  | LoadList
  | LoadEntity
  | ResetListState
  | ResetListPayload
  | ResetEntityState
  | SetListState
  | SetEntityState;
