import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gd-table-section-group',
  templateUrl: './table-section-group.component.html',
  styleUrls: ['./table-section-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableSectionGroupComponent {
  @Input() title: string;
  @Input() subscriptionPageEnabledAB: string;
  isExpanded = true;

  onToggleExpandClick() {
    this.isExpanded = !this.isExpanded;
  }
}
