import { ServicePlanResponse } from '@api-ui-app/src/app/subscriptions/types/subscription-plans';
import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';
import { ServiceUniqueName } from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';

export const getDefaultPlatformServicePlan = (servicePlans: ServicePlanResponse[]): ServicePlanResponse =>
  (servicePlans || []).find(
    (loopServicePlan: ServicePlanResponse): boolean =>
      loopServicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM &&
      loopServicePlan.uniqueName === ServicePlanUniqueNames.gelato_platform_free,
  );
