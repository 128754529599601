import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as SubSelector from '@api-ui-app/src/app/subscriptions/+state/subscriptions.selectors';
import {
  activateServicePlan,
  calculatePricePlanTax,
  chargebeeSubscriptionSuccessful,
  getIfClientEligibleForGoldSubscription,
  loadActiveSubscriptionsAndServicePlans,
  loadSubscriptionPlans,
  loadSubscriptionPlansListFromSanity,
  loadSubscriptionPlansPrices,
  setActivePeriodUnit,
  setActivePlansIsUpdating,
} from '@api-ui-app/src/app/subscriptions/+state/subscriptions.actions';
import { AppState } from '@api-ui-app/src/app/app.state';
import {
  ServicePlanPrice,
  ServicePricePlanTax,
  ServiceUniqueName,
} from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { PricePlansListItem } from '@api-ui-app/src/app/subscriptions/types/price-plans-list-item';
import { UserCurrencyService } from '@api-ui-app/src/app/shared/services/user-currency.service';
import { ServicePlanResponse } from '@api-ui-app/src/app/subscriptions/types/subscription-plans';
import * as R from 'ramda';
import { PricePlansListContent } from '@api-ui-app/src/app/subscriptions/types/price-plans-list-content';
import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { GelatoPlusPopupLocations } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-popup-locations';
import { DesignEditorPlugin } from '@gelato-api-ui/core/design-editor/design-editor-plugin.enum';
import { MoneyPipe } from '@gelato-api-ui/sdk/src/lib/pipes/format/money.pipe';
import { firstNotNil } from '@gelato-api-ui/utils/operators';
import {
  applyPlusSubscriptionCurrencyToFreeSubscription,
  getPricePlanByPeriod,
  getPricePlansListItems,
} from '../lib/helpers/getPriceListMappers';
import { convertYearToMonthPrice } from '../lib/helpers/maths';
import { getCompanyDetails } from '@api-ui-app/src/app/ngrx/company-details.reducer';
import { validateBillingAddress } from '@api-ui-app/src/app/subscriptions/lib/helpers/validateBillingAddress';
import { clientIdGodMod } from '@api-ui-app/src/app/subscriptions/constants/non-blocking-client-id';
import { getAssetsCollection } from '@product-catalogue/src/lib/ngrx/assets.selector';
import { GelatoPlatformMetadataKeys } from '@api-ui-app/src/app/clients/types/subscription-metadata';

@Injectable()
export class SubscriptionsFacade {
  constructor(
    private readonly store: Store<AppState>,
    private readonly moneyPipe: MoneyPipe,
    private readonly userCurrencyService: UserCurrencyService,
  ) {}
  DEFAULT_CURRENCY = 'USD';

  activeSubscriptions$ = this.store.select(SubSelector.getActiveSubscriptions);
  servicePlans$ = this.store.select(SubSelector.getServicePlans);
  servicePlansPrices$ = this.store.select(SubSelector.getServicePlansPrices);
  activeServicePlans$ = this.store.select(SubSelector.getActiveServicePlans);
  gelatoPlusIsActive$ = this.store.select(SubSelector.getGelatoPlusIsActive);
  gelatoPlusGoldIsActive$ = this.store.select(SubSelector.getGelatoPlusGoldIsActive);
  isGelatoPlusGoldTrialActive$ = this.store.select(SubSelector.getIsGelatoPlusGoldTrialActive);
  gelatoPlusOrGoldIsActive$ = this.store.select(SubSelector.getGelatoPlusOrGoldIsActive);
  selectedSubscriptionPlan$ = this.store.select(SubSelector.getSelectedSubscriptionPlan);
  getFreeShippingDaysLeft$ = this.store.select(SubSelector.getFreeShippingDaysLeft);
  isGelatoPlusYearlyPlanActive$ = this.store.select(SubSelector.getIsGelatoPlusYearlyPlanActive);
  isGelatoPlusGoldYearlyPlanActive$ = this.store.select(SubSelector.getIsGelatoPlusGoldYearlyPlanActive);
  gelatoPlusSubscriptionStartDate$ = this.store.select(SubSelector.getGelatoPlusSubscriptionStartDate);
  gelatoPlusGoldSubscriptionStartDate$ = this.store.select(SubSelector.getGelatoPlusGoldSubscriptionStartDate);
  activeFreePlanIsLegacy$ = this.store.select(SubSelector.getActiveFreePlanIsLegacy);
  pricePlansLoading$ = this.store.select(SubSelector.getIsSubscriptionPlansLoading);
  activePlansIsUpdating$ = this.store.select(SubSelector.getActivePlansUpdateStatus);
  isLoadingSubscriptionPlansFirstTime$ = this.store.select(SubSelector.getIsLoadingSubscriptionPlansFirstTime);
  isActiveSubscriptionsLoaded$ = this.store.select(SubSelector.getIsActiveSubscriptionsLoaded);
  gelatoPlusSubscriptionHasExpired$ = this.store.select(SubSelector.getGelatoPlusSubscriptionHasExpired);
  isUserNeverHadGelatoSubscription$ = this.store.select(SubSelector.getIsUserNeverHadGelatoSubscription);
  isUserNeverHadGelatoPlusSubscription$ = this.store.select(SubSelector.getIsUserNeverHadGelatoPlusSubscription);
  isUserNeverHadGelatoPlusGoldSubscription$ = this.store.select(
    SubSelector.getIsUserNeverHadGelatoPlusGoldSubscription,
  );
  getGelatoPlusTrialSubscriptionData$ = this.store.select(SubSelector.getGelatoPlusTrialSubscriptionData);
  gelatoPlusSubscriptionsStatuses$ = this.store.select(SubSelector.getGelatoPlusSubscriptionsStatuses);
  getGelatoPlusOrGoldSubscriptionsStatuses = this.store.select(SubSelector.getGelatoPlusOrGoldSubscriptionsStatuses);
  isNonRenewingSubscriptionEnding$ = this.store.select(SubSelector.getIsNonRenewingSubscriptionEnding);
  nonRenewingGelatoPlusDaysLeft$ = this.store.select(SubSelector.getNonRenewingGelatoPlusDaysLeft);
  isClientCreatedAfterGelatoPlusStart$ = this.store.select(SubSelector.getIsClientCreatedAfterGelatoPlusStart);
  isClientCreatedBeforeGelatoPlusStart$ = this.store.select(SubSelector.getIsClientCreatedBeforeGelatoPlusStart);
  isExpandCategoriesSubscriptionIsActive$ = this.store.select(SubSelector.getExpandCategoriesSubscription);
  isProfitMarginSubscriptionIsActive$ = this.store.select(SubSelector.getProfitMarginSubscription);
  isAutoApproveSubscriptionIsActive$ = this.store.select(SubSelector.getAutoApproveSubscription);
  isClientEligibleForGoldSubscription$ = this.store.select(SubSelector.getIsClientEligibleForGoldSubscription);
  assetsCollection$ = this.store.select(getAssetsCollection);
  isGelatoPlusTrialActive$ = this.store.select(SubSelector.getIsGelatoPlusTrialActive);
  isGelatoPlusOrGoldTrialActive$ = combineLatest([
    this.isGelatoPlusTrialActive$,
    this.isGelatoPlusGoldTrialActive$,
  ]).pipe(map(([plusTrialIsActive, goldTrialIsActive]) => plusTrialIsActive || goldTrialIsActive));
  isGelatoPlusTrialScheduledToCancel$ = this.store.select(SubSelector.getIsGelatoPlusTrialScheduledToCancel);
  isGelatoPlusGoldTrialScheduledToCancel$ = this.store.select(SubSelector.getIsGelatoPlusGoldTrialScheduledToCancel);
  companyDetails$ = this.store.pipe(select(getCompanyDetails));
  countryISOCode$ = this.companyDetails$.pipe(map(companyDetails => companyDetails?.countryIsoCode));
  isExpandCategoriesAvailable$ = combineLatest([
    this.gelatoPlusGoldIsActive$,
    this.isExpandCategoriesSubscriptionIsActive$,
  ]).pipe(
    map(([isGoldActive, isExpandCategoriesSubscriptionIsActive]) =>
      Boolean(isGoldActive || isExpandCategoriesSubscriptionIsActive),
    ),
  );
  servicePricePlanTax$ = this.store.pipe(
    select(SubSelector.getServicePricePlanTax),
    map((pricePlanTax: ServicePricePlanTax) => pricePlanTax),
  );
  isTaxCalculating$ = this.servicePricePlanTax$.pipe(
    map((pricePlanTax: ServicePricePlanTax) => pricePlanTax.isLoading),
  );
  isBillingValid$ = combineLatest([this.companyDetails$, this.servicePricePlanTax$]).pipe(
    map(
      ([companyDetails, servicePricePlanTax]) => !servicePricePlanTax?.error && validateBillingAddress(companyDetails),
    ),
  );
  clientIdIsNonBlocking$ = this.companyDetails$.pipe(
    map(companyDetails => companyDetails?.clientId === clientIdGodMod),
  );

  platformSubscriptionPlans$ = this.store.select(SubSelector.getSubscriptionPlansListFromSanity);

  allSubscriptionPlans$ = this.platformSubscriptionPlans$.pipe(
    map(platformSubscriptionPlans => platformSubscriptionPlans || []),
  );

  activeServiceUniqueNames$ = this.store.select(SubSelector.getActiveServiceUniqueNames);
  activePlatformPlanWithMetadata$ = this.store.select(SubSelector.getPlatformActivePlanMetadata);

  pricePlansListContent$ = combineLatest([
    this.allSubscriptionPlans$,
    this.servicePlans$,
    this.servicePlansPrices$,
    this.userCurrencyService.get(),
    this.activeServicePlans$,
    this.activeSubscriptions$,
  ]).pipe(
    filter(R.all(a => !!a)),
    map(
      ([
        allSubscriptionPlans,
        servicePlans,
        servicePlansPrices,
        userCurrency,
        activeServicePlans,
        activeSubscriptions,
      ]): PricePlansListContent =>
        [TimePeriodUnit.MONTH, TimePeriodUnit.YEAR].reduce(
          (acc: PricePlansListContent, loopPeriodUnit: TimePeriodUnit): PricePlansListContent => ({
            ...acc,
            [loopPeriodUnit]: getPricePlansListItems(
              servicePlans,
              servicePlansPrices,
              allSubscriptionPlans,
              loopPeriodUnit,
              userCurrency,
              activeServicePlans,
              activeSubscriptions,
            ),
          }),
          {},
        ),
    ),
  );

  gelatoPlusMonthPriceOnYearlyPlan$ = combineLatest([
    this.userCurrencyService.get(),
    this.pricePlansListContent$,
    this.isClientCreatedBeforeGelatoPlusStart$,
  ]).pipe(
    map(([userCurrency, pricePlansListContent, isLegacy]): string => {
      const pricePlan = getPricePlanByPeriod(
        pricePlansListContent,
        userCurrency,
        TimePeriodUnit.YEAR,
        isLegacy ? ServicePlanUniqueNames.gelato_platform_plus_legacy : ServicePlanUniqueNames.gelato_platform_plus,
      );

      if (!pricePlan) {
        return null;
      }

      return this.moneyPipe.transform(
        pricePlan && String(convertYearToMonthPrice(pricePlan.price)),
        pricePlan && pricePlan.currency,
        false,
        true,
      );
    }),
  );
  gelatoPlusYearSubscriptionPrice$ = combineLatest([
    this.userCurrencyService.get(),
    this.pricePlansListContent$,
    this.isClientCreatedBeforeGelatoPlusStart$,
  ]).pipe(
    map(([userCurrency, pricePlansListContent, isLegacy]): string => {
      const pricePlan = getPricePlanByPeriod(
        pricePlansListContent,
        userCurrency,
        TimePeriodUnit.YEAR,
        isLegacy ? ServicePlanUniqueNames.gelato_platform_plus_legacy : ServicePlanUniqueNames.gelato_platform_plus,
      );

      if (!pricePlan) {
        return this.moneyPipe.transform('', this.DEFAULT_CURRENCY, false, true);
      }

      return this.moneyPipe.transform(String(pricePlan.price), pricePlan.currency, false, true);
    }),
  );
  gelatoPlusMonthSubscriptionPrice$ = combineLatest([
    this.userCurrencyService.get(),
    this.pricePlansListContent$,
    this.isClientCreatedBeforeGelatoPlusStart$,
  ]).pipe(
    map(([userCurrency, pricePlansListContent, isLegacy]): string => {
      const pricePlan = getPricePlanByPeriod(
        pricePlansListContent,
        userCurrency,
        TimePeriodUnit.MONTH,
        isLegacy ? ServicePlanUniqueNames.gelato_platform_plus_legacy : ServicePlanUniqueNames.gelato_platform_plus,
      );

      if (!pricePlan) {
        return this.moneyPipe.transform('', this.DEFAULT_CURRENCY, false, true);
      }

      return this.moneyPipe.transform(String(pricePlan.price), pricePlan.currency, false, true);
    }),
  );

  gelatoGoldMonthPriceOnYearlyPlan$ = combineLatest([this.userCurrencyService.get(), this.pricePlansListContent$]).pipe(
    map(([userCurrency, pricePlansListContent]): string => {
      const pricePlan = getPricePlanByPeriod(
        pricePlansListContent,
        userCurrency,
        TimePeriodUnit.YEAR,
        ServicePlanUniqueNames.gelato_platform_gold,
      );

      if (!pricePlan) {
        return null;
      }

      return this.moneyPipe.transform(
        pricePlan && String(convertYearToMonthPrice(pricePlan.price)),
        pricePlan && pricePlan.currency,
        false,
        true,
      );
    }),
  );

  gelatoGoldYearSubscriptionPrice$ = combineLatest([this.userCurrencyService.get(), this.pricePlansListContent$]).pipe(
    map(([userCurrency, pricePlansListContent]): string => {
      const pricePlan = getPricePlanByPeriod(
        pricePlansListContent,
        userCurrency,
        TimePeriodUnit.YEAR,
        ServicePlanUniqueNames.gelato_platform_gold,
      );

      if (!pricePlan) {
        return this.moneyPipe.transform('', this.DEFAULT_CURRENCY, false, true);
      }

      return this.moneyPipe.transform(String(pricePlan.price), pricePlan.currency, false, true);
    }),
  );

  gelatoGoldMonthSubscriptionPrice$ = combineLatest([this.userCurrencyService.get(), this.pricePlansListContent$]).pipe(
    map(([userCurrency, pricePlansListContent]): string => {
      const pricePlan = getPricePlanByPeriod(
        pricePlansListContent,
        userCurrency,
        TimePeriodUnit.MONTH,
        ServicePlanUniqueNames.gelato_platform_gold,
      );

      if (!pricePlan) {
        return this.moneyPipe.transform('', this.DEFAULT_CURRENCY, false, true);
      }

      return this.moneyPipe.transform(String(pricePlan.price), pricePlan.currency, false, true);
    }),
  );

  pricePlansListItems$ = this.pricePlansListContent$.pipe(
    map((pricePlansListContent): PricePlansListItem[] => {
      return applyPlusSubscriptionCurrencyToFreeSubscription(pricePlansListContent[TimePeriodUnit.YEAR]);
    }),
  );

  showGelatoPlusCrown$ = combineLatest([
    this.pricePlansLoading$,
    this.activeServicePlans$,
    this.gelatoPlusIsActive$,
    this.activePlansIsUpdating$,
  ]).pipe(
    map(([, activeServicePlans, gelatoPlusOrEnterpriseIsActive, activePlansIsUpdating]) => {
      return !!activeServicePlans && !gelatoPlusOrEnterpriseIsActive && !activePlansIsUpdating;
    }),
  );

  storesSubscriptionLimit$: Observable<number | null> = this.activePlatformPlanWithMetadata$.pipe(
    map(servicePlan => {
      if (Boolean(servicePlan && servicePlan.metadata)) {
        const storesPlanMetaData = servicePlan.metadata.find(
          metaDataItem => metaDataItem.key === GelatoPlatformMetadataKeys.numberOfStores,
        );
        if (storesPlanMetaData) {
          return storesPlanMetaData.value;
        }
      }

      return null;
    }),
  );
  shutterstockEssentialPlanIsEnabled$: Observable<boolean> = this.activeServicePlans$.pipe(
    map(activeServicePlans =>
      Boolean(
        activeServicePlans &&
          activeServicePlans.find(
            activeServicePlan =>
              activeServicePlan &&
              [
                ServicePlanUniqueNames.app_shutterstock_essential_plan_0,
                ServicePlanUniqueNames.app_shutterstock_essential_plan_1,
              ].includes(activeServicePlan.uniqueName as ServicePlanUniqueNames),
          ),
      ),
    ),
  );

  pluginsIsUsedInTheEditor$ = new BehaviorSubject<boolean>(false);
  pluginsListUsedInTheEditor$ = new BehaviorSubject<DesignEditorPlugin[]>([]);

  init() {
    this.loadSubscriptionPlans(ServiceUniqueName.GELATO_PLATFORM);

    this.servicePlans$
      .pipe(
        firstNotNil,
        map((plans: ServicePlanResponse[]) => plans.map(plan => plan.uniqueName)),
        tap(plans => this.loadSubscriptionPlansListFromSanity(plans)),
      )
      .subscribe();

    this.userCurrencyService
      .get()
      .pipe(
        distinctUntilChanged((prev, next) => prev === next),
        tap(() => this.loadSubscriptionPlans(ServiceUniqueName.GELATO_PLATFORM, true)),
      )
      .subscribe();
  }

  loadActiveSubscriptionsAndServicePlans() {
    this.store.dispatch(loadActiveSubscriptionsAndServicePlans());
  }

  fireSuccessfulSubscriptionAction() {
    this.store.dispatch(chargebeeSubscriptionSuccessful());
  }

  /**
   * Search plans and prices for plans by service name
   * @param serviceUniqueName
   * @param fetchPricesOnly
   */
  loadSubscriptionPlans(serviceUniqueName: string, fetchPricesOnly = false) {
    this.userCurrencyService
      .get()
      .pipe(
        filter((userCurrency: string): boolean => Boolean(userCurrency)),
        first(),
        tap((userCurrency: string) => {
          if (fetchPricesOnly) {
            this.store.dispatch(loadSubscriptionPlansPrices({ serviceUniqueNames: [serviceUniqueName], userCurrency }));
          } else {
            this.store.dispatch(loadSubscriptionPlans({ serviceUniqueNames: [serviceUniqueName] }));
            this.store.dispatch(loadSubscriptionPlansPrices({ serviceUniqueNames: [serviceUniqueName], userCurrency }));
          }
        }),
      )
      .subscribe();
  }

  loadSubscriptionPlansListFromSanity(uniqueNames: string[]) {
    this.store.dispatch(loadSubscriptionPlansListFromSanity({ uniqueNames }));
  }

  activateServicePlan(servicePlanPrice: ServicePlanPrice) {
    this.store.dispatch(activateServicePlan({ servicePlanPrice }));
  }

  setActivePeriodUnit(periodUnit: TimePeriodUnit) {
    this.store.dispatch(setActivePeriodUnit({ periodUnit }));
  }

  setActivePlansStatus(status: boolean) {
    this.store.dispatch(setActivePlansIsUpdating({ status }));
  }

  logStartGelatoPlusFunnel(gelatoPlusLocation: GelatoPlusPopupLocations) {
    logEvent('gelatoPlusFunnelStart', {
      locationPopUp: gelatoPlusLocation,
    });
  }

  logGelatoPlusPromptShown(locationPopUp: GelatoPlusPopupLocations, gelatoPlusSubscriptionsStatuses: string[]) {
    logEvent('prompterShown', {
      locationPopUp,
      gelato_plus_subscription: gelatoPlusSubscriptionsStatuses,
    });
  }

  setPluginsIsUsedInTheEditor(isUsed: boolean, usedApps: DesignEditorPlugin[]) {
    this.pluginsIsUsedInTheEditor$.next(isUsed);
    this.pluginsListUsedInTheEditor$.next(usedApps);
  }

  calculateServicePricePlanTax(servicePricePlanId: string, silentUpdate: boolean) {
    this.store.dispatch(calculatePricePlanTax({ servicePricePlanId, silentUpdate }));
  }

  fetchClientGoldEligibility(): void {
    this.store.dispatch(getIfClientEligibleForGoldSubscription());
  }
}
