import { BillingEntityStatus } from './billing-entity-status.enum';

export class BillingEntityUpdateRequest {
  constructor(
    public companyName: string,
    public companyNumber: string,
    public companyVatNumber: string,
    public countryIsoCode: string,
    public recipientName: string,
    public addressLine1: string,
    public addressLine2: string,
    public city: string,
    public postCode: string,
    public stateCode: string,
    public email: string,
    public phone: string,
    public currencyIsoCode: string,
    public status: BillingEntityStatus,
  ) {}
}
