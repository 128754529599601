import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VoucherApiService {
  constructor(private apiRequestService: ApiRequestService) {}

  public applyPromotion(clientId: string, promotionId: string, eventName: string): Observable<any> {
    return this.apiRequestService
      .post('/api/v1/vouchers/event', {
        body: {
          clientId,
          promotionId,
          eventName,
        },
      })
      .pipe(map(data => data.data));
  }

  public getListOfPromotions(clientId: string): Observable<any> {
    return this.apiRequestService
      .post('/api/v1/promotions/validation', {
        body: {
          customer: {
            source_id: clientId,
          },
          // 'order' property is needed for voucherify to return available discounts. 9900000 - is amount in EUR (99EUR), should be less than 100
          order: {
            items: [
              {
                amount: 990000,
              },
            ],
          },
        },
      })
      .pipe(map(data => data.data));
  }
}
