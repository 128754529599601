<div #origin class="menu-button" (click)="onOpenMenu(discountsListContent, origin)">
  <img class="icon" [src]="'icons/percent.svg' | assetsPath | async" alt="" />
</div>

<ng-template #discountsListContent>
  <div class="menu-content" [class.mobile]="isMobile">
    <div *ngIf="isMobile" class="deck-header">
      <h3>
        <img [src]="'icons/percent.svg' | assetsPath | async" width="28" height="28" />
        {{ 'txt_available_discounts_title' | translate }}
      </h3>
      <img (click)="onCloseMenu()" class="close" [src]="'checkout/close.svg' | assetsPath | async" alt="" />
    </div>
    <div class="menu-items" [class.mobile]="isMobile">
      <ng-container *ngFor="let discount of discounts; let i = index">
        <gd-discount-item [discount]="discount"> </gd-discount-item>
        <div class="ui divider horisontal" *ngIf="i !== discounts.length - 1"></div>
      </ng-container>
    </div>
  </div>
</ng-template>
