import { createAction, props } from '@ngrx/store';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import {
  ExpandCategoriesListItem,
  ExpandCategoriesListItemPreviews,
} from '@api-ui-app/src/app/e-commerce-expand-categories/lib/e-commerce-expand-categories';
import { SupportedPreviewScenesCollection } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/supported-preview-scenes-collection';
import { SelectedPreviewScenesCollection } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/selected-preview-scenes-collection';
import { Design } from '@gelato-api-ui/core/designs/design';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { ProductExpansionCategoryMappingsCollection } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/product-expansion-category-mappings-collection';

export const setSourceProduct = createAction(
  '[ExpandCategories] Set Source Product',
  props<{ sourceProduct: EProductWithVariants }>(),
);

export const setSourceProductVariants = createAction(
  '[ExpandCategories] Set Source Product Variants',
  props<{ sourceProductVariants: EProductVariant[] }>(),
);

export const updateExpandedProductStart = createAction('[ExpandCategories] Update Expanded Product Start');

export const updateExpandedProductFailed = createAction('[ExpandCategories] Update Expanded Product Failed');

export const updateExpandedProductSuccess = createAction(
  '[ExpandCategories] Update Expanded Product Success',
  props<{ expandedProduct: EProductWithVariants }>(),
);

export const setExistingDimensions = createAction(
  '[ExpandCategories] Set Existing Dimensions',
  props<{ existingDimensions: string[] }>(),
);

export const setDefaultNewDimension = createAction(
  '[ExpandCategories] Set Default New Dimension',
  props<{ defaultNewDimension: string }>(),
);

export const setSelectedDimension = createAction(
  '[ExpandCategories] Set Selected Dimension',
  props<{ selectedDimension: string }>(),
);

export const setNewDimensionValues = createAction(
  '[ExpandCategories] Set New Dimension Values',
  props<{ newDimensionValues: string[] }>(),
);

export const setDefaultNewDimensionValues = createAction(
  '[ExpandCategories] Set Default New Dimension Values',
  props<{ defaultNewDimensionValues: string[] }>(),
);

export const setMapping = createAction(
  '[ExpandCategories] Set Mapping',
  props<{ mapping: ProductExpansionCategoryMappingsCollection }>(),
);

export const setCategories = createAction(
  '[ExpandCategories] Set Categories',
  props<{ categories: ExpandCategoriesListItem[] }>(),
);

export const fetchCategoryPreviews = createAction(
  '[ExpandCategories] Fetch Category Previews',
  props<{ categories: ExpandCategoriesListItem[] }>(),
);

export const fetchCategoryPreviewsSuccess = createAction(
  '[ExpandCategories] Fetch Category Previews Success',
  props<{ payload: ExpandCategoriesListItemPreviews[] }>(),
);

export const selectCategory = createAction(
  '[ExpandCategories] Select Category',
  props<{ category: ExpandCategoriesListItem }>(),
);

export const fetchVariantOptionDesign = createAction(
  '[ExpandCategories] Fetch Variant Design',
  props<{ force: boolean }>(),
);

export const fetchVariantOptionDesignSuccess = createAction(
  '[ExpandCategories] Fetch Variant Design Success',
  props<{ design: Design }>(),
);

export const setVariantOptionPreview = createAction(
  '[ExpandCategories] Set Variant Option Preview',
  props<{ url: string }>(),
);

export const setCreatePreviewsFlag = createAction(
  '[ExpandCategories] Set Create Previews Flag',
  props<{ createPreviews: boolean }>(),
);

export const fetchSupportedPreviewScenes = createAction('[ExpandCategories] Fetch Supported Preview Scenes');

export const fetchSupportedPreviewScenesStart = createAction('[ExpandCategories] Fetch Supported Preview Scenes Start');

export const fetchSupportedPreviewScenesSuccess = createAction(
  '[ExpandCategories] Fetch Supported Preview Scenes Success',
  props<{ supportedPreviewScenes: SupportedPreviewScenesCollection }>(),
);

export const setSelectedPreviewScenes = createAction(
  '[ExpandCategories] Set Selected Preview Scenes',
  props<{ selectedPreviewScenes: SelectedPreviewScenesCollection }>(),
);

export const selectPreviewScene = createAction(
  '[ExpandCategories] Select Preview Scenes',
  props<{ productTypeUid: ProductTypeUid; previewScene: string }>(),
);

export const expandCategoriesPublish = createAction('[ExpandCategories] Publish');

export const clearExpandCategoriesState = createAction('[ExpandCategories] Clear State');

export const setProfitMargin = createAction('[ExpandCategories] Set Profit Margin', props<{ margin: number }>());
