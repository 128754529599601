import { SanityProductCategoryName } from './sanity-product-category-name.enum';

export const ApparelSanityProductCategoryNames = [
  SanityProductCategoryName.APPAREL,
  SanityProductCategoryName.T_SHIRTS,
  SanityProductCategoryName.HOODIES,
  SanityProductCategoryName.SWEATSHIRTS,
  SanityProductCategoryName.T_SHIRTS_MAN,
  SanityProductCategoryName.TANK_TOPS_MAN,
  SanityProductCategoryName.THREE_FOUR_SLEEVE_SHIRTS,
  SanityProductCategoryName.LONG_SLEEVE_SHIRTS,
  SanityProductCategoryName.MENS_SPORTSWEAR,
  SanityProductCategoryName.ORGANIC_CLOTHING,
  SanityProductCategoryName.WOMENS_TANK_TOPS,
  SanityProductCategoryName.KIDS_APPAREL,
  SanityProductCategoryName.T_SHIRTS_KIDS,
  SanityProductCategoryName.HOODIES_KIDS,
  SanityProductCategoryName.SWEATSHIRTS_KIDS,
  SanityProductCategoryName.KIDS_ORGANIC,
  SanityProductCategoryName.BABY_CLOTHING,
  SanityProductCategoryName.LONG_SLEEVE_SHIRTS_MEN,
  SanityProductCategoryName.MEN_SPORTSWEAR,
  SanityProductCategoryName.ORGANIC_CLOTHING_MEN,
  SanityProductCategoryName.HOODIES_MEN,
  SanityProductCategoryName.SWEATSHIRTS_MEN,
  SanityProductCategoryName.SWEATSHIRTS_WOMEN,
];
