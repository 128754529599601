import { Injectable } from '@angular/core';
import { DesignEditorPlugin } from '@gelato-api-ui/core/design-editor/design-editor-plugin.enum';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsHelperService {
  constructor(private readonly translateService: TranslateService) {}

  getGelatoPlusPromptDescription(isGelatoPlusActive: boolean, pluginName: DesignEditorPlugin) {
    if (!isGelatoPlusActive) {
      return this.getPromptPluginDescription(pluginName);
    }
  }

  getPromptPluginDescription(pluginName: DesignEditorPlugin) {
    switch (pluginName) {
      case DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION:
        return this.translateService.instant('txt_gelato_plus_prompt_personalization_description');
      case DesignEditorPlugin.APP_IMAGE_FILTERS:
        return this.translateService.instant('txt_gelato_plus_prompt_image_filters_description');
      case DesignEditorPlugin.APP_GRAPHICS:
        return this.translateService.instant('txt_gelato_plus_prompt_graphics_description');
      case DesignEditorPlugin.APP_GETTY_IMAGES:
        return this.translateService.instant('txt_gelato_plus_prompt_image_gallery_description');
      case DesignEditorPlugin.APP_TEXT_EFFECTS:
        return this.translateService.instant('txt_gelato_plus_prompt_text_effects_description');
      case DesignEditorPlugin.APP_BACKGROUND_REMOVAL:
        return this.translateService.instant('txt_gelato_plus_prompt_bg_removal_tool_description');
      default:
        return null;
    }
  }
}
