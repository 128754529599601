<div class="desktop">
  <div class="header"></div>
  <div class="body">
    <div class="sidebar">
      <div class="ghost-active-fluid sidebar-item" style="width: 50px"></div>
      <div class="ghost-active-fluid sidebar-item" style="width: 125px"></div>
      <div class="ghost-active-fluid sidebar-item" style="width: 50px"></div>
      <div class="ghost-active-fluid sidebar-item" style="width: 50px"></div>
      <div class="ghost-active-fluid sidebar-item" style="width: 75px"></div>
      <div class="ghost-active-fluid sidebar-item" style="width: 175px"></div>
      <div class="ghost-active-fluid sidebar-item" style="width: 50px"></div>
      <div class="ghost-active-fluid sidebar-item" style="width: 75px"></div>
      <div class="ghost-active-fluid sidebar-item" style="width: 100px"></div>
      <div class="ghost-active-fluid sidebar-item" style="width: 100px"></div>
    </div>
    <div class="content">
      <div class="ghost-active-fluid title"></div>
      <gd-dashboard-ghost></gd-dashboard-ghost>
    </div>
  </div>
</div>

<gd-dashboard-ghost class="mobile"></gd-dashboard-ghost>
