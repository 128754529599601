import { NgModule } from '@angular/core';
import { SharedModule } from '@api-ui-app/src/app/shared/shared.module';
import { ChargeBeeService } from '@api-ui-app/src/app/subscriptions/services/charge-bee.service';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { TryGelatoPlusNotificationComponent } from '@api-ui-app/src/app/subscriptions/components/try-gelato-plus-notification/try-gelato-plus-notification.component';
import { SubscriptionsSdkSharedModule } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/subscriptions-sdk-shared.module';
import { PlansComparisonTableComponent } from '@api-ui-app/src/app/subscriptions/components/plans-comparison-table/plans-comparison-table.component';
import { PlansComparisonTableCellComponent } from '@api-ui-app/src/app/subscriptions/components/plans-comparison-table-cell/plans-comparison-table-cell.component';
import { TableSectionGroupComponent } from './components/table-section-group/table-section-group.component';
import { ComparisonTableCellComponent } from '@api-ui-app/src/app/subscriptions/components/comparison-table-cell/comparison-table-cell.component';

const exportedComponents = [
  TryGelatoPlusNotificationComponent,
  PlansComparisonTableComponent,
  PlansComparisonTableCellComponent,
  TableSectionGroupComponent,
  ComparisonTableCellComponent,
];

@NgModule({
  declarations: [...exportedComponents],
  exports: [...exportedComponents],
  providers: [ChargeBeeService, SubscriptionsFacade],
  imports: [SharedModule, SubscriptionsSdkSharedModule],
})
export class SubscriptionsSharedModule {}
