import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { HelloBarFacade } from '@api-ui-app/src/app/hello-bar/hello-bar.facade';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'gd-hello-bar',
  templateUrl: './hello-bar.component.html',
  styleUrls: ['./hello-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideUp', [
      state('false', style({ transform: 'translateY(0)' })),
      state('true', style({ transform: 'translateY({{top}})' }), { params: { top: '-40px' } }),
      transition('false => true', [animate('1s ease-out')]),
    ]),
  ],
})
export class HelloBarComponent implements OnInit {
  helloBar$ = this.facade.helloBar$.pipe(filter(d => Boolean(d)));
  backgroundColor$ = this.helloBar$.pipe(map(d => d.backgroundColor));
  fontColor$ = this.helloBar$.pipe(map(d => d.fontColor));
  fontSize$ = this.helloBar$.pipe(map(d => d.fontSize));
  isHelloBarVisible$ = this.facade.isHelloBarVisible$;
  triggerAnimation$ = this.facade.triggerAnimation$;
  activeMessages$ = this.facade.activeMessages$;
  isMobile$ = this.facade.isMobile$;

  constructor(private readonly facade: HelloBarFacade) {}

  ngOnInit(): void {
    this.facade.loadData();
    this.facade.init();
  }

  onDismiss(key: string) {
    this.facade.onDismiss(key);
  }

  goTo(route: string, message: string) {
    this.facade.goTo(route, message);
  }

  onAnimationDone() {
    requestAnimationFrame(() => {
      this.triggerAnimation$.next(false);
      this.facade.setNewMessages();
    });
  }
}
