import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({ name: 'chileanRegionName' })
export class ChileanRegionNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(stateCode: string, showStateCode?: boolean): Observable<string> {
    const translationKey = `txt_chilean_region_${stateCode}`;

    return this.translateService.get(translationKey).pipe(
      map((name: string): string => {
        if (translationKey === name) {
          return stateCode;
        }

        if (showStateCode && stateCode) {
          return `${stateCode} - ${name}`;
        }

        return name;
      }),
    );
  }
}
