import { Injectable } from '@angular/core';
import {
  ServicePlanResponse,
  ServicePlanSearchRequest,
  ServicePlanSearchResponse,
} from '@api-ui-app/src/app/subscriptions/types/subscription-plans';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { Observable, of } from 'rxjs';
import {
  SubscriptionResponse,
  SubscriptionSearchRequest,
  SubscriptionSearchResponse,
  SubscriptionStatus,
} from '@api-ui-app/src/app/subscriptions/types/subscriptions';
import { SubscriptionPlansApiService } from '@api-ui-app/src/app/subscriptions/services/subscription-plans-api.service';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { SubscriptionsApiService } from '@api-ui-app/src/app/subscriptions/services/subscriptions-api.service';
import {
  DEFAULT_BILLING_PERIOD_UNIT,
  ServiceUniqueName,
} from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { getServicePlanIdsFromSubscriptions } from '@api-ui-app/src/app/subscriptions/lib/helpers/getServicePlanIdsFromSubscriptions';
import { getDefaultPlatformServicePlan } from '@api-ui-app/src/app/subscriptions/lib/helpers/getDefaultPlatformServicePlan';

export interface ActiveSubscriptionsAndServicePlansPayload {
  activeSubscriptions: SubscriptionResponse[];
  activeServicePlans: ServicePlanResponse[];
}

@Injectable()
export class ActiveSubscriptionsService {
  constructor(
    private readonly subscriptionsApiService: SubscriptionsApiService,
    private readonly subscriptionPlansApiService: SubscriptionPlansApiService,
    private readonly store$: Store<AppState>,
  ) {}

  fetchActiveSubscriptionsAndServicePlans(clientId: string): Observable<ActiveSubscriptionsAndServicePlansPayload> {
    return this.fetchActiveSubscriptions(clientId).pipe(
      switchMap((activeSubscriptions: SubscriptionResponse[]) =>
        this.fetchActiveServicePlans(activeSubscriptions).pipe(
          map((activeServicePlans: ServicePlanResponse[]): ActiveSubscriptionsAndServicePlansPayload => {
            const defaultPlatformServicePlan: ServicePlanResponse = getDefaultPlatformServicePlan(activeServicePlans);

            if (defaultPlatformServicePlan && activeSubscriptions) {
              const servicePlanIdsFromActiveSubscriptions = getServicePlanIdsFromSubscriptions(activeSubscriptions);

              if (!servicePlanIdsFromActiveSubscriptions?.includes(defaultPlatformServicePlan.id)) {
                activeSubscriptions?.push({
                  id: null,
                  clientId,
                  serviceId: null,
                  referenceId: null,
                  plan: {
                    servicePlanId: defaultPlatformServicePlan.id,
                    servicePlanPriceId: null,

                    unitPrice: null,
                    quantity: 1,
                    amount: null,
                    freeQuantity: 0,
                    trialEnd: null,
                    billingCycles: null,

                    createdAt: null,
                    updatedAt: null,
                  },
                  addons: [],
                  status: SubscriptionStatus.active,
                  nextBillingAt: null,
                  billingPeriod: null,
                  billingPeriodUnit: DEFAULT_BILLING_PERIOD_UNIT,
                  activatedAt: null,
                  startedAt: null,
                  cancelledAt: null,
                  createdAt: null,
                  updatedAt: null,
                  trialStart: null,
                  trialEnd: null,
                  trialEndAction: null,
                  remainingBillingCycles: null,
                  cancelScheduleCreatedAt: null,
                  currency: null,
                });
              }
            }

            return { activeSubscriptions, activeServicePlans };
          }),
        ),
      ),
    );
  }

  private fetchActiveSubscriptions(clientId: string): Observable<SubscriptionResponse[]> {
    if (!clientId) {
      return of(null);
    }

    const request: SubscriptionSearchRequest = {
      clientId,
      statuses: [
        SubscriptionStatus.active,
        SubscriptionStatus.in_trial,
        SubscriptionStatus.non_renewing,
        SubscriptionStatus.future,
        SubscriptionStatus.cancelled,
      ],
      offset: 0,
      limit: 200,
    };

    return this.subscriptionsApiService.searchSubscriptions(request).pipe(
      map((payload: SubscriptionSearchResponse): SubscriptionResponse[] => (payload ? payload.subscriptions : null)),
      catchError(() => {
        this.store$.dispatch(new ShowGeneralErrorNotification());

        return of(null);
      }),
    );
  }

  private fetchActiveServicePlans(activeSubscriptions: SubscriptionResponse[]): Observable<ServicePlanResponse[]> {
    const ids = getServicePlanIdsFromSubscriptions(activeSubscriptions);

    return this.fetchServicePlansByIds(ids).pipe(
      switchMap((activeServicePlans: ServicePlanResponse[]) => {
        if (!this.hasActivePlatformServicePlan(activeServicePlans)) {
          return this.fetchDefaultPlatformServicePlan().pipe(
            map((defaultPlatformServicePlan: ServicePlanResponse): ServicePlanResponse[] =>
              defaultPlatformServicePlan ? [defaultPlatformServicePlan, ...activeServicePlans] : activeServicePlans,
            ),
          );
        }

        return of(activeServicePlans);
      }),
      catchError(() => {
        this.store$.dispatch(new ShowGeneralErrorNotification());

        return of(null);
      }),
    );
  }

  private fetchServicePlansByIds(ids: string[]): Observable<ServicePlanResponse[]> {
    if (!ids || !ids.length) {
      return of([]);
    }

    const request: Partial<ServicePlanSearchRequest> = { ids };

    return this.subscriptionPlansApiService.searchSubscriptionPlans(request).pipe(
      map((payload: ServicePlanSearchResponse): ServicePlanResponse[] => (payload ? payload.plans : null)),
      catchError(() => {
        this.store$.dispatch(new ShowGeneralErrorNotification());

        return of([]);
      }),
    );
  }

  private fetchDefaultPlatformServicePlan(): Observable<ServicePlanResponse> {
    const request: Partial<ServicePlanSearchRequest> = {
      serviceUniqueNames: [ServiceUniqueName.GELATO_PLATFORM],
    };

    return this.subscriptionPlansApiService.searchSubscriptionPlans(request).pipe(
      map((payload: ServicePlanSearchResponse): ServicePlanResponse[] => (payload ? payload.plans : null)),
      map((servicePlans: ServicePlanResponse[]): ServicePlanResponse => getDefaultPlatformServicePlan(servicePlans)),
      catchError(() => {
        this.store$.dispatch(new ShowGeneralErrorNotification());

        return of(null);
      }),
    );
  }

  private hasActivePlatformServicePlan(activeServicePlans: ServicePlanResponse[]): boolean {
    const gelatoPlatformServicePlans: ServicePlanResponse[] = (activeServicePlans || []).filter(
      (loopServicePlan: ServicePlanResponse): boolean =>
        loopServicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM,
    );

    return gelatoPlatformServicePlans.length > 0;
  }
}
