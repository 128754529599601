import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ETSY_LINK_V1_ROUTE_PATH_SEGMENT } from '@api-ui-app/src/app/e-commerce-stores/constants/constants';
import { EtsyInitiateV1Request } from '@api-ui-app/src/app/e-commerce-stores/types/etsy-initiate-v1-request';
import { StorageService } from '@gelato-api-ui/sdk/src/lib/storage/storage.service';
import { WINDOW } from '@gelato-api-ui/core/window/window';
import { EtsyInitiateV1Response } from '@api-ui-app/src/app/e-commerce-stores/types/etsy-initiate-v1-response';
import { EtsyConnectV1Request } from '@api-ui-app/src/app/e-commerce-stores/types/etsy-connect-v1-request';
import { EStoreConnectResponse } from '@api-ui-app/src/app/e-commerce-stores/types/e-store-connect-response';
import { AbstractEtsyIntegrationVersionService } from '@api-ui-app/src/app/e-commerce-stores/services/abstract-etsy-integration-version.service';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { ApiResponse } from '@gelato-api-ui/core/api/api-response.interface';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';

@Injectable()
export class EtsyIntegrationV1Service extends AbstractEtsyIntegrationVersionService {
  private readonly tokenLocalStorageKey = 'etsy_connect_token';

  constructor(
    private readonly apiRequest: ApiRequestService,
    private readonly storageService: StorageService,
    @Inject(WINDOW) private window: Window,
  ) {
    super();
  }

  initiateConnection(): Observable<string> {
    const request: EtsyInitiateV1Request = { callbackUrl: this.initiateCallbackUrl };

    return this.apiRequest.get<EtsyInitiateV1Response>('/api/ecommerce/v1/etsy', { body: request }).pipe(
      catchError(error => {
        this.removeConnectToken();

        return throwError(error);
      }),
      map((data: ApiResponse<EtsyInitiateV1Response>): EtsyInitiateV1Response => data?.data),
      tap((response: EtsyInitiateV1Response) => {
        if (response?.token) {
          this.setConnectToken(response.token);
        } else {
          this.removeConnectToken();
        }
      }),
      map((response: EtsyInitiateV1Response): string => response?.url),
    );
  }

  connect(params: Params): Observable<EStore> {
    const request: EtsyConnectV1Request = {
      oauthToken: params.oauth_token,
      oauthVerifier: params.oauth_verifier,
      requestSecret: this.getConnectToken(),
    };

    return this.apiRequest
      .post<EStoreConnectResponse>('/api/ecommerce/v1/etsy/connect', {
        body: request,
      })
      .pipe(
        map((data: ApiResponse<EStoreConnectResponse>): EStoreConnectResponse => data?.data),
        map((response: EStoreConnectResponse) => response?.store),
        tap(() => {
          this.removeConnectToken();
        }),
      );
  }

  private get initiateCallbackUrl(): string {
    return `${this.window.location.origin}/stores/${ETSY_LINK_V1_ROUTE_PATH_SEGMENT}`;
  }

  private getConnectToken(): string {
    return this.storageService.getItem<string>(this.tokenLocalStorageKey);
  }

  private setConnectToken(token: string) {
    this.storageService.setItem(this.tokenLocalStorageKey, token);
  }

  private removeConnectToken() {
    this.storageService.removeItem(this.tokenLocalStorageKey);
  }
}
