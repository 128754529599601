import { RouterNavigationAction, routerReducer, RouterReducerState } from '@ngrx/router-store';
import { storeLogger } from 'ngrx-store-logger';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { InjectionToken } from '@angular/core';
import { RouterStateUrl } from '@gelato-api-ui/sdk/src/lib/router/custom-serializer';
import { reducer as fromLayout, State as LayoutReducerState } from './ngrx/layout.reducer';
import { reducer as fromAuth, State as AuthReducerState } from './ngrx/auth.reducer';
import {
  reducer as fromClientSelectionList,
  State as ClientSelectionListReducerState,
} from './ngrx/client-selection-list.reducer';
import {
  reducer as fromProductCatalogueSelectionList,
  State as ProductCatalogueSelectionListReducerState,
} from './ngrx/product-catalogue-selection-list.reducer';
import {
  reducer as fromPricePlanSelectionList,
  State as PricePlanSelectionListReducerState,
} from './ngrx/price-plan-selection-list.reducer';
import {
  reducer as PreviewsArchiveReducer,
  State as PreviewsArchiveState,
} from './previews-archive/+state/previews-archive.reducer';
import { State as OrdersReducerState } from './orders/+state/orders-state/orders.reducer';
import {
  reducer as fromOrderDetails,
  State as OrderDetailsReducerState,
} from './orders/+state/order-details/order-details.reducer';
import { reducer as fromOrderRefund, State as OrderRefundState } from './ngrx/order-refund.reducer';
import { State as ErrorReportReducerState } from './orders/+state/error-report/error-report.reducer';
import { reducer as fromUserRoles, State as UserRolesState } from './ngrx/user-roles.reducer';
import { reducer as fromUsers, State as UsersState } from './users/+state/users.reducer';
import { reducer as fromClients, State as ClientsReducerState } from './ngrx/clients.reducer';
import { reducer as fromApiKeys, State as ApiKeysReducerState } from './ngrx/api-keys.reducer';
import { reducer as fromNotifications, State as NotificationsReducerState } from './ngrx/notifications.reducer';
import { reducer as fromCompanyDetails, State as CompanyDetailsState } from './ngrx/company-details.reducer';
import { reducer as fromBillingEntities, State as BillingEntitiesState } from './ngrx/billing-entities.reducer';
import {
  reducer as ReturnAddresses,
  State as ReturnAddressesState,
} from './return-addresses/+state/return-addresses.reducer';
import { State as PricePlansState } from './price-plans/+state/price-plans.reducer';
import { reducer as fromDiscounts, State as DiscountsState } from './ngrx/discounts.reducer';
import {
  reducer as fromPricePlanSubscriptions,
  State as PricePlanSubscriptionsState,
} from './ngrx/price-plan-subscriptions.reducer';
import { reducer as fromAnalyticsState, State as AnalyticsState } from './analytics/+state/analytics.reducer';
import {
  reducer as fromProductionConfigurationsState,
  State as ProductionConfigurationsState,
} from './ngrx/production-configurations.reducer';
import {
  reducer as fromResaleCertificatesState,
  State as ResaleCertificatesState,
} from './ngrx/resale-certificates.reducer';
import {
  reducer as fromAccountActivationStepsState,
  State as AccountActivationStepsState,
} from './ngrx/account-activation-steps.reducer';
import { reducer as fromECommerceStoresState, State as ECommerceStoresState } from './ngrx/e-commerce-stores.reducer';
import {
  reducer as fromECommerceProductsState,
  State as ECommerceProductsState,
} from './ngrx/e-commerce-products.reducer';
import {
  reducer as fromECommerceProductWizardState,
  State as ECommerceProductWizardState,
} from './ngrx/e-commerce-product-wizard.reducer';
import { State as CheckoutState } from './checkout/+state/checkout.reducer';
import productCatalogueReducer, { ProductCatalogueState } from '@product-catalogue/src/lib/product-catalogue.state';
import {
  reducer as fromExpandCategoriesReducer,
  State as ExpandCategoriesState,
} from './e-commerce-expand-categories/+state/expand-categories.reducer';
import {
  reducer as fromSubscriptionsReducer,
  State as SubscriptionsState,
} from './subscriptions/+state/subscriptions.reducer';
import {
  reducer as PriceBreakdownReducer,
  State as PriceBreakdownState,
} from './shared/price-breakdown/+state/price-breakdown.reducer';
import { reducer as CountriesReducer, State as CountriesState } from './shared/countries/+state/countries.reducer';
import {
  reducer as fromProfitCalculatorReducer,
  State as ProfitCalculatorState,
} from './profit-calculator/+state/profit-calculator.reducer';

export type All = RouterNavigationAction;

export interface AppState {
  layout: LayoutReducerState;
  auth: AuthReducerState;
  clientSelectionList: ClientSelectionListReducerState;
  productCatalogueSelectionList: ProductCatalogueSelectionListReducerState;
  pricePlanSelectionList: PricePlanSelectionListReducerState;
  orders: OrdersReducerState;
  orderDetails: OrderDetailsReducerState;
  orderRefund: OrderRefundState;
  errorReport: ErrorReportReducerState;
  userRoles: UserRolesState;
  users: UsersState;
  clients: ClientsReducerState;
  apiKeys: ApiKeysReducerState;
  notifications: NotificationsReducerState;
  companyDetails: CompanyDetailsState;
  billingEntities: BillingEntitiesState;
  pricePlans: PricePlansState;
  pricePlanSubscriptions: PricePlanSubscriptionsState;
  analytics: AnalyticsState;
  productionConfigurations: ProductionConfigurationsState;
  resaleCertificates: ResaleCertificatesState;
  accountActivationSteps: AccountActivationStepsState;
  eCommerceStores: ECommerceStoresState;
  eCommerceProducts: ECommerceProductsState;
  eCommerceProductWizard: ECommerceProductWizardState;
  routerReducer: RouterReducerState<RouterStateUrl>;
  checkout: CheckoutState;
  productCatalogue: ProductCatalogueState;
  discounts: DiscountsState;
  expandCategories: ExpandCategoriesState;
  subscriptions: SubscriptionsState;
  previewsArchive: PreviewsArchiveState;
  priceBreakdown: PriceBreakdownState;
  countries: CountriesState;
  returnAddresses: ReturnAddressesState;
  profitCalculator: ProfitCalculatorState;
}

export const reducer = {
  layout: fromLayout,
  auth: fromAuth,
  clientSelectionList: fromClientSelectionList,
  productCatalogueSelectionList: fromProductCatalogueSelectionList,
  pricePlanSelectionList: fromPricePlanSelectionList,
  orderDetails: fromOrderDetails,
  orderRefund: fromOrderRefund,
  userRoles: fromUserRoles,
  users: fromUsers,
  clients: fromClients,
  apiKeys: fromApiKeys,
  notifications: fromNotifications,
  companyDetails: fromCompanyDetails,
  billingEntities: fromBillingEntities,
  pricePlanSubscriptions: fromPricePlanSubscriptions,
  analytics: fromAnalyticsState,
  productionConfigurations: fromProductionConfigurationsState,
  resaleCertificates: fromResaleCertificatesState,
  accountActivationSteps: fromAccountActivationStepsState,
  eCommerceStores: fromECommerceStoresState,
  eCommerceProducts: fromECommerceProductsState,
  eCommerceProductWizard: fromECommerceProductWizardState,
  routerReducer,
  productCatalogue: productCatalogueReducer,
  discounts: fromDiscounts,
  expandCategories: fromExpandCategoriesReducer,
  subscriptions: fromSubscriptionsReducer,
  previewsArchive: PreviewsArchiveReducer,
  priceBreakdown: PriceBreakdownReducer,
  countries: CountriesReducer,
  returnAddresses: ReturnAddresses,
  profitCalculator: fromProfitCalculatorReducer,
};

export function logger(actionReducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return storeLogger()(actionReducer);
}

export const reducerToken = new InjectionToken<ActionReducerMap<AppState>>('Registered Reducers');
