import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BreadcrumbItem } from '@gelato-api-ui/core/breadcrumbs/breadcrumb-item';

@Component({
  selector: 'lib-breadcrumbs-headless',
  templateUrl: './breadcrumbs-headless.component.html',
  styleUrls: ['./breadcrumbs-headless.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsHeadlessComponent implements OnInit {
  @Input() items: BreadcrumbItem[] = [];

  ngOnInit() {}
}
