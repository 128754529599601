import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ResponsiveService } from '@gelato-api-ui/core/responsive/responsive.service';

@Component({
  selector: 'gc-ecommerce-product-wizard-back-button',
  templateUrl: './ecommerce-product-wizard-back-button.component.html',
  styleUrls: ['./ecommerce-product-wizard-back-button.component.scss'],
})
export class EcommerceProductWizardBackButtonComponent implements OnInit, OnDestroy {
  @Input() text: string;
  @Output() click = new EventEmitter<void>();

  isMobile$ = this.responsive.isMobile$;

  constructor(private readonly responsive: ResponsiveService) {}

  ngOnInit() {
    this.responsive.onScreenSizeChanges();
  }

  ngOnDestroy() {
    this.responsive.onRemoveScreenSizeChanges();
  }

  onClick(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.click.emit();
  }
}
