import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ProductVariantIdsCollection } from '@api-ui-app/src/app/product/product-add/types/product-variant-ids-collection';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductTreeItem } from '@api-ui-app/src/app/product/product-add/types/e-product-tree-item';
import { AddStoreProductFlowService } from '@api-ui-app/src/app/shared/services/add-store-product-flow.service';
import { ECommerceProductsTreeCheckedProductVariantsService } from '@api-ui-app/src/app/product/product-add/services/e-commerce-products-tree-checked-product-variants.service';

@Component({
  selector: 'gd-store-products-list-modal-content',
  templateUrl: './store-products-list-modal-content.component.html',
  styleUrls: ['./store-products-list-modal-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreProductsListModalContentComponent {
  @Input() selectedStore: EStore = null;
  @Input() productsTree: EProductTreeItem[] = [];
  @Input() isLoading = false;
  @Input() productSearchQuery: string = null;
  @Input() delayedProductSearchQuery: string = null;
  @Input() checkedProductVariantIds: ProductVariantIdsCollection = {};
  @Input() checkedProducts: string[] = [];
  @Input() maxCheckedItemsCount: number = null;
  @Input() multipleSelectionEnabled: boolean;
  @Input() isSelectVariants: boolean;
  @Input() splashMessageId: string;
  @Output() backButtonClick: EventEmitter<void> = new EventEmitter<void>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();
  @Output() productSearchQueryChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() checkedProductVariantIdsChange = new EventEmitter<ProductVariantIdsCollection>();
  @Output() checkedProductsChange = new EventEmitter<string[]>();
  @Output() chooseProducts: EventEmitter<void> = new EventEmitter<void>();
  @Output() productsListScrollDown: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private readonly addStoreProductFlowService: AddStoreProductFlowService,
    private readonly eCommerceProductsTreeCheckedProductVariantsService: ECommerceProductsTreeCheckedProductVariantsService,
  ) {}

  get checkedProductVariantsCount(): number {
    return this.eCommerceProductsTreeCheckedProductVariantsService.getCheckedProductVariantIdsCount(
      this.checkedProductVariantIds,
    );
  }

  get hasCheckedProductVariants(): boolean {
    return this.checkedProductVariantsCount > 0;
  }

  get checkedProductsCount(): number {
    return this.checkedProducts.length;
  }

  get showSelectedCountLabel(): boolean {
    return !this.maxCheckedItemsCount || this.checkedProductVariantsCount <= this.maxCheckedItemsCount;
  }
  get showLimitExceededLabel(): boolean {
    return this.maxCheckedItemsCount && this.checkedProductVariantsCount > this.maxCheckedItemsCount;
  }

  get selectedCountLabelTextKey(): string {
    return this.checkedProductVariantsCount === 1
      ? 'txt_selected_variants_count_label_singular'
      : 'txt_selected_variants_count_label_plural';
  }

  get limitExceededLabelTextKey(): string {
    return 'txt_selected_variants_count_label_plural_limit_exceeded';
  }

  get footerLabelVariantsTextKey(): string {
    if (this.checkedProductVariantsCount) {
      if (this.showSelectedCountLabel) {
        return this.selectedCountLabelTextKey;
      } else if (this.showLimitExceededLabel) {
        return this.limitExceededLabelTextKey;
      }
    }
    return '';
  }

  get footerLabelProductsTextKey(): string {
    return '';
  }

  get footerLabelTextKey(): string {
    if (!this.multipleSelectionEnabled) {
      return 'txt_single_variant_selection_mode_hint';
    }
    if (this.isSelectVariants) {
      return this.footerLabelVariantsTextKey;
    } else {
      return this.footerLabelProductsTextKey;
    }
  }

  get canSubmitVariants(): boolean {
    return (
      this.hasCheckedProductVariants &&
      (!this.maxCheckedItemsCount || this.checkedProductVariantsCount <= this.maxCheckedItemsCount)
    );
  }

  get canSubmitProducts(): boolean {
    return (
      this.checkedProductsCount &&
      (!this.maxCheckedItemsCount || this.checkedProductsCount <= this.maxCheckedItemsCount)
    );
  }

  get canSubmit(): boolean {
    return this.isSelectVariants ? this.canSubmitVariants : this.canSubmitProducts;
  }

  onBackButtonClick() {
    this.backButtonClick.emit();
  }

  requestModalClose() {
    this.closeModal.emit();
  }

  addStoreProduct() {
    this.requestModalClose();
    this.addStoreProductFlowService.initiate(this.selectedStore);
  }

  onProductSearchQueryChange(query: string) {
    this.productSearchQueryChange.emit(query);
  }

  onCheckedProductVariantIdsChange(checkedProductVariantIds: ProductVariantIdsCollection) {
    this.checkedProductVariantIdsChange.emit(checkedProductVariantIds);
  }

  onCheckedProductsChange(products: string[]) {
    this.checkedProductsChange.emit(products);
  }

  onChooseProductsButtonClick() {
    this.chooseProducts.emit();
  }

  onProductsListScrollDown() {
    this.productsListScrollDown.emit();
  }
}
