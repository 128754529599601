import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { getAssetsAutorNamesFromProductVariants } from '@gelato-api-ui/core/designs/helpers/getAssetsAutorNamesFromProductVariants';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { AssetAuthorsLabelService } from './asset-authors-label.service';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductDescriptionService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly assetAuthorsLabelService: AssetAuthorsLabelService,
  ) {}

  compileProductDescription(
    sourceDescription: string,
    assets: AssetsCollection,
    productVariants: EProductVariant[],
    htmlDescriptionSupported: boolean,
    sizeGuideCmTable: string,
    sizeGuideInchTable: string,
    productCareInstructions: string,
  ): string {
    const assetAuthors = getAssetsAutorNamesFromProductVariants(assets, productVariants);
    const assetAuthorsLabel = this.assetAuthorsLabelService.get(assetAuthors);

    let description: string = sourceDescription;

    if (assetAuthorsLabel) {
      description += htmlDescriptionSupported ? `\n<br><br>${assetAuthorsLabel}` : `\n\n${assetAuthorsLabel}`;
    }

    if (htmlDescriptionSupported) {
      if (sizeGuideCmTable || sizeGuideInchTable) {
        if (description) {
          description += '\n<br><br>\n';
        }

        description += `<p><strong>${this.translateService.instant('txt_size_guide')}</strong></p>\n`;

        if (sizeGuideCmTable) {
          description += '\n<br><br>\n';
          description += sizeGuideCmTable;
        }

        if (sizeGuideInchTable) {
          description += '\n<br><br>\n';
          description += sizeGuideInchTable;
        }
      }

      if (productCareInstructions) {
        if (description) {
          description += '\n<br><br>\n';
        }

        description += productCareInstructions;
      }
    }

    return description;
  }
}
