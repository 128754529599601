<div class="container">
  <header class="header-content">
    <h2 class="title">{{ 'txt_download_gelato_app' | translate }}</h2>
    <div class="description">{{ 'txt_download_gelato_app_description' | translate }}</div>
  </header>
  <div class="content">
    <div class="app-reference">
      <img [src]="'sdk/app-qr.png' | assetsPath | async" alt="ios-qr-code" />
      <div class="app-links">
        <a [href]="nativeAppLink" target="_blank">
          <img
            class="store-button"
            [src]="'sdk/app-store-button.svg' | assetsPath | async"
            width="147"
            height="47"
            alt="ios-store-button"
          />
        </a>
        <a [href]="nativeAppLink" target="_blank">
          <img
            class="store-button"
            [src]="'sdk/google-play-button.svg' | assetsPath | async"
            width="147"
            height="47"
            alt="google-play-button"
          />
        </a>
      </div>
    </div>
  </div>
  <footer class="footer">
    <button class="ui primary button" (click)="onApprove()">{{ 'txt_got_it' | translate }}</button>
  </footer>
</div>
