<div *ngIf="isReconnectionRequired$ | async" class="container">
  <div class="content">
    <img src="{{ 'icons/warning-orange.svg' | assetsPath | async }}" />
    <div class="message">
      <p class="title">{{ title$ | async }}</p>
      <div [innerHTML]="description$ | async"></div>
    </div>
  </div>
  <div *ngIf="canReconnect$ | async" class="action">
    <a class="upgrade-action" (click)="onReconnectButtonClick()">
      {{ 'txt_store_update_notification_action_button_label' | translate }}
    </a>
  </div>
</div>
