import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '../app.state';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import {
  StoreProductPreviewsCollection,
  StoreProductVariantPreviewsCollection,
} from '@product-catalogue/src/lib/product-catalogue/services/store-product-variants.service';
import { key2SceneName } from '@gelato-api-ui/core/e-commerce/helpers/key2SceneName';
import {
  BulkConnectState,
  BulkConnectVariantsMappingPayload,
  DesignAssetCostsCollection,
  PriceCollection,
  SelectedPreviewScenesCollection,
  SelectedProductVariantKeysCollection,
  State,
  StoreProductVariantsCollection,
} from './e-commerce-product-wizard.reducer';
import { EProduct } from '@gelato-api-ui/core/e-commerce/e-product';
import { EProductDetails } from '@gelato-api-ui/core/e-commerce/e-product-details';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { CategoryDesignFamilyResponse } from '@gelato-api-ui/core/designs/category-design-family-response';
import { isApparelOrAccessoriesProductCategory } from '@product-catalogue/src/lib/ngrx/product-catalogue.reducer';
import { calculateTotalDesignAssetsCost } from './helpers/calculateTotalDesignAssetsCost';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EProductVariantConnectionStatus } from '@gelato-api-ui/core/e-commerce/e-product-variant-connection-status.enum';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';
import { getCurrentTimeStamp } from '@gelato-api-ui/core/datetime/helpers/getCurrentTimeStamp';
import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';
import { getMetadataItemValue } from '@gelato-api-ui/core/metadata/helpers/getMetadataItemValue';
import { EProductMetadataKey } from '@gelato-api-ui/core/e-commerce/e-product-metadata-key.enum';

export const getState = (state: AppState): State => state.eCommerceProductWizard;

export const getDestinationProductId = createSelector(getState, (state: State): string => state.destinationProductId);

export const getDestinationProduct = createSelector(
  getState,
  (state: State): EProductWithVariants => state.destinationProduct,
);

export const getDestinationProductVariants = createSelector(
  getState,
  (state: State): EProductVariant[] => (state.destinationProduct ? state.destinationProduct.variants : null) || [],
);

export const getBulkConnectState = createSelector(getState, (state: State): BulkConnectState => state.bulkConnect);

export const getBulkConnectDestinationProductVariants = createSelector(
  getDestinationProductVariants,
  (destinationProductVariants: EProductVariant[]): EProductVariant[] =>
    destinationProductVariants.filter(
      (loopProductVariant: EProductVariant) =>
        loopProductVariant.connectionStatus === EProductVariantConnectionStatus.NOT_CONNECTED,
    ),
);

export const getBulkConnectDefaultProductVariantsMappingIsLoading = createSelector(
  getBulkConnectState,
  (bulkConnectState: BulkConnectState): boolean => bulkConnectState.defaultMapping.isLoading,
);

export const getBulkConnectDefaultProductVariantsMappingPayload = createSelector(
  getBulkConnectState,
  (bulkConnectState: BulkConnectState): BulkConnectVariantsMappingPayload => bulkConnectState.defaultMapping.payload,
);

export const getBulkConnectCustomProductVariantsMappingPayload = createSelector(
  getBulkConnectState,
  (bulkConnectState: BulkConnectState): BulkConnectVariantsMappingPayload => bulkConnectState.customMapping,
);

export const getBulkConnectMappedProductVariantsCount = createSelector(
  getBulkConnectCustomProductVariantsMappingPayload,
  (mappingPayload: BulkConnectVariantsMappingPayload): number =>
    Object.keys(mappingPayload).filter((key: string) => Boolean(mappingPayload[key])).length,
);

export const getBulkConnectAutomaticallyConnectedProductVariantsCount = createSelector(
  getBulkConnectDefaultProductVariantsMappingPayload,
  getBulkConnectCustomProductVariantsMappingPayload,
  (defaultMapping: BulkConnectVariantsMappingPayload, customMapping: BulkConnectVariantsMappingPayload): number => {
    const defaultMappingParis = R.toPairs(defaultMapping);
    const customMappingPairs = R.toPairs(customMapping);

    return R.intersection(customMappingPairs, defaultMappingParis).filter(pair => Boolean(pair[1])).length;
  },
);

export const getBulkConnectManuallyConnectedProductVariantsCount = createSelector(
  getBulkConnectDefaultProductVariantsMappingPayload,
  getBulkConnectCustomProductVariantsMappingPayload,
  (defaultMapping: BulkConnectVariantsMappingPayload, customMapping: BulkConnectVariantsMappingPayload): number => {
    const defaultMappingParis = R.toPairs(defaultMapping);
    const customMappingPairs = R.toPairs(customMapping);

    return R.difference(customMappingPairs, defaultMappingParis).filter(pair => Boolean(pair[1])).length;
  },
);
export const getBulkConnectCreatePreviewFlag = createSelector(
  getBulkConnectState,
  (bulkConnectState: BulkConnectState): boolean => bulkConnectState.createPreviews,
);

export const getProduct = createSelector(getState, (state: State): EProduct => state.product);

export const getProductTitle = createSelector(getProduct, (product: EProduct): string => product?.title);

export const getProductDescription = createSelector(getProduct, (product: EProduct): string => product?.description);

export const getProductMetadata = createSelector(getProduct, (product: EProduct): MetadataItem[] => product?.metadata);

export const getProductTemplateId = createSelector(
  getProductMetadata,
  (productMetadata: MetadataItem[]): string =>
    getMetadataItemValue(productMetadata, EProductMetadataKey.TEMPLATE_ID, true) as string,
);

export const getProductTemplateName = createSelector(getProduct, (product: EProduct): string => product?.templateName);

export const getProductDetails = createSelector(
  getState,
  (state: State): EProductDetails => ({
    product: state.product,
    attachSizeGuideCmTable: state.attachSizeGuideCmTable,
    attachSizeGuideInchTable: state.attachSizeGuideInchTable,
    attachProductCareInstructions: state.attachProductCareInstructions,
    publishImmediately: state.publishImmediately,
    publishWithFreeShipping: state.publishWithFreeShipping,
    attachIllustrationSteps: state.attachIllustrationSteps,
    hasFlatShippingPriceType: state.productDetails?.hasFlatShippingPriceType,
  }),
);

const getSelectedProductVariantKeys = createSelector(
  getState,
  (state: State): SelectedProductVariantKeysCollection => state.selectedProductVariantKeys,
);

const getProductVariantKeysArray = createSelector(getState, (state: State): string[] => state.productVariantKeysArray);

export const getDefaultProductUid = createSelector(getState, (state: State): string => state.defaultProductUid);

export const getDefaultPageCount = createSelector(getState, (state: State): number => state.defaultPageCount);

export const getPageCount = createSelector(getState, (state: State): number => state.pageCount);

export const getSelectedProductVariantKeysCollection = createSelector(
  isApparelOrAccessoriesProductCategory,
  getSelectedProductVariantKeys,
  getProductVariantKeysArray,
  (
    apparelOrAccessoriesProductCategory: boolean,
    selectedProductVariantKeys: SelectedProductVariantKeysCollection,
    productVariantKeysArray: string[],
  ): SelectedProductVariantKeysCollection => {
    if (apparelOrAccessoriesProductCategory) {
      return productVariantKeysArray.reduce(
        (
          acc: SelectedProductVariantKeysCollection,
          productVariantKey: string,
        ): SelectedProductVariantKeysCollection => ({ ...acc, [productVariantKey]: true }),
        {},
      );
    }

    return selectedProductVariantKeys;
  },
);

export const getSelectedProductVariantKeysArray = createSelector(
  getSelectedProductVariantKeysCollection,
  (selectedProductVariantKeysCollection: SelectedProductVariantKeysCollection) =>
    Object.keys(selectedProductVariantKeysCollection).reduce((acc: string[], productVariantKey: string): string[] => {
      if (selectedProductVariantKeysCollection[productVariantKey]) {
        return [...acc, productVariantKey];
      }

      return acc;
    }, []),
);

export const getStoreProductVariantsCollection = createSelector(
  getState,
  (state: State): StoreProductVariantsCollection => state.storeProductVariants,
);

export const getOriginalStoreProductVariantsCollection = createSelector(
  getState,
  (state: State): StoreProductVariantsCollection => state.originalStoreProductVariants,
);

export const getSelectedPreviewFileType = createSelector(
  getState,
  (state: State): PreviewFileType => state.selectedPreviewFileType,
);

export const getSupportedPreviewScenes = createSelector(
  getState,
  (state: State): string[] => state.supportedPreviewScenes,
);

export const getSelectedPreviewScenes = createSelector(
  getState,
  (state: State): SelectedPreviewScenesCollection => state?.selectedPreviewScenes || {},
);

export const getSelectedPreviewScenesArray = createSelector(
  getSelectedPreviewScenes,
  (selectedPreviewScenes: SelectedPreviewScenesCollection): string[] => {
    if (!selectedPreviewScenes) {
      return [];
    }

    const selectedPreviewScenesArray = [];

    R.keys(selectedPreviewScenes).forEach((key: string) => {
      if (selectedPreviewScenes[key]) {
        selectedPreviewScenesArray.push(key2SceneName(key));
      }
    });

    return selectedPreviewScenesArray;
  },
);

export const getSelectedPreviewScenesCount = createSelector(
  getSelectedPreviewScenesArray,
  (selectedPreviewScenesArray: string[]): number => selectedPreviewScenesArray.length,
);

export const getPrimaryPreviewScene = createSelector(getState, (state: State): string => state.primaryPreviewScene);

export const getPrimaryPreviewProductVariantKey = createSelector(
  getState,
  (state: State): string => state.primaryPreviewProductVariantKey,
);

export const getAttachSizeGuideCmTableFlag = createSelector(
  getState,
  (state: State): boolean => state.attachSizeGuideCmTable,
);

export const getAttachSizeGuideInchTableFlag = createSelector(
  getState,
  (state: State): boolean => state.attachSizeGuideInchTable,
);

export const getAttachSizeGuideFlag = createSelector(
  getAttachSizeGuideCmTableFlag,
  getAttachSizeGuideInchTableFlag,
  (attachSizeGuideCmTable: boolean, attachSizeGuideInchTable: boolean): boolean =>
    attachSizeGuideCmTable || attachSizeGuideInchTable,
);

export const getAttachProductCareInstructionsFlag = createSelector(
  getState,
  (state: State): boolean => state.attachProductCareInstructions,
);

export const getActivePriceFetchingRequestsCount = createSelector(
  getState,
  (state: State): number => state.activePriceFetchingRequestsCount,
);

export const isFetchingProductCosts = createSelector(
  getActivePriceFetchingRequestsCount,
  (activePriceFetchingRequestsCount: number): boolean => activePriceFetchingRequestsCount > 0,
);

export const getStoreProductVariantByProductUid = (productUid: string) =>
  createSelector(getStoreProductVariantsCollection, collection => collection[productUid]);

export const getPublishImmediatelyFlag = createSelector(getState, (state: State): boolean => state.publishImmediately);

export const getPublishWithFreeShippingFlag = createSelector(
  getState,
  (state: State): boolean => state.publishWithFreeShipping,
);

export const getProductPreviewsCollection = createSelector(
  getState,
  (state: State): StoreProductPreviewsCollection => state.productPreviewsCollection,
);

export const getProductVariantPreviewsCollection = createSelector(
  getState,
  (state: State): StoreProductVariantPreviewsCollection => state.productVariantPreviewsCollection,
);

export const getProductCosts = createSelector(getState, (state: State): PriceCollection => state.productCosts);

export const getProductShippingCosts = createSelector(
  getState,
  (state: State): PriceCollection => state.productShippingCosts,
);

export const getProductCostsForSelectedProductVariantKeys = createSelector(
  getProductCosts,
  getSelectedProductVariantKeysArray,
  (productCosts: PriceCollection, selectedProductVariantKeysArray: string[]): PriceCollection =>
    R.pick(selectedProductVariantKeysArray, productCosts),
);

export const getProductShippingCostsForSelectedProductVariantKeys = createSelector(
  getProductShippingCosts,
  getSelectedProductVariantKeysArray,
  (productCosts: PriceCollection, selectedProductVariantKeysArray: string[]): PriceCollection =>
    R.pick(selectedProductVariantKeysArray, productCosts),
);

export const getDesignAssetCosts = createSelector(
  getState,
  (state: State): DesignAssetCostsCollection => state.designAssetCosts,
);

export const getDesignAssetCostsForSelectedProductVariantKeys = createSelector(
  getDesignAssetCosts,
  getSelectedProductVariantKeysArray,
  (
    designAssetCosts: DesignAssetCostsCollection,
    selectedProductVariantKeysArray: string[],
  ): DesignAssetCostsCollection => R.pick(selectedProductVariantKeysArray, designAssetCosts),
);

export const getDesignTemplateCosts = createSelector(
  getState,
  (state: State): PriceCollection => state.designTemplateCosts,
);

export const getDesignTemplateCostsForSelectedProductVariantKeys = createSelector(
  getDesignTemplateCosts,
  getSelectedProductVariantKeysArray,
  (designTemplateCosts: PriceCollection, selectedProductVariantKeysArray: string[]): PriceCollection =>
    R.pick(selectedProductVariantKeysArray, designTemplateCosts),
);

export const getTotalCosts = createSelector(
  getProductCosts,
  getDesignAssetCosts,
  getDesignTemplateCosts,
  (
    productCosts: PriceCollection,
    designAssetCosts: DesignAssetCostsCollection,
    designTemplateCosts: PriceCollection,
  ): PriceCollection =>
    Object.keys(productCosts).reduce(
      (acc: PriceCollection, productUid: string) => ({
        ...acc,
        [productUid]:
          (productCosts[productUid] || 0) +
          calculateTotalDesignAssetsCost(designAssetCosts[productUid]) +
          (designTemplateCosts[productUid] || 0),
      }),
      {},
    ),
);

export const getTotalCostsForSelectedProductVariantKeys = createSelector(
  getState,
  getTotalCosts,
  getSelectedProductVariantKeysArray,
  getBulkConnectCustomProductVariantsMappingPayload,
  (
    state: State,
    totalCosts: PriceCollection,
    selectedProductVariantKeysArray: string[],
    mapping: BulkConnectVariantsMappingPayload,
  ): PriceCollection => {
    const usedProductVariantKeys = Object.values(mapping || {});
    const productVariantKeys =
      state.mode === EProductWizardMode.BULK_CONNECT
        ? selectedProductVariantKeysArray.filter((loopProductVariantKey: string): boolean =>
            usedProductVariantKeys.includes(loopProductVariantKey),
          )
        : selectedProductVariantKeysArray;

    return R.pick(productVariantKeys, totalCosts);
  },
);

export const getTotalCostsArrayForSelectedProductVariantKeys = createSelector(
  getTotalCostsForSelectedProductVariantKeys,
  (totalCosts: PriceCollection): number[] => Object.values(totalCosts),
);

export const getPrices = createSelector(getState, (state: State): PriceCollection => state.prices);

export const getPricesForSelectedProductVariantKeys = createSelector(
  getPrices,
  getSelectedProductVariantKeysArray,
  (prices: PriceCollection, selectedProductVariantKeysArray: string[]): PriceCollection =>
    R.pick(selectedProductVariantKeysArray, prices),
);

export const isFetchingDesignAssetCosts = createSelector(
  getState,
  (state: State): boolean => state.fetchingDesignAssetCosts,
);

export const isFetchingDesignTemplateCosts = createSelector(
  getState,
  (state: State): boolean => state.fetchingDesignTemplateCosts,
);

export const getDesignFamily = createSelector(
  getState,
  (state: State): CategoryDesignFamilyResponse => state.designFamily,
);

export const getMode = createSelector(getState, (state: State): EProductWizardMode => state.mode);

export const getIsTemplateMode = createSelector(getMode, (mode: EProductWizardMode): boolean =>
  [EProductWizardMode.CREATE_PRODUCT_TEMPLATE, EProductWizardMode.EDIT_PRODUCT_TEMPLATE].includes(mode),
);

export const getStartedAt = createSelector(getState, (state: State): number => state.startedAt);

export const getDuration = createSelector(
  getStartedAt,
  (startedAt: number): number => (getCurrentTimeStamp() - startedAt) / 1000,
);

export const isSavingDraft = createSelector(getState, (state: State): boolean => state.isSavingDraft);

export const getHasDraft = createSelector(getState, (state: State): boolean => state.product?.hasDraft);

export const getLatestSavedDraftHash = createSelector(getState, (state: State): string => state.latestSavedDraftHash);

export const getActiveStep = createSelector(getState, (state: State): ECommerceProductWizardStep => state.activeStep);

export const getDownloadPreviewsAmount = createSelector(
  getSelectedProductVariantKeysArray,
  getSelectedPreviewScenesArray,
  (productVariantKeysArray, previewScenes) => productVariantKeysArray.length * previewScenes.length,
);

export const getIsSavingTemplate = createSelector(getState, (state: State): boolean => state.isSavingTemplate);

export const getSelectedCountry = createSelector(getState, (state: State): string => state.selectedCountry);
