import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first, take, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppState } from '@api-ui-app/src/app/app.state';
import * as assetsMappingActions from '@product-catalogue/src/lib/ngrx/assets.actions';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { AssetProviderUid } from '@gelato-api-ui/core/designs/asset-provider-uid.enum';
import { ShutterstockImage } from '@gelato-api-ui/core/shutterstock/shutterstock-image';
import { environment } from '@api-ui-app/src/environments/environment';
import { DesignEditorTabName } from '@gelato-api-ui/core/design-editor/design-editor-tab-name.enum';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { DesignRendersLoaderService } from '@gelato-api-ui/core/design-renders-loader/services/design-renders-loader.service';
import { EditorInboundEventType } from '@gelato-api-ui/core/design-editor/editor-inbound-event-type.enum';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { EStoreFeature } from '@gelato-api-ui/core/e-commerce/e-store-feature.enum';
import { ECommerceStoreFeaturesService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-store-features.service';
import { DesignEditorPreviewSettings } from '@gelato-api-ui/core/design-editor/design-editor-preview-settings';
import { DesignEditorPreviewSettingsService } from '@gelato-api-ui/core/design-editor/services/design-editor-preview-settings.service';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import {
  PluginDataState,
  SetPluginsEventDataItem,
} from '@gelato-api-ui/core/design-editor/set-plugins-event-data-item';
import { DesignEditorPlugin } from '@gelato-api-ui/core/design-editor/design-editor-plugin.enum';
import { EditorOutboundEventType } from '@gelato-api-ui/core/design-editor/editor-outbound-event-type.enum';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { ChameleonUserIdentification } from '@gelato-api-ui/core/chameleon/chameleon-user-identification';
import { ChameleonFacade } from '@gelato-api-ui/core/chameleon/services/chameleon.facade';
import { DESIGN_EDITOR_BACKGROUND_COLOR } from '@gelato-api-ui/core/design-editor/desgin-editor-background-color';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { isEmptyDesignStructure } from '@gelato-api-ui/core/designs/helpers/isEmptyDesignStructure';
import { getUsedMediaToTrack } from '@gelato-api-ui/core/designs/helpers/getUsedMediaToTrack';
import { KeycloakAuthService } from '@api-ui-app/src/app/shared/services/keycloak-auth.service';
import { getAppCrownPremiumLevel } from '@api-ui-app/src/app/subscriptions/lib/helpers/visited-apps-local-storage-manager';
import { PremiumIconCrownedFeatures } from '@api-ui-app/src/app/subscriptions/types/icon-crowned-features.enum';
import { isProductTabSupportedPerProductTypeUid } from '@gelato-api-ui/core/sanity/helpers/isProductTabSupported';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { isMirroringSupportedByProductTypeUid } from '@gelato-api-ui/core/sanity/helpers/isMirroringSupported';
import { ApparelProductTypeUids } from '@gelato-api-ui/core/product-catalogue/product-type-uids';
import { WINDOW } from '@gelato-api-ui/core/window/window';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';
import { DesignEditorApplicationsService } from '@product-catalogue/src/lib/product-catalogue/services/design-editor-applications.service';
import { DesignEditorZoomService } from '@product-catalogue/src/lib/product-catalogue/services/design-editor-zoom.service';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { DesignPreviewShutterstockService } from '@product-catalogue/src/lib/product-catalogue/services/design-preview-shutterstock.service';
import { ProductTypeUidService } from '@gelato-api-ui/core/product-catalogue/services/product-type-uid.service';
import { PreflightApiService } from '@gelato-api-ui/core/preflight/services/preflight-api.service';

export enum DesignEditorMode {
  ORDER_ITEM_CREATION = 'order_item_creation',
  PRODUCT_CREATION = 'product_creation',
  PRODUCT_EDITING = 'product_editing',
  PRODUCT_VARIANT_CONNECTION = 'product_variant_connection',
  PRODUCT_VARIANT_EDITING = 'product_variant_editing',
}

export const CATEGORY_WITH_PAGE_COUNTS: Set<string> = new Set([
  SanityProductCategoryName.MULTIPAGE_BROCHURES,
  SanityProductCategoryName.NOTEBOOKS,
  SanityProductCategoryName.HARD_COVER_PHOTOBOOKS,
  SanityProductCategoryName.SOFT_COVER_PHOTOBOOKS,
  SanityProductCategoryName.PHOTOBOOKS,
]);

const DEFAULT_TABS_VISIBILITY: { [tabName in DesignEditorTabName]: boolean } = {
  [DesignEditorTabName.PRODUCT]: false,
  [DesignEditorTabName.VARIANTS]: false,
  [DesignEditorTabName.LAYERS]: true,
  [DesignEditorTabName.GALLERY]: true,
  [DesignEditorTabName.TEXTS]: true,
  [DesignEditorTabName.SHAPES]: false,
  [DesignEditorTabName.TEMPLATES]: false,
  [DesignEditorTabName.WARNINGS]: true,
  [DesignEditorTabName.LAYOUTS]: true,
  [DesignEditorTabName.SETTINGS]: true,
  [DesignEditorTabName.ADDONS]: false,
  [DesignEditorTabName.SHUTTERSTOCK]: false,
};

@Injectable({ providedIn: 'root' })
export class DesignEditorCommunicationService {
  protected designEditorUrl = environment.microFrontendServiceUrls.designEditor;
  protected editorReady$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected uploaderModalVisible$ = new BehaviorSubject<boolean>(false);
  protected defaultIFrameId = 'printFileEditor';
  protected iFrameId = this.defaultIFrameId;
  protected mode: DesignEditorMode = null;
  protected storeType: EStoreType = null;

  private tabsVisibility = DEFAULT_TABS_VISIBILITY;

  constructor(
    private readonly store: Store<AppState>,
    private readonly translateService: TranslateService,
    private readonly designRendersLoaderService: DesignRendersLoaderService,
    private readonly eCommerceStoreFeaturesService: ECommerceStoreFeaturesService,
    private readonly designEditorPreviewSettingsService: DesignEditorPreviewSettingsService,
    private readonly subscriptionsFacade: SubscriptionsFacade,
    private readonly chameleonFacade: ChameleonFacade,
    private readonly auth: KeycloakAuthService,
    private readonly featureSwitcherService: FeatureSwitcherService,
    private readonly designEditorApplicationsService: DesignEditorApplicationsService,
    private readonly designEditorZoomService: DesignEditorZoomService,
    private readonly designPreviewShutterstockService: DesignPreviewShutterstockService,
    private readonly productTypeUidService: ProductTypeUidService,
    private readonly preflightApiService: PreflightApiService,
    @Inject(WINDOW) private readonly window: Window,
  ) {
    window.addEventListener('message', (messageEvent: MessageEvent) => {
      const payload = messageEvent.data?.data;

      switch (messageEvent.data?.type) {
        case EditorOutboundEventType.ready:
          this.setEditorReadyState(true);
          break;
        case EditorOutboundEventType.assetUploaded:
          this.store.dispatch(new assetsMappingActions.ProcessUploadSuccessPayload(payload));
          break;
        default:
          break;
      }
    });
  }

  setupEditorByProductTypeUid(productTypeUid: ProductTypeUid) {
    this.setupPlugins(productTypeUid);

    this.sendPostMessage(EditorInboundEventType.setDefaultZoom, {
      zoom: this.designEditorZoomService.getDefaultZoom(productTypeUid),
    });

    this.sendPostMessage(EditorInboundEventType.setEditorHostSettings, {
      enableMirroring: isMirroringSupportedByProductTypeUid(productTypeUid),
      showDoubleLine: ApparelProductTypeUids.includes(productTypeUid),
      allowSpreadBackgroundColorChange: true,
    });

    const isWallpaper =
      productTypeUid === ProductTypeUid.WALLPAPER || productTypeUid === ProductTypeUid.WALLPAPER_SAMPLE;
    if (isWallpaper) {
      this.sendPostMessage(EditorInboundEventType.setEditorHostSettings, {
        allowAddElements: false,
        allowRemoveElements: false,
        allowLockElements: false,
        activateWallpaperEmptyScreen: true,
        allowShowRollerStripesChange: true,
        allowShowRulersChange: true,
        resizeProductBasedOnFirstElement: true,
      });
    }

    if (productTypeUid === ProductTypeUid.PHONE_CASE) {
      this.sendPostMessage(EditorInboundEventType.setEditorHostSettings, { showPrintAreaLines: false });
    }

    const isProductTabSupported = isProductTabSupportedPerProductTypeUid(productTypeUid);
    if (isProductTabSupported) {
      this.tabsVisibility[DesignEditorTabName.PRODUCT] = true;
    }

    this.tabsVisibility[DesignEditorTabName.LAYERS] = !isWallpaper;
    this.tabsVisibility[DesignEditorTabName.TEXTS] = !isWallpaper;
    this.tabsVisibility[DesignEditorTabName.LAYOUTS] = !isWallpaper;
    this.setupSidebarTabs();

    if (isProductTabSupported) {
      this.sendPostMessage(EditorInboundEventType.setSidebarActiveTabName, DesignEditorTabName.PRODUCT);
    }

    this.featureSwitcherService
      .isFeatureEnabledForCurrentUser(FeatureFlagEnum.editor_image_patterns)
      .pipe(
        filter(Boolean),
        take(1),
        tap(() => this.sendPostMessage(EditorInboundEventType.setEditorHostSettings, { enableImagePatterns: true })),
      )
      .subscribe();

    this.featureSwitcherService
      .isFeatureEnabledForCurrentUser(FeatureFlagEnum.editor_shapes)
      .pipe(
        filter(Boolean),
        take(1),
        tap(() => {
          this.tabsVisibility[DesignEditorTabName.SHAPES] = ![
            ProductTypeUid.WALLPAPER,
            ProductTypeUid.WALLPAPER_SAMPLE,
          ].includes(productTypeUid);
          this.setupSidebarTabs();
        }),
      )
      .subscribe();

    this.featureSwitcherService
      .isFeatureEnabledForCurrentUser(FeatureFlagEnum.destygo_webchat)
      .pipe(
        take(1),
        tap(isWebChatEnabled =>
          this.sendPostMessage(EditorInboundEventType.setEditorHostSettings, {
            enableHelperCenter: { chatBot: isWebChatEnabled, feedback: false },
          }),
        ),
      )
      .subscribe();
  }

  setupEditorByDesignStructure(designStructure: DesignStructure, productTypeUid: ProductTypeUid) {
    this.preflightApiService
      .getProductResolution({ product_uid: designStructure.product_uid })
      .pipe(take(1))
      .subscribe(dpiResolution => {
        this.sendPostMessage(EditorInboundEventType.setEditorHostSettings, {
          dpiLevels: {
            low: dpiResolution.dpi.min,
            medium: dpiResolution.dpi.max,
          },
        });
      });

    if (ApparelProductTypeUids.includes(productTypeUid)) {
      this.sendPostMessage(EditorInboundEventType.setSpreadsDefaultZoom, {
        zoom: this.designEditorZoomService.getSpreadsDefaultZoom(designStructure.spreads),
      });
    }

    const dimensions = designStructure.related_dimensions || designStructure.dimensions;
    if ([ProductTypeUid.WALLPAPER, ProductTypeUid.WALLPAPER_SAMPLE].includes(productTypeUid) && dimensions) {
      this.sendPostMessage(EditorInboundEventType.setImagePatternScaleBases, [
        { value: dimensions.min_width, dimension: 'width', label: 'Fixed to roll size' },
      ]);
    }
  }

  toggleTab(tab: DesignEditorTabName, visible: boolean) {
    if (this.tabsVisibility[tab] === visible) {
      return;
    }

    this.tabsVisibility[tab] = visible;
    this.setupSidebarTabs();
  }

  toggleShutterstockTab(visible: boolean) {
    combineLatest([
      this.subscriptionsFacade.isClientCreatedAfterGelatoPlusStart$,
      this.subscriptionsFacade.shutterstockEssentialPlanIsEnabled$,
      this.subscriptionsFacade.gelatoPlusOrGoldIsActive$,
    ])
      .pipe(
        first(),
        tap(([isClientCreatedAfterGelatoPlusStart, shutterstockEssentialPlan0IsEnabled, isGelatoPlusOrGoldActive]) => {
          if (!visible) {
            this.setShutterstockPlugin(PluginDataState.not_visible, isGelatoPlusOrGoldActive);
          } else if (isClientCreatedAfterGelatoPlusStart && !shutterstockEssentialPlan0IsEnabled) {
            this.setShutterstockPlugin(PluginDataState.non_functional, isGelatoPlusOrGoldActive);
          } else {
            this.setShutterstockPlugin(PluginDataState.enabled, isGelatoPlusOrGoldActive);
          }
        }),
      )
      .subscribe();
  }

  isEditorReady(): Observable<boolean> {
    return this.editorReady$;
  }

  isUploaderModalVisible(): Observable<boolean> {
    return this.uploaderModalVisible$;
  }

  setUploaderModalVisible(value: boolean) {
    this.uploaderModalVisible$.next(value);
  }

  getDesignEditorUrl(): string {
    return this.designEditorUrl;
  }

  initInstance(
    mode: DesignEditorMode,
    fileUploaderConfig,
    storeType: EStoreType = null,
    iFrameId: string = this.defaultIFrameId,
  ) {
    this.iFrameId = iFrameId;
    this.mode = mode;
    this.storeType = storeType;
    this.tabsVisibility = DEFAULT_TABS_VISIBILITY;

    this.setupEditorOnReady(fileUploaderConfig);
  }

  setEditorReadyState(value: boolean) {
    this.editorReady$.next(value);
  }

  sendPostMessage(type: EditorInboundEventType | string, data: any = null, plugin?: string) {
    this.editorReady$
      .pipe(
        filter((editorReady): boolean => Boolean(editorReady)),
        first(),
        tap(() => {
          const frame: any = document.getElementById(this.iFrameId);

          if (!frame || !frame.contentWindow) {
            return;
          }

          frame.contentWindow.postMessage({ type, data, plugin }, this.designEditorUrl);
        }),
      )
      .subscribe();
  }

  sendDesignData(designData: DesignStructure) {
    logEvent('sendDesignDataToEditor', {
      productUidSentToEditor: designData.product_uid,
      isEmptyDesign: isEmptyDesignStructure(designData),
      designMediaInfo: getUsedMediaToTrack(designData),
    });
    this.sendPostMessage(EditorInboundEventType.setDesignData, designData);
    this.requestDesignData();
  }

  requestDesignData() {
    this.sendPostMessage(EditorInboundEventType.requestDesignData, {
      checkWarnings: [
        {
          type: 'LOW_DPI',
          level: ['MEDIUM', 'HIGH'],
        },
      ],
    });
  }

  importImageFromShutterstock(assetProviderUid: AssetProviderUid, image: ShutterstockImage) {
    this.store.dispatch(new assetsMappingActions.ImportImageFromShutterstock(assetProviderUid, image));
    this.sendPostMessage(EditorInboundEventType.setSidebarActiveTabName, DesignEditorTabName.GALLERY);
  }

  closeShutterStockGallery() {
    this.sendPostMessage(EditorInboundEventType.setSidebarActiveTab, -1);
  }

  async loadDesignRenders(
    designData: DesignStructure,
    assets: AssetsCollection,
    removeSamples: boolean,
    productTypeUid: ProductTypeUid,
  ) {
    const isShutterstockUsed = this.designPreviewShutterstockService.isDesignHasShutterstockAssets(assets, designData);
    const previewSettings: DesignEditorPreviewSettings = await this.designEditorPreviewSettingsService
      .fetch(designData?.product_uid, isShutterstockUsed)
      .toPromise();

    const isShutterstockFlatPreviewNeeded = this.designPreviewShutterstockService.isShutterstockFlatPreviewSupported(
      productTypeUid,
      previewSettings?.noFlatPreviews,
      isShutterstockUsed,
    );

    if (previewSettings) {
      this.sendPostMessage(
        EditorInboundEventType.setEditorHostSettings,
        this.designEditorPreviewSettingsService.mapToSetEditorPreviewSettingsPostMessagePayload(previewSettings),
      );

      const renders = await this.designRendersLoaderService.loadDesignRenders(
        designData,
        this.designEditorPreviewSettingsService.mapToDesignRendersLoadingSettings(previewSettings),
        removeSamples,
        ApparelProductTypeUids.includes(productTypeUid),
        isShutterstockFlatPreviewNeeded,
      );

      if (renders) {
        this.sendPostMessage(EditorInboundEventType.setRenders, renders);
      } else {
        this.sendPostMessage(EditorInboundEventType.rendersError);
      }
    } else {
      this.sendPostMessage(EditorInboundEventType.rendersError);
    }
  }

  async requestAvailablePreviews(productUid: string) {
    const previews = await this.designRendersLoaderService.getAvailablePreviews(productUid);

    if (previews) {
      this.sendPostMessage(EditorInboundEventType.setFlatPreviewAvailable, previews && previews.flat);
      this.sendPostMessage(EditorInboundEventType.setThe3dPreviewAvailable, previews && previews.the3d);
    }
  }

  private setupEditorOnReady(fileUploaderConfig) {
    this.uploaderModalVisible$.next(false);

    this.sendPostMessage(EditorInboundEventType.setLocale, this.translateService.currentLang);

    this.sendPostMessage(EditorInboundEventType.setDefaultTextStyle, {
      bold: false,
      italic: false,
      underline: false,
      stroked: false,
      fontFamily: 'OpenSans-Regular',
      fontSize: 48,
      color: '#000000',
      lineHeight: 1,
      textAlign: 'center',
      textDirection: '',
      angle: 0,
    });

    this.sendPostMessage(EditorInboundEventType.setEditorHostSettings, {
      allowUseNotUploadedImages: true,
      backgroundColor: DESIGN_EDITOR_BACKGROUND_COLOR,
    });
    this.sendPostMessage(EditorInboundEventType.setTrackDesignChanges, true);

    if ([DesignEditorMode.PRODUCT_CREATION, DesignEditorMode.PRODUCT_EDITING].includes(this.mode)) {
      this.sendPostMessage(EditorInboundEventType.setAsVariantFlow, true);
      this.tabsVisibility[DesignEditorTabName.VARIANTS] = true;
    }

    if (this.isECommerceMode()) {
      const isDesignPersonalizationSupported = [
        EStoreFeature.DESIGN_PERSONALIZATION,
        EStoreFeature.ADVANCED_DESIGN_PERSONALIZATION_EMAIL,
      ].some((feature: EStoreFeature): boolean =>
        this.eCommerceStoreFeaturesService.isFeatureSupported(this.storeType, feature),
      );

      if (isDesignPersonalizationSupported) {
        this.sendPostMessage(EditorInboundEventType.setEditorHostSettings, {
          allowLockElements: true,
          allowPersonalizationLock: true,
          allowSampleFlagChange: true,
          allowStaticFlagChange: true,
        });
        this.sendPostMessage(EditorInboundEventType.setDefaultPersonalizationLock, true);
      }
    }

    this.sendPostMessage(EditorInboundEventType.setUploaderConfig, fileUploaderConfig);

    this.setupChameleon();
    this.setupAmplitude();
    this.setupSidebarTabs();
  }

  private isECommerceMode() {
    return [
      DesignEditorMode.PRODUCT_CREATION,
      DesignEditorMode.PRODUCT_EDITING,
      DesignEditorMode.PRODUCT_VARIANT_CONNECTION,
      DesignEditorMode.PRODUCT_VARIANT_EDITING,
    ].includes(this.mode);
  }

  private setupAmplitude() {
    this.auth
      .userId()
      .pipe(
        first(),
        tap(userId => this.sendPostMessage(EditorInboundEventType.setupAmplitude, { userId })),
      )
      .subscribe();
  }

  private setupPlugins(productTypeUid: ProductTypeUid) {
    this.designEditorApplicationsService
      .setupEditorPlugins(productTypeUid, this.mode)
      .pipe(
        tap((data: SetPluginsEventDataItem[]) => {
          this.setPlugins(data);
        }),
      )
      .subscribe();
  }

  private setupSidebarTabs() {
    const tabs = [
      DesignEditorTabName.PRODUCT,
      DesignEditorTabName.VARIANTS,
      DesignEditorTabName.LAYERS,
      DesignEditorTabName.GALLERY,
      DesignEditorTabName.TEXTS,
      DesignEditorTabName.SHAPES,
      DesignEditorTabName.TEMPLATES,
      DesignEditorTabName.WARNINGS,
      DesignEditorTabName.LAYOUTS,
      DesignEditorTabName.SETTINGS,
      DesignEditorTabName.SHUTTERSTOCK,
    ].filter(tab => this.tabsVisibility[tab]);

    this.sendPostMessage(EditorInboundEventType.setSidebarTabs, tabs);
  }

  private setShutterstockPlugin(state: PluginDataState, isGelatoPlusOrGoldActive: boolean) {
    this.setPlugins([
      {
        state,
        name: DesignEditorPlugin.APP_SHUTTERSTOCK,
        extra: {
          premiumLevel: getAppCrownPremiumLevel(PremiumIconCrownedFeatures.SHUTTERSTOCK, isGelatoPlusOrGoldActive),
        },
      },
    ]);
  }

  private setPlugins(data: SetPluginsEventDataItem[]) {
    this.sendPostMessage(EditorInboundEventType.setPlugins, data);
  }

  private setupChameleon() {
    this.chameleonFacade.identify$
      .pipe(
        filter((identify: ChameleonUserIdentification) => Boolean(identify && identify.userId && identify.data)),
        first(),
        tap((identify: ChameleonUserIdentification) => {
          this.sendPostMessage(EditorInboundEventType.setupChameleon, {
            token: this.chameleonFacade.getAccountToken(),
            identify,
          });
        }),
      )
      .subscribe();
  }
}
