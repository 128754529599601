import { Injectable } from '@angular/core';
import { EProductPublishScope } from '@gelato-api-ui/core/e-commerce/e-product-publish-scope.enum';
import { catchError, filter, first, map, mergeMap, switchMap, take, takeWhile, tap, timeout } from 'rxjs/operators';
import * as Sentry from '@sentry/angular';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { combineLatest, forkJoin, Observable, of, timer } from 'rxjs';
import { ECommerceProductsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-products-api.service';
import * as R from 'ramda';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductStatus } from '@gelato-api-ui/core/e-commerce/e-product-status.enum';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import * as eCommerceProductsActions from '@api-ui-app/src/app/ngrx/e-commerce-products.actions';
import {
  cancelPublishingProcessMonitoring,
  initiatePublishingProcessMonitoring,
} from '@api-ui-app/src/app/ngrx/e-commerce-products.actions';
import { createEmptyPublishingProduct, PublishingState } from '@api-ui-app/src/app/ngrx/e-commerce-products.reducer';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { SplashMessageFacade } from '@api-ui-app/src/app/shared/splash-message/+state/splash-message/splash-message.facade';
import { SplashMessage } from '@api-ui-app/src/app/shared/splash-message/lib/splash-message';
import { ProductSelectionResult } from '@api-ui-app/src/app/product/product-add/types/product-selection-result';
import {
  getIsInternalStoreType,
  getSelectedStoreId,
  getStore as getSelectedStore,
} from '@api-ui-app/src/app/ngrx/e-commerce-stores.selector';
import { getPrimaryProductImageUrl } from '@gelato-api-ui/core/e-commerce/helpers/getPrimaryProductImageUrl';
import { getSanityProductIdFromEProduct } from '@gelato-api-ui/core/e-commerce/helpers/getSanityProductIdFromEProduct';
import { EProductPublishingErrorCode } from '@gelato-api-ui/core/e-commerce/e-product-publishing-errors.enum';
import { getCurrentIso8601DateTime } from '@api-ui-app/src/app/lib/getCurrentIso8601DateTime';
import { EProductData } from '@gelato-api-ui/core/e-commerce/e-product-data';
import { ECommerceProductGraphQlService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-graph-ql.service';
import { EProductPublishRequestDefaultValues } from '@gelato-api-ui/core/e-commerce/e-product-publish-request-default-values';
import { EProductPublishMode } from '@api-ui-app/src/app/e-commerce-stores/types/e-product-publish-mode.enum';
import { ECommerceProductPublishSettingsService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-publish-settings.service';
import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';
import { ECommerceProductPublishingErrorService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-publishing-error.service';
import { getIsPublishingProcessMonitoringActive } from '@api-ui-app/src/app/ngrx/e-commerce-products.selector';
import { SECOND } from '@gelato-api-ui/utils/time';
import { MINUTES_TO_MILLISECONDS } from '@api-ui-app/src/app/lib/constants';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductPublishFacade {
  selectedStoreId$ = this.store.select(getSelectedStoreId);
  selectedStore$ = this.store.select(getSelectedStore);
  isInternalStoreType$ = this.store.select(getIsInternalStoreType);
  isPublishingProcessMonitoringActive$ = this.store.select(getIsPublishingProcessMonitoringActive);

  publishingTime = 10 * MINUTES_TO_MILLISECONDS;
  publishRepeatRequestTime = 5 * SECOND;

  private scopes: EProductPublishScope[] = [];
  private publishMode: EProductPublishMode = null;

  constructor(
    private readonly store: Store<AppState>,
    private readonly eCommerceProductsApiService: ECommerceProductsApiService,
    private readonly eCommerceProductGraphQlService: ECommerceProductGraphQlService,
    private readonly eCommerceProductPublishSettingsService: ECommerceProductPublishSettingsService,
    private readonly eCommerceProductPublishingErrorService: ECommerceProductPublishingErrorService,
    private readonly splashMessageFacade: SplashMessageFacade,
  ) {}

  publishProduct(
    productId: string,
    productName: string,
    productMetadata: MetadataItem[],
    publishWithFreeShipping = false,
    publishImmediately = false,
  ) {
    this.scopes = this.eCommerceProductPublishSettingsService.getPublishScopesFromMetadata(productMetadata);
    this.publishMode = this.eCommerceProductPublishSettingsService.getPublishModeFromMetadata(productMetadata);

    this.eCommerceProductsApiService
      .publish(productId, {
        ...EProductPublishRequestDefaultValues,
        scopes: this.scopes,
        withFreeShipping: publishWithFreeShipping,
        isVisibleInTheOnlineStore: publishImmediately,
      })
      .pipe(
        first(),
        catchError(error => {
          this.trackPublishError(productId, productName, error);
          return of(null);
        }),
        filter(Boolean),
        tap(() => {
          this.updatePublishingProducts();
        }),
      )
      .subscribe();
  }

  updatePublishingProducts() {
    this.initiatePublishingProcessMonitoring([EProductStatus.PUBLISHING]);
  }

  trackProductStatuses(products: EProductAbbridged[]): Observable<void> {
    const obs = products.map((product: EProductAbbridged): Observable<void> => this.getProductRetries(product));

    return forkJoin(obs).pipe(map(() => undefined));
  }

  updatePublishingProduct(product, status: EProductStatus): void {
    this.store.dispatch(
      eCommerceProductsActions.updatePublishingProduct({
        product: { ...R.merge(createEmptyPublishingProduct(), product), status },
      }),
    );
  }

  getProductRetries(eProduct: EProductAbbridged): Observable<void> {
    return this.isInternalStoreType$.pipe(
      switchMap((isInternalStoreType: boolean): Observable<void> => {
        if (isInternalStoreType) {
          return of(undefined);
        }

        const timer$ = timer(2000, this.publishRepeatRequestTime);

        return combineLatest([timer$, this.isPublishingProcessMonitoringActive$]).pipe(
          takeWhile(([, isPublishingProcessMonitoringActive]) => isPublishingProcessMonitoringActive),
          mergeMap((): Observable<EProductWithVariants> => {
            // Prevent fetching of product info for products which are not created yet
            if (!eProduct?.id || /^tmp-product-id/i.test(eProduct?.id)) {
              return of(eProduct as unknown as EProductWithVariants);
            }

            return this.getProductInfo(eProduct.id);
          }),
          tap((product: EProductWithVariants) => {
            if (product?.status === EProductStatus.PUBLISHING) {
              this.updatePublishingProduct(this.updateProductWithActualThumbnailUrl(product), product.status);
            }
          }),
          filter(
            (product: EProductWithVariants): boolean =>
              ![EProductStatus.PUBLISHING, EProductStatus.CREATED].includes(product?.status),
          ),
          take(1),
          catchError(() => {
            // Show message that publish was failed
            this.setPublishingState({
              errorCode: EProductPublishingErrorCode.GENERAL_ERROR,
              productId: eProduct.id,
              productTitle: eProduct.title,
            });

            return of(null);
          }),
          tap((product: EProductWithVariants) => {
            if (!product) {
              this.onPublishTimeExceeded(eProduct);
              return;
            }

            if (product?.status === EProductStatus.PUBLISHING_ERROR) {
              this.onPublishingError(product);
              return;
            }

            // Publish has completed
            this.updatePublishingProduct(product, product.status);

            // Show publish successful message
            this.setPublishingState({
              productId: product.id,
              productTitle: product.title,
              detailsFlags: product.publishingDetailsFlags,
            });

            // Clear successful message
            this.clearPublishingMessage(product.id);

            this.trackPublishSuccess(product);
          }),
          map(() => undefined),
        );
      }),
    );
  }

  createPublishingProductFromProductData(productData: EProductData): void {
    const publishingProduct: EProductAbbridged = {
      ...createEmptyPublishingProduct(),
      id: productData.product.id,
      title: productData.product.title,
      externalThumbnailUrl: getPrimaryProductImageUrl(productData.productImages),
    };

    this.createPublishingProduct(publishingProduct);
  }

  createPublishingProduct(sourceProduct: Partial<EProductAbbridged>): void {
    this.isInternalStoreType$
      .pipe(
        take(1),
        tap((isInternalStoreType: boolean) => {
          const publishingProduct: Partial<EProductAbbridged> = {
            ...sourceProduct,
            status: EProductStatus.PUBLISHING,
            createdAt: getCurrentIso8601DateTime(),
            updatedAt: getCurrentIso8601DateTime(),
          };

          const action = publishingProduct.id
            ? eCommerceProductsActions.updatePublishingProduct({ product: publishingProduct, forcePreviewUpdate: true })
            : eCommerceProductsActions.addPublishingProducts({ products: [publishingProduct] });

          this.store.dispatch(action);

          if (!isInternalStoreType) {
            this.addPublishingNotification({ timeout: 8000 });
          }
        }),
      )
      .subscribe();
  }

  addPublishingProducts(sourceProducts: Partial<EProductAbbridged>[]) {
    this.isInternalStoreType$
      .pipe(
        take(1),
        tap((isInternalStoreType: boolean) => {
          const products: EProductAbbridged[] = sourceProducts.map(
            (loopProduct: EProductAbbridged): EProductAbbridged => ({
              ...loopProduct,
              status: EProductStatus.PUBLISHING,
              createdAt: getCurrentIso8601DateTime(),
              updatedAt: getCurrentIso8601DateTime(),
            }),
          );

          this.store.dispatch(eCommerceProductsActions.addPublishingProducts({ products }));

          if (!isInternalStoreType) {
            this.addPublishingNotification({ timeout: 8000 });
          }
        }),
      )
      .subscribe();
  }

  removePublishingProducts(productIds: string[]) {
    this.store.dispatch(eCommerceProductsActions.removePublishingProducts({ productIds }));
  }

  setPublishingState(state: PublishingState): void {
    this.store.dispatch(eCommerceProductsActions.setPublishingState(state));
  }

  addPublishingNotification(message: Partial<SplashMessage> = {}) {
    this.splashMessageFacade.triggerAdd({
      id: `product-publishing-notification`,
      textKey: 'txt_product_is_being_published_notify',
      icon: '/assets/status-icons/status-info.svg',
      color: 'info',
      closeable: true,
      ...message,
    });
  }

  duplicateProduct(productId: string, sourceStoreId: string, destinationStoreId: string, title: string) {
    this.store.dispatch(
      eCommerceProductsActions.duplicateProduct({ productId, sourceStoreId, destinationStoreId, title }),
    );
  }

  duplicateProducts(destinationStoreId: string, { items, store }: ProductSelectionResult) {
    items.forEach(item => this.duplicateProduct(item.id, store.id, destinationStoreId, item.title));
  }

  private onPublishTimeExceeded(eProduct: EProductAbbridged) {
    // Publish time exceeded. Therefore we`ll show publish button again
    this.updatePublishingProduct(eProduct, EProductStatus.UNPUBLISHED);
    // Show message that publish was failed
    this.setPublishingState({
      errorCode: EProductPublishingErrorCode.GENERAL_ERROR,
      productId: eProduct.id,
      productTitle: eProduct.title,
    });
  }

  private onPublishingError(product: EProductWithVariants) {
    this.setPublishingState({
      errorCode: product.publishingErrorCode || EProductPublishingErrorCode.GENERAL_ERROR,
      productId: product.id,
      productTitle: product.title,
      errorDetails: product.publishingErrorDetails,
    });
    this.updatePublishingProduct(product, product.status);

    this.trackEvent('storeProductPublishFailed', {
      storeProductId: product.id,
      error: product.publishingErrorCode,
      scopes: this.scopes,
      publishMode: this.publishMode,
    });
  }

  private getProductInfo(productId: string): Observable<EProductWithVariants> {
    return this.eCommerceProductGraphQlService.getProduct(productId).pipe(catchError(() => of(null)));
  }

  private clearPublishingMessage(productId: string) {
    setTimeout(() => {
      this.setPublishingState({
        productId,
        productTitle: null,
        errorCode: null,
      });
    }, 8000);
  }

  private trackPublishSuccess(product: EProductWithVariants) {
    const eventProperties: object = {
      storeProductId: product.id,
      productName: getSanityProductIdFromEProduct(product),
      scopes: this.scopes,
      publishMode: this.publishMode,
    };

    const eventTypeConfig = {
      [EProductPublishMode.EXPAND_CATEGORIES]: 'expandCategoriesPublishSuccess',
      [EProductPublishMode.PRODUCT_EXPANSION]: 'Gelato Publisher Product Published Successful',
    };
    const eventType = eventTypeConfig[this.publishMode] || 'storeProductPublishSuccess';

    this.trackEvent(eventType, eventProperties);
  }

  private trackPublishError(productId: string, productName: string, error: any) {
    const errorDetails = error?.response?.error;

    Sentry.captureException(new Error('eCommerceProductPublishFailed'), scope => {
      scope.setExtras({
        storeProductId: productId,
        scopes: this.scopes,
        error: errorDetails,
        publishMode: this.publishMode,
      });

      scope.setTag('productPublishing', 'newProduct');

      return scope;
    });

    const eventTypeConfig = {
      [EProductPublishMode.EXPAND_CATEGORIES]: 'expandCategoriesPublishFailed',
      [EProductPublishMode.PRODUCT_EXPANSION]: 'Gelato Publisher Product Published Failed',
    };
    const eventType = eventTypeConfig[this.publishMode] || 'storeProductPublishFailed';

    this.trackEvent(eventType, {
      storeProductId: productId,
      productName,
      error,
      scopes: this.scopes,
      publishMode: this.publishMode,
    });
  }

  private updateProductWithActualThumbnailUrl(product: EProductWithVariants): EProductWithVariants {
    const externalThumbnailUrl = getPrimaryProductImageUrl(product.productImages) || product.externalThumbnailUrl;

    return { ...product, externalThumbnailUrl };
  }

  private trackEvent(eventType: string, eventProperties: object) {
    this.selectedStore$
      .pipe(
        first(),
        tap((selectedStore: EStore) => {
          logEvent(eventType, {
            storeType: selectedStore ? selectedStore.type : null,
            storeName: selectedStore ? selectedStore.name : null,
            storeId: selectedStore ? selectedStore.id : null,
            scopes: this.scopes,
            ...eventProperties,
          });
        }),
      )
      .subscribe();
  }

  initiatePublishingProcessMonitoring(statuses: EProductStatus[]) {
    this.store.dispatch(initiatePublishingProcessMonitoring({ statuses }));
  }

  cancelPublishingProcessMonitoring() {
    this.store.dispatch(cancelPublishingProcessMonitoring());
  }
}
