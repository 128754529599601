import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';

interface GelatoPlusDescriptionModalContext {
  headerText: string;
  text: string;
  buttonText: string;
  imagePath: string;
  headerIconPath: string;
}

@Component({
  selector: 'gc-gelato-plus-description-modal, gd-gelato-plus-description-modal',
  templateUrl: './gelato-plus-description-modal.component.html',
  styleUrls: ['./gelato-plus-description-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GelatoPlusDescriptionModalComponent implements OnInit {
  constructor(public modal: SuiModal<GelatoPlusDescriptionModalContext, void, void>) {}

  ngOnInit() {}

  get headerText() {
    return this.modal.context.headerText;
  }

  get text() {
    return this.modal.context.text;
  }

  get buttonText() {
    return this.modal.context.buttonText;
  }

  get imagePath() {
    return this.modal.context.imagePath;
  }

  get headerIconPath() {
    return this.modal.context.headerIconPath;
  }

  onActionClick() {
    this.modal.approve();
  }
}

export class GelatoPlusDescriptionModal extends ComponentModalConfig<GelatoPlusDescriptionModalContext, void, void> {
  constructor(context: GelatoPlusDescriptionModalContext) {
    super(GelatoPlusDescriptionModalComponent, context);

    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Small;
  }
}
