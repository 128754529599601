<p>
  {{ 'txt_stores_list_modal_empty_state_description' | translate }}
</p>

<a href="{{ shopifyAppUrl }}" target="_blank" rel="noreferrer noopener">
  <gd-stores-list-item type="shopify" [name]="'txt_connect_shopify_store' | translate" [showRightArrow]="false">
  </gd-stores-list-item>
</a>

<a [routerLink]="['/stores/etsy/connect']" (click)="requestModalClose()">
  <gd-stores-list-item type="etsy" [name]="'txt_connect_etsy_store' | translate" [showRightArrow]="false">
  </gd-stores-list-item>
</a>
