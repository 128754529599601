import { WeightUnit } from '../weight-unit.enum';

const supportedMeasureUnits = [String(WeightUnit.GRAM), String(WeightUnit.POUND)];

export const convertWeightValue = (
  weightValue: number,
  sourceMeasureUnit: string,
  destinationMeasureUnit: string,
): number => {
  if (supportedMeasureUnits.indexOf(sourceMeasureUnit) === -1) {
    throw new Error('Not supported source measure unit');
  }

  if (supportedMeasureUnits.indexOf(destinationMeasureUnit) === -1) {
    throw new Error('Not supported destination measure unit');
  }

  if (sourceMeasureUnit === destinationMeasureUnit) {
    return weightValue;
  }

  const GRAMS_IN_POUND = 453.59237;

  if (destinationMeasureUnit === WeightUnit.POUND) {
    return weightValue / GRAMS_IN_POUND;
  } else {
    return weightValue * GRAMS_IN_POUND;
  }
};
