export const loadImage = (src: string) =>
  new Promise((resolve, reject) => {
    const img = document.createElement('img');

    img.onload = () => {
      resolve(true);
    };

    img.onerror = () => {
      reject(false);
    };

    img.src = src;
  });
