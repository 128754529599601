import { Injectable } from '@angular/core';
import { ActionCreator, select, Store } from '@ngrx/store';
import { walletsQuery } from './wallet.selector';
import {
  fetchIntent,
  presetStart,
  topUpInternalStart,
  topUpStart,
  updateFail,
  updateStart,
  updateSuccess,
  withdrawStart,
} from './wallet.actions';
import { WalletState } from './wallet.adapter';
import { WalletApiService } from '../../services/wallet-api.service';
import { Wallet, WalletChangeSet } from '../../lib/api';
import { Actions, ofType } from '@ngrx/effects';
import { catchError, first, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class WalletFacade {
  public readonly all$ = this.store$.pipe(select(walletsQuery.getAll));
  public readonly ids$ = this.store$.pipe(select(walletsQuery.getIds));
  public readonly isFetchingComplete$ = this.store$.pipe(select(walletsQuery.isFetchingComplete));
  public readonly isFetchingStarted$ = this.store$.pipe(select(walletsQuery.isFetchingStarted));
  public readonly isFetchingToBeDone$ = this.store$.pipe(select(walletsQuery.isFetchingToBeDone));
  public readonly activeWallets$ = this.store$.select(walletsQuery.getActiveSorted);
  public readonly inactiveWallets$ = this.store$.select(walletsQuery.getInactiveSorted);

  constructor(
    private readonly store$: Store<WalletState>,
    private readonly actions$: Actions,
    private readonly walletApi: WalletApiService,
  ) {}

  public disableWallet(wallet: Wallet) {
    if (wallet.balance === 0) {
      return this.updateWalletToDisabled(wallet);
    }

    return this.withdraw(wallet, wallet.balance, wallet.clientUid).pipe(
      switchMap(() => this.updateWalletToDisabled(wallet)),
    );
  }

  /**
   * Disable wallet, and then wallet is moved to inactive
   *
   * Before we disable wallet, we need to withdraw all funds from it
   * and remove primary and secondary payment details (remove cards linked to it)
   * With that, we also need to move top-up to manual and reset it to default variables
   *
   * @param   {Wallet}  wallet
   */
  private updateWalletToDisabled(wallet: Wallet) {
    return this.update(wallet, {
      name: wallet.name,
      primaryPaymentDetailsUid: '',
      secondaryPaymentDetailsUid: '',
      topUpAmount: 5000,
      topUpThreshold: 5000,
      topUpType: 'manual',
    }).pipe(
      tap((w: Wallet) => {
        this.store$.dispatch(updateSuccess({ wallet: w }));
      }),
      catchError(err => {
        this.store$.dispatch(updateFail({ err }));
        return of();
      }),
    );
  }

  public triggerFetchIntent(clientId: string, force = false) {
    this.store$.dispatch(fetchIntent({ clientId, force }));
  }

  public triggerUpdate(wallet: Wallet, change: WalletChangeSet) {
    this.store$.dispatch(updateStart({ wallet, change }));
  }

  public update(wallet: Wallet, change: WalletChangeSet) {
    return this.walletApi.update(wallet, change);
  }

  public triggerTopUp(wallet: Wallet, amountInMinorUnits) {
    this.store$.dispatch(topUpStart({ wallet, amountInMinorUnits }));
  }

  public topUp(wallet: Wallet, amountInMinorUnits: number) {
    return this.walletApi.topUp(wallet, amountInMinorUnits);
  }

  public triggerTopUpInternal(wallet: Wallet, amountInMinorUnits, clientId: string) {
    this.store$.dispatch(topUpInternalStart({ wallet, amountInMinorUnits, clientId }));
  }

  public topUpInternal(wallet: Wallet, amountInMinorUnits: number, clientId: string) {
    return this.walletApi.topUpInternal(wallet, amountInMinorUnits, clientId);
  }

  public triggerWithdraw(wallet: Wallet, amountInMinorUnits, clientId: string) {
    this.store$.dispatch(withdrawStart({ wallet, amountInMinorUnits, clientId }));
  }

  public withdraw(wallet: Wallet, amountInMinorUnits: number, clientId: string) {
    return this.walletApi.withdraw(wallet, amountInMinorUnits, clientId);
  }

  public search(clientId: string) {
    return this.walletApi.search(clientId);
  }

  public byId(id: string) {
    return this.store$.select(walletsQuery.getByUid(id));
  }

  public byCurrency(currency: string) {
    return this.store$.select(walletsQuery.getByCurrency(currency));
  }

  public triggerPreset(clientId: string) {
    this.store$.dispatch(presetStart({ clientId }));
  }

  public preset(clientId: string) {
    return this.walletApi.preset(clientId);
  }

  public onAction(action: ActionCreator) {
    return this.actions$.pipe(ofType(action), first());
  }
}
