<ng-template #designFeeTooltip>
  <div class="design-fee-tooltip">
    {{ 'txt_design_fee_label' | translate: { value: totalFee.toString() | money: currency } }}
  </div>
</ng-template>

<div
  *ngIf="totalFee && currency"
  class="design-fee-label"
  [gcTooltip]="designFeeTooltip"
  [gcTooltipTheme]="'dark'"
  [gcTooltipOpenTimeout]="500"
  [gcTooltipTriggerOpenEvent]="'mouseenter'"
  [gcTooltipTriggerDestroyEvent]="'mouseleave'"
  [gcTooltipPosition]="tooltipPosition"
  [gcTooltipShowArrow]="false"
>
  <img src="/assets/icons/info.svg" width="20" height="20" alt="" />
</div>
