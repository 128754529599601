import { NgModule } from '@angular/core';
import { LayoutModule } from '@api-ui-app/src/app/layout/layout.module';
import { SharedModule } from '@api-ui-app/src/app/shared/shared.module';
import { ChargeBeeService } from '@api-ui-app/src/app/subscriptions/services/charge-bee.service';
import { SubscriptionsApiService } from '@api-ui-app/src/app/subscriptions/services/subscriptions-api.service';
import { TryGelatoPlusModalComponent } from './components/try-gelato-plus-modal/try-gelato-plus-modal.component';
import { ChargeBeeApiService } from '@api-ui-app/src/app/subscriptions/services/charge-bee-api.service';
import { SubscriptionPlansApiService } from '@api-ui-app/src/app/subscriptions/services/subscription-plans-api.service';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { SubscriptionsToEditorCommunicationService } from '@api-ui-app/src/app/subscriptions/services/subscriptions-to-editor-communication.service';
import { SubscriptionsSharedModule } from '@api-ui-app/src/app/subscriptions/subscriptions-shared.module';
import { ActiveSubscriptionsService } from '@api-ui-app/src/app/subscriptions/services/active-subscriptions.service';
import { InstallingGelatoPlusService } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/installing-gelato-plus-service';
import { SubscriptionsHelperService } from '@api-ui-app/src/app/subscriptions/services/subscriptions-helper.service';
import { FormsModule } from '@angular/forms';
import { BackgroundRemoveService } from '@api-ui-app/src/app/subscriptions/services/background-remove.service';

const SubscriptionsComponents = [TryGelatoPlusModalComponent];

@NgModule({
  declarations: [...SubscriptionsComponents],
  exports: [...SubscriptionsComponents],
  providers: [
    ChargeBeeService,
    ChargeBeeApiService,
    SubscriptionsApiService,
    SubscriptionPlansApiService,
    SubscriptionsFacade,
    SubscriptionsToEditorCommunicationService,
    SubscriptionsHelperService,
    ActiveSubscriptionsService,
    InstallingGelatoPlusService,
    BackgroundRemoveService,
  ],
  imports: [LayoutModule, SharedModule, SubscriptionsSharedModule, FormsModule],
})
export class SubscriptionsModule {}
