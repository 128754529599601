import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PreflightGraphQlService } from '@gelato-api-ui/core/preflight/services/preflight-graph-ql.service';
import { EditorAdditionalScenesRegExp } from '../apparel-preview-scenes';

@Injectable({ providedIn: 'root' })
export class ProductPreviewScenesService {
  constructor(private readonly preflightGraphQlService: PreflightGraphQlService) {}

  get(sampleProductUid: string, isProductPageScenes: boolean = false): Observable<string[]> {
    return this.preflightGraphQlService.fetchProductPreviewScenes(sampleProductUid).pipe(
      catchError(() => of(null)),
      map((scenes): string[] => {
        // we should not show "new print area" previews for Product Page
        // they are not "selling" previews, but rather technical ones
        return isProductPageScenes ? scenes.filter(s => !EditorAdditionalScenesRegExp.test(s)) : scenes;
      }),
    );
  }
}
