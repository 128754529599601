import { StoreProductMode } from '../store-product-mode.enum';
import { homeCategoryId } from '@gelato-api-ui/core/product-catalogue/product-search-categories';

const getMainRouteSegment = (storeProductMode: StoreProductMode): string => {
  switch (storeProductMode) {
    case StoreProductMode.PRODUCT_WIZARD_BULK_CONNECT:
      return 'init-bulk-connect';
    case StoreProductMode.PRODUCT_TEMPLATE_WIZARD:
      return 'init-new-product-template';
    default:
      return 'init-new-product';
  }
};

export const getProductRouterLink = (
  storeProductMode: StoreProductMode,
  rootPath: string,
  productCategoryName: string,
  parentCategoryName: string,
  productName: string,
  productUid?: string,
  pageCount?: number,
): any[] => {
  if (
    [
      StoreProductMode.PRODUCT_WIZARD,
      StoreProductMode.PRODUCT_TEMPLATE_WIZARD,
      StoreProductMode.PRODUCT_WIZARD_BULK_CONNECT,
    ].includes(storeProductMode)
  ) {
    const productWizardRouteParams: any = {
      productCategoryName,
      productName,
    };

    if (productUid) {
      productWizardRouteParams.productUid = productUid;
    }

    if (pageCount) {
      productWizardRouteParams.pageCount = pageCount;
    }

    return [rootPath, getMainRouteSegment(storeProductMode), productWizardRouteParams];
  }

  let route = [rootPath, 'categories'];

  if (parentCategoryName && parentCategoryName !== homeCategoryId) {
    route.push(parentCategoryName);
  }

  route = [...route, productCategoryName, 'products', productName];
  return route;
};
