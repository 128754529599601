import { Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { loadImage } from '@gelato-api-ui/core/dom/helpers/loadImage';

interface ImagePreviewModalContext {
  title: string;
  url: string;
}

@Component({
  selector: 'gd-image-preview-modal',
  templateUrl: './image-preview-modal.component.html',
  styleUrls: ['./image-preview-modal.component.scss'],
})
export class ImagePreviewModalComponent implements OnInit {
  loadedImageUrl: string = null;

  constructor(public modal: SuiModal<ImagePreviewModalContext, void, void>) {}

  ngOnInit() {
    const imageUrl = this.modal.context.url;

    loadImage(imageUrl).then(() => {
      this.loadedImageUrl = imageUrl;
    });
  }

  close() {
    this.modal.approve(undefined);
  }
}

export class ImagePreviewModal extends ComponentModalConfig<ImagePreviewModalContext, void, void> {
  constructor(url: string, title: string) {
    super(ImagePreviewModalComponent, {
      url,
      title,
    });

    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Normal;
  }
}
