import { Injectable } from '@angular/core';
import { ActionCreator, select, Store } from '@ngrx/store';
import { paymentDetailsQuery } from './payment-details.selector';
import { deleteStart, fetchIntent, setBackupStart, setDefaultStart } from './payment-details.actions';
import { PaymentDetailsState } from './payment-details.adapter';
import { PaymentDetailsApiService } from '../../services/payment-details-api.service';
import { Actions, ofType } from '@ngrx/effects';
import { first } from 'rxjs/operators';

@Injectable()
export class PaymentDetailsFacade {
  public readonly all$ = this.store$.select(paymentDetailsQuery.getAll);
  public readonly count$ = this.store$.select(paymentDetailsQuery.getCount);
  public readonly ids$ = this.store$.select(paymentDetailsQuery.getIds);
  public readonly isFetchingComplete$ = this.store$.select(paymentDetailsQuery.isFetchingComplete);
  public readonly isFetchingStarted$ = this.store$.select(paymentDetailsQuery.isFetchingStarted);
  public readonly isFetchingToBeDone$ = this.store$.select(paymentDetailsQuery.isFetchingToBeDone);
  public readonly last$ = this.store$.select(paymentDetailsQuery.getLastAdded);
  public readonly default$ = this.store$.select(paymentDetailsQuery.getDefault);

  constructor(
    private readonly store$: Store<PaymentDetailsState>,
    private readonly actions$: Actions,
    private readonly paymentDetailsApi: PaymentDetailsApiService,
  ) {}

  public triggerFetchIntent(force = false) {
    this.store$.dispatch(fetchIntent({ force }));
  }

  public search() {
    return this.paymentDetailsApi.search();
  }

  public triggerDelete(id: string) {
    this.store$.dispatch(deleteStart({ id }));
  }

  public delete(id: string) {
    return this.paymentDetailsApi.delete(id);
  }

  public triggerSetDefault(id: string) {
    this.store$.dispatch(setDefaultStart({ id }));
  }

  public triggerSetBackup(id: string) {
    this.store$.dispatch(setBackupStart({ id }));
  }

  public setUsageOrder(id: string, data: { isDefault: boolean; isBackup: boolean }) {
    return this.paymentDetailsApi.setUsageOrder(id, data);
  }

  public byId(id: string) {
    return this.store$.pipe(select(paymentDetailsQuery.getByUid(id)));
  }

  public onAction(action: ActionCreator) {
    return this.actions$.pipe(ofType(action), first());
  }
}
