import { Inject, Pipe, PipeTransform } from '@angular/core';
import { STATIC_URL } from './static-url.token';

@Pipe({ name: 'staticPath' })
export class StaticPathPipe implements PipeTransform {
  private readonly staticUrl: string;

  constructor(@Inject(STATIC_URL) staticUrl: string) {
    this.staticUrl = staticUrl.replace(/\/?$/, '/');
  }

  transform(value: string): string {
    return value.match(/https?:\/\//gi) ? value : `${this.staticUrl}${value.replace(/^\/?/, '')}`;
  }
}
