<div *ngIf="modal.context.title" class="header">
  {{ modal.context.title }}
</div>
<div *ngIf="modal.context.message" class="content">
  <p>{{ modal.context.message }}</p>
</div>
<div class="actions">
  <button class="ui primary button" (click)="modal.approve(undefined)" autofocus>
    <ng-template [ngIf]="modal.context.approveButtonText">
      {{ modal.context.approveButtonText }}
    </ng-template>
    <ng-template [ngIf]="!modal.context.approveButtonText">
      {{ 'txt_ok' | translate }}
    </ng-template>
  </button>
</div>
