<div class="preview" (click)="playVideo(videoId)">
  <img [src]="imgUrl" />
  <gc-play-button></gc-play-button>
</div>

<gc-single-video-fullscreen
  *ngIf="playVideo$ | async"
  [videoId]="playVideo$ | async"
  (close)="playVideo(null)"
></gc-single-video-fullscreen>
