import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree } from '@angular/router';
import { combineLatest, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { KeycloakAuthService } from '@api-ui-app/src/app/shared/services/keycloak-auth.service';
import { LastRequestedUrlService } from '@api-ui-app/src/app/shared/services/last-requested-url.service';
import { DestygoWebChatService } from '@api-ui-app/src/app/app-store/services/destygo-web-chat.service';

@Injectable({
  providedIn: 'root',
})
export class ActivationGuard implements CanActivate {
  constructor(
    private auth: KeycloakAuthService,
    private router: Router,
    private lastRequestedUrlService: LastRequestedUrlService,
    private destygoWebChatService: DestygoWebChatService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([this.auth.isAuthorised(), this.auth.isActivated(), this.auth.userId()]).pipe(
      switchMap(([authorised, activated, userId]) => {
        if (!authorised) {
          if (route.queryParams['from-landing']) {
            this.auth.redirectToSignUp();
          } else {
            this.destygoWebChatService.resetChat();
            this.auth.redirectToSignIn();
          }
        }
        if (activated === false) {
          if (userId) {
            return this.auth.activateUser().pipe(map(() => true));
          } else {
            this.lastRequestedUrlService.saveActivatedRouteSnapshot(route, this.lastRequestedUrlService.activationKey);
            return of(this.router.createUrlTree(['/on-boarding'], { queryParams: route.queryParams }));
          }
        }

        return of(true);
      }),
    );
  }
}
