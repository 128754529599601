import { Inject, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ASSET_BASE_URL } from '@gelato-api-ui/core/asset/asset-token';

@Pipe({ name: 'assetsPath' })
export class AssetsPathPipe implements PipeTransform {
  constructor(@Inject(ASSET_BASE_URL) private baseUrl: string) {}

  transform(relativeAssetUrl: any, args?: any): Observable<string> {
    return of(`${this.baseUrl}/assets/${relativeAssetUrl}`);
  }
}
