import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SHOPIFY_APP_URL } from '@api-ui-app/src/app/lib/constants';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';

@Component({
  selector: 'gd-stores-list-empty-state',
  templateUrl: './stores-list-empty-state.component.html',
  styleUrls: ['./stores-list-empty-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoresListEmptyStateComponent {
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  shopifyAppUrl = SHOPIFY_APP_URL;

  items = [
    {
      type: EStoreType.SHOPIFY,
      name: this.translateService.instant('txt_connect_shopify_store'),
    },
    {
      type: EStoreType.ETSY,
      name: this.translateService.instant('txt_connect_etsy_store'),
    },
  ];

  constructor(private readonly translateService: TranslateService) {}

  requestModalClose() {
    this.closeModal.emit();
  }
}
