import { Action } from '@ngrx/store';
import { State } from './product-catalogue-selection-list.reducer';

export enum ProductCatalogueSelectionListActionTypes {
  Load = '[ProductCatalogueSelectionList] Load',
  SetState = '[ProductCatalogueSelectionList] Set State',
}

export class Load implements Action {
  readonly type = ProductCatalogueSelectionListActionTypes.Load;
}

export class SetState implements Action {
  readonly type = ProductCatalogueSelectionListActionTypes.SetState;

  constructor(public payload: State) {}
}

export type ProductCatalogueSelectionListActions = Load | SetState;
