import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { BillingEntity } from '../billing-entities/lib/billing-entity';
import { BillingEntitySearchRequest } from '../billing-entities/lib/billing-entity-search-request';
import { BillingEntitySearchResponse } from '../billing-entities/lib/billing-entity-search-response';
import * as actions from './billing-entities.actions';

export const getState = (state: AppState): State => state.billingEntities;

export const getBillingEntityListState = createSelector(
  getState,
  (state: State): BillingEntityListState => state.billingEntityList,
);

export const getBillingEntityListResponse = createSelector(
  getBillingEntityListState,
  (billingEntityListState: BillingEntityListState): BillingEntitySearchResponse => billingEntityListState.payload,
);

export const getBillingEntityState = createSelector(
  getState,
  (state: State): BillingEntityState => state.billingEntity,
);

export const getBillingEntity = createSelector(
  getBillingEntityState,
  (billingEntityState: BillingEntityState): BillingEntity => billingEntityState.payload,
);

export interface State {
  billingEntityList: BillingEntityListState;
  billingEntity: BillingEntityState;
}

export interface BillingEntityListState {
  isLoading: boolean;
  request: BillingEntitySearchRequest;
  payload: BillingEntitySearchResponse;
}

export interface BillingEntityState {
  isLoading: boolean;
  payload: BillingEntity;
}

export const initialState: State = {
  billingEntityList: {
    isLoading: false,
    request: null,
    payload: null,
  },
  billingEntity: {
    isLoading: false,
    payload: null,
  },
};

export function reducer(state = initialState, action: actions.BillingEntitiesActions): State {
  switch (action.type) {
    case actions.BillingEntitiesActionTypes.ResetBillingEntityListState:
      return {
        ...state,
        billingEntityList: { ...initialState.billingEntityList },
      };
    case actions.BillingEntitiesActionTypes.ResetBillingEntityListPayload:
      return {
        ...state,
        billingEntityList: {
          ...state.billingEntityList,
          payload: null,
        },
      };
    case actions.BillingEntitiesActionTypes.SetBillingEntityListState:
      return {
        ...state,
        billingEntityList: action.payload,
      };
    case actions.BillingEntitiesActionTypes.ResetBillingEntityState:
      return {
        ...state,
        billingEntity: { ...initialState.billingEntity },
      };
    case actions.BillingEntitiesActionTypes.SetBillingEntityState:
      return {
        ...state,
        billingEntity: action.payload,
      };
    default:
      return state;
  }
}
