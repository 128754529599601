import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gc-country-flag',
  templateUrl: './country-flag.component.html',
  styleUrls: ['./country-flag.component.scss'],
})
export class CountryFlagComponent implements OnInit {
  @Input() countryIsoCode = null;

  constructor() {}

  ngOnInit() {}
}
