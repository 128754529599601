export const sortAccessScopesByPriority = (
  accessScopes: string[],
  descendingSortingOrder: boolean = false,
): string[] => {
  if (!accessScopes) {
    return [];
  }

  return [...accessScopes].sort((a: string, b: string) => {
    const priorities = {
      ro: 1,
      rw: 2,
    };

    const accessLevelA = a.substr(a.length - 2).toLowerCase();
    const accessLevelB = b.substr(b.length - 2).toLowerCase();

    if (accessLevelA === accessLevelB) {
      return a.localeCompare(b);
    }

    const result = priorities[accessLevelA] - priorities[accessLevelB];

    return descendingSortingOrder ? -result : result;
  });
};
