import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DropdownService } from '@gelato-api-ui/ui-kit/src/lib/dropdown/services';
import { DropdownContentType } from '@gelato-api-ui/ui-kit/src/lib/dropdown/types';
import { DeckService } from '@gelato-api-ui/ui-kit/src/lib/deck/services';
import { DeckContentType } from '@gelato-api-ui/ui-kit/src/lib/deck/types';
import { DropdownRef } from '@gelato-api-ui/ui-kit/src/lib/dropdown/components/dropdown-ref/dropdown-ref';
import { DeckRef } from '@gelato-api-ui/ui-kit/src/lib/deck/components/deck-ref/deck-ref';
import { Promotion } from '../voucher/voucher';

@Component({
  selector: 'gd-user-discounts-menu',
  templateUrl: './user-discounts-menu.component.html',
  styleUrls: ['./user-discounts-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDiscountsMenuComponent {
  @Input() isMobile: boolean;
  @Input() discounts: Promotion[] = [];

  private dropdownRef: DropdownRef;
  private deckRef: DeckRef;

  constructor(private readonly dropdown: DropdownService, private readonly deck: DeckService) {}

  onOpenMenu(content: DropdownContentType, origin: HTMLElement): void {
    this.onCloseMenu();

    if (this.isMobile) {
      this.onOpenMobileSwitcher(content);
    } else {
      this.onOpenDesktopSwitcher(content, origin);
    }
  }

  onCloseMenu() {
    if (this.dropdownRef) {
      this.dropdownRef.triggerCloseDropdown();
    }

    if (this.deckRef) {
      this.deckRef.triggerCloseDeck();
    }
  }

  private onOpenDesktopSwitcher(content: DropdownContentType, origin: HTMLElement): void {
    if (this.dropdownRef) {
      this.dropdownRef.close();
    }

    this.dropdownRef = this.dropdown.open({
      content,
      origin,
      width: 300,
      height: 'auto',
      position: 'end',
    });
  }

  private onOpenMobileSwitcher(content: DeckContentType): void {
    this.deckRef = this.deck.open({
      content,
      width: '100%',
      height: 'auto',
    });
  }
}
