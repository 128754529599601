import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipPositions } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.enum';

@Component({
  selector: 'gc-circle-crown-icon',
  templateUrl: './circle-crown-icon.component.html',
  styleUrls: ['./circle-crown-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CircleCrownIconComponent {
  @Input() shrinkOnMobile = true;
  @Input() colorType: 'light' | 'bright' = 'bright';
  @Input() performActionOnClick = false;
  @Input() tooltipText = 'txt_press_to_learn_more_about_gelato';

  @Output() labelClick = new EventEmitter();

  public gcTooltipPosition: typeof TooltipPositions = TooltipPositions;

  get imgName() {
    return this.colorType === 'bright' ? 'sdk/crown-white.svg' : 'sdk/crown.svg';
  }

  onLabelClick() {
    if (this.performActionOnClick) {
      this.labelClick.emit();
    }
  }
}
