import { Component, Input, OnInit } from '@angular/core';
import { OrderStatus } from '../../orders/lib/order-status.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gd-order-status-label',
  templateUrl: './order-status-label.component.html',
  styleUrls: ['./order-status-label.component.scss'],
})
export class OrderStatusLabelComponent implements OnInit {
  @Input() status: OrderStatus = null;
  @Input() noShrink = false;
  @Input() fluid = true;
  @Input() isCheckout: boolean;

  constructor(private readonly translate: TranslateService) {}

  ngOnInit() {}

  get color() {
    switch (this.status) {
      case OrderStatus.CREATED:
      case OrderStatus.UPLOADING:
        return 'grey';
      case OrderStatus.PASSED:
      case OrderStatus.PRINTED:
      case OrderStatus.SHIPPED:
        return 'green';
      case OrderStatus.PENDING_APPROVAL:
      case OrderStatus.PENDING_PERSONALIZATION:
      case OrderStatus.CANCELED:
        return 'orange';
      case OrderStatus.FAILED:
      case OrderStatus.NOT_CONNECTED:
      case OrderStatus.ON_HOLD:
        return 'red';
      case OrderStatus.IGNORED:
        return `${this.isCheckout ? 'white border' : 'grey'}`;
      case OrderStatus.DRAFT:
        return `${this.isCheckout ? 'blue' : 'grey'}`;
      default:
        return null;
    }
  }

  get text() {
    if (this.isCheckout && this.status === OrderStatus.DRAFT) {
      return this.translate.instant('txt_saved_as_draft');
    }

    return this.translate.instant(`txt_order_status_${this.status}`);
  }
}
