import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import * as actions from './sanity.actions';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { SanityRelatedProducts } from '@gelato-api-ui/core/sanity/SanityRelatedProducts';
import {
  SanityHomePageContent,
  SanityProductCommunicationBannersItem,
} from '@gelato-api-ui/core/sanity/sanity-home-page-content';
import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import { SanityHelloBar } from '@gelato-api-ui/core/sanity/types/sanity-hello-bar';

export interface State {
  isLoading: boolean;
  isRelatedProductsLoading: boolean;
  locale: LocaleCode;
  payload: SanityProductCategory[];
  products: { [id: string]: { category: string; product: SanityProduct } };
  relatedProducts: SanityRelatedProducts[];
  homePageContent: SanityHomePageContent;
  productCommunicationBanners: SanityProductCommunicationBannersItem[];
  helloBar: SanityHelloBar;
  isHelloBarVisible: boolean;
}

export const initialState: State = {
  isLoading: false,
  isRelatedProductsLoading: false,
  locale: null,
  payload: null,
  products: {},
  relatedProducts: [],
  homePageContent: null,
  productCommunicationBanners: null,
  helloBar: null,
  isHelloBarVisible: false,
};

export function reducer(state = initialState, action: actions.SanityActions): State {
  switch (action.type) {
    case actions.SanityActionTypes.ResetState:
      return {
        ...initialState,
      };
    case actions.SanityActionTypes.SetCategories:
      return {
        ...state,
        payload: action.payload,
        locale: action.locale,
        isLoading: false,
      };
    case actions.SanityActionTypes.LoadStart:
      return {
        ...state,
        isLoading: true,
      };
    case actions.loadProductSuccess.type:
      const payload = state.payload.map(category =>
        category.productModel === action.categoryName
          ? { ...category, products: category.products ? [...category.products, action.product] : [action.product] }
          : category,
      );
      return {
        ...state,
        payload,
        products: {
          ...state.products,
          [action.productName]: {
            category: action.categoryName,
            product: action.product,
          },
        },
      };
    case actions.loadRelatedProductsStart.type:
      return {
        ...state,
        isRelatedProductsLoading: true,
      };
    case actions.loadRelatedProductsSuccess.type:
      return {
        ...state,
        isRelatedProductsLoading: false,
        relatedProducts: action.payload,
      };
    case actions.loadHomePageContentSuccess.type:
      return {
        ...state,
        homePageContent: action.payload,
      };
    case actions.loadProductCommunicationBannersSuccess.type:
      return {
        ...state,
        productCommunicationBanners: action.payload,
      };
    case actions.loadHelloBarSuccess.type:
      return {
        ...state,
        helloBar: action.payload
          ? {
              ...action.payload,
              content: action.payload?.content?.filter(m => m.isEnabled),
            }
          : null,
      };
    case actions.setHelloBarVisibility.type:
      return {
        ...state,
        isHelloBarVisible: action.payload,
      };

    default:
      return state;
  }
}

export const sanityReducerToken = new InjectionToken<ActionReducerMap<State>>('sanity reducer');
