import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import * as ExpandCategoriesActions from './expand-categories.actions';
import { InjectionToken } from '@angular/core';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { ExpandCategoriesListItem } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/e-commerce-expand-categories';
import { SupportedPreviewScenesCollection } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/supported-preview-scenes-collection';
import { SelectedPreviewScenesCollection } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/selected-preview-scenes-collection';
import { getSelectedPreviewScenesFromSourceProduct } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/helpers/getSelectedPreviewScenesFromSourceProduct';
import { Design } from '@gelato-api-ui/core/designs/design';
import { ProductExpansionCategoryMappingsCollection } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/product-expansion-category-mappings-collection';
import { DEFAULT_PRICE_MARGIN } from '@api-ui-app/src/app/e-commerce-expand-categories/lib/constants';

export const expandCategoriesFeatureKey = 'expandCategories';

export interface State {
  loading: boolean;
  sourceProduct: EProductWithVariants;
  sourceProductVariants: EProductVariant[];
  expandedProduct: EProductWithVariants;
  existingDimensions: string[];
  selectedDimension: string;
  defaultNewDimension: string;
  defaultNewDimensionValues: string[];
  newDimensionValues: string[];
  categories: ExpandCategoriesListItem[];
  selectedCategories: ExpandCategoriesListItem[];
  mapping: ProductExpansionCategoryMappingsCollection;
  previews: string[];
  variantOptionDesign: Design;
  profitMargin: number; // percents
  createPreviews: boolean;
  supportedPreviewScenes: SupportedPreviewScenesCollection;
  selectedPreviewScenes: SelectedPreviewScenesCollection;
}

export const initialState: State = {
  loading: null,
  sourceProduct: null,
  sourceProductVariants: [],
  expandedProduct: null,
  existingDimensions: [],
  selectedDimension: null,
  defaultNewDimension: null,
  defaultNewDimensionValues: [],
  newDimensionValues: [],
  categories: null,
  selectedCategories: [],
  mapping: {},
  previews: [],
  variantOptionDesign: null,
  profitMargin: DEFAULT_PRICE_MARGIN * 100 - 100,
  createPreviews: false,
  supportedPreviewScenes: {},
  selectedPreviewScenes: {},
};

export const reducer = createReducer(
  initialState,
  on(ExpandCategoriesActions.setSourceProduct, (state, action) => ({
    ...state,
    sourceProduct: action.sourceProduct,
    selectedPreviewScenes: getSelectedPreviewScenesFromSourceProduct(action.sourceProduct),
  })),
  on(ExpandCategoriesActions.setSourceProductVariants, (state, action) => ({
    ...state,
    sourceProductVariants: action.sourceProductVariants,
  })),
  on(ExpandCategoriesActions.updateExpandedProductStart, (state, action) => ({
    ...state,
    loading: true,
    expandedProduct: null,
  })),
  on(ExpandCategoriesActions.updateExpandedProductSuccess, (state, action) => ({
    ...state,
    loading: false,
    expandedProduct: action.expandedProduct,
  })),
  on(ExpandCategoriesActions.updateExpandedProductFailed, state => ({
    ...state,
    loading: false,
  })),
  on(ExpandCategoriesActions.setExistingDimensions, (state, action) => ({
    ...state,
    existingDimensions: action.existingDimensions,
  })),
  on(ExpandCategoriesActions.setSelectedDimension, (state, action) => ({
    ...state,
    selectedDimension: action.selectedDimension,
  })),
  on(ExpandCategoriesActions.setDefaultNewDimension, (state, action) => ({
    ...state,
    defaultNewDimension: action.defaultNewDimension,
  })),
  on(ExpandCategoriesActions.setDefaultNewDimensionValues, (state, action) => ({
    ...state,
    defaultNewDimensionValues: action.defaultNewDimensionValues,
  })),
  on(ExpandCategoriesActions.setNewDimensionValues, (state, action) => ({
    ...state,
    newDimensionValues: action.newDimensionValues,
  })),
  on(ExpandCategoriesActions.setMapping, (state, action) => ({
    ...state,
    mapping: action.mapping,
  })),
  on(ExpandCategoriesActions.setCategories, (state, action) => ({
    ...state,
    categories: action.categories,
  })),
  on(ExpandCategoriesActions.fetchVariantOptionDesignSuccess, (state, action) => ({
    ...state,
    variantOptionDesign: action.design,
  })),
  on(ExpandCategoriesActions.clearExpandCategoriesState, () => ({
    ...initialState,
  })),
  on(ExpandCategoriesActions.selectCategory, (state, action) => {
    const isAdded = state.selectedCategories.find(
      selectedCategory => selectedCategory.productUid === action.category.productUid,
    );
    const updatedCategories = isAdded
      ? state.selectedCategories.filter(c => c.productUid !== action.category.productUid)
      : [...state.selectedCategories, action.category];
    return {
      ...state,
      selectedDimension: null,
      selectedCategories: updatedCategories,
    };
  }),
  on(ExpandCategoriesActions.fetchCategoryPreviewsSuccess, (state, action) => {
    const updatedCategories = state.categories.map(category => {
      const { payload } = action;
      const payloadItem = (payload || []).find(item => item.productUid === category.productUid);

      if (!payloadItem) {
        return category;
      }

      return {
        ...category,
        ...payloadItem,
      };
    });

    return {
      ...state,
      categories: updatedCategories,
    };
  }),
  on(ExpandCategoriesActions.setCreatePreviewsFlag, (state, action) => ({
    ...state,
    createPreviews: action.createPreviews,
  })),
  on(ExpandCategoriesActions.fetchSupportedPreviewScenesStart, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(ExpandCategoriesActions.fetchSupportedPreviewScenesSuccess, (state, action) => ({
    ...state,
    loading: false,
    supportedPreviewScenes: action.supportedPreviewScenes,
  })),
  on(ExpandCategoriesActions.setSelectedPreviewScenes, (state, action) => ({
    ...state,
    selectedPreviewScenes: action.selectedPreviewScenes,
  })),
  on(ExpandCategoriesActions.selectPreviewScene, (state, action) => ({
    ...state,
    selectedPreviewScenes: {
      ...state.selectedPreviewScenes,
      [action.productTypeUid]: action.previewScene,
    },
  })),
  on(ExpandCategoriesActions.setProfitMargin, (state, action) => {
    return {
      ...state,
      profitMargin: action.margin,
    };
  }),
);

export const expandCategoriesReducerToken = new InjectionToken<ActionReducerMap<State>>('expand categories reducer');
