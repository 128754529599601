import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { isSupportedLocale } from '@gelato-api-ui/core/i18n/helpers/isSupportedLocale';
import { KeycloakAuthService } from '@api-ui-app/src/app/shared/services/keycloak-auth.service';
import { LocaleService } from '@api-ui-app/src/app/shared/services/locale.service';

@Injectable({ providedIn: 'root' })
export class LocalizationGuard implements CanActivate {
  constructor(private readonly auth: KeycloakAuthService, private readonly localeService: LocaleService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const queryLocale = route.queryParams['locale'];
    if (queryLocale) {
      this.applyLocale(queryLocale);
    }
    return of(true);
  }

  private applyLocale(locale: string): void {
    if (locale && isSupportedLocale(locale)) {
      this.localeService.updateLocale(locale as LocaleCode);
    }
  }
}
