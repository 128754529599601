<div
  #origin
  class="ui secondary-grey button header-create-menu"
  [class.header-create-menu-desktop]="!isMobile"
  [class.header-create-menu-mobile]="isMobile"
  (click)="onOpenMenu()"
>
  <img [src]="'icons/add-product-small.svg' | assetsPath | async" width="20" height="20" />
  <span>{{ 'txt_header_create_menu_button' | translate }}</span>
</div>

<ng-template #rootListContent>
  <div class="menu-content" [class.mobile]="isMobile">
    <div *ngIf="isMobile" class="deck-header">
      <h3>
        {{ 'txt_header_create_menu_button' | translate }}
      </h3>
      <img
        (click)="onCloseSwitcher()"
        class="close"
        [src]="'checkout/close.svg' | assetsPath | async"
        alt=""
        width=""
        height=""
      />
    </div>
    <div class="menu-items" [class.mobile]="isMobile">
      <div class="menu-item" data-item-name="place-order" (click)="onPlaceOrder()">
        {{ 'txt_header_create_menu_item_place_order_order' | translate }}
      </div>
      <div class="menu-item" data-item-name="add-new-product" (click)="onAddNewProduct()">
        {{ 'txt_header_create_menu_item_place_add_new_product' | translate }}
      </div>
    </div>
  </div>
</ng-template>
