import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gd-number-selector',
  templateUrl: './number-selector.component.html',
  styleUrls: ['./number-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberSelectorComponent implements OnInit, OnChanges {
  @Input()
  value = null;

  @Input()
  min = -999999;

  @Input()
  max = 999999;

  @Input()
  step = 1;

  @Input()
  disabled = false;

  @Output()
  valueChange = new EventEmitter<number>();

  selectedOption: string = null;
  options$ = new BehaviorSubject<string[]>([]);

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.min || changes.max || changes.step) {
      const options = [];
      const min = this.min;
      const max = this.max;
      const step = this.step;

      for (let i = min; i <= max; i += step) {
        options.push(String(i));
      }

      this.options$.next(options);
    }

    if (changes.value) {
      this.selectedOption = this.value ? String(this.value) : null;
    }
  }

  onSelect(value: string) {
    let newValue = Number(value);

    newValue = Math.min(newValue, this.max);
    newValue = Math.max(newValue, this.min);

    this.valueChange.emit(newValue);
  }
}
