import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '@api-ui-app/src/app/app.state';
import { State } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.reducer';
import { ServicePlanResponse } from '@api-ui-app/src/app/subscriptions/types/subscription-plans';
import { ServicePlanPrice, ServiceUniqueName } from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { SanitySubscriptionPlan } from '@gelato-api-ui/core/app-store/sanity-subscription-plan';
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';
import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';
import {
  CustomSubscriptionStatus,
  SubscriptionResponse,
  SubscriptionStatus,
  TrialEndAction,
} from '../types/subscriptions';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import { GELATO_PLUS_ONE_MONTH_LEFT_DAYS } from '../constants/warning-trial-days-left';
import {
  findEComFeatureServicePlanId,
  findExpandCategoriesServicePlanId,
  findGelatoPlusGoldServicePlanId,
  findGelatoPlusServicePlanId,
  getSubscriptionByStatus,
  getSubscriptionsByStatuses,
} from '../lib/helpers/getSubscriptionHelpers';
import { differenceInMilliseconds, differenceInMinutes, parseISO } from 'date-fns';
import { AppStoreService } from '@api-ui-app/src/app/app-store/services/app-store.service';
import {
  DATE_WHEN_ONE_MONTH_FREE_SHIPPING_APPLIED,
  DATE_WHEN_ONE_MONTH_FREE_SHIPPING_REMOVED,
} from '../constants/subscription-dates';
import { SubscriptionPlanType } from '../../app-store/types/price-plan-items';

export const getSubscriptionsState = (state: AppState): State => state.subscriptions;

export const getActiveSubscriptions = createSelector(getSubscriptionsState, state => {
  return state.activeSubscriptions;
});

export const getIsSubscriptionPlansLoading = createSelector(getSubscriptionsState, state => {
  return state.loading;
});

export const getServicePlansPrices = createSelector(getSubscriptionsState, (state: State): ServicePlanPrice[] => {
  return state.servicePlansPricesList;
});

export const getServicePlans = createSelector(
  getSubscriptionsState,
  (state: State): ServicePlanResponse[] => state.servicePlansList,
);

export const getSubscriptionPlansListFromSanity = createSelector(
  getSubscriptionsState,
  (state: State): SanitySubscriptionPlan[] => state.subscriptionPlansListFromSanity || [],
);

export const getActiveServicePlans = createSelector(
  getSubscriptionsState,
  (state: State): ServicePlanResponse[] => state.activeServicePlans,
);

export const getIsActiveSubscriptionsLoaded = createSelector(
  getSubscriptionsState,
  (state: State) => state.activeSubscriptionsLoaded,
);

// subscription plans can be re-requested, so we will have loading false -> true again
// this function checks if we don't have initial data, i.e. loading plans only first time
export const getIsLoadingSubscriptionPlansFirstTime = createSelector(
  getIsActiveSubscriptionsLoaded,
  getIsSubscriptionPlansLoading,
  getActiveServicePlans,
  (isActiveSubscriptionsLoaded, isLoading, activeServicePlans) =>
    !isActiveSubscriptionsLoaded && isLoading && !activeServicePlans,
);

export const getActiveServiceUniqueNames = createSelector(
  getActiveServicePlans,
  (activeServicePlans: ServicePlanResponse[]): string[] => {
    if (!activeServicePlans) {
      return [];
    }

    return R.uniq(activeServicePlans.map((loopServicePlan: ServicePlanResponse) => loopServicePlan.serviceUniqueName));
  },
);

export const getActivePeriodUnit = createSelector(
  getSubscriptionsState,
  (state: State): TimePeriodUnit => state.activePeriodUnit,
);

export const getGelatoPlusIsActive = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusServicePlanId = findGelatoPlusServicePlanId(activeServicePlans);

    const filteredActiveSubscription = activeSubscriptions?.find(
      subscription =>
        subscription?.plan?.servicePlanId === gelatoPlusServicePlanId &&
        subscription.status !== SubscriptionStatus.cancelled,
    );

    return Boolean(filteredActiveSubscription);
  },
);

export const getGelatoPlusGoldIsActive = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusServicePlanId = findGelatoPlusGoldServicePlanId(activeServicePlans);

    const filteredActiveSubscription = activeSubscriptions?.find(
      subscription =>
        subscription?.plan?.servicePlanId === gelatoPlusServicePlanId &&
        subscription.status !== SubscriptionStatus.cancelled,
    );

    return Boolean(filteredActiveSubscription);
  },
);

export const getIsGelatoPlusGoldTrialActive = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusServicePlanId = findGelatoPlusGoldServicePlanId(activeServicePlans);
    const trialGoldSubscription = activeSubscriptions?.find(
      subscription =>
        subscription?.plan?.servicePlanId === gelatoPlusServicePlanId &&
        subscription.status === SubscriptionStatus.in_trial,
    );

    return Boolean(trialGoldSubscription);
  },
);

export const getGelatoPlusOrGoldIsActive = createSelector(
  getGelatoPlusIsActive,
  getGelatoPlusGoldIsActive,
  (isGelatoPlusActive, isGelatoPlusGoldActive) => {
    if (isGelatoPlusActive === null || isGelatoPlusGoldActive === null) {
      return null;
    }

    return isGelatoPlusActive || isGelatoPlusGoldActive;
  },
);

export const getFreeShippingDaysLeft = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getGelatoPlusGoldIsActive,
  getIsActiveSubscriptionsLoaded,
  (
    activeServicePlans: ServicePlanResponse[],
    activeSubscriptions: SubscriptionResponse[],
    isGelatoPlusGoldActive: boolean,
    isLoaded: boolean,
  ) => {
    if (!isLoaded || !activeServicePlans || !isGelatoPlusGoldActive) {
      return null;
    }

    const gelatoPlusGoldPlanServiceId = findGelatoPlusGoldServicePlanId(activeServicePlans);
    const gelatoPlusGoldSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.active, SubscriptionStatus.non_renewing],
      gelatoPlusGoldPlanServiceId,
    );
    const gelatoPlusGoldSubscriptionData = gelatoPlusGoldSubscriptions[gelatoPlusGoldSubscriptions.length - 1];
    const isOneMonthFreeShippingAvailable =
      new Date(gelatoPlusGoldSubscriptionData.activatedAt) < DATE_WHEN_ONE_MONTH_FREE_SHIPPING_REMOVED;
    const daysLeft =
      isOneMonthFreeShippingAvailable && calculateFreeShippingDaysLeft(gelatoPlusGoldSubscriptionData.activatedAt);

    return daysLeft > 0 ? daysLeft : null;
  },
);

export const getIsGelatoPlusGoldYearlyPlanActive = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getGelatoPlusGoldIsActive,
  getIsActiveSubscriptionsLoaded,
  (
    activeServicePlans: ServicePlanResponse[],
    activeSubscriptions: SubscriptionResponse[],
    isGelatoPlusGoldActive: boolean,
    isLoaded: boolean,
  ) => {
    if (!isLoaded || !activeServicePlans || !isGelatoPlusGoldActive) {
      return null;
    }

    const gelatoPlusGoldPlanServiceId = findGelatoPlusGoldServicePlanId(activeServicePlans);
    const gelatoPlusGoldSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.active, SubscriptionStatus.non_renewing],
      gelatoPlusGoldPlanServiceId,
    );
    return (
      gelatoPlusGoldSubscriptions[gelatoPlusGoldSubscriptions.length - 1].billingPeriodUnit === TimePeriodUnit.YEAR
    );
  },
);

export const getIsGelatoPlusYearlyPlanActive = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getGelatoPlusIsActive,
  getIsActiveSubscriptionsLoaded,
  (
    activeServicePlans: ServicePlanResponse[],
    activeSubscriptions: SubscriptionResponse[],
    isGelatoPlusActive: boolean,
    isLoaded: boolean,
  ) => {
    if (!isLoaded || !activeServicePlans || !isGelatoPlusActive) {
      return null;
    }

    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);
    const gelatoPlusSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.active, SubscriptionStatus.non_renewing],
      gelatoPlusPlanServiceId,
    );

    return gelatoPlusSubscriptions[gelatoPlusSubscriptions.length - 1]?.billingPeriodUnit === TimePeriodUnit.YEAR;
  },
);

export const getGelatoPlusSubscriptionStartDate = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getGelatoPlusIsActive,
  getIsActiveSubscriptionsLoaded,
  (
    activeServicePlans: ServicePlanResponse[],
    activeSubscriptions: SubscriptionResponse[],
    isGelatoPlusdActive: boolean,
    isLoaded: boolean,
  ) => {
    if (!isLoaded || !activeServicePlans || !isGelatoPlusdActive) {
      return null;
    }

    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);
    const gelatoPlusSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.active, SubscriptionStatus.non_renewing, SubscriptionStatus.in_trial],
      gelatoPlusPlanServiceId,
    );
    const gelatoPlusSubscriptionData = gelatoPlusSubscriptions[gelatoPlusSubscriptions.length - 1];

    return gelatoPlusSubscriptionData.startedAt;
  },
);

export const getGelatoPlusGoldSubscriptionStartDate = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getGelatoPlusGoldIsActive,
  getIsActiveSubscriptionsLoaded,
  (
    activeServicePlans: ServicePlanResponse[],
    activeSubscriptions: SubscriptionResponse[],
    isGelatoPlusGoldActive: boolean,
    isLoaded: boolean,
  ) => {
    if (!isLoaded || !activeServicePlans || !isGelatoPlusGoldActive) {
      return null;
    }

    const gelatoPlusGoldPlanServiceId = findGelatoPlusGoldServicePlanId(activeServicePlans);
    const gelatoPlusGoldSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.active, SubscriptionStatus.non_renewing],
      gelatoPlusGoldPlanServiceId,
    );
    const gelatoPlusGoldSubscriptionData = gelatoPlusGoldSubscriptions[gelatoPlusGoldSubscriptions.length - 1];

    return gelatoPlusGoldSubscriptionData?.startedAt;
  },
);

export const getSelectedSubscriptionPlan = createSelector(
  getGelatoPlusIsActive,
  getGelatoPlusGoldIsActive,
  (isGelatoPlusActive, isGoldActive) => {
    const conditions: [ServicePlanUniqueNames, () => boolean][] = [
      [ServicePlanUniqueNames.gelato_platform_free, () => !isGelatoPlusActive && !isGoldActive],
      [ServicePlanUniqueNames.gelato_platform_plus, () => isGelatoPlusActive],
      [ServicePlanUniqueNames.gelato_platform_gold, () => isGoldActive],
    ];

    return conditions?.find(([, condition]) => !!condition())[0];
  },
);

export const getPlatformActivePlanMetadata = createSelector(
  getActiveServicePlans,
  getGelatoPlusIsActive,
  getGelatoPlusGoldIsActive,
  (activeServicePlans: ServicePlanResponse[], gelatoPlusIsActive, gelatoGoldIsActive): ServicePlanResponse => {
    const gelatoPlatformServicePlans = activeServicePlans?.filter(
      activePlan => activePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM && !!activePlan.metadata.length,
    );

    const servicePlanUniqueNames = gelatoGoldIsActive
      ? [ServicePlanUniqueNames.gelato_platform_gold]
      : gelatoPlusIsActive
      ? [ServicePlanUniqueNames.gelato_platform_plus, ServicePlanUniqueNames.gelato_platform_plus_legacy]
      : [ServicePlanUniqueNames.gelato_platform_free, ServicePlanUniqueNames.gelato_platform_free_legacy];

    return gelatoPlatformServicePlans?.find(servicePlan =>
      servicePlanUniqueNames.includes(servicePlan.uniqueName as ServicePlanUniqueNames),
    );
  },
);

export const getNonRenewingSubscriptionData = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);

    return getSubscriptionByStatus(activeSubscriptions, SubscriptionStatus.non_renewing, gelatoPlusPlanServiceId);
  },
);

export const getIsNonRenewingSubscriptionEnding = createSelector(
  getNonRenewingSubscriptionData,
  getActiveServicePlans,
  getActiveSubscriptions,
  (
    subscriptionData: SubscriptionResponse,
    activeServicePlans: ServicePlanResponse[],
    activeSubscriptions: SubscriptionResponse[],
  ) => {
    const gelatoPlusServicePlanId = findGelatoPlusServicePlanId(activeServicePlans);

    const activeAndFutureGelatoPlusSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.future, SubscriptionStatus.active],
      gelatoPlusServicePlanId,
    );

    if (activeAndFutureGelatoPlusSubscriptions?.length || !subscriptionData) {
      return false;
    }

    const daysLeft = AppStoreService.getDaysLeft(subscriptionData.cancelledAt);

    return daysLeft > 0 && daysLeft <= GELATO_PLUS_ONE_MONTH_LEFT_DAYS;
  },
);

export const getNonRenewingGelatoPlusDaysLeft = createSelector(
  getIsNonRenewingSubscriptionEnding,
  getNonRenewingSubscriptionData,
  (isNonRenewingSubscriptionEnding: boolean, subscriptionData: SubscriptionResponse) => {
    if (!isNonRenewingSubscriptionEnding) {
      return null;
    }

    return AppStoreService.getDaysLeft(subscriptionData.cancelledAt);
  },
);

export const getGelatoPlusSubscriptionHasExpired = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  getIsNonRenewingSubscriptionEnding,
  (
    activeServicePlans: ServicePlanResponse[],
    activeSubscriptions: SubscriptionResponse[],
    isLoaded: boolean,
    isNonRenewingSubscriptionEnding: boolean,
  ) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);

    const cancelledGelatoPlusSubscription = getSubscriptionByStatus(
      activeSubscriptions,
      SubscriptionStatus.cancelled,
      gelatoPlusPlanServiceId,
      true,
    );
    const inTrialGelatoPlusSubscription = getSubscriptionByStatus(
      activeSubscriptions,
      SubscriptionStatus.in_trial,
      gelatoPlusPlanServiceId,
    );

    if (isNonRenewingSubscriptionEnding || !cancelledGelatoPlusSubscription || !!inTrialGelatoPlusSubscription) {
      return false;
    }
    const trialEndTime = parseISO(cancelledGelatoPlusSubscription.trialEnd);
    const currentTime = new Date();
    const freeTrialHasExpired = differenceInMilliseconds(currentTime, trialEndTime) > 0;

    return Boolean(freeTrialHasExpired);
  },
);

// Free trial has ended and user doesn't have other subscriptions
export const getIsFreeTrialExpired = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded: boolean) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);
    const cancelledGelatoPlusSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.cancelled],
      gelatoPlusPlanServiceId,
    );
    const filteredActiveSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.in_trial, SubscriptionStatus.active, SubscriptionStatus.future],
      gelatoPlusPlanServiceId,
    );
    const hasOneCancelledFreeTrialSubscription =
      cancelledGelatoPlusSubscriptions.length === 1 &&
      cancelledGelatoPlusSubscriptions[0].trialEndAction !== TrialEndAction.cancel_subscription;

    if (
      cancelledGelatoPlusSubscriptions.length > 1 ||
      filteredActiveSubscriptions.length ||
      !hasOneCancelledFreeTrialSubscription
    ) {
      return false;
    }

    const trialEndTime = parseISO(cancelledGelatoPlusSubscriptions[0].trialEnd);
    const currentTime = new Date();
    const freeTrialHasExpired = differenceInMilliseconds(currentTime, trialEndTime) > 0;

    return Boolean(freeTrialHasExpired);
  },
);

// If user had no Gelato subscription in lifetime
export const getIsUserNeverHadGelatoSubscription = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded: boolean) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }
    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);
    const gelatoPlusGoldPlanServiceId = findGelatoPlusGoldServicePlanId(activeServicePlans);

    const allGelatoPlusSubscriptionsByStatuses = getSubscriptionsByStatuses(
      activeSubscriptions,
      [
        SubscriptionStatus.in_trial,
        SubscriptionStatus.active,
        SubscriptionStatus.future,
        SubscriptionStatus.cancelled,
        SubscriptionStatus.paused,
        SubscriptionStatus.non_renewing,
      ],
      gelatoPlusPlanServiceId,
    );
    const allGelatoPlusGoldSubscriptionsByStatuses = getSubscriptionsByStatuses(
      activeSubscriptions,
      [
        SubscriptionStatus.in_trial,
        SubscriptionStatus.active,
        SubscriptionStatus.future,
        SubscriptionStatus.cancelled,
        SubscriptionStatus.paused,
        SubscriptionStatus.non_renewing,
      ],
      gelatoPlusGoldPlanServiceId,
    );

    return allGelatoPlusSubscriptionsByStatuses?.length === 0 && allGelatoPlusGoldSubscriptionsByStatuses?.length === 0;
  },
);

// If user had no G+ subscription in lifetime
export const getIsUserNeverHadGelatoPlusSubscription = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded: boolean) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }
    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);

    const allGelatoPlusSubscriptionsByStatuses = getSubscriptionsByStatuses(
      activeSubscriptions,
      [
        SubscriptionStatus.in_trial,
        SubscriptionStatus.active,
        SubscriptionStatus.future,
        SubscriptionStatus.cancelled,
        SubscriptionStatus.paused,
        SubscriptionStatus.non_renewing,
      ],
      gelatoPlusPlanServiceId,
    );
    return allGelatoPlusSubscriptionsByStatuses.length === 0;
  },
);

// If user had no G+ Gold subscription in lifetime
export const getIsUserNeverHadGelatoPlusGoldSubscription = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded: boolean) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }
    const gelatoPlusGoldPlanServiceId = findGelatoPlusGoldServicePlanId(activeServicePlans);

    const allGelatoPlusGoldSubscriptionsByStatuses = getSubscriptionsByStatuses(
      activeSubscriptions,
      [
        SubscriptionStatus.in_trial,
        SubscriptionStatus.active,
        SubscriptionStatus.future,
        SubscriptionStatus.cancelled,
        SubscriptionStatus.paused,
        SubscriptionStatus.non_renewing,
      ],
      gelatoPlusGoldPlanServiceId,
    );
    return allGelatoPlusGoldSubscriptionsByStatuses.length === 0;
  },
);

export const getGelatoPlusTrialSubscriptionData = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded: boolean) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);

    const futureGelatoPlusSubscription = getSubscriptionByStatus(
      activeSubscriptions,
      SubscriptionStatus.future,
      gelatoPlusPlanServiceId,
    );
    const hasNoTrialSubscription =
      !gelatoPlusPlanServiceId || !activeSubscriptions?.length || !!futureGelatoPlusSubscription;

    return hasNoTrialSubscription
      ? null
      : getSubscriptionByStatus(activeSubscriptions, SubscriptionStatus.in_trial, gelatoPlusPlanServiceId);
  },
);

export const isGelatoPlusPageVisible = createSelector(
  getGelatoPlusIsActive,
  (gelatoPlusOrEnterpriseIsActive: boolean) => !gelatoPlusOrEnterpriseIsActive,
);

export const getActiveFreePlanIsNotLegacy = createSelector(
  getActiveServicePlans,
  (activeServicePlans: ServicePlanResponse[]): boolean =>
    Boolean(
      activeServicePlans &&
        activeServicePlans.find(
          activeServicePlan =>
            activeServicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM &&
            activeServicePlan.uniqueName === ServicePlanUniqueNames.gelato_platform_free,
        ),
    ),
);

export const getActiveFreePlanIsLegacy = createSelector(
  getActiveServicePlans,
  (activeServicePlans: ServicePlanResponse[]): boolean =>
    Boolean(
      activeServicePlans?.find(
        activeServicePlan =>
          activeServicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM &&
          activeServicePlan.uniqueName === ServicePlanUniqueNames.gelato_platform_free_legacy,
      ),
    ),
);

export const getIsClientCreatedAfterGelatoPlusStart = createSelector(
  getActiveServicePlans,
  (activeServicePlans: ServicePlanResponse[]): boolean =>
    Boolean(
      activeServicePlans?.filter(
        activeServicePlan =>
          activeServicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM &&
          [ServicePlanUniqueNames.gelato_platform_plus, ServicePlanUniqueNames.gelato_platform_free].includes(
            activeServicePlan.uniqueName as ServicePlanUniqueNames,
          ),
      )?.length,
    ),
);

export const getIsClientCreatedBeforeGelatoPlusStart = createSelector(
  getActiveServicePlans,
  (activeServicePlans: ServicePlanResponse[]): boolean =>
    Boolean(
      activeServicePlans?.filter(
        activeServicePlan =>
          activeServicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM &&
          [
            ServicePlanUniqueNames.gelato_platform_plus_legacy,
            ServicePlanUniqueNames.gelato_platform_free_legacy,
          ].includes(activeServicePlan.uniqueName as ServicePlanUniqueNames),
      )?.length,
    ),
);

export const getActivePlansUpdateStatus = createSelector(getSubscriptionsState, state => state.isActivePlansUpdating);

export const getGelatoPlusSubscriptionsStatuses = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded): string[] => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusServicePlanId = findGelatoPlusServicePlanId(activeServicePlans);

    const filteredActiveSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.in_trial, SubscriptionStatus.active, SubscriptionStatus.future],
      gelatoPlusServicePlanId,
    );
    const cancelledSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.cancelled],
      gelatoPlusServicePlanId,
    );

    // if we dont have any active subscriptions and have cancleled - we return status of cancelled
    // if we have active subscriptions and we have cancelled subscriptions - we return status of active ones
    if (filteredActiveSubscriptions?.length === 0 && cancelledSubscriptions?.length > 0) {
      // there may be sevelar cancelled subscriptions - in that case we return the status of the last one
      return [cancelledSubscriptions[cancelledSubscriptions.length - 1].status];
    }

    // Can have multiple values. E.g. Customer have recently created an account and bought a subscription upfront
    // In this case it will be: ['in_trial_cancel_subscription', 'future']
    return filteredActiveSubscriptions.length
      ? filteredActiveSubscriptions?.map(filteredActiveSubscription =>
          filteredActiveSubscription.trialEndAction
            ? `${filteredActiveSubscription.status}_${filteredActiveSubscription.trialEndAction}`
            : filteredActiveSubscription.status,
        )
      : [CustomSubscriptionStatus.absent];
  },
);

export const getGelatoPlusOrGoldSubscriptionsStatuses = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded): string[] => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusOrGoldServicePlanId = findGelatoPlusGoldServicePlanId(activeServicePlans);

    const filteredActiveSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.in_trial, SubscriptionStatus.active, SubscriptionStatus.future],
      gelatoPlusOrGoldServicePlanId,
    );
    const cancelledSubscriptions = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.cancelled],
      gelatoPlusOrGoldServicePlanId,
    );

    // if we dont have any active subscriptions and have canceled - we return status of cancelled
    // if we have active subscriptions and we have cancelled subscriptions - we return status of active ones
    if (filteredActiveSubscriptions?.length === 0 && cancelledSubscriptions?.length > 0) {
      // there may be sevelar cancelled subscriptions - in that case we return the status of the last one
      return [cancelledSubscriptions[cancelledSubscriptions.length - 1].status];
    }

    // Can have multiple values. E.g. Customer have recently created an account and bought a subscription upfront
    // In this case it will be: ['in_trial_cancel_subscription', 'future']
    return filteredActiveSubscriptions.length
      ? filteredActiveSubscriptions?.map(filteredActiveSubscription =>
          filteredActiveSubscription.trialEndAction
            ? `${filteredActiveSubscription.status}_${filteredActiveSubscription.trialEndAction}`
            : filteredActiveSubscription.status,
        )
      : [CustomSubscriptionStatus.absent];
  },
);

export const getServicePricePlanTax = createSelector(getSubscriptionsState, state => state.pricePlanTax);

export const getExpandCategoriesSubscription = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  (activeServicePlans, activeSubscriptions) => {
    const expandCategoriesServicePlanId = findExpandCategoriesServicePlanId(activeServicePlans);

    const filteredActiveSubscription = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.in_trial, SubscriptionStatus.active, SubscriptionStatus.future],
      expandCategoriesServicePlanId,
    );

    return Boolean(filteredActiveSubscription?.length);
  },
);

export const getProfitMarginSubscription = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  (activeServicePlans, activeSubscriptions) => {
    const expandCategoriesServicePlanId = findEComFeatureServicePlanId(
      activeServicePlans,
      ServicePlanUniqueNames.app_profit_margin_plan_0,
    );

    const filteredActiveSubscription = getSubscriptionsByStatuses(
      activeSubscriptions,
      [
        SubscriptionStatus.in_trial,
        SubscriptionStatus.active,
        SubscriptionStatus.future,
        SubscriptionStatus.non_renewing,
      ],
      expandCategoriesServicePlanId,
    );

    return Boolean(filteredActiveSubscription?.length);
  },
);

export const getAutoApproveSubscription = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  (activeServicePlans, activeSubscriptions) => {
    const expandCategoriesServicePlanId = findEComFeatureServicePlanId(
      activeServicePlans,
      ServicePlanUniqueNames.app_automatic_order_approval_plan_0,
    );

    const filteredActiveSubscription = getSubscriptionsByStatuses(
      activeSubscriptions,
      [
        SubscriptionStatus.in_trial,
        SubscriptionStatus.active,
        SubscriptionStatus.future,
        SubscriptionStatus.non_renewing,
      ],
      expandCategoriesServicePlanId,
    );

    return Boolean(filteredActiveSubscription?.length);
  },
);

export const getIsClientEligibleForGoldSubscription = createSelector(
  getSubscriptionsState,
  state => state.isClientEligibleForGoldSubscription,
);

export const getIsGelatoPlusTrialActive = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (
    activeServicePlans: ServicePlanResponse[],
    activeSubscriptions: SubscriptionResponse[],
    isLoaded: boolean,
  ): boolean => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }
    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);

    const gelatoPlusSubscriptionsByStatus = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.in_trial],
      gelatoPlusPlanServiceId,
    );

    const isTrialSubscriptionDoesNotHaveCancelAction =
      Boolean(gelatoPlusSubscriptionsByStatus?.length) &&
      gelatoPlusSubscriptionsByStatus?.[0]?.trialEndAction !== TrialEndAction.cancel_subscription;

    return Boolean(isTrialSubscriptionDoesNotHaveCancelAction);
  },
);

export const getIsGelatoPlusTrialScheduledToCancel = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);
    const gelatoPlusSubscriptionsByStatus = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.in_trial],
      gelatoPlusPlanServiceId,
    );

    const isGelatoPlusTrialScheduledToCancel =
      (Boolean(gelatoPlusSubscriptionsByStatus?.length) &&
        gelatoPlusSubscriptionsByStatus?.[0]?.trialEndAction === TrialEndAction.cancel_subscription) ||
      Boolean(gelatoPlusSubscriptionsByStatus?.[0]?.cancelledAt);

    return Boolean(isGelatoPlusTrialScheduledToCancel);
  },
);

export const getIsGelatoPlusGoldTrialScheduledToCancel = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (activeServicePlans: ServicePlanResponse[], activeSubscriptions: SubscriptionResponse[], isLoaded) => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusGoldPlanServiceId = findGelatoPlusGoldServicePlanId(activeServicePlans);
    const gelatoPlusGoldSubscriptionsByStatus = getSubscriptionsByStatuses(
      activeSubscriptions,
      [SubscriptionStatus.in_trial],
      gelatoPlusGoldPlanServiceId,
    );

    const isGelatoPlusGoldTrialScheduledToCancel =
      (Boolean(gelatoPlusGoldSubscriptionsByStatus.length) &&
        gelatoPlusGoldSubscriptionsByStatus?.[0]?.trialEndAction === TrialEndAction.cancel_subscription) ||
      Boolean(gelatoPlusGoldSubscriptionsByStatus?.[0]?.cancelledAt);

    return Boolean(isGelatoPlusGoldTrialScheduledToCancel);
  },
);

export const getSubscriptionByStatusProp = createSelector(
  getActiveServicePlans,
  getActiveSubscriptions,
  getIsActiveSubscriptionsLoaded,
  (
    activeServicePlans: ServicePlanResponse[],
    activeSubscriptions: SubscriptionResponse[],
    isLoaded: boolean,
    prop: { status: SubscriptionStatus; subscriptionType: SubscriptionPlanType },
  ): SubscriptionResponse => {
    if (!isLoaded || !activeServicePlans) {
      return null;
    }

    const gelatoPlusPlanServiceId = findGelatoPlusServicePlanId(activeServicePlans);
    const gelatoPlusGoldPlanServiceId = findGelatoPlusGoldServicePlanId(activeServicePlans);
    const actualServicePlan =
      prop.subscriptionType === SubscriptionPlanType.PLUS ? gelatoPlusPlanServiceId : gelatoPlusGoldPlanServiceId;

    if (!actualServicePlan) {
      return null;
    }

    const gelatoPlusSubscriptionsByStatus = getSubscriptionsByStatuses(
      activeSubscriptions,
      [prop.status],
      gelatoPlusPlanServiceId,
    );
    const gelatoPlusGoldSubscriptionsByStatus = getSubscriptionsByStatuses(
      activeSubscriptions,
      [prop.status],
      gelatoPlusGoldPlanServiceId,
    );

    return prop.subscriptionType === SubscriptionPlanType.PLUS
      ? gelatoPlusSubscriptionsByStatus[0] || null
      : gelatoPlusGoldSubscriptionsByStatus[0] || null;
  },
);

function calculateFreeShippingDaysLeft(subscriptionStartDate: string) {
  const MINUTES_IN_DAY = 1440;
  const daysLeft =
    new Date(subscriptionStartDate) > DATE_WHEN_ONE_MONTH_FREE_SHIPPING_APPLIED
      ? Math.ceil(differenceInMinutes(addDays(new Date(subscriptionStartDate), 30), new Date()) / MINUTES_IN_DAY)
      : Math.ceil(differenceInMinutes(addMonths(new Date(subscriptionStartDate), 3), new Date()) / MINUTES_IN_DAY);

  return daysLeft;
}
