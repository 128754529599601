<div class="ui segment" [class.loading]="isLoading">
  <div class="empty-state-content">
    <div class="empty-state-description">
      <p>
        {{ descriptionText }}
      </p>
      <a
        *ngIf="canCreate && creationRouterLink"
        class="ui primary button"
        [class.disabled]="isLoading"
        [routerLink]="creationRouterLink"
      >
        {{ creationButtonText }}
      </a>
      <ng-content></ng-content>
    </div>
  </div>
</div>
