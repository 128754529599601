import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '../app.state';
import { GELATO_ADMIN_CLIENT_UID } from '../lib/constants';
import { User } from '../shared/lib/user';
import { Client } from '@gelato-api-ui/core/clients/client';
import { ClientStatus } from '@gelato-api-ui/core/clients/client-status.enum';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import * as actions from './auth.actions';
import { getCompanyDetails, getCurrency, isJapanCountry } from './company-details.reducer';
import { getDefaultClientIdFromKeycloakClientIds } from './helpers/getDefaultClientIdFromKeycloakClientIds';

export const getState = (state: AppState): State => state.auth;

export const getUser = createSelector(getState, (state: State): User => state.user);

export const getUserName = createSelector(getUser, isJapanCountry, (user: User, isJapanCountry: boolean): string => {
  const fullName = isJapanCountry ? `${user.lastName} ${user.firstName}` : `${user.firstName} ${user.lastName}`;
  return user ? R.trim(fullName) : '';
});

export const getUserId = createSelector(getUser, (user: User): string => (user ? user.userId : ''));

export const getUserEmail = createSelector(getUser, (user: User): string => (user ? user.email : ''));

export const getUserRoles = createSelector(getState, (state: State): string[] => state.userRoles);

export const getClientIds = createSelector(getState, (state: State): string[] => state.clientIds);

export const getDefaultClientId = createSelector(getClientIds, (clientIds: string[]): string =>
  getDefaultClientIdFromKeycloakClientIds(clientIds),
);

export const isGelatoUser = createSelector(
  getDefaultClientId,
  (defaultClientId: string): boolean => defaultClientId === GELATO_ADMIN_CLIENT_UID,
);

export const getDefaultCustomerRefId = createSelector(
  isGelatoUser,
  getUserEmail,
  (isGelato: boolean, email: string): string => (isGelato ? null : email),
);

export const isMultiClientUser = createSelector(
  getClientIds,
  isGelatoUser,
  (clientIds: string[], gelatoAdminUser: boolean): boolean =>
    (Boolean(clientIds) && clientIds.length > 1) || gelatoAdminUser,
);

export const isSingleClientUser = createSelector(
  isMultiClientUser,
  (multiClientUser: boolean): boolean => !multiClientUser,
);

export const getSelectedClientId = createSelector(getState, (state: State): string => state.selectedClientId);

export const getSelectedClientIdIfGelatoUser = createSelector(
  isGelatoUser,
  getSelectedClientId,
  (gelatoAdminUser, clientId) => (gelatoAdminUser ? clientId : null),
);

export const getSelectedClientFromCompanyDetails = createSelector(
  getSelectedClientId,
  getDefaultClientId,
  getCompanyDetails,
  getCurrency,
  (selectedClientId: string, defaultClientId: string, companyDetails: CompanyDetails, currency: string): Client => {
    if (!selectedClientId || selectedClientId !== defaultClientId) {
      return null;
    }

    const preferredCurrency = currency || (companyDetails ? companyDetails.currency : null) || '';

    return new Client(
      selectedClientId,
      '',
      companyDetails ? companyDetails.companyName : '',
      ClientStatus.ACTIVE,
      null,
      preferredCurrency,
      companyDetails ? companyDetails.billingType : null,
      companyDetails ? companyDetails.shippingPriceType : null,
      null,
    );
  },
);

export const getSelectedClient = createSelector(
  getState,
  getSelectedClientFromCompanyDetails,
  (state: State, selectedClientFromCompanyDetails: Client): Client => {
    return state.selectedClient ? state.selectedClient : selectedClientFromCompanyDetails;
  },
);

export const getSelectedClientPreferredCurrency = createSelector(getSelectedClient, (selectedClient: Client): string =>
  selectedClient ? selectedClient.preferredCurrency : null,
);

export const getSelectedClientCountry = createSelector(getCompanyDetails, (companyDetails: CompanyDetails): string =>
  companyDetails ? companyDetails.countryIsoCode : null,
);

export const getSelectedClientCreatedAt = createSelector(getCompanyDetails, (companyDetails: CompanyDetails): number =>
  companyDetails ? companyDetails.createdAt : null,
);

export const getSelectedDashboardCurrency = createSelector(
  getCompanyDetails,
  getCurrency,
  (companyDetails: CompanyDetails, currency: string): string => {
    if (!companyDetails) {
      return null;
    }

    return companyDetails.currency ? companyDetails.currency : currency;
  },
);

export interface State {
  user: User;
  userRoles: string[];
  clientIds: string[];
  selectedClientId: string;
  selectedClient: Client;
}

export const initialState: State = {
  user: null,
  userRoles: null,
  clientIds: null,
  selectedClientId: null,
  selectedClient: null,
};

export function reducer(state = initialState, action: actions.AuthActions): State {
  switch (action.type) {
    case actions.AuthActionTypes.ResetState:
      return {
        ...initialState,
      };
    case actions.AuthActionTypes.SetState:
      return {
        ...state,
        ...action.payload,
      };
    case actions.AuthActionTypes.SetSelectedClientId:
      return {
        ...state,
        selectedClientId: action.payload,
      };
    case actions.AuthActionTypes.SetSelectedClient:
      return {
        ...state,
        selectedClient: action.payload,
      };
    default:
      return state;
  }
}
