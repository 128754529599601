import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SanityProductBadge } from '@gelato-api-ui/core/sanity/sanity-product-badge';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Component({
  selector: 'lib-mounted-framed-poster-available-badge',
  templateUrl: './mounted-framed-poster-available-badge.component.html',
  styleUrls: ['./mounted-framed-poster-available-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MountedFramedPosterAvailableBadgeComponent {
  productBadge$ = this.translateService.get('txt_mounted_framed_poster_available').pipe(
    map(
      (name: string): SanityProductBadge => ({
        name,
        background: '#5991ff',
        color: '#fff',
      }),
    ),
  );

  constructor(private readonly translateService: TranslateService) {}
}
