import { ConnectedPosition } from '@angular/cdk/overlay';

export enum TooltipPositions {
  top,
  topCenter,
  right,
  rightCenter,
  bottom,
  bottomCenter,
  left,
  leftCenter,
}

export const TooltipPositionsClass = {
  [TooltipPositions.top]: 'top',
  [TooltipPositions.topCenter]: 'top center',
  [TooltipPositions.right]: 'right',
  [TooltipPositions.rightCenter]: 'right center',
  [TooltipPositions.bottom]: 'bottom',
  [TooltipPositions.bottomCenter]: 'bottom center',
  [TooltipPositions.left]: 'left',
  [TooltipPositions.leftCenter]: 'left center',
};

export const tooltipPositions: { [k: string]: ConnectedPosition[] } = {
  [TooltipPositions.top]: [
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      offsetX: -35,
      offsetY: -15,
    },
  ],
  [TooltipPositions.topCenter]: [
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom',
      offsetX: 0,
      offsetY: -15,
    },
  ],
  [TooltipPositions.right]: [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'top',
      offsetX: 15,
      offsetY: -35,
    },
  ],

  [TooltipPositions.rightCenter]: [
    {
      originX: 'end',
      originY: 'center',
      overlayX: 'start',
      overlayY: 'center',
      offsetX: 15,
      offsetY: 0,
    },
  ],
  [TooltipPositions.bottom]: [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: 15,
      offsetX: -35,
    },
  ],

  [TooltipPositions.bottomCenter]: [
    {
      originX: 'center',
      originY: 'bottom',
      overlayX: 'center',
      overlayY: 'top',
      offsetX: 0,
      offsetY: 15,
    },
  ],
  [TooltipPositions.left]: [
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'top',
      offsetX: -15,
      offsetY: -35,
    },
  ],
  [TooltipPositions.leftCenter]: [
    {
      originX: 'start',
      originY: 'center',
      overlayX: 'end',
      overlayY: 'center',
      offsetX: -15,
      offsetY: 0,
    },
  ],
};
