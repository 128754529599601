<ng-select
  #select
  [ngModel]="value || undefined"
  (ngModelChange)="onSelect($event)"
  [items]="options$ | async"
  [bindValue]="valueField"
  [bindLabel]="'name'"
  [placeholder]="placeholder"
  [clearable]="false"
  [readonly]="isDisabled"
  [searchable]="isSearchable"
  [virtualScroll]="true"
></ng-select>
