import { Injectable } from '@angular/core';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import { AssetProviderUid } from '@gelato-api-ui/core/designs/asset-provider-uid.enum';
import { freeAssetProviderUidsWithActiveSubscription } from '@gelato-api-ui/core/designs/free-asset-provider-uids-for-subscribers';
import { PaidAssetProviderUids } from '@gelato-api-ui/core/designs/paid-asset-provider-uids';
import { UploadedImageData } from '@gelato-api-ui/core/designs/uploaded-image-data';
import { getUploadedImageColors } from '@gelato-api-ui/core/designs/helpers/getUploadedImageColors';
import { getAssetFileUrl } from '@gelato-api-ui/core/designs/helpers/getAssetFileUrl';
import { AssetFileType } from '@gelato-api-ui/core/designs/asset-file-type.enum';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import * as R from 'ramda';
import { AssetProviderGroupId } from '@gelato-api-ui/core/designs/asset-provider-group-id.enum';
import { RemoveBackgroundItemsMetadata } from '@gelato-api-ui/core/metadata/metadata-item';

@Injectable({
  providedIn: 'root',
})
export class UploadedImageDataService {
  private getAssetLabel = (asset: Asset, isGelatoPlusGoldActive: boolean): string => {
    const isGettyEssentialAsset =
      asset.assetProviderUid === AssetProviderUid.GETTY_IMAGES &&
      asset.providerAssetGroupUid === AssetProviderGroupId.GETTY_IMAGES_ESSENTIAL;

    if (isGelatoPlusGoldActive && isGettyEssentialAsset) {
      return null;
    }

    const hideLabel =
      isGelatoPlusGoldActive && freeAssetProviderUidsWithActiveSubscription.includes(asset.assetProviderUid);
    if (hideLabel) {
      return null;
    }

    return PaidAssetProviderUids.includes(asset.assetProviderUid) ? '$' : null;
  };

  getUploadedImageDataFromAsset = (id: string, asset: Asset, isGelatoPlusGoldActive = null): UploadedImageData => {
    const isAssetHidden = asset?.metadata?.find(
      meta => meta.key === RemoveBackgroundItemsMetadata.BACKGROUND_REMOVAL_HAS_BACKGROUND && !meta.value,
    );

    return {
      file: {
        id,
        width: asset.width,
        height: asset.height,
        label: this.getAssetLabel(asset, isGelatoPlusGoldActive),
        source: asset.assetProviderUid,
        colors: getUploadedImageColors(asset),
      },
      url: getAssetFileUrl(asset, AssetFileType.PREVIEW_DEFAULT),
      thumbnailUrl: getAssetFileUrl(asset, AssetFileType.PREVIEW_THUMBNAIL),
      mimeType: asset.mimeType,
      assetId: asset.id,
      hidden: Boolean(isAssetHidden),
    };
  };

  mapAssetsToUploadedImagesData = (assets: Asset[], gelatoPlusOrGoldIsActive = null): UploadedImageData[] => {
    if (!assets || !assets.length) {
      return [];
    }

    return assets.map(
      (loopAsset: Asset): UploadedImageData =>
        this.getUploadedImageDataFromAsset(loopAsset.id, loopAsset, gelatoPlusOrGoldIsActive),
    );
  };

  getUploadedImagesDataFromAssetsCollection = (
    assetsCollection: AssetsCollection,
    gelatoPlusOrGoldIsActive: boolean,
  ): UploadedImageData[] => {
    return R.map((assetId: string): UploadedImageData => {
      const loopAsset: Asset = assetsCollection[assetId];

      return this.getUploadedImageDataFromAsset(loopAsset.id, loopAsset, gelatoPlusOrGoldIsActive);
    }, R.keys(assetsCollection));
  };
}
