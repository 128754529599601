import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { HeaderCreateMenuFacade } from '@api-ui-app/src/app/header-create-menu/header-create-menu.facade';

@Component({
  selector: 'gd-header-create-menu',
  templateUrl: './header-create-menu.component.html',
  styleUrls: ['./header-create-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCreateMenuComponent implements OnInit {
  @Input() isMobile: boolean;

  isGelatoUser$ = this.facade.isGelatoUser$;

  constructor(private readonly facade: HeaderCreateMenuFacade) {}

  ngOnInit() {
    this.facade.loadStoresList();
  }

  placeOrder() {
    this.facade.placeAnOrder();
  }

  addNewProduct() {
    this.facade.addNewProduct();
  }
}
