import { Injectable } from '@angular/core';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { EProductPreviewFileTypesListItem } from '@api-ui-app/src/app/e-commerce-stores/types/e-product-preview-file-types-list-item';

@Injectable({ providedIn: 'root' })
export class ECommercePreviewFileTypeService {
  private readonly previewFileTypes = [PreviewFileType.JPEG, PreviewFileType.PNG];

  getPreviewFileTypesList(storeType: EStoreType): EProductPreviewFileTypesListItem[] {
    return this.previewFileTypes.map((loopPreviewFileType: PreviewFileType): EProductPreviewFileTypesListItem => {
      const enabled = this.isPreviewFileTypeSupported(storeType, loopPreviewFileType);
      const textKey =
        !enabled && storeType === EStoreType.ETSY ? 'txt_etsy_doesnt_support_transparent_images' : undefined;

      return {
        type: loopPreviewFileType,
        enabled,
        textKey,
      };
    });
  }

  getDefaultPreviewFileType(storeType: EStoreType): PreviewFileType {
    return this.getSupportedPreviewFileTypes(storeType)[0];
  }

  validatePreviewFileType(storeType: EStoreType, previewFileType: PreviewFileType): PreviewFileType {
    return this.isPreviewFileTypeSupported(storeType, previewFileType)
      ? previewFileType
      : this.getDefaultPreviewFileType(storeType);
  }

  private getSupportedPreviewFileTypes(storeType: EStoreType): PreviewFileType[] {
    return this.previewFileTypes.filter(
      (loopPreviewFileType: PreviewFileType): boolean =>
        !(storeType === EStoreType.ETSY && loopPreviewFileType === PreviewFileType.PNG),
    );
  }

  private isPreviewFileTypeSupported(storeType: EStoreType, previewFileType: PreviewFileType): boolean {
    return this.getSupportedPreviewFileTypes(storeType).includes(previewFileType);
  }
}
