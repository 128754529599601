<div
  [class]="'plus-wrapper ' + colorType"
  gcTooltip
  [gcTooltipText]="tooltipText | translate"
  [gcTooltipTheme]="'dark'"
  [gcTooltipShowArrow]="true"
  [gcTooltipPosition]="gcTooltipPosition.bottomCenter"
  (click)="onLabelClick()"
>
  <img [src]="imgName | assetsPath | async" width="20" />
</div>
