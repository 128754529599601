import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BillingType } from '@gelato-api-ui/core/clients/billing-type.enum';

@Pipe({
  name: 'billingType',
})
export class BillingTypePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(billingType: BillingType): any {
    return this.translateService.get(`txt_billing_type_${billingType}`);
  }
}
