import { Injectable } from '@angular/core';
import { filter, first, tap, withLatestFrom } from 'rxjs/operators';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { EditorOutboundEventType } from '@gelato-api-ui/core/design-editor/editor-outbound-event-type.enum';
import {
  PluginDataState,
  SetPluginsEventDataItem,
} from '@gelato-api-ui/core/design-editor/set-plugins-event-data-item';
import { EditorInboundEventType } from '@gelato-api-ui/core/design-editor/editor-inbound-event-type.enum';
import {
  DesignEditorPlugin,
  DesignEditorPluginNames,
} from '@gelato-api-ui/core/design-editor/design-editor-plugin.enum';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { GelatoPlusModalService } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/gelato-plus-modal.service';
import { GelatoPlusFeatures } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-features';
import { GelatoPlusPopupLocations } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-popup-locations';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import {
  getAppCrownPremiumLevel,
  getIsFeatureUsed,
  setFeatureUsed,
} from '../lib/helpers/visited-apps-local-storage-manager';
import { FileManagerEditorOutboundEventTypeEnum } from '@gelato-api-ui/file-manager/src/lib/core/file-manager-editor-outbound-event-type.enum';
import { mapAppNameToIconCrownedFeatureName } from '@gelato-api-ui/core/design-editor/helpers/icon-crowned-feature-mapper';
import { TranslateService } from '@ngx-translate/core';
import { NavigateToListService } from '../../shared/services/navigate-to-list.service';
import { EditorPluginService } from '@product-catalogue/src/lib/product-catalogue/services/editor-plugin.service';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { GelatoSubscriptionModalType } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-subscription-modal';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { EditorPostMessageInterface } from '@product-catalogue/src/lib/product-catalogue/constants/design-editor.constant';
import { BackgroundRemoveService } from '@api-ui-app/src/app/subscriptions/services/background-remove.service';
import { DestygoWebChatService } from '@api-ui-app/src/app/app-store/services/destygo-web-chat.service';
import { HelpCenterArticles } from '@gelato-api-ui/core/help-center/help-center-articles';

@Injectable()
export class SubscriptionsToEditorCommunicationService {
  postMessage$ = new Subject<EditorPostMessageInterface>();
  subscriptions: Subscription[] = [];
  usedApps = new Set<DesignEditorPlugin>();
  productTypeUid: ProductTypeUid;
  isClientCreatedBeforeGelatoPlusStart$ = this.subscriptionsFacade.isClientCreatedBeforeGelatoPlusStart$;
  assetsCollection$ = this.subscriptionsFacade.assetsCollection$;

  constructor(
    private readonly store: Store<AppState>,
    private readonly gelatoPlusModalService: GelatoPlusModalService,
    private readonly subscriptionsFacade: SubscriptionsFacade,
    private readonly translateService: TranslateService,
    private readonly navigateToListService: NavigateToListService,
    private readonly editorPluginService: EditorPluginService,
    private readonly removeBackgroundService: BackgroundRemoveService,
    private readonly destygoWebChatService: DestygoWebChatService,
  ) {}

  init(postMessage$: Observable<EditorPostMessageInterface>) {
    const subscription = postMessage$
      .pipe(
        tap(message => {
          switch (message.type) {
            case EditorOutboundEventType.disabledPluginClick:
              if (
                [
                  DesignEditorPlugin.APP_GRAPHICS,
                  DesignEditorPlugin.APP_TEXT_EFFECTS,
                  DesignEditorPlugin.APP_GETTY_IMAGES,
                  DesignEditorPlugin.APP_IMAGE_FILTERS,
                  DesignEditorPlugin.APP_SHUTTERSTOCK,
                  DesignEditorPlugin.APP_SHUTTERSTOCK_FULL,
                  DesignEditorPlugin.APP_SHUTTERSTOCK_ESSENTIAL,
                  DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION,
                  DesignEditorPlugin.APP_FONTS,
                  DesignEditorPlugin.APP_BACKGROUND_REMOVAL,
                ].includes(message.data.name)
              ) {
                this.trackDisabledFeatureClick(message.data.name);
                this.gelatoPlusModalService.open(
                  message.data.name as GelatoPlusFeatures,
                  GelatoPlusPopupLocations.editor,
                  GelatoSubscriptionModalType.GELATO_PLUS,
                );
              }
              break;
            case EditorOutboundEventType.pluginOpened:
              this.updatePremiumLevel(message.data.pluginName);
              this.trackFeatureClick(message.data.pluginName);
              break;
            case EditorOutboundEventType.premiumFontSelected:
              this.updatePremiumLevel(DesignEditorPlugin.APP_FONTS);
              this.trackEvents();
              this.trackFeatureClick(DesignEditorPlugin.APP_FONTS);
              break;
            case FileManagerEditorOutboundEventTypeEnum.openLibrary: // Open Collections
              this.updatePremiumLevel(DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION);
              this.trackFeatureClick(DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION);
              break;
            case EditorOutboundEventType.subscribeGelatoPlusButtonClick:
              this.openSubscribeGelatoPlusModal(message.data.name);
              break;
            case EditorOutboundEventType.gelatoPlusClick:
              this.onGelatoPlusClick();
              break;
            case EditorOutboundEventType.removeBackground:
              this.removeBackgroundService.removeBackground(message);
              break;
            case EditorOutboundEventType.openHelperCenter:
              window.open(HelpCenterArticles.helpCenter, '_blank');
              break;
            case EditorOutboundEventType.openChatBot:
              this.destygoWebChatService.toggleChat();
              break;
            default:
              break;
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(subscription);
  }

  unsubscribe() {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptionsFacade.setPluginsIsUsedInTheEditor(false, null);
  }

  trackEvents() {
    this.logGelatoPlusPrompterShown();
    this.trackGelatoPlusFunnelStart();
  }

  updatePremiumLevel(appName: DesignEditorPlugin) {
    const iconCrownedFeatureName = mapAppNameToIconCrownedFeatureName(appName);

    if (!getIsFeatureUsed(iconCrownedFeatureName)) {
      setFeatureUsed(iconCrownedFeatureName);

      const updatedPluginsData: SetPluginsEventDataItem[] = [
        {
          state: PluginDataState.enabled,
          ...this.editorPluginService.getPluginByAppName(appName, this.productTypeUid),
          name: appName,
          displayName: DesignEditorPluginNames[appName]
            ? this.translateService.instant(DesignEditorPluginNames[appName])
            : null,
          extra: {
            premiumLevel: getAppCrownPremiumLevel(iconCrownedFeatureName, true),
            gelatoPlusPrompt: null,
          },
        },
      ];
      this.updatePlugins(updatedPluginsData);
    }
  }

  logGelatoPlusPrompterShown() {
    this.subscriptionsFacade.gelatoPlusIsActive$
      .pipe(
        filter(isGelatoPlusActive => !isGelatoPlusActive),
        withLatestFrom(this.subscriptionsFacade.gelatoPlusSubscriptionsStatuses$),
        first(),
        tap(([, gelatoPlusSubscriptionsStatuses]) => {
          this.subscriptionsFacade.logGelatoPlusPromptShown(
            GelatoPlusPopupLocations.editor,
            gelatoPlusSubscriptionsStatuses,
          );
        }),
      )
      .subscribe();
  }

  openSubscribeGelatoPlusModal(featureName) {
    this.gelatoPlusModalService.open(
      featureName as GelatoPlusFeatures,
      GelatoPlusPopupLocations.editor,
      GelatoSubscriptionModalType.GELATO_PLUS,
    );
  }

  onGelatoPlusClick() {
    this.navigateToListService.onGelatoPlusLabelClick();
    logEvent('gelatoPlusLabelClick', { labelLocation: GelatoPlusPopupLocations.editor });
  }

  setPluginsState(data: DesignStructure) {
    if (!data) {
      return;
    }

    const isShutterstockIsUsed = this.isShuttestockUsedInDataStructure(data);
    const isAddonUsed = this.isGraphicsUsedInDataStructure(data);
    const isFiltersApplied = this.isFiltersAppliedInDataStructure(data);
    const isCollectionsUsed = this.isCollectionsUsedInDataStructure(data);
    const isPremiumFontsUsed = this.isPremiumFontsUsed(data);
    const isBGRemovalToolUsed = this.isBGRemovalToolUsed(data);

    this.setUsedApps(
      data.plugins,
      isAddonUsed,
      isFiltersApplied,
      isCollectionsUsed,
      isPremiumFontsUsed,
      isBGRemovalToolUsed,
    );

    this.subscriptionsFacade.gelatoPlusOrGoldIsActive$
      .pipe(
        first(),
        withLatestFrom(this.isClientCreatedBeforeGelatoPlusStart$),
        tap(([isPlusActive, isLegacyUser]) => {
          if (isPlusActive) {
            this.subscriptionsFacade.setPluginsIsUsedInTheEditor(false, null);
          } else {
            // Legacy clients has Shutterstock enabled w/o Subscription
            if (isLegacyUser && isShutterstockIsUsed && !isAddonUsed && !isFiltersApplied && !isCollectionsUsed) {
              this.subscriptionsFacade.setPluginsIsUsedInTheEditor(false, null);
            } else {
              this.subscriptionsFacade.setPluginsIsUsedInTheEditor(
                Boolean(isShutterstockIsUsed || isAddonUsed || isFiltersApplied || isCollectionsUsed),
                [...this.usedApps],
              );
            }
          }
        }),
      )
      .subscribe();
  }

  public updatePlugins(data: SetPluginsEventDataItem[]) {
    this.postMessage$.next({
      data,
      type: EditorInboundEventType.setPlugins,
    });
  }

  isShuttestockUsedInDataStructure(data: DesignStructure): boolean {
    const { plugins } = data;
    return plugins?.some(pluginName =>
      [
        DesignEditorPlugin.APP_SHUTTERSTOCK,
        DesignEditorPlugin.APP_SHUTTERSTOCK_FULL,
        DesignEditorPlugin.APP_SHUTTERSTOCK_ESSENTIAL,
      ].includes(pluginName as DesignEditorPlugin),
    );
  }

  isCollectionsUsedInDataStructure(data: DesignStructure): boolean {
    const collectionAppName = DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION;

    return data?.spreads?.some(spread =>
      spread?.pages?.some(page =>
        page?.groups?.media?.some(media => media.plugins && media.plugins[collectionAppName]),
      ),
    );
  }

  isPremiumFontsUsed(data: DesignStructure): boolean {
    const collectionAppName = DesignEditorPlugin.APP_FONTS;

    return data?.spreads?.some(spread =>
      spread?.pages?.some(page =>
        page?.groups?.media?.some(media => media.plugins && media.plugins[collectionAppName]),
      ),
    );
  }

  isBGRemovalToolUsed(data: DesignStructure): boolean {
    const collectionAppName = DesignEditorPlugin.APP_BACKGROUND_REMOVAL;

    return data?.spreads?.some(spread =>
      spread?.pages?.some(page =>
        page?.groups?.media?.some(media => media.plugins && media.plugins[collectionAppName]),
      ),
    );
  }

  isGraphicsUsedInDataStructure(data: DesignStructure): boolean {
    const collectionAppName = DesignEditorPlugin.APP_GRAPHICS;

    return data?.spreads?.some(spread =>
      spread?.pages?.some(page =>
        page?.groups?.media?.some(media => media.plugins && media.plugins[collectionAppName]),
      ),
    );
  }

  isFiltersAppliedInDataStructure(data: DesignStructure): boolean {
    const freeAdjustments = ['brightness', 'contrast'];
    let filters = [];
    let adjustments = [];

    data?.spreads.forEach(spread =>
      spread?.pages?.forEach(page =>
        page?.groups?.media?.forEach(media => {
          if (media.adjustments) {
            adjustments.push(...media.adjustments);
          }
          if (media.filters) {
            filters.push(...media.filters);
          }
        }),
      ),
    );

    adjustments = adjustments.filter(
      adjustment => !freeAdjustments.includes(adjustment.name) && adjustment.value !== 0,
    );
    filters = filters.filter(_filter => _filter.name !== 'none');

    return Boolean(filters.length || adjustments.length);
  }

  setUsedApps(
    pluginsFromDesignStructure: string[],
    isAddonUsed: boolean,
    isFiltersApplied: boolean,
    isCollectionsUsed: boolean,
    isPremiumFontsUsed: boolean,
    isBGRemovalToolUsed: boolean,
  ) {
    const isShutterstockEssentialUsed = pluginsFromDesignStructure?.includes(
      DesignEditorPlugin.APP_SHUTTERSTOCK_ESSENTIAL,
    );
    const isShutterstockFullUsed = pluginsFromDesignStructure?.includes(DesignEditorPlugin.APP_SHUTTERSTOCK_FULL);
    if (isShutterstockEssentialUsed) {
      this.usedApps.add(DesignEditorPlugin.APP_SHUTTERSTOCK_ESSENTIAL);
    } else {
      this.usedApps.delete(DesignEditorPlugin.APP_SHUTTERSTOCK_ESSENTIAL);
    }

    if (isShutterstockFullUsed) {
      this.usedApps.add(DesignEditorPlugin.APP_SHUTTERSTOCK_FULL);
    } else {
      this.usedApps.delete(DesignEditorPlugin.APP_SHUTTERSTOCK_FULL);
    }

    if (isAddonUsed) {
      this.usedApps.add(DesignEditorPlugin.APP_GRAPHICS);
    } else {
      this.usedApps.delete(DesignEditorPlugin.APP_GRAPHICS);
    }

    if (isFiltersApplied) {
      this.usedApps.add(DesignEditorPlugin.APP_IMAGE_FILTERS);
    } else {
      this.usedApps.delete(DesignEditorPlugin.APP_IMAGE_FILTERS);
    }

    if (isCollectionsUsed) {
      this.usedApps.add(DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION);
    } else {
      this.usedApps.delete(DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION);
    }

    if (isPremiumFontsUsed) {
      this.usedApps.add(DesignEditorPlugin.APP_FONTS);
    } else {
      this.usedApps.delete(DesignEditorPlugin.APP_FONTS);
    }

    if (isBGRemovalToolUsed) {
      this.usedApps.add(DesignEditorPlugin.APP_BACKGROUND_REMOVAL);
    } else {
      this.usedApps.delete(DesignEditorPlugin.APP_BACKGROUND_REMOVAL);
    }
  }

  validatePluginsInDesignStructure(data: DesignStructure): DesignStructure {
    if (data?.plugins?.includes(DesignEditorPlugin.APP_IMAGE_FILTERS)) {
      const isFiltersUsed = this.isFiltersAppliedInDataStructure(data);
      if (!isFiltersUsed) {
        data.plugins = data.plugins.filter(plugin => plugin !== DesignEditorPlugin.APP_IMAGE_FILTERS);
      }
    }

    return data;
  }

  private trackGelatoPlusFunnelStart() {
    combineLatest([this.subscriptionsFacade.gelatoPlusIsActive$, this.subscriptionsFacade.pricePlansLoading$])
      .pipe(
        filter(([isGelatoPlusActive, isPricePlanLoading]) => !isPricePlanLoading && !isGelatoPlusActive),
        first(),
        tap(() => {
          this.subscriptionsFacade.logStartGelatoPlusFunnel(GelatoPlusPopupLocations.editor);
        }),
      )
      .subscribe();
  }

  private trackDisabledFeatureClick(appName: string): void {
    logEvent('editorDisabledAppClick', {
      appName,
    });
  }

  private trackFeatureClick(appName: string): void {
    logEvent('Editor App Opened', {
      appName,
    });
  }
}
