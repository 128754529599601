import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './sanity.reducer';
import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import {
  SanityHomePageContent,
  SanityHomePageItem,
  SanityHomePageVideoItem,
} from '@gelato-api-ui/core/sanity/sanity-home-page-content';
import { SanityTipsAndGuides } from '@gelato-api-ui/core/sanity/sanity-tips-and-guides';
import { SanityTrendingProduct } from '@gelato-api-ui/core/sanity/sanity-trending-product';
import { sanityFeatureName } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.feature';
import { SanityHelloBar } from '@gelato-api-ui/core/sanity/types/sanity-hello-bar';

export const getSanityContentState = createFeatureSelector<State>(sanityFeatureName);

export const getSanityContent = createSelector(
  getSanityContentState,
  (state: State): SanityProductCategory[] => state.payload,
);

export const getSanityProducts = createSelector(getSanityContentState, (state: State) => state.products);

export const getSanityRelatedProducts = createSelector(getSanityContentState, (state: State) => state.relatedProducts);

export const getSanityParentCategoryByName = createSelector(
  getSanityContent,
  (content: SanityProductCategory[], props: { name: string }) => {
    const category = content.find(cat => cat.productModel === props.name);
    const parent = content.find(cat => {
      if (cat.children && cat.children.length) {
        return cat.children.includes(category._id);
      }
      return false;
    });
    return parent || null;
  },
);

export const getIsSanityRelatedProductsLoading = createSelector(
  getSanityContentState,
  (state: State) => state.isRelatedProductsLoading,
);

export const getHomePageContent = createSelector(
  getSanityContentState,
  (state: State): SanityHomePageContent => state.homePageContent,
);

export const getProductCommunicationBanners = createSelector(
  getSanityContentState,
  (state: State) => state.productCommunicationBanners,
);

export const getTipsAndGuides = createSelector(
  getHomePageContent,
  (homePageContent: SanityHomePageContent): SanityTipsAndGuides =>
    homePageContent && homePageContent.tipsAndGuides ? homePageContent.tipsAndGuides.content : null,
);

export const getTrendingProducts = createSelector(
  getHomePageContent,
  (homePageContent: SanityHomePageContent): SanityTrendingProduct[] =>
    homePageContent && homePageContent.trendingProducts ? homePageContent.trendingProducts.content.products : null,
);

export const getPersonalisedTrendingProducts = createSelector(
  getHomePageContent,
  (homePageContent: SanityHomePageContent): SanityTrendingProduct[] =>
    homePageContent && homePageContent.trendingProducts
      ? homePageContent.trendingProducts.content.personalisedProducts
      : null,
);

export const getVideosWidget = createSelector(
  getHomePageContent,
  (homePageContent: SanityHomePageContent): SanityHomePageVideoItem =>
    homePageContent && homePageContent.video ? homePageContent.video : null,
);

export const getHomePageWidgets = createSelector(
  getHomePageContent,
  (homePageContent: SanityHomePageContent): SanityHomePageItem[] =>
    homePageContent ? Object.values(homePageContent) : [],
);

export const getHelloBar = createSelector(getSanityContentState, (state): SanityHelloBar => state.helloBar);
export const isHelloBarVisible = createSelector(getSanityContentState, (state): boolean => state.isHelloBarVisible);
