import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { first, map, take, tap, withLatestFrom } from 'rxjs/operators';
import { loadScript } from '@gelato-api-ui/core/dom/helpers/loadScript';
import { environment } from '@api-ui-app/src/environments/environment';
import { UserCurrencyService } from '@api-ui-app/src/app/shared/services/user-currency.service';
import { ChargeBeeApiService } from '@api-ui-app/src/app/subscriptions/services/charge-bee-api.service';
import { SuiModal, SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { ServicePlanPrice, ServiceUniqueName } from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { SubscriptionsToEditorCommunicationService } from '@api-ui-app/src/app/subscriptions/services/subscriptions-to-editor-communication.service';
import {
  DesignEditorPlugin,
  DesignEditorPluginNames,
} from '@gelato-api-ui/core/design-editor/design-editor-plugin.enum';
import { PluginDataState } from '@gelato-api-ui/core/design-editor/set-plugins-event-data-item';
import { interval } from 'rxjs';
import { ChargebeeCheckoutStep } from '@api-ui-app/src/app/subscriptions/types/chargebee-checkout-step';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { InstallingGelatoPlusService } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/installing-gelato-plus-service';
import {
  activateSubscriptionRepeatCount,
  cancelSubscriptionRepeatCount,
  updateSubscriptionRepeatCount,
  updateSubscriptionTime,
} from '@api-ui-app/src/app/subscriptions/constants/update-subscription-time';
import { SubscriptionState } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/installing-gelato-plus-overlay/installing-gelato-plus-overlay.component';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { ShowNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';

declare global {
  interface Window {
    Chargebee: any;
  }
}

export interface ChargeBeeRequest {
  itemPriceId: string;
  trialEnd?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChargeBeeService {
  public cbInstance = null;
  getLoggedInClientId$ = this.authService.getClientId();
  isNonRenevingSubscriptionActive$ = this.subscriptionsFacade.isNonRenewingSubscriptionEnding$;
  getGelatoPlusOrGoldSubscriptionsStatuses$ = this.subscriptionsFacade.getGelatoPlusOrGoldSubscriptionsStatuses;
  userCurrency$ = this.userCurrencyService.get().pipe(map(currency => currency.toUpperCase()));
  subscriptionPageEnabledAB$ = this.featureSwitcherService.isFeatureEnabledForCurrentUser(
    FeatureFlagEnum.subscription_page_ab,
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly authService: AuthService,
    private readonly apiRequest: ApiRequestService,
    private readonly modalService: SuiModalService,
    private readonly userCurrencyService: UserCurrencyService,
    private readonly chargeBeeApiService: ChargeBeeApiService,
    private readonly translate: TranslateService,
    private readonly subscriptionsFacade: SubscriptionsFacade,
    private readonly installingGelatoPlusService: InstallingGelatoPlusService,
    private readonly subscriptionsToEditorCommunicationService: SubscriptionsToEditorCommunicationService,
    private readonly featureSwitcherService: FeatureSwitcherService,
  ) {}

  public loadChargeBeeSdk() {
    if (!window['Chargebee']) {
      loadScript('https://js.chargebee.com/v2/chargebee.js').then(() => {
        this.reInitChargeBee();
      });
    }
  }

  private initChargeBee() {
    if (Boolean(window['Chargebee'])) {
      window.Chargebee.init({
        isItemsModel: true,
        site: environment.chargebeeSiteName,
      });
      this.cbInstance = window.Chargebee.getInstance();
    } else {
      this.loadChargeBeeSdk();
    }
  }

  private initPortalSessions() {
    if (this.cbInstance) {
      this.cbInstance.setPortalSession(() => {
        return this.chargeBeeApiService.chargeBeePortalAuthorize().toPromise();
      });
    } else {
      this.reInitChargeBee();
    }
  }

  private registerChargeBeeEvents() {
    if (Boolean(window['Chargebee'])) {
      window['Chargebee'].registerAgain();
    }
  }

  private reInitChargeBee() {
    this.initChargeBee();
    this.initPortalSessions();
    this.registerChargeBeeEvents();
  }

  openPortal() {
    this.initPortalSessions();

    const cbInstanceCallbacks = {
      loaded: loadedCb => {
        // Optional
        // called when chargebee portal is loaded
      },
      close: closeCb => {
        // Optional
        // called when chargebee portal is closed
        this.subscriptionsFacade.loadActiveSubscriptionsAndServicePlans();
      },
      visit: sectionName => {
        // Optional
        // called whenever the customer navigates across different sections in portal
        logEvent('chargebeePortalVisitSection', {
          sectionName,
        });
      },
      paymentSourceAdd: () => {
        // Optional
        // called whenever a new payment source is added in portal
        logEvent('chargebeePortalPaymentAdded');
      },
      paymentSourceUpdate: paymentSourceUpdateCb => {
        // Optional
        // called whenever a payment source is updated in portal
        logEvent('chargebeePortalPaymentUpdated');
      },
      paymentSourceRemove: paymentSourceRemoveCb => {
        // Optional
        // called whenever a payment source is removed in portal.
        logEvent('chargebeePortalPaymentRemoved');
      },
      subscriptionChanged: subscriptionChangedCb => {
        // Optional
        // called whenever a subscription is changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        logEvent('chargebeePortalSubscriptionChanged');
        this.reloadSubscriptionsWithDelay();
      },
      subscriptionCustomFieldsChanged: subscriptionCustomFieldsChangedCb => {
        // Optional
        // called whenever a subscription custom fields are changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCancelled: subscriptionCancelledCb => {
        // Optional
        // called when a subscription is cancelled
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        this.trackCancelSubscription(subscriptionCancelledCb);
        this.reloadSubscriptionsWithDelay(SubscriptionState.canceling);
      },
      subscriptionPaused: subscriptionPausedCb => {
        // Optional
        // called when a subscription is Paused.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        logEvent('chargebeePortalSubscriptionPaused');
        this.reloadSubscriptionsWithDelay();
      },
      subscriptionResumed: subscriptionResumedCb => {
        // Optional
        // called when a paused subscription is resumed.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        logEvent('chargebeePortalSubscriptionResumed');
        this.reloadSubscriptionsWithDelay();
      },
      scheduledPauseRemoved: scheduledPauseRemovedCb => {
        // Optional
        // called when the schedule to pause a subscription is removed for that subscription.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        logEvent('chargebeePortalScheduledPauseRemoved');
        this.reloadSubscriptionsWithDelay();
      },
      subscriptionReactivated: subscriptionReactivatedCb => {
        // Optional
        // called when a cancelled subscription is reactivated.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
        logEvent('chargebeePortalSubscriptionReactivated');
        this.reloadSubscriptionsWithDelay();
      },
    };
    this.cbInstance.createChargebeePortal().open(cbInstanceCallbacks);
  }

  reloadSubscriptionsWithDelay(subscriptionState?: SubscriptionState) {
    this.subscriptionsFacade.setActivePlansStatus(true);
    this.loadActiveSubscriptions(null, subscriptionState);
  }

  /**
   * Load active subscriptions several times to be sure everything will be updated after cancel | purchasing | activation
   * @public
   */
  loadActiveSubscriptions(servicePlanPrice?: ServicePlanPrice, subscriptionState?: SubscriptionState) {
    this.isNonRenevingSubscriptionActive$
      .pipe(
        first(),
        tap(isNonRenewingSubscriptionActive => {
          const _subscriptionState = isNonRenewingSubscriptionActive
            ? SubscriptionState.prolongation
            : subscriptionState || SubscriptionState.installing;

          this.installingGelatoPlusService.open(_subscriptionState);
          this.loadSubscriptionsAndServicePlansWithInterval(
            servicePlanPrice,
            isNonRenewingSubscriptionActive,
            _subscriptionState,
          );
        }),
      )
      .subscribe();
  }

  loadSubscriptionsAndServicePlansWithInterval(
    servicePlanPrice: ServicePlanPrice,
    isNonRenewingSubscriptionActive: boolean,
    subscriptionState: SubscriptionState,
  ) {
    const repeatCount =
      subscriptionState === SubscriptionState.activation
        ? activateSubscriptionRepeatCount
        : subscriptionState === SubscriptionState.canceling
        ? cancelSubscriptionRepeatCount
        : updateSubscriptionRepeatCount;
    interval(updateSubscriptionTime)
      .pipe(
        take(repeatCount),
        tap(num => {
          this.subscriptionsFacade.loadActiveSubscriptionsAndServicePlans();
          if (num >= repeatCount - 1) {
            // "num" related to number in the "take" operator
            this.subscriptionsFacade.setActivePlansStatus(false);
            this.subscriptionsFacade.fireSuccessfulSubscriptionAction();
            if (servicePlanPrice) {
              if (!isNonRenewingSubscriptionActive) {
                this.updateEditorApps(servicePlanPrice);
              }
            }
          }
        }),
      )
      .subscribe();
  }

  openChargeBeeCheckout(servicePlanPrice: ServicePlanPrice, modalRef: SuiModal<any, void, void>, trialEnd?: number) {
    let tmpHostedPageId = null;
    if (!this.cbInstance) {
      this.reInitChargeBee();
    }

    const chargeBeeRequest: ChargeBeeRequest = {
      itemPriceId: servicePlanPrice.uniqueName,
    };

    if (trialEnd != null) {
      chargeBeeRequest.trialEnd = trialEnd;
    }

    this.cbInstance.logout();

    this.cbInstance.openCheckout({
      hostedPage: () => this.chargeBeeApiService.chargeBeeCheckoutAuthorize(chargeBeeRequest).toPromise(),
      loaded: loadedCb => {},
      success: hostedPageId => {
        tmpHostedPageId = hostedPageId;
      },
      step: checkoutStep => {
        this.trackChargebeeCheckoutSteps(checkoutStep);

        if (checkoutStep === ChargebeeCheckoutStep.THANKYOU_SCREEN) {
          this.subscriptionIsSuccessful(tmpHostedPageId, modalRef, servicePlanPrice);
          this.trackPricePlanPurchase(servicePlanPrice, modalRef);
          this.loadActiveSubscriptions(servicePlanPrice);
        }
      },
      close: closeCb => {
        logEvent('closeChargebeeCheckout');
      },
      error: errorCb => {
        logEvent('errorChargebeeCheckout', {
          errorCb,
        });
        this.store.dispatch(new ShowNotification({ message: errorCb }));
        this.cbInstance.closeAll();
      },
    });
  }

  private subscriptionIsSuccessful(tmpHostedPageId, modalRef: SuiModal<void>, servicePlanPrice: ServicePlanPrice) {
    if (tmpHostedPageId) {
      if (modalRef) {
        modalRef.approve(undefined);
      }
      this.cbInstance.closeAll();
    }
  }

  private updateEditorApps(servicePlanPrice: ServicePlanPrice) {
    if (servicePlanPrice.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM) {
      const appsToEnableWhenSubscribedToPlus = [
        DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION,
        DesignEditorPlugin.APP_GRAPHICS,
        DesignEditorPlugin.APP_IMAGE_FILTERS,
        DesignEditorPlugin.APP_SHUTTERSTOCK,
        DesignEditorPlugin.APP_FONTS,
        DesignEditorPlugin.APP_TEXT_EFFECTS,
        DesignEditorPlugin.APP_BACKGROUND_REMOVAL,
      ];
      appsToEnableWhenSubscribedToPlus.forEach(pluginName => {
        this.subscriptionsToEditorCommunicationService.updatePlugins([
          {
            name: pluginName,
            state: PluginDataState.enabled,
            displayName: DesignEditorPluginNames[pluginName as DesignEditorPlugin]
              ? this.translate.instant(DesignEditorPluginNames[pluginName as DesignEditorPlugin])
              : null,
          },
        ]);
      });
      this.subscriptionsFacade.setPluginsIsUsedInTheEditor(false, null);
    }
  }

  private trackPricePlanPurchase(servicePlanPrice: ServicePlanPrice, modalRef: SuiModal<any, void, void>): void {
    this.getGelatoPlusOrGoldSubscriptionsStatuses$
      .pipe(
        first(),
        withLatestFrom(this.subscriptionPageEnabledAB$),
        tap(([gelatoPlusOrGoldSubscriptionsStatuses, subscriptionPageEnabledAB]) => {
          const eventName = subscriptionPageEnabledAB ? 'pricePlanIsPurchasedABtest' : 'pricePlanIsPurchased';

          logEvent(eventName, {
            periodUnit: servicePlanPrice.periodUnit,
            appName: servicePlanPrice.serviceUniqueName,
            pricePlan: servicePlanPrice.servicePlanUniqueName,
            uniqueName: servicePlanPrice.uniqueName,
            locationPopup: modalRef.context.gelatoPlusPopupLocation,
            featurePopUp: modalRef.context.gelatoPlusFeature,
            featuresUsed: this.subscriptionsFacade.pluginsListUsedInTheEditor$.getValue(),
            gelato_plus_subscription: gelatoPlusOrGoldSubscriptionsStatuses,
          });
        }),
      )
      .subscribe();
  }

  private trackCancelSubscription(subscriptionCancelledCb: any) {
    logEvent('subscriptionIsCancelled', {
      subscriptionId:
        subscriptionCancelledCb && subscriptionCancelledCb.data && subscriptionCancelledCb.data.subscription
          ? subscriptionCancelledCb.data.subscription.id
          : null,
    });
  }

  private trackChargebeeCheckoutSteps(step: string) {
    logEvent('chargebeeCheckoutStep', {
      step,
    });
  }
}
