import { DesignStructure } from '../design-structure';

export const getUsedMediaToTrack = (designStructure: DesignStructure): string => {
  if (!designStructure || !designStructure.spreads || !designStructure.spreads.length) {
    return '';
  }

  const data = {};

  designStructure.spreads.forEach((spread, spreadIndex: number) => {
    data[`spread${spreadIndex}`] = {};

    spread.pages.forEach((page, pageIndex: number) => {
      const spreadData = data[`spread${spreadIndex}`];
      spreadData[`page${pageIndex}`] = {};

      if (page.groups && page.groups.media) {
        page.groups.media.forEach((element, mediaIndex: number) => {
          const { imageId, extra, type } = element;
          const pageData = spreadData[`page${pageIndex}`];

          if (imageId) {
            pageData[`image${mediaIndex}`] = imageId;
          } else {
            pageData[`text${mediaIndex}`] = (extra?.text || type).slice(0, 30);
          }
        });
      }
    });
  });

  return JSON.stringify(data);
};
