import { ResaleCertificateCategory } from './resale-certificate-category.enum';
import { ResaleCertificateStatus } from './resale-certificate-status.enum';

export class ResaleCertificateUpdateRequest {
  constructor(
    public id: string,
    public clientId: string,
    public vendorName: string,
    public sellerPermitId: string,
    public categories: ResaleCertificateCategory[],
    public status: ResaleCertificateStatus,
    public stateCodes: string[],
    public fileUrl: string,
    public activeUntil: string,
  ) {}
}
