export enum PrintAreaNameEnum {
  Front = 'front',
  Back = 'back',
  SleeveLeft = 'sleeve-left',
  SleeveRight = 'sleeve-right',
  NeckInner = 'neck-inner',
  NeckOuter = 'neck-outer',
}

// this order is what GN expects to get in the design structure (i.e. pages order)
export const PrintAreaNameSortedArr = [
  PrintAreaNameEnum.Front,
  PrintAreaNameEnum.Back,
  PrintAreaNameEnum.NeckInner,
  PrintAreaNameEnum.NeckOuter,
  PrintAreaNameEnum.SleeveLeft,
  PrintAreaNameEnum.SleeveRight,
];
