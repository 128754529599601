<div>
  <div class="segment-header" [class.with-divider]="showDivider" [class.mobile-flex-column]="mobileFlexColumn">
    <h3 *ngIf="text">
      <img *ngIf="iconUrl" [width]="iconSize" [height]="iconSize" src="{{ iconUrl }}" />
      {{ text }}
      <img *ngIf="iconUrlAfterTitle" [width]="iconSize" [height]="iconSize" src="{{ iconUrlAfterTitle }}" />
    </h3>
    <div class="segment-header-content">
      <ng-content></ng-content>
    </div>
  </div>
</div>
<div *ngIf="showDivider" class="ui divider horisontal"></div>
