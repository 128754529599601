export enum EProductStatus {
  UNPUBLISHED = 'unpublished',
  PUBLISHED = 'published',
  DELETED = 'deleted',
  CREATED = 'created',
  PUBLISHING = 'publishing',
  PUBLISHING_ERROR = 'publishing_error',
  ACTIVE = 'active',
  UPDATING = 'updating',
}
