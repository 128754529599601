import { Component, Input, OnDestroy, Output } from '@angular/core';
import { SplashMessageFacade } from '../+state/splash-message/splash-message.facade';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { SplashMessage } from '../lib/splash-message';
import { filter, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'gd-splash-message',
  templateUrl: './splash-message.component.html',
  styleUrls: ['./splash-message.component.scss'],
})
export class SplashMessageComponent implements OnDestroy {
  @Input() set id(value: string) {
    if (!value) {
      return;
    }

    if (!!this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }

    this._id = value;
    this.subscription = this.splashMessageFacade
      .byId(value)
      .pipe(
        takeUntil(this.ngOnDestroy$),
        filter(m => m !== undefined),
        tap(message => {
          this.message$.next(message);

          if (message && message.timeout) {
            setTimeout(() => this.message$.next(null), message.timeout);
          }
        }),
      )
      .subscribe();
  }

  @Output() close$ = new Subject<boolean>();

  _id: string;
  subscription: Subscription;

  message$ = new BehaviorSubject<SplashMessage>(null);
  ngOnDestroy$ = new Subject();

  constructor(private readonly splashMessageFacade: SplashMessageFacade) {}

  ngOnDestroy() {
    this.splashMessageFacade.triggerHide(this._id);
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }

  onClose(e: MouseEvent) {
    this.splashMessageFacade.triggerHide(this._id);
    e.preventDefault();
    this.message$.next(null);
    this.close$.next(true);
  }
}
