import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { VideoComponent } from '@gelato-api-ui/sdk/src/lib/shared/video/video.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [VideoComponent],
  exports: [VideoComponent],
})
export class VideoModule {}
