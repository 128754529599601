import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@gelato-api-ui/sdk/src/lib/storage/storage.service';
import { DOCUMENT } from '@angular/common';
import { I18N_STORAGE } from '@gelato-api-ui/sdk/src/lib/translations/i18n.service';

@Injectable()
export class LocaleService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly storage: StorageService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  updateLocale(locale) {
    this.translateService.use(locale);
    this.storage.setItem(I18N_STORAGE, locale);
    this.document.documentElement.lang = locale.split('_')[0];
  }
}
