import * as R from 'ramda';
import { SanityProductVariation } from '../sanity-product-variation';

export const findProductVariation = (
  productVariations: SanityProductVariation[],
  searchQuery: Partial<SanityProductVariation>,
): SanityProductVariation => {
  if (!productVariations || !productVariations.length || !searchQuery || !R.keys(searchQuery)) {
    return null;
  }

  const searchQueryKeys: string[] = R.keys(searchQuery);

  return (
    productVariations.find(loopProductVariation => {
      return R.reduce(
        (result: boolean, key: string): boolean => result && searchQuery[key] === loopProductVariation[key],
        true,
        searchQueryKeys,
      );
    }) || null
  );
};
