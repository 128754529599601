<div class="header">
  <div class="header-content">
    <img width="32" height="32" [src]="'sidebar-icons/stores.svg' | assetsPath | async" />
    <div>
      {{ title || ('txt_checkout_add_existing_product' | translate) }}
    </div>
  </div>
</div>

<div class="content">
  <gc-full-screen-spinner *ngIf="isLoading"></gc-full-screen-spinner>

  <gd-stores-list-empty-state *ngIf="!isLoading && !stores?.length" (closeModal)="requestModalClose()">
  </gd-stores-list-empty-state>

  <gd-stores-list *ngIf="stores?.length" [items]="stores" [title]="subtitle" (itemSelect)="onStoreSelect($event)">
  </gd-stores-list>
</div>

<div *ngIf="!isLoading && !stores?.length" class="actions">
  <button class="ui secondary button" [class.disabled]="isLoading" (click)="requestModalClose()">
    {{ 'txt_close' | translate }}
  </button>
</div>
