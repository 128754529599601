<ng-template #accessGroupRow let-accessGroup="accessGroup" let-level="level">
  <tr>
    <td>
      <p
        *ngIf="!accessGroup.childGroups?.length"
        class="access-group level-{{ level }}"
        [class.selected]="isAccessGroupGranted(accessGroup.name)"
      >
        {{ accessGroup.name }}
      </p>

      <a class="settings-group" *ngIf="accessGroup.childGroups?.length" (click)="toggleAccessGroup(accessGroup.name)">
        <p class="access-group level-{{ level }}" [class.selected]="isAccessGroupGranted(accessGroup.name)">
          {{ accessGroup.name }}
          <i *ngIf="!expandedAccessGroups[accessGroup.name]" class="icon angle right"></i>
          <i *ngIf="expandedAccessGroups[accessGroup.name]" class="icon angle down"></i>
        </p>
      </a>
    </td>
    <td>
      <div class="access-level-buttons" *ngIf="accessGroup.accessScopes?.length">
        <button
          type="button"
          class="ui button"
          [class.active]="!selectedAccessGroupScopes[accessGroup.name]"
          (click)="selectAccessGroupScope(accessGroup.name, null)"
        >
          {{ 'txt_user_access_level_none' | translate }}
        </button>
        <button
          *ngFor="let accessScope of accessGroup.accessScopes"
          type="button"
          class="ui button"
          [class.active]="selectedAccessGroupScopes[accessGroup.name] === accessScope"
          (click)="selectAccessGroupScope(accessGroup.name, accessScope)"
        >
          {{ accessScope | accessLevel | async }}
        </button>
      </div>
    </td>
  </tr>

  <ng-container *ngIf="expandedAccessGroups[accessGroup.name]">
    <ng-container *ngFor="let childGroup of accessGroup?.childGroups">
      <ng-container *ngTemplateOutlet="accessGroupRow; context: { accessGroup: childGroup, level: level + 1 }">
      </ng-container>
    </ng-container>
  </ng-container>
</ng-template>

<table class="ui very basic unstackable table" *ngIf="accessGroups?.length">
  <thead>
    <tr>
      <th>{{ 'txt_user_access_resource_type_column' | translate }}</th>
      <th>{{ 'txt_user_access_permissions_column' | translate }}</th>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngFor="let accessGroup of accessGroups">
      <ng-container *ngIf="accessGroup.accessScopes?.length || accessGroup.childGroups?.length">
        <ng-container *ngTemplateOutlet="accessGroupRow; context: { accessGroup: accessGroup, level: 0 }">
        </ng-container>
      </ng-container>
    </ng-container>
  </tbody>
</table>
