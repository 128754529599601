import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { OrderDetails } from '../orders/lib/order-details';
import { OrderReceipt } from '../orders/lib/order-receipt';
import { OrderRefundSearchRequest } from '../orders/lib/order-refund-search-request';
import { OrderRefundSearchResponse } from '../orders/lib/order-refund-search-response';
import { OrderRefund } from '../orders/lib/order-refund';
import { getDefaultOrderReceipt, getOrderDetails } from '../orders/+state/order-details/order-details.selector';
import { State } from './order-refund.reducer';

export const getState = (state: AppState): State => state.orderRefund;

export const getRequest = createSelector(getState, (state: State): OrderRefundSearchRequest => state.request);

export const getOrderId = createSelector(getRequest, (request: OrderRefundSearchRequest): string => {
  if (!request) {
    return null;
  }

  return request.orderId;
});

export const getPayload = createSelector(getState, (state: State): OrderRefundSearchResponse => state.payload);

export const getOrderRefunds = createSelector(
  getPayload,
  (payload: OrderRefundSearchResponse): OrderRefund[] => (payload ? payload.data : null) || [],
);

export const isLoading = createSelector(getState, (state: State): boolean => state.isLoading);

export const getCreatedRefundsSummary = createSelector(getOrderRefunds, (orderRefunds: OrderRefund[]): OrderRefund => {
  let currency = null;
  let productsRefundAmount = 0;
  let productsRefundVat = 0;
  let productsRefundIncVatAmount = 0;
  let shipmentRefundAmount = 0;
  let shipmentRefundVat = 0;
  let shipmentRefundIncVatAmount = 0;

  if (orderRefunds && orderRefunds.length) {
    currency = orderRefunds[0].currency;

    orderRefunds.forEach((refund: OrderRefund) => {
      productsRefundAmount += refund.productsRefundAmount;
      productsRefundVat += refund.productsRefundVat;
      productsRefundIncVatAmount += refund.productsRefundIncVatAmount;

      shipmentRefundAmount += refund.shipmentRefundAmount;
      shipmentRefundVat += refund.shipmentRefundVat;
      shipmentRefundIncVatAmount += refund.shipmentRefundIncVatAmount;
    });
  }

  return new OrderRefund(
    null,
    null,
    null,
    currency,
    null,
    productsRefundAmount,
    productsRefundVat,
    productsRefundIncVatAmount,
    shipmentRefundAmount,
    shipmentRefundVat,
    shipmentRefundIncVatAmount,
    null,
    null,
  );
});

export const getAlreadyRefundedProductsAmount = createSelector(
  getCreatedRefundsSummary,
  (createdRefundsSummary: OrderRefund): number => {
    if (!createdRefundsSummary) {
      return 0;
    }

    return createdRefundsSummary.productsRefundIncVatAmount;
  },
);

export const getAlreadyRefundedShipmentAmount = createSelector(
  getCreatedRefundsSummary,
  (createdRefundsSummary: OrderRefund): number => {
    if (!createdRefundsSummary) {
      return 0;
    }

    return createdRefundsSummary.shipmentRefundIncVatAmount;
  },
);

export const getAlreadyRefundedTaxAmount = createSelector(
  getCreatedRefundsSummary,
  getDefaultOrderReceipt,
  (createdRefundsSummary: OrderRefund, defaultOrderReceipt: OrderReceipt): number => {
    if (!createdRefundsSummary) {
      return 0;
    }

    return createdRefundsSummary.productsRefundVat + createdRefundsSummary.shipmentRefundVat;
  },
);

export const getAlreadyRefundedTotalAmount = createSelector(
  getAlreadyRefundedProductsAmount,
  getAlreadyRefundedShipmentAmount,
  getAlreadyRefundedTaxAmount,
  (
    alreadyRefundedProductsAmount: number,
    alreadyRefundedShipmentAmount: number,
    alreadyRefundedTaxAmount: number,
  ): number => alreadyRefundedProductsAmount + alreadyRefundedShipmentAmount + alreadyRefundedTaxAmount,
);

export const getMaxAvailableRefundSummary = createSelector(
  getOrderDetails,
  getDefaultOrderReceipt,
  getOrderId,
  getCreatedRefundsSummary,
  (
    orderDetails: OrderDetails,
    defaultReceipt: OrderReceipt,
    orderId: string,
    createdRefundsSummary: OrderRefund,
  ): OrderRefund => {
    let currency = null;
    let productsRefundAmount = 0;
    let productsRefundVat = 0;
    let productsRefundIncVatAmount = 0;

    let shipmentRefundAmount = 0;
    let shipmentRefundVat = 0;
    let shipmentRefundIncVatAmount = 0;

    if (orderDetails && orderId && orderDetails.id === orderId && defaultReceipt && createdRefundsSummary) {
      currency = defaultReceipt.currency;

      productsRefundAmount = Math.max(
        0,
        Number(
          (
            defaultReceipt.productsPrice +
            defaultReceipt.packagingPrice -
            createdRefundsSummary.productsRefundAmount
          ).toFixed(2),
        ),
      );

      productsRefundIncVatAmount = Math.max(
        0,
        Number(
          (
            defaultReceipt.productsPriceInclVat +
            defaultReceipt.packagingPriceInclVat -
            createdRefundsSummary.productsRefundIncVatAmount
          ).toFixed(2),
        ),
      );

      productsRefundVat = Number((productsRefundIncVatAmount - productsRefundAmount).toFixed(2));

      shipmentRefundAmount = Math.max(
        0,
        Number((defaultReceipt.shippingPrice - createdRefundsSummary.shipmentRefundAmount).toFixed(2)),
      );

      shipmentRefundIncVatAmount = Math.max(
        0,
        Number((defaultReceipt.shippingPriceInclVat - createdRefundsSummary.shipmentRefundIncVatAmount).toFixed(2)),
      );

      shipmentRefundVat = Number((shipmentRefundIncVatAmount - shipmentRefundAmount).toFixed(2));
    }

    return new OrderRefund(
      null,
      null,
      null,
      currency,
      null,
      productsRefundAmount,
      productsRefundVat,
      productsRefundIncVatAmount,
      shipmentRefundAmount,
      shipmentRefundVat,
      shipmentRefundIncVatAmount,
      null,
      null,
    );
  },
);

export const isRefundAllowed = createSelector(
  getMaxAvailableRefundSummary,
  (maxAvailableRefundSummary: OrderRefund): boolean => {
    if (!maxAvailableRefundSummary) {
      return false;
    }

    return (
      maxAvailableRefundSummary.productsRefundIncVatAmount > 0 ||
      maxAvailableRefundSummary.shipmentRefundIncVatAmount > 0
    );
  },
);
