import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AppState } from '../../app.state';
import { select, Store } from '@ngrx/store';
import { getStoresList } from '../../ngrx/e-commerce-stores.selector';
import { map } from 'rxjs/operators';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';

type EStoreOrShowAll = EStore | { id: string; key: string };

@Component({
  selector: 'gd-e-com-store-selector',
  templateUrl: './e-com-store-selector.component.html',
  styleUrls: ['./e-com-store-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EComStoreSelectorComponent {
  @Input() showAnyStoreOption = false;
  @Input() externalStores: EStore[];
  @Input() value: string;

  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  storeList$ = this.store.pipe(
    select(getStoresList),
    map(stores => this.withAllStoresOption(stores)),
  );

  constructor(private readonly store: Store<AppState>) {}

  get currentValue() {
    return this.value || (this.showAnyStoreOption ? '' : undefined);
  }

  onSelect(value: string) {
    this.valueChange.emit(value);
  }

  private withAllStoresOption(stores: EStore[]): EStoreOrShowAll[] {
    return this.showAnyStoreOption ? [{ id: '', key: 'txt_all_stores_option' }, ...stores] : stores;
  }
}
