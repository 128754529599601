import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import {
  ImpactApiService,
  IRCLICKID,
  LOCAL_STORAGE_IRCLICKID_KEY,
} from '@api-ui-app/src/app/dashboard/services/impact-api.service';

@Injectable({ providedIn: 'root' })
export class ImpactGuard implements CanActivate {
  constructor(private readonly impactApiService: ImpactApiService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const clickId = route.queryParams[IRCLICKID] || localStorage.getItem(LOCAL_STORAGE_IRCLICKID_KEY);

    if (clickId) {
      this.impactApiService.track(clickId);
    }

    return of(true);
  }
}
