import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldErrorMessageModule } from '@gelato-api-ui/sdk/src/lib/shared/form-field-error-message/form-field-error-message.module';
import { PhoneFieldModule } from '@gelato-api-ui/ui-kit/src/lib/phone-field/phone-field.module';
import { SubscriptionBillingFormComponent } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/subscription-billing-address-form/subscription-billing-form.component';
import { CountrySelectorModule } from '@gelato-api-ui/sdk/src/lib/shared/country-selector/country-selector.module';
import { CountryStateSelectorModule } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/country-state-selector.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    FormFieldErrorMessageModule,
    PhoneFieldModule,
    CountrySelectorModule,
    CountryStateSelectorModule,
  ],
  declarations: [SubscriptionBillingFormComponent],
  exports: [SubscriptionBillingFormComponent],
})
export class SubscriptionBillingFormModule {}
