import { Injectable } from '@angular/core';
import {
  DesignEditorPlugin,
  DesignEditorPluginNames,
} from '@gelato-api-ui/core/design-editor/design-editor-plugin.enum';
import {
  PluginDataState,
  SetPluginsEventDataItem,
} from '@gelato-api-ui/core/design-editor/set-plugins-event-data-item';
import { TranslateService } from '@ngx-translate/core';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { ApparelProductTypeUids, BagProductTypeUids } from '@gelato-api-ui/core/product-catalogue/product-type-uids';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { ApparelAndAccessoriesSanityProductCategoryNames } from '@gelato-api-ui/core/sanity/apparel-and-accessories-sanity-product-category-names';

@Injectable({
  providedIn: 'root',
})
export class EditorPluginService {
  private imageProviderProductTypeAvailable: ProductTypeUid[] = [
    ProductTypeUid.POSTER,
    ProductTypeUid.FRAMED_POSTER,
    ProductTypeUid.MOUNTED_FRAMED_POSTER,
    ProductTypeUid.POSTER_WITH_HANGER,
    ProductTypeUid.CANVAS,
    ProductTypeUid.ACRYLIC_PRINT,
    ProductTypeUid.ALUMINUM_PRINT,
    ProductTypeUid.FOAM_PRINT,
    ProductTypeUid.WOOD_PRINT,
    ProductTypeUid.MUG,
    ProductTypeUid.BOTTLE,
    ProductTypeUid.WALLPAPER,
    ProductTypeUid.WALLPAPER_SAMPLE,
    ProductTypeUid.PHONE_CASE,
    ...ApparelProductTypeUids,
    ...BagProductTypeUids,
  ];

  private imageProviderNotWallpaperProductTypeAvailable: ProductTypeUid[] =
    this.imageProviderProductTypeAvailable.filter(
      type => type !== ProductTypeUid.WALLPAPER && type !== ProductTypeUid.WALLPAPER_SAMPLE,
    );

  private imageProviderCategoryTypeAvailable = new Set([
    SanityProductCategoryName.POSTERS,
    SanityProductCategoryName.FRAMED_POSTERS,
    SanityProductCategoryName.POSTERS_WITH_HANGERS,
    SanityProductCategoryName.CANVAS,
    SanityProductCategoryName.ACRYLIC,
    SanityProductCategoryName.METALLIC,
    SanityProductCategoryName.FOAM_BOARD_PRINT,
    SanityProductCategoryName.WOOD_PRINT,
    SanityProductCategoryName.MUGS,
    SanityProductCategoryName.PHONE_CASES,
    ...ApparelAndAccessoriesSanityProductCategoryNames,
  ]);

  private pluginProductTypeAvailability = {
    [DesignEditorPlugin.APP_GETTY_IMAGES]: this.imageProviderProductTypeAvailable,
    [DesignEditorPlugin.APP_SHUTTERSTOCK]: this.imageProviderProductTypeAvailable,
    [DesignEditorPlugin.APP_IMAGE_FILTERS]: Object.values(ProductTypeUid).filter(
      type => type !== ProductTypeUid.WALLPAPER && type !== ProductTypeUid.WALLPAPER_SAMPLE,
    ),
    [DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION]: this.imageProviderNotWallpaperProductTypeAvailable,
  };

  constructor(private readonly translateService: TranslateService) {}

  getGettyImagesPlugin(productTypeUid: ProductTypeUid): SetPluginsEventDataItem {
    let gettyPluginState = PluginDataState.not_visible;
    if (this.isPluginAvailableByProduct(DesignEditorPlugin.APP_GETTY_IMAGES, productTypeUid)) {
      gettyPluginState = PluginDataState.enabled;
    }
    return {
      name: DesignEditorPlugin.APP_GETTY_IMAGES,
      state: gettyPluginState,
      type: 'assets-source',
      icon: `${window.location.origin}/assets/editor/editor-getty.svg`,
      indexInApps: 0,
      tab: true,
      title: this.translateService.instant(DesignEditorPluginNames[DesignEditorPlugin.APP_GETTY_IMAGES]),
    };
  }

  getPluginByAppName(appName: DesignEditorPlugin, productTypeUid: ProductTypeUid): SetPluginsEventDataItem {
    const plugins = {
      [DesignEditorPlugin.APP_GETTY_IMAGES]: this.getGettyImagesPlugin(productTypeUid),
    };

    return plugins[appName] || {};
  }

  isPluginAvailableByProduct(appName: DesignEditorPlugin, productTypeUid: ProductTypeUid) {
    if (!this.pluginProductTypeAvailability[appName] || !productTypeUid) {
      return true;
    }
    return !!this.pluginProductTypeAvailability[appName].includes(productTypeUid);
  }

  isShutterstockAvailableByProduct(productTypeUid: ProductTypeUid) {
    return this.isPluginAvailableByProduct(DesignEditorPlugin.APP_SHUTTERSTOCK, productTypeUid);
  }

  isShutterstockTabSupported(sanityProductCategoryName: string) {
    return this.imageProviderCategoryTypeAvailable.has(sanityProductCategoryName as SanityProductCategoryName);
  }
}
