import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gd-search-by-client-field',
  templateUrl: './search-by-client-field.component.html',
  styleUrls: ['./search-by-client-field.component.scss'],
})
export class SearchByClientFieldComponent implements OnInit {
  @Input()
  clientId = '';

  @Output()
  clientIdChange = new EventEmitter();

  @Output()
  submit = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onChange(value) {
    this.clientIdChange.emit(value ? value : null);
    this.submit.emit();
  }

  onSubmit() {
    this.submit.emit();
  }
}
