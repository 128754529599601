import { BulkConnectVariantsMappingPayload } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import {
  EProductVariantMappingResponse,
  MappedVariant,
} from '@gelato-api-ui/core/e-commerce/e-product-variant-mapping-response';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';

export const mapResponseToBulkConnectProductVariantsMappingPayload = (
  response: EProductVariantMappingResponse,
  destinationProductVariants: EProductVariant[],
) =>
  response.mappedVariants.reduce(
    (acc: BulkConnectVariantsMappingPayload, loopMappedVariant: MappedVariant): BulkConnectVariantsMappingPayload => {
      const foundDestinationVariant: EProductVariant = destinationProductVariants.find(
        (loopProductVariant: EProductVariant): boolean =>
          loopProductVariant.externalVariantId === loopMappedVariant.originalId,
      );

      if (foundDestinationVariant && foundDestinationVariant.id && loopMappedVariant.mappedId) {
        return {
          ...acc,
          [foundDestinationVariant.id]: loopMappedVariant.mappedId,
        };
      }

      return acc;
    },
    {},
  );
