export enum OrderStatus {
  CREATED = 'created',
  UPLOADING = 'uploading',
  PASSED = 'passed',
  FAILED = 'failed',
  CANCELED = 'canceled',
  PRINTED = 'printed',
  SHIPPED = 'shipped',
  PENDING_APPROVAL = 'pending_approval',
  PENDING_PERSONALIZATION = 'pending_personalization',
  DRAFT = 'draft',
  NOT_CONNECTED = 'not_connected',
  ON_HOLD = 'on_hold',
  IGNORED = 'ignored',
}
