import * as R from 'ramda';
import { ProductionConfiguration } from '../../production-configurations/lib/production-configuration';
import { State } from '../production-configurations.reducer';

export const findProductionConfigurationInState = (state: State, id: string): ProductionConfiguration => {
  if (!state || !id) {
    return null;
  }

  if (state.entity && state.entity.payload && state.entity.payload.id === id) {
    return { ...state.entity.payload };
  }

  if (state.list && state.list.payload && state.list.payload.data) {
    return { ...R.find(R.propEq('id', id))(state.list.payload.data) };
  }

  return null;
};
