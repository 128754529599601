import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { ClientsApiService } from '@gelato-api-ui/core/clients/services/clients-api.service';
import { Client } from '@gelato-api-ui/core/clients/client';
import { ClientSearchResponse } from '@gelato-api-ui/core/clients/client-search-response';
import * as actions from './clients.actions';
import { getClientListState, getState } from './clients.reducer';
import { findClientInState } from './helpers/findClientInState';

@Injectable()
export class ClientsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private clientsApiService: ClientsApiService,
  ) {}

  loadClientListStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadClientListStart>(actions.ClientsActionTypes.LoadClientListStart),
        withLatestFrom(this.store$.select(getClientListState)),
        filter(([action, clientListState]) => {
          const { forced, searchRequest } = action;
          const isDataRelevant = R.equals(searchRequest, clientListState.request) && clientListState.payload;
          return forced || !isDataRelevant;
        }),
        switchMap(([action, clientListState]) => {
          const { searchRequest } = action;
          const { forced } = action;
          const isDataRelevant = R.equals(searchRequest, clientListState.request) && clientListState.payload;

          const searchClients = (): Observable<ClientSearchResponse> => {
            if (!forced && isDataRelevant) {
              return of({ ...clientListState.payload });
            }

            return this.clientsApiService.search(searchRequest);
          };

          this.store$.dispatch(
            new actions.SetClientListState({
              isLoading: true,
              request: searchRequest,
              payload: isDataRelevant ? clientListState.payload : null,
            }),
          );

          return searchClients().pipe(
            catchError((err): Observable<ClientSearchResponse> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: ClientSearchResponse) => {
              this.store$.dispatch(
                new actions.SetClientListState({
                  isLoading: false,
                  request: searchRequest,
                  payload: response,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadClientStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadClientStart>(actions.ClientsActionTypes.LoadClientStart),
        withLatestFrom(this.store$.select(getState)),
        switchMap(([action, state]) => {
          const { clientId, forced } = action;
          const clientFromState: Client = findClientInState(state, clientId);
          const isDataRelevant = Boolean(clientFromState);

          const getClient = (): Observable<Client> => {
            if (!forced && isDataRelevant) {
              return of({ ...clientFromState });
            }

            return this.clientsApiService.getClient(clientId);
          };

          this.store$.dispatch(
            new actions.SetClientState({
              isLoading: true,
              payload: isDataRelevant ? clientFromState : null,
            }),
          );

          return getClient().pipe(
            catchError((err): Observable<Client> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((user: Client) => {
              this.store$.dispatch(
                new actions.SetClientState({
                  isLoading: false,
                  payload: user,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
