import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as queryString from 'qs';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import {
  PreflightPreviewsArchiveRequest,
  PreflightPreviewsArchiveRequestProduct,
} from '@gelato-api-ui/core/preflight/preflight-previews-download';
import { Scene } from '@gelato-api-ui/core/preflight/scene';
import { PREVIEWS_SIZE_TO_DOWNLOAD } from '@api-ui-app/src/app/previews-archive/lib/preview-archive';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import { PrintAreaService } from '@gelato-api-ui/core/designs/services/print-area.service';
import { ECommerceProductVariantPreviewService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-variant-preview.service';
import { DesignPreviewFileTypeService } from '@gelato-api-ui/core/preflight/services/design-preview-file-type.service';

@Injectable()
export class PreviewsArchiveRequestService {
  constructor(
    private readonly printAreaService: PrintAreaService,
    private readonly eCommerceProductVariantPreviewService: ECommerceProductVariantPreviewService,
    private readonly designPreviewFileTypeService: DesignPreviewFileTypeService,
  ) {}

  get(
    sanityProductCategoryName: SanityProductCategoryName,
    productVariants: EProductVariant[],
    previewScenes: string[],
    selectedPreviewFileType: string,
    previewSize: number,
  ): Observable<PreflightPreviewsArchiveRequest> {
    return this.getProducts(sanityProductCategoryName, productVariants, previewScenes).pipe(
      take(1),
      map(
        (products: PreflightPreviewsArchiveRequestProduct[]): PreflightPreviewsArchiveRequest => ({
          scenes: previewScenes,
          format: selectedPreviewFileType,
          width: previewSize,
          height: previewSize,
          products,
          // FIXME: implement support of force_dimensions parameter
        }),
      ),
    );
  }

  private getProducts(
    sanityProductCategoryName: SanityProductCategoryName,
    productVariants: EProductVariant[],
    previewScenes: string[],
  ): Observable<PreflightPreviewsArchiveRequestProduct[]> {
    return this.getProductPreviewUrls(sanityProductCategoryName, productVariants, previewScenes).pipe(
      map((productPreviewUrls: string[]): PreflightPreviewsArchiveRequestProduct[] =>
        this.mapProductPreviewUrlsToRequestProducts(productPreviewUrls),
      ),
    );
  }

  private getProductPreviewUrls(
    sanityProductCategoryName: SanityProductCategoryName,
    productVariants: EProductVariant[],
    previewScenes: string[],
  ): Observable<string[]> {
    const productPreviewUrls$: Observable<string>[] = [];

    previewScenes.forEach((loopPreviewScene: string) => {
      productVariants.forEach((loopProductVariant: EProductVariant) => {
        productPreviewUrls$.push(
          this.eCommerceProductVariantPreviewService.getProductPreviewUrl(
            loopProductVariant,
            loopPreviewScene,
            PreviewFileType.PNG,
            this.designPreviewFileTypeService.getBySanityProductCategoryName(sanityProductCategoryName),
            {
              width: PREVIEWS_SIZE_TO_DOWNLOAD,
              height: PREVIEWS_SIZE_TO_DOWNLOAD,
              forceDimensions: false,
            },
          ),
        );
      });
    });

    return forkJoin(...productPreviewUrls$);
  }

  private mapProductPreviewUrlsToRequestProducts(
    productPreviewUrls: string[],
  ): PreflightPreviewsArchiveRequestProduct[] {
    const requestProducts: PreflightPreviewsArchiveRequestProduct[] = productPreviewUrls.map(
      (url: string): PreflightPreviewsArchiveRequestProduct => this.mapProductPreviewUrlToRequestProduct(url),
    );

    return this.combineRequestProducts(requestProducts);
  }

  private mapProductPreviewUrlToRequestProduct(url: string): PreflightPreviewsArchiveRequestProduct {
    const urlParams = queryString.parse(url.substr(url.indexOf('?')), { ignoreQueryPrefix: true });
    const productUid = urlParams?.product_uid as string;

    if (urlParams?.images_urls) {
      return {
        product_uid: productUid,
        images_urls: JSON.parse(urlParams?.images_urls as string),
      };
    }

    const previewScene = urlParams.scene as Scene;
    const pageNr = this.printAreaService.getPageNrByScene(previewScene as Scene);

    return {
      product_uid: productUid,
      images: [
        {
          url: urlParams?.image_url as string,
          page_nr: pageNr,
          area: this.printAreaService.getPrintAreaNameByPageNr(pageNr),
        },
      ],
    };
  }

  private combineRequestProducts(
    requestProducts: PreflightPreviewsArchiveRequestProduct[],
  ): PreflightPreviewsArchiveRequestProduct[] {
    const mapped = requestProducts.reduce((acc, loopRequestProduct: PreflightPreviewsArchiveRequestProduct) => {
      const { product_uid, images, images_urls } = loopRequestProduct;

      if (acc[product_uid] && images?.length) {
        const combinedImages = [...(acc[product_uid].images || []), ...images];

        return {
          ...acc,
          [product_uid]: {
            ...acc[product_uid],
            images: combinedImages,
          },
        };
      }

      return {
        ...acc,
        [product_uid]: {
          images,
          images_urls,
        },
      };
    }, {});

    return Object.keys(mapped).map(uid => {
      let images;

      if (mapped[uid].images) {
        // reduce so we have only one page_nr1 and ond page_nr=2
        images = mapped[uid].images.reduce(
          (acc, el) => (acc.find(e => e.page_nr === el.page_nr) ? acc : [...acc, el]),
          [],
        );
      }

      return {
        product_uid: uid,
        images,
        images_urls: mapped[uid].images_urls,
      };
    });
  }
}
