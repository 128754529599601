import { Inject, Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { ETSY_LINK_V2_ROUTE_PATH_SEGMENT } from '@api-ui-app/src/app/e-commerce-stores/constants/constants';
import { WINDOW } from '@gelato-api-ui/core/window/window';
import { EStoreConnectResponse } from '@api-ui-app/src/app/e-commerce-stores/types/e-store-connect-response';
import { EtsyConnectV2Request } from '@api-ui-app/src/app/e-commerce-stores/types/etsy-connect-v2-request';
import { EtsyInitiateV2Request } from '@api-ui-app/src/app/e-commerce-stores/types/etsy-initiate-v2-request';
import { EtsyInitiateV2Response } from '@api-ui-app/src/app/e-commerce-stores/types/etsy-initiate-v2-response';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { AbstractEtsyIntegrationVersionService } from '@api-ui-app/src/app/e-commerce-stores/services/abstract-etsy-integration-version.service';
import { ApiResponse } from '@gelato-api-ui/core/api/api-response.interface';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { EtsyInitiateV1Response } from '@api-ui-app/src/app/e-commerce-stores/types/etsy-initiate-v1-response';

@Injectable()
export class EtsyIntegrationV2Service extends AbstractEtsyIntegrationVersionService {
  constructor(private readonly apiRequest: ApiRequestService, @Inject(WINDOW) private window: Window) {
    super();
  }

  initiateConnection(): Observable<string> {
    const request: EtsyInitiateV2Request = { callbackUrl: this.initiateCallbackUrl };

    return this.apiRequest.get<EtsyInitiateV1Response>('/api/ecommerce/v2/etsy', { body: request }).pipe(
      map((data: ApiResponse<EtsyInitiateV1Response>): EtsyInitiateV1Response => data?.data),
      map((response: EtsyInitiateV2Response): string => response?.url),
    );
  }

  connect(params: Params): Observable<EStore> {
    if (params.error || params.error_description) {
      return throwError({
        response: {
          error: {
            code: params.error,
            message: params.error_description,
          },
        },
      });
    }

    const request: EtsyConnectV2Request = {
      code: params.code,
      state: params.state,
    };

    return this.apiRequest
      .post<EStoreConnectResponse>('/api/ecommerce/v2/etsy/connect', {
        body: request,
      })
      .pipe(map((data: ApiResponse<EStoreConnectResponse>): EStore => data?.data?.store));
  }

  private get initiateCallbackUrl(): string {
    return `${this.window.location.origin}/stores/${ETSY_LINK_V2_ROUTE_PATH_SEGMENT}`;
  }
}
