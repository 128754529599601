import { createReducer, on } from '@ngrx/store';
import * as actions from './countries.actions';

export interface State {
  isLoaded: boolean;
  supportedCountries: string[];
  regionsToCountries: Record<string, string[]>;
  error: any;
}

export const initialState: State = {
  isLoaded: false,
  supportedCountries: null,
  regionsToCountries: null,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(actions.resetCountries, () => ({
    ...initialState,
  })),
  on(actions.loadCountriesComplete, (state, action) => ({
    ...state,
    isLoaded: true,
    ...action,
  })),
  on(actions.loadCountriesError, (state, action) => ({
    ...state,
    isLoaded: false,
    error: action,
  })),
);
