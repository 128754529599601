import * as R from 'ramda';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { AssetsCollection } from '../assets-collection';
import { getAssetsAutorNamesFromDesignStructure } from './getAssetsAutorNamesFromDesignStructure';

export const getAssetsAutorNamesFromProductVariants = (
  assetsCollection: AssetsCollection,
  productVariants: EProductVariant[],
): string[] => {
  if (!assetsCollection || !productVariants || !productVariants.length) {
    return [];
  }

  let authorNames: string[] = [];

  productVariants.forEach((loopProductVariant: EProductVariant) => {
    if (!loopProductVariant.designStructureJson) {
      return;
    }

    let designData = null;

    try {
      designData = JSON.parse(loopProductVariant.designStructureJson);
    } catch (e) {}

    if (!designData) {
      return;
    }

    authorNames = [...authorNames, ...getAssetsAutorNamesFromDesignStructure(assetsCollection, designData)];
  });

  authorNames = R.sort((a, b) => a.localeCompare(b), authorNames);

  return R.uniq(authorNames);
};
