import { Injectable } from '@angular/core';
import * as R from 'ramda';

@Injectable()
export class StorageValueService {
  fromRawValue<T>(rawValue: string, defaultValue: T): T {
    return R.isNil(rawValue) ? defaultValue : JSON.parse(rawValue);
  }

  toRawValue<T>(value: T): string {
    return JSON.stringify(value);
  }
}
