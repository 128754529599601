import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap, withLatestFrom } from 'rxjs/operators';
import { OrdersApiService } from '@api-ui-app/src/app/orders/services/orders-api.service';
import { CheckoutOrderIdService } from '@api-ui-app/src/app/orders/services/checkout-order-id.service';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OrderDeletionService {
  clientId$ = this.authService.getClientId();

  constructor(
    private readonly ordersApiService: OrdersApiService,
    private readonly checkoutOrderIdService: CheckoutOrderIdService,
    private readonly authService: AuthService,
  ) {}

  delete(id: string): Observable<any> {
    return this.ordersApiService.delete(id).pipe(
      catchError(() => of(null)),
      withLatestFrom(this.authService.getClientId()),
      tap(([response, clientId]) => {
        if (this.checkoutOrderIdService.get(clientId) === id) {
          this.checkoutOrderIdService.remove(clientId);
        }
      }),
    );
  }
}
