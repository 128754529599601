import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyMinorUnitAmountMap } from '../lib/currency-minor-unit-amount.const';

@Pipe({
  name: 'moneyToMinorUnits',
})
export class MoneyToMinorUnitsPipe implements PipeTransform {
  transform(amount: number, currency: string): any {
    const units = CurrencyMinorUnitAmountMap[currency] || CurrencyMinorUnitAmountMap.DEFAULT;

    return parseInt((amount * units).toFixed(1));
  }
}
