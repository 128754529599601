import { getAppVersion } from './getAppVersion';
import { environment } from '@api-ui-app/src/environments/environment';

declare global {
  interface Window {
    dataLayer: any[];
    analytics: any;
  }
}

export interface AmplitudeEvent {
  event: string;
  eventType?: string;
  eventProperties?: object;
  userId?: string;
  userProperties?: object;
  content?: string;
  clientId?: string;
  rating?: number;
}

export const dataLayerPush = (event: AmplitudeEvent) => {
  try {
    if (!event) {
      return;
    }

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({
      ...event,
      eventProperties: {
        ...(event.eventProperties || {}),
        appVersion: getAppVersion(),
      },
    });
  } catch (e) {
    // Ignore
  }
};

export const trackEvent = (event: AmplitudeEvent) => {
  if (!environment.production) {
    console.log('trackEvent', event);
  }
  dataLayerPush(event);
};

// this is primary method to use
export const logEvent = (eventType: string, eventProperties?: object) => {
  try {
    trackEvent({
      event: 'logEvent',
      eventType,
      eventProperties,
    });
  } catch (e) {}
};
