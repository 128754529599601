import { Injectable } from '@angular/core';
import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';

@Injectable({ providedIn: 'root' })
export class SanityProductTitleService {
  get(sanityProductCategory: SanityProductCategory, sanityProduct: SanityProduct): string {
    const titleSegments: string[] = [];

    if (sanityProductCategory?.title) {
      titleSegments.push(sanityProductCategory.title);
    }

    if (sanityProduct?.title) {
      titleSegments.push(sanityProduct.title);
    }

    return titleSegments.join(', ');
  }
}
