<div class="wide column" [ngClass]="wrapperClass" *ngIf="!isDismissed && isVisible">
  <div class="ui segment">
    <img class="close" [src]="'/assets/checkout/close.svg'" alt="" width="" height="" (click)="onDismiss()" />

    <swiper
      class="previews"
      [class.single-widget]="widgets && widgets.length === 1"
      [config]="swiperConfig"
      (slideChange)="onSlideChange($event)"
    >
      <ng-container *ngFor="let widget of widgets; let i = index">
        <ng-template swiperSlide *ngIf="isCustomWidgetShown(widget)">
          <gc-widget-segment
            [index]="i"
            [headerTitle]="widget.title"
            [headerIcon]="widget.title_icon"
            [title]="widget.head_text"
            [text]="widget.body_text"
            [buttonText]="widget.primary_button_text"
            [buttonIcon]="widget.primary_button_icon"
            [secondaryButtonText]="widget.secondary_button_text"
            [image]="widget.image"
            [imageMaxHeight]="widget.image_max_height"
            [videoId]="widget.videoId"
            [showButtonArrowIcon]="widget.primary_button_arrow_icon"
            (buttonClick)="onPrimaryButtonClick($event)"
            (secondaryButtonClick)="onSecondaryButtonClick($event)"
            (startVideo)="playVideo(widget.videoId)"
          ></gc-widget-segment>
        </ng-template>
      </ng-container>
    </swiper>
  </div>

  <gc-single-video-fullscreen
    *ngIf="playVideo$ | async"
    [videoId]="playVideo$ | async"
    (close)="playVideo(null)"
  ></gc-single-video-fullscreen>
</div>
