import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  PlansComparisonTableCell,
  PlansComparisonTableCellType,
} from '@api-ui-app/src/app/subscriptions/types/compare-table';

@Component({
  selector: 'gd-comparison-table-cell',
  templateUrl: './comparison-table-cell.component.html',
  styleUrls: ['./comparison-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonTableCellComponent {
  @Input() cell: PlansComparisonTableCell;
  @Input() currency: string;
  @Input() countryISOCode: string;
  @Input() isFirstCell: boolean;

  public cellType = PlansComparisonTableCellType;
}
