import { Action } from '@ngrx/store';
import { PricePlanSubscriptionSearchRequest } from '../price-plan-subscriptions/lib/price-plan-subscription-search-request';
import { PricePlanSubscriptionListState, PricePlanSubscriptionState } from './price-plan-subscriptions.reducer';

export enum PricePlanSubscriptionsActionTypes {
  LoadPricePlanSubscriptionList = '[PricePlanSubscriptions] Load PricePlanSubscriptionList',
  LoadPricePlanSubscription = '[PricePlanSubscriptions] Load PricePlanSubscription',

  ResetPricePlanSubscriptionListState = '[PricePlanSubscriptions] Reset PricePlanSubscriptionList State',
  ResetPricePlanSubscriptionListPayload = '[PricePlanSubscriptions] Reset PricePlanSubscriptionList Payload',
  ResetPricePlanSubscriptionState = '[PricePlanSubscriptions] Reset PricePlanSubscription State',

  SetPricePlanSubscriptionListState = '[PricePlanSubscriptions] Set PricePlanSubscriptionList State',
  SetPricePlanSubscriptionState = '[PricePlanSubscriptions] Set PricePlanSubscription State',
}

export class LoadPricePlanSubscriptionList implements Action {
  readonly type = PricePlanSubscriptionsActionTypes.LoadPricePlanSubscriptionList;

  constructor(public searchRequest: PricePlanSubscriptionSearchRequest, public forced: boolean = false) {}
}

export class LoadPricePlanSubscription implements Action {
  readonly type = PricePlanSubscriptionsActionTypes.LoadPricePlanSubscription;

  constructor(public id: string, public forced: boolean = false) {}
}

export class ResetPricePlanSubscriptionListState implements Action {
  readonly type = PricePlanSubscriptionsActionTypes.ResetPricePlanSubscriptionListState;
}

export class ResetPricePlanSubscriptionListPayload implements Action {
  readonly type = PricePlanSubscriptionsActionTypes.ResetPricePlanSubscriptionListPayload;
}

export class ResetPricePlanSubscriptionState implements Action {
  readonly type = PricePlanSubscriptionsActionTypes.ResetPricePlanSubscriptionState;
}

export class SetPricePlanSubscriptionListState implements Action {
  readonly type = PricePlanSubscriptionsActionTypes.SetPricePlanSubscriptionListState;

  constructor(public payload: PricePlanSubscriptionListState) {}
}

export class SetPricePlanSubscriptionState implements Action {
  readonly type = PricePlanSubscriptionsActionTypes.SetPricePlanSubscriptionState;

  constructor(public payload: PricePlanSubscriptionState) {}
}

export type PricePlanSubscriptionsActions =
  | LoadPricePlanSubscriptionList
  | LoadPricePlanSubscription
  | ResetPricePlanSubscriptionListState
  | ResetPricePlanSubscriptionListPayload
  | ResetPricePlanSubscriptionState
  | SetPricePlanSubscriptionListState
  | SetPricePlanSubscriptionState;
