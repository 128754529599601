import { Injectable } from '@angular/core';
import { ConfigCatProvider } from '@gelato-api-ui/sdk/src/lib/feature-switcher/config-cat/config-cat.provider';
import { EMPTY, Observable } from 'rxjs';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class FeatureSwitcherService {
  constructor(private readonly configCatProvider: ConfigCatProvider, private readonly authService: AuthService) {}

  public isFeatureEnabled(featureName: FeatureFlagEnum, defaultValue = false): Observable<boolean> {
    return this.configCatProvider.isFeatureEnabled(featureName, defaultValue);
  }

  public isFeatureEnabledForUser(
    featureName: FeatureFlagEnum,
    clientId: string,
    defaultValue = false,
  ): Observable<boolean> {
    return this.configCatProvider.isFeatureEnabledForUser(featureName, defaultValue, clientId);
  }

  public isFeatureEnabledForCurrentUser(featureName: FeatureFlagEnum, defaultValue = false): Observable<boolean> {
    return this.authService.getClientId().pipe(
      switchMap(clientId =>
        this.configCatProvider.isFeatureEnabledForUser(featureName, defaultValue, clientId).pipe(
          map(response => response),
          catchError(() => EMPTY),
        ),
      ),
    );
  }
}
