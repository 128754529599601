import { Action } from '@ngrx/store';
import { State } from './user-roles.reducer';

export enum UserRolesActionTypes {
  Load = '[UserRoles] Load',
  ResetState = '[UserRoles] Reset State',
  SetState = '[UserRoles] Set State',
}

export class Load implements Action {
  readonly type = UserRolesActionTypes.Load;

  constructor(public clientId: string, public forced: boolean = false) {}
}

export class ResetState implements Action {
  readonly type = UserRolesActionTypes.ResetState;
}

export class SetState implements Action {
  readonly type = UserRolesActionTypes.SetState;

  constructor(public payload: State) {}
}

export type RolesActions = Load | ResetState | SetState;
