import { createFeatureSelector, createSelector } from '@ngrx/store';
import { EProductTreeItem } from '@api-ui-app/src/app/product/product-add/types/e-product-tree-item';
import * as fromProductsTree from './e-commerce-products-tree.reducer';

export const getState = createFeatureSelector<fromProductsTree.State>(fromProductsTree.eCommerceProductsTreeFeatureKey);

export const getLoadingFlag = createSelector(getState, (state: fromProductsTree.State): boolean => state.isLoading);

export const getProductsTree = createSelector(
  getState,
  (state: fromProductsTree.State): EProductTreeItem[] => state.payload || [],
);
