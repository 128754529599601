import { Directive, HostListener, Input, NgModule } from '@angular/core';
import { NgControl } from '@angular/forms';
import { PhonePipe } from '@gelato-api-ui/sdk/src/lib/pipes/phone.pipe';
import { CountryCode } from 'libphonenumber-js';

@Directive({
  selector: '[gcPhoneMask]',
})
export class PhoneMaskDirective {
  @Input() gcPhoneMaskCountryCode: CountryCode;

  constructor(public ngControl: NgControl, private phonePipe: PhonePipe) {}

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event) {
    this.ngControl.valueAccessor.writeValue(this.phonePipe.transform(event, this.gcPhoneMaskCountryCode));
  }

  @HostListener('keydown.backspace', ['$event'])
  keydownBackspace(event) {
    this.ngControl.valueAccessor.writeValue(this.phonePipe.transform(event.target.value, this.gcPhoneMaskCountryCode));
  }
}

@NgModule({
  declarations: [PhoneMaskDirective],
  exports: [PhoneMaskDirective],
})
export class PhoneMaskDirectiveModule {}
