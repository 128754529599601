import { animate, AnimationTriggerMetadata, group, state, style, transition, trigger } from '@angular/animations';

export function deckAnimation(
  params: {
    readonly duration: number;
    readonly easing: string;
  } = { duration: 180, easing: 'cubic-bezier(0.5, 1, 0.89, 1)' },
): AnimationTriggerMetadata {
  return trigger('deck', [
    state('void', style({ opacity: 0 })),
    transition('void => open', [
      group([
        style({ opacity: 0, transform: 'translateY(250px)' }),
        animate(`${params.duration}ms ${params.easing}`, style({ opacity: 1, transform: 'translateY(0px)' })),
      ]),
    ]),
    transition('open => close', [
      group([
        style({ opacity: 1, transform: 'translateY(0px)' }),
        animate(`${params.duration}ms ${params.easing}`, style({ opacity: 0, transform: 'translateY(250px)' })),
      ]),
    ]),
  ]);
}
