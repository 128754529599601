import * as actions from './assets.actions';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';

export interface State {
  assetsCollection: AssetsCollection;
}

export const initialState: State = {
  assetsCollection: {},
};

export function reducer(state = initialState, action: actions.StoreProductVariantsActions): State {
  switch (action.type) {
    case actions.AssetsMappingActionTypes.ResetState:
      return { ...initialState };
    case actions.AssetsMappingActionTypes.SetAssets:
      return {
        ...state,
        assetsCollection: action.payload,
      };
    case actions.AssetsMappingActionTypes.AddAssets:
      return {
        ...state,
        assetsCollection: {
          ...action.payload,
          ...state.assetsCollection,
        },
      };
    case actions.AssetsMappingActionTypes.SetAsset: {
      const { payload } = action;

      if (!payload) {
        return state;
      }

      return {
        ...state,
        assetsCollection: {
          [action.payload.id]: { ...action.payload },
          ...state.assetsCollection,
        },
      };
    }
    default:
      return state;
  }
}
