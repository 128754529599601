import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';
import { PricePlanItemComponent } from './price-plan-item.component';
import { TooltipModule } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SdkPipesModule, TooltipModule],
  declarations: [PricePlanItemComponent],
  exports: [PricePlanItemComponent],
})
export class PricePlanItemModule {}
