import { Component, Input, OnInit } from '@angular/core';
import { Client } from '@gelato-api-ui/core/clients/client';

@Component({
  selector: 'gd-client-info-segment',
  templateUrl: './client-info-segment.component.html',
  styleUrls: ['./client-info-segment.component.scss'],
})
export class ClientInfoSegmentComponent implements OnInit {
  @Input() client: Client = null;
  @Input() showSpinner = false;

  constructor() {}

  ngOnInit() {}
}
