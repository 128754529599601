<div class="ui message negative">
  <div class="content">
    <div class="warning-icon">
      <img src="/assets/icons/warning-red.svg" alt="" />
    </div>
    <div class="main-content-wrapper">
      <div class="main-content">
        <p class="title">
          <strong>
            {{ titleText }}
          </strong>
        </p>
        <p class="description">
          {{ descriptionText }}
        </p>
      </div>
      <div class="action-button">
        <button class="ui button" (click)="onActionButtonClick()">{{ actionButtonText }}</button>
      </div>
    </div>
  </div>
</div>
