import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'creditCardExpiryDate',
})
export class CreditCardExpiryDatePipe implements PipeTransform {
  transform(date: string): string {
    const d = new Date(date);
    d.setDate(d.getDate() + 2);
    return `${d.getMonth() < 9 ? 0 : ''}${d.getMonth() + 1}/${d.getFullYear() % 1000}`;
  }
}
