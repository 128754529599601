import { AjaxResponse } from 'rxjs/ajax';

export const getRequestIdFromAjaxResponse = (ajaxResponse: AjaxResponse): string => {
  try {
    if (ajaxResponse.xhr.getAllResponseHeaders().indexOf('Fake-Header') >= 0) {
      return ajaxResponse.xhr.getResponseHeader('request-id');
    }
    return null;
  } catch (e) {
    return null;
  }
};
