import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '../app.state';
import { MAX_ONBOARDING_ORDERS_COUNT } from '../lib/constants';
import { User } from '../shared/lib/user';
import { BillingType } from '@gelato-api-ui/core/clients/billing-type.enum';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { getUser } from './auth.reducer';
import {
  getIsCompanyDetailsEmpty,
  getState as getCompanyDetailsState,
  hasBillingEntity,
  isPrepaidAccount,
  State as CompanyDetailsState,
} from './company-details.reducer';
import { paymentDetailsQuery } from '../payment-methods/wallets/+state/payment-details/payment-details.selector';
import { OnboardingStepName } from '../dashboard/types/onboarding-step-name.enum';
import { AccountActivationSteps, State } from './account-activation-steps.reducer';
import {
  generateDiscountedOrdersPromotions,
  getDiscountedOrders,
} from '@api-ui-app/src/app/ngrx/helpers/getDiscountedOrders';
import { getUnixTime, parseISO } from 'date-fns';

export const getState = (state: AppState): State => state.accountActivationSteps;

export const getPaidOrdersCount = createSelector(getState, (state: State): number => state.paidOrdersCount);

export const getFirstAvailableStore = createSelector(getState, (state: State): EStore => state.firstAvailableStore);

export const getFirstAvailableProduct = createSelector(
  getState,
  (state: State): EProductAbbridged => state.firstAvailableProduct,
);

export const getCompletedOnboardingStepNames = createSelector(
  getFirstAvailableStore,
  getFirstAvailableProduct,
  paymentDetailsQuery.getLastAdded,
  isPrepaidAccount,
  getIsCompanyDetailsEmpty,
  getPaidOrdersCount,
  (
    firstAvailableStore: EStore,
    firstAvailableProduct: EProductAbbridged,
    paymentDetails,
    isPrepaid: boolean,
    isCompanyDetailsEmpty: boolean,
    paidOrdersCount: number,
  ): OnboardingStepName[] => {
    const creditCardMissing = isPrepaid && !paymentDetails;
    const eCommerceStoreMissing = !firstAvailableStore;
    const eCommerceProductsMissing = !firstAvailableProduct;
    const completedOnboardingStepNames: OnboardingStepName[] = [];

    if (!eCommerceStoreMissing) {
      completedOnboardingStepNames.push(OnboardingStepName.CONNECT_STORE);
    }

    if (!eCommerceProductsMissing) {
      completedOnboardingStepNames.push(OnboardingStepName.ADD_PRODUCT);
    }

    if (!creditCardMissing) {
      completedOnboardingStepNames.push(OnboardingStepName.ADD_CREDIT_CARD);
    }

    if (!isCompanyDetailsEmpty) {
      completedOnboardingStepNames.push(OnboardingStepName.ADD_COMPANY_INFO);
    }

    if (paidOrdersCount > 0) {
      completedOnboardingStepNames.push(OnboardingStepName.PLACE_FIRST_ORDER);
    }

    return completedOnboardingStepNames;
  },
);

export const getAccountActivationSteps = createSelector(
  getState,
  getUser,
  getCompanyDetailsState,
  hasBillingEntity,
  getFirstAvailableStore,
  getFirstAvailableProduct,
  paymentDetailsQuery.getLastAdded,
  getCompletedOnboardingStepNames,
  (
    state: State,
    user: User,
    companyDetailsState: CompanyDetailsState,
    companyDetailsAdded: boolean,
    firstAvailableStore: EStore,
    firstAvailableProduct: EProductAbbridged,
    paymentDetails,
    completedOnboardingStepNames: OnboardingStepName[],
  ): AccountActivationSteps => {
    if (companyDetailsState.isLoading || !companyDetailsState.payload || R.isNil(state.paidOrdersCount) || !user) {
      return null;
    }

    const { paidOrdersCount, hasMultipleStores } = state;
    const isPrepaid = companyDetailsState.payload.billingType === BillingType.PREPAID;

    const companyDetailsMissing = !companyDetailsAdded;
    const creditCardMissing = isPrepaid && !paymentDetails;
    const canPlaceOrder = !companyDetailsMissing && !creditCardMissing;
    const ordersDiscount = getDiscountedOrders(companyDetailsState.payload, user, state.paidOrdersCount);
    const ordersPromotions = generateDiscountedOrdersPromotions(
      companyDetailsState.payload,
      user,
      state.paidOrdersCount,
    );
    const discountOfferApplicable = ordersDiscount.ordersCount > 0;

    const userCreatedThisYear = getUnixTime(parseISO('2021-01-01')) < getUnixTime(parseISO(user.createdAt));
    const showOnboardingSteps = isPrepaid && userCreatedThisYear && paidOrdersCount <= MAX_ONBOARDING_ORDERS_COUNT;

    return {
      isPrepaid,
      showWelcomeBanner: discountOfferApplicable,
      showOnboardingSteps,
      canPlaceOrder,
      companyDetailsMissing,
      creditCardMissing,
      ordersDiscount,
      firstAvailableStore,
      firstAvailableProduct,
      completedOnboardingStepNames,
      hasMultipleStores,
      ordersPromotions,
    };
  },
);
