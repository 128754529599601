import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { RowId, RowType } from '@api-ui-app/src/app/subscriptions/types/sanity-apps-list-page';
import {
  PlansComparisonTable,
  PlansComparisonTableCell,
  PlansComparisonTableSections,
} from '@api-ui-app/src/app/subscriptions/types/compare-table';
import Swiper, { SwiperOptions } from 'swiper';
import { PaginationOptions } from 'swiper/types';
import { GelatoSubscriptionModalType } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-subscription-modal';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { TooltipPositions } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.enum';
import { SubscriptionPlanType } from '@api-ui-app/src/app/app-store/types/price-plan-items';
import { ActivationConfirmationModalService } from '@api-ui-app/src/app/activation-modal/services/activation-confirmation-modal.service';

@Component({
  selector: 'gd-plans-comparison-table',
  templateUrl: './plans-comparison-table.component.html',
  styleUrls: ['./plans-comparison-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlansComparisonTableComponent {
  @Input() userCurrency: string;
  @Input() isGelatoPlusActive: boolean;
  @Input() isGelatoPlusTrialActive: boolean;
  @Input() isGelatoPlusGoldTrialActive: boolean;
  @Input() isGelatoPlusTrialScheduledToCancel: boolean;
  @Input() isGelatoPlusGoldTrialScheduledToCancel: boolean;
  @Input() isGelatoPlusGoldActive: boolean;
  @Input() isMobile: boolean;
  @Input() isClientEligibleForGoldSubscription: boolean;
  @Input() countryISOCode: string;
  @Input() isGoldTrialNotAvailable: boolean;
  @Input() subscriptionPageEnabledAB: boolean;
  @Input() plansComparisonTableSections: PlansComparisonTableSections;
  @Input() isUserNeverHadGelatoPlusSubscription: boolean;
  @Input() isUserNeverHadGelatoPlusGoldSubscription: boolean;
  @Input() set plansComparisonTableData(data: PlansComparisonTableCell[] | PlansComparisonTableCell[][]) {
    this.plansComparisonTableDataFlatted = (data as any)?.filter(row => !row?.[0]?.length);
    this.plansComparisonTableDataGrouped = (data as any)?.filter(row => row?.[0]?.length);
  }
  @Input() set plansComparisonTableRows(tableRows: PlansComparisonTable) {
    this.tableRows = tableRows;
  }
  public get mobileTableRows(): PlansComparisonTable {
    if (!this.tableRows) {
      return [];
    }

    return this.tableRows.map(row => [row[0], row[this.activeSwiperIndex + 1]]);
  }

  plansComparisonTableDataFlatted: PlansComparisonTableCell[];
  plansComparisonTableDataGrouped: PlansComparisonTableCell[][];

  @Output() subscriptionsModalOpen = new EventEmitter<GelatoSubscriptionModalType>();
  @Output() activateSubscription = new EventEmitter<SubscriptionPlanType>();
  @Output() managePlanModalOpen = new EventEmitter();

  subscriptionType = SubscriptionPlanType;
  rowId = RowId;
  rowType = RowType;
  tooltipPositions = TooltipPositions;
  gelatoSubscriptionModalType = GelatoSubscriptionModalType;

  thumbnailsSwiperConfig: SwiperOptions = {
    mousewheel: true,
    threshold: 10,
    keyboard: true,
    slidesPerView: 1.2,
    centeredSlides: true,
  };

  pagination: PaginationOptions = {
    clickable: true,
  };

  isTableShown = true;

  private activeSwiperIndex = 0;
  private tableRows: PlansComparisonTable;

  constructor(
    private cdRef: ChangeDetectorRef,
    private readonly activationConfirmationModalService: ActivationConfirmationModalService,
  ) {}

  toggleTableIsShown() {
    this.isTableShown = !this.isTableShown;
  }

  activeIndexChange({ activeIndex }: Swiper) {
    this.activeSwiperIndex = activeIndex;
    // for some reason cd doesnt run on this swiper event, so trigger it manually
    this.cdRef.detectChanges();
  }

  openSubscriptionsModal(gelatoSubscriptionModalType) {
    this.subscriptionsModalOpen.emit(gelatoSubscriptionModalType);

    const eventName = this.subscriptionPageEnabledAB
      ? 'PlanComparisonTableOpenSubscriptionModalABtest'
      : 'PlanComparisonTableOpenSubscriptionModal';
    logEvent(eventName, { modalType: gelatoSubscriptionModalType });
  }

  onActivateSubscription(subscriptionType: SubscriptionPlanType) {
    this.activationConfirmationModalService.openConfirmationModal(subscriptionType);
  }

  onManagePlanClick() {
    logEvent('PlanComparisonTableManagePlanClick');
    this.managePlanModalOpen.emit();
  }

  getGroupTitle(group: PlansComparisonTableCell[][]): string {
    const row = group?.[0];
    const cell = row?.[0];

    return cell?.sectionTitle;
  }

  contactUsClick() {
    location.href = 'mailto:' + 'apisupport@gelato.com' + '?subject=' + 'Gold Subscription';
    logEvent('GoldPricePlanContactUs');
  }
}
