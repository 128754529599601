export const GarmentSizesSorted = [
  'newborn',
  '6m',
  '12m',
  '18m',
  '24m',
  '3XS',
  '2XS',
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  '2XL',
  'XXL',
  '3XL',
  '4XL',
];
