import { Action } from '@ngrx/store';
import { ClientSearchRequest } from '@gelato-api-ui/core/clients/client-search-request';
import { ClientListState, ClientState } from './clients.reducer';

export enum ClientsActionTypes {
  LoadUserListStart = '[Users] Load UserList Start',

  LoadClientListStart = '[Clients] Load ClientList Start',
  LoadClientStart = '[Clients] Load Client Start',

  ResetClientListState = '[Clients] Reset ClientList State',
  ResetClientListPayload = '[Clients] Reset ClientList Payload',
  ResetClientState = '[Clients] Reset Client State',

  SetClientListState = '[Clients] Set ClientList State',
  SetClientState = '[Clients] Set Client State',
}

export class LoadClientListStart implements Action {
  readonly type = ClientsActionTypes.LoadClientListStart;

  constructor(public searchRequest: ClientSearchRequest, public forced: boolean = false) {}
}

export class LoadClientStart implements Action {
  readonly type = ClientsActionTypes.LoadClientStart;

  constructor(public clientId: string, public forced: boolean = false) {}
}

export class ResetClientListState implements Action {
  readonly type = ClientsActionTypes.ResetClientListState;
}

export class ResetClientListPayload implements Action {
  readonly type = ClientsActionTypes.ResetClientListPayload;
}

export class ResetClientState implements Action {
  readonly type = ClientsActionTypes.ResetClientState;
}

export class SetClientListState implements Action {
  readonly type = ClientsActionTypes.SetClientListState;

  constructor(public payload: ClientListState) {}
}

export class SetClientState implements Action {
  readonly type = ClientsActionTypes.SetClientState;

  constructor(public payload: ClientState) {}
}

export type ClientsActions =
  | LoadClientListStart
  | LoadClientStart
  | ResetClientListState
  | ResetClientListPayload
  | ResetClientState
  | SetClientListState
  | SetClientState;
