import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EProductTreeItem } from '@api-ui-app/src/app/product/product-add/types/e-product-tree-item';
import { EProductVariantConnectionStatus } from '@gelato-api-ui/core/e-commerce/e-product-variant-connection-status.enum';
import { Injectable } from '@angular/core';

@Injectable()
export class ECommerceProductsTreeMappingService {
  getProductsTree(productsList: EProductAbbridged[], productVariantsList: EProductVariant[]): EProductTreeItem[] {
    return (productsList || []).map((loopProduct: EProductAbbridged): EProductTreeItem => {
      const variants: EProductVariant[] = (productVariantsList || []).filter(
        (loopProductVariant: EProductVariant): boolean => loopProductVariant.productId === loopProduct.id,
      );

      const summary = {
        [EProductVariantConnectionStatus.CREATED]: 0,
        [EProductVariantConnectionStatus.CONNECTED]: 0,
        [EProductVariantConnectionStatus.IGNORED]: 0,
        [EProductVariantConnectionStatus.NOT_CONNECTED]: 0,
      };

      variants.forEach((loopProductVariant: EProductVariant) => {
        if (!loopProductVariant.connectionStatus) {
          return;
        }

        summary[loopProductVariant.connectionStatus]++;
      });

      return {
        id: loopProduct.id,
        externalId: loopProduct.externalId,
        title: loopProduct.title,
        description: loopProduct.description,
        previewUrl: loopProduct.previewUrl,
        externalPreviewUrl: loopProduct.externalPreviewUrl,
        externalThumbnailUrl: loopProduct.externalThumbnailUrl,
        summary,
        variants,
      };
    });
  }
}
