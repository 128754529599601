import { AnalyticsGraphName } from './analytics-graph-name.enum';
import { AnalyticsGraphGroupType } from './analytics-graph-group-type.enum';

export class AnalyticsGraphRequest {
  constructor(
    public clientId: string,
    public graphName: AnalyticsGraphName,
    public startDate: string,
    public endDate: string,
    public groupBy: AnalyticsGraphGroupType = AnalyticsGraphGroupType.NONE,
  ) {}
}
