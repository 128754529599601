import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';

@Injectable({
  providedIn: 'root',
})
export class CompanyDetailsApiService {
  constructor(private apiRequestService: ApiRequestService) {}

  get(): Observable<CompanyDetails> {
    return this.apiRequestService.get<CompanyDetails>('/api/identity/v3/company').pipe(map((data: any) => data.data));
  }

  save(companyDetails: CompanyDetails): Observable<CompanyDetails> {
    return this.apiRequestService
      .put<CompanyDetails>('/api/identity/v3/company', {
        body: companyDetails,
      })
      .pipe(map((response: any) => response.data));
  }

  deleteCompany(companyName: string) {
    return this.apiRequestService
      .delete(`/api/identity/v3/company`, {
        headers: {
          'Content-Type': 'application/json',
        },
        body: {
          companyName,
        },
      })
      .pipe(map((response: any) => response.data));
  }
}
