import { GELATO_ADMIN_CLIENT_UID } from '../../lib/constants';

export const getDefaultClientIdFromKeycloakClientIds = (clientIds: string[]): string => {
  if (!clientIds || !clientIds.length) {
    return null;
  }

  const firstClientId = clientIds[0];

  return firstClientId === '*' ? GELATO_ADMIN_CLIENT_UID : firstClientId;
};
