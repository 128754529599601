import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';
import * as actions from './e-commerce-products-tree.actions';
import { EProductTreeItem } from '@api-ui-app/src/app/product/product-add/types/e-product-tree-item';

export const eCommerceProductsTreeFeatureKey = 'eCommerceProductsTree';

export interface State {
  isLoading: boolean;
  payload: EProductTreeItem[];
}

export const initialState: State = {
  isLoading: false,
  payload: null,
};

export function reducer(state = initialState, action: actions.ECommerceProductsTreeActions): State {
  switch (action.type) {
    case actions.ECommerceProductsTreeActionTypes.ResetState:
      return { ...initialState };
    case actions.ECommerceProductsTreeActionTypes.SetState:
      return action.payload;
    default:
      return state;
  }
}

export const eCommerceProductsTreeReducerToken = new InjectionToken<ActionReducerMap<State>>('products tree reducer');
