import * as md5 from 'md5';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';

export const calculateProductDraftHash = (product: EProductWithVariants): string => {
  try {
    const json = JSON.stringify(product);

    return md5(json);
  } catch (e) {
    return null;
  }
};
