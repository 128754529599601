import { ClientType } from './client-type.enum';
import { ClientStatus } from './client-status.enum';
import { BillingType } from './billing-type.enum';
import { ShippingPriceType } from './shipping-price-type.enum';

export class ClientUpdateRequest {
  constructor(
    public name: string,
    public description: string,
    public type: ClientType,
    public preferredCurrency: string,
    public billingType: BillingType,
    public shippingPriceType: ShippingPriceType,
    public shippingPriceEconomyCatalogUid: string,
    public shippingPriceExpressCatalogUid: string,
    public status: ClientStatus,
    public contactEmail: string = null,
    public billingEmail: string = null,
    public supportEmail: string = null,
  ) {}
}
