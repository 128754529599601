import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import examples from 'libphonenumber-js/examples.mobile.json';
import { CountryCode, getExampleNumber, parsePhoneNumber } from 'libphonenumber-js';

@Component({
  selector: 'gc-phone-field',
  templateUrl: './phone-field.component.html',
  styleUrls: ['./phone-field.component.scss'],
})
export class PhoneFieldComponent implements OnChanges, AfterViewInit {
  @Input() public phoneNumber: string;
  @Input() public countryCode: CountryCode;
  @Input() public placeholder: string;
  @Input() public height: string = null;
  @Input() public disabled = false;

  @Output() public phoneChange = new EventEmitter<string>();

  public customPlaceholder: string = null;

  ngAfterViewInit() {
    // Initial phone number format
    this.phoneNumber = this.phoneNumber ? this.formatPhoneNumber(this.phoneNumber, this.countryCode) : null;
    this.customPlaceholder = this.generateNumberPlaceholder(this.countryCode);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countryCode?.currentValue) {
      this.phoneNumber = this.phoneNumber
        ? this.formatPhoneNumber(this.phoneNumber, changes.countryCode.currentValue)
        : null;
      this.customPlaceholder = this.generateNumberPlaceholder(changes.countryCode.currentValue);
    }
  }

  public onPhoneChange(v: string): void {
    this.phoneChange.emit(v);
  }

  public onCountryChange(countryCode: CountryCode): void {
    this.customPlaceholder = this.generateNumberPlaceholder(countryCode);
  }

  private generateNumberPlaceholder(countryCode: CountryCode): string {
    const examplePhoneNumber = getExampleNumber(countryCode, examples);
    return examplePhoneNumber ? examplePhoneNumber.formatNational() : '';
  }

  private formatPhoneNumber(number: string, countryCode: CountryCode): string {
    const parsed = parsePhoneNumber(number, countryCode);
    return parsed ? parsed.formatNational() : '';
  }
}
