import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { ResaleCertificatesApiService } from '../resale-certificates/services/resale-certificates-api.service';
import { ResaleCertificate } from '../resale-certificates/lib/resale-certificate';
import { ResaleCertificateSearchResponse } from '../resale-certificates/lib/resale-certificate-search-response';
import * as actions from './resale-certificates.actions';
import { getListState, getState } from './resale-certificates.reducer';
import { findResaleCertificateInState } from './helpers/findResaleCertificateInState';
import { LoadClientsByIds } from '@api-ui-app/src/app/ngrx/client-selection-list.actions';

@Injectable()
export class ResaleCertificatesEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private resaleCertificatesApiService: ResaleCertificatesApiService,
    private authService: AuthService,
  ) {}

  loadList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadList>(actions.ResaleCertificatesActionTypes.LoadList),
        withLatestFrom(this.store$.select(getListState)),
        switchMap(([action, communicationSubscriptionsList]) => {
          const { searchRequest, forced } = action;

          const isDataRelevant =
            R.equals(searchRequest, communicationSubscriptionsList.request) && communicationSubscriptionsList.payload;

          let observable: Observable<ResaleCertificateSearchResponse> =
            this.resaleCertificatesApiService.search(searchRequest);

          if (!forced && isDataRelevant) {
            observable = of({ ...communicationSubscriptionsList.payload });
          }

          this.store$.dispatch(
            new actions.SetListState({
              isLoading: true,
              request: searchRequest,
              payload: isDataRelevant ? communicationSubscriptionsList.payload : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<ResaleCertificateSearchResponse> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: ResaleCertificateSearchResponse) => {
              this.store$.dispatch(
                new actions.SetListState({
                  isLoading: false,
                  request: searchRequest,
                  payload: response,
                }),
              );
              if (response && response.resaleCertificates) {
                const ids = response.resaleCertificates.map(_d => _d.clientId);
                this.store$.dispatch(new LoadClientsByIds(ids));
              }
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadEntity>(actions.ResaleCertificatesActionTypes.LoadEntity),
        withLatestFrom(this.store$.select(getState)),
        switchMap(([action, state]) => {
          const { id, forced } = action;
          const entityFromState: ResaleCertificate = findResaleCertificateInState(state, id);
          const isDataRelevant = Boolean(entityFromState);

          let observable: Observable<ResaleCertificate> = this.resaleCertificatesApiService.get(id);

          if (!forced && isDataRelevant) {
            observable = of({ ...entityFromState });
          }

          this.store$.dispatch(
            new actions.SetEntityState({
              isLoading: true,
              payload: isDataRelevant ? entityFromState : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<ResaleCertificate> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((entity: ResaleCertificate) => {
              this.store$.dispatch(
                new actions.SetEntityState({
                  isLoading: false,
                  payload: entity,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
