import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as R from 'ramda';
import { EProductTreeItem } from '@api-ui-app/src/app/product/product-add/types/e-product-tree-item';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { getProductThumbnailUrl } from '@gelato-api-ui/core/e-commerce/helpers/getProductThumbnailUrl';
import { EProductVariantConnectionStatus } from '@gelato-api-ui/core/e-commerce/e-product-variant-connection-status.enum';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';

const isCheckedPartially = (checkedProductVariantIds: string[], allProductVariantIds: string[]): boolean => {
  if (!checkedProductVariantIds || !allProductVariantIds || !checkedProductVariantIds.length) {
    return false;
  }

  return checkedProductVariantIds.length < allProductVariantIds.length;
};

const getProductVariantIdsFromProductTreeItem = (item: EProductTreeItem): string[] => {
  if (!item || !item.variants) {
    return [];
  }

  return R.map((loopProductVariant: EProductVariant): string => loopProductVariant.id, item.variants);
};

@Component({
  selector: 'gd-store-products-list-item',
  templateUrl: './store-products-list-item.component.html',
  styleUrls: ['./store-products-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreProductsListItemComponent implements OnInit, OnChanges {
  @Input() item: EProductTreeItem = null;
  @Input() storeType: EStoreType;
  @Input() checkedProductVariantIds: string[] = [];
  @Input() multipleSelectionEnabled: boolean;
  @Input() isSelectVariants = true;
  @Input() showExpandVariantsButton = true;
  @Output() checkedProductVariantIdsChange: EventEmitter<string[]> = new EventEmitter<string[]>();
  @Output() checked = new EventEmitter<boolean>();

  expandedVariants: boolean;
  isChecked = false;
  isCheckedPartially = false;

  get thumbnailUrl(): string {
    return getProductThumbnailUrl(this.item, this.storeType);
  }

  get variantsCount(): number {
    if (!this.item?.summary) {
      return null;
    }

    return this.item.summary[EProductVariantConnectionStatus.CONNECTED];
  }

  constructor() {}

  ngOnInit(): void {
    this.expandedVariants = this.isSelectVariants;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checkedProductVariantIds) {
      const checkedProductVariantIds = changes.checkedProductVariantIds.currentValue;

      this.isChecked = Boolean(checkedProductVariantIds) && Boolean(checkedProductVariantIds.length);

      this.isCheckedPartially = isCheckedPartially(
        checkedProductVariantIds,
        getProductVariantIdsFromProductTreeItem(this.item),
      );
    }
  }

  toggleProductVariant(productVariantId: string, isChecked: boolean) {
    if (this.multipleSelectionEnabled) {
      const updatedCheckedVariantIds = R.filter(
        (loopProductVariantId: string) => loopProductVariantId !== productVariantId,
        this.checkedProductVariantIds || [],
      );

      if (isChecked) {
        updatedCheckedVariantIds.push(productVariantId);
      }

      this.checkedProductVariantIdsChange.emit(updatedCheckedVariantIds);
    } else {
      this.checkedProductVariantIdsChange.emit(isChecked ? [productVariantId] : []);
    }
  }

  toggleProduct() {
    if (this.isSelectVariants) {
      if (this.multipleSelectionEnabled) {
        this.toggleAllProductVariants();
      }
    } else {
      this.isChecked = !this.isChecked;
      this.checked.emit(this.isChecked);
    }
  }

  toggleAllProductVariants() {
    const checkedProductVariantIds = this.checkedProductVariantIds || [];
    const allProductVariantIds = getProductVariantIdsFromProductTreeItem(this.item);
    const checkAll =
      isCheckedPartially(checkedProductVariantIds, allProductVariantIds) || !checkedProductVariantIds.length;
    const updatedCheckedVariantIds = checkAll ? allProductVariantIds : [];

    this.checkedProductVariantIdsChange.emit(updatedCheckedVariantIds);
  }

  toggleVariantsVisibility() {
    this.expandedVariants = !this.expandedVariants;
  }
}
