import { createAction, props } from '@ngrx/store';
import {
  PriceBreakdownApiRequest,
  PriceBreakdownData,
} from '@api-ui-app/src/app/shared/price-breakdown/types/price-breakdown';

export const loadPriceBreakdown = createAction(
  '[PriceBreakdown] Load PriceBreakdown',
  props<PriceBreakdownApiRequest>(),
);

export const resetPriceBreakdown = createAction('[PriceBreakdown] Reset PriceBreakdown');

export const loadPriceBreakdownComplete = createAction(
  '[PriceBreakdown] Load PriceBreakdown Complete',
  props<PriceBreakdownData>(),
);

export const loadPriceBreakdownError = createAction('[PriceBreakdown] Load PriceBreakdown Error', props<any>());
