import { ConnectedPosition, Overlay, OverlayConfig, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Directive, ElementRef, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { TooltipComponent } from './tooltip.component';
import { tooltipPositions, TooltipPositions, TooltipPositionsClass } from './tooltip.enum';
import { fromEvent, interval, merge, Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[gcTooltip]',
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('gcTooltip') tooltipTemplate: TemplateRef<HTMLElement>;
  @Input() gcTooltipText: string;
  @Input() gcTooltipPosition: TooltipPositions = TooltipPositions.top;
  @Input() gcTooltipDisabled: boolean;
  @Input() gcTooltipWidth: number | string = 240;
  @Input() gcTooltipTheme: 'light' | 'dark' | 'red' = 'light';
  @Input() gcTooltipAutoOpen = false;
  @Input() gcTooltipAutoOpenTimeout = 5000; // milliseconds
  @Input() gcTooltipOpenTimeout = 250; // milliseconds
  @Input() gcTooltipShowArrow = true;
  @Input() gcTooltipTriggerOpenEvent = 'mouseenter'; // focus || mouseover etc.
  @Input() gcTooltipTriggerDestroyEvent = 'mouseleave'; // focusout || mouseleave etc.
  @Input() gcTooltipShowDebounceTime = 0;
  @Input() gcTooltipDestroyDebounceTime = 250;
  @Input() gcTooltipManualDestroy = false;
  @Input() gcTooltipDestroyOn: Observable<void>;

  destroyEvents = ['focusout', 'mouseleave', 'manual-destroy'];
  overlayRef: OverlayRef;
  tooltipRef: ComponentRef<TooltipComponent>;
  autoOpenStart$: Observable<number> = new Subject();
  autoOpenComplete$: Observable<MouseEvent> = new Subject();

  private manualDestroy$ = new Subject();
  private ngDestroy$ = new Subject();
  private readonly tooltipPortal: ComponentPortal<TooltipComponent> = new ComponentPortal(TooltipComponent);

  constructor(
    private readonly overlay: Overlay,
    private readonly overlayPositionBuilder: OverlayPositionBuilder,
    private readonly elementRef: ElementRef,
  ) {}

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();

    this.destroyOverlay();
  }

  ngOnInit(): void {
    this.gcTooltipDestroyOn?.pipe(takeUntil(this.ngDestroy$)).subscribe(() => {
      this.manualDestroy$.next({ type: 'manual-destroy' });
    });
    if (this.gcTooltipAutoOpen) {
      this.autoOpenStart$ = interval(this.gcTooltipOpenTimeout).pipe(take(1));
      this.autoOpenComplete$ = interval(this.gcTooltipAutoOpenTimeout).pipe(
        take(1),
        map(() => ({ type: 'mouseleave' } as MouseEvent)),
      );
    }

    merge(fromEvent(this.elementRef.nativeElement, this.gcTooltipTriggerOpenEvent), this.autoOpenStart$)
      .pipe(
        debounceTime(this.gcTooltipShowDebounceTime),
        filter(() => !this.gcTooltipDisabled),
        switchMap(() => {
          if (this.overlayRef) {
            this.destroyOverlay();
          }

          this.overlayRef = this.createOverlay();
          this.tooltipRef = this.overlayRef.attach(this.tooltipPortal);
          this.tooltipRef.instance.tooltipTemplate = this.tooltipTemplate;
          this.tooltipRef.instance.tooltipText = this.gcTooltipText;
          this.tooltipRef.instance.arrowPosition = this.getArrowPositionClass();
          this.tooltipRef.instance.theme = this.gcTooltipTheme;
          this.tooltipRef.instance.showArrow = this.gcTooltipShowArrow;

          const leave$ = fromEvent(
            [this.elementRef.nativeElement, this.overlayRef.overlayElement],
            this.gcTooltipTriggerDestroyEvent,
          ).pipe(filter(() => !this.gcTooltipManualDestroy));

          const enterOverlay$ = fromEvent(this.overlayRef.overlayElement, 'mouseenter');

          return merge(leave$, enterOverlay$, this.autoOpenComplete$, this.manualDestroy$).pipe(
            debounceTime(this.gcTooltipDestroyDebounceTime),
            filter((ev: MouseEvent) => this.destroyEvents.indexOf(ev.type) > -1),
            tap(() => this.destroyOverlay()),
          );
        }),
        takeUntil(this.ngDestroy$),
      )
      .subscribe();
  }

  private createOverlay() {
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .setOrigin(this.elementRef)
      .withTransformOriginOn('.tooltip-icon')
      .withFlexibleDimensions(true)
      .withPush(false)
      .withPositions(this.getOverlayPosition());

    const conf: OverlayConfig = {
      positionStrategy: strategy,
      panelClass: 'gc-tooltip',
    };

    if (this.gcTooltipWidth) {
      conf.width = this.gcTooltipWidth;
    }
    return this.overlay.create(conf);
  }

  private destroyOverlay() {
    if (this.overlayRef) {
      this.overlayRef.detach();
      this.overlayRef.dispose();
    }
  }

  private getArrowPositionClass(): string {
    return TooltipPositionsClass[this.gcTooltipPosition !== null ? this.gcTooltipPosition : TooltipPositions.right];
  }

  private getOverlayPosition(): ConnectedPosition[] {
    return tooltipPositions[this.gcTooltipPosition !== null ? this.gcTooltipPosition : TooltipPositions.right];
  }
}
