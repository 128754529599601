import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, HostBinding, TemplateRef } from '@angular/core';

@Component({
  selector: 'gc-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('tooltip', [
      transition(':enter', [style({ opacity: 0 }), animate(200, style({ opacity: 1 }))]),
      transition(':leave', [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class TooltipComponent {
  @HostBinding('@tooltip') anim = true;

  tooltipTemplate: TemplateRef<HTMLElement>;
  tooltipText: string;
  arrowPosition = '';
  theme = '';
  showArrow = false;

  @HostBinding('class')
  get hostClassName() {
    return `${this.arrowPosition} ${this.theme}`;
  }

  constructor() {}
}
