import { GrowChecklistStepConfig } from '../types/grow-checklist';
import { GrowChecklistStepState } from '../types/grow-checklist-step-state';
import { GrowChecklistStep } from '../types/grow-checklist-steps';

export const starterGrowChecklist: GrowChecklistStepConfig[] = [
  {
    id: GrowChecklistStep.StoreLink,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_store_link_title',
  },
  {
    id: GrowChecklistStep.ExpandWithMoreProducts,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_expand_with_more_products_title',
  },
  {
    id: GrowChecklistStep.UpgradeMockups,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_upgrade_mockups_title',
  },
  {
    id: GrowChecklistStep.RunStoreFromPhone,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_run_store_from_phone_title',
  },
  {
    id: GrowChecklistStep.SellDesignsOnMoreProductCategories,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_sell_on_more_product_categories_title',
  },
];

export const switcherGrowChecklist: GrowChecklistStepConfig[] = [
  {
    id: GrowChecklistStep.ExpandWithMoreProducts,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_expand_with_more_products_title',
  },
  {
    id: GrowChecklistStep.UpgradeMockups,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_upgrade_mockups_title',
  },
  {
    id: GrowChecklistStep.RunStoreFromPhone,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_run_store_from_phone_title',
  },
  {
    id: GrowChecklistStep.SellDesignsOnMoreProductCategories,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_sell_on_more_product_categories_title',
  },
  {
    id: GrowChecklistStep.AddSalesChannel,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_add_sales_channel_title',
  },
  {
    id: GrowChecklistStep.TargetCustomersInMoreCountries,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_target_customers_in_more_countries_title',
  },
  {
    id: GrowChecklistStep.OptimizePricing,
    state: GrowChecklistStepState.Number,
    label: 'txt_growth_checklist_optimize_pricing_title',
  },
];
