import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as R from 'ramda';
import { CountryListService } from '@gelato-api-ui/sdk/src/lib/shared/country-selector/country-list.service';

@Component({
  selector: 'gd-country-multiselect',
  templateUrl: './country-multiselect.component.html',
  styleUrls: ['./country-multiselect.component.scss'],
})
export class CountryMultiselectComponent implements OnChanges {
  @Input() disabled = false;
  @Input() values: string[];
  @Input() isSearchable = true;
  @Input() countriesToShow: string[] = null;
  @Output() valuesChange: EventEmitter<string[]> = new EventEmitter();

  countriesToShow$ = new BehaviorSubject<string[]>(null);

  options$ = combineLatest([this.countryListService.getOptions(), this.countriesToShow$]).pipe(
    map(([options, countriesToShow]) => {
      const filteredOptions = Boolean(countriesToShow)
        ? R.filter(option => countriesToShow.indexOf(option.id) !== -1, options)
        : options;

      return filteredOptions;
    }),
  );

  constructor(private countryListService: CountryListService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countriesToShow) {
      this.countriesToShow$.next(changes.countriesToShow.currentValue);
    }
  }

  optionsFilter(options, query) {
    return R.filter((option: any) => R.startsWith(R.toLower(query), R.toLower(option.name)), options);
  }

  onSelect(countryId) {
    this.valuesChange.emit(countryId);
  }

  preventFormSubmit(event) {
    if (event.code === 'Enter') {
      event.preventDefault();
    }
  }
}
