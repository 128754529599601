import { NgModule } from '@angular/core';
import { PreviewsArchiveLoadingMessageComponent } from '@api-ui-app/src/app/previews-archive/components/previews-archive-loading-message/previews-archive-loading-message.component';
import { PreviewsArchiveFacade } from '@api-ui-app/src/app/previews-archive/+state/previews-archive.facade';
import { SharedModule } from '@api-ui-app/src/app/shared/shared.module';
import { PreviewsArchiveService } from '@api-ui-app/src/app/previews-archive/+state/previews-archive.service';
import { UiKitModule } from '@gelato-api-ui/ui-kit/src';
import { PreviewsArchiveRequestService } from '@api-ui-app/src/app/previews-archive/services/previews-archive-request.service';

@NgModule({
  declarations: [PreviewsArchiveLoadingMessageComponent],
  exports: [PreviewsArchiveLoadingMessageComponent],
  providers: [PreviewsArchiveFacade, PreviewsArchiveService, PreviewsArchiveRequestService],
  imports: [SharedModule, UiKitModule],
})
export class PreviewsArchiveModule {}
