import { Action, createAction, props, union } from '@ngrx/store';
import { InitialProductVariantConnectionData } from '@gelato-api-ui/core/e-commerce/initial-product-variant-connection-data';
import { ProductVariantConnectionData } from '@gelato-api-ui/core/e-commerce/product-variant-connection-data';
import { ProductsListState, ProductState, PublishingState } from './e-commerce-products.reducer';
import { EProductCollection } from '@gelato-api-ui/core/e-commerce/e-product-collection';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { EProductData } from '@gelato-api-ui/core/e-commerce/e-product-data';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { EProductsListTabName } from '@api-ui-app/src/app/e-commerce-stores/lib/e-products-list-tab-name.enum';
import { EProductPublishMode } from '@api-ui-app/src/app/e-commerce-stores/types/e-product-publish-mode.enum';

export enum ECommerceProductsActionTypes {
  LoadProductsList = '[ECommerceProducts] Load ProductsList',
  LoadProduct = '[ECommerceProducts] Load Product',
  ConnectProductVariant = '[ECommerceProducts] Connect ProductVariant',

  ResetProductsListState = '[ECommerceProducts] Reset ProductsList State',
  ResetProductsListPayload = '[ECommerceProducts] Reset ProductsList Payload',
  ResetProductState = '[ECommerceProducts] Reset Product State',

  SetProductsListState = '[ECommerceProducts] Set ProductsList State',
  SetProductState = '[ECommerceProducts] Set Product State',
  SetInitialProductVariantConnectionData = '[ECommerceProducts] Set InitialProductVariantConnectionData',
}

export class LoadProductsList implements Action {
  readonly type = ECommerceProductsActionTypes.LoadProductsList;

  constructor(
    public storeId: string,
    public title: string,
    public tabName: EProductsListTabName,
    public clearList: boolean = true,
  ) {}
}

export class LoadProduct implements Action {
  readonly type = ECommerceProductsActionTypes.LoadProduct;

  constructor(public id: string, public forced: boolean = false) {}
}

export class ConnectProductVariant implements Action {
  readonly type = ECommerceProductsActionTypes.ConnectProductVariant;

  constructor(public payload: ProductVariantConnectionData) {}
}

export class ResetProductsListState implements Action {
  readonly type = ECommerceProductsActionTypes.ResetProductsListState;
}

export class ResetProductsListPayload implements Action {
  readonly type = ECommerceProductsActionTypes.ResetProductsListPayload;
}

export class ResetProductState implements Action {
  readonly type = ECommerceProductsActionTypes.ResetProductState;
}

export class SetProductsListState implements Action {
  readonly type = ECommerceProductsActionTypes.SetProductsListState;

  constructor(public payload: ProductsListState) {}
}

export class SetProductState implements Action {
  readonly type = ECommerceProductsActionTypes.SetProductState;

  constructor(public payload: ProductState) {}
}

export class SetInitialProductVariantConnectionData implements Action {
  readonly type = ECommerceProductsActionTypes.SetInitialProductVariantConnectionData;

  constructor(public payload: InitialProductVariantConnectionData) {}
}

export const initiatePublishingProcessMonitoring = createAction(
  '[ECommerceProducts] Initiate Publishing Process Monitoring',
  props<{ statuses: string[] }>(),
);

export const cancelPublishingProcessMonitoring = createAction(
  '[ECommerceProducts] Cancel Publishing Process Monitoring',
);

export const loadProductTags = createAction('[ECommerceProducts] Load ProductTags', props<{ storeId: string }>());

export const loadProductTagsSuccess = createAction(
  '[ECommerceProducts] Load ProductTags Success',
  props<{ storeId: string; tags: { [id: number]: string } }>(),
);

export const loadProductCollections = createAction(
  '[ECommerceProducts] Load ProductCollections',
  props<{ storeId: string }>(),
);

export const loadProductCollectionsSuccess = createAction(
  '[ECommerceProducts] Load ProductCollections Success',
  props<{ storeId: string; collections: EProductCollection[] }>(),
);

export const setPublishingProduct = createAction(
  '[ECommerceProducts] Set Publishing Product',
  props<{ product: EProductWithVariants }>(),
);

export const addPublishingProducts = createAction(
  '[ECommerceProducts] Add Publishing Products',
  props<{ products: Partial<EProductAbbridged>[] }>(),
);

export const updatePublishingProduct = createAction(
  '[ECommerceProducts] Update Publishing Product',
  props<{ product: Partial<EProductAbbridged>; forcePreviewUpdate?: boolean }>(),
);

export const removePublishingProducts = createAction(
  '[ECommerceProducts] Remove Publishing Product',
  props<{ productIds: string[] }>(),
);

export const saveProduct = createAction('[ECommerceProducts] Save Product');
export const expandProduct = createAction(
  '[ECommerceProducts] Expand Product',
  props<{
    productData: EProductData;
    mode: EProductWizardMode;
    publishWithFreeShipping?: boolean;
    publishImmediately?: boolean;
  }>(),
);
export const saveProductStart = createAction('[ECommerceProducts] Save Product Start');
export const saveProductStatelessStart = createAction(
  '[ECommerceProducts] Save Product Stateless Start',
  props<{
    productData: EProductData;
    mode: EProductWizardMode;
    publishWithFreeShipping?: boolean;
    publishImmediately?: boolean;
    publishMode?: EProductPublishMode;
  }>(),
);

export const duplicateProduct = createAction(
  '[ECommerceProducts] Duplicate Product',
  props<{ productId: string; sourceStoreId: string; destinationStoreId: string; title: string }>(),
);
export const duplicateProductSuccess = createAction(
  '[ECommerceProducts] Duplicate Product Success',
  props<{ product: EProductAbbridged }>(),
);

export const setPublishingState = createAction('[ECommerceProducts] Set Publishing State', props<PublishingState>());

export const clearCanDuplicateProductAsDraftFlags = createAction(
  '[ECommerceProducts] Clear Can Duplicate Product As Draft Flags',
);
export const fetchProductDuplicationAvailabilityFlag = createAction(
  '[ECommerceProducts] Fetch Product Duplication Availability Flag',
  props<{ productId: string }>(),
);
export const setProductDuplicationAvailabilityFlag = createAction(
  '[ECommerceProducts] Set Product Duplication Availability Flag',
  props<{ productId: string; payload: boolean }>(),
);

const actions = union({
  initiatePublishingProcessMonitoring,
  cancelPublishingProcessMonitoring,
  loadProductTags,
  loadProductTagsSuccess,
  loadProductCollections,
  loadProductCollectionsSuccess,
  setPublishingProduct,
  addPublishingProducts,
  updatePublishingProduct,
  removePublishingProducts,
  setPublishingState,
  saveProduct,
  expandProduct,
  saveProductStart,
  saveProductStatelessStart,
  duplicateProduct,
  clearCanDuplicateProductAsDraftFlags,
  fetchProductDuplicationAvailabilityFlag,
  setProductDuplicationAvailabilityFlag,
});

export type ECommerceProductsActions =
  | typeof actions
  | LoadProductsList
  | LoadProduct
  | ResetProductsListState
  | ResetProductsListPayload
  | ResetProductState
  | SetProductsListState
  | SetProductState
  | SetInitialProductVariantConnectionData;
