import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { SanityProductCategoryName } from '../sanity-product-category-name.enum';

const mirroringTabCategories = new Set([SanityProductCategoryName.CANVAS]);
function isMirroringSupported(category: string | SanityProductCategoryName) {
  return mirroringTabCategories.has(category as SanityProductCategoryName);
}

const mirroringTabType = new Set([ProductTypeUid.CANVAS]);
export function isMirroringSupportedByProductTypeUid(typeUid: ProductTypeUid) {
  return mirroringTabType.has(typeUid);
}

export default isMirroringSupported;
