import { SanityProductControlOption } from './sanity-product-control-option';

export interface SanityProductControl {
  key: SanityProductControlKey;
  title: string;
  options: SanityProductControlOption[];
}

export enum SanityProductControlKey {
  formatSize = 'formatsize',
  wallpaperMaterial = 'wallpaperMaterial',
  wallpaperType = 'wallpaperType',
  garmentColor = 'garmentcolor',
  garmentSize = 'garmentsize',
  garmentPrint = 'garmentprint',
  paperFormat = 'formatsize',
  orientation = 'orientation',
  frameColor = 'framecolor',
  frameAssembling = 'frameassembling',
  wallHangerColor = 'wallhangercolor',
  coatingType = 'coatingtype',
  mugMaterial = 'mugmaterial',
  foamColor = 'foamcolor',
  foamThickness = 'foamthickness',
  woodThickness = 'woodthickness',
  paperType = 'papertype',
  bagPrint = 'bagprint',
  bagColor = 'bagcolor',
  bagSize = 'bagsize',
  phoneBrand = 'phonebrand',
  phoneModel = 'phonemodel',
  coatingSides = 'coatingsides',
  colorType = 'colortype',
  bindingType = 'bindingtype',
}
