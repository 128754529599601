import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalService } from '@gelato-api-ui/sdk/src/lib/shared/services/confirm-modal.service';

@Injectable()
export class PageLeaveConfirmationModalService {
  constructor(
    private readonly translate: TranslateService,
    private readonly confirmModalService: ConfirmModalService,
  ) {}

  show() {
    const body = this.translate.instant('txt_page_leave_confirmation_text');
    const title = this.translate.instant('txt_page_leave_confirmation_title');
    const approveButtonText = this.translate.instant('txt_page_leave_confirmation_option_quit');
    const denyButtonText = this.translate.instant('txt_page_leave_confirmation_option_cancel');

    return this.confirmModalService.open({ body, title, approveButtonText, denyButtonText, isClosable: true });
  }
}
