import { BulkConnectVariantsMappingPayload } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';

export const mergeBulkConnectProductVariantsMappings = (
  currentMapping: BulkConnectVariantsMappingPayload,
  defaultMapping: BulkConnectVariantsMappingPayload,
): BulkConnectVariantsMappingPayload =>
  [...Object.keys(defaultMapping), ...Object.keys(currentMapping)].reduce(
    (acc: BulkConnectVariantsMappingPayload, loopProductVariantId: string): BulkConnectVariantsMappingPayload => ({
      ...acc,
      [loopProductVariantId]: currentMapping[loopProductVariantId] || defaultMapping[loopProductVariantId],
    }),
    {},
  );
