import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export const ATTENTION_ORDERS_DISMISSED_KEY = 'attentionOrdersDismissed';
export const ATTENTION_NEEDED_ORDERS_COUNT_KEY = 'attentionNeededOrdersCount';

@Injectable({
  providedIn: 'root',
})
export class OrdersAttentionListService {
  ordersWidgetDismissed$ = new BehaviorSubject<boolean>(this.getIsOrdersWidgetDismissed());

  setAttentionNeededOrdersWidgetDismissed() {
    localStorage.setItem(ATTENTION_ORDERS_DISMISSED_KEY, '1');
    this.ordersWidgetDismissed$.next(this.getIsOrdersWidgetDismissed());
  }

  handleAttentionNeededOrdersCount(attentionNeededOrdersCount: number) {
    const currentAttentionNeededOrdersCount = Number(localStorage.getItem(ATTENTION_NEEDED_ORDERS_COUNT_KEY)) ?? 0;

    if (attentionNeededOrdersCount > currentAttentionNeededOrdersCount) {
      localStorage.removeItem(ATTENTION_ORDERS_DISMISSED_KEY);
      localStorage.setItem(ATTENTION_NEEDED_ORDERS_COUNT_KEY, String(attentionNeededOrdersCount));
    }
  }

  private getIsOrdersWidgetDismissed(): boolean {
    return !!localStorage.getItem(ATTENTION_ORDERS_DISMISSED_KEY);
  }
}
