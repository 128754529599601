<ng-template #input>
  <input
    placeholder="{{ placeholder }}"
    [ngModel]="value"
    (ngModelChange)="onValueChange($event)"
    (blur)="onBlur()"
    type="number"
    min="1"
    max="999999"
    [disabled]="disabled"
    (focusout)="onFocusOut()"
  />
</ng-template>

<div class="ui input fluid" *ngIf="!unitLabel">
  <ng-container *ngTemplateOutlet="input"></ng-container>
</div>

<div class="ui right labeled input fluid" [class.disabled]="disabled" *ngIf="unitLabel">
  <ng-container *ngTemplateOutlet="input"></ng-container>

  <div class="ui label">
    {{ unitLabel }}
  </div>
</div>
