export function getColorBackground(hex: string): string {
  let background = hex;
  if (!hex) {
    background = `linear-gradient(135deg, rgba(51, 51, 51, 0) 16.67%, rgba(51, 51, 51, 0) 48.27%, rgba(51, 51, 51, 0.1) 48.34%, rgba(51, 51, 51, 0.1) 52.68%, rgba(51, 51, 51, 0) 52.73%, rgba(51, 51, 51, 0) 85.42%)`;
  } else {
    const colors = hex.split(';');
    if (colors.length === 2) {
      background = `linear-gradient(90deg, ${colors[0]} 50%, ${colors[1]} 50%)`;
    }
  }
  return background;
}
