import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { fetchFail, fetchStart, fetchSuccess } from './wallet-transactions.actions';
import { of } from 'rxjs';
import { WalletTransactionsFacade } from './wallet-transactions.facade';

@Injectable()
export class WalletTransactionsEffects {
  fetchStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchStart),
      withLatestFrom(this.facade.filter$),
      switchMap(([action, criteria]) =>
        this.facade.search(action.walletUid, criteria, action.clientId).pipe(
          map(res =>
            fetchSuccess({
              list: res.transactions,
              filter: { limit: res.limit, total: res.total },
              clientId: action.clientId,
            }),
          ),
          catchError(err => of(fetchFail({ err, clientId: action.clientId }))),
        ),
      ),
    ),
  );

  constructor(private readonly actions$: Actions, private readonly facade: WalletTransactionsFacade) {}
}
