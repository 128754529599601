import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Pipe({
  name: 'financialOrderStatus',
})
export class FinancialOrderStatusPipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(value: string): Observable<string> {
    return this.translateService.get(`txt_financial_order_status_${value}`);
  }
}
