<div
  *ngIf="show$ | async"
  class="message"
  [class.collapsed]="collapsed$ | async"
  [class.top-position]="isMessageTopPosition$ | async"
>
  <div class="header">
    <span>
      {{ 'txt_download_tasks_title' | translate }}
      <gc-animated-dots></gc-animated-dots>
    </span>
    <div class="toggle-icon" (click)="toggleListView()">
      <img [src]="'sdk/chevron-right.svg' | assetsPath | async" alt="" height="10" width="10" />
    </div>
  </div>
  <div class="tasks">
    <ng-container *ngFor="let taskId of taskIds$ | async; let i = index">
      <ng-container
        [ngTemplateOutlet]="task"
        [ngTemplateOutletContext]="{ name: getTaskName(i) | async, id: taskId }"
      ></ng-container
    ></ng-container>
  </div>
</div>

<ng-template #task let-name="name" let-id="id">
  <div class="task" *ngIf="id">
    <span class="loading-name">
      <div class="loader-container">
        <div class="ui loader visible tiny"></div>
      </div>
      <span>{{ name }}</span>
    </span>
    <div class="close-icon" (click)="cancelDownload(id)">
      <img [src]="'sdk/close.svg' | assetsPath | async" alt="" height="10" width="10" />
    </div>
  </div>
</ng-template>
