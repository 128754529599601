<ng-container *ngFor="let item of items">
  <gd-store-products-list-item
    [item]="item"
    [storeType]="storeType"
    [multipleSelectionEnabled]="multipleSelectionEnabled"
    [isSelectVariants]="isSelectVariants"
    [checkedProductVariantIds]="checkedProductVariantIds[item?.id]"
    (checkedProductVariantIdsChange)="onCheckedProductVariantIdsChange(item?.id, $event)"
    (checked)="onCheckedProduct(item?.id, $event)"
  >
  </gd-store-products-list-item>
</ng-container>
