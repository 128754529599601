import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { caller } from '@gelato-api-ui/core/api/caller';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { SanityProductResponse } from '@gelato-api-ui/core/sanity/sanity-product-response';
import { SanityRelatedProducts } from '@gelato-api-ui/core/sanity/SanityRelatedProducts';
import { SanityProductCategoryResponse } from '@gelato-api-ui/core/sanity/sanity-product-category-response';
import {
  SanityHomePageContent,
  SanityProductCommunicationBannersItem,
} from '@gelato-api-ui/core/sanity/sanity-home-page-content';
import { SANITY_BASE_URL } from '@gelato-api-ui/core/sanity/sanity-base-url-token';
import { SanityDesignCategory } from '@gelato-api-ui/core/sanity/sanity-design-category';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import { SanityGQLResponse } from '@gelato-api-ui/core/sanity/types/sanity-gql-response';
import { AppDetails } from '@gelato-api-ui/core/app-store/app-details';
import * as gql from 'gql-query-builder';
import { SanityAppsListPage } from '@api-ui-app/src/app/subscriptions/types/sanity-apps-list-page';
import { SanitySubscriptionPlan } from '@gelato-api-ui/core/app-store/sanity-subscription-plan';
import { ProductTypeGalleryCollections } from '@gelato-api-ui/core/gallery/product-type-gallery-collections';
import VariableOptions from 'gql-query-builder/build/VariableOptions';
import { GettyFiltersResponse } from '@gelato-api-ui/core/apps-and-services/types/getty-filters-response';
import {
  ProfitCalculator,
  ProfitCalculatorGraphQLPlatform,
  ProfitCalculatorGraphQLSubscriptionPlans,
} from '@api-ui-app/src/app/profit-calculator/types/profit-calculator';
import { SanityHelloBar } from '../types/sanity-hello-bar';

@Injectable({
  providedIn: 'root',
})
export class SanityApiService {
  get: (url: string, options?: AjaxRequest) => Observable<any>;

  constructor(
    @Inject(SANITY_BASE_URL) private baseUrl: string,
    private readonly graphQlRequestService: GraphQlRequestService,
  ) {
    this.get = caller(baseUrl, 'GET');
  }

  getData(locale: LocaleCode): Observable<SanityProductCategoryResponse[]> {
    return this.query(locale, 'catalog').pipe(map(data => data.catalog));
  }

  getProduct(
    locale: LocaleCode,
    productName: string,
    productNameUid?: string,
    productUid?: string,
  ): Observable<SanityProductResponse> {
    const priceListVariables: VariableOptions = {
      productId: { type: '[String!]!', value: productName },
      productNameUid: { type: 'String', value: productNameUid },
      productUid: { type: 'String', value: productUid },
    };

    return this.query(locale, 'product', priceListVariables).pipe(map(data => data.product));
  }

  getRelatedProducts(locale: LocaleCode): Observable<SanityRelatedProducts[]> {
    return this.query(locale, 'related_products').pipe(map(data => data.related_products));
  }

  getHomePageContent(locale: LocaleCode): Observable<SanityHomePageContent> {
    return this.query(locale, 'home_page').pipe(map(data => data.home_page));
  }

  getProductCommunicationBanners(locale: LocaleCode): Observable<SanityProductCommunicationBannersItem[]> {
    return this.query(locale, 'product_communication_banners').pipe(map(data => data.product_communication_banners));
  }

  getGelatoPlusPageContent(locale: LocaleCode): Observable<SanityAppsListPage> {
    return this.query(locale, 'gelato_plus_page').pipe(map(({ gelato_plus_page }) => gelato_plus_page));
  }

  getAppsListContent(locale: LocaleCode): Observable<AppDetails[]> {
    return this.query(locale, 'apps_list').pipe(map(data => data.apps_list));
  }

  getProductTypesCollections(locale: LocaleCode): Observable<ProductTypeGalleryCollections[]> {
    return this.query(locale, 'product_types_gallery_collections').pipe(
      map(data => data.product_types_gallery_collections),
    );
  }

  getAppAndServices(locale: LocaleCode): Observable<GettyFiltersResponse> {
    return this.query(locale, 'apps_and_services').pipe(map(data => data.apps_and_services));
  }

  getAppDetailsContent(
    variables: { uid?: string; serviceUniqueName?: string },
    locale: LocaleCode,
  ): Observable<AppDetails> {
    const vars = {};
    Object.keys(variables).forEach(key => (vars[key] = { type: 'String', value: variables[key] }));
    return this.query(locale, 'apps_list', vars).pipe(map(data => data?.apps_list[0]));
  }

  getDesignCategories(productTypeUid: ProductTypeUid, locale: string): Observable<SanityDesignCategory[]> {
    if (!productTypeUid || !locale) {
      return of(null);
    }

    return this.query(
      locale as LocaleCode,
      `design_categories(productTypeUid: "${productTypeUid}")`,
      'design_categories',
    ).pipe(map(data => data.design_categories));
  }

  getSubscriptionsList(locale: string, uniqueNames: string[]): Observable<SanitySubscriptionPlan[]> {
    const vars = {
      uniqueNames: { type: '[String!]!', value: uniqueNames },
    };
    return this.query(locale as LocaleCode, 'subscription_plans', vars).pipe(map(data => data.subscription_plans));
  }

  getProfitCalculator(
    locale: string,
  ): Observable<Array<ProfitCalculator | ProfitCalculatorGraphQLPlatform | ProfitCalculatorGraphQLSubscriptionPlans>> {
    return this.query(locale as LocaleCode, 'profit_calculator').pipe(map(data => data.profit_calculator));
  }

  getHelloBar(locale: string): Observable<SanityHelloBar> {
    return this.query(locale as LocaleCode, 'hello_bar').pipe(map(data => data.hello_bar));
  }

  private query(locale: LocaleCode, operation: string, variables = {}): Observable<SanityGQLResponse> {
    const query = gql.query({
      operation: 'sanityContent',
      variables: { lang: { type: 'String!', value: locale } },
      fields: [
        {
          operation,
          fields: [],
          variables,
        },
      ],
    });
    return this.graphQlRequestService
      .query<{ sanityContent: SanityGQLResponse }>(query, `sanityContent:${operation}`)
      .pipe(map(data => data.data.sanityContent));
  }
}
