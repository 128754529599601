import { AnimationEvent } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DropdownAnimation } from '@gelato-api-ui/ui-kit/src/lib/dropdown/animations';
import {
  DropdownAnimationType,
  DropdownContentType,
  DropdownRenderType,
} from '@gelato-api-ui/ui-kit/src/lib/dropdown/types';
import { DropdownRef } from '@gelato-api-ui/ui-kit/src/lib/dropdown/components/dropdown-ref/dropdown-ref';

@Component({
  selector: 'gc-dropdown',
  templateUrl: './dropdown-ref.component.html',
  styleUrls: ['./dropdown-ref.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [DropdownAnimation()],
})
export class DropdownRefComponent implements OnInit, OnDestroy {
  public renderType: DropdownRenderType = 'template';
  public content: DropdownContentType;
  private readonly ngDestroy$ = new Subject<boolean>();
  private readonly animComplete$ = new BehaviorSubject<boolean>(false);

  @HostBinding('@dropdown') public state: DropdownAnimationType = 'close';

  @HostListener('@dropdown.done', ['$event']) public doneAnimHandler(event: AnimationEvent): void {
    if (event && event.fromState === 'open' && event.toState === 'close') {
      this.animComplete$.next(true);
      this.animComplete$.complete();
    }
  }

  constructor(private readonly dropdownRef: DropdownRef) {
    combineLatest([this.dropdownRef.close$, this.animComplete$])
      .pipe(
        tap(([_, __]) => (this.state = 'close')),
        filter(([_, isAnimComplete]) => !!isAnimComplete),
        takeUntil(this.ngDestroy$),
      )
      .subscribe(() => this.dropdownRef.close());
  }

  public ngOnInit(): void {
    this.state = 'open';
    this.content = this.dropdownRef.content;

    if (this.content instanceof TemplateRef) {
      this.renderType = 'template';
    }
  }

  public ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
