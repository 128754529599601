import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getUser, getUserEmail, getUserId, getUserName } from '../../ngrx/auth.reducer';
import { getCompanyName } from '../../ngrx/company-details.reducer';
import { map, switchMap } from 'rxjs/operators';
import { DropdownRef } from '@gelato-api-ui/ui-kit/src/lib/dropdown/components/dropdown-ref/dropdown-ref';
import { DeckRef } from '@gelato-api-ui/ui-kit/src/lib/deck/components/deck-ref/deck-ref';
import { DropdownService } from '@gelato-api-ui/ui-kit/src/lib/dropdown/services';
import { DeckService } from '@gelato-api-ui/ui-kit/src/lib/deck/services';
import { DropdownContentType } from '@gelato-api-ui/ui-kit/src/lib/dropdown/types';
import { DeckContentType } from '@gelato-api-ui/ui-kit/src/lib/deck/types';
import { combineLatest, Observable } from 'rxjs';
import { UserNameService } from '@api-ui-app/src/app/shared/services/user-name.service';

@Component({
  selector: 'gd-active-client-info',
  templateUrl: './active-client-info.component.html',
  styleUrls: ['./active-client-info.component.scss'],
})
export class ActiveClientInfoComponent {
  @Input() isMobile = false;
  @Input() userWrapperClass: string = null;
  @Input() userInitialsBackgroundLoading: boolean;

  user$ = this.store.select(getUser);
  userName$ = this.store.select(getUserName);
  userId$ = this.store.select(getUserId);
  userEmail$ = this.store.select(getUserEmail);
  companyName$ = this.store.select(getCompanyName);
  shortName$ = combineLatest([this.user$, this.companyName$]).pipe(
    switchMap(([user, companyName]): Observable<string> => {
      const fallback = companyName?.trim()?.charAt(0);
      return this.userNameService.getUserShortName$(user).pipe(map(userShortName => userShortName || fallback));
    }),
  );

  private dropdownRef: DropdownRef;
  private deckRef: DeckRef;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dropdown: DropdownService,
    private readonly deck: DeckService,
    private readonly userNameService: UserNameService,
  ) {}

  onOpenUserInformation(content: DropdownContentType, origin: HTMLElement): void {
    this.onCloseSwitcher();

    if (this.isMobile) {
      this.onOpenMobileSwitcher(content);
    } else {
      this.onOpenDesktopSwitcher(content, origin);
    }
  }

  onCloseSwitcher() {
    if (this.dropdownRef) {
      this.dropdownRef.triggerCloseDropdown();
    }

    if (this.deckRef) {
      this.deckRef.triggerCloseDeck();
    }
  }

  private onOpenDesktopSwitcher(content: DropdownContentType, origin: HTMLElement): void {
    if (this.dropdownRef) {
      this.dropdownRef.close();
    }

    this.dropdownRef = this.dropdown.open({
      content,
      origin,
      width: 320,
      height: 'auto',
      position: 'end',
    });
  }

  private onOpenMobileSwitcher(content: DeckContentType): void {
    this.deckRef = this.deck.open({
      content,
      width: '100%',
      height: 'auto',
    });
  }
}
