import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { filter, take } from 'rxjs/operators';
import { getUserEmail, getUserName } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { getCurrentUrl } from '@api-ui-app/src/app/utils/router.selector';
import { combineLatest, Observable } from 'rxjs';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';
import { Store } from '@ngrx/store';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { AppState } from '@api-ui-app/src/app/app.state';

@Injectable({
  providedIn: 'root',
})
export class DestygoWebChatService {
  private readonly chatId = 'destygo_widget';
  private readonly userEmail$ = this.store.select(getUserEmail);
  private readonly userName$ = this.store.select(getUserName);

  readonly isWebChatEnabled$: Observable<boolean> = this.featureSwitcherService.isFeatureEnabledForCurrentUser(
    FeatureFlagEnum.destygo_webchat,
  );

  constructor(
    private readonly store: Store<AppState>,
    private readonly featureSwitcherService: FeatureSwitcherService,
    private readonly authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  get chat() {
    return window['DestygoChat'];
  }

  init(): void {
    this.isWebChatEnabled$.pipe(filter(Boolean), take(1)).subscribe(() => {
      const widget: HTMLElement = this.document.createElement('destygo-webchat');
      widget.setAttribute('id', this.chatId);
      widget.setAttribute('class', this.chatId);
      widget.setAttribute('token', 'd7bcfad3-9526-79a3-7774-a6661298f252');
      widget.setAttribute('auth_url', this.authService.getAuthUrl(window.location.href));
      widget.setAttribute('customer_id', this.authService.getIdToken());

      this.document.body.appendChild(widget);

      const dynamicScript: HTMLScriptElement = this.document.createElement('script');
      dynamicScript.type = 'text/javascript';
      dynamicScript.async = true;
      dynamicScript.src = 'https://widget.mindsay.com/destygo-webchat.js';
      this.document.body.appendChild(dynamicScript);

      this.initEvents(widget);
      this.initPageSubscription();
    });
  }

  toggleChat() {
    this.chat?.ToggleChat();
  }

  resetChat() {
    this.chat?.ResetWidget();
    this.resetSession();
  }

  private resetSession() {
    const chatSessionStorageKey = Object.keys(sessionStorage).filter(k => k.includes('destygo_chat'))[0];

    if (chatSessionStorageKey) {
      sessionStorage.removeItem(chatSessionStorageKey);
    }
  }

  private hideChat() {
    if (this.chat?.isOpen()) {
      this.toggleChat();
    }
  }

  private initEvents(widget: HTMLElement) {
    widget.addEventListener('destygo_zendesk_triggered', () => {
      combineLatest([this.userEmail$, this.userName$])
        .pipe(take(1))
        .subscribe(([email, display_name]) => {
          this.chat?.setZendeskUserInfo({
            email,
            display_name,
          });
        });
    });
  }

  private initPageSubscription(): void {
    this.store.select(getCurrentUrl).subscribe(() => {
      this.hideChat();
    });
  }
}
