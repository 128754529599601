import * as R from 'ramda';

export const serializeObject = (obj: object): string => {
  if (!obj) {
    return '';
  }

  const pairs: string[] = [];

  R.keys(obj).forEach((key: string) => {
    const value = encodeURIComponent(obj[key]);

    pairs.push(`${key}=${value}`);
  });

  return pairs.join('&');
};
