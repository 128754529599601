import { ChileCommunas } from './chileCommunas';

export const GELATO_ADMIN_CLIENT_UID = '00000000-0000-0000-0000-000000000000';
export const DEFAULT_BROCHURE_PAGE_COUNT = 40;
export const MINUTES_TO_MILLISECONDS = 60 * 1000;

export const SUPPORTED_CURRENCIES = [
  'USD',
  'EUR',
  'GBP',
  'AUD',
  'CAD',
  'JPY',
  'CHF',
  'DKK',
  'NOK',
  'SEK',
  'AED',
  'BRL',
  'CLP',
  'CNY',
  'COP',
  'CZK',
  'HKD',
  'HUF',
  'ILS',
  'INR',
  'KRW',
  'MXN',
  'MYR',
  'NZD',
  'PLN',
  'RUB',
  'SGD',
  'THB',
  'TRY',
  'ZAR',
  'BGN',
  'RON',
  'ISK',
  'HRK',
  'IDR',
  'PHP',
];

export const SUPPORTED_RETAIL_CURRENCIES = [...SUPPORTED_CURRENCIES, 'QAR'];

export const BRAZILIAN_STATES = [
  'ISENTO',
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PR',
  'PB',
  'PA',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SE',
  'SP',
  'TO',
];

export const US_STATES = [
  // States
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',

  // US Outlying Territories
  'AS',
  'GU',
  'MP',
  'PR',
  'UM',
  'VI',
  'AA',
  'AE',
  'AP',
];

export const CANADIAN_PROVINCES = [
  // Provinces
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NS',
  'ON',
  'PE',
  'QC',
  'SK',

  // Territories
  'NT',
  'YT',
  'NU',
];

export const AUSTRALIAN_STATES = [
  // States
  'NSW',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',

  'ACT',
  'NT',
];

export const JAPANESE_PREFECTURES = [
  // Prefectures
  'Hokkaido',
  'Aomori',
  'Iwate',
  'Miyagi',
  'Akita',
  'Yamagata',
  'Fukushima',
  'Ibaraki',
  'Tochigi',
  'Gunma',
  'Saitama',
  'Chiba',
  'Tokyo',
  'Kanagawa',
  'Niigata',
  'Toyama',
  'Ishikawa',
  'Fukui',
  'Yamanashi',
  'Nagano',
  'Gifu',
  'Shizuoka',
  'Aichi',
  'Mie',
  'Shiga',
  'Kyoto',
  'Osaka',
  'Hyogo',
  'Nara',
  'Wakayama',
  'Tottori',
  'Shimane',
  'Okayama',
  'Hiroshima',
  'Yamaguchi',
  'Tokushima',
  'Kagawa',
  'Ehime',
  'Kochi',
  'Fukuoka',
  'Saga',
  'Nagasaki',
  'Kumamoto',
  'Oita',
  'Miyazaki',
  'Kagoshima',
  'Okinawa',
];

export const CHILEAN_PROVINCES = ChileCommunas.map(c => c.value);

export const COUNTRIES_WITH_STATE = ['US', 'CA', 'AU', 'BR', 'CL', 'JP'];

export const MAX_DISCOUNTED_ORDERS_COUNT = 1;
export const MAX_ONBOARDING_ORDERS_COUNT = 19;
export const SHOPIFY_APP_URL = 'https://apps.shopify.com/gelato-print-on-demand';

export const HEADER_NAME_GELATO_CLIENT_ID = 'Gelato-Client-ID';
export const FRAMES_AND_HANGERS_STOCK_STATUS_ARTICLE_URL =
  'https://apisupport.gelato.com/hc/en-us/articles/360016181300-Frames-Hangers-Stock-Status';

export const TRUSTPILOT_URL = 'https://www.trustpilot.com/evaluate/gelato.com';
