<ng-select
  #select
  [items]="clients$ | async"
  [multiple]="false"
  [bindValue]="valueField"
  [bindLabel]="'name'"
  [clearable]="true"
  [trackByFn]="trackByFn"
  [minTermLength]="3"
  [compareWith]="compareFn"
  [loading]="clientsLoading"
  [placeholder]="placeholder || 'txt_search_client_field_placeholder' | translate"
  [typeToSearchText]="'txt_enter_3_or_more_characters' | translate"
  [typeahead]="clientInput$"
  [editableSearchTerm]="true"
  [ngModel]="selectedClients"
  (ngModelChange)="onSelect($event)"
>
</ng-select>
