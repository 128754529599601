<ng-container *ngIf="activationSteps">
  <ng-container *ngIf="activationSteps?.companyDetailsMissing || activationSteps?.creditCardMissing">
    {{ 'txt_welcome_offer_description_required_steps_start' | translate }}

    <ng-container *ngIf="activationSteps?.creditCardMissing">
      <a [routerLink]="['/payment-methods/cards/new', { from: 'home' }]" (click)="onAddCreditCardClick()">
        {{ 'txt_welcome_offer_description_required_step_credit_card' | translate }}
      </a>
    </ng-container>
    <ng-container *ngIf="activationSteps?.companyDetailsMissing && activationSteps?.creditCardMissing">
      {{ 'txt_welcome_offer_description_required_steps_conjuction' | translate }}
    </ng-container>
    <ng-container *ngIf="activationSteps?.companyDetailsMissing">
      <a [routerLink]="['/company-info']" (click)="onAddCompanyDetailsClick()">
        {{ 'txt_welcome_offer_description_required_step_company_details' | translate }}
      </a>
    </ng-container>
  </ng-container>
</ng-container>
