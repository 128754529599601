import { Action } from '@ngrx/store';
import { CommunicationSubscriptionSearchRequest } from '../notifications/lib/communication-subscription-search-request';
import { EntityState, ListState } from './notifications.reducer';

export enum NotificationsActionTypes {
  LoadList = '[Notifications] Load List',
  LoadEntity = '[Notifications] Load Entity',

  ResetListState = '[Notifications] Reset List State',
  ResetListPayload = '[Notifications] Reset List Payload',
  ResetEntityState = '[Notifications] Reset Entity State',

  SetListState = '[Notifications] Set List State',
  SetEntityState = '[Notifications] Set Entity State',
}

export class LoadList implements Action {
  readonly type = NotificationsActionTypes.LoadList;

  constructor(public searchRequest: CommunicationSubscriptionSearchRequest, public forced: boolean = false) {}
}

export class LoadEntity implements Action {
  readonly type = NotificationsActionTypes.LoadEntity;

  constructor(public id: string, public forced: boolean = false) {}
}

export class ResetListState implements Action {
  readonly type = NotificationsActionTypes.ResetListState;
}

export class ResetListPayload implements Action {
  readonly type = NotificationsActionTypes.ResetListPayload;
}

export class SetListState implements Action {
  readonly type = NotificationsActionTypes.SetListState;

  constructor(public payload: ListState) {}
}

export class ResetEntityState implements Action {
  readonly type = NotificationsActionTypes.ResetEntityState;
}

export class SetEntityState implements Action {
  readonly type = NotificationsActionTypes.SetEntityState;

  constructor(public payload: EntityState) {}
}

export type NotificationsActions =
  | LoadList
  | LoadEntity
  | ResetListState
  | ResetListPayload
  | ResetEntityState
  | SetListState
  | SetEntityState;
