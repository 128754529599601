import { Injectable } from '@angular/core';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { ImagePreviewModal } from '../image-preview-modal/image-preview-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ImagePreviewModalService {
  constructor(private modalService: SuiModalService) {}

  open(url: string, title: string = null) {
    return this.modalService.open(new ImagePreviewModal(url, title));
  }
}
