import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientRelationType } from '../lib/client-relation-type.enum';

@Component({
  selector: 'gd-client-relation-type-selector',
  templateUrl: './client-relation-type-selector.component.html',
  styleUrls: ['./client-relation-type-selector.component.scss'],
})
export class ClientRelationTypeSelectorComponent implements OnInit {
  @Input()
  clientRelationType: ClientRelationType = null;

  @Input()
  isDisabled = false;

  @Output()
  clientRelationTypeChange = new EventEmitter<ClientRelationType>();

  clientRelationTypes = [ClientRelationType.INTERNAL, ClientRelationType.EXTERNAL];

  constructor() {}

  ngOnInit() {}

  onClientRelationTypeChange(clientRelationType: ClientRelationType) {
    if (clientRelationType === this.clientRelationType) {
      return;
    }

    this.clientRelationTypeChange.emit(clientRelationType);
  }
}
