import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SingleVideoFullscreenComponent } from '@gelato-api-ui/sdk/src/lib/shared/single-video-fullscreen/single-video-fullscreen.component';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';
import { VideoModule } from '@gelato-api-ui/sdk/src/lib/shared/video/video.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SdkPipesModule, VideoModule],
  declarations: [SingleVideoFullscreenComponent],
  exports: [SingleVideoFullscreenComponent],
})
export class SingleVideoFullscreenModule {}
