export enum GrowChecklistStep {
  StoreLink = 'store-link',
  ExpandWithMoreProducts = 'expand-with-more-products',
  UpgradeMockups = 'upgrage-mockups',
  RunStoreFromPhone = 'run-store-from-app',
  SellDesignsOnMoreProductCategories = 'sell-designs-on-more-product-categories',
  AddSalesChannel = 'add-sales-channel',
  TargetCustomersInMoreCountries = 'target-customers-in-more-countries',
  OptimizePricing = 'optimize-pricing',
}
