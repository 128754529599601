<a
  *ngIf="uid"
  class="copy-uid-link"
  ngxClipboard
  [cbContent]="uid"
  (cbOnSuccess)="uidCopied(uid)"
  [attr.title]="linkTextKey | translate"
>
  <ng-container *ngIf="showIcon">
    <img width="32" height="32" src="{{ 'icons/copy-blue.svg' | assetsPath | async }}" />
  </ng-container>

  <ng-container *ngIf="showText">
    {{ linkTextKey | translate }}
  </ng-container>
</a>
