import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PreviewFileType } from '../preview-file-type.enum';
import { PreflightApiService } from './preflight-api.service';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { CachedRequestsService } from '@gelato-api-ui/core/api/services/cached-requests.service';

@Injectable({ providedIn: 'root' })
export class DesignPreviewService {
  constructor(
    private readonly preflightApiService: PreflightApiService,
    private readonly cachedRequestsService: CachedRequestsService,
  ) {}

  getUrl(
    designStructure: DesignStructure,
    pageNr: number,
    width: number,
    height: number,
    format: PreviewFileType,
    removeSamples: boolean,
  ): Observable<string> {
    if (!pageNr) {
      pageNr = 1;
    }

    const requestData = {
      service: 'DesignPreviewService',
      designStructureJson: JSON.stringify(designStructure),
      pageNr,
      width,
      height,
      format,
    };

    return this.cachedRequestsService
      .get(requestData, () =>
        this.getPreviewFromStructure(designStructure, pageNr, width, height, format, removeSamples),
      )
      .pipe(map(response => response?.url));
  }

  private getPreviewFromStructure(
    designStructure: DesignStructure,
    pageNr: number,
    width: number,
    height: number,
    format: PreviewFileType,
    removeSamples: boolean,
  ): Observable<{ url: string }> {
    return this.preflightApiService.getImageFromJson(designStructure, pageNr, width, height, format, removeSamples);
  }
}
