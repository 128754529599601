import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Wallet } from '../../lib/api';

export interface WalletState extends EntityState<Wallet> {
  isFetched: boolean;
}

export const walletAdapter: EntityAdapter<Wallet> = createEntityAdapter<Wallet>({
  selectId: details => details.walletUid,
});
