import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonTabslikeSwitcherChoice } from './lib/button-tabslike-switcher-choice.interface';

@Component({
  selector: 'gd-button-tabslike-switcher',
  templateUrl: './button-tabslike-switcher.component.html',
  styleUrls: ['./button-tabslike-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonTabslikeSwitcherComponent {
  @Input() selected: string;
  @Input() choices: ButtonTabslikeSwitcherChoice[] = [];

  @Output() select = new EventEmitter<string>();

  onSelect(e: Event, choice: ButtonTabslikeSwitcherChoice) {
    e.preventDefault();
    e.stopPropagation();

    if (!choice.disabled && this.selected !== choice.id) {
      this.select.emit(choice.id);
    }
  }
}
