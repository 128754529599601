import { SanityProductControlOption } from '@gelato-api-ui/core/sanity/sanity-product-control-option';
import { PrintSide } from '../print-side.enum';
import { PrintSidesSorted } from '../print-sides-sorted';

export const getSortedPrintSideControlOptions = (
  controlOptions: SanityProductControlOption[],
  sortingOrder = PrintSidesSorted,
): SanityProductControlOption[] =>
  sortingOrder
    .map((loopPrintSide: PrintSide) =>
      controlOptions.find((loopOption: SanityProductControlOption) => loopOption.value === loopPrintSide),
    )
    .filter((loopOption: SanityProductControlOption) => Boolean(loopOption));
