import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { UserCurrencyService } from '@api-ui-app/src/app/shared/services/user-currency.service';
import { SUPPORTED_CURRENCIES } from '@api-ui-app/src/app/lib/constants';

@Injectable({
  providedIn: 'root',
})
export class CurrencyGuard implements CanActivate {
  constructor(private readonly userCurrencyService: UserCurrencyService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const queryCurrency = route.queryParams['currency'];
    if (queryCurrency) {
      this.applyCurrency(queryCurrency);
    }
    return of(true);
  }

  private applyCurrency(currency: string): void {
    if (currency && SUPPORTED_CURRENCIES.includes(currency)) {
      this.userCurrencyService.set(currency);
    }
  }
}
