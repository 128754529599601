import { Injectable } from '@angular/core';
import { DesignStructure, PrintAreasToUidVariant } from '@gelato-api-ui/core/designs/design-structure';
import { DesignStructureConversionService } from '@gelato-api-ui/core/designs/services/design-structure-conversion.service';
import { Observable, of } from 'rxjs';
import { isNotEmptySpread } from '@gelato-api-ui/core/print-sides/helpers/detectPrintSide';

@Injectable({ providedIn: 'root' })
export class DesignEditorApparelService {
  constructor(private readonly designStructureConversionService: DesignStructureConversionService) {}

  getUid(
    designStructure: DesignStructure,
    printAreasToUidVariants: PrintAreasToUidVariant[],
    fallbackUid: string,
  ): string {
    const filledPrintAreas = this.getFilledPrintAreas(designStructure);

    if (!filledPrintAreas.length || !printAreasToUidVariants?.length) {
      return fallbackUid;
    }

    const foundVariant = printAreasToUidVariants.find(v => {
      const sameLength = v.types.length === filledPrintAreas.length;
      const sameValues = v.types.every(printSideName => filledPrintAreas.includes(printSideName));
      return sameLength && sameValues;
    });
    return foundVariant?.product_uid || '';
  }

  convert(designStructure: DesignStructure, destinationProductUid: string): Observable<DesignStructure> {
    const filledPrintAreas = this.getFilledPrintAreas(designStructure);

    if (!filledPrintAreas.length) {
      return of(designStructure);
    }

    const convertedDesignStructure = this.designStructureConversionService.prepareMultiPrintAreasStructure(
      designStructure,
      filledPrintAreas,
      destinationProductUid,
    );
    return of(convertedDesignStructure);
  }

  getFilledPrintAreas(designStructure: DesignStructure) {
    return !designStructure?.spreads
      ? []
      : designStructure.spreads.reduce((acc, spread) => (isNotEmptySpread(spread) ? [...acc, spread.name] : acc), []);
  }
}
