import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FinancialOrderStatus } from '../../orders/lib/financial-order-status.enum';

@Component({
  selector: 'gd-financial-order-status-multi-select',
  templateUrl: './financial-order-status-multi-select.component.html',
  styleUrls: ['./financial-order-status-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FinancialOrderStatusMultiSelectComponent implements OnInit {
  @Input() statuses: FinancialOrderStatus[] = null;

  @Output() statusesChange: EventEmitter<FinancialOrderStatus[]> = new EventEmitter();

  @Input()
  options: FinancialOrderStatus[] = [
    FinancialOrderStatus.INVOICED,
    FinancialOrderStatus.REFUNDED,
    FinancialOrderStatus.PARTIALLY_REFUNDED,
    FinancialOrderStatus.TO_BE_INVOICED,
    FinancialOrderStatus.PAID,
    FinancialOrderStatus.REFUSED,
    FinancialOrderStatus.PENDING,
    FinancialOrderStatus.CANCELED,
  ];

  constructor() {}

  ngOnInit() {}

  onSelect(statuses: FinancialOrderStatus[]) {
    this.statusesChange.emit(statuses);
  }
}
