import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { OrderDetails } from '../../orders/lib/order-details';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { OrderProductPreview } from '../../orders/lib/order-product-preview';
import { OrderProductPreviewType } from '../../orders/lib/order-product-preview-type.enum';
import { CheckoutQuoteRequest } from '@api-ui-app/src/app/checkout/types/checkout-quote-request';
import { CheckoutQuoteResponse } from '@api-ui-app/src/app/checkout/types/checkout-quote-response';
import { SaveOrderRequest } from '@api-ui-app/src/app/checkout/types/save-order-request';

@Injectable({ providedIn: 'root' })
export class CheckoutApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  saveOrder(order: SaveOrderRequest): Observable<OrderDetails> {
    return order.id ? this.updateOrder(order) : this.createOrder(order);
  }

  createOrder(order: SaveOrderRequest) {
    return this.apiRequest
      .post<OrderDetails>('/api/order/v4/orders', {
        body: order,
      })
      .pipe(map(data => data.data));
  }

  updateOrder(order: SaveOrderRequest) {
    return this.apiRequest
      .put<OrderDetails>(`/api/order/v4/orders/${order.id}`, { body: order })
      .pipe(map(data => data.data));
  }

  quote(request: CheckoutQuoteRequest) {
    return this.apiRequest
      .post<CheckoutQuoteResponse>('/api/v3/orders:quote', { body: request })
      .pipe(map(data => data.data));
  }

  loadPreview(orderId: string, itemId: string) {
    return this.apiRequest
      .post<{ previews: OrderProductPreview[] }>(`/api/order/v3/orders/${orderId}/products/${itemId}/previews`, {
        body: {
          orderId,
          productId: itemId,
          isInstant: true,
          force: false,
          previewTypes: [OrderProductPreviewType.THUMBNAIL, OrderProductPreviewType.DEFAULT],
        },
      })
      .pipe(map(data => data.data));
  }
}
