<div class="deck-wrapper">
  <ng-container [ngSwitch]="renderType">
    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="content; context: data"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="content"></ng-container>
    </ng-container>
  </ng-container>
</div>
