import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';

export const MAX_DIMENSIONS_COUNT = 3;
export const DEFAULT_PRICE_MARGIN = 1.5;

export const SUPPORTED_SOURCE_PRODUCT_TYPE_UIDS = [
  ProductTypeUid.POSTER,
  ProductTypeUid.FRAMED_POSTER,
  ProductTypeUid.MOUNTED_FRAMED_POSTER,
  ProductTypeUid.POSTER_WITH_HANGER,
  ProductTypeUid.ACRYLIC_PRINT,
  ProductTypeUid.ALUMINUM_PRINT,
  ProductTypeUid.FOAM_PRINT,
  ProductTypeUid.WOOD_PRINT,
  ProductTypeUid.CANVAS,
];

export const SUPPORTED_FORMAT_REG_EXPS = [
  /300x300-?mm-12x12-?inch/i, // 30x30 cm / 12x12″
  /300x400-?mm-12x16-?inch/i, // 30x40 cm / 12x16″
  /300x450-?mm-12x18-?inch/i, // 30x45 cm / 12x18″
  /400x500-?mm-16x20-?inch/i, // 40x50 cm / 16x20″
  /450x600-?mm-18x24-?inch/i, // 45x60 cm / 18x24″
  /500x500-?mm-20x20-?inch/i, // 50x50 cm / 20x20″
  /500x700-?mm-20x28-?inch/i, // 50x70 cm / 20x28″
  /600x800-?mm-24x32-?inch/i, // 60x80 cm / 24x32″
  /600x900-?mm-24x36-?inch/i, // 60x90 cm / 24x36″
  /700x1000-?mm-28x40-?inch/i, // 70x100 cm / 28x40″

  /300x300-?mm/i, // 30x30 cm
  /300x400-?mm/i, // 30x40 cm
  /300x450-?mm/i, // 30x45 cm
  /400x500-?mm/i, // 40x50 cm
  /450x600-?mm/i, // 45x60 cm
  /500x500-?mm/i, // 50x50 cm
  /500x700-?mm/i, // 50x70 cm
  /600x800-?mm/i, // 60x80 cm
  /600x900-?mm/i, // 60x90 cm
  /700x1000-?mm/i, // 70x100 cm

  /12x12-?inch/i, // 12x12″
  /12x16-?inch/i, // 12x16″
  /12x18-?inch/i, // 12x18″
  /16x20-?inch/i, // 16x20″
  /18x24-?inch/i, // 18x24″
  /20x20-?inch/i, // 20x20″
  /20x28-?inch/i, // 20x28″
  /24x32-?inch/i, // 24x32″
  /24x36-?inch/i, // 24x36″
  /28x40-?inch/i, // 28x40″
];

export const SUPPORTED_ORIENTATION_REG_EXPS = {
  vertical: /_ver$/,
  horizontal: /_hor$/,
};
