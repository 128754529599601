import * as R from 'ramda';
import { Client } from '@gelato-api-ui/core/clients/client';
import { State } from '../clients.reducer';

export const findClientInState = (state: State, clientId: string): Client => {
  if (!state || !clientId) {
    return null;
  }

  if (state.client && state.client.payload && state.client.payload.id === clientId) {
    return { ...state.client.payload };
  }

  if (state.clientList && state.clientList.payload && state.clientList.payload.data) {
    const foundClient = R.find(R.propEq('id', clientId))(state.clientList.payload.data);

    if (foundClient) {
      return { ...foundClient };
    }
  }

  return null;
};
