import { GettyCollectionGraphQLResponse, GettyCollectionsRequest } from '@gelato-api-ui/core/gallery/gallery-image';
import { ProductTypeGalleryCollections } from '@gelato-api-ui/core/gallery/product-type-gallery-collections';
import { createAction, props } from '@ngrx/store';

export const loadSanityProductTypesGalleryCollections = createAction(
  '[GalleryCollections] Load Sanity Product Types Gallery Collections',
);

export const loadSanityProductTypesGalleryCollectionsSuccess = createAction(
  '[GalleryCollections] Load Sanity Product Types Gallery Collections Success',
  props<{ payload: ProductTypeGalleryCollections[] }>(),
);

export const loadSanityProductTypesGalleryCollectionsError = createAction(
  '[GalleryCollections] Load Sanity Product Types Gallery Collections Error',
);
export const loadGettyCollections = createAction(
  '[GalleryCollections] Load Getty Collections',
  props<{ requestData: GettyCollectionsRequest }>(),
);

export const loadGettyCollectionsSuccess = createAction(
  '[GalleryCollections] Load Getty Collections Success',
  props<{ payload: GettyCollectionGraphQLResponse[] }>(),
);

export const loadGettyCollectionsError = createAction('[GalleryCollections] Load Getty Collections Error');
