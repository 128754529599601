<input
  name="phoneNumber"
  type="tel"
  inputmode="tel"
  gcPhoneMask
  [disabled]="disabled"
  [ngStyle]="{
    height: height ? height + 'px' : 'initial'
  }"
  [ngModel]="phoneNumber"
  [gcPhoneMaskCountryCode]="countryCode"
  [placeholder]="customPlaceholder"
  (ngModelChange)="onPhoneChange($event)"
/>
