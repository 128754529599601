import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TooltipPositions } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.enum';

@Component({
  selector: 'gc-plus-label',
  templateUrl: './plus-label.component.html',
  styleUrls: ['./plus-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlusLabelComponent {
  @Input() shrinkOnMobile = true;
  @Input() colorType: 'light' | 'bright' | 'dark' | 'gold' = 'light';
  @Input() performActionOnClick = false;
  @Input() isBold = false;
  @Input() tooltipText = 'txt_press_to_learn_more_about_gelato';
  @Input() plusLabelText = 'txt_sidebar_gelato_plus';

  @Output() labelClick = new EventEmitter();

  private iconConfig = {
    bright: 'sdk/crown-white.svg',
    light: 'sdk/crown.svg',
    dark: 'sdk/crown-black.svg',
    gold: 'sdk/gold-crown-with-border.svg',
  };

  public gcTooltipPosition: typeof TooltipPositions = TooltipPositions;

  get imgName() {
    return this.iconConfig[this.colorType];
  }

  onLabelClick() {
    if (this.performActionOnClick) {
      this.labelClick.emit();
    }
  }
}
