import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorisationGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.isAuthorised().pipe(
      map(isAuthorised => {
        if (!isAuthorised) {
          if (route.queryParams['from-landing']) {
            this.authService.redirectToSignUp();
          } else {
            this.authService.redirectToSignIn();
          }
        }

        return isAuthorised;
      }),
    );
  }
}
