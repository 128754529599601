import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class GraphQlInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return /^.*\/graphql$/.test(request.url) ? this.appendSearchQuery(request, next) : next.handle(request);
  }

  private appendSearchQuery(req: HttpRequest<any>, next: HttpHandler) {
    const operationName = req.body?.variables?.operationName;
    const newUrl = operationName ? `${req.url}?name=${operationName}` : req.url;
    return next.handle(req.clone({ url: newUrl }));
  }
}
