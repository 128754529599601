import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { UserRoleSearchRequest } from '../lib/user-role-search-request';
import { AccessGroupSearchRequest } from '../lib/access-group-search-request';
import { UserRoleObject } from '../lib/user-role-object';
import { AccessGroup } from '../lib/access-group';

@Injectable({
  providedIn: 'root',
})
export class UserRolesApiService {
  constructor(private apiRequest: ApiRequestService) {}

  getUserRoles(request: UserRoleSearchRequest = null): Observable<UserRoleObject[]> {
    if (!request || !request.clientId) {
      return of([]);
    }

    return this.apiRequest
      .get<UserRoleObject[]>('/api/identity/v3/user-roles', { body: request })
      .pipe(map((data: any) => data.data));
  }

  getAccessGroups(request: AccessGroupSearchRequest = null): Observable<AccessGroup[]> {
    if (!request || !request.clientId) {
      return of([]);
    }

    return this.apiRequest
      .get<AccessGroup[]>('/api/identity/v3/user-access-groups', { body: request })
      .pipe(map((data: any) => data.data));
  }
}
