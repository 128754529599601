import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SDK_CONFIG, SdkConfig } from '@gelato-api-ui/sdk/src/lib/config/sdk-config';
import { ConsoleLoggerService } from '@gelato-api-ui/sdk/src/lib/logger/console-logger.service';
import { LoggerService } from '@gelato-api-ui/sdk/src/lib/logger/logger.interface';
import { NullLoggerService } from '@gelato-api-ui/sdk/src/lib/logger/null-logger.service';
import { SdkModule } from '@gelato-api-ui/sdk/src/lib/sdk.module';
import { environment } from '../../environments/environment';
import { LayoutModule } from '../layout/layout.module';

const config: SdkConfig = {
  showTranslationKeys: environment.showTranslationKeys,
};

@NgModule({
  imports: [CommonModule, SdkModule, LayoutModule],
  exports: [],
  providers: [
    { provide: SDK_CONFIG, useValue: config },
    {
      provide: LoggerService,
      useClass: environment.production ? NullLoggerService : ConsoleLoggerService,
    },
  ],
})
export class UtilsModule {
  constructor() {}
}
