import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { map } from 'rxjs/operators';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class MockupStudioGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private featureSwitcherService: FeatureSwitcherService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.authService.isGelatoUser(),
      this.featureSwitcherService.isFeatureEnabledForCurrentUser(FeatureFlagEnum.mockup_studio),
    ]).pipe(
      map(([isGelatoUser, isFeatureEnabled]) => {
        if (!isGelatoUser && !isFeatureEnabled) {
          this.router.navigate(['/'], { queryParams: route.queryParams });
        }

        return isGelatoUser || isFeatureEnabled;
      }),
    );
  }
}
