import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, Type } from '@angular/core';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import * as actions from '@api-ui-app/src/app/ngrx/account-activation-steps.actions';
import { getSubscriptionClassName } from '../../lib/subscription-branding-helpers';
import { Promotion } from '../../shared/voucher/voucher';

@Component({
  selector: 'gd-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StandardHeaderDesktopComponent {
  @Input() sidebarVisible: boolean;
  @Input() headerComponent: Type<Component>;
  @Input() currentLang: string;
  @Input() currentCurrency: string;
  @Input() isGelatoPlusActive: boolean;
  @Input() isGelatoPlusGoldActive: boolean;
  @Input() isGelatoPlusPage: boolean;
  @Input() userWithFirstOrder: boolean;
  @Input() discounts: Promotion[];

  @Output() sidebarVisibilityChange = new EventEmitter();
  @Output() useLocale = new EventEmitter<LocaleCode>();
  @Output() useCurrency = new EventEmitter<string>();
  @Output() gelatoPlusLabelClick = new EventEmitter<void>();

  constructor(private readonly store: Store<AppState>) {
    this.store.dispatch(new actions.Load());
  }

  get isBrandingColorsLoading() {
    return this.isGelatoPlusActive == null && this.isGelatoPlusGoldActive == null;
  }

  get userWrapperClass() {
    return getSubscriptionClassName(this.isGelatoPlusActive, this.isGelatoPlusGoldActive);
  }

  toggleSidebar() {
    this.sidebarVisibilityChange.emit();
  }

  onLanguageChange(value: LocaleCode): void {
    this.useLocale.emit(value);
  }

  onCurrencyChange(value: string): void {
    this.useCurrency.emit(value);
  }

  clickGelatoPlusLabel(): void {
    this.gelatoPlusLabelClick.emit();
  }
}
