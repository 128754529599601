import { ResaleCertificateCategory } from './resale-certificate-category.enum';

export class ResaleCertificateCreateRequest {
  constructor(
    public clientId: string,
    public vendorName: string,
    public sellerPermitId: string,
    public categories: ResaleCertificateCategory[],
    public stateCodes: string[],
    public fileUrl: string,
    public activeUntil: string,
  ) {}
}
