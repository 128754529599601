import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import * as R from 'ramda';

@Component({
  selector: 'gd-numeric-field',
  templateUrl: './numeric-field.component.html',
  styleUrls: ['./numeric-field.component.scss'],
})
export class NumericFieldComponent implements OnInit {
  @Input()
  unitLabel = '';

  @Input()
  placeholder = '';

  @Input()
  value = null;

  @Input()
  min = -999999;

  @Input()
  max = 999999;

  @Input()
  disabled = false;

  @Output()
  valueChange = new EventEmitter<number>();

  @Output() public blur = new EventEmitter<void>();
  @Output() public focusOut = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  onValueChange(rawValue: string) {
    let numericValue: number;

    if (!R.isNil(rawValue) && rawValue !== '') {
      numericValue = Number(rawValue);

      if (isNaN(numericValue)) {
        numericValue = this.min;
      }

      numericValue = Math.min(numericValue, this.max);
      numericValue = Math.max(numericValue, this.min);
    }

    this.valueChange.emit(numericValue);
  }

  public onBlur(): void {
    this.blur.emit();
  }

  public onFocusOut(): void {
    this.focusOut.emit();
  }
}
