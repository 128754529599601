export enum QuickQuestionsSteps {
  sellingProductsToday = 'sellingProductsToday',
  sellingSystem = 'sellingSystem',
  customSellingSystem = 'customSellingSystem',
  onlinePresence = 'onlinePresence',
  customOnlinePresence = 'customOnlinePresence',
  followers = 'followers',
  isAlreadySelling = 'isAlreadySelling',
  isAlreadySellingPrintOnDemandProducts = 'isAlreadySellingPrintOnDemandProducts',
  revenue = 'revenue',
  printSpend = 'printSpend',
  industry = 'industry',
  influencer = 'influencer',
  podcast = 'podcast',
  superpower = 'superpower',
  customSuperpower = 'customSuperpower',
  referer = 'referer',
  customReferer = 'customReferer',
  reviewSite = 'reviewSite',
  customReviewSite = 'customReviewSite',
  customIndustry = 'customIndustry',
  ecommercePlatform = 'ecommercePlatform',
  storeLink = 'storeLink',
  customEcommercePlatform = 'customEcommercePlatform',
  phonePrefix = 'phonePrefix',
  phoneNumber = 'phoneNumber',
  termsAndConditions = 'termsAndConditions',
  receiveNews = 'receiveNews',
  socialMediaChannel = 'socialMediaChannel',
  customSocialMediaChannel = 'customSocialMediaChannel',
}

export interface QuickQuestionConfig {
  title: string;
  subtitle?: string;
  multiple?: boolean;
  custom?: boolean;
  containerWidth?: ContainerWidth;
}

export enum ContainerWidth {
  small = 'small',
  medium = 'medium',
}

export const quickQuestionConfig: { [step: string]: QuickQuestionConfig } = {
  [QuickQuestionsSteps.sellingProductsToday]: {
    title: 'txt_what_to_do_with_gelato_label',
    subtitle: 'txt_what_to_do_with_gelato_subtitle',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.onlinePresence]: {
    title: 'txt_where_the_strongest_presence_label',
    subtitle: 'txt_where_the_strongest_presence_subtitle',
    containerWidth: ContainerWidth.medium,
  },
  [QuickQuestionsSteps.followers]: {
    title: 'txt_how_many_followers_do_you_have_label',
    subtitle: 'txt_how_many_followers_do_you_have_subtitle',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.isAlreadySelling]: {
    title: 'txt_are_you_already_selling_print_label',
    subtitle: 'txt_are_you_already_selling_print_subtitle',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.isAlreadySellingPrintOnDemandProducts]: {
    title: 'txt_are_you_already_selling_print_on_demand_products',
    subtitle: 'txt_are_you_already_selling_print_on_demand_products_subtitle',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.ecommercePlatform]: {
    title: 'txt_where_you_selling_online_label',
    subtitle: 'txt_where_you_selling_online_subtitle',
    multiple: true,
    containerWidth: ContainerWidth.medium,
  },
  [QuickQuestionsSteps.storeLink]: {
    title: 'txt_store_link_label',
    subtitle: 'txt_store_link_subtitle',
    containerWidth: ContainerWidth.medium,
  },
  [QuickQuestionsSteps.printSpend]: {
    title: 'txt_what_your_yearly_spend_on_print_label',
    subtitle: 'txt_what_your_yearly_spend_on_print_subtitle',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.phoneNumber]: {
    title: 'txt_quick_question_personal_sales_manager',
    subtitle: 'txt_quick_question_personal_sales_manager_subtitle',
    custom: true,
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.industry]: {
    title: 'txt_what_products_do_you_sell_label',
    subtitle: 'txt_what_products_do_you_sell_subtitle',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.superpower]: {
    title: 'txt_superpower_question_label',
    subtitle: 'txt_superpower_question_subtitle',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.referer]: {
    title: 'txt_how_did_you_hear_about_us',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.influencer]: {
    title: 'txt_which_influencer_did_you_see',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.podcast]: {
    title: 'txt_which_podcast_did_you_listen_to',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.reviewSite]: {
    title: 'txt_what_site_did_you_see_us_in',
    containerWidth: ContainerWidth.small,
  },
  [QuickQuestionsSteps.socialMediaChannel]: {
    title: 'txt_which_channel_did_you_see_us_in',
    containerWidth: ContainerWidth.small,
  },
};

export class QuickQuestions {
  constructor(
    public sellingProductsToday: string = null,
    public sellingSystem: string = null,
    public customSellingSystem: string = null,
    public onlinePresence: string = null,
    public customOnlinePresence: string = null,
    public followers: string = null,
    public isAlreadySelling: string = null,
    public isAlreadySellingPrintOnDemandProducts: string = null,
    public revenue: string = null,
    public printSpend: string = null,
    public industry: string = null,
    public customIndustry: string = null,
    public superpower: string = null,
    public customSuperpower: string = null,
    public referer: string = null,
    public ecommercePlatform: string[] = [],
    public storeLink: string = null,
    public customEcommercePlatform: string = null,
    public termsAndConditions: boolean = false,
    public receiveNews: boolean = true,
    public phoneNumber: string = null,
    public phonePrefix: string = null,
    public customReferer: string = null,
    public influencer: string = null,
    public podcast: string = null,
    public reviewSite: string = null,
    public customReviewSite: string = null,
    public countryCode: string = null,
    public socialMediaChannel: string = null,
    public customSocialMediaChannel: string = null,
  ) {}
}
