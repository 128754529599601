import { Pipe, PipeTransform } from '@angular/core';
import { stripTags } from '@gelato-api-ui/core/dom/helpers/stripTags';

@Pipe({
  name: 'stripTags',
})
export class StripTagsPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    return stripTags(value);
  }
}
