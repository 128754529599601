import { Component, Input } from '@angular/core';
import { ErrorData } from '@api-ui-app/src/app/shared/lib/error-data';
import { getFormFieldError } from '@api-ui-app/src/app/lib/getFormFieldError';

@Component({
  selector: 'gd-form-field-error-message, gc-form-field-error-message',
  templateUrl: './form-field-error-message.component.html',
  styleUrls: ['./form-field-error-message.component.scss'],
})
export class FormFieldErrorMessageComponent {
  @Input() error: ErrorData = null;
  @Input() fieldName = null;
  @Input() errorMessage = null;

  get isMessageTextKey() {
    return /^txt_.*/.test(this.message);
  }

  get message() {
    return getFormFieldError(this.error, this.fieldName);
  }
}
