import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gc-card-base',
  templateUrl: './card-base.component.html',
  styleUrls: ['./card-base.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardBaseComponent {
  @Input() public iconUrl!: string;
  @Input() public defaultPaddings = true;
  @Input() public headerTitle!: string;
  @Input() public loading: boolean;
}
