import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getUserListResponse } from '../../users/+state/users.reducer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'toUserName',
})
export class ToUserNamePipe implements PipeTransform {
  constructor(private readonly store: Store<AppState>) {}
  transform(id: string): Observable<string> {
    return this.store.select(getUserListResponse).pipe(
      map(users => (users ? users.data.find(user => user.id === id) : null)),
      map(user => (user ? `${user.firstName} ${user.lastName}` : null)),
    );
  }
}
