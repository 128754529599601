import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { EProductVariantSearchRequest } from '@gelato-api-ui/core/e-commerce/e-product-variant-search-request';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import { ApolloQueryResult } from '@apollo/client/core';
import * as gql from 'gql-query-builder';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { TranslateService } from '@ngx-translate/core';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { BulkConnectVariantsMappingPayload } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { EProductValidationResponse } from '@gelato-api-ui/core/e-commerce/e-product-validation-response';
import { ProductVariantConnectionData } from '@gelato-api-ui/core/e-commerce/product-variant-connection-data';
import { EProductPublishMode } from '@api-ui-app/src/app/e-commerce-stores/types/e-product-publish-mode.enum';

@Injectable({ providedIn: 'root' })
export class ECommerceProductGraphQlService {
  private readonly operation = 'eCommerce';

  constructor(
    private readonly graphQlRequestService: GraphQlRequestService,
    private readonly translateService: TranslateService,
  ) {}

  getProduct(id: string): Observable<EProductWithVariants> {
    const fieldOperation = 'product';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            id: { type: 'String!', value: id },
            locale: { type: 'String!', value: this.translateService.currentLang },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`, true)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  searchProductVariants(storeId: string, request: EProductVariantSearchRequest): Observable<EProductVariant[]> {
    if (!request) {
      return of(null);
    }

    const fieldOperation = 'productVariants';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            storeId: { type: 'String', value: storeId || null },
            ids: { type: '[String!]', value: request.ids || null },
            productIds: { type: '[String!]', value: request.productIds || null },
            connectionStatuses: { type: '[String!]', value: request.connectionStatuses || null },
            offset: { type: 'Int', value: request.offset || null },
            limit: { type: 'Int', value: request.limit || null },
            locale: { type: 'String!', value: this.translateService.currentLang },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`, true)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  saveProduct(
    clientId: string,
    product: EProductWithVariants,
    mode: EProductWizardMode,
    publishMode: EProductPublishMode,
  ): Observable<EProductWithVariants> {
    const operation = 'saveECommerceProduct';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        product: { type: 'JSONObject!', value: product },
        mode: { type: 'String!', value: mode },
        publishMode: { type: 'String', value: publishMode },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  saveProductDraft(clientId: string, product: EProductWithVariants): Observable<EProductWithVariants> {
    const operation = 'saveECommerceProductDraft';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        product: { type: 'JSONObject!', value: product },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  deleteProductDraft(storeId: string, productId: string): Observable<boolean> {
    const operation = 'deleteECommerceProductDraft';
    const mutation = gql.mutation({
      operation,
      variables: {
        storeId: { type: 'String!', value: storeId },
        productId: { type: 'String!', value: productId },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  bulkConnectProductVariants(
    clientId: string,
    sourceProduct: EProductWithVariants,
    destinationProductId: string,
    mapping: BulkConnectVariantsMappingPayload,
    createPreviews: boolean,
  ): Observable<EProductWithVariants> {
    const operation = 'bulkConnectECommerceProductVariants';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        sourceProduct: { type: 'JSONObject!', value: sourceProduct },
        destinationProductId: { type: 'String!', value: destinationProductId },
        mapping: { type: 'JSONObject!', value: mapping },
        createPreviews: { type: 'Boolean!', value: createPreviews },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  validateProduct(
    clientId: string,
    product: EProductWithVariants,
    mode: EProductWizardMode,
  ): Observable<EProductValidationResponse> {
    const fieldOperation = 'validateProduct';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            clientId: { type: 'String!', value: clientId },
            product: { type: 'JSONObject!', value: product },
            mode: { type: 'String!', value: mode },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`, true)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  validateBulkConnectProductVariants(
    clientId: string,
    sourceProduct: EProductWithVariants,
    destinationProductId: string,
    mapping: BulkConnectVariantsMappingPayload,
    mode: EProductWizardMode,
  ): Observable<EProductValidationResponse> {
    const fieldOperation = 'validateBulkConnectProductVariants';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            clientId: { type: 'String!', value: clientId },
            sourceProduct: { type: 'JSONObject!', value: sourceProduct },
            destinationProductId: { type: 'String!', value: destinationProductId },
            mapping: { type: 'JSONObject!', value: mapping },
            mode: { type: 'String!', value: mode },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`, true)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  validateProductVariantPrice(
    clientId: string,
    storeId: string,
    currency: string,
    price: number,
  ): Observable<EProductValidationResponse> {
    const fieldOperation = 'validateProductVariantPrice';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            clientId: { type: 'String!', value: clientId },
            storeId: { type: 'String!', value: storeId },
            currency: { type: 'String!', value: currency },
            price: { type: 'Float!', value: price },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  ignoreProductVariant(productId: string, variantId: string): Observable<EProductWithVariants> {
    const operation = 'ignoreECommerceProductVariant';
    const mutation = gql.mutation({
      operation,
      variables: {
        productId: { type: 'String!', value: productId },
        variantId: { type: 'String!', value: variantId },
        locale: { type: 'String!', value: this.translateService.currentLang },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  disconnectProductVariant(productId: string, variantId: string): Observable<EProductWithVariants> {
    const operation = 'disconnectECommerceProductVariant';
    const mutation = gql.mutation({
      operation,
      variables: {
        productId: { type: 'String!', value: productId },
        variantId: { type: 'String!', value: variantId },
        locale: { type: 'String!', value: this.translateService.currentLang },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  connectProductVariant(productVariantConnectionData: ProductVariantConnectionData): Observable<EProductWithVariants> {
    const operation = 'connectECommerceProductVariant';
    const mutation = gql.mutation({
      operation,
      variables: {
        data: { type: 'JSONObject!', value: productVariantConnectionData },
        locale: { type: 'String!', value: this.translateService.currentLang },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  deleteProductVariants(productId: string, variantIds: string[]): Observable<EProductWithVariants> {
    const operation = 'deleteECommerceProductVariants';
    const mutation = gql.mutation({
      operation,
      variables: {
        productId: { type: 'String!', value: productId },
        variantIds: { type: '[String!]!', value: variantIds },
        locale: { type: 'String!', value: this.translateService.currentLang },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  canDuplicateProductAsDraft(productId: string): Observable<boolean> {
    const fieldOperation = 'canDuplicateProductAsDraft';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            productId: { type: 'String!', value: productId },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`, true)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  duplicateProductAsDraft(
    clientId: string,
    productId: string,
    duplicatedProductTitle?: string,
    destinationStoreId?: string,
  ): Observable<EProductWithVariants> {
    const operation = 'duplicateECommerceProductAsDraft';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        productId: { type: 'String!', value: productId },
        duplicatedProductTitle: { type: 'String', value: duplicatedProductTitle },
        destinationStoreId: { type: 'String', value: destinationStoreId },
        locale: { type: 'String!', value: this.translateService.currentLang },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  duplicateProductAsTemplate(
    clientId: string,
    productId: string,
    templateName: string,
  ): Observable<EProductWithVariants> {
    const operation = 'duplicateECommerceProductAsTemplate';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        productId: { type: 'String!', value: productId },
        templateName: { type: 'String', value: templateName },
        locale: { type: 'String!', value: this.translateService.currentLang },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }
}
