import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DropdownRef } from '@gelato-api-ui/ui-kit/src/lib/dropdown/components/dropdown-ref/dropdown-ref';
import { DeckRef } from '@gelato-api-ui/ui-kit/src/lib/deck/components/deck-ref/deck-ref';
import { TranslateService } from '@ngx-translate/core';
import { DropdownService } from '@gelato-api-ui/ui-kit/src/lib/dropdown/services';
import { DeckService } from '@gelato-api-ui/ui-kit/src/lib/deck/services';
import { DropdownContentType } from '@gelato-api-ui/ui-kit/src/lib/dropdown/types';
import { DeckContentType } from '@gelato-api-ui/ui-kit/src/lib/deck/types';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';

@Component({
  selector: 'gd-header-create-menu-stateless',
  templateUrl: './header-create-menu-stateless.component.html',
  styleUrls: ['./header-create-menu-stateless.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCreateMenuStatelessComponent {
  @Input() isMobile: boolean;
  @Output() placeOrder = new EventEmitter<void>();
  @Output() addNewProduct = new EventEmitter<void>();

  @ViewChild('origin') origin: ElementRef;
  @ViewChild('rootListContent') rootListContent: ElementRef;
  @ViewChild('langListContent') langListContent: ElementRef;

  private dropdownRef: DropdownRef;
  private deckRef: DeckRef;

  constructor(
    private readonly translateService: TranslateService,
    private readonly dropdown: DropdownService,
    private readonly deck: DeckService,
  ) {}

  onOpenSwitcher(content: DropdownContentType, origin: HTMLElement | ElementRef): void {
    this.onCloseSwitcher();

    if (this.isMobile) {
      this.onOpenMobileSwitcher(content);
    } else {
      this.onOpenDesktopSwitcher(content, origin);
    }
  }

  onCloseSwitcher() {
    if (this.dropdownRef) {
      this.dropdownRef.triggerCloseDropdown();
    }

    if (this.deckRef) {
      this.deckRef.triggerCloseDeck();
    }
  }

  onOpenMenu() {
    logEvent('Create Header Button Clicked');
    this.onOpenSwitcher(this.rootListContent, this.origin);
  }

  private onOpenDesktopSwitcher(content: DropdownContentType, origin: HTMLElement | ElementRef): void {
    if (this.dropdownRef) {
      this.dropdownRef.close();
    }

    this.dropdownRef = this.dropdown.open({
      content,
      origin,
      width: 250,
      height: 'auto',
      position: 'end',
    });
  }

  private onOpenMobileSwitcher(content: DeckContentType): void {
    this.deckRef = this.deck.open({
      content,
      width: '100%',
      height: 'auto',
    });
  }

  onPlaceOrder() {
    logEvent('Header Place Order Clicked');
    this.placeOrder.emit();
    this.onCloseSwitcher();
  }

  onAddNewProduct() {
    logEvent('Header Add New product Clicked');
    this.addNewProduct.emit();
    this.onCloseSwitcher();
  }
}
