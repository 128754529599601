<ng-template #tooltipTemplate>
  <div class="mounted-framed-poster-available-tooltip">
    {{ 'txt_mounted_framed_poster_available_tooltip' | translate }}
  </div>
</ng-template>

<div class="label-container">
  {{ 'txt_mounted_framed_poster_available' | translate }}
  <div
    class="info-icon-wrapper"
    [gcTooltip]="tooltipTemplate"
    [gcTooltipTheme]="'dark'"
    [gcTooltipOpenTimeout]="500"
    [gcTooltipTriggerOpenEvent]="'mouseenter'"
    [gcTooltipTriggerDestroyEvent]="'mouseleave'"
    [gcTooltipPosition]="tooltipPosition"
    [gcTooltipShowArrow]="true"
  >
    <img src="{{ 'icons/info-blue.svg' | assetsPath | async }}" width="16" height="16" />
  </div>
</div>
