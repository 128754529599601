import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DropdownMenuComponent } from '@gelato-api-ui/sdk/src/lib/shared/dropdown-menu/dropdown-menu.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [DropdownMenuComponent],
  exports: [DropdownMenuComponent],
})
export class DropdownMenuModule {}
