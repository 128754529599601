import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProductCatalogueSearchRequest } from '../product-catalogue-search-request';
import { ProductCatalogueSearchResponse } from '../product-catalogue-search-response';
import { ProductCatalogueCreateRequest } from '../product-catalogue-create-request';
import { ProductCatalogueUpdateRequest } from '../product-catalogue-update-request';
import { ProductCatalogue } from '../product-catalogue';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';

@Injectable({
  providedIn: 'root',
})
export class ProductCatalogueApiService {
  constructor(private apiRequest: ApiRequestService) {}

  searchCatalogues(request: ProductCatalogueSearchRequest = null): Observable<ProductCatalogueSearchResponse> {
    return this.apiRequest.post<ProductCatalogue[]>('/api/v2/catalogs:search', { body: request }).pipe(
      map((data: any) => ({
        data: data.data,
        pagination: data.pagination,
      })),
    );
  }

  getCatalogue(catalogueIdOrAlias: string): Observable<ProductCatalogue> {
    if (!catalogueIdOrAlias) {
      return of(null);
    }

    return this.apiRequest
      .get<ProductCatalogue[]>(`/api/v2/catalogs/${encodeURIComponent(catalogueIdOrAlias)}`)
      .pipe(map((data: any) => data.data));
  }

  createCatalogue(request: ProductCatalogueCreateRequest): Observable<ProductCatalogue> {
    return this.apiRequest
      .post<ProductCatalogue>('/api/v2/catalogs', { body: request })
      .pipe(map((data: any) => data.data));
  }

  updateCatalogue(catalogueIdOrAlias: string, request: ProductCatalogueCreateRequest): Observable<ProductCatalogue> {
    return this.apiRequest
      .put<ProductCatalogue>(`/api/v2/catalogs/${encodeURIComponent(catalogueIdOrAlias)}`, {
        body: request,
      })
      .pipe(map((data: any) => data.data));
  }

  saveCatalogue(catalogue: ProductCatalogue): Observable<ProductCatalogue> {
    if (catalogue.id) {
      const updateRequest = new ProductCatalogueUpdateRequest(
        catalogue.title,
        catalogue.alias,
        catalogue.clientId,
        catalogue.rootNodeId,
        catalogue.isPublic,
        catalogue.productTypeUid,
        catalogue.status,
      );

      return this.updateCatalogue(catalogue.id, updateRequest);
    }

    const createRequest = new ProductCatalogueCreateRequest(
      catalogue.title,
      catalogue.alias,
      catalogue.clientId,
      catalogue.rootNodeId,
      catalogue.isPublic,
      catalogue.productTypeUid,
    );

    return this.createCatalogue(createRequest);
  }

  deleteCatalogue(catalogueIdOrAlias: string): Observable<ProductCatalogue> {
    return this.apiRequest
      .delete<ProductCatalogue>(`/api/v2/catalogs/${encodeURIComponent(catalogueIdOrAlias)}`)
      .pipe(map((data: any) => data.data));
  }
}
