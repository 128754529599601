import { merge, Observable, Subject } from 'rxjs';
import { OverlayRef } from '@angular/cdk/overlay';
import { DeckContentType } from '../../types';

export class DeckRef<T = any> {
  private readonly beforeClosed = new Subject<boolean>();
  private readonly beforeClosed$: Observable<boolean>;
  private readonly backdropClick$: Observable<MouseEvent>;
  public readonly close$: Observable<boolean | MouseEvent>;

  constructor(
    private readonly overlayRef: OverlayRef,
    public readonly content: DeckContentType,
    public readonly data: T,
  ) {
    this.backdropClick$ = overlayRef.backdropClick();
    this.beforeClosed$ = this.beforeClosed.asObservable();
    this.close$ = merge(this.beforeClosed$, this.backdropClick$);
  }

  public triggerCloseDeck(): void {
    this.beforeClosed.next(true);
  }

  public close(): void {
    this._close();
  }

  private _close(): void {
    this.overlayRef.detach();
    this.overlayRef.dispose();
    this.beforeClosed.complete();
  }
}
