import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

export interface ChargeBeeRequest {
  itemPriceId: string;
  trialEnd?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChargeBeeApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  chargeBeeCheckoutAuthorize(request: ChargeBeeRequest) {
    return this.apiRequest
      .post('/api/v3/charge-bee/checkout', {
        body: request,
      })
      .pipe(
        map((data: any) => data.data),
        catchError((error: Error) => of(error)),
      );
  }

  chargeBeePortalAuthorize() {
    return this.apiRequest.post('/api/v3/charge-bee/portal').pipe(
      map((data: any) => data.data),
      catchError((error: Error) => of(error)),
    );
  }
}
