import * as actions from './page-leave-confirmation.actions';
import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

export interface State {
  enabled: boolean;
}

export const initialState: State = {
  enabled: false,
};

export function reducer(state = initialState, action): State {
  switch (action.type) {
    case actions.setFlag.type:
      return {
        ...state,
        enabled: action.payload,
      };
    default:
      return state;
  }
}

export const pageLeaveConfirmationReducerToken = new InjectionToken<ActionReducerMap<State>>(
  'page leave confirmation reducer',
);
