<div class="container">
  <!-- BILLING PERIOD STEP -->
  <div class="content-block" [class.reversed]="isMobile">
    <div class="description">
      <div class="description-wrapper">
        <ng-container *ngIf="isUserNotEligibleToPurchaseGoldSubscription">
          <ng-container *ngTemplateOutlet="purchaseRestricted"></ng-container>
        </ng-container>

        <ng-container *ngIf="showSubscriptionPricePlanStep">
          <ng-container *ngTemplateOutlet="billingPeriodStep"></ng-container>
        </ng-container>

        <ng-container *ngIf="gelatoSubscriptionModalType === gelatoSubscriptionModalTypeEnum.GENERAL">
          <ng-container *ngTemplateOutlet="generalStepModalContent"></ng-container>
        </ng-container>

        <ng-container *ngIf="activeStep === activeSteps.termsAndConditions">
          <ng-container *ngTemplateOutlet="termsAndConditionsTemplate"></ng-container>
        </ng-container>

        <ng-container *ngIf="activeStep === activeSteps.billingAddress">
          <ng-container *ngTemplateOutlet="billingAddressStep"></ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <!-- price plan step templates -->
  <ng-template #billingPeriodStep>
    <!-- gelato plus modal templates -->
    <ng-container *ngIf="isGelatoPlusModal">
      <!--  template for case if user never had trial subscription -->
      <ng-container *ngIf="isUserNeverHadGelatoPlusSubscription">
        <ng-container *ngTemplateOutlet="userNeverHadPlusTrialDescription"></ng-container>
      </ng-container>

      <!--  template for case if user has ended gelato+ subscription -->
      <ng-container *ngIf="gelatoPlusSubscriptionHasExpired">
        <ng-container *ngTemplateOutlet="gelatoPlusExpiredDescription"></ng-container>
      </ng-container>
    </ng-container>

    <!-- gelato gold modal template -->
    <ng-container *ngIf="isGelatoPlusGoldModal">
      <div class="content-description middle-aligned">
        <h3>{{ 'txt_start_trial_modal_gold_title' | translate }}</h3>
      </div>
      <div class="content-description middle-aligned">
        <p [innerHTML]="'txt_gelato_gold_popup_description' | translate"></p>
      </div>
    </ng-container>

    <div class="price-plans">
      <gd-price-plans-radio-group
        [gelatoSubscriptionModalType]="gelatoSubscriptionModalType"
        [selectedPricePlan]="selectedPricePlan$ | async"
        [modalRef]="modal"
        (selectPricePlan)="selectPricePlan($event)"
      ></gd-price-plans-radio-group>
    </div>

    <div *ngIf="!hideDiscountSection" class="activate-subscription-description">
      <div class="ui divider"></div>
      <p>
        {{
          isGelatoPlusModal
            ? ('txt_start_trial_modal_activate_subscription_plus_description' | translate)
            : ('txt_start_trial_modal_activate_subscription_gold_description' | translate)
        }}
      </p>

      <div class="activate-checkbox" [class.gold]="isGelatoPlusGoldModal">
        <div class="ui checkbox">
          <input type="checkbox" name="example" [(ngModel)]="skipTrial" />
          <label [innerHTML]="txtDiscount | translate | safe: 'html'"></label>
        </div>
      </div>
    </div>

    <div class="ui divider"></div>

    <ng-container *ngIf="showTrialInformation">
      <div class="gelato-plus-trial-features" *ngIf="selectedPricePlan$ | async as selectedPricePlan">
        <div class="price-item bold">
          <p [innerHtml]="'txt_due_today_free_trial' | translate | safe: 'html'"></p>
          <div class="price">0.00 {{ selectedPricePlan.price?.currency }}</div>
        </div>
        <div class="price-item">
          <p [innerHtml]="'txt_due' | translate: { untilDate: dateInAMonth }"></p>
          <div class="price">{{ selectedPricePlan.price?.price }} {{ selectedPricePlan.price?.currency }}</div>
        </div>
      </div>
    </ng-container>

    <gc-subscription-summary
      [companyDetails]="companyDetails"
      [tax]="servicePricePlanTax"
      [isTaxLoading]="isTaxLoading"
      [gelatoSubscriptionModalType]="gelatoSubscriptionModalType"
      [showTrialInformation]="showTrialInformation"
      [selectedPricePlan]="selectedPricePlan$ | async"
    >
    </gc-subscription-summary>

    <div class="next-step-button">
      <button
        class="ui button primary big"
        [ngClass]="{
          'plus-button': isGelatoPlusModal,
          'gold-button': isGelatoPlusGoldModal
        }"
        (click)="goToNextStep()"
      >
        {{ 'txt_next' | translate }}
      </button>
    </div>

    <div [innerHTML]="termsAndConditions | translate | safe: 'html'"></div>
  </ng-template>

  <!-- general step template -->
  <ng-template #generalStepModalContent>
    <div class="content-description middle-aligned">
      <h3>{{ 'txt_grow_faster_with_gelato' | translate }}</h3>
    </div>
    <div class="content-description middle-aligned">
      <p [innerHTML]="'txt_general_step_gelato_subscription_popup_description' | translate"></p>
    </div>
    <div *ngIf="gelatoPlusSubscriptionHasExpired" class="content-description middle-aligned">
      <img src="{{ 'subscriptions/clock.svg' | assetsPath | async }}" alt="" />
      <p [innerHtml]="'txt_subscription_is_over_popup_text' | translate"></p>
    </div>
    <div class="plans">
      <gd-price-plan-item
        class="plan"
        [planId]="planId.PLUS"
        [isCurrentPlan]="false"
        [planName]="'txt_plus' | translate"
        [planDescription]="'txt_subscription_popup_plus_description' | translate"
        [priceDescription]="'txt_monthly_on_annual_plan' | translate"
        [price]="gelatoPlusMonthPriceOnYearlyPlan$ | async"
        [buttons]="pricePlanGelatoPlusButtons"
        [countryISOCode]="countryISOCode$ | async"
      ></gd-price-plan-item>

      <gd-price-plan-item
        class="plan"
        [planId]="planId.GOLD"
        [isCurrentPlan]="false"
        [planName]="'txt_plain_gold' | translate"
        [planDescription]="'txt_subscription_popup_gold_description' | translate"
        [priceDescription]="'txt_monthly_on_annual_plan' | translate"
        [price]="gelatoGoldMonthPriceOnYearlyPlan$ | async"
        [buttons]="goldPlanButtons"
        [countryISOCode]="countryISOCode$ | async"
      ></gd-price-plan-item>
    </div>
  </ng-template>

  <ng-template #userNeverHadPlusTrialDescription>
    <div class="content-description middle-aligned">
      <h3>
        {{ 'txt_start_trial_modal_plus_title' | translate }}
      </h3>
    </div>
    <div class="content-description">
      <p [innerHTML]="'txt_start_trial_modal_plus_subtitle' | translate"></p>
      <p>{{ 'txt_select_your_billing_cycle' | translate }}</p>
    </div>
  </ng-template>

  <ng-template #gelatoPlusExpiredDescription>
    <div class="content-description middle-aligned">
      <h3 [innerHtml]="'txt_gelato_plus_is_over' | translate"></h3>
    </div>
    <div class="content-description middle-aligned">
      <p [innerHTML]="'txt_subscribe_now_to_continue_access' | translate"></p>
    </div>
  </ng-template>

  <ng-template #termsAndConditionsTemplate>
    <div class="content-description middle-aligned">
      <h3>{{ 'txt_terms_and_conditions' | translate }}</h3>
    </div>
    <div class="content-description middle-aligned">
      <p class="terms-and-conditions-text" [innerHTML]="'gelato-plus-gold-termsandconditions' | translate"></p>
    </div>

    <button class="ui button fluid gold-button next-step-button" (click)="goToNextStep()">
      {{ 'txt_accept_terms_and_conditions' | translate }}
    </button>
  </ng-template>

  <ng-template #purchaseRestricted>
    <div class="content-description middle-aligned">
      <h3>
        {{ 'txt_welcome_to_gelato_gold' | translate }}
      </h3>
    </div>
    <div class="content-description middle-aligned">
      <p [innerHTML]="'txt_subscription_gold_restricted_message' | translate"></p>
    </div>

    <button class="ui button fluid gold-button next-step-button" (click)="onContactUsClick()">
      {{ 'txt_subscriptions_contact_us' | translate }}
    </button>
  </ng-template>

  <ng-template #billingAddressStep>
    <div class="content-description middle-aligned">
      <h3>{{ 'txt_subscription_flow_billing_address' | translate }}</h3>
    </div>

    <div class="content-description middle-aligned">
      <p>{{ 'txt_subscription_provide_billing_information' | translate }}</p>
    </div>

    <div class="ui divider"></div>

    <gc-subscription-billing-form
      [class.collapsed]="billingInfoIsFilled"
      [companyDetails]="companyDetails"
      [errorDetails]="error$.getValue()"
      (update)="onUpdateBillingAddress($event)"
    ></gc-subscription-billing-form>

    <gc-subscription-summary
      *ngIf="billingInfoIsFilled"
      [companyDetails]="companyDetails"
      [tax]="servicePricePlanTax"
      [isTaxLoading]="isTaxLoading"
      [gelatoSubscriptionModalType]="gelatoSubscriptionModalType"
      [selectedPricePlan]="selectedPricePlan$ | async"
    >
    </gc-subscription-summary>

    <button
      class="ui button fluid next-step-button"
      [ngClass]="{
        'plus-button': isGelatoPlusModal,
        'gold-button': isGelatoPlusGoldModal
      }"
      (click)="goToNextStep()"
    >
      {{ 'txt_continue' | translate }}
    </button>
  </ng-template>
</div>
