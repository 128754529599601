import { Injectable } from '@angular/core';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TranslatedStoreTypeService {
  constructor(private readonly translateService: TranslateService) {}

  get(storeType: EStoreType): Observable<string> {
    const key: string = this.getTranslationKey(storeType);

    return key ? this.translateService.get(key) : of('');
  }

  instant(storeType: EStoreType): string {
    const key: string = this.getTranslationKey(storeType);

    return key ? this.translateService.instant(key) : '';
  }

  private getTranslationKey(storeType: EStoreType): string {
    if (!Object.values(EStoreType).includes(storeType)) {
      return null;
    }

    switch (storeType) {
      case EStoreType.BIGCOMMERCE:
        return `txt_store_type_big_commerce`;
      default:
        return `txt_store_type_${storeType}`;
    }
  }
}
