import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gc-placeholder',
  templateUrl: './placeholder.component.html',
  styleUrls: ['./placeholder.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceholderComponent {
  @Input() linesNumber = 1;
  @Input() set borderRadius(v: number) {
    this.containerStyle$.next({ ...this.containerStyle$.value, borderRadius: `${v}px` });
  }
  @Input() set lineMargin(v: number) {
    this.style$.next({ ...this.style$.value, height: `${v}px` });
  }
  @Input() set lineHeight(v: number) {
    this.style$.next({ ...this.style$.value, marginBottom: `${v}px` });
  }

  style$ = new BehaviorSubject<object>({});
  containerStyle$ = new BehaviorSubject<object>({});
}
