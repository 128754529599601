import { Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, take, tap } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { ProductVariantIdsCollection } from '@api-ui-app/src/app/product/product-add/types/product-variant-ids-collection';
import { loadStoresListForCurrentClient } from '@api-ui-app/src/app/ngrx/e-commerce-stores.actions';
import * as eCommerceProductsTreeActions from '@api-ui-app/src/app/product/product-add/+state/e-commerce-products-tree.actions';
import { getStoresList, getStoresListLoadingFlag } from '@api-ui-app/src/app/ngrx/e-commerce-stores.selector';
import {
  getLoadingFlag,
  getProductsTree,
} from '@api-ui-app/src/app/product/product-add/+state/e-commerce-products-tree.selector';
import { getSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { ProductSelectionResult } from '@api-ui-app/src/app/product/product-add/types/product-selection-result';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { ECommerceProductsTreeCheckedProductVariantsService } from '@api-ui-app/src/app/product/product-add/services/e-commerce-products-tree-checked-product-variants.service';

type Step = 'stores' | 'products';

export interface ExistingProductSelectionModalModalContext {
  maxCheckedProductVariantsCount: number;
  multipleSelectionEnabled: boolean;
  selectVariantsEnabled: boolean;
  selectProductsEnabled: boolean;
  excludeStoreIds?: string[];
  splashMessage?: string;
  title?: string;
  subtitle?: string;
}

@Component({
  selector: 'gd-existing-product-selection-modal',
  templateUrl: './existing-product-selection-modal.component.html',
  styleUrls: ['./existing-product-selection-modal.component.scss'],
})
export class ExistingProductSelectionModalComponent implements OnInit {
  storesList$ = this.store.pipe(
    select(getStoresList),
    map(stores => stores.filter(store => !this.modal.context.excludeStoreIds.includes(store.id))),
  );
  storesListLoading$ = this.store.select(getStoresListLoadingFlag);
  productsTree$ = this.store.select(getProductsTree);
  productsTreeLoading$ = this.store.select(getLoadingFlag);
  selectedClientId$ = this.store.select(getSelectedClientId);

  selectedStore$: BehaviorSubject<EStore> = new BehaviorSubject(null);
  step$: BehaviorSubject<Step> = new BehaviorSubject('stores');
  productSearchQuery$: BehaviorSubject<string> = new BehaviorSubject(null);

  delayedProductSearchQuery$ = this.productSearchQuery$.pipe(
    filter(() => Boolean(this.selectedStore$.getValue())),
    debounceTime(1000),
    distinctUntilChanged((prev: string, next: string) => {
      return (prev || '') === (next || '');
    }),
  );

  checkedProductVariantIds$: BehaviorSubject<ProductVariantIdsCollection> = new BehaviorSubject({});
  checkedProducts$: BehaviorSubject<string[]> = new BehaviorSubject([]);

  constructor(
    private readonly modal: SuiModal<ExistingProductSelectionModalModalContext, ProductSelectionResult, void>,
    private readonly store: Store<AppState>,
    private readonly eCommerceProductsTreeCheckedProductVariantsService: ECommerceProductsTreeCheckedProductVariantsService,
  ) {}

  ngOnInit(): void {
    logEvent('addExistingProductStart');

    this.store.dispatch(loadStoresListForCurrentClient());

    this.delayedProductSearchQuery$.subscribe(() => {
      if (!this.selectedStore$.getValue()) {
        return;
      }

      this.checkedProductVariantIds$.next({});
      this.loadStoreProducts();
    });
  }

  get maxCheckedProductVariantsCount() {
    return this.modal.context.maxCheckedProductVariantsCount;
  }

  get multipleSelectionEnabled() {
    return this.modal.context.multipleSelectionEnabled;
  }

  get selectVariantsEnabled() {
    return this.modal.context.selectVariantsEnabled;
  }

  get selectProductsEnabled() {
    return this.modal.context.selectProductsEnabled;
  }

  get splashMessageId() {
    return this.modal.context.splashMessage;
  }

  get title() {
    return this.modal.context.title;
  }

  get subTitle() {
    return this.modal.context.subtitle;
  }

  selectStore(store: EStore) {
    if (!this.selectProductsEnabled) {
      this.modal.approve({ items: [], store });
      return;
    }

    logEvent('addExistingProductSelectedStore', {
      storeType: store.type,
      storeName: store.name,
      storeUrl: store.domain,
    });
    this.productSearchQuery$.next(null);
    this.checkedProductVariantIds$.next({});
    this.selectedStore$.next(store);
    this.step$.next('products');
    this.loadStoreProducts();
  }

  loadStoreProducts(clearList: boolean = true) {
    const selectedStore: EStore = this.selectedStore$.getValue();
    const title: string = this.productSearchQuery$.getValue();

    if (!selectedStore) {
      return;
    }

    this.store.dispatch(new eCommerceProductsTreeActions.Load(selectedStore, title, clearList));
  }

  loadMoreStoreProducts() {
    this.loadStoreProducts(false);
  }

  showStoreSelection() {
    this.step$.next('stores');
    this.selectedStore$.next(null);
  }

  onProductSearchQueryChange(query: string) {
    this.productSearchQuery$.next(query);
  }

  onCheckedProductVariantIdsChange(checkedProductVariantIds: ProductVariantIdsCollection) {
    logEvent('addExistingProductSelectedProductVariants', { selectedItemsCount: checkedProductVariantIds.length });
    this.checkedProductVariantIds$.next(checkedProductVariantIds);
  }

  onCheckedProductsChange(checkedProducts: string[]) {
    logEvent('addExistingProductSelectedProducts', { selectedItemsCount: checkedProducts.length });
    this.checkedProducts$.next(checkedProducts);
  }

  cancel() {
    logEvent('addExistingProductCancel');
    this.modal.deny(null);
  }

  chooseVariants() {
    combineLatest([this.productsTree$, this.checkedProductVariantIds$])
      .pipe(
        first(),
        tap(([productsTree, checkedProductVariantIds]) => {
          const productVariants: EProductVariant[] =
            this.eCommerceProductsTreeCheckedProductVariantsService.getCheckedProductVariants(
              productsTree,
              checkedProductVariantIds,
            );

          this.modal.approve({ items: productVariants, store: this.selectedStore$.value, productsTree });
        }),
      )
      .subscribe();
  }

  chooseProducts() {
    this.productsTree$
      .pipe(
        take(1),
        tap(products => {
          const productIds = this.checkedProducts$.value;
          this.modal.approve({
            items: products.filter(pr => productIds.includes(pr.id)),
            store: this.selectedStore$.value,
          });
        }),
      )
      .subscribe();
  }

  submit() {
    logEvent('addExistingProductComplete');

    if (this.selectVariantsEnabled) {
      this.chooseVariants();
    } else {
      this.chooseProducts();
    }
  }
}

export class ExistingProductSelectionModal extends ComponentModalConfig<
  ExistingProductSelectionModalModalContext,
  ProductSelectionResult,
  void
> {
  constructor(config: ExistingProductSelectionModalModalContext) {
    super(ExistingProductSelectionModalComponent, {
      ...config,
      excludeStoreIds: config.excludeStoreIds || [],
    });
    this.isFullScreen = true;
    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}
