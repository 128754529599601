import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { splashMessageFeatureName } from './splash-message/splash-message.feature';
import { splashMessageReducer } from './splash-message/splash-message.reducer';
import { SplashMessageFacade } from './splash-message/splash-message.facade';

@NgModule({
  imports: [StoreModule.forFeature(splashMessageFeatureName, splashMessageReducer)],
  providers: [SplashMessageFacade],
})
export class SplashMessageStateModule {}
