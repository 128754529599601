import { Action } from '@ngrx/store';
import { Client } from '@gelato-api-ui/core/clients/client';
import { ClientSearchRequest } from '@gelato-api-ui/core/clients/client-search-request';

export enum ClientSelectionListAction {
  LoadStart = '[ClientSelectionList] LoadStart',
  LoadSuccess = '[ClientSelectionList] LoadSuccess',
  LoadClientsByIds = '[ClientSelectionList] LoadClientsByIds',
}

export class LoadStart implements Action {
  readonly type = ClientSelectionListAction.LoadStart;

  constructor(public searchRequest?: ClientSearchRequest) {}
}

export class LoadClientsByIds implements Action {
  readonly type = ClientSelectionListAction.LoadClientsByIds;

  constructor(public ids: string[]) {}
}

export class LoadSuccess implements Action {
  readonly type = ClientSelectionListAction.LoadSuccess;
  constructor(public payload: Client[]) {}
}

export type All = LoadStart | LoadSuccess | LoadClientsByIds;
