import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import * as R from 'ramda';
import { EProductTreeItem } from '@api-ui-app/src/app/product/product-add/types/e-product-tree-item';
import { ProductVariantIdsCollection } from '@api-ui-app/src/app/product/product-add/types/product-variant-ids-collection';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';

@Component({
  selector: 'gd-store-products-list',
  templateUrl: './store-products-list.component.html',
  styleUrls: ['./store-products-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreProductsListComponent {
  @Input() items: EProductTreeItem[] = [];
  @Input() storeType: EStoreType;
  @Input() multipleSelectionEnabled: boolean;
  @Input() checkedProductVariantIds: ProductVariantIdsCollection = null;
  @Input() checkedProductIds: string[] = [];
  @Input() isSelectVariants: boolean;
  @Output() checkedProductVariantIdsChange = new EventEmitter<ProductVariantIdsCollection>();
  @Output() checkedProductsChange = new EventEmitter<string[]>();

  onCheckedProductVariantIdsChange(productId: string, checkedProductVariantIds: string[]) {
    if (this.multipleSelectionEnabled) {
      const updatedCheckedProductVariantIds: ProductVariantIdsCollection = R.omit(
        [productId],
        this.checkedProductVariantIds,
      );

      if (checkedProductVariantIds && checkedProductVariantIds.length) {
        updatedCheckedProductVariantIds[productId] = checkedProductVariantIds;
      }

      this.checkedProductVariantIdsChange.emit(updatedCheckedProductVariantIds);
    } else {
      if (checkedProductVariantIds && checkedProductVariantIds.length) {
        this.checkedProductVariantIdsChange.emit({
          [productId]: checkedProductVariantIds,
        });
      } else {
        this.checkedProductVariantIdsChange.emit({});
      }
    }
  }

  onCheckedProduct(productId: string, checked) {
    const products = new Set(this.checkedProductIds);
    if (checked) {
      products.add(productId);
    } else {
      products.delete(productId);
    }
    this.checkedProductsChange.emit([...products]);
  }
}
