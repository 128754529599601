<div *ngIf="message$ | async" class="wrapper">
  <div class="ui message {{ (message$ | async).color }}">
    <div class="message-icon" *ngIf="(message$ | async).icon">
      <img [src]="(message$ | async).icon" alt="" width="" height="" />
    </div>
    <div class="message" *ngIf="(message$ | async).text" [innerHTML]="(message$ | async).text"></div>
    <div class="message" *ngIf="(message$ | async).textKey" [innerHTML]="(message$ | async).textKey | translate"></div>
    <img
      *ngIf="(message$ | async).closeable"
      class="close-icon"
      [src]="'assets/icons/close-splash-message.svg'"
      alt=""
      width=""
      height=""
      (click)="onClose($event)"
    />
  </div>
</div>
