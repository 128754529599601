import { Action, createAction, props, union } from '@ngrx/store';
import { OrderSearchRequest } from '../../lib/order-search-request';
import { OrderSearchResponse } from '../../lib/order-search-response';
import { OrderTotals } from '../../lib/order-totals';
import { OrdersSyncStatusResponse } from '@api-ui-app/src/app/orders/lib/orders-sync-status-response';

export enum OrdersAction {
  Load = '[Orders] Load',
  ResetPayload = '[Orders] Reset Payload',
}

export const noop = createAction('[Orders] Noop');

export class Load implements Action {
  readonly type = OrdersAction.Load;

  constructor(
    public payload: OrderSearchRequest = null,
    public forced: boolean = false,
    public shuldLoadTotals: boolean = true,
  ) {}
}

export class ResetPayload implements Action {
  readonly type = OrdersAction.ResetPayload;
  constructor() {}
}

export const loadTotals = createAction('[Orders] Load Totals', props<{ payload: OrderSearchRequest }>());
export const loadTotalsSuccess = createAction('[Orders] Load Totals Success', props<{ payload: OrderTotals }>());
export const loadSuccess = createAction(
  '[Orders] Load Success',
  props<{ payload: OrderSearchResponse; request: OrderSearchRequest }>(),
);
export const loadFailure = createAction('[Orders] Load Failure');
export const loadStart = createAction('[Orders] Load Start', props<{ request: OrderSearchRequest }>());

export const deleteOrder = createAction(
  '[Orders] Delete Order',
  props<{ orderId: string; orderReferenceId: string }>(),
);
export const deleteOrderFail = createAction('[Orders] Delete Order Fail');
export const setRequest = createAction('[Orders] Set Request', props<{ payload: OrderSearchRequest }>());

export const setCanSyncOrdersFlag = createAction('[Orders] Set Can Sync Orders Flag', props<{ payload: boolean }>());
export const syncOrdersStart = createAction('[Orders] Sync Orders Start');
export const syncOrdersSuccess = createAction('[Orders] Sync Orders Success');
export const syncOrdersFailure = createAction('[Orders] Sync Orders Failure');
export const syncOrdersProgressUpdate = createAction(
  '[Orders] Sync Orders Progress Update',
  props<{ payload: OrdersSyncStatusResponse }>(),
);
export const loadTotalsForPastMonth = createAction(
  '[Orders] Load Totals For Past Month',
  props<{ payload: OrderSearchRequest }>(),
);
export const loadTotalsForPastMonthSuccess = createAction(
  '[Orders] Load Totals For Past Month Success',
  props<{ payload: OrderTotals }>(),
);

const actions = union({
  loadTotals,
  loadTotalsSuccess,
  loadStart,
  loadFailure,
  loadSuccess,
  deleteOrder,
  deleteOrderFail,
  setCanSyncOrdersFlag,
  syncOrdersStart,
  syncOrdersSuccess,
  syncOrdersFailure,
  syncOrdersProgressUpdate,
  loadTotalsForPastMonth,
  loadTotalsForPastMonthSuccess,
});
export type OrdersActions = typeof actions | Load | ResetPayload;
