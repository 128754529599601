<div
  class="wrapper"
  [ngStyle]="{ height: wrapperHeight ? wrapperHeight + 'px' : '500px' }"
  [ngClass]="{ 'mobile-view': isMobile, 'with-fullscreen': allowFullscreen }"
>
  <swiper
    *ngIf="contentNavigationType === contentNavigationOptions.Tiles"
    class="thumbnails"
    [config]="thumbnailsSwiperConfig"
    [direction]="direction"
    [navigation]="navigation"
    (swiper)="setThumbsSwiper($event)"
  >
    <ng-template swiperSlide *ngFor="let mediaItem of media; let i = index">
      <ng-container
        *ngTemplateOutlet="mediaItem.videoId ? thumbnailVideo : thumbnailImage; context: { media: mediaItem, index: i }"
      >
      </ng-container>
    </ng-template>
  </swiper>

  <swiper
    class="previews"
    [class.with-navigation]="showNavigation"
    [pagination]="contentNavigationType === contentNavigationOptions.PaginationPoints && { clickable: true }"
    [navigation]="showNavigation"
    [config]="previewSwiperConfig"
    [thumbs]="{ swiper: (thumbsSwiper$ | async) }"
    (swiper)="setSwiper($event)"
    (click)="onClickSwiperPreview($event)"
    (activeIndexChange)="onActiveIndexChange($event)"
  >
    <ng-template swiperSlide *ngFor="let mediaItem of media; let i = index">
      <ng-container
        *ngTemplateOutlet="mediaItem.videoId ? previewVideo : previewImage; context: { media: mediaItem, index: i }"
      >
      </ng-container>
    </ng-template>
  </swiper>
</div>

<ng-template #thumbnailImage let-media="media">
  <gc-carousel-preview [url]="media.imageLink"></gc-carousel-preview>
</ng-template>

<ng-template #previewImage let-media="media">
  <gc-carousel-preview [url]="media.imageLink"></gc-carousel-preview>
</ng-template>

<ng-template #thumbnailVideo let-media="media" let-index="index">
  <div class="video-wrapper">
    <gc-carousel-preview [url]="media.imageLink">
      <gc-play-button size="small" (click)="onClick(index)"></gc-play-button>
    </gc-carousel-preview>
  </div>
</ng-template>

<ng-template #previewVideo let-media="media">
  <div class="video-wrapper" [ngClass]="{ 'video-titled': media.title }">
    <gc-carousel-preview [url]="media.imageLink">
      <gc-play-button></gc-play-button>
    </gc-carousel-preview>
    <div *ngIf="media.title" class="video-title">
      {{ media.title | translate }}
    </div>
  </div>
</ng-template>
