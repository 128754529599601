import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CanadianProvinceNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/canadian-province-name.pipe';
import { AustralianStateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/australian-state-name.pipe';
import { BrazilianStateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/brazilian-state-name.pipe';
import { UsStateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/us-state-name.pipe';

@Pipe({
  name: 'countryStateName',
})
export class CountryStateNamePipe implements PipeTransform {
  constructor(
    private usStateNamePipe: UsStateNamePipe,
    private canadianProvinceNamePipe: CanadianProvinceNamePipe,
    private australianStateName: AustralianStateNamePipe,
    private brazilianStateNamePipe: BrazilianStateNamePipe,
  ) {}

  transform(stateCode: string, countryIsoCode: string, showStateCode?: boolean): Observable<string> {
    switch (countryIsoCode) {
      case 'US':
        return this.usStateNamePipe.transform(stateCode, showStateCode);
      case 'CA':
        return this.canadianProvinceNamePipe.transform(stateCode, showStateCode);
      case 'AU':
        return this.australianStateName.transform(stateCode, showStateCode);
      case 'BR':
        return this.brazilianStateNamePipe.transform(stateCode, showStateCode);
      default:
        return of(stateCode);
    }
  }
}
