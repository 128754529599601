import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { ExtendedPriceCollection } from '../product-catalogue/lib/extended-price-collection';
import { ProductPrice } from '@gelato-api-ui/core/product-prices/product-price';
import { ShipmentMethodsResponse } from '@gelato-api-ui/core/shipment/shipment-methods-response';
import { ShipmentMethod } from '@gelato-api-ui/core/shipment/shipment-method';
import { ShipmentPricesResponse } from '@gelato-api-ui/core/shipment/shipment-prices-response';
import { ShipmentPriceValuePropertyName } from '../product-catalogue/lib/shipment-price-value-property-name.enum';
import { ShipmentPriceValueType } from '../product-catalogue/lib/shipment-price-value-type.enum';
import * as actions from './product-prices.actions';
import { ShipmentFlatPricesItemBody } from '@gelato-api-ui/core/shipment/shipment-prices-request';
import { RecommendedRetailPrices } from '@product-catalogue/src/lib/product-catalogue/types/product-recommeded-retail-prices';

export const getState = (state): State => state.productCatalogue.productPrices;

export const getProductPricesState = createSelector(
  getState,
  (productCatalogueState: State): ProductPricesState => productCatalogueState.productPrices,
);

export const getProductPrices = createSelector(
  getProductPricesState,
  (state: ProductPricesState): ProductPrice[][] => state.payload,
);

export const getProductQuantities = createSelector(getProductPrices, (prices: ProductPrice[][]) => {
  if (!prices) {
    return null;
  }

  const flatPricesArray = R.flatten(prices);
  const quantities = R.map(R.path(['quantity']), flatPricesArray);
  const uniqueQuantities = R.sort((a: number, b: number): number => a - b, R.uniq(quantities));

  const notEmptyQuantities = R.filter((quantity: number): boolean => Boolean(quantity), uniqueQuantities);

  return notEmptyQuantities.length ? notEmptyQuantities : null;
});

export const getShipmentMethodsState = createSelector(
  getState,
  (productCatalogueState: State): ShipmentMethodsState => productCatalogueState.shipmentMethods,
);

export const getShipmentMethods = createSelector(
  getShipmentMethodsState,
  (shipmentMethodsState: ShipmentMethodsState): ShipmentMethod[] =>
    shipmentMethodsState.payload ? shipmentMethodsState.payload.shipmentMethods : [],
);

export const getShipmentPricesState = createSelector(
  getState,
  (productCatalogueState: State): ShipmentPricesState => productCatalogueState.shipmentPrices,
);

export const getFlatShipmentPricesState = createSelector(
  getState,
  (productCatalogueState: State): number =>
    productCatalogueState.flatShipmentPrices &&
    productCatalogueState.flatShipmentPrices.rate &&
    productCatalogueState.flatShipmentPrices.rate[0].firstItemPrice,
);

export const getProductRecommendedRetailPrices = createSelector(
  getState,
  (productCatalogueState: State): RecommendedRetailPrices => productCatalogueState.recommendedRetailPrices,
);

export const getExtendedProductPricesState = createSelector(
  getProductPricesState,
  getShipmentPricesState,
  getShipmentMethodsState,
  (
    productPricesState: ProductPricesState,
    shipmentPricesState: ShipmentPricesState,
    shipmentMethodsState: ShipmentMethodsState,
  ): ExtendedProductPricesState => {
    const isLoading = productPricesState.isLoading || shipmentPricesState.isLoading || shipmentMethodsState.isLoading;

    const { productUid, currency, countries, pagesCount, clientId } = productPricesState;

    const payload = null;

    return {
      isLoading,
      productUid,
      currency,
      countries,
      pagesCount,
      clientId,
      payload,
    };
  },
);

export const getExtendedPrices = createSelector(
  getExtendedProductPricesState,
  (state: ExtendedProductPricesState): ExtendedPriceCollection => state.payload,
);

export const getShipmentPriceValuePropertyName = createSelector(
  getState,
  (state: State): ShipmentPriceValuePropertyName => state.shipmentPriceValuePropertyName,
);

export const getShipmentPriceValueType = createSelector(
  getState,
  (state: State): ShipmentPriceValueType => state.shipmentPriceValueType,
);

export const getSelectedPageCount = createSelector(getState, (state: State): number => state.selectedPageCount);

export interface ExtendedProductPricesState {
  isLoading: boolean;
  productUid: string;
  currency: string;
  countries: string[];
  pagesCount: number;
  clientId: string;
  payload: ExtendedPriceCollection;
}

export interface ProductPricesState {
  isLoading: boolean;
  productUid: string;
  currency: string;
  countries: string[];
  pagesCount: number;
  width: number;
  height: number;
  clientId: string;
  payload: ProductPrice[][];
}

export interface ShipmentMethodsState {
  isLoading: boolean;
  payload: ShipmentMethodsResponse;
}

export interface ShipmentPricesState {
  isLoading: boolean;
  countries: string[];
  currency: string;
  quantities: number[];
  productUid: string;
  payload: ShipmentPricesResponse[];
}

export interface State {
  productPrices: ProductPricesState;
  shipmentMethods: ShipmentMethodsState;
  shipmentPrices: ShipmentPricesState;
  recommendedRetailPrices: RecommendedRetailPrices;
  flatShipmentPrices: ShipmentFlatPricesItemBody;
  shipmentPriceValuePropertyName: ShipmentPriceValuePropertyName;
  shipmentPriceValueType: ShipmentPriceValueType;
  selectedPageCount: number;
}

export const initialState: State = {
  productPrices: {
    isLoading: false,
    productUid: null,
    countries: null,
    currency: null,
    pagesCount: null,
    width: null,
    height: null,
    clientId: null,
    payload: null,
  },
  shipmentMethods: {
    isLoading: false,
    payload: null,
  },
  shipmentPrices: {
    isLoading: false,
    countries: null,
    currency: null,
    quantities: null,
    productUid: null,
    payload: null,
  },
  recommendedRetailPrices: null,
  flatShipmentPrices: null,
  shipmentPriceValuePropertyName: ShipmentPriceValuePropertyName.MIN_PRICE,
  shipmentPriceValueType: ShipmentPriceValueType.SHIPPING_PRICE,
  selectedPageCount: null,
};

export function reducer(state = initialState, action: actions.ProductPricesActions): State {
  switch (action.type) {
    case actions.ProductPricesActionTypes.ResetProductPricesState:
      return {
        ...state,
        productPrices: {
          ...initialState.productPrices,
        },
      };
    case actions.ProductPricesActionTypes.ResetShipmentMethodsState:
      return {
        ...state,
        shipmentMethods: {
          ...initialState.shipmentMethods,
        },
      };
    case actions.ProductPricesActionTypes.ResetShipmentPricesState:
      return {
        ...state,
        shipmentPrices: {
          ...initialState.shipmentPrices,
        },
        flatShipmentPrices: {
          ...initialState.flatShipmentPrices,
        },
      };

    case actions.ProductPricesActionTypes.SetProductPricesState:
      return {
        ...state,
        productPrices: action.payload,
      };
    case actions.ProductPricesActionTypes.SetProductPricesLoading:
      return {
        ...state,
        productPrices: {
          ...state.productPrices,
          isLoading: true,
          payload: null,
        },
      };
    case actions.ProductPricesActionTypes.SetShipmentMethodsState:
      return {
        ...state,
        shipmentMethods: action.payload,
      };
    case actions.ProductPricesActionTypes.SetShipmentPricesState:
      return {
        ...state,
        shipmentPrices: action.payload,
      };
    case actions.ProductPricesActionTypes.SetRecommendedPricesState:
      return {
        ...state,
        recommendedRetailPrices: action.payload,
      };
    case actions.ProductPricesActionTypes.SetFlatShipmentPricesState:
      if (!!action) {
        return {
          ...state,
          flatShipmentPrices: action?.payload?.body,
        };
      }
      return state;
    case actions.ProductPricesActionTypes.SetShipmentPriceValuePropertyName:
      return {
        ...state,
        shipmentPriceValuePropertyName: action.payload,
      };
    case actions.ProductPricesActionTypes.SetShipmentPriceValueType:
      return {
        ...state,
        shipmentPriceValueType: action.payload,
      };
    case actions.ProductPricesActionTypes.SetSelectedPageCount:
      return {
        ...state,
        selectedPageCount: action.payload,
      };
    default:
      return state;
  }
}
