export enum PremiumIconCrownedFeatures {
  ADDONS = 'ADDONS',
  SHUTTERSTOCK = 'SHUTTERSTOCK',
  TEXT_EFFECTS = 'TEXT_EFFECTS',
  GETTY_IMAGES = 'GETTY_IMAGES',
  PREMIUM_MOCKUPS = 'PREMIUM_MOCKUPS',
  COLLECTIONS = 'COLLECTIONS',
  IMAGE_FILTERS = 'IMAGE_FILTERS',
  EXPAND_CATEGORIES = 'EXPAND_CATEGORIES',
  MOCKUPS_DOWNLOAD = 'MOCKUPS_DOWNLOAD',
  STORE_PRINT_FILE_FIELD_NAME = 'STORE_PRINT_FILE_FIELD_NAME',
  STORE_PERSONALIZATION = 'STORE_PERSONALIZATION',
  STORE_PROFIT_MARGIN = 'STORE_PROFIT_MARGIN',
  STORE_LIVE_RATES = 'STORE_LIVE_RATES',
  PREMIUM_FONTS = 'PREMIUM_FONTS',
  BACKGROUND_REMOVAL = 'BACKGROUND_REMOVAL',
}
