<div *ngIf="isGelatoUser$ | async" class="ui form">
  <div class="fields inline">
    <div class="field">
      <label>{{ 'txt_company_name_field_label' | translate }}</label>
      <div class="ui input">
        <gd-client-id-selector
          [isDisabled]="isDisabled"
          [showAllClientsOption]="false"
          [clientId]="selectedClientId$ | async"
          (clientIdChange)="onClientIdChange($event)"
        >
        </gd-client-id-selector>
      </div>
    </div>
  </div>
</div>
