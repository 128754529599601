import { Injectable } from '@angular/core';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { AlertModal } from '../alert-modal/alert-modal.component';

@Injectable()
export class AlertModalService {
  constructor(private modalService: SuiModalService) {}

  open(message, title = null, approveButtonText = null) {
    return this.modalService.open(new AlertModal(title, message, approveButtonText));
  }
}
