import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { User } from '../lib/user';
import { UserStatus } from '../lib/user-status.enum';
import { UserSearchRequest } from '../lib/user-search-request';
import { UserSearchResponse } from '../lib/user-search-response';
import { UserCreateRequest } from '../lib/user-create-request';
import { UserUpdateRequest } from '../lib/user-update-request';
import { UserSessionListResponse } from '@api-ui-app/src/app/users/lib/user-active-sessions';
import { UserPasswordUpdateRequest } from '@api-ui-app/src/app/users/lib/user-update-password';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService {
  constructor(private apiRequest: ApiRequestService) {}

  search(request: UserSearchRequest = null): Observable<UserSearchResponse> {
    return this.apiRequest.post<UserSearchResponse>('/api/identity/v3/users:search', { body: request }).pipe(
      map((data: any) => ({
        data: data.data,
        pagination: data.pagination,
      })),
      catchError((error): Observable<UserSearchResponse> => {
        console.error(error);
        return of(null);
      }),
    );
  }

  getUser(userId: string): Observable<User> {
    if (!userId) {
      return of(null);
    }

    return this.apiRequest.get<User>(`/api/identity/v3/users/${userId}`).pipe(map(data => data.data));
  }

  createUser(request: UserCreateRequest): Observable<User> {
    return this.apiRequest.post<User>('/api/identity/v3/users', { body: request }).pipe(map(data => data.data));
  }

  updateUser(userId: string, request: UserUpdateRequest): Observable<User> {
    return this.apiRequest
      .put<User>(`/api/identity/v3/users/${userId}`, { body: request })
      .pipe(map(data => data.data));
  }

  saveUser(user: User): Observable<User> {
    if (user.id) {
      const userUpdateRequest: UserUpdateRequest = new UserUpdateRequest(
        user.clientId,
        user.firstName,
        user.lastName,
        user.email,
        user.phone,
        user.details,
        user.roles,
        user.accessScopes,
        user.locale,
        user.status,
      );

      return this.updateUser(user.id, userUpdateRequest);
    }

    const userCreateRequest: UserCreateRequest = new UserCreateRequest(
      user.clientId,
      user.firstName,
      user.lastName,
      user.email,
      user.phone,
      user.details,
      user.roles,
      user.accessScopes,
      user.locale,
      UserStatus.CREATED,
    );

    return this.createUser(userCreateRequest);
  }

  deleteUser(userId: string): Observable<User> {
    return this.apiRequest.delete<User>(`/api/identity/v3/users/${userId}`).pipe(map(data => data.data));
  }

  /**
   * List active sessions of current user
   */
  public getCurrentUserActiveSessions() {
    return this.apiRequest
      .get<UserSessionListResponse>(`/api/identity/v3/users/me/sessions`)
      .pipe(map(data => data.data));
  }

  /**
   * List active sessions by userId
   */
  public getUserActiveSessions(userId: string): Observable<UserSessionListResponse> {
    return this.apiRequest
      .get<UserSessionListResponse>(`/api/identity/v3/users/${userId}/sessions`)
      .pipe(map(data => data.data));
  }

  /**
   * Delete other active sessions
   */
  public deleteActiveSessions() {
    return this.apiRequest.post('/api/identity/v3/users/me/sessions:delete-other').pipe(map(data => data.data));
  }

  public deleteAllActiveSessions() {
    return this.apiRequest.delete('/api/identity/v3/users/me/sessions').pipe(map(data => data.data));
  }

  public changeUserPassword(request: UserPasswordUpdateRequest) {
    return this.apiRequest
      .put<UserPasswordUpdateRequest>('/api/identity/v3/users/me/password', {
        body: request,
      })
      .pipe(map(data => data.data));
  }
}
