import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COUNTRIES_WITH_STATE } from '@api-ui-app/src/app/lib/constants';
import { CountryWithStates } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/types/countryWithStates';

@Component({
  selector: 'gc-country-state-selector',
  templateUrl: './country-state-selector.component.html',
  styleUrls: ['./country-state-selector.component.scss'],
})
export class CountryStateSelectorComponent {
  @Input() countryIsoCode: CountryWithStates = null;
  @Input() value: string = null;
  @Input() disabled = false;
  @Input() isSearchable = true;
  @Input() placeholderTxtKey = 'txt_address_state_field_label';
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  isCountryWithStates(countryIsoCode: string): boolean {
    return COUNTRIES_WITH_STATE.includes(countryIsoCode);
  }

  onStateChange(stateCode: string) {
    this.valueChange.emit(stateCode);
  }
}
