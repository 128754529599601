import { createAction, props } from '@ngrx/store';
import { ErrorData } from '@api-ui-app/src/app/shared/lib/error-data';
import { ReturnAddress } from '../types/return-address';

export const initiateState = createAction('[ReturnAddresses] Initiate State');

export const loadReturnAddressesList = createAction(
  '[ReturnAddresses] Load Return Addresses',
  props<{ clientId: string }>(),
);

export const loadReturnAddressesListSuccess = createAction(
  '[ReturnAddresses] Load Return Addresses Success',
  props<{ payload: ReturnAddress[] }>(),
);

export const loadReturnAddressesListFailure = createAction(
  '[ReturnAddresses] Load Return Addresses Failure',
  props<{ error: ErrorData }>(),
);
