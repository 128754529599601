import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';

export enum RestrictionTypes {
  usersList = 'usersList',
  storesList = 'storesList',
  unlimitedLiveChat = 'unlimitedLiveChat',
}

interface TryGelatoPlusModalComponentContext {
  restrictionType: RestrictionTypes;
}

@Component({
  selector: 'gd-try-gelato-plus-modal',
  templateUrl: './try-gelato-plus-modal.component.html',
  styleUrls: ['./try-gelato-plus-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TryGelatoPlusModalComponent {
  restrictionType = this.modal.context.restrictionType;

  featureRestrictionTranslations = {
    usersList: {
      modalTitle: 'txt_gelato_plus_modal_title',
      modalContent: 'txt_gelato_plus_modal_users_text',
    },
    storesList: {
      modalTitle: 'txt_gelato_plus_modal_title',
      modalContent: 'txt_gelato_plus_modal_stores_text',
    },
    unlimitedLiveChat: {
      modalTitle: 'txt_no_more_users_available',
      modalContent: 'txt_basic_plan_users_limit_message',
    },
  };

  constructor(public modal: SuiModal<TryGelatoPlusModalComponentContext, void, void>) {}

  onApprove() {
    this.modal.approve(undefined);
  }
}

export class TryGelatoPlusModal extends ComponentModalConfig<TryGelatoPlusModalComponentContext, void, void> {
  constructor(restrictionType: RestrictionTypes) {
    super(TryGelatoPlusModalComponent, { restrictionType });

    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}
