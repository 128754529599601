<ng-select
  [ngModel]="statuses"
  (ngModelChange)="onSelect($event)"
  [items]="options"
  [clearable]="false"
  [searchable]="false"
  [virtualScroll]="true"
  [multiple]="true"
  [closeOnSelect]="false"
  [hideSelected]="true"
  [placeholder]="'txt_all_statuses' | translate"
  class="show-all"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-icon" (click)="clear(item)" aria-hidden="true">×</span>
    <span class="ng-value-label">
      {{ item | financialOrderStatus | async }}
    </span>
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    {{ item | financialOrderStatus | async }}
  </ng-template>
</ng-select>
