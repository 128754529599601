import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'gd-pagination-page-selector, gc-pagination-page-selector',
  templateUrl: './pagination-page-selector.component.html',
  styleUrls: ['./pagination-page-selector.component.scss'],
})
export class PaginationPageSelectorComponent implements OnChanges {
  @Input() offset: number = null;
  @Input() limit: number = null;
  @Input() total: number = null;
  @Output() offsetChange: EventEmitter<number> = new EventEmitter();

  shownPageNumberSections: number[][] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.offset || changes?.limit || changes?.total) {
      this.shownPageNumberSections = this.getShownPageNumberSections();
    }
  }

  get pagesCount(): number {
    return !this.limit ? 1 : Math.ceil(this.total / this.limit);
  }

  get activePageIndex(): number {
    return !this.limit ? 0 : Math.floor(this.offset / this.limit);
  }

  get activePageNumber(): number {
    return this.activePageIndex + 1;
  }

  getShownPageNumberSections(): number[][] {
    const pagesCount = this.pagesCount;
    const shownNumbers = [];

    const minPageNumber = Math.max(this.activePageNumber - 2, 1);
    const maxPageNumber = Math.min(this.activePageNumber + 3, pagesCount + 1);
    const pageNumbersCountToShow = 5;

    const mainSection = new Array(5).fill(null).reduce((acc, value, index) => {
      const pageNumber = minPageNumber + index;
      return pageNumber < maxPageNumber ? [...acc, minPageNumber + index] : acc;
    }, []);

    shownNumbers.push(mainSection);

    if (minPageNumber > 1) {
      const firstPageNumber = 1;

      if (minPageNumber > 2) {
        shownNumbers.unshift([firstPageNumber]);
      } else {
        shownNumbers[0].unshift(firstPageNumber);
      }
    }

    const lastShownPageNumber = minPageNumber + pageNumbersCountToShow;

    if (lastShownPageNumber < pagesCount + 1) {
      const lastPageNumber = pagesCount;

      if (lastShownPageNumber < pagesCount) {
        shownNumbers.push([lastPageNumber]);
      } else {
        shownNumbers[shownNumbers.length - 1].push(lastPageNumber);
      }
    }

    return shownNumbers;
  }

  selectPage(pageNumber: number): void {
    const offset = (pageNumber - 1) * this.limit;
    this.offsetChange.emit(offset);
  }
}
