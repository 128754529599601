import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';

@Component({
  selector: 'gd-stores-list-modal-content',
  templateUrl: './stores-list-modal-content.component.html',
  styleUrls: ['./stores-list-modal-content.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoresListModalContentComponent {
  @Input() stores: EStore[] = [];
  @Input() isLoading = false;
  @Input() title: string;
  @Input() subtitle: string;
  @Output() storeSelect: EventEmitter<EStore> = new EventEmitter<EStore>();
  @Output() closeModal: EventEmitter<void> = new EventEmitter<void>();

  onStoreSelect(store: EStore) {
    this.storeSelect.emit(store);
  }

  requestModalClose() {
    this.closeModal.emit();
  }
}
