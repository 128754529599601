import { ErrorHandler, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { STATIC_URL } from '@gelato-api-ui/sdk/src/lib/shared/static-path/static-url.token';
import { I18nModule } from '@gelato-api-ui/sdk/src/lib/translations/i18n.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { KeycloakAngularModule } from 'keycloak-angular';
import * as Sentry from '@sentry/angular';
import { API_MODULE_CONFIG } from '@gelato-api-ui/sdk/src/lib/api/api.tokens';
import { I18N_BASE_URL, I18N_DEFAULT_PARAMS } from '@gelato-api-ui/sdk/src/lib/translations/i18n.token';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppEffects } from './app.effects';
import { AuthEffects } from './ngrx/auth.effects';
import { ClientSelectionListEffects } from './ngrx/client-selection-list.effects';
import { ProductCatalogueSelectionListEffects } from './ngrx/product-catalogue-selection-list.effects';
import { PricePlanSelectionListEffects } from './ngrx/price-plan-selection-list.effects';
import { OrderRefundEffects } from './ngrx/order-refund.effects';
import { UserRolesEffects } from './ngrx/user-roles.effects';
import { UsersEffects } from './users/+state/users.effects';
import { AnalyticsEffects } from './analytics/+state/analytics.effects';
import { ClientsEffects } from './ngrx/clients.effects';
import { ApiKeysEffects } from './ngrx/api-keys.effects';
import { NotificationsEffects } from './ngrx/notifications.effects';
import { CompanyDetailsEffects } from './ngrx/company-details.effects';
import { BillingEntitiesEffects } from './ngrx/billing-entities.effects';
import { PricePlanSubscriptionsEffects } from './ngrx/price-plan-subscriptions.effects';
import { ProductionConfigurationsEffects } from './ngrx/production-configurations.effects';
import { ResaleCertificatesEffects } from './ngrx/resale-certificates.effects';
import { AccountActivationStepsEffects } from './ngrx/account-activation-steps.effects';
import { ECommerceStoresEffects } from './ngrx/e-commerce-stores.effects';
import { ECommerceProductsEffects } from './ngrx/e-commerce-products.effects';
import { ECommerceProductWizardEffects } from './ngrx/e-commerce-product-wizard.effects';
import { reducer, reducerToken } from './app.state';
import { UtilsModule } from './utils/utils.module';
import { KeycloakCredentials } from './shared/lib/keycloak-credentials';
import { environment } from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { WalletsStateModule } from './payment-methods/wallets/+state/wallets-state.module';
import { SanityModule } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.module';
import { SanityEffects } from '@api-ui-app/src/app/ngrx/sanity.effects';
import { PageLeaveConfirmationModule } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation.module';
import { ASSET_BASE_URL } from '@gelato-api-ui/core/asset/asset-token';
import { SANITY_BASE_URL } from '@gelato-api-ui/core/sanity/sanity-base-url-token';
import { DiscountsEffects } from '@api-ui-app/src/app/ngrx/discounts.effects';
import { CONFIG_CAT_KEY } from '@gelato-api-ui/sdk/src/lib/feature-switcher/config-cat/config-cat-key.token';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/cache';
import { HttpLink } from 'apollo-angular/http';
import { GraphQlInterceptor } from '@gelato-api-ui/sdk/src/lib/api/interceptors/graph-ql.interceptor';
import { LayoutEffects } from '@api-ui-app/src/app/ngrx/layout.effects';
import { onError } from '@apollo/client/link/error';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { ChargeBeeService } from '@api-ui-app/src/app/subscriptions/services/charge-bee.service';
import { SubscriptionsEffects } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.effects';
import { SubscriptionsModule } from '@api-ui-app/src/app/subscriptions/subscriptions.module';
import { OrdersStateModule } from '@api-ui-app/src/app/orders/+state/orders-state/orders-state.module';
import { LocaleService } from '@api-ui-app/src/app/shared/services/locale.service';
import { PreviewsArchiveEffects } from '@api-ui-app/src/app/previews-archive/+state/previews-archive.effects';
import { NgxMaskModule } from 'ngx-mask';
import { Application, APPLICATION } from '@gelato-api-ui/sdk/src/lib/application.token';
import { CountriesEffects } from '@api-ui-app/src/app/shared/countries/+state/countries.effects';
import { IS_EMBEDDABLE_EDITOR } from '@gelato-api-ui/core/design-renders-loader/services/design-renders-loader.service';
import { CustomerOnboardingModule } from './onboarding-steps-widget/customer-onboarding.module';
import { HelloBarModule } from '@api-ui-app/src/app/hello-bar/hello-bar.module';

export const WINDOW = new InjectionToken<Window>('WindowToken', {
  providedIn: 'root',
  factory: () => window,
});

// v1.0.96
@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SanityModule,
    StoreModule.forRoot(reducerToken),
    PageLeaveConfirmationModule,
    WalletsStateModule,
    SubscriptionsModule,
    OrdersStateModule,
    CustomerOnboardingModule,
    EffectsModule.forRoot([
      AppEffects,
      AuthEffects,
      ClientSelectionListEffects,
      CountriesEffects,
      ProductCatalogueSelectionListEffects,
      PricePlanSelectionListEffects,
      OrderRefundEffects,
      UserRolesEffects,
      SanityEffects,
      UsersEffects,
      ClientsEffects,
      ApiKeysEffects,
      NotificationsEffects,
      CompanyDetailsEffects,
      BillingEntitiesEffects,
      PricePlanSubscriptionsEffects,
      AnalyticsEffects,
      ProductionConfigurationsEffects,
      ResaleCertificatesEffects,
      AccountActivationStepsEffects,
      ECommerceStoresEffects,
      ECommerceProductsEffects,
      ECommerceProductWizardEffects,
      DiscountsEffects,
      LayoutEffects,
      SubscriptionsEffects,
      PreviewsArchiveEffects,
    ]),
    UtilsModule,
    I18nModule.forRoot(),
    KeycloakAngularModule,
    NgxMaskModule.forRoot(),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    HelloBarModule,
  ],
  providers: [
    { provide: reducerToken, useValue: reducer },
    { provide: API_MODULE_CONFIG, useValue: environment.api },
    { provide: ASSET_BASE_URL, useValue: '' },
    { provide: SANITY_BASE_URL, useValue: environment.baseUrls.sanity },
    { provide: KeycloakCredentials, useValue: environment.keycloak },
    { provide: CONFIG_CAT_KEY, useValue: environment.configCatKey },
    { provide: STATIC_URL, useValue: environment.cdn },
    { provide: APPLICATION, useValue: Application.dashboard },
    { provide: I18N_BASE_URL, useValue: environment.baseUrls.i18n, multi: true },
    { provide: I18N_DEFAULT_PARAMS, useValue: {}, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: GraphQlInterceptor, multi: true },
    { provide: IS_EMBEDDABLE_EDITOR, useValue: false },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({ showDialog: false }),
    },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        const httpUrlLink = httpLink.create({ uri: environment.baseUrls.graphql });
        const errorLink = onError(errorResponse => {
          const networkError: HttpErrorResponse = errorResponse.networkError as HttpErrorResponse;

          if (!networkError?.status) {
            return;
          }

          const errors = networkError.error ? (networkError.error.errors || []).map(error => error.message) : [];
          const errorData = {
            status: networkError.status,
            statusText: networkError.statusText,
            url: networkError.url,
            errors: JSON.stringify(errors),
          };

          logEvent('graphQlRequestFailed', errorData);

          Sentry.captureException(new Error(`GraphQlRequestFailed`), scope => {
            scope.setExtras(errorData);
            return scope;
          });
        });

        return {
          cache: new InMemoryCache(),
          link: errorLink.concat(httpUrlLink),
        };
      },
      deps: [HttpLink],
    },
    ChargeBeeService,
    LocaleService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
