import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { getHelloBar, isHelloBarVisible } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.selector';
import { loadHelloBar, setHelloBarVisibility } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.actions';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { SanityHelloBar, SanityHelloBarContentItem } from '@gelato-api-ui/core/sanity/types/sanity-hello-bar';
import { ResponsiveService } from '@gelato-api-ui/core/responsive/responsive.service';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';

@Injectable({ providedIn: 'root' })
export class HelloBarFacade {
  ONE_SEC_TO_MS = 1000;
  LS_KEY = 'HelloBarDismissed';
  currentMessageIndexes = [0, 1];
  animationInterval: number;
  isCameFromEditor = false;
  routerSubscription: Subscription;
  updatesSubscription: Subscription;

  helloBar$ = this.store.select(getHelloBar);
  isHelloBarVisible$ = this.store.select(isHelloBarVisible);
  triggerAnimation$ = new BehaviorSubject<boolean>(false);
  activeMessages$ = new BehaviorSubject<SanityHelloBarContentItem[]>(null);
  isMobile$ = this.responsive.isMobile$;

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly responsive: ResponsiveService,
  ) {}

  init() {
    this.helloBar$.pipe(filter(d => Boolean(d), take(1))).subscribe(data => {
      const dismissedValue = localStorage.getItem(this.LS_KEY);
      const isDismissed = dismissedValue && data?.publishedAt === dismissedValue;

      if (!isDismissed) {
        this.trackRoutes();
        this.trackDataUpdate();
      }
    });
  }

  private trackDataUpdate() {
    // check for new values (example: language change)
    this.updatesSubscription = this.helloBar$.pipe(filter(d => Boolean(d))).subscribe(data => this.startWithData(data));
  }

  private trackRoutes() {
    this.routerSubscription = this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        const isEditorUrl = this.isEditorUrl(e.urlAfterRedirects);
        const shouldRestartInterval = this.isCameFromEditor && !isEditorUrl;

        if (isEditorUrl) {
          clearInterval(this.animationInterval);
        }

        if (shouldRestartInterval) {
          this.helloBar$.pipe(take(1)).subscribe(d => this.startWithData(d));
        }

        this.isCameFromEditor = isEditorUrl;
        this.setVisibility(!isEditorUrl);
      }
    });
  }

  loadData() {
    this.store.dispatch(loadHelloBar({}));
  }

  goTo(route: string, message: string) {
    this.helloBar$.pipe(take(1)).subscribe(data => {
      logEvent('Dashboard Hello Bar Interacted', {
        link: route,
        referrer: location.pathname,
        title: message,
      });

      if (/^https?:\/\//.test(route)) {
        window.open(route, '_blank');
      } else {
        this.router.navigate([route]).then();
      }
    });
  }

  onDismiss(key: string) {
    this.updatesSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
    localStorage.setItem(this.LS_KEY, key);
    this.setVisibility(false);
  }

  setNewMessages() {
    this.helloBar$
      .pipe(
        filter(d => Boolean(d)),
        take(1),
        filter(({ content: messages }) => messages.length > 1),
      )
      .subscribe(({ content: messages }) => {
        const [index, index2] = this.currentMessageIndexes;
        this.activeMessages$.next([messages[index], messages[index2]]);
      });
  }

  private startWithData({ content: messages, seconds: intervalTimeout }: SanityHelloBar) {
    if (messages.length <= 1) {
      return this.activeMessages$.next([messages[0]]);
    }

    const [index, index2] = this.currentMessageIndexes;
    this.activeMessages$.next([messages[index], messages[index2]]);

    clearInterval(this.animationInterval);
    this.animationInterval = setInterval(() => {
      this.triggerAnimation$.next(true);
      const [index, index2] = this.currentMessageIndexes;
      const newIndex = this.getIndex(index, messages.length - 1);
      const newIndex2 = this.getIndex(index2, messages.length - 1);
      this.currentMessageIndexes = [newIndex, newIndex2];
    }, intervalTimeout * this.ONE_SEC_TO_MS);
  }

  private getIndex(index, maxIndex) {
    return index + 1 > maxIndex ? 0 : index + 1;
  }

  private isEditorUrl(url: string) {
    const routesWithNoBar = [
      '/print-file-editor',
      '/catalogue/stores/current',
      '/stores/current/products',
      '/on-boarding/questionnaire',
    ];
    return routesWithNoBar.some(part => url.includes(part));
  }

  private setVisibility(state: boolean) {
    this.store.dispatch(setHelloBarVisibility({ payload: state }));
  }
}
