import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FinancialOrderStatus } from '../../orders/lib/financial-order-status.enum';

@Component({
  selector: 'gd-order-financial-status-label',
  templateUrl: './order-financial-status-label.component.html',
  styleUrls: ['./order-financial-status-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderFinancialStatusLabelComponent implements OnInit {
  @Input() status: FinancialOrderStatus = null;
  @Input() noShrink = false;
  @Input() fluid = true;

  constructor() {}

  ngOnInit() {}

  get color() {
    switch (this.status) {
      case FinancialOrderStatus.TO_BE_INVOICED:
      case FinancialOrderStatus.PENDING:
      case FinancialOrderStatus.PAID:
      case FinancialOrderStatus.INVOICED:
        return 'grey';
      case FinancialOrderStatus.REFUNDED:
      case FinancialOrderStatus.PARTIALLY_REFUNDED:
      case FinancialOrderStatus.CANCELED:
        return 'orange';
      case FinancialOrderStatus.REFUSED:
        return 'red';
      default:
        return null;
    }
  }
}
