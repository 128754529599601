import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Promotion } from '../../shared/voucher/voucher';

@Component({
  selector: 'gd-discount-item',
  templateUrl: './discount-item.component.html',
  styleUrls: ['./discount-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DiscountItemComponent {
  @Input() discount: Promotion;
  @Input() orderDiscount = null;
}
