<ng-select
  class="select hide-tags"
  [items]="items$ | async"
  [disabled]="isSelectedItemsCountLimitReached$ | async"
  [multiple]="true"
  [closeOnSelect]="false"
  [clearable]="false"
  [clearSearchOnAdd]="true"
  [placeholder]="placeholder"
  [ngModel]="selectedTags$ | async"
  [editableSearchTerm]=""
  [maxSelectedItems]="maxSelectedItemsCount$ | async"
  [inputAttrs]="inputAttrs$ | async"
  notFoundText=""
  bindLabel="title"
  (keyup.,)="onSubmit()"
  (keyup.enter)="onEnterPress()"
  (search)="onSearch($event)"
  (add)="clearSearch()"
  (ngModelChange)="onChangeSelected($event)"
>
  <ng-template ng-header-tmp>
    <div class="header-content" *ngIf="newTag && canAddNewItems" (click)="onSubmit()">
      <img src="/assets/icons/radiobutton-add.svg" />
      <p>{{ newTag }}</p>
    </div>
  </ng-template>

  <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
    <div class="item" [class.disabled]="(isSelectedItemsCountLimitReached$ | async) && !item$.selected">
      <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" /> {{ item.title }}
    </div>
  </ng-template>
</ng-select>
<div>
  <gc-tag *ngFor="let tag of selectedTags$ | async" [tag]="tag" (close)="unselect(tag)"> </gc-tag>
</div>
