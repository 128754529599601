import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  deleteFail,
  deleteStart,
  deleteSuccess,
  fetchFail,
  fetchIntent,
  fetchStart,
  fetchSuccess,
  setBackupFail,
  setBackupStart,
  setBackupSuccess,
  setDefaultFail,
  setDefaultStart,
  setDefaultSuccess,
} from './payment-details.actions';
import { of } from 'rxjs';
import { PaymentDetailsFacade } from './payment-details.facade';

@Injectable()
export class PaymentDetailsEffects {
  fetchIntent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchIntent),
      withLatestFrom(this.facade.isFetchingToBeDone$),
      filter(([action, toBeDone]) => action.force || toBeDone),
      map(([action]) => action),
      map(() => fetchStart()),
    ),
  );

  fetchStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fetchStart),
      switchMap(action =>
        this.facade.search().pipe(
          map(res => fetchSuccess({ list: res.paymentDetails })),
          catchError(err => of(fetchFail(err))),
        ),
      ),
    ),
  );

  deleteStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteStart),
      switchMap(action =>
        this.facade.delete(action.id).pipe(
          map(() => deleteSuccess({ id: action.id })),
          catchError(err => of(deleteFail(err))),
        ),
      ),
    ),
  );

  setDefaultStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setDefaultStart),
      switchMap(action =>
        this.facade.setUsageOrder(action.id, { isDefault: true, isBackup: false }).pipe(
          mergeMap(() => [setDefaultSuccess({ id: action.id }), fetchIntent({ force: true })]),
          catchError(err => of(setDefaultFail(err))),
        ),
      ),
    ),
  );

  setBackupStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBackupStart),
      switchMap(action =>
        this.facade.setUsageOrder(action.id, { isDefault: false, isBackup: true }).pipe(
          mergeMap(() => [setBackupSuccess({ id: action.id }), fetchIntent({ force: true })]),
          catchError(err => of(setBackupFail(err))),
        ),
      ),
    ),
  );

  constructor(private readonly actions$: Actions, private readonly facade: PaymentDetailsFacade) {}
}
