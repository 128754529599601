export enum GelatoPlusPopupLocations {
  counter = 'counter',
  editor = 'editor',
  appList = 'app-list',
  ordersList = 'orders-list',
  orderDetails = 'orders-details',
  usersList = 'users-list',
  appDetails = 'app-details',
  storesList = 'stores-list',
  storeProductsList = 'stores-products-list',
  productDetails = 'product-details',
  storeDetails = 'store-details',
  homeScreen = 'home-screen',
  checkoutShippingStep = 'checkout-shipping-step',
  checkoutReviewStep = 'checkout-review-step',
  checkoutProductStep = 'checkout-product-step',
  checkoutSuccessStep = 'checkout-success-step',
  brandingPage = 'branding-page',
}
