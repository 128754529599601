import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { Observable, of } from 'rxjs';
import {
  EProductValidationError,
  EProductValidationErrorCode,
  EProductValidationResponse,
} from '@gelato-api-ui/core/e-commerce/e-product-validation-response';
import { catchError, first, map } from 'rxjs/operators';
import { ShowNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { ECommerceProductGraphQlService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-graph-ql.service';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { BulkConnectVariantsMappingPayload } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { ECommerceProductDataMappingService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-data-mapping.service';
import { EProductData } from '@gelato-api-ui/core/e-commerce/e-product-data';
import { EProduct } from '@gelato-api-ui/core/e-commerce/e-product';
import { ECommerceProductValidationErrorMessageService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-validation-error-message.service';

@Injectable({ providedIn: 'root' })
export class ECommerceProductValidationService {
  eStore: EStore = null;

  constructor(
    private readonly store: Store<AppState>,
    private readonly eCommerceProductGraphQlService: ECommerceProductGraphQlService,
    private readonly eCommerceProductDataMappingService: ECommerceProductDataMappingService,
    private readonly eCommerceProductValidationErrorMessageService: ECommerceProductValidationErrorMessageService,
  ) {}

  validateProductData(
    clientId: string,
    eStore: EStore,
    productData: EProductData,
    mode: EProductWizardMode,
  ): Observable<boolean> {
    const product: EProductWithVariants = this.eCommerceProductDataMappingService.toEProductWithVariants(productData);

    return this.eCommerceProductGraphQlService.validateProduct(clientId, product, mode).pipe(
      first(),
      catchError(() => of(this.getGeneralValidationErrorResponse())),
      map((response: EProductValidationResponse): boolean =>
        this.processValidationResponse(eStore, productData, mode, response),
      ),
    );
  }

  validateBulkConnectProductVariants(
    clientId: string,
    eStore: EStore,
    sourceProductData: EProductData,
    destinationProductId: string,
    mapping: BulkConnectVariantsMappingPayload,
    mode: EProductWizardMode,
  ): Observable<boolean> {
    const sourceProduct: EProductWithVariants =
      this.eCommerceProductDataMappingService.toEProductWithVariants(sourceProductData);

    return this.eCommerceProductGraphQlService
      .validateBulkConnectProductVariants(clientId, sourceProduct, destinationProductId, mapping, mode)
      .pipe(
        first(),
        catchError(() => of(this.getGeneralValidationErrorResponse())),
        map((response: EProductValidationResponse): boolean =>
          this.processValidationResponse(eStore, sourceProductData, mode, response),
        ),
      );
  }

  private processValidationResponse(
    eStore: EStore,
    productData: EProductData,
    mode: EProductWizardMode,
    validateResponse: EProductValidationResponse,
  ): boolean {
    this.eStore = eStore;

    const timeOut = 12 * 1000;
    const extendedTimeOut = 8 * 1000;
    const { errors } = validateResponse;

    if (errors && errors.length) {
      this.trackValidationError(productData.product, mode, errors);
    }

    if (errors && errors.length) {
      this.store.dispatch(
        new ShowNotification({
          message: this.eCommerceProductValidationErrorMessageService.getFormattedErrorMessage(errors),
          type: 'error',
          timeout: timeOut,
          extendedTimeOut,
          enableClose: true,
          tapToDismiss: false,
          messageClass: 'validation-message',
        }),
      );
      return false;
    } else {
      return true;
    }
  }

  private trackValidationError(eProduct: EProduct, mode: EProductWizardMode, errorCodes: EProductValidationError[]) {
    this.trackEvent('storeProductValidationFailed', mode, {
      productId: eProduct.id,
      productName: eProduct.productName,
      errorGroups: errorCodes.map(err => err.code),
    });
  }

  private trackEvent(eventType: string, mode: EProductWizardMode, eventProperties: object) {
    const selectedStore = this.eStore;

    logEvent(eventType, {
      storeType: selectedStore ? selectedStore.type : null,
      storeName: selectedStore ? selectedStore.name : null,
      storeId: selectedStore ? selectedStore.id : null,
      mode,
      ...eventProperties,
    });
  }

  private getGeneralValidationErrorResponse(): EProductValidationResponse {
    return {
      errors: [
        {
          code: EProductValidationErrorCode.GENERAL_ERROR,
          data: null,
        },
      ],
    };
  }
}
