export interface SanityProductVariant {
  productUid: string;
  preview: string;
  imperial: boolean;
  CoatingType: string;
  ColorType: string;
  FrameColor: string;
  FrameAssembling: SanityProductVariantFrameAssembling;
  FrameMaterial: string;
  FrameProperties: string;
  FrameSize: string;
  WallHangerColor: string;
  WallHangerMaterial: string;
  wallpaperMaterial: string;
  wallpaperType: SanityProductVariantWallpaperType;
  WallHangerProperties: string;
  WallHangerSize: string;
  GlassType: string;
  Orientation: string;
  FormatSize: string;
  PaperFormat: string;
  PaperType: string;
  ProductStatus: string;
  ProtectionType: SanityProductVariantProtectionType;
  State: string;
  GarmentCategory: string;
  GarmentColor: string;
  GarmentCut: string;
  GarmentPrint: string;
  GarmentQuality: string;
  GarmentSize: string;
  GarmentSubcategory: string;
  CanvasFrame: string;
  CanvasThicknessType: string;
  BindingType: string;
  MugMaterial: string;
  FoamColor: string;
  FoamThickness: string;
  WoodThickness: string;
  PagesCount: string;
  BagColor: string;
  BagPrint: string;
  BagQuality: string;
  BagSize: string;
  BagSubCategory: string;
  PhoneModel: string;
  PhoneBrand: string;
  hasFlatShippingPriceType: boolean;
  digitalDimensions: Record<string, string>;
}

export enum SanityProductVariantProtectionType {
  none = 'none',
  first = '1-0',
}

export enum SanityProductVariantWallpaperType {
  fullWall = 'fullWall',
  sample = 'sample',
}

export enum SanityProductVariantFrameAssembling {
  notMounted = 'not_mounted',
  mounted = 'mounted',
}

export enum SanityProductVariantOrientation {
  horizontal = 'hor',
  vertical = 'ver',
}

export enum SanityProductVariantBindingType {
  wireOTop = 'wire-o-top',
  wireWithHookTop = 'wire-with-hook-top',
}
