import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { ClientsApiService } from '@gelato-api-ui/core/clients/services/clients-api.service';
import * as actions from './client-selection-list.actions';
import { ClientSearchResponse } from '@gelato-api-ui/core/clients/client-search-response';
import { ClientSearchRequest } from '@gelato-api-ui/core/clients/client-search-request';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';

@Injectable()
export class ClientSelectionListEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private service: ClientsApiService,
    private authService: AuthService,
  ) {}

  loadStart$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoadStart>(actions.ClientSelectionListAction.LoadStart),
      withLatestFrom(this.authService.hasReadPermission('identity', 'client')),
      filter(([, permissions]) => !!permissions),
      switchMap(([{ searchRequest }]) =>
        this.service.search(searchRequest).pipe(
          catchError(() => {
            this.store$.dispatch(new ShowGeneralErrorNotification());

            return null;
          }),
          map((clientsResponse: ClientSearchResponse) => new actions.LoadSuccess(clientsResponse.data)),
        ),
      ),
    ),
  );

  loadClientsByIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoadClientsByIds>(actions.ClientSelectionListAction.LoadClientsByIds),
      filter(({ ids }) => {
        const clientSearchRequest = new ClientSearchRequest();
        clientSearchRequest.ids = Array.from(new Set(ids)).filter(i => !!i);
        return Boolean(clientSearchRequest.ids && clientSearchRequest.ids.length);
      }),
      map(({ ids }) => {
        const clientSearchRequest = new ClientSearchRequest();
        clientSearchRequest.ids = Array.from(new Set(ids)).filter(i => !!i);
        return new actions.LoadStart(clientSearchRequest);
      }),
    ),
  );
}
