import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryProductSegmentRoutedComponent } from '@product-catalogue/src/lib/category-shared/category-product-segment-routed/category-product-segment-routed.component';
import { CategoryProductSegmentComponent } from '@product-catalogue/src/lib/category-shared/category-product-segment/category-product-segment.component';
import { CatalogProductPreviewVariantsComponent } from '@product-catalogue/src/lib/category-shared/catalog-product-preview-variants/catalog-product-preview-variants.component';
import { PipesModule } from '@product-catalogue/src/lib/shared/pipes/pipes/pipes.module';
import { SdkSharedModule } from '@gelato-api-ui/sdk/src/lib/shared/sdk-shared.module';
import { RouterModule } from '@angular/router';
import { ProductBadgeComponent } from '@product-catalogue/src/lib/category-shared/product-badge/product-badge.component';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { UiKitModule } from '@gelato-api-ui/ui-kit/src';
import { MountedFramedPosterAvailableBadgeComponent } from '@product-catalogue/src/lib/category-shared/mounted-framed-poster-available-badge/mounted-framed-poster-available-badge.component';
import { MountedFramedPosterAvailableLabelComponent } from '@product-catalogue/src/lib/category-shared/mounted-framed-poster-available-label/mounted-framed-poster-available-label.component';

const exportedComponents = [
  CategoryProductSegmentRoutedComponent,
  CategoryProductSegmentComponent,
  CatalogProductPreviewVariantsComponent,
  ProductBadgeComponent,
  MountedFramedPosterAvailableBadgeComponent,
  MountedFramedPosterAvailableLabelComponent,
];

@NgModule({
  declarations: exportedComponents,
  exports: exportedComponents,
  imports: [CommonModule, PipesModule, SdkPipesModule, SdkSharedModule, RouterModule, SuiModule, UiKitModule],
})
export class CategorySharedModule {}
