import { resetDate } from '@api-ui-app/src/app/lib/resetDate';
import { addHours, addMinutes, parseISO } from 'date-fns';

export const convertIso8601ToGmtDate = (iso8601: string, resetTime = true): Date => {
  const timezoneOffset = new Date().getTimezoneOffset();

  let date = parseISO(iso8601);

  date = addHours(date, Math.floor(timezoneOffset / 60));
  date = addMinutes(date, timezoneOffset % 60);

  if (resetTime) {
    date = resetDate(date);
  }

  return date;
};
