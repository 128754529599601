import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'gc-actions-panel-step-separator',
  templateUrl: './actions-panel-step-separator.component.html',
  styleUrls: ['./actions-panel-step-separator.component.scss'],
})
export class ActionsPanelStepSeparatorComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
