export enum OnBoardingStep {
  OrderSample,
  ConnectStore,
  AddProduct,
}

export enum OnBoardingTrackingStep {
  OrderSample = 'order_sample',
  ConnectStore = 'connect_store',
  AddProduct = 'add_product',
}
