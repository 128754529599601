import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../app.state';
import { MAX_DISCOUNTED_ORDERS_COUNT, MAX_ONBOARDING_ORDERS_COUNT } from '../lib/constants';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { OrdersApiService } from '../orders/services/orders-api.service';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EStoreSearchResponse } from '@gelato-api-ui/core/e-commerce/e-store-search-response';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { EProductVariantConnectionStatus } from '@gelato-api-ui/core/e-commerce/e-product-variant-connection-status.enum';
import { EProductSearchResponse } from '@gelato-api-ui/core/e-commerce/e-product-search-response';
import { ECommerceStoresApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-stores-api.service';
import { ECommerceProductsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-products-api.service';
import * as actions from './account-activation-steps.actions';
import * as companyDetailsActions from './company-details.actions';
import { getSelectedClientId } from './auth.reducer';
import { PaymentDetailsFacade } from '../payment-methods/wallets/+state/payment-details/payment-details.facade';
import { getDefaultStoresListRequest } from '@gelato-api-ui/core/e-commerce/helpers/getDefaultStoresListRequest';

@Injectable()
export class AccountActivationStepsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private authService: AuthService,
    private ordersApiService: OrdersApiService,
    private eCommerceStoresApiService: ECommerceStoresApiService,
    private eCommerceProductsApiService: ECommerceProductsApiService,
    private paymentDetailsFacade: PaymentDetailsFacade,
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.Load>(actions.AccountActivationStepsActionTypes.Load),
        switchMap(() => {
          this.store$.dispatch(new companyDetailsActions.Load());
          this.store$.dispatch(new actions.LoadPaidOrdersCount());
          this.store$.dispatch(new actions.LoadStores());
          this.paymentDetailsFacade.triggerFetchIntent();

          return of(null);
        }),
      ),
    { dispatch: false },
  );

  loadPaidOrdersCount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.Load>(actions.AccountActivationStepsActionTypes.LoadPaidOrdersCount),
        switchMap(() => {
          if (!this.authService.hasReadPermission('order', 'order')) {
            return of(null);
          }

          const limit = Math.max(MAX_DISCOUNTED_ORDERS_COUNT, MAX_ONBOARDING_ORDERS_COUNT + 1);

          return this.ordersApiService.getPaidOrdersCount(limit).pipe(
            tap((paidOrdersCount: number) => {
              this.store$.dispatch(new actions.SetPaidOrdersCount(paidOrdersCount));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadStores$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.Load>(actions.AccountActivationStepsActionTypes.LoadStores),
        withLatestFrom(this.store$.select(getSelectedClientId)),
        switchMap(([_, selectedClientId]) => {
          const clientId = selectedClientId ? selectedClientId : undefined;

          return this.eCommerceStoresApiService.search({ ...getDefaultStoresListRequest(clientId), limit: 2 }).pipe(
            map((response: EStoreSearchResponse): EStore[] => (response ? response.stores : [])),
            switchMap((stores: EStore[]) => {
              const firstAvailableStore: EStore = stores ? stores[0] : null;
              const hasMultipleStores = stores ? stores.length > 1 : null;

              this.store$.dispatch(new actions.SetFirstAvailableStore(firstAvailableStore));
              this.store$.dispatch(new actions.SetHasMultipleStoresFlag(hasMultipleStores));

              if (!firstAvailableStore) {
                return of(null);
              }

              return this.eCommerceProductsApiService
                .search({
                  storeId: firstAvailableStore.id,
                  connectionStatus: EProductVariantConnectionStatus.CONNECTED,
                  limit: 1,
                  offset: 0,
                })
                .pipe(
                  map((response: EProductSearchResponse): EProductAbbridged[] => (response ? response.products : [])),
                  tap((products: EProductAbbridged[]) => {
                    const firstAvailableProduct: EProductAbbridged = products ? products[0] : null;

                    this.store$.dispatch(new actions.SetFirstAvailableProduct(firstAvailableProduct));
                  }),
                );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
