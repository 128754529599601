<div *ngIf="items?.length" class="tab-links-container">
  <ng-container *ngFor="let item of items">
    <a
      *ngIf="item.routerLink"
      class="tab-link"
      [routerLink]="item.routerLink"
      [routerLinkActive]="'active'"
      (click)="onClick(item)"
    >
      {{ item.translationKey | translate }}
    </a>
    <a *ngIf="!item.routerLink" class="tab-link" [class.active]="item.isActive" (click)="onClick(item)">
      <img *ngIf="item.iconPath" class="icon" [src]="item.iconPath" />
      {{ item.translationKey | translate }}
    </a>
  </ng-container>
</div>
