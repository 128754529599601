import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';
import { ProductOption } from './product-option';
import { OrderProductPreview } from './order-product-preview';
import { OrderProductPrintJob } from './order-product-print-job';

export interface OrderItem {
  category: string;
  designId: string;
  eventLog: EventLogItem[];
  files: ItemFile[];
  finalProductUid: string;
  fulfillmentStatus: string;
  id: string;
  orderId: string;
  itemReferenceId: string;
  itemReferenceName: string;
  metadata: MetadataItem[];
  options: ProductOption[];
  pageCount: number;
  previews: OrderProductPreview[];
  printJobs: OrderProductPrintJob[];
  processedFileUrl: string;
  productFileMimeType: string;
  productTypeUid: string;
  productCategoryUid: string;
  productNameUid: string;
  productUid: string;
  productName: string;
  quantity: number;
  price: number;
  retailPriceInclVat: number;
  attributes: OrderItemAttribute[];
  isIgnored: boolean;
  customTrim?: ItemCustomTrim;
  storeProductId: string;
  storeProductVariantId: string;
}

export interface ItemFile {
  type: ItemFileType;
  url: string;
}

export enum ItemFileType {
  default = 'default',
}

export interface OrderItemAttribute {
  formattedValue: string;
  name: string;
  title: string;
  value: string;
}

export interface EventLogItem {
  date: string;
  message: string;
}

export interface OrderItemIdToItemData {
  name: string;
  preview: string;
}

export interface ItemCustomTrim {
  widthMm: number;
  heightMm: number;
}
