import { HEADER_NAME_GELATO_CLIENT_ID } from './constants';

export function decorateHeadersWithClientId(headers: { [key: string]: string }, clientId: string) {
  if (!!clientId) {
    return {
      ...(headers || {}),
      [HEADER_NAME_GELATO_CLIENT_ID]: clientId,
    };
  }

  return headers;
}
