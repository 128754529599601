import { Action } from '@ngrx/store';
import { State } from './company-details.reducer';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { Question } from '@gelato-api-ui/core/quick-questions/types/question';

export enum CompanyDetailsActionTypes {
  Load = '[CompanyDetails] Load',
  Save = '[CompanyDetails] Save',
  ResetState = '[CompanyDetails] Reset State',
  SetState = '[CompanyDetails] Set State',
  SetCompanyDetails = '[CompanyDetails] Set Company Details',
  SetQuestionnaire = '[CompanyDetails] Set Questionnaire',
}

export class Load implements Action {
  readonly type = CompanyDetailsActionTypes.Load;

  constructor(public forced = false) {}
}

export class Save implements Action {
  readonly type = CompanyDetailsActionTypes.Save;

  constructor(public payload: CompanyDetails) {}
}

export class ResetState implements Action {
  readonly type = CompanyDetailsActionTypes.ResetState;
}

export class SetState implements Action {
  readonly type = CompanyDetailsActionTypes.SetState;

  constructor(public payload: State) {}
}

export class SetCompanyDetails implements Action {
  readonly type = CompanyDetailsActionTypes.SetCompanyDetails;

  constructor(public payload: CompanyDetails) {}
}

export class SetQuestionnaire implements Action {
  readonly type = CompanyDetailsActionTypes.SetQuestionnaire;

  constructor(public payload: Question[]) {}
}

export type CompanyDetailsActions = Load | ResetState | SetState | SetCompanyDetails | SetQuestionnaire;
