import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { DropdownService } from '@gelato-api-ui/ui-kit/src/lib/dropdown/services';
import { Locale, LocaleCode, LocaleList } from '@gelato-api-ui/core/i18n/locales.constant';
import { DropdownContentType } from '@gelato-api-ui/ui-kit/src/lib/dropdown/types';
import { DeckService } from '@gelato-api-ui/ui-kit/src/lib/deck/services';
import { DeckContentType } from '@gelato-api-ui/ui-kit/src/lib/deck/types';
import { DropdownRef } from '@gelato-api-ui/ui-kit/src/lib/dropdown/components/dropdown-ref/dropdown-ref';
import { DeckRef } from '@gelato-api-ui/ui-kit/src/lib/deck/components/deck-ref/deck-ref';
import { SUPPORTED_CURRENCIES } from '@api-ui-app/src/app/lib/constants';

interface Currency {
  code: string;
  title: string;
}

@Component({
  selector: 'gd-user-preferences-menu',
  templateUrl: './user-preferences-menu.component.html',
  styleUrls: ['./user-preferences-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserPreferencesMenuComponent implements OnInit, OnDestroy {
  @Input() currentLocaleCode: string;
  @Input() currentCurrency: string;
  @Input() isMobile: boolean;
  @Input() showLanguageOnly = false;
  @Output() changeLocaleCode = new EventEmitter<LocaleCode>();
  @Output() changeCurrency = new EventEmitter<string>();

  @ViewChild('origin') origin: ElementRef;
  @ViewChild('rootListContent') rootListContent: ElementRef;
  @ViewChild('langListContent') langListContent: ElementRef;

  ngOnDestroy$ = new Subject();

  public languagesList = LocaleList;
  public currenciesList: Currency[];

  private dropdownRef: DropdownRef;
  private deckRef: DeckRef;

  constructor(
    private readonly translateService: TranslateService,
    private readonly dropdown: DropdownService,
    private readonly deck: DeckService,
  ) {}

  get currentLocale(): Locale {
    return this.languagesList.find(lang => lang.code === this.currentLocaleCode);
  }

  ngOnInit() {
    this.initCurrenciesList();

    this.translateService.onLangChange
      .pipe(
        takeUntil(this.ngOnDestroy$),
        tap(() => {
          this.initCurrenciesList();
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }

  initCurrenciesList() {
    this.currenciesList = SUPPORTED_CURRENCIES.map((currency: string) => ({
      code: currency,
      title: this.translateService.instant(`txt_currency_${currency}`),
    }));
  }

  onOpenSwitcher(content: DropdownContentType, origin: HTMLElement | ElementRef): void {
    this.onCloseSwitcher();

    if (this.isMobile) {
      this.onOpenMobileSwitcher(content);
    } else {
      this.onOpenDesktopSwitcher(content, origin);
    }
  }

  onChangeLocaleCode(value: LocaleCode): void {
    this.changeLocaleCode.emit(value);
    this.onCloseSwitcher();
  }

  onChangeCurrency(value: string): void {
    this.changeCurrency.emit(value);
    this.onCloseSwitcher();
  }

  onCloseSwitcher() {
    if (this.dropdownRef) {
      this.dropdownRef.triggerCloseDropdown();
    }

    if (this.deckRef) {
      this.deckRef.triggerCloseDeck();
    }
  }

  onOpenMenu() {
    if (this.showLanguageOnly) {
      this.onOpenSwitcher(this.langListContent, this.origin);
    } else {
      this.onOpenSwitcher(this.rootListContent, this.origin);
    }
  }

  private onOpenDesktopSwitcher(content: DropdownContentType, origin: HTMLElement | ElementRef): void {
    if (this.dropdownRef) {
      this.dropdownRef.close();
    }

    this.dropdownRef = this.dropdown.open({
      content,
      origin,
      width: 250,
      height: 'auto',
      position: 'end',
    });
  }

  private onOpenMobileSwitcher(content: DeckContentType): void {
    this.deckRef = this.deck.open({
      content,
      width: '100%',
      height: 'auto',
    });
  }
}
