import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { SanityProductControl } from '@gelato-api-ui/core/sanity/sanity-product-control';
import {
  SanityProductVariation,
  SanityProductVariationCoatingSides,
} from '@gelato-api-ui/core/sanity/sanity-product-variation';
import { SanityProductResponse } from '@gelato-api-ui/core/sanity/sanity-product-response';
import {
  SanityProductVariant,
  SanityProductVariantProtectionType,
} from '@gelato-api-ui/core/sanity/sanity-product-variant';
import { SanityProductFilter } from '@gelato-api-ui/core/sanity/sanity-product-filter';

export function mapSanityProductResponse(response: SanityProductResponse): SanityProduct {
  return {
    id: response.id,
    title: response.title,
    subtitle: response.subtitle,
    descriptionShort: response.descriptionShort,
    description: response.description,
    nameProductFor1: response.nameProductFor1,
    nameProductFor10: response.nameProductFor10,
    sustainability: response.sustainability,
    material: response.material,
    brands: response.brands,
    fulfilment: response.fulfilment,
    packaging: response.packaging,
    faq: response.faq,
    uspArea: response.uspArea,
    quality: response.quality,
    sizeGuide: response.sizeGuide,
    printAreas: response.printAreas,
    productUidForPrice: response.productUidForPrice,
    productCareInstructions: response.productCareInstructions,
    fileRequirements: response.fileRequirements,
    productAttributes: response.productAttributes,
    productImages: response.productImages,
    productControls: response?.filters?.map(filter => mapProductFilterToControl(filter)),
    productVariations: response?.variants?.map(variant => mapProductVariantToVariation(variant)),
    productBadge: response.productBadge,
    hasMountedFramedPosters: response.hasMountedFramedPosters,
    pages: response.pages,
    similar: response.similar,
    highlight: response.highlight,
    ecoCredentials: response.ecoCredentials,
    fileSpecifications: response.fileSpecifications,
    videoId: response.videoId,
    instructionSteps: response.instructionSteps,
    instructionDetailsLink: response.instructionDetailsLink,
    extraPrintAreas: response.extraPrintAreas,
    printAreasSpecifications: response.printAreasSpecifications,
    digitalDimensions: response.digitalDimensions,
    productVariationSpecificContent: response.productVariationSpecificContent,
  } as SanityProduct;
}
export function mapProductFilterToControl(filter: SanityProductFilter): SanityProductControl {
  return {
    key: productControlMapper[filter.key] || filter.key.toLowerCase(),
    title: filter.title,
    options: filter.options,
  };
}

const productControlMapper = {
  PaperFormat: 'formatsize',
  wallpaperMaterial: 'wallpaperMaterial',
  wallpaperType: 'wallpaperType',
};

function mapProductVariantToVariation(variant: SanityProductVariant): SanityProductVariation {
  return {
    formatsize: variant.FormatSize,
    coatingsides: mapCoatingSides[variant.ProtectionType],
    coatingtype: variant.CoatingType,
    colortype: variant.ColorType,
    orientation: variant.Orientation,
    papertype: variant.PaperType,
    framecolor: variant.FrameColor,
    frameassembling: variant.FrameAssembling,
    wallhangercolor: variant.WallHangerColor,
    wallhangermaterial: variant.WallHangerMaterial,
    wallpaperMaterial: variant.wallpaperMaterial,
    wallpaperType: variant.wallpaperType,
    // wallhangerproperties: variant.WallHangerProperties,
    // wallhangersize: variant.WallHangerSize,
    garmentcolor: variant.GarmentColor,
    garmentprint: variant.GarmentPrint,
    garmentsize: variant.GarmentSize,
    productUid: variant.productUid,
    preview: variant.preview,
    canvasframe: variant.CanvasFrame,
    canvasthicknesstype: variant.CanvasThicknessType,
    garmentcategory: variant.GarmentCategory,
    garmentcut: variant.GarmentCut,
    garmentquality: variant.GarmentQuality,
    garmentsubcategory: variant.GarmentSubcategory,
    bindingtype: variant.BindingType,
    mugmaterial: variant.MugMaterial,
    foamcolor: variant.FoamColor,
    foamthickness: variant.FoamThickness,
    woodthickness: variant.WoodThickness,
    pagescount: variant.PagesCount,
    bagcolor: variant.BagColor,
    bagprint: variant.BagPrint,
    bagquality: variant.BagQuality,
    bagsize: variant.BagSize,
    bagsubcategory: variant.BagSubCategory,
    phonebrand: variant.PhoneBrand,
    phonemodel: variant.PhoneModel,
    hasFlatShippingPriceType: variant.hasFlatShippingPriceType,
    digitalDimensions: variant.digitalDimensions,
  };
}

const mapCoatingSides = {
  [SanityProductVariantProtectionType.none]: SanityProductVariationCoatingSides.none,
  [SanityProductVariantProtectionType.first]: SanityProductVariationCoatingSides.first,
};
