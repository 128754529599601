import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';

@Component({
  selector: 'gd-store-type-icon',
  templateUrl: './store-type-icon.component.html',
  styleUrls: ['./store-type-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreTypeIconComponent {
  @Input() storeType: EStoreType = null;
  @Input() size = 32;
}
