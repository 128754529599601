import { Action } from '@ngrx/store';
import { State } from './e-commerce-products-tree.reducer';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';

export enum ECommerceProductsTreeActionTypes {
  Load = '[ECommerceProductsTree] Load',
  LoadSingleProduct = '[ECommerceProductsTree] Load Single Product',
  ResetState = '[ECommerceProductsTree] Reset State',
  SetState = '[ECommerceProductsTree] Set State',
}

export class Load implements Action {
  readonly type = ECommerceProductsTreeActionTypes.Load;

  constructor(public store: EStore, public title: string, public clearList: boolean = true) {}
}

export class LoadSingleProduct implements Action {
  readonly type = ECommerceProductsTreeActionTypes.LoadSingleProduct;

  constructor(public store: EStore, public productId: string) {}
}

export class ResetState implements Action {
  readonly type = ECommerceProductsTreeActionTypes.ResetState;
}

export class SetState implements Action {
  readonly type = ECommerceProductsTreeActionTypes.SetState;

  constructor(public payload: State) {}
}

export type ECommerceProductsTreeActions = Load | LoadSingleProduct | ResetState | SetState;
