import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClientSelectorComponent } from '@gelato-api-ui/core/client-selector/components/client-selector/client-selector.component';
import { ClientIdSelectorComponent } from '@gelato-api-ui/core/client-selector/components/client-id-selector/client-id-selector.component';
import { ClientDescriptionSelectorComponent } from '@gelato-api-ui/core/client-selector/components/client-description-selector/client-description-selector.component';

const exportedComponents = [ClientSelectorComponent, ClientIdSelectorComponent, ClientDescriptionSelectorComponent];

@NgModule({
  declarations: [...exportedComponents],
  imports: [CommonModule, NgSelectModule, TranslateModule, FormsModule],
  exports: [...exportedComponents],
})
export class ClientSelectorModule {}
