import { ServicePlanUniqueNames } from '../../types/service-plan-unique-names';
import { ServicePlanResponse } from '../../types/subscription-plans';
import { ServiceUniqueName } from '../../types/subscription-price-plans';
import { SubscriptionResponse, SubscriptionStatus } from '../../types/subscriptions';

export const getSubscriptionByStatus = (
  activeSubscriptions: SubscriptionResponse[],
  status: SubscriptionStatus,
  gelatoPlusPlanServiceId: string,
  checkTrialEnd: boolean = false,
) =>
  activeSubscriptions?.find(subscription =>
    checkTrialEnd
      ? subscription.trialEnd &&
        subscription?.plan?.servicePlanId === gelatoPlusPlanServiceId &&
        subscription.status === status
      : subscription?.plan?.servicePlanId === gelatoPlusPlanServiceId && subscription.status === status,
  );

export const getSubscriptionsByStatuses = (
  activeSubscriptions: SubscriptionResponse[],
  statuses: SubscriptionStatus[],
  gelatoPlusPlanServiceId: string,
): SubscriptionResponse[] =>
  activeSubscriptions?.filter(
    subscription =>
      subscription?.plan?.servicePlanId === gelatoPlusPlanServiceId && statuses.includes(subscription.status),
  );

export const findGelatoPlusServicePlanId = activeServicePlans =>
  activeServicePlans?.find(
    (loopServicePlan: ServicePlanResponse) =>
      loopServicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM &&
      [ServicePlanUniqueNames.gelato_platform_plus, ServicePlanUniqueNames.gelato_platform_plus_legacy].includes(
        loopServicePlan.uniqueName as ServicePlanUniqueNames,
      ),
  )?.id;

export const findGelatoPlusGoldServicePlanId = activeServicePlans =>
  activeServicePlans?.find(
    (loopServicePlan: ServicePlanResponse) =>
      loopServicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM &&
      [ServicePlanUniqueNames.gelato_platform_gold].includes(loopServicePlan.uniqueName as ServicePlanUniqueNames),
  )?.id;

export const findGelatoPlusOrGoldServicePlanId = activeServicePlans =>
  activeServicePlans?.find(
    (loopServicePlan: ServicePlanResponse) =>
      loopServicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM &&
      [
        ServicePlanUniqueNames.gelato_platform_plus,
        ServicePlanUniqueNames.gelato_platform_plus_legacy,
        ServicePlanUniqueNames.gelato_platform_gold,
      ].includes(loopServicePlan.uniqueName as ServicePlanUniqueNames),
  )?.id;

export const findExpandCategoriesServicePlanId = activeServicePlans =>
  activeServicePlans?.find(
    (loopServicePlan: ServicePlanResponse) =>
      loopServicePlan.serviceUniqueName === ServiceUniqueName.APP_EXPAND_CATEGORIES &&
      [ServicePlanUniqueNames.app_expand_categories_plan_0].includes(
        loopServicePlan.uniqueName as ServicePlanUniqueNames,
      ),
  )?.id;

export const findEComFeatureServicePlanId = (activeServicePlans, plan: ServicePlanUniqueNames) =>
  activeServicePlans?.find(
    (loopServicePlan: ServicePlanResponse) =>
      loopServicePlan.serviceUniqueName === ServiceUniqueName.APP_E_COMMERCE &&
      [plan].includes(loopServicePlan.uniqueName as ServicePlanUniqueNames),
  )?.id;
