import { createReducer, on } from '@ngrx/store';
import {
  loadProfitCalculatorSanityContent,
  loadProfitCalculatorSanityContentSuccess,
} from '@api-ui-app/src/app/profit-calculator/+state/profit-calculator.actions';
import {
  ProfitCalculator,
  ProfitCalculatorPlatformItem,
  SubscriptionPlanItem,
} from '@api-ui-app/src/app/profit-calculator/types/profit-calculator';

export interface State {
  loading: boolean;
  sanity: ProfitCalculator;
  platform: ProfitCalculatorPlatformItem[];
  subscriptions: SubscriptionPlanItem[];
}

export const initialState: State = {
  loading: false,
  sanity: null,
  platform: null,
  subscriptions: null,
};

export const reducer = createReducer(
  initialState,
  on(loadProfitCalculatorSanityContent, state => ({
    ...state,
    loading: true,
  })),
  on(loadProfitCalculatorSanityContentSuccess, (state, action) => ({
    ...state,
    loading: false,
    sanity: action.payload.sanity,
    platform: action.payload.platforms,
    subscriptions: action.payload.subscriptions,
  })),
);
