import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SplashMessageState } from './splash-message.adapter';
import { splashMessageFeatureName } from './splash-message.feature';

const getSplashMessageState = createFeatureSelector<SplashMessageState>(splashMessageFeatureName);

export const getAll = createSelector(getSplashMessageState, state => state.entities);

export const byId = (id: string) => createSelector(getAll, all => all[id]);

export const query = {
  byId,
  getAll,
};
