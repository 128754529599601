<div class="field">
  <sui-radio-button
    *ngFor="let loopClientRelationType of clientRelationTypes"
    name="clientRelationType"
    value="{{ loopClientRelationType }}"
    [isDisabled]="isDisabled"
    [ngModel]="clientRelationType"
    (ngModelChange)="onClientRelationTypeChange($event)"
  >
    {{ 'txt_client_relation_type_' + loopClientRelationType | translate }}
  </sui-radio-button>
</div>
