import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { ordersFeatureName } from '@api-ui-app/src/app/orders/+state/orders-state/orders.feature';
import { ordersReducerToken, reducer } from '@api-ui-app/src/app/orders/+state/orders-state/orders.reducer';
import { EffectsModule } from '@ngrx/effects';
import { OrdersEffects } from '@api-ui-app/src/app/orders/+state/orders-state/orders.effects';

@NgModule({
  providers: [
    {
      provide: ordersReducerToken,
      useValue: reducer,
    },
  ],
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(ordersFeatureName, ordersReducerToken),
    EffectsModule.forFeature([OrdersEffects]),
  ],
})
export class OrdersStateModule {}
