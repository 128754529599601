import { PricePlanType } from './price-plan-type.enum';
import { PricePlanStatus } from './price-plan-status.enum';

export class PricePlanUpdateRequest {
  constructor(
    public clientId: string,
    public type: PricePlanType,
    public catalogueId: string,
    public name: string,
    public description: string,
    public margin: number,
    public status: PricePlanStatus,
  ) {}
}
