import { TranslateLoader } from '@ngx-translate/core';
import { I18nService } from './i18n.service';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';

export function TranslationLoaderFactory(i18n: I18nService): TranslateLoader {
  return {
    getTranslation(lang: LocaleCode) {
      return i18n.fetchStoredKeys(lang);
    },
  };
}
