import { getDifferenceInDays } from '../../lib/getDifferenceInDays';
import { AnalyticsGraphGroupType } from '../../analytics/types/analytics-graph-group-type.enum';

export const getAvailableGroupTypes = (startDate: string, endDate: string): AnalyticsGraphGroupType[] => {
  const daysCount = getDifferenceInDays(startDate, endDate);

  if (daysCount <= 100) {
    return [AnalyticsGraphGroupType.BY_DAY, AnalyticsGraphGroupType.BY_WEEK, AnalyticsGraphGroupType.BY_MONTH];
  }

  if (daysCount <= 365) {
    return [AnalyticsGraphGroupType.BY_WEEK, AnalyticsGraphGroupType.BY_MONTH];
  }

  return [AnalyticsGraphGroupType.BY_MONTH];
};
