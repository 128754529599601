import { DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MoneyPipe } from './format/money.pipe';
import { PriceRangePipe } from './format/price-range.pipe';
import { SafePipe } from '@gelato-api-ui/sdk/src/lib/pipes/safe/safe.pipe';
import { PhonePipe } from '@gelato-api-ui/sdk/src/lib/pipes/phone.pipe';
import { CurrencyRatePipe } from '@gelato-api-ui/sdk/src/lib/pipes/currency-rate/currency-rate.pipe';
import { IsFeatureEnabledPipe } from './is-feature-enabled.pipe';
import { AssetsPathPipe } from '@gelato-api-ui/sdk/src/lib/pipes/assets-path.pipe';
import { TimePeriodPipe } from '@gelato-api-ui/sdk/src/lib/pipes/time-period/time-period.pipe';

const exportsPipes = [
  MoneyPipe,
  PriceRangePipe,
  SafePipe,
  PhonePipe,
  CurrencyRatePipe,
  IsFeatureEnabledPipe,
  AssetsPathPipe,
  TimePeriodPipe,
];

@NgModule({
  imports: [],
  declarations: [...exportsPipes],
  providers: [DecimalPipe, MoneyPipe, PhonePipe],
  exports: [...exportsPipes],
})
export class SdkPipesModule {}
