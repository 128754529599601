import { Observable, of } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';
import { ApiResponse } from '@gelato-api-ui/core/api/api-response.interface';
import { ApiResponseType } from '@gelato-api-ui/core/api/api-response-type.enum';
import { HttpHeadersCallback } from '@gelato-api-ui/core/api/http-headers-callback';
import { addRequestToQueue } from '@gelato-api-ui/core/api/helpers/queue';

export function caller(
  host: string,
  method: string = 'GET',
  defaultHeadersCallback?: HttpHeadersCallback,
  responseType: ApiResponseType = null,
) {
  return function call<T>(url: string, options: AjaxRequest = {}): Observable<ApiResponse<T>> {
    return addRequestToQueue(
      host,
      method,
      responseType,
      defaultHeadersCallback ? defaultHeadersCallback : () => of({}),
      url,
      options,
    );
  };
}
