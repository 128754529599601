<header class="flex-row flex-justify-start flex-items-stretch">
  <!--LOGO-->
  <div class="logo-wrapper">
    <div class="sidebar-toggle-button-wrapper flex-row flex-items-center flex-justify-center">
      <button
        class="ui icon sidebar-toggle-button"
        [class.active]="!sidebarVisible"
        (click)="toggleSidebar()"
        title="{{ (sidebarVisible ? 'txt_hide_menu' : 'txt_show_menu') | translate }}"
      >
        <img src="/assets/header/hamburger.svg" />
      </button>
    </div>

    <div class="logo">
      <a routerLink="/">
        <gd-store-logo></gd-store-logo>
      </a>
    </div>
  </div>

  <ng-container *ngIf="headerComponent">
    <ng-container *ngComponentOutlet="headerComponent"></ng-container>
  </ng-container>

  <ng-container *ngIf="!headerComponent">
    <!--ACTIONS-->
    <nav class="flex-row flex-justify-end flex-items-center flex-1">
      <gd-user-preferences-menu
        [currentLocaleCode]="currentLang"
        [currentCurrency]="currentCurrency"
        [isMobile]="false"
        (changeLocaleCode)="onLanguageChange($event)"
        (changeCurrency)="onCurrencyChange($event)"
      ></gd-user-preferences-menu>
      <gd-user-discounts-menu *ngIf="discounts?.length" [discounts]="discounts" [isMobile]="false">
      </gd-user-discounts-menu>
      <gd-header-create-menu [isMobile]="false"></gd-header-create-menu>
      <gd-active-client-info
        [userWrapperClass]="userWrapperClass"
        [userInitialsBackgroundLoading]="isBrandingColorsLoading"
      >
      </gd-active-client-info>
    </nav>
  </ng-container>
</header>
