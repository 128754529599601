<span
  [style]="css"
  [gcTooltip]="tooltip"
  [gcTooltipTheme]="'dark'"
  [gcTooltipOpenTimeout]="0"
  [gcTooltipDisabled]="!productBadge.tooltip"
  [gcTooltipPosition]="tooltipPosition"
  >{{ productBadge.name }}</span
>

<ng-template #tooltip>
  <div class="tooltip">
    {{ productBadge.tooltip }}
  </div>
</ng-template>
