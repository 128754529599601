import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PriceListGraphQlService } from './price-list-graph-ql.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [PriceListGraphQlService],
})
export class PriceSdkModule {}
