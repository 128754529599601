import { Action } from '@ngrx/store';
import {
  CatalogInfoState,
  CatalogueListState,
  CatalogueState,
  ExportProductsState,
  ProductDetailsState,
  ProductPricesState,
  ProductSearchCategoriesState,
  ProductSearchState,
} from './product-catalogue.reducer';
import { ProductCatalogueSearchRequest } from '@gelato-api-ui/core/product-catalogue/product-catalogue-search-request';
import { DimensionUnit } from '@gelato-api-ui/core/product-details/dimension-unit.enum';
import { ProductSearchRequestAttributeFiltersCollection } from '@gelato-api-ui/core/product-catalogue/product-search-request-attribute-filters-collection';
import { SanityProductVariation } from '@gelato-api-ui/core/sanity/sanity-product-variation';

export enum ProductCatalogueActionTypes {
  LoadCatalogueListStart = '[ProductCatalogue] Load CatalogueList Start',
  LoadCatalogueStart = '[ProductCatalogue] Load Catalogue Start',
  LoadProductDetailsStart = '[ProductCatalogue] Load ProductDetails Start',
  LoadProductPricesStart = '[ProductCatalogue] Load ProductPrices Start',

  ExportProducts = '[ProductCatalogue] Export Products',
  LoadProductSearchCategories = '[ProductCatalogue] Load ProductSearchCategories',
  SearchProducts = '[ProductCatalogue] Search Products',
  LoadCatalogInfo = '[ProductCatalogue] Load CatalogInfo',
  ExportProductSearchResults = '[ExportProductSearchResults] Export ProductSearchResults',

  ResetCatalogueListPayload = '[ProductCatalogue] Set ResetCatalogueList Payload',
  SetCatalogueListState = '[ProductCatalogue] Set CatalogueList State',
  ResetCatalogueState = '[ProductCatalogue] Reset Catalogue State',
  SetCatalogueState = '[ProductCatalogue] Set Catalogue State',
  SetProductDetailsState = '[ProductCatalogue] Set ProductDetails State',
  ResetProductPricesState = '[ProductCatalogue] Reset ProductPrices State',
  SetProductPricesState = '[ProductCatalogue] Set ProductPrices State',
  ResetExportProductsState = '[ProductCatalogue] Reset ExportProducts State',
  SetExportProductsState = '[ProductCatalogue] Set ExportProducts State',
  ResetProductSearchCategoriesState = '[ProductCatalogue] Reset ProductSearchCategories State',
  SetProductSearchCategoriesState = '[ProductCatalogue] Set ProductSearchCategories State',
  ResetProductSearchState = '[ProductCatalogue] Reset ProductSearch State',
  SetProductSearchState = '[ProductCatalogue] Set ProductSearch State',
  ResetCatalogInfoState = '[ProductCatalogue] Reset CatalogInfo State',
  SetCatalogInfoState = '[ProductCatalogue] Set CatalogInfo State',
  SetSelectedDimensionUnit = '[ProductCatalogue] Set SelectedDimensionUnit',
  ToggleSelectedDimensionUnit = '[ProductCatalogue] Toggle SelectedDimensionUnit',
  SetSelectedProductCategoryName = '[ProductCatalogue] Set SelectedProductCategoryName',
  SetSelectedProductName = '[ProductCatalogue] Set SelectedProductName',
  SetSelectedCountry = '[ProductCatalogue] Set SelectedCountry',
  SetSelectedProductVariation = '[ProductCatalogue] Set SelectedProductVariation',
  SelectProductOption = '[ProductCatalogue] Select ProductOption',

  ResetVariantPreviewUrls = '[ProductCatalogue] Reset Variant Preview Urls',
}

export class LoadCatalogueListStart implements Action {
  readonly type = ProductCatalogueActionTypes.LoadCatalogueListStart;

  constructor(public payload: ProductCatalogueSearchRequest, public forced: boolean = false) {}
}

export class SetCatalogueListState implements Action {
  readonly type = ProductCatalogueActionTypes.SetCatalogueListState;

  constructor(public payload: CatalogueListState) {}
}

export class LoadCatalogueStart implements Action {
  readonly type = ProductCatalogueActionTypes.LoadCatalogueStart;

  constructor(public payload: string, public forced: boolean = false) {}
}

export class ResetCatalogueListPayload implements Action {
  readonly type = ProductCatalogueActionTypes.ResetCatalogueListPayload;
}

export class ResetVariantPreviewUrls implements Action {
  readonly type = ProductCatalogueActionTypes.ResetVariantPreviewUrls;
}

export class ResetCatalogueState implements Action {
  readonly type = ProductCatalogueActionTypes.ResetCatalogueState;
}

export class SetCatalogueState implements Action {
  readonly type = ProductCatalogueActionTypes.SetCatalogueState;

  constructor(public payload: CatalogueState) {}
}

export class LoadProductDetailsStart implements Action {
  readonly type = ProductCatalogueActionTypes.LoadProductDetailsStart;

  constructor(public productUid: string, public forced: boolean = false) {}
}

export class SetProductDetailsState implements Action {
  readonly type = ProductCatalogueActionTypes.SetProductDetailsState;

  constructor(public payload: Partial<ProductDetailsState>) {}
}

export class LoadProductPricesStart implements Action {
  readonly type = ProductCatalogueActionTypes.LoadProductPricesStart;

  constructor(
    public productUid: string,
    public country: string,
    public currency: string,
    public pagesCount: number,
    public forced: boolean = false,
  ) {}
}

export class ResetProductPricesState implements Action {
  readonly type = ProductCatalogueActionTypes.ResetProductPricesState;
}

export class SetProductPricesState implements Action {
  readonly type = ProductCatalogueActionTypes.SetProductPricesState;

  constructor(public payload: ProductPricesState) {}
}

export class SetSelectedDimensionUnit implements Action {
  readonly type = ProductCatalogueActionTypes.SetSelectedDimensionUnit;

  constructor(public payload: DimensionUnit) {}
}

export class ToggleSelectedDimensionUnit implements Action {
  readonly type = ProductCatalogueActionTypes.ToggleSelectedDimensionUnit;
}

export class ResetExportProductsState implements Action {
  readonly type = ProductCatalogueActionTypes.ResetExportProductsState;
}

export class SetExportProductsState implements Action {
  readonly type = ProductCatalogueActionTypes.SetExportProductsState;

  constructor(public payload: ExportProductsState) {}
}

export class ResetProductSearchCategoriesState implements Action {
  readonly type = ProductCatalogueActionTypes.ResetProductSearchCategoriesState;
}

export class SetProductSearchCategoriesState implements Action {
  readonly type = ProductCatalogueActionTypes.SetProductSearchCategoriesState;

  constructor(public payload: ProductSearchCategoriesState) {}
}

export class ResetProductSearchState implements Action {
  readonly type = ProductCatalogueActionTypes.ResetProductSearchState;
}

export class SetProductSearchState implements Action {
  readonly type = ProductCatalogueActionTypes.SetProductSearchState;

  constructor(public payload: ProductSearchState) {}
}

export class ResetCatalogInfoState implements Action {
  readonly type = ProductCatalogueActionTypes.ResetCatalogInfoState;
}

export class SetCatalogInfoState implements Action {
  readonly type = ProductCatalogueActionTypes.SetCatalogInfoState;

  constructor(public payload: CatalogInfoState) {}
}

export class ExportProducts implements Action {
  readonly type = ProductCatalogueActionTypes.ExportProducts;

  constructor(public catalogueAlias: string, public nodeId: string) {}
}

export class LoadProductSearchCategories implements Action {
  readonly type = ProductCatalogueActionTypes.LoadProductSearchCategories;

  constructor(public forced: boolean = false) {}
}

export class SearchProducts implements Action {
  readonly type = ProductCatalogueActionTypes.SearchProducts;

  constructor(
    public catalogueAlias: string,
    public attributeFilters: ProductSearchRequestAttributeFiltersCollection,
    public countryIsoCodes: string[],
    public limit: number,
    public offset: number,
    public forced: boolean = false,
  ) {}
}

export class LoadCatalogInfo implements Action {
  readonly type = ProductCatalogueActionTypes.LoadCatalogInfo;

  constructor(public catalogueAlias: string, public forced: boolean = false) {}
}

export class ExportProductSearchResults implements Action {
  readonly type = ProductCatalogueActionTypes.ExportProductSearchResults;

  constructor(
    public catalogueAlias: string,
    public attributeFilters: ProductSearchRequestAttributeFiltersCollection,
    public countryIsoCodes: string[],
  ) {}
}

export class SetSelectedProductCategoryName implements Action {
  readonly type = ProductCatalogueActionTypes.SetSelectedProductCategoryName;

  constructor(public payload: string) {}
}

export class SetSelectedProductName implements Action {
  readonly type = ProductCatalogueActionTypes.SetSelectedProductName;

  constructor(public payload: string) {}
}

export class SetSelectedCountry implements Action {
  readonly type = ProductCatalogueActionTypes.SetSelectedCountry;

  constructor(public payload: string) {}
}

export class SetSelectedProductVariation implements Action {
  readonly type = ProductCatalogueActionTypes.SetSelectedProductVariation;

  constructor(public payload: SanityProductVariation) {}
}

export class SelectProductOption implements Action {
  readonly type = ProductCatalogueActionTypes.SelectProductOption;

  constructor(public key: string, public value: string) {}
}

export type ProductCatalogueActions =
  | LoadCatalogueListStart
  | LoadCatalogueStart
  | LoadProductDetailsStart
  | LoadProductPricesStart
  | ResetCatalogueListPayload
  | SetCatalogueListState
  | ResetCatalogueState
  | SetCatalogueState
  | SetProductDetailsState
  | ResetProductPricesState
  | SetProductPricesState
  | ResetExportProductsState
  | SetExportProductsState
  | ResetProductSearchCategoriesState
  | SetProductSearchCategoriesState
  | ResetProductSearchState
  | SetProductSearchState
  | ResetCatalogInfoState
  | SetCatalogInfoState
  | SetSelectedDimensionUnit
  | ToggleSelectedDimensionUnit
  | ExportProducts
  | SearchProducts
  | LoadCatalogInfo
  | ExportProductSearchResults
  | SetSelectedProductCategoryName
  | SetSelectedProductName
  | SetSelectedCountry
  | SetSelectedProductVariation
  | ResetVariantPreviewUrls
  | SelectProductOption;
