import { Asset } from '@gelato-api-ui/core/designs/asset';
import { getMetadataItemValue } from '@gelato-api-ui/core/metadata/helpers/getMetadataItemValue';

export const getContentIdFromAsset = (asset: Asset): string => {
  if (!asset) {
    return null;
  }

  return asset.providerAssetId || (getMetadataItemValue(asset.metadata, 'providerAssetId') as string);
};
