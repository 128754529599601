import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gd-pagination, gc-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent {
  @Input() offset: number = null;
  @Input() limit: number = null;
  @Input() total: number = null;
  @Output() offsetChange: EventEmitter<number> = new EventEmitter();

  onOffsetChange(offset) {
    this.offsetChange.emit(offset);
  }
}
