import * as R from 'ramda';
import { EProduct } from '@gelato-api-ui/core/e-commerce/e-product';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import { getDefaultPrimaryPreviewSceneByProductCategory } from '@product-catalogue/src/lib/product-catalogue/lib/helpers/getDefaultPrimaryPreviewSceneByProductCategory';
import {
  StoreProductPreviewsCollection,
  StoreProductVariantPreviewsCollection,
} from '@product-catalogue/src/lib/product-catalogue/services/store-product-variants.service';
import { sceneName2Key } from '@gelato-api-ui/core/e-commerce/helpers/sceneName2Key';
import * as actions from './e-commerce-product-wizard.actions';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { CategoryDesignFamilyResponse } from '@gelato-api-ui/core/designs/category-design-family-response';
import { getRelevantSupportedPreviewScenes } from '@gelato-api-ui/core/preflight/helpers/getRelevantSupportedPreviewScenes';
import { validateSelectedPreviewScenes } from './helpers/validateSelectedPreviewScenes';
import { validatePrimaryPreviewScene } from './helpers/validatePrimaryPreviewScene';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { mergeBulkConnectProductVariantsMappings } from '@gelato-api-ui/core/e-commerce/helpers/mergeBulkConnectProductVariantsMappings';
import { getCurrentTimeStamp } from '@gelato-api-ui/core/datetime/helpers/getCurrentTimeStamp';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';
import {
  setProductAndShippingCostsBatch,
  setProductDetails,
  setProductShippingCost,
} from './e-commerce-product-wizard.actions';
import { ProductDetails } from '@gelato-api-ui/core/product-details/product-details';

export interface PriceCollection {
  [productVariantKey: string]: number;
}

export interface DesignAssetCostsCollection {
  [productVariantKey: string]: number[];
}

export interface BulkConnectState {
  defaultMapping: BulkConnectVariantsMapping;
  customMapping: BulkConnectVariantsMappingPayload;
  createPreviews: boolean;
}

export interface BulkConnectVariantsMapping {
  isLoading: boolean;
  payload: BulkConnectVariantsMappingPayload;
}

export interface BulkConnectVariantsMappingPayload {
  [productVariantId: string]: string;
}

export interface State {
  product: EProduct;
  productDetails: ProductDetails;
  selectedProductVariantKeys: SelectedProductVariantKeysCollection;
  storeProductVariants: StoreProductVariantsCollection;
  originalStoreProductVariants: StoreProductVariantsCollection;
  selectedPreviewFileType: PreviewFileType;
  selectedPreviewScenes: SelectedPreviewScenesCollection;
  supportedPreviewScenes: string[];
  primaryPreviewScene: string;
  primaryPreviewProductVariantKey: string;
  activePriceFetchingRequestsCount: number;
  productVariantKeysArray: string[];
  defaultProductUid: string;
  defaultPageCount: number;
  pageCount: number;
  attachSizeGuideCmTable: boolean;
  attachIllustrationSteps: boolean;
  attachSizeGuideInchTable: boolean;
  attachProductCareInstructions: boolean;
  selectedStore: EStore;
  publishImmediately: boolean;
  publishWithFreeShipping: boolean;
  productPreviewsCollection: StoreProductPreviewsCollection;
  productVariantPreviewsCollection: StoreProductVariantPreviewsCollection;
  productCosts: PriceCollection;
  productShippingCosts: PriceCollection;
  fetchingDesignAssetCosts: boolean;
  designAssetCosts: DesignAssetCostsCollection;
  fetchingDesignTemplateCosts: boolean;
  designTemplateCosts: PriceCollection;
  prices: PriceCollection;
  designFamily: CategoryDesignFamilyResponse;
  mode: EProductWizardMode;
  destinationProductId: string;
  destinationProduct: EProductWithVariants;
  bulkConnect: BulkConnectState;
  startedAt: number;
  isSavingDraft: boolean;
  latestSavedDraftHash: string;
  activeStep: ECommerceProductWizardStep;
  isSavingTemplate: boolean;
  selectedCountry: string;
}

export const initialState: State = {
  product: null,
  productDetails: null,
  selectedProductVariantKeys: {},
  storeProductVariants: {},
  originalStoreProductVariants: {},
  selectedPreviewFileType: PreviewFileType.PNG,
  selectedPreviewScenes: { simple: true },
  supportedPreviewScenes: [],
  primaryPreviewScene: null,
  primaryPreviewProductVariantKey: null,
  activePriceFetchingRequestsCount: 0,
  productVariantKeysArray: [],
  defaultProductUid: null,
  defaultPageCount: undefined,
  pageCount: undefined,
  attachSizeGuideCmTable: true,
  attachIllustrationSteps: true,
  attachSizeGuideInchTable: true,
  attachProductCareInstructions: true,
  selectedStore: null,
  publishImmediately: true,
  publishWithFreeShipping: false,
  productPreviewsCollection: {},
  productVariantPreviewsCollection: {},
  productCosts: {},
  productShippingCosts: {},
  fetchingDesignAssetCosts: false,
  designAssetCosts: {},
  fetchingDesignTemplateCosts: false,
  designTemplateCosts: {},
  prices: {},
  designFamily: null,
  mode: null,
  destinationProductId: null,
  destinationProduct: null,
  bulkConnect: {
    defaultMapping: {
      isLoading: false,
      payload: {},
    },
    customMapping: {},
    createPreviews: false,
  },
  startedAt: null,
  isSavingDraft: false,
  latestSavedDraftHash: null,
  activeStep: null,
  isSavingTemplate: false,
  selectedCountry: null,
};

export interface SelectedProductVariantKeysCollection {
  [productVariantKey: string]: boolean;
}

export interface StoreProductVariantsCollection {
  [productVariantKey: string]: EProductVariant;
}

export interface SelectedPreviewScenesCollection {
  [scene: string]: boolean;
}

export function reducer(state = initialState, action: actions.ECommerceProductWizardActions): State {
  switch (action.type) {
    case actions.ECommerceProductWizardActionTypes.ResetState: {
      const { mode, productCategoryName, publishImmediately, selectedPreviewFileType } = action;
      const primaryPreviewScene = getDefaultPrimaryPreviewSceneByProductCategory(productCategoryName);

      const selectedPreviewScenes = {
        [sceneName2Key(primaryPreviewScene)]: true,
      };

      let destinationProductId = initialState.destinationProductId;
      let destinationProduct = initialState.destinationProduct;
      let bulkConnect = initialState.bulkConnect;
      let startedAt = getCurrentTimeStamp();

      if (mode === EProductWizardMode.BULK_CONNECT) {
        destinationProductId = state.destinationProductId;
        destinationProduct = state.destinationProduct;
        bulkConnect = state.bulkConnect;
        startedAt = state.startedAt;
      }

      return {
        ...state,
        ...initialState,
        mode,
        selectedStore: state.selectedStore,
        selectedPreviewScenes,
        primaryPreviewScene,
        selectedPreviewFileType,
        publishImmediately,
        destinationProductId,
        destinationProduct,
        bulkConnect,
        startedAt,
      };
    }
    case actions.ECommerceProductWizardActionTypes.InitState:
      return {
        ...initialState,
        ...action.payload,
        startedAt: getCurrentTimeStamp(),
      };
    case actions.ECommerceProductWizardActionTypes.SetProduct:
      return {
        ...state,
        product: action.payload,
      };
    case actions.ECommerceProductWizardActionTypes.SetProductDescription:
      if (!state.product) {
        return state;
      }

      return {
        ...state,
        product: {
          ...state.product,
          description: action.payload,
        },
      };
    case actions.ECommerceProductWizardActionTypes.SetProductDetails:
      return {
        ...state,
        ...action.payload,
      };
    case actions.ECommerceProductWizardActionTypes.SetProductVariantKeysArray:
      return {
        ...state,
        productVariantKeysArray: action.payload,
      };
    case actions.ECommerceProductWizardActionTypes.SetProductVariantKeysList: {
      const productVariantKeysList = action.payload;

      const selectedProductVariantKeys = {};
      productVariantKeysList.forEach(p => (selectedProductVariantKeys[p] = true));
      const storeProductVariants = { ...state.storeProductVariants };
      Object.keys(storeProductVariants).forEach(v => {
        if (!productVariantKeysList.includes(v)) {
          delete storeProductVariants[v];
        }
      });

      return {
        ...state,
        selectedProductVariantKeys,
        storeProductVariants,
      };
    }
    case actions.setStoreProductVariants.type:
      return {
        ...state,
        storeProductVariants: action.variants,
      };
    case actions.ECommerceProductWizardActionTypes.SetStoreProductVariantOptions:
      return {
        ...state,
        product: {
          ...state.product,
          productVariantOptions: action.payload,
        },
      };
    case actions.ECommerceProductWizardActionTypes.SetSelectedPreviewFileType:
      return {
        ...state,
        selectedPreviewFileType: action.payload,
      };
    case actions.ECommerceProductWizardActionTypes.SetSelectedPreviewScenes:
      return {
        ...state,
        selectedPreviewScenes: action.payload,
      };
    case actions.ECommerceProductWizardActionTypes.TogglePreviewScene: {
      const { sceneKey, singleValueSelectionMode } = action;
      const value = R.isNil(action.value) ? !state.selectedPreviewScenes[sceneKey] : action.value;

      const selectedPreviewScenes = {
        ...(singleValueSelectionMode ? {} : state.selectedPreviewScenes),
        [sceneKey]: value,
      };

      const primaryPreviewScene = validatePrimaryPreviewScene(state.primaryPreviewScene, selectedPreviewScenes);

      return {
        ...state,
        selectedPreviewScenes,
        primaryPreviewScene,
      };
    }
    case actions.ECommerceProductWizardActionTypes.SetPrimaryPreviewScene:
      return {
        ...state,
        primaryPreviewScene: action.payload,
      };
    case actions.ECommerceProductWizardActionTypes.SetPrimaryPreviewProductVariantKey:
      return {
        ...state,
        primaryPreviewProductVariantKey: action.payload,
      };
    case actions.ECommerceProductWizardActionTypes.DecreaseActivePriceFetchingRequestsCount:
      return {
        ...state,
        activePriceFetchingRequestsCount: Math.max(0, state.activePriceFetchingRequestsCount - 1),
      };
    case actions.setPreviewCollections.type:
      return {
        ...state,
        productPreviewsCollection: action.productPreviewsCollection,
        productVariantPreviewsCollection: action.productVariantPreviewsCollection,
      };
    case actions.setPrimaryProductPreview.type:
      return {
        ...state,
        productPreviewsCollection: {
          ...state.productPreviewsCollection,
          [sceneName2Key(state.primaryPreviewScene)]: action.payload,
        },
      };
    case actions.setSupportedPreviewScenes.type:
      return {
        ...state,
        supportedPreviewScenes: action.supportedPreviewScenes,
      };
    case actions.setProductCost.type:
      return {
        ...state,
        productCosts: {
          ...state.productCosts,
          [action.productVariantKey]: action.productCost,
        },
      };
    case actions.setProductShippingCost.type:
      return {
        ...state,
        productShippingCosts: {
          ...state.productShippingCosts,
          [action.productVariantKey]: action.productShippingCost,
        },
      };
    case actions.setProductAndShippingCostsBatch.type:
      return {
        ...state,
        productCosts: action.productCosts,
        productShippingCosts: action.productShippingCosts,
      };
    case actions.setPrices.type:
      return {
        ...state,
        prices: action.prices,
      };
    case actions.fetchDesignAssetCosts.type:
      return {
        ...state,
        fetchingDesignAssetCosts: true,
      };
    case actions.fetchDesignAssetCostsSuccess.type:
      return {
        ...state,
        fetchingDesignAssetCosts: false,
        designAssetCosts: action.designAssetCosts,
      };
    case actions.fetchDesignTemplateCosts.type:
      return {
        ...state,
        fetchingDesignTemplateCosts: true,
      };
    case actions.fetchDesignTemplateCostsSuccess.type:
      return {
        ...state,
        fetchingDesignTemplateCosts: false,
        designTemplateCosts: action.designTemplateCosts,
      };
    case actions.updateSelectedPreviewScenes.type: {
      const { hasUsedShutterstockAssets } = action;
      const supportedPreviewScenes = getRelevantSupportedPreviewScenes(
        state.supportedPreviewScenes,
        hasUsedShutterstockAssets,
      );
      const selectedPreviewScenes = validateSelectedPreviewScenes(state.selectedPreviewScenes, supportedPreviewScenes);
      const primaryPreviewScene = validatePrimaryPreviewScene(state.primaryPreviewScene, selectedPreviewScenes);

      return {
        ...state,
        selectedPreviewScenes,
        primaryPreviewScene,
      };
    }
    case actions.setDesignFamily.type:
      return {
        ...state,
        designFamily: action.designFamily,
      };
    case actions.setDefaultProductUid.type:
      return {
        ...state,
        defaultProductUid: action.uid,
      };
    case actions.setDefaultPageCount.type:
      return {
        ...state,
        defaultPageCount: action.defaultPageCount,
        pageCount: action.defaultPageCount,
      };
    case actions.setPageCount.type:
      return {
        ...state,
        pageCount: action.pageCount,
      };
    case actions.setupInitialStateForBulkConnect.type:
      return {
        ...initialState,
        destinationProductId: action.destinationProductId,
        startedAt: getCurrentTimeStamp(),
      };
    case actions.setDestinationProductId.type:
      return {
        ...state,
        destinationProductId: action.payload,
      };
    case actions.setDestinationProduct.type:
      return {
        ...state,
        destinationProduct: action.payload,
      };
    case actions.clearBulkConnectProductVariantsMapping.type:
      return {
        ...state,
        bulkConnect: {
          ...state.bulkConnect,
          customMapping: { ...initialState.bulkConnect.customMapping },
        },
      };
    case actions.resetBulkConnectProductVariantsMappingToDefault.type:
      return {
        ...state,
        bulkConnect: {
          ...state.bulkConnect,
          customMapping: { ...state.bulkConnect.defaultMapping.payload },
        },
      };
    case actions.mapBulkConnectProductVariantsPair.type:
      return {
        ...state,
        bulkConnect: {
          ...state.bulkConnect,
          customMapping: {
            ...state.bulkConnect.customMapping,
            [action.destinationProductVariantId]: action.sourceProductVariantKey,
          },
        },
      };
    case actions.loadBulkConnectDefaultProductVariantMappingStart.type:
      return {
        ...state,
        bulkConnect: {
          ...state.bulkConnect,
          defaultMapping: {
            ...state.bulkConnect.defaultMapping,
            isLoading: true,
          },
        },
      };
    case actions.loadBulkConnectDefaultProductVariantMappingFailure.type:
      return {
        ...state,
        bulkConnect: {
          ...state.bulkConnect,
          defaultMapping: {
            ...state.bulkConnect.defaultMapping,
            isLoading: false,
          },
        },
      };
    case actions.loadBulkConnectDefaultProductVariantMappingSuccess.type: {
      const customMapping =
        Object.keys(state.bulkConnect.customMapping).length !== Object.keys(action.payload).length
          ? mergeBulkConnectProductVariantsMappings(state.bulkConnect.customMapping, action.payload)
          : state.bulkConnect.customMapping;

      return {
        ...state,
        bulkConnect: {
          ...state.bulkConnect,
          defaultMapping: {
            ...state.bulkConnect.defaultMapping,
            isLoading: false,
            payload: action.payload,
          },
          customMapping,
        },
      };
    }
    case actions.setBulkConnectCustomProductVariantsMapping.type:
      return {
        ...state,
        bulkConnect: {
          ...state.bulkConnect,
          customMapping: action.payload,
        },
      };
    case actions.setBulkConnectCreatePreviewsFlag.type:
      return {
        ...state,
        bulkConnect: {
          ...state.bulkConnect,
          createPreviews: action.payload,
        },
      };
    case actions.saveProductDraftStart.type:
      return {
        ...state,
        isSavingDraft: true,
      };
    case actions.saveProductDraftSuccess.type:
      return {
        ...state,
        isSavingDraft: false,
        latestSavedDraftHash: action.latestSavedDraftHash,
        product: {
          ...state.product,
          id: action.productId,
          hasDraft: true,
        },
      };
    case actions.saveProductDraftFailure.type:
      return {
        ...state,
        isSavingDraft: false,
      };
    case actions.saveProductTemplateStart.type:
      return {
        ...state,
        isSavingTemplate: true,
      };
    case actions.saveProductTemplateSuccess.type:
    case actions.saveProductTemplateFailure.type:
      return {
        ...state,
        isSavingTemplate: false,
      };
    case actions.setActiveStep.type:
      return {
        ...state,
        activeStep: action.payload,
      };
    case actions.setProductDetails.type:
      return {
        ...state,
        productDetails: action.payload,
      };
    case actions.setCountry.type:
      return {
        ...state,
        selectedCountry: action.payload,
      };
    default:
      return state;
  }
}
