import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, map, mergeMap, skipWhile, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '@api-ui-app/src/app/app.state';
import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import { SanityProductControl, SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { SanityApiService } from '@gelato-api-ui/core/sanity/services/sanity-api.service';
import * as actions from './sanity.actions';
import { loadHelloBar, loadHelloBarSuccess } from './sanity.actions';
import * as R from 'ramda';
import {
  getSanityContent,
  getSanityContentState,
  getSanityProducts,
  getSanityRelatedProducts,
} from './sanity.selector';
import { updateLocale } from '@gelato-api-ui/sdk/src/lib/sdk.actions';
import { TranslateService } from '@ngx-translate/core';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { mapSanityProductResponse } from '@gelato-api-ui/core/sanity/helpers/mapSanityProductResponse';
import { mapSanityProductCategoryResponse } from '@gelato-api-ui/core/sanity/helpers/mapSanityProductCategoryResponse';
import { SanityHelloBar } from '@gelato-api-ui/core/sanity/types/sanity-hello-bar';

@Injectable()
export class SanityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly sanityApiService: SanityApiService,
    private readonly translations: TranslateService,
  ) {}

  Load$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.LoadSanityContent>(actions.SanityActionTypes.Load),
      withLatestFrom(this.store$.select(getSanityContentState)),
      filter(([action, sanityContentState]) => !sanityContentState.isLoading),
      filter(
        ([action, sanityContentState]) =>
          !sanityContentState.payload ||
          !sanityContentState.payload.length ||
          action.forced ||
          (action.locale && sanityContentState.locale !== action.locale),
      ),
      tap(() => this.store$.dispatch(new actions.LoadSanityContentStart())),
      switchMap(([action, _]) => {
        const locale = action.locale || (this.translations.currentLang as LocaleCode);
        return this.sanityApiService.getData(locale).pipe(
          catchError(() => {
            this.store$.dispatch(new ShowGeneralErrorNotification());
            return of(null);
          }),
          map(categories => mapSanityProductCategoryResponse(categories)),
          map((productCategories: SanityProductCategory[]) => new actions.SetCategories(productCategories, locale)),
        );
      }),
    ),
  );

  loadProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadProduct),
      withLatestFrom(this.store$.select(getSanityProducts)),
      filter(([action, products]) => !products[action.productName] || !!action.locale),
      mergeMap(([action]) =>
        this.store$.select(getSanityContentState).pipe(
          skipWhile(content => !content.payload || content.locale !== this.translations.currentLang),
          take(1),
          mergeMap(() =>
            this.sanityApiService
              .getProduct(
                action.locale || (this.translations.currentLang as LocaleCode),
                action.productName,
                action.productNameUid,
                action.productUid,
              )
              .pipe(
                map(product => mapSanityProductResponse(product)),
                map(product => ({
                  ...product,
                  productControls: product?.productControls?.map(
                    (loopProductControl: SanityProductControl): SanityProductControl => {
                      if (loopProductControl.key === SanityProductControlKey.mugMaterial) {
                        return {
                          ...loopProductControl,
                          title: this.translations.instant('txt_product_control_mug_material'),
                        };
                      }

                      return loopProductControl;
                    },
                  ),
                })),
                map(result =>
                  actions.loadProductSuccess({
                    product: result,
                    categoryName: action.categoryName,
                    productName: action.productName,
                  }),
                ),
              ),
          ),
        ),
      ),
    ),
  );

  loadRelatedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadRelatedProducts),
      withLatestFrom(this.store$.select(getSanityRelatedProducts)),
      filter(([action, relatedProducts]) => !relatedProducts.length || !!action.locale),
      tap(() => this.store$.dispatch(actions.loadRelatedProductsStart())),
      switchMap(([action]) =>
        this.sanityApiService
          .getRelatedProducts(action.locale || (this.translations.currentLang as LocaleCode))
          .pipe(map(payload => actions.loadRelatedProductsSuccess({ payload }))),
      ),
    ),
  );

  updateLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLocale),
      withLatestFrom(this.store$.select(getSanityContent)),
      filter(([_, content]) => !!content && !!content.length),
      map(([action]) => new actions.LoadSanityContent(false, action.locale)),
    ),
  );

  updateLocaleProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLocale),
      withLatestFrom(this.store$.select(getSanityProducts)),
      filter(([_, products]) => !!products && !!R.keys(products).length),
      mergeMap(([action, products]) => [
        ...R.keys(products).map(productName =>
          actions.loadProduct({ productName, categoryName: products[productName].category, locale: action.locale }),
        ),
      ]),
    ),
  );

  loadHelloBar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadHelloBar, updateLocale),
      switchMap(action => {
        return this.sanityApiService.getHelloBar(action.locale || (this.translations.currentLang as LocaleCode)).pipe(
          catchError(() => of(null)),
          map((payload: SanityHelloBar) => loadHelloBarSuccess({ payload })),
        );
      }),
    ),
  );
}
