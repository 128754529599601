import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountryStateSelectorComponent } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/components/country-state-selector/country-state-selector.component';
import { StateSelectorComponent } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/components/state-selector/state-selector.component';
import { AustralianStateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/australian-state-name.pipe';
import { UsStateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/us-state-name.pipe';
import { ChileanRegionNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/chilean-region-name.pipe';
import { CanadianProvinceNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/canadian-province-name.pipe';
import { BrazilianStateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/brazilian-state-name.pipe';
import { CountryStateNamePipe } from '@api-ui-app/src/app/shared/pipes/country-state-name.pipe';
import { StateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/state-name-strategy.pipe';
import { JpPrefectureNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/jp-prefecture-name.pipe';

const components = [
  CountryStateSelectorComponent,
  StateSelectorComponent,
  AustralianStateNamePipe,
  UsStateNamePipe,
  ChileanRegionNamePipe,
  CanadianProvinceNamePipe,
  BrazilianStateNamePipe,
  StateNamePipe,
  JpPrefectureNamePipe,
];

@NgModule({
  imports: [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule, TranslateModule],
  declarations: [...components],
  exports: [...components],
  providers: [
    AustralianStateNamePipe,
    UsStateNamePipe,
    ChileanRegionNamePipe,
    CanadianProvinceNamePipe,
    BrazilianStateNamePipe,
    CountryStateNamePipe,
    UsStateNamePipe,
    StateNamePipe,
    JpPrefectureNamePipe,
  ],
})
export class CountryStateSelectorModule {}
