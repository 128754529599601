import { Action } from '@ngrx/store';
import { State } from './order-refund.reducer';

export enum OrderRefundActionTypes {
  LoadOrderRefunds = '[OrderRefund] Load Order Refunds',
  ResetState = '[OrderRefund] Reset State',
  SetState = '[OrderRefund] Set State',
}

export class LoadOrderRefunds implements Action {
  readonly type = OrderRefundActionTypes.LoadOrderRefunds;

  constructor(public orderId: string, public forced = false) {}
}

export class ResetState implements Action {
  readonly type = OrderRefundActionTypes.ResetState;
}

export class SetState implements Action {
  readonly type = OrderRefundActionTypes.SetState;

  constructor(public payload: State) {}
}

export type OrderRefundActions = LoadOrderRefunds | ResetState | SetState;
