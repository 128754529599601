import { createReducer, on } from '@ngrx/store';
import {
  loadSanityGettyFilters,
  loadSanityGettyFiltersError,
  loadSanityGettyFiltersSuccess,
} from './app-and-services.actions';
import { FilterBaseControl } from '@gelato-api-ui/core/apps-and-services/types/getty-filters-response';

export interface State {
  isLoading: boolean;
  gettyFilters: FilterBaseControl[];
}

export const initialState: State = {
  isLoading: false,
  gettyFilters: null,
};

export const reducer = createReducer(
  initialState,
  on(loadSanityGettyFilters, state => ({
    ...state,
    isLoading: true,
  })),
  on(loadSanityGettyFiltersSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    gettyFilters: action.payload,
  })),
  on(loadSanityGettyFiltersError, state => ({
    ...state,
    isLoading: false,
  })),
);
