import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getProductCatalogueTitles } from '../../ngrx/product-catalogue-selection-list.reducer';

@Pipe({
  name: 'productCatalogueTitle',
})
export class ProductCatalogueTitlePipe implements PipeTransform {
  productCatalogueTitles$ = this.store.pipe(select(getProductCatalogueTitles));

  constructor(private store: Store<AppState>) {}

  transform(value: any, args?: any): any {
    return this.productCatalogueTitles$.pipe(map(productCatalogueTitles => productCatalogueTitles[value] || ''));
  }
}
