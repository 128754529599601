import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EStoreSearchRequest } from '@gelato-api-ui/core/e-commerce/e-store-search-request';
import { EStoreSearchResponse } from '@gelato-api-ui/core/e-commerce/e-store-search-response';
import * as actions from './e-commerce-stores.actions';

export interface State {
  storesList: StoresListState;
  store: StoreState;
  selectedStoreId: string;
}

export interface StoresListState {
  isLoading: boolean;
  request: EStoreSearchRequest;
  payload: EStoreSearchResponse;
}

export interface StoreState {
  isLoading: boolean;
  payload: EStore;
}

export const initialState: State = {
  storesList: {
    isLoading: false,
    request: null,
    payload: null,
  },
  store: {
    isLoading: false,
    payload: null,
  },
  selectedStoreId: null,
};

export function reducer(state = initialState, action: actions.ECommerceStoresActions): State {
  switch (action.type) {
    case actions.ECommerceStoresActionTypes.ResetStoresListState:
      return {
        ...state,
        storesList: { ...initialState.storesList },
      };
    case actions.ECommerceStoresActionTypes.ResetStoresListPayload:
      return {
        ...state,
        storesList: {
          ...state.storesList,
          payload: null,
        },
      };
    case actions.ECommerceStoresActionTypes.ResetStoreState:
      return {
        ...state,
        store: { ...initialState.store },
      };
    case actions.ECommerceStoresActionTypes.LoadStoresListSuccess:
      return {
        ...state,
        storesList: action.payload,
      };
    case actions.updateEcommerceStoreSuccess.type:
      const updatedStores = state.storesList.payload.stores.map(eStore => {
        return eStore.id === action.payload.id ? action.payload : eStore;
      });

      return {
        ...state,
        storesList: {
          ...state.storesList,
          payload: {
            ...state.storesList.payload,
            stores: updatedStores,
          },
        },
      };
    case actions.ECommerceStoresActionTypes.SetEcommerceStore:
      return {
        ...state,
        store: action.payload,
      };
    case actions.ECommerceStoresActionTypes.SetSelectedStoreId:
      return {
        ...state,
        selectedStoreId: action.payload,
      };
    case actions.addEcommerceStore.type:
      return {
        ...state,
        storesList: {
          ...state.storesList,
          payload: {
            stores: [...this.state.storesList.payload, action.payload],
          },
        },
      };
    case actions.setSelectedStore.type:
      return {
        ...state,
        selectedStoreId: action.payload?.id,
        store: {
          isLoading: false,
          payload: action.payload,
        },
      };
    default:
      return state;
  }
}
