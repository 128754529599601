import { Action } from '@ngrx/store';
import { Client } from '@gelato-api-ui/core/clients/client';
import { State } from './auth.reducer';

export enum AuthActionTypes {
  ResetState = '[Auth] Reset state',
  SetState = '[Auth] Set State',
  SetSelectedClientId = '[Auth] Set SelectedClientId',
  SetSelectedClient = '[Auth] Set SelectedClient',
}

export class ResetState implements Action {
  readonly type = AuthActionTypes.ResetState;
}

export class SetState implements Action {
  readonly type = AuthActionTypes.SetState;

  constructor(public payload: State) {}
}

export class SetSelectedClientId implements Action {
  readonly type = AuthActionTypes.SetSelectedClientId;

  constructor(public payload: string) {}
}

export class SetSelectedClient implements Action {
  readonly type = AuthActionTypes.SetSelectedClient;

  constructor(public payload: Client) {}
}

export type AuthActions = ResetState | SetState | SetSelectedClientId | SetSelectedClient;
