import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertModalService } from '@gelato-api-ui/sdk/src/lib/shared/services/alert-modal.service';

@Injectable()
export class ECommerceStoreConnectionErrorMessageService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly alertModalService: AlertModalService,
  ) {}

  show(error) {
    const message = this.getMessage(error) || this.translateService.instant('txt_general_error_message');

    this.alertModalService
      .open(
        message,
        this.translateService.instant('txt_store_connection_error_modal_title'),
        this.translateService.instant('txt_close'),
      )
      .onApprove(null);
  }

  private getMessage(error): string {
    return error?.response?.error?.message;
  }
}
