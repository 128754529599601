import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gd-number-option-select',
  templateUrl: './number-option-select.component.html',
  styleUrls: ['./number-option-select.component.scss'],
})
export class NumberOptionSelectComponent implements OnInit {
  @Input()
  options: number[] = [];

  @Input()
  value: number = null;

  @Input()
  placeholder: string = null;

  @Output()
  valueChange = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onValueChange(event) {
    const value = event.target.value;

    this.valueChange.emit(value ? Number(value) : null);
  }
}
