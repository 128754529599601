import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { CompanyDetailsApiService } from '@gelato-api-ui/core/company-details/services/company-details-api.service';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import * as actions from './company-details.actions';
import { getState } from './company-details.reducer';
import { SetSelectedClient } from './auth.actions';
import { getSelectedClientFromCompanyDetails, isGelatoUser } from './auth.reducer';
import { KeycloakAuthService } from '@api-ui-app/src/app/shared/services/keycloak-auth.service';

@Injectable()
export class CompanyDetailsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private companyDetailsApiService: CompanyDetailsApiService,
    private auth: KeycloakAuthService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.Load>(actions.CompanyDetailsActionTypes.Load),
      withLatestFrom(this.store$.select(getState)),
      switchMap(([action, state]) =>
        this.auth.isActivated().pipe(
          filter(active => active),
          filter(() => action.forced || !state.payload),
          tap(() =>
            this.store$.dispatch(
              new actions.SetState({
                isLoading: true,
                payload: null,
              }),
            ),
          ),
          switchMap(() =>
            this.companyDetailsApiService.get().pipe(
              catchError((err): Observable<CompanyDetails> => {
                this.store$.dispatch(new ShowGeneralErrorNotification());

                return of(null);
              }),
              map(
                (response: CompanyDetails) =>
                  new actions.SetState({
                    isLoading: false,
                    payload: response,
                  }),
              ),
            ),
          ),
        ),
      ),
    ),
  );

  setState$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.SetState>(actions.CompanyDetailsActionTypes.SetState),
      withLatestFrom(this.store$.select(isGelatoUser), this.store$.select(getSelectedClientFromCompanyDetails)),
      filter(([action, gelatoUser, updatedSelectedClient]) => !gelatoUser && Boolean(updatedSelectedClient)),
      map(([action, gelatoUser, updatedSelectedClient]) => new SetSelectedClient(updatedSelectedClient)),
    ),
  );
}
