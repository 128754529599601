import * as R from 'ramda';
import { AccessGroup } from '../access-group';
import { sortAccessScopesByPriority } from './sortAccessScopesByPriority';

const getMatchingAccessScope = (accessScopes: string[], accessGroup: AccessGroup) => {
  if (!accessGroup || !accessGroup.accessScopes || !accessGroup.accessScopes.length) {
    return null;
  }

  const foundAccessScopes = R.intersection(accessGroup.accessScopes, accessScopes);

  if (!foundAccessScopes || !foundAccessScopes.length) {
    return null;
  }

  return sortAccessScopesByPriority(foundAccessScopes, true)[0];
};

export const splitAccessScopesIntoGroups = (accessScopes: string[], accessGroups: AccessGroup[]): any => {
  if (!accessScopes || !accessScopes.length || !accessGroups || !accessGroups.length) {
    return {};
  }

  const groupedAccessScopes = {};

  accessGroups.forEach((accessGroup: AccessGroup) => {
    if (accessGroup.childGroups && accessGroup.childGroups.length) {
      accessGroup.childGroups.forEach((childAccessGroup: AccessGroup) => {
        const accessScope = getMatchingAccessScope(accessScopes, childAccessGroup);

        if (accessScope) {
          groupedAccessScopes[childAccessGroup.name] = accessScope;
        }
      });
    } else if (accessGroup.accessScopes && accessGroup.accessScopes.length) {
      const accessScope = getMatchingAccessScope(accessScopes, accessGroup);

      if (accessScope) {
        groupedAccessScopes[accessGroup.name] = accessScope;
      }
    }
  });

  return groupedAccessScopes;
};
