import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PreflightApiService } from '@gelato-api-ui/core/preflight/services/preflight-api.service';
import { PreflightCdnService } from '@gelato-api-ui/core/preflight/services/preflight-cdn.service';
import { DesignProductPreviewService } from '@gelato-api-ui/core/preflight/services/design-product-preview.service';
import { PreflightProductPreviewRequest } from '@gelato-api-ui/core/preflight/preflight-product-preview-request';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import { MimeType } from '@gelato-api-ui/core/preflight/mime-type.enum';
import { ECommerceProductPreviewSize } from '@api-ui-app/src/app/e-commerce-stores/types/e-commerce-product-preview-size';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ECommerceProductVariantPreviewService {
  constructor(
    private readonly preflightApiService: PreflightApiService,
    private readonly preflightCdnService: PreflightCdnService,
    private readonly designProductPreviewService: DesignProductPreviewService,
  ) {}

  getProductPreviewUrl(
    productVariant: EProductVariant,
    previewScene: string,
    previewFileType: PreviewFileType,
    designPreviewFileType: PreviewFileType,
    previewSize: ECommerceProductPreviewSize,
  ): Observable<string> {
    return this.getProductPreviewRequest(
      productVariant,
      previewScene,
      previewFileType,
      designPreviewFileType,
      previewSize,
    ).pipe(
      map(request => {
        if (!request) {
          return null;
        }
        return this.preflightApiService.getProductPreviewUrlOld(request);
      }),
    );
  }

  getProductPreviewRequest(
    productVariant: EProductVariant,
    previewScene: string,
    previewFileType: PreviewFileType,
    designPreviewFileType: PreviewFileType,
    previewSize: ECommerceProductPreviewSize,
  ): Observable<PreflightProductPreviewRequest> {
    if (!productVariant) {
      return null;
    }
    if (productVariant.designStructureJson) {
      return this.getDesignStructurePreviewRequest(
        productVariant,
        previewScene,
        previewFileType,
        designPreviewFileType,
        previewSize,
      );
    } else if (productVariant.fileUrl) {
      return this.getFilePreviewRequest(productVariant, previewScene, previewFileType, previewSize);
    }
  }

  getDesignStructurePreviewRequest(
    productVariant: EProductVariant,
    previewScene: string,
    previewFileType: PreviewFileType,
    designPreviewFileType: PreviewFileType,
    previewSize: ECommerceProductPreviewSize,
  ): Observable<PreflightProductPreviewRequest> {
    return this.designProductPreviewService.getPreviewRequest(
      productVariant.productUid,
      JSON.parse(productVariant.designStructureJson),
      previewScene,
      previewFileType,
      designPreviewFileType,
      previewSize.width,
      previewSize.height,
      false,
      previewSize.forceDimensions,
    );
  }

  getFilePreviewRequest(
    productVariant: EProductVariant,
    previewScene: string,
    previewFileType: PreviewFileType,
    previewSize: ECommerceProductPreviewSize,
  ): Observable<PreflightProductPreviewRequest> {
    const productPreviewRequest: PreflightProductPreviewRequest = {
      product_uid: productVariant.productUid,
      width: previewSize.width,
      height: previewSize.height,
      force_dimensions: previewSize.forceDimensions,
    };

    // Old product variants don't have populated fileMimeType fields
    if (!productVariant.fileMimeType || productVariant.fileMimeType === MimeType.PDF) {
      productPreviewRequest.pdf_url = productVariant.fileUrl;
    } else {
      productPreviewRequest.image_url = productVariant.fileUrl;
    }

    if (previewScene) {
      productPreviewRequest.scene = previewScene;
    }

    if (previewFileType) {
      productPreviewRequest.format = previewFileType;
    }

    return of(productPreviewRequest);
  }
}
