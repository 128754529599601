import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { LoggerService } from '@gelato-api-ui/sdk/src/lib/logger/logger.interface';
import { StorageService } from './storage.service';
import { FallbackStorageService } from '@gelato-api-ui/sdk/src/lib/storage/fallback-storage.service';
import { StorageValueService } from '@gelato-api-ui/sdk/src/lib/storage/storage-value.service';

export const SESSION_STORAGE_PREFIX = new InjectionToken<string>('[SDK] SESSION_STORAGE_PREFIX');

@Injectable()
export class SessionStorageService extends StorageService {
  private globalKeys = new Set(['i18n']);

  constructor(
    @Optional()
    @Inject(SESSION_STORAGE_PREFIX)
    private readonly prefix: string,
    private readonly logger: LoggerService,
    private readonly fallbackStorageService: FallbackStorageService,
    private readonly storageValueService: StorageValueService,
  ) {
    super();
  }

  clear() {
    try {
      this.fallbackStorageService.clear();
      sessionStorage.clear();
    } catch (e) {
      this.logger.error(e.message);
    }
  }

  getItem<T>(key: string, defaultValue: T): T {
    try {
      return this.storageValueService.fromRawValue(sessionStorage.getItem(this.decorateKey(key)), defaultValue);
    } catch (e) {
      return this.fallbackStorageService.getItem(key, defaultValue);
    }
  }

  setItem(key: string, value: any) {
    try {
      this.fallbackStorageService.setItem(key, value);
      sessionStorage.setItem(this.decorateKey(key), this.storageValueService.toRawValue(value));
    } catch (e) {
      console.error(e.message);
    }
  }

  removeItem(key: string) {
    try {
      this.fallbackStorageService.removeItem(key);
      sessionStorage.removeItem(this.decorateKey(key));
    } catch (e) {
      console.error(e.message);
    }
  }

  private decorateKey(key: string) {
    return this.globalKeys.has(key) ? key : `${this.prefix || ''}${key}`;
  }
}
