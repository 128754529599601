import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { getFormFieldError, removeFormFieldError } from '@api-ui-app/src/app/lib/getFormFieldError';
import { ErrorData } from '@api-ui-app/src/app/shared/lib/error-data';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import {
  BillingAddressEnum,
  BillingAddressViewEnum,
} from '@api-ui-app/src/app/shared/billing-address-form/billingAddressEnum';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BillingEntityUpdateRequest } from '@api-ui-app/src/app/billing-entities/lib/billing-entity-update-request';
import { COUNTRIES_WITH_STATE } from '@api-ui-app/src/app/lib/constants';

@Component({
  selector: 'gd-billing-address-form',
  templateUrl: './billing-address-form.component.html',
  styleUrls: ['./billing-address-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingAddressFormComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit {
  @Input() set companyDetails(company: CompanyDetails) {
    this.form.patchValue(company, { emitEvent: false });
  }
  @Input() view: BillingAddressViewEnum = BillingAddressViewEnum.default;
  @Input() error: ErrorData = null;
  @Input() showSupportEmail = false;
  @Output() update = new EventEmitter<BillingEntityUpdateRequest>();

  @ViewChild('name') nameRef: TemplateRef<any>;
  @ViewChild('addressField') addressFieldRef: TemplateRef<any>;
  @ViewChild('city') cityRef: TemplateRef<any>;
  @ViewChild('postCode') postCodeRef: TemplateRef<any>;
  @ViewChild('country') countryRef: TemplateRef<any>;
  @ViewChild('state') stateRef: TemplateRef<any>;
  @ViewChild('email') emailRef: TemplateRef<any>;
  @ViewChild('contactEmail') contactEmailRef: TemplateRef<any>;
  @ViewChild('phone') phoneRef: TemplateRef<any>;
  @ViewChild('billingEmail') billingEmailRef: TemplateRef<any>;
  @ViewChild('supportEmail') supportEmailRef: TemplateRef<any>;
  @ViewChild('content') contentRef: TemplateRef<any>;

  order$ = new BehaviorSubject(null);
  onAfterViewInit$ = new BehaviorSubject(false);
  ngOnDestroy$ = new Subject();

  address = BillingAddressEnum;
  controls = {
    [BillingAddressEnum.recipientName]: new UntypedFormControl(''),
    [BillingAddressEnum.address]: new UntypedFormControl(''),
    [BillingAddressEnum.city]: new UntypedFormControl(''),
    [BillingAddressEnum.postCode]: new UntypedFormControl(''),
    [BillingAddressEnum.countryIsoCode]: new UntypedFormControl(''),
    [BillingAddressEnum.stateCode]: new UntypedFormControl(''),
    [BillingAddressEnum.email]: new UntypedFormControl(''),
    [BillingAddressEnum.contactEmail]: new UntypedFormControl(''),
    [BillingAddressEnum.phone]: new UntypedFormControl(''),
    [BillingAddressEnum.billingEmail]: new UntypedFormControl(''),
    [BillingAddressEnum.supportEmail]: new UntypedFormControl(''),
  };
  form: UntypedFormGroup = this.fb.group(this.controls);

  constructor(private readonly fb: UntypedFormBuilder, private cdRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.form.valueChanges.pipe(takeUntil(this.ngOnDestroy$)).subscribe(value => this.update.emit(value));
  }

  ngAfterViewInit() {
    this.onAfterViewInit$.next(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    const details = changes.companyDetails;

    // initialize order of the for fields
    // (we can get company details at any point for the first time)
    if (details) {
      const currentCountry = details.currentValue.countryIsoCode;
      const prevCountry = details.previousValue?.countryIsoCode;
      const isCountryAppeared = currentCountry && !prevCountry;

      if (isCountryAppeared) {
        this.updateOrder(currentCountry);
      }
    }
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }

  get isShowStateOptionalField() {
    return COUNTRIES_WITH_STATE.indexOf(this.form.get(this.address.countryIsoCode).value) === -1;
  }

  get checkoutViewValue() {
    return BillingAddressViewEnum.checkout;
  }

  get selectedCountry() {
    return this.form.get(this.address.countryIsoCode).value;
  }

  updateOrder(country: string) {
    this.onAfterViewInit$
      .pipe(
        filter(v => Boolean(v)),
        take(1),
      )
      .subscribe(() => {
        if (this.view === BillingAddressViewEnum.checkout) {
          return this.setCheckoutFormOrder(country);
        }

        this.setDefaultFormOrder(country);
      });
  }

  fieldValue(field: BillingAddressEnum) {
    return this.form.get(field).value;
  }

  hasError(fieldName: string): boolean {
    return Boolean(getFormFieldError(this.error, fieldName));
  }

  onCountryIdChange(countryIsoCode: string) {
    if (countryIsoCode !== this.fieldValue(BillingAddressEnum.countryIsoCode)) {
      this.onCountryStateChange('');
      this.updateOrder(countryIsoCode);
    }

    this.form.get(BillingAddressEnum.countryIsoCode).patchValue(countryIsoCode);
  }

  onCountryStateChange(state: string) {
    this.form.get(BillingAddressEnum.stateCode).patchValue(state);
  }

  onPhoneChange(phone: string) {
    this.error = removeFormFieldError(this.error, 'phone');
    this.form.get(BillingAddressEnum.phone).patchValue(phone);
  }

  setDefaultFormOrder(country: string) {
    if (this.isJapanCountry(country)) {
      return this.order$.next([
        this.countryRef,
        this.nameRef,
        this.postCodeRef,
        this.stateRef,
        this.cityRef,
        this.addressFieldRef,
        this.contactEmailRef,
        this.phoneRef,
        this.billingEmailRef,
        this.supportEmailRef,
      ]);
    }

    this.order$.next([
      this.countryRef,
      this.nameRef,
      this.addressFieldRef,
      this.cityRef,
      this.postCodeRef,
      this.stateRef,
      this.contactEmailRef,
      this.phoneRef,
      this.billingEmailRef,
      this.supportEmailRef,
    ]);
    this.cdRef.detectChanges();
  }

  setCheckoutFormOrder(country: string) {
    if (this.isJapanCountry(country)) {
      return this.order$.next([
        [this.countryRef],
        [this.nameRef],
        [this.postCodeRef, this.stateRef],
        [this.cityRef, this.addressFieldRef],
        [this.emailRef, this.phoneRef],
      ]);
    }

    this.order$.next([
      [this.countryRef],
      [this.nameRef, this.contentRef],
      [this.addressFieldRef, this.emailRef],
      [this.postCodeRef, this.phoneRef],
      [this.cityRef, this.stateRef],
    ]);

    this.cdRef.detectChanges();
  }

  isJapanCountry(country: string) {
    return country === 'JP';
  }

  getStateLabelKey(country: string) {
    return this.isJapanCountry(country) ? 'txt_address_prefecture_field_label' : 'txt_address_state_field_label';
  }
}
