import { NgModule } from '@angular/core';
import { NotificationModule } from './notification';
import { DeckModule } from './deck/deck.module';
import { TagsComponent } from './tags/tags.component';
import { DropdownModule } from './dropdown/dropdown.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { TagComponent } from './tag/tag.component';
import { TooltipModule } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.module';
import { PhoneFieldModule } from '@gelato-api-ui/ui-kit/src/lib/phone-field/phone-field.module';
import { PageHeaderComponent } from '@gelato-api-ui/ui-kit/src/lib/components/page-header/page-header.component';
import { BreadcrumbsComponent } from '@gelato-api-ui/ui-kit/src/lib/components/breadcrumbs/breadcrumbs.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { RefreshButtonComponent } from '@gelato-api-ui/ui-kit/src/lib/components/refresh-button/refresh-button.component';
import { CheckboxComponent } from '@gelato-api-ui/ui-kit/src/lib/components/checkbox/checkbox.component';
import { ThumbnailComponent } from '@gelato-api-ui/ui-kit/src/lib/components/thumbnail/thumbnail.component';
import { ColorComponent } from '@gelato-api-ui/ui-kit/src/lib/components/color/color.component';
import { ActionsPanelButtonsContainerComponent } from '@gelato-api-ui/ui-kit/src/lib/components/actions-panel-buttons-container/actions-panel-buttons-container.component';
import { ActionsPanelComponent } from '@gelato-api-ui/ui-kit/src/lib/components/actions-panel/actions-panel.component';
import { ActionsPanelStepButtonComponent } from '@gelato-api-ui/ui-kit/src/lib/components/actions-panel-step-button/actions-panel-step-button.component';
import { ActionsPanelStepSeparatorComponent } from '@gelato-api-ui/ui-kit/src/lib/components/actions-panel-step-separator/actions-panel-step-separator.component';
import { ActionsPanelStepsContainerComponent } from '@gelato-api-ui/ui-kit/src/lib/components/actions-panel-steps-container/actions-panel-steps-container.component';
import { EcommerceProductWizardBreadcrumbsComponent } from '@gelato-api-ui/ui-kit/src/lib/components/ecommerce-product-wizard-breadcrumbs/ecommerce-product-wizard-breadcrumbs.component';
import { EcommerceProductWizardBackButtonComponent } from '@gelato-api-ui/ui-kit/src/lib/components/ecommerce-product-wizard-back-to-store-button/ecommerce-product-wizard-back-button.component';
import { EcommerceProductWizardActionsPanelComponent } from '@gelato-api-ui/ui-kit/src/lib/components/ecommerce-product-wizard-actions-panel/ecommerce-product-wizard-actions-panel.component';
import { EcommerceProductWizardActionsPanelProductSelectionComponent } from '@gelato-api-ui/ui-kit/src/lib/components/ecommerce-product-wizard-actions-panel-product-selection/ecommerce-product-wizard-actions-panel-product-selection.component';
import { RadioButtonComponent } from '@gelato-api-ui/ui-kit/src/lib/components/radio-button/radio-button.component';
import { ProgressBarComponent } from '@gelato-api-ui/ui-kit/src/lib/progress-bar/progress-bar.component';
import { SuiProgressModule } from '@giomamaladze/ng2-semantic-ui';
import { ResetableTextFieldModule } from '@gelato-api-ui/ui-kit/src/lib/resetable-text-field/resetable-text-field.module';
import { LogEventDirective } from '@gelato-api-ui/ui-kit/src/lib/analytics/log-event.directive';
import { ScrollEventDirective } from '@gelato-api-ui/ui-kit/src/lib/sticky-scroll/sticky-scroll';
import { AnimatedDotsComponent } from '@gelato-api-ui/ui-kit/src/lib/components/animated-dots/animated-dots.component';
import { EcommerceProductWizardAutosaveStatusComponent } from '@gelato-api-ui/ui-kit/src/lib/components/ecommerce-product-wizard-autosave-status/ecommerce-product-wizard-autosave-status.component';
import { PlaceholderComponent } from '@gelato-api-ui/ui-kit/src/lib/placeholder/placeholder.component';
import { FullScreenSpinnerComponent } from '@gelato-api-ui/ui-kit/src/lib/components/spinner/full-screen-spinner.component';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';
import { TabLinksComponent } from './tab-links/tab-links.component';

const modules = [
  NotificationModule,
  DeckModule,
  DropdownModule,
  TagsComponent,
  TooltipModule,
  PhoneFieldModule,
  ResetableTextFieldModule,
];
const components = [
  TagsComponent,
  TagComponent,
  PageHeaderComponent,
  BreadcrumbsComponent,
  RefreshButtonComponent,
  CheckboxComponent,
  RadioButtonComponent,
  ThumbnailComponent,
  ColorComponent,
  ActionsPanelComponent,
  ActionsPanelButtonsContainerComponent,
  ActionsPanelStepButtonComponent,
  ActionsPanelStepSeparatorComponent,
  ActionsPanelStepsContainerComponent,
  EcommerceProductWizardBreadcrumbsComponent,
  EcommerceProductWizardBackButtonComponent,
  EcommerceProductWizardActionsPanelComponent,
  EcommerceProductWizardActionsPanelProductSelectionComponent,
  EcommerceProductWizardAutosaveStatusComponent,
  ProgressBarComponent,
  LogEventDirective,
  ScrollEventDirective,
  AnimatedDotsComponent,
  PlaceholderComponent,
  FullScreenSpinnerComponent,
  TabLinksComponent,
];

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    TooltipModule,
    SuiProgressModule,
    SdkPipesModule,
  ],
  exports: [...modules, ...components],
  declarations: components,
})
export class UiKitModule {}
