import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AssetAuthorsLabelService {
  constructor(private readonly translate: TranslateService) {}

  get(assetAuthors: string[]): string {
    if (!assetAuthors || !assetAuthors.length) {
      return null;
    }

    const key =
      assetAuthors.length > 1 ? 'txt_shutterstock_images_authors_plural' : 'txt_shutterstock_images_authors_singular';

    return this.translate.instant(key, { authors: assetAuthors.join(', ') });
  }
}
