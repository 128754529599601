import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { ResponsiveService } from '@gelato-api-ui/core/responsive/responsive.service';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { take, tap } from 'rxjs/operators';
import { GelatoPlusPopupLocations } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-popup-locations';
import { GelatoPlusFeatures } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-features';
import {
  SubscriptionFlowActiveStep,
  SubscriptionFlowFacade,
} from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/subscription-flow.facade';
import { GelatoSubscriptionModalType } from '../../lib/gelato-subscription-modal';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';

export interface GelatoSubscriptionModalContext {
  gelatoPlusFeature: GelatoPlusFeatures;
  gelatoPlusPopupLocation: GelatoPlusPopupLocations;
  gelatoSubscriptionModal: GelatoSubscriptionModalType;
}

@Component({
  selector: 'gc-gelato-plus-subscription-modal',
  templateUrl: './gelato-plus-subscription-modal.component.html',
  styleUrls: ['./gelato-plus-subscription-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GelatoSubscriptionModalComponent implements OnInit, OnDestroy, AfterViewInit {
  analyticsProperties = this.subscriptionFlowFacade.analyticsProperties$;
  activeStep: SubscriptionFlowActiveStep;

  gelatoPlusSubscriptionsStatuses$ = this.subscriptionFacade.gelatoPlusSubscriptionsStatuses$;
  companyDetails$ = this.subscriptionFacade.companyDetails$;
  isMobile$ = this.responsive.isMobile$;
  monthServicePlanPrice$ = this.subscriptionFacade.gelatoPlusMonthPriceOnYearlyPlan$;
  gelatoPlusSubscriptionHasExpired$ = this.subscriptionFacade.gelatoPlusSubscriptionHasExpired$;
  gelatoPlusGoldIsActive$ = this.subscriptionFacade.gelatoPlusGoldIsActive$;
  isGelatoPlusActive$ = this.subscriptionFacade.gelatoPlusIsActive$;
  isUserNeverHadGelatoPlusSubscription$ = this.subscriptionFacade.isUserNeverHadGelatoPlusSubscription$;
  isUserNeverHadGelatoPlusGoldSubscription$ = this.subscriptionFacade.isUserNeverHadGelatoPlusGoldSubscription$;
  isClientEligibleForGoldSubscription$ = this.subscriptionFacade.isClientEligibleForGoldSubscription$;
  billingInfoIsFilled$ = this.subscriptionFacade.isBillingValid$;
  isTaxLoading$ = this.subscriptionFacade.isTaxCalculating$;
  servicePricePlanTax$ = this.subscriptionFacade.servicePricePlanTax$;
  isGoldTrialNotAvailable$ = this.featureSwitcherService.isFeatureEnabledForCurrentUser(
    FeatureFlagEnum.is_gold_trial_not_available,
  );

  constructor(
    public modal: SuiModal<GelatoSubscriptionModalContext, void, void>,
    private readonly responsive: ResponsiveService,
    private readonly subscriptionFacade: SubscriptionsFacade,
    private readonly subscriptionFlowFacade: SubscriptionFlowFacade,
    private readonly featureSwitcherService: FeatureSwitcherService,
  ) {
    this.subscriptionFlowFacade.saveModalInstance(this.modal);
  }

  ngOnInit() {
    this.responsive.onScreenSizeChanges();
    const { gelatoPlusFeature } = this.modal.context;

    if (gelatoPlusFeature) {
      this.logInitEvents();
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const parentElement = document.querySelectorAll('.modal.ui')[0] as HTMLDivElement;
      parentElement.style.maxWidth = '1048px';
    });
  }

  ngOnDestroy() {
    this.responsive.onRemoveScreenSizeChanges();
  }

  private logInitEvents() {
    const { gelatoPlusPopupLocation } = this.modal.context;

    this.gelatoPlusSubscriptionsStatuses$
      .pipe(
        take(1),
        tap(gelatoPlusSubscriptionsStatuses => {
          logEvent('subscriptionFlowInit', this.subscriptionFlowFacade.analyticsProperties$.getValue());

          this.subscriptionFlowFacade.analyticsProperties$.next({
            featurePopUp: this.modal.context.gelatoPlusFeature,
            modalType: this.modal.context.gelatoSubscriptionModal,
            locationPopup: gelatoPlusPopupLocation,
            featuresUsed: this.subscriptionFacade.pluginsListUsedInTheEditor$.getValue(),
            gelato_plus_subscription: gelatoPlusSubscriptionsStatuses,
          });

          if (this.activeStep === SubscriptionFlowActiveStep.pricePlans) {
            logEvent(
              'gelatoPlusPopupViewBillingFrequencySelection',
              this.subscriptionFlowFacade.analyticsProperties$.getValue(),
            );
            logEvent(
              'subscriptionFlowBillingFrequencyStep',
              this.subscriptionFlowFacade.analyticsProperties$.getValue(),
            );
          }
        }),
      )
      .subscribe();
  }
}

export class GelatoSubscriptionModal extends ComponentModalConfig<GelatoSubscriptionModalContext, void, void> {
  constructor(
    gelatoPlusFeature: GelatoPlusFeatures,
    gelatoPlusPopupLocation: GelatoPlusPopupLocations,
    gelatoSubscriptionModal: GelatoSubscriptionModalType,
  ) {
    super(GelatoSubscriptionModalComponent, {
      gelatoPlusFeature,
      gelatoPlusPopupLocation,
      gelatoSubscriptionModal,
    });
    this.isClosable = true;
    this.transitionDuration = 400;
    this.size = ModalSize.Tiny;
  }
}
