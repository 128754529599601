import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CdkStep, CdkStepper } from '@angular/cdk/stepper';

@Component({
  selector: 'gc-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {
  @Input() activeClass;
  @Input() showStepperCount = true;

  setSelectedIndex(idx: number, step: CdkStep): void {
    if (step.editable) {
      this.selectedIndex = idx;
    }
  }
}
