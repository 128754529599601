export enum DesignEditorPlugin {
  APP_IMAGE_FILTERS = 'app-image-filters',
  APP_ADVANCED_PERSONALIZATION = 'app-advanced-personalization',
  APP_GRAPHICS = 'app-graphics',
  APP_TEXT_EFFECTS = 'app-text-effects',
  APP_SHUTTERSTOCK_ESSENTIAL = 'app-shutterstock-essential',
  APP_SHUTTERSTOCK_FULL = 'app-shutterstock-full',
  APP_SHUTTERSTOCK = 'app-shutterstock',
  APP_GETTY_IMAGES = 'app-getty-images',
  APP_FONTS = 'app-fonts',
  APP_BACKGROUND_REMOVAL = 'app-background-removal',
}

export const DesignEditorPluginNames = {
  [DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION]: 'txt_design_editor_collections_tab',
  [DesignEditorPlugin.APP_GRAPHICS]: 'txt_design_editor_graphics_tab',
  [DesignEditorPlugin.APP_SHUTTERSTOCK]: 'txt_design_editor_shutterstock_tab',
  [DesignEditorPlugin.APP_GETTY_IMAGES]: 'txt_design_editor_getty_tab',
};
