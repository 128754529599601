import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  ServicePlanSearchRequest,
  ServicePlanSearchResponse,
} from '@api-ui-app/src/app/subscriptions/types/subscription-plans';
import {
  ServicePlanPriceSearchRequest,
  ServicePlanPriceSearchResponse,
  ServicePricePlanTaxResponse,
} from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPlansApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  /**
   * Search subscription plans
   * @param servicePlanSearchRequest -> {@link ServicePlanSearchRequest}
   */
  searchSubscriptionPlans(
    servicePlanSearchRequest: Partial<ServicePlanSearchRequest>,
  ): Observable<ServicePlanSearchResponse> {
    return this.apiRequest
      .get<ServicePlanSearchResponse>('/api/v3/services/*/plans', { body: servicePlanSearchRequest })
      .pipe(
        map((data: any) => data.data),
        catchError((error): Observable<ServicePlanSearchResponse> => of(null)),
      );
  }

  /**
   * Get price plans for the particular subscription/service
   * @param servicePlanPriceSearchRequest -> {@link ServicePlanPriceSearchRequest}
   */
  searchSubscriptionPlansPrices(
    servicePlanPriceSearchRequest: Partial<ServicePlanPriceSearchRequest>,
  ): Observable<ServicePlanPriceSearchResponse> {
    return this.apiRequest
      .get<ServicePlanPriceSearchResponse>(`/api/v3/services/*/plans/*/prices`, {
        body: servicePlanPriceSearchRequest,
      })
      .pipe(
        map((data: any) => data.data),
        catchError((error): Observable<ServicePlanPriceSearchResponse> => of(null)),
      );
  }

  calculateSubscriptionPlanTax(servicePricePlanID: string, clientId: string): Observable<ServicePricePlanTaxResponse> {
    return this.apiRequest
      .get<ServicePricePlanTaxResponse>(`/api/v3/services/*/plans/*/prices/${servicePricePlanID}:calc-tax`, {
        body: {
          clientId,
        },
      })
      .pipe(map((data: any) => data.data));
  }
}
