import { Injectable } from '@angular/core';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { ProductTemplateNameModal } from '@api-ui-app/src/app/e-commerce-product-templates/components/product-template-name-modal/product-template-name-modal.component';
import { TranslateService } from '@ngx-translate/core';
import { ActiveModal } from '@giomamaladze/ng2-semantic-ui/index';

@Injectable({ providedIn: 'root' })
export class ECommerceProductTemplateNameModalService {
  constructor(private readonly modalService: SuiModalService, private readonly translateService: TranslateService) {}

  openTemplateCreationModal(templateName: string): ActiveModal<any, any, any> {
    return this.modalService.open(
      new ProductTemplateNameModal(
        templateName,
        this.translateService.instant('txt_product_template_creation_modal_title'),
        this.translateService.instant('txt_product_template_creation_modal_description'),
        this.translateService.instant('txt_save_template'),
        true,
      ),
    );
  }

  openTemplateRenamingModal(templateName: string): ActiveModal<any, any, any> {
    return this.modalService.open(
      new ProductTemplateNameModal(
        templateName,
        this.translateService.instant('txt_product_template_renaming_modal_title'),
        this.translateService.instant('txt_product_template_renaming_modal_description'),
        this.translateService.instant('txt_rename_template'),
        false,
      ),
    );
  }
}
