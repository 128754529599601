import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gd-price-plan-id-selector',
  templateUrl: './price-plan-id-selector.component.html',
  styleUrls: ['./price-plan-id-selector.component.scss'],
})
export class PricePlanIdSelectorComponent implements OnInit {
  @Input() pricePlanId: string;
  @Input() showAnyValueOption = false;
  @Input() isSearchable = true;
  @Input() isDisabled = false;
  @Output() pricePlanIdChange: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onPricePlanIdChange(clientId: string) {
    this.pricePlanIdChange.emit(clientId);
  }
}
