/**
 * https://confluence.gelato.tech/pages/viewpage.action?spaceKey=GAPI&title=2.2.8.2+Products+V1#ProductValidateRequest
 */
export interface EProductValidationResponse {
  errors: EProductValidationError[];
}

export interface EProductValidationError {
  code: EProductValidationErrorCode; // Error code attached to a particular rule
  data: EProductValidationErrorData[]; // Key-value map of additional data that can be used in the error message.
}

export interface EProductValidationErrorData {
  key: string;
  value: number | string[] | string;
}

export enum EProductValidationErrorCode {
  GENERAL_ERROR = 'GENERAL_ERROR',
  TOO_MANY_PRODUCT_OPTIONS = 'TOO_MANY_PRODUCT_OPTIONS', // Too many options on a product level
  TOO_MANY_VARIANT_OPTIONS = 'TOO_MANY_VARIANT_OPTIONS', // Too many options on a variant level
  TOO_MANY_VARIANTS = 'TOO_MANY_VARIANTS', // Too many variants
  TOO_MANY_IMAGES = 'TOO_MANY_IMAGES', // Too many images
  PRICE_TOO_LOW = 'PRICE_TOO_LOW', // Price below minimum
  PRICE_TOO_HIGH = 'PRICE_TOO_HIGH', // Price above maximum
  TOO_MANY_TAGS = 'TOO_MANY_TAGS', // Too many product tags
  DUPLICATED_TAGS = 'DUPLICATED_TAGS', // Tags were duplicated
  TAGS_TOO_LONG = 'TAGS_TOO_LONG', // Tags were too long
  DESCRIPTION_EMPTY = 'DESCRIPTION_EMPTY', // Empty description
  DESCRIPTION_TOO_LONG = 'DESCRIPTION_TOO_LONG', // Description too long
  TITLE_EMPTY = 'TITLE_EMPTY', // Empty title
  TITLE_TOO_LONG = 'TITLE_TOO_LONG', // Title to long
  TITLE_ALL_CAPITAL_LETTERS = 'TITLE_ALL_CAPITAL_LETTERS', // TItle with all capital letters
  TITLE_NOT_ALLOWED_CHARACTERS = 'TITLE_NOT_ALLOWED_CHARACTERS', // Title with characters not being allowed
  TITLE_CHARACTERS_ALLOWED_ONCE = 'TITLE_CHARACTERS_ALLOWED_ONCE', // The title contained characters allowed only once more than once
  TITLE_MUST_START_WITH_LETTER_OR_NUMBER = 'TITLE_MUST_START_WITH_LETTER_OR_NUMBER', // Title starting not with letter or number
  TITLE_NOT_ALLOWED_DUPLICATE = 'TITLE_NOT_ALLOWED_DUPLICATE', // Title is the same as for already existed product
  PERSONALIZATION_NOT_ALLOWED = 'PERSONALIZATION_NOT_ALLOWED', // Variant using personalization
  NOT_ALL_PRODUCT_COMBINATIONS = 'NOT_ALL_PRODUCT_COMBINATIONS', // Not all possible combinations of product options were selected while all were required
  TITLE_TOO_MANY_CAPITAL_LETTERS_WORDS = 'TITLE_TOO_MANY_CAPITAL_LETTERS_WORDS', // Title with too many words written in capital letters
  TAGS_MUST_START_WITH_LETTER_OR_NUMBER = 'TAGS_MUST_START_WITH_LETTER_OR_NUMBER', // Tags must start with letter or number
  TAGS_NOT_ALLOWED_CHARACTERS = 'TAGS_NOT_ALLOWED_CHARACTERS', // Tags with characters not being allowed
}

export const EProductValidationErrorMessages = {
  [EProductValidationErrorCode.GENERAL_ERROR]: 'txt_validation_general_error',
  [EProductValidationErrorCode.TOO_MANY_PRODUCT_OPTIONS]: 'txt_validation_too_many_product_options',
  [EProductValidationErrorCode.TOO_MANY_VARIANT_OPTIONS]: 'txt_validation_too_many_variant_options',
  [EProductValidationErrorCode.TOO_MANY_VARIANTS]: 'txt_validation_too_many_variants',
  [EProductValidationErrorCode.TOO_MANY_IMAGES]: 'txt_validation_too_many_images',
  [EProductValidationErrorCode.PRICE_TOO_LOW]: 'txt_validation_price_too_low',
  [EProductValidationErrorCode.PRICE_TOO_HIGH]: 'txt_validation_price_too_high',
  [EProductValidationErrorCode.TOO_MANY_TAGS]: 'txt_validation_too_many_tags',
  [EProductValidationErrorCode.DUPLICATED_TAGS]: 'txt_validation_duplicated_tags',
  [EProductValidationErrorCode.TAGS_TOO_LONG]: 'txt_validation_tags_too_long',
  [EProductValidationErrorCode.DESCRIPTION_EMPTY]: 'txt_validation_description_empty',
  [EProductValidationErrorCode.DESCRIPTION_TOO_LONG]: 'txt_validation_description_too_long',
  [EProductValidationErrorCode.TITLE_EMPTY]: 'txt_validation_title_empty',
  [EProductValidationErrorCode.TITLE_TOO_LONG]: 'txt_validation_title_too_long',
  [EProductValidationErrorCode.TITLE_ALL_CAPITAL_LETTERS]: 'txt_validation_title_all_capital_letters',
  [EProductValidationErrorCode.TITLE_NOT_ALLOWED_CHARACTERS]: 'txt_validation_title_not_allowed_characters',
  [EProductValidationErrorCode.TITLE_CHARACTERS_ALLOWED_ONCE]: 'txt_validation_title_characters_allowed_once',
  [EProductValidationErrorCode.TITLE_MUST_START_WITH_LETTER_OR_NUMBER]:
    'txt_validation_title_must_start_with_letter_or_number',
  [EProductValidationErrorCode.TITLE_NOT_ALLOWED_DUPLICATE]: 'txt_validation_title_not_allowed_duplicate',
  [EProductValidationErrorCode.PERSONALIZATION_NOT_ALLOWED]: 'txt_validation_personalization_not_allowed',
  [EProductValidationErrorCode.NOT_ALL_PRODUCT_COMBINATIONS]: 'txt_validation_not_all_product_combinations',
  [EProductValidationErrorCode.TITLE_TOO_MANY_CAPITAL_LETTERS_WORDS]: 'txt_validation_title_too_many_capital_letters',
  [EProductValidationErrorCode.TAGS_MUST_START_WITH_LETTER_OR_NUMBER]:
    'txt_validation_tags_must_start_with_letter_or_number',
  [EProductValidationErrorCode.TAGS_NOT_ALLOWED_CHARACTERS]: 'txt_validation_tags_not_allowed_characters',
};
