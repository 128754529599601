<ng-select
  [ngModel]="values"
  (ngModelChange)="onSelect($event)"
  [items]="options$ | async"
  [bindValue]="'id'"
  [bindLabel]="'name'"
  [placeholder]="'txt_address_country_field_multiple_selection_placeholder' | translate"
  [clearable]="false"
  [readonly]="disabled || !(options$ | async)?.length"
  [searchable]="isSearchable"
  [virtualScroll]="true"
  [multiple]="true"
  [closeOnSelect]="false"
  [hideSelected]="true"
>
  <ng-template ng-label-tmp let-item="item" let-clear="clear">
    <span class="ng-value-icon" (click)="clear(item)" aria-hidden="true">×</span>
    <span class="ng-value-label">
      <ng-container *ngIf="item.id">
        <gc-country-flag [countryIsoCode]="item.id"></gc-country-flag>
        &nbsp;
      </ng-container>
      {{ item.name }}
    </span>
  </ng-template>

  <ng-template ng-option-tmp let-item="item">
    <ng-container *ngIf="item.id">
      <gc-country-flag [countryIsoCode]="item.id"></gc-country-flag>
      &nbsp;
    </ng-container>
    {{ item.name }}
  </ng-template>
</ng-select>
