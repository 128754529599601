import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { UserRolesApiService } from '../shared/services/user-roles-api.service';
import { UserRoleObject } from '../shared/lib/user-role-object';
import { AccessGroup } from '../shared/lib/access-group';
import * as actions from './user-roles.actions';
import { State } from './user-roles.reducer';
import { getState } from './user-roles.selector';
import { getDefaultClientId, isGelatoUser } from './auth.reducer';

@Injectable()
export class UserRolesEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private userRolesApiService: UserRolesApiService,
  ) {}

  Load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.Load>(actions.UserRolesActionTypes.Load),
        withLatestFrom(
          this.store$.select(getState),
          this.store$.select(isGelatoUser),
          this.store$.select(getDefaultClientId),
        ),
        switchMap(([action, state, gelatoUser, defaultClientId]) => {
          const { forced } = action;
          let { clientId } = action;

          if (!clientId && !gelatoUser) {
            clientId = defaultClientId;
          }

          const isDataRelevant = clientId === state.clientId && Boolean(state.userRoles) && Boolean(state.accessGroups);

          const loadData = (): Observable<State> => {
            if (!forced && isDataRelevant) {
              return of(state);
            }

            return this.userRolesApiService.getUserRoles({ clientId }).pipe(
              catchError(() => of(null)),
              switchMap((userRoles: UserRoleObject[]) => {
                return this.userRolesApiService.getAccessGroups({ clientId }).pipe(
                  catchError(() => of(null)),
                  map((accessGroups: AccessGroup[]): State => {
                    return {
                      isLoading: false,
                      clientId,
                      userRoles,
                      accessGroups,
                    };
                  }),
                );
              }),
            );
          };

          this.store$.dispatch(
            new actions.SetState({
              isLoading: true,
              clientId,
              userRoles: isDataRelevant ? state.userRoles : null,
              accessGroups: isDataRelevant ? state.accessGroups : null,
            }),
          );

          return loadData().pipe(
            catchError((err): Observable<State> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((updatedState: State) => {
              this.store$.dispatch(new actions.SetState(updatedState));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
