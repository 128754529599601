import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';
import { updateMetadataItem } from '@gelato-api-ui/core/metadata/helpers/updateMetadataItem';

export const updateMetadataItems = (existingItems: MetadataItem[], itemsToUpdate: MetadataItem[]): MetadataItem[] => {
  let metadata = existingItems && existingItems.length ? [...existingItems] : [];

  if (!itemsToUpdate || !itemsToUpdate.length) {
    return metadata;
  }

  itemsToUpdate.forEach((loopMetadataItem: MetadataItem) => {
    metadata = updateMetadataItem(metadata, loopMetadataItem.key, loopMetadataItem.value);
  });

  return metadata;
};
