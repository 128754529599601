import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Subject } from 'rxjs';
import {
  ServicePricePlanTax,
  ServiceUniqueName,
} from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { takeUntil, tap } from 'rxjs/operators';
import { PricePlansListItem } from '@api-ui-app/src/app/subscriptions/types/price-plans-list-item';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import {
  SubscriptionFlowActiveStep,
  SubscriptionFlowFacade,
} from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/subscription-flow.facade';
import { calculatePricePlanTaxSuccess } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.actions';
import { Actions, ofType } from '@ngrx/effects';
import { GelatoSubscriptionModalType } from '../../lib/gelato-subscription-modal';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { PricePlanItemButton, SubscriptionPlanType } from '@api-ui-app/src/app/app-store/types/price-plan-items';
import { addDays, format } from 'date-fns';
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';

@Component({
  selector: 'gc-subscription-flow',
  templateUrl: './subscription-flow.component.html',
  styleUrls: ['./subscription-flow.component.scss'],
})
export class SubscriptionFlowComponent implements OnChanges, OnInit, OnDestroy {
  @Input() companyDetails: CompanyDetails;
  @Input() isMobile: boolean;
  @Input() monthServicePlanPrice: string;
  @Input() gelatoPlusSubscriptionHasExpired: boolean;
  @Input() isGelatoPlusActive: boolean;
  @Input() gelatoPlusGoldIsActive: boolean;
  @Input() isUserNeverHadGelatoPlusSubscription: boolean;
  @Input() isUserNeverHadGelatoPlusGoldSubscription: boolean;
  @Input() isClientEligibleForGoldSubscription: boolean;
  @Input() billingInfoIsFilled: boolean;
  @Input() isTaxLoading: boolean;
  @Input() servicePricePlanTax: ServicePricePlanTax;
  @Input() gelatoSubscriptionModal: GelatoSubscriptionModalType;
  @Input() isGoldTrialNotAvailable: boolean;

  serviceUniqueName = ServiceUniqueName;
  activeSteps = SubscriptionFlowActiveStep;
  activeStep: SubscriptionFlowActiveStep;
  gelatoSubscriptionModalTypeEnum = GelatoSubscriptionModalType;
  gelatoSubscriptionModalType: GelatoSubscriptionModalType = null;
  planId = SubscriptionPlanType;

  error$ = this.subscriptionFlowFacade.error$;
  modal = this.subscriptionFlowFacade.modal;
  selectedPricePlan$ = this.subscriptionFlowFacade.selectedPricePlan$;
  gelatoPlusMonthPriceOnYearlyPlan$ = this.subscriptionFlowFacade.subscriptionFacade.gelatoPlusMonthPriceOnYearlyPlan$;
  gelatoGoldMonthPriceOnYearlyPlan$ = this.subscriptionFlowFacade.subscriptionFacade.gelatoGoldMonthPriceOnYearlyPlan$;
  countryISOCode$ = this.subscriptionFlowFacade.subscriptionFacade.countryISOCode$;

  get termsAndConditions() {
    return this.isGelatoPlusModal
      ? 'txt_activate_modal_plus_terms_and_conditions'
      : 'txt_activate_modal_gold_terms_and_conditions';
  }

  get txtDiscount() {
    return this.isGelatoPlusModal ? 'txt_activate_modal_plus_discount' : 'txt_activate_modal_gold_discount';
  }

  get pricePlanGelatoPlusButtons(): PricePlanItemButton[] {
    return [
      {
        isVisible: true,
        textKey: this.isUserNeverHadGelatoPlusSubscription ? 'txt_try_gelato_plus_free' : 'txt_subscribe_plus',
        className: 'plus-button',
        onClick: () => {
          this.isUserNeverHadGelatoPlusSubscription
            ? logEvent('GeneralSubscriptionModalTryPlusForFreeClick')
            : logEvent('GeneralSubscriptionModalSubscribePlusClick');
          this.gelatoSubscriptionModalType = GelatoSubscriptionModalType.GELATO_PLUS;
        },
      },
    ];
  }

  get goldPlanButtons(): PricePlanItemButton[] {
    return [
      {
        isVisible: true,
        textKey: this.isGelatoPlusActive ? 'txt_upgrade_to_gold' : 'txt_subscribe_gold',
        className: 'gold-button',
        onClick: () => {
          this.gelatoSubscriptionModalType = GelatoSubscriptionModalType.GELATO_GOLD;
          logEvent('GeneralSubscriptionModalSubscribeGoldClick');
        },
      },
    ];
  }

  get hideDiscountSection() {
    return !this.isUserNeverHadGelatoPlusSubscription || !this.isUserNeverHadGelatoPlusGoldSubscription;
  }

  get showTrialInformation() {
    return (
      (!this.skipTrial && this.isGelatoPlusModal && this.isUserNeverHadGelatoPlusSubscription) ||
      (!this.skipTrial && this.isGelatoPlusGoldModal && this.isUserNeverHadGelatoPlusGoldSubscription)
    );
  }

  get isGelatoPlusGoldModal(): boolean {
    return this.gelatoSubscriptionModalType === GelatoSubscriptionModalType.GELATO_GOLD;
  }

  get isGelatoPlusModal(): boolean {
    return this.gelatoSubscriptionModalType === GelatoSubscriptionModalType.GELATO_PLUS;
  }

  get showSubscriptionPricePlanStep(): boolean {
    const isGelatoPlusGoldModal = this.isGelatoPlusGoldModal && this.isClientEligibleForGoldSubscription;

    return (
      (this.isGelatoPlusModal || isGelatoPlusGoldModal) && this.activeStep === SubscriptionFlowActiveStep.pricePlans
    );
  }

  get isUserNotEligibleToPurchaseGoldSubscription(): boolean {
    return this.isGelatoPlusGoldModal && !this.isClientEligibleForGoldSubscription;
  }

  ngOnDestroy$ = new Subject();
  dateInAMonth = format(addDays(new Date(), 30), 'd MMMM yyyy');
  skipTrial = false;

  constructor(
    private readonly actions$: Actions,
    private readonly cdRef: ChangeDetectorRef,
    public readonly subscriptionFlowFacade: SubscriptionFlowFacade,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.companyDetails && changes.companyDetails.currentValue) {
      this.subscriptionFlowFacade.localCompanyDetails$.next({ ...changes.companyDetails.currentValue });
    }
  }

  ngOnInit() {
    this.activeStep = SubscriptionFlowActiveStep.pricePlans;
    this.gelatoSubscriptionModalType = this.subscriptionFlowFacade.gelatoSubscriptionModalType;

    this.actions$
      .pipe(
        takeUntil(this.ngOnDestroy$),
        ofType(calculatePricePlanTaxSuccess),
        tap(taxResponse => {
          if (taxResponse?.payload?.priceVat) {
            logEvent('subscriptionFlowCalculateTaxSuccess', {
              priceVat: taxResponse?.payload?.priceVat,
              ...this.subscriptionFlowFacade.analyticsProperties$.getValue(),
            });
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }

  selectPricePlan(pricePlan: PricePlansListItem): void {
    this.subscriptionFlowFacade.selectPricePlan(pricePlan, this.activeStep);
  }

  goToNextStep() {
    switch (this.activeStep) {
      case SubscriptionFlowActiveStep.pricePlans:
        if (this.isGelatoPlusModal) {
          this.goToBillingStep();
        } else if (this.isGelatoPlusGoldModal) {
          this.goToTermsAndConditions();
        }
        break;
      case SubscriptionFlowActiveStep.billingAddress:
        if (this.billingInfoIsFilled) {
          this.goToBillingStep();
        } else {
          this.subscriptionFlowFacade.onSaveBillingAddress(this.cdRef);
        }
        break;
      case SubscriptionFlowActiveStep.termsAndConditions:
        this.goToBillingStep();
        break;
    }
  }

  onContactUsClick() {
    location.href = 'mailto:' + 'apisupport@gelato.com' + '?subject=' + 'Gold Subscription';
    logEvent('GoldPricePlanContactUs');
  }

  onUpdateBillingAddress(address) {
    this.subscriptionFlowFacade.localCompanyDetails$.next({
      ...address,
    });
  }

  private goToTermsAndConditions() {
    this.activeStep = SubscriptionFlowActiveStep.termsAndConditions;
  }

  private goToBillingStep() {
    if (this.billingInfoIsFilled) {
      const triaEnd = this.skipTrial ? 0 : null;
      this.subscriptionFlowFacade.openCheckout(triaEnd);
    } else {
      this.activeStep = SubscriptionFlowActiveStep.billingAddress;
      logEvent('gelatoPlusPopupViewSetupBilling', this.subscriptionFlowFacade.analyticsProperties$.getValue());
    }
  }
}
