<gd-client-selector
  [showAllClientsOption]="showAllClientsOption"
  [hideGelatoAdminClient]="hideGelatoAdminClient"
  [allClientsOptionText]="allClientsOptionText || ('txt_any' | translate)"
  [placeholder]="placeholder || ('txt_search_client_field_placeholder' | translate)"
  [valueField]="'id'"
  [value]="clientId"
  (valueChange)="onClientIdChange($event)"
  [isSearchable]="isSearchable"
  [isDisabled]="isDisabled"
>
</gd-client-selector>
