<div class="ui segment box-shadow-new">
  <div class="flex-row flex-justify-between flex-items-center">
    <div class="flex-row flex-justify-start flex-items-center">
      <gd-store-type-icon class="store-icon" [storeType]="type" [size]="32"></gd-store-type-icon>
      <p>{{ name }}</p>
    </div>
    <div *ngIf="showRightArrow">
      <i class="icon chevron right"></i>
    </div>
  </div>
</div>
