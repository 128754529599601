import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, combineLatest, of, Subject } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { PreviewsArchiveFacade } from '@api-ui-app/src/app/previews-archive/+state/previews-archive.facade';
import { DestygoWebChatService } from '@api-ui-app/src/app/app-store/services/destygo-web-chat.service';

const LS_KEY = 'previewsDownloadPanelVisible';

@Component({
  selector: 'gc-previews-archive-loading-message',
  templateUrl: './previews-archive-loading-message.component.html',
  styleUrls: ['./previews-archive-loading-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewsArchiveLoadingMessageComponent implements OnInit, OnDestroy {
  previewsArchiveIsLoading$ = this.facade.previewsArchiveIsLoading$;
  taskIds$ = this.facade.previewsArchiveTasks$;
  productTitles$ = this.facade.previewsArchiveProductTitles$;
  previewsAmount$ = this.facade.previewsArchivePreviewsAmount$;
  isMessageTopPosition$ = this.destygoWebChatService.isWebChatEnabled$;
  show$ = combineLatest([this.previewsArchiveIsLoading$, this.taskIds$]).pipe(
    mergeMap(([isLoading, tasks]) => of(isLoading && tasks.length > 0)),
  );
  collapsed$ = new BehaviorSubject<boolean>(Boolean(localStorage.getItem(LS_KEY)));
  public ngOnDestroy$ = new Subject<boolean>();

  constructor(private readonly facade: PreviewsArchiveFacade, private destygoWebChatService: DestygoWebChatService) {}

  ngOnInit() {
    this.taskIds$
      .pipe(
        tap(tasks => this.hideZendeskChatWidget(Boolean(tasks.length))),
        takeUntil(this.ngOnDestroy$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.hideZendeskChatWidget(false);
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }

  hideZendeskChatWidget(hide = true) {
    const widgetElement = document.getElementById('launcher');

    if (widgetElement) {
      widgetElement.style.opacity = hide ? '0' : '1';
      widgetElement.style.pointerEvents = hide ? 'none' : 'auto';
    }
  }

  getTaskName(index: number) {
    return combineLatest([this.productTitles$, this.previewsAmount$]).pipe(
      map(([titles, amounts]) => {
        const title = this.capitalize(titles[index] || '');
        return title && amounts[index] ? `${title} (${amounts[index]})` : '';
      }),
    );
  }

  cancelDownload(taskId: string) {
    this.facade.cancelPreviewsArchiveDownload(taskId);
  }

  toggleListView() {
    localStorage.setItem(LS_KEY, !this.collapsed$.value ? 'true' : '');
    this.collapsed$.next(!this.collapsed$.value);
    this.hideZendeskChatWidget();
  }

  private capitalize(word) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }
}
