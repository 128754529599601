import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';
import { catchError, map, tap } from 'rxjs/operators';
import { caller } from '@gelato-api-ui/core/api/caller';
import { callerBlob } from '@gelato-api-ui/core/api/caller-blob';
import { environment } from '@api-ui-app/src/environments/environment';
import {
  PreflightProductPreviewScenesRequest,
  PreflightProductPreviewScenesResponse,
} from '../preflight-product-preview-scenes-response';
import { PreflightPdfProductTemplateRequest } from '../preflight-pdf-product-template-request';
import { DESIGN_EDITOR_BACKGROUND_COLOR } from '@gelato-api-ui/core/design-editor/desgin-editor-background-color';
import { ProductVariantAdditionalParams } from '@gelato-api-ui/core/product-catalogue/product-variant-additional-params';

type PreflightPreviewScenesCollection = Record<string, PreflightProductPreviewScenesResponse>;

@Injectable({ providedIn: 'root' })
export class PreflightCdnService {
  get: <T>(url: string, options?: AjaxRequest) => Observable<any>;
  getBlob: <T>(url: string, options?: AjaxRequest) => Observable<Blob>;
  cachedEditorProductPreviewScenes: PreflightPreviewScenesCollection = {};

  constructor() {
    this.get = caller(this.baseUrl, 'GET');
    this.getBlob = callerBlob(this.baseUrl, 'GET');
  }

  get baseUrl(): string {
    return environment.baseUrls.preflightCdn;
  }

  getProductPreviewScenes(
    sceneType: string,
    productUid: string,
    backgroundColor?: string,
    additionalParams?: ProductVariantAdditionalParams,
  ): Observable<PreflightProductPreviewScenesResponse> {
    const body: PreflightProductPreviewScenesRequest = {
      product_uid: productUid,
      page_count: additionalParams?.pageCount,
      width: additionalParams?.width,
      height: additionalParams?.height,
    };

    if (backgroundColor) {
      body.background_color = encodeURIComponent(backgroundColor);
    }

    return this.get<PreflightProductPreviewScenesResponse>(`/product/preview/scenes/${sceneType}`, { body }).pipe(
      map((r): PreflightProductPreviewScenesResponse => r.data),
    );
  }

  getEditorPagesProductPreviewScenes(
    productUid: string,
    additionalParams?: ProductVariantAdditionalParams,
  ): Observable<PreflightProductPreviewScenesResponse> {
    if (this.cachedEditorProductPreviewScenes?.[productUid]) {
      return of(this.cachedEditorProductPreviewScenes[productUid]);
    }

    return this.getProductPreviewScenes('editor', productUid, DESIGN_EDITOR_BACKGROUND_COLOR, additionalParams).pipe(
      tap(data => (this.cachedEditorProductPreviewScenes[productUid] = data)),
    );
  }

  getProductTemplate(request: PreflightPdfProductTemplateRequest): Observable<Blob> {
    return this.getBlob<Blob>('/product/template', { body: request }).pipe(
      catchError((): Observable<Blob> => {
        return of(null);
      }),
    );
  }

  downloadProductTemplate(productUid: string, pageCount: number = null): void {
    window.open(this.getProductTemplateUrl(productUid, pageCount || undefined), '_blank');
  }

  getProductTemplateUrl(productUid: string, pageCount: number = null): string {
    const paramsPairs = [];

    if (productUid) {
      paramsPairs.push(`product_uid=${productUid}`);
    }

    if (pageCount) {
      paramsPairs.push(`page_count=${pageCount}`);
    }

    return `${this.baseUrl}/product/template?${paramsPairs.join('&')}`;
  }
}
