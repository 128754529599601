import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { ProductCatalogueSearchRequest } from '@gelato-api-ui/core/product-catalogue/product-catalogue-search-request';
import { ProductCatalogueSearchResponse } from '@gelato-api-ui/core/product-catalogue/product-catalogue-search-response';
import { ProductCatalogue } from '@gelato-api-ui/core/product-catalogue/product-catalogue';
import {
  ProductAttributesCollection as ProductDetailsAttributesCollection,
  ProductDetails,
  ProductDimension,
  ProductDimensionsCollection as ProductDetailsDimensionsCollection,
  ProductNaming,
} from '@gelato-api-ui/core/product-details/product-details';
import { ProductPrice } from '@gelato-api-ui/core/product-prices/product-price';
import { DimensionUnit } from '@gelato-api-ui/core/product-details/dimension-unit.enum';
import { WeightUnit } from '@gelato-api-ui/core/product-details/weight-unit.enum';
import { ProductSearchRequest } from '@gelato-api-ui/core/product-catalogue/product-search-request';
import { ProductSearchResponse } from '@gelato-api-ui/core/product-catalogue/product-search-response';
import { ProductSearchHits } from '@gelato-api-ui/core/product-catalogue/product-search-hits';
import { ProductSearchResultItem } from '@gelato-api-ui/core/product-catalogue/product-search-result-item';
import { ProductSearchResultItemDimensionsCollection } from '@gelato-api-ui/core/product-catalogue/product-search-result-item-dimensions-collection';
import { ProductCatalogInfo } from '@gelato-api-ui/core/product-catalogue/product-catalog-info';
import { ProductCatalogInfoResponse } from '@gelato-api-ui/core/product-catalogue/product-catalog-info-response';
import { ProductAttributesCollection } from '@gelato-api-ui/core/product-catalogue/product-attributes-collection';
import { ProductAttributeValuesCollection } from '@gelato-api-ui/core/product-catalogue/product-attribute-values-collection';
import { ProductAttribute } from '@gelato-api-ui/core/product-catalogue/product-attribute';
import { ProductAttributeValue } from '@gelato-api-ui/core/product-catalogue/product-attribute-value';
import { ProductSearchCategory } from '@gelato-api-ui/core/product-catalogue/product-search-category';
import * as actions from './product-catalogue.actions';
import { convertDimensionValue } from '@gelato-api-ui/core/product-details/helpers/convertDimensionValue';
import { convertWeightValue } from '@gelato-api-ui/core/product-details/helpers/convertWeightValue';
import { findProductCategoryByName } from '@gelato-api-ui/core/sanity/helpers/findProductCategoryByName';
import { getSelectedClientCountry } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { getSanityContent, getSanityProducts } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.selector';
import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { SanityProductSimplified } from '@gelato-api-ui/core/sanity/sanity-product-simplified';
import { findSanityProductCategoryByProductName } from '@gelato-api-ui/core/sanity/helpers/findSanityProductCategoryByProductName';
import { SanityProductVariation } from '@gelato-api-ui/core/sanity/sanity-product-variation';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { ApparelSanityProductCategoryNames } from '@gelato-api-ui/core/sanity/apparel-sanity-product-category-names';
import { ApparelAndAccessoriesSanityProductCategoryNames } from '@gelato-api-ui/core/sanity/apparel-and-accessories-sanity-product-category-names';
import { getCategoryByProductModel, getParentCategory } from '@gelato-api-ui/core/sanity/helpers/getParentCategory';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { ApparelExtraPrintAreas } from '@gelato-api-ui/core/print-sides/extra-print-areas';

export const getProductCatalogueState = (state): State => state.productCatalogue.productCatalogue;

export const getCatalogueListState = createSelector(
  getProductCatalogueState,
  (productCatalogueState: State): CatalogueListState => productCatalogueState.catalogueList,
);

export const getCatalogueListResponse = createSelector(
  getProductCatalogueState,
  (productCatalogueState: State): ProductCatalogueSearchResponse => productCatalogueState.catalogueList.payload,
);

export const getCatalogueState = createSelector(
  getProductCatalogueState,
  (productCatalogueState: State): CatalogueState => productCatalogueState.catalogue,
);

export const getCatalogue = createSelector(
  getCatalogueState,
  (state: CatalogueState): ProductCatalogue => state.payload,
);

export const isClientSpecificCatalogue = createSelector(
  getCatalogue,
  (productCatalogue: ProductCatalogue): boolean => Boolean(productCatalogue) && Boolean(productCatalogue.clientId),
);

export const getSelectedDimensionUnit = createSelector(
  getProductCatalogueState,
  (state: State): DimensionUnit => state.selectedDimensionUnit,
);

export const getSelectedWeightUnit = createSelector(getSelectedDimensionUnit, (selectedDimensionUnit: string): string =>
  selectedDimensionUnit === DimensionUnit.INCH ? WeightUnit.POUND : WeightUnit.GRAM,
);

export const getProductDetailsState = createSelector(
  getProductCatalogueState,
  (productCatalogueState: State): ProductDetailsState => productCatalogueState.productDetails,
);

export const getProductDetails = createSelector(
  getProductDetailsState,
  getSelectedDimensionUnit,
  getSelectedWeightUnit,
  (
    state: ProductDetailsState,
    selectedDimensionUnit: DimensionUnit,
    selectedWeightUnit: WeightUnit,
  ): ProductDetails => {
    if (!state.payload) {
      return null;
    }

    if (state.payload.dimensions || state.payload.weight) {
      let dimensions = null;

      if (state.payload.dimensions) {
        dimensions = R.map((dimension: ProductDimension) => {
          try {
            const convertedValue = convertDimensionValue(dimension.value, dimension.measureUnit, selectedDimensionUnit);

            return {
              ...dimension,
              value: convertedValue,
              measureUnit: selectedDimensionUnit,
            };
          } catch (e) {
            return dimension;
          }
        }, state.payload.dimensions);
      }

      let weight = null;

      if (state.payload.weight) {
        try {
          const convertedValue = convertWeightValue(
            state.payload.weight.value,
            state.payload.weight.measureUnit,
            selectedWeightUnit,
          );

          weight = {
            ...state.payload.weight,
            value: convertedValue,
            measureUnit: selectedWeightUnit,
          };
        } catch (e) {
          weight = state.payload.weight;
        }
      }

      return {
        ...state.payload,
        weight,
        dimensions,
      };
    }

    return state.payload;
  },
);

export const getProductNaming = createSelector(
  getProductDetails,
  (productDetails: ProductDetails): ProductNaming => (productDetails ? productDetails.naming : null),
);

export const getProductTypeUid = createSelector(
  getProductNaming,
  (productNaming: ProductNaming): ProductTypeUid => (productNaming ? productNaming.productTypeUid : null),
);

export const getProductDetailsDimensions = createSelector(
  getProductDetails,
  (productDetails: ProductDetails): ProductDetailsDimensionsCollection =>
    (productDetails ? productDetails.dimensions : null) || {},
);

export const getProductDetailsAttributes = createSelector(
  getProductDetails,
  (productDetails: ProductDetails): ProductDetailsAttributesCollection =>
    (productDetails ? productDetails.attributes : null) || {},
);

export const getProductDimensionValue = (
  productDimensions: ProductDetailsDimensionsCollection,
  regExp: RegExp,
): ProductDimension => {
  if (!regExp) {
    return null;
  }

  const key = Object.keys(productDimensions).find((loopKey: string): boolean => regExp.test(loopKey));

  return key && productDimensions[key] ? productDimensions[key] : null;
};

export const getProductWidth = createSelector(
  getProductDetailsDimensions,
  (dimensions: ProductDetailsDimensionsCollection): ProductDimension =>
    getProductDimensionValue(dimensions, /^width$/i),
);

export const getProductHeight = createSelector(
  getProductDetailsDimensions,
  (dimensions: ProductDetailsDimensionsCollection): ProductDimension =>
    getProductDimensionValue(dimensions, /^height$/i),
);

export const getProductDiameter = createSelector(
  getProductDetailsDimensions,
  (dimensions: ProductDetailsDimensionsCollection): ProductDimension =>
    getProductDimensionValue(dimensions, /^diameter$/i),
);

export const getProductVolume = createSelector(
  getProductDetailsDimensions,
  (dimensions: ProductDetailsDimensionsCollection): ProductDimension =>
    getProductDimensionValue(dimensions, /^volume$/i),
);

export const getProductFrameWidth = createSelector(
  getProductDetailsDimensions,
  (dimensions: ProductDetailsDimensionsCollection): ProductDimension =>
    getProductDimensionValue(dimensions, /^frame width$/i),
);

export const getProductFrameThickness = createSelector(
  getProductDetailsDimensions,
  (dimensions: ProductDetailsDimensionsCollection): ProductDimension =>
    getProductDimensionValue(dimensions, /^frame thickness$/i),
);

export const getProductWallHangerWidth = createSelector(
  getProductDetailsDimensions,
  (dimensions: ProductDetailsDimensionsCollection): ProductDimension =>
    getProductDimensionValue(dimensions, /^wall hanger width$/i),
);

export const getProductWallHangerThickness = createSelector(
  getProductDetailsDimensions,
  (dimensions: ProductDetailsDimensionsCollection): ProductDimension =>
    getProductDimensionValue(dimensions, /^wall hanger thickness$/i),
);

export const isPrintableProduct = createSelector(getProductDetails, (productDetails: ProductDetails): boolean => {
  if (!productDetails) {
    return null;
  }

  return productDetails.isPrintable;
});

export const getProductPricesState = createSelector(
  getProductCatalogueState,
  (productCatalogueState: State): ProductPricesState => productCatalogueState.productPrices,
);

export const getProductPrices = createSelector(
  getProductPricesState,
  (state: ProductPricesState): ProductPrice[] => state.payload,
);

export const getValidPageCounts = createSelector(getProductDetails, (productDetails: ProductDetails): number[] => {
  if (!productDetails || !productDetails.validPageCounts) {
    return [];
  }

  return productDetails.validPageCounts;
});

export const hasValidPageCounts = createSelector(
  getValidPageCounts,
  (pageCountOptions: number[]): boolean => Boolean(pageCountOptions) && Boolean(pageCountOptions.length),
);

export const getExportProductsState = createSelector(
  getProductCatalogueState,
  (state: State): ExportProductsState => state.exportProducts,
);

export const getProductSearchCategoriesState = createSelector(
  getProductCatalogueState,
  (state: State): ProductSearchCategoriesState => state.productSearchCategories,
);

export const getIsLoading = createSelector(getProductSearchCategoriesState, state => state && state.isLoading);

export const getProductSearchState = createSelector(
  getProductCatalogueState,
  (state: State): ProductSearchState => state.productSearch,
);

export const getProductSearchResponse = createSelector(
  getProductSearchState,
  (state: ProductSearchState): ProductSearchResponse => state.response,
);

export const getProductSearchResultItems = createSelector(
  getProductSearchResponse,
  getSelectedDimensionUnit,
  (response: ProductSearchResponse, selectedDimensionUnit: DimensionUnit): ProductSearchResultItem[] => {
    const products: ProductSearchResultItem[] = R.pathOr([], ['data', 'products'], response);

    return R.map((product: ProductSearchResultItem) => {
      const convertedProductDimensions: ProductSearchResultItemDimensionsCollection = {};

      R.keys(product.dimensions).forEach((dimensionName: string) => {
        const dimensionObject = product.dimensions[dimensionName];

        if (dimensionObject.measureUnit === selectedDimensionUnit) {
          convertedProductDimensions[dimensionName] = dimensionObject;
        } else {
          const precision = 'Thickness' === dimensionName ? 5 : 2;

          try {
            convertedProductDimensions[dimensionName] = {
              value: convertDimensionValue(
                Number(dimensionObject.value),
                dimensionObject.measureUnit,
                selectedDimensionUnit,
              ).toFixed(precision),
              measureUnit: selectedDimensionUnit,
            };
          } catch (e) {
            convertedProductDimensions[dimensionName] = dimensionObject;
          }
        }
      });

      return {
        ...product,
        dimensions: convertedProductDimensions,
      };
    }, products);
  },
);

export const getCatalogInfoState = createSelector(
  getProductCatalogueState,
  (state: State): CatalogInfoState => state.catalogInfo,
);

export const getCatalogInfo = createSelector(
  getCatalogInfoState,
  (state: CatalogInfoState): ProductCatalogInfo => R.path(['response', 'data'], state),
);

export const getProductAttributes = createSelector(
  getCatalogInfo,
  (catalogueInfo: ProductCatalogInfo): ProductAttribute[] => R.path(['productAttributes'], catalogueInfo) || [],
);

export const getProductAttributesCollection = createSelector(
  getProductAttributes,
  (productAttributes: ProductAttribute[]): ProductAttributesCollection => {
    const collection: ProductAttributesCollection = {};

    Object.values(productAttributes).forEach((productAttribute: ProductAttribute) => {
      if (!productAttribute.values || !productAttribute.productAttributeUid) {
        return;
      }

      const values: ProductAttributeValuesCollection = {};

      Object.values(productAttribute.values).forEach((productAttributeValue: ProductAttributeValue) => {
        if (!productAttributeValue.productAttributeValueUid) {
          return;
        }

        values[productAttributeValue.productAttributeValueUid] = {
          title: productAttributeValue.title,
        };
      });

      collection[productAttribute.productAttributeUid] = {
        title: productAttribute.title,
        values,
      };
    });

    return collection;
  },
);

export const getSelectedProductCategoryName = createSelector(
  getProductCatalogueState,
  (state: State): string => state.selectedProductCategoryName,
);

export const isApparelProductCategory = createSelector(
  getSelectedProductCategoryName,
  (selectedProductCategoryName: string): boolean =>
    ApparelSanityProductCategoryNames.includes(selectedProductCategoryName as SanityProductCategoryName),
);

export const isApparelOrAccessoriesProductCategory = createSelector(
  getSelectedProductCategoryName,
  (selectedProductCategoryName: string): boolean =>
    ApparelAndAccessoriesSanityProductCategoryNames.includes(selectedProductCategoryName as SanityProductCategoryName),
);

export const getSelectedProductName = createSelector(
  getProductCatalogueState,
  (state: State): string => state?.selectedProductName,
);

export const getSelectedProductCategoryFromSanityContent = createSelector(
  getSanityContent,
  getSelectedProductCategoryName,
  (sanityResponse: SanityProductCategory[], productCategoryName: string): SanityProductCategory => {
    return findProductCategoryByName(sanityResponse, productCategoryName);
  },
);

export const getSelectedParentProductCategory = createSelector(
  getSanityContent,
  getSelectedProductCategoryFromSanityContent,
  (
    sanityResponse: SanityProductCategory[],
    category: SanityProductCategory,
    props?: { id: string },
  ): SanityProductCategory => {
    if (!sanityResponse || !category) {
      return null;
    }
    return props?.id
      ? getCategoryByProductModel(sanityResponse, props.id)
      : getParentCategory(sanityResponse, category);
  },
);

export const getSelectedProductFromSanityContent = createSelector(
  getSelectedProductName,
  getSanityProducts,
  (productName: string, sanityProducts): SanityProduct => sanityProducts?.[productName]?.product || null,
);

export const getSelectedAvailableProductFromSanityContent = createSelector(
  getSelectedProductFromSanityContent,
  getSanityContent,
  (product, sanityResponse) =>
    product?.id && findSanityProductCategoryByProductName(product.id, sanityResponse) && product,
);

export const getFirstProductUidFromSelectedProduct = createSelector(
  getSelectedProductFromSanityContent,
  (product: SanityProduct): string => product?.productVariations[0].productUid || null,
);

export const getSelectedProductTitle = createSelector(
  getSelectedProductCategoryFromSanityContent,
  getSelectedProductFromSanityContent,
  (category: SanityProductCategory, product: SanityProduct): string => {
    return !category || !product ? '' : [category.title, product.title].join(', ');
  },
);

export const getProductExtraPrintAreas = createSelector(
  getSelectedProductFromSanityContent,
  (product: SanityProduct): ApparelExtraPrintAreas[] => {
    return product?.extraPrintAreas || [];
  },
);

export const getSimilarProducts = createSelector(
  getSelectedProductFromSanityContent,
  getSanityContent,
  (product: SanityProduct, sanityResponse: SanityProductCategory[]): SanityProductSimplified[] => {
    if (!product || !product.similar || !product.similar.length) {
      return [];
    }

    return R.map((loopProduct: SanityProductSimplified) => {
      const foundCategory = findSanityProductCategoryByProductName(loopProduct.id, sanityResponse);

      return {
        ...loopProduct,
        categoryName: foundCategory ? foundCategory.productModel : null,
      };
    }, product.similar);
  },
);

export const getSelectedCountry = createSelector(
  getProductCatalogueState,
  (state: State): string => state.selectedCountry,
);

export const getSuggestedCountry = createSelector(
  getSelectedCountry,
  getSelectedClientCountry,
  (selectedCountry: string, selectedClientCountry: string): string => selectedCountry || selectedClientCountry,
);

export const getSelectedProductVariation = createSelector(
  getProductCatalogueState,
  (state: State): SanityProductVariation => state?.selectedProductVariation,
);

export const getSelectedProductUid = createSelector(
  getSelectedProductVariation,
  (productVariation: SanityProductVariation): string => {
    if (!productVariation) {
      return null;
    }
    return productVariation.productUid;
  },
);

export const getSelectedProductPreviewUrls = createSelector(
  getSelectedProductFromSanityContent,
  getSelectedProductVariation,
  getProductDetailsState,
  getSelectedProductCategoryName,
  (product: SanityProduct, productVariation: SanityProductVariation, state, categoryName): string[] => {
    let previewUrls: string[] = state.variantPreviewUrls ? [...state.variantPreviewUrls] : [];

    if (productVariation?.preview) {
      previewUrls.push(productVariation.preview);
    }

    if (
      !ApparelSanityProductCategoryNames.includes(categoryName as SanityProductCategoryName) &&
      product?.productImages?.length
    ) {
      previewUrls = [...previewUrls, ...product.productImages];
    }

    return previewUrls;
  },
);

export const getSelectedProductPreviewUrl = createSelector(
  getSelectedProductPreviewUrls,
  (productPreviewUrls: string[]): string => (!productPreviewUrls.length ? null : productPreviewUrls[0]),
);

export const getProductDetailsHasFlatPrices = createSelector(
  getProductDetails,
  productDetails => productDetails && productDetails.hasFlatShippingPriceType,
);

export interface CatalogueListState {
  isLoading: boolean;
  request: ProductCatalogueSearchRequest;
  payload: ProductCatalogueSearchResponse;
}
export interface CatalogueState {
  isLoading: boolean;
  payload: ProductCatalogue;
}

export interface ProductDetailsState {
  isLoading: boolean;
  payload: ProductDetails;
  variantPreviewUrls: string[];
}

export interface ProductPricesState {
  isLoading: boolean;
  productUid: string;
  currency: string;
  country: string;
  pagesCount: number;
  clientId: string;
  payload: ProductPrice[];
}

export interface ExportProductsState {
  inProgress: boolean;
  showSuccessMessage: boolean;
}

export interface ProductSearchCategoriesState {
  isLoading: boolean;
  payload: ProductSearchCategory[];
}

export interface ProductSearchState {
  isLoading: boolean;
  showBlockingSpinner: boolean;
  catalogueAlias: string;
  initialHits: ProductSearchHits;
  request: ProductSearchRequest;
  response: ProductSearchResponse;
}

export interface CatalogInfoState {
  isLoading: boolean;
  catalogueAlias: string;
  response: ProductCatalogInfoResponse;
}

export interface State {
  catalogueList: CatalogueListState;
  catalogue: CatalogueState;
  productDetails: ProductDetailsState;
  productPrices: ProductPricesState;
  exportProducts: ExportProductsState;
  productSearchCategories: ProductSearchCategoriesState;
  productSearch: ProductSearchState;
  catalogInfo: CatalogInfoState;
  selectedDimensionUnit: DimensionUnit;
  selectedProductCategoryName: string;
  selectedProductName: string;
  selectedCountry: string;
  selectedProductVariation: SanityProductVariation;
}

export const initialState: State = {
  catalogueList: {
    isLoading: false,
    request: null,
    payload: null,
  },
  catalogue: {
    isLoading: false,
    payload: null,
  },
  productDetails: {
    isLoading: false,
    payload: null,
    variantPreviewUrls: null,
  },
  productPrices: {
    isLoading: false,
    country: null,
    currency: null,
    productUid: null,
    pagesCount: null,
    clientId: null,
    payload: null,
  },
  exportProducts: {
    inProgress: false,
    showSuccessMessage: false,
  },
  productSearchCategories: {
    isLoading: false,
    payload: [],
  },
  productSearch: {
    isLoading: false,
    showBlockingSpinner: false,
    catalogueAlias: null,
    initialHits: null,
    request: null,
    response: null,
  },
  catalogInfo: {
    isLoading: false,
    catalogueAlias: null,
    response: null,
  },
  selectedDimensionUnit: null,
  selectedProductCategoryName: null,
  selectedProductName: null,
  selectedCountry: null,
  selectedProductVariation: null,
};

export function reducer(state: State = initialState, action: actions.ProductCatalogueActions): State {
  switch (action.type) {
    case actions.ProductCatalogueActionTypes.ResetCatalogueListPayload:
      return {
        ...state,
        catalogueList: {
          ...state.catalogueList,
          payload: null,
        },
      };
    case actions.ProductCatalogueActionTypes.SetCatalogueListState:
      return {
        ...state,
        catalogueList: action.payload,
      };
    case actions.ProductCatalogueActionTypes.ResetVariantPreviewUrls:
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          variantPreviewUrls: null,
        },
      };
    case actions.ProductCatalogueActionTypes.ResetCatalogueState:
      return {
        ...state,
        catalogue: {
          ...initialState.catalogue,
        },
      };
    case actions.ProductCatalogueActionTypes.SetCatalogueState:
      return {
        ...state,
        catalogue: action.payload,
      };
    case actions.ProductCatalogueActionTypes.SetProductDetailsState:
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          ...action.payload,
        },
      };
    case actions.ProductCatalogueActionTypes.ResetProductPricesState:
      return {
        ...state,
        productPrices: {
          ...initialState.productPrices,
        },
      };
    case actions.ProductCatalogueActionTypes.SetProductPricesState:
      return {
        ...state,
        productPrices: action.payload,
      };
    case actions.ProductCatalogueActionTypes.ResetExportProductsState:
      return {
        ...state,
        exportProducts: {
          ...initialState.exportProducts,
        },
      };
    case actions.ProductCatalogueActionTypes.ResetProductSearchCategoriesState:
      return {
        ...state,
        productSearchCategories: {
          ...initialState.productSearchCategories,
        },
      };
    case actions.ProductCatalogueActionTypes.ResetProductSearchState:
      return {
        ...state,
        productSearch: {
          ...initialState.productSearch,
        },
      };
    case actions.ProductCatalogueActionTypes.ResetCatalogInfoState:
      return {
        ...state,
        catalogInfo: {
          ...initialState.catalogInfo,
        },
      };
    case actions.ProductCatalogueActionTypes.SetSelectedDimensionUnit:
      return {
        ...state,
        selectedDimensionUnit: action.payload,
      };
    case actions.ProductCatalogueActionTypes.SetExportProductsState:
      return {
        ...state,
        exportProducts: action.payload,
      };
    case actions.ProductCatalogueActionTypes.SetProductSearchCategoriesState:
      return {
        ...state,
        productSearchCategories: action.payload,
      };
    case actions.ProductCatalogueActionTypes.SetProductSearchState:
      return {
        ...state,
        productSearch: action.payload,
      };
    case actions.ProductCatalogueActionTypes.SetCatalogInfoState:
      return {
        ...state,
        catalogInfo: action.payload,
      };
    case actions.ProductCatalogueActionTypes.SetSelectedProductCategoryName:
      return {
        ...state,
        selectedProductCategoryName: action.payload,
      };
    case actions.ProductCatalogueActionTypes.SetSelectedProductName:
      return {
        ...state,
        selectedProductName: action.payload,
      };
    case actions.ProductCatalogueActionTypes.SetSelectedCountry:
      return {
        ...state,
        selectedCountry: action.payload,
      };
    case actions.ProductCatalogueActionTypes.SetSelectedProductVariation:
      return {
        ...state,
        selectedProductVariation: action.payload,
      };
    default:
      return state;
  }
}
