import * as R from 'ramda';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RouterStateSnapshot } from '@angular/router';

export interface RouterReducerState {
  state: RouterStateSnapshot;
  navigationId: number;
}

export const getRouterState = createFeatureSelector<RouterReducerState>('routerReducer');
export const getCurrentUrl = createSelector(getRouterState, state => (state?.state ? state.state.url || '' : ''));
