import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { setProductDetails } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.actions';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { ProductDetailsGraphQlService } from '@gelato-api-ui/core/product-details/services/product-details-graph-ql.service';

@Injectable({ providedIn: 'root' })
export class ECommerceProductWizardDetailsService {
  constructor(
    private readonly store: Store<AppState>,
    private readonly productDetailsGraphQlService: ProductDetailsGraphQlService,
  ) {}

  fetchProductDetails(sortedProductVariants$: Observable<EProductVariant[]>) {
    return sortedProductVariants$.pipe(
      filter(sortedProductVariants => !!sortedProductVariants?.length),
      take(1),
      switchMap(sortedProductVariants =>
        this.productDetailsGraphQlService.get(sortedProductVariants[0]?.productUid).pipe(
          tap(payload => {
            this.store.dispatch(setProductDetails({ payload }));
          }),
          catchError(() => of(new ShowGeneralErrorNotification())),
        ),
      ),
    );
  }
}
