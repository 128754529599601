import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[gcLogEvent]',
})
export class LogEventDirective implements OnDestroy {
  private subs: Subscription = new Subscription();

  @Input() gcLogEvent = 'click';
  @Input() analyticsProperties?: object;
  @Input() analyticsKey: string;

  constructor(private readonly elementRef: ElementRef) {
    this.subs.add(
      fromEvent(elementRef.nativeElement, this.gcLogEvent)
        .pipe(filter(() => Boolean(this.analyticsKey)))
        .subscribe(() => {
          logEvent(this.analyticsKey, this.analyticsProperties);
        }),
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
