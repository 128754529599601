<div
  class="product-preview-fullscreen"
  [ngClass]="{ 'mobile-view': isMobile, 'with-background': useBackground }"
  *ngIf="isOpened"
  [style.top.px]="topOffset"
>
  <ng-container *ngTemplateOutlet="isMobile ? mobileView : desktopView"></ng-container>
</div>

<ng-template #desktopView>
  <img [src]="'sdk/close.svg' | assetsPath | async" class="close-icon" alt="" (click)="onClose()" />
  <swiper
    class="thumbnails"
    [class.centered]="mediaData.length < SLIDER_PER_VIEW_DEFAULT"
    [config]="thumbnailsSwiperConfig"
    (swiper)="setThumbsSwiper($event)"
    (activeIndexChange)="onActiveIndexChange($event)"
  >
    <ng-template swiperSlide *ngFor="let mediaItem of mediaData; let i = index">
      <ng-container
        *ngTemplateOutlet="mediaItem.videoId ? thumbnailVideo : thumbnailImage; context: { media: mediaItem, index: i }"
      >
      </ng-container>
    </ng-template>
  </swiper>

  <div class="control">
    <div class="swiper-navigation-prev swiper-button-disabled">
      <img
        [src]="'sdk/chevron-right.svg' | assetsPath | async"
        class="chevron-left"
        width="24"
        height="34"
        alt="prev"
      />
    </div>
  </div>

  <swiper
    class="previews"
    [config]="previewSwiperConfig"
    [thumbs]="{ swiper: thumbsSwiper$.value }"
    [initialSlide]="activeIndex"
    (activeIndexChange)="onActiveIndexChange($event)"
  >
    <ng-template swiperSlide *ngFor="let mediaItem of mediaData; let i = index">
      <ng-container
        *ngTemplateOutlet="mediaItem.videoId ? previewVideo : previewImage; context: { media: mediaItem, index: i }"
      >
      </ng-container>
    </ng-template>
  </swiper>

  <div class="control">
    <div class="swiper-navigation-next swiper-button-disabled">
      <img
        [src]="'sdk/chevron-right.svg' | assetsPath | async"
        class="chevron-right"
        width="24"
        height="34"
        alt="next"
      />
    </div>
  </div>
</ng-template>

<ng-template #mobileView>
  <div class="minimal-controls">
    <div class="mc-counter">
      {{ 'txt_num_from_num' | translate: { start: (activeIndex$ | async) + 1, finish: mediaData.length } }}
    </div>

    <div class="mc-controls-row">
      <div class="swiper-navigation-prev">
        <img
          [src]="'sdk/chevron-right.svg' | assetsPath | async"
          class="chevron-left"
          width="24"
          height="34"
          alt="prev"
        />
      </div>

      <div class="swiper-navigation-next">
        <img
          [src]="'sdk/chevron-right.svg' | assetsPath | async"
          class="chevron-right"
          width="24"
          height="34"
          alt="next"
        />
      </div>

      <div class="mc-close" (click)="onClose()">
        <img [src]="'sdk/close.svg' | assetsPath | async" class="close-control" alt="" />
      </div>
    </div>
  </div>

  <swiper
    class="previews"
    [config]="previewSwiperConfig"
    [initialSlide]="activeIndex"
    (activeIndexChange)="onActiveIndexChange($event)"
  >
    <ng-template swiperSlide *ngFor="let mediaItem of mediaData; let i = index">
      <ng-container
        *ngTemplateOutlet="mediaItem.videoId ? previewVideo : previewImage; context: { media: mediaItem, index: i }"
      >
      </ng-container>
    </ng-template>
  </swiper>
</ng-template>

<ng-template #thumbnailImage let-media="media">
  <gc-carousel-preview [url]="media.imageLink" [useLoading]="useLoading"></gc-carousel-preview>
</ng-template>

<ng-template #previewImage let-media="media">
  <gc-carousel-preview [url]="media.imageLink" [addZoomClasses]="true" [useLoading]="useLoading"></gc-carousel-preview>
</ng-template>

<ng-template #thumbnailVideo let-media="media" let-index="index">
  <div class="video-wrapper">
    <gc-play-button size="small" (click)="onPlayVideo(index, media.videoId)"></gc-play-button>
    <gc-carousel-preview [url]="media.imageLink"></gc-carousel-preview>
  </div>
</ng-template>

<ng-template #previewVideo let-media="media" let-index="index">
  <div class="ui active inverted transparent dimmer" *ngIf="!isVideoPlayerReady">
    <div class="ui large loader"></div>
  </div>
  <div class="video-container">
    <gc-video
      [play]="videoComponents?.length < 1 ? playVideo$.value === activeIndex$.value : null"
      [stop]="index !== activeIndex$.value"
      [id]="media.videoId"
      [autoplay]="activeIndex === index"
      (playerReady)="isVideoPlayerReady = true"
      (playerStateChange)="playerStateChange($event, media)"
    ></gc-video>
  </div>
</ng-template>
