import { Action } from '@ngrx/store';
import { UploadedFile } from '../shared/lib/uploaded-file';
import { FileInfoState, PagePreviewState, PreflightState, ProductMatchState } from './print-file-verification.reducer';
import { PreflightFitMethod } from '@gelato-api-ui/core/preflight/preflight-fit-method.enum';
import { PreflightFillMethod } from '@gelato-api-ui/core/preflight/preflight-fill-method.enum';

export enum PrintFileVerificationActionTypes {
  LoadAll = '[PrintFileVerificationCatalogue] Load All',

  LoadFileInfo = '[PrintFileVerificationCatalogue] Load FileInfo',
  LoadProductMatch = '[PrintFileVerificationCatalogue] Load ProductMatch',
  LoadPreflight = '[PrintFileVerificationCatalogue] Load Preflight',
  LoadPagePreview = '[PrintFileVerificationCatalogue] Load PagePreview',

  SelectPage = '[PrintFileVerificationCatalogue] Select Page',

  ResetFileInfoState = '[PrintFileVerificationCatalogue] Reset FileInfo State',
  ResetProductMatchState = '[PrintFileVerificationCatalogue] Reset ProductMatch State',
  ResetPreflightState = '[PrintFileVerificationCatalogue] Reset Preflight State',
  ResetPagePreviewState = '[PrintFileVerificationCatalogue] Reset PagePreview State',

  SetFileInfoState = '[PrintFileVerificationCatalogue] Set FileInfo State',
  SetProductMatchState = '[PrintFileVerificationCatalogue] Set ProductMatch State',
  SetPreflightState = '[PrintFileVerificationCatalogue] Set Preflight State',
  SetPagePreviewState = '[PrintFileVerificationCatalogue] Set PagePreview State',
  SetUploadedFile = '[PrintFileVerificationCatalogue] Set UploadedFile',
  SetOriginalUploadedFile = '[PrintFileVerificationCatalogue] Set OriginalUploadedFile',
  SetConvertedUploadedFile = '[PrintFileVerificationCatalogue] Set ConvertedUploadedFile',
}

export class LoadAll implements Action {
  readonly type = PrintFileVerificationActionTypes.LoadAll;

  constructor(
    public uploadedFile: UploadedFile,
    public productUid: string,
    public pageCount: number,
    public fitMethod: PreflightFitMethod,
    public fillMethod: PreflightFillMethod,
    public skipPreflight: boolean = false,
    public forced: boolean = false,
  ) {}
}

export class LoadFileInfo implements Action {
  readonly type = PrintFileVerificationActionTypes.LoadFileInfo;

  constructor(public fileUrl: string, public forced: boolean = false) {}
}

export class LoadProductMatch implements Action {
  readonly type = PrintFileVerificationActionTypes.LoadProductMatch;

  constructor(
    public fileUrl: string,
    public productUid: string,
    public pageCount: number,
    public forced: boolean = false,
  ) {}
}

export class LoadPreflight implements Action {
  readonly type = PrintFileVerificationActionTypes.LoadPreflight;

  constructor(public fileUrl: string, public forced: boolean = false) {}
}

export class LoadPagePreview implements Action {
  readonly type = PrintFileVerificationActionTypes.LoadPagePreview;

  constructor(
    public fileUrl: string,
    public productUid: string,
    public pageNumber: number,
    public pageCount: number,
    public forced: boolean = false,
  ) {}
}

export class SelectPage implements Action {
  readonly type = PrintFileVerificationActionTypes.SelectPage;

  constructor(public pageNumber: number) {}
}

export class ResetFileInfoState implements Action {
  readonly type = PrintFileVerificationActionTypes.ResetFileInfoState;
}

export class ResetProductMatchState implements Action {
  readonly type = PrintFileVerificationActionTypes.ResetProductMatchState;
}

export class ResetPreflightState implements Action {
  readonly type = PrintFileVerificationActionTypes.ResetPreflightState;
}

export class ResetPagePreviewState implements Action {
  readonly type = PrintFileVerificationActionTypes.ResetPagePreviewState;
}

export class SetFileInfoState implements Action {
  readonly type = PrintFileVerificationActionTypes.SetFileInfoState;

  constructor(public payload: FileInfoState) {}
}

export class SetProductMatchState implements Action {
  readonly type = PrintFileVerificationActionTypes.SetProductMatchState;

  constructor(public payload: ProductMatchState) {}
}

export class SetPreflightState implements Action {
  readonly type = PrintFileVerificationActionTypes.SetPreflightState;

  constructor(public payload: PreflightState) {}
}

export class SetPagePreviewState implements Action {
  readonly type = PrintFileVerificationActionTypes.SetPagePreviewState;

  constructor(public payload: PagePreviewState) {}
}

export class SetUploadedFile implements Action {
  readonly type = PrintFileVerificationActionTypes.SetUploadedFile;

  constructor(public payload: UploadedFile) {}
}

export class SetOriginalUploadedFile implements Action {
  readonly type = PrintFileVerificationActionTypes.SetOriginalUploadedFile;

  constructor(public payload: UploadedFile) {}
}

export class SetConvertedUploadedFile implements Action {
  readonly type = PrintFileVerificationActionTypes.SetConvertedUploadedFile;

  constructor(public payload: UploadedFile) {}
}

export type PrintFileVerificationActions =
  | LoadAll
  | LoadFileInfo
  | LoadProductMatch
  | LoadPreflight
  | LoadPagePreview
  | ResetFileInfoState
  | ResetProductMatchState
  | ResetPreflightState
  | ResetPagePreviewState
  | SetFileInfoState
  | SetProductMatchState
  | SetPreflightState
  | SetPagePreviewState
  | SetUploadedFile
  | SetOriginalUploadedFile
  | SetConvertedUploadedFile;
