import { createAction, props } from '@ngrx/store';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { StockAvailabilityPayload } from '@product-catalogue/src/lib/product-catalogue/types/stock-availability-payload';

export const resetState = createAction('[StockAvailabilitySummary] Reset State');

export const loadStockAvailabilitySummary = createAction(
  '[StockAvailabilitySummary] Load StockAvailabilitySummary',
  props<{ payload: SanityProduct }>(),
);

export const loadStockAvailabilitySummaryStart = createAction(
  '[StockAvailabilitySummary] Load StockAvailabilitySummary Start',
);

export const loadStockAvailabilitySummarySuccess = createAction(
  '[StockAvailabilitySummary] Load StockAvailabilitySummary Success',
  props<{ payload: StockAvailabilityPayload }>(),
);

export const loadStockAvailabilitySummaryFailure = createAction(
  '[StockAvailabilitySummary] Load StockAvailabilitySummary Failure',
  props<{ error: any }>(),
);
