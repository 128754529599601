<ng-container [ngSwitch]="view">
  <ng-container *ngSwitchCase="checkoutViewValue">
    <ng-container *ngTemplateOutlet="checkoutView"></ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="defaultView"></ng-container>
  </ng-container>
</ng-container>

<ng-template #defaultView>
  <form [formGroup]="form" class="ui form">
    <ng-container *ngFor="let templateName of order$ | async" [ngTemplateOutlet]="templateName"></ng-container>
  </form>
</ng-template>

<ng-template #checkoutView>
  <form [formGroup]="form" class="ui form">
    <div class="fields" *ngFor="let items of order$ | async">
      <div class="eight wide field" [class.sixteen]="items.length === 1" *ngFor="let templateName of items">
        <ng-container [ngTemplateOutlet]="templateName"></ng-container>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #name>
  <!--  RECIPIENT -->
  <div class="field" [class.error]="hasError(address.recipientName)" [formGroup]="form">
    <label>{{ 'txt_recipient_name_field_label' | translate }}</label>
    <div class="ui input fluid">
      <input placeholder="" [formControlName]="address.recipientName" name="recipientName" />
    </div>
    <gd-form-field-error-message [error]="error" [fieldName]="address.recipientName"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #addressField>
  <!--  ADDRESS -->
  <div class="field" [class.error]="hasError(address.address)" [formGroup]="form">
    <label>{{ 'txt_address_field_label' | translate }}</label>
    <div class="ui input fluid">
      <input placeholder="" [formControlName]="address.address" name="address" />
    </div>
    <gd-form-field-error-message [error]="error" [fieldName]="address.address"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #city>
  <!--  CITY -->
  <div class="field" [class.error]="hasError(address.city)" [formGroup]="form">
    <label>{{ 'txt_address_city_field_label' | translate }}</label>
    <div class="ui input fluid">
      <input placeholder="" [formControlName]="address.city" name="city" />
    </div>
    <gd-form-field-error-message [error]="error" [fieldName]="address.city"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #postCode>
  <!--  POST CODE -->
  <div class="field" [class.error]="hasError(address.postCode)" [formGroup]="form">
    <label>{{ 'txt_address_postcode_field_label' | translate }}</label>
    <div class="ui input fluid">
      <input placeholder="" [formControlName]="address.postCode" name="postCode" />
    </div>
    <gd-form-field-error-message [error]="error" [fieldName]="address.postCode"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #country>
  <!--  COUNTRY -->
  <div class="field" [class.error]="hasError(address.countryIsoCode)" [formGroup]="form">
    <label>{{ 'txt_address_country_field_label' | translate }}</label>
    <gc-country-selector [value]="fieldValue(address.countryIsoCode)" (valueChange)="onCountryIdChange($event)">
    </gc-country-selector>
    <gd-form-field-error-message [error]="error" [fieldName]="address.countryIsoCode"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #state>
  <!--  STATE -->
  <div class="field" [class.error]="hasError(address.stateCode)" [formGroup]="form">
    <label>
      {{ getStateLabelKey(selectedCountry) | translate }}
      <ng-container *ngIf="isShowStateOptionalField"> ({{ 'txt_optional_field_label' | translate }}) </ng-container>
    </label>

    <gc-country-state-selector
      [countryIsoCode]="fieldValue(address.countryIsoCode)"
      [value]="fieldValue(address.stateCode)"
      (valueChange)="onCountryStateChange($event)"
    >
    </gc-country-state-selector>

    <gd-form-field-error-message [error]="error" [fieldName]="address.stateCode"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #email>
  <!-- EMAIL -->
  <div class="field" *ngIf="!showSupportEmail" [class.error]="hasError(address.email)" [formGroup]="form">
    <label>{{ 'txt_address_email_field_label' | translate }}</label>
    <div class="ui input fluid">
      <input placeholder="" [formControlName]="address.email" name="email" />
    </div>
    <gd-form-field-error-message [error]="error" [fieldName]="address.email"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #contactEmail>
  <!-- CONTACT EMAIL -->
  <div class="field" *ngIf="showSupportEmail" [class.error]="hasError(address.contactEmail)" [formGroup]="form">
    <label>{{ 'txt_address_contact_email_field_label' | translate }}</label>
    <div class="ui input fluid">
      <input placeholder="" [formControlName]="address.contactEmail" name="contactEmail" />
    </div>
    <gd-form-field-error-message [error]="error" [fieldName]="address.contactEmail"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #phone>
  <!-- PHONE -->
  <div class="field" [class.error]="hasError(address.phone)" [formGroup]="form">
    <label
      >{{ 'txt_address_phone_field_label' | translate
      }}<span class="dimmed">({{ 'txt_optional_field_label' | translate }})</span></label
    >
    <div class="ui input fluid">
      <gc-phone-field
        [phoneNumber]="fieldValue(address.phone)"
        [countryCode]="fieldValue(address.countryIsoCode)"
        (phoneChange)="onPhoneChange($event)"
      ></gc-phone-field>
    </div>
    <gd-form-field-error-message [error]="error" [fieldName]="address.phone"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #billingEmail>
  <!-- BILLING EMAIL -->
  <div class="field" *ngIf="showSupportEmail" [class.error]="hasError(address.billingEmail)" [formGroup]="form">
    <label>{{ 'txt_address_billing_email_field_label' | translate }}</label>
    <div class="ui input fluid">
      <input placeholder="" [formControlName]="address.billingEmail" name="billingEmail" />
    </div>
    <gd-form-field-error-message [error]="error" [fieldName]="address.billingEmail"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #supportEmail>
  <!-- CUSTOMER SUPPORT EMAIL -->
  <div class="field" *ngIf="showSupportEmail" [class.error]="hasError(address.supportEmail)" [formGroup]="form">
    <label>{{ 'txt_address_support_email_field_label' | translate }}</label>
    <div class="ui input fluid">
      <input placeholder="" [formControlName]="address.supportEmail" name="supportEmail" />
    </div>
    <gd-form-field-error-message [error]="error" [fieldName]="address.supportEmail"> </gd-form-field-error-message>
  </div>
</ng-template>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
