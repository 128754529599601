import { Action } from '@ngrx/store';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';

export enum AccountActivationStepsActionTypes {
  Load = '[AccountActivationSteps] Load',
  LoadPaidOrdersCount = '[AccountActivationSteps] Load PaidOrdersCount',
  LoadStores = '[AccountActivationSteps] Load Stores',
  IncreasePaidOrdersCount = '[AccountActivationSteps] Increase PaidOrdersCount',
  SetPaidOrdersCount = '[AccountActivationSteps] Set PaidOrdersCount',
  SetFirstAvailableStore = '[AccountActivationSteps] Set FirstAvailableStore',
  SetFirstAvailableProduct = '[AccountActivationSteps] Set FirstAvailableProduct',
  SetHasMultipleStoresFlag = '[AccountActivationSteps] Set HasMultipleStoresFlag',
}

export class Load implements Action {
  readonly type = AccountActivationStepsActionTypes.Load;
}

export class LoadPaidOrdersCount implements Action {
  readonly type = AccountActivationStepsActionTypes.LoadPaidOrdersCount;
}

export class LoadStores implements Action {
  readonly type = AccountActivationStepsActionTypes.LoadStores;
}

export class IncreasePaidOrdersCount implements Action {
  readonly type = AccountActivationStepsActionTypes.IncreasePaidOrdersCount;
}

export class SetPaidOrdersCount implements Action {
  readonly type = AccountActivationStepsActionTypes.SetPaidOrdersCount;

  constructor(public payload: number) {}
}

export class SetFirstAvailableStore implements Action {
  readonly type = AccountActivationStepsActionTypes.SetFirstAvailableStore;

  constructor(public payload: EStore) {}
}

export class SetFirstAvailableProduct implements Action {
  readonly type = AccountActivationStepsActionTypes.SetFirstAvailableProduct;

  constructor(public payload: EProductAbbridged) {}
}

export class SetHasMultipleStoresFlag implements Action {
  readonly type = AccountActivationStepsActionTypes.SetHasMultipleStoresFlag;

  constructor(public payload: boolean) {}
}

export type AccountActivationStepsActions =
  | Load
  | LoadPaidOrdersCount
  | LoadStores
  | IncreasePaidOrdersCount
  | SetPaidOrdersCount
  | SetFirstAvailableStore
  | SetFirstAvailableProduct
  | SetHasMultipleStoresFlag;
