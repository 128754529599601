import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'gc-refresh-button, gd-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss'],
})
export class RefreshButtonComponent {
  @Output() refreshButtonClick = new EventEmitter();

  onClick() {
    this.refreshButtonClick.emit();
  }
}
