import { Injectable } from '@angular/core';
import { DesignStructure, PrintAreasToUidVariant } from '@gelato-api-ui/core/designs/design-structure';
import { PrintAreaNameEnum } from '@gelato-api-ui/core/print-sides/print-side-name.enum';
import { DesignEditorApparelService } from '@product-catalogue/src/lib/product-catalogue/services/design-editor-apparel.service';
import { PrintAreaNames } from '@product-catalogue/src/lib/product-details-shared/constants/print-areas-names';
import { TranslateService } from '@ngx-translate/core';

type PrintSidesStatus = Record<PrintAreaNameEnum, boolean>;

interface DisablePageData {
  spreadIndex: number;
  information: string;
}

@Injectable({ providedIn: 'root' })
export class DesignEditorPrintAreaConflictsService {
  constructor(
    private readonly translate: TranslateService,
    private readonly designEditorApparelService: DesignEditorApparelService,
  ) {}

  getPrintAreasToDisable(
    designData: DesignStructure,
    printAreasToUidMap: PrintAreasToUidVariant[] = [],
  ): DisablePageData[] {
    // variants provided for conflict details, no variants - no problem
    if (!printAreasToUidMap?.length) {
      return [];
    }

    // take filled print areas from the design
    const printSides = this.designEditorApparelService.getFilledPrintAreas(designData);
    // check which one should be disabled
    const printAreasStatus = this.getPrintAreasConflictStatus(printAreasToUidMap, printSides, true);
    // get name of print areas which conflicts with disabled ones to notify user
    const conflictedPrintArea = this.getPrintAreasConflictReason(printSides, printAreasStatus, printAreasToUidMap);
    // list all spreads to disable
    return Object.keys(printAreasStatus).reduce<{ spreadIndex: number; information: string }[]>(
      (spreads, printAreaName: PrintAreaNameEnum) => {
        if (!printAreasStatus[printAreaName]) {
          const spreadIndex = designData.spreads.findIndex(spread => spread.name === printAreaName);
          spreads.push({ spreadIndex, information: this.getConflictMessage(printAreaName, conflictedPrintArea) });
        }
        return spreads;
      },
      [],
    );
  }

  getPrintAreasConflictStatus(
    variants: PrintAreasToUidVariant[],
    printSides: PrintAreaNameEnum[],
    skipNoPrintsConflicts = false,
  ): PrintSidesStatus {
    // if we don't want to see conflicts when design is empty -> say all print areas is active
    if (skipNoPrintsConflicts && !printSides.length) {
      return this.getAllAvailablePrintAreasWithStatus(true);
    }

    const resTemplate = this.getAllAvailablePrintAreasWithStatus();
    const variantsSrc = !printSides.length
      ? variants.filter(v => v.types.length === 1)
      : this.getVariantsWithPrintSides(variants, printSides);

    const allowedPrintSidesArr = this.getAllPrintSidesFromVariants(variantsSrc);
    const allowedPrintSidesObj = this.getAllowedPrintSides(allowedPrintSidesArr);
    return { ...resTemplate, ...allowedPrintSidesObj };
  }

  isAvailableSinglePrintAreaConfiguration(variants: PrintAreasToUidVariant[], printAreaName: PrintAreaNameEnum) {
    return variants.filter(v => v.types.length === 1).reduce((res, v) => v.types[0] === printAreaName || res, false);
  }

  getPrintAreasConflictReason(
    printSides: PrintAreaNameEnum[],
    status: PrintSidesStatus,
    variants: PrintAreasToUidVariant[],
  ): PrintAreaNameEnum {
    // no print areas -> no conflict reason to look for
    if (!printSides.length) {
      return null;
    }

    const disabledPrintAreas = this.getDisabledPrintAreas(status);
    // no disabled areas -> no conflict
    if (!disabledPrintAreas.length) {
      return null;
    }

    // take one area from the selected ones
    const [candidateForConflict, ...restPrintSides] = printSides;
    // check if with this reduced list we don't have conflicts anymore
    const newStatus = this.getPrintAreasConflictStatus(variants, restPrintSides, true);
    // look for new disabled areas in the status (if we have conflicts)
    const newDisabled = this.getDisabledPrintAreas(newStatus);

    // if not conflicts, or it's the last one area -> found the culprit
    if (!newDisabled.length || !restPrintSides.length) {
      return candidateForConflict;
    }
    // if not luck -> run same function with new status and new print
    // areas to check next area in the list
    return this.getPrintAreasConflictReason(restPrintSides, newStatus, variants);
  }

  private getDisabledPrintAreas(status: PrintSidesStatus): PrintAreaNameEnum[] {
    return Object.keys(status).reduce((acc, printArea) => {
      return !status[printArea] ? [...acc, printArea as PrintAreaNameEnum] : acc;
    }, [] as PrintAreaNameEnum[]);
  }

  private getConflictMessage(hoveredPrintArea: PrintAreaNameEnum, conflictedPrintArea: PrintAreaNameEnum): string {
    const trKey = conflictedPrintArea ? 'txt_editor_disabled_page_reason' : 'txt_editor_disabled_page_start';
    const hovered = this.translate.instant(PrintAreaNames[hoveredPrintArea]);
    const used = conflictedPrintArea ? this.translate.instant(PrintAreaNames[conflictedPrintArea]) : '';
    return this.translate.instant(trKey, { used, hovered });
  }

  private getAllAvailablePrintAreasWithStatus(status: boolean = false): PrintSidesStatus {
    return Object.values(PrintAreaNameEnum).reduce(
      (acc, printSide) => ({ ...acc, [printSide]: status }),
      {} as PrintSidesStatus,
    );
  }

  private getAllPrintSidesFromVariants(variants: PrintAreasToUidVariant[]): Set<PrintAreaNameEnum> {
    return variants.reduce((acc, el) => {
      el.types.forEach(value => acc.add(value));
      return acc;
    }, new Set<PrintAreaNameEnum>());
  }

  private getVariantsWithPrintSides(
    variants: PrintAreasToUidVariant[],
    printSides: PrintAreaNameEnum[],
  ): PrintAreasToUidVariant[] {
    return variants.filter(variant => printSides.every(printSide => variant.types.indexOf(printSide) >= 0));
  }

  private getAllowedPrintSides(allowedPrintSides: Set<PrintAreaNameEnum>): PrintSidesStatus {
    return Array(...allowedPrintSides).reduce((acc, el) => {
      acc[el] = true;
      return acc;
    }, {} as PrintSidesStatus);
  }
}
