<div class="container">
  <div class="product-info">
    <img
      *ngIf="!item?.productVariant?.externalThumbnailUrl"
      class="thumbnail-placeholder"
      src="/assets/icons/image-placeholder-grey.svg"
    />

    <gc-thumbnail
      *ngIf="item?.productVariant?.externalThumbnailUrl"
      width="96"
      height="96"
      [url]="item?.productVariant?.externalThumbnailUrl"
      (click)="showPreviewModal(item?.productVariant?.externalPreviewUrl)"
    ></gc-thumbnail>

    <div class="product-text">
      <p class="name" class="product-name">
        <ng-container *ngIf="item?.title">
          {{ item?.title }}
          <ng-container *ngIf="!item?.productVariant"> ({{ 'txt_deleted_product_variant' | translate }}) </ng-container>
        </ng-container>
        <ng-container *ngIf="!item?.productVariant">
          {{ 'txt_deleted_product_variant' | translate }}
        </ng-container>
      </p>
      <p *ngIf="item?.retailPrice && retailCurrency" class="retail-price">
        {{ 'txt_retail_price' | translate }}: {{ item?.retailPrice | money: retailCurrency }}
      </p>
      <p *ngIf="item?.productVariant?.sku" class="sku">
        {{ item?.productVariant?.sku }}
      </p>
    </div>

    <img width="32" height="32" src="/assets/icons/chevron-double-right-grey.svg" />
  </div>

  <div class="product-connection">
    <ng-container *ngIf="!item?.isIgnored">
      <gd-status-label
        [color]="'red'"
        [text]="'txt_variant_connection_status_not_connected' | translate"
        [maxWidth]="130"
        [noShrink]="true"
        [fluid]="false"
      >
      </gd-status-label>

      <ng-container *ngIf="!readOnlyMode">
        <div class="product-connection-actions">
          <gd-connect-order-item-button
            [isMobile]="isMobile"
            (connectToNewProductButtonClick)="connectItemToNewProduct()"
            (connectToExistingProductButtonClick)="connectItemToExistingProduct()"
          >
          </gd-connect-order-item-button>

          <a class="ui link button with-svg-icon" (click)="ignoreItem()">
            <img width="32" height="32" src="/assets/icons/eye-slash-blue.svg" />
            {{ 'txt_ignore' | translate }}
          </a>
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="item?.isIgnored">
      <gd-status-label
        [color]="'grey'"
        [text]="'txt_variant_connection_status_ignored' | translate"
        [maxWidth]="130"
        [noShrink]="true"
        [fluid]="false"
      >
      </gd-status-label>

      <ng-container *ngIf="!readOnlyMode">
        <div class="product-connection-actions">
          <a class="ui link button with-svg-icon" (click)="disconnectItem()">
            <img width="32" height="32" src="/assets/icons/eye-blue.svg" />
            {{ 'txt_stop_ignoring' | translate }}
          </a>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
