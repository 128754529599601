import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { DeckRef } from '@gelato-api-ui/ui-kit/src/lib/deck/components/deck-ref/deck-ref';
import { DeckService } from '@gelato-api-ui/ui-kit/src/lib/deck/services';

@Component({
  selector: 'gd-dropdown-menu, gc-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownMenuComponent implements OnInit {
  @Input() isDisabled = false;
  @Input() isMobile = false;
  @Input() mobileMenuTitle: string;
  @Input() buttonContent: TemplateRef<any>;
  @Input() buttonType = 'basic';
  @Input() dropdownTpl: TemplateRef<HTMLAllCollection>;
  @Input() positionTop: boolean;
  @Input() positionLeft: boolean;
  @Input() showDefaultButtonBorder: boolean;
  @Input() menuTopValue: string;
  @Output() visible = new EventEmitter<boolean>();
  @Output() menuButtonClick = new EventEmitter();
  @Output() menuShown = new EventEmitter();

  @ViewChild('containerRef')
  containerRef: ElementRef;

  @ViewChild('menuRef')
  menuRef: ElementRef;

  clickListener = null;
  clickListenerModal = null;

  @ViewChild('deckMenu') deckMenu;
  private deckRef: DeckRef;

  constructor(private readonly deck: DeckService) {}

  ngOnInit() {}

  onMenuButtonClick(event: Event) {
    event.stopPropagation();
    event.preventDefault();

    this.menuButtonClick.emit();

    if (this.isMobile) {
      this.openDropdownDeck();
      return;
    }

    this.removeClickEventListener();

    const wasVisible = /visible/.test(this.menuRef.nativeElement.className);

    this.hideAllMenus();

    if (!wasVisible) {
      this.showMenu();

      this.clickListener = window.addEventListener('click', () => this.hideAllMenus());

      // workaround for dropdown to be working in modal
      this.clickListenerModal = document
        .querySelector('.ui .modal')
        ?.addEventListener('click', () => this.hideAllMenus());
    }
  }

  closeMenu(event?: Event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }

    if (this.isMobile) {
      this.closeDropdownDeck();
    } else {
      this.hideAllMenus();
    }
  }

  private showMenu() {
    this.menuRef.nativeElement.className = 'menu transition slide in visible';
    this.visible.emit(true);
    this.menuShown.emit();
  }

  private hideAllMenus() {
    document.querySelectorAll('.ui.dropdown .menu').forEach(element => (element.className = 'menu transition hidden'));
    this.visible.emit(false);
  }

  private removeClickEventListener() {
    if (this.clickListener) {
      window.removeEventListener('click', this.clickListener);
    }
    if (this.clickListenerModal) {
      document.querySelector('.ui .modal').removeEventListener('click', this.clickListenerModal);
    }
  }

  private openDropdownDeck(): void {
    this.deckRef = this.deck.open({
      content: this.deckMenu,
      width: '100%',
      height: 'auto',
    });
  }

  private closeDropdownDeck() {
    this.deckRef.triggerCloseDeck();
  }
}
