import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EProductPublishingStateMessageParams } from '@api-ui-app/src/app/e-commerce-stores/types/e-product-publishing-state-message-params';
import { TranslatedStoreTypeService } from '@api-ui-app/src/app/e-commerce-stores/services/translated-store-type.service';

@Injectable({ providedIn: 'root' })
export class ECommerceProductPublishingStateMessageFormattingService {
  private readonly messageFormattingPlaceholders = {
    bold: '<strong>',
    end_bold: '</strong>',
  };

  constructor(
    private readonly translateService: TranslateService,
    private readonly translatedStoreTypeService: TranslatedStoreTypeService,
  ) {}

  get(translationKey: string, params?: EProductPublishingStateMessageParams): string {
    return this.translateService.instant(translationKey, this.getMessageParams(params));
  }

  private getMessageParams(params: EProductPublishingStateMessageParams): object {
    return {
      ...this.messageFormattingPlaceholders,
      storeType: this.translatedStoreTypeService.instant(params?.storeType),
      productTitle: params?.productTitle,
    };
  }
}
