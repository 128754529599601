import {
  animate,
  AnimationTriggerMetadata,
  group,
  state,
  style,
  transition,
  trigger,
  useAnimation,
} from '@angular/animations';
import { transformScale } from '@gelato-api-ui/ui-kit/src/lib/dropdown/animations/transform-scale.animation';

export function DropdownAnimation(
  params: {
    readonly duration: number;
    readonly easing: string;
  } = { duration: 100, easing: 'ease-in' },
): AnimationTriggerMetadata {
  return trigger('dropdown', [
    state('void', style({ opacity: 0 })),
    transition('void => open', [
      group([
        style({ opacity: 0 }),
        useAnimation(transformScale, {
          params: {
            xStart: 0.9,
            yStart: 0.9,
            xEnd: 1,
            yEnd: 1,
            time: `${params.duration}ms`,
            easing: params.easing,
          },
        }),
        animate(`${params.duration}ms ${params.easing}`, style({ opacity: 1 })),
      ]),
    ]),
    transition('open => close', [
      group([
        style({ opacity: 1 }),
        useAnimation(transformScale, {
          params: {
            xStart: 1,
            yStart: 1,
            xEnd: 0.9,
            yEnd: 0.9,
            time: `${params.duration}ms`,
            easing: 'ease-out',
          },
        }),
        animate(`${params.duration}ms ease-out`, style({ opacity: 0 })),
      ]),
    ]),
  ]);
}
