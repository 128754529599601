export interface MetadataItem {
  key: string;
  value: MetadataItemValue;
}

export type MetadataItemValue = string | string[];

export enum RemoveBackgroundItemsMetadata {
  BACKGROUND_REMOVAL_REFERENCE_ASSET_ID = 'app-background-removal:referenceAssetId',
  BACKGROUND_REMOVAL_HAS_BACKGROUND = 'app-background-removal:hasBackground',
  BACKGROUND_REMOVAL_USER_ID = 'app-background-removal:userId',
}
