import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FeaturesService {
  private PREFIX = 'feature-toggling:';

  FEATURE_MANAGEMENT = 'feature-management';
  DESIGN_TEMPLATES = 'design-templates';

  constructor() {}

  isEnabled(featureName: string): boolean {
    try {
      return localStorage.getItem(this.PREFIX + featureName) === 'enabled';
    } catch (e) {
      return false;
    }
  }

  setEnabled(featureName: string, isEnabled: boolean): void {
    try {
      localStorage.setItem(this.PREFIX + featureName, isEnabled ? 'enabled' : 'disabled');
    } catch (e) {
      // Ignore
    }
  }
}
