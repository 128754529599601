import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { filter, take } from 'rxjs/operators';
import { WINDOW } from '@gelato-api-ui/core/window/window';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class UrlFragmentService {
  excludedPages: string[] = ['/checkout'];

  constructor(
    private route: ActivatedRoute,
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  removeFragmentsFromTheUrl(): void {
    const pathUrl = this.window.location.pathname;

    if (this.excludedPages.every(view => !pathUrl.includes(view))) {
      this.route.fragment.pipe(filter(Boolean), take(1)).subscribe(() => {
        history.replaceState('', this.document.title, pathUrl);
      });
    }
  }
}
