import { createReducer, on } from '@ngrx/store';
import { ProductTypeGalleryCollections } from '@gelato-api-ui/core/gallery/product-type-gallery-collections';
import {
  loadGettyCollections,
  loadGettyCollectionsError,
  loadGettyCollectionsSuccess,
  loadSanityProductTypesGalleryCollections,
  loadSanityProductTypesGalleryCollectionsError,
  loadSanityProductTypesGalleryCollectionsSuccess,
} from './gallery-collections.actions';
import { GettyCollectionGraphQLResponse } from '@gelato-api-ui/core/gallery/gallery-image';

export interface State {
  isLoading: boolean;
  sanityProductTypesGalleryCollections: ProductTypeGalleryCollections[];
  gettyCollectionsData: GettyCollectionGraphQLResponse[];
}

export const initialState: State = {
  isLoading: false,
  sanityProductTypesGalleryCollections: null,
  gettyCollectionsData: null,
};

export const reducer = createReducer(
  initialState,
  on(loadSanityProductTypesGalleryCollections, state => ({
    ...state,
    isLoading: true,
  })),
  on(loadSanityProductTypesGalleryCollectionsSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    sanityProductTypesGalleryCollections: action.payload,
  })),
  on(loadSanityProductTypesGalleryCollectionsError, state => ({
    ...state,
    isLoading: false,
  })),
  on(loadGettyCollections, state => ({
    ...state,
    isLoading: true,
  })),
  on(loadGettyCollectionsSuccess, (state, action) => ({
    ...state,
    isLoading: false,
    gettyCollectionsData: action.payload,
  })),
  on(loadGettyCollectionsError, state => ({
    ...state,
    isLoading: false,
  })),
);
