<div class="header">
  {{ featureRestrictionTranslations[restrictionType].modalTitle | translate }}
</div>
<div class="content">
  <div class="ui">
    <p [innerHTML]="featureRestrictionTranslations[restrictionType].modalContent | translate"></p>
  </div>
</div>
<div class="horizontal-separator"></div>
<div class="actions">
  <button class="ui grey basic button" (click)="modal.deny(undefined)" autofocus>
    {{ 'txt_cancel' | translate }}
  </button>
  <button class="ui purple button" (click)="modal.approve(undefined)" autofocus>
    {{ 'txt_subscribe' | translate }}
  </button>
</div>
