import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SanityProductBadge } from '@gelato-api-ui/core/sanity/sanity-product-badge';
import { TooltipPositions } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.enum';

@Component({
  selector: 'lib-product-badge',
  templateUrl: './product-badge.component.html',
  styleUrls: ['./product-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductBadgeComponent {
  @Input() productBadge: SanityProductBadge;

  tooltipPosition = TooltipPositions.top;
  get css() {
    return `color: ${this.productBadge.color}; background-color: ${this.productBadge.background}`;
  }
}
