import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as gql from 'gql-query-builder';
import { ApolloQueryResult } from '@apollo/client/core';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import { CountriesApiResponse, CountriesDataModel } from '@api-ui-app/src/app/shared/countries/types/countries.types';

@Injectable({
  providedIn: 'root',
})
export class CountriesApiService {
  constructor(private readonly graphQlRequestService: GraphQlRequestService) {}

  fetchCountries(): Observable<CountriesDataModel> {
    const query = gql.query({
      operation: 'countries',
      fields: ['supportedCountries', 'regionsToCountries'],
    });

    return this.graphQlRequestService
      .query(query, 'countries')
      .pipe(map((data: ApolloQueryResult<CountriesApiResponse>) => data?.data?.countries));
  }
}
