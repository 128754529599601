import { createReducer, on } from '@ngrx/store';
import {
  activateSubscriptionSuccess,
  calculatePricePlanTax,
  calculatePricePlanTaxFailure,
  calculatePricePlanTaxSuccess,
  cancelSubscription,
  cancelSubscriptionFailure,
  cancelSubscriptionSuccess,
  getIfClientEligibleForGoldSuccess,
  loadActiveSubscriptionsAndServicePlans,
  loadActiveSubscriptionsSuccess,
  loadSubscriptionPlans,
  loadSubscriptionPlansListFromSanitySuccess,
  loadSubscriptionPlansPricesSuccess,
  loadSubscriptionPlansSuccess,
  setActivePeriodUnit,
  setActivePlansIsUpdating,
  setServicePlanActivationPayload,
} from '@api-ui-app/src/app/subscriptions/+state/subscriptions.actions';
import { SubscriptionResponse } from '@api-ui-app/src/app/subscriptions/types/subscriptions';
import { ServicePlanResponse } from '@api-ui-app/src/app/subscriptions/types/subscription-plans';
import {
  ServicePlanPrice,
  ServicePricePlanTax,
  ServicePricePlanTaxResponse,
  ServiceUniqueName,
} from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';
import { getDefaultPlatformServicePlan } from '@api-ui-app/src/app/subscriptions/lib/helpers/getDefaultPlatformServicePlan';
import { SanitySubscriptionPlan } from '@gelato-api-ui/core/app-store/sanity-subscription-plan';

export interface State {
  loading: boolean;
  isActivePlansUpdating: boolean;
  activeSubscriptions: SubscriptionResponse[]; // Active user subscriptions
  servicePlansList: ServicePlanResponse[];
  servicePlansPricesList: ServicePlanPrice[];
  subscriptionPlansListFromSanity: SanitySubscriptionPlan[];
  activeServicePlans: ServicePlanResponse[];
  activePeriodUnit: TimePeriodUnit;
  activeSubscriptionsLoaded: boolean;
  pricePlanTax: ServicePricePlanTax;
  isClientEligibleForGoldSubscription: boolean;
}

export const initialState: State = {
  loading: false,
  isActivePlansUpdating: false,
  servicePlansPricesList: null,
  activeSubscriptions: null,
  servicePlansList: null,
  subscriptionPlansListFromSanity: null,
  activeServicePlans: null,
  activePeriodUnit: TimePeriodUnit.MONTH,
  activeSubscriptionsLoaded: false,
  pricePlanTax: {
    error: null,
    isLoading: false,
    billingTag: null,
    price: null,
    priceInclVat: null,
    priceVat: null,
    taxTransactionId: null,
    vatRate: null,
  },
  isClientEligibleForGoldSubscription: false,
};

export const reducer = createReducer(
  initialState,
  on(loadActiveSubscriptionsAndServicePlans, loadSubscriptionPlans, cancelSubscription, (state, action) => ({
    ...state,
    loading: true,
  })),
  on(loadActiveSubscriptionsSuccess, (state, action) => ({
    ...state,
    loading: false,
    activeSubscriptions: action.activeSubscriptions,
    activeServicePlans: [...(action.activeServicePlans || []), ...(state.servicePlansList || [])],
    activeSubscriptionsLoaded: true,
  })),
  on(loadSubscriptionPlansSuccess, (state, action) => {
    return {
      ...state,
      loading: false,
      servicePlansList: action.payload ? action.payload.plans : null,
      activeServicePlans: action.payload
        ? [...(state.activeServicePlans || []), ...action.payload.plans]
        : [...state.activeServicePlans],
    };
  }),
  on(loadSubscriptionPlansPricesSuccess, (state, action) => ({
    ...state,
    servicePlansPricesList: action.payload ? action.payload.prices : null,
  })),
  on(loadSubscriptionPlansListFromSanitySuccess, (state, action) => {
    return {
      ...state,
      subscriptionPlansListFromSanity: action.payload,
    };
  }),
  on(setServicePlanActivationPayload, (state, action) => {
    let activeServicePlans = [...(state.activeServicePlans || []), action.servicePlan];
    let activeSubscriptions = [...(state.activeSubscriptions || []), action.subscription];

    if (action.servicePlan && action.servicePlan.serviceUniqueName === ServiceUniqueName.GELATO_PLATFORM) {
      const defaultServicePlan = getDefaultPlatformServicePlan(activeServicePlans);

      // Remove default (free) platform service plan once it's upgraded
      if (defaultServicePlan) {
        activeServicePlans = activeServicePlans.filter(
          (loopServicePlan: ServicePlanResponse): boolean => loopServicePlan.id !== defaultServicePlan.id,
        );
        activeSubscriptions = activeSubscriptions.filter(
          (loopSubscription: SubscriptionResponse): boolean =>
            loopSubscription.plan && loopSubscription.plan.servicePlanId !== defaultServicePlan.id,
        );
      }
    }

    return {
      ...state,
      activeServicePlans,
      activeSubscriptions,
    };
  }),
  on(setActivePeriodUnit, (state, action) => ({
    ...state,
    activePeriodUnit: action.periodUnit,
  })),
  on(setActivePlansIsUpdating, (state, action) => ({
    ...state,
    isActivePlansUpdating: action.status,
  })),
  on(calculatePricePlanTax, state => ({
    ...state,
    pricePlanTax: {
      error: null,
      isLoading: true,
      ...new ServicePricePlanTaxResponse(),
    },
  })),
  on(calculatePricePlanTaxSuccess, (state, action) => ({
    ...state,
    pricePlanTax: {
      error: null,
      isLoading: false,
      ...action.payload,
    },
  })),
  on(calculatePricePlanTaxFailure, (state, error) => ({
    ...state,
    pricePlanTax: {
      error,
      isLoading: false,
      ...new ServicePricePlanTaxResponse(),
    },
  })),
  on(cancelSubscriptionSuccess, (state, { subscription }) => ({
    ...state,
    activeSubscriptions: [...state?.activeSubscriptions?.map(s => (s.id === subscription.id ? subscription : s))],
    loading: true,
  })),
  on(cancelSubscriptionFailure, (state, error) => ({
    ...state,
    loading: false,
  })),
  on(getIfClientEligibleForGoldSuccess, (state, action) => ({
    ...state,
    isClientEligibleForGoldSubscription: action
      ? action.isClientEligibleForGoldSubscription
      : state.isClientEligibleForGoldSubscription,
  })),
  on(activateSubscriptionSuccess, (state, action) => ({
    ...state,
    activeSubscriptions: action?.payload?.id
      ? state.activeSubscriptions.map(subscription =>
          subscription.id === action.payload.id ? { ...action.payload, plan: subscription.plan } : subscription,
        )
      : state.activeSubscriptions,
  })),
);
