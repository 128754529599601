<div class="container" [class.first-cell]="isFirstCell">
  <ng-container [ngSwitch]="cell?.type">
    <ng-container *ngSwitchCase="cellType.bool">
      <img
        [src]="cell.value == 'true' ? (getCheckmarkImg(cell) | assetsPath | async) : ('close.svg' | assetsPath | async)"
      />
    </ng-container>
    <ng-container *ngSwitchCase="cellType.price">
      <div>
        <div>
          <ng-container *ngIf="!cell?.currency">
            {{ cell.value | translate }}
          </ng-container>
          <ng-container *ngIf="cell?.currency && currency">
            {{ cell.value }}
          </ng-container>
        </div>
        <div *ngIf="cell?.pricePerMonth" class="price-per-month">
          ({{ cell?.pricePerMonth }} / {{ 'txt_month' | translate }})
        </div>
        <div *ngIf="showExclTax" class="excl-tax">({{ 'txt_excl_tax_label' | translate }})</div>
      </div>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <span *ngIf="!cell?.link"> {{ cell.value | translate }} </span>
      <a class="link" *ngIf="cell?.link" [routerLink]="cell.link">{{ cell.value }}</a>
    </ng-container>
  </ng-container>

  <div class="info-icon" *ngIf="cell?.tooltip" [gcTooltip]="tooltip">
    <img src="{{ 'icons/info.svg' | assetsPath | async }}" alt="info icon" />
  </div>
</div>

<ng-template #tooltip>
  <div class="tooltip">
    {{ cell?.tooltip }}
  </div>
</ng-template>
