import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrderStatus } from '../../orders/lib/order-status.enum';

@Component({
  selector: 'gd-order-status-multi-select',
  templateUrl: './order-status-multi-select.component.html',
  styleUrls: ['./order-status-multi-select.component.scss'],
})
export class OrderStatusMultiSelectComponent implements OnInit {
  @Input() statuses: OrderStatus[] = null;

  @Output() statusesChange: EventEmitter<OrderStatus[]> = new EventEmitter();

  @Input()
  options: OrderStatus[] = [
    OrderStatus.CREATED,
    OrderStatus.UPLOADING,
    OrderStatus.PASSED,
    OrderStatus.PRINTED,
    OrderStatus.SHIPPED,
    OrderStatus.CANCELED,
    OrderStatus.FAILED,
    OrderStatus.PENDING_APPROVAL,
    OrderStatus.PENDING_PERSONALIZATION,
    OrderStatus.DRAFT,
    OrderStatus.NOT_CONNECTED,
  ];

  constructor() {}

  ngOnInit() {}

  onSelect(statuses: OrderStatus[]) {
    this.statusesChange.emit(statuses);
  }
}
