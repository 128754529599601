<div class="discount-item">
  <div class="discount-item-content-wrapper">
    <img
      [src]="'icons/discount_badge.svg' | assetsPath | async"
      width="48"
      height="48"
      alt="icon with percentage sign near single discount information"
    />

    <div class="discount-item-content">
      <div class="discount-item-info">
        <div class="discount-item-title" [innerHTML]="discount.name | safe: 'html'"></div>
        <div
          class="discount-item-description"
          *ngIf="discount.banner"
          [innerHTML]="discount.banner | safe: 'html'"
        ></div>
      </div>

      <div class="discount-item-time" *ngIf="discount.expiration_date">
        <gd-discount-item-dynamic-time-label [expirationDate]="discount.expiration_date">
        </gd-discount-item-dynamic-time-label>
      </div>
      <div class="discount-amount-wrapper" *ngIf="orderDiscount">
        <div class="discount-amount">
          {{ orderDiscount }}
        </div>
      </div>
    </div>
  </div>
</div>
