import { SanityProduct } from '../sanity-product';
import { SanityProductCareInstructionGroup } from '../sanity-product-care-instruction-group';
import { SanityProductCareInstruction } from '../sanity-product-care-instruction';

export const getFormattedProductCareInstructionsFromSanityProduct = (sanityProduct: SanityProduct): string => {
  if (
    !sanityProduct ||
    !sanityProduct.productCareInstructions ||
    !sanityProduct.productCareInstructions.instructionGroups ||
    !sanityProduct.productCareInstructions.instructionGroups.length
  ) {
    return '';
  }

  let productCareInstructions = '';

  sanityProduct.productCareInstructions.instructionGroups.forEach(
    (group: SanityProductCareInstructionGroup, groupIndex: number) => {
      if (!group.instructions || !group.instructions.length) {
        return;
      }

      productCareInstructions += `<p><strong>${group.title}</strong></p>\n`;
      productCareInstructions += '<table>';

      group.instructions.forEach((instruction: SanityProductCareInstruction, instructionIndex: number) => {
        productCareInstructions += `<tr><td>${instruction.title}</td><td>${instruction.description}</td>\n`;
      });

      productCareInstructions += '</table>';

      if (groupIndex < sanityProduct.productCareInstructions.instructionGroups.length - 1) {
        productCareInstructions += '<br>\n';
      }
    },
  );

  return productCareInstructions;
};
