import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import * as sanityActions from '@gelato-api-ui/sdk/src/lib/sanity/sanity.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { getProductCommunicationBanners } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.selector';
import {
  SanityHomePageItemWidth,
  sanityHomePageItemWidthMapper,
  SanityProductCommunicationBannersItem,
} from '@gelato-api-ui/core/sanity/sanity-home-page-content';
import { Router } from '@angular/router';
import { filter, take, tap } from 'rxjs/operators';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { getShopifyStoresList } from '@api-ui-app/src/app/ngrx/e-commerce-stores.selector';
import { loadStoresListForCurrentClient } from '@api-ui-app/src/app/ngrx/e-commerce-stores.actions';
import { getSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';

@Component({
  selector: 'gd-custom-widget-container',
  templateUrl: './custom-widget-container.component.html',
  styleUrls: ['./custom-widget-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomWidgetContainerComponent implements OnInit {
  widgets$ = this.store.select(getProductCommunicationBanners);
  isGelatoPlusOrGoldActive$ = this.subscriptions.gelatoPlusOrGoldIsActive$;
  shopifyStoresList$ = this.store.select(getShopifyStoresList);
  clientId$ = this.store.select(getSelectedClientId);

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly subscriptions: SubscriptionsFacade,
  ) {}

  ngOnInit(): void {
    this.widgets$
      .pipe(
        filter(widgets => widgets === null),
        take(1),
        tap(() => this.store.dispatch(sanityActions.loadProductCommunicationBanners({}))),
      )
      .subscribe();

    this.widgets$
      .pipe(
        filter(
          widgets =>
            widgets &&
            widgets.some(
              widget =>
                this.isWidgetVisible(widget) && widget.content.widgets.some(w => w.visible_when_has_shopify_store),
            ),
        ),
        take(1),
        tap(() => this.store.dispatch(loadStoresListForCurrentClient())),
      )
      .subscribe();
  }

  get currentRoute() {
    return this.router.url.split('#')[0].split(';')[0].split('?')[0];
  }

  getWidgetWidthClass(width: SanityHomePageItemWidth): string {
    return sanityHomePageItemWidthMapper[width || SanityHomePageItemWidth.sixteen];
  }

  isWidgetVisible(widget: SanityProductCommunicationBannersItem) {
    return widget.visible && widget.routes.includes(this.currentRoute);
  }
}
