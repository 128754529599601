import { createAction, props } from '@ngrx/store';
import { SubscriptionResponse } from '@api-ui-app/src/app/subscriptions/types/subscriptions';
import {
  ServicePlanResponse,
  ServicePlanSearchResponse,
} from '@api-ui-app/src/app/subscriptions/types/subscription-plans';
import {
  ServicePlanPrice,
  ServicePlanPriceSearchResponse,
  ServicePricePlanTaxResponse,
} from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';
import { SanitySubscriptionPlan } from '@gelato-api-ui/core/app-store/sanity-subscription-plan';
import { SubscriptionPlanType } from '../../app-store/types/price-plan-items';

export const loadActiveSubscriptionsAndServicePlans = createAction(
  '[Subscriptions] Load Active Subscriptions And Service Plans',
);

export const chargebeeSubscriptionSuccessful = createAction('[Subscriptions] Chargebee Subscription Success');

export const loadActiveSubscriptionsSuccess = createAction(
  '[Subscriptions] Load Subscriptions Success',
  props<{ activeSubscriptions: SubscriptionResponse[]; activeServicePlans: ServicePlanResponse[] }>(),
);

export const loadSubscriptionPlans = createAction(
  '[Subscriptions] Load Subscription Plans',
  props<{ serviceUniqueNames: string[] }>(),
);

export const loadSubscriptionPlansSuccess = createAction(
  '[Subscriptions] Load Subscription Plans Success',
  props<{ payload: ServicePlanSearchResponse }>(),
);

export const loadSubscriptionPlansFailure = createAction(
  '[Subscriptions] Load Subscription Plans Failure',
  props<{ error: any }>(),
);

export const loadSubscriptionPlansListFromSanity = createAction(
  '[Subscriptions] Load Subscription Plans List From Sanity',
  props<{ uniqueNames: string[] }>(),
);

export const loadSubscriptionPlansListFromSanitySuccess = createAction(
  '[Subscriptions] Load Subscription Plans List From Sanity Success',
  props<{ payload: SanitySubscriptionPlan[] }>(),
);

export const loadSubscriptionPlansListFromSanityFailure = createAction(
  '[Subscriptions] Load Subscription Plans List From Sanity Failure',
  props<{ error: any }>(),
);

export const loadSubscriptionPlansPrices = createAction(
  '[Subscriptions] Load Subscription Plans Prices',
  props<{ serviceUniqueNames: string[]; userCurrency: string }>(),
);

export const loadSubscriptionPlansPricesSuccess = createAction(
  '[Subscriptions] Load Subscription Plans Prices Success',
  props<{ payload: ServicePlanPriceSearchResponse }>(),
);

export const activateServicePlan = createAction(
  '[Subscriptions] Activate Service Plan',
  props<{ servicePlanPrice: ServicePlanPrice }>(),
);

export const setServicePlanActivationPayload = createAction(
  '[Subscriptions] Set Service Plan Activation',
  props<{ servicePlan: ServicePlanResponse; subscription: SubscriptionResponse }>(),
);

export const setActivePeriodUnit = createAction(
  '[Subscriptions] Set Active Period Unit',
  props<{ periodUnit: TimePeriodUnit }>(),
);

export const setActivePlansIsUpdating = createAction(
  '[Subscriptions] Set Active Plans Is Updating',
  props<{ status: boolean }>(),
);

export const calculatePricePlanTax = createAction(
  '[Subscriptions] Calculate Price Plan Tax',
  props<{ servicePricePlanId: string; silentUpdate?: boolean }>(),
);

export const calculatePricePlanTaxSuccess = createAction(
  '[Subscriptions] Calculate Price Plan Tax Success',
  props<{ payload: ServicePricePlanTaxResponse }>(),
);

export const calculatePricePlanTaxFailure = createAction(
  '[Subscriptions] Calculate Price Plan Tax Failure',
  props<{ error: any }>(),
);

export const getIfClientEligibleForGoldSubscription = createAction(
  '[Subscriptions] Get If Client Eligible For Gold Subscription',
);

export const getIfClientEligibleForGoldSuccess = createAction(
  '[Subscriptions] Get If Client Eligible For Gold Success',
  props<{ isClientEligibleForGoldSubscription: boolean }>(),
);

export const activateSubscription = createAction(
  '[Subscriptions] Activate Subscription Start',
  props<{ subscriptionType: SubscriptionPlanType }>(),
);

export const activateSubscriptionSuccess = createAction(
  '[Subscriptions] Activate Subscription Success',
  props<{ payload: SubscriptionResponse }>(),
);

export const cancelSubscription = createAction(
  '[Subscriptions] Cancel Subscription',
  props<{ subscriptionId: string }>(),
);

export const cancelSubscriptionSuccess = createAction(
  '[Subscriptions] Cancel Subscription Success',
  props<{ subscription: SubscriptionResponse }>(),
);

export const cancelSubscriptionFailure = createAction('[Subscriptions] Cancel Subscription Failure');
