import { Pipe, PipeTransform } from '@angular/core';
import { MoneyPipe } from './money.pipe';

@Pipe({ name: 'priceRange' })
export class PriceRangePipe implements PipeTransform {
  constructor(private moneyPipe: MoneyPipe) {}

  transform(values: number[], currency?: string): string {
    const minValue = values[0];
    const maxValue = values[1];

    const minPriceFormatted = this.moneyPipe.transform(minValue, currency, true);
    const maxPriceFormatted = this.moneyPipe.transform(maxValue, currency, !currency);

    return minValue !== maxValue ? `${minPriceFormatted} - ${maxPriceFormatted}` : maxPriceFormatted;
  }
}
