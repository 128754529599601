import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { PreflightCdnService } from '@gelato-api-ui/core/preflight/services/preflight-cdn.service';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { validateDesignStructure } from '@gelato-api-ui/core/designs/helpers/validateDesignStructure';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';
import { PrintAreaNameEnum, PrintAreaNameSortedArr } from '@gelato-api-ui/core/print-sides/print-side-name.enum';
import { DesignGraphQlService } from '@gelato-api-ui/core/designs/services/design-graph-ql.service';

@Injectable({ providedIn: 'root' })
export class DesignStructureConversionService {
  isDTGNewPrintAreasAB$ = this.featureSwitcherService.isFeatureEnabledForCurrentUser(
    FeatureFlagEnum.dtg_new_print_areas,
  );

  constructor(
    private readonly preflightCdnService: PreflightCdnService,
    private readonly featureSwitcherService: FeatureSwitcherService,
    private readonly designGraphQlService: DesignGraphQlService,
  ) {}

  convertToAnotherProduct(
    sourceDesignStructure: DesignStructure,
    sourceProductUid: string,
    sourcePageCount: number,
    destinationProductUid: string,
    sourceProductTypeUid: ProductTypeUid = null,
    scaleImage = true,
  ): Observable<DesignStructure> {
    if (sourceProductUid === destinationProductUid) {
      return of(sourceDesignStructure);
    }

    return this.designGraphQlService.convertDesignStructure(
      sourceDesignStructure,
      sourceProductUid,
      sourcePageCount,
      destinationProductUid,
      sourceProductTypeUid,
      scaleImage,
    );
  }

  prepareMultiPrintAreasStructure(
    sourceDesignStructure: DesignStructure,
    filledPrintAreas: PrintAreaNameEnum[],
    validProductUid: string,
  ): DesignStructure {
    const validatedSourceDesignData = validateDesignStructure(sourceDesignStructure);

    const newSpreads = PrintAreaNameSortedArr.map(spreadName => {
      const spread = validatedSourceDesignData.spreads.find(s => s.name === spreadName);

      if (!spread || !filledPrintAreas.includes(spread.name as PrintAreaNameEnum)) {
        return { name: spreadName, pages: [] };
      }

      return spread;
    });

    return {
      ...validatedSourceDesignData,
      product_uid: validProductUid,
      spreads: newSpreads,
    };
  }
}
