import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PaymentDetailsEffects } from './payment-details/payment-details.effects';
import { paymentDetailsReducer } from './payment-details/payment-details.reducer';
import { paymentDetailsFeatureName } from './payment-details/payment-details.feature';
import { PaymentDetailsFacade } from './payment-details/payment-details.facade';
import { WalletEffects } from './wallet/wallet.effects';
import { walletFeatureName } from './wallet/wallet.feature';
import { walletReducer } from './wallet/wallet.reducer';
import { WalletFacade } from './wallet/wallet.facade';
import { walletTransactionsReducer } from './wallet-transactions/wallet-transactions.reducer';
import { walletTransactionsFeatureName } from './wallet-transactions/wallet-transactions.feature';
import { WalletTransactionsEffects } from './wallet-transactions/wallet-transactions.effects';
import { WalletTransactionsFacade } from './wallet-transactions/wallet-transactions.facade';

@NgModule({
  imports: [
    StoreModule.forFeature(paymentDetailsFeatureName, paymentDetailsReducer),
    StoreModule.forFeature(walletFeatureName, walletReducer),
    StoreModule.forFeature(walletTransactionsFeatureName, walletTransactionsReducer),
    EffectsModule.forFeature([WalletEffects, PaymentDetailsEffects, WalletTransactionsEffects]),
  ],
  providers: [PaymentDetailsFacade, WalletFacade, WalletTransactionsFacade],
})
export class WalletsStateModule {}
