import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { getTimestampFromString } from '../../lib/getTimestampFromString';

@Pipe({ name: 'formattedDateTimeUtc' })
export class FormattedDateTimeUtcPipe implements PipeTransform {
  datePipe = new DatePipe('en-GB');

  transform(value: any, format?: string): any {
    if (!value) {
      return '';
    }

    const timestamp = getTimestampFromString(value);
    const zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return this.datePipe.transform(timestamp, format || 'y-MM-dd HH:mm:ss', `${zone}`);
  }
}
