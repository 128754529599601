<ng-container *ngIf="isSaving">
  <p class="is-saving">{{ 'txt_e_commerce_product_autosave_status_in_progress' | translate }}</p>
</ng-container>
<ng-container *ngIf="!isSaving && isSaved">
  <ng-container *ngIf="!isSavedRecently">
    <p class="is-saved">{{ 'txt_e_commerce_product_autosave_status_complete' | translate }}</p>
  </ng-container>

  <ng-container *ngIf="isSavedRecently">
    <p class="is-saved-recently">{{ 'txt_e_commerce_product_autosave_status_complete_recently' | translate }}</p>
  </ng-container>
</ng-container>
