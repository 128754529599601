import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ErrorData } from '@api-ui-app/src/app/shared/lib/error-data';

@Component({
  selector: 'gd-form-error-message, gc-form-error-message',
  templateUrl: './form-error-message.component.html',
  styleUrls: ['./form-error-message.component.scss'],
})
export class FormErrorMessageComponent {
  @Input() isVisible = false;
  @Input() error: ErrorData = null;
  @Output() dismiss = new EventEmitter();

  onDismiss() {
    this.dismiss.emit();
  }
}
