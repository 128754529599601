import { Injectable } from '@angular/core';
import { ApparelPreviewScenes, PremiumPreviewSceneRegExp } from '@gelato-api-ui/core/preflight/apparel-preview-scenes';
import { Scene } from '@gelato-api-ui/core/preflight/scene';
import { PrintAreaNameEnum, PrintAreaNameSortedArr } from '@gelato-api-ui/core/print-sides/print-side-name.enum';

@Injectable({ providedIn: 'root' })
export class PrintAreaService {
  isApparelScene(previewScene: Scene) {
    return ApparelPreviewScenes.includes(previewScene as Scene) || PremiumPreviewSceneRegExp.test(previewScene);
  }

  getExpectedSpreadNameByScene(previewScene: Scene): PrintAreaNameEnum {
    return PrintAreaNameSortedArr.reduce((spreadName, pageAreaName) => {
      const regExp = new RegExp(pageAreaName, 'i');
      if (regExp.test(previewScene)) {
        return pageAreaName;
      }
      return spreadName;
    }, null);
  }

  getPageNrByScene(previewScene: Scene): number {
    return PrintAreaNameSortedArr.reduce((pageNr, pageAreaName, index) => {
      const regExp = new RegExp(pageAreaName, 'i');
      if (regExp.test(previewScene)) {
        return index + 1;
      }
      return pageNr;
    }, 1);
  }

  getPrintAreaNameByPageNr(pageNr) {
    return PrintAreaNameSortedArr[pageNr - 1];
  }
}
