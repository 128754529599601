import { createReducer, on } from '@ngrx/store';
import * as actions from './price-breakdown.actions';
import {
  DesignPriceBreakdownItem,
  PriceBreakdown,
} from '@api-ui-app/src/app/shared/price-breakdown/types/price-breakdown';
import { PrintAreaNameEnum } from '@gelato-api-ui/core/print-sides/print-side-name.enum';

export interface State {
  isLoading: boolean;
  productUid: string;
  designId: string;
  printAreas: string[];
  country: string;
  currency: string;
  pageCount: number;
  clientId: string;
  total: string;
  productTotal: string;
  includedPrintArea: PrintAreaNameEnum;
  designTotal: string;
  breakdown: PriceBreakdown;
  designBreakdown: DesignPriceBreakdownItem[];
  error: any;
}

export const initialState: State = {
  isLoading: false,
  productUid: null,
  designId: null,
  printAreas: null,
  country: null,
  currency: null,
  pageCount: undefined,
  clientId: null,
  total: null,
  productTotal: null,
  includedPrintArea: null,
  designTotal: null,
  breakdown: null,
  designBreakdown: null,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(actions.resetPriceBreakdown, () => ({
    ...initialState,
  })),
  on(actions.loadPriceBreakdown, (state, action) => ({
    ...initialState,
    isLoading: true,
    ...action,
  })),
  on(actions.loadPriceBreakdownComplete, (state, action) => ({
    ...state,
    isLoading: false,
    ...action,
  })),
  on(actions.loadPriceBreakdownError, (state, action) => ({
    ...state,
    isLoading: false,
    error: action,
  })),
);
