<gc-dropdown-menu
  #dropdownMenu
  [buttonType]="'primary'"
  [buttonContent]="dropdown"
  [dropdownTpl]="dropdownTpl"
  [isMobile]="isMobile"
  [mobileMenuTitle]="'txt_connect'"
  [isDisabled]="disabled"
>
</gc-dropdown-menu>
<ng-template #dropdown>{{ 'txt_connect' | translate }}</ng-template>

<ng-template #dropdownTpl>
  <a class="item checkout dropdown" (click)="onConnectToNewProductButtonClick()">
    <img src="/assets/icons/products.svg" alt="" />{{ 'txt_checkout_add_new_product' | translate }}
  </a>
  <a class="item checkout dropdown" (click)="onConnectToExistingProductButtonClick()">
    <img src="/assets/sidebar-icons/stores.svg" alt="" />{{ 'txt_checkout_add_existing_product' | translate }}
  </a>
</ng-template>
