import { ChangeDetectionStrategy, Component, ElementRef, HostListener, Input } from '@angular/core';
import { NavigationTreeService } from '../sidebar-nav/navigation-tree.service';
import { Subject } from 'rxjs';
import { sidebarStorageKey } from '@api-ui-app/src/app/ngrx/layout';

@Component({
  selector: 'gd-sidebar-desktop',
  templateUrl: './sidebar-desktop.component.html',
  styleUrls: ['./sidebar-desktop.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarDesktopComponent {
  @Input() isNonRenewingSubscriptionEnding: boolean;
  @Input() isGelatoPlusGoldActive: boolean;
  @Input() nonRenewingGelatoPlusDaysLeft: number;
  @Input() public set minimise(val: boolean) {
    this.isMinimised = val;
    if (val) {
      this.elRef.nativeElement.classList.add('minimised');
    } else {
      this.elRef.nativeElement.classList.remove('minimised');
    }
  }

  public hovered = false;
  public isMinimised = false;
  ngOnDestroy$ = new Subject();

  get navigationTree$() {
    return this.navigationTreeService.tree;
  }

  get isGelatoPlusSubscriptionEnding() {
    return !this.isGelatoPlusGoldActive && this.isNonRenewingSubscriptionEnding;
  }

  constructor(private readonly navigationTreeService: NavigationTreeService, private readonly elRef: ElementRef) {
    const sidebarStorageValue = localStorage.getItem(sidebarStorageKey);
    if (!!sidebarStorageValue && Boolean(Number(sidebarStorageValue))) {
      this.elRef.nativeElement.classList.add('minimised');
    } else {
      this.elRef.nativeElement.classList.remove('minimised');
    }
  }

  @HostListener('mouseenter')
  onMouseOver() {
    if (this.isMinimised && !this.hovered) {
      this.hovered = true;
    }
  }

  @HostListener('mouseleave')
  onMouseOut() {
    if (this.isMinimised && !!this.hovered) {
      this.hovered = false;
    }
  }
}
