import { Component, Input } from '@angular/core';

@Component({
  selector: 'gd-fixed-width-container, gc-fixed-width-container',
  templateUrl: './fixed-width-container.component.html',
  styleUrls: ['./fixed-width-container.component.scss'],
})
export class FixedWidthContainerComponent {
  @Input() width = 1000;
}
