<gc-fixed-width-container>
  <ng-container *ngFor="let widget of widgets$ | async">
    <gd-custom-widget
      class="widget"
      *ngIf="isWidgetVisible(widget)"
      [wrapperClass]="getWidgetWidthClass(widget.width)"
      [widgets]="widget.content.widgets"
      [id]="widget.id"
      [type]="widget.type"
      [route]="currentRoute"
      [isPlusActive]="isGelatoPlusOrGoldActive$ | async"
      [hasShopify]="(shopifyStoresList$ | async)?.length"
    ></gd-custom-widget>
  </ng-container>
</gc-fixed-width-container>
