import * as R from 'ramda';
import { SubscriptionAddon, SubscriptionResponse } from '@api-ui-app/src/app/subscriptions/types/subscriptions';

export const getServicePlanIdsFromSubscription = (subscription: SubscriptionResponse): string[] => {
  const ids: string[] = [
    subscription.plan.servicePlanId,
    ...subscription.addons.map((loopAddon: SubscriptionAddon): string => loopAddon.referenceServicePlanId),
  ].filter(id => !!id);

  return R.uniq(ids);
};
