import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ResetableTextFieldComponent } from '@gelato-api-ui/ui-kit/src/lib/resetable-text-field/resetable-text-field.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule],
  exports: [ResetableTextFieldComponent],
  declarations: [ResetableTextFieldComponent],
})
export class ResetableTextFieldModule {}
