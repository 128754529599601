import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { TranslateModule } from '@ngx-translate/core';
import { UiKitModule } from '@gelato-api-ui/ui-kit/src';
import { PricePlansRadioGroupComponent } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/price-plans-radio-group/price-plans-radio-group.component';
import { SubscriptionPaywallPopupComponent } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/subscription-paywall-popup/subscription-paywall-popup.component';
import { GelatoSubscriptionModalComponent } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/containers/gelato-plus-subscription-modal/gelato-plus-subscription-modal.component';
import { GelatoPlusModalService } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/gelato-plus-modal.service';
import { DropdownMenuModule } from '@gelato-api-ui/sdk/src/lib/shared/dropdown-menu/dropdown-menu.module';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';
import { SingleVideoFullscreenModule } from '@gelato-api-ui/sdk/src/lib/shared/single-video-fullscreen/single-video-fullscreen.module';
import { VideoModule } from '@gelato-api-ui/sdk/src/lib/shared/video/video.module';
import { GelatoPlusDescriptionModalComponent } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/gelato-plus-description-modal/gelato-plus-description-modal.component';
import { InstallingGelatoPlusOverlayComponent } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/installing-gelato-plus-overlay/installing-gelato-plus-overlay.component';
import { InstallingGelatoPlusService } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/installing-gelato-plus-service';
import { SubscriptionBillingFormModule } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/subscription-billing-address-form/subscription-billing-form.module';
import { SubscriptionSummaryComponent } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/subscription-summary/subscription-summary.component';
import { SubscriptionFlowComponent } from './containers/subscription-flow/subscription-flow.component';
import { SubscriptionFlowFacade } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/subscription-flow.facade';
import { PricePlanItemModule } from '../price-plan-item/price-plan-item.module';

const exportedModules = [
  // Angular
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  TranslateModule,
  SuiModule,
  UiKitModule,

  DropdownMenuModule,
];

const exportedComponents = [
  PricePlansRadioGroupComponent,
  SubscriptionPaywallPopupComponent,
  GelatoSubscriptionModalComponent,
  GelatoPlusDescriptionModalComponent,
  InstallingGelatoPlusOverlayComponent,
  SubscriptionSummaryComponent,
];

@NgModule({
  imports: [
    ...exportedModules,
    SdkPipesModule,
    SingleVideoFullscreenModule,
    VideoModule,
    PricePlanItemModule,
    SubscriptionBillingFormModule,
  ],
  declarations: [...exportedComponents, SubscriptionFlowComponent],
  exports: [...exportedComponents],
  providers: [GelatoPlusModalService, InstallingGelatoPlusService, SubscriptionFlowFacade],
})
export class SubscriptionsSdkSharedModule {}
