<ng-template #itemTextTemplate let-item="item">
  <span class="item-text" *ngIf="item.translationKey">{{ item.translationKey | translate }}</span>
  <span class="item-text" *ngIf="item.text">{{ item.text }}</span>
</ng-template>

<div class="breadcrumbs" *ngIf="items && items.length > 1">
  <div class="link-wrapper" *ngFor="let item of items; last as isLast">
    <a *ngIf="item.routerLink" [routerLink]="item.routerLink">
      <ng-container *ngTemplateOutlet="itemTextTemplate; context: { item: item }"> </ng-container>
    </a>
    <a *ngIf="!item.routerLink && item.onClick" (click)="item.onClick()">
      <ng-container *ngTemplateOutlet="itemTextTemplate; context: { item: item }"> </ng-container>
    </a>
    <ng-template [ngIf]="!item.routerLink && !item.onClick">
      <ng-container *ngTemplateOutlet="itemTextTemplate; context: { item: item }"> </ng-container>
    </ng-template>
    <i class="angle right icon" *ngIf="!isLast"></i>
  </div>
</div>

<div class="breadcrumbs" *ngIf="!items || items.length <= 1">
  <div class="link-wrapper">
    <ng-container *ngTemplateOutlet="itemTextTemplate; context: { item: { text: '&nbsp;' } }"> </ng-container>
  </div>
</div>
