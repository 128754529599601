import { ActionReducerMap, createReducer, on } from '@ngrx/store';
import { initiateState } from '@api-ui-app/src/app/checkout/+state/checkout.actions';
import {
  loadReturnAddressesList,
  loadReturnAddressesListFailure,
  loadReturnAddressesListSuccess,
} from '@api-ui-app/src/app/return-addresses/+state/return-addresses.actions';
import { InjectionToken } from '@angular/core';
import { ReturnAddress } from '../types/return-address';

export const returnAddressesReducerToken = new InjectionToken<ActionReducerMap<State>>('return addresses reducer');

export interface State {
  isLoading: boolean;
  payload: ReturnAddress[];
  errorMessage: string;
}

export const initialState: State = {
  isLoading: false,
  payload: null,
  errorMessage: null,
};

export const reducer = createReducer(
  initialState,
  on(initiateState, () => initialState),
  on(loadReturnAddressesList, state => ({
    ...state,
    isLoading: true,
  })),
  on(loadReturnAddressesListSuccess, (state, { payload }) => ({
    ...state,
    payload,
    isLoading: false,
  })),
  on(loadReturnAddressesListFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    errorMessage: error?.message || null,
  })),
);
