import { Injectable } from '@angular/core';
import { DesignEditorPreviewSettings } from '@gelato-api-ui/core/design-editor/design-editor-preview-settings';
import { DesignEditorPreviewSettingsGraphQlService } from '@gelato-api-ui/core/design-editor/services/design-editor-preview-settings-graph-ql.service';
import { Observable } from 'rxjs';
import { DesignRendersLoadingSettings } from '@gelato-api-ui/core/design-renders-loader/design-renders-loading-settings';
import { DesignEditorPreviewSettingsPostMessagePayload } from '@gelato-api-ui/core/design-editor/design-editor-preview-settings-post-message-payload';

@Injectable({
  providedIn: 'root',
})
export class DesignEditorPreviewSettingsService {
  constructor(private readonly designEditorPreviewSettingsGraphQlService: DesignEditorPreviewSettingsGraphQlService) {}

  fetch(productUid: string, hasShutterstockAssets: boolean): Observable<DesignEditorPreviewSettings> {
    return this.designEditorPreviewSettingsGraphQlService.fetch(productUid, hasShutterstockAssets);
  }

  mapToSetEditorPreviewSettingsPostMessagePayload(
    previewSettings: DesignEditorPreviewSettings,
  ): DesignEditorPreviewSettingsPostMessagePayload {
    return {
      previews: {
        includeBackground: previewSettings.includeBackground,
        includeForeground: previewSettings.includeForeground,
        firstSpreadOnly: previewSettings.firstSpreadOnly,
        noFlatPreviews: !previewSettings.clientSideRendering || previewSettings.noFlatPreviews,
      },
    };
  }

  mapToDesignRendersLoadingSettings(previewSettings: DesignEditorPreviewSettings): DesignRendersLoadingSettings {
    return {
      takeFirstSpread: previewSettings.firstSpreadOnly,
      noFlatPreview: previewSettings.clientSideRendering || previewSettings.noFlatPreviews,
    };
  }
}
