import { Action } from '@ngrx/store';
import { UserSearchRequest } from '../lib/user-search-request';
import { UserListState, UserState } from './users.reducer';

export enum UsersActionTypes {
  LoadUserListStart = '[Users] Load UserList Start',
  LoadUserStart = '[Users] Load User Start',

  SetUserListState = '[Users] Set UserList State',
  SetUserState = '[Users] Set User State',

  ResetUserListState = '[Users] Reset UserList State',
  ResetUserListPayload = '[Users] Reset UserList Payload',
  ResetUserState = '[Users] Reset User State',
}

export class LoadUserListStart implements Action {
  readonly type = UsersActionTypes.LoadUserListStart;

  constructor(public searchRequest: UserSearchRequest, public forced: boolean = false) {}
}

export class SetUserListState implements Action {
  readonly type = UsersActionTypes.SetUserListState;

  constructor(public payload: UserListState) {}
}

export class ResetUserListState implements Action {
  readonly type = UsersActionTypes.ResetUserListState;
}

export class ResetUserListPayload implements Action {
  readonly type = UsersActionTypes.ResetUserListPayload;
}

export class LoadUserStart implements Action {
  readonly type = UsersActionTypes.LoadUserStart;

  constructor(public id: string, public forced: boolean = false) {}
}

export class SetUserState implements Action {
  readonly type = UsersActionTypes.SetUserState;

  constructor(public payload: UserState) {}
}

export class ResetUserState implements Action {
  readonly type = UsersActionTypes.ResetUserState;
}

export type UsersActions =
  | LoadUserListStart
  | LoadUserStart
  | ResetUserListState
  | ResetUserListPayload
  | ResetUserState
  | SetUserListState
  | SetUserState;
