import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as R from 'ramda';

import { currencyRound } from '@gelato-api-ui/core/currency/currency-round';

@Pipe({ name: 'money' })
export class MoneyPipe implements PipeTransform {
  digits = {
    CLP: 0,
    INR: 0,
    JPY: 0,
    KRW: 0,
  };

  constructor(private decimal: DecimalPipe) {}

  transform(value: string | number, currency?: string, hideCurrency = false, hideDigits = false): string {
    if (R.isNil(value)) {
      return '';
    }

    const floatValue = parseFloat(String(value));
    const rounded = currencyRound(floatValue, currency);
    const digits = R.defaultTo(2, this.digits[currency]);
    const formatted = this.decimal.transform(rounded, hideDigits ? null : `1.${digits}-${digits}`);

    return hideCurrency ? formatted : `${formatted} ${currency}`;
  }
}
