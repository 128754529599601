export function downloadFileFromUrl(url: string, fileName: string, target: string = null) {
  const link = document.createElement('a');
  link.href = url;
  link.style.opacity = '0';
  link.innerText = fileName;

  if (target) {
    link.target = target;
  }

  if (fileName) {
    link.setAttribute('download', fileName);
  }

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
