import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';
import { PriceList, PriceListItem } from '@product-catalogue/src/lib/product-catalogue/lib/price-list';
import { Observable } from 'rxjs';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import * as gql from 'gql-query-builder';
import { ProductPrice } from '@gelato-api-ui/core/product-prices/product-price';
import { Dimensions } from '@product-catalogue/src/lib/product-catalogue/lib/dimentions';
import VariableOptions from 'gql-query-builder/build/VariableOptions';
import { ProductUidWithDimensions } from '@product-catalogue/src/lib/lib/price/ProductUidsWithDimensions';
import { FlatPrice } from '@gelato-api-ui/product-catalogue/src/lib/product-details-shared/regional-pricing-table/types/flat-price';
import * as R from 'ramda';

@Injectable()
export class PriceListGraphQlService {
  constructor(private readonly graphQlRequestService: GraphQlRequestService) {}

  fetchPriceList(
    products: ProductUidWithDimensions[],
    country: string,
    currency: string,
    pageCount: number,
    clientId: string,
    useFlatPrices: boolean,
    fetchShipmentPrices: boolean = true,
  ): Observable<PriceList> {
    const query = this.fetchPriceListQuery(
      products,
      country,
      currency,
      pageCount,
      clientId,
      useFlatPrices,
      fetchShipmentPrices,
    );
    return this.graphQlRequestService.query(query, 'priceList:fetchPriceList').pipe(
      map((data: ApolloQueryResult<any>) => data?.data?.priceList),
      map((priceList: { prices: PriceListItem[]; downloadUrl: string }) => {
        const res: PriceList = {};

        priceList.prices.forEach(price => {
          let key = price.productUid;

          if (price.width && price.height) {
            key += `+width=${price.width}+height=${price.height}`;
          }

          res[key] = price;
        });

        return res;
      }),
    );
  }

  getPriceFlatRates(
    productUid: string,
    currency: string,
    clientId?: string,
    pageCount?: number,
  ): Observable<Record<string, FlatPrice>> {
    const priceListVariables: VariableOptions = {
      productUid: { type: 'String!', value: productUid },
      currency: { type: 'String!', value: currency },
      clientId: { type: 'String', value: clientId },
      pageCount: { type: 'Int', value: pageCount },
    };
    const shipmentPriceVariables: VariableOptions = {
      productUid: { type: 'String!', value: productUid },
      currency: { type: 'String!', value: currency },
    };

    const query = gql.query([
      {
        operation: 'priceList',
        fields: [
          {
            operation: 'flatRates',
            variables: priceListVariables,
            fields: [],
          },
        ],
      },
      {
        operation: 'shipmentPrice',
        fields: [
          {
            operation: 'flatRates',
            fields: [],
            variables: shipmentPriceVariables,
          },
        ],
      },
    ]);

    return this.graphQlRequestService.query(query, 'priceList:flatRates').pipe(
      map((data: ApolloQueryResult<any>) => {
        const priceList = data?.data?.priceList?.flatRates || {};
        const shipmentPrice = data?.data?.shipmentPrice?.flatRates || {};

        return R.mergeDeepLeft(priceList, shipmentPrice);
      }),
    );
  }

  getPricesForMultipleCountries(
    productUid: string,
    countries: string[] = null,
    currency: string = null,
    pageCount: number = null,
    dimensions: Dimensions = null,
    clientId: string = null,
  ): Observable<ProductPrice[][]> {
    let variables: VariableOptions = {
      productUid: { type: 'String!', value: productUid },
      currency: { type: 'String!', value: currency },
      countries: { type: '[String!]!', value: countries },
      clientId: { type: 'String', value: clientId },
      pageCount: { type: 'Int', value: pageCount },
    };

    if (dimensions) {
      variables = {
        ...variables,
        width: { type: 'Int', value: dimensions.width },
        height: { type: 'Int', value: dimensions.height },
      };
    }

    const query = gql.query({
      operation: 'priceList',
      fields: [
        {
          operation: 'priceByCountry',
          variables,
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, 'priceList:priceByCountry')
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.priceList?.priceByCountry));
  }

  private fetchPriceListQuery(
    productUidWithDimensions: ProductUidWithDimensions[],
    country: string,
    currency: string,
    pageCount: number,
    clientId: string,
    isFlatPrices: boolean,
    fetchShipmentPrices: boolean,
  ) {
    const variables = {
      country: { type: 'String!', value: country },
      currency: { type: 'String!', value: currency },
      products: { type: '[JSONObject!]', value: productUidWithDimensions },
      productUids: {
        type: 'String!',
        list: true,
        value: productUidWithDimensions.map(p => p.productUid),
        required: true,
      },
      useFlatPrices: isFlatPrices,
      fetchShipmentPrices: { type: 'Boolean', defaultValue: true, value: fetchShipmentPrices, required: true },
    };

    if (clientId) {
      variables['clientId'] = clientId;
    }

    if (pageCount) {
      variables['pageCount'] = { type: 'Float!', value: pageCount };
    }

    const pricesFields = !!fetchShipmentPrices
      ? ['product', 'shipment', 'total', 'productUid', 'width', 'height']
      : ['product', 'productUid', 'width', 'height'];

    return gql.query({
      operation: 'priceList',
      fields: [
        {
          operation: 'prices',
          variables,
          fields: pricesFields,
        },
      ],
    });
  }

  public fetchPricesByProduct(
    productUids: string[],
    country: string,
    currency: string,
    pageCount?: number,
    clientId?: string,
  ): Observable<ProductPrice[][]> {
    const variables = {
      country: { type: 'String!', value: country },
      currency: { type: 'String!', value: currency },
      productUid: { type: 'String!', list: true, value: productUids?.filter(val => !!val), required: true },
      pageCount: { type: 'Int', value: pageCount },
      clientId: { type: 'String', value: clientId },
    };

    const query = gql.query({
      operation: 'priceList',
      fields: [
        {
          operation: 'priceByProduct',
          variables,
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, 'priceList:fetchPricesByProduct')
      .pipe(map(({ data }: ApolloQueryResult<any>) => data.priceList.priceByProduct));
  }
}
