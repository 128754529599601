import { createAction, props } from '@ngrx/store';
import { Wallet, WalletChangeSet } from '../../lib/api';
import { TopUpFailureResponse } from '@api-ui-app/src/app/payment-methods/wallets/top-up-form/lib/top-up-success.event';

export const fetchStart = createAction('[Wallets] FetchStart', props<{ clientId: string }>());

export const fetchSuccess = createAction('[Wallets] FetchSuccess', props<{ list: Wallet[]; clientId: string }>());

export const fetchFail = createAction('[Wallets] FetchFail', props<{ err: any; clientId: string }>());

export const fetchIntent = createAction('[Wallets] FetchIntent', props<{ force: boolean; clientId: string }>());

export const updateStart = createAction('[Wallets] UpdateStart', props<{ wallet: Wallet; change: WalletChangeSet }>());

export const updateSuccess = createAction('[Wallets] UpdateSuccess', props<{ wallet: Wallet }>());

export const updateFail = createAction('[Wallets] UpdateFail', props<{ err: any }>());

export const presetStart = createAction('[Wallets] PresetStart', props<{ clientId: string }>());

export const presetSuccess = createAction('[Wallets] PresetSuccess', props<{ clientId: string }>());

export const presetFail = createAction('[Wallets] PresetFail', props<{ err: any; clientId: string }>());

export const topUpStart = createAction('[Wallets] TopUpStart', props<{ wallet: Wallet; amountInMinorUnits: number }>());

export const topUpSuccess = createAction('[Wallets] TopUpSuccess', props<{ wallet: Wallet }>());

export const topUpFail = createAction('[Wallets] TopUpFail', props<{ err: TopUpFailureResponse }>());

export const topUpInternalStart = createAction(
  '[Wallets] TopUpInternalStart',
  props<{ wallet: Wallet; amountInMinorUnits: number; clientId: string }>(),
);

export const topUpInternalSuccess = createAction('[Wallets] TopUpInternalSuccess', props<{ wallet: Wallet }>());

export const topUpInternalFail = createAction('[Wallets] TopUpInternalFail', props<{ err: TopUpFailureResponse }>());

export const withdrawStart = createAction(
  '[Wallets] WithdrawStart',
  props<{ wallet: Wallet; amountInMinorUnits: number; clientId: string }>(),
);

export const withdrawSuccess = createAction('[Wallets] WithdrawSuccess', props<{ wallet: Wallet; clientId: string }>());

export const withdrawFail = createAction('[Wallets] WithdrawFail', props<{ err: any; clientId: string }>());
