<div class="container" [class.firs-sell]="isFirstCell">
  <ng-container [ngSwitch]="cell?.type">
    <ng-container *ngSwitchCase="cellType.bool">
      <ng-container>
        <span *ngIf="!cell?.link && isFirstCell"> {{ cell?.value }} </span>
        <a class="link" *ngIf="cell?.link" [routerLink]="cell?.link">{{ cell?.value }}</a>
      </ng-container>
      <ng-container *ngIf="cell.value == 'true' && !isFirstCell">
        <img [src]="'subscriptions/black-checkmark.svg' | assetsPath | async" />
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span *ngIf="!cell?.link"> {{ cell?.value }} </span>
      <a class="link" *ngIf="cell?.link" [routerLink]="cell?.link">{{ cell?.value }}</a>
    </ng-container>
  </ng-container>

  <div class="info-icon" *ngIf="cell?.tooltip" [gcTooltip]="tooltip">
    <img src="{{ 'icons/info.svg' | assetsPath | async }}" alt="info icon" />
  </div>
</div>

<ng-template #tooltip>
  <div class="tooltip">
    {{ cell?.tooltip }}
  </div>
</ng-template>
