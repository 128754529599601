import { PremiumIconCrownedFeatures } from '@api-ui-app/src/app/subscriptions/types/icon-crowned-features.enum';
import { CrownIconPremiumLevel } from '@gelato-api-ui/core/design-editor/set-plugins-event-data-item';

export const VISITED_APPS_KEY = 'premium_icon_crowned_used_features';

export const setFeatureUsed = (appName: PremiumIconCrownedFeatures) => {
  const visitedApps = JSON.parse(localStorage.getItem(VISITED_APPS_KEY));
  localStorage.setItem(VISITED_APPS_KEY, JSON.stringify({ ...visitedApps, [appName]: true }));
};

export const getIsFeatureUsed = (appName: PremiumIconCrownedFeatures) => {
  const visitedApps = JSON.parse(localStorage.getItem(VISITED_APPS_KEY));

  if (!visitedApps) {
    return false;
  }

  return visitedApps[appName] ?? false;
};

export const getAppCrownPremiumLevel = (appName: PremiumIconCrownedFeatures, isGelatoPlusActive: boolean) => {
  if (isGelatoPlusActive) {
    return getIsFeatureUsed(appName) ? CrownIconPremiumLevel.SILVER : CrownIconPremiumLevel.GOLD;
  }

  return null;
};
