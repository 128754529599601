import { Action, createAction, props, union } from '@ngrx/store';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import {
  BulkConnectVariantsMappingPayload,
  DesignAssetCostsCollection,
  PriceCollection,
  SelectedPreviewScenesCollection,
  State,
  StoreProductVariantsCollection,
} from './e-commerce-product-wizard.reducer';
import { EProduct } from '@gelato-api-ui/core/e-commerce/e-product';
import { EProductVariantOption } from '@gelato-api-ui/core/e-commerce/e-product-variant-option';
import { EProductDetails } from '@gelato-api-ui/core/e-commerce/e-product-details';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { CategoryDesignFamilyResponse } from '@gelato-api-ui/core/designs/category-design-family-response';
import {
  StoreProductPreviewsCollection,
  StoreProductVariantPreviewsCollection,
} from '@product-catalogue/src/lib/product-catalogue/services/store-product-variants.service';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';
import { ProductDetails } from '@gelato-api-ui/core/product-details/product-details';

export enum ECommerceProductWizardActionTypes {
  ResetState = '[ECommerceProductWizard] Reset State',
  InitState = '[ECommerceProductWizard] Init State',
  SetProductVariantKeysList = '[ECommerceProductWizard] Set Product Variant Keys List',
  SetProductVariantKeysArray = '[ECommerceProductWizard] Set Product Variant Keys Array',
  SetSelectedPreviewFileType = '[ECommerceProductWizard] Set Selected Preview File Type',
  SetSelectedPreviewScenes = '[ECommerceProductWizard] Set Selected Preview Scenes',
  TogglePreviewScene = '[ECommerceProductWizard] Toggle Preview Scene',
  SetPrimaryPreviewScene = '[ECommerceProductWizard] Set Primary Preview Scene',
  SetPrimaryPreviewProductVariantKey = '[ECommerceProductWizard] Set Primary Preview Product Variant Key',
  DecreaseActivePriceFetchingRequestsCount = '[ECommerceProductWizard] Decrease Active Price Fetching Requests Count',
  SetProduct = '[ECommerceProductWizard] Set Product',
  SetProductDescription = '[ECommerceProductWizard] Set Product Description',
  SetStoreProductVariantOptions = '[ECommerceProductWizard] Set Store Product Variant Options',
  UpdateSelectedProductAndVariantOptions = '[ECommerceProductWizard] Update Selected Product And Variant Options',
  SetProductDetails = '[ECommerceProductWizard] Set Product Details',
}

export class ResetState implements Action {
  readonly type = ECommerceProductWizardActionTypes.ResetState;

  constructor(
    public mode: EProductWizardMode,
    public productCategoryName: string,
    public publishImmediately: boolean,
    public selectedPreviewFileType: PreviewFileType,
  ) {}
}

export class InitState implements Action {
  readonly type = ECommerceProductWizardActionTypes.InitState;

  constructor(public payload: Partial<State>) {}
}

export class SetProduct implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetProduct;

  constructor(public payload: EProduct) {}
}

export class SetProductDescription implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetProductDescription;

  constructor(public payload: string) {}
}

export class SetProductDetails implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetProductDetails;

  constructor(public payload: EProductDetails) {}
}

export class SetProductVariantKeysArray implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetProductVariantKeysArray;

  constructor(public payload: string[]) {}
}

export class SetProductVariantKeysList implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetProductVariantKeysList;
  constructor(public payload: string[]) {}
}

export const setStoreProductVariants = createAction(
  '[ECommerceProductWizard] Set Store Product Variants',
  props<{ variants: StoreProductVariantsCollection }>(),
);

export class SetSelectedPreviewFileType implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetSelectedPreviewFileType;

  constructor(public payload: PreviewFileType) {}
}

export class SetSelectedPreviewScenes implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetSelectedPreviewScenes;

  constructor(public payload: SelectedPreviewScenesCollection) {}
}

export class TogglePreviewScene implements Action {
  readonly type = ECommerceProductWizardActionTypes.TogglePreviewScene;

  constructor(public sceneKey: string, public value?: boolean, public singleValueSelectionMode?: boolean) {}
}

export class SetPrimaryPreviewScene implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetPrimaryPreviewScene;

  constructor(public payload: string) {}
}

export class SetPrimaryPreviewProductVariantKey implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetPrimaryPreviewProductVariantKey;

  constructor(public payload: string) {}
}

export class DecreaseActivePriceFetchingRequestsCount implements Action {
  readonly type = ECommerceProductWizardActionTypes.DecreaseActivePriceFetchingRequestsCount;

  constructor() {}
}

export class SetStoreProductVariantOptions implements Action {
  readonly type = ECommerceProductWizardActionTypes.SetStoreProductVariantOptions;

  constructor(public payload: EProductVariantOption[]) {}
}

export class UpdateSelectedProductAndVariantOptions implements Action {
  readonly type = ECommerceProductWizardActionTypes.UpdateSelectedProductAndVariantOptions;
}

export const setDesignFamily = createAction(
  '[ECommerceProductWizard] Set Design Family',
  props<{ designFamily: CategoryDesignFamilyResponse }>(),
);

export const setSupportedPreviewScenes = createAction(
  '[ECommerceProductWizard] Set Supported Preview Scenes',
  props<{ supportedPreviewScenes: string[] }>(),
);

export const updateSelectedPreviewScenes = createAction(
  '[ECommerceProductWizard] Update Selected Preview Scenes',
  props<{ hasUsedShutterstockAssets: boolean }>(),
);

export const setPreviewCollections = createAction(
  '[ECommerceProductWizard] Set Preview Collections',
  props<{
    productPreviewsCollection: StoreProductPreviewsCollection;
    productVariantPreviewsCollection: StoreProductVariantPreviewsCollection;
  }>(),
);

export const setPrimaryProductPreview = createAction(
  '[ECommerceProductWizard] Set Primary Product Preview',
  props<{ payload: string }>(),
);

export const setProductCost = createAction(
  '[ECommerceProductWizard] Set Product Cost',
  props<{ productVariantKey: string; productCost: number }>(),
);

export const setProductShippingCost = createAction(
  '[ECommerceProductWizard] Set Product Shipping Cost',
  props<{ productVariantKey: string; productShippingCost: number }>(),
);

export const setProductAndShippingCostsBatch = createAction(
  '[ECommerceProductWizard] Set Prices Batch',
  props<{ productCosts: PriceCollection; productShippingCosts: PriceCollection }>(),
);

export const setProductDetails = createAction(
  '[ECommerceProductWizard] Setup Product Details',
  props<{ payload: ProductDetails }>(),
);

export const setCountry = createAction('[ECommerceProductWizard] Set Country', props<{ payload: string }>());

export const setPrices = createAction('[ECommerceProductWizard] Set Prices', props<{ prices: PriceCollection }>());

export const fetchDesignAssetCosts = createAction('[ECommerceProductWizard] Fetch Design Assets Costs');

export const fetchDesignAssetCostsSuccess = createAction(
  '[ECommerceProductWizard] Fetch Assets Costs Success',
  props<{ designAssetCosts: DesignAssetCostsCollection }>(),
);

export const fetchDesignTemplateCosts = createAction('[ECommerceProductWizard] Fetch Design Template Costs');

export const fetchDesignTemplateCostsSuccess = createAction(
  '[ECommerceProductWizard] Fetch Design Temlplate Costs Success',
  props<{ designTemplateCosts: PriceCollection }>(),
);

export const setDefaultProductUid = createAction(
  '[ECommerceProductWizard] Set Default Product Uid',
  props<{ uid: string }>(),
);

export const setDefaultPageCount = createAction(
  '[ECommerceProductWizard] Set Default Page Count',
  props<{ defaultPageCount: number }>(),
);

export const setPageCount = createAction('[ECommerceProductWizard] Set Page Count', props<{ pageCount: number }>());

export const setupInitialStateForBulkConnect = createAction(
  '[ECommerceProductWizard] Setup Initial State For Bulk Connect',
  props<{ destinationProductId: string }>(),
);

export const setDestinationProductId = createAction(
  '[ECommerceProductWizard] Set Destination Product Id',
  props<{ payload: string }>(),
);

export const setDestinationProduct = createAction(
  '[ECommerceProductWizard] Set Destination Product',
  props<{ payload: EProductWithVariants }>(),
);

export const clearBulkConnectProductVariantsMapping = createAction(
  '[ECommerceProductWizard] Clear Bulk Connect Product Variants Mapping',
);

export const resetBulkConnectProductVariantsMappingToDefault = createAction(
  '[ECommerceProductWizard] Reset Bulk Connect Product Variants Mapping To Default',
);

export const loadBulkConnectDefaultProductVariantMappingStart = createAction(
  '[ECommerceProductWizard] Load Bulk Connect Default Product Variant Mapping Start',
);

export const loadBulkConnectDefaultProductVariantMappingFailure = createAction(
  '[ECommerceProductWizard] Load Bulk Connect Default Product Variant Mapping Failure',
);

export const loadBulkConnectDefaultProductVariantMappingSuccess = createAction(
  '[ECommerceProductWizard] Load Bulk Connect Default Product Variant Mapping Success',
  props<{ payload: BulkConnectVariantsMappingPayload }>(),
);

export const mapBulkConnectProductVariantsPair = createAction(
  '[ECommerceProductWizard] Map Bulk Connect Product Variants Pair',
  props<{ destinationProductVariantId: string; sourceProductVariantKey: string }>(),
);

export const cleanupBulkConnectVariantsMappings = createAction(
  '[ECommerceProductWizard] Update Bulk Connect Variants Mapping',
);

export const setBulkConnectCustomProductVariantsMapping = createAction(
  '[ECommerceProductWizard] Set Bulk Connect Custom Product Variants Mappings',
  props<{ payload: BulkConnectVariantsMappingPayload }>(),
);

export const setBulkConnectCreatePreviewsFlag = createAction(
  '[ECommerceProductWizard] Set Bulk Connect Create Previews Flag',
  props<{ payload: boolean }>(),
);

export const saveProductDraftStart = createAction('[ECommerceProductWizard] Save Product Draft Start');

export const saveProductDraftSuccess = createAction(
  '[ECommerceProductWizard] Save Product Draft Success',
  props<{ productId: string; latestSavedDraftHash: string }>(),
);

export const saveProductDraftFailure = createAction('[ECommerceProductWizard] Save Product Draft Failure');

export const saveProductTemplateStart = createAction('[ECommerceProductWizard] Save Product Template Start');

export const saveProductTemplateSuccess = createAction('[ECommerceProductWizard] Save Product Template Success');

export const saveProductTemplateFailure = createAction('[ECommerceProductWizard] Save Product Template Failure');

export const createProductTemplateStart = createAction('[ECommerceProductWizard] Create Product Template Start');

export const createProductTemplateSuccess = createAction('[ECommerceProductWizard] Create Product Template Success');

export const createProductTemplateFailure = createAction('[ECommerceProductWizard] Create Product Template Failure');

export const setActiveStep = createAction(
  '[ECommerceProductWizard] Set Active Step',
  props<{ payload: ECommerceProductWizardStep }>(),
);

export const actions = union({
  setDesignFamily,
  setStoreProductVariants,
  setSupportedPreviewScenes,
  updateSelectedPreviewScenes,
  setPreviewCollections,
  setPrimaryProductPreview,
  setProductCost,
  setPrices,
  fetchDesignAssetCosts,
  fetchDesignAssetCostsSuccess,
  fetchDesignTemplateCosts,
  fetchDesignTemplateCostsSuccess,
  setDefaultProductUid,
  setDefaultPageCount,
  setPageCount,
  setupInitialStateForBulkConnect,
  setDestinationProductId,
  setDestinationProduct,
  clearBulkConnectProductVariantsMapping,
  resetBulkConnectProductVariantsMappingToDefault,
  loadBulkConnectDefaultProductVariantMappingStart,
  loadBulkConnectDefaultProductVariantMappingFailure,
  loadBulkConnectDefaultProductVariantMappingSuccess,
  mapBulkConnectProductVariantsPair,
  cleanupBulkConnectVariantsMappings,
  setBulkConnectCustomProductVariantsMapping,
  setBulkConnectCreatePreviewsFlag,
  saveProductDraftStart,
  saveProductDraftSuccess,
  saveProductDraftFailure,
  saveProductTemplateStart,
  saveProductTemplateSuccess,
  saveProductTemplateFailure,
  setActiveStep,
  setProductShippingCost,
  setProductDetails,
  setProductAndShippingCostsBatch,
  setCountry,
});

export type ECommerceProductWizardActions =
  | typeof actions
  | ResetState
  | InitState
  | SetProductVariantKeysList
  | SetSelectedPreviewFileType
  | SetSelectedPreviewScenes
  | TogglePreviewScene
  | SetPrimaryPreviewScene
  | SetPrimaryPreviewProductVariantKey
  | DecreaseActivePriceFetchingRequestsCount
  | SetProductVariantKeysArray
  | SetStoreProductVariantOptions
  | UpdateSelectedProductAndVariantOptions
  | SetProduct
  | SetProductDescription
  | SetProductDetails;
