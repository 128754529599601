import { Inject, Injectable, Optional } from '@angular/core';
import { of } from 'rxjs';
import { API_MODULE_CONFIG } from '@gelato-api-ui/sdk/src/lib/api/api.tokens';
import { ApiModuleConfig } from '@gelato-api-ui/sdk/src/lib/api/api-module-config';
import { HttpHeaders } from '@gelato-api-ui/core/api/http-headers';
import { caller } from '@gelato-api-ui/core/api/caller';

@Injectable({
  providedIn: 'root',
})
export class UserActivationService {
  private config: ApiModuleConfig;

  constructor(
    @Inject(API_MODULE_CONFIG)
    @Optional()
    config: ApiModuleConfig,
  ) {
    this.config = {
      host: '',
      ...config,
    };
  }

  activateUser(httpHeaders: HttpHeaders) {
    const postCaller = caller(this.config.host, 'POST', () => of(httpHeaders));

    return postCaller('/api/identity/v3/users/me:activate');
  }
}
