import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';
import { getMetadataItem } from '@gelato-api-ui/core/metadata/helpers/getMetadataItem';

export const getMetadataItemValue = (
  items: MetadataItem[],
  key: string,
  skipEmptyValues?: boolean,
): string | string[] => {
  const item: MetadataItem = getMetadataItem(items, key, skipEmptyValues);

  return item ? item.value : null;
};
