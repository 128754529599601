import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PaymentDetails } from '../../lib/api';

export interface PaymentDetailsState extends EntityState<PaymentDetails> {
  isFetched: boolean;
}

export const paymentDetailsAdapter: EntityAdapter<PaymentDetails> = createEntityAdapter<PaymentDetails>({
  selectId: details => details.paymentDetailsUid,
});
