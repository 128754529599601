import { Injectable } from '@angular/core';
import { CustomTrim, EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { EProductVariantConnectionStatus } from '@gelato-api-ui/core/e-commerce/e-product-variant-connection-status.enum';
import { ECommerceProductVariantOptionsService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-variant-options.service';
import { ECommerceProductVariantTitleService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-variant-title.service';

@Injectable({ providedIn: 'root' })
export class ECommerceEmptyProductVariantCreationService {
  constructor(
    private readonly eCommerceProductVariantOptionsService: ECommerceProductVariantOptionsService,
    private readonly eCommerceProductVariantTitleService: ECommerceProductVariantTitleService,
  ) {}

  createEmptyProductVariant(
    productUid: string,
    pageCount: number,
    customTrim: CustomTrim,
    sanityProductCategory: SanityProductCategory,
    sanityProduct: SanityProduct,
    preferredCurrency: string,
  ): EProductVariant {
    const title = this.eCommerceProductVariantTitleService.get(
      sanityProductCategory,
      sanityProduct,
      productUid,
      customTrim,
    );
    const variantOptions = this.eCommerceProductVariantOptionsService.getVariantOptionsFromSanityProduct(
      sanityProduct,
      productUid,
      customTrim,
    );
    const productName = sanityProduct.id;

    return {
      id: null,
      externalVariantId: null,
      productId: null,
      productName,
      title,
      productUid,
      pageCount,
      customTrim,
      fileUrl: null,
      fileMimeType: null,
      designId: null,
      designStructureJson: null,
      isCustomizable: false,
      metadata: [],
      price: 0,
      cost: 0,
      currency: preferredCurrency,
      connectionStatus: EProductVariantConnectionStatus.CREATED,
      position: 0,
      createdAt: null,
      updatedAt: null,
      variantOptions,
      sku: undefined,
      clientId: undefined,
      preview: undefined,
      externalPreviewUrl: undefined,
      externalThumbnailUrl: undefined,
      productTypeUid: null,
    };
  }
}
