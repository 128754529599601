import { OrderType } from '../order-type.enum';

const OrderTypesForDeletion = [
  OrderType.DRAFT,
  OrderType.APPROVAL_REQUEST,
  OrderType.NOT_CONNECTED,
  OrderType.PENDING_PERSONALIZATION,
];

export const canDeleteOrder = (orderType: OrderType): boolean => OrderTypesForDeletion.includes(orderType);
