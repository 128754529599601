import { UserRoleObject } from '../shared/lib/user-role-object';
import { AccessGroup } from '../shared/lib/access-group';
import * as actions from './user-roles.actions';

export interface State {
  isLoading: boolean;
  clientId: string;
  userRoles: UserRoleObject[];
  accessGroups: AccessGroup[];
}

export const initialState: State = {
  isLoading: false,
  clientId: null,
  userRoles: null,
  accessGroups: null,
};

export function reducer(state = initialState, action: actions.RolesActions): State {
  switch (action.type) {
    case actions.UserRolesActionTypes.ResetState:
      return { ...initialState };
    case actions.UserRolesActionTypes.SetState:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
