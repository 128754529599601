export enum SidebarNavItemIdEnum {
  HOME = 'home',
  PRODUCT_CATALOGUE = 'productCatalogue',
  ORDERS = 'orders',
  CLIENTS = 'clients',
  USERS = 'users',
  FEATURES = 'features',
  BILLING_ENTITIES = 'billingEntities',
  PAYMENT_TRANSACTIONS = 'paymentTransactions',
  PRICE_PLANS = 'pricePlans',
  PRICE_PLAN_SUBSCRIPTIONS = 'pricePlanSubscriptions',
  PRODUCTION_CONFIGURATIONS = 'productionConfigurations',
  API_DOCUMENTATION = 'apiDocumentation',
  SUPPORT = 'support',
  HELP_CENTER = 'helpCenter',
  CHAT_BOT = 'chatBot',
  WHATS_NEW = 'whatsNew',
  SETTINGS = 'settings',
  SETTINGS_USERS = 'settingsUsers',
  SETTINGS_API_KEYS = 'settingsApiKeys',
  SETTINGS_NOTIFICATIONS = 'settingsNotifications',
  SETTINGS_PAYMENT_METHODS = 'settingsPaymentMethods',
  SETTINGS_COMPANY_INFO = 'settingsCompanyInfo',
  SETTINGS_RESALE_CERTIFICATES = 'settingsResaleCertificates',
  SETTINGS_TAX_CENTER = 'settingsTaxCenter',
  E_COMMERCE_STORES = 'eCommerceStores',
  E_COMMERCE_PRODUCT_TEMPLATES = 'eCommerceProductTemplates',
  ANALYTICS = 'analytics',
  SETTINGS_CURRENCY = 'currency',
  GELATO_PLUS = 'gelatoPlus',
  DEVELOPER = 'developer',
  APPS = 'apps',
  SUBSCRIPTION_PLAN = 'subscriptionPlan',
  BRANDING = 'branding',
  BRANDING_CONFIGURE = 'brandingConfigure',
  BRANDING_DESIGNS = 'brandingDesigns',
  AUDIT_LOGS = 'auditLogs',
  MOCKUP_GALLERY = 'mockupGallery',
  MOCKUP_STUDIO = 'mockupStudio',
}
