export function scrollLayoutContentTo(x: number, y: number) {
  const nodes = document.getElementsByClassName('layout-content');

  if (!nodes || !nodes.length) {
    return;
  }

  const firstNode = nodes[0];

  firstNode.scrollTo(x, y);
}
