import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SanityHomePageCustomItem } from '@gelato-api-ui/core/sanity/sanity-home-page-content';
import { Router } from '@angular/router';
import { SanityButtonActions } from '@api-ui-app/src/app/dashboard/types/sanity-button-actions';
import { GelatoPlusModalService } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/gelato-plus-modal.service';
import { GelatoPlusFeatures } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-features';
import { GelatoPlusPopupLocations } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-popup-locations';
import { ContentNavigationType } from '@gelato-api-ui/sdk/src/lib/shared/product-previews-carousel/lib/content-navigation-type';
import SwiperCore, { EffectFade, SwiperOptions } from 'swiper';
import { BehaviorSubject } from 'rxjs';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { GelatoSubscriptionModalType } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-subscription-modal';

const LOCAL_STORAGE_KEY = 'custom-widget';

SwiperCore.use([EffectFade]);

@Component({
  selector: 'gd-custom-widget',
  templateUrl: './custom-widget.component.html',
  styleUrls: ['./custom-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomWidgetComponent implements OnInit {
  @Input() widgets: SanityHomePageCustomItem[];
  @Input() wrapperClass: string;
  @Input() isPlusActive: boolean; // Plus or Gold
  @Input() hasShopify: boolean;
  @Input() type: string;
  @Input() route: string;
  @Input() id: string;

  playVideo$ = new BehaviorSubject<string>(null);

  swiperConfig: SwiperOptions = {
    threshold: 10,
    spaceBetween: 2,
    keyboard: true,
    navigation: true,
    pagination: { clickable: true },
    effect: 'fade',
  };

  pointsNavigationOption: ContentNavigationType = ContentNavigationType.PaginationPoints;
  isDismissed = false;
  widgetShown: string[] = [];

  constructor(private readonly router: Router, private readonly plusModalService: GelatoPlusModalService) {}

  ngOnInit() {
    this.isDismissed = Boolean(localStorage.getItem(this.localStorageKey));
  }

  get isVisible() {
    return this.widgets.some(w => this.isCustomWidgetShown(w));
  }

  get localStorageKey() {
    return `${LOCAL_STORAGE_KEY}_${this.id}`;
  }

  isCustomWidgetShown(widget: SanityHomePageCustomItem) {
    if (this.isPlusActive === null) {
      return false;
    }

    const showForAllCustomer = widget.visible_for_all_customers;
    const showForNonPlusCustomer = widget.visible_for_non_plus_customers && !this.isPlusActive;
    const showForPlusCustomer = widget.visible_for_plus_customers && this.isPlusActive;
    const showWhenUserHasShopify = widget.visible_when_has_shopify_store && this.hasShopify;
    const shown = showForAllCustomer || showForNonPlusCustomer || showForPlusCustomer || showWhenUserHasShopify;

    if (shown) {
      this.sendWidgetShownAnalyticEvent(widget);
    }

    return shown;
  }

  onDismiss() {
    this.isDismissed = true;
    localStorage.setItem(this.localStorageKey, '1');
    this.sendAnalyticEvent('Dashboard Banner Dismissed', this.widgets[0]);
  }

  onPrimaryButtonClick(widgetIndex) {
    const widget = this.widgets[widgetIndex];
    this.sendAnalyticEvent('Dashboard Banner Clicked', widget);
    if (widget.primary_button_action) {
      this.runAction(widget.primary_button_action);
    } else {
      this.goTo(widget.primary_button_route);
    }
  }

  onSecondaryButtonClick(widgetIndex) {
    const widget = this.widgets[widgetIndex];
    this.sendAnalyticEvent('Dashboard Banner Clicked', widget, 'secondary');
    if (widget.secondary_button_action) {
      this.runAction(widget.secondary_button_action);
    } else {
      this.goTo(widget.secondary_button_route);
    }
  }

  playVideo(id: string) {
    this.playVideo$.next(id);
  }

  private goTo(route: string) {
    if (/^https?:\/\//.test(route)) {
      window.open(route, '_blank');
    } else {
      this.router.navigate([route]);
    }
  }

  private runAction(action: SanityButtonActions) {
    switch (action) {
      case SanityButtonActions.openGelatoPlusModal:
        this.plusModalService.open(
          GelatoPlusFeatures.homeScreenCard,
          GelatoPlusPopupLocations.homeScreen,
          GelatoSubscriptionModalType.GELATO_PLUS,
        );
        break;
      default:
        break;
    }
  }

  private onSlideChange(swiper: any) {
    logEvent('homepageWidgetCarouselSlideChange', { index: swiper?.activeIndex });
  }

  private sendWidgetShownAnalyticEvent(widget: SanityHomePageCustomItem) {
    const key = JSON.stringify(widget);

    if (!this.widgetShown.includes(key)) {
      this.widgetShown.push(key);
      this.sendAnalyticEvent('Dashboard Banner Viewed', widget);
    }
  }

  private sendAnalyticEvent(
    eventType: string,
    widget: SanityHomePageCustomItem,
    cta: 'primary' | 'secondary' = 'primary',
  ) {
    const bannerCTA = cta === 'primary' ? widget.primary_button_text : widget.secondary_button_text;
    const bannerCTALink =
      cta === 'primary'
        ? widget.primary_button_action || widget.primary_button_route
        : widget.secondary_button_action || widget.secondary_button_route;

    const params = {
      bannerId: this.id,
      bannerType: this.type,
      bannerPlacement: 'top',
      bannerPath: this.route,
      bannerTitle: widget.title,
      bannerBody: widget.body_text,
      bannerCTA,
      bannerCTALink,
      bannerImage: widget.videoId ? `https://www.youtube.com/watch?v=${widget.videoId}` : widget.image,
      time: new Date().getTime(),
    };
    logEvent(eventType, params);
  }
}
