import * as R from 'ramda';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { State } from '../e-commerce-stores.reducer';

export const findECommerceStoreInState = (state: State, id: string): EStore => {
  if (!state || !id) {
    return null;
  }

  if (state.store && state.store.payload && state.store.payload.id === id) {
    return { ...state.store.payload };
  }

  if (state.storesList && state.storesList.payload && state.storesList.payload.stores) {
    return {
      ...R.find(R.propEq('id', id))(state.storesList.payload.stores),
    };
  }

  return null;
};
