import { ClientType } from './client-type.enum';
import { ClientStatus } from './client-status.enum';

export class ClientSearchRequest {
  constructor(
    public ids: string[] = [],
    public name: string = '',
    public description: string = '',
    public types: ClientType[] = [],
    public statuses: ClientStatus[] = [],
    public limit: number = null,
    public offset: number = 0,
  ) {}
}
