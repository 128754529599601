<ng-container [ngSwitch]="step$ | async">
  <ng-container *ngSwitchCase="'stores'">
    <gd-stores-list-modal-content
      [stores]="storesList$ | async"
      [isLoading]="storesListLoading$ | async"
      [title]="title"
      [subtitle]="subTitle"
      (storeSelect)="selectStore($event)"
      (closeModal)="cancel()"
    >
    </gd-stores-list-modal-content>
  </ng-container>
  <ng-container *ngSwitchCase="'products'">
    <gd-store-products-list-modal-content
      [selectedStore]="selectedStore$ | async"
      [productsTree]="productsTree$ | async"
      [isLoading]="productsTreeLoading$ | async"
      [isSelectVariants]="selectVariantsEnabled"
      [productSearchQuery]="productSearchQuery$ | async"
      [delayedProductSearchQuery]="delayedProductSearchQuery$ | async"
      [checkedProductVariantIds]="checkedProductVariantIds$ | async"
      [checkedProducts]="checkedProducts$ | async"
      [multipleSelectionEnabled]="multipleSelectionEnabled"
      [maxCheckedItemsCount]="maxCheckedProductVariantsCount"
      [splashMessageId]="splashMessageId"
      (backButtonClick)="showStoreSelection()"
      (closeModal)="cancel()"
      (productSearchQueryChange)="onProductSearchQueryChange($event)"
      (checkedProductVariantIdsChange)="onCheckedProductVariantIdsChange($event)"
      (checkedProductsChange)="onCheckedProductsChange($event)"
      (chooseProducts)="submit()"
      (productsListScrollDown)="loadMoreStoreProducts()"
    >
    </gd-store-products-list-modal-content>
  </ng-container>
</ng-container>
