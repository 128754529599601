import * as actions from '@api-ui-app/src/app/ngrx/discounts.actions';
import { Promotion } from '@api-ui-app/src/app/shared/voucher/voucher';
import * as R from 'ramda';

export const discountsFeatureKey = 'discounts';

export interface State {
  promotions: Promotion[];
  discount: Promotion;
  isLoading: boolean;
}

export const initialState: State = {
  promotions: [],
  discount: null,
  isLoading: false,
};

export function reducer(state = initialState, action: actions.DiscountsActions): State {
  switch (action.type) {
    case actions.applyPromotion.type:
      return {
        ...state,
        isLoading: true,
      };
    case actions.loadPromotions.type:
      return {
        ...state,
        isLoading: true,
      };
    case actions.setLoading.type:
      return {
        ...state,
        isLoading: true,
      };
    case actions.loadPromotionsSuccess.type:
      const storePromotions = state.promotions;
      const serverPromotions = action.payload;
      const arraysAreEqual = R.equals(
        storePromotions.map(promotions => promotions.id),
        serverPromotions.map(promotions => promotions.id),
      );

      return arraysAreEqual
        ? { ...state, isLoading: false }
        : {
            ...state,
            promotions: action.payload,
            isLoading: false,
          };
    default:
      return state;
  }
}
