import { createReducer, on } from '@ngrx/store';
import * as actions from './customer-onboarding.actions';
import { OnBoardingStepConfig } from '../types/on-boarding-step-config';
import { CustomerOrdersState } from '../types/customer-orders';
import { CustomerSegment } from '../types/customer-segment';
import { onBoardingStepConfig } from '../constants/onboarding-step-config';
import { GrowChecklistStepConfig } from '../types/grow-checklist';
import { CreatedProductsState } from '../types/created-products';
import { starterGrowChecklist, switcherGrowChecklist } from '../constants/grow-checklist-steps';

export interface State {
  onBoardingSteps: OnBoardingStepConfig[];
  growCheckList: { starter: GrowChecklistStepConfig[]; switcher: GrowChecklistStepConfig[] };
  createdProducts: CreatedProductsState;
  customerMadeOrders: CustomerOrdersState;
  customerOrdersCount: number;
  customerSegment: CustomerSegment;
}

export const initialState: State = {
  onBoardingSteps: onBoardingStepConfig,
  growCheckList: {
    starter: starterGrowChecklist,
    switcher: switcherGrowChecklist,
  },
  createdProducts: {
    isLoading: null,
    products: [],
  },
  customerMadeOrders: {
    isLoading: null,
    orders: [],
  },
  customerOrdersCount: null,
  customerSegment: null,
};

export const reducer = createReducer(
  initialState,
  on(actions.initiateState, () => initialState),
  on(actions.loadCreatedProducts, state => ({
    ...state,
    createdProducts: {
      ...state.createdProducts,
      isLoading: true,
    },
  })),
  on(actions.loadCreatedProductsSuccess, (state, { products }) => ({
    ...state,
    createdProducts: {
      isLoading: false,
      products,
    },
  })),
  on(actions.loadCustomerMadeOrder, state => ({
    ...state,
    customerMadeOrders: {
      ...state.customerMadeOrders,
      isLoading: true,
    },
  })),
  on(actions.loadCustomerMadeOrderSuccess, (state, { orders }) => ({
    ...state,
    customerMadeOrders: {
      isLoading: false,
      orders,
    },
  })),
  on(actions.loadCustomerOrdersCountSuccess, (state, { ordersCount }) => ({
    ...state,
    customerOrdersCount: ordersCount,
  })),
  on(actions.setCustomerSegment, (state, { segment }) => {
    return {
      ...state,
      customerSegment: segment,
    };
  }),
  on(actions.setGrowChecklistStepState, (state, { stepId, stepState }) => {
    return {
      ...state,
      growCheckList: {
        ...state.growCheckList,
        [state.customerSegment]: state.growCheckList[state.customerSegment].map(step => {
          if (step.id === stepId) {
            return { ...step, state: stepState };
          } else {
            return step;
          }
        }),
      },
    };
  }),
);
