import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountActivationSteps } from '../../ngrx/account-activation-steps.reducer';

@Component({
  selector: 'gd-order-placement-requirements',
  templateUrl: './order-placement-requirements.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPlacementRequirementsComponent {
  @Input() activationSteps: AccountActivationSteps = null;
  @Output() addCreditCardClick = new EventEmitter();
  @Output() addCompanyDetailsClick = new EventEmitter();

  onAddCreditCardClick() {
    this.addCreditCardClick.emit();
  }

  onAddCompanyDetailsClick() {
    this.addCompanyDetailsClick.emit();
  }
}
