import { DesignPage, DesignPageMediaItem, DesignSpread, DesignStructure } from '../design-structure';

export const hasAppliedDesignTemplate = (designStructure: DesignStructure): boolean => {
  if (!designStructure || !designStructure.design_template_id) {
    return false;
  }

  let mediaItemsFromTemplateCount = 0;

  designStructure.spreads.forEach((loopSpread: DesignSpread) => {
    loopSpread.pages.forEach((loopPage: DesignPage) => {
      if (!loopPage.groups || !loopPage.groups.media) {
        return;
      }

      loopPage.groups.media.forEach((loopMediaItem: DesignPageMediaItem) => {
        if (loopMediaItem.dataFromTemplate) {
          mediaItemsFromTemplateCount++;
        }
      });
    });
  });

  return mediaItemsFromTemplateCount > 0;
};
