import { ClientType } from './client-type.enum';
import { BillingType } from './billing-type.enum';
import { ShippingPriceType } from './shipping-price-type.enum';

export class ClientCreateRequest {
  constructor(
    public name: string,
    public description: string,
    public type: ClientType,
    public preferredCurrency: string,
    public billingType: BillingType,
    public shippingPriceType: ShippingPriceType,
    public shippingPriceEconomyCatalogUid: string,
    public shippingPriceExpressCatalogUid: string,
    public contactEmail: string,
    public billingEmail: string,
    public supportEmail: string,
  ) {}
}
