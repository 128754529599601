import { Inject, Injectable, Optional } from '@angular/core';
import { API_MODULE_CONFIG } from '@gelato-api-ui/sdk/src/lib/api/api.tokens';
import { ApiModuleConfig } from '@gelato-api-ui/sdk/src/lib/api/api-module-config';
import { AjaxRequest } from 'rxjs/ajax';
import { Observable } from 'rxjs';
import { ApiResponse } from '@gelato-api-ui/core/api/api-response.interface';
import { HttpHeaders } from '@gelato-api-ui/core/api/http-headers';
import { caller } from '@gelato-api-ui/core/api/caller';
import { callerBlob } from '@gelato-api-ui/core/api/caller-blob';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { callerText } from '../caller-text';

@Injectable({ providedIn: 'root' })
export class ApiRequestService {
  public get: <T>(url: string, options?: AjaxRequest) => Observable<ApiResponse<T>>;
  public getText: <T>(url: string, options?: AjaxRequest) => Observable<string>;
  public getBlob: <T>(url: string, options?: AjaxRequest) => Observable<Blob>;
  public post: <T>(url: string, options?: AjaxRequest) => Observable<ApiResponse<T>>;
  public put: <T>(url: string, options?: AjaxRequest) => Observable<ApiResponse<T>>;
  public patch: <T>(url: string, options?: AjaxRequest) => Observable<ApiResponse<T>>;
  public delete: <T>(url: string, options?: AjaxRequest) => Observable<ApiResponse<T>>;

  private config: ApiModuleConfig;

  constructor(
    @Inject(API_MODULE_CONFIG)
    @Optional()
    config: ApiModuleConfig,
    private authService: AuthService,
  ) {
    this.config = {
      host: '',
      ...config,
    };

    this.get = caller(this.config.host, 'GET', () => this.headers$);
    this.getText = callerText(this.config.host, 'GET', () => this.headers$);
    this.getBlob = callerBlob(this.config.host, 'GET', () => this.headers$);
    this.post = caller(this.config.host, 'POST', () => this.headers$);
    this.put = caller(this.config.host, 'PUT', () => this.headers$);
    this.patch = caller(this.config.host, 'PATCH', () => this.headers$);
    this.delete = caller(this.config.host, 'DELETE', () => this.headers$);
  }

  get headers$(): Observable<HttpHeaders> {
    return this.authService.getHttpHeaders();
  }
}
