import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { RefundOrderApiService } from '../orders/services/refund-order-api.service';
import { OrderRefundSearchRequest } from '../orders/lib/order-refund-search-request';
import { OrderRefundSearchResponse } from '../orders/lib/order-refund-search-response';
import * as actions from './order-refund.actions';
import { getState } from './order-refund.selector';

@Injectable()
export class OrderRefundEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private authService: AuthService,
    private refundOrderApiService: RefundOrderApiService,
  ) {}

  loadOrderRefunds$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadOrderRefunds>(actions.OrderRefundActionTypes.LoadOrderRefunds),
        withLatestFrom(this.store$.select(getState), this.authService.hasReadPermission('order', 'order')),
        switchMap(([action, state, hasPermission]) => {
          const { orderId, forced } = action;
          const searchRequest = new OrderRefundSearchRequest([], null, orderId, 0, 100);

          const isDataRelevant = R.equals(searchRequest, state.request) && state.payload;

          let observable: Observable<OrderRefundSearchResponse> = this.refundOrderApiService.search(searchRequest);

          if (!forced && isDataRelevant) {
            observable = of({ ...state.payload });
          }

          if (!hasPermission) {
            observable = of({
              data: [],
              pagination: {
                total: 0,
                offset: 0,
              },
            });
          }

          this.store$.dispatch(
            new actions.SetState({
              isLoading: true,
              request: searchRequest,
              payload: isDataRelevant ? state.payload : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<OrderRefundSearchResponse> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: OrderRefundSearchResponse) => {
              this.store$.dispatch(
                new actions.SetState({
                  isLoading: false,
                  request: searchRequest,
                  payload: response,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
