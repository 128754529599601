import { Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { AjaxRequest } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { GelatoApiDesignRendersLoader, GelatoApiDesignRendersLoaderConfig } from '../gelato-api-design-renders-loader';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { PreflightGraphQlService } from '@gelato-api-ui/core/preflight/services/preflight-graph-ql.service';
import * as queryString from 'qs';
import { environment } from '@api-ui-app/src/environments/environment';
import { ApiRequestPublicService } from '@gelato-api-ui/core/api/services/api-request-public.service';
import { DesignRendersLoadingSettings } from '@gelato-api-ui/core/design-renders-loader/design-renders-loading-settings';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';

export const IS_EMBEDDABLE_EDITOR = new InjectionToken('IS_EMBEDDABLE_EDITOR');

@Injectable({ providedIn: 'root' })
export class DesignRendersLoaderService {
  defaultConfig: GelatoApiDesignRendersLoaderConfig = {
    preflightUrl: environment.baseUrls.preflight,
  };

  instance: GelatoApiDesignRendersLoader = null;
  apiRequestService: ApiRequestPublicService | ApiRequestService;

  constructor(
    private injector: Injector,
    private readonly preflightGraphQlService: PreflightGraphQlService,
    @Inject(IS_EMBEDDABLE_EDITOR) private readonly isEmbeddableEditor: boolean,
  ) {
    this.apiRequestService = isEmbeddableEditor
      ? injector.get<ApiRequestPublicService>(ApiRequestPublicService)
      : injector.get<ApiRequestService>(ApiRequestService);
  }

  initInstance(config: GelatoApiDesignRendersLoaderConfig = this.defaultConfig) {
    this.instance = new GelatoApiDesignRendersLoader(
      config,
      (url: string, options: AjaxRequest = {}) => this.requestProxy(url, options, 'GET'),
      (url: string, options: AjaxRequest = {}) => this.requestProxy(url, options, 'POST'),
      this.isEmbeddableEditor,
    );
  }

  requestProxy(url: string, options: AjaxRequest, method: 'GET' | 'POST'): Promise<any> {
    const parsedQueryString = queryString.parse(url.substr(url.indexOf('?')), { ignoreQueryPrefix: true });
    const requestBody = options.body;

    if (url.indexOf('/product/preview/types') !== -1) {
      return this.preflightGraphQlService
        .fetchProductPreviewTypes(parsedQueryString.product_uid as string)
        .pipe(map(response => ({ data: response })))
        .toPromise();
    }

    if (url.indexOf('/product/preview/scenes') !== -1) {
      return this.preflightGraphQlService
        .fetchProductPreviewScenes(parsedQueryString.product_uid as string)
        .pipe(map(response => ({ data: response })))
        .toPromise();
    }

    if (url.indexOf('/product/image_from_json') !== -1) {
      return this.preflightGraphQlService
        .getImageFromJson(
          requestBody as DesignStructure,
          parsedQueryString.page_nr ? Number(parsedQueryString.page_nr) : null,
          parsedQueryString.width ? Number(parsedQueryString.width) : null,
          parsedQueryString.height ? Number(parsedQueryString.height) : null,
          parsedQueryString.format as PreviewFileType,
          parsedQueryString.remove_samples ? Boolean(Number(parsedQueryString.remove_samples)) : true,
        )
        .pipe(map(response => ({ data: response })))
        .toPromise();
    }

    if (method === 'POST') {
      return this.apiRequestService.post(url, options).toPromise();
    }

    return this.apiRequestService.get(url, options).toPromise();
  }

  getAvailablePreviews = (productUid: string) => {
    return this.instance.getAvailablePreviews(productUid);
  };

  loadDesignRenders = async (
    designData: DesignStructure,
    settings: DesignRendersLoadingSettings,
    removeSamples: boolean,
    needAllSpreads: boolean,
    isShutterstockFlatPreviewNeeded: boolean,
  ) => {
    return this.instance.loadDesignRenders(
      designData,
      settings.takeFirstSpread,
      settings.noFlatPreview,
      removeSamples,
      needAllSpreads,
      isShutterstockFlatPreviewNeeded,
    );
  };
}
