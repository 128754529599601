import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import * as gql from 'gql-query-builder';
import { ProductDetails } from '@gelato-api-ui/core/product-details/product-details';

@Injectable({
  providedIn: 'root',
})
export class ProductDetailsGraphQlService {
  constructor(private readonly graphQlRequestService: GraphQlRequestService) {}

  get(productUid: string): Observable<ProductDetails> {
    const query = this.getQuery(productUid);

    return this.graphQlRequestService
      .query(query, 'productDetails')
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.productDetails));
  }

  private getQuery(productUid: string) {
    return gql.query({
      operation: 'productDetails',
      variables: {
        productUid: { type: 'String!', value: productUid },
      },
    });
  }
}
