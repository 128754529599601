import { SanityProductControl, SanityProductControlKey } from '../sanity-product-control';
import { SanityProductControlOption } from '../sanity-product-control-option';
import { SanityProductVariation } from '../sanity-product-variation';
import { findMatchingProductVariation } from './findMatchingProductVariation';

export const getRelevantProductControls = (
  productControls: SanityProductControl[],
  productVariations: SanityProductVariation[],
  currentProductVariation: SanityProductVariation,
): SanityProductControl[] => {
  if (!productControls?.length || !productVariations?.length) {
    return [];
  }

  return productControls.map((productControl: SanityProductControl) => {
    if (productControl.key === SanityProductControlKey.formatSize) {
      return productControl;
    }

    const relevantOptions = (productControl.options || []).filter((option: SanityProductControlOption): boolean => {
      if (productControl.key === SanityProductControlKey.phoneBrand) {
        return Boolean(
          productVariations.find(
            (loopProductVariation: SanityProductVariation): boolean => loopProductVariation.phonebrand === option.value,
          ),
        );
      }

      return Boolean(
        findMatchingProductVariation(productVariations, currentProductVariation, productControl.key, option.value),
      );
    });

    return {
      ...productControl,
      options: relevantOptions,
    };
  });
};
