import { Pipe, PipeTransform } from '@angular/core';
import { CountryListService } from '@gelato-api-ui/sdk/src/lib/shared/country-selector/country-list.service';

@Pipe({
  name: 'countryName',
})
export class CountryNamePipe implements PipeTransform {
  constructor(private countryListService: CountryListService) {}

  transform(value: any, args?: any): any {
    return this.countryListService.getCountryName(value);
  }
}
