import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({ name: 'jpPrefectureName' })
export class JpPrefectureNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(stateCode: string): Observable<string> {
    const translationKey = `txt_jp_prefecture_${stateCode.toLowerCase()}`;

    return this.translateService.get(translationKey).pipe(
      map((name: string): string => {
        if (translationKey === name) {
          return stateCode;
        }

        return name;
      }),
    );
  }
}
