import { ActionReducer, combineReducers } from '@ngrx/store';

import * as fromCore from './sdk.actions';

export type All = fromCore.All;

export interface CoreState {
  core: {};
}

const sdkReducer: ActionReducer<{}> = combineReducers({});

export default sdkReducer;
