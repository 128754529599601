import { FlatShipmentPricesCollection } from '../product-catalogue/lib/flat-shipment-prices-collection';
import * as actions from './flat-shipment-prices.actions';

export interface State {
  isLoading: boolean;
  productUid: string;
  currency: string;
  payload: FlatShipmentPricesCollection;
}

export const initialState: State = {
  isLoading: false,
  productUid: null,
  currency: null,
  payload: null,
};

export function reducer(state = initialState, action: actions.FlatShipmentPricesActions): State {
  switch (action.type) {
    case actions.FlatShipmentPricesActionTypes.ResetState:
      return {
        ...initialState,
      };
    case actions.FlatShipmentPricesActionTypes.SetState:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
