import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmModalService } from '@gelato-api-ui/sdk/src/lib/shared/services/confirm-modal.service';

@Injectable()
export class OrderDeletionConfirmationModalService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly confirmModalService: ConfirmModalService,
  ) {}

  open(orderReferenceId: string) {
    return this.confirmModalService.open({
      title: this.translateService.instant('txt_order_deletion_confirmation_title', { orderReferenceId }),
      body: this.translateService.instant('txt_order_deletion_confirmation_text', { orderReferenceId }),
      approveButtonText: this.translateService.instant('txt_delete_order'),
      denyButtonText: this.translateService.instant('txt_cancel'),
    });
  }
}
