<ng-select
  [ngModel]="currentValue"
  (ngModelChange)="onSelect($event)"
  [items]="storeList$ | async"
  [bindValue]="'id'"
  [bindLabel]="'name'"
  [placeholder]="'txt_e-commerce_store_placeholder' | translate"
  [clearable]="false"
  [searchable]="true"
  [virtualScroll]="true"
>
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    {{ item.key ? (item.key | translate) : item.name }}
  </ng-template>
</ng-select>
