<div
  #origin
  *ngIf="(shortName$ | async) && !userInitialsBackgroundLoading"
  id="user-info"
  class="ui dropdown user-wrapper"
  [ngClass]="userWrapperClass"
  (click)="onOpenUserInformation(rootListContent, origin)"
>
  {{ shortName$ | async }}
</div>

<div *ngIf="userInitialsBackgroundLoading" class="ghost-active-fluid">&nbsp;</div>

<ng-template #rootListContent>
  <div class="menu-content" [class.mobile]="isMobile">
    <div class="deck-header">
      <div class="client-info">
        <div class="ui dropdown user-wrapper" [ngClass]="userWrapperClass">
          {{ shortName$ | async }}
        </div>
        <div class="user-details">
          <span class="user-name">{{ userName$ | async }}</span>
          <span class="user-email">{{ userEmail$ | async }}</span>
          <span class="company-name">{{ companyName$ | async }}</span>
        </div>
      </div>
    </div>
    <div class="menu-items" [class.mobile]="isMobile">
      <div class="menu-item" (click)="onCloseSwitcher()" [routerLink]="['/users/edit', userId$ | async]">
        {{ 'txt_your_settings' | translate }}
      </div>
      <div class="menu-item">
        <gd-auth-button-mobile></gd-auth-button-mobile>
      </div>
    </div>
  </div>
</ng-template>
