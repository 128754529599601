<div *ngIf="items?.length" class="ui segment box-shadow-new faq-section">
  <gd-segment-header
    class="faq-segment-header"
    [text]="'txt_faq_section_title_short' | translate"
    iconUrl="/assets/icons/help.svg"
    [showDivider]="true"
  >
    <a
      href="{{ helpCenterArticles.helpCenter }}"
      class="faq-help-center-link"
      target="_blank"
      rel="noreferrer noopener"
    >
      {{ 'txt_help_center' | translate }}
      <img width="24" height="24" src="/assets/product-page/open.svg" alt="" />
    </a>
  </gd-segment-header>

  <div class="product-faq-articles-container">
    <div class="product-faq-link-wrapper" *ngFor="let faqArticle of items">
      <a class="product-faq-article" [href]="faqArticle.url" target="_blank" rel="noreferrer noopener">
        <span>{{ faqArticle.title | translate }}</span>
        <img width="12" height="12" src="/assets/dashboard/chevron-right-black.svg" alt="" />
      </a>
    </div>
  </div>
</div>
