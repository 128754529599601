export enum ProductExpansionCategoryUid {
  POSTER = 'poster',
  FRAMED_POSTER_BLACK_WOOD_FRAME = 'framed_poster_black_wood_frame',
  FRAMED_POSTER_WHITE_WOOD_FRAME = 'framed_poster_white_wood_frame',
  FRAMED_POSTER_NATURAL_WOOD_FRAME = 'framed_poster_natural_wood_frame',
  FRAMED_POSTER_DARK_WOOD_FRAME = 'framed_poster_dark_wood_frame',
  FRAMED_POSTER_BLACK_METAL_FRAME = 'framed_poster_black_metal_frame',
  HANGING_POSTER_BLACK_HANGER = 'hanging_poster_black_hanger',
  HANGING_POSTER_WHITE_HANGER = 'hanging_poster_white_hanger',
  HANGING_POSTER_NATURAL_WOOD_HANGER = 'hanging_poster_natural_wood_hanger',
  HANGING_POSTER_DARK_WOOD_HANGER = 'hanging_poster_dark_wood_hanger',
  METALLIC_PRINT = 'metallic_print',
  ACRYLIC_PRINT = 'acrylic_print',
  FOAM_PRINT = 'foam_print',
  WOOD_PRINT = 'wood_print',
  CANVAS = 'canvas',
}
