import { Injectable } from '@angular/core';
import { DesignGraphQlService } from '@gelato-api-ui/core/designs/services/design-graph-ql.service';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { Observable } from 'rxjs';
import { hasAppliedDesignTemplate } from '@gelato-api-ui/core/designs/helpers/hasAppliedDesignTemplate';
import { getUsedAssets } from '@gelato-api-ui/core/designs/helpers/getUsedAssets';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import { PaidAssetProviderUids } from '@gelato-api-ui/core/designs/paid-asset-provider-uids';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';

@Injectable({ providedIn: 'root' })
export class DesignCostService {
  constructor(private readonly designGraphQlService: DesignGraphQlService) {}

  getDesignCost(
    clientId: string,
    designStructure: DesignStructure,
    assetsCollection: AssetsCollection,
    productUid: string,
    pageCount: number,
    userCurrency: string,
    storeCurrency: string,
    country: string,
  ): Observable<number> {
    return this.designGraphQlService.getDesignCost(
      clientId,
      this.getUsedPaidAssetsIds(designStructure, assetsCollection),
      this.hasDesignTemplate(designStructure),
      productUid,
      pageCount,
      userCurrency,
      storeCurrency,
      country,
    );
  }

  getDesignContentCost(
    clientId: string,
    designStructure: DesignStructure,
    assetsCollection: AssetsCollection,
    productUid: string,
    currency: string,
    productCost: number,
  ): Observable<number> {
    return this.designGraphQlService.getDesignContentCost(
      clientId,
      this.getUsedPaidAssetsIds(designStructure, assetsCollection),
      this.hasDesignTemplate(designStructure),
      productUid,
      currency,
      productCost,
    );
  }

  getDesignAssetPrices(
    clientId: string,
    designStructure: DesignStructure,
    assetsCollection: AssetsCollection,
    productTypeUid: ProductTypeUid,
    currency: string,
    productCost: number,
  ): Observable<number[]> {
    return this.designGraphQlService.getDesignAssetPrices(
      clientId,
      productTypeUid,
      this.getUsedPaidAssetsIds(designStructure, assetsCollection),
      currency,
      productCost,
    );
  }

  private getUsedPaidAssetsIds(designStructure: DesignStructure, assetsCollection: AssetsCollection): string[] {
    if (!designStructure || !assetsCollection) {
      return [];
    }

    const usedAssets = getUsedAssets(assetsCollection, designStructure, PaidAssetProviderUids);

    return (usedAssets || [])
      .map((loopAsset: Asset): string => loopAsset.id)
      .sort((a: string, b: string): number => a.localeCompare(b));
  }

  private hasDesignTemplate(designStructure: DesignStructure): boolean {
    if (!designStructure) {
      return false;
    }

    return hasAppliedDesignTemplate(designStructure);
  }
}
