<input
  *ngIf="!isCountryWithStates(countryIsoCode); else stateSelector"
  type="text"
  name="state"
  [placeholder]="placeholderTxtKey | translate"
  [disabled]="disabled"
  [ngModel]="value"
  (ngModelChange)="onStateChange($event)"
/>

<ng-template #stateSelector>
  <gc-state-selector
    [selectedCountry]="countryIsoCode"
    [disabled]="disabled"
    [isSearchable]="isSearchable"
    [value]="value"
    (valueChange)="onStateChange($event)"
  ></gc-state-selector>
</ng-template>
