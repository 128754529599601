export enum DesignPageMediaItemType {
  TEXT = 'text',
  IMAGE = 'image',
  LINE = 'line',
  RECTANGLE = 'rectangle',
}

export enum DesignPatternScaleBaseType {
  Element = 'element',
  Width = 'width',
  Height = 'height',
}
