import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getPricePlanNames } from '../../ngrx/price-plan-selection-list.reducer';

@Pipe({
  name: 'pricePlanName',
})
export class PricePlanNamePipe implements PipeTransform {
  pricePlanNames$ = this.store.pipe(select(getPricePlanNames));

  constructor(private store: Store<AppState>) {}

  transform(value: any, args?: any): any {
    return this.pricePlanNames$.pipe(map(pricePlanNames => pricePlanNames[value] || ''));
  }
}
