import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountriesMapComponent } from './countries-map/countries-map.component';

@NgModule({
  declarations: [CountriesMapComponent],
  exports: [CountriesMapComponent],
  imports: [CommonModule],
})
export class CountriesMapModule {}
