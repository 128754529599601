import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Pipe({ name: 'brazilianStateName' })
export class BrazilianStateNamePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(stateCode: string, showStateCode?: boolean): Observable<string> {
    const translationKey = `txt_brazilian_state_${stateCode || 'ISENTO'}`;

    return this.translateService.get(translationKey).pipe(
      map((name: string): string => {
        if (translationKey === name) {
          return stateCode;
        }

        if (showStateCode && stateCode && !/ISENTO/i.test(stateCode)) {
          return `${stateCode} - ${name}`;
        }

        return name;
      }),
    );
  }
}
