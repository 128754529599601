import { createAction, props } from '@ngrx/store';
import { OrderDetails } from '../../orders/lib/order-details';
import { ErrorData } from '../../shared/lib/error-data';
import { OrderAddress } from '../../orders/lib/order-address';
import { OrderProductPreview } from '../../orders/lib/order-product-preview';
import { PaymentMethodType } from '../../payment-methods/wallets/lib/payment-method.type';
import { TypedAction } from '@ngrx/store/src/models';
import { OrderItem } from '../../orders/lib/order-item';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { OrderDetailsExtended } from '@api-ui-app/src/app/orders/lib/order-details-extended';
import { OrderCreateItem, SaveOrderRequest } from '@api-ui-app/src/app/checkout/types/save-order-request';
import { OrderInformation } from '@api-ui-app/src/app/checkout/types/order-information';
import { CheckoutQuoteResponse } from '@api-ui-app/src/app/checkout/types/checkout-quote-response';

export const initiateState = createAction('[Checkout] Initiate State');

export const loadCheckoutOrder = createAction(
  '[Checkout] Load Checkout Order',
  props<{ orderId: string; allowNotSupportedStatus?: boolean }>(),
);

export const loadCheckoutOrderSuccess = createAction(
  '[Checkout] Load Checkout Order Success',
  props<{ orderDetails: OrderDetailsExtended; allowNotSupportedStatus?: boolean }>(),
);

export const loadCheckoutOrderFailure = createAction(
  '[Checkout] Load Checkout Order Failure',
  props<{ res: ErrorData }>(),
);

export const updateOrderFailure = createAction(
  '[Checkout] Update Order Failure',
  props<{ res: ErrorData; orderId: string }>(),
);

export const saveOrder = createAction(
  '[Checkout] Save Order',
  props<{ order: SaveOrderRequest; silentUpdate?: boolean; isFreeReorderAllowed?: boolean }>(),
);

export const updateOrder = createAction(
  '[Checkout] Update Order',
  props<{
    orderMapFn: (order: SaveOrderRequest) => SaveOrderRequest;
    orderId: string;
    silentUpdate: boolean;
    actionBeforeStart: TypedAction<any>;
    actionAfterFinish: TypedAction<any>;
  }>(),
);

export const updateOrderStart = createAction('[Checkout] Update Order Start');
export const updateOrderFinish = createAction('[Checkout] Update Order Finish');

export const saveOrderWithQuote = createAction('[Checkout] Save Order', props<{ order: SaveOrderRequest }>());

export const saveOrderSuccess = createAction('[Checkout] Save Order Success', props<{ orderDetails: OrderDetails }>());
export const createOrderSuccess = createAction(
  '[Checkout] Create Order Success',
  props<{ orderDetails: OrderDetails; isFreeReorderAllowed?: boolean }>(),
);

export const saveOrderFailure = createAction('[Checkout] Save Order Failure', props<{ res: ErrorData }>());

export const saveOrderAddress = createAction('[Checkout] Save Order Address', props<{ order: SaveOrderRequest }>());

export const saveOrderAddressSuccess = createAction(
  '[Checkout] Save Order Address Success',
  props<{ orderDetails: OrderDetails }>(),
);

export const savePaymentMethod = createAction('[Checkout] Save Payment Method');
export const savePaymentMethodSuccess = createAction(
  '[Checkout] Save Payment Method Success',
  props<{ paymentMethodId: string }>(),
);
export const savePaymentMethodFail = createAction('[Checkout] Save Payment Method Fail', props<{ err: any }>());

export const placeOrderIntent = createAction('[Checkout] Place Order Intent', props<{ order: SaveOrderRequest }>());

export const placeOrder = createAction('[Checkout] Place Order', props<{ order: SaveOrderRequest }>());

export const placeOrderSuccess = createAction(
  '[Checkout] Place Order Success',
  props<{ orderDetails: OrderDetailsExtended }>(),
);

export const placeOrderFailure = createAction('[Checkout] Place Order Failure', props<{ res: ErrorData }>());

export const draftShippingAddress = createAction(
  '[Checkout] Draft shipping address',
  props<{ address: OrderAddress }>(),
);

export const draftCompanyDetails = createAction(
  '[Checkout] Draft Company Details',
  props<{ details: CompanyDetails }>(),
);

export const draftOrderInfo = createAction(
  '[Checkout] Draft order information',
  props<{ orderInfo: OrderInformation }>(),
);

export const validateShippingAddress = createAction('[Checkout] Validate shipping address');

export const validateShippingAddressSuccess = createAction('[Checkout] Validate shipping address success');

export const validateShippingAddressError = createAction(
  '[Checkout] Validate shipping address error',
  props<{ addressErrorData: ErrorData }>(),
);

export const hideErrorMessage = createAction('[Checkout] Hide error message');

export const getQuote = createAction('[Checkout] Get Quote');

export const getQuoteSuccess = createAction('[Checkout] Get Quote Success', props<{ quote: CheckoutQuoteResponse }>());

export const getQuoteError = createAction('[Checkout] Get Quote Error', props<{ res: ErrorData }>());

export const clearQuote = createAction('[Checkout] Clear Quote');

export const loadItemPreviews = createAction(
  '[Checkout] Load Item Previews',
  props<{ orderId: string; itemId: string }>(),
);

export const loadItemPreviewsSuccess = createAction(
  '[Checkout] Load Item Previews Success',
  props<{ previews: OrderProductPreview[]; orderId: string; itemId: string }>(),
);

export const loadItemPreviewsFail = createAction('[Checkout] Load Item Previews Fail', props<{ res: ErrorData }>());

export const removeGeneratedItemPreviews = createAction(
  '[Checkout] Remove Generated Item Previews',
  props<{ itemId: string }>(),
);

export const updateClientInfo = createAction('[Checkout] Update Client Info', props<{ clientId: string }>());

export const updateOrderCurrency = createAction('[Checkout] Update Order Currency', props<{ currency: string }>());

export const updateRetailCurrency = createAction(
  '[Checkout] Update Retail Currency',
  props<{ currency: string; rate: number }>(),
);

export const setPaymentMethodType = createAction(
  '[Checkout] Set Payment Method Type',
  props<{ paymentMethodType: PaymentMethodType }>(),
);

export const setPaymentDetailsUid = createAction(
  '[Checkout] Set Payment Details Uid',
  props<{ paymentDetailsUid: string }>(),
);

export const itemAddStart = createAction('[Checkout] Add item start', props<{ items: OrderCreateItem[] }>());
export const itemDuplicateStart = createAction('[Checkout] Duplicate item start', props<{ item: OrderItem }>());

export const itemQuantityUpdateStart = createAction(
  '[Checkout] Item Quantity Update Start',
  props<{ itemId: string; quantity: number }>(),
);
export const itemQuantityUpdateFinish = createAction(
  '[Checkout] Item Quantity Update Finish',
  props<{ itemId: string }>(),
);

export const itemRetailPriceUpdateStart = createAction(
  '[Checkout] Item Retail Price Update Start',
  props<{ itemId: string; price: number }>(),
);

export const itemRetailPriceUpdateFinish = createAction(
  '[Checkout] Item Retail Price Update Finish',
  props<{ itemId: string }>(),
);

export const itemProductUidUpdateStart = createAction(
  '[Checkout] Item Product Uid Update Start',
  props<{ itemId: string; uid: string }>(),
);

export const itemProductUidUpdateFinish = createAction(
  '[Checkout] Item Product Uid Update Finish',
  props<{ itemId: string }>(),
);

export const shippingRetailPriceUpdateStart = createAction(
  '[Checkout] Shipping Retail Price Update Start',
  props<{ price: number }>(),
);

export const shippingMethodUpdate = createAction('[Checkout] Shipping Method Update', props<{ methodUid: string }>());

export const stateDirty = createAction('[Checkout] State Dirty');

export const itemDeleteStart = createAction('[Checkout] Item Delete Start', props<{ itemId: string }>());
export const itemDeleteFinish = createAction('[Checkout] Item Delete Finish', props<{ itemId: string }>());

export const itemDisconnectStart = createAction(
  '[Checkout] Items Disconnect Start',
  props<{ itemIds: string[]; isIgnored: boolean }>(),
);

export const productVariantDisconnectStart = createAction(
  '[Checkout] Product Variant Disconnect Start',
  props<{ productVariantId: string; isIgnored: boolean }>(),
);

export const productDisconnectStart = createAction(
  '[Checkout] Product Disconnect Start',
  props<{ productId: string; isIgnored: boolean }>(),
);

export const saveDraftBillingEntity = createAction('[Checkout] Save Draft Billing Entity');
export const saveDraftBillingFail = createAction(
  '[Checkout] Save Draft Billing Entity Fail',
  props<{ errors: ErrorData }>(),
);
export const saveDraftBillingSuccess = createAction(
  '[Checkout] Save Draft Billing Entity Success',
  props<{ details: CompanyDetails }>(),
);
export const showRetail = createAction('[Checkout] Show retail', props<{ state: boolean }>());
export const applyDiscountCode = createAction('[Checkout] Apply Discount Code', props<{ code: string }>());
export const deleteOrder = createAction(
  '[Checkout] Delete Order',
  props<{ orderId: string; orderReferenceId: string }>(),
);
export const deleteOrderFail = createAction('[Checkout] Delete Order Fail');
export const setFreeReorderAllowed = createAction('[Checkout] Set Free Reorder Allowed');
