export const ChileCommunas = [
  {
    value: 'ACHA',
    label: 'ACHAO',
  },
  {
    value: 'AISE',
    label: 'AISEN',
  },
  {
    value: 'ALGA',
    label: 'ALGARROBO',
  },
  {
    value: 'ALHU',
    label: 'ALHUE',
  },
  {
    value: 'ALBB',
    label: 'ALTO BIOBIO',
  },
  {
    value: 'ACAR',
    label: 'ALTO DEL CARMEN',
  },
  {
    value: 'AHOS',
    label: 'ALTO HOSPICIO',
  },
  {
    value: 'AJAH',
    label: 'ALTO JAHUEL',
  },
  {
    value: 'ANCU',
    label: 'ANCUD',
  },
  {
    value: 'ANDA',
    label: 'ANDACOLLO',
  },
  {
    value: 'ANGO',
    label: 'ANGOL',
  },
  {
    value: 'ANTA',
    label: 'ANTARTICA',
  },
  {
    value: 'ANTO',
    label: 'ANTOFAGASTA',
  },
  {
    value: 'ANTU',
    label: 'ANTUCO',
  },
  {
    value: 'ARAU',
    label: 'ARAUCO',
  },
  {
    value: 'ARIC',
    label: 'ARICA',
  },
  {
    value: 'ARTI',
    label: 'ARTIFICIO',
  },
  {
    value: 'BALM',
    label: 'BALMACEDA',
  },
  {
    value: 'BARR',
    label: 'BARRANCAS',
  },
  {
    value: 'BATU',
    label: 'BATUCO',
  },
  {
    value: 'BUIN',
    label: 'BUIN',
  },
  {
    value: 'BULN',
    label: 'BULNES',
  },
  {
    value: 'CABI',
    label: 'CABILDO',
  },
  {
    value: 'CAHO',
    label: 'CABO DE HORNOS',
  },
  {
    value: 'CABR',
    label: 'CABRERO',
  },
  {
    value: 'CALA',
    label: 'CALAMA',
  },
  {
    value: 'CALB',
    label: 'CALBUCO',
  },
  {
    value: 'CALD',
    label: 'CALDERA',
  },
  {
    value: 'CTAN',
    label: 'CALERA DE TANGO',
  },
  {
    value: 'CALL',
    label: 'CALLE LARGA',
  },
  {
    value: 'CAMA',
    label: 'CAMARONES',
  },
  {
    value: 'CAMI',
    label: 'CAMINA',
  },
  {
    value: 'CANL',
    label: 'CANELA',
  },
  {
    value: 'CANE',
    label: 'CANETE',
  },
  {
    value: 'CARA',
    label: 'CARAHUE',
  },
  {
    value: 'CART',
    label: 'CARTAGENA',
  },
  {
    value: 'CASA',
    label: 'CASABLANCA',
  },
  {
    value: 'CAST',
    label: 'CASTRO',
  },
  {
    value: 'CATE',
    label: 'CATEMU',
  },
  {
    value: 'CAUQ',
    label: 'CAUQUENES',
  },
  {
    value: 'LOSC',
    label: 'CERRILLOS',
  },
  {
    value: 'CNAV',
    label: 'CERRO NAVIA',
  },
  {
    value: 'CHAI',
    label: 'CHAITEN',
  },
  {
    value: 'CHAN',
    label: 'CHANARAL',
  },
  {
    value: 'CHNC',
    label: 'CHANCO',
  },
  {
    value: 'CHEP',
    label: 'CHEPICA',
  },
  {
    value: 'CHIC',
    label: 'CHICUREO',
  },
  {
    value: 'CHIG',
    label: 'CHIGUAYANTE',
  },
  {
    value: 'CHCH',
    label: 'CHILE CHICO',
  },
  {
    value: 'CHIL',
    label: 'CHILLAN',
  },
  {
    value: 'CHVJ',
    label: 'CHILLAN VIEJO',
  },
  {
    value: 'CHIM',
    label: 'CHIMBARONGO',
  },
  {
    value: 'CHLC',
    label: 'CHOL CHOL',
  },
  {
    value: 'CHOL',
    label: 'CHOLGUAN',
  },
  {
    value: 'CHON',
    label: 'CHONCHI',
  },
  {
    value: 'CISN',
    label: 'CISNES',
  },
  {
    value: 'COBQ',
    label: 'COBQUECURA',
  },
  {
    value: 'CCHM',
    label: 'COCHAMO',
  },
  {
    value: 'COCH',
    label: 'COCHRANE',
  },
  {
    value: 'CODE',
    label: 'CODEGUA',
  },
  {
    value: 'RTOM',
    label: 'CODELCO RADOMIRO TOMIC',
  },
  {
    value: 'COEL',
    label: 'COELEMU',
  },
  {
    value: 'COIC',
    label: 'COIHUECO',
  },
  {
    value: 'COIN',
    label: 'COINCO',
  },
  {
    value: 'COLB',
    label: 'COLBUN',
  },
  {
    value: 'COLC',
    label: 'COLCHANE',
  },
  {
    value: 'COLI',
    label: 'COLINA',
  },
  {
    value: 'COLL',
    label: 'COLLIPULL',
  },
  {
    value: 'COLT',
    label: 'COLTAUCO',
  },
  {
    value: 'COMB',
    label: 'COMBARBALA',
  },
  {
    value: 'CONC',
    label: 'CONCEPCION',
  },
  {
    value: 'CCHA',
    label: 'CONCHALI',
  },
  {
    value: 'CCON',
    label: 'CONCON',
  },
  {
    value: 'CONS',
    label: 'CONSTITUCION',
  },
  {
    value: 'CONT',
    label: 'CONTULMO',
  },
  {
    value: 'COPI',
    label: 'COPIAPO',
  },
  {
    value: 'COQU',
    label: 'COQUIMBO',
  },
  {
    value: 'CORO',
    label: 'CORONEL',
  },
  {
    value: 'CORR',
    label: 'CORRAL',
  },
  {
    value: 'COYH',
    label: 'COYHAIQUE',
  },
  {
    value: 'CUMP',
    label: 'CUMPEO',
  },
  {
    value: 'CUNC',
    label: 'CUNCO',
  },
  {
    value: 'CURC',
    label: 'CURACAUTIN',
  },
  {
    value: 'CRCV',
    label: 'CURACAVI',
  },
  {
    value: 'CUVE',
    label: 'CURACO DE VELEZ',
  },
  {
    value: 'CURA',
    label: 'CURANILAHUE',
  },
  {
    value: 'CRHU',
    label: 'CURARREHUE',
  },
  {
    value: 'CURE',
    label: 'CUREPTO',
  },
  {
    value: 'CURI',
    label: 'CURICO',
  },
  {
    value: 'DALC',
    label: 'DALCAHUE',
  },
  {
    value: 'DIEG',
    label: 'DIEGO DE ALMAGRO',
  },
  {
    value: 'DONI',
    label: 'DONIHUE',
  },
  {
    value: 'ELBE',
    label: 'EL BELLOTO',
  },
  {
    value: 'ELBO',
    label: 'EL BOSQUE',
  },
  {
    value: 'ECAR',
    label: 'EL CARMEN',
  },
  {
    value: 'ELME',
    label: 'EL MELOCOTON',
  },
  {
    value: 'EMEL',
    label: 'EL MELON',
  },
  {
    value: 'ELMO',
    label: 'EL MONTE',
  },
  {
    value: 'ELPA',
    label: 'EL PAICO',
  },
  {
    value: 'QSCO',
    label: 'EL QUISCO',
  },
  {
    value: 'ESAL',
    label: 'EL SALADO',
  },
  {
    value: 'ELSA',
    label: 'EL SALVADOR',
  },
  {
    value: 'TABI',
    label: 'EL TABITO',
  },
  {
    value: 'TABO',
    label: 'EL TABO',
  },
  {
    value: 'EMPE',
    label: 'EMPEDRADO',
  },
  {
    value: 'ELAG',
    label: 'ENTRE LAGOS',
  },
  {
    value: 'ERCI',
    label: 'ERCILLA',
  },
  {
    value: 'ECEN',
    label: 'ESTACION CENTRAL',
  },
  {
    value: 'EPAI',
    label: 'ESTACION PAIPOTE',
  },
  {
    value: 'FLOR',
    label: 'FLORIDA',
  },
  {
    value: 'FRER',
    label: 'FREIRE',
  },
  {
    value: 'FREI',
    label: 'FREIRINA',
  },
  {
    value: 'FRES',
    label: 'FRESIA',
  },
  {
    value: 'FRUT',
    label: 'FRUTILLAR',
  },
  {
    value: 'FBAQ',
    label: 'FUERTE BAQUEDANO',
  },
  {
    value: 'FUTA',
    label: 'FUTALEUFU',
  },
  {
    value: 'FUTR',
    label: 'FUTRONO',
  },
  {
    value: 'GALV',
    label: 'GALVARINO',
  },
  {
    value: 'GLAG',
    label: 'GENERAL LAGOS',
  },
  {
    value: 'GORB',
    label: 'GORBEA',
  },
  {
    value: 'GRAN',
    label: 'GRANEROS',
  },
  {
    value: 'GUAI',
    label: 'GUAITECAS',
  },
  {
    value: 'HIJU',
    label: 'HIJUELAS',
  },
  {
    value: 'HORP',
    label: 'HORNOPIREN',
  },
  {
    value: 'HULA',
    label: 'HUALAIHUE',
  },
  {
    value: 'HUAL',
    label: 'HUALANE',
  },
  {
    value: 'HPEN',
    label: 'HUALPEN',
  },
  {
    value: 'HAQI',
    label: 'HUALQUI',
  },
  {
    value: 'HUAR',
    label: 'HUARA',
  },
  {
    value: 'HUAS',
    label: 'HUASCO',
  },
  {
    value: 'HUEC',
    label: 'HUECHURABA',
  },
  {
    value: 'HUEP',
    label: 'HUEPIL',
  },
  {
    value: 'ILLA',
    label: 'ILLAPEL',
  },
  {
    value: 'INDE',
    label: 'INDEPENDENCIA',
  },
  {
    value: 'IQUI',
    label: 'IQUIQUE',
  },
  {
    value: 'IMAI',
    label: 'ISLA DE MAIPO',
  },
  {
    value: 'PASC',
    label: 'ISLA DE PASCUA',
  },
  {
    value: 'INEG',
    label: 'ISLA NEGRA',
  },
  {
    value: 'ITEJ',
    label: 'ISLA TEJA',
  },
  {
    value: 'ITAH',
    label: 'ITAHUE',
  },
  {
    value: 'JFER',
    label: 'JUAN FERNANDEZ',
  },
  {
    value: 'LACA',
    label: 'LA CALERA',
  },
  {
    value: 'LACI',
    label: 'LA CISTERNA',
  },
  {
    value: 'LACR',
    label: 'LA CRUZ',
  },
  {
    value: 'LAES',
    label: 'LA ESTRELLA',
  },
  {
    value: 'LAFL',
    label: 'LA FLORIDA',
  },
  {
    value: 'LAGR',
    label: 'LA GRANJA',
  },
  {
    value: 'LAHI',
    label: 'LA HIGUERA',
  },
  {
    value: 'LAJU',
    label: 'LA JUNTA',
  },
  {
    value: 'LALI',
    label: 'LA LIGUA',
  },
  {
    value: 'LAPI',
    label: 'LA PINTANA',
  },
  {
    value: 'LARE',
    label: 'LA REINA',
  },
  {
    value: 'LASE',
    label: 'LA SERENA',
  },
  {
    value: 'LAUN',
    label: 'LA UNION',
  },
  {
    value: 'LRAN',
    label: 'LAGO RANCO',
  },
  {
    value: 'LAVE',
    label: 'LAGO VERDE',
  },
  {
    value: 'LABL',
    label: 'LAGUNA BLANCA',
  },
  {
    value: 'LAJA',
    label: 'LAJA',
  },
  {
    value: 'LAMP',
    label: 'LAMPA',
  },
  {
    value: 'LANC',
    label: 'LANCO',
  },
  {
    value: 'LCAB',
    label: 'LAS CABRAS',
  },
  {
    value: 'LCON',
    label: 'LAS CONDES',
  },
  {
    value: 'LCRU',
    label: 'LAS CRUCES',
  },
  {
    value: 'LAUT',
    label: 'LAUTARO',
  },
  {
    value: 'LEBU',
    label: 'LEBU',
  },
  {
    value: 'LICA',
    label: 'LICANTEN',
  },
  {
    value: 'LIMA',
    label: 'LIMACHE',
  },
  {
    value: 'LINA',
    label: 'LINARES',
  },
  {
    value: 'LCHE',
    label: 'LITUECHE',
  },
  {
    value: 'LLAN',
    label: 'LLANQUIHUE',
  },
  {
    value: 'LLAY',
    label: 'LLAY LLAY',
  },
  {
    value: 'LLOL',
    label: 'LLO LLEO',
  },
  {
    value: 'LOBA',
    label: 'LO BARNECHEA',
  },
  {
    value: 'LOES',
    label: 'LO ESPEJO',
  },
  {
    value: 'LOMI',
    label: 'LO MIRANDA',
  },
  {
    value: 'LOPR',
    label: 'LO PRADO',
  },
  {
    value: 'LOLO',
    label: 'LOLOL',
  },
  {
    value: 'LONC',
    label: 'LONCOCHE',
  },
  {
    value: 'LONG',
    label: 'LONGAVI',
  },
  {
    value: 'LONV',
    label: 'LONGOVILO',
  },
  {
    value: 'LONQ',
    label: 'LONQUEN',
  },
  {
    value: 'LNQY',
    label: 'LONQUIMAY',
  },
  {
    value: 'LONT',
    label: 'LONTUE',
  },
  {
    value: 'ALAM',
    label: 'LOS ALAMOS',
  },
  {
    value: 'LAND',
    label: 'LOS ANDES',
  },
  {
    value: 'LANG',
    label: 'LOS ANGELES',
  },
  {
    value: 'LLAG',
    label: 'LOS LAGOS',
  },
  {
    value: 'LMUE',
    label: 'LOS MUERMOS',
  },
  {
    value: 'SAUC',
    label: 'LOS SAUCES',
  },
  {
    value: 'LVIL',
    label: 'LOS VILOS',
  },
  {
    value: 'LOTA',
    label: 'LOTA',
  },
  {
    value: 'LUMA',
    label: 'LUMACO',
  },
  {
    value: 'MACH',
    label: 'MACHALI',
  },
  {
    value: 'MACU',
    label: 'MACUL',
  },
  {
    value: 'MAFI',
    label: 'MAFIL',
  },
  {
    value: 'MIPU',
    label: 'MAIPU',
  },
  {
    value: 'MALO',
    label: 'MALLOA',
  },
  {
    value: 'MALL',
    label: 'MALLOCO',
  },
  {
    value: 'MARC',
    label: 'MARCHIGUE',
  },
  {
    value: 'MARI',
    label: 'MARIA ELENA',
  },
  {
    value: 'MAPI',
    label: 'MARIA PINTO',
  },
  {
    value: 'MRQN',
    label: 'MARIQUINA',
  },
  {
    value: 'MULE',
    label: 'MAULE',
  },
  {
    value: 'MAUL',
    label: 'MAULLIN',
  },
  {
    value: 'MEJI',
    label: 'MEJILLONES',
  },
  {
    value: 'MELK',
    label: 'MELINKA',
  },
  {
    value: 'MLPC',
    label: 'MELIPEUCO',
  },
  {
    value: 'MELI',
    label: 'MELIPILLA',
  },
  {
    value: 'MINI',
    label: 'MININCO',
  },
  {
    value: 'MOLI',
    label: 'MOLINA',
  },
  {
    value: 'MOPA',
    label: 'MONTE PATRIA',
  },
  {
    value: 'MULC',
    label: 'MULCHEN',
  },
  {
    value: 'NACI',
    label: 'NACIMIENTO',
  },
  {
    value: 'NANC',
    label: 'NANCAGUA',
  },
  {
    value: 'PNAT',
    label: 'NATALES',
  },
  {
    value: 'NAVI',
    label: 'NAVIDAD',
  },
  {
    value: 'NEGR',
    label: 'NEGRETE',
  },
  {
    value: 'NINH',
    label: 'NINHUE',
  },
  {
    value: 'NIQU',
    label: 'NIQUEN',
  },
  {
    value: 'NOGA',
    label: 'NOGALES',
  },
  {
    value: 'NOSO',
    label: 'NOS',
  },
  {
    value: 'NALD',
    label: 'NUEVA ALDEA',
  },
  {
    value: 'NVAI',
    label: 'NUEVA IMPERIAL',
  },
  {
    value: 'NUNO',
    label: 'NUNOA',
  },
  {
    value: 'OLIV',
    label: 'OLIVAR',
  },
  {
    value: 'OLLA',
    label: 'OLLAGUE',
  },
  {
    value: 'OLMU',
    label: 'OLMUE',
  },
  {
    value: 'OSOR',
    label: 'OSORNO',
  },
  {
    value: 'OVAL',
    label: 'OVALLE',
  },
  {
    value: 'PHUR',
    label: 'PADRE HURTADO',
  },
  {
    value: 'PLCA',
    label: 'PADRE LAS CASAS',
  },
  {
    value: 'PAIG',
    label: 'PAIGUANO',
  },
  {
    value: 'PAIL',
    label: 'PAILLACO',
  },
  {
    value: 'PAIN',
    label: 'PAINE',
  },
  {
    value: 'PAIP',
    label: 'PAIPOTE',
  },
  {
    value: 'PALE',
    label: 'PALENA',
  },
  {
    value: 'PALM',
    label: 'PALMILLA',
  },
  {
    value: 'PANG',
    label: 'PANGUIPULLI',
  },
  {
    value: 'PANQ',
    label: 'PANQUEHUE',
  },
  {
    value: 'PAPU',
    label: 'PAPUDO',
  },
  {
    value: 'PARE',
    label: 'PAREDONES',
  },
  {
    value: 'PARG',
    label: 'PARGUA',
  },
  {
    value: 'PARR',
    label: 'PARRAL',
  },
  {
    value: 'PEDR',
    label: 'PEDRO AGUIRRE CERDA',
  },
  {
    value: 'PELA',
    label: 'PELARCO',
  },
  {
    value: 'PELE',
    label: 'PELEQUEN',
  },
  {
    value: 'PELL',
    label: 'PELLUHUE',
  },
  {
    value: 'PEMU',
    label: 'PEMUCO',
  },
  {
    value: 'PBLA',
    label: 'PENABLANCA',
  },
  {
    value: 'PENA',
    label: 'PENAFLOR',
  },
  {
    value: 'PLOL',
    label: 'PENALOLEN',
  },
  {
    value: 'PNCH',
    label: 'PENCAHUE',
  },
  {
    value: 'PENC',
    label: 'PENCO',
  },
  {
    value: 'PERA',
    label: 'PERALILLO',
  },
  {
    value: 'PERQ',
    label: 'PERQUENCO',
  },
  {
    value: 'PETO',
    label: 'PETORCA',
  },
  {
    value: 'PEUM',
    label: 'PEUMO',
  },
  {
    value: 'PICA',
    label: 'PICA',
  },
  {
    value: 'PICD',
    label: 'PICHIDEGUA',
  },
  {
    value: 'PICH',
    label: 'PICHILEMU',
  },
  {
    value: 'PINT',
    label: 'PINTO',
  },
  {
    value: 'PIRQ',
    label: 'PIRQUE',
  },
  {
    value: 'PITR',
    label: 'PITRUFQUEN',
  },
  {
    value: 'PLAV',
    label: 'PLACILLA QUINTA REGION',
  },
  {
    value: 'PLAC',
    label: 'PLACILLA SEXTA REGION',
  },
  {
    value: 'PORT',
    label: 'PORTEZUELO',
  },
  {
    value: 'PORV',
    label: 'PORVENIR',
  },
  {
    value: 'POZO',
    label: 'POZO ALMONTE',
  },
  {
    value: 'PRIM',
    label: 'PRIMAVERA',
  },
  {
    value: 'PROV',
    label: 'PROVIDENCIA',
  },
  {
    value: 'PUCH',
    label: 'PUCHUNCAVI',
  },
  {
    value: 'PUCO',
    label: 'PUCON',
  },
  {
    value: 'PUDA',
    label: 'PUDAHUEL',
  },
  {
    value: 'PSEC',
    label: 'PUEBLO SECO',
  },
  {
    value: 'PALT',
    label: 'PUENTE ALTO',
  },
  {
    value: 'PAGU',
    label: 'PUERTO AGUIRRE',
  },
  {
    value: 'PAYS',
    label: 'PUERTO AYSEN',
  },
  {
    value: 'PCHA',
    label: 'PUERTO CHACABUCO',
  },
  {
    value: 'PCIS',
    label: 'PUERTO CISNES',
  },
  {
    value: 'PMON',
    label: 'PUERTO MONTT',
  },
  {
    value: 'POCT',
    label: 'PUERTO OCTAY',
  },
  {
    value: 'PVAR',
    label: 'PUERTO VARAS',
  },
  {
    value: 'PWIL',
    label: 'PUERTO WILLIAMS',
  },
  {
    value: 'PUMA',
    label: 'PUMANQUE',
  },
  {
    value: 'PUNI',
    label: 'PUNITAQUI',
  },
  {
    value: 'PUNT',
    label: 'PUNTA ARENAS',
  },
  {
    value: 'PUQU',
    label: 'PUQUELDON',
  },
  {
    value: 'PURE',
    label: 'PUREN',
  },
  {
    value: 'PURR',
    label: 'PURRANQUE',
  },
  {
    value: 'PUTA',
    label: 'PUTAENDO',
  },
  {
    value: 'PUTR',
    label: 'PUTRE',
  },
  {
    value: 'PUYG',
    label: 'PUYEHUE',
  },
  {
    value: 'PUYU',
    label: 'PUYUHUAPI',
  },
  {
    value: 'QUEI',
    label: 'QUEILEN',
  },
  {
    value: 'QUEL',
    label: 'QUELLON',
  },
  {
    value: 'QUEM',
    label: 'QUEMCHI',
  },
  {
    value: 'QUEP',
    label: 'QUEPE',
  },
  {
    value: 'QLAC',
    label: 'QUILACO',
  },
  {
    value: 'QILI',
    label: 'QUILICURA',
  },
  {
    value: 'QLLC',
    label: 'QUILLECO',
  },
  {
    value: 'QULL',
    label: 'QUILLON',
  },
  {
    value: 'QLTA',
    label: 'QUILLOTA',
  },
  {
    value: 'QUIL',
    label: 'QUILPUE',
  },
  {
    value: 'QUCH',
    label: 'QUINCHAO',
  },
  {
    value: 'QTIL',
    label: 'QUINTA DE TILCOCO',
  },
  {
    value: 'QNOR',
    label: 'QUINTA NORMAL',
  },
  {
    value: 'QUIN',
    label: 'QUINTERO',
  },
  {
    value: 'QUIR',
    label: 'QUIRIHUE',
  },
  {
    value: 'QUIQ',
    label: 'QUIRIQUINA',
  },
  {
    value: 'RANC',
    label: 'RANCAGUA',
  },
  {
    value: 'RANQ',
    label: 'RANQUIL',
  },
  {
    value: 'RAUC',
    label: 'RAUCO',
  },
  {
    value: 'RECO',
    label: 'RECOLETA',
  },
  {
    value: 'RENA',
    label: 'RENACA',
  },
  {
    value: 'RNCO',
    label: 'RENAICO',
  },
  {
    value: 'RENC',
    label: 'RENCA',
  },
  {
    value: 'RENG',
    label: 'RENGO',
  },
  {
    value: 'REQU',
    label: 'REQUINOA',
  },
  {
    value: 'RETI',
    label: 'RETIRO',
  },
  {
    value: 'RINC',
    label: 'RINCONADA',
  },
  {
    value: 'RIOB',
    label: 'RIO BUENO',
  },
  {
    value: 'RCLA',
    label: 'RIO CLARO',
  },
  {
    value: 'RHUR',
    label: 'RIO HURTADO',
  },
  {
    value: 'RIBA',
    label: 'RIO IBANEZ',
  },
  {
    value: 'RNEG',
    label: 'RIO NEGRO',
  },
  {
    value: 'RVER',
    label: 'RIO VERDE',
  },
  {
    value: 'ROME',
    label: 'ROMERAL',
  },
  {
    value: 'ROSA',
    label: 'ROSARIO',
  },
  {
    value: 'SVDR',
    label: 'SAAVEDRA',
  },
  {
    value: 'SAFA',
    label: 'SAGRADA FAMILIA',
  },
  {
    value: 'SALA',
    label: 'SALAMANCA',
  },
  {
    value: 'SANT',
    label: 'SAN ANTONIO',
  },
  {
    value: 'SBER',
    label: 'SAN BERNARDO',
  },
  {
    value: 'SCAR',
    label: 'SAN CARLOS',
  },
  {
    value: 'SCLE',
    label: 'SAN CLEMENTE',
  },
  {
    value: 'SEST',
    label: 'SAN ESTEBAN',
  },
  {
    value: 'SFAB',
    label: 'SAN FABIAN',
  },
  {
    value: 'SFEL',
    label: 'SAN FELIPE',
  },
  {
    value: 'SFER',
    label: 'SAN FERNANDO',
  },
  {
    value: 'SFLI',
    label: 'SAN FRANCISCO DE LIMACHE',
  },
  {
    value: 'SFRA',
    label: 'SAN FRANCISCO DE MOSTAZAL',
  },
  {
    value: 'SGRE',
    label: 'SAN GREGORIO',
  },
  {
    value: 'SIGN',
    label: 'SAN IGNACIO',
  },
  {
    value: 'SJAV',
    label: 'SAN JAVIER',
  },
  {
    value: 'SJOA',
    label: 'SAN JOAQUIN',
  },
  {
    value: 'SJMA',
    label: 'SAN JOSE DE LA MARIQUINA',
  },
  {
    value: 'SJOS',
    label: 'SAN JOSE DE MAIPO',
  },
  {
    value: 'SJCO',
    label: 'SAN JUAN DE LA COSTA',
  },
  {
    value: 'SMIG',
    label: 'SAN MIGUEL',
  },
  {
    value: 'SNIC',
    label: 'SAN NICOLAS',
  },
  {
    value: 'SPAB',
    label: 'SAN PABLO',
  },
  {
    value: 'SNPD',
    label: 'SAN PEDRO',
  },
  {
    value: 'SPAT',
    label: 'SAN PEDRO DE ATACAMA',
  },
  {
    value: 'SPED',
    label: 'SAN PEDRO DE LA PAZ',
  },
  {
    value: 'SPDO',
    label: 'SAN PEDRO QUINTA REGION',
  },
  {
    value: 'SRAF',
    label: 'SAN RAFAEL',
  },
  {
    value: 'SRAM',
    label: 'SAN RAMON',
  },
  {
    value: 'SANR',
    label: 'SAN ROSENDO',
  },
  {
    value: 'SSEB',
    label: 'SAN SEBASTIAN',
  },
  {
    value: 'SVIC',
    label: 'SAN VICENTE DE TAGUA TAGUA',
  },
  {
    value: 'SBAR',
    label: 'SANTA BARBARA',
  },
  {
    value: 'SCRU',
    label: 'SANTA CRUZ',
  },
  {
    value: 'SJUN',
    label: 'SANTA JUANA',
  },
  {
    value: 'SMAR',
    label: 'SANTA MARIA',
  },
  {
    value: 'STGO',
    label: 'SANTIAGO CENTRO',
  },
  {
    value: 'SDGO',
    label: 'SANTO DOMINGO',
  },
  {
    value: 'SGOR',
    label: 'SIERRA GORDA',
  },
  {
    value: 'TALA',
    label: 'TALAGANTE',
  },
  {
    value: 'TALC',
    label: 'TALCA',
  },
  {
    value: 'THNO',
    label: 'TALCAHUANO',
  },
  {
    value: 'TALT',
    label: 'TALTAL',
  },
  {
    value: 'TEMU',
    label: 'TEMUCO',
  },
  {
    value: 'TENO',
    label: 'TENO',
  },
  {
    value: 'TSCH',
    label: 'TEODORO SCHMIDT',
  },
  {
    value: 'TAMA',
    label: 'TIERRA AMARILLA',
  },
  {
    value: 'TILT',
    label: 'TIL TIL',
  },
  {
    value: 'TMKL',
    label: 'TIMAUKEL',
  },
  {
    value: 'TIRU',
    label: 'TIRUA',
  },
  {
    value: 'TOCO',
    label: 'TOCOPILLA',
  },
  {
    value: 'TOLT',
    label: 'TOLTEN',
  },
  {
    value: 'TOME',
    label: 'TOME',
  },
  {
    value: 'TONG',
    label: 'TONGOY',
  },
  {
    value: 'TPAI',
    label: 'TORRES DEL PAINE',
  },
  {
    value: 'TRTL',
    label: 'TORTEL',
  },
  {
    value: 'TRAI',
    label: 'TRAIGUEN',
  },
  {
    value: 'TREG',
    label: 'TREGUACO',
  },
  {
    value: 'TUCA',
    label: 'TUCAPEL',
  },
  {
    value: 'VALD',
    label: 'VALDIVIA',
  },
  {
    value: 'VALL',
    label: 'VALLENAR',
  },
  {
    value: 'VALP',
    label: 'VALPARAISO',
  },
  {
    value: 'VICH',
    label: 'VICHUQUEN',
  },
  {
    value: 'VICT',
    label: 'VICTORIA',
  },
  {
    value: 'VICU',
    label: 'VICUNA',
  },
  {
    value: 'VILC',
    label: 'VILCUN',
  },
  {
    value: 'VALG',
    label: 'VILLA ALEGRE',
  },
  {
    value: 'VALE',
    label: 'VILLA ALEMANA',
  },
  {
    value: 'VMAN',
    label: 'VILLA MANIHUALES',
  },
  {
    value: 'VILL',
    label: 'VILLARRICA',
  },
  {
    value: 'VINA',
    label: 'VINA DEL MAR',
  },
  {
    value: 'VITA',
    label: 'VITACURA',
  },
  {
    value: 'YBNS',
    label: 'YERBAS BUENAS',
  },
  {
    value: 'YUMB',
    label: 'YUMBEL',
  },
  {
    value: 'YUNG',
    label: 'YUNGAY',
  },
  {
    value: 'ZAPA',
    label: 'ZAPALLAR',
  },
];
