import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { catchError, map, mergeMap, takeWhile } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { EStoreSearchRequest } from '@gelato-api-ui/core/e-commerce/e-store-search-request';
import { EStoreSearchResponse } from '@gelato-api-ui/core/e-commerce/e-store-search-response';
import { EStoreCreateRequest } from '@gelato-api-ui/core/e-commerce/e-store-create-request';
import { EStoreUpdateRequest } from '@gelato-api-ui/core/e-commerce/e-store-update-request';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { ImportProductsResponse } from '@gelato-api-ui/core/e-commerce/import-products-response';
import { EStoreSyncStatusResponse } from '@gelato-api-ui/core/e-commerce/e-store-sync-status-response';
import { isSyncStoreProductsActive } from '@gelato-api-ui/core/e-commerce/helpers/isSyncStoreProductsActive';

@Injectable({
  providedIn: 'root',
})
export class ECommerceStoresApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  search(request: EStoreSearchRequest): Observable<EStoreSearchResponse> {
    return this.apiRequest
      .get<EStoreSearchResponse>('/api/ecommerce/v1/stores', {
        body: request,
      })
      .pipe(map((data: any) => data.data));
  }

  save(eStore: EStore, clientCountryCode: string = null): Observable<EStore> {
    if (!eStore) {
      return of(null);
    }

    if (eStore.id) {
      const request: EStoreUpdateRequest = {
        id: eStore.id,
        name: eStore.name,
        domain: eStore.domain,
        orderApproval: eStore.orderApproval,
        status: eStore.status,
        settlementCurrency: eStore.settlementCurrency,
        isNotConnectedProductsEnabled: eStore.isNotConnectedProductsEnabled,
        autoApproveUrl: eStore.autoApproveUrl,
        autoApproveAfter: eStore.autoApproveAfter,
        printFileFieldName: eStore.printFileFieldName,
        isOutOfStockSyncEnabled: eStore.isOutOfStockSyncEnabled,
        outOfStockRegion: eStore.outOfStockRegion,
        isLiveRatesEnabled: eStore.isLiveRatesEnabled,
        isLiveFreeShippingEnabled: eStore.isLiveFreeShippingEnabled,
        shippingMargin: eStore.shippingMargin,
        personalizationSettings: eStore.personalizationSettings,
        disableShippingProfiles: eStore.disableShippingProfiles,
        isLiveRatesEstimatedDatesEnabled: eStore.isLiveRatesEstimatedDatesEnabled,
        liveRatesIncreaseDays: eStore.liveRatesIncreaseDays,
        iossNumber: eStore.iossNumber,
        shippingOriginCountryIso: eStore.shippingOriginCountryIso,
        shippingOriginPostalCode: eStore.shippingOriginPostalCode,
      };

      return this.update(eStore.id, request);
    } else {
      const request: EStoreCreateRequest = {
        name: eStore.name,
        domain: eStore.domain,
        type: eStore.type,
        currency: eStore.currency,
        orderApproval: eStore.orderApproval,
        isNotConnectedProductsEnabled: eStore.isNotConnectedProductsEnabled,
        clientCountryCode,
        shippingMargin: eStore.shippingMargin,
        isLiveRatesEnabled: eStore.isLiveRatesEnabled,
        isLiveRatesEstimatedDatesEnabled: eStore.isLiveRatesEstimatedDatesEnabled,
        liveRatesIncreaseDays: eStore.liveRatesIncreaseDays,
        iossNumber: eStore.iossNumber,
        shippingOriginCountryIso: eStore.shippingOriginCountryIso,
        shippingOriginPostalCode: eStore.shippingOriginPostalCode,
      };

      return this.create(request);
    }
  }

  get(id: string): Observable<EStore> {
    if (!id) {
      return of(null);
    }

    return this.apiRequest.get<EStore>(`/api/ecommerce/v1/stores/${id}`).pipe(map(data => data.data));
  }

  create(request: EStoreCreateRequest): Observable<EStore> {
    return this.apiRequest.post<EStore>('/api/ecommerce/v1/stores', { body: request }).pipe(map(data => data.data));
  }

  update(id: string, request: EStoreUpdateRequest): Observable<EStore> {
    return this.apiRequest
      .put<EStore>(`/api/ecommerce/v1/stores/${id}`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  sync(id: string, isInstant: boolean): Observable<string> {
    return this.apiRequest
      .post<string>(`/api/ecommerce/v1/stores/${id}:sync`, { body: { isInstant } })
      .pipe(map(data => data.data));
  }

  getSyncStatus(id: string): Observable<EStoreSyncStatusResponse> {
    return this.apiRequest
      .get<EStoreSyncStatusResponse>(`/api/ecommerce/v1/stores/${id}:sync-status`)
      .pipe(map(data => data.data));
  }

  getSyncStatusUntilFinished(id: string): Observable<EStoreSyncStatusResponse> {
    return timer(0, 1000).pipe(
      mergeMap(() => this.getSyncStatus(id).pipe(catchError(() => of(null)))),
      takeWhile((response: EStoreSyncStatusResponse): boolean => isSyncStoreProductsActive(response), true),
    );
  }

  importProducts(
    id: string,
    productsFileUrl: string,
    email: string,
    isInstant: boolean,
  ): Observable<ImportProductsResponse> {
    return this.apiRequest
      .post<ImportProductsResponse>(`/api/ecommerce/v1/stores/${id}:import`, {
        body: { productsFile: productsFileUrl, email, isInstant },
      })
      .pipe(map(data => data.data));
  }

  exportProducts(id: string, email: string, isInstant: boolean): Observable<string> {
    return this.apiRequest
      .post<any>(`/api/ecommerce/v1/stores/${id}:export`, { body: { email, isInstant } })
      .pipe(map(data => data.data));
  }

  delete(id: string): Observable<EStore> {
    return this.apiRequest.delete<EStore>(`/api/ecommerce/v1/stores/${id}`).pipe(map(data => data.data));
  }
}
