import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';

export const getMetadataItem = (items: MetadataItem[], key: string, skipEmptyValues?: boolean): MetadataItem =>
  (items || []).find((loopMetadataItem: MetadataItem): boolean => {
    if (loopMetadataItem.key !== key) {
      return false;
    }

    if (skipEmptyValues && !loopMetadataItem.value) {
      return false;
    }

    return true;
  });
