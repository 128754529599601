import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';

interface ProductTemplateNameModalContext {
  templateName: string;
  title: string;
  description: string;
  actionButtonText: string;
  acceptInitialTemplateName: boolean;
}

@Component({
  selector: 'gd-product-template-name-modal',
  templateUrl: './product-template-name-modal.component.html',
  styleUrls: ['./product-template-name-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductTemplateNameModalComponent implements OnInit {
  initialTemplateName: string;
  templateName: string;
  acceptInitialTemplateName: boolean;

  constructor(public modal: SuiModal<ProductTemplateNameModalContext, string, void>) {}

  ngOnInit() {
    const { templateName, acceptInitialTemplateName } = this.modal.context;

    this.initialTemplateName = templateName;
    this.templateName = templateName;
    this.acceptInitialTemplateName = acceptInitialTemplateName;
  }

  close() {
    this.modal.deny(undefined);
  }

  approve() {
    this.modal.approve(this.templateName);
  }
}

export class ProductTemplateNameModal extends ComponentModalConfig<ProductTemplateNameModalContext, string, void> {
  constructor(
    templateName: string,
    title: string,
    description: string,
    actionButtonText: string,
    allowInitialTemplateName: boolean,
  ) {
    super(ProductTemplateNameModalComponent, {
      templateName,
      title,
      description,
      actionButtonText,
      acceptInitialTemplateName: allowInitialTemplateName,
    });

    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}
