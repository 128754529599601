import { EProductImage } from '../e-product-image';
import { EProductImageStatus } from '../e-product-image-status.enum';

export const getPrimaryProductImageUrl = (productImages: EProductImage[]): string => {
  if (!productImages || !productImages.length) {
    return null;
  }

  const primaryProductImage = [...productImages]
    .sort((a: EProductImage, b: EProductImage) => (b.createdAt || '').localeCompare(a.createdAt || ''))
    .find(
      (loopProductImage: EProductImage): boolean =>
        loopProductImage.isPrimary && loopProductImage.status !== EProductImageStatus.DELETED,
    );

  return primaryProductImage ? primaryProductImage.fileUrl : null;
};
