export enum FinancialOrderStatus {
  INVOICED = 'invoiced',
  REFUNDED = 'refunded',
  PARTIALLY_REFUNDED = 'partially_refunded',
  TO_BE_INVOICED = 'to_be_invoiced',
  PAID = 'paid',
  REFUSED = 'refused',
  PENDING = 'pending',
  CANCELED = 'canceled',
  DRAFT = 'draft',
}
