import * as R from 'ramda';
import { PricePlanSubscription } from '../../price-plan-subscriptions/lib/price-plan-subscription';
import { State } from '../price-plan-subscriptions.reducer';

export const findPricePlanSubscriptionInState = (state: State, id: string): PricePlanSubscription => {
  if (!state || !id) {
    return null;
  }

  if (
    state.pricePlanSubscription &&
    state.pricePlanSubscription.payload &&
    state.pricePlanSubscription.payload.id === id
  ) {
    return { ...state.pricePlanSubscription.payload };
  }

  if (
    state.pricePlanSubscriptionList &&
    state.pricePlanSubscriptionList.payload &&
    state.pricePlanSubscriptionList.payload.data
  ) {
    return {
      ...R.find(R.propEq('id', id))(state.pricePlanSubscriptionList.payload.data),
    };
  }

  return null;
};
