import { SelectedPreviewScenesCollection } from '../e-commerce-product-wizard.reducer';
import { sceneName2Key } from '@gelato-api-ui/core/e-commerce/helpers/sceneName2Key';

export const validateSelectedPreviewScenes = (
  selectedPreviewScenes: SelectedPreviewScenesCollection,
  supportedPreviewScenes: string[],
): SelectedPreviewScenesCollection => {
  const newSelectedPreviewScenes = supportedPreviewScenes.reduce(
    (acc: SelectedPreviewScenesCollection, scene: string) => {
      const key = sceneName2Key(scene);

      if (selectedPreviewScenes[key]) {
        return {
          ...acc,
          [key]: true,
        };
      }

      return acc;
    },
    {},
  );

  if (!Object.keys(newSelectedPreviewScenes).length && supportedPreviewScenes.length > 0) {
    newSelectedPreviewScenes[sceneName2Key(supportedPreviewScenes[0])] = true;
  }

  return newSelectedPreviewScenes;
};
