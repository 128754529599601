import { Injectable } from '@angular/core';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { getUsedAssets } from '@gelato-api-ui/core/designs/helpers/getUsedAssets';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import { ShutterstockAssetProviderUids } from '@gelato-api-ui/core/shutterstock/shutterstock.constant';

@Injectable({ providedIn: 'root' })
export class DesignPreviewShutterstockService {
  isShutterstockFlatPreviewSupported(
    productTypeUid: ProductTypeUid,
    noFlatPreviews: boolean,
    isShutterstockUsed: boolean,
  ): boolean {
    return productTypeUid
      ? noFlatPreviews && isShutterstockUsed && [ProductTypeUid.POSTER].includes(productTypeUid)
      : false;
  }

  isDesignHasShutterstockAssets(assetsCollection: AssetsCollection, designStructure: DesignStructure) {
    const usedShutterstockAssets: Asset[] = getUsedAssets(
      assetsCollection,
      designStructure,
      ShutterstockAssetProviderUids,
    );

    return usedShutterstockAssets?.length > 0;
  }
}
