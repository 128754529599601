import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { ProductionConfigurationsApiService } from '../production-configurations/services/production-configurations-api.service';
import { ProductionConfiguration } from '../production-configurations/lib/production-configuration';
import { ProductionConfigurationSearchResponse } from '../production-configurations/lib/production-configuration-search-response';
import * as actions from './production-configurations.actions';
import { getListState, getState } from './production-configurations.reducer';
import { findProductionConfigurationInState } from './helpers/findProductionConfigurationInState';
import { LoadClientsByIds } from '@api-ui-app/src/app/ngrx/client-selection-list.actions';

@Injectable()
export class ProductionConfigurationsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private productionConfigurationsApiService: ProductionConfigurationsApiService,
    private authService: AuthService,
  ) {}

  loadList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadList>(actions.ProductionConfigurationsActionTypes.LoadList),
        withLatestFrom(this.store$.select(getListState)),
        switchMap(([action, communicationSubscriptionsList]) => {
          const { searchRequest, forced } = action;

          const isDataRelevant =
            R.equals(searchRequest, communicationSubscriptionsList.request) && communicationSubscriptionsList.payload;

          let observable: Observable<ProductionConfigurationSearchResponse> =
            this.productionConfigurationsApiService.search(searchRequest);

          if (!forced && isDataRelevant) {
            observable = of({ ...communicationSubscriptionsList.payload });
          }

          this.store$.dispatch(
            new actions.SetListState({
              isLoading: true,
              request: searchRequest,
              payload: isDataRelevant ? communicationSubscriptionsList.payload : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<ProductionConfigurationSearchResponse> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: ProductionConfigurationSearchResponse) => {
              this.store$.dispatch(
                new actions.SetListState({
                  isLoading: false,
                  request: searchRequest,
                  payload: response,
                }),
              );
              if (response && response.data) {
                const ids = response.data.map(_d => _d.clientId);
                this.store$.dispatch(new LoadClientsByIds(ids));
              }
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadEntity$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadEntity>(actions.ProductionConfigurationsActionTypes.LoadEntity),
        withLatestFrom(this.store$.select(getState)),
        switchMap(([action, state]) => {
          const { id, forced } = action;
          const entityFromState: ProductionConfiguration = findProductionConfigurationInState(state, id);
          const isDataRelevant = Boolean(entityFromState);

          let observable: Observable<ProductionConfiguration> = this.productionConfigurationsApiService.get(id);

          if (!forced && isDataRelevant) {
            observable = of({ ...entityFromState });
          }

          this.store$.dispatch(
            new actions.SetEntityState({
              isLoading: true,
              payload: isDataRelevant ? entityFromState : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<ProductionConfiguration> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((entity: ProductionConfiguration) => {
              this.store$.dispatch(
                new actions.SetEntityState({
                  isLoading: false,
                  payload: entity,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
