import { Component } from '@angular/core';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { DestygoWebChatService } from '@api-ui-app/src/app/app-store/services/destygo-web-chat.service';

@Component({
  selector: 'gd-auth-button-mobile',
  templateUrl: './auth-button-mobile.component.html',
  styleUrls: ['./auth-button-mobile.component.scss'],
})
export class AuthButtonMobileComponent {
  isAuthorised$ = this.authService.isAuthorised();

  constructor(public authService: AuthService, private destygoWebChatService: DestygoWebChatService) {}

  signIn() {
    this.authService.redirectToSignIn();
  }

  signOut() {
    this.destygoWebChatService.resetChat();
    this.authService.signOut();
  }
}
