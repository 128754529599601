export enum ProductTypeUid {
  // apparel
  HOODIE = 'hoodie',
  ONESIE = 'onesie',
  SWEATSHIRT = 'sweatshirt',
  T_SHIRT = 't-shirt',
  TANK_TOP = 'tank-top',

  // bags
  TOTE_BAG = 'tote-bag',

  // cards
  CARDS = 'cards',
  PACK_OF_CARDS = 'pack-of-cards',

  // drinkware
  MUG = 'mug',
  BOTTLE = 'bottle',

  // photo-gifts
  CALENDAR = 'calendar',
  PHOTO_BOOK = 'photo-book',

  // stationery
  BLANK_ENVELOPES = 'blank-envelopes',
  BROCHURE = 'brochure',
  BUSINESS_CARDS = 'business-cards',
  BUSINESS_CARDS_SINGLE_SIDED = 'business-cards-single-sided',
  BUSINESS_CARDS_WITH_VARIABLE_PRINTING = 'business-cards-with-variable-printing',
  FLYER = 'flyer',
  FOLDER = 'folder',
  LETTERHEAD = 'letterhead',
  NOTEBOOK = 'notebook',
  ROLL_UP = 'roll-up',
  STICKER = 'sticker',
  TABLE_TALKER = 'table-talker',
  TABLE_TENT = 'table-tent',

  // wall-art
  ACRYLIC_PRINT = 'acrylic-print',
  ALUMINUM_PRINT = 'aluminum-print',
  CANVAS = 'canvas',
  FOAM_PRINT = 'foam-print',
  FRAME = 'frame',
  FRAMED_POSTER = 'framed-poster',
  MOUNTED_FRAMED_POSTER = 'mounted-framed-poster',
  HANGER = 'hanger',
  POSTER = 'poster',
  POSTER_WITH_HANGER = 'poster-with-hanger',
  WOOD_PRINT = 'wood-print',
  WALLPAPER = 'wallpaper',
  WALLPAPER_SAMPLE = 'wallpaper-sample',

  // phone-case
  PHONE_CASE = 'phone-case',

  // Branding
  BRANDING_INSERT = 'branded-insert',
  BRANDING_LABEL = 'branded-label',
}
