import * as actions from './layout.actions';
import { sidebarStorageKey } from '@api-ui-app/src/app/ngrx/layout';

export interface State {
  sidebarMinimised: boolean;
  fullScreenMode: boolean;
}

export const initialState: State = {
  sidebarMinimised: Boolean(Number(localStorage.getItem(sidebarStorageKey))) || false,
  fullScreenMode: false,
};

export function reducer(state = initialState, action: actions.LayoutActions): State {
  switch (action.type) {
    case actions.LayoutActionTypes.ResetState:
      return {
        ...initialState,
      };
    case actions.LayoutActionTypes.SetState:
      return {
        ...state,
        ...action.payload,
      };
    case actions.LayoutActionTypes.ToggleSidebar:
      const sidebarStorageValue = localStorage.getItem(sidebarStorageKey);

      return {
        ...state,
        sidebarMinimised: !!sidebarStorageValue ? Boolean(Number(sidebarStorageValue)) : !state.sidebarMinimised,
      };
    case actions.LayoutActionTypes.ToggleFullScreenMode:
      return {
        ...state,
        fullScreenMode: action.payload,
      };
    default:
      return state;
  }
}
