<gd-price-plan-selector
  [showAnyValueOption]="showAnyValueOption"
  [anyValueOptionText]="'txt_any' | translate"
  [placeholder]="'txt_price_plan_field_placeholder' | translate"
  [valueField]="'id'"
  [value]="pricePlanId"
  (valueChange)="onPricePlanIdChange($event)"
  [isSearchable]="isSearchable"
  [isDisabled]="isDisabled"
>
</gd-price-plan-selector>
