import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxCurrencyModule } from 'ngx-currency';
import { AutofocusFixModule } from 'ngx-autofocus-fix';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { UiKitModule } from '@gelato-api-ui/ui-kit/src';
import { SdkSharedModule } from '@gelato-api-ui/sdk/src/lib/shared/sdk-shared.module';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { CopyUidLinkComponent } from './copy-uid-link/copy-uid-link.component';
import { CountryMultiselectComponent } from './country-multiselect/country-multiselect.component';
import { NumberOptionSelectComponent } from './number-option-select/number-option-select.component';
import { CircleNumberLabelComponent } from './circle-number-label/circle-number-label.component';
import { NumberSelectorComponent } from './number-selector/number-selector.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { BreadcrumbsHeadlessComponent } from './breadcrumbs-headless/breadcrumbs-headless.component';
import { PipesModule } from '@product-catalogue/src/lib/shared/pipes/pipes/pipes.module';
import { CategorySharedModule } from '../category-shared/category-shared.module';
import { ClientSelectorModule } from '@gelato-api-ui/core/client-selector/client-selector.module';
import { CountryFlagModule } from '@gelato-api-ui/sdk/src/lib/shared/country-flag/country-flag.module';
import { CountrySelectorModule } from '@gelato-api-ui/sdk/src/lib/shared/country-selector/country-selector.module';
import { CountriesMapModule } from '@gelato-api-ui/ui-kit/src/lib/countries-map/countries-map.module';

const exportedModules = [
  // Angular
  CommonModule,
  FormsModule,
  TranslateModule,
  ReactiveFormsModule,
  ScrollingModule,
  SuiModule,
  UiKitModule,
  // Custom
  SdkSharedModule,
  SdkPipesModule,
  ClipboardModule,
  NgxCurrencyModule,
  AutofocusFixModule,
  NgSelectModule,
  NgxSkeletonLoaderModule,
  PipesModule,
  CategorySharedModule,
  ClientSelectorModule,
  CountryFlagModule,
  CountrySelectorModule,
  CountriesMapModule,
];

const exportedComponents = [
  CountryMultiselectComponent,
  CopyUidLinkComponent,
  BreadcrumbsHeadlessComponent,
  NumberOptionSelectComponent,
  NumberSelectorComponent,
  CircleNumberLabelComponent,
];

@NgModule({
  imports: [RouterModule, ...exportedModules, NgxCurrencyModule, AutofocusFixModule.forRoot()],
  declarations: [...exportedComponents],
  exports: [RouterModule, ...exportedModules, ...exportedComponents],
  providers: [ApiRequestService],
})
export class SharedModule {}
