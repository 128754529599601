import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import {
  SanityHomePageContent,
  SanityProductCommunicationBannersItem,
} from '@gelato-api-ui/core/sanity/sanity-home-page-content';
import { SanityApiService } from '@gelato-api-ui/core/sanity/services/sanity-api.service';
import {
  loadHomePageContent,
  loadHomePageContentSuccess,
  loadProductCommunicationBanners,
  loadProductCommunicationBannersSuccess,
} from '@gelato-api-ui/sdk/src/lib/sanity/sanity.actions';
import { getHomePageContent } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.selector';
import { TranslateService } from '@ngx-translate/core';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { updateLocale } from '@gelato-api-ui/sdk/src/lib/sdk.actions';

@Injectable()
export class SanityEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly sanityApiService: SanityApiService,
    private readonly translations: TranslateService,
  ) {}

  loadHomePageContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadHomePageContent),
      switchMap(action =>
        this.sanityApiService
          .getHomePageContent(action.locale || (this.translations.currentLang as LocaleCode))
          .pipe(map((payload: SanityHomePageContent) => loadHomePageContentSuccess({ payload }))),
      ),
    ),
  );

  loadProductCommunicationBanners$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadProductCommunicationBanners),
      switchMap(action =>
        this.sanityApiService
          .getProductCommunicationBanners(action.locale || (this.translations.currentLang as LocaleCode))
          .pipe(
            map((payload: SanityProductCommunicationBannersItem[]) =>
              loadProductCommunicationBannersSuccess({ payload }),
            ),
          ),
      ),
    ),
  );

  updateLocale$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateLocale),
      withLatestFrom(this.store$.select(getHomePageContent)),
      filter(([_, content]) => !!content),
      mergeMap(([action]) => [
        loadHomePageContent({ locale: action.locale }),
        loadProductCommunicationBanners({ locale: action.locale }),
      ]),
    ),
  );
}
