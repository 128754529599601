<div *ngIf="!subscriptionPageEnabledAB" class="title">
  <h2>{{ 'txt_compare_plans' | translate }}</h2>
</div>

<div class="table-toggle" [class.opened]="isTableShown" *ngIf="subscriptionPageEnabledAB">
  <button class="ui button" (click)="toggleTableIsShown()">
    {{ 'txt_complete_feature_list' | translate }}
    <img src="/assets/icons/chevron.svg" alt="arrow icon" />
  </button>
</div>

<swiper
  *ngIf="isMobile"
  class="table-carousel"
  [config]="thumbnailsSwiperConfig"
  [pagination]="pagination"
  (activeIndexChange)="activeIndexChange($event)"
>
  <ng-template swiperSlide>
    <ng-container [ngTemplateOutlet]="freePlan"></ng-container>
  </ng-template>
  <ng-template swiperSlide>
    <ng-container [ngTemplateOutlet]="plusPlan"></ng-container>
  </ng-template>
  <ng-template swiperSlide>
    <ng-container [ngTemplateOutlet]="goldPlan"></ng-container>
  </ng-template>
</swiper>

<ng-container *ngIf="isTableShown">
  <h3 class="table-title" *ngIf="subscriptionPageEnabledAB">
    {{ 'txt_comparison_table_title' | translate }}
  </h3>

  <table class="table" [class.table-ab]="subscriptionPageEnabledAB">
    <tbody>
      <tr class="table-header" *ngIf="!isMobile">
        <td class="left-corner"></td>
        <td>
          <ng-container [ngTemplateOutlet]="freePlan"></ng-container>
        </td>
        <td>
          <ng-container [ngTemplateOutlet]="plusPlan"></ng-container>
        </td>
        <td>
          <ng-container [ngTemplateOutlet]="goldPlan"></ng-container>
        </td>
      </tr>
      <ng-container *ngIf="!subscriptionPageEnabledAB">
        <ng-container *ngIf="!isMobile; else mobileTable">
          <tr class="data-row" *ngFor="let noSectionRow of plansComparisonTableSections?.noSectionRows">
            <td *ngFor="let cell of noSectionRow; let i = index">
              <gd-plans-comparison-table-cell
                [isFirstCell]="i === 0"
                [currency]="userCurrency"
                [cell]="cell"
                [countryISOCode]="countryISOCode"
              ></gd-plans-comparison-table-cell>
            </td>
          </tr>
          <gd-table-section-group
            *ngFor="let section of plansComparisonTableSections?.sections"
            [title]="section?.sectionTitleKey | translate"
          >
            <tr class="data-row" *ngFor="let row of section.tableRows">
              <td *ngFor="let cell of row; let i = index">
                <gd-plans-comparison-table-cell
                  [isFirstCell]="i === 0"
                  [currency]="userCurrency"
                  [cell]="cell"
                  [countryISOCode]="countryISOCode"
                ></gd-plans-comparison-table-cell>
              </td>
            </tr>
          </gd-table-section-group>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="subscriptionPageEnabledAB">
        <tr class="data-row" *ngFor="let row of plansComparisonTableDataFlatted">
          <td *ngFor="let cell of row; let i = index">
            <gd-comparison-table-cell
              [isFirstCell]="i === 0"
              [currency]="userCurrency"
              [cell]="cell"
              [countryISOCode]="countryISOCode"
            ></gd-comparison-table-cell>
          </td>
        </tr>

        <gd-table-section-group
          *ngFor="let group of plansComparisonTableDataGrouped"
          [subscriptionPageEnabledAB]="subscriptionPageEnabledAB"
          [title]="getGroupTitle(group)"
        >
          <tr class="data-row" *ngFor="let row of group">
            <td *ngFor="let cell of row; let i = index">
              <gd-comparison-table-cell
                [isFirstCell]="i === 0"
                [currency]="userCurrency"
                [cell]="cell"
                [countryISOCode]="countryISOCode"
              ></gd-comparison-table-cell>
            </td>
          </tr>
        </gd-table-section-group>
      </ng-container>
    </tbody>
  </table>
</ng-container>

<ng-template #mobileTable>
  <tr class="data-row" *ngFor="let row of mobileTableRows">
    <td *ngFor="let cell of row; let i = index">
      <gd-plans-comparison-table-cell
        [isFirstCell]="i === 0"
        [currency]="userCurrency"
        [cell]="cell"
        [countryISOCode]="countryISOCode"
      ></gd-plans-comparison-table-cell>
    </td>
  </tr>
</ng-template>

<ng-template #freePlan>
  <div class="row-header" [ngClass]="{ mobile: isMobile }">
    <div class="plan-name">
      <span>{{ 'txt_free' | translate }}</span>
    </div>
  </div>
</ng-template>

<ng-template #plusPlan>
  <div class="row-header" [ngClass]="{ mobile: isMobile }">
    <div class="plan-name">
      <span>{{ 'txt_plus' | translate }}</span>
      <img
        class="plan-icon"
        src="{{ 'subscriptions/plus-purple-crown.svg' | assetsPath | async }}"
        alt="plus crown icon"
      />
    </div>
    <button
      *ngIf="!isGelatoPlusActive && !isGelatoPlusGoldActive"
      class="ui primary button plus-button"
      (click)="openSubscriptionsModal(gelatoSubscriptionModalType.GELATO_PLUS)"
    >
      {{ isUserNeverHadGelatoPlusSubscription ? ('txt_free_trial' | translate) : ('txt_subscribe' | translate) }}
    </button>
    <button
      *ngIf="isGelatoPlusTrialActive && isGelatoPlusActive && !isGelatoPlusGoldActive"
      class="ui primary button"
      (click)="onActivateSubscription(subscriptionType.PLUS)"
    >
      {{
        isGelatoPlusTrialScheduledToCancel
          ? ('txt_cancel_subscription_cta' | translate)
          : ('txt_activate_subscription_cta' | translate)
      }}
    </button>
  </div>
</ng-template>

<ng-template #goldPlan>
  <div class="row-header" [ngClass]="{ mobile: isMobile }">
    <div class="plan-name">
      <span>{{ 'txt_gold' | translate }}</span>
      <img class="plan-icon" src="{{ 'subscriptions/gold-crown.svg' | assetsPath | async }}" alt="plus crown icon" />
    </div>
    <button
      *ngIf="!isGelatoPlusGoldActive && isClientEligibleForGoldSubscription"
      class="ui primary button gold-button"
      (click)="openSubscriptionsModal(gelatoSubscriptionModalType.GELATO_GOLD)"
    >
      {{
        isUserNeverHadGelatoPlusGoldSubscription && !isGoldTrialNotAvailable
          ? ('txt_free_trial' | translate)
          : ('txt_subscribe' | translate)
      }}
    </button>
    <button *ngIf="!isClientEligibleForGoldSubscription" class="ui button primary" (click)="contactUsClick()">
      {{ 'txt_subscriptions_contact_us' | translate }}
    </button>
    <button
      *ngIf="isGelatoPlusGoldTrialActive && isGelatoPlusGoldActive && !isGelatoPlusActive"
      class="ui primary button"
      (click)="onActivateSubscription(subscriptionType.GOLD)"
    >
      {{
        isGelatoPlusGoldTrialScheduledToCancel
          ? ('txt_cancel_subscription_cta' | translate)
          : ('txt_activate_subscription_cta' | translate)
      }}
    </button>
  </div>
</ng-template>
