import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { first, tap } from 'rxjs/operators';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { trackStoreAddProductInitiatedEvent } from './helpers/trackStoreAddProductInitiatedEvent';
import { AppState } from '@api-ui-app/src/app/app.state';
import { Store } from '@ngrx/store';
import { setSelectedStore } from '@api-ui-app/src/app/ngrx/e-commerce-stores.actions';
import { getStore } from '@api-ui-app/src/app/ngrx/e-commerce-stores.selector';

@Injectable({
  providedIn: 'root',
})
export class AddStoreProductFlowService {
  private readonly eStore$ = this.store.select(getStore);

  constructor(private readonly router: Router, private readonly store: Store<AppState>) {}

  initiate(eStore: EStore) {
    this.store.dispatch(setSelectedStore({ payload: eStore }));

    this.eStore$
      .pipe(
        first(),
        tap((store: EStore) => {
          trackStoreAddProductInitiatedEvent(store);
          this.router.navigate(['/catalogue/stores/current/products/wizard']);
        }),
      )
      .subscribe();
  }
}
