import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import { loadCountries, loadCountriesComplete, loadCountriesError } from './countries.actions';
import { of } from 'rxjs';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { CountriesApiService } from '@api-ui-app/src/app/shared/countries/countries-api.service';

@Injectable()
export class CountriesEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private countriesApiService: CountriesApiService,
  ) {}

  loadCountries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadCountries),
      switchMap(action =>
        this.countriesApiService.fetchCountries().pipe(
          map(countriesData => loadCountriesComplete(countriesData)),
          catchError(e => {
            this.store$.dispatch(loadCountriesError(e?.message));
            return of(new ShowGeneralErrorNotification());
          }),
        ),
      ),
    ),
  );
}
