import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import * as R from 'ramda';
import { map } from 'rxjs/operators';
import { SUPPORTED_CURRENCIES } from '@api-ui-app/src/app/lib/constants';
import { DropdownPosition } from '@ng-select/ng-select/lib/ng-select.component';

@Component({
  selector: 'gd-currency-selector, gc-currency-selector',
  templateUrl: './currency-selector.component.html',
  styleUrls: ['./currency-selector.component.scss'],
})
export class CurrencySelectorComponent implements OnChanges {
  @Input() selectedCurrencyId: string;
  @Input() dropdownPosition: DropdownPosition = 'bottom';
  @Input() showAllCurrenciesOption = false;
  @Input() isSearchable = true;
  @Input() isTextView: boolean;
  @Input() disabled: boolean;
  @Input() currencyList = SUPPORTED_CURRENCIES;
  @Input() showAllTextKey = 'txt_all_currencies';
  @Input() showCurrencyTitle: boolean = false;
  @Input() firstCurrencyId: string;
  @Output() currencyIdChange: EventEmitter<string> = new EventEmitter();

  currencyId$ = new BehaviorSubject<string>(undefined);
  currencyList$ = new BehaviorSubject<string[]>(this.currencyList);

  options$ = combineLatest([this.translate.get(this.showAllTextKey), this.currencyList$]).pipe(
    map(([text, currencyList]) => {
      const supportedCurrencies = currencyList ? [...currencyList] : [];
      let options = [];

      if (supportedCurrencies?.length) {
        options = R.map(
          currencyIsoCode => ({
            id: currencyIsoCode,
            name: currencyIsoCode,
            title: this.translate.instant(`txt_currency_${currencyIsoCode}`),
          }),
          supportedCurrencies.sort(),
        );
      }

      if (this.showAllCurrenciesOption) {
        options = [{ id: '', name: text }].concat(options);
      }

      if (this.firstCurrencyId) {
        options = options.reduce((acc, element) => {
          if (element.id === this.firstCurrencyId) {
            element.title = this.translate.instant(`txt_price_settings_store_currency`);
            return [element, ...acc];
          }
          return [...acc, element];
        }, []);
      }

      return options;
    }),
  );

  constructor(private translate: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.currencyList) {
      this.currencyList$.next(changes.currencyList.currentValue);
    }

    if (changes.selectedCurrencyId) {
      this.currencyId$.next(changes.selectedCurrencyId.currentValue);
    }
  }

  handleCurrencyChange(currencyId) {
    this.currencyIdChange.emit(currencyId);
  }
}
