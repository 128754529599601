<ng-select
  [class.text]="isTextView"
  [ngModel]="(currencyId$ | async) || undefined"
  [items]="options$ | async"
  [bindValue]="'id'"
  [bindLabel]="'name'"
  [placeholder]="'txt_select_currency' | translate"
  [clearable]="false"
  [searchable]="isSearchable"
  [readonly]="disabled || !(options$ | async)?.length"
  [virtualScroll]="true"
  [dropdownPosition]="dropdownPosition"
  (ngModelChange)="handleCurrencyChange($event)"
>
  <ng-template ng-option-tmp let-item="item">
    <ng-container *ngIf="showCurrencyTitle">
      <div class="custom-option">
        <span>{{ item.title }}</span>
        <span class="code">
          <span class="checkmark">L</span>
          {{ item.id }}</span
        >
      </div>
    </ng-container>
    <ng-container *ngIf="!showCurrencyTitle">
      {{ item.name }}
    </ng-container>
  </ng-template>
  <ng-template ng-label-tmp let-item="item">
    {{ showCurrencyTitle ? (showAllTextKey | translate) + ' ' + item.id : item.name }}
  </ng-template>
</ng-select>
