import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { getBaseUid } from '@gelato-api-ui/core/sanity/helpers/getBaseUid';
import { ApparelSanityProductCategoryNames } from '@gelato-api-ui/core/sanity/apparel-sanity-product-category-names';
import { SanityProductVariation } from '@gelato-api-ui/core/sanity/sanity-product-variation';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { StoreProductVariantsCollection } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { ApparelAndAccessoriesSanityProductCategoryNames } from '@gelato-api-ui/core/sanity/apparel-and-accessories-sanity-product-category-names';
import { findProductVariationByProductUid } from '@gelato-api-ui/core/sanity/helpers/findProductVariationByProductUid';
import { SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { AccessoriesSanityProductCategoryNames } from '@gelato-api-ui/core/sanity/accessories-sanity-product-category-names';
import { getAppropriateProductUidByPrintSide } from '@gelato-api-ui/core/sanity/helpers/getAppropriateProductUidByPrintSide';
import { PrintSide } from '@gelato-api-ui/core/print-sides/print-side.enum';
import {
  findFirstApplicableProductVariantKey,
  isProductVariantApplicableForMockup,
} from '@gelato-api-ui/core/e-commerce/helpers/isProductVariantKeyApplicableForMockup';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { getProductVariantKey } from '@gelato-api-ui/core/e-commerce/helpers/getProductVariantKey';
import { PrintSidesSorted } from '@gelato-api-ui/core/print-sides/print-sides-sorted';

@Injectable({ providedIn: 'root' })
export class SharedProductVariantPreviewsService {
  private readonly supportedProductCategories = [
    ...ApparelAndAccessoriesSanityProductCategoryNames,
    SanityProductCategoryName.FRAMED_POSTERS,
  ];

  getProductVariantKeyForSharedPreview(
    productVariantsCollection: StoreProductVariantsCollection,
    productVariantKey: string,
    sanityProductCategoryName: SanityProductCategoryName,
    product: SanityProduct,
  ): string {
    if (!this.isProductCategorySupported(sanityProductCategoryName)) {
      return null;
    }

    const similarProductVariation = this.getSimilarProductVariation(
      sanityProductCategoryName,
      product?.productVariations,
      productVariantsCollection,
      productVariantKey,
    );

    if (!similarProductVariation) {
      return null;
    }

    // GPS-1925: Fix for Apparel Products with DTG areas
    return Object.keys(productVariantsCollection).find(
      (loopProductVariantKey: string): boolean =>
        getBaseUid(loopProductVariantKey) === similarProductVariation?.productUid,
    );
  }

  getProductVariantKeysArrayForSharedMockups(
    productVariantsCollection: StoreProductVariantsCollection,
    sanityProductCategoryName: SanityProductCategoryName,
    sanityProduct: SanityProduct,
    productVariantKeysArray: string[],
  ): string[] {
    if (!this.isProductCategorySupported(sanityProductCategoryName)) {
      return null;
    }

    const filteredProductVariantKeys: string[] = productVariantKeysArray.filter((loopProductVariantKey: string) =>
      Boolean(productVariantsCollection[loopProductVariantKey]),
    );

    return R.uniqBy(
      (loopProductVariantKey: string): SanityProductVariation =>
        this.getSimilarProductVariation(
          sanityProductCategoryName,
          sanityProduct?.productVariations,
          productVariantsCollection,
          loopProductVariantKey,
        ),
      filteredProductVariantKeys,
    );
  }

  validatePrimaryPreviewProductVariantKey(
    sourceProductVariantKey: string,
    productVariantsCollection: StoreProductVariantsCollection,
    sanityProductCategoryName: SanityProductCategoryName,
    sanityProduct: SanityProduct,
  ): string {
    const allowedProductVariantKeys: string[] = Object.values(productVariantsCollection).map(
      (loopVariant: EProductVariant): string => getProductVariantKey(loopVariant),
    );

    if (!allowedProductVariantKeys || !allowedProductVariantKeys.length) {
      return null;
    }

    if (sourceProductVariantKey) {
      if (allowedProductVariantKeys.includes(sourceProductVariantKey)) {
        return this.getProductVariantKeyApplicableForMockup(
          sourceProductVariantKey,
          productVariantsCollection,
          sanityProduct,
        );
      }

      if (ApparelAndAccessoriesSanityProductCategoryNames.includes(sanityProductCategoryName)) {
        const productVariantKeyWithAnotherPrintSide = this.getProductUidWithAnotherPrintSide(
          sourceProductVariantKey,
          allowedProductVariantKeys,
          sanityProduct,
        );

        if (productVariantKeyWithAnotherPrintSide) {
          return this.getProductVariantKeyApplicableForMockup(
            productVariantKeyWithAnotherPrintSide,
            productVariantsCollection,
            sanityProduct,
          );
        }
      }
    }

    return this.getProductVariantKeyApplicableForMockup(
      allowedProductVariantKeys[0],
      productVariantsCollection,
      sanityProduct,
    );
  }

  private isProductCategorySupported(productCategoryName: SanityProductCategoryName): boolean {
    return this.supportedProductCategories.includes(productCategoryName);
  }

  private getSimilarProductVariation(
    sanityProductCategoryName: SanityProductCategoryName,
    productVariations: SanityProductVariation[],
    productVariantsCollection,
    productVariantKey: string,
  ) {
    const productUid = productVariantsCollection?.[productVariantKey]?.productUid;

    if (!productUid) {
      return null;
    }

    const baseProductUid = getBaseUid(productUid); // GPS-1925: Fix for Apparel Products with DTG areas

    const productUidsFromVariantsCollection: string[] = R.uniq(
      Object.keys(productVariantsCollection).map((key: string): string =>
        getBaseUid(productVariantsCollection[key]?.productUid),
      ),
    ).filter(Boolean);
    const productVariationsForVariantsFromCollection: SanityProductVariation[] = (productVariations || []).filter(
      (loopProductVariation: SanityProductVariation): boolean =>
        productUidsFromVariantsCollection.includes(loopProductVariation?.productUid),
    );

    const sourceVariation: SanityProductVariation = findProductVariationByProductUid(
      productVariationsForVariantsFromCollection,
      baseProductUid,
    );

    if (!sourceVariation) {
      return null;
    }

    let fieldsToMatch: SanityProductControlKey[];

    if (AccessoriesSanityProductCategoryNames.includes(sanityProductCategoryName as SanityProductCategoryName)) {
      fieldsToMatch = [SanityProductControlKey.bagColor, SanityProductControlKey.bagPrint];
    } else if (ApparelSanityProductCategoryNames.includes(sanityProductCategoryName as SanityProductCategoryName)) {
      fieldsToMatch = [SanityProductControlKey.garmentColor, SanityProductControlKey.garmentPrint];
    } else if (sanityProductCategoryName === SanityProductCategoryName.FRAMED_POSTERS) {
      fieldsToMatch = [
        SanityProductControlKey.formatSize,
        SanityProductControlKey.orientation,
        SanityProductControlKey.frameColor,
      ];
    }

    if (!fieldsToMatch?.length) {
      return null;
    }

    return productVariationsForVariantsFromCollection.find((loopProductVariation: SanityProductVariation): boolean => {
      if (!loopProductVariation.productUid) {
        return false;
      }

      return fieldsToMatch.reduce(
        (isMathing: boolean, loopFieldName: string): boolean =>
          isMathing && loopProductVariation[loopFieldName] === sourceVariation[loopFieldName],
        true,
      );
    });
  }

  private getProductUidWithAnotherPrintSide(
    sourceProductUid: string,
    allowedProductUids: string[],
    sanityProduct: SanityProduct,
  ): string {
    return PrintSidesSorted.reduce((foundProductUid: string, printSide: PrintSide) => {
      if (foundProductUid) {
        return foundProductUid;
      }

      const loopProductUid: string = getAppropriateProductUidByPrintSide(sanityProduct, sourceProductUid, printSide);

      return allowedProductUids.includes(loopProductUid) ? loopProductUid : null;
    }, null);
  }

  private getProductVariantKeyApplicableForMockup = (
    selectedProductVariantKey: string,
    productVariantsCollection: StoreProductVariantsCollection,
    sanityProduct: SanityProduct,
  ): string => {
    if (isProductVariantApplicableForMockup(productVariantsCollection[selectedProductVariantKey], sanityProduct)) {
      return selectedProductVariantKey;
    }

    const applicableProductVariantKey = findFirstApplicableProductVariantKey(productVariantsCollection, sanityProduct);

    return applicableProductVariantKey || selectedProductVariantKey;
  };
}
