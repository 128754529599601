<gc-subscription-flow
  [companyDetails]="companyDetails$ | async"
  [isMobile]="isMobile$ | async"
  [monthServicePlanPrice]="monthServicePlanPrice$ | async"
  [gelatoPlusSubscriptionHasExpired]="gelatoPlusSubscriptionHasExpired$ | async"
  [gelatoPlusGoldIsActive]="gelatoPlusGoldIsActive$ | async"
  [isGelatoPlusActive]="isGelatoPlusActive$ | async"
  [isUserNeverHadGelatoPlusSubscription]="isUserNeverHadGelatoPlusSubscription$ | async"
  [isUserNeverHadGelatoPlusGoldSubscription]="isUserNeverHadGelatoPlusGoldSubscription$ | async"
  [isClientEligibleForGoldSubscription]="isClientEligibleForGoldSubscription$ | async"
  [billingInfoIsFilled]="billingInfoIsFilled$ | async"
  [isTaxLoading]="isTaxLoading$ | async"
  [servicePricePlanTax]="servicePricePlanTax$ | async"
  [gelatoSubscriptionModal]="modal.context.gelatoSubscriptionModal"
  [isGoldTrialNotAvailable]="isGoldTrialNotAvailable$ | async"
>
</gc-subscription-flow>
