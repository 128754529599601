import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import examples from 'libphonenumber-js/examples.mobile.json';
import { getExampleNumber } from 'libphonenumber-js';

@Component({
  selector: 'gd-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberInputComponent implements OnInit, OnChanges {
  @Input()
  phone: string = null;

  @Input()
  country: string = null;

  @Input()
  disabled = false;

  @Output()
  phoneChange: EventEmitter<string> = new EventEmitter();

  placeholder = '';

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.country) {
      if (changes.country.currentValue) {
        const phoneNumber = getExampleNumber(changes.country.currentValue, examples);

        this.placeholder = phoneNumber ? phoneNumber.formatInternational() : '';
      } else {
        this.placeholder = '';
      }
    }
  }

  onPhoneChange(phone: string) {
    this.phoneChange.emit(phone);
  }
}
