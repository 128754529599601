import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { PricePlanSubscription } from '../price-plan-subscriptions/lib/price-plan-subscription';
import { PricePlanSubscriptionSearchRequest } from '../price-plan-subscriptions/lib/price-plan-subscription-search-request';
import { PricePlanSubscriptionSearchResponse } from '../price-plan-subscriptions/lib/price-plan-subscription-search-response';
import * as actions from './price-plan-subscriptions.actions';

export const getState = (state: AppState): State => state.pricePlanSubscriptions;

export const getPricePlanSubscriptionListState = createSelector(
  getState,
  (state: State): PricePlanSubscriptionListState => state.pricePlanSubscriptionList,
);

export const getPricePlanSubscriptionListResponse = createSelector(
  getPricePlanSubscriptionListState,
  (pricePlanSubscriptionListState: PricePlanSubscriptionListState): PricePlanSubscriptionSearchResponse =>
    pricePlanSubscriptionListState.payload,
);

export const getPricePlanSubscriptionState = createSelector(
  getState,
  (state: State): PricePlanSubscriptionState => state.pricePlanSubscription,
);

export const getPricePlanSubscription = createSelector(
  getPricePlanSubscriptionState,
  (pricePlanSubscriptionState: PricePlanSubscriptionState): PricePlanSubscription => pricePlanSubscriptionState.payload,
);

export interface State {
  pricePlanSubscriptionList: PricePlanSubscriptionListState;
  pricePlanSubscription: PricePlanSubscriptionState;
}

export interface PricePlanSubscriptionListState {
  isLoading: boolean;
  request: PricePlanSubscriptionSearchRequest;
  payload: PricePlanSubscriptionSearchResponse;
}

export interface PricePlanSubscriptionState {
  isLoading: boolean;
  payload: PricePlanSubscription;
}

export const initialState: State = {
  pricePlanSubscriptionList: {
    isLoading: false,
    request: null,
    payload: null,
  },
  pricePlanSubscription: {
    isLoading: false,
    payload: null,
  },
};

export function reducer(state = initialState, action: actions.PricePlanSubscriptionsActions): State {
  switch (action.type) {
    case actions.PricePlanSubscriptionsActionTypes.ResetPricePlanSubscriptionListState:
      return {
        ...state,
        pricePlanSubscriptionList: { ...initialState.pricePlanSubscriptionList },
      };
    case actions.PricePlanSubscriptionsActionTypes.ResetPricePlanSubscriptionListPayload:
      return {
        ...state,
        pricePlanSubscriptionList: {
          ...state.pricePlanSubscriptionList,
          payload: null,
        },
      };
    case actions.PricePlanSubscriptionsActionTypes.ResetPricePlanSubscriptionState:
      return {
        ...state,
        pricePlanSubscription: { ...initialState.pricePlanSubscription },
      };
    case actions.PricePlanSubscriptionsActionTypes.SetPricePlanSubscriptionListState:
      return {
        ...state,
        pricePlanSubscriptionList: action.payload,
      };
    case actions.PricePlanSubscriptionsActionTypes.SetPricePlanSubscriptionState:
      return {
        ...state,
        pricePlanSubscription: action.payload,
      };
    default:
      return state;
  }
}
