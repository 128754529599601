<div
  class="container flex-row flex-justify-between flex-items-center"
  [class.selected]="isChecked"
  (click)="toggleItem(null)"
>
  <div class="flex-row flex-justify-start flex-items-center">
    <gd-checkbox *ngIf="multipleSelectionEnabled" [checked]="isChecked" (checkedChange)="toggleItem($event)">
    </gd-checkbox>

    <gd-radio-button
      *ngIf="!multipleSelectionEnabled"
      [value]="item?.id"
      [checked]="isChecked"
      (checkedChange)="toggleItem($event)"
    >
    </gd-radio-button>

    <gc-thumbnail width="30" height="30" [url]="thumbnailUrl"></gc-thumbnail>

    <div>
      <p class="title">
        {{ item?.title }}
      </p>
    </div>
  </div>
</div>
