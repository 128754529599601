import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';

@Component({
  selector: 'gd-stores-list',
  templateUrl: './stores-list.component.html',
  styleUrls: ['./stores-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoresListComponent {
  @Input() items: EStore[] = [];
  @Input() title: string;
  @Output() itemSelect: EventEmitter<EStore> = new EventEmitter<EStore>();

  selectItem(item: EStore) {
    this.itemSelect.emit(item);
  }
}
