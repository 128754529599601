<ng-template [ngIf]="total > limit">
  <div class="page-selector">
    <ng-container *ngFor="let shownPageNumbers of shownPageNumberSections; let lastSection = last">
      <button
        *ngFor="let pageNumber of shownPageNumbers"
        class="ui button"
        [class.active]="pageNumber === activePageNumber"
        (click)="selectPage(pageNumber)"
      >
        {{ pageNumber }}
      </button>
      <button class="ui button basic" disabled *ngIf="!lastSection">...</button>
    </ng-container>
  </div>
</ng-template>
