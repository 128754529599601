import { SanityRelatedProduct, SanityRelatedProducts } from '@gelato-api-ui/core/sanity/SanityRelatedProducts';

export const getRelatedProduct = (
  relatedProducts: SanityRelatedProducts[],
  productUid: string,
  parentProductUid: string,
): SanityRelatedProduct => {
  const parent = relatedProducts.find(products => products.uid === parentProductUid);
  return parent ? parent.products.find(product => product.uid === productUid) : null;
};
