import { RouterNavigationAction, routerReducer, RouterReducerState } from '@ngrx/router-store';

import { combineReducers } from '@ngrx/store';
import { RouterStateUrl } from '@gelato-api-ui/sdk/src/lib/router/custom-serializer';
import coreReducer, { All as fromCoreAll, CoreState } from '@gelato-api-ui/product-catalogue/sdk/src/lib/sdk.reducer';
import { reducer as fromConfig, State as ConfigReducerState } from './ngrx/config.reducer';
import { reducer as fromProductCatalogue, State as CatalogueState } from './ngrx/product-catalogue.reducer';
import { reducer as fromProductPrices, State as ProductPricesState } from './ngrx/product-prices.reducer';
import {
  reducer as fromFlatShipmentPrices,
  State as FlatShipmentPricesState,
} from './ngrx/flat-shipment-prices.reducer';
import {
  reducer as fromPrintFileVerification,
  State as PrintFileVerificationState,
} from './ngrx/print-file-verification.reducer';
import { reducer as fromAssets, State as AssetsState } from './ngrx/assets.reducer';
import {
  reducer as fromStockAvailabilitySummary,
  State as StockAvailabilitySummaryState,
} from './ngrx/stock-availability-summary.reducer';
import {
  reducer as fromProductTypesGalleryCollections,
  State as ProductTypesGalleryCollectionsState,
} from './ngrx/gallery-collections.reducer';
import {
  reducer as fromAppsAndServicesReducer,
  State as fromAppsAndServicesState,
} from './ngrx/apps-and-services.reducer';
import { reducer as fromPriceList, State as PriceListState } from './ngrx/price-list.reducer';

export type All = fromCoreAll | RouterNavigationAction;

export interface ProductCatalogueState extends CoreState {
  config: ConfigReducerState;
  productCatalogue: CatalogueState;
  productPrices: ProductPricesState;
  flatShipmentPrices: FlatShipmentPricesState;
  printFileVerification: PrintFileVerificationState;
  assetsMapping: AssetsState;
  stockAvailabilitySummary: StockAvailabilitySummaryState;
  priceList: PriceListState;
  galleryCollections: ProductTypesGalleryCollectionsState;
  routerReducer: RouterReducerState<RouterStateUrl>;
  appsAndServices: fromAppsAndServicesState;
}

export interface ProductState {
  products: ProductCatalogueState;
}

export const productCatalogueReducer = combineReducers({
  core: coreReducer,
  config: fromConfig,
  productCatalogue: fromProductCatalogue,
  productPrices: fromProductPrices,
  flatShipmentPrices: fromFlatShipmentPrices,
  printFileVerification: fromPrintFileVerification,
  assets: fromAssets,
  stockAvailabilitySummary: fromStockAvailabilitySummary,
  priceList: fromPriceList,
  galleryCollections: fromProductTypesGalleryCollections,
  routerReducer,
  appsAndServices: fromAppsAndServicesReducer,
});

export default productCatalogueReducer;
