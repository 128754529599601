import { Injectable } from '@angular/core';
import { EProductPublishScope } from '@gelato-api-ui/core/e-commerce/e-product-publish-scope.enum';
import { EProductPublishScopes } from '@gelato-api-ui/core/e-commerce/e-product-publish-scopes';
import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';
import { getMetadataItemValue } from '@gelato-api-ui/core/metadata/helpers/getMetadataItemValue';
import { EProductMetadataKey } from '@gelato-api-ui/core/e-commerce/e-product-metadata-key.enum';
import { EProductPublishMode } from '@api-ui-app/src/app/e-commerce-stores/types/e-product-publish-mode.enum';

@Injectable({ providedIn: 'root' })
export class ECommerceProductPublishSettingsService {
  getPublishScopesFromMetadata(metadata: MetadataItem[]): EProductPublishScope[] {
    try {
      const value = getMetadataItemValue(metadata, EProductMetadataKey.PUBLISH_SCOPES) as string;

      return JSON.parse(value);
    } catch (e) {
      return EProductPublishScopes;
    }
  }

  getPublishModeFromMetadata(metadata: MetadataItem[]): EProductPublishMode {
    return getMetadataItemValue(metadata, EProductMetadataKey.PUBLISH_MODE) as EProductPublishMode;
  }
}
