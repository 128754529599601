import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppStoreState } from '@api-ui-app/src/app/app-store/+state/app-store.reducer';
import * as selectors from '@api-ui-app/src/app/app-store/+state/app-store.selector';
import * as actions from '@api-ui-app/src/app/app-store/+state/app-store.actions';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { ResponsiveService } from '@gelato-api-ui/core/responsive/responsive.service';
import { GelatoSubscriptionModalType } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-subscription-modal';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  PlansComparisonRawRow,
  PlansComparisonTableCellType,
  PlansComparisonTable,
} from '@api-ui-app/src/app/subscriptions/types/compare-table';
import { UserCurrencyService } from '@api-ui-app/src/app/shared/services/user-currency.service';
import { MoneyPipe } from '@gelato-api-ui/sdk/src/lib/pipes/format/money.pipe';
import { AppStoreService } from '@api-ui-app/src/app/app-store/services/app-store.service';
import { activateSubscription } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.actions';
import { SubscriptionPlanType } from '../types/price-plan-items';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';

@Injectable({
  providedIn: 'root',
})
export class AppsListFacade {
  readonly isLoading$ = this.store.select(selectors.getIsAppsListLoading);
  readonly isMobile$ = this.responsiveService.isMobile$;
  readonly appsList$ = this.store.select(selectors.getAppsListPayload);
  readonly appsListSanityContent$ = this.store.select(selectors.getAppsListSanityContentPayload);
  readonly activeServiceUniqueNames$ = this.subscriptionsFacade.activeServiceUniqueNames$;
  readonly showGelatoPlusNotification$ = this.subscriptionsFacade.showGelatoPlusCrown$;
  readonly activePlansIsUpdating$ = this.subscriptionsFacade.activePlansIsUpdating$;
  readonly isGelatoPlusActive$ = this.subscriptionsFacade.gelatoPlusIsActive$;
  readonly isGelatoPlusOrGoldActive$ = this.subscriptionsFacade.gelatoPlusOrGoldIsActive$;
  readonly gelatoPlusGoldIsActive$ = this.subscriptionsFacade.gelatoPlusGoldIsActive$;
  readonly isNonRenewingSubscriptionEnding$ = this.subscriptionsFacade.isNonRenewingSubscriptionEnding$;
  readonly pricePlansListItems$ = this.subscriptionsFacade.pricePlansListItems$;
  readonly pricePlansLoading$ = this.subscriptionsFacade.pricePlansLoading$;
  readonly gelatoPlusMonthPriceOnYearlyPlan$ = this.subscriptionsFacade.gelatoPlusMonthPriceOnYearlyPlan$;
  readonly gelatoPlusYearSubscriptionPrice$ = this.subscriptionsFacade.gelatoPlusYearSubscriptionPrice$;
  readonly gelatoPlusMonthSubscriptionPrice$ = this.subscriptionsFacade.gelatoPlusMonthSubscriptionPrice$;
  readonly gelatoPlusSubscriptionHasExpired$ = this.subscriptionsFacade.gelatoPlusSubscriptionHasExpired$;
  readonly gelatoGoldMonthPriceOnYearlyPlan$ = this.subscriptionsFacade.gelatoGoldMonthPriceOnYearlyPlan$;
  readonly gelatoGoldAnnualPrice$ = this.subscriptionsFacade.gelatoGoldYearSubscriptionPrice$;
  readonly gelatoGoldMonthlyPrice$ = this.subscriptionsFacade.gelatoGoldMonthSubscriptionPrice$;
  readonly isActiveSubscriptionsLoaded$ = this.subscriptionsFacade.isActiveSubscriptionsLoaded$;
  readonly isUserNeverHadGelatoSubscription$ = this.subscriptionsFacade.isUserNeverHadGelatoSubscription$;
  readonly isUserNeverHadGelatoPlusSubscription$ = this.subscriptionsFacade.isUserNeverHadGelatoPlusSubscription$;
  readonly isUserNeverHadGelatoPlusGoldSubscription$ =
    this.subscriptionsFacade.isUserNeverHadGelatoPlusGoldSubscription$;
  readonly isGelatoPlusTrialActive$ = this.subscriptionsFacade.isGelatoPlusTrialActive$;
  readonly isGelatoPlusGoldTrialActive$ = this.subscriptionsFacade.isGelatoPlusGoldTrialActive$;
  readonly isGelatoPlusTrialScheduledToCancel$ = this.subscriptionsFacade.isGelatoPlusTrialScheduledToCancel$;
  readonly isGelatoPlusGoldTrialScheduledToCancel$ = this.subscriptionsFacade.isGelatoPlusGoldTrialScheduledToCancel$;
  readonly currency$ = this.userCurrencyService.get();
  readonly pricesRows$ = combineLatest([
    this.gelatoPlusMonthPriceOnYearlyPlan$,
    this.gelatoPlusYearSubscriptionPrice$,
    this.gelatoPlusMonthSubscriptionPrice$,
    this.gelatoGoldMonthPriceOnYearlyPlan$,
    this.gelatoGoldAnnualPrice$,
    this.gelatoGoldMonthlyPrice$,
    this.userCurrencyService.get(),
  ]).pipe(
    map(
      ([
        monthPlusPriceOnAnnualPlan,
        annualPlusPrice,
        monthPlusPrice,
        monthGoldPriceOnAnnualPlan,
        gelatoGoldAnnualPrice,
        gelatoGoldMonthlyPrice,
        userCurrency,
      ]) => {
        const monthlyPriceRow: PlansComparisonRawRow = [
          { value: 'txt_comparison_table_monthly_table' },
          {
            value: this.moneyPipe.transform(0, userCurrency, false, false),
            currency: userCurrency,
          },
          {
            value: monthPlusPrice,
            currency: userCurrency,
          },
          { value: gelatoGoldMonthlyPrice, currency: userCurrency },
          { value: PlansComparisonTableCellType.price },
        ];
        const annualPriceRow: PlansComparisonRawRow = [
          { value: 'txt_comparison_table_annual_table' },
          {
            value: this.moneyPipe.transform(0, userCurrency, false, false),
            currency: userCurrency,
          },
          {
            value: annualPlusPrice,
            currency: userCurrency,
            pricePerMonth: monthPlusPriceOnAnnualPlan,
          },
          {
            value: gelatoGoldAnnualPrice,
            currency: userCurrency,
            pricePerMonth: monthGoldPriceOnAnnualPlan,
          },
          { value: PlansComparisonTableCellType.price },
        ];

        return [annualPriceRow, monthlyPriceRow];
      },
    ),
  );

  subscriptionPageEnabledAB$ = this.featureSwitcherService.isFeatureEnabledForCurrentUser(
    FeatureFlagEnum.subscription_page_ab,
  );

  plansComparisonTable$ = combineLatest([
    this.appsListSanityContent$,
    this.pricesRows$,
    this.subscriptionPageEnabledAB$,
  ]).pipe(
    map(([appsListSanityContent, pricesRows]) =>
      this.appStoreService.mapPlansComparisonTable(appsListSanityContent, pricesRows),
    ),
  );

  plansComparisonTableData$ = this.appsListSanityContent$.pipe(
    map(appsListSanityContent => this.appStoreService.getPlansComparisonTableData(appsListSanityContent)),
  );

  plansComparisonTableSections$ = this.plansComparisonTable$.pipe(
    map(plansComparisonTableRows =>
      this.appStoreService.mapPlansComparisonTableToSections(plansComparisonTableRows as PlansComparisonTable),
    ),
  );

  constructor(
    private readonly store: Store<AppStoreState>,
    private readonly moneyPipe: MoneyPipe,
    private readonly subscriptionsFacade: SubscriptionsFacade,
    private readonly responsiveService: ResponsiveService,
    private readonly userCurrencyService: UserCurrencyService,
    private readonly appStoreService: AppStoreService,
    private readonly featureSwitcherService: FeatureSwitcherService,
  ) {}

  load() {
    this.subscriptionsFacade.loadActiveSubscriptionsAndServicePlans();
    this.store.dispatch(actions.loadAppsList());
  }

  onScreenSizeChanges() {
    this.responsiveService.onScreenSizeChanges();
  }

  onRemoveScreenSizeChanges() {
    this.responsiveService.onRemoveScreenSizeChanges();
  }

  openMenagePlanModal(): void {
    this.store.dispatch(actions.managePlanModal());
  }

  openSubscriptionsModal(gelatoSubscriptionModalType?: GelatoSubscriptionModalType): void {
    this.store.dispatch(actions.openSubscriptionsModal({ gelatoSubscriptionModalType }));
  }

  activateSubscription(subscriptionType: SubscriptionPlanType): void {
    this.store.dispatch(activateSubscription({ subscriptionType }));
  }
}
