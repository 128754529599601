import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationTreeService } from '../sidebar-nav/navigation-tree.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'gd-sidebar-mobile',
  templateUrl: './sidebar-mobile.component.html',
  styleUrls: ['./sidebar-mobile.component.scss'],
})
export class SidebarMobileComponent implements OnInit, OnDestroy {
  @Input() isVisible = false;
  @Input() isGelatoPlusSubscriptionEnding: boolean;
  @Input() endingSubscriptionDaysLeft: number;
  @Output() toggle: EventEmitter<boolean> = new EventEmitter();

  ngOnDestroy$ = new Subject();

  constructor(private readonly navigationTreeService: NavigationTreeService) {}

  get navigationTree$() {
    return this.navigationTreeService.tree;
  }

  ngOnInit() {
    this.navigationTreeService.toggleMobileSidebarTrigger.pipe(takeUntil(this.ngOnDestroy$)).subscribe(() => {
      this.toggleSidebar();
    });
  }

  ngOnDestroy() {
    this.ngOnDestroy$.next(true);
    this.ngOnDestroy$.complete();
  }

  toggleSidebar() {
    this.toggle.emit(!this.isVisible);
  }
}
