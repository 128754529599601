import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { TranslatedStoreTypeService } from '@api-ui-app/src/app/e-commerce-stores/services/translated-store-type.service';

@Pipe({
  name: 'storeType',
})
export class StoreTypePipe implements PipeTransform {
  constructor(private readonly service: TranslatedStoreTypeService) {}

  transform(value: EStoreType): Observable<string> {
    return this.service.get(value);
  }
}
