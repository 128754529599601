export enum DesignEditorTabName {
  PRODUCT = 'PRODUCT',
  LAYERS = 'LAYERS',
  GALLERY = 'GALLERY',
  TEXTS = 'TEXTS',
  TEMPLATES = 'TEMPLATES',
  WARNINGS = 'WARNINGS',
  LAYOUTS = 'LAYOUTS',
  SETTINGS = 'SETTINGS',
  SHUTTERSTOCK = 'SHUTTERSTOCK',
  ADDONS = 'ADDONS',
  VARIANTS = 'VARIANTS',
  SHAPES = 'SHAPES',
}
