import { createFeatureSelector, createSelector } from '@ngrx/store';
import { State } from './previews-archive.reducer';
import { FEATURE_NAME } from '@api-ui-app/src/app/previews-archive/lib/preview-archive';

export const getFeatureState = createFeatureSelector<State>(FEATURE_NAME);
export const getPreviewsArchiveLoading = createSelector(getFeatureState, (state: State): boolean => state.isLoading);
export const getPreviewsArchiveUrl = createSelector(getFeatureState, (state: State): string => state.url);
export const getPreviewsArchiveTaskIds = createSelector(getFeatureState, (state: State): string[] => state.taskIds);
export const getPreviewsArchiveProductTitles = createSelector(
  getFeatureState,
  (state: State): string[] => state.productTitles,
);
export const getPreviewsArchivePreviewsAmount = createSelector(
  getFeatureState,
  (state: State): number[] => state.previewsAmounts,
);
