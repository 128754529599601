export enum OrderChannel {
  UI = 'ui',
  APP = 'app',
  API = 'api',
  SHOPIFY = 'shopify',
  ETSY = 'etsy',
  WOOCOMMERCE = 'woocommerce',
  ORDER_DESK = 'order-desk',
  WIX = 'wix',
  SQUARESPACE = 'squarespace',
  BIGCOMMERCE = 'bigcommerce',
}
