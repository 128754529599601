import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { first, map, mergeMap, tap } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { PreflightPreviewsArchiveRequest } from '@gelato-api-ui/core/preflight/preflight-previews-download';
import {
  cancelPreviewsArchiveRequest,
  requestPreviewsArchive,
  setPreviewsArchiveUrl,
} from './previews-archive.actions';
import {
  getSelectedProductCategoryName,
  getSelectedProductFromSanityContent,
  getSelectedProductName,
} from '@product-catalogue/src/lib/ngrx/product-catalogue.reducer';
import {
  getPreviewsArchiveLoading,
  getPreviewsArchivePreviewsAmount,
  getPreviewsArchiveProductTitles,
  getPreviewsArchiveTaskIds,
} from './previews-archive.selectors';
import { getAssetsCollection } from '@product-catalogue/src/lib/ngrx/assets.selector';
import {
  getSelectedPreviewScenesCount,
  getStoreProductVariantsCollection,
} from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.selector';
import { getContentIdsFromDesignData } from '@gelato-api-ui/core/designs/helpers/getContentIdsFromDesignData';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { ShutterstockAssetProviderUids } from '@gelato-api-ui/core/shutterstock/shutterstock.constant';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { PreflightTaskState } from '@gelato-api-ui/core/preflight/preflight-task-state.enum';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { PreflightTaskStatusResponse } from '@gelato-api-ui/core/preflight/preflight-task-status-response';

@Injectable()
export class PreviewsArchiveFacade {
  sanityProductCategoryName$ = this.store.select(getSelectedProductCategoryName);
  sanityProductName$ = this.store.select(getSelectedProductName);
  previewsArchiveIsLoading$ = this.store.select(getPreviewsArchiveLoading);
  previewsArchiveTasks$ = this.store.select(getPreviewsArchiveTaskIds);
  previewsArchiveProductTitles$ = this.store.select(getPreviewsArchiveProductTitles);
  previewsArchivePreviewsAmount$ = this.store.select(getPreviewsArchivePreviewsAmount);
  assetsCollection$ = this.store.select(getAssetsCollection);
  selectedPreviewScenesCount$ = this.store.select(getSelectedPreviewScenesCount);
  productVariantsCollection$ = this.store.select(getStoreProductVariantsCollection);
  selectedProductFromSanityContent$ = this.store.select(getSelectedProductFromSanityContent);

  isDownloadPreviewsAvailable$ = combineLatest([
    this.selectedPreviewScenesCount$,
    this.productVariantsCollection$,
  ]).pipe(
    mergeMap(([selectedPreviewScenesCount, productVariantsCollection]) => {
      if (!selectedPreviewScenesCount) {
        return of(false);
      }

      const observables$ = Object.keys(productVariantsCollection).map(uid => {
        const variant = productVariantsCollection[uid];
        const designStructure = JSON.parse(variant.designStructureJson);
        return this.isShutterstockImagesUsedInDesign(designStructure);
      });

      return combineLatest(observables$).pipe(
        first(),
        map((results: boolean[]) => results.every(e => !Boolean(e))),
      );
    }),
  );

  constructor(private readonly store: Store<AppState>) {}

  requestPreviewsArchive(request: PreflightPreviewsArchiveRequest, previewsAmount: number) {
    return this.store.dispatch(
      requestPreviewsArchive({
        payload: {
          request,
          previewsAmount,
        },
      }),
    );
  }

  cancelPreviewsArchiveDownload(taskId: string) {
    this.store.dispatch(cancelPreviewsArchiveRequest({ payload: taskId }));
  }

  trackPreviewsToDownload(previewsToDownloadCount: number) {
    combineLatest([this.sanityProductCategoryName$, this.sanityProductName$])
      .pipe(
        first(),
        tap(([productCategory, productName]) => {
          logEvent('mockupDownloadButtonClicked', {
            productCategory,
            productName,
            mockupsDownloadedTotal: previewsToDownloadCount,
          });
        }),
      )
      .subscribe();
  }

  isShutterstockImagesUsedInDesign(designData: DesignStructure) {
    return this.assetsCollection$.pipe(
      mergeMap((assetsCollection: AssetsCollection) => {
        const shutterstockContentIds = getContentIdsFromDesignData(
          assetsCollection,
          designData,
          ShutterstockAssetProviderUids,
        );
        return of(Boolean(shutterstockContentIds.length));
      }),
    );
  }

  handleDownloadTaskCheckResponse(response: PreflightTaskStatusResponse) {
    if (response.state === PreflightTaskState.SUCCESS && response.result?.url && response.task_id) {
      const payload = {
        url: response.result.url,
        taskId: response.task_id,
      };
      return this.store.dispatch(setPreviewsArchiveUrl({ payload }));
    }

    if (response.state === PreflightTaskState.FAILURE) {
      const payload = {
        url: null,
        taskId: response.task_id,
      };

      this.store.dispatch(new ShowGeneralErrorNotification());
      return this.store.dispatch(setPreviewsArchiveUrl({ payload }));
    }
  }
}
