<div
  class="wrapper"
  [ngStyle]="{ 'font-size': textFontSize }"
  [ngClass]="{
    'redirect-link': redirectOnClick,
    warning: daysLeft <= WARNING_GELATO_PLUS_DAYS_LEFT,
    floating: floatingMode,
    hide: floatingMode && daysLeft > WARNING_GELATO_PLUS_DAYS_LEFT,
    menu: isMenuItem
  }"
  (click)="openPlusModal()"
>
  <span class="text">
    <span *ngIf="showTextInBrackets">(</span>
    <span>{{ daysLeft }}</span>
    <span *ngIf="!floatingMode"> {{ text }}</span>
    <span *ngIf="showTextInBrackets">)</span>
  </span>
</div>
