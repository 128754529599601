import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { ProductionConfiguration } from '../lib/production-configuration';
import { ProductionConfigurationSearchRequest } from '../lib/production-configuration-search-request';
import { ProductionConfigurationSearchResponse } from '../lib/production-configuration-search-response';
import { ProductionConfigurationCreateRequest } from '../lib/production-configuration-create-request';
import { ProductionConfigurationUpdateRequest } from '../lib/production-configuration-update-request';

@Injectable({
  providedIn: 'root',
})
export class ProductionConfigurationsApiService {
  constructor(private apiRequest: ApiRequestService) {}

  search(request: ProductionConfigurationSearchRequest): Observable<ProductionConfigurationSearchResponse> {
    return this.apiRequest
      .post<ProductionConfigurationSearchResponse>('/api/v3/production/configurations:search', { body: request })
      .pipe(
        map((data: any) => ({
          data: data.data,
          pagination: data.pagination,
        })),
      );
  }

  get(id: string): Observable<ProductionConfiguration> {
    if (!id) {
      return of(null);
    }

    return this.apiRequest
      .get<ProductionConfiguration>(`/api/v3/production/configurations/${id}`)
      .pipe(map(data => data.data));
  }

  create(request: ProductionConfigurationCreateRequest): Observable<ProductionConfiguration> {
    return this.apiRequest
      .post<ProductionConfiguration>('/api/v3/production/configurations', {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  update(id: string, request: ProductionConfigurationUpdateRequest): Observable<ProductionConfiguration> {
    return this.apiRequest
      .put<ProductionConfiguration>(`/api/v3/production/configurations/${id}`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  save(productionConfiguration: ProductionConfiguration): Observable<ProductionConfiguration> {
    if (productionConfiguration.id) {
      const updateRequest = new ProductionConfigurationUpdateRequest(
        productionConfiguration.name,
        productionConfiguration.conditions,
        productionConfiguration.actions,
        productionConfiguration.status,
      );

      return this.update(productionConfiguration.id, updateRequest);
    }

    const createRequest = new ProductionConfigurationCreateRequest(
      productionConfiguration.clientId,
      productionConfiguration.name,
      productionConfiguration.conditions,
      productionConfiguration.actions,
      productionConfiguration.status,
    );

    return this.create(createRequest);
  }

  delete(id: string): Observable<ProductionConfiguration> {
    return this.apiRequest
      .delete<ProductionConfiguration>(`/api/v3/production/configurations/${id}`)
      .pipe(map(data => data.data));
  }
}
