import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { DropdownMenuComponent } from '@gelato-api-ui/sdk/src/lib/shared/dropdown-menu/dropdown-menu.component';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { OrderItem } from '@api-ui-app/src/app/orders/lib/order-item';

@Component({
  selector: 'gd-product-add-button',
  templateUrl: './product-add-button.component.html',
  styleUrls: ['./product-add-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductAddButtonComponent {
  @Input() disabled: boolean;
  @Input() isMobile: boolean;
  @Input() isLoading: boolean;
  @Input() buttonType = 'primary';
  @Input() positionLeft = false;
  @Input() canAddNewProduct = true;
  @Input() canAddExistingProduct = true;
  @Input() connectedItems: OrderItem[];
  @Input() canConnectExistingProducts = false;
  @Input() showAddBranding = false;
  @Output() addNewProductClick = new EventEmitter<void>();
  @Output() addExistingProductClick = new EventEmitter<void>();
  @Output() connectExistingProductsClick = new EventEmitter<void>();
  @Output() brandingAdd = new EventEmitter<void>();
  @ViewChild('dropdownMenu') dropdownMenu: DropdownMenuComponent;

  get showDropDownMenu() {
    return (
      [this.canAddNewProduct, this.canAddExistingProduct, this.canConnectExistingProducts, this.showAddBranding].filter(
        value => !!value,
      ).length > 1
    );
  }

  addButtonClick() {
    logEvent('AddMoreProducts', { productIds: null, productUid: null });
  }

  addBranding(): void {
    if (this.connectedItems?.length) {
      this.brandingAdd.emit();
    }
  }

  onAddNewProductClick(event: Event) {
    this.onAction(event);
    this.addNewProductClick.emit();
  }

  onAddExistingProductClick(event: Event) {
    this.onAction(event);
    this.addExistingProductClick.emit();
  }

  onConnectExistingProductsClick(event: Event) {
    this.onAction(event);
    this.connectExistingProductsClick.emit();
  }

  private onAction(event: Event) {
    if (this.dropdownMenu) {
      this.dropdownMenu.closeMenu(event);
    } else {
      event.stopPropagation();
      event.preventDefault();
    }
  }
}
