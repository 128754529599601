import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WalletState } from './wallet.adapter';
import { walletFeatureName } from './wallet.feature';
import { Wallet } from '../../lib/api';

const getWalletsState = createFeatureSelector<WalletState>(walletFeatureName);
const isFetchingComplete = createSelector(getWalletsState, state => state.isFetched === true);
const isFetchingStarted = createSelector(getWalletsState, state => state.isFetched === false);
const isFetchingToBeDone = createSelector(
  isFetchingComplete,
  isFetchingStarted,
  (isComplete, isStarted) => !isComplete && !isStarted,
);
const getAll = createSelector(getWalletsState, state => Object.values(state.entities));
const getActiveSorted = createSelector(getAll, list => sortByCurrency(list.filter(isActiveWallet)));
const getInactiveSorted = createSelector(getAll, list => sortByCurrency(list.filter(w => !isActiveWallet(w))));
const getIds = createSelector(getWalletsState, state => Object.keys(state.entities));
const getByUid = (uid: string) => createSelector(getWalletsState, state => state.entities[uid]);
const getByCurrency = (currency: string) =>
  createSelector(getAll, list => list.find(w => w.currencyIsoCode === currency));
export const walletsQuery = {
  getAll,
  getIds,
  getByUid,
  getActiveSorted,
  getInactiveSorted,
  isFetchingComplete,
  isFetchingStarted,
  isFetchingToBeDone,
  getByCurrency,
};

export function isActiveWallet(wallet: Wallet) {
  return wallet.balance > 0 || wallet.primaryPaymentDetailsUid || wallet.topUpType === 'automatic';
}

function sortByCurrency(list: Wallet[]) {
  return [...list].sort((a, b) => {
    if (a.currencyIsoCode > b.currencyIsoCode) {
      return 1;
    }

    if (b.currencyIsoCode > a.currencyIsoCode) {
      return -1;
    }

    return 0;
  });
}
