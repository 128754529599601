import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import { loadImage } from '@gelato-api-ui/core/dom/helpers/loadImage';

@Component({
  selector: 'gc-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThumbnailComponent implements OnChanges, OnDestroy {
  @Input() width = 100;
  @Input() height = 100;
  @Input() url = null;
  @Input() showBorder = false;
  @Input() showLoadingSpinner = true;
  @Input() isLoading = false;

  loadedImageUrl: string = null;
  isDestroyed = false;

  constructor(private readonly ref: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url) {
      const url = changes.url.currentValue;
      const previousUrl = changes.url.previousValue;

      if (url) {
        if (url !== previousUrl) {
          if (this.showLoadingSpinner) {
            this.isLoading = true;
          }

          loadImage(url)
            .then(() => {
              this.loadedImageUrl = url;
              this.isLoading = false;
              this.detectChanges();
            })
            .catch(() => {
              this.loadedImageUrl = null;
              this.isLoading = false;
              this.detectChanges();
            });
        }
      } else {
        this.loadedImageUrl = null;
        this.isLoading = false;
      }
    }
  }

  detectChanges() {
    if (this.isDestroyed) {
      return;
    }

    this.ref.detectChanges();
  }

  ngOnDestroy() {
    this.isDestroyed = true;
  }
}
