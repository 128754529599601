import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import { setFlag } from './page-leave-confirmation.actions';
import { isEnabled } from './page-leave-confirmation.selector';

@Injectable()
export class PageLeaveConfirmationService {
  isEnabled$ = this.store.select(isEnabled);

  constructor(private readonly store: Store<AppState>, private readonly router: Router) {}

  init() {
    this.enable();
  }

  enable() {
    this.store.dispatch(setFlag({ payload: true }));
  }

  disable() {
    this.store.dispatch(setFlag({ payload: false }));
  }

  navigateWithoutConfirmation(commands: any[], extras?: NavigationExtras) {
    this.disable();
    this.isEnabled$
      .pipe(
        filter((enabled: boolean) => !enabled),
        take(1),
        tap(() => this.router.navigate(commands, extras)),
      )
      .subscribe();
  }

  canDeactivate(currentState, nextState, confirmationModalCallback): Promise<boolean> {
    return new Promise(resolve => {
      if (currentState.url === nextState.url) {
        resolve(true);
        return;
      }

      this.isEnabled$
        .pipe(
          take(1),
          tap((enabled: boolean) => {
            if (enabled) {
              confirmationModalCallback()
                .onApprove(() => {
                  // GAPI-4508: Prevent showing the modal twice (editor -> checkout transition)
                  this.disable();
                  resolve(true);
                })
                .onDeny(() => {
                  resolve(false);
                });
            } else {
              resolve(true);
            }
          }),
        )
        .subscribe();
    });
  }

  initAfterRedirect() {
    setTimeout(() => this.init());
  }

  destroy() {
    this.disable();
  }
}
