<div class="ui segment box-shadow-new" [class.selected]="isChecked">
  <div class="flex-row flex-justify-between flex-items-stretch">
    <div
      class="product-variants-selection-toggle flex-row flex-justify-start flex-items-center flex-1"
      (click)="toggleProduct()"
    >
      <gd-checkbox
        *ngIf="multipleSelectionEnabled"
        [partial]="isCheckedPartially"
        [checked]="isChecked"
        (checkedChange)="toggleProduct()"
      ></gd-checkbox>

      <gc-thumbnail width="54" height="54" [url]="thumbnailUrl"></gc-thumbnail>

      <div>
        <p>
          <strong>{{ item?.title }}</strong>
        </p>

        <p class="variants-count-label">
          <ng-container *ngIf="variantsCount === 1">
            {{ 'txt_variants_count_label_singular' | translate }}
          </ng-container>
          <ng-container *ngIf="variantsCount !== 1">
            {{ 'txt_variants_count_label_plural' | translate: { count: variantsCount } }}
          </ng-container>
        </p>
      </div>
    </div>
    <div
      *ngIf="item?.variants?.length && isSelectVariants && showExpandVariantsButton"
      class="product-variants-visibility-toggle"
      (click)="toggleVariantsVisibility()"
    >
      <i *ngIf="!expandedVariants" class="icon chevron down"></i>
      <i *ngIf="expandedVariants" class="icon chevron up"></i>
    </div>
  </div>

  <ng-container *ngIf="expandedVariants">
    <ng-container *ngFor="let variant of item?.variants">
      <gd-store-product-variants-list-item
        [item]="variant"
        [storeType]="storeType"
        [multipleSelectionEnabled]="multipleSelectionEnabled"
        [isChecked]="checkedProductVariantIds && checkedProductVariantIds.indexOf(variant?.id) !== -1"
        (isCheckedChange)="toggleProductVariant(variant?.id, $event)"
      >
      </gd-store-product-variants-list-item>
    </ng-container>
  </ng-container>
</div>
