import { DesignPage, DesignSpread, DesignStructure } from '../design-structure';
import { DesignPageMediaItemType } from '../constants/design-structure.constant';

const NOT_EMPTY_TYPES = new Set([
  DesignPageMediaItemType.TEXT,
  DesignPageMediaItemType.LINE,
  DesignPageMediaItemType.RECTANGLE,
]);

const isEmptyPage = (page: DesignPage): boolean => {
  if (!page?.groups?.media?.length) {
    return true;
  }

  return page.groups.media.every(element => {
    if (element.type === DesignPageMediaItemType.IMAGE) {
      return !element.imageId;
    }

    return !NOT_EMPTY_TYPES.has(element.type);
  });
};

export const isEmptySpread = (spread: DesignSpread): boolean => {
  if (!spread?.pages) {
    return true;
  }

  return spread.pages.every(isEmptyPage);
};

export const isEmptyDesignStructure = (designStructure: DesignStructure): boolean => {
  if (!designStructure?.spreads) {
    return true;
  }

  return designStructure.spreads.every(isEmptySpread);
};
