import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { getAppVersion } from '@gelato-api-ui/core/analytics/helpers/getAppVersion';
import 'hammerjs';
import { sentryIgnoreErrors } from '@gelato-api-ui/core/sentry/sentry-ignore-errors';

if (environment.production) {
  Sentry.init({
    dsn: 'https://8eb9ba1ba9744e2e90b2730c379e89b5@o297899.ingest.sentry.io/5468797',
    environment: environment.env,
    release: environment.version,
    ignoreErrors: sentryIgnoreErrors,
  });

  Sentry.setTag('app.version', getAppVersion());

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
