import { Inject, Injectable } from '@angular/core';
import { API_MODULE_CONFIG } from '@gelato-api-ui/sdk/src/lib/api/api.tokens';
import { ApiModuleConfig } from '@gelato-api-ui/sdk/src/lib/api/api-module-config';
import { AjaxRequest } from 'rxjs/ajax';
import { Observable } from 'rxjs';
import { ApiResponse } from '@gelato-api-ui/core/api/api-response.interface';
import { caller } from '@gelato-api-ui/core/api/caller';
import { callerText } from '@gelato-api-ui/core/api/caller-text';

@Injectable({ providedIn: 'root' })
export class ApiRequestPublicService {
  public get: <T>(url: string, options?: AjaxRequest) => Observable<ApiResponse<T>>;
  public getText: <T>(url: string, options?: AjaxRequest) => Observable<string>;
  public post: <T>(url: string, options?: AjaxRequest) => Observable<ApiResponse<T>>;

  constructor(@Inject(API_MODULE_CONFIG) private config: ApiModuleConfig) {
    this.get = caller(this.config.host, 'GET');
    this.getText = callerText(this.config.host, 'GET');
    this.post = caller(this.config.host, 'POST');
  }
}
