<ng-select
  [placeholder]="emptyOptionKey | translate"
  [ngModel]="value || undefined"
  [readonly]="disabled"
  [items]="options$ | async"
  [searchable]="isSearchable"
  [clearable]="false"
  [typeahead]="input$"
  [multiple]="isMultiple"
  (ngModelChange)="onSelect($event)"
>
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    <ng-container *ngIf="!item">
      {{ emptyOptionKey | translate }}
    </ng-container>
    <ng-container *ngIf="item">
      {{ item | stateName: true:selectedCountry | async }}
    </ng-container>
  </ng-template>
</ng-select>
