import { Pipe, PipeTransform } from '@angular/core';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';

@Pipe({
  name: 'storeIconName',
})
export class StoreIconNamePipe implements PipeTransform {
  constructor() {}

  transform(value: EStoreType, args?: any): string {
    switch (value) {
      case EStoreType.GELATO:
      case EStoreType.MANUAL:
      case EStoreType.BRANDING:
      case EStoreType.TEMPLATE:
      case EStoreType.MOCKUP:
        return 'gelato';
      case EStoreType.SHOPIFY:
        return 'shopify';
      case EStoreType.ETSY:
        return 'etsy';
      case EStoreType.WOOCOMMERCE:
        return 'woocommerce';
      case EStoreType.AMAZON:
        return 'amazon';
      case EStoreType.WIX:
        return 'wix';
      case EStoreType.SQUARESPACE:
        return 'squarespace';
      case EStoreType.BIGCOMMERCE:
        return 'bigcommerce';
      default:
        return '';
    }
  }
}
