import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { HelpCenterArticles } from '@gelato-api-ui/core/help-center/help-center-articles';
import { SanityFaqItem } from '@gelato-api-ui/core/sanity/sanity-faq-item';

@Component({
  selector: 'gd-faq-segment',
  templateUrl: './faq-segment.component.html',
  styleUrls: ['./faq-segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqSegmentComponent {
  @Input() items: SanityFaqItem[];

  helpCenterArticles = HelpCenterArticles;
}
