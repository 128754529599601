<div class="ui segment box-shadow-new no-padding">
  <img
    class="close"
    [src]="'/assets/checkout/close.svg'"
    alt="close banner"
    width="26"
    height="26"
    (click)="dismissAction.emit()"
  />
  <div class="banner">
    <div class="image">
      <img [src]="imageUrl" alt="" />
    </div>
    <div class="info">
      <p class="text">{{ text }}</p>
      <button class="ui button purple subscribe-gelato-plus-button" (click)="buttonAction.emit()">
        {{ buttonText }}
      </button>
    </div>
  </div>
</div>
