<div *ngIf="title" class="header">
  <img *ngIf="titleIconUrl" src="{{ titleIconUrl }}" />
  {{ title }}
</div>
<div *ngIf="body" class="content" [innerHTML]="body"></div>
<div class="actions">
  <button class="ui {{ denyButtonStyle }} button" *ngIf="!hideDenyButton" (click)="onDenyButtonClick()">
    <img *ngIf="denyButtonIconUrl" src="{{ denyButtonIconUrl }}" />
    <ng-container *ngIf="denyButtonText">
      {{ denyButtonText }}
    </ng-container>
    <ng-container *ngIf="!denyButtonText">
      {{ 'txt_no' | translate }}
    </ng-container>
  </button>
  <button
    class="ui {{ approveButtonStyle }} button"
    *ngIf="!hideApplyButton"
    (click)="onApproveButtonClick()"
    autofocus
  >
    <img *ngIf="approveButtonIconUrl" src="{{ approveButtonIconUrl }}" />
    <ng-container *ngIf="approveButtonText">
      {{ approveButtonText }}
    </ng-container>
    <ng-container *ngIf="!approveButtonText">
      {{ 'txt_yes' | translate }}
    </ng-container>
  </button>
</div>
