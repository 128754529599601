import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ProductCatalogueState } from '../product-catalogue.state';
import * as actions from './config.actions';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { productCatalogueFeatureName } from '@product-catalogue/src/lib/product-catalogue.feature';

export const getPCState = createFeatureSelector<ProductCatalogueState>(productCatalogueFeatureName);
export const getState = createSelector(getPCState, state => {
  return state.config;
});

export const getUseFlatShippingPricesFlag = createSelector(
  getState,
  (state: State): boolean => state.useFlatShippingPrices,
);

export const getMobileVersionFlag = createSelector(getState, (state: State): boolean => state.mobileVersion);
export const getPhoneFlag = createSelector(getState, (state: State): boolean => state.isPhone);
export const getTabletFlag = createSelector(getState, (state: State): boolean => state.isTablet);

export interface State {
  designEditorUrl: string;
  selectedStore: EStore;
  selectedStoreId: string;
  useFlatShippingPrices: boolean;
  mobileVersion: boolean;
  isPhone: boolean;
  isTablet: boolean;
}

export const initialState: State = {
  designEditorUrl: null,
  selectedStore: null,
  selectedStoreId: null,
  useFlatShippingPrices: false,
  mobileVersion: false,
  isPhone: false,
  isTablet: false,
};

export function reducer(state = initialState, action: actions.ConfigActions): State {
  switch (action.type) {
    case actions.ConfigActionTypes.ResetState:
      return {
        ...initialState,
      };
    case actions.ConfigActionTypes.SetState:
      return {
        ...state,
        ...action.payload,
      };
    case actions.ConfigActionTypes.SetUseFlatShippingPricesFlag:
      return {
        ...state,
        useFlatShippingPrices: action.payload,
      };
    case actions.ConfigActionTypes.SetMobileVersionFlag:
      return {
        ...state,
        mobileVersion: action.payload,
      };
    case actions.ConfigActionTypes.SetPhoneFlag:
      return {
        ...state,
        isPhone: action.payload,
      };
    case actions.ConfigActionTypes.SetTabletFlag:
      return {
        ...state,
        isTablet: action.payload,
      };
    default:
      return state;
  }
}
