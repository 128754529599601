<div *ngIf="showTaxSection">
  <div class="due-time">
    {{ 'txt_subscription_flow_total_excl_tax' | translate }}
    <span *ngIf="!isTaxLoading">{{
      tax.price?.toString() | money: selectedPricePlan?.price.currency:false:false
    }}</span>
    <div class="field ui placeholder" *ngIf="isTaxLoading"></div>
  </div>
  <div class="due-time">
    {{ 'txt_subscription_flow_tax' | translate }}
    <span *ngIf="!isTaxLoading">{{
      tax.priceVat?.toString() | money: selectedPricePlan?.price.currency:false:false
    }}</span>
    <div class="field ui placeholder" *ngIf="isTaxLoading"></div>
  </div>
  <div class="due-time">
    {{ 'txt_subscription_flow_total_incl_tax' | translate }}
    <span *ngIf="!isTaxLoading">{{
      tax.priceInclVat?.toString() | money: selectedPricePlan?.price.currency:false:false
    }}</span>
    <div class="field ui placeholder" *ngIf="isTaxLoading"></div>
  </div>
</div>

<div class="due-time" *ngIf="!showTaxSection && !showTrialInformation">
  {{ 'txt_plus_due_today' | translate }}
  <span *ngIf="selectedPricePlan && !isTaxLoading">{{
    selectedPricePlan?.price.price.toString() | money: selectedPricePlan?.price.currency:false:false
  }}</span>
  <div class="field ui placeholder" *ngIf="isTaxLoading"></div>
</div>
