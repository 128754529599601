import * as R from 'ramda';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { ChangeDetectionStrategy, Component, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { SidebarItem, SidebarItemInterface } from '../sidebar-nav/sidebar-item';
import { SidebarNavItemIdEnum } from '@api-ui-app/src/app/layout/sidebar-nav/sidebar-nav-item-id.enum';

@Component({
  selector: 'gd-sidebar-nav-item',
  templateUrl: './sidebar-nav-item.component.html',
  styleUrls: ['./sidebar-nav-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarNavItemComponent implements OnChanges {
  @Input() hovered: boolean;
  @Input() minimise: boolean;
  @Input() item: SidebarItem | SidebarItemInterface;
  @Input() hasActiveSibling = false;
  @Input() counters: R.Dictionary<number> = {};
  @Input() isGelatoPlusSubscriptionEnding: boolean;
  @Input() endingSubscriptionDaysLeft: number;
  @Input() level = 0;

  isExpanded = false;

  item$ = new BehaviorSubject<SidebarItem>(null);
  itemId$ = this.item$.pipe(map((item: SidebarItem): string => (item ? item.id : null)));

  hasActiveChild$ = this.item$.pipe(
    map((item: SidebarItem): boolean => {
      if (!item.children || !item.children.length) {
        return false;
      }

      let hasActiveChild = false;

      item.children.forEach((childItem: SidebarItem) => {
        if (!childItem.route) {
          return;
        }

        if (this.isActiveItem(childItem)) {
          hasActiveChild = true;
          this.elRef.nativeElement.classList.add('active');
        }
      });

      return hasActiveChild;
    }),
  );

  isItemVisible$ = this.item$.pipe(switchMap((item: SidebarItem): Observable<boolean> => item.isVisible()));
  hasItemVisibleChild$ = this.item$.pipe(switchMap((item: SidebarItem): Observable<boolean> => item.hasVisibleChild()));
  i18nParams$ = this.item$.pipe(
    switchMap((item: SidebarItem) => (item.i18nNameParams ? item.i18nNameParams() : of({}))),
  );
  route$ = combineLatest([this.item$, this.item$.pipe(switchMap((item: SidebarItem) => item.routeParams()))]).pipe(
    map(([item, params]) => (item.route ? [...item.route, params] : null)),
  );
  noIcon$ = this.item$.pipe(map(item => !item.icon && !item.iconSvg));
  ngDestroy$ = new Subject();

  constructor(private router: Router, private readonly elRef: ElementRef) {
    this.item$.subscribe(item => {
      this.isExpanded = (item?.children.length && item.isExpanded) || this.isSubRouteActive(item);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      this.item$.next(changes.item.currentValue);
    }
  }

  get count() {
    return this.item.children.length
      ? this.item.children.reduce<number>(
          (result, item) => (R.isNil(this.counters[item.id]) ? result : this.counters[item.id] + result),
          null,
        )
      : this.counters[this.item.id];
  }

  get isGelatoAppsPage() {
    return this.item.id === SidebarNavItemIdEnum.APPS;
  }

  get hasSubscriptionCounter() {
    return this.isGelatoAppsPage && this.isGelatoPlusSubscriptionEnding;
  }

  isSubRouteActive(item: SidebarItem): boolean {
    return !item?.children
      ? false
      : item.children
          .filter(child => child.route)
          .map(child => this.router.createUrlTree(child.route))
          .some(child => this.router.isActive(child, false));
  }

  isActiveItem(item: SidebarItem): boolean {
    return !item?.route ? false : new RegExp(`^${item.route.join('/')}`).test(this.router.url);
  }

  onClick() {
    this.itemId$
      .pipe(
        first(),
        tap((itemId: string) => logEvent('sidebarNavItemClick', { id: itemId })),
      )
      .subscribe();

    this.toggle();

    if (!!this.item.action && typeof this.item.action === 'function') {
      this.item.action();
    }
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }
}
