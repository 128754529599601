import { NgModule } from '@angular/core';
import { SharedModule } from '@api-ui-app/src/app/shared/shared.module';
import { HeaderCreateMenuStatelessComponent } from '@api-ui-app/src/app/header-create-menu/components/header-create-menu-stateless/header-create-menu-stateless.component';
import { HeaderCreateMenuComponent } from '@api-ui-app/src/app/header-create-menu/containers/header-create-menu/header-create-menu.component';
import { HeaderCreateMenuFacade } from '@api-ui-app/src/app/header-create-menu/header-create-menu.facade';

@NgModule({
  declarations: [HeaderCreateMenuStatelessComponent, HeaderCreateMenuComponent],
  exports: [HeaderCreateMenuComponent],
  imports: [SharedModule],
  providers: [HeaderCreateMenuFacade],
})
export class HeaderCreateMenuModule {}
