import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gd-price-plan-name-selector',
  templateUrl: './price-plan-name-selector.component.html',
  styleUrls: ['./price-plan-name-selector.component.scss'],
})
export class PricePlanNameSelectorComponent implements OnInit {
  @Input() pricePlanName: string;
  @Input() showAnyValueOption = false;
  @Input() isSearchable = true;
  @Input() isDisabled = false;
  @Output() pricePlanNameChange: EventEmitter<string> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  onPricePlanNameChange(name: string) {
    this.pricePlanNameChange.emit(name);
  }
}
