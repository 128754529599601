import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BreadcrumbItem } from '@gelato-api-ui/core/breadcrumbs/breadcrumb-item';

@Component({
  selector: 'gc-page-header, gd-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent {
  @Input() text = '';
  @Input() isMobile = false;
  @Input() breadcrumbs: BreadcrumbItem[] = [];
  @Input() showRefreshButton = false;
  @Input() showDivider = true;
  @Input() isLoading = false;
  @Input() useH1Tag = false;
  @Output() refresh = new EventEmitter();

  onRefreshClick() {
    this.refresh.emit();
  }
}
