<gc-ecommerce-product-wizard-actions-panel
  [backButtonText]="backButtonLabelTranslationKey | translate"
  [showSteps]="true"
  [includeSpacer]="true"
  [activeStep]="activeStep"
  [visibleSteps]="visibleSteps"
  [disabledSteps]="disabledSteps"
  (backButtonClick)="onReturnToStoreButtonClick()"
>
  <p class="actions-panel-hint">{{ 'txt_ecommerce_product_selection_top_panel_hint' | translate }}</p>
</gc-ecommerce-product-wizard-actions-panel>
