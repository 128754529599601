import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { TranslateService } from '@ngx-translate/core';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { SubscriptionsHelperService } from '@api-ui-app/src/app/subscriptions/services/subscriptions-helper.service';
import { combineLatest } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import {
  PluginDataState,
  SetPluginsEventDataItem,
} from '@gelato-api-ui/core/design-editor/set-plugins-event-data-item';
import {
  DesignEditorPlugin,
  DesignEditorPluginNames,
} from '@gelato-api-ui/core/design-editor/design-editor-plugin.enum';
import { getAppCrownPremiumLevel } from '@api-ui-app/src/app/subscriptions/lib/helpers/visited-apps-local-storage-manager';
import { PremiumIconCrownedFeatures } from '@api-ui-app/src/app/subscriptions/types/icon-crowned-features.enum';
import { DesignEditorMode } from '@product-catalogue/src/lib/product-catalogue/services/design-editor-communication.service';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { EditorPluginService } from '@product-catalogue/src/lib/product-catalogue/services/editor-plugin.service';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';

@Injectable({
  providedIn: 'root',
})
export class DesignEditorApplicationsService {
  constructor(
    private readonly store: Store<AppState>,
    private readonly translateService: TranslateService,
    private readonly subscriptionsFacade: SubscriptionsFacade,
    private readonly editorPluginService: EditorPluginService,
    private readonly subscriptionsHelperService: SubscriptionsHelperService,
    private readonly featureSwitcherService: FeatureSwitcherService,
  ) {}

  setupEditorPlugins(productTypeUid: ProductTypeUid, mode: DesignEditorMode) {
    return combineLatest([
      this.subscriptionsFacade.activeFreePlanIsLegacy$,
      this.subscriptionsFacade.activeServicePlans$,
      this.subscriptionsFacade.gelatoPlusOrGoldIsActive$,
      this.subscriptionsFacade.isUserNeverHadGelatoPlusSubscription$,
      this.featureSwitcherService.isFeatureEnabledForCurrentUser(FeatureFlagEnum.editor_text_effects),
      this.featureSwitcherService.isFeatureEnabledForCurrentUser(FeatureFlagEnum.background_removal_tool),
    ]).pipe(
      filter(
        ([, activeServicePlans, isGelatoPlusOrGoldActive]) =>
          Boolean(activeServicePlans) && isGelatoPlusOrGoldActive != null,
      ),
      take(5),
      map(
        ([
          activeFreePlanIsLegacy,
          activeServicePlans,
          isGelatoPlusOrGoldActive,
          isUserNeverHadGelatoPlusSubscription,
          isTextEffectsEnabled,
          isBGRemovalEnabled,
        ]): SetPluginsEventDataItem[] => {
          const advancedPersonalizationApp = {
            name: DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION,
            state: PluginDataState.disabled,
            extra: {
              hideIfEmpty: mode === DesignEditorMode.ORDER_ITEM_CREATION,
              premiumLevel: getAppCrownPremiumLevel(PremiumIconCrownedFeatures.COLLECTIONS, isGelatoPlusOrGoldActive),
              gelatoPlusPrompt: !isGelatoPlusOrGoldActive
                ? {
                    description: this.subscriptionsHelperService.getGelatoPlusPromptDescription(
                      isGelatoPlusOrGoldActive,
                      DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION,
                    ),
                    buttonText: isUserNeverHadGelatoPlusSubscription
                      ? this.translateService.instant('txt_start_free_trial')
                      : this.translateService.instant('txt_subscribe_now'),
                  }
                : null,
            },
            displayName: DesignEditorPluginNames[DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION]
              ? this.translateService.instant(DesignEditorPluginNames[DesignEditorPlugin.APP_ADVANCED_PERSONALIZATION])
              : null,
          };
          const gettyPlugin = this.editorPluginService.getGettyImagesPlugin(productTypeUid);
          const isWallpaper =
            productTypeUid === ProductTypeUid.WALLPAPER || productTypeUid === ProductTypeUid.WALLPAPER_SAMPLE;
          const bgRemovalApp: SetPluginsEventDataItem = isBGRemovalEnabled
            ? {
                name: DesignEditorPlugin.APP_BACKGROUND_REMOVAL,
                state: isGelatoPlusOrGoldActive ? PluginDataState.enabled : PluginDataState.non_functional,
                extra: {
                  premiumLevel: getAppCrownPremiumLevel(
                    PremiumIconCrownedFeatures.BACKGROUND_REMOVAL,
                    isGelatoPlusOrGoldActive,
                  ),
                  gelatoPlusPrompt: !isGelatoPlusOrGoldActive
                    ? {
                        description: this.subscriptionsHelperService.getGelatoPlusPromptDescription(
                          isGelatoPlusOrGoldActive,
                          DesignEditorPlugin.APP_BACKGROUND_REMOVAL,
                        ),
                        buttonText: isUserNeverHadGelatoPlusSubscription
                          ? this.translateService.instant('txt_start_free_trial')
                          : this.translateService.instant('txt_subscribe_now'),
                      }
                    : null,
                },
              }
            : null;
          const defaultEditorAppList: SetPluginsEventDataItem[] = [
            {
              ...gettyPlugin,
              state: gettyPlugin.state,
              extra: {
                premiumLevel: getAppCrownPremiumLevel(
                  PremiumIconCrownedFeatures.GETTY_IMAGES,
                  isGelatoPlusOrGoldActive,
                ),
                gelatoPlusPrompt: !isGelatoPlusOrGoldActive
                  ? {
                      description: this.subscriptionsHelperService.getGelatoPlusPromptDescription(
                        isGelatoPlusOrGoldActive,
                        DesignEditorPlugin.APP_GETTY_IMAGES,
                      ),
                      buttonText: isUserNeverHadGelatoPlusSubscription
                        ? this.translateService.instant('txt_start_free_trial')
                        : this.translateService.instant('txt_subscribe_now'),
                    }
                  : null,
              },
            },
            {
              name: DesignEditorPlugin.APP_SHUTTERSTOCK,
              state: PluginDataState.enabled,
              extra: {
                premiumLevel: getAppCrownPremiumLevel(
                  PremiumIconCrownedFeatures.SHUTTERSTOCK,
                  isGelatoPlusOrGoldActive,
                ),
              },
              displayName: DesignEditorPluginNames[DesignEditorPlugin.APP_SHUTTERSTOCK]
                ? this.translateService.instant(DesignEditorPluginNames[DesignEditorPlugin.APP_SHUTTERSTOCK])
                : null,
            },
            {
              name: DesignEditorPlugin.APP_IMAGE_FILTERS,
              state: PluginDataState.non_functional,
              extra: {
                premiumLevel: getAppCrownPremiumLevel(
                  PremiumIconCrownedFeatures.IMAGE_FILTERS,
                  isGelatoPlusOrGoldActive,
                ),
                gelatoPlusPrompt: !isGelatoPlusOrGoldActive
                  ? {
                      description: this.subscriptionsHelperService.getGelatoPlusPromptDescription(
                        isGelatoPlusOrGoldActive,
                        DesignEditorPlugin.APP_IMAGE_FILTERS,
                      ),
                      buttonText: isUserNeverHadGelatoPlusSubscription
                        ? this.translateService.instant('txt_start_free_trial')
                        : this.translateService.instant('txt_subscribe_now'),
                    }
                  : null,
              },
            },
            advancedPersonalizationApp,
            {
              name: DesignEditorPlugin.APP_GRAPHICS,
              state: isWallpaper ? PluginDataState.not_visible : PluginDataState.enabled,
              extra: {
                premiumLevel: getAppCrownPremiumLevel(PremiumIconCrownedFeatures.ADDONS, isGelatoPlusOrGoldActive),
                gelatoPlusPrompt: !isGelatoPlusOrGoldActive
                  ? {
                      description: this.subscriptionsHelperService.getGelatoPlusPromptDescription(
                        isGelatoPlusOrGoldActive,
                        DesignEditorPlugin.APP_GRAPHICS,
                      ),
                      buttonText: isUserNeverHadGelatoPlusSubscription
                        ? this.translateService.instant('txt_start_free_trial')
                        : this.translateService.instant('txt_subscribe_now'),
                    }
                  : null,
              },
              displayName: DesignEditorPluginNames[DesignEditorPlugin.APP_GRAPHICS]
                ? this.translateService.instant(DesignEditorPluginNames[DesignEditorPlugin.APP_GRAPHICS])
                : null,
            },
            isTextEffectsEnabled
              ? {
                  name: DesignEditorPlugin.APP_TEXT_EFFECTS,
                  state: isGelatoPlusOrGoldActive ? PluginDataState.enabled : PluginDataState.non_functional,
                  extra: {
                    premiumLevel: getAppCrownPremiumLevel(
                      PremiumIconCrownedFeatures.TEXT_EFFECTS,
                      isGelatoPlusOrGoldActive,
                    ),
                    gelatoPlusPrompt: !isGelatoPlusOrGoldActive
                      ? {
                          description: this.subscriptionsHelperService.getGelatoPlusPromptDescription(
                            isGelatoPlusOrGoldActive,
                            DesignEditorPlugin.APP_TEXT_EFFECTS,
                          ),
                          buttonText: isUserNeverHadGelatoPlusSubscription
                            ? this.translateService.instant('txt_start_free_trial')
                            : this.translateService.instant('txt_subscribe_now'),
                        }
                      : null,
                  },
                }
              : null,
            {
              name: DesignEditorPlugin.APP_FONTS,
              state: isGelatoPlusOrGoldActive ? PluginDataState.enabled : PluginDataState.non_functional,
              extra: {
                premiumLevel: getAppCrownPremiumLevel(
                  PremiumIconCrownedFeatures.PREMIUM_FONTS,
                  isGelatoPlusOrGoldActive,
                ),
              },
            },
            bgRemovalApp,
          ];

          const activeServicePlansByGelatoPlus = isGelatoPlusOrGoldActive ? activeServicePlans : [];
          const installedApps = activeServicePlansByGelatoPlus.map(activeServicePlan => {
            return {
              name: activeServicePlan.serviceUniqueName as DesignEditorPlugin,
              state: PluginDataState.enabled,
            };
          });

          return defaultEditorAppList
            .filter((appItem: SetPluginsEventDataItem): boolean => !!appItem)
            .map((appItem: SetPluginsEventDataItem): SetPluginsEventDataItem => {
              const appIsInstalled = installedApps.find(
                installedApp => installedApp.name === appItem.name || installedApp.name.includes(appItem.name),
              );

              return {
                ...appItem,
                state: this.editorPluginService.isPluginAvailableByProduct(appItem.name, productTypeUid)
                  ? appIsInstalled
                    ? appIsInstalled.state
                    : appItem.state
                  : PluginDataState.not_visible,
                displayName: appItem.displayName ? this.translateService.instant(appItem.displayName) : null,
              };
            });
        },
      ),
    );
  }
}
