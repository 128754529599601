import { ActionReducer, createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import * as actions from './client-selection-list.actions';
import { Client } from '@gelato-api-ui/core/clients/client';

export interface State {
  isLoading: boolean;
  payload: Client[];
}

export const getClientsState = (state: AppState): State => state.clientSelectionList;

export const getClients = createSelector(
  getClientsState,
  (clientsState: State): Client[] => clientsState.payload || [],
);

export const getClientNames = createSelector(getClients, (clients: Client[]) => {
  if (!clients) {
    return {};
  }

  const clientNames = {};

  clients.forEach((client: Client) => {
    clientNames[client.id] = client.name;
  });

  return clientNames;
});

export const getClientDescriptions = createSelector(getClients, (clients: Client[]) => {
  if (!clients) {
    return {};
  }

  const clientNames = {};

  clients.forEach((client: Client) => {
    clientNames[client.id] = client.description || client.name;
  });

  return clientNames;
});

export const initialState: State = {
  isLoading: false,
  payload: [],
};

export const reducer: ActionReducer<State> = (state = initialState, action: actions.All): State => {
  switch (action.type) {
    case actions.ClientSelectionListAction.LoadStart:
      return {
        ...state,
        isLoading: true,
      };
    case actions.ClientSelectionListAction.LoadSuccess:
      return {
        ...state,
        isLoading: false,
        payload: action.payload,
      };
    default:
      return state;
  }
};
