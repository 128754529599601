import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { OrderSearchResponse } from '../lib/order-search-response';
import { OrderDetailsVATInvoice } from '../lib/order-details';
import { OrderSearchRequest } from '../lib/order-search-request';
import { FinancialOrderStatus } from '../lib/financial-order-status.enum';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { OrderTotals } from '../lib/order-totals';
import { OrdersAttentionListService } from '@api-ui-app/src/app/dashboard/orders-attention-list/orders-attention-list.service';

@Injectable({ providedIn: 'root' })
export class OrdersApiService {
  constructor(
    private readonly apiRequest: ApiRequestService,
    private readonly attentionOrderService: OrdersAttentionListService,
  ) {}

  search(request: OrderSearchRequest = null): Observable<OrderSearchResponse> {
    return this.apiRequest.post<any>('/api/order/v4/orders:search', { body: request }).pipe(map(data => data.data));
  }

  searchTotals(request: OrderSearchRequest = null): Observable<OrderTotals> {
    return this.apiRequest.post<OrderTotals>('/api/order/v4/orders:search-totals', { body: request }).pipe(
      map(data => data.data),
      tap(data =>
        this.attentionOrderService.handleAttentionNeededOrdersCount(data?.totalCountPerFulfillmentStatus?.failed),
      ),
    );
  }

  getPaidOrdersCount(limit: number): Observable<number> {
    const request: OrderSearchRequest = {
      financialStatuses: [FinancialOrderStatus.PAID],
      limit,
      offset: 0,
    };

    return this.search(request).pipe(
      catchError(() => of(null)),
      map((response: OrderSearchResponse) => {
        if (!response || !response.orders) {
          return 0;
        }

        return response.orders.length;
      }),
    );
  }

  cancel(id: string): Observable<any> {
    return this.apiRequest.post<any>(`/api/order/v4/orders/${id}:cancel`).pipe(map((data: any): any => data));
  }

  fetchVatInvoiceLink(id: string): Observable<OrderDetailsVATInvoice> {
    return this.apiRequest
      .get<OrderDetailsVATInvoice>(`/api/order/v3/orders/${id}/vat-invoice`)
      .pipe(map(resp => resp.data));
  }

  delete(id: string): Observable<any> {
    return this.apiRequest.delete<any>(`/api/order/v4/orders/${id}`).pipe(map((data: any): any => data));
  }
}
