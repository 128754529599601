import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { GelatoPlusFeatures } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-features';
import { GelatoPlusPopupLocations } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-plus-popup-locations';
import { GelatoPlusModalService } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/gelato-plus-modal.service';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { GelatoSubscriptionModalType } from '../../lib/gelato-subscription-modal';

@Component({
  selector: 'gc-subscription-paywall-popup, gd-subscription-paywall-popup',
  templateUrl: './subscription-paywall-popup.component.html',
  styleUrls: ['./subscription-paywall-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionPaywallPopupComponent implements OnInit {
  @Input() buttonText: string;
  @Input() isPluginUsed: boolean;
  @Input() canProceed: boolean;

  constructor(
    private readonly gelatoPlusModalService: GelatoPlusModalService,
    private readonly subscriptionFacade: SubscriptionsFacade,
  ) {}

  ngOnInit(): void {}

  onOpenGelatoPlusModal() {
    this.gelatoPlusModalService.open(
      GelatoPlusFeatures.editor_paywall,
      GelatoPlusPopupLocations.editor,
      GelatoSubscriptionModalType.GELATO_PLUS,
    );
    logEvent('trialSelectedFromAlert', {
      featuresUsed: this.subscriptionFacade.pluginsListUsedInTheEditor$.getValue(),
    });
  }

  logProceedWithPlusProductEvent() {
    logEvent('proceedWithPlusProduct', {
      featuresUsed: this.subscriptionFacade.pluginsListUsedInTheEditor$.getValue(),
    });
  }
}
