import { Injectable } from '@angular/core';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import {
  ExistingProductSelectionModal,
  ExistingProductSelectionModalModalContext,
} from '@api-ui-app/src/app/product/product-add/containers/existing-product-selection-modal/existing-product-selection-modal.component';
import { ProductSelectionResult } from '@api-ui-app/src/app/product/product-add/types/product-selection-result';
import { SplashMessageFacade } from '@api-ui-app/src/app/shared/splash-message/+state/splash-message/splash-message.facade';
import { TranslateService } from '@ngx-translate/core';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import {
  SingleProductSelectionModal,
  SingleProductSelectionModalModalContext,
} from '@api-ui-app/src/app/product/product-add/containers/single-product-selection-modal/single-product-selection-modal.component';

type OnApproveCallback = (res: ProductSelectionResult) => void;

@Injectable()
export class ProductAddService {
  constructor(
    private readonly modalService: SuiModalService,
    private readonly splashMessageFacade: SplashMessageFacade,
    private readonly translateService: TranslateService,
  ) {}

  openStoreListModal(
    maxCheckedProductVariantsCount: number,
    selectVariantsEnabled: boolean,
    multipleSelectionEnabled: boolean,
    onApprove: OnApproveCallback,
    splashMessage?: string,
    excludeStoreIds?: string[],
  ) {
    if (splashMessage) {
      this.addSplashMessage(splashMessage);
    }

    const modalConfig: ExistingProductSelectionModalModalContext = {
      maxCheckedProductVariantsCount,
      multipleSelectionEnabled,
      selectVariantsEnabled,
      selectProductsEnabled: true,
      splashMessage,
      excludeStoreIds,
    };

    this.modalService.open(new ExistingProductSelectionModal(modalConfig)).onApprove((res: ProductSelectionResult) => {
      onApprove(res);
    });
  }

  openStoreSelectModal(title: string, subtitle: string, onApprove: OnApproveCallback) {
    const modalConfig: ExistingProductSelectionModalModalContext = {
      maxCheckedProductVariantsCount: 1,
      multipleSelectionEnabled: false,
      selectVariantsEnabled: false,
      selectProductsEnabled: false,
      title,
      subtitle,
    };

    this.modalService.open(new ExistingProductSelectionModal(modalConfig)).onApprove((res: ProductSelectionResult) => {
      onApprove(res);
    });
  }

  openSingleProductVariantsSelectionModal(store: EStore, productId: string, onApprove: OnApproveCallback) {
    const modalConfig: SingleProductSelectionModalModalContext = {
      store,
      productId,
    };

    this.modalService.open(new SingleProductSelectionModal(modalConfig)).onApprove((res: ProductSelectionResult) => {
      onApprove(res);
    });
  }

  addSplashMessage(id: string) {
    this.splashMessageFacade.triggerAdd({
      id,
      text: this.translateService.instant(`txt_${id}_splash_message`),
      color: 'info',
      icon: 'assets/status-icons/status-info.svg',
      closeable: true,
    });
  }
}
