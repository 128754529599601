import * as R from 'ramda';
import { round } from '../../utils/round';

const rounding = {
  CLP: 0,
  INR: -2,
  JPY: 0,
  KRW: 0,
};

export function currencyRound(number: number, currency: string): number {
  return round(number, R.defaultTo(2, rounding[currency]));
}
