import { Injectable } from '@angular/core';
import { CustomTrim } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { convertDimensionValue } from '@gelato-api-ui/core/product-details/helpers/convertDimensionValue';
import { DimensionUnit } from '@gelato-api-ui/core/product-details/dimension-unit.enum';

@Injectable({ providedIn: 'root' })
export class CustomTrimTitleService {
  get(customTrim: CustomTrim): string {
    const width = customTrim.width.value;
    const height = customTrim.height.value;

    const widthCm = Math.round(width / 10);
    const heightCm = Math.round(height / 10);

    const widthInch = Math.round(convertDimensionValue(width, DimensionUnit.MILLIMETER, DimensionUnit.INCH));
    const heightInch = Math.round(convertDimensionValue(height, DimensionUnit.MILLIMETER, DimensionUnit.INCH));

    return `${widthCm}x${heightCm}cm / ${widthInch}”x${heightInch}“`;
  }
}
