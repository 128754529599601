import { createAction, props } from '@ngrx/store';
import { CountriesDataModel } from '@api-ui-app/src/app/shared/countries/types/countries.types';

export const loadCountries = createAction('[Countries] Load Countries');

export const loadCountriesComplete = createAction('[Countries] Load Countries Complete', props<CountriesDataModel>());

export const resetCountries = createAction('[Countries] Reset Countries');

export const loadCountriesError = createAction('[Countries] Load Countries Error', props<any>());
