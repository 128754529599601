<a
  gcLogEvent
  analyticsKey="catalogueNavigationStep"
  [analyticsProperties]="{ ProductName: product.title }"
  [id]="product.id"
  (click)="onNavigate()"
>
  <div class="ui segment box-shadow-new product-wrapper" [class.box-shadow-new]="newBoxShadow">
    <div>
      <div *ngIf="product.icon" class="product-icon">
        <img src="{{ product.icon }}" />
      </div>

      <ng-container *ngIf="product.productImages?.length">
        <div class="product-preview-wrapper">
          <lib-product-badge *ngIf="product.productBadge" [productBadge]="product.productBadge"></lib-product-badge>
          <img src="{{ product.productImages[0] }}" />
        </div>
      </ng-container>

      <div *ngIf="product.subtitle" class="product-subtitle">
        {{ product.subtitle }}
      </div>

      <div class="product-title">
        {{ product.title }}
      </div>

      <div *ngIf="product.descriptionShort" class="product-subtitle">
        {{ product.descriptionShort }}
      </div>

      <lib-mounted-framed-poster-available-label
        *ngIf="product.hasMountedFramedPosters"
      ></lib-mounted-framed-poster-available-label>

      <ng-container *ngIf="catalogProduct">
        <gd-catalog-product-preview-variants [product]="product"></gd-catalog-product-preview-variants>
      </ng-container>

      <ng-container
        *ngIf="product?.productUidForPrice && productPrices && productPrices[product?.productUidForPrice] && currency"
      >
        <div class="product-price">
          {{ 'txt_from' | translate }}
          <span class="price-label">{{ productPrices[product?.productUidForPrice] | money: currency }}</span>
          {{ 'txt_excl_vat' | translate }}
        </div>
      </ng-container>
    </div>
  </div>
</a>
