import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { Client } from '@gelato-api-ui/core/clients/client';
import { ClientsApiService } from '@gelato-api-ui/core/clients/services/clients-api.service';
import * as actions from './auth.actions';
import { getSelectedClient, getSelectedClientId } from './auth.reducer';
import { LocalStorageItemKey } from '@gelato-api-ui/core/local-storage/types/local-storage-item-key.enum';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private clientsApiService: ClientsApiService,
  ) {}

  setSelectedClientId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.SetSelectedClientId>(actions.AuthActionTypes.SetSelectedClientId),
        withLatestFrom(this.store$.select(getSelectedClientId), this.store$.select(getSelectedClient)),
        switchMap(([action, selectedClientId, selectedClient]) => {
          const { payload } = action;
          const isDataRelevant = Boolean(selectedClient) && selectedClient.id === selectedClientId;
          const observable = isDataRelevant
            ? of({ ...selectedClient })
            : this.clientsApiService.getClient(selectedClientId);

          return observable.pipe(
            catchError((err): Observable<Client> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((client: Client) => {
              if (client) {
                localStorage.setItem(LocalStorageItemKey.selectedClientId, selectedClientId);
              }

              this.store$.dispatch(new actions.SetSelectedClient(client));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
