import * as R from 'ramda';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';

export const pluckValueFromProductVariants = (product: EProductWithVariants, fieldName: string): string => {
  if (!product?.variants?.length) {
    return null;
  }

  const values = R.pluck(fieldName, product.variants);

  return R.uniq(values).length === 1 ? values[0] : null;
};
