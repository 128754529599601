export enum SanityProductCategoryName {
  CARDS = 'cards',
  CARDS_EU = 'cards-eu',
  CARDS_US = 'cards-us',
  POSTERS = 'posters',
  FRAMED_POSTERS = 'framed-posters',
  POSTERS_WITH_HANGERS = 'poster-hangers',
  CANVAS = 'canvas',
  ACRYLIC = 'acrylic',
  METALLIC = 'metallic',
  FOAM_BOARD_PRINT = 'foam-print-product',
  WOOD_PRINT = 'wood-prints',
  MUGS = 'mugs',
  LETTERHEADS = 'letterheads',
  ROLL_UPS = 'roll-ups',
  FOLDED_CARDS = 'folded-cards',
  BUSINESS_CARDS = 'business-cards',
  FLYERS = 'flyers',
  SINGLE_FOLD_BROCHURES = 'single-fold-brochures',
  ROLL_FOLDED_BROCHURES = 'roll-folded-brochures',
  ACCORDION_FOLD_BROCHURES = 'accordion-fold-brochures',
  FOLDERS = 'folders',
  PHOTOBOOKS = 'photo-books',
  SOFT_COVER_PHOTOBOOKS = 'soft-cover-photobooks',
  HARD_COVER_PHOTOBOOKS = 'hard-cover-photobooks',
  NOTEBOOKS = 'notebooks',
  CALENDARS = 'calendars',
  MULTIPAGE_BROCHURES = 'multipage-brochures',
  WALLPAPER = 'wallpaper',
  PHONE_CASES = 'phone-cases',

  // Apparel
  APPAREL = 'apparel',
  T_SHIRTS = 't-shirts',
  HOODIES = 'hoodies',
  SWEATSHIRTS = 'sweatshirts',
  T_SHIRTS_MAN = 't-shirts-men',
  TANK_TOPS_MAN = 'tank-tops-men',
  THREE_FOUR_SLEEVE_SHIRTS = '3-4-sleeve-shirts',
  LONG_SLEEVE_SHIRTS = 'long-sleeve-shirts',
  MENS_SPORTSWEAR = 'mens-sportswear',
  ORGANIC_CLOTHING = 'organic-clothing',
  WOMENS_TANK_TOPS = 'womens-tank-tops',
  KIDS_APPAREL = 'kids-apparel',
  T_SHIRTS_KIDS = 't-shirts-kids',
  HOODIES_KIDS = 'hoodies-kids',
  SWEATSHIRTS_KIDS = 'sweatshirts-kids',
  KIDS_ORGANIC = 'kids-organic',
  BABY_CLOTHING = 'baby-clothing',
  LONG_SLEEVE_SHIRTS_MEN = 'long-sleeve-shirts-men',
  MEN_SPORTSWEAR = 'men-sportswear',
  ORGANIC_CLOTHING_MEN = 'organic-clothing-men',
  HOODIES_MEN = 'hoodies-men',
  SWEATSHIRTS_MEN = 'sweatshirts-men',
  SWEATSHIRTS_WOMEN = 'sweatshirts-women',

  TOTE_BAGS = 'tote-bags',
  BRANDING = 'branding',
}
