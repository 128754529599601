<ng-template #color>
  <div class="garment-color">
    <span
      class="color"
      *ngFor="let color of product.colors"
      suiPopup
      popupInverted
      [popupText]="color.title + ', ' + color.colorHex"
      popupPlacement="top"
      [style.background]="colorBackground(color.colorHex)"
    ></span>
  </div>
</ng-template>
<ng-template #garmentSize>
  <div class="garment-size">
    <span>{{ garmentSizesString(garmentSizes | sortApparelSizes) }}</span>
  </div>
</ng-template>
<ng-template #brands>
  <div class="brands">
    <span>{{ product.brands }}</span>
  </div>
</ng-template>
<ng-template #fulfilmentSummary>
  <div class="fulfilment-summary">
    <img [src]="'icons/globe-small.svg' | assetsPath | async" alt="" height="" width="" />
    <div [innerHTML]="'txt_fulfilment_summary' | translate: { count: product.fulfilmentCountriesCount }"></div>
  </div>
</ng-template>

<ng-container *ngIf="product.colors">
  <ng-container *ngTemplateOutlet="color"></ng-container>
</ng-container>
<ng-container *ngIf="product?.brands">
  <ng-container *ngTemplateOutlet="brands"></ng-container>
</ng-container>
<ng-container *ngIf="garmentSizes">
  <ng-container *ngTemplateOutlet="garmentSize"></ng-container>
</ng-container>
<ng-container *ngIf="product?.fulfilmentCountriesCount">
  <ng-container *ngTemplateOutlet="fulfilmentSummary"></ng-container>
</ng-container>
