import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, withLatestFrom } from 'rxjs/operators';
import { AppState } from '../app.state';
import * as actions from './layout.actions';
import { isSidebarMinimised } from '@api-ui-app/src/app/ngrx/layout.selector';
import { sidebarStorageKey } from '@api-ui-app/src/app/ngrx/layout';

@Injectable()
export class LayoutEffects {
  constructor(private actions$: Actions, private store$: Store<AppState>) {}

  persistSidebarValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.PersistToggleState>(actions.LayoutActionTypes.PersistToggleState),
      withLatestFrom(this.store$.select(isSidebarMinimised)),
      map(([, isSidebarVisible]) => {
        const toggleValueToSave = !!isSidebarVisible ? '0' : '1';
        localStorage.setItem(sidebarStorageKey, toggleValueToSave);
        return new actions.ToggleSidebar();
      }),
    ),
  );
}
