<div class="wrapper">
  <gd-segment-header
    *ngIf="headerTitle"
    [text]="headerTitle"
    [iconUrl]="headerIcon"
    [showDivider]="true"
  ></gd-segment-header>

  <div class="widget-segment">
    <div
      class="widget-segment-banner-image"
      *ngIf="showImage"
      [style.background-image]="backgroundImage"
      [class.video]="isVideo"
    >
      <gc-play-button *ngIf="isVideo" (click)="startVideo.emit()"></gc-play-button>
      <img [src]="backgroundImage" class="image" [style.max-height]="mainImageMaxHeight" alt="" />
    </div>
    <div class="widget-segment-text-content" [class.with-image]="showImage">
      <div *ngIf="title" class="widget-segment-title">
        {{ title }}
      </div>

      <div *ngIf="text" class="widget-segment-description" [innerHTML]="text"></div>

      <a *ngIf="buttonText" class="ui button primary" (click)="this.buttonClick.emit(index)">
        <img class="primary-button-icon" *ngIf="buttonIcon" [src]="buttonIcon" alt="" width="" height="" />
        {{ buttonText }}
        <i *ngIf="!buttonIcon && showButtonArrowIcon" class="ui icon arrow right"></i>
      </a>
      <a *ngIf="secondaryButtonText" class="ui button secondary-grey" (click)="this.secondaryButtonClick.emit(index)">
        {{ secondaryButtonText }}
      </a>
    </div>
  </div>
</div>
