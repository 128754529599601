import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { BillingType } from '@gelato-api-ui/core/clients/billing-type.enum';
import { User } from '@api-ui-app/src/app/shared/lib/user';
import { Promotion, PromotionTemplate } from '@api-ui-app/src/app/shared/voucher/voucher';
import { addDays, differenceInCalendarDays, parseISO } from 'date-fns';

export interface OrdersDiscount {
  ordersCount: number;
  discount: number;
  paidOrdersCount: number;
}

export function getDiscountedOrders(company: CompanyDetails, user: User, paidOrdersCount: number): OrdersDiscount {
  const isPrepaid = company.billingType === BillingType.PREPAID;

  const result = {
    ordersCount: 0,
    discount: 0,
    paidOrdersCount,
  } as OrdersDiscount;

  if (isPrepaid) {
    const promo50PercentLengthInDays = 3;
    const orderCountToApply = 3;

    const clientCreatedAt = parseISO(user.createdAt);
    const daysDiff = differenceInCalendarDays(new Date(), clientCreatedAt);
    const discountedOrdersCount = orderCountToApply - paidOrdersCount;

    if (paidOrdersCount === 0 && daysDiff < promo50PercentLengthInDays) {
      result.discount = 50;
      result.ordersCount = 1;
    } else if (discountedOrdersCount > 0) {
      result.discount = 30;
      result.ordersCount = discountedOrdersCount;
    }
  }
  return result;
}

export function generateDiscountedOrdersPromotions(
  company: CompanyDetails,
  user: User,
  paidOrdersCount: number,
): PromotionTemplate[] {
  const isPrepaid = company.billingType === BillingType.PREPAID;
  const arr: PromotionTemplate[] = [];

  if (isPrepaid) {
    const promo50PercentLengthInDays = 3;
    const orderCountToApply = 3;

    const clientCreatedAt = parseISO(user.createdAt);
    const expirationDate = addDays(clientCreatedAt, 3);
    const daysDiff = differenceInCalendarDays(new Date(), clientCreatedAt);
    const discountedOrdersCount = orderCountToApply - paidOrdersCount;

    if (paidOrdersCount === 0 && daysDiff < promo50PercentLengthInDays) {
      const ordersAmount = discountedOrdersCount - 1;
      arr.push(
        {
          name_tr: 'txt_discount_first_order_name',
          banner_tr: 'txt_discount_first_order_banner',
          date_tr: null,
          expiration_date: expirationDate.toISOString(),
        },
        {
          name_tr: 'txt_discount_warming_up_name',
          banner_tr: ordersAmount > 1 ? 'txt_discount_warming_up_banner' : 'txt_discount_warming_up_banner_single',
          date_tr: { amount: discountedOrdersCount - 1 },
          expiration_date: null,
        },
      );
    } else if (discountedOrdersCount > 0) {
      arr.push({
        name_tr: 'txt_discount_warming_up_name',
        banner_tr:
          discountedOrdersCount > 1 ? 'txt_discount_warming_up_banner' : 'txt_discount_warming_up_banner_single',
        date_tr: { amount: discountedOrdersCount },
        expiration_date: null,
      });
    }
  }

  return arr;
}

export function getDiscountedOrdersTextKey(discount: OrdersDiscount): string {
  const first = discount.paidOrdersCount > 0 ? 'next' : 'first';
  const count = discount.ordersCount > 1 ? '_{count}' : '';
  const plural = discount.ordersCount > 1 ? 's' : '';
  return `txt_discount_for_${first}${count}_order${plural}`;
}
