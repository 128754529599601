<form class="ui form">
  <div class="grouped fields" *ngIf="(subscriptionPlanItems$ | async)?.length">
    <div
      class="field"
      *ngFor="let subscriptionPlan of subscriptionPlanItems$ | async"
      (click)="onSelectPricePlan(subscriptionPlan)"
    >
      <sui-radio-button
        name="subscriptionPlan"
        class="radio-button-wrapper"
        [formControl]="control"
        [class.checked]="selectedPeriod === subscriptionPlan.periodUnit"
        [class.best-value]="subscriptionPlan.periodUnit === timePeriodUnitEnum.YEAR"
        [value]="subscriptionPlan.periodUnit"
        [(ngModel)]="selectedPeriod"
      >
        <div class="period-price-wrapper">
          <div class="checkbox-label">
            {{
              subscriptionPlan.periodUnit === timePeriodUnitEnum.YEAR
                ? ('txt_period_plan_year' | translate)
                : ('txt_period_plan_month' | translate)
            }}
          </div>
          <div *ngIf="subscriptionPlan.periodUnit === timePeriodUnitEnum.YEAR" class="best-value-label">
            {{
              'txt_plus_best_value_save_price'
                | translate: { savePrice: (yearlyDiscountPrice$ | async | money: subscriptionPlan?.price?.currency) }
            }}
          </div>
          <div class="price-plan-wrapper">
            <div
              class="period-full-price"
              *ngIf="!isGelatoPlusGoldModal && subscriptionPlan.periodUnit === timePeriodUnitEnum.YEAR"
            >
              {{
                getYearlyPriceWithoutDiscount$(gelatoSubscriptionModalType)
                  | async
                  | money: subscriptionPlan?.price?.currency
              }}/{{ 'txt_month' | translate }}
            </div>
            <div class="period-discount-price">
              <ng-container *ngIf="isGoldMonthPrice(subscriptionPlan)">
                {{ gelatoPlusGoldMonthlyPrice$ | async | money: subscriptionPlan?.price?.currency }}/{{
                  'txt_month' | translate
                }}
              </ng-container>
              <ng-container *ngIf="!isGoldMonthPrice(subscriptionPlan)">
                {{
                  isGelatoPlusGoldModal
                    ? (gelatoPlusGoldYearPrice$ | async | money: subscriptionPlan?.price?.currency)
                    : (getPriceWithoutDiscount$(subscriptionPlan.periodUnit === timePeriodUnitEnum.YEAR)
                      | async
                      | money: subscriptionPlan?.price?.currency)
                }}/{{ isGelatoPlusGoldModal ? ('txt_year' | translate) : ('txt_month' | translate) }}
              </ng-container>
            </div>
          </div>
        </div>
      </sui-radio-button>
    </div>
  </div>
  <div class="grouped fields no-plans" *ngIf="!(subscriptionPlanItems$ | async)?.length">
    <div class="field ui placeholder"></div>
    <div class="field ui placeholder"></div>
  </div>
</form>
