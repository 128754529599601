import { LoggerService } from '@gelato-api-ui/sdk/src/lib/logger/logger.interface';
import { Injectable } from '@angular/core';

@Injectable()
export class ConsoleLoggerService implements LoggerService {
  // tslint:disable
  debug = (...args) => console.debug(...args);
  error = (...args) => console.error(...args);
  info = (...args) => console.info(...args);
  log = (...args) => console.log(...args);
  warn = (...args) => console.warn(...args);
}
