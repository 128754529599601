import { Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as R from 'ramda';
import { CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { CountriesFacade } from '@api-ui-app/src/app/shared/countries/countries.facade';

export interface CountryOption {
  id: string;
  name: string;
}

export interface CountryWithDialingCode {
  code: string;
  name: string;
  dialingCode: string;
}

@Injectable({
  providedIn: 'root',
})
export class CountryListService {
  private cachedList = null;

  constructor(private countriesFacade: CountriesFacade, private translateService: TranslateService) {}

  getList() {
    if (this.cachedList) {
      return of(this.cachedList);
    }

    return this.countriesFacade.getSupportedCountries$().pipe(
      filter(Boolean),
      switchMap((countries: string[]) => {
        const translationKeys: string[] = R.map((countryIsoCode: string) => `txt_country_${countryIsoCode}`, countries);

        return combineLatest([of(countries), this.translateService.get(translationKeys), of(translationKeys)]);
      }),
      filter(
        ([countries, translations, translationKeys]) =>
          countries && !R.equals(Object.values(translations), translationKeys),
      ),
      map(([countries, translations]) => {
        const list = {};

        countries.forEach((countryIsoCode: string) => {
          list[countryIsoCode] = translations[`txt_country_${countryIsoCode}`];
        });

        this.cachedList = list;

        return list;
      }),
    );
  }

  getCountryName(countryIsoCode: string): Observable<string> {
    return this.getList().pipe(map(list => list[String(countryIsoCode || '').toUpperCase()]));
  }

  getSortedPairs(): Observable<string[][]> {
    return this.getList().pipe(
      map(countryList =>
        R.sort((a: string[], b: string[]) => {
          const countryNameA = a[1] || '';
          const countryNameB = b[1] || '';

          return countryNameA.localeCompare(countryNameB);
        }, R.toPairs(countryList)),
      ),
    );
  }

  getOptions(): Observable<CountryOption[]> {
    return this.getSortedPairs().pipe(
      map(sortedPairs =>
        R.map(
          (pair: string[]): CountryOption => ({
            id: pair[0],
            name: pair[1],
          }),
          sortedPairs,
        ),
      ),
    );
  }

  getSortedCountryIsoCodes(): Observable<string[]> {
    return this.getSortedPairs().pipe(
      map((sortedPairs: string[][]) => R.map((pair: string[]): string => (pair ? pair[0] : null), sortedPairs)),
    );
  }

  public getCountriesWithDialingCodes(): Observable<CountryWithDialingCode[]> {
    const notSupportedCountryCodes = ['GS', 'IC', 'TF', 'PN'];

    return this.getOptions().pipe(
      map((options: CountryOption[]) =>
        options
          .filter(
            (loopOption: CountryOption): boolean =>
              Boolean(loopOption) && !notSupportedCountryCodes.includes(loopOption.id),
          )
          .map(
            (loopOption: CountryOption): CountryWithDialingCode => ({
              code: loopOption.id,
              name: loopOption.name,
              dialingCode: `+${getCountryCallingCode(loopOption.id as CountryCode)}`,
            }),
          ),
      ),
    );
  }
}
