import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { Wallet, WalletChangeSet, WalletsSearchResponse, WalletTransactionsSearchResponse } from '../lib/api';
import { map } from 'rxjs/operators';
import { decorateHeadersWithClientId } from '../../../lib/decorate-headers-with-client-id';

@Injectable({ providedIn: 'root' })
export class WalletApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  search(clientId: string) {
    return this.apiRequest
      .post<WalletsSearchResponse>('/api/payments/v1/wallets:search', {
        headers: decorateHeadersWithClientId({}, clientId),
      })
      .pipe(map(res => res.data));
  }

  topUp(wallet: Wallet, amountInMinorUnits: number) {
    return this.apiRequest
      .post<{ wallet: Wallet }>(`/api/payments/v1/wallets/${wallet.walletUid}:topUp`, {
        body: { amountInMinorUnits },
      })
      .pipe(map(res => res.data.wallet));
  }

  topUpInternal(wallet: Wallet, amountInMinorUnits: number, clientId: string) {
    return this.apiRequest
      .post<{ wallet: Wallet }>(`/api/payments/v1/wallets/${wallet.walletUid}:topUpInternal`, {
        body: { amountInMinorUnits },
        headers: decorateHeadersWithClientId({}, clientId),
      })
      .pipe(map(res => res.data.wallet));
  }

  withdraw(wallet: Wallet, amountInMinorUnits: number, clientId: string) {
    return this.apiRequest
      .post<{ wallet: Wallet }>(`/api/payments/v1/wallets/${wallet.walletUid}:withdraw`, {
        body: { amountInMinorUnits },
        headers: decorateHeadersWithClientId({}, clientId),
      })
      .pipe(map(res => res.data.wallet));
  }

  preset(clientId: string) {
    return this.apiRequest
      .post<Wallet>(`/api/payments/v1/wallets:preset`, { headers: decorateHeadersWithClientId({}, clientId) })
      .pipe(map(res => res.data));
  }

  update(wallet: Wallet, change: WalletChangeSet) {
    return this.apiRequest
      .put<Wallet>(`/api/payments/v1/wallets/${wallet.walletUid}`, {
        body: change,
      })
      .pipe(map(res => res.data));
  }

  transactions(walletUid: string, clientId: string) {
    return this.apiRequest
      .post<WalletTransactionsSearchResponse>(`/api/payments/v1/wallets/${walletUid}/transactions:search`, {
        headers: decorateHeadersWithClientId({}, clientId),
      })
      .pipe(map(res => res.data));
  }
}
