import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { ToastrModule } from 'ngx-toastr';

import { NotificationEffects } from './notification.effects';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      maxOpened: 1,
      autoDismiss: true,
    }),
    EffectsModule.forFeature([NotificationEffects]),
  ],
  declarations: [],
  providers: [NotificationEffects],
})
export class NotificationModule {}
