import { SanityProduct } from '../sanity-product';
import { SanityProductVariation } from '../sanity-product-variation';
import { PrintSide } from '@gelato-api-ui/core/print-sides/print-side.enum';
import { getBaseUid } from '@gelato-api-ui/core/sanity/helpers/getBaseUid';

export const getAppropriateProductUidByPrintSide = (
  product: SanityProduct,
  productUid: string,
  printSide: PrintSide,
): string => {
  if (!product?.productVariations?.length || !productUid) {
    return null;
  }

  const preparedProductUid = getBaseUid(productUid);
  const variant = product.productVariations.find(v => v.productUid === preparedProductUid);

  if (!variant) {
    return null;
  }

  const appropriateVariant = product.productVariations.find((v: SanityProductVariation): boolean => {
    if (
      v.garmentprint === printSide &&
      v.garmentsize === variant.garmentsize &&
      v.garmentcolor === variant.garmentcolor
    ) {
      return true;
    }

    if (v.bagprint === printSide && v.bagsize === variant.bagsize && v.bagcolor === variant.bagcolor) {
      return true;
    }

    return false;
  });

  return appropriateVariant.productUid;
};
