import { MetadataItem, MetadataItemValue } from '@gelato-api-ui/core/metadata/metadata-item';
import { getMetadataItem } from '@gelato-api-ui/core/metadata/helpers/getMetadataItem';

const removeMetadataItem = (items: MetadataItem[], key: string) => {
  if (!items || !items.length) {
    return [];
  }

  return items.filter((loopItem: MetadataItem): boolean => loopItem.key !== key);
};

const addMetadataItem = (items: MetadataItem[], key: string, value: MetadataItemValue) => {
  if (!items || !items.length) {
    return [{ key, value }];
  }

  return [...items, { key, value }];
};

export const updateMetadataItem = (items: MetadataItem[], key: string, value: MetadataItemValue): MetadataItem[] => {
  if (!value) {
    return removeMetadataItem(items, key);
  }

  if (getMetadataItem(items, key)) {
    return (items || []).map((loopItem: MetadataItem) => {
      if (loopItem.key !== key) {
        return loopItem;
      }

      return { ...loopItem, value };
    });
  }

  return addMetadataItem(items, key, value);
};
