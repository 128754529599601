import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

const BLUE500 = '#5991ff';

@Component({
  selector: 'gc-widget-segment',
  templateUrl: './widget-segment.component.html',
  styleUrls: ['./widget-segment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetSegmentComponent {
  @Input() index: number;
  @Input() headerTitle: string;
  @Input() headerIcon: string;
  @Input() title: string;
  @Input() text: string;
  @Input() buttonText: string;
  @Input() buttonIcon: string;
  @Input() secondaryButtonText: string;
  @Input() image: string;
  @Input() imageMaxHeight: string;
  @Input() videoId: string;
  @Input() buttonColor = BLUE500;
  @Input() showButtonArrowIcon = false;
  @Output() buttonClick = new EventEmitter<number>();
  @Output() secondaryButtonClick = new EventEmitter<number>();
  @Output() startVideo = new EventEmitter<void>();

  get backgroundImage() {
    return this.isVideo ? `https://img.youtube.com/vi/${this.videoId}/maxresdefault.jpg` : this.image;
  }

  get isVideo() {
    return !!this.videoId;
  }

  get showImage() {
    return this.image || this.isVideo;
  }

  get mainImageMaxHeight() {
    return `${this.imageMaxHeight}px` || '170px';
  }
}
