/**
 * editor.setEditorHostSettings -> https://confluence.gelato.tech/display/AD/editor.setEditorHostSettings
 */
export enum EditorInboundEventType {
  setEditorHostSettings = 'editor.setEditorHostSettings',
  setSidebarTabs = 'editor.setSidebarTabs',
  setSidebarActiveTabName = 'editor.setSidebarActiveTabName',
  setSidebarActiveTab = 'editor.setSidebarActiveTab',
  setTrackDesignChanges = 'editor.setTrackDesignChanges',
  setDefaultZoom = 'editor.setDefaultZoom',
  setSpreadsDefaultZoom = 'editor.setSpreadsDefaultZoom',
  setLocale = 'editor.setLocale',
  setDefaultTextStyle = 'editor.setDefaultTextStyle',
  setDefaultPersonalizationLock = 'editor.setDefaultPersonalizationLock',
  setupChameleon = 'editor.setupChameleon',
  setupAmplitude = 'editor.setupAmplitude',
  setDisabledSpreads = 'editor.setDisabledSpreads',
  setImagePatternScaleBases = 'editor.setImagePatternScaleBases',

  applyDesignDataTo = 'design.applyDesignDataTo',
  setDesignData = 'design.setDesignData',
  requestDesignData = 'design.requestDesignData',
  spreadBackgrounds = 'design.spreadBackgrounds',
  spreadForegrounds = 'design.spreadForegrounds',

  loadUploadedImages = 'gallery.loadUploadedImages',
  removeImages = 'gallery.removeImages',

  previewGeneratedSuccess = 'uploader.previewGeneratedSuccess',
  previewGeneratedRemotelySuccess = 'uploader.previewGeneratedRemotelySuccess',
  previewGeneratedError = 'uploader.previewGeneratedError',
  previewGeneratedRemotelyError = 'uploader.previewGeneratedRemotelyError',
  uploadSuccess = 'uploader.uploadSuccess',
  uploadError = 'uploader.uploadError',
  uploadProgress = 'uploader.uploadProgress',
  uploadStart = 'uploader.uploadStart',
  setUploaderConfig = 'uploader.setConfig',

  setRenders = 'preview.setRenders',
  rendersError = 'preview.rendersError',
  setFlatPreviewAvailable = 'preview.setFlatPreviewAvailable',
  setThe3dPreviewAvailable = 'preview.setThe3dPreviewAvailable',

  setProductData = 'variants.setProductData',
  setAsVariantFlow = 'variants.setAsVariantFlow',
  designTemplates = 'variants.designTemplates',

  applyTemplate = 'templates.applyTemplate',
  setTemplates = 'templates.setTemplates',
  setLayoutsAllowedTags = 'layouts.setAllowedTags',

  setAddons = 'addons.setAddons',
  addonUploaded = 'addons.addonUploaded',
  assetUploaded = 'plugins.assetUploaded',
  assetUploadedFailed = 'plugins.assetUploadFailed',
  addonUploadFailed = 'addons.addonUploadFailed',

  setAssetWithReplacedColors = 'asset.setAssetWithReplacedColors',

  trackEvent = 'event.trackEvent',

  setPlugins = 'plugins.setPlugins',
  setData = 'plugins.setData',

  backgroundRemoved = 'plugins.backgroundRemoved',
  backgroundRestored = 'plugins.backgroundRestored',
}
