import { createAction, props } from '@ngrx/store';
import { AppDetails } from '@gelato-api-ui/core/app-store/app-details';
import { SanityAppsListPage } from '@api-ui-app/src/app/subscriptions/types/sanity-apps-list-page';
import { GelatoSubscriptionModalType } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/lib/gelato-subscription-modal';

export const loadAppsList = createAction('[AppStore] Load AppsList');
export const loadAppsListStart = createAction('[AppStore] Load AppsList Start');
export const managePlanModal = createAction('[AppStore] Open manage plan modal');
export const loadAppsListContentStart = createAction('[AppStore] Load AppsListContent Start');

export const loadAppsListContentSuccess = createAction(
  '[AppStore] Load AppsListContent Success',
  props<{ payload: SanityAppsListPage }>(),
);

export const openSubscriptionsModal = createAction(
  '[AppStore] Open subscriptions modal',
  props<{ gelatoSubscriptionModalType: GelatoSubscriptionModalType }>(),
);

export const loadAppsListSuccess = createAction('[AppStore] Load AppsListSuccess', props<{ payload: AppDetails[] }>());

export const loadAppsListError = createAction('[AppStore] Load AppsListError');

export const loadAppDetails = createAction('[AppStore] Load AppDetails', props<{ uid: string }>());

export const loadAppDetailsByServiceName = createAction(
  '[AppStore] Load AppDetails By Service Name',
  props<{ serviceUniqueName: string }>(),
);

export const loadAppDetailsSuccess = createAction(
  '[AppStore] Load AppDetailsSuccess',
  props<{ payload: AppDetails }>(),
);

export const loadAppDetailsError = createAction('[AppStore] Load AppDetailsError');
