<form [formGroup]="form" class="ui form">
  <div class="field">
    <div class="two fields">
      <!-- FULL NAME -->
      <div class="field" [class.error]="hasError(address.recipientName)">
        <label>{{ 'txt_billing_name_field_label' | translate }}</label>
        <div class="ui input fluid">
          <input placeholder="" [formControlName]="address.recipientName" name="recipientName" />
        </div>
        <gc-form-field-error-message [error]="error" [fieldName]="address.recipientName"> </gc-form-field-error-message>
      </div>

      <!-- COMPANY NAME -->
      <div class="field" [class.error]="hasError(address.companyName)">
        <label>{{ 'txt_billing_company_name_field_label' | translate }}</label>
        <div class="ui input fluid">
          <input placeholder="" [formControlName]="address.companyName" name="companyName" />
        </div>
        <gc-form-field-error-message [error]="error" [fieldName]="address.companyName"> </gc-form-field-error-message>
      </div>
    </div>
  </div>

  <div class="field">
    <div class="two fields">
      <!-- ADDRESS -->
      <div class="field" [class.error]="hasError(address.address)">
        <label>{{ 'txt_billing_address_field_label' | translate }}</label>
        <div class="ui input fluid">
          <input placeholder="" [formControlName]="address.address" name="address" />
        </div>
        <gc-form-field-error-message [error]="error" [fieldName]="address.address"> </gc-form-field-error-message>
      </div>

      <!-- CONTACT EMAIL -->
      <div class="field" [class.error]="hasError(address.contactEmail)">
        <label>{{ 'txt_billing_email_field_label' | translate }}</label>
        <div class="ui input fluid">
          <input placeholder="" [formControlName]="address.contactEmail" name="contactEmail" />
        </div>
        <gc-form-field-error-message [error]="error" [fieldName]="address.contactEmail"> </gc-form-field-error-message>
      </div>
    </div>
  </div>

  <div class="field">
    <div class="two fields">
      <!-- POST CODE -->
      <div class="field" [class.error]="hasError(address.postCode)">
        <label>{{ 'txt_billing_postcode_field_label' | translate }}</label>
        <div class="ui input fluid">
          <input placeholder="" [formControlName]="address.postCode" name="postCode" />
        </div>
        <gc-form-field-error-message [error]="error" [fieldName]="address.postCode"> </gc-form-field-error-message>
      </div>

      <!-- PHONE -->
      <div class="field" [class.error]="hasError(address.phone)">
        <label>{{ 'txt_address_phone_field_label' | translate }}</label>
        <div class="ui input fluid">
          <gc-phone-field
            [phoneNumber]="fieldValue(address.phone)"
            [countryCode]="fieldValue(address.countryIsoCode)"
            (phoneChange)="onPhoneChange($event)"
          ></gc-phone-field>
        </div>
        <gc-form-field-error-message [error]="error" [fieldName]="address.phone"> </gc-form-field-error-message>
      </div>
    </div>
  </div>

  <div class="field">
    <div class="two fields">
      <!-- COUNTRY -->
      <div class="field" [class.error]="hasError(address.countryIsoCode)">
        <label>{{ 'txt_address_country_field_label' | translate }}</label>
        <gc-country-selector [value]="fieldValue(address.countryIsoCode)" (valueChange)="onCountryIdChange($event)">
        </gc-country-selector>
        <gc-form-field-error-message [error]="error" [fieldName]="address.countryIsoCode">
        </gc-form-field-error-message>
      </div>

      <!-- CITY -->
      <div class="field" [class.error]="hasError(address.city)">
        <label>{{ 'txt_address_city_field_label' | translate }}</label>
        <div class="ui input fluid">
          <input placeholder="" [formControlName]="address.city" name="city" />
        </div>
        <gc-form-field-error-message [error]="error" [fieldName]="address.city"> </gc-form-field-error-message>
      </div>
    </div>
  </div>

  <div class="field">
    <div class="two fields">
      <!-- STATE -->
      <div class="field" [class.error]="hasError(address.stateCode)">
        <label>
          {{ 'txt_billing_state_field_label' | translate }}
          <ng-container *ngIf="isShowStateOptionalField"> ({{ 'txt_optional_field_label' | translate }}) </ng-container>
        </label>

        <gc-country-state-selector
          [placeholderTxtKey]="''"
          [countryIsoCode]="fieldValue(address.countryIsoCode)"
          [value]="fieldValue(address.stateCode)"
          (valueChange)="onCountryStateChange($event)"
        >
        </gc-country-state-selector>

        <gc-form-field-error-message [error]="error" [fieldName]="address.stateCode"> </gc-form-field-error-message>
      </div>
    </div>
  </div>
</form>
