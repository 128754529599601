import { Action } from '@ngrx/store';
import { State } from './config.reducer';

export enum ConfigActionTypes {
  ResetState = '[ConfigCatalogue] ResetState',
  SetState = '[ConfigCatalogue] Set State',
  SetUseFlatShippingPricesFlag = '[AuthCatalogue] Set UseFlatShippingPrices Flag',
  SetMobileVersionFlag = '[AuthCatalogue] Set MobileDeviceFlag Flag',
  SetPhoneFlag = '[AuthCatalogue] Set Phone Flag',
  SetTabletFlag = '[AuthCatalogue] Set Tablet Flag',
}

export class ResetState implements Action {
  readonly type = ConfigActionTypes.ResetState;
}

export class SetState implements Action {
  readonly type = ConfigActionTypes.SetState;

  constructor(public payload: State) {}
}

export class SetUseFlatShippingPricesFlag implements Action {
  readonly type = ConfigActionTypes.SetUseFlatShippingPricesFlag;

  constructor(public payload: boolean) {}
}

export class SetMobileVersionFlag implements Action {
  readonly type = ConfigActionTypes.SetMobileVersionFlag;

  constructor(public payload: boolean) {}
}

export class SetPhoneFlag implements Action {
  readonly type = ConfigActionTypes.SetPhoneFlag;

  constructor(public payload: boolean) {}
}

export class SetTabletFlag implements Action {
  readonly type = ConfigActionTypes.SetTabletFlag;

  constructor(public payload: boolean) {}
}

export type ConfigActions =
  | ResetState
  | SetState
  | SetUseFlatShippingPricesFlag
  | SetMobileVersionFlag
  | SetPhoneFlag
  | SetTabletFlag;
