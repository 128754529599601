import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { SanityProductSimplified } from '@gelato-api-ui/core/sanity/sanity-product-simplified';
import { CatalogProduct } from '../catalog-product-preview-variants/lib/catalog-product.interface';
import { getProductRouterLink } from '@product-catalogue/src/lib/product-catalogue/lib/helpers/getProductRouterLink';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';

@Component({
  selector: 'gd-category-product-segment-routed',
  templateUrl: './category-product-segment-routed.component.html',
  styleUrls: ['./category-product-segment-routed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CategoryProductSegmentRoutedComponent implements OnChanges {
  @Input() product: SanityProductSimplified;
  @Input() catalogProduct: CatalogProduct<any>;
  @Input() productCategoryName: string;
  @Input() parentCategoryName: string;
  @Input() currency: string;
  @Input() productPrices: { [productUid: string]: number };
  @Input() rootPath;
  @Input() storeProductMode;
  @Input() newBoxShadow: boolean;
  @Output() navigate = new EventEmitter<any[]>();

  routerLink = [];

  ngOnChanges() {
    let productCategoryName = this.productCategoryName;
    let productName = null;

    if (this.product) {
      if (this.product.categoryName) {
        productCategoryName = this.product.categoryName;
      }

      productName = this.product.id;
    }

    this.routerLink = getProductRouterLink(
      this.storeProductMode,
      this.rootPath,
      productCategoryName,
      this.parentCategoryName,
      productName,
    );
  }

  onNavigate() {
    this.navigate.emit(this.routerLink);

    logEvent('Product Clicked', {
      product_id: this.product.id,
      category: this.productCategoryName,
      name: this.product.title,
      brand: this.product.brands,
      price: this.productPrices?.[this.product?.productUidForPrice],
      imageUrl: this.product?.productImages?.[0],
    });
  }
}
