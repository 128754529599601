import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { CommunicationSubscription } from '../notifications/lib/communication-subscription';
import { CommunicationSubscriptionSearchRequest } from '../notifications/lib/communication-subscription-search-request';
import { CommunicationSubscriptionSearchResponse } from '../notifications/lib/communication-subscription-search-response';
import * as actions from './notifications.actions';

export const getState = (state: AppState): State => state.notifications;

export const getListState = createSelector(getState, (state: State): ListState => state.list);

export const getListRequest = createSelector(
  getListState,
  (keysListState: ListState): CommunicationSubscriptionSearchRequest => keysListState.request,
);

export const getListResponse = createSelector(
  getListState,
  (keysListState: ListState): CommunicationSubscriptionSearchResponse => keysListState.payload,
);

export const getList = createSelector(
  getListResponse,
  (response: CommunicationSubscriptionSearchResponse): CommunicationSubscription[] => {
    if (!response || !response.data) {
      return [];
    }

    return response.data;
  },
);

export const getEntityState = createSelector(getState, (state: State): EntityState => state.entity);

export const getEntity = createSelector(
  getEntityState,
  (entityState: EntityState): CommunicationSubscription => entityState.payload,
);

export interface ListState {
  isLoading: boolean;
  request: CommunicationSubscriptionSearchRequest;
  payload: CommunicationSubscriptionSearchResponse;
}

export interface EntityState {
  isLoading: boolean;
  payload: CommunicationSubscription;
}

export interface State {
  list: ListState;
  entity: EntityState;
}

export const initialState: State = {
  list: {
    isLoading: false,
    request: null,
    payload: null,
  },
  entity: {
    isLoading: false,
    payload: null,
  },
};

export function reducer(state = initialState, action: actions.NotificationsActions): State {
  switch (action.type) {
    case actions.NotificationsActionTypes.ResetListState:
      return {
        ...state,
        list: { ...initialState.list },
      };
    case actions.NotificationsActionTypes.ResetListPayload:
      return {
        ...state,
        list: {
          ...state.list,
          payload: null,
        },
      };
    case actions.NotificationsActionTypes.SetListState:
      return {
        ...state,
        list: action.payload,
      };
    case actions.NotificationsActionTypes.ResetEntityState:
      return {
        ...state,
        entity: { ...initialState.entity },
      };
    case actions.NotificationsActionTypes.SetEntityState:
      return {
        ...state,
        entity: action.payload,
      };
    default:
      return state;
  }
}
