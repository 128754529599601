import { Asset } from '../asset';
import { AssetFile } from '../asset-file';
import { AssetFileType } from '../asset-file-type.enum';

export const getAssetFileUrl = (asset: Asset, assetFileType: AssetFileType): string => {
  if (!asset || !asset.files || !assetFileType) {
    return null;
  }

  let url = null;

  asset.files.forEach((assetFile: AssetFile) => {
    if (assetFile.type === assetFileType) {
      url = assetFile.url;
    }
  });

  return url;
};
