import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CountryFlagComponent } from '@gelato-api-ui/sdk/src/lib/shared/country-flag/country-flag.component';

@NgModule({
  imports: [CommonModule],
  declarations: [CountryFlagComponent],
  exports: [CountryFlagComponent],
})
export class CountryFlagModule {}
