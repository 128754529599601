export enum EProductPublishingErrorCode {
  GENERAL_ERROR = 'GENERAL_ERROR', // Unknown type of error
  GENERAL_PRODUCT_DUPLICATION_ERROR = 'GENERAL_PRODUCT_DUPLICATION_ERROR', // Unknown type of error
  CONNECTION_ERROR = 'CONNECTION_ERROR', // Issues with connection to the customer's store
  ACCESS_SCOPE_ERROR = 'ACCESS_SCOPE_ERROR', // Too low permissions assigned to the token
  ACCESS_ERROR = 'ACCESS_ERROR', // Issues with accessing the customer's store
  VALIDATION_ERROR = 'VALIDATION_ERROR', // Violation of a known validation rule
  UNKNOWN_VALIDATION_ERROR = 'UNKNOWN_VALIDATION_ERROR', // Violation of an unknown validation rule
  QUOTA_EXCEEDED_ERROR = 'QUOTA_EXCEEDED_ERROR', // Too many requests performed
  STORE_UNAVAILABLE_ERROR = 'STORE_UNAVAILABLE_ERROR', // Store unavailable
  PAYMENT_REQUIRED_ERROR = 'PAYMENT_REQUIRED_ERROR', // Customer has payment due towards certain platform
  PRODUCT_NOT_FOUND_ERROR = 'PRODUCT_NOT_FOUND_ERROR', // Product was not found in the external platform
  PRODUCT_NOT_EDITABLE_ERROR = 'PRODUCT_NOT_EDITABLE_ERROR', // Product is not editable in external platform
  TIMEOUT_ERROR = 'TIMEOUT_ERROR', // Making the request didn't complete in time
  DELIVERY_PROFILE_UPDATE_ERROR = 'DELIVERY_PROFILE_UPDATE_ERROR', // Assigning product to Delivery Profile failed
  QUOTA_TEMP_EXCEEDED_ERROR = 'QUOTA_TEMP_EXCEEDED_ERROR', // Too many requests performed in a short period of time, but daily quota is not yet exceeded
  INVALID_EXPAND_OPERATION_ERROR = 'INVALID_EXPAND_OPERATION_ERROR', // Customer tried to remove product option value during expand operation
  WOO_ACCESS_SCOPE_ERROR = 'WOO_ACCESS_SCOPE_ERROR',
  SHOPIFY_VARIANT_CREATE_LIMIT_ERROR = 'SHOPIFY_VARIANT_CREATE_LIMIT_ERROR',
  ETSY_STORE_IN_VACATION_MODE = 'ETSY_STORE_IN_VACATION_MODE',
  ETSY_DOMESTIC_AND_GLOBAL_PRICING_ERROR = 'ETSY_DOMESTIC_AND_GLOBAL_PRICING_ERROR',
}
