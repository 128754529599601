import { Injectable } from '@angular/core';
import { map, switchMap } from 'rxjs/operators';
import { CheckoutApiService } from './checkout-api.service';
import { OrderDetails } from '../../orders/lib/order-details';
import { ProductVariantConnectionData } from '@gelato-api-ui/core/e-commerce/product-variant-connection-data';
import { Observable } from 'rxjs';
import { OrderCreateItem, SaveOrderRequest } from '@api-ui-app/src/app/checkout/types/save-order-request';
import { getOrderCreateRequest } from '@api-ui-app/src/app/checkout/helpers/order-save-mapper';
import { getProductVariantIdFromMetadata } from '@api-ui-app/src/app/orders/lib/helpers/getProductVariantIdFromMetadata';
import { getCustomTrimValuesMm } from '@gelato-api-ui/core/e-commerce/helpers/getCustomTrimValuesMm';
import { OrderGraphQlService } from '@api-ui-app/src/app/orders/services/order-graph-ql.service';
import { ItemFileType } from '@api-ui-app/src/app/orders/lib/order-item';

@Injectable({
  providedIn: 'root',
})
export class OrderItemConnectionService {
  constructor(
    private readonly checkoutApiService: CheckoutApiService,
    private readonly orderGraphQlService: OrderGraphQlService,
  ) {}

  connectOrderItem(productVariantConnectionData: ProductVariantConnectionData): Observable<OrderDetails> {
    return this.orderGraphQlService.get(productVariantConnectionData.orderId).pipe(
      map(
        (orderDetails: OrderDetails): SaveOrderRequest =>
          this.getSaveOrderRequestWithConnectedItems(orderDetails, productVariantConnectionData),
      ),
      switchMap(
        (saveOrderRequest: SaveOrderRequest): Observable<OrderDetails> =>
          this.checkoutApiService.saveOrder(saveOrderRequest),
      ),
    );
  }

  private getSaveOrderRequestWithConnectedItems(
    orderDetails: OrderDetails,
    productVariantConnectionData: ProductVariantConnectionData,
  ) {
    const saveOrderRequest: SaveOrderRequest = getOrderCreateRequest(orderDetails);

    const items: OrderCreateItem[] = (saveOrderRequest.items || []).map(
      (loopItem: OrderCreateItem): OrderCreateItem => {
        const orderItemIdMatched = productVariantConnectionData.orderItemId === loopItem.id;
        const productVariantIdMatched =
          getProductVariantIdFromMetadata(loopItem.metadata) === productVariantConnectionData.productVariantId;
        const customTrimMm = getCustomTrimValuesMm(productVariantConnectionData.customTrim);

        if (orderItemIdMatched || (productVariantIdMatched && !loopItem.isIgnored)) {
          return {
            ...loopItem,
            productUid: productVariantConnectionData.productUid,
            customTrim: customTrimMm ? { widthMm: customTrimMm.width, heightMm: customTrimMm.height } : null,
            files: productVariantConnectionData.fileUrl
              ? [{ type: ItemFileType.default, url: productVariantConnectionData.fileUrl }]
              : null,
            designId: productVariantConnectionData.designId,
            pageCount: productVariantConnectionData.pageCount,
          };
        }

        return loopItem;
      },
    );

    return {
      ...saveOrderRequest,
      items,
    };
  }
}
