import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { getFormattedSizeGuideFromSanityProduct } from '@gelato-api-ui/core/sanity/helpers/getFormattedSizeGuideFromSanityProduct';
import { getFormattedProductCareInstructionsFromSanityProduct } from '@gelato-api-ui/core/sanity/helpers/getFormattedProductCareInstructionsFromSanityProduct';
import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import { EStoreFeature } from '@gelato-api-ui/core/e-commerce/e-store-feature.enum';
import { stripTags } from '@gelato-api-ui/core/dom/helpers/stripTags';
import { ECommerceStoreFeaturesService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-store-features.service';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductDetailsService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly eCommerceStoreFeaturesService: ECommerceStoreFeaturesService,
  ) {}

  getProductTitle(sanityProductCategory: SanityProductCategory, sanityProduct: SanityProduct): string {
    return sanityProduct?.title || sanityProductCategory?.title || 'Untitled';
  }

  getProductDescription(sanityProduct: SanityProduct, storeType: EStoreType): string {
    return this.normalizeProductDescription(sanityProduct.description || '', storeType);
  }

  getProductCareInstructions(sanityProduct: SanityProduct): string {
    return getFormattedProductCareInstructionsFromSanityProduct(sanityProduct);
  }

  getSizeGuideTable(sanityProduct: SanityProduct, inInches: boolean): string {
    if (!sanityProduct || !sanityProduct.sizeGuide) {
      return '';
    }

    const columnSuffixTranslationKey = inInches
      ? 'txt_size_guide_inches_table_first_column_suffix'
      : 'txt_size_guide_centimeters_table_first_column_suffix';

    const columnSuffix = this.translateService.instant(columnSuffixTranslationKey);

    const sourceTable = inInches ? sanityProduct.sizeGuide.inchesTable : sanityProduct.sizeGuide.centimetersTable;

    if (!sourceTable) {
      return '';
    }

    return getFormattedSizeGuideFromSanityProduct(sourceTable, columnSuffix);
  }

  normalizeProductDescription(description: string, storeType: EStoreType): string {
    const useHtmlMarkup = this.eCommerceStoreFeaturesService.isFeatureSupported(
      storeType,
      EStoreFeature.HTML_DESCRIPTION,
    );

    return useHtmlMarkup ? description : stripTags(description);
  }
}
