import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { ProductionConfiguration } from '../production-configurations/lib/production-configuration';
import { ProductionConfigurationSearchRequest } from '../production-configurations/lib/production-configuration-search-request';
import { ProductionConfigurationSearchResponse } from '../production-configurations/lib/production-configuration-search-response';
import * as actions from './production-configurations.actions';

export const getState = (state: AppState): State => state.productionConfigurations;

export const getListState = createSelector(getState, (state: State): ListState => state.list);

export const getListRequest = createSelector(
  getListState,
  (keysListState: ListState): ProductionConfigurationSearchRequest => keysListState.request,
);

export const getListResponse = createSelector(
  getListState,
  (keysListState: ListState): ProductionConfigurationSearchResponse => keysListState.payload,
);

export const getList = createSelector(
  getListResponse,
  (response: ProductionConfigurationSearchResponse): ProductionConfiguration[] => {
    if (!response || !response.data) {
      return [];
    }

    return response.data;
  },
);

export const getEntityState = createSelector(getState, (state: State): EntityState => state.entity);

export const getEntity = createSelector(
  getEntityState,
  (entityState: EntityState): ProductionConfiguration => entityState.payload,
);

export interface ListState {
  isLoading: boolean;
  request: ProductionConfigurationSearchRequest;
  payload: ProductionConfigurationSearchResponse;
}

export interface EntityState {
  isLoading: boolean;
  payload: ProductionConfiguration;
}

export interface State {
  list: ListState;
  entity: EntityState;
}

export const initialState: State = {
  list: {
    isLoading: false,
    request: null,
    payload: null,
  },
  entity: {
    isLoading: false,
    payload: null,
  },
};

export function reducer(state = initialState, action: actions.ProductionConfigurationsActions): State {
  switch (action.type) {
    case actions.ProductionConfigurationsActionTypes.ResetListState:
      return {
        ...state,
        list: { ...initialState.list },
      };
    case actions.ProductionConfigurationsActionTypes.ResetListPayload:
      return {
        ...state,
        list: {
          ...state.list,
          payload: null,
        },
      };
    case actions.ProductionConfigurationsActionTypes.SetListState:
      return {
        ...state,
        list: action.payload,
      };
    case actions.ProductionConfigurationsActionTypes.ResetEntityState:
      return {
        ...state,
        entity: { ...initialState.entity },
      };
    case actions.ProductionConfigurationsActionTypes.SetEntityState:
      return {
        ...state,
        entity: action.payload,
      };
    default:
      return state;
  }
}
