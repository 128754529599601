import { PricePlanSubscriptionStatus } from './price-plan-subscription-status.enum';

export class PricePlanSubscriptionUpdateRequest {
  constructor(
    public clientId: string,
    public pricePlanId: string,
    public status: PricePlanSubscriptionStatus,
    public activeFrom: string,
    public activeTo: string,
  ) {}
}
