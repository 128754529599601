<div *ngIf="isHelloBarVisible$ | async" class="wrapper" [style.backgroundColor]="backgroundColor$ | async">
  <ng-container *ngIf="helloBar$ | async as data">
    <div
      class="animator"
      [@slideUp]="{
        value: triggerAnimation$ | async,
        params: { top: (isMobile$ | async) ? '-60px' : '-40px' }
      }"
      (@slideUp.done)="onAnimationDone()"
    >
      <div
        *ngFor="let block of activeMessages$ | async"
        class="block"
        [style.fontSize]="fontSize$ | async"
        [style.color]="fontColor$ | async"
        (click)="goTo(block.link, block.message)"
      >
        <img *ngIf="block.icon" width="20" height="20" [src]="block.icon" alt="" />
        <span>{{ block.message }}</span>
      </div>
    </div>
    <img class="close-button" alt="" [src]="'close.svg' | assetsPath | async" (click)="onDismiss(data.publishedAt)" />
  </ng-container>
</div>
