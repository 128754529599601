import { NgModule, Optional, SkipSelf } from '@angular/core';
import { ClipboardModule } from 'ngx-clipboard';
import { throwIfAlreadyLoaded } from './sdk-import-guard';
import { StorageModule } from './storage/storage.module';
import { I18nModule } from './translations/i18n.module';
import { SanityModule } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.module';
import { PageLeaveConfirmationModule } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation.module';

@NgModule({
  imports: [I18nModule, StorageModule, ClipboardModule, SanityModule, PageLeaveConfirmationModule],
})
export class SdkModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: SdkModule,
  ) {
    throwIfAlreadyLoaded(parentModule, 'SdkModule');
  }
}
