import { CustomTrim, CustomTrimUnit, CustomTrimValue } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { CustomTrimValuesMm } from '@gelato-api-ui/core/e-commerce/custom-trim-values-mm';
import { convertDimensionValue } from '@gelato-api-ui/core/product-details/helpers/convertDimensionValue';

export const getCustomTrimValuesMm = (customTrim: CustomTrim): CustomTrimValuesMm => {
  if (!customTrim?.width?.value || !customTrim?.width?.unit || !customTrim.height?.value || !customTrim?.height?.unit) {
    return null;
  }

  return {
    width: getDimensionValue(customTrim.width),
    height: getDimensionValue(customTrim.height),
  };
};

const getDimensionValue = (customTrimValue: CustomTrimValue): number => {
  if (!customTrimValue) {
    return null;
  }

  if (customTrimValue.unit !== CustomTrimUnit.mm) {
    return convertDimensionValue(customTrimValue.value, customTrimValue.unit, CustomTrimUnit.inch);
  }

  return customTrimValue.value;
};
