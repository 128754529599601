import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gd-radio-button, gc-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RadioButtonComponent implements OnInit {
  @Input() name: string;
  @Input() value: string;
  @Input() checked: boolean;
  @Input() disabled: boolean;
  @Output() checkedChange = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  toggleChecked(event) {
    this.checkedChange.emit(!this.checked);
    event.stopPropagation();
  }
}
