import { createAction, props } from '@ngrx/store';
import { PreflightPreviewsArchiveRequest } from '@gelato-api-ui/core/preflight/preflight-previews-download';

export const requestPreviewsArchive = createAction(
  '[PreviewsArchive] Request Previews Archive',
  props<{
    payload: {
      request: PreflightPreviewsArchiveRequest;
      previewsAmount: number;
    };
  }>(),
);

export const setPreviewsArchiveTaskId = createAction(
  '[PreviewsArchive] Set Previews Archive TaskId',
  props<{
    payload: {
      taskId: string;
      productTitle: string;
      previewsAmount: number;
    };
  }>(),
);

export const setPreviewsArchiveUrl = createAction(
  '[PreviewsArchive] Set Previews Archive Url',
  props<{
    payload: {
      url: string | null;
      taskId: string;
    };
  }>(),
);

export const cancelPreviewsArchiveRequest = createAction(
  '[PreviewsArchive] Cancel Previews Archive Request',
  props<{ payload: string }>(),
);
