import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ResourceAccessLevel } from '../lib/resource-access-level.enum';

@Pipe({
  name: 'accessLevel',
})
export class AccessLevelPipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(accessScope: string): any {
    let accessLevel = 'none';

    if (accessScope) {
      if (/:ro$/i.test(accessScope)) {
        accessLevel = ResourceAccessLevel.READ_ONLY;
      }

      if (/:rw$/i.test(accessScope)) {
        accessLevel = ResourceAccessLevel.FULL;
      }
    }

    return this.translateService.get(`txt_user_access_level_${accessLevel}`);
  }
}
