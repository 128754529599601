export enum BillingAddressEnum {
  recipientName = 'recipientName',
  address = 'address',
  city = 'city',
  postCode = 'postCode',
  countryIsoCode = 'countryIsoCode',
  stateCode = 'stateCode',
  email = 'email',
  contactEmail = 'contactEmail',
  phone = 'phone',
  billingEmail = 'billingEmail',
  supportEmail = 'supportEmail',
}

export enum BillingAddressViewEnum {
  default = 'default',
  checkout = 'checkout',
}
