<div [class.active]="active" [class.disabled]="disabled" class="button" (click)="onClick()">
  <div>
    <div>
      <img *ngIf="icon" src="{{ icon }}" />
    </div>
    <div class="button-label">
      {{ label }}
    </div>
  </div>
</div>
