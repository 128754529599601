import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

export interface GraphQlOperationOptions {
  variables: any;
  query: string;
}

@Injectable({ providedIn: 'root' })
export class GraphQlRequestService {
  constructor(private readonly apollo: Apollo) {}

  query<T>(query: GraphQlOperationOptions, operationName: string = null, noCache: boolean = false) {
    const variables = query.variables || {};
    return this.apollo.query<T>({
      variables: {
        ...variables,
        operationName,
      },
      query: gql`
        ${query.query}
      `,
      fetchPolicy: noCache ? 'no-cache' : undefined,
    });
  }

  mutate<T>(mutation: GraphQlOperationOptions, operationName: string = null) {
    const variables = mutation.variables || {};
    return this.apollo.mutate<T>({
      variables: {
        ...variables,
        operationName,
      },
      mutation: gql`
        ${mutation.query}
      `,
    });
  }
}
