import { Action, createReducer, on } from '@ngrx/store';
import { splashMessageAdapter, SplashMessageState } from './splash-message.adapter';
import { add, hide } from './splash-message.actions';

const initialState: SplashMessageState = splashMessageAdapter.getInitialState();

const reducer = createReducer(
  initialState,
  on(add, (state, action) => splashMessageAdapter.addOne(action.message, state)),
  on(hide, (state, action) => splashMessageAdapter.removeOne(action.id, state)),
);

export function splashMessageReducer(state: SplashMessageState, action: Action) {
  return reducer(state, action);
}
