import { Component, Input } from '@angular/core';
import { TabLinkItem } from '@api-ui-app/src/app/shared/lib/tab-link-item';

@Component({
  selector: 'gd-tab-links',
  templateUrl: './tab-links.component.html',
  styleUrls: ['./tab-links.component.scss'],
})
export class TabLinksComponent {
  @Input() items: TabLinkItem[] = [];

  onClick(item: TabLinkItem) {
    if (item?.onClick) {
      item.onClick();
    }
  }
}
