import { distinctUntilChanged, filter, first, map, skipWhile, tap } from 'rxjs/operators';
import * as R from 'ramda';
import { isNotNil } from './ramda';
import { pipe } from 'rxjs';

export const filterNotNil = filter(isNotNil);
export const mapToValues = map(R.values);

export const isLoadedPipe = (triggerLoad: () => any) =>
  pipe(
    distinctUntilChanged(),
    tap(isLoaded => {
      if (!isLoaded) {
        triggerLoad();
      }
    }),
    skipWhile(isLoaded => !isLoaded),
    map(() => true),
    first(),
  );

export const firstNotNil = pipe(skipWhile(R.isNil), first());
