import { Injectable } from '@angular/core';
import { catchError, take, tap } from 'rxjs/operators';
import { of, Subject, Subscription } from 'rxjs';
import { EditorInboundEventType } from '@gelato-api-ui/core/design-editor/editor-inbound-event-type.enum';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { BackgroundRemoveApiService } from '@api-ui-app/src/app/subscriptions/services/background-remove-api.service';
import { CreatePreviewResponse } from '@api-ui-app/src/app/subscriptions/types/background-remove';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { AssetFileType } from '@gelato-api-ui/core/designs/asset-file-type.enum';
import { RemoveBackgroundItemsMetadata } from '@gelato-api-ui/core/metadata/metadata-item';
import { EditorPostMessageInterface } from '@product-catalogue/src/lib/product-catalogue/constants/design-editor.constant';
import { UploadedImageData } from '@gelato-api-ui/core/designs/uploaded-image-data';
import { mapAssetsToCollection } from '@gelato-api-ui/core/designs/helpers/mapAssetsToCollection';
import { AddAssets } from '@product-catalogue/src/lib/ngrx/assets.actions';
import { UploadedImageDataService } from '@gelato-api-ui/core/designs/services/uploaded-image-data.service';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import * as Sentry from '@sentry/angular';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';

@Injectable()
export class BackgroundRemoveService {
  postMessage$ = new Subject<EditorPostMessageInterface>();
  subscriptions: Subscription[] = [];
  productTypeUid: ProductTypeUid;
  assetsCollection$ = this.subscriptionsFacade.assetsCollection$;

  constructor(
    private readonly store: Store<AppState>,
    private readonly subscriptionsFacade: SubscriptionsFacade,
    private readonly backgroundRemoveApiService: BackgroundRemoveApiService,
    private readonly uploadedImageDataService: UploadedImageDataService,
  ) {}

  removeBackground(message: EditorPostMessageInterface) {
    this.assetsCollection$
      .pipe(
        take(1),
        tap((assets: AssetsCollection) => {
          if (message?.data?.preview) {
            this.createPreviewForAsset(message);
          } else {
            this.replaceImageWithExistingOneInAssets(assets, message);
          }
        }),
      )
      .subscribe();
  }

  private createPreviewForAsset(message: EditorPostMessageInterface) {
    const data = this.mapEventMessageToData(message);

    this.backgroundRemoveApiService
      .createPreviewForAsset(message.data.assetId)
      .pipe(
        catchError(error => {
          Sentry.captureException(new Error('createPreviewForAsset'), scope => {
            scope.setExtras({
              error: error?.response?.error?.internalMessage,
              requestId: error?.response?.error?.requestId,
              assetId: data?.assetId,
            });

            return scope;
          });
          this.store.dispatch(new ShowGeneralErrorNotification());
          return of(null);
        }),
        take(1),
        tap((response: CreatePreviewResponse) => {
          logEvent('Asset Preview Created', {
            assetId: data.assetId,
          });
          this.updatePreviewEvent(data.assetId, data.elementId, data.preview, response?.url);
        }),
      )
      .subscribe();
  }

  private replaceImageWithExistingOneInAssets(assets: AssetsCollection, message: EditorPostMessageInterface) {
    const data = this.mapEventMessageToData(message);
    const metadata = Object.values(assets)?.find(asset => asset.id === data?.assetId)?.metadata;
    const hasReference = metadata?.find(
      metadataItem => metadataItem.key === RemoveBackgroundItemsMetadata.BACKGROUND_REMOVAL_REFERENCE_ASSET_ID,
    )?.value;

    const existingAssetWithRemovedBackground: Asset = Object.values(assets)?.find(asset => asset.id === hasReference);
    const existingAssetHasNoBackground = !existingAssetWithRemovedBackground?.metadata?.find(
      metadataItem => metadataItem.key === RemoveBackgroundItemsMetadata.BACKGROUND_REMOVAL_HAS_BACKGROUND,
    )?.value;

    // Client has an asset with already removed background and we can use it to avoid making a duplicate request
    if (existingAssetHasNoBackground && existingAssetWithRemovedBackground) {
      this.updatePreviewEvent(
        existingAssetWithRemovedBackground?.id,
        data?.elementId,
        data?.preview,
        existingAssetWithRemovedBackground?.files.find(file => file.type === AssetFileType.PREVIEW_DEFAULT)?.url,
      );
    } else {
      // Client does not have an asset with already removed background and we need call the request to create such an asset
      this.createAssetWithRemovedBackground(message);
    }
  }

  private createAssetWithRemovedBackground(message: EditorPostMessageInterface) {
    const data = this.mapEventMessageToData(message);

    this.backgroundRemoveApiService
      .createAssetWithoutBackground(data?.assetId)
      .pipe(
        catchError(error => {
          Sentry.captureException(new Error('createAssetWithoutBackground'), scope => {
            scope.setExtras({
              error: error?.response?.error?.internalMessage,
              requestId: error?.response?.error?.requestId,
              assetId: data?.assetId,
            });

            return scope;
          });
          this.store.dispatch(new ShowGeneralErrorNotification());
          return of(null);
        }),
        take(1),
        tap((asset: Asset) => {
          logEvent('Asset Created', {
            background: 'without',
            assetId: data?.assetId,
          });

          if (!asset) {
            this.updatePreviewEvent(data?.assetId, data?.elementId, data?.preview, null);
            return null;
          }

          const uploadedImagesData: UploadedImageData[] = this.uploadedImageDataService.mapAssetsToUploadedImagesData(
            [asset],
            true,
          );

          const assetsCollection: AssetsCollection = mapAssetsToCollection([asset]);

          this.store.dispatch(new AddAssets(assetsCollection));

          if (uploadedImagesData) {
            this.postMessage$.next({
              data: uploadedImagesData,
              type: EditorInboundEventType.loadUploadedImages,
            });
          }

          this.updatePreviewEvent(asset?.id, data?.elementId, data?.preview, asset?.files[0]?.url);
        }),
      )
      .subscribe();
  }

  private mapEventMessageToData(message: EditorPostMessageInterface) {
    return {
      assetId: message.data.assetId,
      elementId: message.data.elementId,
      preview: message.data.preview,
    };
  }

  private updatePreviewEvent(assetId, elementId, preview, url) {
    this.postMessage$.next({
      data: {
        assetId,
        elementId,
        preview,
        url,
      },
      type: EditorInboundEventType.backgroundRemoved,
    });
  }
}
