import { Injectable } from '@angular/core';
import { EProductVariantConnectionSettings } from '@gelato-api-ui/sdk/src/lib/shared/e-product-variant-connection-settings-modal/types/e-product-variant-connection-settings';
import { EProductPublishScope } from '@gelato-api-ui/core/e-commerce/e-product-publish-scope.enum';

@Injectable()
export class EProductVariantConnectionSettingsService {
  getPublishScopes(settings: EProductVariantConnectionSettings): EProductPublishScope[] {
    const scopes = [EProductPublishScope.VARIANTS];

    if (settings.updatePreview) {
      scopes.push(EProductPublishScope.IMAGES);
    }

    if (settings.updatePrice) {
      scopes.push(EProductPublishScope.PRICES);
    }

    return scopes;
  }
}
