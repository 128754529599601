import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import {
  ShowGeneralErrorNotification,
  ShowNotification,
} from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../../../app.state';
import { getLastRequest, getOrdersTotalForPastMonth, getState as getOrdersState } from './orders.selector';
import { OrderSearchResponse } from '../../lib/order-search-response';
import { OrdersApiService } from '../../services/orders-api.service';
import { OrderDeletionService } from '../../services/order-deletion.service';
import * as actions from './orders.actions';
import { ResetOrderDetails } from '../order-details/order-details.actions';
import { LoadClientsByIds } from '@api-ui-app/src/app/ngrx/client-selection-list.actions';

@Injectable()
export class OrdersEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly ordersApiService: OrdersApiService,
    private readonly orderDeletionService: OrderDeletionService,
    private readonly translateService: TranslateService,
  ) {}

  search$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.Load>(actions.OrdersAction.Load),
      withLatestFrom(this.store$.select(getOrdersState)),
      filter(
        ([action, ordersState]) =>
          action.forced || !R.equals(action.payload, ordersState.request) || !ordersState.payload,
      ),
      tap(([action]) => this.store$.dispatch(actions.loadStart({ request: action.payload }))),
      switchMap(([action]) =>
        this.ordersApiService.search(action.payload).pipe(
          catchError((): Observable<OrderSearchResponse> => {
            this.store$.dispatch(new ShowGeneralErrorNotification());
            this.store$.dispatch(actions.loadFailure());
            return of(null);
          }),
          filter((payload: OrderSearchResponse) => !!payload && !!payload.orders),
          mergeMap((payload: OrderSearchResponse) => {
            const ids = payload.orders.map(order => order.clientId);
            return [
              actions.loadSuccess({ payload, request: action.payload }),
              this.shouldLoadTotals(action) ? actions.loadTotals({ payload: action.payload }) : actions.noop(),
              new LoadClientsByIds(ids),
            ];
          }),
        ),
      ),
    ),
  );

  deleteOrder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.deleteOrder),
        withLatestFrom(this.store$.select(getLastRequest)),
        switchMap(([action, lastRequest]) => {
          const { orderId, orderReferenceId } = action;

          return this.orderDeletionService.delete(orderId).pipe(
            catchError(() => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap(response => {
              this.store$.dispatch(new ResetOrderDetails());
              this.store$.dispatch(new actions.Load(lastRequest, true));
              if (response) {
                this.store$.dispatch(
                  new ShowNotification({
                    message: this.translateService.instant('txt_order_deletion_success', { orderReferenceId }),
                    type: 'success',
                  }),
                );
              }
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadTotals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadTotals),
      switchMap(action =>
        this.ordersApiService
          .searchTotals(action.payload)
          .pipe(map(res => actions.loadTotalsSuccess({ payload: res }))),
      ),
    ),
  );

  loadTotalsForPastMonth$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadTotalsForPastMonth),
      withLatestFrom(this.store$.select(getOrdersTotalForPastMonth)),
      filter(([_, totals]) => R.isNil(totals)),
      switchMap(([action]) =>
        this.ordersApiService
          .searchTotals(action.payload)
          .pipe(map(res => actions.loadTotalsForPastMonthSuccess({ payload: res }))),
      ),
    ),
  );

  private shouldLoadTotals(action: actions.Load) {
    return !action.payload.offset && action.shuldLoadTotals;
  }
}
