import { FlatPreviewWithShutterstockAssetBorderWidthPxs } from '@gelato-api-ui/core/shutterstock/shutterstock.constant';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';

export interface GelatoApiDesignRendersLoaderConfig {
  preflightUrl: string;
}

export interface RequestOptions {
  body?: any;
  headers?: Record<string, string>;
}

enum ENDPOINTS {
  PRODUCT_PREVIEW_NO_AUTH = '/product/preview',
  PRODUCT_PREVIEW = '/v1/product/preview',
  PRODUCT_PREVIEWS_TYPES = '/product/preview/types',
  PRODUCT_SCENES = '/product/preview/scenes',
  PRODUCT_PDF_FROM_JSON = '/product/pdf_from_json',
  PRODUCT_IMAGE_FROM_JSON = '/product/image_from_json',
}

interface FlatPreview {
  url: string;
  thumbnailUrl: string;
}

export interface Previews {
  flat?: FlatPreview[];
  the3d?: { url: string };
}

export class GelatoApiDesignRendersLoader {
  private productUid = '';
  private previews: { flat: boolean; the3d: boolean; image: boolean; scene: boolean } = {
    flat: true,
    the3d: false,
    image: true,
    scene: true,
  };
  private PRODUCT_PREVIEW_ENDPOINT = ENDPOINTS.PRODUCT_PREVIEW;

  constructor(
    private readonly config: GelatoApiDesignRendersLoaderConfig,
    private readonly get: <T>(url: string, options: RequestOptions) => Promise<{ data?: T; error?: any }>,
    private readonly post: <T>(url: string, options: RequestOptions) => Promise<{ data?: T; error?: any }>,
    private readonly isEmbeddableEditor: boolean,
  ) {
    if (isEmbeddableEditor) {
      this.PRODUCT_PREVIEW_ENDPOINT = ENDPOINTS.PRODUCT_PREVIEW_NO_AUTH;
    }
  }

  async getAvailablePreviews(productUid: string) {
    try {
      this.productUid = productUid;
      const url = this.getRequestUrl(ENDPOINTS.PRODUCT_PREVIEWS_TYPES, { product_uid: productUid });
      const response = await this.get<string[]>(url, {});
      const availablePreviews = response.data;
      if (!availablePreviews) {
        return false;
      }

      this.previews = {
        flat: availablePreviews.indexOf('scene') !== -1 || availablePreviews.indexOf('image') !== -1,
        the3d: availablePreviews.indexOf('ar-viewer') !== -1,
        image: availablePreviews.indexOf('image') !== -1,
        scene: availablePreviews.indexOf('scene') !== -1,
      };
      return this.previews;
    } catch (e) {
      return false;
    }
  }

  async loadDesignRenders(
    designData: any,
    takeFirstSpread = false,
    noFlatPreview = false,
    removeSamples = true,
    passAllSpreads = false,
    isShutterstockFlatPreviewNeeded = false,
  ) {
    let designStructure = { ...designData };
    if (takeFirstSpread) {
      const [firstSpread] = designStructure.spreads;
      designStructure = {
        ...designStructure,
        spreads: [firstSpread],
      };
    }
    const pagesCount = this.getPagesCount(designStructure);
    const imageArray = Array(noFlatPreview ? 1 : pagesCount).fill(null);

    const imagePromises = Promise.all(
      imageArray.map((_, index) => this.loadImageUrlFromJson(designStructure, index + 1, removeSamples)),
    ).then(imageUrls => imageUrls.filter(url => !!url)) as Promise<string[]>;

    const [scenes, images] = await Promise.all([this.loadScenesNames(this.productUid), imagePromises]);

    if (!images.length || !scenes) {
      return false;
    }

    const previews: Previews = { flat: [] };
    let imageRenders: FlatPreview[] = [];
    let scenesRenders: FlatPreview[] = [];

    if (this.previews.image && !noFlatPreview) {
      imageRenders = await this.getImagesPreview(pagesCount, designData, images, passAllSpreads);
    }

    if (isShutterstockFlatPreviewNeeded) {
      // Render a flat preview with a padding. Only for Shutterstock assets, according to their policy
      imageRenders = [
        await this.getImagePreview(
          0,
          images,
          designData,
          passAllSpreads,
          FlatPreviewWithShutterstockAssetBorderWidthPxs,
        ),
      ];
    }

    if (this.previews.scene && pagesCount === 1) {
      // GAPI-15227: 4 is a max scenes to take
      const limitedScenes = scenes.slice(0, 4);
      scenesRenders = await this.getScenesPreview(limitedScenes, designData, images, passAllSpreads);
    }

    // GAPI-15227: 4 is the limit for all the previews to show
    previews.flat = [...imageRenders, ...scenesRenders].slice(0, 4);
    previews.the3d = this.previews.the3d && pagesCount === 1 ? await this.getThe3dPreview(images[0]) : undefined;

    return previews;
  }

  private getRequestUrl(endpoint: ENDPOINTS, urlParams: { [key: string]: any }) {
    const baseUrl = this.config.preflightUrl;
    const params = new URLSearchParams(urlParams);
    return `${baseUrl}${endpoint}?${params}`;
  }

  private async loadImageUrlFromJson(designData: any, pageNumber: number, removeSamples: boolean) {
    try {
      const imageRequestUrl = this.getRequestUrl(ENDPOINTS.PRODUCT_IMAGE_FROM_JSON, {
        page_nr: pageNumber,
        format: 'png',
        width: '1024',
        height: '1024',
        remove_samples: removeSamples ? 1 : 0,
      });
      const response = await this.post<{ url: string }>(imageRequestUrl, { body: designData });
      return response.data?.url;
    } catch (e) {
      return undefined;
    }
  }

  private async loadScenesNames(productUid: string) {
    try {
      const scenesUrl = this.getRequestUrl(ENDPOINTS.PRODUCT_SCENES, { product_uid: productUid });
      const scenesResponse = await this.get<string[]>(scenesUrl, {});
      return scenesResponse.data;
    } catch (e) {
      return undefined;
    }
  }

  private getPagesCount(designData: any): number {
    return designData.spreads.slice(-1).pop().pages.slice(-1).pop().page_nr;
  }

  private async getThe3dPreview(pdfUrl: string): Promise<{ url: string }> {
    const params = new URLSearchParams({
      type: 'ar-viewer',
      product_uid: this.productUid,
      image_url: pdfUrl,
    });

    const url = this.getRequestUrl(this.PRODUCT_PREVIEW_ENDPOINT, params);

    if (this.isEmbeddableEditor) {
      return { url };
    }

    const response = await this.get<{ url: string }>(url, {});
    return response.data;
  }

  private async getScenesPreview(
    scenes: string[],
    designData: any,
    imageUrls: string[],
    passAllSpreads: boolean,
  ): Promise<FlatPreview[]> {
    const scenesPromises = scenes
      .filter((scene: string): boolean => scene && scene !== 'default' && !/back/i.test(scene))
      .map((scene: string): Promise<FlatPreview> => this.getScenePreview(scene, designData, imageUrls, passAllSpreads));
    const flatPreviews = await Promise.all(scenesPromises);
    return flatPreviews;
  }

  private getImagesUrlsMap(imageUrls: string[], designData: any) {
    const imageMap: { [spreadName: string]: string } = {};
    imageUrls.forEach((url, i) => {
      imageMap[designData.spreads[i]?.name || ''] = url;
    });
    return JSON.stringify(imageMap);
  }

  private async getScenePreview(
    scene: string,
    designData: any,
    imageUrls: string[],
    passAllSpreads: boolean,
  ): Promise<FlatPreview> {
    const params = new URLSearchParams({
      type: 'scene',
      product_uid: this.productUid,
      width: '1200',
      height: '800',
      scene,
    });

    const paramsThumbnail = new URLSearchParams({
      type: 'scene',
      product_uid: this.productUid,
      width: '140',
      height: '100',
      scene,
    });

    if (passAllSpreads) {
      const imageMap = this.getImagesUrlsMap(imageUrls, designData);
      params.set('images_urls', imageMap);
      paramsThumbnail.set('images_urls', imageMap);
    } else {
      params.set('image_url', imageUrls[0]);
      paramsThumbnail.set('image_url', imageUrls[0]);
    }
    const url = this.getRequestUrl(this.PRODUCT_PREVIEW_ENDPOINT, params);
    const urlThumb = this.getRequestUrl(this.PRODUCT_PREVIEW_ENDPOINT, paramsThumbnail);

    if (this.isEmbeddableEditor) {
      return {
        url,
        thumbnailUrl: urlThumb,
      };
    }

    const response = await this.get<{ url: string }>(url, {});
    const responseThumb = await this.get<{ url: string }>(urlThumb, {});

    return {
      url: response.data.url,
      thumbnailUrl: responseThumb.data.url,
    };
  }

  private async getImagesPreview(
    pagesCount: number,
    designData: any,
    imageUrls: string[],
    passAllSpreads: boolean,
  ): Promise<FlatPreview[]> {
    const promises = Array(pagesCount)
      .fill(null)
      .map((_, index) => {
        return imageUrls[index] ? this.getImagePreview(index, imageUrls, designData, passAllSpreads) : undefined;
      })
      .filter(_ => !!_) as Promise<FlatPreview>[];

    const imagesPreview = await Promise.all(promises);
    return imagesPreview;
  }

  private async getImagePreview(
    index: number,
    imageUrls: string[],
    designData: any,
    passAllSpreads: boolean,
    borderWidth = null,
  ): Promise<FlatPreview> {
    const params = new URLSearchParams({
      type: 'image',
      product_uid: this.productUid,
      width: '1200',
      height: '800',
      page_nr: (index + 1).toString(),
    });

    if (borderWidth) {
      params.set('preview_border_width', borderWidth.toString());
    }

    const paramsThumbnail = new URLSearchParams({
      type: 'image',
      product_uid: this.productUid,
      width: '140',
      height: '100',
      page_nr: (index + 1).toString(),
    });

    if (passAllSpreads) {
      const imageMap = this.getImagesUrlsMap(imageUrls, designData);
      params.set('images_urls', imageMap);
      paramsThumbnail.set('images_urls', imageMap);
    } else {
      params.set('image_url', imageUrls[index]);
      paramsThumbnail.set('image_url', imageUrls[index]);
    }

    const url = this.getRequestUrl(this.PRODUCT_PREVIEW_ENDPOINT, params);
    const urlThumb = this.getRequestUrl(this.PRODUCT_PREVIEW_ENDPOINT, paramsThumbnail);

    if (this.isEmbeddableEditor) {
      return {
        url,
        thumbnailUrl: urlThumb,
      };
    }

    const response = await this.get<{ url: string }>(url, {});
    const responseThumb = await this.get<{ url: string }>(urlThumb, {});

    return {
      url: response.data.url,
      thumbnailUrl: responseThumb.data.url,
    };
  }
}
