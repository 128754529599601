import { createSelector } from '@ngrx/store';
import { AppState } from '../../../app.state';
import { State } from './orders.reducer';
import { Order } from '../../lib/order';
import { OrderSearchRequest } from '../../lib/order-search-request';
import { SyncOrdersState } from '@api-ui-app/src/app/orders/lib/sync-orders-state';

export const getState = (state: AppState): State => {
  return state.orders;
};

export const getLastRequest = createSelector(getState, (orderState: State): OrderSearchRequest => orderState.request);

export const getOrdersTotal = createSelector(getState, (orderState: State) =>
  orderState.totals ? orderState.totals.totalCount : null,
);

export const getOrders = createSelector(getState, (orderState: State): Order[] => {
  if (!orderState.payload || !orderState.payload.orders) {
    return [];
  }

  return orderState.payload.orders;
});

export const getSyncOrdersState = createSelector(
  getState,
  (orderState: State): SyncOrdersState => orderState.syncOrders,
);

export const getCanSyncOrders = createSelector(
  getSyncOrdersState,
  (syncOrdersState: SyncOrdersState): boolean => syncOrdersState.canSync,
);

export const getIsSyncingOrders = createSelector(
  getSyncOrdersState,
  (syncOrdersState: SyncOrdersState): boolean => syncOrdersState.inProgress,
);

export const getSyncOrdersProgressPercentage = createSelector(
  getSyncOrdersState,
  (syncOrdersState: SyncOrdersState): number => syncOrdersState.progressPercentage,
);

export const getOrdersTotalForPastMonth = createSelector(
  getState,
  (orderState: State) => orderState.totalsForPastMonth?.totalCount,
);
