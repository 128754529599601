export const loadScript = (src: string, attrs: { [key: string]: string } = {}) =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = src;

    Object.keys(attrs).forEach(attr => {
      script.setAttribute(attr, attrs[attr]);
    });

    script.onload = () => {
      resolve(true);
    };

    script.onerror = () => {
      reject(false);
    };

    document.body.appendChild(script);
  });
