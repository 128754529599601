import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { ProductTypeUidService } from '@gelato-api-ui/core/product-catalogue/services/product-type-uid.service';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { hasAppliedDesignTemplate } from '@gelato-api-ui/core/designs/helpers/hasAppliedDesignTemplate';
import {
  DesignAssetCostsCollection,
  PriceCollection,
} from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { DesignGraphQlService } from '@gelato-api-ui/core/designs/services/design-graph-ql.service';
import { DesignCostService } from '@gelato-api-ui/core/designs/services/design-cost.service';
import { currencyRound } from '@gelato-api-ui/core/currency/currency-round';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductWizardConvertPricesService {
  GELATO_PLUS_SHIPPING_DISCOUNT = 0.3;
  GELATO_PLUS_GOLD_SHIPPING_DISCOUNT = 0.3;
  convertShippingPricesBasedOnSubscription(
    prices: PriceCollection,
    plusIsActive: boolean,
    goldIsActive: boolean,
    selectedCurrency: string,
  ): PriceCollection {
    if (!plusIsActive && !goldIsActive) {
      return prices;
    }
    const discountValue = goldIsActive
      ? this.GELATO_PLUS_GOLD_SHIPPING_DISCOUNT
      : plusIsActive
      ? this.GELATO_PLUS_SHIPPING_DISCOUNT
      : 0;

    return Object.keys(prices).reduce(
      (acc: PriceCollection, productVariantKey: string): PriceCollection => ({
        ...acc,
        [productVariantKey]: currencyRound(
          prices[productVariantKey] - prices[productVariantKey] * discountValue,
          selectedCurrency,
        ),
      }),
      {},
    );
  }

  convertPriceCollection(
    prices: PriceCollection,
    selectedCurrency: string,
    storeCurrency: string,
    storeCurrencyRate: number,
  ): PriceCollection {
    if (!storeCurrencyRate || selectedCurrency === storeCurrency) {
      return prices;
    }

    return Object.keys(prices).reduce(
      (acc: PriceCollection, productVariantKey: string): PriceCollection => ({
        ...acc,
        [productVariantKey]: currencyRound(prices[productVariantKey] * storeCurrencyRate, selectedCurrency),
      }),
      {},
    );
  }

  convertPriceCollectionV2(
    prices: PriceCollection,
    newCurrency: string,
    prevCurrency: string,
    currencyRate: number,
  ): PriceCollection {
    if (!prevCurrency || newCurrency === prevCurrency) {
      return prices;
    }

    return Object.keys(prices).reduce(
      (acc: PriceCollection, productVariantKey: string): PriceCollection => ({
        ...acc,
        [productVariantKey]: currencyRound(prices[productVariantKey] * currencyRate, newCurrency),
      }),
      {},
    );
  }
}
