import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '../app.state';
import { PRODUCT_UID_DELIMITER } from '@gelato-api-ui/core/e-commerce/constants';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductSearchRequest } from '@gelato-api-ui/core/e-commerce/e-product-search-request';
import { EProductSearchResponse } from '@gelato-api-ui/core/e-commerce/e-product-search-response';
import { EProductSummary } from '../e-commerce-stores/lib/e-product-summary';
import { EProductVariantAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-variant-abbridged';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import {
  EProductVariantSummary,
  EProductVariantSummaryProduct,
} from '../e-commerce-stores/lib/e-product-variant-summary';
import { EProductVariantConnectionStatus } from '@gelato-api-ui/core/e-commerce/e-product-variant-connection-status.enum';
import { InitialProductVariantConnectionData } from '@gelato-api-ui/core/e-commerce/initial-product-variant-connection-data';
import { getRelatedProduct } from '@gelato-api-ui/core/sanity/helpers/getRelatedProduct';
import { ProductsListState, ProductState, State } from './e-commerce-products.reducer';
import { getSanityRelatedProducts } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.selector';
import { SanityRelatedProducts } from '@gelato-api-ui/core/sanity/SanityRelatedProducts';
import { isBulkEditingAllowed } from '@gelato-api-ui/core/e-commerce/helpers/isBulkEditingAllowed';
import { EProductStatus } from '@gelato-api-ui/core/e-commerce/e-product-status.enum';

export const getState = (state: AppState): State => state.eCommerceProducts;

export const getProductsListState = createSelector(getState, (state: State): ProductsListState => state.productsList);

export const getProductsListLoadingFlag = createSelector(
  getProductsListState,
  (state: ProductsListState): boolean => state.isLoading,
);

export const getProductsListRequest = createSelector(
  getProductsListState,
  (productsListState: ProductsListState): EProductSearchRequest => productsListState.request,
);

export const getProductsListResponse = createSelector(
  getProductsListState,
  (productsListState: ProductsListState): EProductSearchResponse => productsListState.payload,
);

export const getProductsList = createSelector(
  getProductsListResponse,
  (response: EProductSearchResponse): EProductAbbridged[] => {
    if (!response || !response.products) {
      return [];
    }

    return response.products;
  },
);

export const getProductsSummaryList = createSelector(
  getProductsList,
  (list: EProductAbbridged[]): EProductSummary[] => {
    if (!list) {
      return null;
    }

    return R.compose(
      R.reverse,
      R.sortBy(R.prop('updatedAt')),
      R.map((item: EProductAbbridged): EProductSummary => {
        let connectedVariantsCount = 0;
        let notConnectedVariantsCount = 0;
        let ignoredVariantsCount = 0;

        if (item.variants && item.variants.length) {
          item.variants.forEach((variant: EProductVariantAbbridged) => {
            switch (variant.connectionStatus) {
              case EProductVariantConnectionStatus.CONNECTED:
                connectedVariantsCount++;
                break;
              case EProductVariantConnectionStatus.NOT_CONNECTED:
                notConnectedVariantsCount++;
                break;
              case EProductVariantConnectionStatus.IGNORED:
                ignoredVariantsCount++;
                break;
              default:
            }
          });
        }

        return {
          ...item,
          connectedVariantsCount,
          notConnectedVariantsCount,
          ignoredVariantsCount,
        };
      }),
    )(list);
  },
);

export const getProductState = createSelector(getState, (state: State): ProductState => state.product);

export const getProductLoadingFlag = createSelector(
  getProductState,
  (productState: ProductState): boolean => productState.isLoading,
);

export const getProduct = createSelector(
  getProductState,
  (productState: ProductState): EProductWithVariants => productState.payload,
);

export const getProductId = createSelector(getProduct, (product: EProductWithVariants): string => product?.id);

export const getProductTitle = createSelector(getProduct, (product: EProductWithVariants): string => product?.title);

export const getProductDescription = createSelector(
  getProduct,
  (product: EProductWithVariants): string => product?.description,
);

export const getProductStatus = createSelector(
  getProduct,
  (product: EProductWithVariants): EProductStatus => product?.status,
);

export const getProductVariantsSummary = createSelector(
  getProduct,
  getSanityRelatedProducts,
  (product: EProductWithVariants, relatedProducts: SanityRelatedProducts[]): EProductVariantSummary[] => {
    if (!product || !product.variants) {
      return null;
    }

    return product.variants
      .filter(
        (loopProductVariant: EProductVariant): boolean =>
          loopProductVariant.connectionStatus !== EProductVariantConnectionStatus.DELETED,
      )
      .map((loopProductVariant: EProductVariant): EProductVariantSummary => {
        const productUids: string[] = (loopProductVariant.productUid || '').split(PRODUCT_UID_DELIMITER);

        const products: EProductVariantSummaryProduct[] = R.addIndex(R.map)(
          (productUid: string, index: number): EProductVariantSummaryProduct => {
            if (index > 0) {
              const relatedProduct = getRelatedProduct(relatedProducts, productUid, productUids[0]);
              return {
                title: relatedProduct ? relatedProduct.title : productUid,
                previewUrl: relatedProduct ? relatedProduct.preview : null,
                productUid,
              };
            }

            return {
              title: loopProductVariant.title,
              previewUrl: loopProductVariant.preview,
              productUid,
            };
          },
          productUids,
        );

        return {
          ...loopProductVariant,
          products,
        };
      });
  },
);

export const getIsBulkEditingAllowedFlag = createSelector(getProduct, (product: EProductWithVariants): boolean =>
  isBulkEditingAllowed(product),
);

export const getInitialProductVariantConnectionData = createSelector(
  getState,
  (state: State): InitialProductVariantConnectionData => state.initialProductVariantConnectionData,
);

export const getInitialProductVariantConnectionCurrency = createSelector(getState, (state: State): string =>
  state.initialProductVariantConnectionData && state.initialProductVariantConnectionData.productVariant
    ? state.initialProductVariantConnectionData.productVariant.currency
    : null,
);

export const getSelectedProductVariant = createSelector(
  getInitialProductVariantConnectionData,
  (initialProductVariantConnectionData: InitialProductVariantConnectionData): EProductVariant =>
    initialProductVariantConnectionData ? initialProductVariantConnectionData.productVariant : null,
);

export const getSelectedOrderId = createSelector(
  getInitialProductVariantConnectionData,
  (initialProductVariantConnectionData: InitialProductVariantConnectionData): string =>
    initialProductVariantConnectionData ? initialProductVariantConnectionData.orderId : null,
);

export const getSelectedOrderItemId = createSelector(
  getInitialProductVariantConnectionData,
  (initialProductVariantConnectionData: InitialProductVariantConnectionData): string =>
    initialProductVariantConnectionData ? initialProductVariantConnectionData.orderItemId : null,
);

export const getSelectedProductId = createSelector(
  getSelectedProductVariant,
  (selectedProductVariant: EProductVariant): string =>
    selectedProductVariant ? selectedProductVariant.productId : null,
);

export const getTags = createSelector(getState, state => state.tags);

export const getIsSaveProduct = createSelector(getState, state => state.saveProduct);

export const getExpandProduct = createSelector(getState, state => state.expandProduct);

export const getCollections = createSelector(getState, state => state.collections);

export const getPublishingState = createSelector(getState, state => state.publishState);

export const getProductDuplicationAvailabilityFlags = createSelector(
  getState,
  state => state.productDuplicationAvailability,
);

export const getIsPublishingProcessMonitoringActive = createSelector(
  getState,
  state => state.isPublishingProcessMonitoringActive,
);
