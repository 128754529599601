import { Injectable } from '@angular/core';
import { User } from '@api-ui-app/src/app/shared/lib/user';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Order } from '@api-ui-app/src/app/orders/lib/order';
import { UserCountryService } from '@api-ui-app/src/app/shared/services/user-country.service';

@Injectable({ providedIn: 'root' })
export class UserNameService {
  isJapanCountry$ = this.userCountryService.isJapanCountry$;

  constructor(private readonly userCountryService: UserCountryService) {}

  getUserShortName$(user: User): Observable<string> {
    return this.isJapanCountry$.pipe(
      map(isJapan => {
        const firstChar = user?.firstName?.trim().charAt(0);
        const lastChar = user?.lastName?.trim().charAt(0);

        if (!lastChar) {
          return firstChar || null;
        }

        return isJapan ? `${lastChar}${firstChar}` : `${firstChar}${lastChar}`;
      }),
    );
  }

  getOrderFullName(user: Partial<Order>) {
    const firstName = user.firstName || '';
    const lastName = user.lastName || '';
    const isJapan = user.country === 'JP';
    return isJapan ? `${lastName} ${firstName}` : `${firstName} ${lastName}`;
  }
}
