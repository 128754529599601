import { UploadedFile } from '../shared/lib/uploaded-file';
import { PreflightPdfInfoRequest } from '@gelato-api-ui/core/preflight/preflight-pdf-info-request';
import { PreflightPdfInfoResponse } from '@gelato-api-ui/core/preflight/preflight-pdf-info-response';
import { PreflightProductMatchRequest } from '@gelato-api-ui/core/preflight/preflight-product-match-request';
import { PreflightProductMatchResponse } from '@gelato-api-ui/core/preflight/preflight-product-match-response';
import { PreflightPdfPreflightRequest } from '@gelato-api-ui/core/preflight/preflight-pdf-preflight-request';
import { PreflightPdfPreflightResponse } from '@gelato-api-ui/core/preflight/preflight-pdf-preflight-response';
import { PreflightProductPreviewRequest } from '@gelato-api-ui/core/preflight/preflight-product-preview-request';
import * as actions from './print-file-verification.actions';

export interface State {
  fileInfo: FileInfoState;
  productMatch: ProductMatchState;
  preflight: PreflightState;
  pagePreview: PagePreviewState;
  uploadedFile: {
    original: UploadedFile;
    converted: UploadedFile;
  };
}

export interface FileInfoState {
  isLoading: boolean;
  request: PreflightPdfInfoRequest;
  payload: PreflightPdfInfoResponse;
}

export interface ProductMatchState {
  isLoading: boolean;
  request: PreflightProductMatchRequest;
  payload: PreflightProductMatchResponse;
}

export interface PreflightState {
  isLoading: boolean;
  progress: number;
  request: PreflightPdfPreflightRequest;
  payload: PreflightPdfPreflightResponse;
}

export interface PagePreviewState {
  isLoading: boolean;
  request: PreflightProductPreviewRequest;
  payload: string;
}

export const initialState: State = {
  fileInfo: {
    isLoading: false,
    request: null,
    payload: null,
  },
  productMatch: {
    isLoading: false,
    request: null,
    payload: null,
  },
  preflight: {
    isLoading: false,
    progress: 0,
    request: null,
    payload: null,
  },
  pagePreview: {
    isLoading: false,
    request: null,
    payload: null,
  },
  uploadedFile: {
    original: null,
    converted: null,
  },
};

export function reducer(state = initialState, action: actions.PrintFileVerificationActions): State {
  switch (action.type) {
    case actions.PrintFileVerificationActionTypes.ResetFileInfoState:
      return {
        ...state,
        fileInfo: { ...initialState.fileInfo },
      };
    case actions.PrintFileVerificationActionTypes.ResetProductMatchState:
      return {
        ...state,
        productMatch: { ...initialState.productMatch },
      };
    case actions.PrintFileVerificationActionTypes.ResetPreflightState:
      return {
        ...state,
        preflight: { ...initialState.preflight },
      };
    case actions.PrintFileVerificationActionTypes.ResetPagePreviewState:
      return {
        ...state,
        pagePreview: { ...initialState.pagePreview },
      };
    case actions.PrintFileVerificationActionTypes.SetFileInfoState:
      return {
        ...state,
        fileInfo: action.payload,
      };
    case actions.PrintFileVerificationActionTypes.SetProductMatchState:
      return {
        ...state,
        productMatch: action.payload,
      };
    case actions.PrintFileVerificationActionTypes.SetPreflightState:
      return {
        ...state,
        preflight: action.payload,
      };
    case actions.PrintFileVerificationActionTypes.SetPagePreviewState:
      return {
        ...state,
        pagePreview: action.payload,
      };
    case actions.PrintFileVerificationActionTypes.SetUploadedFile:
      return {
        ...state,
        uploadedFile: {
          original: action.payload,
          converted: action.payload,
        },
      };
    case actions.PrintFileVerificationActionTypes.SetOriginalUploadedFile:
      return {
        ...state,
        uploadedFile: {
          ...state.uploadedFile,
          original: action.payload,
        },
      };
    case actions.PrintFileVerificationActionTypes.SetConvertedUploadedFile:
      return {
        ...state,
        uploadedFile: {
          ...state.uploadedFile,
          converted: action.payload,
        },
      };
    default:
      return state;
  }
}
