export function getSubscriptionClassName(isGelatoPlusActive: boolean, isGelatoPlusGoldActive: boolean): string {
  let className: string;

  if (!isGelatoPlusActive && !isGelatoPlusGoldActive) {
    className = null;
  }
  if (isGelatoPlusActive) {
    className = 'purple';
  }
  if (isGelatoPlusGoldActive) {
    className = 'gold';
  }

  return className;
}
