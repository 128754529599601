import { SanityProductCategory } from '../sanity-product-category';

export const findSanityProductCategoryByProductName = (
  productName: string,
  sanityProductCategories: SanityProductCategory[],
): SanityProductCategory => {
  for (const sanityContentCategory of sanityProductCategories) {
    if (sanityContentCategory.lists) {
      for (const list of sanityContentCategory.lists) {
        const foundProduct = list.products.find(pr => pr.id === productName);
        if (foundProduct) {
          return sanityContentCategory;
        }
      }
    }
  }
};
