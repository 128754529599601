import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { LoggerService } from '@gelato-api-ui/sdk/src/lib/logger/logger.interface';
import { StorageService } from './storage.service';

export const LOCAL_STORAGE_PREFIX = new InjectionToken<string>('[SDK] LOCAL_STORAGE_PREFIX');

@Injectable()
export class LocalStorageService extends StorageService {
  private globalKeys = new Set(['i18n']);

  constructor(
    @Optional()
    @Inject(LOCAL_STORAGE_PREFIX)
    private prefix: string,
    private logger: LoggerService,
  ) {
    super();
  }

  clear() {
    try {
      localStorage.clear();
    } catch (e) {
      this.logger.error(e.message);
    }
  }

  getItem<T>(key: string, defaultValue?: T): T {
    try {
      const val = localStorage.getItem(this.decorateKey(key));
      return val === null ? defaultValue : JSON.parse(val);
    } catch (e) {
      return defaultValue;
    }
  }

  setItem(key: string, value: any) {
    try {
      localStorage.setItem(this.decorateKey(key), JSON.stringify(value));
    } catch (e) {
      console.error(e.message);
    }
  }

  removeItem(key: string) {
    try {
      localStorage.removeItem(this.decorateKey(key));
    } catch (e) {
      console.error(e.message);
    }
  }

  private decorateKey(key: string) {
    return this.globalKeys.has(key) ? key : `${this.prefix || ''}${key}`;
  }
}
