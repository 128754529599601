import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '@api-ui-app/src/app/app.state';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { EProductTreeItem } from '@api-ui-app/src/app/product/product-add/types/e-product-tree-item';
import { ECommerceProductsTreeService } from '../services/e-commerce-products-tree.service';
import * as actions from './e-commerce-products-tree.actions';
import { getProductsTree } from './e-commerce-products-tree.selector';

@Injectable()
export class ECommerceProductsTreeEffects {
  hasPermissions$ = this.authService.hasReadPermission('ecommerce', '*');
  limit = 15;

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly eCommerceProductsTreeService: ECommerceProductsTreeService,
    private readonly authService: AuthService,
  ) {}

  Load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.Load>(actions.ECommerceProductsTreeActionTypes.Load),
        withLatestFrom(this.hasPermissions$, this.store$.select(getProductsTree)),
        switchMap(([action, hasPermission, loadedItems]) => {
          const { store, title, clearList } = action;

          if (!hasPermission) {
            return of(null);
          }

          let offset = 0;

          if (clearList) {
            this.store$.dispatch(
              new actions.SetState({
                isLoading: true,
                payload: loadedItems,
              }),
            );
          } else {
            offset = (loadedItems || []).length;

            if (offset % this.limit !== 0) {
              return of(null);
            }
          }

          return this.eCommerceProductsTreeService.getProductsList(store, title, this.limit, offset).pipe(
            catchError((err): Observable<EProductTreeItem[]> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: EProductTreeItem[]) => {
              let payload: EProductTreeItem[] = response;

              if (!clearList) {
                if (!response) {
                  return;
                }

                payload = [...loadedItems, ...response];
              }

              this.store$.dispatch(
                new actions.SetState({
                  isLoading: false,
                  payload,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  LoadSingleProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadSingleProduct>(actions.ECommerceProductsTreeActionTypes.LoadSingleProduct),
        withLatestFrom(this.hasPermissions$),
        switchMap(([action, hasPermission]) => {
          const { store, productId } = action;

          if (!hasPermission) {
            return of(null);
          }

          this.store$.dispatch(
            new actions.SetState({
              isLoading: true,
              payload: [],
            }),
          );

          return this.eCommerceProductsTreeService.getSingleProduct(store, productId).pipe(
            catchError((err): Observable<EProductTreeItem[]> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((payload: EProductTreeItem[]) => {
              this.store$.dispatch(
                new actions.SetState({
                  isLoading: false,
                  payload,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
