import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';

@Component({
  selector: 'gd-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  homeRoute$ = combineLatest([
    this.activatedRoute.paramMap,
    this.authService.hasWritePermission('billing', 'company-details'),
    this.authService.hasReadPermission('product', 'catalogue'),
    this.authService.hasReadPermission('order', 'analytics'),
  ]).pipe(
    first(),
    map(([params, companyDetailsAllowed, productCatalogueAllowed, analyticsAllowed]): any[] => {
      if (
        params &&
        params.get('selfRegistrationMode') &&
        Boolean(Number(params.get('selfRegistrationMode'))) &&
        companyDetailsAllowed
      ) {
        return ['/on-boarding'];
      }

      if (analyticsAllowed) {
        return ['/home'];
      }

      if (productCatalogueAllowed) {
        return ['/catalogue'];
      }

      return ['/orders'];
    }),
  );

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private authService: AuthService) {}

  ngOnInit() {
    combineLatest([this.homeRoute$, this.activatedRoute.queryParams])
      .pipe(first())
      .subscribe(([homeRoute, queryParams]) => {
        this.router.navigate(homeRoute, { queryParams });
      });
  }
}
