import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';

export enum PlansComparisonTableCellType {
  bool = 'boolean',
  str = 'string',
  price = 'price',
}

export enum PlansComparisonTableSectionName {
  NONE = 'none',
  TEAMS_AND_STORES = 'teamAndStores',
  PREMIUM_DESIGN_TOOAL_AND_MOCKUPS = 'premiumDesignToolsAndMockups',
  AUTOMATION_TOOLS = 'automationTools',
  DISCOUNTS = 'discounts',
  MIGRATION_PRODUCTS = 'migratingProducts',
}

export interface PlansComparisonTableSections {
  noSectionRows: PlansComparisonTable;
  sections: PlansComparisonTableSectionsData[];
}
export interface PlansComparisonTableSectionsData {
  sectionTitleKey: string;
  tableRows: PlansComparisonTable;
}
export type PlansComparisonTableRowTooltip = 'boolean';
export type PlansComparisonTableRowLink = string;

export interface PlansComparisonTableCell<T = string | number> {
  value: T;
  plan?: ServicePlanUniqueNames | undefined;
  type?: PlansComparisonTableCellType;
  link?: string;
  tooltip?: string;
  sectionGroup?: PlansComparisonTableSectionName;
  currency?: string;
  price?: number;
  pricePerMonth?: string;
  sectionTitle?: string;
}

export type PlansComparisonTable = PlansComparisonTableCell[][];

export type PlansComparisonRawRow = [
  PlansComparisonTableCell,
  PlansComparisonTableCell,
  PlansComparisonTableCell,
  PlansComparisonTableCell,
  PlansComparisonTableCell<PlansComparisonTableCellType>?,
  PlansComparisonTableCell<PlansComparisonTableSectionName>?,
  PlansComparisonTableCell<PlansComparisonTableRowLink>?,
  PlansComparisonTableCell<PlansComparisonTableRowTooltip>?,
];

export type PlansComparisonResponse = [
  string | number,
  string | number,
  string | number,
  string | number,
  PlansComparisonTableSectionName,
  PlansComparisonTableCellType?,
  PlansComparisonTableRowLink?,
  PlansComparisonTableRowTooltip?,
];
