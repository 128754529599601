import * as Sentry from '@sentry/angular';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';

export const trackECommerceProductWizardStep = (mode: EProductWizardMode, step: ECommerceProductWizardStep) => {
  logEvent('eCommerceProductWizardStep', { mode, step });

  Sentry.addBreadcrumb({
    category: 'ECommerceProductWizardStep',
    data: { mode, step },
    level: Sentry.Severity.Info,
  });
};
