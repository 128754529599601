import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';
import { ResponsiveService } from '@gelato-api-ui/core/responsive/responsive.service';

@Component({
  selector: 'gc-ecommerce-product-wizard-actions-panel',
  templateUrl: './ecommerce-product-wizard-actions-panel.component.html',
  styleUrls: ['./ecommerce-product-wizard-actions-panel.component.scss'],
})
export class EcommerceProductWizardActionsPanelComponent implements OnInit, OnDestroy {
  @Input() backButtonText: boolean;
  @Input() showSteps: boolean;
  @Input() includeSpacer: boolean;
  @Input() activeStep: ECommerceProductWizardStep;
  @Input() visibleSteps: ECommerceProductWizardStep[];
  @Input() disabledSteps: ECommerceProductWizardStep[];
  @Output() stepButtonClick = new EventEmitter<ECommerceProductWizardStep>();
  @Output() backButtonClick = new EventEmitter<void>();

  isMobileOrTablet$ = this.responsive.isMobileOrTablet$;

  constructor(private readonly responsive: ResponsiveService) {}

  ngOnInit() {
    this.responsive.onScreenSizeChanges();
  }

  ngOnDestroy() {
    this.responsive.onRemoveScreenSizeChanges();
  }

  onStepButtonClick(step: ECommerceProductWizardStep) {
    this.stepButtonClick.emit(step);
  }

  onBackButtonClick() {
    this.backButtonClick.emit();
  }
}
