import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UsStateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/us-state-name.pipe';
import { CanadianProvinceNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/canadian-province-name.pipe';
import { AustralianStateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/australian-state-name.pipe';
import { BrazilianStateNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/brazilian-state-name.pipe';
import { ChileanRegionNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/chilean-region-name.pipe';
import { JpPrefectureNamePipe } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/pipes/jp-prefecture-name.pipe';

@Pipe({ name: 'stateName' })
export class StateNamePipe implements PipeTransform {
  constructor(
    private usStateNamePipe: UsStateNamePipe,
    private canadianProvinceNamePipe: CanadianProvinceNamePipe,
    private australianStateName: AustralianStateNamePipe,
    private brazilianStateNamePipe: BrazilianStateNamePipe,
    private chileanRegionNamePipe: ChileanRegionNamePipe,
    private jpPrefectureNamePipe: JpPrefectureNamePipe,
  ) {}

  transform(stateCode: string, showStateCode: boolean, selectedCountry: string): Observable<string> {
    const strategy = {
      US: this.usStateNamePipe,
      CA: this.canadianProvinceNamePipe,
      AU: this.australianStateName,
      BR: this.brazilianStateNamePipe,
      CL: this.chileanRegionNamePipe,
      JP: this.jpPrefectureNamePipe,
    };

    return strategy[selectedCountry] ? strategy[selectedCountry].transform(stateCode, showStateCode) : of(stateCode);
  }
}
