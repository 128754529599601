import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';

interface StepsConfig {
  [key: string]: {
    translationKey: string;
    iconUrl: string;
  };
}

@Component({
  selector: 'gc-ecommerce-product-wizard-breadcrumbs',
  templateUrl: './ecommerce-product-wizard-breadcrumbs.component.html',
  styleUrls: ['./ecommerce-product-wizard-breadcrumbs.component.scss'],
})
export class EcommerceProductWizardBreadcrumbsComponent {
  @Input() activeStep: ECommerceProductWizardStep;
  @Input() visibleSteps: ECommerceProductWizardStep[];
  @Input() disabledSteps: ECommerceProductWizardStep[];
  @Output() stepButtonClick = new EventEmitter<ECommerceProductWizardStep>();

  stepEnum = ECommerceProductWizardStep;

  completeStepIconUrl = '/assets/icons/check-black.svg';

  stepsConfig: StepsConfig = {
    [ECommerceProductWizardStep.PRODUCT]: {
      translationKey: 'txt_action_button_label_product',
      iconUrl: '/assets/store-product-icons/product.svg',
    },
    [ECommerceProductWizardStep.DESIGN]: {
      translationKey: 'txt_action_button_label_design',
      iconUrl: '/assets/store-product-icons/edit.svg',
    },
    [ECommerceProductWizardStep.MEDIA]: {
      translationKey: 'txt_action_button_label_mockups',
      iconUrl: '/assets/store-product-icons/media.svg',
    },
    [ECommerceProductWizardStep.DETAILS]: {
      translationKey: 'txt_action_button_label_details',
      iconUrl: '/assets/store-product-icons/details.svg',
    },
    [ECommerceProductWizardStep.CONNECT]: {
      translationKey: 'txt_action_button_label_connect',
      iconUrl: '/assets/store-product-icons/connect.svg',
    },
    [ECommerceProductWizardStep.PRICES]: {
      translationKey: 'txt_action_button_label_prices',
      iconUrl: '/assets/store-product-icons/pricing.svg',
    },
  };

  onClick(step: ECommerceProductWizardStep) {
    this.stepButtonClick.emit(step);
  }
}
