import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gc-price-input',
  templateUrl: './price-input.component.html',
  styleUrls: ['./price-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceInputComponent {
  @Input() currency: string;
  @Input() minValue: number;
  @Input() value: number;
  @Input() error: boolean;
  @Input() disabled: boolean;
  @Input() showCurrency = true;
  @Output() valueChange = new EventEmitter<number>();

  setValue(value: number) {
    this.valueChange.emit(value);
  }

  onFocusOut() {
    if (!this.value) {
      this.valueChange.emit(0);
    }
  }
}
