import { Component, Input } from '@angular/core';

@Component({
  selector: 'gd-status-label, gc-status-label',
  templateUrl: './status-label.component.html',
  styleUrls: ['./status-label.component.scss'],
})
export class StatusLabelComponent {
  @Input() color = '';
  @Input() text = '';
  @Input() fluid = true;
  @Input() noShrink = false;
  @Input() noWrap = false;
  @Input() maxWidth = 100;

  get maxWidthStyle() {
    return this.maxWidth ? `${this.maxWidth}px` : 'auto';
  }
}
