import * as R from 'ramda';
import { Key } from '../../api-keys/lib/key';
import { State } from '../api-keys.reducer';

export const findIncompleteKeyInState = (state: State, keyId: string): Key => {
  if (!state || !keyId) {
    return null;
  }

  if (state.keysList && state.keysList.payload && state.keysList.payload.data) {
    return { ...R.find(R.propEq('id', keyId))(state.keysList.payload.data) };
  }

  return null;
};
