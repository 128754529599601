export enum Breakpoint {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop',
}

export const BREAKPOINTS = {
  [Breakpoint.mobile]: 480,
  [Breakpoint.tablet]: 768,
  [Breakpoint.desktop]: 1024,
};
