import { Action } from '@ngrx/store';
import { State } from './flat-shipment-prices.reducer';

export enum FlatShipmentPricesActionTypes {
  Load = '[FlatShipmentPrices] Load',
  LoadPriceByCountry = '[FlatShipmentPrices] Load Flat Price By Country',
  ResetState = '[FlatShipmentPrices] Reset State',
  SetState = '[FlatShipmentPrices] Set State',
}

export class Load implements Action {
  readonly type = FlatShipmentPricesActionTypes.Load;

  constructor(public productUid: string, public currency: string, public forced: boolean = false) {}
}

export class LoadPriceByCountry implements Action {
  readonly type = FlatShipmentPricesActionTypes.LoadPriceByCountry;

  constructor(public productUid: string, public currency: string, public countries: string[]) {}
}

export class ResetState implements Action {
  readonly type = FlatShipmentPricesActionTypes.ResetState;
}

export class SetState implements Action {
  readonly type = FlatShipmentPricesActionTypes.SetState;

  constructor(public payload: State) {}
}

export type FlatShipmentPricesActions = Load | ResetState | SetState | LoadPriceByCountry;
