import * as R from 'ramda';
import { SanityProductVariation } from '../sanity-product-variation';

export const getProductUidsFromSanityProductVariations = (
  sanityProductVariations: SanityProductVariation[],
): string[] => {
  if (!sanityProductVariations?.length) {
    return [];
  }

  const productUids = sanityProductVariations.map(
    (loopProductVariation: SanityProductVariation): string => loopProductVariation.productUid,
  );

  return R.uniq(productUids.filter((loopProductUid: string): boolean => Boolean(loopProductUid)));
};
