import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { EProductVariantsUpdateSummary } from '@api-ui-app/src/app/e-commerce-stores/lib/e-product-variants-update-summary';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { StoreProductVariantsCollection } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import {
  getControlKeyGroupPerCategory,
  getProductControls,
} from '@product-catalogue/src/lib/ngrx/helpers/editorHelper';
import { SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { SanityProductVariationTitleService } from '@gelato-api-ui/core/sanity/services/sanity-product-variation-title.service';

@Injectable({ providedIn: 'root' })
export class ECommerceProductVariantUpdateSummaryService {
  constructor(private readonly sanityProductVariationTitleService: SanityProductVariationTitleService) {}

  get(
    mode: EProductWizardMode,
    storeProductVariantsCollection: StoreProductVariantsCollection,
    originalStoreProductVariantsCollection: StoreProductVariantsCollection,
    sanityProductCategoryName: SanityProductCategoryName,
    sanityProduct: SanityProduct,
  ): EProductVariantsUpdateSummary {
    if (
      mode !== EProductWizardMode.EDIT_DESIGNS ||
      !originalStoreProductVariantsCollection ||
      !Object.values(originalStoreProductVariantsCollection).length
    ) {
      return null;
    }

    const addedProductVariants = this.getAddedProductVariants(storeProductVariantsCollection);
    const removedProductVariants = this.getRemovedProductVariants(
      storeProductVariantsCollection,
      originalStoreProductVariantsCollection,
    );

    return {
      added: this.getTitles(addedProductVariants, sanityProductCategoryName, sanityProduct),
      removed: this.getTitles(removedProductVariants, sanityProductCategoryName, sanityProduct),
    };
  }

  getAddedProductVariants(storeProductVariantsCollection: StoreProductVariantsCollection): EProductVariant[] {
    if (!storeProductVariantsCollection) {
      return [];
    }

    const newProductVariants = Object.values(storeProductVariantsCollection);

    return newProductVariants.filter(v => !v.id);
  }

  getRemovedProductVariants(
    storeProductVariantsCollection: StoreProductVariantsCollection,
    originalStoreProductVariantsCollection: StoreProductVariantsCollection,
  ): EProductVariant[] {
    if (!storeProductVariantsCollection || !originalStoreProductVariantsCollection) {
      return [];
    }

    const newProductVariants = Object.values(storeProductVariantsCollection);
    const newProductVariantIds = newProductVariants.map(v => v.id);
    const oldProductVariants = Object.values(originalStoreProductVariantsCollection);
    const oldProductVariantIds = oldProductVariants.map(v => v.id);

    const productVariantIdsToRemove = R.difference(oldProductVariantIds, newProductVariantIds);

    return oldProductVariants.filter(v => productVariantIdsToRemove.includes(v.id));
  }

  private getTitles(
    productVariants: EProductVariant[],
    sanityProductCategoryName: SanityProductCategoryName,
    sanityProduct: SanityProduct,
  ): string[] {
    const controls = getProductControls(sanityProduct);
    const controlKeys = controls.map(
      (loopControl): SanityProductControlKey => loopControl.key as SanityProductControlKey,
    );
    const groupBy = getControlKeyGroupPerCategory(sanityProductCategoryName);

    if (groupBy && groupBy.length) {
      const groups = productVariants.reduce((acc, loopProductVariant: EProductVariant) => {
        const groupTitle = this.sanityProductVariationTitleService.get(
          sanityProduct,
          loopProductVariant.productUid,
          loopProductVariant.customTrim,
          R.difference(controlKeys, groupBy as SanityProductControlKey[]),
        );
        const variantTitle = this.sanityProductVariationTitleService.get(
          sanityProduct,
          loopProductVariant.productUid,
          loopProductVariant.customTrim,
          groupBy as SanityProductControlKey[],
        );

        if (!groupTitle || !variantTitle) {
          return acc;
        }

        return {
          ...acc,
          [groupTitle]: acc[groupTitle] ? [...acc[groupTitle], variantTitle] : [variantTitle],
        };
      }, {});

      return Object.keys(groups).map((groupKey: string): string => `${groupKey} - ${groups[groupKey].join(', ')}`);
    } else {
      return productVariants.map(
        (loopProductVariant: EProductVariant): string =>
          this.sanityProductVariationTitleService.get(
            sanityProduct,
            loopProductVariant.productUid,
            loopProductVariant.customTrim,
            [],
          ) || loopProductVariant.title,
      );
    }
  }
}
