export const getRelevantSupportedPreviewScenes = (
  supportedPreviewScenes: string[],
  hasUsedShutterstockAssets: boolean,
): string[] => {
  if (hasUsedShutterstockAssets) {
    const filteredSupportedPreviewScenes: string[] = supportedPreviewScenes.filter(Boolean);

    // EIN-2116: Fix for calendars and other products having only one default preview scene available
    if (filteredSupportedPreviewScenes?.length > 0) {
      return filteredSupportedPreviewScenes;
    }
  }

  return supportedPreviewScenes;
};
