import { SanityTipsAndGuides } from './sanity-tips-and-guides';
import { SanityTrendingProduct } from './sanity-trending-product';
import { SanityButtonActions } from '@api-ui-app/src/app/dashboard/types/sanity-button-actions';
import { CustomerSegment } from '@api-ui-app/src/app/onboarding-steps-widget/types/customer-segment';

export enum SanityHomePageItemWidth {
  sixteen = 1,
  eight = 2,
  ten = 3,
  six = 4,
}

export const sanityHomePageItemWidthMapper = {
  [SanityHomePageItemWidth.sixteen]: 'sixteen',
  [SanityHomePageItemWidth.eight]: 'eight',
  [SanityHomePageItemWidth.ten]: 'ten',
  [SanityHomePageItemWidth.six]: 'six',
};

export interface SanityHomePageContent {
  analytics: SanityHomePageItem;
  discounts: SanityHomePageItem;
  'onboarding-steps': SanityHomePageItem;
  video: SanityHomePageVideoItem;
  tipsAndGuides: SanityHomePageTipsAndGuidesItem;
  trendingProducts: SanityHomePageTrendingProductItem;
  custom: SanityHomePageCustomItem;
}

export interface SanityHomePageItem {
  id: string;
  title: string;
  width: SanityHomePageItemWidth;
  visible: boolean;
  routes?: string[];
  type: string;
  content: unknown;
}

export interface SanityProductCommunicationBannersItem extends SanityHomePageItem {
  content: {
    widgets: SanityHomePageCustomItem[];
  };
}

export interface SanityHomePageCarousel extends SanityHomePageItem {
  content: {
    widgets: SanityHomePageCustomItem[];
  };
}

export interface SanityHomePageCustomItem {
  id: string;
  title: string;
  title_icon: string;
  body_text: string;
  head_text: string;
  image: string;
  image_max_height: string;
  videoId: string;
  primary_button_route: string;
  primary_button_action: SanityButtonActions;
  primary_button_icon: string;
  primary_button_text: string;
  primary_button_arrow_icon: boolean;
  secondary_button_route: string;
  secondary_button_text: string;
  secondary_button_action: SanityButtonActions;
  visible_for_non_plus_customers: boolean;
  visible_for_all_customers: boolean;
  visible_for_plus_customers: boolean;
  visible_when_has_shopify_store: boolean;
}

export interface SanityHomePageTipsAndGuidesItem extends SanityHomePageItem {
  content: SanityTipsAndGuides;
}
export interface SanityHomePageTrendingProductItem extends SanityHomePageItem {
  content: { products: SanityTrendingProduct[]; personalisedProducts: SanityTrendingProduct[] };
}
export interface SanityHomePageVideoItem extends SanityHomePageItem {
  content: SanityVideoContent;
}
export interface SanityVideoContent {
  links: SanityVideoContentLink[];
  moreVideo: string;
}

export interface SanityVideoContentLink {
  image: string;
  link: string;
  title: string;
  customerSegment: CustomerSegment;
  videoId: string;
}
