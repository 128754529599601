export enum LocaleCode {
  DE = 'de_DE',
  GB = 'en-GB',
  EN = 'en',
  US = 'en_US',
  FR = 'fr_FR',
  NO = 'nb_NO',
  SE = 'sv_SE',
  NL = 'nl_NL',
  DK = 'dk_DK',
  ES = 'es_ES',
  CN = 'zh_CN',
  RU = 'ru_RU',
  PT = 'pt_PT',
  IT = 'it_IT',
  JP = 'ja_JP',
  VI = 'vi_VN',
  DA = 'da_DK',
}

export enum LocaleName {
  DE = 'Deutsch - DE',
  EN = 'English - EN',
  FR = 'Français - FR',
  ES = 'Español - ES',
  IT = 'Italian - IT',
  SE = 'Svenska - SE',
  NO = 'Norsk - NO',
  JP = '日本語 - JP',
  VI = 'Tiếng Việt - VI',
  NL = 'Nederlands - NL',
  DA = 'Dansk - DA',
}

export enum LocaleNameShort {
  DE = 'DE',
  EN = 'EN',
  FR = 'FR',
  ES = 'ES',
  IT = 'IT',
  SE = 'SE',
  NO = 'NO',
  JP = 'JP',
  VI = 'VI',
  NL = 'NL',
  DA = 'DA',
}

export const defaultLocale = LocaleCode.EN;

export class Locale {
  constructor(public code: LocaleCode, public name: LocaleName, public nameShort: LocaleNameShort) {}
}

export const LocaleList = [
  new Locale(LocaleCode.EN, LocaleName.EN, LocaleNameShort.EN),
  new Locale(LocaleCode.FR, LocaleName.FR, LocaleNameShort.FR),
  new Locale(LocaleCode.ES, LocaleName.ES, LocaleNameShort.ES),
  new Locale(LocaleCode.IT, LocaleName.IT, LocaleNameShort.IT),
  new Locale(LocaleCode.DE, LocaleName.DE, LocaleNameShort.DE),
  new Locale(LocaleCode.SE, LocaleName.SE, LocaleNameShort.SE),
  new Locale(LocaleCode.NO, LocaleName.NO, LocaleNameShort.NO),
  new Locale(LocaleCode.JP, LocaleName.JP, LocaleNameShort.JP),
  new Locale(LocaleCode.VI, LocaleName.VI, LocaleNameShort.VI),
  new Locale(LocaleCode.NL, LocaleName.NL, LocaleNameShort.NL),
  new Locale(LocaleCode.DA, LocaleName.DA, LocaleNameShort.DA),
];

export enum KeycloakLocaleCode {
  EN = 'en',
  FR = 'fr',
  ES = 'es',
  IT = 'it',
  DE = 'de',
  SE = 'sv',
  NO = 'no',
  JP = 'ja',
  VI = 'vi',
  NL = 'nl',
  DA = 'da',
}
