export enum ServicePlanUniqueNames {
  gelato_platform_free = 'gelato-platform-free',
  gelato_platform_free_legacy = 'gelato-platform-free-legacy',
  gelato_platform_plus = 'gelato-platform-plus',
  gelato_platform_gold = 'gelato-platform-gold',
  gelato_platform_plus_legacy = 'gelato-platform-plus-legacy',
  app_shutterstock_full_plan_0 = 'app-shutterstock-full-plan-0',
  app_shutterstock_full_plan_1 = 'app-shutterstock-full-plan-1',
  app_shutterstock_essential_plan_0 = 'app-shutterstock-essential-plan-0',
  app_shutterstock_essential_plan_1 = 'app-shutterstock-essential-plan-1',
  app_expand_categories_plan_0 = 'app-expand-categories-plan-0',
  app_profit_margin_plan_0 = 'app-profit-margin-plan-0',
  app_automatic_order_approval_plan_0 = 'app-automatic-order-approval-plan-0',
}
