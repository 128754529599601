import { NgModule } from '@angular/core';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { reducer } from './+state/customer-onboarding.reducer';
import { SharedModule } from '../shared/shared.module';
import { ProductAddModule } from '@api-ui-app/src/app/product/product-add/product-add.module';
import { CustomerOnBoardingEffects } from './+state/customer-onboarding.effects';
import { customerOnboardingFeatureName } from './+state/customer-onboarding.feature';
import { OnboardingWidgetContainerComponent } from './containers/onboarding-widget-container/onboarding-widget-container.component';
import { OnboardingWidgetComponent } from './components/onboarding-widget/onboarding-widget.component';
import { GrowChecklistContainertComponent } from './containers/grow-checklist-container/grow-checklist-container.component';
import { GrowChecklistComponent } from './components/grow-checklist/grow-checklist.component';
import { GelatoHelpWidgetContainerComponent } from './containers/gelato-help-widget-container/gelato-help-widget-container.component';
import { GelatoFaceComponent } from './components/gelato-face/gelato-face.component';

@NgModule({
  declarations: [
    OnboardingWidgetContainerComponent,
    OnboardingWidgetComponent,
    GrowChecklistContainertComponent,
    GrowChecklistComponent,
    GelatoHelpWidgetContainerComponent,
    GelatoFaceComponent,
  ],
  imports: [
    SharedModule,
    ProductAddModule,
    CdkStepperModule,
    EffectsModule.forFeature([CustomerOnBoardingEffects]),
    StoreModule.forFeature(customerOnboardingFeatureName, reducer),
  ],
  exports: [OnboardingWidgetContainerComponent, GrowChecklistContainertComponent, GelatoHelpWidgetContainerComponent],
})
export class CustomerOnboardingModule {}
