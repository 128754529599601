import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';

@Component({
  selector: 'gc-missing-gelato-plus-warning',
  templateUrl: './missing-gelato-plus-warning.component.html',
  styleUrls: ['./missing-gelato-plus-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MissinGelatoPlusWarningComponent implements AfterViewInit {
  @Input() titleText: string;
  @Input() descriptionText: string;
  @Input() actionButtonText: string;
  @Input() logEventOnRender?: boolean;
  @Input() logEventKey?: string;
  @Output() actionButtonClick = new EventEmitter();

  ngAfterViewInit(): void {
    if (this.logEventOnRender) {
      logEvent(this.logEventKey);
    }
  }

  onActionButtonClick() {
    this.actionButtonClick.emit();
  }
}
