import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { ECommerceStoresApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-stores-api.service';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EStoreSearchResponse } from '@gelato-api-ui/core/e-commerce/e-store-search-response';
import * as actions from './e-commerce-stores.actions';
import { loadStoresListForCurrentClient } from './e-commerce-stores.actions';
import { getState, getStoresListState } from './e-commerce-stores.selector';
import { findECommerceStoreInState } from './helpers/findECommerceStoreInState';
import { getSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { getDefaultStoresListRequest } from '@gelato-api-ui/core/e-commerce/helpers/getDefaultStoresListRequest';
import { LoadClientsByIds } from '@api-ui-app/src/app/ngrx/client-selection-list.actions';
import { LocalStorageItemKey } from '@gelato-api-ui/core/local-storage/types/local-storage-item-key.enum';
import { EStoreSearchRequest } from '@gelato-api-ui/core/e-commerce/e-store-search-request';
import { StoresListState } from '@api-ui-app/src/app/ngrx/e-commerce-stores.reducer';

@Injectable()
export class ECommerceStoresEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly eCommerceStoresApiService: ECommerceStoresApiService,
    private readonly authService: AuthService,
  ) {}

  LoadStoresList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadStoresList>(actions.ECommerceStoresActionTypes.LoadStoresList),
        withLatestFrom(this.store$.select(getStoresListState), this.authService.hasReadPermission('ecommerce', '*')),
        filter(([action, state]): boolean => {
          if (this.isStoresListDataAlreadyFetched(action.searchRequest, state)) {
            return action.forced;
          }

          return !this.isStoresListDataAlreadyRequested(action.searchRequest, state);
        }),
        switchMap(([action, state, hasPermission]) => {
          const { searchRequest } = action;

          const searchStores = (): Observable<EStoreSearchResponse> => {
            if (!hasPermission) {
              return of({ stores: [] });
            }

            return this.eCommerceStoresApiService.search(searchRequest);
          };

          this.store$.dispatch(
            new actions.LoadStoresListSuccess({
              isLoading: true,
              request: searchRequest,
              payload:
                this.isStoresListDataAlreadyFetched(searchRequest, state) ||
                this.isAnotherStoresListBatchRequested(searchRequest, state)
                  ? state.payload
                  : null,
            }),
          );

          return searchStores().pipe(
            catchError((): Observable<EStoreSearchResponse> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: EStoreSearchResponse) => {
              // Pagination
              const payload =
                searchRequest.offset > 0 ? this.mergeStoresListPayloads(state.payload, response) : response;

              if (payload?.stores?.length) {
                const ids = payload.stores.map(stores => stores.clientId);

                this.store$.dispatch(new LoadClientsByIds(ids));
              }

              this.store$.dispatch(
                new actions.LoadStoresListSuccess({
                  isLoading: false,
                  request: searchRequest,
                  payload,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  RefreshStoresList$ = createEffect(() =>
    this.actions$.pipe(
      ofType<actions.RefreshStoresList>(actions.ECommerceStoresActionTypes.RefreshStoresList),
      withLatestFrom(this.store$.select(getSelectedClientId)),
      map(([, clientId]) => new actions.LoadStoresList(getDefaultStoresListRequest(clientId), true)),
    ),
  );

  LoadStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadStore>(actions.ECommerceStoresActionTypes.LoadStore),
        withLatestFrom(this.store$.select(getState), this.authService.hasReadPermission('ecommerce', '*')),
        switchMap(([action, state, hasPermission]) => {
          const { id, forced } = action;
          const entityFromState: EStore = findECommerceStoreInState(state, id);
          const isDataRelevant = Boolean(entityFromState);

          const getStore = (): Observable<EStore> => {
            if (!hasPermission) {
              return of(null);
            }

            if (!forced && isDataRelevant) {
              return of({ ...entityFromState });
            }

            return this.eCommerceStoresApiService.get(id);
          };

          this.store$.dispatch(
            new actions.SetEcommerceStore({
              isLoading: true,
              payload: isDataRelevant ? entityFromState : null,
            }),
          );

          return getStore().pipe(
            catchError((): Observable<EStore> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((store: EStore) => {
              this.store$.dispatch(actions.setSelectedStore({ payload: store }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  SetSelectedStoreId$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.SetSelectedStoreId>(actions.ECommerceStoresActionTypes.SetSelectedStoreId),
        tap(action => {
          localStorage.setItem(LocalStorageItemKey.selectedStoreId, action.payload);
        }),
      ),
    { dispatch: false },
  );

  SetSelectedStore$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.setSelectedStore),
        tap(action => {
          localStorage.setItem(LocalStorageItemKey.selectedStoreId, action.payload?.id);
        }),
      ),
    { dispatch: false },
  );

  loadStoresListForCurrentClient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadStoresListForCurrentClient),
      withLatestFrom(this.store$.select(getSelectedClientId)),
      map(([_, clientId]) => clientId),
      filter(clientId => !!clientId),
      map(clientId => {
        return new actions.LoadStoresList(getDefaultStoresListRequest(clientId), false);
      }),
    ),
  );

  private mergeStoresListPayloads(
    statePayload: EStoreSearchResponse,
    response: EStoreSearchResponse,
  ): EStoreSearchResponse {
    const statePayloadStores = statePayload?.stores || [];
    const responseStores = response?.stores || [];

    return {
      stores: [...statePayloadStores, ...responseStores],
    };
  }

  private isStoresListDataAlreadyRequested(searchRequest: EStoreSearchRequest, state: StoresListState): boolean {
    return R.equals(searchRequest, state.request) && Boolean(state.isLoading);
  }

  private isStoresListDataAlreadyFetched(searchRequest: EStoreSearchRequest, state: StoresListState): boolean {
    return R.equals(searchRequest, state.request) && Boolean(state.payload);
  }

  private isAnotherStoresListBatchRequested(searchRequest: EStoreSearchRequest, state: StoresListState): boolean {
    return R.equals(R.omit(['offset'], searchRequest), R.omit(['offset'], state.request)) && Boolean(state.payload);
  }
}
