import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { PricePlansListItem } from '@api-ui-app/src/app/subscriptions/types/price-plans-list-item';
import { ServicePricePlanTax } from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { GelatoSubscriptionModalType } from '../../lib/gelato-subscription-modal';
import { addDays, format } from 'date-fns';
import { countryIsoCodesWithVat } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/constants/country-iso-codes-with-vat';

@Component({
  selector: 'gc-subscription-summary',
  templateUrl: './subscription-summary.component.html',
  styleUrls: ['./subscription-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscriptionSummaryComponent {
  @Input() selectedPricePlan: PricePlansListItem;
  @Input() gelatoSubscriptionModalType: GelatoSubscriptionModalType;
  @Input() tax: ServicePricePlanTax;
  @Input() companyDetails: CompanyDetails;
  @Input() isTaxLoading = false;
  @Input() showTrialInformation: boolean;

  gelatoSubscriptionModalTypes = GelatoSubscriptionModalType;
  dateInAMonth = format(addDays(new Date(), 30), 'd MMMM yyyy');
  gelatoPlusSubscriptionHasExpired$ = this.facade.gelatoPlusSubscriptionHasExpired$;

  get showTaxSection() {
    return (
      this.tax?.price &&
      this.companyDetails?.countryIsoCode &&
      countryIsoCodesWithVat.includes(this.companyDetails?.countryIsoCode)
    );
  }

  constructor(private readonly facade: SubscriptionsFacade) {}
}
