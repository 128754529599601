import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { query } from './wallet-transactions.selector';
import { fetchStart, setFilter } from './wallet-transactions.actions';
import { WalletTransactionsState } from './wallet-transactions.adapter';
import { WalletTransactionsApiService } from '../../services/wallet-transactions-api.service';
import { WalletTransaction, WalletTransactionsFilter } from '../../lib/api';
import { catchError } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { EMPTY } from 'rxjs';

@Injectable()
export class WalletTransactionsFacade {
  public readonly all$ = this.store$.pipe(select(query.getAll));
  public readonly ids$ = this.store$.pipe(select(query.getIds));
  public readonly filter$ = this.store$.pipe(select(query.getFilter));
  public readonly total$ = this.store$.pipe(select(query.getTotal));
  public readonly offset$ = this.store$.pipe(select(query.getOffset));
  public readonly limit$ = this.store$.pipe(select(query.getLimit));
  public readonly isFetchingComplete$ = this.store$.pipe(select(query.isFetchingComplete));
  public readonly isFetchingStarted$ = this.store$.pipe(select(query.isFetchingStarted));
  public readonly isFetchingToBeDone$ = this.store$.pipe(select(query.isFetchingToBeDone));

  constructor(
    private readonly store$: Store<WalletTransactionsState>,
    private readonly walletTransactionsApi: WalletTransactionsApiService,
  ) {}

  public triggerFetch(walletUid: string, clientId: string) {
    this.store$.dispatch(fetchStart({ walletUid, clientId }));
  }

  public triggerSetFilter(filter: WalletTransactionsFilter) {
    this.store$.dispatch(setFilter({ filter }));
  }

  public search(walletUid: string, filter: WalletTransactionsFilter, clientId: string) {
    return this.walletTransactionsApi.search(walletUid, filter, clientId);
  }

  public getPdfConfirmationUrl(tr: WalletTransaction, clientId: string) {
    return this.walletTransactionsApi.getPdfConfirmationUrl(tr.walletUid, tr.walletTransactionUid, clientId).pipe(
      catchError(() => {
        this.store$.dispatch(new ShowGeneralErrorNotification());
        return EMPTY;
      }),
    );
  }
}
