import { ChangeDetectorRef, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, first, take, tap } from 'rxjs/operators';
import { PricePlansListItem } from '@api-ui-app/src/app/subscriptions/types/price-plans-list-item';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { getFormFieldError } from '@api-ui-app/src/app/lib/getFormFieldError';
import { ServicePlanPrice } from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { ChargeBeeService } from '@api-ui-app/src/app/subscriptions/services/charge-bee.service';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { GelatoSubscriptionModalContext } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/containers/gelato-plus-subscription-modal/gelato-plus-subscription-modal.component';
import { isPossibleNumber, ParsedNumber, ParseError, parseNumber, parsePhoneNumberWithError } from 'libphonenumber-js';
import { CountryCode, PhoneNumber } from 'libphonenumber-js/types';
import * as actions from '@api-ui-app/src/app/ngrx/company-details.actions';
import { ErrorData } from '@api-ui-app/src/app/shared/lib/error-data';
import { TranslateService } from '@ngx-translate/core';
import { CompanyDetailsApiService } from '@gelato-api-ui/core/company-details/services/company-details-api.service';
import { Dictionary } from '@ngrx/entity';
import { COUNTRIES_WITH_STATE } from '@api-ui-app/src/app/lib/constants';
import { countryIsoCodesWithVat } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/constants/country-iso-codes-with-vat';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';

export enum SubscriptionFlowActiveStep {
  pricePlans = 'PricePlans',
  billingAddress = 'BillingAddress',
  termsAndConditions = 'TermsAndConditions',
}

@Injectable()
export class SubscriptionFlowFacade {
  modal: SuiModal<GelatoSubscriptionModalContext, void, void> = null;
  gelatoSubscriptionModalType = null;
  error$ = new BehaviorSubject<ErrorData>(null);
  analyticsProperties$ = new BehaviorSubject<Dictionary<any>>(null);
  selectedPricePlan$ = new BehaviorSubject<PricePlansListItem>(null);
  localCompanyDetails$ = new BehaviorSubject<CompanyDetails>(null);
  subscriptionPageEnabledAB$ = this.featureSwitcherService.isFeatureEnabledForCurrentUser(
    FeatureFlagEnum.subscription_page_ab,
  );

  gelatoPlusSubscriptionsStatuses$ = this.subscriptionFacade.gelatoPlusSubscriptionsStatuses$;

  private eventsNameABConfig = {
    subscriptionFlowSaveBillingAddress: 'subscriptionFlowSaveBillingAddressABtest',
    subscriptionFlowSaveBillingAddressSuccess: 'subscriptionFlowSaveBillingAddressSuccessABtest',
  };

  constructor(
    private readonly store: Store<AppState>,
    private readonly translateService: TranslateService,
    public readonly subscriptionFacade: SubscriptionsFacade,
    private readonly chargeBeeService: ChargeBeeService,
    private readonly companyDetailsApiService: CompanyDetailsApiService,
    private readonly featureSwitcherService: FeatureSwitcherService,
  ) {}

  saveModalInstance(modal) {
    this.modal = modal;
    this.gelatoSubscriptionModalType = modal?.context?.gelatoSubscriptionModal;
  }

  getErrorMessage(errorMessage: string, fieldName: string) {
    return {
      code: null,
      message: this.translateService.instant('txt_errors_in_submitted_data'),
      details: [
        {
          message: this.translateService.instant(errorMessage),
          reference: fieldName,
        },
      ],
    };
  }

  hasError(fieldName: string): boolean {
    return Boolean(getFormFieldError(this.error$.getValue(), fieldName));
  }

  public openCheckout(trialEnd?: number) {
    const selectedPricePlan = this.selectedPricePlan$.getValue();

    if (selectedPricePlan) {
      logEvent('subscriptionFlowOpenCheckout', this.analyticsProperties$.getValue());

      if (this.modal) {
        this.modal.approve();
      }

      this.trackPricePlanClick(selectedPricePlan.price);
      this.chargeBeeService.openChargeBeeCheckout(selectedPricePlan.price, this.modal, trialEnd);
    }
  }

  public selectPricePlan(pricePlan: PricePlansListItem, activeStep: SubscriptionFlowActiveStep): void {
    logEvent('subscriptionFlowSelectPricePlan', {
      pricePlan: pricePlan.price,
      ...this.analyticsProperties$.getValue(),
    });
    this.selectedPricePlan$.next(pricePlan);
    this.calculateTax(activeStep);
  }

  public calculateTax(activeStep: SubscriptionFlowActiveStep) {
    const pricePlan = this.selectedPricePlan$.getValue();

    logEvent('subscriptionFlowCalculateTax', {
      pricePlan: pricePlan.price,
      ...this.analyticsProperties$.getValue(),
    });
    this.subscriptionFacade.calculateServicePricePlanTax(
      pricePlan.price.id,
      activeStep !== SubscriptionFlowActiveStep.billingAddress,
    );
  }

  public onSaveBillingAddress(cdRef: ChangeDetectorRef) {
    this.error$.next(null);
    const companyDetails = this.localCompanyDetails$.getValue();

    if (!companyDetails.contactEmail) {
      this.error$.next(this.getErrorMessage('txt_form_validation_blank_field_message', 'contactEmail'));
      return;
    }
    let phoneNumber = null;
    try {
      const parsedPhoneNumber = parseNumber(companyDetails.phone, companyDetails.countryIsoCode as CountryCode);
      phoneNumber = parsePhoneNumberWithError(companyDetails.phone, companyDetails.countryIsoCode as CountryCode);

      if (!isPossibleNumber(parsedPhoneNumber as ParsedNumber)) {
        this.error$.next(this.getErrorMessage('txt_phone_field_enter_valid_number', 'phone'));
        return;
      }
    } catch (error) {
      this.error$.next(
        this.getErrorMessage(
          error instanceof ParseError
            ? 'txt_phone_validation_rule_' + error.message
            : 'txt_phone_field_enter_valid_number',
          'phone',
        ),
      );
      return;
    }

    if (companyDetails.contactEmail && !companyDetails.email) {
      companyDetails.email = companyDetails.contactEmail;
    }

    if (
      companyDetails.countryIsoCode &&
      COUNTRIES_WITH_STATE.includes(companyDetails.countryIsoCode) &&
      !companyDetails.stateCode
    ) {
      this.error$.next(this.getErrorMessage('txt_form_validation_blank_field_message', 'stateCode'));
      return;
    }

    this.onSaveCompanyDetails(cdRef, companyDetails, phoneNumber);
  }

  public onSaveCompanyDetails(cdRef: ChangeDetectorRef, companyDetails: CompanyDetails, phoneNumber: PhoneNumber) {
    this.logSubscriptionEvent('subscriptionFlowSaveBillingAddress');

    this.companyDetailsApiService
      .save({
        ...companyDetails,
        phone: phoneNumber?.formatNational(),
      })
      .pipe(
        catchError(data => {
          this.error$.next(data.response.error);
          cdRef.markForCheck();
          return of(null);
        }),
        tap((companyDetailsResponse: CompanyDetails) => {
          if (companyDetailsResponse) {
            this.logSubscriptionEvent('subscriptionFlowSaveBillingAddressSuccess');
            this.error$.next(null);
            this.localCompanyDetails$.next({
              ...companyDetailsResponse,
              phone: phoneNumber?.formatNational(),
            });

            this.store.dispatch(
              new actions.SetState({
                isLoading: false,
                payload: {
                  ...companyDetailsResponse,
                  phone: phoneNumber?.formatNational(),
                },
              }),
            );

            // Refresh TAX
            this.calculateTax(SubscriptionFlowActiveStep.billingAddress);

            // We need to show taxes for US and NO first - once the billing address has been saved
            if (!countryIsoCodesWithVat.includes(companyDetailsResponse.countryIsoCode)) {
              this.openCheckout();
            }
          }
        }),
      )
      .subscribe();
  }

  private logSubscriptionEvent(eventName: string) {
    this.subscriptionPageEnabledAB$.pipe(take(1)).subscribe(subscriptionPageEnabledAB => {
      const event = subscriptionPageEnabledAB ? this.eventsNameABConfig[eventName] : eventName;

      logEvent(event, this.analyticsProperties$.getValue());
    });
  }

  private trackPricePlanClick(servicePlanPrice: ServicePlanPrice) {
    this.gelatoPlusSubscriptionsStatuses$
      .pipe(
        first(),
        tap(gelatoPlusSubscriptionsStatuses => {
          logEvent('trialSignUpFromPopup', {
            periodUnit: servicePlanPrice.periodUnit,
            price: servicePlanPrice.price,
            currency: servicePlanPrice.currency,
            locationPopUp: this.modal.context.gelatoPlusPopupLocation,
            featurePopUp: this.modal.context.gelatoPlusFeature,
            gelato_plus_subscription: gelatoPlusSubscriptionsStatuses,
          });
        }),
      )
      .subscribe();
  }
}
