import { Action, createReducer, on } from '@ngrx/store';
import { walletAdapter, WalletState } from './wallet.adapter';
import { fetchFail, fetchStart, fetchSuccess, topUpSuccess, updateSuccess, withdrawSuccess } from './wallet.actions';

const initialState: WalletState = walletAdapter.getInitialState({
  /**
   * undefined - not even started
   * false - in progress
   * true - fetched
   */
  isFetched: undefined,
});

const reducer = createReducer(
  initialState,
  on(fetchSuccess, (state, action) => ({
    ...walletAdapter.setAll(action.list, state),
    isFetched: true,
  })),
  on(updateSuccess, (state, action) => ({
    ...walletAdapter.updateOne({ id: action.wallet.walletUid, changes: action.wallet }, state),
  })),
  on(topUpSuccess, (state, action) => ({
    ...walletAdapter.updateOne({ id: action.wallet.walletUid, changes: action.wallet }, state),
  })),
  on(withdrawSuccess, (state, action) => ({
    ...walletAdapter.updateOne({ id: action.wallet.walletUid, changes: action.wallet }, state),
  })),
  on(fetchStart, state => ({ ...state, isFetched: false })),
  on(fetchFail, state => ({ ...state, isFetched: undefined })),
);

export function walletReducer(state: WalletState, action: Action) {
  return reducer(state, action);
}
