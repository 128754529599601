import { ConnectedPosition, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { ElementRef, Injectable, Injector } from '@angular/core';
import { DropdownContentType } from '@gelato-api-ui/ui-kit/src/lib/dropdown/types';
import { DropdownRef } from '@gelato-api-ui/ui-kit/src/lib/dropdown/components/dropdown-ref/dropdown-ref';
import { DropdownRefComponent } from '@gelato-api-ui/ui-kit/src/lib/dropdown/components/dropdown-ref/dropdown-ref.component';

export type DropdownConnectedPosition = 'start' | 'center' | 'end';
@Injectable({
  providedIn: 'root',
})
export class DropdownService {
  constructor(private readonly overlay: Overlay, private readonly injector: Injector) {}

  public open(params: {
    readonly origin: HTMLElement | ElementRef;
    readonly content: DropdownContentType;
    readonly width: number | string;
    readonly height: number | string;
    readonly position?: DropdownConnectedPosition;
  }): DropdownRef {
    const { origin, content, width, height, position } = params;
    const overlayRef = this.overlay.create(this.getOverlayConfig({ origin, width, height, position }));
    const dropdownRef = new DropdownRef<DropdownContentType>(overlayRef, content);
    const inject = this.createInjector(dropdownRef, this.injector);

    overlayRef.attach(new ComponentPortal(DropdownRefComponent, null, inject));

    return dropdownRef;
  }

  private createInjector(dropdownRef: DropdownRef, injector: Injector): PortalInjector {
    const tokens = new WeakMap([[DropdownRef, dropdownRef]]);

    return new PortalInjector(injector, tokens);
  }

  private getOverlayConfig(params: {
    readonly origin: HTMLElement | ElementRef;
    readonly width: number | string;
    readonly height: number | string;
    readonly position: DropdownConnectedPosition;
  }): OverlayConfig {
    const { origin, width, height, position } = params;

    return new OverlayConfig({
      backdropClass: 'backdrop',
      hasBackdrop: true,
      height,
      positionStrategy: this.getOverlayPosition(origin, position),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      width,
    });
  }

  private getOverlayPosition(origin: HTMLElement | ElementRef, position: DropdownConnectedPosition): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(origin)
      .setOrigin(origin)
      .withFlexibleDimensions(true)
      .withPush(false)
      .withGrowAfterOpen(true)
      .withPositions(this.getPositions(position));
  }

  private getPositions(pos: DropdownConnectedPosition = 'start'): ConnectedPosition[] {
    return [
      {
        originX: pos,
        originY: 'bottom',
        overlayX: pos,
        overlayY: 'top',
        offsetX: 0,
        offsetY: 0,
      },
    ];
  }
}
