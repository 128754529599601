import { Injectable } from '@angular/core';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { ECommerceProductPreviewSize } from '@api-ui-app/src/app/e-commerce-stores/types/e-commerce-product-preview-size';

@Injectable({ providedIn: 'root' })
export class ECommerceProductPreviewSizeService {
  private readonly baseThumbnailSize: number = 400;

  getProductPreviewSize(storeType: EStoreType): ECommerceProductPreviewSize {
    return this.getProductSizeObject(this.getBasePreviewSize(storeType), true);
  }

  getProductThumbnailSize(): ECommerceProductPreviewSize {
    return this.getProductSizeObject(this.baseThumbnailSize, true);
  }

  private getBasePreviewSize(storeType: EStoreType): number {
    switch (storeType) {
      case EStoreType.SHOPIFY:
      case EStoreType.WOOCOMMERCE:
        return 2048;
      default:
        return 1000;
    }
  }

  private getProductSizeObject(size: number, forceDimensions: boolean): ECommerceProductPreviewSize {
    return {
      width: size,
      height: size,
      forceDimensions,
    };
  }
}
