import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { EProductImageSearchRequest } from '@gelato-api-ui/core/e-commerce/e-product-image-search-request';
import { EProductImageSearchResponse } from '@gelato-api-ui/core/e-commerce/e-product-image-search-response';
import { EProductImageCreateRequest } from '@gelato-api-ui/core/e-commerce/e-product-image-create-request';
import { EProductImageUpdateRequest } from '@gelato-api-ui/core/e-commerce/e-product-image-update-request';
import { EProductImage } from '@gelato-api-ui/core/e-commerce/e-product-image';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductImagesApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  search(storeId: string, request: EProductImageSearchRequest): Observable<EProductImageSearchResponse> {
    if (!request) {
      return of(null);
    }

    return this.apiRequest
      .get<EProductImageSearchResponse>(`/api/ecommerce/v1/stores/${storeId}/products/*/images`, {
        body: request,
      })
      .pipe(map((data: any) => data.data));
  }

  save(storeProductImage: EProductImage): Observable<EProductImage> {
    if (!storeProductImage) {
      return of(null);
    }

    if (storeProductImage.id) {
      const request = new EProductImageUpdateRequest(
        storeProductImage.id,
        storeProductImage.productVariantIds,
        storeProductImage.fileUrl,
        storeProductImage.isPrimary,
        storeProductImage.metadata,
      );

      return this.update(storeProductImage.id, request);
    } else {
      const productVariantIds = storeProductImage.productVariantIds.filter(value => Boolean(value));
      const request = new EProductImageCreateRequest(
        storeProductImage.productId,
        productVariantIds,
        storeProductImage.fileUrl,
        storeProductImage.isPrimary,
        storeProductImage.metadata,
      );

      return this.create(request);
    }
  }

  get(id: string): Observable<EProductImage> {
    if (!id) {
      return of(null);
    }

    return this.apiRequest
      .get<EProductImage>(`/api/ecommerce/v1/stores/*/products/*/images/${id}`)
      .pipe(map(data => data.data));
  }

  create(request: EProductImageCreateRequest): Observable<EProductImage> {
    if (!request) {
      return of(null);
    }

    return this.apiRequest
      .post<EProductImage>('/api/ecommerce/v1/stores/*/products/*/images', {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  update(id: string, request: EProductImageUpdateRequest): Observable<EProductImage> {
    if (!request) {
      return of(null);
    }

    return this.apiRequest
      .put<EProductImage>(`/api/ecommerce/v1/stores/*/products/*/images/${id}`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  delete(id: string): Observable<EProductImage> {
    return this.apiRequest
      .delete<EProductImage>(`/api/ecommerce/v1/stores/*/products/*/images/${id}`)
      .pipe(map(data => data.data));
  }
}
