import * as R from 'ramda';
import { SanityProductCategory } from '../sanity-product-category';

export const findProductCategoryByName = (
  categories: SanityProductCategory[],
  productCategoryName: string,
): SanityProductCategory => {
  if (!categories || !categories.length || !productCategoryName) {
    return null;
  }

  return R.find(
    (category: SanityProductCategory): boolean => category.productModel === productCategoryName,
    categories,
  );
};
