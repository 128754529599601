import { Action, createAction, props, union } from '@ngrx/store';
import { EStoreSearchRequest } from '@gelato-api-ui/core/e-commerce/e-store-search-request';
import { StoresListState, StoreState } from './e-commerce-stores.reducer';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';

export enum ECommerceStoresActionTypes {
  LoadStoresList = '[ECommerceStores] Load StoresList',
  RefreshStoresList = '[ECommerceStores] Refresh StoresList',
  LoadStore = '[ECommerceStores] Load Store',

  ResetStoresListState = '[ECommerceStores] Reset StoresList State',
  ResetStoresListPayload = '[ECommerceStores] Reset StoresList Payload',
  ResetStoreState = '[ECommerceStores] Reset Store State',

  LoadStoresListSuccess = '[ECommerceStores] Load Stores List Success',
  SetEcommerceStore = '[ECommerceStores] Set Ecommerce Store',
  SetSelectedStoreId = '[ECommerceStores] Set SelectedStoreId',
}

export class LoadStoresList implements Action {
  readonly type = ECommerceStoresActionTypes.LoadStoresList;

  constructor(public searchRequest: EStoreSearchRequest, public forced: boolean = false) {}
}

export class RefreshStoresList implements Action {
  readonly type = ECommerceStoresActionTypes.RefreshStoresList;
}

export class LoadStore implements Action {
  readonly type = ECommerceStoresActionTypes.LoadStore;

  constructor(public id: string, public forced: boolean = false) {}
}

export class ResetStoresListState implements Action {
  readonly type = ECommerceStoresActionTypes.ResetStoresListState;
}

export class ResetStoresListPayload implements Action {
  readonly type = ECommerceStoresActionTypes.ResetStoresListPayload;
}

export class ResetStoreState implements Action {
  readonly type = ECommerceStoresActionTypes.ResetStoreState;
}

export class LoadStoresListSuccess implements Action {
  readonly type = ECommerceStoresActionTypes.LoadStoresListSuccess;

  constructor(public payload: StoresListState) {}
}

export class SetEcommerceStore implements Action {
  readonly type = ECommerceStoresActionTypes.SetEcommerceStore;

  constructor(public payload: StoreState) {}
}

export class SetSelectedStoreId implements Action {
  readonly type = ECommerceStoresActionTypes.SetSelectedStoreId;

  constructor(public payload: string) {}
}

export const loadStoresListForCurrentClient = createAction('[ECommerceStores] Load StoresList For Current Client');

export const updateEcommerceStore = createAction('[ECommerceStores] Update Ecommerce Store', props<{ id: string }>());
export const updateEcommerceStoreSuccess = createAction(
  '[ECommerceStores] Update Ecommerce Store Success',
  props<{ payload: EStore }>(),
);

export const addEcommerceStore = createAction('[ECommerceStores] Add Ecommerce Store', props<{ payload: EStore }>());

export const setSelectedStore = createAction('[ECommerceStores] Set SelectedStore Store', props<{ payload: EStore }>());

const actions = union({
  updateEcommerceStore,
  updateEcommerceStoreSuccess,
  loadStoresListForCurrentClient,
  addEcommerceStore,
  setSelectedStore,
});

export type ECommerceStoresActions =
  | typeof actions
  | LoadStoresList
  | LoadStore
  | ResetStoresListState
  | ResetStoresListPayload
  | ResetStoreState
  | LoadStoresListSuccess
  | SetEcommerceStore
  | SetSelectedStoreId;
