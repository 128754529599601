import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { ShowNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';

@Component({
  selector: 'lib-copy-uid-link',
  templateUrl: './copy-uid-link.component.html',
  styleUrls: ['./copy-uid-link.component.scss'],
})
export class CopyUidLinkComponent {
  @Input() uid: string;

  @Input() linkTextKey = 'txt_copy_uid';

  @Input() messageTextKey = 'txt_uid_copied_to_clipboard';

  @Input() messageTextPlaceholder = 'uid';

  @Input() showText = false;

  @Input() showIcon = true;

  constructor(private readonly translate: TranslateService, private readonly store: Store<AppState>) {}

  uidCopied(uid: string) {
    const message = this.translate.instant(this.messageTextKey, { [this.messageTextPlaceholder]: uid });

    this.store.dispatch(new ShowNotification({ message }));
  }
}
