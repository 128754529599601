import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { PricePlan } from '../price-plans/lib/price-plan';
import { PricePlanStatus } from '../price-plans/lib/price-plan-status.enum';
import { PricePlanSearchRequest } from '../price-plans/lib/price-plan-search-request';
import { PricePlanSearchResponse } from '../price-plans/lib/price-plan-search-response';
import { PricePlansApiService } from '../price-plans/services/price-plans-api.service';
import * as actions from './price-plan-selection-list.actions';
import { getState } from './price-plan-selection-list.reducer';

@Injectable()
export class PricePlanSelectionListEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private pricePlansApiService: PricePlansApiService,
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.Load>(actions.PricePlanSelectionListActionTypes.Load),
        withLatestFrom(this.store$.select(getState)),
        switchMap(([action, state]) => {
          this.store$.dispatch(
            new actions.SetState({
              ...state,
              isLoading: true,
            }),
          );

          const request: PricePlanSearchRequest = {
            statuses: [PricePlanStatus.CREATED, PricePlanStatus.ACTIVE],
          };

          return this.pricePlansApiService.search(request).pipe(
            map((response: PricePlanSearchResponse): PricePlan[] => response.data),
            catchError((err): Observable<PricePlan> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((pricePlans: PricePlan[]) => {
              this.store$.dispatch(
                new actions.SetState({
                  isLoading: false,
                  payload: pricePlans,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
