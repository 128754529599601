<span
  class="border"
  (click)="onSelect()"
  [class.active]="selected"
  [class.hide-border]="hideBorder"
  [style.width.px]="size"
  [style.height.px]="size"
>
  <!--  the only set gcTooltipDisabled is not enough. it doesn't work in gelato.com-->
  <ng-container *ngTemplateOutlet="hideTooltip ? color : colorWithTooltip"></ng-container>
</span>
<ng-template #tooltip></ng-template>
<ng-content></ng-content>

<ng-template #colorWithTooltip>
  <span
    class="color"
    (click)="onSelect()"
    [gcTooltip]="tooltip"
    [gcTooltipText]="tooltipText"
    [gcTooltipPosition]="tooltipPosition"
    [gcTooltipTheme]="'dark'"
    [gcTooltipOpenTimeout]="0"
    [gcTooltipDisabled]="!hex"
    [style.background]="colorBackground(hex)"
    [style.width.px]="size - (hideBorder ? 0 : 8)"
    [style.height.px]="size - (hideBorder ? 0 : 8)"
  >
    <ng-container *ngTemplateOutlet="icon"></ng-container>
  </span>
</ng-template>

<ng-template #color>
  <span
    class="color"
    [style.background]="colorBackground(hex)"
    [style.width.px]="size - (hideBorder ? 0 : 8)"
    [style.height.px]="size - (hideBorder ? 0 : 8)"
  >
    <ng-container *ngTemplateOutlet="icon"></ng-container>
  </span>
</ng-template>

<ng-template #icon>
  <ng-container *ngIf="texture && texture !== 'none'">
    <div [class]="'color-texture ' + texture"></div>
  </ng-container>
  <img *ngIf="colorSelectedIcon" class="color-selected" [src]="colorSelectedIcon" />
</ng-template>
