import { Component, Input } from '@angular/core';

@Component({
  selector: 'gd-payment-method-icon',
  templateUrl: './payment-method-icon.component.html',
  styleUrls: ['./payment-method-icon.component.scss'],
})
export class PaymentMethodIconComponent {
  @Input() type: string = null;

  get iconUrl() {
    if (!this.type) {
      return null;
    }

    switch (this.type) {
      case 'mc':
        return this.buildDefaultUrl('mastercard');
      case 'payoneer':
        return '/assets/payments/payoneer.svg';
      default:
        return this.buildDefaultUrl(this.type);
    }
  }

  buildDefaultUrl(paymentType: string) {
    return `/assets/payment-icons/min/flat/${paymentType}.svg`;
  }
}
