<div class="header">
  {{ modal.context.title || ('txt_default_preview_modal_title' | translate) }}
</div>
<div *ngIf="modal.context.url" class="content">
  <div class="preview-wrapper">
    <div *ngIf="!loadedImageUrl" class="ui loader active preview-modal-loader"></div>
    <img *ngIf="loadedImageUrl" src="{{ loadedImageUrl }}" />
  </div>
</div>
<div class="actions">
  <button class="ui primary button" (click)="close()" autofocus>
    {{ 'txt_close' | translate }}
  </button>
</div>
