import { SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { SanityProductControlOption } from '@gelato-api-ui/core/sanity/sanity-product-control-option';
import { PrintSidesSortedByPrice } from '@gelato-api-ui/core/print-sides/print-sides-sorted-by-price';
import { getSortedFormatSizeControlOptions } from '@gelato-api-ui/core/sanity/helpers/getSortedFormatSizeControlOptions';
import { getSortedGarmentSizeControlOptions } from '@gelato-api-ui/core/garment/helpers/getSortedGarmentSizeControlOptions';
import { getSortedPrintSideControlOptions } from '@gelato-api-ui/core/print-sides/helpers/getSortedPrintSideControlOptions';

export const getSortedProductControlOptions = (
  productControlKey: string,
  options: SanityProductControlOption[],
): SanityProductControlOption[] => {
  switch (productControlKey) {
    case SanityProductControlKey.formatSize:
      return getSortedFormatSizeControlOptions(options);
    case SanityProductControlKey.garmentSize:
      return getSortedGarmentSizeControlOptions(options);
    case SanityProductControlKey.garmentPrint:
    case SanityProductControlKey.bagPrint:
      return getSortedPrintSideControlOptions(options, PrintSidesSortedByPrice);
    default:
      return options;
  }
};
