import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';

export const logStoreConnectionSuccessEvent = (store: EStore) => {
  if (!store) {
    return;
  }

  logEvent('storeAddNewStoreCreatedSuccessfully', {
    storeType: store.type,
    storeName: store.name,
    storeUrl: store.domain,
    createdAt: store.createdAt,
    updatedAt: store.updatedAt,
  });
};
