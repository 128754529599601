import { Action } from '@ngrx/store';
import { ResaleCertificateSearchRequest } from '../resale-certificates/lib/resale-certificate-search-request';
import { EntityState, ListState } from './resale-certificates.reducer';

export enum ResaleCertificatesActionTypes {
  LoadList = '[ResaleCertificates] Load List',
  LoadEntity = '[ResaleCertificates] Load Entity',

  ResetListState = '[ResaleCertificates] Reset List State',
  ResetListPayload = '[ResaleCertificates] Reset List Payload',
  ResetEntityState = '[ResaleCertificates] Reset Entity State',

  SetListState = '[ResaleCertificates] Set List State',
  SetEntityState = '[ResaleCertificates] Set Entity State',
}

export class LoadList implements Action {
  readonly type = ResaleCertificatesActionTypes.LoadList;

  constructor(public searchRequest: ResaleCertificateSearchRequest, public forced: boolean = false) {}
}

export class LoadEntity implements Action {
  readonly type = ResaleCertificatesActionTypes.LoadEntity;

  constructor(public id: string, public forced: boolean = false) {}
}

export class ResetListState implements Action {
  readonly type = ResaleCertificatesActionTypes.ResetListState;
}

export class ResetListPayload implements Action {
  readonly type = ResaleCertificatesActionTypes.ResetListPayload;
}

export class SetListState implements Action {
  readonly type = ResaleCertificatesActionTypes.SetListState;

  constructor(public payload: ListState) {}
}

export class ResetEntityState implements Action {
  readonly type = ResaleCertificatesActionTypes.ResetEntityState;
}

export class SetEntityState implements Action {
  readonly type = ResaleCertificatesActionTypes.SetEntityState;

  constructor(public payload: EntityState) {}
}

export type ResaleCertificatesActions =
  | LoadList
  | LoadEntity
  | ResetListState
  | ResetListPayload
  | ResetEntityState
  | SetListState
  | SetEntityState;
