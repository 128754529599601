import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import SwiperCore, { FreeMode, Keyboard, Mousewheel, Navigation, Swiper, SwiperOptions, Thumbs, Zoom } from 'swiper';
import { BehaviorSubject } from 'rxjs';
import { Media } from '@gelato-api-ui/core/sanity/media';
import { VideoComponent } from '../video/video.component';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';

SwiperCore.use([FreeMode, Navigation, Thumbs, Zoom, Keyboard, Mousewheel]);

@Component({
  selector: 'gc-product-previews-fullscreen',
  templateUrl: './product-previews-fullscreen.component.html',
  styleUrls: ['./product-previews-fullscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductPreviewsFullscreenComponent implements OnInit {
  @Input() set media(data: Media[]) {
    this.mediaData = data || [];
  }
  @Input() activeIndex = 0;
  @Input() topOffset = 0;
  @Input() isOpened = false;
  @Input() slidesPerView = 9;
  @Input() isMobile = false;
  @Input() useLoading = false;
  @Input() useBackground = false;
  @Output() close = new EventEmitter<number>();

  @ViewChildren(VideoComponent) videoComponents: QueryList<VideoComponent>;

  mediaData: Media[];
  SLIDER_PER_VIEW_DEFAULT = 7;
  isVideoPlayerReady = false;
  thumbnailsSwiperConfig: SwiperOptions;

  previewSwiperConfig: SwiperOptions = {
    threshold: 10,
    spaceBetween: 2,
    navigation: {
      prevEl: '.swiper-navigation-prev',
      nextEl: '.swiper-navigation-next',
    },
    zoom: true,
    keyboard: true,
  };

  activeIndex$ = new BehaviorSubject<number>(null);
  playVideo$ = new BehaviorSubject<number>(null);
  thumbsSwiper$ = new BehaviorSubject<Swiper>(null);

  private trackCurrentVideoMap: Record<string, boolean> = {};
  private lastPlayedVideoId!: string;

  constructor(private readonly ref: ChangeDetectorRef) {}

  ngOnInit() {
    this.thumbnailsSwiperConfig = {
      direction: 'vertical',
      freeMode: true,
      slidesPerView: this.slidesPerView || this.SLIDER_PER_VIEW_DEFAULT,
      spaceBetween: 10,
      threshold: 10,
      navigation: true,
      mousewheel: true,
    };
    this.activeIndex$.next(this.activeIndex);
  }

  setThumbsSwiper(swiper: Swiper) {
    this.thumbsSwiper$.next(swiper);
  }

  onActiveIndexChange(swiper?: Swiper) {
    this.playVideo$.next(null);
    this.activeIndex$.next(swiper.activeIndex);
    this.ref.detectChanges();

    this.toggleVideoChanged(this.lastPlayedVideoId);
  }

  @HostListener('document:keydown.escape')
  onClose() {
    this.close.emit(this.activeIndex$.value);
  }

  onPlayVideo(index: number, videoId: string) {
    this.playVideo$.next(index);

    // in case there is more than 1 player, we only need to play the player for the video with the same id as the id of clicked video
    if (this.videoComponents.length <= 1) {
      return;
    }

    this.videoComponents.forEach((videoComponent: VideoComponent) => {
      if (videoComponent.id === videoId) {
        videoComponent.player.playVideo(videoId);
      }
    });
  }

  playerStateChange({ data }, { videoId, title }: Media): void {
    this.lastPlayedVideoId = videoId;
    if (data === 1 && !this.trackCurrentVideoMap[videoId]) {
      this.toggleVideoChanged(videoId);

      logEvent('DashboardVideoPlayed', { VideoName: title });
    }
  }

  private toggleVideoChanged(videoId: string): void {
    this.trackCurrentVideoMap[videoId] = !this.trackCurrentVideoMap[videoId];
  }
}
