import { Action, createAction, props, union } from '@ngrx/store';
import { OrderReceiptType } from '../../lib/order-receipt-type.enum';
import { Client } from '@gelato-api-ui/core/clients/client';
import { OrderDetailsExtended } from '@api-ui-app/src/app/orders/lib/order-details-extended';

export enum OrderDetailsActionTypes {
  LoadOrderDetailsStart = '[OrderDetails] Load OrderDetails Start',
  LoadOrderDetailsSuccess = '[OrderDetails] Load OrderDetails Success',
  LoadOrderDetailsFailure = '[OrderDetails] Load OrderDetails Failure',
  ResetOrderDetails = '[OrderDetails] Reset OrderDetails',
  RefreshOrderDetails = '[OrderDetails] Refresh OrderDetails',
  CancelOrder = '[OrderDetails] Cancel Order',

  LoadClientStart = '[OrderDetails] Load Client Start',
  LoadClientSuccess = '[OrderDetails] Load Client Success',

  ToggleLoader = '[OrderDetails] Toggle Loader',
  SetActiveOrderReceiptType = '[OrderDetails] Set ActiveOrderReceiptType',
}

export class LoadOrderDetailsStart implements Action {
  readonly type = OrderDetailsActionTypes.LoadOrderDetailsStart;

  constructor(public payload: string, public forced: boolean = false) {}
}

export class ResetOrderDetails implements Action {
  readonly type = OrderDetailsActionTypes.ResetOrderDetails;
}

export class RefreshOrderDetails implements Action {
  readonly type = OrderDetailsActionTypes.RefreshOrderDetails;
}

export class CancelOrder implements Action {
  readonly type = OrderDetailsActionTypes.CancelOrder;
}

export class LoadOrderDetailsSuccess implements Action {
  readonly type = OrderDetailsActionTypes.LoadOrderDetailsSuccess;

  constructor(public payload: OrderDetailsExtended) {}
}

export class LoadOrderDetailsFailure implements Action {
  readonly type = OrderDetailsActionTypes.LoadOrderDetailsFailure;
}

export class LoadClientStart implements Action {
  readonly type = OrderDetailsActionTypes.LoadClientStart;

  constructor(public payload: string, public forced: boolean = false) {}
}

export class LoadClientSuccess implements Action {
  readonly type = OrderDetailsActionTypes.LoadClientSuccess;

  constructor(public payload: Client) {}
}

export class ToggleLoader implements Action {
  readonly type = OrderDetailsActionTypes.ToggleLoader;

  constructor(public payload: boolean) {}
}

export class SetActiveOrderReceiptType implements Action {
  readonly type = OrderDetailsActionTypes.SetActiveOrderReceiptType;

  constructor(public payload: OrderReceiptType) {}
}

export const deleteOrder = createAction(
  '[OrderDetails] Delete Order',
  props<{ orderId: string; orderReferenceId: string }>(),
);

const actions = union({
  deleteOrder,
});

export type OrderDetailsActions =
  | typeof actions
  | LoadOrderDetailsStart
  | ResetOrderDetails
  | RefreshOrderDetails
  | CancelOrder
  | LoadOrderDetailsSuccess
  | LoadOrderDetailsFailure
  | LoadClientStart
  | LoadClientSuccess
  | ToggleLoader
  | SetActiveOrderReceiptType;
