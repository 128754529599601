import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { EProductVariantsUpdateSummary } from '@api-ui-app/src/app/e-commerce-stores/lib/e-product-variants-update-summary';

interface StoreProductVariantsUpdateModalContext {
  productVariantsUpdateSummary: EProductVariantsUpdateSummary;
}

@Component({
  selector: 'gd-store-product-variants-update-modal',
  templateUrl: './store-product-variants-update-modal.component.html',
  styleUrls: ['./store-product-variants-update-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoreProductVariantsUpdateModalComponent implements OnInit {
  constructor(public readonly modal: SuiModal<StoreProductVariantsUpdateModalContext, void, void>) {}

  ngOnInit() {}

  cancel() {
    this.modal.deny(undefined);
  }

  continue() {
    this.modal.approve(undefined);
  }
}

export class StoreProductVariantsUpdateModal extends ComponentModalConfig<
  StoreProductVariantsUpdateModalContext,
  void,
  void
> {
  constructor(productVariantsUpdateSummary: EProductVariantsUpdateSummary) {
    super(StoreProductVariantsUpdateModalComponent, { productVariantsUpdateSummary });

    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}
