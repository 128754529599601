import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { SanityEffects } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.effects';
import { reducer, sanityReducerToken } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.reducer';
import { StoreModule } from '@ngrx/store';
import { sanityFeatureName } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.feature';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    EffectsModule.forFeature([SanityEffects]),
    StoreModule.forFeature(sanityFeatureName, sanityReducerToken),
  ],
  providers: [
    {
      provide: sanityReducerToken,
      useValue: reducer,
    },
  ],
})
export class SanityModule {}
