import { createAction, props } from '@ngrx/store';
import { ProfitCalculatorRedux } from '@api-ui-app/src/app/profit-calculator/types/profit-calculator';

export const loadProfitCalculatorSanityContent = createAction('[ProfitCalculator] Load Profit Calculator Sanity');

export const loadProfitCalculatorSanityContentSuccess = createAction(
  '[ProfitCalculator] Load Profit Calculator Sanity Success',
  props<{ payload: ProfitCalculatorRedux }>(),
);

export const loadProfitCalculatorSanityContentFailure = createAction(
  '[ProfitCalculator] Load Profit Calculator Sanity Failure',
);
