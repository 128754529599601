import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class NavigateToListService {
  constructor(private readonly router: Router) {}

  onGelatoPlusLabelClick() {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/growth-plans']));

    window.open(url, '_blank');
  }
}
