import { Injectable } from '@angular/core';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductData } from '@gelato-api-ui/core/e-commerce/e-product-data';
import { EProduct } from '@gelato-api-ui/core/e-commerce/e-product';
import { Tag } from '@gelato-api-ui/ui-kit/src/lib/tag/tag';
import { EProductCollection } from '@gelato-api-ui/core/e-commerce/e-product-collection';
import { EProductTagsCollection } from '@gelato-api-ui/core/e-commerce/e-product-tags-collection';
import { getTagsCollection } from '@gelato-api-ui/core/e-commerce/helpers/getTagsCollection';
import { getProductCollectionIds } from '@gelato-api-ui/core/e-commerce/helpers/getProductCollectionIds';
import { getSanityCategoryIdFromEProduct } from '@gelato-api-ui/core/e-commerce/helpers/getSanityCategoryIdFromEProduct';
import { getSanityProductIdFromEProduct } from '@gelato-api-ui/core/e-commerce/helpers/getSanityProductIdFromEProduct';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { EProductVariantAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-variant-abbridged';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductDataMappingService {
  fromEProductWithVariants(productWithVariants: EProductWithVariants): EProductData {
    if (!productWithVariants) {
      return null;
    }

    const eProduct = new EProduct(
      productWithVariants.id,
      productWithVariants.externalId,
      productWithVariants.storeId,
      productWithVariants.title,
      productWithVariants.description,
      getSanityCategoryIdFromEProduct(productWithVariants),
      getSanityProductIdFromEProduct(productWithVariants),
      productWithVariants.previewFileType,
      productWithVariants.productVariantPreviewScene,
      productWithVariants.productVariantOptions,
      productWithVariants.publishingErrorCode,
      productWithVariants.publishingErrorDetails,
      productWithVariants.publishingDetailsFlags,
      productWithVariants.status,
      productWithVariants.publishedAt,
      productWithVariants.createdAt,
      productWithVariants.updatedAt,
      this.tagsCollectionToArray(productWithVariants.tags),
      this.collectionIdsToCollections(productWithVariants.collectionsIds),
      productWithVariants.metadata,
      productWithVariants.hasDraft,
    );

    return new EProductData(
      eProduct,
      productWithVariants.variants,
      productWithVariants.productImages,
      productWithVariants.wizardStep,
    );
  }

  toEProductWithVariants(eProductData: EProductData): EProductWithVariants {
    if (!eProductData) {
      return null;
    }

    return {
      ...eProductData.product,
      tags: this.tagsArrayToCollection(eProductData.product.tags),
      collectionsIds: this.collectionsToIds(eProductData.product.collections),
      externalPreviewUrl: '',
      externalThumbnailUrl: '',
      isReadyToPublish: false,
      previewUrl: '',
      variants: eProductData.productVariants,
      productImages: eProductData.productImages,
      wizardStep: eProductData.wizardStep,
    };
  }

  toEProductAbbridged(eProductData: EProductData): EProductAbbridged {
    if (!eProductData) {
      return null;
    }

    const { product, productVariants, productImages } = eProductData;
    const previewUrl = productImages[0].fileUrl;
    const productAbbridged: EProductAbbridged = {
      ...product,
      tags: null,
      collectionsIds: [],
      previewUrl,
      externalPreviewUrl: productVariants[0].externalPreviewUrl,
      externalThumbnailUrl: productVariants[0].externalThumbnailUrl,
      isReadyToPublish: true,
      publishedAt: product.publishedAt,
      variants: productVariants.map(v => this.toEProductVariantAbbridged(v)),
      createdAt: product.createdAt,
      updatedAt: product.updatedAt,
    };

    return productAbbridged;
  }

  private toEProductVariantAbbridged(eProductVariant: EProductVariant): EProductVariantAbbridged {
    if (!eProductVariant) {
      return null;
    }

    const { id, externalVariantId, clientId, title, connectionStatus, productId } = eProductVariant;

    return {
      id,
      external_id: externalVariantId,
      clientId,
      title,
      connectionStatus,
      productId,
    };
  }

  private tagsCollectionToArray(tags: EProductTagsCollection): Tag[] {
    return Object.keys(tags).map(id => ({ id, title: tags[id] } as Tag));
  }

  private tagsArrayToCollection(tags: Tag[]): EProductTagsCollection {
    return getTagsCollection(tags);
  }

  private collectionIdsToCollections(collectionsIds: string[]): EProductCollection[] {
    return collectionsIds.map(id => ({ id, title: null, externalId: null }));
  }

  private collectionsToIds(collections: EProductCollection[]): string[] {
    return getProductCollectionIds(collections);
  }
}
