import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as R from 'ramda';
import { AuthServiceInterface } from '@gelato-api-ui/core/auth/auth-service-interface';
import { HttpHeaders } from '@gelato-api-ui/core/api/http-headers';

export abstract class AbstractAuthService implements AuthServiceInterface {
  abstract appBoot(): Promise<any>;
  abstract isAuthorised(): Observable<boolean>;
  abstract isGelatoUser(): Observable<boolean>;
  abstract getUserRoles(): Observable<string[]>;
  abstract getClientId(): Observable<string>;
  abstract getHttpHeaders(): Observable<HttpHeaders>;
  abstract redirectToSignIn(): void;
  abstract redirectToSignUp(): void;
  abstract signOut(): void;
  abstract requireAuthorisedUserWithUserRole(userRole: string, route: ActivatedRouteSnapshot): Observable<boolean>;
  abstract requireAuthorisedUserWithPermission(
    scopeType: string,
    scopeName: string,
    scopeAccessLevel: 'read' | 'write',
    route: ActivatedRouteSnapshot,
  ): Observable<boolean>;
  abstract requireAuthorisedUser(route: ActivatedRouteSnapshot): Observable<boolean>;

  hasUserRole(userRole: string): Observable<boolean> {
    return this.getUserRoles().pipe(map((userRoles: string[]) => (userRoles || []).indexOf(userRole) !== -1));
  }

  hasClientUserRolesOnly(): Observable<boolean> {
    return this.isGelatoUser().pipe(map(hasRole => !hasRole));
  }

  hasReadPermission(scopeType: string, scopeName: string) {
    return this.hasPermission(scopeType, scopeName, 'read');
  }

  hasWritePermission(scopeType: string, scopeName: string) {
    return this.hasPermission(scopeType, scopeName, 'write');
  }

  hasPermission(scopeType: string, scopeName: string, scopeAccessLevel: 'read' | 'write'): Observable<boolean> {
    return this.getUserRoles().pipe(
      map((userRoles: string[]) => {
        const permissionsPerAccessLevel = {
          ro: ['read'],
          rw: ['read', 'write'],
        };

        const matchingUserRole = R.find((userRole: string) => {
          const matches = userRole.match(/urn:([a-z0-9-]*):scopes:([a-z0-9-\*]*):(ro|rw)/);

          if (!matches) {
            return false;
          }

          const resourceType = matches[1];
          const resourceName = matches[2];
          const resourceAccessLevel = matches[3];

          if (
            !resourceType ||
            !resourceName ||
            !resourceAccessLevel ||
            !permissionsPerAccessLevel[resourceAccessLevel]
          ) {
            return false;
          }

          return (
            resourceType === scopeType &&
            resourceName === scopeName &&
            permissionsPerAccessLevel[resourceAccessLevel].indexOf(scopeAccessLevel) !== -1
          );
        }, userRoles);

        return Boolean(matchingUserRole);
      }),
    );
  }

  requireAuthorisedUserWithReadPermission(
    scopeType: string,
    scopeName: string,
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    return this.requireAuthorisedUserWithPermission(scopeType, scopeName, 'read', route);
  }

  requireAuthorisedUserWithWritePermission(
    scopeType: string,
    scopeName: string,
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    return this.requireAuthorisedUserWithPermission(scopeType, scopeName, 'write', route);
  }
}
