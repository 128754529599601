import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gd-circle-number-label',
  templateUrl: './circle-number-label.component.html',
  styleUrls: ['./circle-number-label.component.scss'],
})
export class CircleNumberLabelComponent implements OnInit {
  @Input()
  value: number = null;

  constructor() {}

  ngOnInit() {}
}
