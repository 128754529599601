import { ErrorData } from '@api-ui-app/src/app/shared/lib/error-data';

export interface OrdersSyncStatusResponse {
  data: OrdersSyncStatusResponseData;
  error: ErrorData;
}

export interface OrdersSyncStatusResponseData {
  step: number;
  maxSteps: number;
  currentStore: string;
  currentStoreNumber: number;
  storesCount: number;
  percent: number;
  message: string;
  imported: number;
  existing: number;
  invalid: number;
  failed: number;
  status: OrdersSyncStatus;
}

export enum OrdersSyncStatus {
  STARTED = 'started',
  PROGRESS = 'progress',
  FINISHED = 'finished',
}
