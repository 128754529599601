import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { KeysApiService } from '../api-keys/services/keys-api.service';
import { Key } from '../api-keys/lib/key';
import { KeyType } from '../api-keys/lib/key-type.enum';
import { KeySearchResponse } from '../api-keys/lib/key-search-response';
import * as actions from './api-keys.actions';
import { getKeysListState, getState } from './api-keys.reducer';
import { findIncompleteKeyInState } from './helpers/findIncompleteKeyInState';
import { LoadClientsByIds } from '@api-ui-app/src/app/ngrx/client-selection-list.actions';

@Injectable()
export class ApiKeysEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private keysApiService: KeysApiService,
    private authService: AuthService,
  ) {}

  loadKeysList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadKeysList>(actions.ApiKeysActionTypes.LoadKeysList),
        withLatestFrom(this.store$.select(getKeysListState), this.authService.isGelatoUser()),
        switchMap(([action, keysListState, isGelatoUser]) => {
          const searchRequest = { ...action.searchRequest };

          if (!isGelatoUser) {
            searchRequest.types = [KeyType.CLIENT_REQUEST];
          }

          const isDataRelevant = R.equals(searchRequest, keysListState.request) && keysListState.payload;

          let observable: Observable<KeySearchResponse> = this.keysApiService.search(searchRequest);

          if (!action.forced && isDataRelevant) {
            observable = of({ ...keysListState.payload });
          }

          this.store$.dispatch(
            new actions.SetKeysListState({
              isLoading: true,
              request: searchRequest,
              payload: isDataRelevant ? keysListState.payload : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<KeySearchResponse> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: KeySearchResponse) => {
              this.store$.dispatch(
                new actions.SetKeysListState({
                  isLoading: false,
                  request: searchRequest,
                  payload: response,
                }),
              );
              if (response && response.data) {
                const ids = response.data.map(_d => _d.clientId);
                this.store$.dispatch(new LoadClientsByIds(ids));
              }
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadKey$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadKey>(actions.ApiKeysActionTypes.LoadKey),
        withLatestFrom(this.store$.select(getState)),
        switchMap(([action, state]) => {
          const { keyId, forced } = action;
          const keyFromState: Key = state.key.payload;
          const isDataRelevant = Boolean(keyFromState) && keyFromState.id === keyId;

          const keyToShow = isDataRelevant ? keyFromState : findIncompleteKeyInState(state, keyId);

          let observable: Observable<Key> = this.keysApiService.get(keyId);

          if (!forced && isDataRelevant) {
            observable = of({ ...keyFromState });
          }

          this.store$.dispatch(
            new actions.SetKeyState({
              isLoading: true,
              payload: keyToShow,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<Key> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((key: Key) => {
              this.store$.dispatch(
                new actions.SetKeyState({
                  isLoading: false,
                  payload: key,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
