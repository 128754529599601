import { Scene } from './scene';

export const EditorApparelPreviewScenes: Scene[] = [
  'editor/front',
  'editor/back',
  'editor/neck-inner',
  'editor/neck-outer',
  'editor/sleeve-left',
  'editor/sleeve-right',
];

export const ApparelPreviewScenes: Scene[] = [
  ...EditorApparelPreviewScenes,
  'person1/front',
  'person1/back',
  'person1/left-front',
  'person2/front',
  'person2/left-front2',
  'person2/back',
];

export const PremiumPreviewSceneRegExp = /^premium\/.*/;

export const EditorAdditionalScenesRegExp = /^editor\/(neck-inner|neck-outer|sleeve-left|sleeve-right).*/;
