import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  activateServicePlan,
  activateSubscription,
  activateSubscriptionSuccess,
  calculatePricePlanTax,
  calculatePricePlanTaxFailure,
  calculatePricePlanTaxSuccess,
  cancelSubscription,
  cancelSubscriptionFailure,
  cancelSubscriptionSuccess,
  getIfClientEligibleForGoldSubscription,
  getIfClientEligibleForGoldSuccess,
  loadActiveSubscriptionsAndServicePlans,
  loadActiveSubscriptionsSuccess,
  loadSubscriptionPlans,
  loadSubscriptionPlansListFromSanity,
  loadSubscriptionPlansListFromSanitySuccess,
  loadSubscriptionPlansPrices,
  loadSubscriptionPlansPricesSuccess,
  loadSubscriptionPlansSuccess,
  setServicePlanActivationPayload,
} from '@api-ui-app/src/app/subscriptions/+state/subscriptions.actions';
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  withLatestFrom,
} from 'rxjs/operators';
import { SubscriptionsApiService } from '@api-ui-app/src/app/subscriptions/services/subscriptions-api.service';
import {
  ShowGeneralErrorNotification,
  ShowNotification,
} from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { SubscriptionResponse, SubscriptionStatus } from '@api-ui-app/src/app/subscriptions/types/subscriptions';
import { getSelectedClientId, isGelatoUser } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { SubscriptionPlansApiService } from '@api-ui-app/src/app/subscriptions/services/subscription-plans-api.service';
import {
  ServicePlanResponse,
  ServicePlanSearchRequest,
} from '@api-ui-app/src/app/subscriptions/types/subscription-plans';
import {
  DEFAULT_SERVICE_PLAN_PRICE_CURRENCY,
  ServicePlanPriceSearchRequest,
  ServicePlanPriceSearchResponse,
} from '@api-ui-app/src/app/subscriptions/types/subscription-price-plans';
import { UserCurrencyService } from '@api-ui-app/src/app/shared/services/user-currency.service';
import { TranslateService } from '@ngx-translate/core';
import { ActiveSubscriptionsService } from '@api-ui-app/src/app/subscriptions/services/active-subscriptions.service';
import {
  getIsClientCreatedBeforeGelatoPlusStart,
  getIsGelatoPlusGoldTrialScheduledToCancel,
  getIsGelatoPlusTrialScheduledToCancel,
  getServicePlans,
  getSubscriptionByStatusProp,
} from '@api-ui-app/src/app/subscriptions/+state/subscriptions.selectors';
import { SanityApiService } from '@gelato-api-ui/core/sanity/services/sanity-api.service';
import { AuthActionTypes, SetSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.actions';
import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';
import { updateLocale } from '@gelato-api-ui/sdk/src/lib/sdk.actions';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { SubscriptionState } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/components/installing-gelato-plus-overlay/installing-gelato-plus-overlay.component';
import { ChargeBeeService } from '@api-ui-app/src/app/subscriptions/services/charge-bee.service';
import { SubscriptionPlanType } from '../../app-store/types/price-plan-items';
import { LoadClientStart } from '@api-ui-app/src/app/ngrx/clients.actions';

@Injectable()
export class SubscriptionsEffects {
  userCurrency$ = this.userCurrencyService.get();

  constructor(
    private readonly store$: Store,
    private readonly actions$: Actions,
    private readonly subscriptionsApiService: SubscriptionsApiService,
    private readonly subscriptionPlansApiService: SubscriptionPlansApiService,
    private readonly userCurrencyService: UserCurrencyService,
    private readonly translateService: TranslateService,
    private readonly activeSubscriptionsService: ActiveSubscriptionsService,
    private readonly sanityApiService: SanityApiService,
    private readonly chargeBeeService: ChargeBeeService,
  ) {}

  loadActiveSubscriptionsAndServicePlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadActiveSubscriptionsAndServicePlans),
      withLatestFrom(this.store$.select(getSelectedClientId)),
      switchMap(([, clientId]) =>
        this.activeSubscriptionsService
          .fetchActiveSubscriptionsAndServicePlans(clientId)
          .pipe(
            map(({ activeSubscriptions, activeServicePlans }) =>
              loadActiveSubscriptionsSuccess({ activeSubscriptions, activeServicePlans }),
            ),
          ),
      ),
    ),
  );

  loadActiveSubscriptionsAndServicePlansOnClientChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SetSelectedClientId>(AuthActionTypes.SetSelectedClientId),
      withLatestFrom(this.store$.select(isGelatoUser)),
      filter(([_, gelatoUser]) => gelatoUser),
      map(([action, _]) => action.payload),
      distinctUntilChanged((p, n) => p === n),
      map(() => loadActiveSubscriptionsAndServicePlans()),
    ),
  );

  loadSubscriptionsPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSubscriptionPlans),
      switchMap(({ serviceUniqueNames }) => {
        const servicePlanSearchRequest: Partial<ServicePlanSearchRequest> = {
          serviceUniqueNames,
        };

        return this.subscriptionPlansApiService.searchSubscriptionPlans(servicePlanSearchRequest).pipe(
          map(payload => loadSubscriptionPlansSuccess({ payload })),
          catchError(() => of(new ShowGeneralErrorNotification())),
        );
      }),
    ),
  );

  loadSubscriptionsPlansPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSubscriptionPlansPrices),
      delay(200),
      debounceTime(500),
      withLatestFrom(this.store$.select(getIsClientCreatedBeforeGelatoPlusStart)),
      switchMap(([{ serviceUniqueNames, userCurrency }, isLegacyCustomer]) => {
        const servicePlanSearchRequest: Partial<ServicePlanPriceSearchRequest> = {
          serviceUniqueNames,
          currencies: [DEFAULT_SERVICE_PLAN_PRICE_CURRENCY, userCurrency],
        };

        return this.subscriptionPlansApiService.searchSubscriptionPlansPrices(servicePlanSearchRequest).pipe(
          map(payload =>
            loadSubscriptionPlansPricesSuccess({
              payload: this.filterPlansPricesByCustomer(isLegacyCustomer, payload),
            }),
          ),
          catchError(() => of(new ShowGeneralErrorNotification())),
        );
      }),
    ),
  );

  loadSubscriptionPlansListFromSanity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSubscriptionPlansListFromSanity, updateLocale),
      withLatestFrom(this.store$.select(getServicePlans)),
      switchMap(([action, stateStore]) => {
        return this.sanityApiService
          .getSubscriptionsList(
            action.type === updateLocale.type ? action.locale : this.getLocale(),
            (stateStore || []).map(plan => plan.uniqueName),
          )
          .pipe(
            map(payload => loadSubscriptionPlansListFromSanitySuccess({ payload })),
            catchError(() => of(new ShowGeneralErrorNotification())),
          );
      }),
    ),
  );

  getIfClientEligibleForGoldSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getIfClientEligibleForGoldSubscription),
      withLatestFrom(this.store$.select(getSelectedClientId)),
      switchMap(([, clientId]) =>
        this.subscriptionsApiService
          .isClientEligibleForGoldSubscriptions(clientId)
          .pipe(
            map(({ isClientEligibleForGoldSubscription }) =>
              getIfClientEligibleForGoldSuccess({ isClientEligibleForGoldSubscription }),
            ),
          ),
      ),
    ),
  );

  calculateServicePricePlanTax$ = createEffect(() =>
    this.actions$.pipe(
      ofType(calculatePricePlanTax),
      withLatestFrom(this.store$.select(getSelectedClientId)),
      switchMap(([{ servicePricePlanId, silentUpdate }, clientId]) => {
        return this.subscriptionPlansApiService.calculateSubscriptionPlanTax(servicePricePlanId, clientId).pipe(
          map(payload => calculatePricePlanTaxSuccess({ payload })),
          catchError(err => {
            logEvent('subscriptionFlowCalculateTaxFail', {
              servicePricePlanId,
            });
            const {
              response: { error },
            } = err;

            const errorAction = new ShowNotification({
              type: 'error',
              message: this.translateService.instant('txt_subscription_flow_address_is_invalid'),
            });
            return silentUpdate
              ? of(calculatePricePlanTaxFailure({ error }))
              : from([errorAction, calculatePricePlanTaxFailure({ error })]);
          }),
        );
      }),
    ),
  );

  activateServicePlan$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateServicePlan),
      withLatestFrom(this.store$.select(getServicePlans), this.store$.select(getSelectedClientId)),
      map(([{ servicePlanPrice }, servicePlans, clientId]) => {
        const servicePlan: ServicePlanResponse = servicePlans.find(
          (loopServicePlan: ServicePlanResponse): boolean =>
            loopServicePlan.uniqueName === servicePlanPrice.servicePlanUniqueName,
        );
        const subscription: SubscriptionResponse = {
          id: null,
          clientId,
          serviceId: null,
          referenceId: null,
          plan: {
            servicePlanId: servicePlan.id,
            servicePlanPriceId: servicePlanPrice.id,

            unitPrice: servicePlanPrice.price,
            quantity: 1,
            amount: servicePlanPrice.price,
            freeQuantity: 0,
            trialEnd: null,
            billingCycles: null,

            createdAt: null,
            updatedAt: null,
          },
          addons: [],
          status: SubscriptionStatus.active,
          billingPeriod: servicePlanPrice.period,
          billingPeriodUnit: servicePlanPrice.periodUnit,
          nextBillingAt: null,
          activatedAt: null,
          startedAt: null,
          cancelledAt: null,
          createdAt: null,
          updatedAt: null,
          trialStart: null,
          trialEnd: null,
          trialEndAction: null,
          remainingBillingCycles: null,
          cancelScheduleCreatedAt: null,
          currency: servicePlanPrice.currency,
        };

        return setServicePlanActivationPayload({ servicePlan, subscription });
      }),
    ),
  );

  updateSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(activateSubscription),
      withLatestFrom(
        this.store$.select(getSubscriptionByStatusProp, {
          status: SubscriptionStatus.in_trial,
          subscriptionType: SubscriptionPlanType.PLUS,
        }),
        this.store$.select(getSubscriptionByStatusProp, {
          status: SubscriptionStatus.in_trial,
          subscriptionType: SubscriptionPlanType.GOLD,
        }),
        this.store$.select(getIsGelatoPlusTrialScheduledToCancel),
        this.store$.select(getIsGelatoPlusGoldTrialScheduledToCancel),
      ),
      filter(
        ([, inTrialPlusSubscription, inTrialGoldSubscription]) =>
          Boolean(inTrialPlusSubscription) || Boolean(inTrialGoldSubscription),
      ),
      switchMap(
        ([
          action,
          inTrialPlusSubscription,
          inTrialGoldSubscription,
          isGelatoPlusTrialScheduledToCancel,
          isGelatoPlusGoldTrialScheduledToCancel,
        ]) => {
          const isSubscriptionScheduledToCancel =
            action.subscriptionType === SubscriptionPlanType.PLUS
              ? isGelatoPlusTrialScheduledToCancel
              : isGelatoPlusGoldTrialScheduledToCancel;
          const actualTrialSubscription =
            action.subscriptionType === SubscriptionPlanType.PLUS ? inTrialPlusSubscription : inTrialGoldSubscription;

          return this.subscriptionsApiService.updateSubscription(actualTrialSubscription.id).pipe(
            map(payload => {
              if (isSubscriptionScheduledToCancel) {
                this.chargeBeeService.loadActiveSubscriptions(null, SubscriptionState.canceling);
                return cancelSubscriptionSuccess({ subscription: payload });
              } else {
                return activateSubscriptionSuccess({ payload });
              }
            }),
            catchError(() => of(new ShowGeneralErrorNotification())),
          );
        },
      ),
    ),
  );

  cancelSubscription$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelSubscription),
      switchMap(({ subscriptionId }) => this.subscriptionsApiService.cancelSubscription(subscriptionId)),
      map((response: SubscriptionResponse) => {
        if (response) {
          this.store$.dispatch(
            new ShowNotification({
              type: 'success',
              message: this.translateService.instant('txt_subscription_cancel_success'),
            }),
          );
          this.store$.dispatch(new LoadClientStart(response.clientId, false));

          return cancelSubscriptionSuccess({ subscription: response });
        } else {
          this.store$.dispatch(new ShowGeneralErrorNotification());

          return cancelSubscriptionFailure();
        }
      }),
    ),
  );

  private getLocale(): string {
    return this.translateService.currentLang;
  }

  private filterPlansPricesByCustomer(
    isLegacy: boolean,
    plansPricesResponse: ServicePlanPriceSearchResponse,
  ): ServicePlanPriceSearchResponse {
    const legacyServicePlanUniqueNames = [
      ServicePlanUniqueNames.gelato_platform_plus_legacy,
      ServicePlanUniqueNames.gelato_platform_free_legacy,
      ServicePlanUniqueNames.gelato_platform_gold,
    ];
    const nonLegacyServicePlanUniqueNames = [
      ServicePlanUniqueNames.gelato_platform_plus,
      ServicePlanUniqueNames.gelato_platform_free,
      ServicePlanUniqueNames.gelato_platform_gold,
    ];
    const servicePlanUniqueNames = isLegacy ? legacyServicePlanUniqueNames : nonLegacyServicePlanUniqueNames;

    const filteredPrices = plansPricesResponse?.prices?.filter(planPrices =>
      servicePlanUniqueNames.includes(planPrices.servicePlanUniqueName as ServicePlanUniqueNames),
    );

    return {
      prices: filteredPrices,
    };
  }
}
