<div class="header">
  <img src="{{ 'icons/settings.svg' | assetsPath | async }}" />
  {{ 'txt_product_variant_review_pricing_modal_title' | translate }}
</div>
<div class="content">
  <div
    *ngIf="oldCost && newCost && isCostChanged"
    class="ui message info"
    [innerHTML]="
      'txt_product_variant_connection_settings_changed_cost_info'
        | translate: { oldCost: (oldCost | money: currency), newCost: (newCost | money: currency) }
    "
  ></div>

  <p>
    {{ 'txt_product_variant_connection_settings_price_section_description' | translate }}
    <strong *ngIf="newCost">({{ 'txt_product_cost_label' | translate }} = {{ newCost | money: currency }})</strong>
  </p>

  <gd-radio-button
    *ngIf="existingPrice"
    name="priceMode"
    [disabled]="isValidatingPrice"
    [checked]="priceMode === priceModeEnum.EXISTING_PRICE"
    (checkedChange)="onPriceModeChange(priceModeEnum.EXISTING_PRICE)"
  >
    <div
      [innerHTML]="
        'txt_product_variant_connection_settings_price_keep_existing_price_option'
          | translate: { price: (existingPrice | money: currency) }
      "
    ></div>
  </gd-radio-button>
  <gd-radio-button
    name="priceMode"
    [disabled]="isValidatingPrice"
    [checked]="priceMode === priceModeEnum.YOUR_OWN_PRICE"
    (checkedChange)="onPriceModeChange(priceModeEnum.YOUR_OWN_PRICE)"
  >
    {{ 'txt_product_variant_connection_settings_price_set_your_own_price_option' | translate }}
  </gd-radio-button>

  <div class="price-input-wrapper">
    <gc-price-input
      [disabled]="priceMode !== priceModeEnum.YOUR_OWN_PRICE || isValidatingPrice"
      [(value)]="price"
      [error]="!isPositivePrice"
      [currency]="currency"
    ></gc-price-input>
    <div *ngIf="priceError" class="error-message" [innerHTML]="priceError"></div>
  </div>

  <div
    *ngIf="existingPrice <= newCost"
    class="ui message warning"
    [innerHTML]="
      'txt_product_variant_connection_settings_price_small_existing_price_warning'
        | translate: { price: (existingPrice | money: currency), cost: (newCost | money: currency) }
    "
  ></div>
</div>
<div class="actions">
  <button class="ui secondary button" (click)="onDenyButtonClick()">
    {{ 'txt_cancel' | translate }}
  </button>
  <button
    class="ui primary button"
    [class.loading]="isValidatingPrice"
    [class.disabled]="!isPositivePrice"
    (click)="onApproveButtonClick()"
    autofocus
  >
    {{ 'txt_done' | translate }}
  </button>
</div>
