import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as R from 'ramda';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { EProductSearchRequest } from '@gelato-api-ui/core/e-commerce/e-product-search-request';
import { EProductSearchResponse } from '@gelato-api-ui/core/e-commerce/e-product-search-response';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { EProductTagsResponse } from '@gelato-api-ui/core/e-commerce/e-product-tags-response';
import { EProductCollectionsResponse } from '@gelato-api-ui/core/e-commerce/e-product-collections-response';
import { EProductPublishRequest } from '@gelato-api-ui/core/e-commerce/e-product-publish-request';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductsApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  getProduct(productId: string, storeId: string): Observable<EProductWithVariants> {
    if (!productId) {
      return of(null);
    }

    return this.apiRequest
      .get<EProductWithVariants>(`/api/ecommerce/v1/stores/${storeId}/products/${productId}`)
      .pipe(map((data: any) => data.data));
  }

  search(request: EProductSearchRequest): Observable<EProductSearchResponse> {
    if (!request) {
      return of(null);
    }

    return this.apiRequest
      .get<EProductSearchResponse>(`/api/ecommerce/v1/stores/${request.storeId}/products`, {
        body: R.omit(['storeId'], request),
      })
      .pipe(map((data: any) => data.data));
  }

  getTags(storeId: string): Observable<EProductTagsResponse> {
    return this.apiRequest
      .get<EProductTagsResponse>(`/api/ecommerce/v1/stores/${storeId}/products/*/tags`)
      .pipe(map(data => data.data));
  }

  getCollections(storeId: string): Observable<EProductCollectionsResponse> {
    return this.apiRequest
      .get<EProductCollectionsResponse>(`/api/ecommerce/v1/stores/${storeId}/products/collections`)
      .pipe(map(data => data.data));
  }

  publish(id: string, request: EProductPublishRequest): Observable<EProductAbbridged> {
    return this.apiRequest
      .post<EProductAbbridged>(`/api/ecommerce/v1/stores/*/products/${id}:publish`, {
        body: request,
      })
      .pipe(map(data => data.data));
  }

  duplicate(
    productId: string,
    storeId: string,
    destinationStoreId: string,
    isInstant = false,
  ): Observable<EProductAbbridged> {
    return this.apiRequest
      .post<EProductAbbridged>(`/api/ecommerce/v1/stores/${storeId}/products/${productId}:duplicate`, {
        body: { storeId: destinationStoreId, isInstant },
      })
      .pipe(map(data => data.data));
  }

  ignore(storeId: string, id: string): Observable<EProductAbbridged> {
    return this.apiRequest
      .post<EProductAbbridged>(`/api/ecommerce/v1/stores/${storeId}/products/${id}:ignore`)
      .pipe(map(data => data.data));
  }

  delete(storeId: string, id: string): Observable<EProductAbbridged> {
    return this.apiRequest
      .delete<EProductAbbridged>(`/api/ecommerce/v1/stores/${storeId}/products/${id}`)
      .pipe(map(data => data.data));
  }
}
