import { AppDetailsFooter, AppDetailsReview } from '@gelato-api-ui/core/app-store/app-details';
import { SanityFaqItem } from '@gelato-api-ui/core/sanity/sanity-faq-item';
import { PlansComparisonResponse } from '@api-ui-app/src/app/subscriptions/types/compare-table';

export interface SanityAppsListPage {
  /**
   * @deprecated
   */
  banner: AppsListBanner;
  banners: AppsListBannerItem[];
  kitList: KitListItem[];
  reviews: AppDetailsReview[];
  benefits: AppsListBenefits;
  /**
   * @deprecated
   * Become deprecated after A/B tests
   */
  plansComparisonTable: {
    rows: { cells: PlansComparisonResponse }[];
  };
  plansComparisonTableNew: {
    rows: { cells: PlansComparisonResponse }[];
  };
  faq: {
    title: string;
    description: string;
    moreLink: string;
    items: SanityFaqItem[];
  };
  footer: AppDetailsFooter;
  subscriptionCardFeatures: SubscriptionCardFeatures;
  testimonial: Testimonial;
}

export interface Testimonial {
  author: string;
  image: string;
  text: string;
  title: string;
}

export interface SubscriptionCardFeatures {
  free: string[];
  plus: string[];
  gold: string[];
}

export interface AppsListBannerItem {
  bgColor: string;
  bgImage: string;
  subscription: string;
  video_id: string;
  videoSrc: string;
  body_text: string;
  title: string;
}

/**
 * @deprecated
 */
export interface AppsListBanner {
  title: string;
  body_text: string;
  video_id: number;
  image: string;
}

export interface KitItemLink {
  text: string;
  icon?: string;
  link: string;
  linkText: string;
}

export interface KitItem {
  title: string;
  descriptionShort: string;
  descriptionListing: string;
  isFullWide: boolean;
  imageUrl: string;
  serviceUniqueName: string;
  uid: string;
  links: KitItemLink[];
}

export interface KitListItem {
  title: string;
  iconUrls: string[];
  list: KitItem[];
}

export enum RowId {
  annualPrice = 'annualPrice',
  monthPrice = 'monthPrice',
  regular = 'regular',
}

export enum RowType {
  text = 'text',
  checkmark = 'checkmark',
}

export interface AppsListBenefits {
  title;
  description;
  iconUrl: string;
  list: ReadonlyArray<{ description: string; tooltip: string }>;
}
