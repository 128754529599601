import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import {
  catchError,
  delay,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as R from 'ramda';
import { AppState } from '@api-ui-app/src/app/app.state';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { getUsedAssets } from '@gelato-api-ui/core/designs/helpers/getUsedAssets';
import { getAssetsAutorNamesFromProductVariants } from '@gelato-api-ui/core/designs/helpers/getAssetsAutorNamesFromProductVariants';
import { getDesignFamilyIdsFromProductVariants } from '@gelato-api-ui/core/designs/helpers/getDesignFamilyIdsFromProductVariants';
import { ShutterstockAssetProviderUids } from '@gelato-api-ui/core/shutterstock/shutterstock.constant';
import { getContentIdsFromProductVariants } from '@gelato-api-ui/core/designs/helpers/getContentIdsFromProductVariants';
import { EProduct } from '@gelato-api-ui/core/e-commerce/e-product';
import { EProductData } from '@gelato-api-ui/core/e-commerce/e-product-data';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { ECommerceProductDetailsService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-details.service';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import {
  StoreProductPreviewsCollection,
  StoreProductVariantPreviewsCollection,
  StoreProductVariantsService,
} from '@product-catalogue/src/lib/product-catalogue/services/store-product-variants.service';
import {
  getFirstProductUidFromSelectedProduct,
  getSelectedProductCategoryFromSanityContent,
  getSelectedProductCategoryName,
  getSelectedProductFromSanityContent,
  getSelectedProductName,
  getSelectedProductTitle,
  isApparelProductCategory,
} from '@product-catalogue/src/lib/ngrx/product-catalogue.reducer';
import { getAssetsCollection } from '@product-catalogue/src/lib/ngrx/assets.selector';
import { loadStockAvailabilitySummary } from '@product-catalogue/src/lib/ngrx/stock-availability-summary.actions';
import { getOutOfStockSummary } from '@product-catalogue/src/lib/ngrx/stock-availability-summary.selector';
import * as eCommerceProductWizardActions from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.actions';
import { setCountry, setProductDetails } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.actions';
import {
  BulkConnectVariantsMappingPayload,
  DesignAssetCostsCollection,
  PriceCollection,
  State as ECommerceProductWizardState,
  StoreProductVariantsCollection,
} from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import {
  getActiveStep,
  getBulkConnectAutomaticallyConnectedProductVariantsCount,
  getBulkConnectCustomProductVariantsMappingPayload,
  getBulkConnectDefaultProductVariantsMappingIsLoading,
  getBulkConnectDestinationProductVariants,
  getBulkConnectManuallyConnectedProductVariantsCount,
  getBulkConnectMappedProductVariantsCount,
  getDefaultPageCount,
  getDefaultProductUid,
  getDesignAssetCostsForSelectedProductVariantKeys,
  getDesignTemplateCostsForSelectedProductVariantKeys,
  getDestinationProductId,
  getDownloadPreviewsAmount,
  getDuration,
  getHasDraft,
  getIsSavingTemplate,
  getIsTemplateMode,
  getLatestSavedDraftHash,
  getMode,
  getOriginalStoreProductVariantsCollection,
  getPageCount,
  getPricesForSelectedProductVariantKeys,
  getPrimaryPreviewProductVariantKey,
  getPrimaryPreviewScene,
  getProduct,
  getProductCostsForSelectedProductVariantKeys,
  getProductDescription,
  getProductDetails,
  getProductPreviewsCollection,
  getProductShippingCostsForSelectedProductVariantKeys,
  getProductTemplateId,
  getProductTemplateName,
  getProductTitle,
  getProductVariantPreviewsCollection,
  getSelectedCountry,
  getSelectedPreviewFileType,
  getSelectedPreviewScenes,
  getSelectedPreviewScenesArray,
  getSelectedPreviewScenesCount,
  getSelectedProductVariantKeysArray,
  getSelectedProductVariantKeysCollection,
  getState,
  getStoreProductVariantsCollection,
  getSupportedPreviewScenes,
  getTotalCostsForSelectedProductVariantKeys,
  isFetchingDesignAssetCosts,
  isFetchingDesignTemplateCosts,
  isFetchingProductCosts,
  isSavingDraft,
} from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.selector';
import {
  getIsInternalStoreType,
  getSelectedStoreCollections,
  getSelectedStoreId,
  getSelectedStoreTags,
  getStore,
  getStoreCurrency,
  getStoreType,
} from '@api-ui-app/src/app/ngrx/e-commerce-stores.selector';
import { getRelevantSupportedPreviewScenes } from '@gelato-api-ui/core/preflight/helpers/getRelevantSupportedPreviewScenes';
import * as eCommerceProductsActions from '@api-ui-app/src/app/ngrx/e-commerce-products.actions';
import { getSelectedClientCountry, getSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { AssetAuthorsLabelService } from '../asset-authors-label.service';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { trackECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/helpers/trackECommerceProductWizardStep';
import { getPrimaryProductImageUrl } from '@gelato-api-ui/core/e-commerce/helpers/getPrimaryProductImageUrl';
import { ECommerceProductWizardPricesService } from './e-commerce-product-wizard-prices.service';
import { getProductsList } from '@api-ui-app/src/app/ngrx/e-commerce-products.selector';
import { EProductStatus } from '@gelato-api-ui/core/e-commerce/e-product-status.enum';
import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { ProductPreviewScenesService } from '@gelato-api-ui/core/preflight/services/product-preview-scenes.service';
import { getProductUidsFromSanityProduct } from '@gelato-api-ui/core/sanity/helpers/getProductUidsFromSanityProduct';
import { LoadSanityContent } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.actions';
import { CategoryDesignFamilyResponse } from '@gelato-api-ui/core/designs/category-design-family-response';
import { PageLeaveConfirmationService } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation.service';
import { ECommerceProductPublishFacade } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-publish-facade.service';
import {
  PRODUCT_WIZARD_ROUTE_DETAILS,
  PRODUCT_WIZARD_ROUTE_MEDIA,
  PRODUCT_WIZARD_ROUTE_PRICES,
  PRODUCT_WIZARD_ROUTE_VARIANTS,
  PRODUCT_WIZARD_ROUTE_VARIANTS_MAPPING,
} from '@gelato-api-ui/core/e-commerce/constants';
import { ECommerceProductDescriptionService } from '../e-commerce-product-description.service';
import * as productCatalogueActions from '@product-catalogue/src/lib/ngrx/product-catalogue.actions';
import * as assetsMappingActions from '@product-catalogue/src/lib/ngrx/assets.actions';
import { getInitialWizardRouteByMode } from '@gelato-api-ui/core/e-commerce/helpers/getInitialWizardRouteByMode';
import { calculateRecommendedPrice } from '@gelato-api-ui/core/e-commerce/helpers/calculateRecommendedPrice';
import { BreadcrumbItem } from '@gelato-api-ui/core/breadcrumbs/breadcrumb-item';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { EStoreFeature } from '@gelato-api-ui/core/e-commerce/e-store-feature.enum';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { ECommerceStoreFeaturesService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-store-features.service';
import { UserCurrencyService } from '@api-ui-app/src/app/shared/services/user-currency.service';
import { toPriceNumber } from '@gelato-api-ui/core/product-prices/helpers/toPriceNumber';
import { AssetProviderUid } from '@gelato-api-ui/core/designs/asset-provider-uid.enum';
import { hasProductsWithCustomization } from '@gelato-api-ui/core/e-commerce/helpers/hasProductsWithCustomization';
import { DesignPersonalizationEmailFeatureDescriptionService } from '@api-ui-app/src/app/e-commerce-stores/services/design-personalization-email-feature-description.service';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { CurrencyRateService } from '@gelato-api-ui/core/currency-rate/services/currency-rate.service';
import { ConfirmModalService } from '@gelato-api-ui/sdk/src/lib/shared/services/confirm-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ECommerceProductGraphQlService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-graph-ql.service';
import { ECommerceProductValidationService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-validation.service';
import { ECommerceProductDataMappingService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-data-mapping.service';
import { ECommerceProductVariantUpdateConfirmationService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-variant-update-confirmation.service';
import { ECommerceProductVariantUpdateSummaryService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-variant-update-summary.service';
import { getUsedMediaToTrack } from '@gelato-api-ui/core/designs/helpers/getUsedMediaToTrack';
import { PriceListGraphQlService } from '@gelato-api-ui/sdk/src/lib/shared/price-sdk/price-list-graph-ql.service';
import * as actions from '@api-ui-app/src/app/ngrx/e-commerce-stores.actions';
import { LayoutService } from '@gelato-api-ui/sdk/src/lib/shared/services/layout.service';
import { updateMetadataItem } from '@gelato-api-ui/core/metadata/helpers/updateMetadataItem';
import { EProductMetadataKey } from '@gelato-api-ui/core/e-commerce/e-product-metadata-key.enum';
import { calculateProductDraftHash } from '@gelato-api-ui/core/e-commerce/helpers/calculateProductDraftHash';
import { ECommerceProductsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-products-api.service';
import { getInitialWizardRouteByStep } from '@gelato-api-ui/core/e-commerce/helpers/getInitialWizardRouteByStep';
import { EditorPluginService } from '@product-catalogue/src/lib/product-catalogue/services/editor-plugin.service';
import { getVisibleWizardSteps } from '@api-ui-app/src/app/e-commerce-stores/lib/helpers/getVisibleWizardSteps';
import {
  isStandaloneEditMode,
  isStandaloneEditMode as isStandaloneEditModeHelper,
} from '@api-ui-app/src/app/e-commerce-stores/lib/helpers/isStandaloneEditMode';
import { isEditModeForPublishedProduct } from '@api-ui-app/src/app/e-commerce-stores/lib/helpers/isEditModeForPublishedProduct';
import { getCustomTrimValuesMm } from '@gelato-api-ui/core/e-commerce/helpers/getCustomTrimValuesMm';
import { getCustomTrimByDimensions } from '@gelato-api-ui/core/e-commerce/helpers/getCustomTrimByDimensions';
import { getProductVariantKey } from '@gelato-api-ui/core/e-commerce/helpers/getProductVariantKey';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { DesignEditorSharedService } from '@product-catalogue/src/lib/product-catalogue/services/design-editor-shared.service';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { loadImage } from '@gelato-api-ui/core/dom/helpers/loadImage';
import { EProductPublishMode } from '@api-ui-app/src/app/e-commerce-stores/types/e-product-publish-mode.enum';
import { ECommerceProductVariantTitleService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-variant-title.service';
import { ECommerceFramedPostersProductDescriptionService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-framed-posters-product-description.service';
import { ECommerceProductPreviewSizeService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-preview-size.service';
import { EProductPreviewFileTypesListItem } from '@api-ui-app/src/app/e-commerce-stores/types/e-product-preview-file-types-list-item';
import { ECommercePreviewFileTypeService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-preview-file-type.service';
import { ECommerceProductDataVariantOptionsService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-data-variant-options.service';
import { ECommerceProductTemplatesGraphQlService } from '@api-ui-app/src/app/e-commerce-product-templates/services/e-commerce-product-templates-graph-ql.service';
import { ECommerceProductWizardBackRouteService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard-back-route.service';
import { StoreProductMode } from '@product-catalogue/src/lib/product-catalogue/lib/store-product-mode.enum';
import { ECommerceProductTemplateNameModalService } from '@api-ui-app/src/app/e-commerce-product-templates/services/e-commerce-product-template-name-modal.service';
import { ProductDetailsGraphQlService } from '@gelato-api-ui/core/product-details/services/product-details-graph-ql.service';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { ECommerceProductWizardDetailsService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard-details.service';
import { ECommerceProductWizardConvertPricesService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard-convert-prices.service';

type ProductDraftSaveCallback = () => Observable<boolean>;
const noActionCallback = () => of(true);

@Injectable({ providedIn: 'root' })
export class ECommerceProductWizardFacade {
  isGelatoUser$ = this.authService.isGelatoUser();
  clientId$ = this.authService.getClientId();
  state$ = this.store.select(getState);
  suggestedCountry$ = this.store.select(getSelectedClientCountry);
  preferredCurrency$ = this.userCurrencyService.get();
  storeCurrency$ = this.store.select(getStoreCurrency);
  selectedStoreId$ = this.store.select(getSelectedStoreId);
  selectedClientId$ = this.store.select(getSelectedClientId);

  destinationProductId$ = this.store.select(getDestinationProductId);
  bulkConnectDestinationProductVariants$ = this.store.select(getBulkConnectDestinationProductVariants);
  bulkConnectProductVariantsMappingIsLoading$ = this.store.select(getBulkConnectDefaultProductVariantsMappingIsLoading);
  bulkConnectProductVariantsMappingPayload$ = this.store.select(getBulkConnectCustomProductVariantsMappingPayload);
  bulkConnectProductVariantsMappedItemsCount$ = this.store.select(getBulkConnectMappedProductVariantsCount);
  bulkConnectProductVariantsHasMappedItems$ = this.bulkConnectProductVariantsMappedItemsCount$.pipe(
    map((mappedItemsCount: number): boolean => Boolean(mappedItemsCount)),
  );

  tags$ = this.store.select(getSelectedStoreTags);
  collections$ = this.store.select(getSelectedStoreCollections);
  eStore$ = this.store.select(getStore);
  eStoreType$ = this.store.select(getStoreType);
  isInternalStoreType$ = this.store.select(getIsInternalStoreType);
  previewFileTypes$ = this.eStoreType$.pipe(
    map((storeType): EProductPreviewFileTypesListItem[] =>
      this.eCommercePreviewFileTypeService.getPreviewFileTypesList(storeType),
    ),
  );

  productDetails$ = this.store.select(getProductDetails);
  product$ = this.store.select(getProduct);
  productTemplateId$ = this.store.select(getProductTemplateId);
  productTemplateName$ = this.store.select(getProductTemplateName);
  productTitle$ = this.store.select(getProductTitle);
  productDescription$ = this.store.select(getProductDescription);
  hasDraft$ = this.store.select(getHasDraft);
  latestSavedDraftHash$ = this.store.select(getLatestSavedDraftHash);

  productId$ = this.product$.pipe(map((product: EProduct): string => (product ? product.id : null)));

  mode$ = this.store.select(getMode);
  isTemplateMode$ = this.store.select(getIsTemplateMode);
  isStandaloneEditMode$ = this.mode$.pipe(map((mode: EProductWizardMode): boolean => isStandaloneEditMode(mode)));
  isEditModeForPublishedProduct$ = this.mode$.pipe(
    map((mode: EProductWizardMode): boolean => isEditModeForPublishedProduct(mode)),
  );

  isBulkConnectMode$ = this.mode$.pipe(
    map((mode: EProductWizardMode): boolean => mode === EProductWizardMode.BULK_CONNECT),
  );

  visibleSteps$ = combineLatest([this.mode$, this.eStoreType$]).pipe(
    map(([mode, storeType]): ECommerceProductWizardStep[] => getVisibleWizardSteps(mode, storeType)),
  );

  sanityProductCategoryName$ = this.store.select(getSelectedProductCategoryName);
  sanityProductCategory$ = this.store.select(getSelectedProductCategoryFromSanityContent);
  sanityProductName$ = this.store.select(getSelectedProductName);
  sanityProductTitle$ = this.store.select(getSelectedProductTitle);
  sanityProduct$ = this.store.select(getSelectedProductFromSanityContent);
  firstProductUidFromSanityProduct$ = this.store.select(getFirstProductUidFromSelectedProduct);

  isApparelCategory$ = this.store.select(isApparelProductCategory);

  productVariantSetModificationSupported$ = this.eStoreType$.pipe(
    map((storeType: EStoreType): boolean =>
      this.eCommerceStoreFeaturesService.isFeatureSupported(storeType, EStoreFeature.PRODUCT_VARIANT_SET_MODIFICATION),
    ),
  );

  isProductTabSupported$ = combineLatest([this.mode$, this.productVariantSetModificationSupported$]).pipe(
    map(([mode, productVariantSetModificationSupported]): boolean => {
      if (mode === EProductWizardMode.EDIT_DESIGNS) {
        return productVariantSetModificationSupported;
      }

      return [
        EProductWizardMode.CREATE_PRODUCT,
        EProductWizardMode.CREATE_PRODUCT_TEMPLATE,
        EProductWizardMode.EDIT_PRODUCT_TEMPLATE,
        EProductWizardMode.EDIT_DRAFT,
        EProductWizardMode.BULK_CONNECT,
      ].includes(mode);
    }),
  );

  htmlDescriptionSupported$ = this.eStoreType$.pipe(
    map((storeType: EStoreType): boolean =>
      this.eCommerceStoreFeaturesService.isFeatureSupported(storeType, EStoreFeature.HTML_DESCRIPTION),
    ),
  );
  tagsSupported$ = this.eStoreType$.pipe(
    map((storeType: EStoreType): boolean =>
      this.eCommerceStoreFeaturesService.isFeatureSupported(storeType, EStoreFeature.TAGS),
    ),
  );
  collectionsSupported$ = this.eStoreType$.pipe(
    map((storeType: EStoreType): boolean =>
      this.eCommerceStoreFeaturesService.isFeatureSupported(storeType, EStoreFeature.COLLECTIONS),
    ),
  );
  immediatePublishSupported$ = this.eStoreType$.pipe(
    map((storeType: EStoreType): boolean =>
      this.eCommerceStoreFeaturesService.isFeatureSupported(storeType, EStoreFeature.IMMEDIATE_PUBLISH),
    ),
  );
  freeShippingSupported$ = this.eStore$.pipe(
    map(
      (store: EStore): boolean =>
        this.eCommerceStoreFeaturesService.isFeatureSupported(store?.type, EStoreFeature.FREE_SHIPPING_STOREFRONT) &&
        !store?.isLiveRatesEnabled,
    ),
  );
  isDTGNewPrintAreasAB$ = this.featureSwitcherService.isFeatureEnabledForCurrentUser(
    FeatureFlagEnum.dtg_new_print_areas,
  );

  storeProductVariantsCollection$ = this.store.select(getStoreProductVariantsCollection);

  hasProductsWithCustomization$ = this.storeProductVariantsCollection$.pipe(
    map((storeProductVariantsCollection: StoreProductVariantsCollection): boolean =>
      hasProductsWithCustomization(storeProductVariantsCollection),
    ),
  );

  originalStoreProductVariantsCollection$ = this.store.select(getOriginalStoreProductVariantsCollection);

  selectedProductVariantKeysCollection$ = this.store.select(getSelectedProductVariantKeysCollection);
  selectedProductVariantKeysArray$ = this.store.select(getSelectedProductVariantKeysArray);

  selectedPreviewFileType$ = this.store.select(getSelectedPreviewFileType);
  selectedPreviewScenes$ = this.store.select(getSelectedPreviewScenes);
  selectedPreviewScenesArray$ = this.store.select(getSelectedPreviewScenesArray);
  selectedPreviewScenesCount$ = this.store.select(getSelectedPreviewScenesCount);

  primaryPreviewScene$ = this.store.select(getPrimaryPreviewScene);
  primaryPreviewProductVariantKey$ = this.store.select(getPrimaryPreviewProductVariantKey);
  downloadPreviewsAmount$ = this.store.select(getDownloadPreviewsAmount);

  productPreviewsCollection$ = this.store.select(getProductPreviewsCollection);
  productVariantPreviewsCollection$ = this.store.select(getProductVariantPreviewsCollection);

  primaryProductPreviewUrl$ = combineLatest([this.productPreviewsCollection$, this.primaryPreviewScene$]).pipe(
    map(([productPreviewsCollection, primaryPreviewScene]) => {
      const primaryProductImage = this.storeProductVariantsService
        .getProductImages(productPreviewsCollection, primaryPreviewScene)
        .find(image => image.isPrimary);

      return primaryProductImage ? primaryProductImage.fileUrl : null;
    }),
  );

  sortedProductVariants$ = combineLatest([
    this.storeProductVariantsCollection$,
    this.selectedProductVariantKeysArray$,
  ]).pipe(
    distinctUntilChanged(),
    map(([storeProductVariantsCollection, selectedProductVariantKeysArray]) =>
      this.storeProductVariantsService.getSortedProductVariants(
        storeProductVariantsCollection,
        selectedProductVariantKeysArray,
      ),
    ),
  );

  hasProductVariants$ = this.sortedProductVariants$.pipe(
    map((sortedProductVariants: EProductVariant[]) => {
      if (!sortedProductVariants) {
        return false;
      }

      return Boolean(sortedProductVariants.length);
    }),
  );

  productVariantsForPriceTable$ = combineLatest([
    this.sortedProductVariants$,
    this.isBulkConnectMode$,
    this.bulkConnectProductVariantsMappingPayload$,
  ]).pipe(
    map(([sortedProductVariants, isBulkConnectMode, mapping]) => {
      if (isBulkConnectMode && mapping) {
        const usedProductVariantKeys: string[] = Object.values(mapping || {});

        return sortedProductVariants.filter((loopProductVariant: EProductVariant): boolean => {
          const productVariantKey: string = getProductVariantKey(loopProductVariant);

          return Boolean(productVariantKey) && usedProductVariantKeys.includes(productVariantKey);
        });
      }

      return sortedProductVariants;
    }),
  );

  // FIXME: uncomment the code once sophisticated grouping per "cheap" and "expensive" garment colors is implemented
  // showGroupedPriceTable$ = combineLatest([
  //   this.isApparelCategory$,
  //   this.productVariantsForPriceTable$,
  //   this.isBulkConnectMode$,
  // ]).pipe(
  //   map(([isApparelCategory, productVariantsForPriceTable, isBulkConnectMode]): boolean => {
  //     if (isApparelCategory && !isBulkConnectMode) {
  //       return productVariantsForPriceTable.every((v: EProductVariant): boolean => isProductVariantLinked(v));
  //     }
  //
  //     return false;
  //   }),
  // );
  showGroupedPriceTable$ = of(false);

  assetsCollection$ = this.store.select(getAssetsCollection);

  assetAuthors$ = combineLatest([this.assetsCollection$, this.sortedProductVariants$]).pipe(
    map(([assets, sortedProductVariants]) => getAssetsAutorNamesFromProductVariants(assets, sortedProductVariants)),
  );

  assetAuthorsLabel$ = this.assetAuthors$.pipe(
    map((assetAuthors: string[]) => this.assetAuthorsLabelService.get(assetAuthors)),
  );

  shutterstockTabSupported$ = this.sanityProductCategoryName$.pipe(
    map((sanityProductCategoryName: SanityProductCategoryName): boolean =>
      this.editorPluginService.isShutterstockTabSupported(sanityProductCategoryName),
    ),
  );

  hasUsedShutterstockAssets$ = combineLatest([this.assetsCollection$, this.sortedProductVariants$]).pipe(
    map(([assets, sortedProductVariants]) => {
      const variantsWithDesignStructure: EProductVariant[] = sortedProductVariants.filter(
        (loopProductVariant: EProductVariant) => Boolean(loopProductVariant.designStructureJson),
      );

      if (!variantsWithDesignStructure.length) {
        return false;
      }

      return variantsWithDesignStructure.reduce((acc: boolean, loopProductVariant: EProductVariant) => {
        const designData = JSON.parse(loopProductVariant.designStructureJson);
        const usedShutterstockAssets: Asset[] = getUsedAssets(assets, designData, ShutterstockAssetProviderUids);

        return acc || usedShutterstockAssets.length > 0;
      }, false);
    }),
  );

  supportedPreviewScenes$ = combineLatest([
    this.store.select(getSupportedPreviewScenes),
    this.hasUsedShutterstockAssets$,
  ]).pipe(
    map(([supportedPreviewScenes, hasUsedShutterstockAssets]) =>
      getRelevantSupportedPreviewScenes(supportedPreviewScenes, hasUsedShutterstockAssets),
    ),
  );

  productCareInstructions$ = this.sanityProduct$.pipe(
    map(sanityProduct => this.eCommerceProductDetailsService.getProductCareInstructions(sanityProduct)),
  );
  sizeGuideCmTable$ = this.sanityProduct$.pipe(
    map(sanityProduct => this.eCommerceProductDetailsService.getSizeGuideTable(sanityProduct, false)),
  );
  sizeGuideInchTable$ = this.sanityProduct$.pipe(
    map(sanityProduct => this.eCommerceProductDetailsService.getSizeGuideTable(sanityProduct, true)),
  );

  outOfStockSummary$ = this.store.select(getOutOfStockSummary);

  outOfStockProductUidsCount$ = combineLatest([
    this.selectedProductVariantKeysCollection$,
    this.outOfStockSummary$,
  ]).pipe(
    map(([productVariantKeysCollection, outOfStock]) => {
      if (!productVariantKeysCollection || !outOfStock) {
        return 0;
      }

      return Object.keys(productVariantKeysCollection).reduce((count, key) => count + (outOfStock[key] ? 1 : 0), 0);
    }),
  );

  isFetchingProductCosts$ = this.store.select(isFetchingProductCosts);
  isFetchingDesignAssetsCosts$ = this.store.select(isFetchingDesignAssetCosts);
  isFetchingDesignTemplateCosts$ = this.store.select(isFetchingDesignTemplateCosts);
  isSavingDraft$ = this.store.select(isSavingDraft);

  failedDraftAutoSaveAttemptsCount$ = new BehaviorSubject<number>(0);

  isProductDraftAutoSaveEffortsLimitReached$ = this.failedDraftAutoSaveAttemptsCount$.pipe(
    map((failedAttemptsCount: number): boolean => {
      const maxAttemptsCount = 3;

      return failedAttemptsCount >= maxAttemptsCount;
    }),
  );

  isProductDraftAutoSaveEnabled$ = this.mode$.pipe(
    map((mode: EProductWizardMode): boolean =>
      [EProductWizardMode.CREATE_PRODUCT, EProductWizardMode.EDIT_DRAFT].includes(mode),
    ),
  );

  selectedCurrency$ = new BehaviorSubject<string>(null);
  previousSelectedCurrency$ = new BehaviorSubject<string>(null);

  canAutoSaveProductDraft$ = combineLatest([
    this.isProductDraftAutoSaveEnabled$,
    this.isProductDraftAutoSaveEffortsLimitReached$,
  ]).pipe(
    map(
      ([isProductDraftAutoSaveEnabled, isProductDraftAutoSaveEffortsLimitReached]): boolean =>
        isProductDraftAutoSaveEnabled && !isProductDraftAutoSaveEffortsLimitReached,
    ),
  );

  isDraftSaved$ = this.hasDraft$;

  selectedCountryIsoCode$ = this.store.select(getSelectedCountry);
  productCosts$ = this.store.select(getProductCostsForSelectedProductVariantKeys);
  productShippingCosts$ = this.store.select(getProductShippingCostsForSelectedProductVariantKeys);
  designAssetCosts$ = this.store.select(getDesignAssetCostsForSelectedProductVariantKeys);
  designTemplateCosts$ = this.store.select(getDesignTemplateCostsForSelectedProductVariantKeys);
  totalCosts$ = this.store.select(getTotalCostsForSelectedProductVariantKeys);
  customPrices$ = this.store.select(getPricesForSelectedProductVariantKeys);

  storeCurrencyRate$: BehaviorSubject<number> = new BehaviorSubject(null);

  convertedTotalCosts$ = combineLatest([
    this.totalCosts$,
    this.selectedCurrency$,
    this.storeCurrency$,
    this.storeCurrencyRate$,
  ]).pipe(
    distinctUntilChanged(),
    map(
      ([totalCosts, selectedCurrency, storeCurrency, storeCurrencyRate]): PriceCollection =>
        this.eCommerceProductWizardConvertPricesService.convertPriceCollection(
          totalCosts,
          selectedCurrency,
          storeCurrency,
          storeCurrencyRate,
        ),
    ),
  );
  convertedShippingCosts$ = combineLatest([
    this.productShippingCosts$,
    this.selectedCurrency$,
    this.storeCurrency$,
    this.storeCurrencyRate$,
  ]).pipe(
    distinctUntilChanged(),
    map(
      ([productShippingCosts, selectedCurrency, storeCurrency, storeCurrencyRate]): PriceCollection =>
        this.eCommerceProductWizardConvertPricesService.convertPriceCollection(
          productShippingCosts,
          selectedCurrency,
          storeCurrency,
          storeCurrencyRate,
        ),
    ),
    withLatestFrom(
      this.subscriptionsFacade.gelatoPlusIsActive$,
      this.subscriptionsFacade.gelatoPlusGoldIsActive$,
      this.selectedCurrency$,
    ),
    map(([convertedPrices, plusIsActive, goldIsActive, selectedCurrency]) => {
      return this.eCommerceProductWizardConvertPricesService.convertShippingPricesBasedOnSubscription(
        convertedPrices,
        plusIsActive,
        goldIsActive,
        selectedCurrency,
      );
    }),
  );

  recommendedPrices$ = this.convertedTotalCosts$.pipe(
    distinctUntilChanged(),
    map((totalCosts: PriceCollection): PriceCollection => this.getRecommendedPricesFromTotalCosts(totalCosts)),
  );

  initialPrices$ = combineLatest([this.recommendedPrices$, this.customPrices$]).pipe(
    map(
      ([recommendedPrices, customPrices]): PriceCollection => ({
        ...recommendedPrices,
        ...customPrices,
      }),
    ),
  );

  list$ = this.store.select(getProductsList);
  defaultProductUid$ = this.store.select(getDefaultProductUid);
  defaultPageCount$ = this.store.select(getDefaultPageCount);
  pageCount$ = this.store.select(getPageCount);

  hasDefinedMedia$ = combineLatest([this.primaryPreviewProductVariantKey$, this.selectedPreviewFileType$]).pipe(
    map(
      ([primaryPreviewProductVariantKey, selectedPreviewFileType]) =>
        Boolean(primaryPreviewProductVariantKey) && Boolean(selectedPreviewFileType),
    ),
  );

  breadcrumbs$ = combineLatest([this.eStore$, this.product$]).pipe(
    map(([eStore, product]): BreadcrumbItem[] => [
      {
        translationKey: 'txt_stores_list_page_title',
        routerLink: ['/stores/list'],
      },
      {
        text: (eStore ? eStore.name : null) || '...',
        routerLink: eStore && eStore.id ? [`/stores/${eStore.id}/products/list`] : null,
      },
      eStore && eStore.id && product && product.id ? { text: product.title } : { translationKey: 'txt_new_product' },
    ]),
  );

  activeStep$ = this.store.select(getActiveStep);
  nextStep$ = combineLatest([this.visibleSteps$, this.activeStep$]).pipe(
    map(([visibleSteps, activeStep]): ECommerceProductWizardStep => {
      const index = visibleSteps.indexOf(activeStep);

      return index !== -1 ? visibleSteps[index + 1] : null;
    }),
  );
  backButtonLabelTranslationKey$ = this.isTemplateMode$.pipe(
    map((isTemplateMode: boolean): string => (isTemplateMode ? 'txt_back_to_dashboard' : 'txt_return_to_store')),
  );
  continueButtonLabelTranslationKey$ = combineLatest([this.nextStep$, this.mode$, this.isInternalStoreType$]).pipe(
    map(([nextStep, mode, isInternalStoreType]): string => {
      if (isStandaloneEditModeHelper(mode)) {
        return 'txt_publish_changes';
      }

      if (nextStep) {
        switch (nextStep) {
          case ECommerceProductWizardStep.MEDIA:
            return 'txt_continue_to_mockups';
          default:
            return `txt_continue_to_${nextStep}`;
        }
      }

      if (isInternalStoreType) {
        return 'txt_save';
      }

      if (isEditModeForPublishedProduct(mode)) {
        return 'txt_publish_changes';
      }

      return 'txt_publish';
    }),
  );

  isSavingTemplate$ = this.store.select(getIsSavingTemplate);
  isProductTemplatesFeatureEnabled$ = this.featureSwitcherService.isFeatureEnabledForCurrentUser(
    FeatureFlagEnum.product_templates,
  );

  private bulkConnectAutomaticallyConnectedProductVariantsCount$ = this.store.select(
    getBulkConnectAutomaticallyConnectedProductVariantsCount,
  );
  private bulkConnectManuallyConnectedProductVariantsCount$ = this.store.select(
    getBulkConnectManuallyConnectedProductVariantsCount,
  );
  private duration$ = this.store.select(getDuration);

  private addedProductVariantsCount$ = this.storeProductVariantsCollection$.pipe(
    map(variants => (this.eCommerceProductVariantUpdateSummaryService.getAddedProductVariants(variants) || []).length),
  );

  private deletedProductVariantsCount$ = combineLatest([
    this.storeProductVariantsCollection$,
    this.originalStoreProductVariantsCollection$,
  ]).pipe(
    map(
      ([variants, originalVariants]) =>
        (this.eCommerceProductVariantUpdateSummaryService.getRemovedProductVariants(variants, originalVariants) || [])
          .length,
    ),
  );

  productDraftAutoSaveInterval = 10000; // 10 seconds
  productDraftAutoSaveTimer = null;
  beforeProductDraftSaveCallback: ProductDraftSaveCallback = noActionCallback;

  constructor(
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly layoutService: LayoutService,
    private readonly authService: AuthService,

    private readonly storeProductVariantsService: StoreProductVariantsService,
    private readonly eCommerceProductDetailsService: ECommerceProductDetailsService,
    private readonly eCommerceProductsApiService: ECommerceProductsApiService,
    private readonly eCommerceProductGraphQlService: ECommerceProductGraphQlService,
    private readonly assetAuthorsLabelService: AssetAuthorsLabelService,
    private readonly eCommerceProductWizardPricesService: ECommerceProductWizardPricesService,
    private readonly eCommerceStoreFeaturesService: ECommerceStoreFeaturesService,
    private readonly productPreviewScenesService: ProductPreviewScenesService,
    private readonly pageLeaveConfirmationService: PageLeaveConfirmationService,
    private readonly eProductPublishFacade: ECommerceProductPublishFacade,
    private readonly eCommerceProductDescriptionService: ECommerceProductDescriptionService,
    private readonly userCurrencyService: UserCurrencyService,
    private readonly priceListGraphQlService: PriceListGraphQlService,
    private readonly designPersonalizationEmailFeatureDescriptionService: DesignPersonalizationEmailFeatureDescriptionService,
    private readonly currencyRateService: CurrencyRateService,
    private readonly confirmModalService: ConfirmModalService,
    private readonly translateService: TranslateService,
    private readonly productValidationService: ECommerceProductValidationService,
    private readonly eCommerceProductDataMappingService: ECommerceProductDataMappingService,
    private readonly eCommerceProductVariantUpdateConfirmationService: ECommerceProductVariantUpdateConfirmationService,
    private readonly eCommerceProductVariantUpdateSummaryService: ECommerceProductVariantUpdateSummaryService,
    private readonly editorPluginService: EditorPluginService,
    private readonly featureSwitcherService: FeatureSwitcherService,
    private readonly designEditorSharedService: DesignEditorSharedService,
    private readonly eCommerceProductVariantTitleService: ECommerceProductVariantTitleService,
    private readonly eCommerceFramedPostersProductDescriptionService: ECommerceFramedPostersProductDescriptionService,
    private readonly eCommerceProductPreviewSizeService: ECommerceProductPreviewSizeService,
    private readonly eCommercePreviewFileTypeService: ECommercePreviewFileTypeService,
    private readonly eCommerceProductDataVariantOptionsService: ECommerceProductDataVariantOptionsService,
    private readonly eCommerceProductTemplatesGraphQlService: ECommerceProductTemplatesGraphQlService,
    private readonly eCommerceProductWizardBackRouteService: ECommerceProductWizardBackRouteService,
    private readonly eCommerceProductTemplateNameModalService: ECommerceProductTemplateNameModalService,
    private readonly productDetailsGraphQlService: ProductDetailsGraphQlService,
    private readonly subscriptionsFacade: SubscriptionsFacade,
    private readonly eCommerceProductWizardDetailsService: ECommerceProductWizardDetailsService,
    private readonly eCommerceProductWizardConvertPricesService: ECommerceProductWizardConvertPricesService,
  ) {}

  initStateForNewProduct(
    mode: EProductWizardMode,
    productCategoryName: string,
    productName: string,
    productUid?: string,
    pageCount?: number,
  ) {
    this.resetFailedDraftAutoSaveAttemptsCount();
    this.loadSanityContent();
    this.setSelectedProduct(productCategoryName, productName);
    this.resetAssets();
    this.setDefaultProductUid(productUid);
    this.setDefaultPageCount(pageCount);
    this.fetchStoreCurrencyRate();
    this.navigateInitialRouteByMode(mode);
    this.setProduct(productName);
  }

  initStateForBulkConnect(productCategoryName: string, productName: string, productUid?: string, pageCount?: number) {
    this.initStateForNewProduct(
      EProductWizardMode.BULK_CONNECT,
      productCategoryName,
      productName,
      productUid,
      pageCount,
    );
    this.fetchStoreCurrencyRate();
    this.loadBulkConnectDestinationProduct();
  }

  setSelectedStore(eStore: EStore) {
    if (!eStore) {
      return;
    }

    this.store.dispatch(actions.setSelectedStore({ payload: eStore }));
  }

  setSelectedProduct(productCategoryName: string, productName: string) {
    this.store.dispatch(new productCatalogueActions.SetSelectedProductCategoryName(productCategoryName));
    this.store.dispatch(new productCatalogueActions.SetSelectedProductName(productName));
  }

  resetAssets() {
    this.store.dispatch(new assetsMappingActions.ResetState());
  }

  setAssets(assetsCollection: AssetsCollection) {
    this.store.dispatch(new assetsMappingActions.SetAssets(assetsCollection));
  }

  initState(wizardState: Partial<ECommerceProductWizardState>) {
    this.store.dispatch(new eCommerceProductWizardActions.InitState(wizardState));
  }

  setDefaultProductUid(uid: string) {
    this.store.dispatch(eCommerceProductWizardActions.setDefaultProductUid({ uid }));
  }

  setDefaultPageCount(defaultPageCount: number) {
    this.store.dispatch(eCommerceProductWizardActions.setDefaultPageCount({ defaultPageCount }));
  }

  setPageCount(pageCount: number) {
    this.store.dispatch(eCommerceProductWizardActions.setPageCount({ pageCount }));
  }

  navigateInitialRouteByMode(mode: EProductWizardMode) {
    this.router.navigate(getInitialWizardRouteByMode(mode), { replaceUrl: true });
  }

  navigateInitialRouteByStep(mode: ECommerceProductWizardStep) {
    this.router.navigate(getInitialWizardRouteByStep(mode), { replaceUrl: true });
  }

  navigateProductDescriptionPage() {
    combineLatest([this.sanityProductCategoryName$, this.sanityProductName$])
      .pipe(
        take(1),
        tap(([category, product]) => this.router.navigate([`/catalogue/categories/${category}/products/${product}`])),
      )
      .subscribe();
  }

  quit() {
    this.isGelatoUser$
      .pipe(
        withLatestFrom(
          this.selectedClientId$,
          this.selectedStoreId$,
          this.productId$,
          this.defaultProductUid$,
          this.hasDraft$,
          this.isTemplateMode$,
        ),
        take(1),
        tap(
          ([
            isGelatoUser,
            selectedClientId,
            selectedStoreId,
            productId,
            defaultProductUid,
            hasDraft,
            isTemplateMode,
          ]) => {
            if (defaultProductUid) {
              this.navigateProductDescriptionPage();
            } else {
              if (productId && !hasDraft && !isTemplateMode) {
                this.router.navigate([`/stores/${selectedStoreId}/products/edit/${productId}`]);
              } else {
                this.router.navigate(
                  this.eCommerceProductWizardBackRouteService.get(
                    selectedClientId,
                    selectedStoreId,
                    isTemplateMode,
                    isGelatoUser,
                  ),
                );
              }
            }
          },
        ),
      )
      .subscribe();
  }

  backToProductsList(resetState: boolean, storeProductMode?: StoreProductMode) {
    this.isGelatoUser$
      .pipe(
        withLatestFrom(this.selectedClientId$, this.selectedStoreId$, this.isTemplateMode$),
        take(1),
        tap(([isGelatoUser, selectedClientId, selectedStoreId, isTemplateMode]) => {
          if (resetState) {
            this.store.dispatch(new eCommerceProductsActions.ResetProductState());
            this.store.dispatch(new eCommerceProductsActions.ResetProductsListPayload());
            this.store.dispatch(new eCommerceProductsActions.ResetProductsListPayload());
          }

          this.pageLeaveConfirmationService.navigateWithoutConfirmation(
            this.eCommerceProductWizardBackRouteService.get(
              selectedClientId,
              selectedStoreId,
              isTemplateMode || storeProductMode === StoreProductMode.PRODUCT_TEMPLATE_WIZARD,
              isGelatoUser,
            ),
          );
        }),
      )
      .subscribe();
  }

  setActiveStep(step: ECommerceProductWizardStep) {
    this.store.dispatch(eCommerceProductWizardActions.setActiveStep({ payload: step }));
    this.mode$
      .pipe(
        filter(mode => Boolean(mode)),
        take(1),
        tap((mode: EProductWizardMode) => {
          trackECommerceProductWizardStep(mode, step);
        }),
      )
      .subscribe();
  }

  editStoreProductVariants() {
    this.pageLeaveConfirmationService.navigateWithoutConfirmation(PRODUCT_WIZARD_ROUTE_VARIANTS);
  }

  editStoreProductMedia() {
    this.pageLeaveConfirmationService.navigateWithoutConfirmation(PRODUCT_WIZARD_ROUTE_MEDIA);
  }

  editStoreProductDetails() {
    this.pageLeaveConfirmationService.navigateWithoutConfirmation(PRODUCT_WIZARD_ROUTE_DETAILS);
  }

  editStoreProductPrices() {
    this.isBulkConnectMode$
      .pipe(
        withLatestFrom(
          this.selectedProductVariantKeysArray$,
          this.customPrices$,
          this.bulkConnectDestinationProductVariants$,
          this.bulkConnectProductVariantsMappingPayload$,
        ),
        take(1),
        tap(
          ([isBulkConnectMode, selectedProductVariantKeysArray, customPrices, destinationProductVariants, mapping]) => {
            if (isBulkConnectMode && mapping) {
              const newCustomPrices = { ...customPrices };

              selectedProductVariantKeysArray.forEach((loopKey: string) => {
                if (newCustomPrices[loopKey]) {
                  return;
                }

                const destinationProductVariant: EProductVariant = (destinationProductVariants || []).find(
                  (loopProductVariant: EProductVariant): boolean => {
                    const variantIdFromMapping: string = Object.keys(mapping).find(
                      (id: string): boolean => mapping[id] === loopKey,
                    );

                    return Boolean(variantIdFromMapping) && loopProductVariant.id === variantIdFromMapping;
                  },
                );

                if (!destinationProductVariant) {
                  return;
                }

                newCustomPrices[loopKey] = destinationProductVariant.price;
              });

              this.store.dispatch(eCommerceProductWizardActions.setPrices({ prices: newCustomPrices }));
            }

            this.pageLeaveConfirmationService.navigateWithoutConfirmation(PRODUCT_WIZARD_ROUTE_PRICES);
          },
        ),
      )
      .subscribe();
  }

  loadSanityContent() {
    this.store.dispatch(new LoadSanityContent());
  }

  loadStockAvailabilitySummary(sanityProduct: SanityProduct) {
    this.store.dispatch(loadStockAvailabilitySummary({ payload: sanityProduct }));
  }

  loadTagsAndCollections() {
    this.selectedStoreId$
      .pipe(
        take(1),
        withLatestFrom(this.isInternalStoreType$),
        tap(([storeId, isInternalStoreType]) => {
          if (isInternalStoreType) {
            return;
          }

          this.store.dispatch(eCommerceProductsActions.loadProductTags({ storeId }));
          this.store.dispatch(eCommerceProductsActions.loadProductCollections({ storeId }));
        }),
      )
      .subscribe();
  }

  loadSupportedPreviewScenes(productUid: string = null) {
    this.sanityProduct$
      .pipe(
        take(1),
        switchMap(sanityProduct => {
          const productUids = productUid ? [productUid] : getProductUidsFromSanityProduct(sanityProduct);
          return this.productPreviewScenesService.get(productUids[0]);
        }),
        tap((scenes: string[]) => {
          this.store.dispatch(
            eCommerceProductWizardActions.setSupportedPreviewScenes({ supportedPreviewScenes: scenes }),
          );
        }),
      )
      .subscribe();
  }

  setDesignFamily(designFamily: CategoryDesignFamilyResponse) {
    this.store.dispatch(eCommerceProductWizardActions.setDesignFamily({ designFamily }));
  }

  updateProductDescription() {
    this.eStoreType$
      .pipe(
        withLatestFrom(
          this.productDescription$,
          this.hasProductsWithCustomization$,
          this.htmlDescriptionSupported$,
          this.sanityProduct$,
          this.storeProductVariantsCollection$,
          this.sanityProductCategoryName$,
          this.eStoreType$,
        ),
        take(1),
        tap(
          ([
            eStoreType,
            productDescription,
            hasProductsWithCustomizationFlag,
            useHtmlMarkup,
            sanityProduct,
            storeProductVariantsCollection,
            sanityProductCategoryName,
            storeType,
          ]) => {
            let newProductDescription =
              this.designPersonalizationEmailFeatureDescriptionService.getUpdatedProductDescription(
                productDescription,
                eStoreType,
                hasProductsWithCustomizationFlag,
                useHtmlMarkup,
              );

            if (sanityProductCategoryName === SanityProductCategoryName.FRAMED_POSTERS) {
              newProductDescription = this.eCommerceFramedPostersProductDescriptionService.getUpdatedProductDescription(
                newProductDescription,
                sanityProduct,
                storeProductVariantsCollection,
                storeType,
              );
            }

            if (newProductDescription !== productDescription) {
              this.store.dispatch(new eCommerceProductWizardActions.SetProductDescription(newProductDescription));
            }
          },
        ),
      )
      .subscribe();
  }

  updateSelectedPreviewScenes() {
    this.hasUsedShutterstockAssets$
      .pipe(
        take(1),
        tap((hasUsedShutterstockAssets: boolean) => {
          this.store.dispatch(eCommerceProductWizardActions.updateSelectedPreviewScenes({ hasUsedShutterstockAssets }));
        }),
      )
      .subscribe();
  }

  updateSelectedProductAndVariantOptions() {
    this.store.dispatch(new eCommerceProductWizardActions.UpdateSelectedProductAndVariantOptions());
  }

  setCountryCode(countryIsoCode: string) {
    this.store.dispatch(setCountry({ payload: countryIsoCode }));
  }

  setCurrency(currency: string) {
    this.selectedCurrency$.next(currency);
    this.fetchStoreCurrencyRate();
  }

  fetchCosts() {
    this.fetchProductCosts()
      .pipe(
        take(1),
        tap(() => {
          this.fetchAssetCosts();
          this.fetchDesignTemplateCosts();
        }),
      )
      .subscribe();
  }

  fetchProductCostsByCustomParameters(
    variants: StoreProductVariantsCollection,
    pageCount: number,
  ): Observable<number[]> {
    return this.eCommerceProductWizardDetailsService.fetchProductDetails(this.sortedProductVariants$).pipe(
      delay(300),
      withLatestFrom(
        this.storeCurrency$,
        this.suggestedCountry$,
        this.selectedClientId$,
        this.selectedCountryIsoCode$,
        this.productDetails$,
      ),
      switchMap(([, storeCurrency, suggestedCountry, selectedClientId, selectedCountryIsoCode, productDetails]) => {
        return this.priceListGraphQlService
          .fetchPriceList(
            Object.values(variants).map(({ productUid, customTrim }) => ({
              productUid,
              ...getCustomTrimValuesMm(customTrim),
            })),
            selectedCountryIsoCode || suggestedCountry,
            storeCurrency,
            pageCount,
            selectedClientId,
            productDetails?.hasFlatShippingPriceType,
            true,
          )
          .pipe(
            take(1),
            catchError(() => of(null)),
            tap(priceList => {
              if (priceList) {
                let productCostsCollection: PriceCollection = {};
                let productShippingCostsCollection: PriceCollection = {};
                Object.keys(priceList).forEach((key: string) => {
                  const priceListItem = priceList[key];
                  const productVariantKey = getProductVariantKey({
                    productUid: priceListItem.productUid,
                    pageCount,
                    customTrim: getCustomTrimByDimensions(priceListItem.width, priceListItem.height),
                  });

                  productCostsCollection[productVariantKey] = toPriceNumber(priceListItem.product);
                  productShippingCostsCollection[productVariantKey] = toPriceNumber(priceListItem.shipment);

                  this.store.dispatch(new eCommerceProductWizardActions.DecreaseActivePriceFetchingRequestsCount());
                });

                this.store.dispatch(
                  eCommerceProductWizardActions.setProductAndShippingCostsBatch({
                    productCosts: productCostsCollection,
                    productShippingCosts: productShippingCostsCollection,
                  }),
                );
              }
            }),
          );
      }),
    );
  }

  fetchAssetCosts() {
    this.store.dispatch(eCommerceProductWizardActions.fetchDesignAssetCosts());

    this.assetsCollection$
      .pipe(
        withLatestFrom(this.clientId$, this.sortedProductVariants$, this.storeCurrency$, this.productCosts$),
        take(1),
        switchMap(([assets, clientId, sortedProductVariants, preferredCurrency, productCosts]) =>
          this.eCommerceProductWizardPricesService
            .fetchAssetCosts(clientId, assets, sortedProductVariants, preferredCurrency, productCosts)
            .pipe(
              tap((designAssetCosts: DesignAssetCostsCollection) => {
                this.store.dispatch(eCommerceProductWizardActions.fetchDesignAssetCostsSuccess({ designAssetCosts }));
              }),
            ),
        ),
      )
      .subscribe();
  }

  fetchDesignTemplateCosts() {
    this.store.dispatch(eCommerceProductWizardActions.fetchDesignTemplateCosts());

    this.sortedProductVariants$
      .pipe(
        withLatestFrom(this.storeCurrency$),
        take(1),
        switchMap(([sortedProductVariants, preferredCurrency]) =>
          this.eCommerceProductWizardPricesService
            .fetchDesignTemplateCosts(sortedProductVariants, preferredCurrency)
            .pipe(
              tap((designTemplateCosts: PriceCollection) => {
                this.store.dispatch(
                  eCommerceProductWizardActions.fetchDesignTemplateCostsSuccess({ designTemplateCosts }),
                );
              }),
            ),
        ),
      )
      .subscribe();
  }

  generatePreviewCollections() {
    return combineLatest([
      this.generateProductPreviewsCollection(),
      this.generateProductVariantPreviewsCollection(),
    ]).pipe(
      take(1),
      tap(([productPreviewsCollection, productVariantPreviewsCollection]) => {
        this.store.dispatch(
          eCommerceProductWizardActions.setPreviewCollections({
            productPreviewsCollection,
            productVariantPreviewsCollection,
          }),
        );
      }),
    );
  }

  generatePrimaryProductPreview() {
    return this.storeProductVariantsCollection$.pipe(
      withLatestFrom(
        this.primaryPreviewProductVariantKey$,
        this.primaryPreviewScene$,
        this.selectedPreviewFileType$,
        this.sanityProductCategoryName$,
        this.sanityProduct$,
        this.eStoreType$,
      ),
      switchMap(
        ([
          storeProductVariantsCollection,
          primaryPreviewProductVariantKey,
          primaryPreviewScene,
          selectedPreviewFileType,
          sanityProductCategoryName,
          sanityProduct,
          storeType,
        ]) => {
          return this.storeProductVariantsService.getProductPreviewsCollectionItem(
            storeProductVariantsCollection,
            primaryPreviewProductVariantKey,
            primaryPreviewScene,
            selectedPreviewFileType,
            sanityProductCategoryName,
            sanityProduct,
            this.eCommerceProductPreviewSizeService.getProductPreviewSize(storeType),
          );
        },
      ),
      tap((primaryProductPreviewUrl: string) => {
        this.store.dispatch(
          eCommerceProductWizardActions.setPrimaryProductPreview({
            payload: primaryProductPreviewUrl,
          }),
        );
      }),
    );
  }

  savePrices(prices: PriceCollection) {
    this.store.dispatch(eCommerceProductWizardActions.setPrices({ prices }));
  }

  saveDefaultPrices() {
    this.initialPrices$
      .pipe(
        take(1),
        tap((prices: PriceCollection) => {
          this.savePrices(prices);
        }),
      )
      .subscribe();
  }

  getRecommendedPricesFromTotalCosts(totalCosts: PriceCollection): PriceCollection {
    return Object.keys(totalCosts).reduce(
      (acc: PriceCollection, productVariantKey: string): PriceCollection => ({
        ...acc,
        [productVariantKey]: calculateRecommendedPrice(totalCosts[productVariantKey]),
      }),
      {},
    );
  }

  public saveProduct(): Observable<EProduct> {
    this.destroyProductDraftAutoSaveTimer();

    return this.getProductData(false).pipe(
      withLatestFrom(
        this.selectedClientId$,
        this.assetsCollection$,
        this.state$,
        this.addedProductVariantsCount$,
        this.deletedProductVariantsCount$,
        this.isInternalStoreType$,
      ),
      take(1),
      switchMap(
        ([
          productDataToSave,
          selectedClientId,
          assets,
          wizardState,
          addedProductVariantsCount,
          deletedProductVariantsCount,
          isInternalStoreType,
        ]) => {
          if (!productDataToSave) {
            return of(null);
          }

          if (wizardState.mode === EProductWizardMode.BULK_CONNECT) {
            return this.saveBulkConnectProductVariants(
              selectedClientId,
              assets,
              productDataToSave,
              wizardState,
              isInternalStoreType,
            );
          }

          return this.saveProductStateless(
            productDataToSave,
            selectedClientId,
            wizardState.mode,
            assets,
            wizardState.publishWithFreeShipping,
            wizardState.publishImmediately,
            addedProductVariantsCount,
            deletedProductVariantsCount,
            isInternalStoreType,
          );
        },
      ),
    );
  }

  saveProductStateless(
    productData: EProductData,
    clientId: string,
    mode: EProductWizardMode,
    assets: AssetsCollection,
    publishWithFreeShipping: boolean,
    publishImmediately: boolean,
    addedProductVariantsCount: number,
    deletedProductVariantsCount: number,
    isInternalStoreType: boolean,
    publishMode?: EProductPublishMode,
  ): Observable<EProductData> {
    this.onProductSavingStart(productData);

    const product: EProductWithVariants = this.eCommerceProductDataMappingService.toEProductWithVariants(productData);

    return this.eCommerceProductGraphQlService.saveProduct(clientId, product, mode, publishMode).pipe(
      take(1),
      catchError(() => of(null)),
      map(
        (savedProduct: EProductWithVariants): EProductData =>
          this.eCommerceProductDataMappingService.fromEProductWithVariants(savedProduct),
      ),
      tap((savedProductData: EProductData) => {
        this.onProductSavingResponse(
          savedProductData,
          productData,
          assets,
          mode,
          publishWithFreeShipping,
          publishImmediately,
          addedProductVariantsCount,
          deletedProductVariantsCount,
          isInternalStoreType,
        );
      }),
    );
  }

  saveProductDraft(): Observable<EProductData> {
    return this.getProductData(true).pipe(
      withLatestFrom(this.selectedClientId$, this.latestSavedDraftHash$),
      take(1),
      switchMap(([productDataToSave, selectedClientId, latestSavedDraftHash]) => {
        if (!productDataToSave) {
          return of(null);
        }

        const product: EProductWithVariants =
          this.eCommerceProductDataMappingService.toEProductWithVariants(productDataToSave);

        const newDraftHash = calculateProductDraftHash(product);

        if (latestSavedDraftHash && latestSavedDraftHash === newDraftHash) {
          return of(null);
        }

        this.store.dispatch(eCommerceProductWizardActions.saveProductDraftStart());

        return this.eCommerceProductGraphQlService.saveProductDraft(selectedClientId, product).pipe(
          take(1),
          tap((savedProductDraft: EProductWithVariants) => {
            this.store.dispatch(
              eCommerceProductWizardActions.saveProductDraftSuccess({
                productId: savedProductDraft ? savedProductDraft.id : null,
                latestSavedDraftHash: newDraftHash,
              }),
            );
          }),
          map(
            (savedProductDraft: EProductWithVariants): EProductData =>
              this.eCommerceProductDataMappingService.fromEProductWithVariants(savedProductDraft),
          ),
        );
      }),
      catchError(() => {
        this.store.dispatch(eCommerceProductWizardActions.saveProductDraftFailure());

        return of(null);
      }),
    );
  }

  deleteProductDraft(): Observable<boolean> {
    return this.product$.pipe(
      take(1),
      filter((product: EProduct): boolean => Boolean(product && product.id && product.storeId)),
      switchMap((product: EProduct) =>
        this.eCommerceProductGraphQlService.deleteProductDraft(product.storeId, product.id),
      ),
    );
  }

  initProductDraftAutoSaveTimer(callback?: ProductDraftSaveCallback, oneTimeInit?: boolean) {
    this.beforeProductDraftSaveCallback = callback || noActionCallback;
    this.destroyProductDraftAutoSaveTimer();
    this.canAutoSaveProductDraft$
      .pipe(
        take(1),
        tap((canAutoSaveProductDraft: boolean) => {
          if (!canAutoSaveProductDraft) {
            this.destroyProductDraftAutoSaveTimer();
            return;
          }

          this.productDraftAutoSaveTimer = setTimeout(() => {
            this.beforeProductDraftSaveCallback()
              .pipe(
                take(1),
                switchMap((callbackResult: boolean): Observable<EProductData> => {
                  if (!callbackResult) {
                    this.initProductDraftAutoSaveTimer(callback);

                    return of(null);
                  }

                  return this.saveProductDraft().pipe(
                    take(1),
                    tap((productData: EProductData) => {
                      this.updateFailedDraftAutoSaveAttemptsCount(productData);

                      if (!oneTimeInit) {
                        this.initProductDraftAutoSaveTimer(callback);
                      }
                    }),
                  );
                }),
              )
              .subscribe();
          }, this.productDraftAutoSaveInterval);
        }),
      )
      .subscribe();
  }

  destroyProductDraftAutoSaveTimer() {
    clearTimeout(this.productDraftAutoSaveTimer);
    this.productDraftAutoSaveTimer = null;
  }

  private onProductSavingStart(productData: EProductData) {
    // Add publishing product to the list of products
    this.eProductPublishFacade.createPublishingProductFromProductData(productData);
  }

  private onProductSavingResponse(
    savedProductData: EProductData,
    originalProductData: EProductData,
    assets: AssetsCollection,
    mode: EProductWizardMode,
    publishWithFreeShipping: boolean,
    publishImmediately: boolean,
    addedProductVariantsCount: number,
    deletedProductVariantsCount: number,
    isInternalStoreType: boolean,
  ) {
    if (this.validateSavedProductData(savedProductData)) {
      this.onProductSavingSuccess(
        savedProductData,
        assets,
        mode,
        publishWithFreeShipping,
        publishImmediately,
        addedProductVariantsCount,
        deletedProductVariantsCount,
        isInternalStoreType,
      );
    } else {
      this.onProductSavingFailure(originalProductData);
    }
  }

  private onProductSavingSuccess(
    savedProductData: EProductData,
    assets: AssetsCollection,
    mode: EProductWizardMode,
    publishWithFreeShipping: boolean,
    publishImmediately: boolean,
    addedProductVariantsCount: number,
    deletedProductVariantsCount: number,
    isInternalStoreType: boolean,
  ) {
    this.eProductPublishFacade.updatePublishingProduct(
      {
        ...savedProductData.product,
        externalThumbnailUrl: getPrimaryProductImageUrl(savedProductData.productImages),
      },
      EProductStatus.PUBLISHING,
    );

    const savedProductEntity = savedProductData.product;

    this.trackSaveProductSuccess(
      savedProductData,
      assets,
      savedProductEntity,
      mode,
      addedProductVariantsCount,
      deletedProductVariantsCount,
    );

    if (isInternalStoreType) {
      this.eProductPublishFacade.updatePublishingProduct(savedProductEntity, savedProductEntity.status);
    } else {
      this.eProductPublishFacade.publishProduct(
        savedProductEntity.id,
        savedProductEntity.productName,
        savedProductEntity.metadata,
        publishWithFreeShipping,
        publishImmediately,
      );
    }
  }

  private onProductSavingFailure(productData: EProductData) {
    this.store.dispatch(new ShowGeneralErrorNotification());
    this.eProductPublishFacade.updatePublishingProduct(
      {
        ...productData.product,
        externalThumbnailUrl: getPrimaryProductImageUrl(productData.productImages),
        variants: productData.productVariants,
      },
      productData.product.status || EProductStatus.CREATED,
    );
  }

  triggerSaveProduct() {
    this.mode$
      .pipe(
        take(1),
        withLatestFrom(this.isInternalStoreType$),
        tap(([mode, isInternalStoreType]) => {
          if (!isStandaloneEditMode(mode) && isInternalStoreType) {
            // Manual order stores: Populate product variants with default recommended prices
            // because of absent Prices step in such cases
            this.saveDefaultPrices();
          }
        }),
        switchMap(([mode]) => {
          if (mode === EProductWizardMode.EDIT_MEDIA) {
            return this.generatePreviewCollections();
          }

          return of(null);
        }),
        tap(() => {
          this.store.dispatch(eCommerceProductsActions.saveProduct());
          this.backToProductsList(true);
        }),
      )
      .subscribe();
  }

  public getProductData(draftMode: boolean): Observable<EProductData> {
    const storeCurrencyRate = this.storeCurrencyRate$.getValue();

    return this.state$.pipe(
      withLatestFrom(
        this.assetsCollection$,
        this.htmlDescriptionSupported$,
        this.selectedProductVariantKeysArray$,
        this.sanityProductCategory$,
        this.sanityProduct$,
        this.eStore$,
        this.sizeGuideCmTable$,
        this.sizeGuideInchTable$,
        this.productCareInstructions$,
        this.storeCurrency$,
        this.totalCosts$,
        this.isEditModeForPublishedProduct$,
        this.productPreviewsCollection$,
        this.productVariantPreviewsCollection$,
        this.activeStep$,
      ),
      map(
        ([
          state,
          assets,
          htmlDescriptionSupported,
          selectedProductVariantKeysArray,
          sanityProductCategory,
          sanityProduct,
          eStore,
          sizeGuideCmTable,
          sizeGuideInchTable,
          productCareInstructions,
          storeCurrency,
          totalCosts,
          isEditMode,
          productPreviewsCollection,
          productVariantPreviewsCollection,
          activeStep,
        ]): EProductData => {
          const productVariantsCollection = state.storeProductVariants;
          const productVariantsCollectionWithPrices: StoreProductVariantsCollection = Object.keys(
            productVariantsCollection,
          ).reduce((acc: StoreProductVariantsCollection, key: string): StoreProductVariantsCollection => {
            const productVariant = productVariantsCollection[key];
            const price = state.prices[key];
            const cost = totalCosts[key] * storeCurrencyRate;

            return {
              ...acc,
              [key]: {
                ...productVariant,
                currency: storeCurrency,
                price,
                cost,
              },
            };
          }, {});

          const newProductData = this.storeProductVariantsService.getProductData(
            state.product,
            selectedProductVariantKeysArray,
            productVariantsCollectionWithPrices,
            productVariantPreviewsCollection,
            productPreviewsCollection,
            state.selectedPreviewFileType,
            state.selectedPreviewScenes,
            state.primaryPreviewProductVariantKey,
            state.primaryPreviewScene,
            sanityProductCategory,
            sanityProduct,
            eStore,
          );

          if (!newProductData) {
            return null;
          }

          const productDataToSave: EProductData = R.clone(newProductData);

          if (draftMode) {
            productDataToSave.wizardStep = activeStep;

            productDataToSave.product.metadata = updateMetadataItem(
              productDataToSave.product.metadata,
              EProductMetadataKey.ATTACH_SIZE_GUIDE_CM_TABLE,
              JSON.stringify(state.attachSizeGuideCmTable),
            );

            productDataToSave.product.metadata = updateMetadataItem(
              productDataToSave.product.metadata,
              EProductMetadataKey.ATTACH_SIZE_GUIDE_INCH_TABLE,
              JSON.stringify(state.attachSizeGuideInchTable),
            );

            productDataToSave.product.metadata = updateMetadataItem(
              productDataToSave.product.metadata,
              EProductMetadataKey.ATTACH_PRODUCT_CARE_INSTRUCTIONS,
              JSON.stringify(state.attachProductCareInstructions),
            );

            productDataToSave.product.metadata = updateMetadataItem(
              productDataToSave.product.metadata,
              EProductMetadataKey.PUBLISH_IMMEDIATELY,
              JSON.stringify(state.publishImmediately),
            );

            productDataToSave.product.metadata = updateMetadataItem(
              productDataToSave.product.metadata,
              EProductMetadataKey.PUBLISH_WITH_FREE_SHIPPING,
              JSON.stringify(state.publishWithFreeShipping),
            );
          } else if (!isEditMode) {
            [
              EProductMetadataKey.ASSETS,
              EProductMetadataKey.ATTACH_SIZE_GUIDE_CM_TABLE,
              EProductMetadataKey.ATTACH_SIZE_GUIDE_INCH_TABLE,
              EProductMetadataKey.ATTACH_PRODUCT_CARE_INSTRUCTIONS,
              EProductMetadataKey.PUBLISH_IMMEDIATELY,
              EProductMetadataKey.PUBLISH_WITH_FREE_SHIPPING,
            ].map((key: string) => {
              productDataToSave.product.metadata = updateMetadataItem(productDataToSave.product.metadata, key, null);
            });

            productDataToSave.product.description = this.eCommerceProductDescriptionService.compileProductDescription(
              productDataToSave.product.description,
              assets,
              productDataToSave.productVariants,
              htmlDescriptionSupported,
              state.attachSizeGuideCmTable ? sizeGuideCmTable : null,
              state.attachSizeGuideInchTable ? sizeGuideInchTable : null,
              state.attachProductCareInstructions ? productCareInstructions : null,
            );
          }

          return this.eCommerceProductDataVariantOptionsService.update(productDataToSave, eStore?.type);
        },
      ),
    );
  }

  private generateProductPreviewsCollection(): Observable<StoreProductPreviewsCollection> {
    return this.storeProductVariantsCollection$.pipe(
      withLatestFrom(
        this.selectedPreviewScenes$,
        this.primaryPreviewProductVariantKey$,
        this.selectedPreviewFileType$,
        this.sanityProductCategory$,
        this.sanityProduct$,
        this.eStoreType$,
      ),
      take(1),
      switchMap(
        ([
          storeProductVariantsCollection,
          selectedPreviewScenes,
          primaryPreviewProductVariantKey,
          selectedPreviewFileType,
          sanityProductCategory,
          sanityProduct,
          storeType,
        ]) => {
          return this.storeProductVariantsService.getProductPreviewsCollection(
            storeProductVariantsCollection,
            selectedPreviewScenes,
            primaryPreviewProductVariantKey,
            selectedPreviewFileType,
            sanityProductCategory?.productModel,
            sanityProduct,
            this.eCommerceProductPreviewSizeService.getProductPreviewSize(storeType),
          );
        },
      ),
    );
  }

  private generateProductVariantPreviewsCollection(): Observable<StoreProductPreviewsCollection> {
    return this.storeProductVariantsCollection$.pipe(
      withLatestFrom(
        this.sanityProductCategory$,
        this.sanityProduct$,
        this.primaryPreviewScene$,
        this.selectedPreviewFileType$,
        this.mode$,
        this.eStoreType$,
      ),
      take(1),
      switchMap(
        ([
          storeProductVariantsCollection,
          sanityProductCategory,
          sanityProduct,
          primaryPreviewScene,
          selectedPreviewFileType,
          mode,
          storeType,
        ]) => {
          return this.storeProductVariantsService.getProductVariantPreviewsCollection(
            storeProductVariantsCollection,
            sanityProductCategory ? sanityProductCategory.productModel : null,
            sanityProduct,
            primaryPreviewScene,
            selectedPreviewFileType,
            mode,
            this.eCommerceProductPreviewSizeService.getProductPreviewSize(storeType),
          );
        },
      ),
    );
  }

  private trackSaveProductSuccess(
    productDataToSave: EProductData,
    assets: AssetsCollection,
    storeProduct: EProduct,
    mode: EProductWizardMode,
    addedProductVariantsCount: number,
    deletedProductVariantsCount: number,
  ) {
    const shutterstockContentIds = getContentIdsFromProductVariants(
      assets,
      productDataToSave.productVariants,
      ShutterstockAssetProviderUids,
    );
    const clipartContentIds = getContentIdsFromProductVariants(assets, productDataToSave.productVariants, [
      AssetProviderUid.CLIPART,
    ]);
    const gelatoDesignContentIds = getDesignFamilyIdsFromProductVariants(productDataToSave.productVariants);

    const eventType =
      mode === EProductWizardMode.CREATE_PRODUCT ? 'storeAddProductSaveProduct' : 'storeEditProductSaveProduct';

    const mediaInfo = productDataToSave.productVariants.map((loopProductVariant: EProductVariant) => {
      let mediaInfoItem;

      if (loopProductVariant.designStructureJson) {
        try {
          mediaInfoItem = getUsedMediaToTrack(JSON.parse(loopProductVariant.designStructureJson));
        } catch (e) {
          // Ignore
        }
      }

      return {
        uid: getProductVariantKey(loopProductVariant),
        mediaInfo: mediaInfoItem,
      };
    });

    this.duration$
      .pipe(
        take(1),
        tap((duration: number) => {
          logEvent(eventType, {
            productCategory: storeProduct.category,
            productId: storeProduct.id,
            productTitle: storeProduct.title,
            productDescription: storeProduct.description,
            shutterstockContentIds,
            clipartContentIds,
            gelatoDesignContentIds,
            addedProductVariantsCount,
            deletedProductVariantsCount,
            createdAt: storeProduct.createdAt,
            updatedAt: storeProduct.updatedAt,
            mediaInfo: JSON.stringify(mediaInfo),
            duration,
          });
        }),
      )
      .subscribe();
  }

  private fetchProductCosts(): Observable<void> {
    return this.storeProductVariantsCollection$.pipe(
      filter(variants => variants && !!Object.keys(variants)?.length),
      withLatestFrom(this.pageCount$),
      take(1),
      mergeMap(([variants, pageCount]) =>
        this.fetchProductCostsByCustomParameters(variants, pageCount).pipe(map(() => undefined)),
      ),
    );
  }

  getDisabledSteps(inputActiveStep: ECommerceProductWizardStep): Observable<ECommerceProductWizardStep[]> {
    if (inputActiveStep === ECommerceProductWizardStep.PRODUCT) {
      return of([
        ECommerceProductWizardStep.DESIGN,
        ECommerceProductWizardStep.MEDIA,
        ECommerceProductWizardStep.DETAILS,
        ECommerceProductWizardStep.CONNECT,
        ECommerceProductWizardStep.PRICES,
      ]);
    }

    return of(inputActiveStep).pipe(
      switchMap((activeStep: ECommerceProductWizardStep) => {
        switch (activeStep) {
          case ECommerceProductWizardStep.DESIGN:
            return combineLatest([
              this.canProceed(activeStep),
              this.isBulkConnectMode$,
              this.bulkConnectProductVariantsHasMappedItems$,
            ]).pipe(
              map(
                ([
                  canProceed,
                  isBulkConnectMode,
                  bulkConnectProductVariantsHasMappedItems,
                ]): ECommerceProductWizardStep[] => {
                  if (!canProceed) {
                    return [
                      ECommerceProductWizardStep.DETAILS,
                      ECommerceProductWizardStep.PRICES,
                      ECommerceProductWizardStep.CONNECT,
                    ];
                  }

                  if (isBulkConnectMode && !bulkConnectProductVariantsHasMappedItems) {
                    return [ECommerceProductWizardStep.PRICES];
                  }

                  return [];
                },
              ),
            );
          case ECommerceProductWizardStep.MEDIA:
            return combineLatest([
              this.canProceed(activeStep),
              this.hasDefinedMedia$,
              this.isBulkConnectMode$,
              this.bulkConnectProductVariantsHasMappedItems$,
            ]).pipe(
              map(
                ([
                  canProceed,
                  hasDefinedMedia,
                  isBulkConnectMode,
                  bulkConnectProductVariantsHasMappedItems,
                ]): ECommerceProductWizardStep[] => {
                  if (!canProceed) {
                    return [
                      ECommerceProductWizardStep.MEDIA,
                      ECommerceProductWizardStep.DETAILS,
                      ECommerceProductWizardStep.PRICES,
                      ECommerceProductWizardStep.CONNECT,
                    ];
                  }

                  if (!hasDefinedMedia) {
                    return [
                      ECommerceProductWizardStep.DETAILS,
                      ECommerceProductWizardStep.PRICES,
                      ECommerceProductWizardStep.CONNECT,
                    ];
                  }

                  if (isBulkConnectMode && !bulkConnectProductVariantsHasMappedItems) {
                    return [ECommerceProductWizardStep.PRICES];
                  }

                  return [];
                },
              ),
            );
          case ECommerceProductWizardStep.DETAILS:
            return this.hasProductVariants$.pipe(
              map((hasProductVariants: boolean): ECommerceProductWizardStep[] =>
                !hasProductVariants ? [ECommerceProductWizardStep.PRICES] : [],
              ),
            );
          case ECommerceProductWizardStep.PRICES:
            return this.isFetchingDesignAssetsCosts$.pipe(
              map((isFetchingDesignAssetsCosts: boolean): ECommerceProductWizardStep[] =>
                isFetchingDesignAssetsCosts
                  ? [
                      ECommerceProductWizardStep.DESIGN,
                      ECommerceProductWizardStep.MEDIA,
                      ECommerceProductWizardStep.DETAILS,
                      ECommerceProductWizardStep.CONNECT,
                    ]
                  : [],
              ),
            );
          case ECommerceProductWizardStep.CONNECT:
            return this.canProceed(activeStep).pipe(
              map((canProceed: boolean): ECommerceProductWizardStep[] =>
                !canProceed ? [ECommerceProductWizardStep.PRICES] : [],
              ),
            );
          default:
            return of([]);
        }
      }),
      map((steps: ECommerceProductWizardStep[]): ECommerceProductWizardStep[] => [
        ECommerceProductWizardStep.PRODUCT,
        ...steps,
      ]),
    );
  }

  canProceed(activeStep: ECommerceProductWizardStep): Observable<boolean> {
    switch (activeStep) {
      case ECommerceProductWizardStep.DESIGN:
        return combineLatest([
          this.isFetchingProductCosts$,
          this.selectedProductVariantKeysCollection$,
          this.storeProductVariantsCollection$,
        ]).pipe(
          map(([fetchingProductCosts, selectedProductVariantKeysCollection, storeProductVariantsCollection]) => {
            if (fetchingProductCosts) {
              return false;
            }

            return this.storeProductVariantsService.validateData(
              selectedProductVariantKeysCollection,
              storeProductVariantsCollection,
            );
          }),
        );
      case ECommerceProductWizardStep.MEDIA:
        return this.hasDefinedMedia$;
      case ECommerceProductWizardStep.CONNECT:
        return this.bulkConnectProductVariantsHasMappedItems$;
      default:
        return of(true);
    }
  }

  loadBulkConnectDestinationProduct() {
    this.destinationProductId$
      .pipe(
        take(1),
        switchMap((id: string) => this.eCommerceProductGraphQlService.getProduct(id)),
        tap((product: EProductWithVariants) => {
          this.setBulkConnectDestinationProduct(product);
        }),
      )
      .subscribe();
  }

  setBulkConnectDestinationProductId(productId: string) {
    this.store.dispatch(eCommerceProductWizardActions.setDestinationProductId({ payload: productId }));
  }

  setBulkConnectDestinationProduct(product: EProductWithVariants) {
    this.store.dispatch(eCommerceProductWizardActions.setDestinationProduct({ payload: product }));
  }

  connectStoreProductVariants() {
    this.pageLeaveConfirmationService.navigateWithoutConfirmation(PRODUCT_WIZARD_ROUTE_VARIANTS_MAPPING);
  }

  clearConnections() {
    this.store.dispatch(eCommerceProductWizardActions.clearBulkConnectProductVariantsMapping());
  }

  resetConnectionsToDefault() {
    this.store.dispatch(eCommerceProductWizardActions.resetBulkConnectProductVariantsMappingToDefault());
  }

  connectProductVariant(destinationProductVariantId: string, sourceProductVariantKey: string) {
    this.store.dispatch(
      eCommerceProductWizardActions.mapBulkConnectProductVariantsPair({
        destinationProductVariantId,
        sourceProductVariantKey,
      }),
    );
  }

  redirectIfNotInitialized() {
    this.isGelatoUser$
      .pipe(
        withLatestFrom(this.selectedClientId$, this.selectedStoreId$, this.productDetails$, this.isTemplateMode$),
        take(1),
        tap(([isGelatoUser, selectedClientId, selectedStoreId, productDetails, isTemplateMode]) => {
          if (!selectedStoreId || (isGelatoUser && !selectedClientId) || !productDetails?.product) {
            this.pageLeaveConfirmationService.navigateWithoutConfirmation(
              this.eCommerceProductWizardBackRouteService.get(
                selectedClientId,
                selectedStoreId,
                isTemplateMode,
                isGelatoUser,
              ),
            );
          }
        }),
      )
      .subscribe();
  }

  loadDefaultBulkConnectProductVariantMapping() {
    this.store.dispatch(eCommerceProductWizardActions.loadBulkConnectDefaultProductVariantMappingStart());
  }

  updateBulkConnectVariantsMapping() {
    this.store.dispatch(eCommerceProductWizardActions.cleanupBulkConnectVariantsMappings());
  }

  fetchStoreCurrencyRate() {
    combineLatest([this.selectedCurrency$, this.storeCurrency$])
      .pipe(
        take(1),
        switchMap(([selectedCurrency, storeCurrency]) => this.currencyRateService.get(storeCurrency, selectedCurrency)),
        tap((storeCurrencyRate: number) => {
          this.storeCurrencyRate$.next(storeCurrencyRate);
        }),
      )
      .subscribe();
  }

  getCurrencyRate(prev, curr): Observable<number> {
    return this.currencyRateService.get(prev, curr).pipe(take(1));
  }

  validateFactory(mode: EProductWizardMode): Observable<boolean> {
    return this.getProductData(false).pipe(
      withLatestFrom(
        this.isBulkConnectMode$,
        this.clientId$,
        this.eStore$,
        this.destinationProductId$,
        this.bulkConnectProductVariantsMappingPayload$,
      ),
      take(1),
      switchMap(
        ([productData, isBulkConnectMode, clientId, eStore, destinationProductId, mapping]): Observable<boolean> => {
          if (isBulkConnectMode) {
            return this.productValidationService.validateBulkConnectProductVariants(
              clientId,
              eStore,
              productData,
              destinationProductId,
              mapping,
              mode,
            );
          } else {
            return this.productValidationService.validateProductData(clientId, eStore, productData, mode);
          }
        },
      ),
    );
  }

  confirmVariantsUpdate(storeProductVariantsCollection: StoreProductVariantsCollection): Observable<boolean> {
    return this.mode$.pipe(
      withLatestFrom(
        this.originalStoreProductVariantsCollection$,
        this.sanityProductCategoryName$,
        this.sanityProduct$,
      ),
      take(1),
      switchMap(
        ([
          mode,
          originalStoreProductVariantsCollection,
          sanityProductCategoryName,
          sanityProduct,
        ]): Observable<boolean> =>
          this.eCommerceProductVariantUpdateConfirmationService.confirm(
            mode,
            originalStoreProductVariantsCollection,
            sanityProductCategoryName as SanityProductCategoryName,
            sanityProduct,
            storeProductVariantsCollection,
          ),
      ),
    );
  }

  private saveBulkConnectProductVariants(
    clientId: string,
    assets: AssetsCollection,
    sourceProductData: EProductData,
    wizardState: ECommerceProductWizardState,
    isInternalStoreType: boolean,
  ): Observable<EProductData> {
    const { destinationProduct, mode, publishWithFreeShipping, publishImmediately } = wizardState;
    const { createPreviews } = wizardState.bulkConnect;
    const mapping: BulkConnectVariantsMappingPayload = wizardState.bulkConnect.customMapping;

    const destinationProductData: EProductData = new EProductData(
      R.omit(['variants', 'productImages'], destinationProduct) as EProduct,
      destinationProduct.variants,
      destinationProduct.productImages,
    );

    this.onProductSavingStart(destinationProductData);

    const sourceProduct: EProductWithVariants =
      this.eCommerceProductDataMappingService.toEProductWithVariants(sourceProductData);

    return this.eCommerceProductGraphQlService
      .bulkConnectProductVariants(clientId, sourceProduct, destinationProduct.id, mapping, createPreviews)
      .pipe(
        take(1),
        catchError(() => of(null)),
        map(
          (savedProduct: EProductWithVariants): EProductData =>
            this.eCommerceProductDataMappingService.fromEProductWithVariants(savedProduct),
        ),
        tap((savedProductData: EProductData) => {
          this.onProductSavingResponse(
            savedProductData,
            destinationProductData,
            assets,
            mode,
            publishWithFreeShipping,
            publishImmediately,
            0,
            0,
            isInternalStoreType,
          );

          if (savedProductData) {
            this.trackBulkConnectCompletedEvent();
          }
        }),
      );
  }

  private validateSavedProductData(storeProductData: EProductData): boolean {
    return Boolean(storeProductData && storeProductData.product && storeProductData.product.id);
  }

  private trackBulkConnectCompletedEvent() {
    this.duration$
      .pipe(
        withLatestFrom(
          this.bulkConnectAutomaticallyConnectedProductVariantsCount$,
          this.bulkConnectManuallyConnectedProductVariantsCount$,
        ),
        take(1),
        tap(([duration, automaticallyConnectedVariantsCount, manuallyConnectedVariantsCount]) => {
          logEvent('bulkConnectCompleted', {
            automaticallyConnectedVariantsCount,
            manuallyConnectedVariantsCount,
            duration,
          });
        }),
      )
      .subscribe();
  }

  showPreviewCreationConfirmationModal() {
    this.confirmModalService
      .open({
        title: this.translateService.instant('txt_bulk_connect_variant_preview_creation_confirmation_modal_title'),
        titleIconUrl: '/assets/icons/breadcrumbs/media.svg',
        body: this.translateService.instant('txt_bulk_connect_variant_preview_creation_confirmation_text'),
        approveButtonText: this.translateService.instant(
          'txt_bulk_connect_variant_preview_creation_confirmation_skip_this_step_option',
        ),
        denyButtonText: this.translateService.instant(
          'txt_bulk_connect_variant_preview_creation_confirmation_edit_mockups_option',
        ),
      })
      .onApprove(() => {
        this.setCreatePreviewsFlag(false);

        this.generatePreviewCollections().pipe(take(1)).subscribe();
        this.connectStoreProductVariants();
      })
      .onDeny(() => {
        this.setCreatePreviewsFlag(true);
      });
  }

  private setCreatePreviewsFlag(createPreviews: boolean) {
    this.store.dispatch(eCommerceProductWizardActions.setBulkConnectCreatePreviewsFlag({ payload: createPreviews }));
  }

  enableFullScreenMode() {
    this.layoutService.enableFullScreenMode();
  }

  disableFullScreenMode() {
    this.layoutService.disableFullScreenMode();
  }

  setupInitialStateForBulkConnect(destinationProductId: string) {
    this.store.dispatch(eCommerceProductWizardActions.setupInitialStateForBulkConnect({ destinationProductId }));
  }

  addUploadedAssetsToEditor() {
    return this.designEditorSharedService.addUploadedAssetsToEditor().pipe(take(1));
  }

  preloadProductVariantPreviews() {
    this.productVariantPreviewsCollection$
      .pipe(
        take(1),
        tap((productVariantPreviewsCollection: StoreProductVariantPreviewsCollection) => {
          Object.values(productVariantPreviewsCollection).forEach((previewUrl: string) => {
            if (!previewUrl) {
              return;
            }

            loadImage(previewUrl);
          });
        }),
      )
      .subscribe();
  }

  confirmTemplateSaving() {
    this.productTemplateName$
      .pipe(
        withLatestFrom(this.productTitle$),
        take(1),
        tap(([productTemplateName, productTitle]) => {
          if (productTemplateName) {
            this.saveTemplate(productTemplateName);
          } else {
            this.eCommerceProductTemplateNameModalService
              .openTemplateCreationModal(productTitle)
              .onApprove((templateName: string) => {
                this.saveTemplate(templateName);
              });
          }
        }),
      )
      .subscribe();
  }

  confirmNewTemplateCreation() {
    this.productTitle$
      .pipe(
        take(1),
        tap((productTitle: string) => {
          this.eCommerceProductTemplateNameModalService
            .openTemplateCreationModal(productTitle)
            .onApprove((templateName: string) => {
              this.createNewTemplate(templateName);
            });
        }),
      )
      .subscribe();
  }

  private setProduct(productName: string) {
    combineLatest([
      this.product$,
      this.selectedPreviewFileType$,
      this.primaryPreviewScene$,
      this.sanityProductCategory$,
      this.sanityProduct$,
      this.eStore$,
    ])
      .pipe(
        filter(
          ([, , , sanityProductCategory, sanityProduct]) =>
            Boolean(sanityProduct) && Boolean(sanityProductCategory) && sanityProduct?.id === productName,
        ),
        map(
          ([
            sourceStoreProduct,
            selectedPreviewFileType,
            primaryPreviewScene,
            sanityProductCategory,
            sanityProduct,
            store,
          ]) =>
            this.storeProductVariantsService.getStoreProduct(
              sourceStoreProduct,
              selectedPreviewFileType,
              {},
              null,
              primaryPreviewScene,
              sanityProductCategory,
              sanityProduct,
              store,
            ),
        ),
        take(1),
        tap((storeProduct: EProduct) => {
          this.store.dispatch(new eCommerceProductWizardActions.SetProduct(storeProduct));
        }),
      )
      .subscribe();
  }

  private resetFailedDraftAutoSaveAttemptsCount() {
    this.failedDraftAutoSaveAttemptsCount$.next(0);
  }

  private increaseFailedDraftAutoSaveAttemptsCount() {
    const failedDraftAutoSaveAttemptsCount = this.failedDraftAutoSaveAttemptsCount$.getValue();

    this.failedDraftAutoSaveAttemptsCount$.next(failedDraftAutoSaveAttemptsCount + 1);
  }

  private updateFailedDraftAutoSaveAttemptsCount(productData: EProductData) {
    this.productId$
      .pipe(
        take(1),
        tap((productId: string) => {
          const productIdFromLastSaveResponse = !productData?.product?.id;

          if (!productIdFromLastSaveResponse && !productId) {
            this.increaseFailedDraftAutoSaveAttemptsCount();
          } else {
            this.resetFailedDraftAutoSaveAttemptsCount();
          }
        }),
      )
      .subscribe();
  }

  private saveTemplate(templateName: string) {
    this.store.dispatch(eCommerceProductWizardActions.saveProductTemplateStart());
    this.getProductData(true)
      .pipe(
        take(1),
        map(
          (productData: EProductData): EProductWithVariants =>
            this.eCommerceProductDataMappingService.toEProductWithVariants(productData),
        ),
        withLatestFrom(this.selectedClientId$, this.sanityProductName$),
        switchMap(
          ([product, clientId, sanityProductName]): Observable<EProductWithVariants> =>
            this.eCommerceProductTemplatesGraphQlService.saveProductTemplate(
              clientId,
              sanityProductName,
              templateName,
              product,
            ),
        ),
        catchError(() => {
          this.store.dispatch(eCommerceProductWizardActions.saveProductTemplateFailure());

          return of(null);
        }),
        tap(() => {
          this.pageLeaveConfirmationService.navigateWithoutConfirmation(['/templates/list']);
        }),
        filter(Boolean),
        tap(() => {
          this.store.dispatch(eCommerceProductWizardActions.saveProductTemplateSuccess());
        }),
      )
      .subscribe();
  }

  private createNewTemplate(templateName: string) {
    this.store.dispatch(eCommerceProductWizardActions.createProductTemplateStart());
    this.getProductData(true)
      .pipe(
        take(1),
        map(
          (productData: EProductData): EProductWithVariants =>
            this.eCommerceProductDataMappingService.toEProductWithVariants(productData),
        ),
        withLatestFrom(this.selectedClientId$, this.sanityProductName$),
        switchMap(
          ([product, clientId, sanityProductName]): Observable<EProductWithVariants> =>
            this.eCommerceProductTemplatesGraphQlService.createProductTemplate(
              clientId,
              sanityProductName,
              templateName,
              product,
            ),
        ),
        take(1),
        catchError(() => {
          this.store.dispatch(eCommerceProductWizardActions.createProductTemplateFailure());

          return of(null);
        }),
        filter(Boolean),
        tap(() => {
          this.store.dispatch(eCommerceProductWizardActions.createProductTemplateSuccess());
        }),
      )
      .subscribe();
  }
}
