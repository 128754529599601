import { ProductTypeUid } from './product-type-uid.enum';

export class ProductCatalogueCreateRequest {
  constructor(
    public title: string,
    public alias: string,
    public clientId: string,
    public rootNodeId: string,
    public isPublic: boolean,
    public productTypeUid: ProductTypeUid,
  ) {}
}
