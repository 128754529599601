import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { EStoreFeature } from '@gelato-api-ui/core/e-commerce/e-store-feature.enum';
import { stripTags } from '@gelato-api-ui/core/dom/helpers/stripTags';
import { ECommerceStoreFeaturesService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-store-features.service';

@Injectable({
  providedIn: 'root',
})
export class DesignPersonalizationEmailFeatureDescriptionService {
  constructor(
    private readonly translateService: TranslateService,
    private readonly eCommerceStoreFeaturesService: ECommerceStoreFeaturesService,
  ) {}

  getUpdatedProductDescription(
    productDescription: string,
    eStoreType: EStoreType,
    hasProductsWithCustomizationFlag: boolean,
    useHtmlMarkup: boolean,
  ): string {
    if (!this.isFeatureSupported(eStoreType)) {
      return productDescription;
    }

    if (this.shouldAddFeatureDescription(productDescription, hasProductsWithCustomizationFlag)) {
      return this.addFeatureDescription(productDescription, useHtmlMarkup);
    } else if (this.shouldRemoveFeatureDescription(productDescription, hasProductsWithCustomizationFlag)) {
      return this.removeFeatureDescription(productDescription, useHtmlMarkup);
    }

    return productDescription;
  }

  private shouldAddFeatureDescription(productDescription: string, hasProductsWithCustomizationFlag: boolean): boolean {
    return hasProductsWithCustomizationFlag && !this.isFeatureDescriptionPresent(productDescription);
  }

  private shouldRemoveFeatureDescription(
    productDescription: string,
    hasProductsWithCustomizationFlag: boolean,
  ): boolean {
    return !hasProductsWithCustomizationFlag && this.isFeatureDescriptionPresent(productDescription);
  }

  private addFeatureDescription(productDescription: string, useHtmlMarkup: boolean): string {
    const featureDescription = this.getFeatureDescription();
    const newLineSeparator = this.getNewLineSeparator(useHtmlMarkup);
    let newProductDescription = productDescription;

    if (productDescription) {
      newProductDescription += newLineSeparator;
    }

    newProductDescription += `<p>${featureDescription}</p>`;

    if (!useHtmlMarkup) {
      newProductDescription = stripTags(newProductDescription);
    }

    return newProductDescription;
  }

  private removeFeatureDescription(productDescription: string, useHtmlMarkup: boolean): string {
    const featureDescription = this.getFeatureDescription();
    const newLineSeparator = this.getNewLineSeparator(useHtmlMarkup);

    return productDescription
      .replace(`${newLineSeparator}<p>${featureDescription}</p>`, '')
      .replace(`${newLineSeparator}<p>${stripTags(featureDescription)}</p>`, '')
      .replace(`${newLineSeparator}${featureDescription}`, '')
      .replace(`${newLineSeparator}${stripTags(featureDescription)}`, '')
      .replace(`<p>${featureDescription}</p>`, '')
      .replace(`<p>${stripTags(featureDescription)}</p>`, '')
      .replace(featureDescription, '')
      .replace(stripTags(featureDescription), '');
  }

  private getFeatureDescription() {
    return this.translateService.instant('txt_design_personalization_email_description');
  }

  private getNewLineSeparator(useHtmlMarkup: boolean): string {
    return useHtmlMarkup ? '<br>' : '\n\n';
  }

  private isFeatureSupported(eStoreType: EStoreType): boolean {
    return this.eCommerceStoreFeaturesService.isFeatureSupported(
      eStoreType,
      EStoreFeature.ADVANCED_DESIGN_PERSONALIZATION_EMAIL,
    );
  }

  private isFeatureDescriptionPresent(productDescription: string) {
    const featureDescription = this.getFeatureDescription();

    return (
      productDescription.includes(featureDescription) || productDescription.includes(stripTags(featureDescription))
    );
  }
}
