import { SanitySizeGuideTable } from '../sanity-size-guide-table';
import { SanitySizeGuideRow } from '../sanity-size-guide-row';

const getFormattedSizeGuideTable = (table: SanitySizeGuideTable, firstColumnSuffix: string): string => {
  if (!table || !table.rows || !table.rows.length) {
    return '';
  }

  let tableHtml = '<table>\n';

  table.rows.forEach((row: SanitySizeGuideRow, rowIndex: number) => {
    tableHtml += '<tr>\n';

    row.cells.forEach((cell: string, cellIndex: number) => {
      const isFirstRow = !rowIndex;
      const isFirstColumn = !cellIndex;

      if (isFirstRow && isFirstColumn) {
        tableHtml += `<td>&nbsp;</td>\n`;
      } else if (isFirstColumn) {
        tableHtml += `<td><strong>${cell} ${firstColumnSuffix}</strong></td>\n`;
      } else if (isFirstRow) {
        tableHtml += `<td><strong>${cell}</strong></td>\n`;
      } else {
        tableHtml += `<td>${cell}</td>\n`;
      }
    });

    tableHtml += '</tr>\n';
  });

  tableHtml += '</table>\n';

  return tableHtml;
};

export const getFormattedSizeGuideFromSanityProduct = (table: SanitySizeGuideTable, columnSuffix: string): string => {
  if (!table || !table.rows || !table.rows.length) {
    return '';
  }

  let sizeGuideTablesHtml = '';

  if (sizeGuideTablesHtml) {
    sizeGuideTablesHtml += '\n<br>\n';
  }

  sizeGuideTablesHtml += `${getFormattedSizeGuideTable(table, columnSuffix)}\n`;

  if (!sizeGuideTablesHtml) {
    return '';
  }

  return sizeGuideTablesHtml;
};
