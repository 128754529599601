import { NgModule } from '@angular/core';
import { GelatoTranslateParser } from '@gelato-api-ui/sdk/src/lib/translations/gelato-translate-parser';
import { TranslationLoaderFactory } from '@gelato-api-ui/sdk/src/lib/translations/translate-loader-factory';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { I18nService } from './i18n.service';
import { I18N_DEFAULT_PARAMS } from './i18n.token';

@NgModule({
  providers: [
    I18nService,
    {
      provide: I18N_DEFAULT_PARAMS,
      useValue: {},
      multi: true,
    },
  ],
})
export class I18nModule {
  static forRoot() {
    return TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [I18nService],
      },
      parser: {
        provide: TranslateParser,
        useClass: GelatoTranslateParser,
      },
    });
  }

  static forChild() {
    return TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslationLoaderFactory,
        deps: [I18nService],
      },
      parser: {
        provide: TranslateParser,
        useClass: GelatoTranslateParser,
      },
    });
  }
}
