import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { PricePlanSubscriptionsApiService } from '../price-plan-subscriptions/services/price-plan-subscriptions-api.service';
import { PricePlanSubscriptionSearchResponse } from '../price-plan-subscriptions/lib/price-plan-subscription-search-response';
import { PricePlanSubscription } from '../price-plan-subscriptions/lib/price-plan-subscription';
import * as actions from './price-plan-subscriptions.actions';
import { getPricePlanSubscriptionListState, getState } from './price-plan-subscriptions.reducer';
import { findPricePlanSubscriptionInState } from './helpers/findPricePlanSubscriptionInState';
import { LoadClientsByIds } from '@api-ui-app/src/app/ngrx/client-selection-list.actions';

@Injectable()
export class PricePlanSubscriptionsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private pricePlanSubscriptionsApiService: PricePlanSubscriptionsApiService,
  ) {}

  loadPricePlanSubscriptionList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadPricePlanSubscriptionList>(
          actions.PricePlanSubscriptionsActionTypes.LoadPricePlanSubscriptionList,
        ),
        withLatestFrom(this.store$.select(getPricePlanSubscriptionListState)),
        switchMap(([action, pricePlanSubscriptionListState]) => {
          const { searchRequest, forced } = action;
          const isDataRelevant =
            R.equals(searchRequest, pricePlanSubscriptionListState.request) && pricePlanSubscriptionListState.payload;

          let observable: Observable<PricePlanSubscriptionSearchResponse> =
            this.pricePlanSubscriptionsApiService.search(searchRequest);

          if (!forced && isDataRelevant) {
            observable = of({ ...pricePlanSubscriptionListState.payload });
          }

          this.store$.dispatch(
            new actions.SetPricePlanSubscriptionListState({
              isLoading: true,
              request: searchRequest,
              payload: isDataRelevant ? pricePlanSubscriptionListState.payload : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<PricePlanSubscriptionSearchResponse> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: PricePlanSubscriptionSearchResponse) => {
              this.store$.dispatch(
                new actions.SetPricePlanSubscriptionListState({
                  isLoading: false,
                  request: searchRequest,
                  payload: response,
                }),
              );
              if (response && response.data) {
                const ids = response.data.map(_d => _d.clientId);
                this.store$.dispatch(new LoadClientsByIds(ids));
              }
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadPricePlanSubscription$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadPricePlanSubscription>(actions.PricePlanSubscriptionsActionTypes.LoadPricePlanSubscription),
        withLatestFrom(this.store$.select(getState)),
        switchMap(([action, state]) => {
          const { id } = action;
          const { forced } = action;
          const pricePlanSubscriptionFromState: PricePlanSubscription = findPricePlanSubscriptionInState(state, id);
          const isDataRelevant = Boolean(pricePlanSubscriptionFromState);

          let observable: Observable<PricePlanSubscription> = this.pricePlanSubscriptionsApiService.get(id);

          if (!forced && isDataRelevant) {
            observable = of({ ...pricePlanSubscriptionFromState });
          }

          this.store$.dispatch(
            new actions.SetPricePlanSubscriptionState({
              isLoading: true,
              payload: isDataRelevant ? pricePlanSubscriptionFromState : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<PricePlanSubscription> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((pricePlanSubscription: PricePlanSubscription) => {
              this.store$.dispatch(
                new actions.SetPricePlanSubscriptionState({
                  isLoading: false,
                  payload: pricePlanSubscription,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
