import { AnalyticsGraphName } from '../types/analytics-graph-name.enum';
import { AnalyticsGraphGroupType } from '../types/analytics-graph-group-type.enum';
import { AnalyticsGraphRequest } from '../types/analytics-graph-request';
import { AnalyticsGraphResponse } from '../types/analytics-graph-response';
import * as actions from './analytics.actions';
import { getGraphGroupTypeKey } from './analytics.selector';

export interface AnalyticsGraphState {
  isLoading: boolean;
  request: AnalyticsGraphRequest;
  payload: AnalyticsGraphResponse;
}

export interface State {
  startDate: string;
  endDate: string;
  savedShippingCostRangeStartDate: string;
  savedShippingCostRangeEndDate: string;
  savedShippingCost: number;
  [AnalyticsGraphName.SUMMARY]: {
    selectedGraphGroupType: AnalyticsGraphGroupType;
    total: AnalyticsGraphState;
  };
  [AnalyticsGraphName.ORDERS_BY_COUNTRY]: {
    selectedGraphGroupType: AnalyticsGraphGroupType;
    total: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_DAY]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_WEEK]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_MONTH]: AnalyticsGraphState;
  };
  [AnalyticsGraphName.ORDERS_BY_PRODUCT]: {
    selectedGraphGroupType: AnalyticsGraphGroupType;
    total: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_DAY]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_WEEK]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_MONTH]: AnalyticsGraphState;
  };
  [AnalyticsGraphName.PRINT_COST]: {
    selectedGraphGroupType: AnalyticsGraphGroupType;
    [AnalyticsGraphGroupType.BY_DAY]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_WEEK]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_MONTH]: AnalyticsGraphState;
  };
  [AnalyticsGraphName.SHIPPING_COST]: {
    selectedGraphGroupType: AnalyticsGraphGroupType;
    [AnalyticsGraphGroupType.BY_DAY]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_WEEK]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_MONTH]: AnalyticsGraphState;
  };
}

export const emptyGraphState = {
  isLoading: false,
  request: null,
  payload: null,
};

export const initialState: State = {
  startDate: null,
  endDate: null,
  savedShippingCostRangeStartDate: null,
  savedShippingCostRangeEndDate: null,
  savedShippingCost: null,
  [AnalyticsGraphName.SUMMARY]: {
    selectedGraphGroupType: AnalyticsGraphGroupType.NONE,
    total: { ...emptyGraphState },
  },
  [AnalyticsGraphName.ORDERS_BY_COUNTRY]: {
    selectedGraphGroupType: AnalyticsGraphGroupType.BY_DAY,
    total: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_DAY]: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_WEEK]: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_MONTH]: { ...emptyGraphState },
  },
  [AnalyticsGraphName.ORDERS_BY_PRODUCT]: {
    selectedGraphGroupType: AnalyticsGraphGroupType.BY_DAY,
    total: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_DAY]: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_WEEK]: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_MONTH]: { ...emptyGraphState },
  },
  [AnalyticsGraphName.PRINT_COST]: {
    selectedGraphGroupType: AnalyticsGraphGroupType.BY_DAY,
    [AnalyticsGraphGroupType.BY_DAY]: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_WEEK]: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_MONTH]: { ...emptyGraphState },
  },
  [AnalyticsGraphName.SHIPPING_COST]: {
    selectedGraphGroupType: AnalyticsGraphGroupType.BY_DAY,
    [AnalyticsGraphGroupType.BY_DAY]: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_WEEK]: { ...emptyGraphState },
    [AnalyticsGraphGroupType.BY_MONTH]: { ...emptyGraphState },
  },
};

const setGraphState = (
  state: State,
  graphName: AnalyticsGraphName,
  graphGroupType: AnalyticsGraphGroupType,
  graphState: AnalyticsGraphState,
) => {
  try {
    const graphGroupTypeKey = getGraphGroupTypeKey(graphGroupType);

    return {
      ...state,
      [graphName]: {
        ...state[graphName],
        [graphGroupTypeKey]: {
          ...state[graphName][graphGroupTypeKey],
          ...graphState,
        },
      },
    };
  } catch (e) {
    return state;
  }
};

const setSelectedGraphGroupType = (
  state: State,
  graphName: AnalyticsGraphName,
  graphGroupType: AnalyticsGraphGroupType,
) => {
  try {
    const graphGroupTypeKey = getGraphGroupTypeKey(graphGroupType);

    return {
      ...state,
      [graphName]: {
        ...state[graphName],
        selectedGraphGroupType: graphGroupType,
      },
    };
  } catch (e) {
    return state;
  }
};

export function reducer(state = initialState, action): State {
  switch (action.type) {
    case actions.AnalyticsActionTypes.SetDateRange: {
      const { startDate, endDate } = action;

      return {
        ...state,
        startDate,
        endDate,
      };
    }
    case actions.AnalyticsActionTypes.SetSavedShippingCost: {
      const value = action.value;

      return {
        ...state,
        savedShippingCost: value,
      };
    }

    case actions.AnalyticsActionTypes.SetSavedShippingCostDateRange: {
      const { startDate, endDate } = action;

      return {
        ...state,
        savedShippingCostRangeStartDate: startDate,
        savedShippingCostRangeEndDate: endDate,
      };
    }

    case actions.AnalyticsActionTypes.ResetGraphState: {
      const { graphName, graphGroupType } = action;

      return setGraphState(state, graphName, graphGroupType, initialState[graphName][graphGroupType]);
    }
    case actions.AnalyticsActionTypes.SetGraphState: {
      const { graphName, graphGroupType, graphState } = action;

      return setGraphState(state, graphName, graphGroupType, graphState);
    }
    case actions.AnalyticsActionTypes.SetSelectedGraphGroupType: {
      const { graphName, graphGroupType } = action;

      return setSelectedGraphGroupType(state, graphName, graphGroupType);
    }
    default:
      return state;
  }
}
