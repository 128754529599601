import { AnimationEvent } from '@angular/animations';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  Type,
} from '@angular/core';
import { BehaviorSubject, combineLatest, Subject } from 'rxjs';
import { DeckAnimationType, DeckContentType, DeckRenderType } from '../../types';
import { DeckRef } from './deck-ref';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { deckAnimation } from './../../animations';

@Component({
  selector: 'gc-deck-ref',
  templateUrl: './deck-ref.component.html',
  styleUrls: ['./deck-ref.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [deckAnimation()],
})
export class DeckRefComponent<T> implements OnInit, OnDestroy {
  public renderType: DeckRenderType = 'template';
  public content: DeckContentType;
  public data: T;
  private readonly ngDestroy$ = new Subject<boolean>();
  private readonly animComplete$ = new BehaviorSubject<boolean>(false);

  @HostBinding('@deck') public state: DeckAnimationType = 'open';

  @HostListener('@deck.done', ['$event']) public doneAnimHandler(event: AnimationEvent): void {
    if (event && event.fromState === 'open' && event.toState === 'close') {
      this.animComplete$.next(true);
      this.animComplete$.complete();
    }
  }

  constructor(private readonly deckRef: DeckRef) {}

  public ngOnInit(): void {
    combineLatest([this.deckRef.close$, this.animComplete$])
      .pipe(
        tap(([_, __]) => (this.state = 'close')),
        filter(([_, isAnimComplete]) => !!isAnimComplete),
        takeUntil(this.ngDestroy$),
      )
      .subscribe(() => this.deckRef.close());

    this.content = this.deckRef.content;

    if (this.content instanceof TemplateRef) {
      this.renderType = 'template';
      this.data = this.deckRef.data;
    }

    if (this.content instanceof Type) {
      this.renderType = 'component';
    }
  }

  public ngOnDestroy(): void {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
