import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { PreviewFileType } from '../preview-file-type.enum';
import { Scene } from '../scene';
import { PreflightProductPreviewRequest } from '../preflight-product-preview-request';
import { PreflightApiService } from './preflight-api.service';
import { DesignPreviewService } from './design-preview.service';
import { PrintSide } from '@gelato-api-ui/core/print-sides/print-side.enum';
import { PrintAreaService } from '@gelato-api-ui/core/designs/services/print-area.service';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { PreflightGraphQlService } from '@gelato-api-ui/core/preflight/services/preflight-graph-ql.service';
import { DesignProductPreviewUrlParams } from '@gelato-api-ui/core/preflight/types/design-product-preview-url-params';
import { CachedRequestsService } from '@gelato-api-ui/core/api/services/cached-requests.service';

@Injectable({ providedIn: 'root' })
export class DesignProductPreviewService {
  constructor(
    private readonly preflightApiService: PreflightApiService,
    private readonly designPreviewService: DesignPreviewService,
    private readonly printAreaService: PrintAreaService,
    private readonly preflightGraphQlService: PreflightGraphQlService,
    private readonly cachedRequestsService: CachedRequestsService,
  ) {}

  getUrl(
    productUid: string,
    designStructure: DesignStructure,
    previewScene: string,
    previewFileType: PreviewFileType,
    designPreviewFileType: PreviewFileType,
    width: number,
    height: number,
    removeSamples: boolean,
    forceDimensions: boolean,
    flatPreview?: string,
  ): Observable<string> {
    return this.getPreviewRequest(
      productUid,
      designStructure,
      previewScene,
      previewFileType,
      designPreviewFileType,
      width,
      height,
      removeSamples,
      forceDimensions,
      flatPreview,
    ).pipe(
      first(),
      map(request => {
        if (!request) {
          return null;
        }
        return this.preflightApiService.getProductPreviewUrlOld(request);
      }),
    );
  }

  private getAvailablePrintSidesForFlatPreview(productUid: string): string[] {
    if (productUid.includes(PrintSide.Back)) {
      return ['back'];
    }

    return ['front'];
  }

  private getPagePreviewUrlParams(
    designStructure: DesignStructure,
    previewScene: string,
    designPreviewFileType: PreviewFileType,
    width: number,
    height: number,
    removeSamples: boolean,
    flatPreview: string,
    productUid: string,
  ): Observable<DesignProductPreviewUrlParams> {
    if (this.printAreaService.isApparelScene(previewScene as Scene)) {
      const expectedSpreadName = this.printAreaService.getExpectedSpreadNameByScene(previewScene as Scene);

      const sides = this.getAvailablePrintSidesForFlatPreview(productUid);

      if (!designStructure && flatPreview && !sides.includes(expectedSpreadName)) {
        return of(null);
      }
    }

    if (designStructure) {
      const requestData = {
        service: 'DesignProductPreviewService',
        productUid,
        designStructure,
        designPreviewFileType,
        width,
        height,
        removeSamples,
      };

      return this.cachedRequestsService.get(requestData, () =>
        this.preflightGraphQlService.fetchDesignProductPreviewUrlParams(
          productUid,
          designStructure,
          designPreviewFileType,
          width,
          height,
          removeSamples,
        ),
      );
    } else {
      return of({ imageUrl: flatPreview });
    }
  }

  getPreviewRequest(
    productUid: string,
    designStructure: DesignStructure,
    previewScene: string,
    previewFileType: PreviewFileType,
    designPreviewFileType: PreviewFileType,
    width: number,
    height: number,
    removeSamples: boolean,
    forceDimensions: boolean,
    flatPreview?: string,
  ): Observable<PreflightProductPreviewRequest> {
    return this.getPagePreviewUrlParams(
      designStructure,
      previewScene,
      designPreviewFileType,
      width,
      height,
      removeSamples,
      flatPreview,
      productUid,
    ).pipe(
      map(designPreviewUrlParams => {
        if (!designPreviewUrlParams) {
          return null;
        }

        const request: PreflightProductPreviewRequest = {
          product_uid: productUid,
          force_dimensions: forceDimensions,
          width,
          height,
        };

        if (previewScene) {
          request.scene = previewScene;
        }

        if (previewFileType) {
          request.format = previewFileType;
        }

        if (designPreviewUrlParams.imagesUrls) {
          request.images_urls = JSON.stringify(designPreviewUrlParams.imagesUrls);
        } else if (designPreviewUrlParams.imageUrl) {
          request.image_url = designPreviewUrlParams.imageUrl;
        }

        return request;
      }),
    );
  }
}
