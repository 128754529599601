import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { debounce, tap } from 'rxjs/operators';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { ROUTER_CANCEL, ROUTER_ERROR } from '@ngrx/router-store';
import { updateLocale } from '@gelato-api-ui/sdk/src/lib/sdk.actions';
import { TranslateService } from '@ngx-translate/core';
import { I18N_STORAGE } from '@gelato-api-ui/sdk/src/lib/translations/i18n.service';
import { StorageService } from '@gelato-api-ui/sdk/src/lib/storage/storage.service';
import { DOCUMENT } from '@angular/common';

@Injectable()
export class AppEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly storage: StorageService,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  @Effect({ dispatch: false })
  redirectToRoot$ = this.actions$.pipe(
    ofType(ROUTER_ERROR, ROUTER_CANCEL),
    debounce(() => interval(1000)),
    tap(() => {
      if (!this.router.routerState.snapshot.url) {
        this.router.navigate(['/']).then();
      }
    }),
  );

  updateLocale$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateLocale),
        tap(({ locale }) => {
          this.translateService.use(locale);
          this.document.documentElement.lang = locale.split('_')[0];
          this.storage.setItem(I18N_STORAGE, locale);
        }),
      ),
    { dispatch: false },
  );
}
