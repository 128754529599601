<div
  class="ui radio checkbox"
  [attr.name]="name"
  [attr.value]="value"
  [class.checked]="checked"
  [class.disabled]="disabled"
  (click)="toggleChecked($event)"
>
  <input type="checkbox" class="hidden" [checked]="checked" />
  <label><ng-content></ng-content></label>
</div>
