/**
 * https://gelato.atlassian.net/wiki/spaces/GAPI/pages/5252579583/2.2.4.1.8+Services
 */
import { TimePeriodUnit } from '@gelato-api-ui/core/time-period/time-period-unit.enum';

export interface ServicePlanPriceSearchRequest {
  ids: string[];
  clientId: string;
  serviceIds: string[];
  serviceUniqueNames: string[];
  currencies: string[];
  statuses: ServicePlanPriceStatus[];
  offset: number;
  limit: number;
}

export interface ServicePlanPriceSearchResponse {
  prices: ServicePlanPrice[];
}

export interface ServicePlanPrice {
  id: string;
  uniqueName: string;
  serviceId: string;
  serviceUniqueName: string;
  clientId: string;
  title: string;
  periodUnit: TimePeriodUnit;
  description: string;
  status: ServicePlanPriceStatus;
  createdAt: string;
  updatedAt: string;
  currency: string;
  period: number;
  price: number;
  trialPeriod: number;
  trialPeriodUnit: TimePeriodUnit;
  servicePlanId: string;
  servicePlanUniqueName: string;
}

export class ServicePricePlanTaxResponse {
  billingTag: string;
  price: number;
  priceInclVat: number;
  priceVat: number;
  taxTransactionId: string;
  vatRate: number;
}

export interface ServicePricePlanTax extends ServicePricePlanTaxResponse {
  isLoading: boolean;
  error: any;
}

export enum ServicePlanPriceStatus {
  active = 'active',
  archived = 'archived',
  deleted = 'deleted',
}

export enum ServiceUniqueName {
  GELATO_PLATFORM = 'gelato-platform',
  APP_EXPAND_CATEGORIES = 'app-expand-categories',
  APP_E_COMMERCE = 'app-e-commerce',
}

export const DEFAULT_SERVICE_PLAN_PRICE_CURRENCY = 'USD';
export const DEFAULT_BILLING_PERIOD_UNIT = TimePeriodUnit.MONTH;
