import { OrderRefundStatus } from './order-refund-status.enum';

export class OrderRefund {
  constructor(
    public id: string,
    public orderId: string,
    public clientId: string,
    public currency: string,
    public status: OrderRefundStatus,

    public productsRefundAmount: number,
    public productsRefundVat: number,
    public productsRefundIncVatAmount: number,

    public shipmentRefundAmount: number,
    public shipmentRefundVat: number,
    public shipmentRefundIncVatAmount: number,

    public createdAt: number,
    public updatedAt: number,
  ) {}
}
