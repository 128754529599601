<div
  [class]="'plus-wrapper ' + colorType"
  [ngClass]="{ clickable: performActionOnClick, 'shrink-mobile': shrinkOnMobile }"
  gcTooltip
  [gcTooltipText]="tooltipText | translate"
  [gcTooltipDisabled]="!performActionOnClick"
  [gcTooltipTheme]="'dark'"
  [gcTooltipShowArrow]="false"
  [gcTooltipPosition]="gcTooltipPosition.bottomCenter"
  [gcTooltipDestroyDebounceTime]="0"
  (click)="onLabelClick()"
>
  <img [src]="imgName | assetsPath | async" width="20" />
  <span class="text" [class.bold]="isBold">{{ plusLabelText | translate }}</span>
</div>
