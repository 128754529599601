export enum EditorOutboundEventType {
  ready = 'editor.ready',
  customSidebarTabActive = 'editor.customSidebarTabActive',
  customSidebarTabClosed = 'editor.customSidebarTabClosed',

  tabChanged = 'log.tabChanged',
  trackEvent = 'event.trackEvent',

  setDesignData = 'design.setDesignData',
  structureChanged = 'design.structureChanged',
  switchToProduct = 'design.switchToProduct',
  productSwitched = 'design.productSwitched',

  uploadOpen = 'uploader.open',
  retryUpload = 'uploader.retryUpload',
  retryAll = 'uploader.retryAll',
  removeFile = 'uploader.removeFile',
  assetUploaded = 'uploader.assetUploaded',

  requestTemplates = 'templates.requestTemplates',
  requestTemplate = 'templates.requestTemplate',

  loadPreviews = 'preview.loadPreviews',

  variationGroups = 'variants.variationGroups',
  setPageCount = 'variants.setPageCount',
  requestDesignTemplates = 'variants.requestDesignTemplates',

  requestAddons = 'addons.requestAddons',
  requestPluginData = 'plugins.requestData',
  uploadAddon = 'addons.uploadAddon',
  uploadAsset = 'plugins.uploadAsset',

  addImage = 'log.addImage',
  imageReplaced = 'log.imageReplaced',

  getAssetUrlWithReplacedColors = 'asset.getAssetUrlWithReplacedColors',

  disabledPluginClick = 'plugins.disabledPluginClick',
  pluginOpened = 'plugins.pluginOpened',
  premiumFontSelected = 'fonts.premiumFontSelected',
  subscribeGelatoPlusButtonClick = 'plugins.subscribeClick',
  gelatoPlusClick = 'plugins.gelatoPlusClick',
  removeBackground = 'plugins.removeBackground',
  restoreBackground = 'plugins.restoreBackground',

  getFolderContent = 'advancedPersonalisation.getFolderContent',

  logProductSizeAdded = 'log.productSizeAdded',
  logProductSizeSelected = 'log.productSizeSelected',

  openHelperCenter = 'help.openHelperCenter',
  giveFeedback = 'help.giveFeedback',
  openChatBot = 'help.openChat',
}
