import { Injectable } from '@angular/core';
import { PREVIEWS_SIZE_TO_DOWNLOAD } from '@api-ui-app/src/app/previews-archive/lib/preview-archive';
import { timer } from 'rxjs';
import { filter, map, switchMap, take, takeWhile, withLatestFrom } from 'rxjs/operators';
import { PreflightPreviewsArchiveRequest } from '@gelato-api-ui/core/preflight/preflight-previews-download';
import { PreviewsArchiveFacade } from '@api-ui-app/src/app/previews-archive/+state/previews-archive.facade';
import { PreflightApiService } from '@gelato-api-ui/core/preflight/services/preflight-api.service';
import { MINUTES_TO_MILLISECONDS } from '@api-ui-app/src/app/lib/constants';
import { downloadFileFromUrl } from '@api-ui-app/src/app/lib/downloadFileFromUrl';
import { BeforeUnloadEventWarningService } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/before-unload-event-warning.service';
import { PreflightTaskState } from '@gelato-api-ui/core/preflight/preflight-task-state.enum';
import { setFeatureUsed } from '../../subscriptions/lib/helpers/visited-apps-local-storage-manager';
import { PremiumIconCrownedFeatures } from '../../subscriptions/types/icon-crowned-features.enum';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { PreviewsArchiveRequestService } from '@api-ui-app/src/app/previews-archive/services/previews-archive-request.service';

@Injectable()
export class PreviewsArchiveService {
  private readonly TEN_MINUTES = 10 * MINUTES_TO_MILLISECONDS;
  private readonly CHECK_TIMER_VALUE = 3000;
  private readonly CHECK_RETRIES_MAX = this.TEN_MINUTES / this.CHECK_TIMER_VALUE;

  constructor(
    private readonly facade: PreviewsArchiveFacade,
    private readonly preflightApiService: PreflightApiService,
    private readonly beforeUnloadEventWarningService: BeforeUnloadEventWarningService,
    private readonly previewsArchiveRequestService: PreviewsArchiveRequestService,
  ) {}

  requestDownloadPreviews(
    sanityProductCategoryName: SanityProductCategoryName,
    productVariants: EProductVariant[],
    previewScenes: string[],
    selectedPreviewFileType: string,
    previewSize: number = PREVIEWS_SIZE_TO_DOWNLOAD,
  ) {
    return this.previewsArchiveRequestService
      .get(sanityProductCategoryName, productVariants, previewScenes, selectedPreviewFileType, previewSize)
      .pipe(
        take(1),
        map((request: PreflightPreviewsArchiveRequest) => {
          setFeatureUsed(PremiumIconCrownedFeatures.MOCKUPS_DOWNLOAD);

          const previewsCount = request?.products?.length;

          this.facade.trackPreviewsToDownload(previewsCount);

          return this.facade.requestPreviewsArchive(request, previewsCount);
        }),
      );
  }

  setTimerToCheckTaskStatus(taskId: string) {
    if (!taskId) {
      return;
    }

    timer(0, this.CHECK_TIMER_VALUE)
      .pipe(
        withLatestFrom(this.facade.previewsArchiveIsLoading$, this.facade.previewsArchiveTasks$),
        takeWhile(([, isLoading, taskIds]) => {
          const taskIsValid = taskIds.includes(taskId);
          return isLoading && Boolean(taskIsValid);
        }),
        take(this.CHECK_RETRIES_MAX),
        switchMap(() =>
          this.preflightApiService.checkPreviewsDownloadTask(taskId).pipe(
            filter(resp => resp.state === PreflightTaskState.SUCCESS || resp.state === PreflightTaskState.FAILURE),
            map(response => this.facade.handleDownloadTaskCheckResponse(response)),
          ),
        ),
      )
      .subscribe();
  }

  downloadFileByUrl(url: string) {
    // we need to stop tracking before unload event for a moment,
    // as file download triggers it, which is desired behaviour
    this.beforeUnloadEventWarningService.destroy();
    downloadFileFromUrl(url, 'previews.zip');
    requestAnimationFrame(() => this.beforeUnloadEventWarningService.init());
  }
}
