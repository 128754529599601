<div class="dropdown-wrapper">
  <ng-container [ngSwitch]="renderType">
    <ng-container *ngSwitchCase="'template'">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'component'">
      <ng-container *ngComponentOutlet="content"></ng-container>
    </ng-container>
  </ng-container>
</div>
