<ng-container *ngIf="showDropDownMenu">
  <gc-dropdown-menu
    #dropdownMenu
    class="cy-add-product-button"
    [buttonType]="buttonType"
    [buttonContent]="dropdown"
    [dropdownTpl]="dropdownTpl"
    [isMobile]="isMobile"
    [positionLeft]="positionLeft"
    [mobileMenuTitle]="'txt_add_products'"
    [isDisabled]="disabled"
    (menuButtonClick)="addButtonClick()"
  >
  </gc-dropdown-menu>

  <ng-template #dropdown> {{ 'txt_add_product' | translate }} </ng-template>

  <ng-template #dropdownTpl>
    <a
      *ngIf="canAddNewProduct"
      class="item dropdown cy-add-product-menu-item"
      gcLogEvent
      analyticsKey="CreateNewProduct"
      (click)="onAddNewProductClick($event)"
    >
      <img [src]="'icons/product-add-menu/add.svg' | assetsPath | async" alt="" />{{ 'txt_create_new' | translate }}
    </a>
    <a
      *ngIf="canAddExistingProduct"
      class="item dropdown cy-select-product-from-my-stores-menu-item"
      gcLogEvent
      analyticsKey="SelectExistingProduct"
      (click)="onAddExistingProductClick($event)"
    >
      <img [src]="'icons/product-add-menu/copy.svg' | assetsPath | async" alt="" />{{
        'txt_copy_from_existing_store' | translate
      }}
    </a>
    <a
      *ngIf="canConnectExistingProducts"
      class="item dropdown cy-connect-existing-stores-menu-item"
      gcLogEvent
      analyticsKey="SelectExistingProduct"
      (click)="onConnectExistingProductsClick($event)"
    >
      <img [src]="'icons/product-add-menu/connect.svg' | assetsPath | async" alt="" />{{
        'txt_connect_existing_products' | translate
      }}
    </a>

    <div
      *ngIf="showAddBranding"
      class="item dropdown"
      suiPopup
      popupBasic
      popupInverted
      [popupText]="'txt_disabled_add_branding_tooltip' | translate"
      [popupPlacement]="isMobile ? 'top' : 'right'"
      [ngClass]="{ 'item-disabled': !connectedItems?.length }"
      (click)="addBranding()"
    >
      <img [src]="'sidebar-icons/branding.svg' | assetsPath | async" alt="" />
      {{ 'txt_add_branding' | translate }}
    </div>
  </ng-template>
</ng-container>

<ng-container *ngIf="!showDropDownMenu">
  <button
    *ngIf="canAddNewProduct"
    class="ui button {{ buttonType }} cy-add-product-button"
    [class.loading]="isLoading"
    gcLogEvent
    analyticsKey="CreateNewProduct"
    (click)="onAddNewProductClick($event)"
  >
    {{ 'txt_create_new' | translate }}
  </button>
  <button
    *ngIf="canAddExistingProduct"
    class="ui button {{ buttonType }} cy-add-product-button"
    [class.loading]="isLoading"
    gcLogEvent
    analyticsKey="SelectExistingProduct"
    (click)="onAddExistingProductClick($event)"
  >
    {{ 'txt_copy_from_existing_store' | translate }}
  </button>
  <button
    *ngIf="canConnectExistingProducts"
    class="ui button {{ buttonType }} cy-add-product-button"
    [class.loading]="isLoading"
    gcLogEvent
    analyticsKey="ConnectExistingProducts"
    (click)="onConnectExistingProductsClick($event)"
  >
    {{ 'txt_connect_existing_products' | translate }}
  </button>
</ng-container>
