import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  EProductValidationError,
  EProductValidationErrorCode,
  EProductValidationErrorData,
  EProductValidationErrorMessages,
} from '@gelato-api-ui/core/e-commerce/e-product-validation-response';
import * as R from 'ramda';
import { toPriceNumber } from '@gelato-api-ui/core/product-prices/helpers/toPriceNumber';

enum HtmlTag {
  LIST = 'ul',
  LIST_ITEM = 'li',
  PARAGRAPH = 'p',
}

enum ErrorDataItemKey {
  MIN_VALUE = 'min_value',
  MAX_VALUE = 'max_value',
}

@Injectable({ providedIn: 'root' })
export class ECommerceProductValidationErrorMessageService {
  constructor(private readonly translateService: TranslateService) {}

  getFormattedErrorMessage(errors: EProductValidationError[]) {
    if (!errors?.length) {
      return '';
    }

    const uniqErrors: EProductValidationError[] = R.uniqBy((err: EProductValidationError) => err.code, errors);
    const errorMessages = this.getTranslatedErrorMessages(uniqErrors);

    if (errorMessages.length > 1) {
      return this.wrapText(
        errorMessages.reduce(
          (acc: string, loopMessage: string) => acc + '\n' + this.wrapText(loopMessage, HtmlTag.LIST_ITEM),
          '',
        ),
        HtmlTag.LIST,
      );
    } else {
      return this.wrapText(errorMessages[0], HtmlTag.PARAGRAPH);
    }
  }

  private getTranslatedErrorMessages(errors: EProductValidationError[]): string[] {
    return errors.map((err: EProductValidationError): string =>
      this.translateService.instant(
        this.getErrorMessageTranslationKey(err.code),
        err.data ? this.getErrorMessageDetails(err.code, err.data) : null,
      ),
    );
  }

  private getErrorMessageTranslationKey(errorCode: EProductValidationErrorCode): string {
    return (
      EProductValidationErrorMessages[errorCode] ||
      EProductValidationErrorMessages[EProductValidationErrorCode.GENERAL_ERROR]
    );
  }

  private getErrorMessageDetails(errorCode: EProductValidationErrorCode, errorData: EProductValidationErrorData[]) {
    return errorData.reduce(
      (acc, loopErrorDataItem) => ({
        ...acc,
        [loopErrorDataItem.key]: this.getFormattedErrorDataItemValue(errorCode, loopErrorDataItem),
      }),
      {},
    );
  }

  private getFormattedErrorDataItemValue(
    errorCode: EProductValidationErrorCode,
    errorDataItem: EProductValidationErrorData,
  ): string {
    const { key, value } = errorDataItem;

    const isPriceNumber =
      [EProductValidationErrorCode.PRICE_TOO_LOW, EProductValidationErrorCode.PRICE_TOO_HIGH].includes(errorCode) &&
      [ErrorDataItemKey.MIN_VALUE, ErrorDataItemKey.MAX_VALUE].includes(key as ErrorDataItemKey);

    if (isPriceNumber) {
      const roundingAction = key === ErrorDataItemKey.MAX_VALUE ? Math.trunc : Math.ceil;
      const roundedValue = roundingAction(Number(value) * 100) / 100;

      return String(toPriceNumber(roundedValue));
    }

    if (typeof value === 'object' && value.length) {
      return value.join('');
    }

    return String(value);
  }

  private wrapText(text: string, tag: HtmlTag) {
    return `<${tag}>${text}</${tag}>\n`;
  }
}
