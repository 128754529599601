import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { VoucherApiService } from '@api-ui-app/src/app/shared/voucher/voucher-api.service';
import {
  applyPromotion,
  loadPromotions,
  loadPromotionsSuccess,
  setLoading,
} from '@api-ui-app/src/app/ngrx/discounts.actions';
import { SplashMessageFacade } from '@api-ui-app/src/app/shared/splash-message/+state/splash-message/splash-message.facade';
import { TranslateService } from '@ngx-translate/core';
import { SECOND } from '@gelato-api-ui/utils/time';

@Injectable()
export class DiscountsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly voucherApiService: VoucherApiService,
    private readonly translate: TranslateService,
    private readonly splashMessageFacade: SplashMessageFacade,
  ) {}

  applyPromotion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(applyPromotion),
      switchMap(action => {
        return this.voucherApiService.applyPromotion(action.clientId, action.voucherId, action.voucherEvent).pipe(
          tap(() => this.store$.dispatch(setLoading())),
          catchError(() => of(null)),
          // delay needed for making sure Voucherify activated discount and new discount will be returned from api
          delay(SECOND * 2),
          switchMap(() =>
            this.voucherApiService.getListOfPromotions(action.clientId).pipe(
              catchError(() => of(null)),
              map(listOfPromotions => {
                this.splashMessageFacade.triggerAdd({
                  text: this.translate.instant('txt_discount_has_been_added'),
                  color: 'success',
                  closeable: true,
                  icon: 'assets/icons/checked-green.svg',
                  id: 'promotion-notification',
                });
                return loadPromotionsSuccess({ payload: listOfPromotions ? listOfPromotions.promotions : [] });
              }),
            ),
          ),
        );
      }),
    ),
  );

  fetchPromotions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadPromotions),
      switchMap(action =>
        this.voucherApiService.getListOfPromotions(action.clientId).pipe(
          catchError(() => of(null)),
          map(data => loadPromotionsSuccess({ payload: data ? data.promotions : [] })),
        ),
      ),
    ),
  );
}
