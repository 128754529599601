/**
 * https://gelato.atlassian.net/wiki/spaces/GAPI/pages/4714233954/2.2.4.1.8+Subscriptions
 */

export interface SubscriptionSearchRequest {
  ids?: string[];
  clientId: string;
  serviceIds?: string[];
  serviceUniqueNames?: string[];
  statuses: SubscriptionStatus[];
  offset: number;
  limit: number;
}

export interface SubscriptionSearchResponse {
  subscriptions: SubscriptionResponse[];
}

export interface SubscriptionResponse {
  id: string;
  clientId: string;
  serviceId: string;
  referenceId: string;
  plan: SubscriptionPlan;
  addons: SubscriptionAddon[];
  status: SubscriptionStatus;
  nextBillingAt: string;
  billingPeriod: number; // Describes length of billing cycle in billingPeriodUnits
  billingPeriodUnit: string; // Unit to measure billing period in day/week/month/year
  activatedAt: string; // Date & time subscription became active at (ISO8601)
  startedAt: string; // Date & time subscription was created (ISO8601)
  cancelledAt: string; // Date and time when subscription was cancelled at (ISO8601)
  createdAt: string;
  updatedAt: string;
  trialStart: string; // Start of the trial period for the subscription. Presence of this value for future subscription implies the subscription will go into in_trial state when it starts.
  trialEnd: string; // End of the trial period for the subscription. Presence of this value for 'future' subscription implies the subscription will go into 'in_trial' state when it starts.
  trialEndAction: string; // End of the trial action.
  remainingBillingCycles: number;
  cancelScheduleCreatedAt: string;
  currency: string; // Currency subscription was paid in
}

export interface SubscriptionAddon {
  serviceAddonId: string;
  serviceAddonPriceId: string;
  referenceServicePlanId: string;

  unitPrice: number; // Price of 1 item
  quantity: number; // Quantity of items purchased (relevant for addons)
  amount: number; // Total price of items
  freeQuantity: number; // Free quantity: This option is only available when you specify the pricing model for the price point as Per Unit. Free quantity is the number of goods you would like to give to your customers at no extra charge. You or your customer can specify the total quantity of goods at the time of purchase.
  trialEnd: string; // The time at which the trial has ended or will end for the subscription. This is only allowed when the subscription status is future, in_trial, or cancelled. Also, the value must not be earlier than changes_scheduled_at or start_date. Note: This parameter can be backdated (set to a value in the past) only when the subscription is in cancelled status. Do this to keep a record of when the trial ended in case it ended at some point in the past. When trial_end is backdated, the subscription immediately goes into active or non_renewing status.
  billingCycles: number; // Number of cycles(plan interval) this subscription should be charged. After the billing cycles exhausted, the subscription will be cancelled.

  createdAt: string;
  updatedAt: string;
}

export interface SubscriptionSearchResponsePagination {
  nextPageOffset: string;
}

export interface SubscriptionPlan {
  servicePlanId: string; // Subscription plan identifier
  servicePlanPriceId: string; // Subscription plan price identifier

  unitPrice: number; // Price of 1 item
  quantity: number; // Quantity of items purchased (relevant for addons)
  amount: number; // Total price of items
  freeQuantity: number; // Free quantity: This option is only available when you specify the pricing model for the price point as Per Unit. Free quantity is the number of goods you would like to give to your customers at no extra charge. You or your customer can specify the total quantity of goods at the time of purchase.
  trialEnd: string; // The time at which the trial has ended or will end for the subscription. This is only allowed when the subscription status is future, in_trial, or cancelled. Also, the value must not be earlier than changes_scheduled_at or start_date. Note: This parameter can be backdated (set to a value in the past) only when the subscription is in cancelled status. Do this to keep a record of when the trial ended in case it ended at some point in the past. When trial_end is backdated, the subscription immediately goes into active or non_renewing status.
  billingCycles: number; // Number of cycles(plan interval) this subscription should be charged. After the billing cycles exhausted, the subscription will be cancelled.

  createdAt: string;
  updatedAt: string;
}

export enum SubscriptionStatus {
  future = 'future',
  in_trial = 'in_trial',
  active = 'active',
  non_renewing = 'non_renewing',
  paused = 'paused',
  cancelled = 'cancelled',
}

export enum TrialEndAction {
  // action for free sing up trial
  cancel_subscription = 'cancel_subscription',
  // action for trial with prolongation (legacy users)
  site_default = 'site_default',
}

export enum CustomSubscriptionStatus {
  absent = 'absent',
  free_trial = 'free_trial',
}

export type EndingSubscriptionStatus = SubscriptionStatus.in_trial | SubscriptionStatus.non_renewing;
