import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AccessGroup } from '../lib/access-group';
import { flattenAccessScopes } from '../lib/helpers/flattenAccessScopes';
import { splitAccessScopesIntoGroups } from '../lib/helpers/splitAccessScopesIntoGroups';
import { isAccessGroupGranted } from '../lib/helpers/isAccessGroupGranted';

@Component({
  selector: 'gd-access-scopes-selector',
  templateUrl: './access-scopes-selector.component.html',
  styleUrls: ['./access-scopes-selector.component.scss'],
})
export class AccessScopesSelectorComponent implements OnChanges {
  @Input() accessGroups: AccessGroup[] = [];
  @Input() selectedAccessScopes: string[] = [];
  @Output() selectedAccessScopesChange = new EventEmitter<string[]>();

  expandedAccessGroups = {};
  selectedAccessGroupScopes = {};

  constructor() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.accessGroups) {
      this.expandedAccessGroups = {};

      (this.accessGroups || []).forEach((accessGroup: AccessGroup) => {
        if (!accessGroup.childGroups || !accessGroup.childGroups.length) {
          return;
        }

        this.expandedAccessGroups[accessGroup.name] = true;
      });
    }

    if (changes && changes.selectedAccessScopes) {
      this.selectedAccessGroupScopes = splitAccessScopesIntoGroups(this.selectedAccessScopes, this.accessGroups);
    }
  }

  isAccessGroupGranted(accessGroupName: string) {
    return isAccessGroupGranted(accessGroupName, this.selectedAccessScopes, this.accessGroups);
  }

  toggleAccessGroup(accessGroupName: string) {
    this.expandedAccessGroups[accessGroupName] = !this.expandedAccessGroups[accessGroupName];
  }

  selectAccessGroupScope(accessGroupName: string, accessScope: string) {
    this.selectedAccessGroupScopes[accessGroupName] = accessScope;

    const accessScopes = flattenAccessScopes(this.selectedAccessGroupScopes);

    this.selectedAccessScopesChange.emit(accessScopes);
  }
}
