import {
  PRODUCT_WIZARD_ROUTE_DETAILS,
  PRODUCT_WIZARD_ROUTE_MEDIA,
  PRODUCT_WIZARD_ROUTE_PRICES,
  PRODUCT_WIZARD_ROUTE_VARIANTS,
  PRODUCT_WIZARD_ROUTE_VARIANTS_MAPPING,
} from '@gelato-api-ui/core/e-commerce/constants';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';

export const getInitialWizardRouteByStep = (step: ECommerceProductWizardStep): any[] => {
  switch (step) {
    case ECommerceProductWizardStep.MEDIA:
      return PRODUCT_WIZARD_ROUTE_MEDIA;
    case ECommerceProductWizardStep.DETAILS:
      return PRODUCT_WIZARD_ROUTE_DETAILS;
    case ECommerceProductWizardStep.PRICES:
      return PRODUCT_WIZARD_ROUTE_PRICES;
    case ECommerceProductWizardStep.CONNECT:
      return PRODUCT_WIZARD_ROUTE_VARIANTS_MAPPING;
    default:
      return PRODUCT_WIZARD_ROUTE_VARIANTS;
  }
};
