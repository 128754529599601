import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PaymentDetailsState } from './payment-details.adapter';
import { paymentDetailsFeatureName } from './payment-details.feature';

const getPaymentDetailsState = createFeatureSelector<PaymentDetailsState>(paymentDetailsFeatureName);

const isFetchingComplete = createSelector(getPaymentDetailsState, state => state.isFetched === true);
const isFetchingStarted = createSelector(getPaymentDetailsState, state => state.isFetched === false);
const isFetchingToBeDone = createSelector(
  isFetchingComplete,
  isFetchingStarted,
  (isComplete, isStarted) => !isComplete && !isStarted,
);
const getAll = createSelector(getPaymentDetailsState, state => Object.values(state.entities));
const getIds = createSelector(getPaymentDetailsState, state => Object.keys(state.entities));
const getDefault = createSelector(getAll, list => list.find(p => p.isDefault && !p.isDeleted));
const getByUid = (uid: string) => createSelector(getPaymentDetailsState, state => state.entities[uid]);
const getCount = createSelector(getAll, list => (list ? list.length : 0));
const getAllSorted = createSelector(getAll, list =>
  [...list].sort((a, b) => {
    const aDate = new Date(a.createdAt).getTime();
    const bDate = new Date(b.createdAt).getTime();

    if (aDate < bDate) {
      return 1;
    }

    if (aDate > bDate) {
      return -1;
    }

    return 0;
  }),
);
const getLastAdded = createSelector(getAllSorted, list => [...list].shift());
export const paymentDetailsQuery = {
  getAll,
  getCount,
  getIds,
  getByUid,
  getAllSorted,
  getLastAdded,
  isFetchingComplete,
  isFetchingStarted,
  isFetchingToBeDone,
  getDefault,
};
