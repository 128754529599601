import * as R from 'ramda';
import { Asset } from '../asset';
import { AssetsCollection } from '../assets-collection';
import { DesignStructure } from '../design-structure';
import { getUsedAssets } from './getUsedAssets';

export const getAssetsAutorNamesFromDesignStructure = (
  assetsCollection: AssetsCollection,
  designStructure: DesignStructure,
): string[] => {
  const usedAssets = getUsedAssets(assetsCollection, designStructure);
  let assetsAuthorNames: string[] = R.map(
    (loopAsset: Asset) => (loopAsset.author ? loopAsset.author.name : null),
    usedAssets,
  );

  assetsAuthorNames = R.filter((loopAuthorName: string): boolean => Boolean(loopAuthorName), assetsAuthorNames);

  assetsAuthorNames = R.sort((a, b) => a.localeCompare(b), assetsAuthorNames);

  return R.uniq(assetsAuthorNames);
};
