import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { StoreLogoComponent } from './store-logo.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [StoreLogoComponent],
  exports: [StoreLogoComponent],
})
export class StoreLogoModule {}
