import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '../../app.state';
import { AnalyticsGraphData } from '../types/analytics-graph-data';
import { AnalyticsGraphName } from '../types/analytics-graph-name.enum';
import { AnalyticsGraphGroupType } from '../types/analytics-graph-group-type.enum';
import { AnalyticsGraphRequest } from '../types/analytics-graph-request';
import { AnalyticsGraphResponse } from '../types/analytics-graph-response';
import { getAvailableGroupTypes } from '../../ngrx/helpers/getAvailableGroupTypes';

export const getGraphGroupTypeKey = (graphGroupType: AnalyticsGraphGroupType): string => graphGroupType || 'total';

export const getState = (state: AppState): State => state.analytics;

export const getGraphState = createSelector(
  getState,
  (state: State, props): AnalyticsGraphState => state[props.graphName][getGraphGroupTypeKey(props.graphGroupType)],
);

export const getGraphRequest = createSelector(getGraphState, (state: AnalyticsGraphState): AnalyticsGraphRequest => {
  return state.request;
});

export const getGraphData = createSelector(getGraphState, (state: AnalyticsGraphState): AnalyticsGraphData[] => {
  if (!state.payload || !state.payload.data) {
    return [];
  }

  return state.payload.data;
});

export const getGraphValue = createSelector(getGraphData, (data: AnalyticsGraphData[], props): number => {
  const dataItem: AnalyticsGraphData = R.find(R.propEq('name', props.valueName), data);

  return dataItem ? dataItem.value : 0;
});

export const getSavedShippingCost = createSelector(getState, (state: State): number => {
  return state.savedShippingCost;
});

export const getSavedShippingCostDateRange = createSelector(
  getState,
  (state: State): { startDate: string; endDate: string } => {
    return { startDate: state.savedShippingCostRangeStartDate, endDate: state.savedShippingCostRangeEndDate };
  },
);

export const getSelectedGraphGroupType = createSelector(
  getState,
  (state: State, props): AnalyticsGraphGroupType => state[props.graphName].selectedGraphGroupType,
);

export const getAvailableGraphGroupTypes = createSelector(
  getState,
  (state: State, props): AnalyticsGraphGroupType[] => {
    try {
      return getAvailableGroupTypes(state.startDate, state.endDate);
    } catch (e) {
      return [];
    }
  },
);

export const isGraphLoading = createSelector(getGraphState, (state: AnalyticsGraphState): boolean => state.isLoading);

export const getTotalMonthShippingCost = createSelector(
  getState,
  (state: State): AnalyticsGraphData[] =>
    state[AnalyticsGraphName.SHIPPING_COST][AnalyticsGraphGroupType.BY_DAY].payload?.data,
);

export interface AnalyticsGraphState {
  isLoading: boolean;
  request: AnalyticsGraphRequest;
  payload: AnalyticsGraphResponse;
}

export interface State {
  startDate: string;
  endDate: string;
  savedShippingCostRangeStartDate: string;
  savedShippingCostRangeEndDate: string;
  savedShippingCost: number;
  [AnalyticsGraphName.SUMMARY]: {
    selectedGraphGroupType: AnalyticsGraphGroupType;
    total: AnalyticsGraphState;
  };
  [AnalyticsGraphName.ORDERS_BY_COUNTRY]: {
    selectedGraphGroupType: AnalyticsGraphGroupType;
    total: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_DAY]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_WEEK]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_MONTH]: AnalyticsGraphState;
  };
  [AnalyticsGraphName.ORDERS_BY_PRODUCT]: {
    selectedGraphGroupType: AnalyticsGraphGroupType;
    total: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_DAY]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_WEEK]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_MONTH]: AnalyticsGraphState;
  };
  [AnalyticsGraphName.PRINT_COST]: {
    selectedGraphGroupType: AnalyticsGraphGroupType;
    [AnalyticsGraphGroupType.BY_DAY]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_WEEK]: AnalyticsGraphState;
    [AnalyticsGraphGroupType.BY_MONTH]: AnalyticsGraphState;
  };
}
