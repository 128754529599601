import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';

@Component({
  selector: 'gd-stores-list-item',
  templateUrl: './stores-list-item.component.html',
  styleUrls: ['./stores-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StoresListItemComponent {
  @Input() type: EStoreType = null;
  @Input() name: string = null;
  @Input() showRightArrow = true;
}
