import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { filter, first, map, skipWhile, takeUntil, tap } from 'rxjs/operators';
import * as R from 'ramda';
import { Tag } from '@gelato-api-ui/ui-kit/src/lib/tag/tag';

@Component({
  selector: 'gc-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagsComponent implements OnInit, OnDestroy {
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  @Input() set tags(tags: Tag[]) {
    this.tags$.next(tags);
  }

  @Input() set selected(tags: Tag[]) {
    if (tags) {
      this.selectedTags$.next(tags);
    }
  }

  @Input() set maxLength(maxLength: number) {
    this.maxLength$.next(maxLength || undefined);
  }

  @Input() set maxSelectedItemsCount(maxSelectedItems: number) {
    this.maxSelectedItemsCount$.next(maxSelectedItems || undefined);
  }

  @Input() placeholder: string;
  @Input() canAddNewItems = false;
  @Input() searchQueryValidationFunc: (query: string) => Observable<string>;
  @Input() set sortItems(sortItems: boolean) {
    this.sortItems$.next(sortItems);
  }
  @Output() update = new EventEmitter<Tag[]>();

  search: string;
  searchItems: string[];
  tags$ = new BehaviorSubject<Tag[]>(null);
  selectedTags$ = new BehaviorSubject<Tag[]>(null);
  sortItems$ = new BehaviorSubject<boolean>(false);
  maxLength$ = new BehaviorSubject<number>(undefined);
  maxSelectedItemsCount$ = new BehaviorSubject<number>(undefined);
  isSelectedItemsCountLimitReached$ = combineLatest([this.maxSelectedItemsCount$, this.selectedTags$]).pipe(
    map(
      ([maxSelectedItemsCount, selectedTags]): boolean =>
        Boolean(maxSelectedItemsCount) && selectedTags?.length >= maxSelectedItemsCount,
    ),
  );
  inputAttrs$ = this.maxLength$.pipe(map((maxLength: number): object => (maxLength ? { maxlength: maxLength } : null)));
  sort = R.memoizeWith(R.identity, R.sortBy(R.compose(R.toLower, R.prop('title'))));

  items$ = combineLatest([this.tags$, this.sortItems$]).pipe(
    map(([tags, sortItems]): object[] => (sortItems ? this.sort(tags) : tags)),
  );

  private readonly ngDestroy$ = new Subject<boolean>();

  ngOnInit() {
    this.selectedTags$
      .pipe(
        skipWhile(R.isNil),
        takeUntil(this.ngDestroy$),
        tap(tags => {
          this.update.emit(tags);
          this.closeDropdownIfSelectedItemsCountLimitReached();
        }),
      )
      .subscribe();
  }

  unselect(targetTag: Tag) {
    this.selectedTags$.next([...this.selectedTags$.value.filter(tag => tag.id !== targetTag.id)]);
  }

  onSubmit() {
    const newTag = this.search.replace(',', '');

    if (!newTag) {
      return;
    }

    const existingTag = this.getExistingTag(newTag);

    if (existingTag) {
      this.selectExistingTag(existingTag);
    } else {
      this.addNewTag(newTag);
    }
  }

  clearSearch() {
    // @ts-ignore
    this.ngSelectComponent._clearSearch();
    this.search = '';
  }

  onEnterPress() {
    if (!this.searchItems.length) {
      this.onSubmit();
    }
  }

  onSearch({ term, items }) {
    (this.searchQueryValidationFunc ? this.searchQueryValidationFunc(term) : of(term))
      .pipe(
        first(),
        tap((search: string) => {
          if (search) {
            // @ts-ignore
            this.ngSelectComponent._changeSearch(search);
          } else {
            // @ts-ignore
            this.ngSelectComponent._clearSearch();
          }

          this.search = search;
          this.searchItems = items;
        }),
      )
      .subscribe();
  }

  onChangeSelected(tags: Tag[]) {
    this.selectedTags$.next(tags);
  }

  get newTag(): string {
    return this.getExistingTag(this.search) ? '' : this.search;
  }

  private getExistingTag(title: string): Tag {
    return this.tags$.getValue().find(tag => tag.title === title);
  }

  private addNewTag(title: string) {
    if (!this.canAddNewItems) {
      return;
    }

    const newTag = { id: title, title };

    this.tags$.next([...this.tags$.getValue(), newTag]);
    this.selectTag(newTag);
    this.clearSearch();
  }

  private selectExistingTag(tag: Tag) {
    this.selectTag(tag);
    this.clearSearch();
  }

  private selectTag(tag: Tag) {
    const selected = this.selectedTags$.value || [];
    this.selectedTags$.next([...selected, tag]);
  }

  private closeDropdownIfSelectedItemsCountLimitReached() {
    this.isSelectedItemsCountLimitReached$
      .pipe(
        first(),
        filter(Boolean),
        tap(() => {
          this.ngSelectComponent.close();
        }),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngDestroy$.next(true);
    this.ngDestroy$.complete();
  }
}
