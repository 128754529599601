import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Tag } from '@gelato-api-ui/ui-kit/src/lib/tag/tag';

@Component({
  selector: 'gc-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent implements OnInit {
  @Input() tag: Tag;
  @Output() close = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}

  onClose() {
    this.close.emit();
  }
}
