import { Injectable } from '@angular/core';
import { EStoreFeature } from '@gelato-api-ui/core/e-commerce/e-store-feature.enum';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { internalStoreTypes } from '@gelato-api-ui/core/e-commerce/constants/internal-store-types';
import { rutterStoreTypes } from '@gelato-api-ui/core/e-commerce/constants/rutter-store-types';

@Injectable({
  providedIn: 'root',
})
export class ECommerceStoreFeaturesService {
  isFeatureSupported(storeType: EStoreType, feature: EStoreFeature): boolean {
    return this.get(storeType).includes(feature);
  }

  private get(storeType: EStoreType): EStoreFeature[] {
    const isInternalStoreType = internalStoreTypes.includes(storeType);
    const isRutterStoreType = rutterStoreTypes.includes(storeType);
    const features: EStoreFeature[] = [
      EStoreFeature.PRODUCT_DELETION,
      EStoreFeature.PRODUCT_VARIANT_DELETION,
      EStoreFeature.FRAMED_POSTERS_UPGRADE,
    ];

    if (!isInternalStoreType) {
      features.push(EStoreFeature.IMMEDIATE_PUBLISH);
      features.push(EStoreFeature.SYNC_PRODUCTS);
    }

    if ([EStoreType.SHOPIFY].includes(storeType)) {
      features.push(EStoreFeature.DESIGN_PERSONALIZATION);
    }

    if (![EStoreType.SHOPIFY].includes(storeType)) {
      features.push(EStoreFeature.ADVANCED_DESIGN_PERSONALIZATION_EMAIL);
    }

    if (isInternalStoreType || [EStoreType.SHOPIFY, EStoreType.WOOCOMMERCE].includes(storeType)) {
      features.push(EStoreFeature.HTML_DESCRIPTION);
    }

    if ([EStoreType.SHOPIFY, EStoreType.WOOCOMMERCE].includes(storeType)) {
      features.push(EStoreFeature.COLLECTIONS);
    }

    if (!isInternalStoreType && !isRutterStoreType) {
      features.push(EStoreFeature.OUT_OF_STOCK_SYNC);
    }

    if ([EStoreType.SHOPIFY, EStoreType.ETSY, EStoreType.WOOCOMMERCE, EStoreType.SQUARESPACE].includes(storeType)) {
      features.push(EStoreFeature.TAGS);
    }

    if ([EStoreType.WIX, EStoreType.SQUARESPACE, EStoreType.BIGCOMMERCE].includes(storeType)) {
      features.push(EStoreFeature.DELAYED_PREVIEW_GENERATION);
    }

    if ([EStoreType.SHOPIFY, EStoreType.ETSY].includes(storeType)) {
      features.push(EStoreFeature.FREE_SHIPPING_STOREFRONT);
    }

    if ([EStoreType.SHOPIFY, EStoreType.ETSY, EStoreType.WOOCOMMERCE].includes(storeType)) {
      features.push(EStoreFeature.IMPORT_EXPORT_PRODUCTS);
    }

    if (isInternalStoreType || [EStoreType.SHOPIFY, EStoreType.ETSY, EStoreType.WOOCOMMERCE].includes(storeType)) {
      features.push(EStoreFeature.BULK_EDITING);
      features.push(EStoreFeature.PRODUCT_VARIANT_SET_MODIFICATION);
    }

    if (storeType === EStoreType.ETSY) {
      features.push(EStoreFeature.IOSS_NUMBER);
      features.push(EStoreFeature.SHIPPING_ORIGIN);
    }

    if (storeType === EStoreType.WOOCOMMERCE) {
      features.push(EStoreFeature.EDITABLE_DOMAIN);
    }

    return features;
  }
}
