import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import * as gql from 'gql-query-builder';
import { DesignEditorPreviewSettings } from '@gelato-api-ui/core/design-editor/design-editor-preview-settings';

@Injectable({
  providedIn: 'root',
})
export class DesignEditorPreviewSettingsGraphQlService {
  constructor(private readonly graphQlRequestService: GraphQlRequestService) {}

  fetch(productUid: string, hasShutterstockAssets: boolean): Observable<DesignEditorPreviewSettings> {
    const query = this.fetchQuery(productUid, hasShutterstockAssets);

    return this.graphQlRequestService
      .query(query, 'designEditorPreviewSettings')
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.designEditorPreviewSettings));
  }

  private fetchQuery(productUid: string, hasShutterstockAssets: boolean) {
    return gql.query({
      operation: 'designEditorPreviewSettings',
      variables: {
        productUid: { type: 'String!', value: productUid },
        hasShutterstockAssets: { type: 'Boolean!', value: hasShutterstockAssets },
      },
      fields: ['includeBackground', 'includeForeground', 'firstSpreadOnly', 'noFlatPreviews', 'clientSideRendering'],
    });
  }
}
