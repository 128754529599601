import { createAction, props } from '@ngrx/store';
import { PaymentDetails } from '../../lib/api';

export const fetchStart = createAction('[Payment Details] FetchStart');

export const fetchSuccess = createAction('[Payment Details] FetchSuccess', props<{ list: PaymentDetails[] }>());

export const fetchFail = createAction('[Payment Details] FetchFail', props<{ err: any }>());

export const fetchIntent = createAction('[Payment Details] FetchIntent', props<{ force: boolean }>());

export const deleteSuccess = createAction('[Payment Details] DeleteSuccess', props<{ id: string }>());

export const deleteFail = createAction('[Payment Details] DeleteFail', props<{ err: any }>());

export const deleteStart = createAction('[Payment Details] DeleteStart', props<{ id: string }>());

export const setDefaultSuccess = createAction('[Payment Details] SetDefaultSuccess', props<{ id: string }>());

export const setDefaultFail = createAction('[Payment Details] SetDefaultFail', props<{ err: any }>());

export const setDefaultStart = createAction('[Payment Details] SetDefaultStart', props<{ id: string }>());

export const setBackupSuccess = createAction('[Payment Details] SetBackupSuccess', props<{ id: string }>());

export const setBackupFail = createAction('[Payment Details] SetBackupFail', props<{ err: any }>());

export const setBackupStart = createAction('[Payment Details] SetBackupStart', props<{ id: string }>());
