import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';

export class EProductImageUpdateRequest {
  constructor(
    public id: string,
    public productVariantIds: string[],
    public fileUrl: string,
    public isPrimary: boolean,
    public metadata: MetadataItem[],
  ) {}
}
