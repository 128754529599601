import { Action, createAction, props, union } from '@ngrx/store';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import { SanityRelatedProducts } from '@gelato-api-ui/core/sanity/SanityRelatedProducts';
import {
  SanityHomePageContent,
  SanityProductCommunicationBannersItem,
} from '@gelato-api-ui/core/sanity/sanity-home-page-content';
import { SanityHelloBar } from '@gelato-api-ui/core/sanity/types/sanity-hello-bar';

export enum SanityActionTypes {
  Load = '[Sanity] Load',
  LoadStart = '[Sanity] Load Start',
  ResetState = '[Sanity] Reset State',
  SetCategories = '[Sanity] Set Categories',
}

export class LoadSanityContent implements Action {
  readonly type = SanityActionTypes.Load;
  constructor(public forced: boolean = false, public locale: LocaleCode = null) {}
}

export class LoadSanityContentStart implements Action {
  readonly type = SanityActionTypes.LoadStart;
}

export class ResetState implements Action {
  readonly type = SanityActionTypes.ResetState;
}

export class SetCategories implements Action {
  readonly type = SanityActionTypes.SetCategories;

  constructor(public payload: SanityProductCategory[], public locale: LocaleCode) {}
}

export const loadProduct = createAction(
  '[Sanity] Load Product',
  props<{
    categoryName: string;
    productName: string;
    locale?: LocaleCode;
    productNameUid?: string;
    productUid?: string;
  }>(),
);
export const loadProductSuccess = createAction(
  '[Sanity] Load Product Success',
  props<{ categoryName: string; productName: string; product: SanityProduct }>(),
);

export const loadRelatedProducts = createAction('[Sanity] Load Related Products', props<{ locale?: LocaleCode }>());
export const loadRelatedProductsStart = createAction('[Sanity] Load Related Products Start');
export const loadRelatedProductsSuccess = createAction(
  '[Sanity] Load Related Products Success',
  props<{ payload: SanityRelatedProducts[] }>(),
);

export const loadHomePageContent = createAction('[Sanity] Load Home Page Content', props<{ locale?: LocaleCode }>());
export const loadHomePageContentStart = createAction('[Sanity] Load Home Page Content Start');
export const loadHomePageContentSuccess = createAction(
  '[Sanity] Load Home Page Content Success',
  props<{ payload: SanityHomePageContent }>(),
);

export const loadProductCommunicationBanners = createAction(
  '[Sanity] Load Product Communication Banners',
  props<{ locale?: LocaleCode }>(),
);
export const loadProductCommunicationBannersSuccess = createAction(
  '[Sanity] Load Product Communication Banners Success',
  props<{ payload: SanityProductCommunicationBannersItem[] }>(),
);

export const loadHelloBar = createAction('[Sanity] Load Hello Bar', props<{ locale?: LocaleCode }>());
export const loadHelloBarSuccess = createAction(
  '[Sanity] Load Hello Bar Success',
  props<{ payload: SanityHelloBar }>(),
);
export const setHelloBarVisibility = createAction('[Sanity] Set Hello Bar Visibility', props<{ payload: boolean }>());

export const actions = union({
  loadProduct,
  loadProductSuccess,
  loadRelatedProducts,
  loadRelatedProductsSuccess,
  loadRelatedProductsStart,
  loadHomePageContent,
  loadHomePageContentStart,
  loadHomePageContentSuccess,
  loadProductCommunicationBanners,
  loadProductCommunicationBannersSuccess,
  loadHelloBar,
  loadHelloBarSuccess,
  setHelloBarVisibility,
});
export type SanityActions = typeof actions | LoadSanityContent | ResetState | SetCategories | LoadSanityContentStart;
