import { Inject, Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';
import { I18N_DEFAULT_PARAMS } from './i18n.token';

@Injectable()
export class GelatoTranslateParser extends TranslateDefaultParser {
  public templateMatcher: RegExp = /{\s?([^{}\s]*)\s?}/g;

  private readonly defaultParams;

  constructor(@Inject(I18N_DEFAULT_PARAMS) private _allDefaultParams: any[]) {
    super();

    this.defaultParams = this._allDefaultParams.reduce((res, value) => ({
      ...res,
      ...value,
    }));
  }

  interpolate(expr, params?: any) {
    return super.interpolate(expr, { ...params, ...this.defaultParams })?.replace(/\\n/g, '');
  }

  getValue(target: any, key: string): any {
    return super.getValue(target, key);
  }
}
