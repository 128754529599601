import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserRoleObject } from '../lib/user-role-object';

@Component({
  selector: 'gd-user-role-selector',
  templateUrl: './user-role-selector.component.html',
  styleUrls: ['./user-role-selector.component.scss'],
})
export class UserRoleSelectorComponent implements OnInit {
  @Input() userRoles: UserRoleObject[] = [];
  @Input() selectedRoles: string[] = [];
  @Output() selectedRolesChange = new EventEmitter<string[]>();

  constructor() {}

  ngOnInit() {}

  toggleRole(role: string) {
    const selectedRoles = this.selectedRoles || [];
    const foundRole = selectedRoles.find((loopRole: string): boolean => loopRole === role);

    const newRoles = foundRole
      ? selectedRoles.filter((loopRole: string): boolean => loopRole !== role)
      : [...selectedRoles, role];

    this.selectedRolesChange.emit(newRoles);
  }
}
