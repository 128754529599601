import { EProductWizardMode } from '@gelato-api-ui/core/e-commerce/e-product-wizard-mode.enum';
import { ECommerceProductWizardStep } from '@gelato-api-ui/core/e-commerce/e-commerce-product-wizard-step.enum';
import { getInitialWizardRouteByStep } from '@gelato-api-ui/core/e-commerce/helpers/getInitialWizardRouteByStep';

export const getInitialWizardRouteByMode = (mode: EProductWizardMode): any[] => {
  switch (mode) {
    case EProductWizardMode.EDIT_MEDIA:
      return getInitialWizardRouteByStep(ECommerceProductWizardStep.MEDIA);
    case EProductWizardMode.EDIT_DETAILS:
      return getInitialWizardRouteByStep(ECommerceProductWizardStep.DETAILS);
    case EProductWizardMode.EDIT_PRICES:
      return getInitialWizardRouteByStep(ECommerceProductWizardStep.PRICES);
    default:
      return getInitialWizardRouteByStep(ECommerceProductWizardStep.DESIGN);
  }
};
