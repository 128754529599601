import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { PricePlanSearchRequest } from '../lib/price-plan-search-request';
import { PricePlanSearchResponse } from '../lib/price-plan-search-response';
import { PricePlanCreateRequest } from '../lib/price-plan-create-request';
import { PricePlanUpdateRequest } from '../lib/price-plan-update-request';
import { PricePlan } from '../lib/price-plan';

@Injectable({
  providedIn: 'root',
})
export class PricePlansApiService {
  constructor(private apiRequestService: ApiRequestService) {}

  search(request: PricePlanSearchRequest = null): Observable<PricePlanSearchResponse> {
    return this.apiRequestService
      .post<PricePlanSearchResponse>('/api/v3/price-plans:search', {
        body: request,
      })
      .pipe(
        map((data: any) => ({
          data: data.data,
          pagination: data.pagination,
        })),
      );
  }

  get(id: string): Observable<PricePlan> {
    if (!id) {
      return of(null);
    }

    return this.apiRequestService.get<PricePlan>(`/api/v3/price-plans/${id}`).pipe(map(data => data.data));
  }

  create(request: PricePlanCreateRequest): Observable<PricePlan> {
    return this.apiRequestService
      .post<PricePlan>('/api/v3/price-plans', { body: request })
      .pipe(map(data => data.data));
  }

  update(id: string, request: PricePlanUpdateRequest): Observable<PricePlan> {
    return this.apiRequestService
      .put<PricePlan>(`/api/v3/price-plans/${id}`, { body: request })
      .pipe(map(data => data.data));
  }

  save(pricePlan: PricePlan): Observable<PricePlan> {
    if (pricePlan.id) {
      const updateRequest: PricePlanUpdateRequest = new PricePlanUpdateRequest(
        pricePlan.clientId,
        pricePlan.type,
        pricePlan.catalogueId,
        pricePlan.name,
        pricePlan.description,
        pricePlan.margin,
        pricePlan.status,
      );

      return this.update(pricePlan.id, updateRequest);
    }

    const createRequest: PricePlanCreateRequest = new PricePlanCreateRequest(
      pricePlan.clientId,
      pricePlan.type,
      pricePlan.catalogueId,
      pricePlan.name,
      pricePlan.description,
      pricePlan.margin,
      pricePlan.status,
    );

    return this.create(createRequest);
  }

  delete(id: string): Observable<PricePlan> {
    return this.apiRequestService.delete<PricePlan>(`/api/v3/price-plans/${id}`).pipe(map(data => data.data));
  }
}
