import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../../app.state';
import { UsersApiService } from '../services/users-api.service';
import { UserSearchResponse } from '../lib/user-search-response';
import { User } from '../lib/user';
import * as actions from './users.actions';
import { getState, getUserListState } from './users.reducer';
import { findIncompleteUserInState } from '../../ngrx/helpers/findIncompleteUserInState';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { LoadClientsByIds } from '@api-ui-app/src/app/ngrx/client-selection-list.actions';

@Injectable()
export class UsersEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly usersApiService: UsersApiService,
    private readonly authService: AuthService,
  ) {}

  loadUserListStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadUserListStart>(actions.UsersActionTypes.LoadUserListStart),
        withLatestFrom(this.store$.select(getUserListState), this.authService.hasReadPermission('identity', 'user')),
        filter(([action, userListState, hasPermission]) => {
          const { forced, searchRequest } = action;
          const isDataRelevant = R.equals(searchRequest, userListState.request) && userListState.payload;
          return hasPermission && (forced || !isDataRelevant);
        }),
        switchMap(([action, userListState]) => {
          const { searchRequest } = action;
          const { forced } = action;
          const isDataRelevant = R.equals(searchRequest, userListState.request) && userListState.payload;

          let observable: Observable<UserSearchResponse> = this.usersApiService.search(searchRequest);

          if (!forced && isDataRelevant) {
            observable = of({ ...userListState.payload });
          }

          this.store$.dispatch(
            new actions.SetUserListState({
              isLoading: true,
              request: searchRequest,
              payload: isDataRelevant ? userListState.payload : null,
            }),
          );

          return observable.pipe(
            catchError((): Observable<UserSearchResponse> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((response: UserSearchResponse) => {
              this.store$.dispatch(
                new actions.SetUserListState({
                  isLoading: false,
                  request: searchRequest,
                  payload: response,
                }),
              );

              if (response && response.data) {
                const ids = response.data.map(user => user.clientId);
                this.store$.dispatch(new LoadClientsByIds(ids));
              }
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadUserStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadUserStart>(actions.UsersActionTypes.LoadUserStart),
        withLatestFrom(this.store$.select(getState)),
        switchMap(([action, state]) => {
          const { id, forced } = action;
          const userFromState: User = state.user.payload;
          const isDataRelevant = Boolean(userFromState) && userFromState.id === id;

          const userToShow = isDataRelevant ? userFromState : findIncompleteUserInState(state, id);

          let observable: Observable<User> = this.usersApiService.getUser(id);

          if (!forced && isDataRelevant) {
            observable = of({ ...userFromState });
          }

          this.store$.dispatch(
            new actions.SetUserState({
              isLoading: true,
              payload: userToShow,
            }),
          );

          return observable.pipe(
            catchError((): Observable<User> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((user: User) => {
              this.store$.dispatch(
                new actions.SetUserState({
                  isLoading: false,
                  payload: user,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
