import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gc-single-video-fullscreen',
  templateUrl: './single-video-fullscreen.component.html',
  styleUrls: ['./single-video-fullscreen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleVideoFullscreenComponent {
  @Input() videoId: string;
  @Output() close = new EventEmitter<void>();

  onClose() {
    this.close.emit();
  }
}
