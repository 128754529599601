import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { getTimestampFromString } from '../../lib/getTimestampFromString';

@Pipe({
  name: 'formattedDateUtc',
})
export class FormattedDateUtcPipe implements PipeTransform {
  datePipe = new DatePipe('en-GB');

  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }

    const timestamp = getTimestampFromString(value);

    return this.datePipe.transform(timestamp, 'y-MM-dd', '+0000');
  }
}
