import { NgModule } from '@angular/core';
import { StorageService } from './storage.service';
import { LocalStorageService } from './local-storage.service';
import { SessionStorageService } from './session-storage.service';
import { FallbackStorageService } from '@gelato-api-ui/sdk/src/lib/storage/fallback-storage.service';
import { StorageValueService } from '@gelato-api-ui/sdk/src/lib/storage/storage-value.service';

@NgModule({
  providers: [
    { provide: StorageService, useClass: LocalStorageService },
    SessionStorageService,
    FallbackStorageService,
    StorageValueService,
  ],
})
export class StorageModule {}
