import * as R from 'ramda';
import { User } from '../../users/lib/user';
import { State } from '../../users/+state/users.reducer';

export const findIncompleteUserInState = (state: State, userId: string): User => {
  if (!state || !userId) {
    return null;
  }

  if (state.userList && state.userList.payload && state.userList.payload.data) {
    return { ...R.find(R.propEq('id', userId))(state.userList.payload.data) };
  }

  return null;
};
