import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take, tap } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import { LoadStoresList } from '@api-ui-app/src/app/ngrx/e-commerce-stores.actions';
import { getDefaultStoresListRequest } from '@gelato-api-ui/core/e-commerce/helpers/getDefaultStoresListRequest';
import { getStoresList } from '@api-ui-app/src/app/ngrx/e-commerce-stores.selector';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { AddStoreProductFlowService } from '@api-ui-app/src/app/shared/services/add-store-product-flow.service';

@Injectable()
export class HeaderCreateMenuFacade {
  readonly isGelatoUser$ = this.authService.isGelatoUser();
  readonly clientId$ = this.authService.getClientId();
  readonly stores$ = this.store.select(getStoresList);

  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly addStoreProductFlowService: AddStoreProductFlowService,
  ) {}

  loadStoresList() {
    this.clientId$
      .pipe(
        take(1),
        tap((clientId: string) => {
          this.store.dispatch(new LoadStoresList(getDefaultStoresListRequest(clientId), false));
        }),
      )
      .subscribe();
  }

  placeAnOrder() {
    this.router.navigate(['/checkout/new']);
  }

  addNewProduct() {
    this.stores$
      .pipe(
        take(1),
        tap((stores: EStore[]) => {
          if (stores?.length === 1) {
            this.addStoreProductFlowService.initiate(stores?.[0]);
          } else {
            this.router.navigate(['/stores/list']);
          }
        }),
      )
      .subscribe();
  }
}
