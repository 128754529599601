import { DesignPage, DesignSpread, DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { PrintSide } from '../print-side.enum';

export const getSpreadByName = (designData: DesignStructure, name: string): DesignSpread =>
  designData.spreads.find(s => s.name === name);

export const isNotEmptyPage = (page: DesignPage): boolean => {
  if (!page?.groups?.media) {
    return false;
  }

  return page.groups.media.length > 0;
};

export const isNotEmptySpread = (spread: DesignSpread): boolean => {
  if (!spread?.pages) {
    return false;
  }

  const notEmptyPages = spread.pages.filter(p => isNotEmptyPage(p));
  return notEmptyPages.length > 0;
};

export const detectPrintSide = (designData: DesignStructure, defaultValue: PrintSide): PrintSide => {
  if (!designData?.spreads) {
    return defaultValue;
  }

  const isFrontHasMedia = isNotEmptySpread(getSpreadByName(designData, 'front'));
  const isBackHasMedia = isNotEmptySpread(getSpreadByName(designData, 'back'));

  if (isFrontHasMedia && isBackHasMedia) {
    return PrintSide.Both;
  }

  if (isFrontHasMedia) {
    return PrintSide.Front;
  }

  if (isBackHasMedia) {
    return PrintSide.Back;
  }

  return defaultValue;
};
