import { Observable } from 'rxjs';
import { AjaxRequest } from 'rxjs/ajax';
import { HttpHeadersCallback } from '@gelato-api-ui/core/api/http-headers-callback';
import { ApiResponseType } from '@gelato-api-ui/core/api/api-response-type.enum';
import { caller } from '@gelato-api-ui/core/api/caller';

type Result = (url: string, options: AjaxRequest) => Observable<string>;

export function callerText(host: string, method: string = 'GET', defaultHeadersCallback?: HttpHeadersCallback): Result {
  return caller(host, method, defaultHeadersCallback, ApiResponseType.TEXT) as any;
}
