import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { SanityProductDetailsService } from '@product-catalogue/src/lib/product-details-shared/services/sanity-product-details.service';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { StoreProductVariantsCollection } from '@api-ui-app/src/app/ngrx/e-commerce-product-wizard.reducer';
import { ECommerceProductDetailsService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-details.service';
import { SanityProductVariantFrameAssembling } from '@gelato-api-ui/core/sanity/sanity-product-variant';
import { SanityProductVariation } from '@gelato-api-ui/core/sanity/sanity-product-variation';
import { findProductVariationByProductUid } from '@gelato-api-ui/core/sanity/helpers/findProductVariationByProductUid';
import { SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';

interface DefaultDescriptionsCollection {
  [frameAssembling: string]: string;
}

@Injectable({ providedIn: 'root' })
export class ECommerceFramedPostersProductDescriptionService {
  private readonly frameAssemblings = Object.values(SanityProductVariantFrameAssembling);

  constructor(
    private readonly sanityProductDetailsService: SanityProductDetailsService,
    private readonly eCommerceProductDetailsService: ECommerceProductDetailsService,
  ) {}

  getUpdatedProductDescription(
    productDescription: string,
    sanityProduct: SanityProduct,
    storeProductVariantsCollection: StoreProductVariantsCollection,
    storeType: EStoreType,
  ): string {
    const productVariations = this.getProductVariations(sanityProduct, storeProductVariantsCollection);
    const defaultDescriptions: DefaultDescriptionsCollection = this.getDefaultDescriptions(sanityProduct);

    return this.replaceDefaultDescription(productDescription, productVariations, defaultDescriptions, storeType);
  }

  private replaceDefaultDescription(
    productDescription: string,
    productVariations: SanityProductVariation[],
    defaultDescriptions: DefaultDescriptionsCollection,
    storeType: EStoreType,
  ): string {
    if (productDescription) {
      const replacementPair: SanityProductVariantFrameAssembling[] = this.getReplacementPair(productVariations);

      if (replacementPair?.length === 2) {
        const searchValue = defaultDescriptions[replacementPair[0]];
        const replaceValue = defaultDescriptions[replacementPair[1]];
        const normalizedSearchValue = this.eCommerceProductDetailsService.normalizeProductDescription(
          searchValue,
          storeType,
        );
        const normalizedReplaceValue = this.eCommerceProductDetailsService.normalizeProductDescription(
          replaceValue,
          storeType,
        );
        const normalizedProductDescription = this.eCommerceProductDetailsService.normalizeProductDescription(
          productDescription,
          storeType,
        );

        if (normalizedReplaceValue !== normalizedSearchValue) {
          return normalizedProductDescription.replace(searchValue, replaceValue);
        }
      }
    }

    return productDescription;
  }

  private getReplacementPair(productVariations: SanityProductVariation[]): SanityProductVariantFrameAssembling[] {
    if (this.isFrameAssemblingAvailable(productVariations, SanityProductVariantFrameAssembling.mounted)) {
      return [SanityProductVariantFrameAssembling.notMounted, SanityProductVariantFrameAssembling.mounted];
    } else if (this.isFrameAssemblingAvailable(productVariations, SanityProductVariantFrameAssembling.notMounted)) {
      return [SanityProductVariantFrameAssembling.mounted, SanityProductVariantFrameAssembling.notMounted];
    }

    return null;
  }

  private getDefaultDescriptions(sanityProduct: SanityProduct): DefaultDescriptionsCollection {
    return this.frameAssemblings.reduce(
      (acc, loopFrameAssembling: SanityProductVariantFrameAssembling) => ({
        ...acc,
        [loopFrameAssembling]: this.sanityProductDetailsService.getDescription(sanityProduct, {
          [SanityProductControlKey.frameAssembling]: loopFrameAssembling,
          productUid: undefined,
        }),
      }),
      {},
    );
  }

  private getProductUids(storeProductVariantsCollection: StoreProductVariantsCollection): string[] {
    return R.uniq(
      Object.keys(storeProductVariantsCollection).map((key: string) => storeProductVariantsCollection[key]?.productUid),
    );
  }

  private getProductVariation(sanityProduct: SanityProduct, productUid: string): SanityProductVariation {
    return findProductVariationByProductUid(sanityProduct?.productVariations, productUid);
  }

  private getProductVariations(
    sanityProduct: SanityProduct,
    storeProductVariantsCollection: StoreProductVariantsCollection,
  ): SanityProductVariation[] {
    const productUids = this.getProductUids(storeProductVariantsCollection);

    return productUids
      .map((loopProductUid: string): SanityProductVariation => this.getProductVariation(sanityProduct, loopProductUid))
      .filter(Boolean);
  }

  private isFrameAssemblingAvailable(
    sanityProductVariations: SanityProductVariation[],
    frameAssembling: SanityProductVariantFrameAssembling,
  ): boolean {
    return (sanityProductVariations || []).some(
      (loopProductVariation: SanityProductVariation) =>
        loopProductVariation?.[SanityProductControlKey.frameAssembling] === frameAssembling,
    );
  }
}
