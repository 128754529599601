import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'gd-value-change-indicator',
  templateUrl: './value-change-indicator.component.html',
  styleUrls: ['./value-change-indicator.component.scss'],
})
export class ValueChangeIndicatorComponent implements OnInit {
  @Input()
  delta = null;

  @Input()
  showPercentSign = false;

  constructor() {}

  ngOnInit() {}
}
