import * as R from 'ramda';
import { ErrorData } from '../shared/lib/error-data';

export const getFormFieldError = (error: ErrorData, fieldName: string): string => {
  if (!error || !error.details || !fieldName) {
    return null;
  }

  const fieldSpecificError = R.find(R.propEq('reference', fieldName), error.details);

  if (!fieldSpecificError) {
    return null;
  }

  return fieldSpecificError.message;
};

export const removeFormFieldError = (error: ErrorData, fieldName: string): ErrorData => {
  if (!error || !error.details || !fieldName) {
    return null;
  }

  error.details = error.details.filter(detail => detail.reference !== fieldName);

  return error;
};
