import { ProductionConfigurationCondition } from './production-configuration-condition';
import { ProductionConfigurationAction } from './production-configuration-action';
import { ProductionConfigurationStatus } from './production-configuration-status.enum';

export class ProductionConfigurationCreateRequest {
  constructor(
    public clientId: string,
    public name: string,
    public conditions: ProductionConfigurationCondition[],
    public actions: ProductionConfigurationAction[],
    public status: ProductionConfigurationStatus,
  ) {}
}
