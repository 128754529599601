export enum EStoreType {
  MANUAL = 'manual',
  GELATO = 'gelato',
  BRANDING = 'branding',
  TEMPLATE = 'template',
  MOCKUP = 'mockup',
  SHOPIFY = 'shopify',
  ETSY = 'etsy',
  WOOCOMMERCE = 'woocommerce',
  AMAZON = 'amazon',
  WIX = 'wix',
  SQUARESPACE = 'squarespace',
  BIGCOMMERCE = 'bigcommerce',
}
