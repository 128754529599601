import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gc-single-video',
  templateUrl: './single-video.component.html',
  styleUrls: ['./single-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleVideoComponent implements OnInit {
  @Input() videoId: string;
  @Output() videoStarted = new EventEmitter();

  playVideo$ = new BehaviorSubject<string>(null);

  constructor() {}

  ngOnInit() {}

  get backgroundImage() {
    return `url('${this.imgUrl}')`;
  }

  get imgUrl() {
    return this.videoId ? `https://img.youtube.com/vi/${this.videoId}/maxresdefault.jpg` : '';
  }

  playVideo(id: string) {
    this.videoStarted.emit();
    this.playVideo$.next(id);
  }
}
