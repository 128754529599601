import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { State } from '../e-commerce-products.reducer';

export const findECommerceProductInState = (state: State, id: string): EProductWithVariants => {
  if (!state || !id) {
    return null;
  }

  if (state.product && state.product.payload && state.product.payload.id === id) {
    return { ...state.product.payload };
  }

  return null;
};
