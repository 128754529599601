import { createSelector } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { State } from './countries.reducer';

export const getState = (state: AppState): State => state.countries;

export const getIsLoaded = createSelector(getState, (state: State): boolean => state.isLoaded);

export const getSupportedCountries = createSelector(getState, (state: State): string[] => state.supportedCountries);

export const getRegionsToCountries = createSelector(
  getState,
  (state: State): Record<string, string[]> => state.regionsToCountries,
);

export const getCountriesToRegions = createSelector(getRegionsToCountries, (state): Record<string, string> => {
  const res = {};
  if (!state) {
    return null;
  }
  Object.keys(state).forEach(region => state[region].forEach(country => (res[country] = region)));
  return res;
});
