import { EMPTY, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, first, mergeMap, switchMap } from 'rxjs/operators';
import { ajax, AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import { ApiResponse } from '@gelato-api-ui/core/api/api-response.interface';
import { ApiResponseType } from '@gelato-api-ui/core/api/api-response-type.enum';
import { HttpHeaders } from '@gelato-api-ui/core/api/http-headers';
import { HttpHeadersCallback } from '@gelato-api-ui/core/api/http-headers-callback';
import { Query } from '@gelato-api-ui/core/api/query';
import { getCallerConfig } from '@gelato-api-ui/core/api/helpers/getCallerConfig';
import { isRequestFailed } from '@gelato-api-ui/core/api/helpers/isRequestFailed';
import { getResponsePayloadObject } from '@gelato-api-ui/core/api/helpers/getResponsePayloadObject';
import { logAjaxRequest } from '@gelato-api-ui/core/api/helpers/logAjaxRequest';

interface Response<T> {
  id: number;
  response: ApiResponse<T>;
}

let queryId = 1;
const parallelRequestsLimit = 16;
const executingQueries = new Set<number>();

const queue$ = new Subject<Query>();
const response$ = new Subject<Response<any>>();
const error$ = new Subject<Response<any>>();

const processor$: Observable<Response<any>> = queue$.pipe(
  mergeMap(
    query =>
      execute(query).pipe(
        catchError(err => {
          if (err && err.id) {
            executingQueries.delete(err.id);
          }

          error$.next(err);
          return EMPTY;
        }),
      ),
    parallelRequestsLimit,
  ),
);

processor$.subscribe(r => {
  executingQueries.delete(r.id);
  response$.next(r);
});

export function addRequestToQueue<T>(
  host: string,
  method: string,
  responseType: ApiResponseType,
  defaultHeadersCallback: HttpHeadersCallback,
  url: string,
  options: AjaxRequest,
): Subject<ApiResponse<T>> {
  const id = queryId++;
  const req = new Subject<ApiResponse<T>>();

  const responseSubscription = response$.subscribe(
    r => {
      if (r.id === id) {
        req.next(r.response);
        req.complete();
        responseSubscription.unsubscribe();
      }
    },
    () => {
      req.complete();
      responseSubscription.unsubscribe();
    },
    () => {
      req.complete();
      responseSubscription.unsubscribe();
    },
  );

  const errorSubscription = error$.subscribe(
    err => {
      if (err && err.id && err.response && err.id === id) {
        req.error(err.response);
        req.complete();
        errorSubscription.unsubscribe();
      }
    },
    () => {
      req.complete();
      errorSubscription.unsubscribe();
    },
    () => {
      req.complete();
      errorSubscription.unsubscribe();
    },
  );

  const query: Query = {
    id,
    host,
    url,
    method,
    responseType,
    options,
    defaultHeadersCallback,
  };

  queue$.next(query);

  return req;
}

function execute<T>(q: Query): Observable<Response<T>> {
  if (executingQueries.has(q.id)) {
    return EMPTY;
  }

  executingQueries.add(q.id);

  return q.defaultHeadersCallback().pipe(
    first(),
    switchMap((defaultHeaders: HttpHeaders) => {
      const config: AjaxRequest = getCallerConfig(q.host, q.url, q.method, q.responseType, q.options, defaultHeaders);
      return ajax(config).pipe(
        catchError(error => of(error)),
        switchMap((ajaxResponse: AjaxResponse) => {
          const endpoint = config.url;
          const requestFailed = isRequestFailed(ajaxResponse);

          logAjaxRequest(config, ajaxResponse);

          if ([ApiResponseType.BLOB, ApiResponseType.TEXT].includes(q.responseType)) {
            const payload = {
              id: q.id,
              response: ajaxResponse.response,
              endpoint,
            };

            if (requestFailed) {
              return throwError(payload);
            } else {
              return of(payload);
            }
          }

          const res: ApiResponse<T> = getResponsePayloadObject(ajaxResponse);
          const hasErrorCode = res && res.error && res.error.code !== 0;

          if (requestFailed || hasErrorCode) {
            return throwError({
              id: q.id,
              response: {
                response: res,
                endpoint,
              },
            });
          }

          return of({ id: q.id, response: { ...res, endpoint } });
        }),
      );
    }),
  );
}
