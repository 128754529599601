import { EProductStatus } from './e-product-status.enum';
import { PreviewFileType } from '@gelato-api-ui/core/preflight/preview-file-type.enum';
import { EProductVariantOption } from './e-product-variant-option';
import { EProductCollection } from '@gelato-api-ui/core/e-commerce/e-product-collection';
import { Tag } from '@gelato-api-ui/ui-kit/src/lib/tag/tag';
import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';
import { EProductPublishingErrorCode } from '@gelato-api-ui/core/e-commerce/e-product-publishing-errors.enum';
import { EProductPublishingDetailsFlag } from '@gelato-api-ui/core/e-commerce/e-product-publishing-details-flag.enum';

export class EProduct {
  constructor(
    public id: string = null,
    public externalId: string = null,
    public storeId: string = null,
    public title: string = null,
    public description: string = null,
    public category: string = null, // FIXME
    public productName: string = null, // FIXME
    public previewFileType: PreviewFileType = null,
    public productVariantPreviewScene: string = null,
    public productVariantOptions: EProductVariantOption[] = [],
    public publishingErrorCode: EProductPublishingErrorCode = null,
    public publishingErrorDetails: string = null,
    public publishingDetailsFlags: EProductPublishingDetailsFlag[] = [],
    public status: EProductStatus = null,
    public publishedAt: string = null,
    public createdAt: string = null,
    public updatedAt: string = null,
    public tags: Tag[] = [],
    public collections: EProductCollection[] = [],
    public metadata: MetadataItem[] = [],
    public hasDraft: boolean = false,
    public templateName: string = null,
  ) {}
}
