import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { ProductCatalogue } from '@gelato-api-ui/core/product-catalogue/product-catalogue';
import { ProductCatalogueSearchResponse } from '@gelato-api-ui/core/product-catalogue/product-catalogue-search-response';
import { ProductCatalogueApiService } from '@gelato-api-ui/core/product-catalogue/services/product-catalogue-api.service';
import * as actions from './product-catalogue-selection-list.actions';
import { getState } from './product-catalogue-selection-list.reducer';

@Injectable()
export class ProductCatalogueSelectionListEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private productCatalogueApiService: ProductCatalogueApiService,
  ) {}

  load$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.Load>(actions.ProductCatalogueSelectionListActionTypes.Load),
        withLatestFrom(this.store$.select(getState)),
        switchMap(([action, state]) => {
          this.store$.dispatch(
            new actions.SetState({
              ...state,
              isLoading: true,
            }),
          );

          return this.productCatalogueApiService.searchCatalogues().pipe(
            map((response: ProductCatalogueSearchResponse): ProductCatalogue[] => response.data),
            catchError((err): Observable<ProductCatalogue> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((productCatalogues: ProductCatalogue[]) => {
              this.store$.dispatch(
                new actions.SetState({
                  isLoading: false,
                  payload: productCatalogues,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
