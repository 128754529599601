import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DropdownMenuComponent } from '@gelato-api-ui/sdk/src/lib/shared/dropdown-menu/dropdown-menu.component';

@Component({
  selector: 'gd-connect-order-item-button',
  templateUrl: './connect-order-item-button.component.html',
  styleUrls: ['./connect-order-item-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectOrderItemButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() isMobile: boolean;
  @Output() connectToNewProductButtonClick = new EventEmitter<void>();
  @Output() connectToExistingProductButtonClick = new EventEmitter<void>();
  @ViewChild('dropdownMenu') dropdownMenu: DropdownMenuComponent;

  constructor() {}

  ngOnInit(): void {}

  onConnectToNewProductButtonClick() {
    this.connectToNewProductButtonClick.emit();
    this.dropdownMenu.closeMenu();
  }

  onConnectToExistingProductButtonClick() {
    this.connectToExistingProductButtonClick.emit();
    this.dropdownMenu.closeMenu();
  }
}
