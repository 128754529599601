import { createSelector } from '@ngrx/store';
import * as R from 'ramda';
import { AppState } from '../app.state';
import { State } from './user-roles.reducer';
import { UserRoleObject } from '../shared/lib/user-role-object';
import { AccessGroup } from '../shared/lib/access-group';
import { AdminUserRoles } from '../shared/lib/admin-user-roles';

export const getState = (state: AppState): State => state.userRoles;

export const getIsLoadingFlag = createSelector(getState, (state: State): boolean => state.isLoading);

export const getAllUserRoles = createSelector(getState, (state: State): UserRoleObject[] =>
  state.userRoles ? state.userRoles : null,
);

export const getVisibleUserRoles = createSelector(getState, (state: State): UserRoleObject[] =>
  state.userRoles
    ? R.filter((userRole: UserRoleObject) => AdminUserRoles.indexOf(userRole.role) === -1, state.userRoles)
    : null,
);

export const getProductionPartnersUserRoles = createSelector(getAllUserRoles, (userRoles: UserRoleObject[]) =>
  userRoles
    ? R.filter((loopUserRole: UserRoleObject): boolean => /production-partner/i.test(loopUserRole.role), userRoles)
    : null,
);

export const getHasProductionPartnersUserRoles = createSelector(
  getProductionPartnersUserRoles,
  (productionPartnersUserRoles: UserRoleObject[]) =>
    Boolean(productionPartnersUserRoles) && productionPartnersUserRoles.length > 0,
);

export const getAccessGroups = createSelector(getState, (state: State): AccessGroup[] =>
  state.accessGroups ? state.accessGroups : null,
);
