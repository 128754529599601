import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { map } from 'rxjs/operators';
import { PaymentDetails, PaymentDetailsSearchResponse } from '../lib/api';

@Injectable({ providedIn: 'root' })
export class PaymentDetailsApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  search() {
    return this.apiRequest
      .post<PaymentDetailsSearchResponse>('/api/payments/v1/paymentDetails:search')
      .pipe(map(res => res.data));
  }

  delete(id: string) {
    return this.apiRequest.delete<PaymentDetails>(`/api/payments/v1/paymentDetails/${id}`).pipe(map(res => res.data));
  }

  setUsageOrder(id: string, body: { isDefault: boolean; isBackup: boolean }) {
    return this.apiRequest
      .put<PaymentDetails>(`/api/payments/v1/paymentDetails/${id}`, { body })
      .pipe(map(res => res.data));
  }
}
