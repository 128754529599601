import { Tag } from '@gelato-api-ui/ui-kit/src/lib/tag/tag';
import { EProductTagsCollection } from '@gelato-api-ui/core/e-commerce/e-product-tags-collection';

export const getTagsCollection = (tags: Tag[]): EProductTagsCollection =>
  (tags || []).reduce(
    (acc: EProductTagsCollection, loopTag: Tag) => ({
      ...acc,
      [loopTag.id]: loopTag.title,
    }),
    {},
  );
