import { Injectable } from '@angular/core';
import { StorageService } from '@gelato-api-ui/sdk/src/lib/storage/storage.service';
import { StorageValueService } from '@gelato-api-ui/sdk/src/lib/storage/storage-value.service';

@Injectable()
export class FallbackStorageService implements StorageService {
  private values = {};

  constructor(private readonly storageValueService: StorageValueService) {}

  clear(): void {
    this.values = {};
  }

  getItem<T>(key: string, defaultValue?: T): T {
    return this.storageValueService.fromRawValue<T>(this.values[key], defaultValue);
  }

  removeItem(key: string): void {
    delete this.values[key];
  }

  setItem<T>(key: string, value: T): void {
    this.values[key] = this.storageValueService.toRawValue(value);
  }
}
