import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserCurrencyService } from '@api-ui-app/src/app/shared/services/user-currency.service';
import { PreflightPreviewsArchiveResponse } from '@gelato-api-ui/core/preflight/preflight-previews-download';
import { ECommerceProductWizardPricesService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard-prices.service';
import { ECommerceProductVariantsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-variants-api.service';
import { PreflightApiService } from '@gelato-api-ui/core/preflight/services/preflight-api.service';
import { PageLeaveConfirmationService } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation.service';
import { BeforeUnloadEventWarningService } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/before-unload-event-warning.service';
import { requestPreviewsArchive, setPreviewsArchiveTaskId, setPreviewsArchiveUrl } from './previews-archive.actions';
import { PreviewsArchiveFacade } from '@api-ui-app/src/app/previews-archive/+state/previews-archive.facade';
import { PreviewsArchiveService } from '@api-ui-app/src/app/previews-archive/+state/previews-archive.service';

@Injectable()
export class PreviewsArchiveEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<{}>,
    private readonly eCommerceProductWizardPricesService: ECommerceProductWizardPricesService,
    private readonly userCurrencyService: UserCurrencyService,
    private readonly eCommerceProductVariantsApiService: ECommerceProductVariantsApiService,
    private readonly preflightApiService: PreflightApiService,
    private readonly pageLeaveConfirmationService: PageLeaveConfirmationService,
    private readonly beforeUnloadEventWarningService: BeforeUnloadEventWarningService,
    private readonly facade: PreviewsArchiveFacade,
    private readonly service: PreviewsArchiveService,
  ) {}

  requestPreviewsArchive$ = createEffect(() =>
    this.actions$.pipe(
      ofType(requestPreviewsArchive),
      withLatestFrom(this.facade.selectedProductFromSanityContent$),
      switchMap(([{ payload }, product]) => {
        const { request, previewsAmount } = payload;
        return this.preflightApiService.requestPreviewsDownload(request).pipe(
          map(({ task_id }: PreflightPreviewsArchiveResponse) => {
            const data = {
              taskId: task_id,
              productTitle: product.nameProductFor1 || product.title,
              previewsAmount,
            };
            return setPreviewsArchiveTaskId({ payload: data });
          }),
        );
      }),
    ),
  );

  waitForPreviewsArchiveReady$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setPreviewsArchiveTaskId),
        tap(({ payload: { taskId } }) => {
          // we should have multiple tasks in parallel, because of that using tap instead of mergeMap,
          // new effect, completed previous observable, which is not desired in this case
          this.service.setTimerToCheckTaskStatus(taskId);
        }),
      ),
    { dispatch: false },
  );

  downloadPreviewsArchive$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(setPreviewsArchiveUrl),
        filter(({ payload: { url } }) => Boolean(url)),
        tap(({ payload: { url } }) => this.service.downloadFileByUrl(url)),
      );
    },
    { dispatch: false },
  );
}
