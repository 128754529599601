import { Injectable } from '@angular/core';
import { filter, first, skipWhile, tap } from 'rxjs/operators';
import { getSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { DashboardInitUrlQuery } from '@api-ui-app/src/app/dashboard/types/dashboard-init-url-query';
import { SplashMessageFacade } from '@api-ui-app/src/app/shared/splash-message/+state/splash-message/splash-message.facade';
import { PreserveQueryParamsService } from '@api-ui-app/src/app/dashboard/services/preserve-query-params.service';
import { applyPromotion, loadPromotions } from '@api-ui-app/src/app/ngrx/discounts.actions';
import { Params } from '@angular/router';
import { getPromotions } from '../../ngrx/discounts.selectors';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  clientId$ = this.store.select(getSelectedClientId);
  discounts$ = this.store.select(getPromotions).pipe(skipWhile(promotion => !promotion));

  constructor(
    private readonly store: Store<AppState>,
    private readonly splashMessageFacade: SplashMessageFacade,
    private preserveQueryParamsService: PreserveQueryParamsService,
  ) {}

  preserveVoucherQueryParams(queryParams: Params): void {
    const { clientId, voucherId, voucherEvent } = queryParams;

    if (voucherId && voucherEvent && clientId) {
      this.preserveQueryParamsService.saveParams({ clientId, voucherId, voucherEvent });
    }
  }

  handleVoucherParams(params: Params, clearDashboardQueryParams: () => void): void {
    const preserveQueryParams = this.preserveQueryParamsService.getParams() || {};
    const emailClientId =
      params[DashboardInitUrlQuery.CLIENT_ID] || preserveQueryParams[DashboardInitUrlQuery.CLIENT_ID];
    const voucherId = params[DashboardInitUrlQuery.VOUCHER_ID] || preserveQueryParams[DashboardInitUrlQuery.VOUCHER_ID];
    const voucherEvent =
      params[DashboardInitUrlQuery.VOUCHER_EVENT] || preserveQueryParams[DashboardInitUrlQuery.VOUCHER_EVENT];

    if (!voucherId || !voucherEvent || !emailClientId) {
      clearDashboardQueryParams();
      return;
    }

    this.clientId$
      .pipe(
        first(),
        tap(clientId => {
          if (emailClientId !== clientId) {
            this.splashMessageFacade.triggerAdd({
              textKey: 'txt_discount_for_another_account',
              color: 'red',
              closeable: true,
              icon: 'assets/icons/warning-red.svg',
              id: 'promotion-notification',
            });
            clearDashboardQueryParams();
            this.preserveQueryParamsService.removeParams();

            return;
          }

          this.applyPromotion(clientId, voucherId, voucherEvent, true);
          clearDashboardQueryParams();
        }),
      )
      .subscribe();
  }

  getListOfPromotions(): void {
    this.clientId$
      .pipe(
        filter(clientId => !!clientId),
        tap(clientId => this.store.dispatch(loadPromotions({ clientId }))),
      )
      .subscribe();
  }

  applyPromotion(clientId: string, voucherId: string, voucherEvent: string, removeParams: boolean = false): void {
    this.store.dispatch(applyPromotion({ clientId, voucherId, voucherEvent }));

    if (removeParams) {
      this.preserveQueryParamsService.removeParams();
    }
  }
}
