import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  pageLeaveConfirmationReducerToken,
  reducer,
} from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation.reducer';
import { StoreModule } from '@ngrx/store';
import { pageLeaveConfirmationFeatureName } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation.feature';
import { BeforeUnloadEventWarningService } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/before-unload-event-warning.service';
import { PageLeaveConfirmationService } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation.service';
import { PageLeaveConfirmationModalService } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation-modal.service';
import { PageLeaveConfirmationGuard } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation.guard';

@NgModule({
  declarations: [],
  imports: [CommonModule, StoreModule.forFeature(pageLeaveConfirmationFeatureName, pageLeaveConfirmationReducerToken)],
  providers: [
    {
      provide: pageLeaveConfirmationReducerToken,
      useValue: reducer,
    },
    BeforeUnloadEventWarningService,
    PageLeaveConfirmationService,
    PageLeaveConfirmationModalService,
    PageLeaveConfirmationGuard,
  ],
})
export class PageLeaveConfirmationModule {}
