import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gd-form-message, gc-form-message',
  templateUrl: './form-message.component.html',
  styleUrls: ['./form-message.component.scss'],
})
export class FormMessageComponent {
  @Input() className: string = null;
  @Input() isVisible = false;
  @Input() message: string = null;
  @Output() dismiss = new EventEmitter();

  onDismiss() {
    this.dismiss.emit();
  }
}
