import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import * as actions from './account-activation-steps.actions';
import { OnboardingStepName } from '../dashboard/types/onboarding-step-name.enum';
import { OrdersDiscount } from '@api-ui-app/src/app/ngrx/helpers/getDiscountedOrders';
import { PromotionTemplate } from '@api-ui-app/src/app/shared/voucher/voucher';

export interface AccountActivationSteps {
  isPrepaid: boolean;
  showWelcomeBanner: boolean;
  showOnboardingSteps: boolean;
  canPlaceOrder: boolean;
  companyDetailsMissing: boolean;
  creditCardMissing: boolean;
  ordersDiscount: OrdersDiscount;
  firstAvailableStore: EStore;
  firstAvailableProduct: EProductAbbridged;
  completedOnboardingStepNames: OnboardingStepName[];
  hasMultipleStores: boolean;
  ordersPromotions: PromotionTemplate[];
}

export interface State {
  paidOrdersCount: number;
  firstAvailableStore: EStore;
  firstAvailableProduct: EProductAbbridged;
  hasMultipleStores: boolean;
}

export const initialState: State = {
  paidOrdersCount: null,
  firstAvailableStore: null,
  firstAvailableProduct: null,
  hasMultipleStores: false,
};

export function reducer(state = initialState, action: actions.AccountActivationStepsActions): State {
  switch (action.type) {
    case actions.AccountActivationStepsActionTypes.IncreasePaidOrdersCount:
      return {
        ...state,
        paidOrdersCount: state.paidOrdersCount + 1,
      };
    case actions.AccountActivationStepsActionTypes.SetPaidOrdersCount:
      return {
        ...state,
        paidOrdersCount: action.payload,
      };
    case actions.AccountActivationStepsActionTypes.SetFirstAvailableStore:
      return {
        ...state,
        firstAvailableStore: action.payload,
      };
    case actions.AccountActivationStepsActionTypes.SetFirstAvailableProduct:
      return {
        ...state,
        firstAvailableProduct: action.payload,
      };
    case actions.AccountActivationStepsActionTypes.SetHasMultipleStoresFlag:
      return {
        ...state,
        hasMultipleStores: action.payload,
      };
    default:
      return state;
  }
}
