import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { VoucherService } from '@api-ui-app/src/app/dashboard/services/voucher.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
  constructor(private authService: AuthService, private voucherService: VoucherService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    this.voucherService.preserveVoucherQueryParams(route.queryParams);

    return this.authService.requireAuthorisedUserWithReadPermission('order', 'analytics', route);
  }
}
