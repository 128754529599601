export enum EProductWizardMode {
  CREATE_PRODUCT = 'create_product',
  CREATE_PRODUCT_TEMPLATE = 'create_product_template',
  EDIT_PRODUCT_TEMPLATE = 'edit_product_template',
  EDIT_DRAFT = 'edit_draft',
  EDIT_DESIGNS = 'edit_designs',
  EDIT_MEDIA = 'edit_media',
  EDIT_DETAILS = 'edit_details',
  EDIT_PRICES = 'edit_prices',
  BULK_CONNECT = 'bulk_connect',
}
