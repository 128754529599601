import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { BillingType } from '@gelato-api-ui/core/clients/billing-type.enum';
import { ShippingPriceType } from '@gelato-api-ui/core/clients/shipping-price-type.enum';
import * as actions from './company-details.actions';

export interface State {
  isLoading: boolean;
  payload: CompanyDetails;
}

export const initialState: State = {
  isLoading: false,
  payload: null,
};

export const getState = (state: AppState): State => state.companyDetails;

export const getCompanyDetails = createSelector(getState, (state: State): CompanyDetails => state.payload);
export const getQuestionnaire = createSelector(getCompanyDetails, details => details?.questionnaire);

export const getIsCompanyDetailsEmpty = createSelector(
  getCompanyDetails,
  (details: CompanyDetails): boolean =>
    !details || !details.address || !details.postCode || !details.city || !details.countryIsoCode,
);

export const getCompanyDetailsCountryCode = createSelector(
  getCompanyDetails,
  (details: CompanyDetails): string => details?.countryIsoCode,
);

export const isJapanCountry = createSelector(
  getCompanyDetailsCountryCode,
  (countryCode: string): boolean => countryCode === 'JP',
);

export const getCompanyName = createSelector(getCompanyDetails, (companyDetails: CompanyDetails): string =>
  companyDetails ? companyDetails.companyName : '',
);

export const hasBillingEntity = createSelector(getCompanyDetails, (companyDetails: CompanyDetails): boolean => {
  if (!companyDetails) {
    return false;
  }

  const mandatoryFields = [
    'companyName',
    'recipientName',
    'address',
    'postCode',
    'city',
    'countryIsoCode',
    'email',
    'billingType',
    'currency',
  ];

  return mandatoryFields.reduce(
    (result: boolean, fieldName: string): boolean => result && Boolean(companyDetails[fieldName]),
    true,
  );
});

export const getCurrency = createSelector(getCompanyDetails, (companyDetails: CompanyDetails): string => {
  if (!companyDetails) {
    return null;
  }

  switch (companyDetails.countryIsoCode) {
    case 'US':
      return 'USD';
    case 'CA':
      return 'CAD';
    case 'AU':
      return 'AUD';
    case 'GB':
      return 'GBP';
    case 'NZ':
      return 'NZD';
    case 'DK':
      return 'DKK';
    case 'NO':
      return 'NOK';
    case 'SE':
      return 'SEK';
    case 'BR':
      return 'BRL';
    default:
      return 'EUR';
  }
});

export const getBillingType = createSelector(
  getCompanyDetails,
  (companyDetails: CompanyDetails): BillingType => (companyDetails ? companyDetails.billingType : null),
);

export const getShippingPriceType = createSelector(
  getCompanyDetails,
  (companyDetails: CompanyDetails): ShippingPriceType => (companyDetails ? companyDetails.shippingPriceType : null),
);

export const isPrepaidAccount = createSelector(
  getBillingType,
  (billingType: BillingType): boolean => billingType === BillingType.PREPAID,
);

export function reducer(state = initialState, action: actions.CompanyDetailsActions): State {
  switch (action.type) {
    case actions.CompanyDetailsActionTypes.ResetState:
      return {
        ...state,
        ...initialState,
      };
    case actions.CompanyDetailsActionTypes.SetState:
      return {
        ...state,
        ...action.payload,
      };
    case actions.CompanyDetailsActionTypes.SetCompanyDetails:
      return {
        ...state,
        payload: action.payload,
      };
    case actions.CompanyDetailsActionTypes.SetQuestionnaire:
      return {
        ...state,
        payload: {
          ...state.payload,
          questionnaire: action.payload,
        },
      };
    default:
      return state;
  }
}
