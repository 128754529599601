import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import * as R from 'ramda';
import { CountryListService } from '@gelato-api-ui/sdk/src/lib/shared/country-selector/country-list.service';

@Component({
  selector: 'gc-country-selector',
  templateUrl: './country-selector.component.html',
  styleUrls: ['./country-selector.component.scss'],
})
export class CountrySelectorComponent implements OnChanges {
  @Input() disabled = false;
  @Input() value: string;
  @Input() isSearchable = true;
  @Input() showAnyCountryOption = false;
  @Input() countriesToShow: string[] = null;
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  countriesToShow$ = new BehaviorSubject<string[]>(null);
  anyCountryItemId = '';

  options$ = combineLatest([this.countryListService.getOptions(), this.countriesToShow$]).pipe(
    map(([options, countriesToShow]) => {
      const filteredOptions = Boolean(countriesToShow)
        ? R.filter(option => countriesToShow.indexOf(option.id) !== -1, options)
        : options;

      if (this.showAnyCountryOption) {
        return [
          {
            id: this.anyCountryItemId,
            name: 'txt_any_country_option',
          },
          ...filteredOptions,
        ];
      }

      return filteredOptions;
    }),
  );

  constructor(private countryListService: CountryListService) {}

  get currentValue() {
    return this.value || (this.showAnyCountryOption ? this.anyCountryItemId : undefined);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.countriesToShow) {
      this.countriesToShow$.next(changes.countriesToShow.currentValue);
    }
  }

  onSelect(countryId) {
    this.valueChange.emit(countryId);
  }
}
