import { Component, Input } from '@angular/core';
import { OrderAddress } from '../../orders/lib/order-address';

@Component({
  selector: 'gd-order-address',
  templateUrl: './order-address.component.html',
  styleUrls: ['./order-address.component.scss'],
})
export class OrderAddressComponent {
  @Input() orderAddress: OrderAddress = null;

  get phone(): string {
    return this.orderAddress?.phone ? this.orderAddress.phone.split(' ').join('') : '';
  }

  isJapan(country) {
    return country === 'JP';
  }
}
