import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';

@Component({
  selector: 'gc-download-native-app-modal',
  templateUrl: './download-native-app-modal.component.html',
  styleUrls: ['./download-native-app-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadNativeAppModalComponent {
  nativeAppLink = 'https://gelato.onelink.me/ZtTw/ms92vn5c';
  constructor(public modal: SuiModal<void, void, void>) {}

  onApprove() {
    this.modal.approve();
  }
}

export class DownloadNativeAppModal extends ComponentModalConfig<void, void, void> {
  constructor() {
    super(DownloadNativeAppModalComponent);
    this.isClosable = true;
    this.transitionDuration = 400;
    this.size = ModalSize.Tiny;
  }
}
