import { Injectable } from '@angular/core';
import { StorageService } from '@gelato-api-ui/sdk/src/lib/storage/storage.service';

export const CHECKOUT_ORDER_ID_LOCALSTORAGE_KEY = 'checkout_client_order_id';

@Injectable({
  providedIn: 'root',
})
export class CheckoutOrderIdService {
  constructor(private readonly storageService: StorageService) {}

  get(clientId: string) {
    const ids = this.storageService.getItem(CHECKOUT_ORDER_ID_LOCALSTORAGE_KEY) || {};
    return ids[clientId];
  }

  set(clientId: string, orderId: string) {
    const ids = this.storageService.getItem(CHECKOUT_ORDER_ID_LOCALSTORAGE_KEY) || {};

    ids[clientId] = orderId;

    this.storageService.setItem(CHECKOUT_ORDER_ID_LOCALSTORAGE_KEY, ids);
  }

  remove(clientId: string) {
    this.set(clientId, null);
  }
}
