import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import * as gql from 'gql-query-builder';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { GetDesignResponse, GetDesignTemplateResponse } from '@gelato-api-ui/core/designs/types/design-api';

@Injectable({ providedIn: 'root' })
export class DesignGraphQlService {
  private readonly operation = 'design';

  constructor(private readonly graphQlRequestService: GraphQlRequestService) {}

  convertDesignStructure(
    sourceDesignStructure: DesignStructure,
    sourceProductUid: string,
    sourcePageCount: number,
    destinationProductUid: string,
    sourceProductTypeUid: ProductTypeUid,
    scaleImage: boolean,
  ): Observable<DesignStructure> {
    const operation = 'convertDesignStructure';
    const query = gql.query({
      operation,
      variables: {
        sourceDesignStructure: { type: 'JSONObject!', value: sourceDesignStructure },
        sourceProductUid: { type: 'String!', value: sourceProductUid },
        sourcePageCount: { type: 'Int', value: sourcePageCount },
        destinationProductUid: { type: 'String!', value: destinationProductUid },
        sourceProductTypeUid: { type: 'String', value: sourceProductTypeUid },
        scaleImage: { type: 'Boolean', value: scaleImage },
      },
    });

    return this.graphQlRequestService
      .query(query, operation, true)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  getDesignAssetPrices(
    clientId: string,
    productTypeUid: ProductTypeUid,
    paidAssetIds: string[],
    currency: string,
    productPrice: number,
  ): Observable<number[]> {
    const operation = 'designAssetPrices';
    const query = gql.query({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        productTypeUid: { type: 'String!', value: productTypeUid },
        paidAssetIds: { type: '[String!]', value: paidAssetIds },
        currency: { type: 'String!', value: currency },
        productPrice: { type: 'Float!', value: productPrice },
      },
    });

    return this.graphQlRequestService
      .query(query, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  getDesignTemplatePrice(currency: string): Observable<number> {
    const operation = 'designTemplatePrice';
    const query = gql.query({
      operation,
      variables: {
        currency: { type: 'String!', value: currency },
      },
    });

    return this.graphQlRequestService
      .query(query, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  getDesignContentCost(
    clientId: string,
    paidAssetIds: string[],
    hasDesignTemplate: boolean,
    productUid: string,
    currency: string,
    productCost: number,
  ): Observable<number> {
    const operation = 'designContentCost';
    const query = gql.query({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        paidAssetIds: { type: '[String!]', value: paidAssetIds },
        hasDesignTemplate: { type: 'Boolean!', value: hasDesignTemplate },
        productUid: { type: 'String!', value: productUid },
        currency: { type: 'String!', value: currency },
        productCost: { type: 'Float!', value: productCost },
      },
    });

    return this.graphQlRequestService
      .query(query, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  getDesignCost(
    clientId: string,
    paidAssetIds: string[],
    hasDesignTemplate: boolean,
    productUid: string,
    pageCount: number,
    userCurrency: string,
    storeCurrency: string,
    country: string,
  ): Observable<number> {
    const operation = 'designCost';
    const query = gql.query({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        paidAssetIds: { type: '[String!]', value: paidAssetIds },
        hasDesignTemplate: { type: 'Boolean!', value: hasDesignTemplate },
        productUid: { type: 'String!', value: productUid },
        pageCount: { type: 'Int', value: pageCount },
        userCurrency: { type: 'String!', value: userCurrency },
        storeCurrency: { type: 'String!', value: storeCurrency },
        country: { type: 'String', value: country },
      },
    });

    return this.graphQlRequestService
      .query(query, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  getDesign(designId: string, productUid: string): Observable<GetDesignResponse> {
    const fieldOperation = 'model';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            designId: { type: 'String!', value: designId },
          },
          fields: [],
        },
        {
          operation: 'printAreasToUidVariants',
          variables: {
            productUid: { type: 'String!', value: productUid },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`)
      .pipe(map((response: ApolloQueryResult<any>): GetDesignResponse => response?.data?.[this.operation]));
  }

  getDesignTemplate(
    productUid: string,
    pageCount?: number,
    customWidth?: number,
    customHeight?: number,
  ): Observable<GetDesignTemplateResponse> {
    const fieldOperation = 'templateV2';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            productUid: { type: 'String!', value: productUid },
            pageCount: { type: 'Int', value: pageCount },
            customWidth: { type: 'Float', value: customWidth },
            customHeight: { type: 'Float', value: customHeight },
          },
          fields: [],
        },
        {
          operation: 'printAreasToUidVariants',
          variables: {
            productUid: { type: 'String!', value: productUid },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService.query(query, `${this.operation}:${fieldOperation}`).pipe(
      map((response: ApolloQueryResult<any>): GetDesignTemplateResponse => {
        const responsePayload = response?.data?.[this.operation];

        if (!responsePayload) {
          return null;
        }

        const { templateV2, printAreasToUidVariants } = responsePayload;

        return {
          template: templateV2,
          printAreasToUidVariants,
        };
      }),
    );
  }
}
