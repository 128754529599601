import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { Key } from '../lib/key';
import { KeySearchRequest } from '../lib/key-search-request';
import { KeySearchResponse } from '../lib/key-search-response';
import { KeyCreateRequest } from '../lib/key-create-request';
import { KeyUpdateRequest } from '../lib/key-update-request';

@Injectable({
  providedIn: 'root',
})
export class KeysApiService {
  constructor(private apiRequest: ApiRequestService) {}

  search(request: KeySearchRequest): Observable<KeySearchResponse> {
    return this.apiRequest.post<KeySearchResponse>('/api/identity/v3/keys:search', { body: request }).pipe(
      map((data: any) => ({
        data: data.data,
        pagination: data.pagination,
      })),
    );
  }

  get(keyId: string): Observable<Key> {
    if (!keyId) {
      return of(null);
    }

    return this.apiRequest.get<Key>(`/api/identity/v3/keys/${keyId}`).pipe(map(data => data.data));
  }

  create(request: KeyCreateRequest): Observable<Key> {
    return this.apiRequest.post<Key>('/api/identity/v3/keys', { body: request }).pipe(map(data => data.data));
  }

  update(keyId: string, request: KeyUpdateRequest): Observable<Key> {
    return this.apiRequest.put<Key>(`/api/identity/v3/keys/${keyId}`, { body: request }).pipe(map(data => data.data));
  }

  save(key: Key) {
    if (key.id) {
      const keyUpdateRequest: KeyUpdateRequest = new KeyUpdateRequest(
        key.clientId,
        key.name,
        key.key,
        key.type,
        key.accessScopes,
        key.status,
      );

      return this.update(key.id, keyUpdateRequest);
    }

    const keyCreateRequest: KeyCreateRequest = new KeyCreateRequest(
      key.clientId,
      key.name,
      key.key,
      key.type,
      key.accessScopes,
      key.status,
    );

    return this.create(keyCreateRequest);
  }

  activate(keyId: string): Observable<Key> {
    return this.apiRequest.put<Key>(`/api/identity/v3/keys/${keyId}:activate`).pipe(map(data => data.data));
  }

  deactivate(keyId: string): Observable<Key> {
    return this.apiRequest.put<Key>(`/api/identity/v3/keys/${keyId}:deactivate`).pipe(map(data => data.data));
  }

  delete(keyId: string): Observable<Key> {
    return this.apiRequest.delete<Key>(`/api/identity/v3/keys/${keyId}`).pipe(map(data => data.data));
  }
}
