import { Injectable } from '@angular/core';
import { DialogService } from '@gelato-api-ui/sdk/src/lib/shared/dialog/dialog.service';
import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';
import { SubscriptionPlanType } from '@api-ui-app/src/app/app-store/types/price-plan-items';
import { BaseActivationModalComponent } from '../components/base-activation-modal/base-activation-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ActivationConfirmationModalService {
  private plusModalData = {
    data: {
      modalType: ServicePlanUniqueNames.gelato_platform_plus,
      subscriptionType: SubscriptionPlanType.PLUS,
      subtitle: 'txt_activate_modal_plus_title',
      features: [
        {
          title: '30_off_all_shipping',
          subtitle: 'txt_standard_shipping_only',
          icon: '/assets/subscriptions/plus-purple-crown.svg',
        },
      ],
    },
  };
  private goldModalData = {
    data: {
      modalType: ServicePlanUniqueNames.gelato_platform_gold,
      subscriptionType: SubscriptionPlanType.GOLD,
      subtitle: 'txt_activate_modal_gold_title',
      features: [
        {
          title: 'txt_limited_time_offer_in_europe',
          subtitle: 'txt_limited_time_promo',
          icon: '/assets/subscriptions/gold-crown.svg',
        },
        {
          title: 'txt_limited_time_offer_in_usa',
          subtitle: 'txt_limited_time_promo',
          icon: '/assets/subscriptions/gold-crown.svg',
        },
        {
          title: '30_off_all_shipping',
          subtitle: 'txt_standard_shipping_only',
          icon: '/assets/subscriptions/gold-crown.svg',
        },
        {
          title: 'shipping-discount-50percent',
          subtitle: 'txt_standard_shipping_only',
          icon: '/assets/subscriptions/gold-crown.svg',
        },
      ],
    },
  };
  constructor(private readonly dialogService: DialogService) {}

  openConfirmationModal(type: SubscriptionPlanType) {
    return this.dialogService.open(
      BaseActivationModalComponent,
      type === SubscriptionPlanType.PLUS ? this.plusModalData : this.goldModalData,
    );
  }
}
