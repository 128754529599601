import { Injectable } from '@angular/core';
import { ECommerceProductVariantOptionsService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-variant-options.service';
import { EProductData } from '@gelato-api-ui/core/e-commerce/e-product-data';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { EProductVariantOption } from '@gelato-api-ui/core/e-commerce/e-product-variant-option';

@Injectable({ providedIn: 'root' })
export class ECommerceProductDataVariantOptionsService {
  constructor(private readonly eCommerceProductVariantOptionsService: ECommerceProductVariantOptionsService) {}

  update(productData: EProductData, storeType: EStoreType): EProductData {
    const optionsCountLimit: number =
      this.eCommerceProductVariantOptionsService.getProductVariantOptionsCountLimit(storeType);
    const updatedProductData = this.getUpdatedProductData(productData, optionsCountLimit);

    if (
      storeType === EStoreType.ETSY &&
      this.eCommerceProductVariantOptionsService.hasMissingVariantOptionsCombinations(
        updatedProductData?.productVariants,
      )
    ) {
      return this.getUpdatedProductData(productData, 1);
    }

    return updatedProductData;
  }

  private getUpdatedProductData(productData: EProductData, optionsCountLimit: number): EProductData {
    const productVariants: EProductVariant[] = this.getUpdatedProductVariants(productData, optionsCountLimit);
    const productVariantOptions: EProductVariantOption[] =
      this.eCommerceProductVariantOptionsService.extractUsedOptionsFromVariants(productVariants);

    return {
      ...productData,
      product: {
        ...productData.product,
        productVariantOptions,
      },
      productVariants,
    };
  }

  private getUpdatedProductVariants(productDataToSave: EProductData, optionsCountLimit: number): EProductVariant[] {
    return (productDataToSave?.productVariants || []).map(
      (loopProductVariant: EProductVariant): EProductVariant => ({
        ...loopProductVariant,
        variantOptions: this.eCommerceProductVariantOptionsService.mergeVariantOptions(
          productDataToSave?.product?.productVariantOptions,
          loopProductVariant?.variantOptions,
          optionsCountLimit,
        ),
      }),
    );
  }
}
