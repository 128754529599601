import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, mapTo, startWith } from 'rxjs/operators';
import * as R from 'ramda';

export function isRouteMatch(router: Router, equalsTo: string): Observable<boolean> {
  const INITIAL_LOAD = 'initial load';

  return router.events.pipe(
    startWith(INITIAL_LOAD),
    filter(event => event instanceof NavigationEnd || event === INITIAL_LOAD),
    mapTo(router.routerState.snapshot.url),
    map<string, boolean>(R.compose(R.equals(equalsTo), R.head, R.split('#'))),
    distinctUntilChanged(),
  );
}
