import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { PageLeaveConfirmationModalService } from './page-leave-confirmation-modal.service';
import { PageLeaveConfirmationService } from './page-leave-confirmation.service';

@Injectable()
export class PageLeaveConfirmationGuard implements CanDeactivate<any> {
  constructor(
    private readonly pageLeaveConfirmationService: PageLeaveConfirmationService,
    private readonly pageLeaveConfirmationModalService: PageLeaveConfirmationModalService,
  ) {}

  canDeactivate(component, currentRoute, currentState, nextState): Promise<boolean> {
    return this.pageLeaveConfirmationService.canDeactivate(currentState, nextState, () =>
      this.pageLeaveConfirmationModalService.show(),
    );
  }
}
