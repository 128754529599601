import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { loadImage } from '@gelato-api-ui/core/dom/helpers/loadImage';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gd-product-preview, gc-product-preview',
  templateUrl: './product-preview.component.html',
  styleUrls: ['./product-preview.component.scss'],
})
export class ProductPreviewComponent implements OnChanges, OnInit {
  @Input() url: string = null;
  @Input() isLoading: boolean;

  loadedImageUrl: string = null;
  isLoading$ = new BehaviorSubject(false);

  delayLoadingTimeout = null;
  TIMEOUT_VALUE = 200;

  ngOnInit() {
    // we start from loading state
    this.isLoading$.next(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.url) {
      const url = changes.url.currentValue;
      const previousUrl = changes.url.previousValue;

      if (url) {
        if (url !== previousUrl) {
          // timeout to avoid blinking if image load is less than 200ms
          // case: replace image url -> image is loaded fast, but we can see a spinner for a bit
          this.delayLoadingTimeout = setTimeout(() => this.isLoading$.next(true), this.TIMEOUT_VALUE);

          loadImage(url)
            .then(() => {
              this.loadedImageUrl = url;
              clearTimeout(this.delayLoadingTimeout);
              this.isLoading$.next(false);
            })
            .catch(() => {
              this.loadedImageUrl = null;
              clearTimeout(this.delayLoadingTimeout);
              this.isLoading$.next(false);
            });
        }
      } else {
        this.loadedImageUrl = null;
        clearTimeout(this.delayLoadingTimeout);
        this.isLoading$.next(false);
      }
    }

    if (changes.isLoading) {
      this.isLoading$.next(changes.isLoading.currentValue);
    }
  }
}
