import * as R from 'ramda';

export const noop = R.F;
export const isDefined = R.compose(R.not, R.equals('Undefined'), R.type);
export const isFunction = R.compose(R.equals('Function'), R.type);
export const min = R.reduce(R.min, Infinity);
export const max = R.reduce(R.max, -Infinity);
export const compact = (list: any) => R.reject(value => R.isNil(value) || R.isEmpty(value))(list);
export const isNotNil = R.compose(R.not, R.isNil);
export const filterByRegex = (regex, fields) => (list: any[]) =>
  list.filter(item => R.values(R.pick(fields, item)).filter(prop => regex.test(prop)).length > 0);

export function patchState<T>(state: T, changes: Partial<T>): T {
  return R.merge(state, changes) as any;
}

export function getEntity<T>(id: string | number, collection: R.Dictionary<T>): T {
  return collection[id];
}

export function selectEntitiesByIds<T>(ids: string[], collection: R.Dictionary<T>): R.Dictionary<T> {
  return R.pick(ids, collection);
}

export function indexBy<T>(fn: (a: T) => string): (list: T[]) => R.Dictionary<T> {
  return list => R.indexBy(fn, list);
}

export function findByQuery<T>(query: string, fields: string[]): (list: T[]) => T[] {
  const regexp = new RegExp(query, 'gi');
  return R.filter<T>(
    R.pipe(
      fields ? R.pick(fields) : R.identity,
      R.values,
      R.all(s => regexp.test(R.toString(s))),
    ),
  );
}

export function findWhere<T>(where: any, list: ReadonlyArray<T>): T | null {
  return R.find(R.where(where))(list) as T;
}

export const _ = {
  compact,
  filterByRegex,
  noop,
  isDefined,
  isFunction,
  min,
  max,
  isNotNil,
};
