import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SuiModule } from '@giomamaladze/ng2-semantic-ui';
import { TranslateModule } from '@ngx-translate/core';
import { ClipboardModule } from 'ngx-clipboard';
import { NgxCurrencyModule } from 'ngx-currency';
import { AutofocusFixModule } from 'ngx-autofocus-fix';
import { NgSelectModule } from '@ng-select/ng-select';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { I18nModule } from '@gelato-api-ui/sdk/src/lib/translations/i18n.module';
import { UiKitModule } from '@gelato-api-ui/ui-kit/src';
import { SdkSharedModule } from '@gelato-api-ui/sdk/src/lib/shared/sdk-shared.module';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';
import { FeaturesService } from './services/features.service';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { ClientNamePipe } from './pipes/client-name.pipe';
import { ClientDescriptionPipe } from './pipes/client-description.pipe';
import { FormattedDateTimeUtcPipe } from './pipes/formatted-date-time-utc.pipe';
import { FormattedDateUtcPipe } from './pipes/formatted-date-utc.pipe';
import { CountryNamePipe } from './pipes/country-name.pipe';
import { OrderStatusPipe } from './pipes/order-status.pipe';
import { ClientInfoSegmentComponent } from './client-info-segment/client-info-segment.component';
import { CountryMultiselectComponent } from './country-multiselect/country-multiselect.component';
import { HomeComponent } from './home/home.component';
import { NumericFieldComponent } from './numeric-field/numeric-field.component';
import { NumberOptionSelectComponent } from './number-option-select/number-option-select.component';
import { OrderStatusMultiSelectComponent } from './order-status-multi-select/order-status-multi-select.component';
import { ProductCatalogueTitlePipe } from './pipes/product-catalogue-title.pipe';
import { ProductCatalogueIdSelectorComponent } from './product-catalogue-id-selector/product-catalogue-id-selector.component';
import { ProductCatalogueSelectorComponent } from './product-catalogue-selector/product-catalogue-selector.component';
import { PricePlanIdSelectorComponent } from './price-plan-id-selector/price-plan-id-selector.component';
import { PricePlanSelectorComponent } from './price-plan-selector/price-plan-selector.component';
import { PricePlanNamePipe } from './pipes/price-plan-name.pipe';
import { PricePlanNameSelectorComponent } from './price-plan-name-selector/price-plan-name-selector.component';
import { ValueChangeIndicatorComponent } from './value-change-indicator/value-change-indicator.component';
import { AccessScopesSelectorComponent } from './access-scopes-selector/access-scopes-selector.component';
import { UserRoleSelectorComponent } from './user-role-selector/user-role-selector.component';
import { AccessLevelPipe } from './pipes/access-level.pipe';
import { ClientRelationTypeSelectorComponent } from './client-relation-type-selector/client-relation-type-selector.component';
import { BillingTypePipe } from './pipes/billing-type.pipe';
import { ShippingPriceTypePipe } from './pipes/shipping-price-type.pipe';
import { SearchByClientFieldComponent } from './search-by-client-field/search-by-client-field.component';
import { EmptyStateDescriptionComponent } from './empty-state-description/empty-state-description.component';
import { PhoneNumberInputComponent } from './phone-number-input/phone-number-input.component';
import { OrderPlacementRequirementsComponent } from './order-placement-requirements/order-placement-requirements.component';
import { CountryStateNamePipe } from './pipes/country-state-name.pipe';
import { PaymentMethodIconComponent } from './payment-method-icon/payment-method-icon.component';
import { MoneyToMinorUnitsPipe } from './pipes/money-to-minor-units.pipe';
import { MoneyFromMinorUnitsPipe } from './pipes/money-from-minor-units.pipe';
import { SplashMessageModule } from './splash-message/splash-message.module';
import { FormControlErrorComponent } from './form-control-error/form-control-error.component';
import { StripTagsPipe } from './pipes/strip-tags.pipe';
import { ImagePreviewModalComponent } from './image-preview-modal/image-preview-modal.component';
import { OrderAddressComponent } from './order-address/order-address.component';
import { ButtonTabslikeSwitcherComponent } from './button-tabslike-switcher/button-tabslike-switcher.component';
import { RadioOptionContainerComponent } from './radio-option-container/radio-option-container.component';
import { OrderStatusLabelComponent } from './order-status-label/order-status-label.component';
import { CreditCardExpiryDatePipe } from './pipes/credit-card-expiry-date.pipe';
import { NotConnectedOrderItemComponent } from './not-connected-order-item/not-connected-order-item.component';
import { ToUserNamePipe } from './pipes/to-user-name.pipe';
import { EComStoreSelectorComponent } from './e-com-store-selector/e-com-store-selector.component';
import { FinancialOrderStatusMultiSelectComponent } from './financial-order-status-multi-select-component/financial-order-status-multi-select.component';
import { FinancialOrderStatusPipe } from './financial-order-status.pipe';
import { OrderFinancialStatusLabelComponent } from './order-financial-status-label/order-financial-status-label.component';
import { ConnectOrderItemButtonComponent } from './connect-order-item-button/connect-order-item-button.component';
import { TooltipModule } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.module';
import { BillingAddressFormComponent } from './billing-address-form/billing-address-form.component';
import { DesignFeeLabelComponent } from './design-fee-label/design-fee-label.component';
import { PipesModule } from '@product-catalogue/src/lib/shared/pipes/pipes/pipes.module';
import { PhoneFieldModule } from '@gelato-api-ui/ui-kit/src/lib/phone-field/phone-field.module';
import { ClientSelectorModule } from '@gelato-api-ui/core/client-selector/client-selector.module';
import { IsStateFieldOptionalPipe } from '@api-ui-app/src/app/shared/pipes/is-state-field-optional.pipe';
import { UserPreferencesMenuComponent } from '@api-ui-app/src/app/shared/user-preferences-menu/user-preferences-menu.component';
import { OrderDeletionConfirmationModalService } from '@api-ui-app/src/app/shared/services/order-deletion-confirmation-modal.service';
import { NavigateToListService } from '@api-ui-app/src/app/shared/services/navigate-to-list.service';
import { PriceSdkModule } from '@gelato-api-ui/sdk/src/lib/shared/price-sdk/price-sdk.module';
import { CountryStateSelectorModule } from '@gelato-api-ui/sdk/src/lib/shared/country-state-selector/country-state-selector.module';
import { CountrySelectorModule } from '@gelato-api-ui/sdk/src/lib/shared/country-selector/country-selector.module';
import { CountryFlagModule } from '@gelato-api-ui/sdk/src/lib/shared/country-flag/country-flag.module';
import { JsonPrettyPrintPipe } from '@api-ui-app/src/app/shared/pipes/json-prettyprint.pipe';
import { JsonToArrayPipe } from '@api-ui-app/src/app/shared/pipes/json-to-array.pipe';

const exportedModules = [
  // Angular
  CommonModule,
  FormsModule,
  TranslateModule,
  ReactiveFormsModule,
  SuiModule,
  UiKitModule,
  // Custom
  SdkSharedModule,
  SdkPipesModule,
  ClipboardModule,
  NgxCurrencyModule,
  AutofocusFixModule,
  NgSelectModule,
  InfiniteScrollModule,
  SplashMessageModule,
  TooltipModule,
  PipesModule,
  PhoneFieldModule,
  ClientSelectorModule,
  PriceSdkModule,
  CountryFlagModule,
  CountrySelectorModule,
  CountryStateSelectorModule,
];

const exportedComponents = [
  CountryMultiselectComponent,
  ProductCatalogueSelectorComponent,
  ProductCatalogueIdSelectorComponent,
  PricePlanIdSelectorComponent,
  PricePlanNameSelectorComponent,
  PricePlanSelectorComponent,
  FinancialOrderStatusPipe,
  ClientInfoSegmentComponent,
  BillingAddressFormComponent,
  HomeComponent,
  NumericFieldComponent,
  NumberOptionSelectComponent,
  OrderStatusMultiSelectComponent,
  FinancialOrderStatusMultiSelectComponent,
  ValueChangeIndicatorComponent,
  AccessScopesSelectorComponent,
  UserRoleSelectorComponent,
  ClientRelationTypeSelectorComponent,
  SearchByClientFieldComponent,
  EmptyStateDescriptionComponent,
  PhoneNumberInputComponent,
  OrderPlacementRequirementsComponent,
  PaymentMethodIconComponent,
  FormControlErrorComponent,
  ImagePreviewModalComponent,
  OrderAddressComponent,
  OrderStatusLabelComponent,
  NotConnectedOrderItemComponent,
  ConnectOrderItemButtonComponent,
  ButtonTabslikeSwitcherComponent,
  RadioOptionContainerComponent,
  EComStoreSelectorComponent,
  OrderFinancialStatusLabelComponent,
  DesignFeeLabelComponent,
  UserPreferencesMenuComponent,
];

const exportedPipes = [
  ClientNamePipe,
  ClientDescriptionPipe,
  ProductCatalogueTitlePipe,
  PricePlanNamePipe,
  FormattedDateTimeUtcPipe,
  FormattedDateUtcPipe,
  CountryNamePipe,
  OrderStatusPipe,
  AccessLevelPipe,
  BillingTypePipe,
  ShippingPriceTypePipe,
  CountryStateNamePipe,
  MoneyToMinorUnitsPipe,
  MoneyFromMinorUnitsPipe,
  StripTagsPipe,
  CreditCardExpiryDatePipe,
  ToUserNamePipe,
  IsStateFieldOptionalPipe,
  JsonPrettyPrintPipe,
  JsonToArrayPipe,
];

@NgModule({
  imports: [RouterModule, ...exportedModules, I18nModule.forChild(), AutofocusFixModule.forRoot()],
  declarations: [...exportedComponents, ...exportedPipes],
  exports: [RouterModule, ...exportedModules, ...exportedComponents, ...exportedPipes],
  providers: [
    FeaturesService,
    ApiRequestService,
    OrderDeletionConfirmationModalService,
    NavigateToListService,
    MoneyToMinorUnitsPipe,
    MoneyFromMinorUnitsPipe,
  ],
})
export class SharedModule {}
