import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'gc-actions-panel-step-button',
  templateUrl: './actions-panel-step-button.component.html',
  styleUrls: ['./actions-panel-step-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionsPanelStepButtonComponent {
  @Input() label: string = null;
  @Input() icon: string = null;
  @Input() active: boolean;
  @Input() disabled = false;
  @Output() actionClick = new EventEmitter();

  onClick() {
    if (!this.disabled) {
      this.actionClick.emit();
    }
  }
}
