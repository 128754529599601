import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { BehaviorSubject, interval } from 'rxjs';
import {
  activateSubscriptionRepeatCount,
  cancelSubscriptionRepeatCount,
  updateSubscriptionRepeatCount,
  updateSubscriptionTime,
} from '@api-ui-app/src/app/subscriptions/constants/update-subscription-time';
import { take, tap } from 'rxjs/operators';
import {
  CONTAINER_DATA,
  InstallingGelatoPlusService,
} from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/services/installing-gelato-plus-service';

export enum SubscriptionState {
  canceling = 'canceling',
  installing = 'installing',
  prolongation = 'prolongation', // Do not show text, only spinner
  activation = 'activation',
}

export interface SubscriptionModalContentData {
  data: SubscriptionState;
}

@Component({
  selector: 'gd-installing-gelato-plus-overlay, gc-installing-gelato-plus-overlay',
  templateUrl: './installing-gelato-plus-overlay.component.html',
  styleUrls: ['./installing-gelato-plus-overlay.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InstallingGelatoPlusOverlayComponent implements OnInit {
  public isProlongation = this.componentData.data === SubscriptionState.prolongation;
  public isActivation = this.componentData.data === SubscriptionState.activation;
  public isCancelling = this.componentData.data === SubscriptionState.canceling;

  public text = {
    [SubscriptionState.canceling]: {
      header: 'txt_cancelling_gelato_plus_title',
      steps: ['txt_cancel_gelato_plus_step_1', 'txt_cancel_gelato_plus_step_2', 'txt_cancel_gelato_plus_step_3'],
    },
    [SubscriptionState.installing]: {
      header: 'txt_initiating_gelato_plus_title',
      steps: [
        'txt_initiating_gelato_plus_step_1',
        'txt_initiating_gelato_plus_step_2',
        'txt_initiating_gelato_plus_step_3',
      ],
    },
    [SubscriptionState.prolongation]: {
      header: null,
      steps: null,
    },
    [SubscriptionState.activation]: {
      header: 'txt_activating_gelato_plus_title',
      steps: ['txt_activate_gelato_plus_step_1'],
    },
  };

  get headerTextKey() {
    return this.text[this.componentData.data]?.header;
  }

  public activeInitiatingText$ = new BehaviorSubject<string>(
    this.text[this.componentData.data].steps && this.text[this.componentData.data].steps[0],
  );

  constructor(
    @Inject(CONTAINER_DATA) public componentData: SubscriptionModalContentData,
    private readonly installingGelatoPlusService: InstallingGelatoPlusService,
  ) {}

  ngOnInit() {
    if (!this.isProlongation) {
      const textSteps = this.text[this.componentData.data].steps;
      const repeatCount = this.isActivation
        ? activateSubscriptionRepeatCount
        : this.isCancelling
        ? cancelSubscriptionRepeatCount
        : updateSubscriptionRepeatCount;

      interval(updateSubscriptionTime)
        .pipe(
          take(repeatCount),
          tap(num => {
            this.activeInitiatingText$.next(textSteps[num + 1] ? textSteps[num + 1] : textSteps[textSteps.length - 1]);
            if (num >= repeatCount - 1) {
              this.installingGelatoPlusService.close();
            }
          }),
        )
        .subscribe();
    }
  }
}
