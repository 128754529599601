import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@gelato-api-ui/core/window/window';

export type RutterStoreReconnectionWindowCallback = () => void;

@Injectable()
export class RutterStoreReconnectionWindowService {
  private readonly width = 800;
  private readonly height = 700;
  private readonly windowStateCheckTimeout = 250;
  private readonly target = '_blank';
  private rutterReconnectionWindow: Window = null;
  private windowStateCheckTimer = null;
  private callback: RutterStoreReconnectionWindowCallback;

  constructor(@Inject(WINDOW) private window: Window) {
    this.window.addEventListener('message', (messageEvent: MessageEvent) => {
      // Process post messages specific to Wix stores and automatically close the window for Wix stores
      // in 5 seconds to make sure store connection action is complete
      // There is no close button shown in the modal, so it's not intuitive that users have to close the window manually
      if (['onProvisionSuccess', 'waitForTokenReceived'].includes(messageEvent?.data?.installStatus)) {
        const windowCloseTimeout = 5000;

        setTimeout(() => {
          this.close();
        }, windowCloseTimeout);
      }
    });
  }

  open(url: string, callback: RutterStoreReconnectionWindowCallback) {
    if (!url) {
      return;
    }

    this.rutterReconnectionWindow = this.window.open(url, this.target, this.features);
    this.setWindowStateCheckTimer(callback);
  }

  private close() {
    if (this.rutterReconnectionWindow) {
      this.rutterReconnectionWindow.close();
    }
  }

  private setWindowStateCheckTimer(callback: RutterStoreReconnectionWindowCallback) {
    this.clearWindowStateCheckTimer();
    this.callback = callback;

    this.windowStateCheckTimer = setInterval(() => {
      if (this.rutterReconnectionWindow.closed) {
        if (this.callback) {
          this.callback();
        }

        this.clearWindowStateCheckTimer();
      }
    }, this.windowStateCheckTimeout);
  }

  private clearWindowStateCheckTimer() {
    if (this.windowStateCheckTimer) {
      clearInterval(this.windowStateCheckTimer);
      this.windowStateCheckTimer = null;
    }

    this.callback = null;
  }

  private get features(): string {
    // Window size & position are the same as the ones produced by window.Rutter widget
    const left = Math.round(this.window.screenLeft + (this.window.outerWidth - this.width) / 2);
    const top = Math.round(this.window.screenTop + (this.window.outerHeight - this.height) / 2);

    return `popup,width=${this.width},height=${this.height},left=${left},top=${top}`;
  }
}
