import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as gql from 'gql-query-builder';
import { ApolloQueryResult } from '@apollo/client/core';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';

@Injectable({
  providedIn: 'root',
})
export class CurrencyRatesApiService {
  constructor(private readonly graphQlRequestService: GraphQlRequestService) {}

  get(sourceCurrencyIsoCode: string, destinationCurrencyIsoCode: string): Observable<number> {
    const variables = {
      sourceCurrencyIsoCode: { type: 'String!', value: sourceCurrencyIsoCode },
      destinationCurrencyIsoCode: { type: 'String!', value: destinationCurrencyIsoCode },
    };
    const query = gql.query({
      operation: 'priceList',
      fields: [
        {
          operation: 'currencyRate',
          fields: [],
          variables,
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, 'priceList:currencyRate')
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.priceList?.currencyRate));
  }
}
