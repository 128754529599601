import { NgModule } from '@angular/core';
import { HelloBarComponent } from '@api-ui-app/src/app/hello-bar/containers/hello-bar/hello-bar.component';
import { CommonModule } from '@angular/common';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';

@NgModule({
  declarations: [HelloBarComponent],
  exports: [HelloBarComponent],
  imports: [CommonModule, SdkPipesModule],
})
export class HelloBarModule {}
