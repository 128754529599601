import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { getIsLoaded, getRegionsToCountries, getSupportedCountries } from './+state/countries.selector';
import { loadCountries } from '@api-ui-app/src/app/shared/countries/+state/countries.actions';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, take, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CountriesFacade {
  isLoaded$: Observable<boolean> = this.store.select(getIsLoaded).pipe(distinctUntilChanged(), debounceTime(0));

  constructor(private store: Store<AppState>) {}

  getSupportedCountries$(): Observable<string[]> {
    return this.checkIfDataLoadedAndGetSelector<string[]>(getSupportedCountries);
  }

  getRegionsToCountries$(): Observable<Record<string, string[]>> {
    return this.checkIfDataLoadedAndGetSelector<Record<string, string[]>>(getRegionsToCountries);
  }

  private checkIfDataLoadedAndGetSelector<T>(selector): Observable<T> {
    return this.isLoaded$.pipe(
      tap(isDataLoaded => {
        if (!isDataLoaded) {
          this.loadCountries();
        }
      }),
      switchMap(() => this.store.select(selector)),
    );
  }

  private loadCountries(): void {
    this.store.dispatch(loadCountries());
  }
}
