export interface EStoreSyncStatusResponse {
  step: number;
  maxSteps: number;
  percent: number;
  message: string;
  status: EStoreSyncStatus;
}

export enum EStoreSyncStatus {
  STARTED = 'started',
  PROGRESS = 'progress',
  FINISHED = 'finished',
}
