import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'gc-full-screen-spinner',
  templateUrl: './full-screen-spinner.component.html',
  styleUrls: ['./full-screen-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullScreenSpinnerComponent {
  @Input() loadingText: string;
  @Input() zIndex: number;
  @Input() isTransparent = false;
}
