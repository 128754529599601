import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImagePreviewModalService } from '../services/image-preview-modal.service';
import { NotConnectedOrderItem } from '../../orders/lib/not-connected-order-item';

@Component({
  selector: 'gd-not-connected-order-item',
  templateUrl: './not-connected-order-item.component.html',
  styleUrls: ['./not-connected-order-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotConnectedOrderItemComponent implements OnInit {
  @Input() item: NotConnectedOrderItem;
  @Input() retailCurrency: string;
  @Input() readOnlyMode: boolean;
  @Input() isMobile: boolean;
  @Output() connectToNewProductButtonClick = new EventEmitter<void>();
  @Output() connectToExistingProductButtonClick = new EventEmitter<void>();
  @Output() ignoreButtonClick = new EventEmitter<void>();
  @Output() disconnectButtonClick = new EventEmitter<void>();

  constructor(private readonly imagePreviewModalService: ImagePreviewModalService) {}

  ngOnInit(): void {}

  connectItemToNewProduct() {
    this.connectToNewProductButtonClick.emit();
  }

  connectItemToExistingProduct() {
    this.connectToExistingProductButtonClick.emit();
  }

  ignoreItem() {
    this.ignoreButtonClick.emit();
  }

  disconnectItem() {
    this.disconnectButtonClick.emit();
  }

  showPreviewModal(previewUrl: string) {
    this.imagePreviewModalService.open(previewUrl);
  }
}
