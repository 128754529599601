import { Pipe, PipeTransform } from '@angular/core';
import { map } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { getClientNames } from '../../ngrx/client-selection-list.reducer';

@Pipe({
  name: 'clientName',
})
export class ClientNamePipe implements PipeTransform {
  clientNames$ = this.store.pipe(select(getClientNames));

  constructor(private store: Store<AppState>) {}

  transform(value: any, args?: any): any {
    return this.clientNames$.pipe(map(clientNames => clientNames[value] || ''));
  }
}
