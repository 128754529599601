import { Pipe, PipeTransform } from '@angular/core';
import { COUNTRIES_WITH_STATE } from '@api-ui-app/src/app/lib/constants';

@Pipe({
  name: 'isStateFieldOptional',
})
export class IsStateFieldOptionalPipe implements PipeTransform {
  transform(country: string): boolean {
    return !COUNTRIES_WITH_STATE.includes(country);
  }
}
