import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { catchError, debounceTime, distinctUntilChanged, filter, first, map, switchMap, tap } from 'rxjs/operators';
import * as R from 'ramda';
import { AppState } from '@api-ui-app/src/app/app.state';
import { GELATO_ADMIN_CLIENT_UID } from '@api-ui-app/src/app/lib/constants';
import { Client } from '@gelato-api-ui/core/clients/client';
import { concat, Observable, of, Subject } from 'rxjs';
import { ClientsApiService } from '@gelato-api-ui/core/clients/services/clients-api.service';
import { ClientSearchRequest } from '@gelato-api-ui/core/clients/client-search-request';
import { ClientSearchResponse } from '@gelato-api-ui/core/clients/client-search-response';
import { NgOption, NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'gd-client-selector',
  templateUrl: './client-selector.component.html',
  styleUrls: ['./client-selector.component.scss'],
})
export class ClientSelectorComponent implements OnInit, OnChanges {
  @Input() valueField: 'id' | 'name' = null;
  @Input() displayedField = 'description';
  @Input() value: string;
  @Input() placeholder = '';
  @Input() showAllClientsOption = false;
  @Input() hideGelatoAdminClient = false;
  @Input() allClientsOptionText = '';
  @Input() isSearchable = true;
  @Input() isDisabled = false;
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  @ViewChild('select') select: NgSelectComponent;

  clients$: Observable<Client[]>;
  clientsLoading = false;
  clientInput$ = new Subject<string>();
  selectedClients: Client[] = null;

  constructor(
    private store: Store<AppState>,
    private translate: TranslateService,
    private clientsApiService: ClientsApiService,
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      if (!changes.value.currentValue && this.select) {
        this.select.handleClearClick();
      } else if (changes.value.currentValue !== changes.value.previousValue && this.select) {
        const newOption = this.select.itemsList.items.find(
          (item: NgOption) => item.value[this.valueField] === changes.value.currentValue,
        );
        if (newOption) {
          this.select.select(newOption);
        } else {
          this.fetchParticularClient();
        }
      }
    }
  }

  public ngOnInit() {
    if (this.value) {
      this.fetchParticularClient();
    }
    this.loadClients();
  }

  private fetchParticularClient() {
    this.searchClients({
      ids: this.valueField === 'id' ? [this.value] : null,
      description: this.valueField === 'name' ? this.value : null,
    })
      .pipe(
        first(),
        filter((clients: Client[]) => !!clients && !!clients.length),
        tap((clients: Client[]) => {
          this.select.itemsList.setItems(clients);
          const firstOption = this.select.itemsList.items[0];
          this.select.select(firstOption);
        }),
      )
      .subscribe();
  }

  trackByFn(item: Client) {
    return item.id;
  }

  compareFn(c1: Client, c2: Client): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  private loadClients() {
    this.clients$ = concat(
      of([]), // default items
      this.clientInput$.pipe(
        distinctUntilChanged(),
        debounceTime(250),
        filter(inputText => !!inputText && !!inputText.length),
        tap(() => (this.clientsLoading = true)),
        switchMap((term: string) => {
          return this.searchClients({
            description: term,
            limit: 500,
          });
        }),
      ),
    );
  }

  private searchClients(request: Partial<ClientSearchRequest> = null): Observable<Client[]> {
    let clientSearchRequest = new ClientSearchRequest();
    clientSearchRequest = {
      ...clientSearchRequest,
      ...request,
    };
    return this.clientsApiService.search(clientSearchRequest).pipe(
      catchError(() => of([])), // empty list on error
      tap(() => (this.clientsLoading = false)),
      map((searchResponse: ClientSearchResponse) => {
        let clientOptions = R.filter(
          option => Boolean(option.name),
          R.map(
            (client: Client) => ({
              id: client.id,
              name: client[this.displayedField],
            }),
            searchResponse.data,
          ).sort((a, b) => (b.name > a.name ? -1 : 1)),
        );

        if (this.hideGelatoAdminClient) {
          clientOptions = R.filter(options => options.id !== GELATO_ADMIN_CLIENT_UID, clientOptions);
        }

        return clientOptions;
      }),
    );
  }

  onSelect(value: string) {
    this.valueChange.emit(value);
  }
}
