<div class="ui input fluid">
  <select
    class="ui selection dropdown"
    [class.defaultText]="!value"
    [value]="value || ''"
    (change)="onValueChange($event)"
  >
    <option *ngIf="placeholder" [attr.value]="''" [attr.selected]="!value">{{ placeholder }}</option>
    <option
      *ngFor="let option of options"
      [attr.value]="option"
      [attr.selected]="option == value ? 'selected' : undefined"
    >
      {{ option }}
    </option>
  </select>
  <i class="dropdown icon"></i>
</div>
