import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PricePlanItemButton, SubscriptionPlanType } from '@api-ui-app/src/app/app-store/types/price-plan-items';
import { countryIsoCodesWithVat } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/constants/country-iso-codes-with-vat';

@Component({
  selector: 'gd-price-plan-item',
  templateUrl: './price-plan-item.component.html',
  styleUrls: ['./price-plan-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PricePlanItemComponent {
  @Input() planId: SubscriptionPlanType;
  @Input() planName: string;
  @Input() planDescription: string;
  @Input() priceDescription: string;
  @Input() price: number | string;
  @Input() isGelatoPlusActive: boolean;
  @Input() isCurrentPlan: boolean;
  @Input() isRecommended: boolean;
  @Input() subscriptionPageEnabledAB: boolean;
  @Input() buttons: PricePlanItemButton[];
  @Input() countryISOCode: string;

  planIdName = SubscriptionPlanType;

  get showExclTax(): boolean {
    return this.countryISOCode && countryIsoCodesWithVat.includes(this.countryISOCode);
  }
}
