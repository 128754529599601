export enum FeatureFlagEnum {
  branded_packaging = 'branded_packaging',
  e_store_onboarding_flow = 'e_store_onboarding_flow',
  dtg_new_print_areas = 'dtg_new_print_areas',
  destygo_webchat = 'destygo_webchat',
  etsy_connect_api_v2 = 'etsy_connect_api_v2',
  editor_image_patterns = 'editor_image_patterns',
  editor_shapes = 'editor_shapes',
  gelato_plus_upsell = 'gelato_plus_upsell',
  onboarding_subscription_modal = 'onboarding_subscription_modal',
  deprecated_variant_warning_message = 'deprecated_variant_warning_message',
  editor_text_effects = 'editor_text_effects',
  mounted_framed_posters = 'mounted_framed_posters',
  mockup_gallery = 'mockup_gallery',
  mockup_studio = 'mockup_studio',
  framed_posters_upgrade = 'framed_posters_upgrade',
  background_removal_tool = 'background_removal_tool',
  is_gold_trial_not_available = 'is_gold_trial_not_available',
  subscription_activation_widget = 'subscription_activation_widget',
  subscription_page_ab = 'subscription_page_ab',
  product_templates = 'product_templates',
  product_expansion_v2 = 'product_expansion_v2',
}
