import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';

export function getParentCategory(sanityContent: SanityProductCategory[], category: SanityProductCategory) {
  const parent = sanityContent.find(cat => {
    if (cat.children && cat.children.length) {
      return cat.children.includes(category._id);
    }
    return false;
  });
  return parent || null;
}

export function getCategoryByProductModel(sanityContent: SanityProductCategory[], productModel: string) {
  return sanityContent.find(cat => cat.productModel === productModel);
}
