import { TooltipPositions } from '@gelato-api-ui/ui-kit/src/lib/tooltip/tooltip.enum';

export interface PricePlanItemButton {
  textKey: string;
  isVisible: boolean;
  className?: string;
  buttonDescriptionKey?: string;
  onClick: () => void;
  tooltip?: {
    tooltipTextKey: string;
    tooltipTheme: 'light' | 'dark' | 'red';
    tooltipShowArrow: boolean;
    tooltipPosition: TooltipPositions;
    tooltipWidth: string;
  };
}

export enum SubscriptionPlanType {
  FREE = 'free',
  PLUS = 'plus',
  GOLD = 'gold',
}
