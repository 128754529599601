import { BehaviorSubject, combineLatest, fromEvent, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Breakpoint, BREAKPOINTS } from './breakpoints.constant';

@Injectable({
  providedIn: 'root',
})
export class ResponsiveService {
  private readonly DEBOUNCE_TIME = 50;
  private readonly screenSizeSubject$ = new BehaviorSubject<number>(0);
  private readonly isMobileSubject$ = new BehaviorSubject<boolean>(false);
  private readonly isTabletSubject$ = new BehaviorSubject<boolean>(false);
  private resizeSub$: Subscription;
  private screenSizeSub$: Subscription;
  public readonly screenSize$ = this.screenSizeSubject$.asObservable();

  get isMobile$() {
    return this.isMobileSubject$.asObservable();
  }

  get isTablet$() {
    return this.isTabletSubject$.asObservable();
  }

  get isMobileOrTablet$() {
    return combineLatest([this.isMobile$, this.isTablet$]).pipe(
      map(([isMobile, isTablet]): boolean => Boolean(isMobile || isTablet)),
    );
  }

  constructor() {
    this.screenSizeSubject$.next(window.innerWidth);
  }

  private updateSubjects(value: number) {
    let isMobile = false;
    let isTablet = false;

    if (value < BREAKPOINTS[Breakpoint.tablet]) {
      isMobile = true;
    } else if (value < BREAKPOINTS[Breakpoint.desktop]) {
      isTablet = true;
    }

    this.isMobileSubject$.next(isMobile);
    this.isTabletSubject$.next(isTablet);
  }

  public onRemoveScreenSizeChanges(): void {
    this.resizeSub$.unsubscribe();
    this.screenSizeSub$.unsubscribe();
  }

  public onScreenSizeChanges(): void {
    this.screenSizeSubject$.next(window.innerWidth);

    this.screenSizeSub$ = this.screenSize$
      .pipe(
        distinctUntilChanged(),
        tap((value: number) => this.updateSubjects(value)),
      )
      .subscribe();

    this.resizeSub$ = fromEvent(window, 'resize')
      .pipe(
        debounceTime(this.DEBOUNCE_TIME),
        distinctUntilChanged(),
        map((event: Event) => event.target as Window),
        tap((window: Window) => this.screenSizeSubject$.next(window.innerWidth)),
      )
      .subscribe();
  }
}
