import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { SetSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.actions';
import { getSelectedClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';

@Component({
  selector: 'gd-header-client-selector, gc-header-client-selector',
  templateUrl: './header-client-selector.component.html',
  styleUrls: ['./header-client-selector.component.scss'],
})
export class HeaderClientSelectorComponent {
  @Input()
  isDisabled = false;

  @Output()
  clientIdChange = new EventEmitter<string>();

  isGelatoUser$ = this.authService.isGelatoUser();
  selectedClientId$ = this.store.select(getSelectedClientId);

  constructor(private store: Store<AppState>, private authService: AuthService) {}

  onClientIdChange(clientId: string) {
    this.store.dispatch(new SetSelectedClientId(clientId));
    this.clientIdChange.emit(clientId);
  }
}
