import { createSelector } from '@ngrx/store';
import { AppState } from '../app.state';
import { Key } from '../api-keys/lib/key';
import { KeySearchRequest } from '../api-keys/lib/key-search-request';
import { KeySearchResponse } from '../api-keys/lib/key-search-response';
import * as actions from './api-keys.actions';

export const getState = (state: AppState): State => state.apiKeys;

export const getKeysListState = createSelector(getState, (state: State): KeysListState => state.keysList);

export const getKeysListRequest = createSelector(
  getKeysListState,
  (keysListState: KeysListState): KeySearchRequest => keysListState.request,
);

export const getKeysListResponse = createSelector(
  getKeysListState,
  (keysListState: KeysListState): KeySearchResponse => keysListState.payload,
);

export const getKeysList = createSelector(getKeysListResponse, (response: KeySearchResponse): Key[] => {
  if (!response || !response.data) {
    return [];
  }

  return response.data;
});

export const getKeyState = createSelector(getState, (state: State): KeyState => state.key);

export const getKey = createSelector(getKeyState, (keyState: KeyState): Key => keyState.payload);

export interface State {
  keysList: KeysListState;
  key: KeyState;
}

export interface KeysListState {
  isLoading: boolean;
  request: KeySearchRequest;
  payload: KeySearchResponse;
}

export interface KeyState {
  isLoading: boolean;
  payload: Key;
}

export const initialState: State = {
  keysList: {
    isLoading: false,
    request: null,
    payload: null,
  },
  key: {
    isLoading: null,
    payload: null,
  },
};

export function reducer(state = initialState, action: actions.ApiKeysActions): State {
  switch (action.type) {
    case actions.ApiKeysActionTypes.ResetKeysListState:
      return {
        ...state,
        keysList: { ...initialState.keysList },
      };
    case actions.ApiKeysActionTypes.ResetKeysListPayload:
      return {
        ...state,
        keysList: {
          ...state.keysList,
          payload: null,
        },
      };
    case actions.ApiKeysActionTypes.SetKeysListState:
      return {
        ...state,
        keysList: action.payload,
      };
    case actions.ApiKeysActionTypes.ResetKeyState:
      return {
        ...state,
        key: { ...initialState.key },
      };
    case actions.ApiKeysActionTypes.SetKeyState:
      return {
        ...state,
        key: action.payload,
      };
    default:
      return state;
  }
}
