import { Action } from '@ngrx/store';
import { KeySearchRequest } from '../api-keys/lib/key-search-request';
import { KeysListState, KeyState } from './api-keys.reducer';

export enum ApiKeysActionTypes {
  LoadKeysList = '[ApiKeys] Load KeysList',
  LoadKey = '[ApiKeys] Load Key',

  ResetKeysListState = '[ApiKeys] Reset KeysList State',
  ResetKeysListPayload = '[ApiKeys] Reset KeysList Payload',
  ResetKeyState = '[ApiKeys] Reset Key State',

  SetKeysListState = '[ApiKeys] Set KeysList State',
  SetKeyState = '[ApiKeys] Set Key State',
}

export class LoadKeysList implements Action {
  readonly type = ApiKeysActionTypes.LoadKeysList;

  constructor(public searchRequest: KeySearchRequest, public forced: boolean = false) {}
}

export class LoadKey implements Action {
  readonly type = ApiKeysActionTypes.LoadKey;

  constructor(public keyId: string, public forced: boolean = false) {}
}

export class ResetKeysListState implements Action {
  readonly type = ApiKeysActionTypes.ResetKeysListState;
}

export class ResetKeysListPayload implements Action {
  readonly type = ApiKeysActionTypes.ResetKeysListPayload;
}

export class ResetKeyState implements Action {
  readonly type = ApiKeysActionTypes.ResetKeyState;
}

export class SetKeysListState implements Action {
  readonly type = ApiKeysActionTypes.SetKeysListState;

  constructor(public payload: KeysListState) {}
}

export class SetKeyState implements Action {
  readonly type = ApiKeysActionTypes.SetKeyState;

  constructor(public payload: KeyState) {}
}

export type ApiKeysActions =
  | LoadKey
  | ResetKeysListState
  | ResetKeysListPayload
  | ResetKeyState
  | SetKeysListState
  | SetKeyState;
