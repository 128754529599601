<gd-product-catalogue-selector
  [showAnyValueOption]="showAnyValueOption"
  [anyValueOptionText]="'txt_any' | translate"
  [placeholder]="'txt_product_catalogue_field_placeholder' | translate"
  [valueField]="'id'"
  [value]="productCatalogueId"
  (valueChange)="onProductCatalogueIdChange($event)"
  [isSearchable]="isSearchable"
  [isDisabled]="isDisabled"
>
</gd-product-catalogue-selector>
