import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import { isJapanCountry } from '@api-ui-app/src/app/ngrx/company-details.reducer';

@Injectable({ providedIn: 'root' })
export class UserCountryService {
  isJapanCountry$ = this.store.select(isJapanCountry);

  constructor(private readonly store: Store<AppState>) {}
}
