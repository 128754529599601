import { Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { Injectable, Injector } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { DeckContentType } from '../types';
import { DeckRef } from '../components/deck-ref/deck-ref';
import { DeckRefComponent } from '../components';

@Injectable({
  providedIn: 'root',
})
export class DeckService {
  constructor(private readonly overlay: Overlay, private readonly injector: Injector) {}

  public open<T>(params: {
    readonly content: DeckContentType;
    readonly data?: T;
    readonly width: number | string;
    readonly height: number | string;
  }): DeckRef<T> {
    const { content, data, width, height } = params;
    const overlayRef = this.overlay.create(this.getOverlayConfig({ width, height }));
    const deckRef = new DeckRef<T>(overlayRef, content, data);
    const inject = this.createInjector(deckRef, this.injector);

    overlayRef.attach(new ComponentPortal(DeckRefComponent, null, inject));

    return deckRef;
  }

  private createInjector(deckRef: DeckRef, injector: Injector): PortalInjector {
    const tokens = new WeakMap([[DeckRef, deckRef]]);

    return new PortalInjector(injector, tokens);
  }

  private getOverlayConfig(params: {
    readonly width: number | string;
    readonly height: number | string;
  }): OverlayConfig {
    const { width, height } = params;

    return new OverlayConfig({
      backdropClass: 'custom-overlay-backdrop',
      panelClass: 'deck-panel',
      hasBackdrop: true,
      positionStrategy: this.getOverlayPosition(),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      height,
      width,
    });
  }

  private getOverlayPosition(): PositionStrategy {
    return this.overlay.position().global().bottom();
  }
}
