import { DimensionUnit } from '../dimension-unit.enum';

const supportedMeasureUnits = [String(DimensionUnit.MILLIMETER), String(DimensionUnit.INCH)];

export const convertDimensionValue = (
  dimensionValue: number,
  sourceMeasureUnit: string,
  destinationMeasureUnit: string,
): number => {
  if (supportedMeasureUnits.indexOf(sourceMeasureUnit) === -1) {
    throw new Error('Not supported source measure unit');
  }

  if (supportedMeasureUnits.indexOf(destinationMeasureUnit) === -1) {
    throw new Error('Not supported destination measure unit');
  }

  if (sourceMeasureUnit === destinationMeasureUnit) {
    return dimensionValue;
  }

  const MILLIMETERS_IN_INCH = 25.4;

  if (destinationMeasureUnit === DimensionUnit.INCH) {
    return dimensionValue / MILLIMETERS_IN_INCH;
  } else {
    return dimensionValue * MILLIMETERS_IN_INCH;
  }
};
