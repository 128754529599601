import { OnBoardingStep } from '../types/on-boarding-step';
import { OnBoardingStepConfig } from '../types/on-boarding-step-config';

export const onBoardingStepConfig: OnBoardingStepConfig[] = [
  {
    id: OnBoardingStep.OrderSample,
    state: 'number',
  },
  { id: OnBoardingStep.ConnectStore, state: 'number' },
  { id: OnBoardingStep.AddProduct, state: 'number' },
];
