import { createAction, props } from '@ngrx/store';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';

export enum CoreAction {
  AppInit = '[Core] AppInit',
  NavigateAfterLogin = '[Core] NavigateAfterLogin',
}

export class NavigateAfterLogin {
  readonly type = CoreAction.NavigateAfterLogin;
  constructor(public payload: string) {}
}

export class AppInit {
  readonly type = CoreAction.AppInit;
}

export const updateLocale = createAction('[Core] Update Locale', props<{ locale: LocaleCode }>());

export type All = NavigateAfterLogin | AppInit;
