import * as R from 'ramda';
import { BillingEntity } from '../../billing-entities/lib/billing-entity';
import { State } from '../billing-entities.reducer';

export const findBillingEntityInState = (state: State, billingEntityId: number): BillingEntity => {
  if (!state || !billingEntityId) {
    return null;
  }

  if (
    state.billingEntity &&
    state.billingEntity.payload &&
    state.billingEntity.payload.id === Number(billingEntityId)
  ) {
    return { ...state.billingEntity.payload };
  }

  if (state.billingEntityList && state.billingEntityList.payload && state.billingEntityList.payload.data) {
    return {
      ...R.find(R.propEq('id', Number(billingEntityId)))(state.billingEntityList.payload.data),
    };
  }

  return null;
};
