import { Action, createReducer, on } from '@ngrx/store';
import { paymentDetailsAdapter, PaymentDetailsState } from './payment-details.adapter';
import { deleteSuccess, fetchFail, fetchStart, fetchSuccess } from './payment-details.actions';

const initialState: PaymentDetailsState = paymentDetailsAdapter.getInitialState({
  /**
   * undefined - not even started
   * false - in progress
   * true - fetched
   */
  isFetched: undefined,
});

const reducer = createReducer(
  initialState,
  on(fetchSuccess, (state, action) => ({
    ...paymentDetailsAdapter.setAll(action.list, state),
    isFetched: true,
  })),
  on(deleteSuccess, (state, action) => paymentDetailsAdapter.removeOne(action.id, state)),
  on(fetchStart, state => ({ ...state, isFetched: false })),
  on(fetchFail, state => ({ ...state, isFetched: undefined })),
);

export function paymentDetailsReducer(state: PaymentDetailsState, action: Action) {
  return reducer(state, action);
}
