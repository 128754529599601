import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { WalletTransactionsFilter, WalletTransactionsSearchResponse } from '../lib/api';
import { map, switchMap } from 'rxjs/operators';
import { decorateHeadersWithClientId } from '../../../lib/decorate-headers-with-client-id';
import { of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WalletTransactionsApiService {
  constructor(private readonly apiRequest: ApiRequestService) {}

  search(walletUid: string, filter: WalletTransactionsFilter, clientId: string) {
    return this.apiRequest
      .post<WalletTransactionsSearchResponse>(`/api/payments/v1/wallets/${walletUid}/transactions:search`, {
        body: filter,
        headers: decorateHeadersWithClientId(null, clientId),
      })
      .pipe(map(res => res.data));
  }

  getPdfConfirmationUrl(walletUid: string, walletTransactionUid: string, clientId: string) {
    return this.apiRequest
      .post<{ url: string }>(
        `/api/payments/v1/wallets/${walletUid}/transactions/${walletTransactionUid}:confirmation`,
        {
          headers: decorateHeadersWithClientId(null, clientId),
        },
      )
      .pipe(
        switchMap(r => {
          if (!r.data || !r.data.url) {
            return throwError('Could not get pdf confirmation');
          }

          return of(r.data.url);
        }),
      );
  }
}
