import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import {
  loadAll,
  loadGraph,
  loadSavedShippingCost,
  resetGraphState,
  selectGraphGroupType,
  setDateRange,
  setGraphState,
  setSavedShippingCost,
  setSavedShippingCostDateRange,
  setSelectedGraphGroupType,
} from './analytics.actions';
import { UserCurrencyService } from '../../shared/services/user-currency.service';
import { getSavedShippingCostDateRange, getSelectedGraphGroupType, getState } from './analytics.selector';
import { AppState } from '../../app.state';
import { DashboardApiService } from '../services/dashboard-api.service';
import { AnalyticsGraphResponse } from '../types/analytics-graph-response';
import { getSelectedClientId, isGelatoUser } from '../../ngrx/auth.reducer';
import { AnalyticsGraphRequest } from '../types/analytics-graph-request';
import { AnalyticsGraphName } from '../types/analytics-graph-name.enum';
import { getAvailableGroupTypes } from '../../ngrx/helpers/getAvailableGroupTypes';
import { AnalyticsGraphGroupType } from '../types/analytics-graph-group-type.enum';

@Injectable()
export class AnalyticsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private dashboardApiService: DashboardApiService,
    private userCurrencyService: UserCurrencyService,
  ) {}

  LoadGraph$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadGraph),
        withLatestFrom(this.store$.select(getState)),
        map(([action, state]) => {
          const { request, forced } = action;
          const graphName = request.graphName;
          const graphGroupType = request.groupBy;
          const graphState = state[graphName][graphGroupType || 'total'];
          const isDataRelevant = R.equals(graphState.request, request) && Boolean(graphState.payload);

          let observable: Observable<AnalyticsGraphResponse> = this.dashboardApiService.getGraph(request);

          if (!forced && isDataRelevant) {
            observable = of({ ...graphState.payload });
          }

          this.store$.dispatch(
            setGraphState({
              graphName,
              graphGroupType,
              graphState: {
                isLoading: true,
                request,
                payload: isDataRelevant ? graphState.payload : null,
              },
            }),
          );

          observable
            .pipe(
              catchError((): Observable<AnalyticsGraphResponse> => {
                this.store$.dispatch(new ShowGeneralErrorNotification());
                return of(null);
              }),
            )
            .subscribe((response: AnalyticsGraphResponse) => {
              this.store$.dispatch(
                setGraphState({
                  graphName,
                  graphGroupType,
                  graphState: {
                    isLoading: false,
                    request,
                    payload: response,
                  },
                }),
              );
            });
        }),
      ),
    { dispatch: false },
  );

  SelectGraphGroupType$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(selectGraphGroupType),
        withLatestFrom(this.store$.select(getState), this.store$.select(getSelectedClientId)),
        switchMap(([action, state, selectedClientId]) => {
          const { graphName, graphGroupType } = action;
          const { startDate, endDate } = state;

          this.store$.dispatch(
            loadGraph({
              request: new AnalyticsGraphRequest(selectedClientId, graphName, startDate, endDate, graphGroupType),
              forced: false,
            }),
          );

          this.store$.dispatch(setSelectedGraphGroupType({ graphName, graphGroupType }));

          return of(null);
        }),
      ),
    { dispatch: false },
  );

  LoadSavedShippingCost$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSavedShippingCost, setSavedShippingCostDateRange),
      withLatestFrom(
        this.store$.select(getSelectedClientId),
        this.store$.select(getSavedShippingCostDateRange),
        this.userCurrencyService.get(),
      ),
      switchMap(([_, selectedClientId, dateRange, currency]) => {
        const { startDate, endDate } = dateRange;

        return this.dashboardApiService
          .getSavedShippingCost({ clientId: selectedClientId, startDate, endDate, currency, groupBy: '' })
          .pipe(
            map((value: number) => setSavedShippingCost({ value })),
            catchError(() => {
              return of(new ShowGeneralErrorNotification());
            }),
          );
      }),
    ),
  );

  LoadAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAll),
        withLatestFrom(
          this.store$.select(getSelectedClientId),
          this.store$.select(getSelectedGraphGroupType, {
            graphName: AnalyticsGraphName.ORDERS_BY_COUNTRY,
          }),
          this.store$.select(getSelectedGraphGroupType, {
            graphName: AnalyticsGraphName.ORDERS_BY_PRODUCT,
          }),
          this.store$.select(getSelectedGraphGroupType, {
            graphName: AnalyticsGraphName.PRINT_COST,
          }),
          this.store$.select(isGelatoUser),
        ),
        switchMap(
          ([
            action,
            selectedClientId,
            selectedOrdersByCountryGroupType,
            selectedOrdersByProductGroupType,
            selectedPrintCostGroupType,
            gelatoUser,
          ]) => {
            const { startDate, endDate, forced } = action;

            this.store$.dispatch(setDateRange({ startDate, endDate }));

            const availableGroupTypes = getAvailableGroupTypes(startDate, endDate);
            let ordersByCountryGroupType = selectedOrdersByCountryGroupType;
            let ordersByProductGroupType = selectedOrdersByProductGroupType;
            let printCostGroupType = selectedPrintCostGroupType;

            if (availableGroupTypes.indexOf(ordersByCountryGroupType) === -1) {
              ordersByCountryGroupType = availableGroupTypes[0];

              this.store$.dispatch(
                setSelectedGraphGroupType({
                  graphName: AnalyticsGraphName.ORDERS_BY_COUNTRY,
                  graphGroupType: ordersByCountryGroupType,
                }),
              );
            }

            if (availableGroupTypes.indexOf(ordersByProductGroupType) === -1) {
              ordersByProductGroupType = availableGroupTypes[0];

              this.store$.dispatch(
                setSelectedGraphGroupType({
                  graphName: AnalyticsGraphName.ORDERS_BY_PRODUCT,
                  graphGroupType: ordersByProductGroupType,
                }),
              );
            }

            if (availableGroupTypes.indexOf(printCostGroupType) === -1) {
              printCostGroupType = availableGroupTypes[0];

              this.store$.dispatch(
                setSelectedGraphGroupType({
                  graphName: AnalyticsGraphName.PRINT_COST,
                  graphGroupType: printCostGroupType,
                }),
              );
            }

            this.store$.dispatch(
              loadGraph({
                request: new AnalyticsGraphRequest(
                  selectedClientId,
                  AnalyticsGraphName.SUMMARY,
                  startDate,
                  endDate,
                  AnalyticsGraphGroupType.NONE,
                ),
                forced,
              }),
            );

            [AnalyticsGraphGroupType.NONE, ordersByCountryGroupType].forEach(
              (graphGroupType: AnalyticsGraphGroupType) => {
                this.store$.dispatch(
                  loadGraph({
                    request: new AnalyticsGraphRequest(
                      selectedClientId,
                      AnalyticsGraphName.ORDERS_BY_COUNTRY,
                      startDate,
                      endDate,
                      graphGroupType,
                    ),
                    forced,
                  }),
                );
              },
            );

            [AnalyticsGraphGroupType.NONE, ordersByProductGroupType].forEach(
              (graphGroupType: AnalyticsGraphGroupType) => {
                this.store$.dispatch(
                  loadGraph({
                    request: new AnalyticsGraphRequest(
                      selectedClientId,
                      AnalyticsGraphName.ORDERS_BY_PRODUCT,
                      startDate,
                      endDate,
                      graphGroupType,
                    ),
                    forced,
                  }),
                );
              },
            );

            [printCostGroupType].forEach((graphGroupType: AnalyticsGraphGroupType) => {
              this.store$.dispatch(
                loadGraph({
                  request: new AnalyticsGraphRequest(
                    selectedClientId,
                    AnalyticsGraphName.PRINT_COST,
                    startDate,
                    endDate,
                    graphGroupType,
                  ),
                  forced,
                }),
              );
            });

            // Cleanup of state representing inactive tabs
            [AnalyticsGraphGroupType.BY_DAY, AnalyticsGraphGroupType.BY_WEEK, AnalyticsGraphGroupType.BY_MONTH].forEach(
              (graphGroupType: AnalyticsGraphGroupType) => {
                if (graphGroupType !== ordersByCountryGroupType) {
                  this.store$.dispatch(
                    resetGraphState({ graphName: AnalyticsGraphName.ORDERS_BY_COUNTRY, graphGroupType }),
                  );
                }

                if (graphGroupType !== ordersByProductGroupType) {
                  this.store$.dispatch(
                    resetGraphState({ graphName: AnalyticsGraphName.ORDERS_BY_PRODUCT, graphGroupType }),
                  );
                }

                if (graphGroupType !== printCostGroupType) {
                  this.store$.dispatch(resetGraphState({ graphName: AnalyticsGraphName.PRINT_COST, graphGroupType }));
                }
              },
            );

            return of(null);
          },
        ),
      ),
    { dispatch: false },
  );
}
