import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldErrorMessageComponent } from '@gelato-api-ui/sdk/src/lib/shared/form-field-error-message/form-field-error-message.component';

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [FormFieldErrorMessageComponent],
  exports: [FormFieldErrorMessageComponent],
})
export class FormFieldErrorMessageModule {}
