import { Action } from '@ngrx/store';
import { State } from './price-plan-selection-list.reducer';

export enum PricePlanSelectionListActionTypes {
  Load = '[PricePlanSelectionList] Load',
  SetState = '[PricePlanSelectionList] Set State',
}

export class Load implements Action {
  readonly type = PricePlanSelectionListActionTypes.Load;
}

export class SetState implements Action {
  readonly type = PricePlanSelectionListActionTypes.SetState;

  constructor(public payload: State) {}
}

export type PricePlanSelectionListActions = Load | SetState;
