import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  AppDetailsState,
  AppsListContentState,
  AppsListState,
  AppStoreState,
} from '@api-ui-app/src/app/app-store/+state/app-store.reducer';
import { appStoreFeatureKey } from '@api-ui-app/src/app/app-store/+state/app-store.feature';
import { AppDetails } from '@gelato-api-ui/core/app-store/app-details';
import { SanityAppsListPage } from '../../subscriptions/types/sanity-apps-list-page';

export const getAppStoreState = createFeatureSelector<AppStoreState>(appStoreFeatureKey);

export const getAppsListSanityContentState = createSelector(
  getAppStoreState,
  (state: AppStoreState): AppsListContentState => state.appsListSanityContent,
);

export const getAppsListState = createSelector(
  getAppStoreState,
  (state: AppStoreState): AppsListState => state.appsList,
);

export const getIsAppsListLoading = createSelector(
  getAppsListSanityContentState,
  (state: AppsListContentState): boolean => state.isLoading,
);

export const getAppsListPayload = createSelector(
  getAppsListState,
  (state: AppsListState): AppDetails[] => state.payload || [],
);

export const getAppsListSanityContentPayload = createSelector(
  getAppsListSanityContentState,
  (state: AppsListContentState): SanityAppsListPage => state.payload,
);

export const getAppDetailsState = createSelector(
  getAppStoreState,
  (state: AppStoreState): AppDetailsState => state.appDetails,
);

export const getIsAppDetailsLoading = createSelector(
  getAppDetailsState,
  (state: AppDetailsState): boolean => state.isLoading,
);

export const getAppDetailsPayload = createSelector(
  getAppDetailsState,
  (state: AppDetailsState): AppDetails => state.payload,
);
