import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ElementRef, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollingService {
  private readonly DEBOUNCE_TIME = 5;
  private readonly scrollYSubject$ = new BehaviorSubject<number>(0);
  private scrollPositionSub$: Subscription;
  public readonly windowYScroll$ = this.scrollYSubject$.asObservable();
  // Element to check if it`s scrolling. In out application, we do not scroll body or document, we scroll "layout-content"
  public scrollElement: ElementRef;

  public onRemoveWindowScrollChanges(): void {
    this.scrollPositionSub$.unsubscribe();
  }

  public onWindowScrollChanges(): void {
    this.scrollPositionSub$ = fromEvent(this.scrollElement.nativeElement, 'scroll')
      .pipe(debounceTime(this.DEBOUNCE_TIME))
      .subscribe((e: Event) => {
        this.scrollYSubject$.next(e.target['scrollTop']);
      });
  }
}
