import { SanityProductCategory } from '@gelato-api-ui/core/sanity/sanity-product-category';
import { mapProductFilterToControl } from '@gelato-api-ui/core/sanity/helpers/mapSanityProductResponse';
import { SanityProductCategoryResponse } from '@gelato-api-ui/core/sanity/sanity-product-category-response';

export function mapSanityProductCategoryResponse(categories: SanityProductCategoryResponse[]): SanityProductCategory[] {
  return (categories || []).map(category => ({
    ...category,
    lists: (category.lists || []).map(list => ({
      ...list,
      products: !list.products
        ? []
        : list.products.map(product => ({
            ...product,
            filters: product.filters ? product.filters.map(filter => mapProductFilterToControl(filter)) : [],
          })),
    })),
  }));
}
