import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  PlansComparisonTableCell,
  PlansComparisonTableCellType,
} from '@api-ui-app/src/app/subscriptions/types/compare-table';
import { ServicePlanUniqueNames } from '@api-ui-app/src/app/subscriptions/types/service-plan-unique-names';
import { countryIsoCodesWithVat } from '@gelato-api-ui/sdk/src/lib/shared/subscriptions-shared/constants/country-iso-codes-with-vat';

@Component({
  selector: 'gd-plans-comparison-table-cell',
  templateUrl: './plans-comparison-table-cell.component.html',
  styleUrls: ['./plans-comparison-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlansComparisonTableCellComponent {
  @Input() cell: PlansComparisonTableCell;
  @Input() currency: string;
  @Input() countryISOCode: string;
  @Input() isFirstCell: boolean;

  public cellType = PlansComparisonTableCellType;
  public cellPlan = ServicePlanUniqueNames;
  private checkmarkConfig = {
    [ServicePlanUniqueNames.gelato_platform_free]: 'subscriptions/black-checkmark.svg',
    [ServicePlanUniqueNames.gelato_platform_free_legacy]: 'subscriptions/black-checkmark.svg',
    [ServicePlanUniqueNames.gelato_platform_plus]: 'subscriptions/violet-checkmark.svg',
    [ServicePlanUniqueNames.gelato_platform_plus_legacy]: 'subscriptions/violet-checkmark.svg',
    [ServicePlanUniqueNames.gelato_platform_gold]: 'subscriptions/gold-checkmark.svg',
  };

  get showExclTax(): boolean {
    return (
      this.countryISOCode &&
      this.cell?.value &&
      !(this.cell?.value as string).includes('txt') &&
      countryIsoCodesWithVat.includes(this.countryISOCode) &&
      [
        ServicePlanUniqueNames.gelato_platform_plus,
        ServicePlanUniqueNames.gelato_platform_plus_legacy,
        ServicePlanUniqueNames.gelato_platform_gold,
      ].includes(this.cell.plan)
    );
  }

  getCheckmarkImg(cell: PlansComparisonTableCell): string {
    return this.checkmarkConfig[cell.plan];
  }
}
