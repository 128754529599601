import { Pipe, PipeTransform } from '@angular/core';
import { FeatureSwitcherService } from '@gelato-api-ui/sdk/src/lib/feature-switcher/feature-switcher.service';
import { Observable, of } from 'rxjs';
import { FeatureFlagEnum } from '@gelato-api-ui/sdk/src/lib/feature-switcher/featureFlagEnum';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'isFeatureEnabled',
})
export class IsFeatureEnabledPipe implements PipeTransform {
  constructor(private featureSwitcherService: FeatureSwitcherService) {}

  transform(value: FeatureFlagEnum, ...args: unknown[]): Observable<boolean> {
    if (!value) {
      return of(false);
    }
    return this.featureSwitcherService.isFeatureEnabledForCurrentUser(value).pipe(map(isEnabled => Boolean(isEnabled)));
  }
}
