import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import * as gql from 'gql-query-builder';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductTemplatesListItem } from '@api-ui-app/src/app/e-commerce-product-templates/types/e-product-templates-list-item';
import { EProductSearchSearchOrder } from '@api-ui-app/src/app/e-commerce-product-templates/types/e-product-search-order.enum';
import { EProductSearchSearchOrderBy } from '@api-ui-app/src/app/e-commerce-product-templates/types/e-product-search-order-by.enum';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { DesignStructure } from '@gelato-api-ui/core/designs/design-structure';
import { CustomTrim } from '@gelato-api-ui/core/e-commerce/e-product-variant';

@Injectable({ providedIn: 'root' })
export class ECommerceProductTemplatesGraphQlService {
  private readonly operation = 'eCommerce';

  constructor(
    private readonly graphQlRequestService: GraphQlRequestService,
    private readonly translateService: TranslateService,
  ) {}

  fetchProductTemplatesStore(clientId: string): Observable<EStore> {
    const fieldOperation = 'productTemplatesStore';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            clientId: { type: 'String!', value: clientId },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  fetchProductTemplatesList(
    clientId: string,
    productTemplateName: string,
    order: EProductSearchSearchOrder,
    orderBy: EProductSearchSearchOrderBy,
    limit: number,
    offset: number,
  ): Observable<EProductTemplatesListItem[]> {
    const fieldOperation = 'productTemplatesList';
    const query = gql.query({
      operation: this.operation,
      fields: [
        {
          operation: fieldOperation,
          variables: {
            clientId: { type: 'String!', value: clientId },
            productTemplateName: { type: 'String', value: productTemplateName },
            order: { type: 'String', value: order },
            orderBy: { type: 'String', value: orderBy },
            limit: { type: 'Int!', value: limit },
            offset: { type: 'Int!', value: offset },
            locale: { type: 'String!', value: this.translateService.currentLang },
          },
          fields: [],
        },
      ],
    });

    return this.graphQlRequestService
      .query(query, `${this.operation}:${fieldOperation}`, true)
      .pipe(map((response: ApolloQueryResult<any>) => response?.data?.[this.operation]?.[fieldOperation]));
  }

  saveProductTemplate(
    clientId: string,
    sanityProductId: string,
    templateName: string,
    product: EProductWithVariants,
  ): Observable<EProductWithVariants> {
    const operation = 'saveECommerceProductTemplate';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        sanityProductId: { type: 'String!', value: sanityProductId },
        templateName: { type: 'String!', value: templateName },
        product: { type: 'JSONObject!', value: product },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  createProductTemplate(
    clientId: string,
    sanityProductId: string,
    templateName: string,
    product: EProductWithVariants,
  ): Observable<EProductWithVariants> {
    const operation = 'createECommerceProductTemplate';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        sanityProductId: { type: 'String!', value: sanityProductId },
        templateName: { type: 'String!', value: templateName },
        product: { type: 'JSONObject!', value: product },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  createProductTemplateFromDesign(
    clientId: string,
    sanityProductId: string,
    templateName: string,
    designStructure: DesignStructure,
    productUid: string,
    pageCount: number,
    customTrim: CustomTrim,
    country: string,
  ): Observable<EProductWithVariants> {
    const operation = 'createECommerceProductTemplateFromDesign';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        sanityProductId: { type: 'String!', value: sanityProductId },
        templateName: { type: 'String', value: templateName },
        designStructure: { type: 'JSONObject!', value: designStructure },
        productUid: { type: 'String!', value: productUid },
        pageCount: { type: 'Int', value: pageCount },
        customTrim: { type: 'JSONObject', value: customTrim },
        country: { type: 'String!', value: country },
        locale: { type: 'String!', value: this.translateService.currentLang },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  renameProductTemplate(
    clientId: string,
    productTemplateId: string,
    productTemplateName: string,
  ): Observable<EProductWithVariants> {
    const operation = 'renameECommerceProductTemplate';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        productTemplateId: { type: 'String!', value: productTemplateId },
        productTemplateName: { type: 'String!', value: productTemplateName },
        locale: { type: 'String!', value: this.translateService.currentLang },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }

  deleteProductTemplate(clientId: string, productTemplateId: string): Observable<boolean> {
    const operation = 'deleteECommerceProductTemplate';
    const mutation = gql.mutation({
      operation,
      variables: {
        clientId: { type: 'String!', value: clientId },
        productTemplateId: { type: 'String!', value: productTemplateId },
      },
    });

    return this.graphQlRequestService
      .mutate(mutation, operation)
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.[operation]));
  }
}
