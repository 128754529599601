<div class="widgets-list ui stackable grid">
  <div class="column sixteen wide ghost-column"><ng-container [ngTemplateOutlet]="ghostXl"></ng-container></div>
  <div class="column sixteen wide ghost-column"><ng-container [ngTemplateOutlet]="ghostS"></ng-container></div>
  <div class="column eight wide ghost-column"><ng-container [ngTemplateOutlet]="ghostXl"></ng-container></div>
  <div class="column eight wide ghost-column"><ng-container [ngTemplateOutlet]="ghostXl"></ng-container></div>
  <div class="column sixteen wide ghost-column"><ng-container [ngTemplateOutlet]="ghostXl"></ng-container></div>
</div>
<ng-template #ghostXl>
  <div class="ui segment box-shadow-new ghost">
    <div class="ghost-active-fluid line xxl"></div>
    <div class="ghost-active-fluid line xl"></div>
    <div class="ghost-active-fluid line m"></div>
    <div class="ghost-active-fluid line xxl"></div>
    <div class="ghost-active-fluid line xl"></div>
  </div>
</ng-template>

<ng-template #ghostS>
  <div class="ui segment box-shadow-new ghost">
    <div class="ghost-active-fluid line xxl"></div>
    <div class="ghost-active-fluid line m"></div>
    <div class="ghost-active-fluid line xl"></div>
  </div>
</ng-template>
