import { Action } from '@ngrx/store';
import { BillingEntitySearchRequest } from '../billing-entities/lib/billing-entity-search-request';
import { BillingEntityListState, BillingEntityState } from './billing-entities.reducer';

export enum BillingEntitiesActionTypes {
  LoadBillingEntityListStart = '[BillingEntities] Load BillingEntityList Start',
  LoadBillingEntityStart = '[BillingEntities] Load User Start',

  SetBillingEntityListState = '[BillingEntities] Set BillingEntityList State',
  SetBillingEntityState = '[BillingEntities] Set User State',

  ResetBillingEntityListState = '[BillingEntities] Reset BillingEntityList State',
  ResetBillingEntityListPayload = '[BillingEntities] Reset BillingEntityList Payload',
  ResetBillingEntityState = '[BillingEntities] Reset User State',
}

export class LoadBillingEntityListStart implements Action {
  readonly type = BillingEntitiesActionTypes.LoadBillingEntityListStart;

  constructor(public searchRequest: BillingEntitySearchRequest, public forced: boolean = false) {}
}

export class LoadBillingEntityStart implements Action {
  readonly type = BillingEntitiesActionTypes.LoadBillingEntityStart;

  constructor(public id: number, public forced: boolean = false) {}
}

export class ResetBillingEntityListState implements Action {
  readonly type = BillingEntitiesActionTypes.ResetBillingEntityListState;
}

export class ResetBillingEntityListPayload implements Action {
  readonly type = BillingEntitiesActionTypes.ResetBillingEntityListPayload;
}

export class ResetBillingEntityState implements Action {
  readonly type = BillingEntitiesActionTypes.ResetBillingEntityState;
}

export class SetBillingEntityListState implements Action {
  readonly type = BillingEntitiesActionTypes.SetBillingEntityListState;

  constructor(public payload: BillingEntityListState) {}
}

export class SetBillingEntityState implements Action {
  readonly type = BillingEntitiesActionTypes.SetBillingEntityState;

  constructor(public payload: BillingEntityState) {}
}

export type BillingEntitiesActions =
  | LoadBillingEntityListStart
  | LoadBillingEntityStart
  | ResetBillingEntityListState
  | ResetBillingEntityListPayload
  | ResetBillingEntityState
  | SetBillingEntityListState
  | SetBillingEntityState;
