import { Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { first, map, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@api-ui-app/src/app/app.state';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { ProductVariantIdsCollection } from '@api-ui-app/src/app/product/product-add/types/product-variant-ids-collection';
import * as eCommerceProductsTreeActions from '@api-ui-app/src/app/product/product-add/+state/e-commerce-products-tree.actions';
import { getStore, getStoreType } from '@api-ui-app/src/app/ngrx/e-commerce-stores.selector';
import {
  getLoadingFlag,
  getProductsTree,
} from '@api-ui-app/src/app/product/product-add/+state/e-commerce-products-tree.selector';
import { ProductSelectionResult } from '@api-ui-app/src/app/product/product-add/types/product-selection-result';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { ECommerceProductsTreeCheckedProductVariantsService } from '@api-ui-app/src/app/product/product-add/services/e-commerce-products-tree-checked-product-variants.service';

export interface SingleProductSelectionModalModalContext {
  store: EStore;
  productId: string;
}

@Component({
  selector: 'gd-single-product-selection-modal',
  templateUrl: './single-product-selection-modal.component.html',
  styleUrls: ['./single-product-selection-modal.component.scss'],
})
export class SingleProductSelectionModalComponent implements OnInit {
  productsTree$ = this.store.select(getProductsTree);
  productsTreeLoading$ = this.store.select(getLoadingFlag);
  selectedStore$ = this.store.select(getStore);
  storeType$ = this.store.select(getStoreType);
  checkedProductVariantIds$: BehaviorSubject<ProductVariantIdsCollection> = new BehaviorSubject({});
  checkedProductVariantsCount$ = this.checkedProductVariantIds$.pipe(
    map((checkedProductVariantIds: ProductVariantIdsCollection): number =>
      this.eCommerceProductsTreeCheckedProductVariantsService.getCheckedProductVariantIdsCount(
        checkedProductVariantIds,
      ),
    ),
  );

  footerLabelTextKey$ = this.checkedProductVariantsCount$.pipe(
    map((checkedProductVariantsCount: number): string =>
      checkedProductVariantsCount === 1
        ? 'txt_selected_variants_count_label_singular'
        : 'txt_selected_variants_count_label_plural',
    ),
  );

  constructor(
    private readonly modal: SuiModal<SingleProductSelectionModalModalContext, ProductSelectionResult, void>,
    private readonly store: Store<AppState>,
    private readonly eCommerceProductsTreeCheckedProductVariantsService: ECommerceProductsTreeCheckedProductVariantsService,
  ) {}

  get productId(): string {
    return this.modal?.context?.productId;
  }

  ngOnInit(): void {
    logEvent('addExistingProductStart');

    this.checkedProductVariantIds$.next({});
    this.loadProduct();
  }

  loadProduct() {
    this.selectedStore$
      .pipe(
        first(),
        tap((selectedStore: EStore) => {
          this.store.dispatch(new eCommerceProductsTreeActions.LoadSingleProduct(selectedStore, this.productId));
        }),
      )
      .subscribe();
  }

  onCheckedProductVariantIdsChange(checkedProductVariantIds: ProductVariantIdsCollection) {
    logEvent('addExistingProductSelectedProductVariants', { selectedItemsCount: checkedProductVariantIds.length });
    this.checkedProductVariantIds$.next(checkedProductVariantIds);
  }

  cancel() {
    logEvent('addExistingProductCancel');
    this.modal.deny(null);
  }

  chooseVariants() {
    this.productsTree$
      .pipe(
        withLatestFrom(this.checkedProductVariantIds$, this.selectedStore$),
        first(),
        tap(([productsTree, checkedProductVariantIds, selectedStore]) => {
          const productVariants: EProductVariant[] =
            this.eCommerceProductsTreeCheckedProductVariantsService.getCheckedProductVariants(
              productsTree,
              checkedProductVariantIds,
            );

          this.modal.approve({ items: productVariants, store: selectedStore, productsTree });
        }),
      )
      .subscribe();
  }

  submit() {
    logEvent('addExistingProductComplete');

    this.chooseVariants();
  }
}

export class SingleProductSelectionModal extends ComponentModalConfig<
  SingleProductSelectionModalModalContext,
  ProductSelectionResult,
  void
> {
  constructor(config: SingleProductSelectionModalModalContext) {
    super(SingleProductSelectionModalComponent, config);
    this.isFullScreen = true;
    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}
