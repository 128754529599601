<div class="header">
  {{ 'txt_ecommerce_product_wizard_leave_confirmation_modal_title' | translate }}
</div>
<div class="content" [innerHTML]="'txt_ecommerce_product_wizard_leave_confirmation_modal_text' | translate"></div>
<div class="actions">
  <button
    class="ui secondary button"
    [class.loading]="isDeletingDraft"
    [class.disabled]="(isSavingDraft$ | async) || isSavingDraftViaModal || isDeletingDraft"
    (click)="discard()"
  >
    {{ 'txt_discard' | translate }}
  </button>
  <button
    class="ui primary button"
    [class.loading]="isSavingDraftViaModal"
    [class.disabled]="(isSavingDraft$ | async) || isSavingDraftViaModal || isDeletingDraft"
    (click)="saveAsDraft()"
    autofocus
  >
    {{ 'txt_save_as_draft' | translate }}
  </button>
</div>
