import { Injectable } from '@angular/core';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { ECommerceProductWizardLeaveConfirmationModal } from '@api-ui-app/src/app/e-commerce-stores/e-commerce-product-wizard-leave-confirmation-modal/e-commerce-product-wizard-leave-confirmation-modal.component';
import { PageLeaveConfirmationModalService } from '@gelato-api-ui/sdk/src/lib/page-leave-confirmation/page-leave-confirmation-modal.service';

@Injectable()
export class ECommerceProductWizardLeaveConfirmationModalService {
  constructor(
    private readonly modalService: SuiModalService,
    private readonly pageLeaveConfirmationModalService: PageLeaveConfirmationModalService,
  ) {}

  show(isProductDraftAutoSaveEnabled: boolean) {
    if (isProductDraftAutoSaveEnabled) {
      return this.modalService.open(new ECommerceProductWizardLeaveConfirmationModal());
    }

    return this.pageLeaveConfirmationModalService.show();
  }
}
