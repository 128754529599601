import { Injectable } from '@angular/core';
import { SuiModalService } from '@giomamaladze/ng2-semantic-ui';
import { ConfirmModal } from '../confirm-modal/confirm-modal.component';
import { ConfirmModalSettings } from '@api-ui-app/src/app/shared/lib/confirm-modal-settings';

@Injectable()
export class ConfirmModalService {
  constructor(private readonly modalService: SuiModalService) {}

  open(settings: ConfirmModalSettings) {
    return this.modalService.open(new ConfirmModal(settings));
  }
}
