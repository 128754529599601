import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SanityProductSimplified } from '@gelato-api-ui/core/sanity/sanity-product-simplified';
import { SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { getColorBackground } from '@product-catalogue/src/lib/product-details-shared/lib/get-color-background';

@Component({
  selector: 'gd-catalog-product-preview-variants',
  templateUrl: './catalog-product-preview-variants.component.html',
  styleUrls: ['./catalog-product-preview-variants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CatalogProductPreviewVariantsComponent implements OnInit {
  @Input() product: SanityProductSimplified;

  colorBackground = getColorBackground;

  get garmentSizes(): string[] {
    const filter = (this.product?.filters || []).find(v => v.key === SanityProductControlKey.garmentSize);
    return filter ? filter.options.map(option => option.value) : null;
  }

  garmentSizesString(sortedSizes: string[]): string {
    const sizes = [...sortedSizes];
    return sizes.length > 1 ? `${sizes.shift()}-${sizes.pop()}` : sizes[0];
  }

  constructor() {}

  ngOnInit() {}
}
