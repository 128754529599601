<div
  class="ui dropdown"
  #containerRef
  [class.disabled]="isDisabled"
  [class.top]="positionTop"
  [class.left]="positionLeft"
>
  <button
    [class]="'ui button ' + buttonType + ' menu-button'"
    [class.icon]="!buttonContent"
    [class.disabled]="isDisabled"
    [class.default-button]="!buttonContent && showDefaultButtonBorder"
    (click)="onMenuButtonClick($event)"
  >
    <i *ngIf="!buttonContent" class="ellipsis vertical icon"></i>
    <ng-container *ngIf="buttonContent" [ngTemplateOutlet]="buttonContent"></ng-container>
  </button>
  <div class="menu" #menuRef [ngStyle]="{ top: menuTopValue }">
    <ng-container *ngTemplateOutlet="content"></ng-container>
  </div>
</div>

<ng-template #content>
  <ng-container *ngIf="dropdownTpl">
    <ng-container *ngTemplateOutlet="dropdownTpl"></ng-container>
  </ng-container>

  <ng-container *ngIf="!dropdownTpl">
    <ng-content></ng-content>
  </ng-container>
</ng-template>

<ng-template #deckMenu>
  <ng-container *ngIf="isMobile">
    <div class="deck-title">
      <h3 class="title">{{ mobileMenuTitle | translate }}</h3>
      <img (click)="closeMenu()" class="close" src="/assets/checkout/close.svg" alt="" width="" height="" />
    </div>
    <div class="ui dropdown mobile">
      <div class="menu mobile-menu">
        <ng-container *ngTemplateOutlet="content"></ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>
