import { DesignPageMediaItem, DesignStructure } from '../../design-structure';

export const getDesignMedia = (designStructure: DesignStructure): DesignPageMediaItem[] => {
  if (!designStructure?.spreads?.length) {
    return [];
  }

  return designStructure.spreads.reduce((acc, spread) => {
    spread.pages.forEach(page => {
      if (page.groups?.media) {
        page.groups.media.forEach(element => {
          acc = [...acc, element];
        });
      }
    });
    return acc;
  }, [] as DesignPageMediaItem[]);
};
