import * as R from 'ramda';
import { SelectedPreviewScenesCollection } from '../e-commerce-product-wizard.reducer';
import { key2SceneName } from '@gelato-api-ui/core/e-commerce/helpers/key2SceneName';
import { sceneName2Key } from '@gelato-api-ui/core/e-commerce/helpers/sceneName2Key';

export const validatePrimaryPreviewScene = (
  currentPrimaryPreviewScene: string,
  selectedPreviewScenes: SelectedPreviewScenesCollection,
): string => {
  if (selectedPreviewScenes[sceneName2Key(currentPrimaryPreviewScene)]) {
    return currentPrimaryPreviewScene;
  }

  const newPrimarySceneKey: string = R.find(
    (loopKey: string): boolean => selectedPreviewScenes[loopKey],
    R.keys(selectedPreviewScenes),
  );

  return key2SceneName(newPrimarySceneKey);
};
