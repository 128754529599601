import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as R from 'ramda';

export interface SidebarItemInterface {
  id: string;
  name: string;
  icon?: string;
  iconSvg?: string;
  href: string;
  route: any[];
  action: () => void;
  children: SidebarItemInterface[];
  routerOptions: any;
  queryParams: any;
  routeParams: () => Observable<any>;
  isVisible: () => Observable<boolean>;
  i18nNameParams: () => Observable<any>;
  isNew: boolean;
  analyticKey?: string;
}

export class SidebarItem implements SidebarItemInterface {
  private _isExpanded = false;

  constructor(private options: Partial<SidebarItemInterface>) {}

  get action() {
    return this.options.action;
  }

  get id() {
    return this.options.id;
  }

  get name() {
    return this.options.name;
  }

  get icon() {
    return this.options.icon;
  }

  get iconSvg() {
    return this.options.iconSvg;
  }

  get href() {
    return this.options.href;
  }

  get route() {
    return this.options.route;
  }

  get routeParams() {
    return this.options.routeParams || (() => of({}));
  }

  get children() {
    return this.options.children || [];
  }

  get routerOptions() {
    return this.options.routerOptions;
  }

  set routerOptions(value: any) {
    this.options.routerOptions = value;
  }

  get isExpanded() {
    return this._isExpanded;
  }

  set isExpanded(value: boolean) {
    this._isExpanded = value;
  }

  get hasChild() {
    return this.children.length > 0;
  }

  get isVisible() {
    return this.options.isVisible || (() => of(true));
  }

  get i18nNameParams() {
    return this.options.i18nNameParams;
  }

  get queryParams() {
    return this.options.queryParams;
  }

  get isNew() {
    return this.options.isNew;
  }

  get analyticKey() {
    return this.options.analyticKey;
  }

  hasVisibleChild(): Observable<boolean> {
    if (!this.children || !this.children.length) {
      return of(false);
    }

    const observables: Observable<boolean>[] = R.map(
      (childItem: SidebarItemInterface): Observable<boolean> => childItem.isVisible(),
      this.children,
    );

    return combineLatest(observables).pipe(
      map((childItemsVisibility): boolean =>
        R.reduce((acc: boolean, value: boolean): boolean => acc || value, false, childItemsVisibility),
      ),
    );
  }
}
