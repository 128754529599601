import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PlayButtonComponent } from '@gelato-api-ui/sdk/src/lib/shared/play-button/play-button.component';
import { SdkPipesModule } from '@gelato-api-ui/sdk/src/lib/pipes/sdk-pipes.module';

@NgModule({
  imports: [CommonModule, TranslateModule, SdkPipesModule],
  declarations: [PlayButtonComponent],
  exports: [PlayButtonComponent],
})
export class PlayButtonModule {}
