import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { loadImage } from '@gelato-api-ui/core/dom/helpers/loadImage';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'gd-carousel-preview, gc-carousel-preview',
  templateUrl: './carousel-preview.component.html',
  styleUrls: ['./carousel-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselPreviewComponent implements OnChanges {
  @Input() url: string = null;
  @Input() addZoomClasses = false;
  @Input() useLoading = false;

  loadedImageUrl: string = null;
  isLoading$ = new BehaviorSubject(false);

  ngOnChanges(changes: SimpleChanges) {
    if (this.useLoading && changes.url) {
      const url = changes.url.currentValue;
      const previousUrl = changes.url.previousValue;

      if (url) {
        if (url !== previousUrl) {
          this.isLoading$.next(true);

          loadImage(url)
            .then(() => {
              this.loadedImageUrl = url;
              this.isLoading$.next(false);
            })
            .catch(() => {
              this.loadedImageUrl = null;
              this.isLoading$.next(false);
            });
        }
      } else {
        this.loadedImageUrl = null;
        this.isLoading$.next(false);
      }
    }
  }
}
