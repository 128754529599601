import * as R from 'ramda';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { getContentIdsFromDesignData } from './getContentIdsFromDesignData';
import { AssetProviderUid } from '@gelato-api-ui/core/designs/asset-provider-uid.enum';

export const getContentIdsFromProductVariants = (
  assetsCollection: AssetsCollection,
  productVariants: EProductVariant[],
  assetProviderUids: AssetProviderUid[],
): string[] => {
  if (!assetsCollection || !productVariants || !productVariants.length) {
    return [];
  }

  let ids: string[] = [];

  productVariants.forEach((loopProductVariant: EProductVariant) => {
    if (!loopProductVariant.designStructureJson) {
      return;
    }

    let designData = null;

    try {
      designData = JSON.parse(loopProductVariant.designStructureJson);
    } catch (e) {}

    if (!designData) {
      return;
    }

    ids = [...ids, ...getContentIdsFromDesignData(assetsCollection, designData, assetProviderUids)];
  });

  ids = R.sort((a, b) => a.localeCompare(b), ids);

  return R.uniq(ids);
};
