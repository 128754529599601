import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ECommerceProductWizardBackRouteService {
  get(clientId: string, storeId: string, isProductTemplate: boolean, isGelatoUser: boolean): any[] {
    if (isProductTemplate && !isGelatoUser) {
      return ['/templates/list'];
    } else if (storeId) {
      return [`/stores/${storeId}/products/list`];
    } else if (clientId) {
      return ['/stores/list', { clientId }];
    } else {
      return ['/stores/list'];
    }
  }
}
