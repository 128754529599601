import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from './customer-onboarding.actions';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { ECommerceProductsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-products-api.service';
import { EProductVariantConnectionStatus } from '@gelato-api-ui/core/e-commerce/e-product-variant-connection-status.enum';
import { of } from 'rxjs';
import { OrdersApiService } from '../../orders/services/orders-api.service';
import { FinancialOrderStatus } from '../../orders/lib/financial-order-status.enum';
import { Order } from '../../orders/lib/order';
import { OrderChannel } from '../../orders/lib/order-channel.enum';
import { CompanyDetailsActionTypes, SetQuestionnaire, SetState } from '../../ngrx/company-details.actions';
import { CustomerSegment } from '../types/customer-segment';
import { QuickQuestionsSteps } from '@gelato-api-ui/core/quick-questions/types/quick-questions';
import { IsAlreadySellingPODAnswers } from '@gelato-api-ui/core/quick-questions/types/is-already-selling-pod-answers';

@Injectable()
export class CustomerOnBoardingEffects {
  loadCreatedProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCreatedProducts),
      switchMap(() =>
        this.eCommerceProductsApiService
          .search({
            storeId: '*',
            limit: 1,
            offset: 0,
            connectionStatus: EProductVariantConnectionStatus.CONNECTED,
          })
          .pipe(
            map(({ products }) => products),
            map(products => actions.loadCreatedProductsSuccess({ products })),
            catchError(err => of(err)),
          ),
      ),
    ),
  );

  loadCustomerMadeOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCustomerMadeOrder),
      switchMap(() =>
        this.ordersApiService
          .search({
            financialStatuses: [FinancialOrderStatus.PAID],
            limit: 1,
            offset: 0,
          })
          .pipe(
            map(({ orders }) =>
              orders.filter((order: Order) => [OrderChannel.UI, OrderChannel.APP].includes(order.channel)),
            ),
            map(orders => actions.loadCustomerMadeOrderSuccess({ orders })),
            catchError(err => of(err)),
          ),
      ),
    ),
  );

  loadCustomerOdersCount$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCustomerOrdersCount),
      switchMap(() =>
        this.ordersApiService
          .search({
            financialStatuses: [FinancialOrderStatus.PAID],
            limit: 50,
            offset: 0,
          })
          .pipe(
            map(({ orders }) => orders?.length || 0),
            map(ordersCount => actions.loadCustomerOrdersCountSuccess({ ordersCount })),
            catchError(err => of(err)),
          ),
      ),
    ),
  );

  setCustomerSegment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CompanyDetailsActionTypes.SetState, CompanyDetailsActionTypes.SetQuestionnaire),
      filter((action: SetState | SetQuestionnaire) =>
        action.type === CompanyDetailsActionTypes.SetState ? !action.payload.isLoading : true,
      ),
      map((action: SetState | SetQuestionnaire) => {
        const questionnaireAnswers =
          action.type === CompanyDetailsActionTypes.SetState ? action.payload.payload?.questionnaire : action.payload;
        let customerSegment: CustomerSegment;
        const isAlreadySellingPOD = questionnaireAnswers?.find(
          question => question.question === QuickQuestionsSteps.isAlreadySellingPrintOnDemandProducts,
        );

        if (isAlreadySellingPOD) {
          customerSegment = [
            IsAlreadySellingPODAnswers.YesUseSupplierOrProduceInHouse,
            IsAlreadySellingPODAnswers.YesUsePODPartner,
          ].includes(isAlreadySellingPOD.answer as IsAlreadySellingPODAnswers)
            ? CustomerSegment.Switcher
            : CustomerSegment.Starter;
        } else {
          customerSegment = CustomerSegment.Starter;
        }

        return actions.setCustomerSegment({ segment: customerSegment });
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly eCommerceProductsApiService: ECommerceProductsApiService,
    private readonly ordersApiService: OrdersApiService,
  ) {}
}
