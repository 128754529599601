import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, from, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import * as R from 'ramda';
import * as Sentry from '@sentry/angular';
import { AppState } from '../../app.state';
import { KeycloakCredentials } from '../lib/keycloak-credentials';
import { CompanyDetails } from '@gelato-api-ui/core/company-details/company-details';
import { User } from '../lib/user';
import { UserPropertiesEventData } from '../lib/user-properties-event-data';
import { ChameleonUserIdentification } from '@gelato-api-ui/core/chameleon/chameleon-user-identification';
import { ChameleonFacade } from '@gelato-api-ui/core/chameleon/services/chameleon.facade';
import { AbstractAuthService } from './abstract-auth.service';
import { LastRequestedUrlService } from './last-requested-url.service';
import { UserActivationService } from './user-activation.service';
import { logEvent, trackEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { convertTimestampToIso8601Format } from '../../lib/convertTimestampToIso8601Format';
import * as authActions from '../../ngrx/auth.actions';
import * as companyDetailsActions from '../../ngrx/company-details.actions';
import { getDefaultClientId, getUser, getUserId, getUserRoles, isGelatoUser } from '../../ngrx/auth.reducer';
import { getCompanyDetails } from '../../ngrx/company-details.reducer';
import { getDefaultClientIdFromKeycloakClientIds } from '../../ngrx/helpers/getDefaultClientIdFromKeycloakClientIds';
import { QuickQuestions, QuickQuestionsSteps } from '@gelato-api-ui/core/quick-questions/types/quick-questions';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { toKeycloakLocale } from '@gelato-api-ui/core/i18n/helpers/toKeycloakLocale';
import { HttpHeaders } from '@gelato-api-ui/core/api/http-headers';
import { environment } from '@api-ui-app/src/environments/environment';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { I18N_STORAGE } from '@gelato-api-ui/sdk/src/lib/translations/i18n.service';
import { StorageService } from '@gelato-api-ui/sdk/src/lib/storage/storage.service';
import { KeycloakLoginOptions } from 'keycloak-js';
import { LocalStorageItemKey } from '@gelato-api-ui/core/local-storage/types/local-storage-item-key.enum';

@Injectable({ providedIn: 'root' })
export class KeycloakAuthService extends AbstractAuthService {
  private isInitialized$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isActivated$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private isGelatoUser$: Observable<boolean> = this.store.select(isGelatoUser);
  private userRoles$: Observable<string[]> = this.store.select(getUserRoles);
  private userId$: Observable<string> = this.store.select(getUserId);
  private user$: Observable<User> = this.store.select(getUser);
  private companyDetails$: Observable<CompanyDetails> = this.store.select(getCompanyDetails);
  private defaultClientId$: Observable<string> = this.store.select(getDefaultClientId);

  private userPropertiesEventData$: Observable<UserPropertiesEventData> = combineLatest([
    this.isAuthorised(),
    this.user$,
    this.userRoles$,
    this.companyDetails$,
  ]).pipe(
    filter(
      ([isAuthorised, user, roles, companyDetails]): boolean =>
        isAuthorised && Boolean(user) && Boolean(roles) && Boolean(companyDetails),
    ),
    map(
      ([_, user, roles, companyDetails]): UserPropertiesEventData => ({
        client: companyDetails.companyName,
        clientId: user.clientId,
        role: roles,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        createdAt: user.createdAt,
      }),
    ),
    distinctUntilChanged((prev: UserPropertiesEventData, next: UserPropertiesEventData): boolean =>
      R.equals(prev, next),
    ),
  );

  private chameleonUserIdentification$: Observable<ChameleonUserIdentification> = combineLatest([
    this.isAuthorised(),
    this.isActivated(),
    this.user$,
    this.companyDetails$,
  ]).pipe(
    filter(
      ([isAuthorised, isActivated, user, companyDetails]): boolean =>
        isAuthorised &&
        isActivated &&
        Boolean(user?.userId) &&
        Boolean(user?.email) &&
        Boolean(companyDetails?.questionnaire?.length),
    ),
    map(
      ([, , user, companyDetails]): ChameleonUserIdentification =>
        this.chameleonService.getUserIdentificationData(user, companyDetails),
    ),
    distinctUntilChanged((prev: ChameleonUserIdentification, next: ChameleonUserIdentification): boolean =>
      R.equals(prev, next),
    ),
  );

  constructor(
    private readonly keycloakService: KeycloakService,
    private readonly keycloakCredentials: KeycloakCredentials,
    private readonly router: Router,
    private readonly store: Store<AppState>,
    private readonly storage: StorageService,
    private readonly lastRequestedUrlService: LastRequestedUrlService,
    private readonly userActivationService: UserActivationService,
    private readonly translateService: TranslateService,
    private readonly chameleonService: ChameleonFacade,
    private readonly subscriptionsFacade: SubscriptionsFacade,
  ) {
    super();

    this.userId$
      .pipe(
        filter((userId: string): boolean => Boolean(userId)),
        distinctUntilChanged(),
        tap((userId: string) => {
          trackEvent({
            event: 'setUserId',
            userId,
          });
        }),
      )
      .subscribe();

    this.userPropertiesEventData$
      .pipe(
        filter((userProperties: UserPropertiesEventData): boolean => Boolean(userProperties)),
        tap((userProperties: UserPropertiesEventData) => {
          trackEvent({
            event: 'setUserProperties',
            userProperties,
          });
        }),
      )
      .subscribe();

    this.chameleonUserIdentification$
      .pipe(
        filter(
          (chameleonUserIdentification: ChameleonUserIdentification): boolean =>
            Boolean(chameleonUserIdentification) && Boolean(environment.chameleonEnabled),
        ),
        distinctUntilChanged((prev, next) => JSON.stringify(prev) === JSON.stringify(next)),
        tap((chameleonUserIdentification: ChameleonUserIdentification) => {
          this.chameleonService.identify(chameleonUserIdentification);
        }),
      )
      .subscribe();
  }

  appBoot(): Promise<any> {
    logEvent('keycloak initialization start');
    return new Promise((resolve, reject) => {
      this.keycloakService
        .init({
          config: {
            url: this.keycloakCredentials.url,
            realm: this.keycloakCredentials.realm,
            clientId: 'api-dashboard',
          },
          initOptions: {
            onLoad: 'check-sso',
            checkLoginIframe: false,
          },
          enableBearerInterceptor: true,
          bearerExcludedUrls: ['/assets'],
        })
        .then(() => {
          logEvent('keycloak initialized');
          this.loadAuthState();
          this.isInitialized$.next(true);

          resolve(true);
        })
        .catch(e => logEvent('keycloak initialization failed', { message: e.message }));

      this.keycloakService.keycloakEvents$
        .pipe(
          filter(event => event.type === KeycloakEventType.OnTokenExpired),
          tap(() => {
            this.updateToken();
          }),
        )
        .subscribe();
    });
  }

  isAuthorised(): Observable<boolean> {
    return this.isInitialized$.pipe(
      filter((initialized: boolean) => Boolean(initialized)),
      map((initialized: boolean) => {
        const instance = this.getKeycloakInstance();

        return instance.authenticated;
      }),
    );
  }

  isActivated(): Observable<boolean> {
    return combineLatest([this.isInitialized$, this.isActivated$]).pipe(
      map(([initialized, isActivated]): boolean => initialized && isActivated),
    );
  }

  userId(): Observable<string> {
    return this.isInitialized$.pipe(
      filter((initialized: boolean) => Boolean(initialized)),
      map(() => {
        const token = this.getKeycloakParsedToken();
        return token ? token.user_id : null;
      }),
    );
  }

  isTermsAccepted(): Observable<boolean> {
    return this.isInitialized$.pipe(
      filter((initialized: boolean) => Boolean(initialized)),
      map(() => {
        const token = this.getKeycloakParsedToken();
        return token ? token.terms_and_conditions : false;
      }),
    );
  }

  isGelatoUser(): Observable<boolean> {
    return this.isGelatoUser$;
  }

  getHttpHeaders(): Observable<HttpHeaders> {
    const instance = this.getKeycloakInstance();
    const isTokenExpired = instance.isTokenExpired();

    return (isTokenExpired ? from(this.updateToken(true)) : of(null)).pipe(
      map((): string => instance.token),
      catchError(() => of(null)),
      map((token: string): HttpHeaders => ({ Authorization: `Bearer ${token}` })),
    );
  }

  getTokenSessionId(): string {
    const instance = this.getKeycloakInstance();

    return instance.sessionId;
  }

  getIdToken(): string {
    return this.getKeycloakInstance().idToken;
  }

  getKeycloakAuthUrl(redirectUrl: string): any {
    const { authServerUrl, realm, clientId } = this.getKeycloakInstance();

    return `${authServerUrl}/realms/${realm}/protocol/openid-connect/auth?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUrl,
    )}`;
  }

  getUserRoles(): Observable<string[]> {
    return combineLatest([this.isInitialized$, this.userRoles$]).pipe(
      filter(([initialized, roles]) => initialized && Boolean(roles)),
      map(([initialized, roles]) => roles),
    );
  }

  getClientId(): Observable<string> {
    return combineLatest([this.isInitialized$, this.defaultClientId$]).pipe(
      filter(([initialized, clientId]) => initialized),
      map(([initialized, clientId]) => clientId),
    );
  }

  getLoginOptions() {
    const params: KeycloakLoginOptions = { prompt: 'login' };
    const locale = this.storage.getItem(I18N_STORAGE) || this.translateService.currentLang;

    if (locale) {
      params.locale = toKeycloakLocale(locale as LocaleCode);
    }

    return params;
  }

  redirectToSignIn(keycloakClientId: string = null) {
    this.keycloakService.login(this.getLoginOptions()).then(() => {
      this.loadAuthState();
    });
  }

  redirectToSignUp(keycloakClientId: string = null) {
    this.keycloakService.register(this.getLoginOptions()).then(() => {
      this.loadAuthState();
    });
  }

  cleanUpLocalStorage() {
    localStorage.removeItem(LocalStorageItemKey.selectedStoreId);
    localStorage.removeItem(LocalStorageItemKey.selectedClientId);
  }

  signOut() {
    this.cleanUpLocalStorage();
    this.keycloakService.logout();
  }

  requireAuthorisedUserWithUserRole(userRole: string, route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([this.isAuthorised(), this.hasUserRole(userRole)]).pipe(
      map(([isAuthorised, hasUserRole]) => {
        if (!isAuthorised) {
          this.lastRequestedUrlService.saveActivatedRouteSnapshot(route);
          this.redirectToSignIn();
          return false;
        }

        return hasUserRole;
      }),
    );
  }

  requireAuthorisedUserWithPermission(
    scopeType: string,
    scopeName: string,
    scopeAccessLevel: 'read' | 'write',
    route: ActivatedRouteSnapshot,
  ): Observable<boolean> {
    return combineLatest([this.isAuthorised(), this.hasPermission(scopeType, scopeName, scopeAccessLevel)]).pipe(
      map(([isAuthorised, hasPermission]) => {
        if (!isAuthorised) {
          this.lastRequestedUrlService.saveActivatedRouteSnapshot(route);
          this.redirectToSignIn();
          return false;
        }

        return hasPermission;
      }),
    );
  }

  requireAuthorisedUser(route: ActivatedRouteSnapshot, keycloakClientId: string = null): Observable<boolean> {
    return combineLatest([
      this.isAuthorised(),
      this.getUserRoles(), // To make sure the user is initialized with all necessary permissions
    ]).pipe(
      map(([isAuthorised, userRoles]) => {
        if (!isAuthorised) {
          this.lastRequestedUrlService.saveActivatedRouteSnapshot(route);
          this.redirectToSignIn(keycloakClientId);
          return false;
        }

        return true;
      }),
    );
  }

  activateUser() {
    const initialToken = this.getKeycloakParsedToken();

    return from(this.getHttpHeaders()).pipe(
      switchMap((httpHeaders: HttpHeaders) => {
        return this.userActivationService.activateUser(httpHeaders).pipe(
          tap(response => {
            if (response) {
              this.updateToken(true) // Forced update of incomplete but not expired token
                .then(() => {
                  // Tokens for invited users already have populated user_id field
                  this.loadAuthState(true, !initialToken.user_id, false);
                });
            }
          }),
          catchError(() => {
            this.signOut();
            this.redirectToSignIn();

            return of(null);
          }),
        );
      }),
    );
  }

  private async loadAuthState(
    successfulRegistrationCallbackMode: boolean = false,
    selfRegistrationMode: boolean = false,
    redirect: boolean = true,
  ) {
    this.store.dispatch(new authActions.ResetState());

    if (!this.isUserAuthenticated()) {
      Sentry.setUser(null);

      this.store.dispatch(
        new authActions.SetState({
          user: null,
          userRoles: [],
          clientIds: [],
          selectedClient: null,
          selectedClientId: null,
        }),
      );

      this.isActivated$.next(false);

      return;
    }

    const initialToken = this.getKeycloakParsedToken();

    if (!initialToken.activated) {
      this.isActivated$.next(false);
      return;
    }

    trackEvent({
      event: 'logEvent',
      eventType: 'login',
    });

    const instance = this.getKeycloakInstance();
    const parsedToken = this.getKeycloakParsedToken();
    const clientIds = this.getKeycloackClientIds();
    const defaultClientId = getDefaultClientIdFromKeycloakClientIds(clientIds);
    const userCreatedAt = convertTimestampToIso8601Format(parsedToken.user_created_at);
    const profile = await instance.loadUserProfile();
    const userId = parsedToken.user_id;
    const firstName = profile.firstName;
    const lastName = profile.lastName;
    const email = profile.email;
    const userRoles = instance.realmAccess ? instance.realmAccess.roles : [];
    const user: User = new User(userId, firstName, lastName, email, R.path([0], clientIds), userCreatedAt);

    Sentry.setUser(user);

    this.isActivated$.next(true);

    this.store.dispatch(
      new authActions.SetState({
        user,
        userRoles,
        clientIds,
        selectedClient: null,
        selectedClientId: defaultClientId,
      }),
    );

    this.store.dispatch(new companyDetailsActions.Load(true));
    this.subscriptionsFacade.loadActiveSubscriptionsAndServicePlans();
    this.subscriptionsFacade.fetchClientGoldEligibility();

    if (redirect) {
      this.lastRequestedUrlService.restore(successfulRegistrationCallbackMode, selfRegistrationMode);
    }
  }

  async userRegistrationEvent(questions: QuickQuestions, company: CompanyDetails) {
    const parsedToken = this.getKeycloakParsedToken();
    const userId = parsedToken.user_id;
    const instance = this.getKeycloakInstance();
    const profile = await instance.loadUserProfile();
    const firstName = profile.firstName;
    const lastName = profile.lastName;
    const email = profile.email;
    const phoneNumber =
      questions.phonePrefix && questions.phoneNumber ? questions.phonePrefix + questions.phoneNumber : null;

    trackEvent({
      event: 'logEvent',
      eventType: 'registrationComplete',
      eventProperties: {
        userId,
        firstName,
        lastName,
        email,
        country: company.countryIsoCode,
        [QuickQuestionsSteps.sellingProductsToday]: questions.sellingProductsToday,
        [QuickQuestionsSteps.onlinePresence]: questions.onlinePresence,
        [QuickQuestionsSteps.customOnlinePresence]: questions.customOnlinePresence,
        [QuickQuestionsSteps.followers]: questions.followers,
        [QuickQuestionsSteps.isAlreadySelling]: questions.isAlreadySelling,
        [QuickQuestionsSteps.isAlreadySellingPrintOnDemandProducts]: questions.isAlreadySellingPrintOnDemandProducts,
        [QuickQuestionsSteps.printSpend]: questions.printSpend,
        [QuickQuestionsSteps.industry]: questions.industry,
        [QuickQuestionsSteps.customIndustry]: questions.customIndustry,
        [QuickQuestionsSteps.ecommercePlatform]: questions.ecommercePlatform,
        [QuickQuestionsSteps.storeLink]: questions.storeLink,
        [QuickQuestionsSteps.customEcommercePlatform]: questions.customEcommercePlatform,
        [QuickQuestionsSteps.superpower]: questions.superpower,
        [QuickQuestionsSteps.referer]: questions.referer,
        phone: phoneNumber,
      },
    });
  }

  private isUserAuthenticated() {
    return this.getKeycloakInstance().authenticated;
  }

  private getKeycloakInstance() {
    return this.keycloakService.getKeycloakInstance();
  }

  private getKeycloakParsedToken(): any {
    return this.getKeycloakInstance().tokenParsed;
  }

  private getKeycloackClientIds(): string[] {
    const tokenParsed: any = this.getKeycloakParsedToken();
    const clientIds = tokenParsed.client_ids;

    return clientIds;
  }

  private async updateToken(forced: boolean = false): Promise<boolean> {
    const minValidity = forced ? -1 : undefined;

    return this.keycloakService.updateToken(minValidity);
  }
}
