import { Routes } from '@angular/router';
import { HomeComponent } from './shared/home/home.component';
import { DashboardGuard } from './dashboard/guards/dashboard.guard';
import { OrdersListGuard } from './orders/guards/orders-list.guard';
import { OrderBillingReceiptsGuard } from './order-billing-receipts/guards/order-billing-receipts.guard';
import { BillingEntityListGuard } from './billing-entities/guards/billing-entity-list.guard';
import { ClientsListGuard } from './clients/guards/clients-list.guard';
import { KeysListGuard } from './api-keys/guards/keys-list.guard';
import { NotificationsListGuard } from './notifications/guards/notifications-list.guard';
import { CompanyInfoGuard } from './company-info/guards/company-info.guard';
import { UsersListGuard } from './users/guards/users-list.guard';
import { ProductCatalogueGuard } from './product-catalogue/guards/product-catalogue.guard';
import { PricePlansGuard } from './price-plans/guards/price-plans.guard';
import { PricePlanSubscriptionsGuard } from './price-plan-subscriptions/guards/price-plan-subscriptions.guard';
import { ProductionConfigurationsListGuard } from './production-configurations/guards/production-configurations-list.guard';
import { PermissionsGuard } from './shared/guards/permissions.guard';
import { CheckoutPermissionGuard } from './checkout/guards/checkout-permission.guard';
import { ActivationGuard } from '@api-ui-app/src/app/activation.guard';
import { AuthorisationGuard } from '@api-ui-app/src/app/shared/guards/authorisation.guard';
import { LocalizationGuard } from '@api-ui-app/src/app/shared/guards/localization.guard';
import { CurrencyGuard } from '@api-ui-app/src/app/shared/guards/currency.guard';
import { ImpactGuard } from '@api-ui-app/src/app/dashboard/guards/impact.guard';
import { BrandedPackagingGuard } from '@api-ui-app/src/app/branding/branded-packaging-configure/guards/branded-packaging.guard';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { PaymentTransactionsGuard } from '@api-ui-app/src/app/payment-transactions/guards/payment-transactions.guard';
import { ReturnAddressesGuard } from '@api-ui-app/src/app/return-addresses/guards/return-addresses.guard';
import { MockupGalleryGuard } from '@api-ui-app/src/app/mockup-gallery/guards/mockup-gallery.guard';
import { MockupStudioGuard } from '@api-ui-app/src/app/mockup-studio/guards/mockup-studio-guard.service';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    canActivate: [CurrencyGuard, LocalizationGuard, ActivationGuard],
  },
  {
    path: 'auth-callback',
    component: HomeComponent,
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [CurrencyGuard, LocalizationGuard, ActivationGuard, DashboardGuard],
  },
  {
    path: 'auth',
    loadChildren: () => import('./authorisation/authorisation.module').then(m => m.AuthorisationModule),
    canActivate: [LocalizationGuard],
  },
  {
    path: 'authorize',
    loadChildren: () => import('./authorisation/authorisation.module').then(m => m.AuthorisationModule),
  },

  // GAPI-889: Aliases for auth routes
  { path: 'sign-up', pathMatch: 'full', redirectTo: 'auth/sign-up' },
  { path: 'signup', pathMatch: 'full', redirectTo: 'auth/sign-up' },
  { path: 'sign-in', pathMatch: 'full', redirectTo: 'auth/sign-in' },
  { path: 'signin', pathMatch: 'full', redirectTo: 'auth/sign-in' },
  { path: 'sign-out', pathMatch: 'full', redirectTo: 'auth/sign-out' },
  { path: 'signout', pathMatch: 'full', redirectTo: 'auth/sign-out' },
  { path: 'login', pathMatch: 'full', redirectTo: 'auth/sign-in' },
  { path: 'logout', pathMatch: 'full', redirectTo: 'authorize/sign-out' },

  {
    path: 'company-info',
    loadChildren: () => import('./company-info/company-info.module').then(m => m.CompanyInfoModule),
    canActivate: [ActivationGuard, CompanyInfoGuard],
  },
  {
    path: 'features',
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule),
  },
  {
    path: 'orders',
    loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule),
    canActivate: [ActivationGuard, OrdersListGuard],
  },
  {
    path: 'checkout',
    canActivate: [CheckoutPermissionGuard],
    loadChildren: () => import(`./checkout/checkout.module`).then(m => m.CheckoutModule),
  },
  {
    path: 'expand-categories',
    canActivate: [ActivationGuard],
    loadChildren: () =>
      import('./e-commerce-expand-categories/e-commerce-expand-categories.module').then(
        m => m.ECommerceExpandCategoriesModule,
      ),
  },
  {
    path: 'checkout/:orderId',
    canActivate: [CheckoutPermissionGuard],
    loadChildren: () => import(`./checkout/checkout.module`).then(m => m.CheckoutModule),
  },
  {
    path: 'order-billing-receipts',
    loadChildren: () =>
      import('./order-billing-receipts/order-billing-receipts.module').then(m => m.OrderBillingReceiptsModule),
    canActivate: [OrderBillingReceiptsGuard],
  },
  {
    path: 'billing-entities',
    loadChildren: () => import('./billing-entities/billing-entities.module').then(m => m.BillingEntitiesModule),
    canActivate: [BillingEntityListGuard],
  },
  {
    path: 'return-addresses',
    loadChildren: () => import('./return-addresses/return-addresses.module').then(m => m.ReturnAddressesModule),
    canActivate: [ReturnAddressesGuard],
  },
  {
    path: 'clients',
    loadChildren: () => import('./clients/clients.module').then(m => m.ClientsModule),
    canActivate: [ClientsListGuard],
  },
  {
    path: 'keys',
    loadChildren: () => import('./api-keys/api-keys.module').then(m => m.ApiKeysModule),
    canActivate: [ActivationGuard, KeysListGuard],
  },
  {
    path: 'webhooks',
    loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [ActivationGuard, NotificationsListGuard],
  },
  {
    path: 'payment-methods',
    loadChildren: () => import('./payment-methods/wallets/wallets.module').then(m => m.WalletsModule),
    canActivate: [ActivationGuard, PermissionsGuard],
    data: {
      accessRequirements: {
        permission: 'write',
        scopeType: 'billing',
        scopeName: 'stored-payment-method',
      },
    },
  },
  {
    path: 'currency',
    canActivate: [ActivationGuard, PermissionsGuard],
    data: {
      accessRequirements: {
        permission: 'write',
        scopeType: 'billing',
        scopeName: 'stored-payment-method',
      },
    },
    loadChildren: () => import('./currency/currency.module').then(m => m.CurrencyModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    canActivate: [ActivationGuard, UsersListGuard],
  },
  {
    path: 'catalogue',
    loadChildren: () => import('./product-catalogue/product-catalogue.module').then(m => m.ProductCatalogueModule),
    canActivate: [CurrencyGuard, LocalizationGuard, ActivationGuard, AuthorisationGuard],
  },
  {
    path: 'stores/current/products/wizard',
    loadChildren: () => import('./product-catalogue/product-catalogue.module').then(m => m.ProductCatalogueModule),
    canActivate: [ProductCatalogueGuard],
  },
  {
    path: 'stores/current/connect-variant',
    loadChildren: () => import('./product-catalogue/product-catalogue.module').then(m => m.ProductCatalogueModule),
    canActivate: [ProductCatalogueGuard],
  },
  {
    path: 'stores/current/connect-variant/print-file-verification',
    loadChildren: () => import('./product-catalogue/product-catalogue.module').then(m => m.ProductCatalogueModule),
    canActivate: [ProductCatalogueGuard],
  },
  {
    path: 'stores/current/connect-variant/edit-variant/:product-variant-id',
    loadChildren: () => import('./product-catalogue/product-catalogue.module').then(m => m.ProductCatalogueModule),
    canActivate: [ProductCatalogueGuard],
  },
  {
    path: 'price-plans',
    loadChildren: () => import('./price-plans/price-plans.module').then(m => m.PricePlansModule),
    canActivate: [PricePlansGuard],
  },
  {
    path: 'price-plan-subscriptions',
    loadChildren: () =>
      import('./price-plan-subscriptions/price-plan-subscriptions.module').then(m => m.PricePlanSubscriptionsModule),
    canActivate: [PricePlanSubscriptionsGuard],
  },
  {
    path: 'production-configurations',
    loadChildren: () =>
      import('./production-configurations/production-configurations.module').then(
        m => m.ProductionConfigurationsModule,
      ),
    canActivate: [ProductionConfigurationsListGuard],
  },
  {
    path: 'tax-center',
    loadChildren: () => import('./tax-center/tax-center.module').then(m => m.TaxCenterModule),
    canActivate: [ActivationGuard],
  },
  {
    path: `${SanityProductCategoryName.BRANDING}/configure`,
    loadChildren: () =>
      import('./branding/branded-packaging-configure/branded-packaging-configure.module').then(
        m => m.BrandedPackagingConfigureModule,
      ),
    canActivate: [BrandedPackagingGuard, ActivationGuard],
  },
  {
    path: `${SanityProductCategoryName.BRANDING}/projects`,
    loadChildren: () =>
      import('./branding/branded-packaging-projects/branded-packaging-projects.module').then(
        m => m.BrandedPackagingProjectsModule,
      ),
    canActivate: [BrandedPackagingGuard, ActivationGuard],
  },
  {
    path: 'mockup-gallery',
    loadChildren: () => import('./mockup-gallery/mockup-gallery.module').then(m => m.MockupGalleryModule),
    canActivate: [MockupGalleryGuard, ActivationGuard],
  },
  {
    path: 'mockup-studio',
    loadChildren: () => import('./mockup-studio/mockup-studio.module').then(m => m.MockupStudioModule),
    canActivate: [MockupStudioGuard, ActivationGuard],
  },
  {
    path: 'payment-transactions',
    loadChildren: () =>
      import('./payment-transactions/payment-transactions.module').then(m => m.PaymentTransactionsModule),
    canActivate: [PaymentTransactionsGuard, ActivationGuard],
  },
  {
    path: 'on-boarding',
    loadChildren: () => import('./on-boarding/on-boarding.module').then(m => m.OnBoardingModule),
    canActivate: [ImpactGuard],
  },
  {
    path: 'questionnaire',
    pathMatch: 'full',
    redirectTo: 'on-boarding/questionnaire',
  },
  {
    path: 'stores',
    loadChildren: () => import('./e-commerce-stores/e-commerce-stores.module').then(m => m.ECommerceStoresModule),
    canActivate: [ActivationGuard],
  },
  {
    path: 'shopify/link',
    pathMatch: 'full',
    redirectTo: 'stores/shopify/link',
  },
  {
    path: 'shopify',
    pathMatch: 'full',
    redirectTo: 'stores/shopify/link',
  },
  {
    path: 'analytics',
    loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
    canActivate: [ActivationGuard],
  },
  {
    path: 'growth-plans',
    loadChildren: () => import('./app-store/app-store.module').then(m => m.AppStoreModule),
    canActivate: [ActivationGuard],
  },
  {
    path: 'upgrade-framed-posters',
    loadChildren: () =>
      import('./e-commerce-framed-posters-upgrade/e-commerce-framed-posters-upgrade.module').then(
        m => m.ECommerceFramedPostersUpgradeModule,
      ),
    canActivate: [ActivationGuard],
  },
  {
    path: 'audit-logs',
    loadChildren: () => import('./audit-logs/audit-logs.module').then(m => m.AuditLogsModule),
    canActivate: [ActivationGuard],
  },
  {
    path: 'templates',
    loadChildren: () =>
      import('./e-commerce-product-templates/e-commerce-product-templates.module').then(
        m => m.ECommerceProductTemplatesModule,
      ),
    canActivate: [ActivationGuard],
  },
  {
    path: 'product-expansion',
    loadChildren: () =>
      import('./e-commerce-product-expansion/e-commerce-product-expansion.module').then(
        m => m.ECommerceProductExpansionModule,
      ),
    canActivate: [ActivationGuard],
  },
];
