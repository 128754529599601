import { Action } from '@ngrx/store';
import { State } from './layout.reducer';

export enum LayoutActionTypes {
  ResetState = '[Layout] ResetState',
  SetState = '[Layout] Set State',
  ToggleSidebar = '[Layout] Toggle Sidebar',
  PersistToggleState = '[Layout] Persist Toggle State',
  ToggleFullScreenMode = '[Layout] Toggle FullScreenMode',
}

export class ResetState implements Action {
  readonly type = LayoutActionTypes.ResetState;
}

export class SetState implements Action {
  readonly type = LayoutActionTypes.SetState;

  constructor(public payload: State) {}
}

export class PersistToggleState implements Action {
  readonly type = LayoutActionTypes.PersistToggleState;

  constructor() {}
}

export class ToggleSidebar implements Action {
  readonly type = LayoutActionTypes.ToggleSidebar;

  constructor() {}
}

export class ToggleFullScreenMode implements Action {
  readonly type = LayoutActionTypes.ToggleFullScreenMode;

  constructor(public payload: boolean) {}
}

export type LayoutActions = ResetState | SetState | ToggleSidebar | ToggleFullScreenMode | PersistToggleState;
