<gc-dropdown-menu
  #dropdownMenu
  [positionLeft]="true"
  [menuTopValue]="'115%'"
  [buttonType]="'basic orange'"
  [buttonContent]="dropdown"
  [dropdownTpl]="dropdownTpl"
  [isDisabled]="!canProceed"
  (menuButtonClick)="logProceedWithPlusProductEvent()"
>
</gc-dropdown-menu>
<ng-template #dropdown>
  {{ buttonText }}
</ng-template>

<ng-template #dropdownTpl>
  <div class="ui paywall-message">
    <div class="header">
      {{ 'txt_editor_paywall_title' | translate }}
      <img class="close-icon" [src]="'icons/close.svg' | assetsPath | async" />
    </div>
    <p [innerHTML]="'txt_editor_paywall_description' | translate"></p>
    <button class="ui button basic fluid icon open-plus-modal" (click)="onOpenGelatoPlusModal()">
      <img src="{{ 'subscriptions/crown.svg' | assetsPath | async }}" width="32" height="32" alt="gelato-plus" />
      {{ 'txt_editor_paywall_try_gelato_plus_button' | translate }}
    </button>
  </div>
</ng-template>
