import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'gd-try-gelato-plus-notification',
  templateUrl: './try-gelato-plus-notification.component.html',
  styleUrls: ['./try-gelato-plus-notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TryGelatoPlusNotificationComponent implements OnInit {
  @Input() public title: string;
  @Input() public message: string;
  @Input() public showButton = true;

  @Output() public actionClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public onClick() {
    if (this.actionClick) {
      this.actionClick.emit();
    }
  }
}
