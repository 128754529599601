import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  AUSTRALIAN_STATES,
  BRAZILIAN_STATES,
  CANADIAN_PROVINCES,
  CHILEAN_PROVINCES,
  JAPANESE_PREFECTURES,
  US_STATES,
} from '@api-ui-app/src/app/lib/constants';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { HALF_SECOND } from '@gelato-api-ui/utils/time';
import { CountryWithStates } from '../../types/countryWithStates';

@Component({
  selector: 'gc-state-selector',
  templateUrl: './state-selector.component.html',
  styleUrls: ['./state-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateSelectorComponent {
  @Input() disabled = false;
  @Input() value: string | string[];
  @Input() isSearchable = false;
  @Input() showIsentoOption = true;
  @Input() isMultiple = false;
  @Input() set selectedCountry(country: CountryWithStates) {
    this.selectedCountryValue = country;
    this.repaint$.next(null);
  }
  get selectedCountry(): CountryWithStates {
    return this.selectedCountryValue;
  }
  @Output() valueChange: EventEmitter<string> = new EventEmitter();

  private repaint$: BehaviorSubject<null> = new BehaviorSubject<any>(null);
  input$: BehaviorSubject<string | null> = new BehaviorSubject(undefined);
  options$: Observable<string[]> = combineLatest([this.input$, this.repaint$]).pipe(
    debounceTime(HALF_SECOND / 2),
    map(([input]) => this.searchFn(input)),
  );

  private selectedCountryValue: CountryWithStates;
  private readonly STATES_MAP = {
    US: US_STATES,
    CA: CANADIAN_PROVINCES,
    AU: AUSTRALIAN_STATES,
    BR: BRAZILIAN_STATES,
    CL: CHILEAN_PROVINCES,
    JP: JAPANESE_PREFECTURES,
  };
  private readonly TRANSLATION_KEY_MAP = {
    US: 'txt_us_state',
    CA: 'txt_canadian_province',
    AU: 'txt_australian_state',
    BR: 'txt_brazilian_state',
    CL: 'txt_chilean_region',
  };
  private EMPTY_OPTION_MAP = {
    US: 'txt_us_state_selector_empty_option',
    CA: 'txt_canadian_province_selector_empty_option',
    AU: 'txt_australian_state_selector_empty_option',
    BR: 'txt_brazilian_state_selector_empty_option',
    CL: 'txt_chilean_region_selector_empty_option',
    JP: 'txt_japanese_prefecture_selector_empty_option',
  };

  constructor(private readonly translateService: TranslateService) {}

  private get options(): string[] {
    const options = ['', ...this.getStates()];
    return this.showIsentoOption ? options : options.filter((stateId: string) => stateId !== 'ISENTO');
  }

  get emptyOptionKey() {
    return this.EMPTY_OPTION_MAP?.[this.selectedCountry];
  }

  private getStateFullNames(): Record<string, string> {
    return this.options.filter(Boolean).reduce((acc, stateKey) => {
      const translatedState = this.translateService.instant(
        `${this.TRANSLATION_KEY_MAP[this.selectedCountry]}_${stateKey}`,
      );

      return {
        ...acc,
        [stateKey]: `${stateKey} ${translatedState}`.toLowerCase(),
      };
    }, {});
  }

  onSelect(stateId) {
    this.valueChange.emit(stateId);
  }

  searchFn(term: string): string[] {
    if (!term) {
      return this.options;
    }

    const searchTerm = term.toLowerCase().trim();
    const countryFullNameMap = this.getStateFullNames();

    return this.options.filter(stateCode => {
      const fullStateName = countryFullNameMap[stateCode];

      return fullStateName?.includes(searchTerm);
    });
  }

  private getStates(selectedState: CountryWithStates = this.selectedCountry): string[] {
    return this.STATES_MAP ? this.STATES_MAP[selectedState].sort() : [];
  }
}
