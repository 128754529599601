import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';

@Injectable({ providedIn: 'root' })
export class ReturnAddressesGuard implements CanActivate {
  constructor(private readonly authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.authService.requireAuthorisedUserWithReadPermission('order', 'configuration', route);
  }
}
