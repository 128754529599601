import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';
import * as layoutActions from '@api-ui-app/src/app/ngrx/layout.actions';

@Injectable()
export class LayoutService {
  constructor(private readonly store: Store<AppState>) {}

  enableFullScreenMode() {
    this.toggleFullScreenMode(true);
  }

  disableFullScreenMode() {
    this.toggleFullScreenMode(false);
  }

  private toggleFullScreenMode(isFullScreenMode: boolean) {
    this.store.dispatch(new layoutActions.ToggleFullScreenMode(isFullScreenMode));
  }
}
