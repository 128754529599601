import { Injectable } from '@angular/core';
import { PreviewFileType } from '../preview-file-type.enum';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import {
  ApparelAndAccessoriesProductTypeUids,
  BagProductTypeUids,
} from '@gelato-api-ui/core/product-catalogue/product-type-uids';
import { SanityProductCategoryName } from '@gelato-api-ui/core/sanity/sanity-product-category-name.enum';
import { ApparelAndAccessoriesSanityProductCategoryNames } from '@gelato-api-ui/core/sanity/apparel-and-accessories-sanity-product-category-names';

@Injectable({ providedIn: 'root' })
export class DesignPreviewFileTypeService {
  getByProductTypeUid(productTypeUid: ProductTypeUid): PreviewFileType {
    return this.getPreviewFileType(this.isBackgroundTransparencySupportedByProductTypeUid(productTypeUid));
  }

  getBySanityProductCategoryName(sanityProductCategoryName: SanityProductCategoryName): PreviewFileType {
    return this.getPreviewFileType(
      this.isBackgroundTransparencySupportedBySanityProductCategoryName(sanityProductCategoryName),
    );
  }

  private getPreviewFileType(isBackgroundTransparencySupported: boolean): PreviewFileType {
    return isBackgroundTransparencySupported ? PreviewFileType.PNG : PreviewFileType.JPEG;
  }

  private isBackgroundTransparencySupportedByProductTypeUid(productTypeUid: ProductTypeUid): boolean {
    return [...ApparelAndAccessoriesProductTypeUids, ProductTypeUid.PHONE_CASE].includes(productTypeUid);
  }

  private isBackgroundTransparencySupportedBySanityProductCategoryName(
    sanityProductCategoryName: SanityProductCategoryName,
  ): boolean {
    return [
      ...ApparelAndAccessoriesSanityProductCategoryNames,
      ...BagProductTypeUids,
      SanityProductCategoryName.PHONE_CASES,
    ].includes(sanityProductCategoryName);
  }
}
