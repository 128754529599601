import { createSelector } from '@ngrx/store';
import { AppState } from '../../app.state';
import { State } from './price-plans.reducer';
import { PricePlan } from '../lib/price-plan';
import { PricePlanSearchResponse } from '../lib/price-plan-search-response';
import { PricePlanListState, PricePlanState } from '@api-ui-app/src/app/price-plans/+state/price-plans.reducer';

export const getState = (state: AppState): State => state.pricePlans;

export const getPricePlanListState = createSelector(
  getState,
  (state: State): PricePlanListState => state.pricePlanList,
);

export const isPricePlanListLoading = createSelector(
  getState,
  (state: State): boolean => state.pricePlanList?.isLoading,
);

export const getPricePlanListResponse = createSelector(
  getPricePlanListState,
  (pricePlanListState: PricePlanListState): PricePlanSearchResponse => pricePlanListState.payload,
);

export const getPricePlanListNames = createSelector(
  getPricePlanListResponse,
  (pricePlanListState: PricePlanSearchResponse): {} => {
    const pricePlanNameByIdObj = {};

    pricePlanListState?.data.forEach((plan: PricePlan) => {
      pricePlanNameByIdObj[plan.id] = plan.name;
    });

    return pricePlanNameByIdObj;
  },
);

export const getPricePlanState = createSelector(getState, (state: State): PricePlanState => state.pricePlan);

export const getPricePlan = createSelector(
  getPricePlanState,
  (pricePlanState: PricePlanState): PricePlan => pricePlanState.payload,
);
