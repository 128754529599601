import { Injectable } from '@angular/core';
import { EProductTreeItem } from '@api-ui-app/src/app/product/product-add/types/e-product-tree-item';
import { ProductVariantIdsCollection } from '@api-ui-app/src/app/product/product-add/types/product-variant-ids-collection';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';

@Injectable()
export class ECommerceProductsTreeCheckedProductVariantsService {
  getCheckedProductVariants(
    productsTree: EProductTreeItem[],
    checkedProductVariantIds: ProductVariantIdsCollection,
  ): EProductVariant[] {
    if (!productsTree || !productsTree.length || !checkedProductVariantIds) {
      return [];
    }

    const productVariants: EProductVariant[] = [];

    productsTree.forEach((loopProduct: EProductTreeItem) => {
      loopProduct.variants.forEach((loopProductVariant: EProductVariant) => {
        if (
          !checkedProductVariantIds[loopProduct.id] ||
          !checkedProductVariantIds[loopProduct.id].includes(loopProductVariant.id)
        ) {
          return;
        }

        productVariants.push(loopProductVariant);
      });
    });

    return productVariants;
  }

  getCheckedProductVariantIdsCount(checkedProductVariantIds: ProductVariantIdsCollection): number {
    if (!checkedProductVariantIds) {
      return 0;
    }

    let count = 0;

    Object.keys(checkedProductVariantIds).forEach((productId: string) => {
      if (checkedProductVariantIds[productId] && checkedProductVariantIds[productId].length) {
        count += checkedProductVariantIds[productId].length;
      }
    });

    return count;
  }
}
