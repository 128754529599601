import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AppInit } from '@gelato-api-ui/sdk/src/lib/sdk.actions';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { AppState } from './app.state';
import { scrollLayoutContentToTop } from './lib/scrollLayoutContentToTop';
import { StorageService } from '@gelato-api-ui/sdk/src/lib/storage/storage.service';
import { I18N_STORAGE } from '@gelato-api-ui/sdk/src/lib/translations/i18n.service';
import { ChargeBeeService } from '@api-ui-app/src/app/subscriptions/services/charge-bee.service';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { LocaleService } from '@api-ui-app/src/app/shared/services/locale.service';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { filter } from 'rxjs/operators';
import { getCurrentUrl } from '@api-ui-app/src/app/utils/router.selector';
import { DestygoWebChatService } from '@api-ui-app/src/app/app-store/services/destygo-web-chat.service';
import { UrlFragmentService } from '@api-ui-app/src/app/shared/services/url-fragment.service';
import { VoucherService } from './dashboard/services/voucher.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store<AppState>,
    private readonly storage: StorageService,
    private readonly subscriptionFacade: SubscriptionsFacade,
    private readonly chargebeeApiService: ChargeBeeService,
    private readonly destygoWebChatService: DestygoWebChatService,
    private readonly localeService: LocaleService,
    private readonly urlFragmentService: UrlFragmentService,
    private readonly voucherService: VoucherService,
    private authService: AuthService,
  ) {}

  ngOnInit() {
    this.translate.setDefaultLang(this.storage.getItem(I18N_STORAGE) || LocaleCode.EN);
    this.localeService.updateLocale(this.translate.defaultLang as LocaleCode);
    this.destygoWebChatService.init();

    this.authService
      .isAuthorised()
      .pipe(filter(isAuthorised => isAuthorised))
      .subscribe(() => {
        this.subscriptionFacade.init();
        this.voucherService.getListOfPromotions();
        this.chargebeeApiService.loadChargeBeeSdk();
        this.urlFragmentService.removeFragmentsFromTheUrl();
      });

    this.store.select(getCurrentUrl).subscribe(() => {
      // When we navigate between pages, application keeps scrollTop on layout content container
      scrollLayoutContentToTop();
    });

    this.store.dispatch(new AppInit());
  }
}
