<div
  class="ui label {{ color }} center aligned"
  [class.fluid]="fluid"
  [class.no-wrap]="noWrap"
  [class.shrink-on-phone]="!noShrink"
  [style.max-width]="maxWidthStyle"
>
  <ng-content></ng-content>
  <span>{{ text }}</span>
</div>
