import { NgModule } from '@angular/core';
import { ClientNamePipe } from '@product-catalogue/src/lib/shared/pipes/client-name.pipe';
import { ClientDescriptionPipe } from '@product-catalogue/src/lib/shared/pipes/client-description.pipe';
import { CountryNamePipe } from '@product-catalogue/src/lib/shared/pipes/country-name.pipe';
import { SortApparelSizesPipe } from '@product-catalogue/src/lib/shared/pipes/sort-apparel-sizes.pipe';

const exportedPipes = [ClientNamePipe, ClientDescriptionPipe, CountryNamePipe, SortApparelSizesPipe];

@NgModule({
  declarations: [...exportedPipes],
  exports: [...exportedPipes],
})
export class PipesModule {}
