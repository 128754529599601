import { CustomTrim, CustomTrimUnit } from '@gelato-api-ui/core/e-commerce/e-product-variant';

export const getCustomTrimByDimensions = (
  width: number,
  height: number,
  unit: CustomTrimUnit = CustomTrimUnit.mm,
): CustomTrim => {
  if (!width || !height || !unit) {
    return null;
  }

  return {
    width: { value: width, unit },
    height: { value: height, unit },
  };
};
