import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import { addNewUserParameterToShopifyLinkUrl } from '../../e-commerce-stores/lib/helpers/addNewUserParameterToShopifyLinkUrl';

@Injectable({
  providedIn: 'root',
})
export class LastRequestedUrlService {
  key = 'lastRequestedUrl';
  activationKey = 'lastRequestedActivationUrl';

  constructor(private router: Router, private store: Store<AppState>) {}

  restore(successfulRegistrationCallbackMode: boolean, selfRegistrationMode: boolean) {
    const url: string = this.get();

    try {
      if (successfulRegistrationCallbackMode) {
        if (this.isShopifyConnectMode()) {
          this.router.navigateByUrl(addNewUserParameterToShopifyLinkUrl(url));
        } else {
          this.router.navigate(['/auth-callback', { selfRegistrationMode: Number(Boolean(selfRegistrationMode)) }]);
        }
      } else if (url) {
        this.router.navigateByUrl(url);
      }
    } catch (e) {
      // Ignore
    }

    this.clear();
  }

  save(url: string, key: string = null) {
    try {
      if (!url) {
        this.clear();
        return;
      }

      localStorage.setItem(key || this.key, url);
    } catch (e) {
      // Ignore
    }
  }

  saveActivatedRouteSnapshot(activatedRouteSnapshot: ActivatedRouteSnapshot, key: string = null) {
    try {
      this.save(activatedRouteSnapshot['_routerState'].url, key);
    } catch (e) {
      // Ignore
    }
  }

  clear(key: string = null) {
    try {
      localStorage.removeItem(key || this.key);
    } catch (e) {
      // Ignore
    }
  }

  get(key: string = null): string {
    try {
      return localStorage.getItem(key || this.key);
    } catch (e) {
      return null;
    }
  }

  isShopifyConnectMode(key: string = null) {
    const url = this.get(key);

    if (!url) {
      return false;
    }

    return /shopify/.test(url);
  }
}
