import { Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { ECommerceProductWizardFacade } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard.facade';
import { first, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EProductData } from '@gelato-api-ui/core/e-commerce/e-product-data';

@Component({
  selector: 'gd-e-commerce-product-wizard-leave-confirmation-modal',
  templateUrl: './e-commerce-product-wizard-leave-confirmation-modal.component.html',
  styleUrls: ['./e-commerce-product-wizard-leave-confirmation-modal.component.scss'],
})
export class ECommerceProductWizardLeaveConfirmationModalComponent implements OnInit {
  productId$ = this.facade.productId$;
  isDraftSaved$ = this.facade.isDraftSaved$;
  isSavingDraft$ = this.facade.isSavingDraft$;
  isSavingDraftViaModal = false;
  isDeletingDraft = false;

  constructor(public modal: SuiModal<void, void, void>, private readonly facade: ECommerceProductWizardFacade) {}

  ngOnInit() {}

  discard() {
    this.facade.destroyProductDraftAutoSaveTimer();
    this.isDraftSaved$
      .pipe(
        withLatestFrom(this.productId$),
        first(),
        tap(([isDraftSaved, productId]) => {
          if (isDraftSaved && productId) {
            this.deleteDraft();
          } else {
            this.approveModal();
          }
        }),
      )
      .subscribe();
  }

  saveAsDraft() {
    this.facade.destroyProductDraftAutoSaveTimer();
    this.isSavingDraftViaModal = true;
    this.facade
      .beforeProductDraftSaveCallback()
      .pipe(
        first(),
        switchMap(
          (): Observable<EProductData> =>
            this.facade.saveProductDraft().pipe(
              first(),
              tap(() => {
                this.approveModal();
                this.isSavingDraftViaModal = false;
              }),
            ),
        ),
      )
      .subscribe();
  }

  private deleteDraft() {
    this.isDeletingDraft = true;
    this.facade
      .deleteProductDraft()
      .pipe(
        first(),
        tap(() => {
          this.approveModal();
          this.isDeletingDraft = false;
        }),
      )
      .subscribe();
  }

  private approveModal() {
    this.modal.approve(undefined);
  }
}

export class ECommerceProductWizardLeaveConfirmationModal extends ComponentModalConfig<void, void, void> {
  constructor() {
    super(ECommerceProductWizardLeaveConfirmationModalComponent);

    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}
