import {
  EStoreSyncStatus,
  EStoreSyncStatusResponse,
} from '@gelato-api-ui/core/e-commerce/e-store-sync-status-response';

export const isSyncStoreProductsActive = (response: EStoreSyncStatusResponse): boolean =>
  !response || isSyncStoreProductsStatusActive(response.status);

export const isSyncStoreProductsStatusActive = (status: EStoreSyncStatus): boolean =>
  status !== EStoreSyncStatus.FINISHED;
