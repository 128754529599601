import { Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';

interface AlertModalContext {
  title: string;
  message: string;
  approveButtonText: string;
}

@Component({
  selector: 'gd-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss'],
})
export class AlertModalComponent implements OnInit {
  constructor(public modal: SuiModal<AlertModalContext, void, void>) {}

  ngOnInit() {}
}

export class AlertModal extends ComponentModalConfig<AlertModalContext, void, void> {
  constructor(title: string, message: string, approveButtonText: string = null) {
    super(AlertModalComponent, { title, message, approveButtonText });

    this.isClosable = false;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}
