import { Injectable } from '@angular/core';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import {
  SubscriptionResponse,
  SubscriptionSearchRequest,
  SubscriptionSearchResponse,
} from '@api-ui-app/src/app/subscriptions/types/subscriptions';
import { ApolloQueryResult } from '@apollo/client/core';
import * as gql from 'gql-query-builder';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';
import * as Sentry from '@sentry/angular';

@Injectable()
export class SubscriptionsApiService {
  constructor(
    private readonly apiRequest: ApiRequestService,
    private readonly graphQlRequestService: GraphQlRequestService,
  ) {}

  searchSubscriptions(request: Partial<SubscriptionSearchRequest>): Observable<SubscriptionSearchResponse> {
    return this.apiRequest.get<SubscriptionSearchResponse>('/api/v3/subscriptions', { body: request }).pipe(
      map((data: any) => data.data),
      catchError((error): Observable<SubscriptionSearchResponse> => {
        Sentry.captureException(new Error('searchSubscriptions'), scope => {
          scope.setExtras({
            error,
            request,
          });

          return scope;
        });
        return of(error);
      }),
    );
  }

  /**
   * The method to Activate or Completely cancel the current Plus subscription
   * Will be activated when:
   *  1. Current Plus subscription is in 'in_trial' status
   *  2. Current Plus subscription trialEndAction is not 'cancel_subscription'
   * Will be canceled if:
   *  1. Current Plus subscription is in 'in_trial' status
   *  2. Current Plus subscription has cancelledAt is not empty
   * @param subscriptionId
   */
  updateSubscription(subscriptionId: string): Observable<SubscriptionResponse> {
    return this.apiRequest.post<SubscriptionSearchResponse>(`/api/v3/subscriptions/${subscriptionId}:end-trial`).pipe(
      map((data: any) => data.data),
      catchError((error): Observable<SubscriptionSearchResponse> => {
        Sentry.captureException(new Error('subscriptionUpdateFailed'), scope => {
          scope.setExtras({
            error,
            subscriptionId,
          });

          return scope;
        });
        return of(error);
      }),
    );
  }

  isClientEligibleForGoldSubscriptions(clientId: string): Observable<{ isClientEligibleForGoldSubscription: boolean }> {
    const query = this.getQuery(clientId);

    return this.graphQlRequestService
      .query(query, 'isClientEligibleForGoldSubscription')
      .pipe(map((data: ApolloQueryResult<any>) => data?.data));
  }

  cancelSubscription(subscriptionId: string): Observable<SubscriptionResponse> {
    return this.apiRequest
      .post<SubscriptionResponse>(`/api/v3/subscriptions/${subscriptionId}:cancel`, {
        body: { cancelReasonCode: 'Canceled the subscription by the admin' },
      })
      .pipe(
        map(data => data.data),
        catchError((error): Observable<SubscriptionResponse> => {
          Sentry.captureException(new Error('subscriptionCancellationFailed'), scope => {
            scope.setExtras({
              error,
              subscriptionId,
            });

            return scope;
          });
          return of(null);
        }),
      );
  }

  private getQuery(clientId: string) {
    return gql.query({
      operation: 'isClientEligibleForGoldSubscription',
      variables: {
        clientId: { type: 'String!', value: clientId },
      },
    });
  }
}
