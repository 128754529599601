import { Injectable } from '@angular/core';
import { filter, first, tap } from 'rxjs/operators';
import { getDefaultClientId } from '@api-ui-app/src/app/ngrx/auth.reducer';
import { ApiRequestService } from '@gelato-api-ui/core/api/services/api-request.service';
import { Store } from '@ngrx/store';
import { AppState } from '@api-ui-app/src/app/app.state';

interface ImpactCustomerRequest {
  clickId: string;
  clientId: string;
  userAgent: string;
}

export const LOCAL_STORAGE_IRCLICKID_KEY = 'irclickid';
export const IRCLICKID = 'irclickid';

@Injectable({
  providedIn: 'root',
})
export class ImpactApiService {
  constructor(private apiRequest: ApiRequestService, private readonly store: Store<AppState>) {}

  defaultClientId$ = this.store.select(getDefaultClientId);

  saveParams(params) {
    const id = params[IRCLICKID];

    if (id) {
      localStorage.setItem(LOCAL_STORAGE_IRCLICKID_KEY, id);
    }
  }

  removeParams() {
    localStorage.removeItem(LOCAL_STORAGE_IRCLICKID_KEY);
  }

  track(clickId: string) {
    this.defaultClientId$
      .pipe(
        filter(id => Boolean(id)),
        first(),
        tap(clientId => {
          const request: ImpactCustomerRequest = {
            clickId,
            clientId,
            userAgent: window.navigator.userAgent,
          };

          this.removeParams();
          this.apiRequest.post('/api/v1/impact/customers', { body: request });
        }),
      )
      .subscribe();
  }
}
