import * as Sentry from '@sentry/angular';
import { trackEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { EStore } from '@gelato-api-ui/core/e-commerce/e-store';

export const trackStoreAddProductInitiatedEvent = (store: EStore) => {
  if (!store) {
    return;
  }

  const data = {
    storeType: store.type,
    storeName: store.name,
    storeId: store.id,
    storeUrl: store.domain,
  };

  trackEvent({
    event: 'logEvent',
    eventType: 'storeAddProductInitiated',
    eventProperties: data,
  });

  Sentry.addBreadcrumb({
    category: 'AddStoreProductFlowService',
    message: 'StoreAddProductInitiated',
    data,
  });
};
