import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { callWithRetries } from '@gelato-api-ui/core/api/helpers/callWithRetries';
import { PRODUCT_UID_DELIMITER } from '@gelato-api-ui/core/e-commerce/constants';
import { toPriceNumber } from '@gelato-api-ui/core/product-prices/helpers/toPriceNumber';
import { calculateRecommendedPrice } from '@gelato-api-ui/core/e-commerce/helpers/calculateRecommendedPrice';
import { ECommerceProductsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-products-api.service';
import { ECommerceProductVariantsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-variants-api.service';
import { ECommerceProductImagesApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-images-api.service';
import { ProductPriceApiService } from '@gelato-api-ui/core/product-prices/services/product-price-api.service';
import { ProductVariantConnectionData } from '@gelato-api-ui/core/e-commerce/product-variant-connection-data';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { ProductPrice } from '@gelato-api-ui/core/product-prices/product-price';
import { ECommerceProductGraphQlService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-graph-ql.service';
import { OrderDetails } from '@api-ui-app/src/app/orders/lib/order-details';
import { logEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { EProductPublishRequestDefaultValues } from '@gelato-api-ui/core/e-commerce/e-product-publish-request-default-values';
import { OrderItemConnectionService } from '@api-ui-app/src/app/checkout/services/order-item-connection.service';
import { ECommerceProductPublishSettingsService } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-publish-settings.service';

@Injectable({
  providedIn: 'root',
})
export class ECommerceProductVariantConnectionService {
  constructor(
    private readonly eCommerceProductGraphQlService: ECommerceProductGraphQlService,
    private readonly eCommerceProductsApiService: ECommerceProductsApiService,
    private readonly eCommerceProductVariantsApiService: ECommerceProductVariantsApiService,
    private readonly eCommerceProductImagesApiService: ECommerceProductImagesApiService,
    private readonly productPriceApiService: ProductPriceApiService,
    private readonly orderItemConnectionService: OrderItemConnectionService,
    private readonly eCommerceProductMetadataPublishScopesService: ECommerceProductPublishSettingsService,
  ) {}

  connectVariant(
    productVariantConnectionData: ProductVariantConnectionData,
    isInternalStoreType: boolean,
  ): Observable<EProductWithVariants> {
    if (!productVariantConnectionData || !productVariantConnectionData.productId) {
      return of(null);
    }

    return this.connectOrderItem(productVariantConnectionData).pipe(
      switchMap(() => this.eCommerceProductGraphQlService.connectProductVariant(productVariantConnectionData)),
      switchMap((updatedProduct: EProductWithVariants) => {
        if (isInternalStoreType) {
          return of(updatedProduct);
        }

        logEvent('productVariantConnectionPublishingStarted');

        return callWithRetries(() => this.publishProduct(updatedProduct)).pipe(
          catchError(err => of(err)),
          map((response: EProductWithVariants) => {
            logEvent(
              response ? 'productVariantConnectionPublishingFinished' : 'productVariantConnectionPublishingFailed',
            );

            return response;
          }),
        );
      }),
    );
  }

  connectOrderItem(productVariantConnectionData): Observable<OrderDetails> {
    if (productVariantConnectionData.orderId && productVariantConnectionData.orderItemId) {
      return this.orderItemConnectionService.connectOrderItem(productVariantConnectionData);
    }

    return of(null);
  }

  connectRelatedProductToVariant(
    product: EProductWithVariants,
    gelatoVariantId: string,
    preferredCurrency: string,
    suggestedCountry: string,
    relatedProductUid: string,
    clientId?: string,
  ): Observable<EProductWithVariants> {
    if (!gelatoVariantId || !product) {
      return of(null);
    }

    const productVariant: EProductVariant = product.variants.find(
      (loopVariant: EProductVariant): boolean => loopVariant.id === gelatoVariantId,
    );

    if (!productVariant) {
      return of(product);
    }

    const parentProductUid = (productVariant.productUid || '').split(PRODUCT_UID_DELIMITER).slice(0, 1)[0];
    const productUids = relatedProductUid ? [parentProductUid, relatedProductUid] : [parentProductUid];

    return this.getProductCost(productUids, preferredCurrency, suggestedCountry, clientId).pipe(
      first(),
      switchMap((cost: number) => {
        const price = calculateRecommendedPrice(cost);

        return this.eCommerceProductVariantsApiService
          .save({
            ...productVariant,
            productUid: productUids.join(PRODUCT_UID_DELIMITER),
            price,
            cost,
            currency: preferredCurrency,
          })
          .pipe(
            map((savedProductVariant: EProductVariant) => {
              if (!savedProductVariant) {
                return null;
              }

              return {
                ...product,
                productVariants: product.variants.map((loopProductVariant: EProductVariant): EProductVariant => {
                  if (loopProductVariant.id === savedProductVariant.id) {
                    return savedProductVariant;
                  }

                  return loopProductVariant;
                }),
              };
            }),
          );
      }),
    );
  }

  private publishProduct(product: EProductWithVariants): Observable<EProductWithVariants> {
    if (!product) {
      return of(null);
    }

    return this.eCommerceProductsApiService
      .publish(product.id, {
        ...EProductPublishRequestDefaultValues,
        scopes: this.eCommerceProductMetadataPublishScopesService.getPublishScopesFromMetadata(product.metadata),
        isInstant: true,
      })
      .pipe(
        catchError(error => {
          return of(null);
        }),
        map(
          (publishedProduct: EProductAbbridged): EProductWithVariants =>
            publishedProduct
              ? {
                  ...publishedProduct,
                  variants: product.variants,
                  productImages: product.productImages,
                }
              : null,
        ),
      );
  }

  private getProductCost(
    productUids: string[],
    preferredCurrency: string,
    suggestedCountry: string,
    clientId: string,
  ): Observable<number> {
    return forkJoin(
      productUids.map((loopProductUid: string): Observable<number> => {
        if (!suggestedCountry) {
          return of(null);
        }

        return this.productPriceApiService
          .getPrices(loopProductUid, suggestedCountry, preferredCurrency, null, clientId)
          .pipe(
            catchError(e => {
              return of(null);
            }),
            map((productPrices: ProductPrice[]): number => {
              if (!productPrices || !productPrices.length || !productPrices[0]) {
                return;
              }

              return productPrices[0].price;
            }),
          );
      }),
    ).pipe(
      map((productCosts: number[]): number => {
        let cost = 0;

        productCosts.forEach((loopProductCost: number) => {
          if (loopProductCost) {
            cost += loopProductCost;
          }
        });

        cost = toPriceNumber(cost);

        return cost;
      }),
    );
  }
}
