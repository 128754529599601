import { Action } from '@ngrx/store';
import { Asset } from '@gelato-api-ui/core/designs/asset';
import { AssetProviderUid } from '@gelato-api-ui/core/designs/asset-provider-uid.enum';
import { AssetsCollection } from '@gelato-api-ui/core/designs/assets-collection';
import { ShutterstockImage } from '@gelato-api-ui/core/shutterstock/shutterstock-image';

export enum AssetsMappingActionTypes {
  ResetState = '[AssetsMappingCatalogue] Reset State',
  SetAssets = '[AssetsMappingCatalogue] Set Assets',
  AddAssets = '[AssetsMappingCatalogue] Add Assets',
  GetAsset = '[AssetsMappingCatalogue] Get Assets',
  SetAsset = '[AssetsMappingCatalogue] Set Asset',
  ProcessFileAddedPayload = '[AssetsMappingCatalogue] Process File Added Payload',
  ProcessUploadSuccessPayload = '[AssetsMappingCatalogue] Process Upload Success Payload',
  ProcessUploadErrorPayload = '[AssetsMappingCatalogue] Process Upload Error Payload',
  ImportImageFromShutterstock = '[AssetsMappingCatalogue] Import Image From Shutterstock',
  RemoveAsset = '[AssetsMappingCatalogue] Remove Asset',
}

export class ResetState implements Action {
  readonly type = AssetsMappingActionTypes.ResetState;
}

export class SetAssets implements Action {
  readonly type = AssetsMappingActionTypes.SetAssets;

  constructor(public payload: AssetsCollection) {}
}

export class AddAssets implements Action {
  readonly type = AssetsMappingActionTypes.AddAssets;

  constructor(public payload: AssetsCollection) {}
}

export class GetAsset implements Action {
  readonly type = AssetsMappingActionTypes.GetAsset;

  constructor(public assetId: string) {}
}

export class SetAsset implements Action {
  readonly type = AssetsMappingActionTypes.SetAsset;

  constructor(public payload: Asset) {}
}

export class ProcessFileAddedPayload implements Action {
  readonly type = AssetsMappingActionTypes.ProcessFileAddedPayload;

  constructor(public uppyPayload: any) {}
}

export class ProcessUploadSuccessPayload implements Action {
  readonly type = AssetsMappingActionTypes.ProcessUploadSuccessPayload;

  constructor(public uppyPayload: any) {}
}

export class ProcessUploadErrorPayload implements Action {
  readonly type = AssetsMappingActionTypes.ProcessUploadErrorPayload;

  constructor(public uppyPayload: any) {}
}

export class ImportImageFromShutterstock implements Action {
  readonly type = AssetsMappingActionTypes.ImportImageFromShutterstock;

  constructor(public assetProviderUid: AssetProviderUid, public image: ShutterstockImage) {}
}

export class RemoveAsset implements Action {
  readonly type = AssetsMappingActionTypes.RemoveAsset;

  constructor(public assetId: string) {}
}

export type StoreProductVariantsActions =
  | ResetState
  | SetAssets
  | AddAssets
  | SetAsset
  | ProcessFileAddedPayload
  | ProcessUploadSuccessPayload
  | ProcessUploadErrorPayload
  | ImportImageFromShutterstock;
