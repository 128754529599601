import { Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'gd-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss'],
})
export class FormControlErrorComponent {
  @Input() control: UntypedFormControl;
  @Input() messages: { [key: string]: string };

  defaultErrorMessageMap = {
    required: this.translate.instant('txt_form_control_default_error_message_required'),
    _: this.translate.instant('txt_form_control_default_error_message_fallback'),
  };

  constructor(private readonly translate: TranslateService) {}

  get errorKeys() {
    return Object.keys(this.control.errors);
  }

  getError(key: string): string {
    return this.messages[key] || this.messages._ || this.defaultErrorMessageMap[key] || this.defaultErrorMessageMap._;
  }
}
