import { OrderRefundSearchRequest } from '../orders/lib/order-refund-search-request';
import { OrderRefundSearchResponse } from '../orders/lib/order-refund-search-response';
import * as actions from './order-refund.actions';

export interface State {
  isLoading: boolean;
  request: OrderRefundSearchRequest;
  payload: OrderRefundSearchResponse;
}

export const initialState: State = {
  isLoading: false,
  request: null,
  payload: null,
};

export function reducer(state = initialState, action: actions.OrderRefundActions): State {
  switch (action.type) {
    case actions.OrderRefundActionTypes.ResetState: {
      return {
        ...state,
        ...initialState,
      };
    }
    case actions.OrderRefundActionTypes.SetState: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
}
