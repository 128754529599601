import { OrderShipment } from './order-shipment';
import { OrderReceipt } from './order-receipt';
import { OrderStatus } from './order-status.enum';
import { OrderChannel } from './order-channel.enum';
import { FinancialOrderStatus } from './financial-order-status.enum';
import { OrderAddress, ReturnAddress } from './order-address';
import { OrderItem } from './order-item';
import { MetadataItem } from '@gelato-api-ui/core/metadata/metadata-item';
import { OrderType } from '../../shared/lib/order-type.enum';
import { PaymentMethodType } from '../../payment-methods/wallets/lib/payment-method.type';
import { Discount } from '@api-ui-app/src/app/discount/discounts';
import { RefusalReasonCode } from './refusal-reason-code';

export class OrderDetails {
  constructor(
    public id: string = null,
    public clientId: string = null,
    public orderReferenceId: string = null,
    public customerReferenceId: string = null,
    public fulfillmentStatus: OrderStatus = OrderStatus.CREATED,
    public financialStatus: FinancialOrderStatus = null,
    public currency: string = null,
    public shipment: OrderShipment = null,
    public receipts: OrderReceipt[] = null,
    public receiptsIds: string[] = null,
    public refusalReason: string = null,
    public refusalReasonCode: RefusalReasonCode = null,
    public storeId: string = null,
    public channel: OrderChannel = null,
    public createdAt: string | number = null,
    public orderType: OrderType = null,
    public metadata: MetadataItem[] = null,
    public shippingAddress: OrderAddress = null,
    public items: OrderItem[] = null,
    public retailCurrency: string = null,
    public paymentMethodId: string = null,
    public paymentMethodType: PaymentMethodType = null,
    public orderedAt: string = null,
    public discounts: Discount[] = null,
    public returnAddress: ReturnAddress = null,
    public iossNumber: string | null = null,
  ) {}
}

export interface OrderDetailsVATInvoice {
  url: string;
}
