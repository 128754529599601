<gc-actions-panel-steps-container>
  <ng-content></ng-content>

  <ng-container *ngIf="visibleSteps">
    <ng-container *ngFor="let loopStep of visibleSteps; last as isLast">
      <ng-container *ngIf="stepsConfig[loopStep]">
        <gc-actions-panel-step-button
          [active]="activeStep === loopStep"
          [disabled]="disabledSteps && disabledSteps.indexOf(loopStep) !== -1"
          [label]="stepsConfig[loopStep].translationKey | translate"
          [icon]="
            visibleSteps.indexOf(activeStep) <= visibleSteps.indexOf(loopStep)
              ? stepsConfig[loopStep].iconUrl
              : completeStepIconUrl
          "
          (actionClick)="onClick(loopStep)"
        >
        </gc-actions-panel-step-button>

        <gc-actions-panel-step-separator *ngIf="!isLast"></gc-actions-panel-step-separator>
      </ng-container>
    </ng-container>
  </ng-container>
</gc-actions-panel-steps-container>
