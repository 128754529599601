import { Injectable } from '@angular/core';
import * as gql from 'gql-query-builder';
import { ApolloQueryResult } from '@apollo/client/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GraphQlRequestService } from '@gelato-api-ui/core/api/services/graph-ql-request.service';

@Injectable({ providedIn: 'root' })
export class ChameleonGraphQlService {
  constructor(private readonly graphQlRequestService: GraphQlRequestService) {}

  fetchUidHash(uid: string): Observable<string> {
    const query = this.fetchUidHashQuery(uid);

    return this.graphQlRequestService
      .query(query, 'chameleonUidHash')
      .pipe(map((data: ApolloQueryResult<any>) => data?.data?.chameleonUidHash?.uidHash));
  }

  private fetchUidHashQuery(uid: string) {
    return gql.query({
      operation: 'chameleonUidHash',
      variables: {
        uid: { type: 'String!', value: uid },
      },
      fields: ['uidHash'],
    });
  }
}
