<div class="header">
  <div class="header-content">
    {{ 'txt_product_variants_update_modal_title' | translate }}
  </div>
</div>

<div class="content">
  <ng-container *ngIf="modal.context.productVariantsUpdateSummary?.added?.length > 0">
    <p>{{ 'txt_product_variants_update_modal_added_variants_section_title' | translate }}:</p>
    <p>
      <ng-container *ngFor="let variantTitle of modal.context.productVariantsUpdateSummary?.added">
        <i class="ui icon check"></i>{{ variantTitle }}<br />
      </ng-container>
    </p>
  </ng-container>

  <ng-container *ngIf="modal.context.productVariantsUpdateSummary?.removed?.length > 0">
    <p>{{ 'txt_product_variants_update_modal_removed_variants_section_title' | translate }}:</p>
    <p>
      <ng-container *ngFor="let variantTitle of modal.context.productVariantsUpdateSummary?.removed">
        <i class="ui icon x"></i>{{ variantTitle }}<br />
      </ng-container>
    </p>
  </ng-container>
</div>
<div class="actions">
  <button class="ui secondary button" (click)="cancel()">
    {{ 'txt_cancel' | translate }}
  </button>
  <button class="ui primary button" (click)="continue()" autofocus>
    {{ 'txt_continue' | translate }}
  </button>
</div>
