<div class="plan" [class.highlighted]="isRecommended" [ngClass]="planId">
  <div *ngIf="isRecommended" class="plan-label">{{ 'txt_recommended_plan' | translate }}</div>
  <div *ngIf="isCurrentPlan" class="current-plan-label">{{ 'txt_current_plan' | translate }}</div>
  <div class="plan-content">
    <div class="title">
      <span>
        {{ planName }}
      </span>
      <img
        *ngIf="planId === planIdName.PLUS"
        class="plan-icon"
        src="{{ 'subscriptions/plus-purple-crown.svg' | assetsPath | async }}"
        alt="plus crown icon"
      />
      <img
        *ngIf="planId === planIdName.GOLD"
        class="plan-icon"
        src="{{ 'subscriptions/gold-crown.svg' | assetsPath | async }}"
        alt="gold crown icon"
      />
    </div>
    <div class="description">
      {{ planDescription }}
    </div>

    <div class="price">
      {{ price }}
      <p *ngIf="showExclTax" class="excl-tax-message">({{ 'txt_excl_tax_label' | translate }})</p>
    </div>
    <div class="price-description">
      {{ priceDescription }}
    </div>

    <div class="action-button">
      <ng-container *ngFor="let button of buttons">
        <ng-container *ngIf="button.isVisible">
          <ng-container *ngIf="button.tooltip">
            <button
              class="ui button primary"
              [ngClass]="button.className"
              [gcTooltip]="buttonTooltipTpl"
              [gcTooltipPosition]="button.tooltip.tooltipPosition"
              [gcTooltipWidth]="button.tooltip.tooltipWidth"
              [gcTooltipTheme]="button.tooltip.tooltipTheme"
              [gcTooltipShowArrow]="button.tooltip.tooltipShowArrow"
              (click)="button.onClick()"
            >
              {{ button.textKey | translate }}
            </button>

            <ng-template #buttonTooltipTpl>
              <p class="button-tooltip">
                {{ button.tooltip.tooltipTextKey | translate }}
              </p>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="!button.tooltip">
            <button class="ui button primary" [ngClass]="button.className" (click)="button.onClick()">
              {{ button.textKey | translate }}
            </button>
            <span *ngIf="button.buttonDescriptionKey" class="button-description"
              >({{ button.buttonDescriptionKey | translate }})
            </span>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <ng-content></ng-content>
</div>
