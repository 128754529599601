import { DesignEditorPlugin } from '@gelato-api-ui/core/design-editor/design-editor-plugin.enum';

export interface SetPluginsEventDataItem {
  name: DesignEditorPlugin;
  state: PluginDataState;
  type?: string;
  icon?: string;
  indexInTabs?: number; // inject app into tabs in left side panel at given index
  indexInApps?: number; // index of the App at the end of sidebar tabs
  displayName?: string;
  title?: string;
  info?: string;
  tab?: boolean;
  extra?: {
    hideIfEmpty?: boolean;
    premiumLevel?: CrownIconPremiumLevel;
    gelatoPlusPrompt?: GelatoPlusPromptData;
  };
}

export enum PluginDataState {
  not_visible = 'not-visible', // App not visible in the Editor
  disabled = 'disabled', // App button visible, the button has a premium icon, click on the button triggers “buy premium” popup
  non_functional = 'non-functional', //  App button visible, click on the button opens the app, app content is visible but a user can’t perform any (or big part of) actions. Not active elements have a premium icon. Click on the not active element triggers the‘ buy premium' popup.
  enabled = 'enabled', // App works, gold premium icon for not-used plugin and silver premium icon for used plugin
}

export enum CrownIconPremiumLevel {
  GOLD = 'GOLD',
  SILVER = 'SILVER',
}

export interface GelatoPlusPromptData {
  description: string;
  buttonText: string;
}
