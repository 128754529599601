import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StaticPathPipe } from './static-path.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [StaticPathPipe],
  exports: [StaticPathPipe],
  providers: [StaticPathPipe],
})
export class StaticPathModule {}
