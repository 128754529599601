<div
  *ngIf="!isExpandable; else collapsibleTemplate"
  class="uncollapsible-wrapper"
  [ngClass]="{
    gold: subscriptionType === subscriptionTypes.gold,
    plus: subscriptionType === subscriptionTypes.plus,
    'no-description': showOnlyHeader
  }"
>
  <div class="uncollapsible-content-wrapper">
    <div class="heading">
      <img
        *ngIf="!hideHeaderIcon"
        class="subscription-icon"
        src="{{
          subscriptionType === subscriptionTypes.plus
            ? ('subscriptions/violet-crown.svg' | assetsPath | async)
            : ('subscriptions/filled-gold-crown.svg' | assetsPath | async)
        }}"
        width="20"
        alt=""
      />
      <p class="title" [innerHtml]="headerText | safe: 'html'"></p>
    </div>
    <div class="content" *ngIf="descriptionText">
      <div class="description" [innerHtml]="descriptionText | safe: 'html'"></div>
    </div>
  </div>
  <div class="button-wrapper">
    <button
      class="ui button subscribe-button"
      [ngClass]="{
        purple: subscriptionType === subscriptionTypes.plus,
        gold: subscriptionType === subscriptionTypes.gold
      }"
      (click)="onSubscribeButtonClick()"
    >
      {{
        buttonText ||
          (subscriptionType === subscriptionTypes.plus
            ? ('txt_subscribe_plus' | translate)
            : ('txt_subscribe_gold' | translate))
      }}
    </button>
  </div>
</div>

<ng-template #collapsibleTemplate>
  <div
    class="collapsible-wrapper"
    [ngClass]="{
      expanded: isExpanded,
      minimized: isMinimized,
      gold: subscriptionType === subscriptionTypes.gold,
      plus: subscriptionType === subscriptionTypes.plus,
      'no-description': showOnlyHeader
    }"
  >
    <div class="heading">
      <div class="heading-content">
        <img
          *ngIf="!hideHeaderIcon"
          class="subscription-icon"
          src="{{
            subscriptionType === subscriptionTypes.plus
              ? ('subscriptions/violet-crown.svg' | assetsPath | async)
              : ('subscriptions/filled-gold-crown.svg' | assetsPath | async)
          }}"
          alt=""
        />
        <p class="title" [innerHtml]="headerText | safe: 'html'"></p>
      </div>
      <div class="expand-toggle-button" (click)="onToggleExpandClick()">
        <img [src]="isExpanded ? 'assets/icons/minus-black.svg' : 'assets/icons/plus-black.svg'" alt="" />
      </div>
    </div>
    <div *ngIf="isExpanded && descriptionText" class="content">
      <div class="description" [innerHtml]="descriptionText | safe: 'html'"></div>
      <button
        class="ui button subscribe-button"
        [ngClass]="{
          purple: subscriptionType === subscriptionTypes.plus,
          gold: subscriptionType === subscriptionTypes.gold
        }"
        (click)="onSubscribeButtonClick()"
      >
        {{
          buttonText ||
            (subscriptionType === subscriptionTypes.plus
              ? ('txt_subscribe_plus' | translate)
              : ('txt_subscribe_gold' | translate))
        }}
      </button>
    </div>
  </div>
</ng-template>
