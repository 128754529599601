import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import * as R from 'ramda';
import * as Sentry from '@sentry/angular';
import { ShowGeneralErrorNotification } from '@gelato-api-ui/ui-kit/src/lib/notification/notification.actions';
import { AppState } from '../app.state';
import { AuthService } from '@gelato-api-ui/core/auth/auth.service';
import { ECommerceProductsApiService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-products-api.service';
import { ECommerceProductVariantConnectionService } from '../e-commerce-stores/services/e-commerce-product-variant-connection.service';
import { EProductWithVariants } from '@gelato-api-ui/core/e-commerce/e-product-with-variants';
import { EProductSearchRequest } from '@gelato-api-ui/core/e-commerce/e-product-search-request';
import { EProductSearchResponse } from '@gelato-api-ui/core/e-commerce/e-product-search-response';
import { EProductAbbridged } from '@gelato-api-ui/core/e-commerce/e-product-abbridged';
import { ProductVariantConnectionData } from '@gelato-api-ui/core/e-commerce/product-variant-connection-data';
import * as actions from './e-commerce-products.actions';
import { getProduct, getProductsList, getProductsListState, getState } from './e-commerce-products.selector';
import { getIsInternalStoreType, getSelectedStoreId } from './e-commerce-stores.selector';
import { findECommerceProductInState } from './helpers/findECommerceProductInState';
import { ECommerceProductPublishFacade } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-publish-facade.service';
import { EProductStatus } from '@gelato-api-ui/core/e-commerce/e-product-status.enum';
import { ECommerceProductWizardFacade } from '@api-ui-app/src/app/e-commerce-stores/services/e-commerce-product-wizard/e-commerce-product-wizard.facade';
import { ApiResponse } from '@gelato-api-ui/core/api/api-response.interface';
import { ECommerceProductGraphQlService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-graph-ql.service';
import { OrderDetails } from '@api-ui-app/src/app/orders/lib/order-details';
import { EProductVariantConnectionStatus } from '@gelato-api-ui/core/e-commerce/e-product-variant-connection-status.enum';
import { EProductsListTabName } from '@api-ui-app/src/app/e-commerce-stores/lib/e-products-list-tab-name.enum';
import { mobileDeviceDetected } from '@api-ui-app/src/app/lib/mobileDeviceDetected';
import { LayoutService } from '@gelato-api-ui/sdk/src/lib/shared/services/layout.service';
import { EProductPublishingErrorCode } from '@gelato-api-ui/core/e-commerce/e-product-publishing-errors.enum';
import {
  clearCanDuplicateProductAsDraftFlags,
  setProductDuplicationAvailabilityFlag,
} from './e-commerce-products.actions';

@Injectable()
export class ECommerceProductsEffects {
  limit = 50;
  concurrentRequests: 5;

  constructor(
    private readonly actions$: Actions,
    private readonly store$: Store<AppState>,
    private readonly router: Router,
    private readonly eCommerceProductsApiService: ECommerceProductsApiService,
    private readonly eCommerceProductGraphQlService: ECommerceProductGraphQlService,
    private readonly eCommerceProductVariantConnectionService: ECommerceProductVariantConnectionService,
    private readonly layoutService: LayoutService,
    private readonly authService: AuthService,
    private readonly eProductPublishFacade: ECommerceProductPublishFacade,
    private readonly eCommerceProductWizardFacade: ECommerceProductWizardFacade,
  ) {}

  LoadProductsList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadProductsList>(actions.ECommerceProductsActionTypes.LoadProductsList),
        withLatestFrom(this.store$.select(getProductsListState)),
        switchMap(([action, state]) => {
          const { storeId, title, tabName, clearList } = action;

          let connectionStatus: EProductVariantConnectionStatus = null;
          let hasDraft: number;

          switch (tabName) {
            case EProductsListTabName.CONNECTED:
              connectionStatus = EProductVariantConnectionStatus.CONNECTED;
              break;
            case EProductsListTabName.NOT_CONNECTED:
              connectionStatus = EProductVariantConnectionStatus.NOT_CONNECTED;
              break;
            case EProductsListTabName.IGNORED:
              connectionStatus = EProductVariantConnectionStatus.IGNORED;
              break;
            case EProductsListTabName.DRAFTS:
              hasDraft = 1;
              break;
            default:
          }

          if (mobileDeviceDetected()) {
            hasDraft = null; // Exclude draft designs from results for mobile devices
          }

          const searchRequest: EProductSearchRequest = {
            storeId,
            title,
            connectionStatus,
            hasDraft,
            offset: 0,
            limit: this.limit,
          };

          const loadedProducts: EProductAbbridged[] = R.path(['payload', 'products'], state);

          if (clearList) {
            searchRequest.offset = 0;

            this.store$.dispatch(
              new actions.SetProductsListState({
                isLoading: true,
                request: searchRequest,
                payload: null,
              }),
            );
            this.store$.dispatch(clearCanDuplicateProductAsDraftFlags());
          } else {
            searchRequest.offset = (loadedProducts || []).length;

            if (searchRequest.offset % this.limit !== 0) {
              return of(null);
            }
          }

          return this.eCommerceProductsApiService.search(searchRequest).pipe(
            tap((response: EProductSearchResponse) => {
              let newProducts: EProductAbbridged[] = response.products;

              if (!clearList) {
                newProducts = [...loadedProducts, ...newProducts];
              }

              this.store$.dispatch(
                new actions.SetProductsListState({
                  isLoading: false,
                  request: searchRequest,
                  payload: { products: newProducts },
                }),
              );
            }),
            catchError(() => of(new ShowGeneralErrorNotification())),
          );
        }),
      ),
    { dispatch: false },
  );

  LoadProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.LoadProduct>(actions.ECommerceProductsActionTypes.LoadProduct),
        withLatestFrom(this.store$.select(getState), this.authService.hasReadPermission('ecommerce', '*')),
        switchMap(([action, state, hasPermission]) => {
          const { id, forced } = action;
          const entityFromState: EProductWithVariants = findECommerceProductInState(state, id);
          const isDataRelevant = Boolean(entityFromState);

          let observable: Observable<EProductWithVariants> = this.eCommerceProductGraphQlService.getProduct(id);

          if (!hasPermission) {
            observable = of(null);
          }

          if (!forced && isDataRelevant) {
            observable = of({ ...entityFromState });
          }

          this.store$.dispatch(
            new actions.SetProductState({
              isLoading: true,
              payload: isDataRelevant ? entityFromState : null,
            }),
          );

          return observable.pipe(
            catchError((err): Observable<EProductWithVariants> => {
              this.store$.dispatch(new ShowGeneralErrorNotification());

              return of(null);
            }),
            tap((product: EProductWithVariants) => {
              this.store$.dispatch(
                new actions.SetProductState({
                  isLoading: false,
                  payload: product,
                }),
              );
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  ConnectProductVariant$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<actions.ConnectProductVariant>(actions.ECommerceProductsActionTypes.ConnectProductVariant),
        withLatestFrom(
          this.store$.select(getSelectedStoreId),
          this.store$.select(getIsInternalStoreType),
          this.store$.select(getProduct),
        ),
        switchMap(([action, selectedStoreId, isInternalStoreType, product]) => {
          this.layoutService.enableFullScreenMode();

          const productVariantConnectionData: ProductVariantConnectionData = action.payload;

          const navigateBack = (variantConnectionData: ProductVariantConnectionData) => {
            let backRoute = [`/stores/${selectedStoreId}/products`];

            if (variantConnectionData) {
              if (variantConnectionData.orderId) {
                backRoute = [`/checkout/${variantConnectionData.orderId}`];
              } else if (variantConnectionData.productId) {
                backRoute = [`/stores/${selectedStoreId}/products/edit/${variantConnectionData.productId}`];
              }
            }

            this.layoutService.disableFullScreenMode();
            this.router.navigate(backRoute, { replaceUrl: true });
          };

          Sentry.addBreadcrumb({
            category: 'ConnectProductVariant',
            message: 'ProductVariantConnectionDataReceived',
            data: {
              productVariantConnectionData,
              selectedStoreId,
              product,
            },
            level: Sentry.Severity.Info,
          });

          if (!productVariantConnectionData || !selectedStoreId) {
            Sentry.captureException(new Error('InvalidProductVariantConnectionDataReceived'));

            navigateBack(productVariantConnectionData);

            return of(null);
          }

          if (!productVariantConnectionData.productId) {
            return this.eCommerceProductVariantConnectionService.connectOrderItem(productVariantConnectionData).pipe(
              tap((orderDetails: OrderDetails) => {
                if (!orderDetails) {
                  this.store$.dispatch(new ShowGeneralErrorNotification());
                }

                this.store$.dispatch(
                  new actions.SetProductState({
                    isLoading: false,
                    payload: null,
                  }),
                );

                navigateBack(productVariantConnectionData);
              }),
            );
          }

          Sentry.addBreadcrumb({
            category: 'ConnectProductVariant',
            message: 'ProductVariantConnectionStarted',
            level: Sentry.Severity.Info,
          });

          this.router.navigate(['/stores/current/products/variant-connection-progress']);

          this.store$.dispatch(
            new actions.SetProductState({
              isLoading: true,
              payload: product,
            }),
          );

          return this.eCommerceProductVariantConnectionService
            .connectVariant(productVariantConnectionData, isInternalStoreType)
            .pipe(
              catchError((): Observable<EProductWithVariants> => {
                return of(null);
              }),
              tap((response: EProductWithVariants) => {
                if (!response) {
                  Sentry.captureException(new Error('ProductVariantConnectionFailed'));

                  this.store$.dispatch(new ShowGeneralErrorNotification());

                  this.store$.dispatch(
                    new actions.SetProductState({
                      isLoading: false,
                      payload: product,
                    }),
                  );

                  navigateBack(productVariantConnectionData);

                  return;
                }

                this.store$.dispatch(
                  new actions.SetProductState({
                    isLoading: false,
                    payload: response,
                  }),
                );

                this.store$.dispatch(new actions.LoadProduct(response.id, true));

                Sentry.addBreadcrumb({
                  category: 'ConnectProductVariant',
                  message: 'ProductVariantConnectionFinished',
                  level: Sentry.Severity.Info,
                });

                navigateBack(productVariantConnectionData);
              }),
            );
        }),
      ),
    { dispatch: false },
  );

  loadProductTags$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadProductTags),
      switchMap(action =>
        this.eCommerceProductsApiService
          .getTags(action.storeId)
          .pipe(map(res => actions.loadProductTagsSuccess({ ...action, tags: res.tags }))),
      ),
    ),
  );

  loadProductCollections$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadProductCollections),
      switchMap(action =>
        this.eCommerceProductsApiService
          .getCollections(action.storeId)
          .pipe(map(res => actions.loadProductCollectionsSuccess({ ...action, collections: res.collections }))),
      ),
    ),
  );

  initiatePublishingProcessMonitoring$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.initiatePublishingProcessMonitoring),
        withLatestFrom(this.store$.select(getProductsList)),
        map(([action, products]) => products.filter(product => action.statuses.includes(product.status))),
        filter(unPublishedProducts => !!unPublishedProducts.length),
        switchMap(
          (products: EProductAbbridged[]): Observable<void> =>
            this.eProductPublishFacade.trackProductStatuses(products),
        ),
      ),
    { dispatch: false },
  );

  onSetListState$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.ECommerceProductsActionTypes.SetProductsListState),
      map(action => actions.initiatePublishingProcessMonitoring({ statuses: [EProductStatus.PUBLISHING] })),
    ),
  );

  saveProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.saveProductStart),
        switchMap(() => this.eCommerceProductWizardFacade.saveProduct()),
      ),
    { dispatch: false },
  );

  saveProductStateless$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.saveProductStatelessStart),
        withLatestFrom(
          this.eCommerceProductWizardFacade.selectedClientId$,
          this.eCommerceProductWizardFacade.isInternalStoreType$,
        ),
        switchMap(([action, clientId, isInternalStoreType]) => {
          const addedProductVariantsCount: number = action.productData.productVariants.filter(v => !v.id).length;

          return this.eCommerceProductWizardFacade.saveProductStateless(
            action.productData,
            clientId,
            action.mode,
            {},
            action.publishWithFreeShipping,
            action.publishImmediately,
            addedProductVariantsCount,
            0,
            isInternalStoreType,
            action.publishMode,
          );
        }),
      ),
    { dispatch: false },
  );

  duplicateProduct$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.duplicateProduct),
      mergeMap(
        action =>
          this.eCommerceProductsApiService
            .duplicate(action.productId, action.sourceStoreId, action.destinationStoreId)
            .pipe(
              catchError((response: { response: ApiResponse<any> }): Observable<unknown> => {
                const errorDetails = response?.response?.error?.message;

                this.eProductPublishFacade.setPublishingState({
                  errorCode: EProductPublishingErrorCode.GENERAL_PRODUCT_DUPLICATION_ERROR,
                  errorDetails,
                  productId: action.productId,
                  productTitle: action.title,
                });

                return of(null);
              }),
              filter(Boolean),
              map((product: EProductAbbridged) => actions.duplicateProductSuccess({ product })),
            ),
        this.concurrentRequests,
      ),
    ),
  );

  onDuplicate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.duplicateProductSuccess),
      tap(() =>
        this.eProductPublishFacade.addPublishingNotification({
          textKey: 'txt_products_duplicate_notification',
        }),
      ),
      mergeMap(({ product }) => [
        actions.addPublishingProducts({ products: [{ ...product, status: EProductStatus.PUBLISHING }] }),
        actions.initiatePublishingProcessMonitoring({ statuses: [EProductStatus.PUBLISHING] }),
      ]),
    ),
  );

  fetchProductDuplicationAvailabilityFlag$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.fetchProductDuplicationAvailabilityFlag),
      switchMap(action => {
        const { productId } = action;

        return this.eCommerceProductGraphQlService.canDuplicateProductAsDraft(productId).pipe(
          first(),
          map((payload: boolean) => setProductDuplicationAvailabilityFlag({ productId, payload })),
        );
      }),
    ),
  );
}
