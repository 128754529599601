import * as R from 'ramda';
import { Asset } from '../asset';
import { AssetProviderUid } from '../asset-provider-uid.enum';
import { AssetsCollection } from '../assets-collection';
import { DesignPage, DesignPageMediaItem, DesignStructure } from '../design-structure';
import { ShutterstockAssetProviderUids } from '@gelato-api-ui/core/shutterstock/shutterstock.constant';

export const getUsedAssets = (
  assetsCollection: AssetsCollection,
  designStructure: DesignStructure,
  assetProviderUids: AssetProviderUid[] = null,
): Asset[] => {
  if (!designStructure || !designStructure.spreads || !designStructure.spreads.length) {
    return [];
  }

  const assetsArray: Asset[] = R.values(assetsCollection);
  const usedAssets: Asset[] = [];

  designStructure.spreads.forEach((spread, spreadIndex: number) => {
    spread.pages.forEach((page: DesignPage) => {
      if (page.groups && page.groups.media) {
        page.groups.media.forEach((element: DesignPageMediaItem) => {
          const { imageId } = element;

          if (imageId) {
            const foundAsset: Asset = R.find((loopAsset: Asset): boolean => {
              if (![loopAsset.id].includes(imageId)) {
                return false;
              }

              if (
                assetProviderUids &&
                assetProviderUids.length &&
                !assetProviderUids.includes(loopAsset.assetProviderUid)
              ) {
                return false;
              }

              return true;
            }, assetsArray);

            if (foundAsset) {
              usedAssets.push(R.omit(['uppyId'], foundAsset));
            }
          }
        });
      }
    });
  });

  return R.uniq(usedAssets);
};
