import { Injectable } from '@angular/core';
import { ProductTypeUid } from '@gelato-api-ui/core/product-catalogue/product-type-uid.enum';
import { DesignSpread } from '@gelato-api-ui/core/designs/design-structure';
import { ApparelAndAccessoriesProductTypeUids } from '@gelato-api-ui/core/product-catalogue/product-type-uids';
import { PrintAreaNameEnum } from '@gelato-api-ui/core/print-sides/print-side-name.enum';

@Injectable({ providedIn: 'root' })
export class DesignEditorZoomService {
  DEFAULT_ZOOM = 1;
  CALENDAR_PRODUCT_ZOOM = undefined; // let the editor decides what's best
  APPAREL_PRODUCT_ZOOM = 0.6;
  APPAREL_SPREAD_ZOOM_MAP = {
    [PrintAreaNameEnum.Front]: 0.6,
    [PrintAreaNameEnum.Back]: 0.6,
    [PrintAreaNameEnum.NeckInner]: 0.2,
    [PrintAreaNameEnum.NeckOuter]: 0.6,
    [PrintAreaNameEnum.SleeveLeft]: 0.3,
    [PrintAreaNameEnum.SleeveRight]: 0.3,
  };

  getDefaultZoom(productTypeUid: ProductTypeUid) {
    if (productTypeUid === ProductTypeUid.CALENDAR) {
      return this.CALENDAR_PRODUCT_ZOOM;
    }

    return ApparelAndAccessoriesProductTypeUids.includes(productTypeUid)
      ? this.APPAREL_PRODUCT_ZOOM
      : this.DEFAULT_ZOOM;
  }

  getSpreadsDefaultZoom(spreads: DesignSpread[]) {
    return spreads.reduce((memo, { name }) => [...memo, this.APPAREL_SPREAD_ZOOM_MAP[name]], [] as number[]);
  }
}
