<ng-select
  [ngModel]="currentValue"
  (ngModelChange)="onSelect($event)"
  [items]="options$ | async"
  [bindValue]="'id'"
  [bindLabel]="'name'"
  [placeholder]="'txt_address_country_field_placeholder' | translate"
  [clearable]="false"
  [readonly]="disabled || !(options$ | async)?.length"
  [searchable]="isSearchable"
  [virtualScroll]="true"
>
  <ng-template ng-label-tmp ng-option-tmp let-item="item">
    <ng-container *ngIf="item.id">
      <gc-country-flag [countryIsoCode]="item.id"></gc-country-flag>
      &nbsp;
    </ng-container>
    {{ item.id ? ('txt_country_' + item.id | translate) : (item.name | translate) }}
  </ng-template>
</ng-select>
