import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WalletTransaction, WalletTransactionsFilter } from '../../lib/api';

export interface WalletTransactionsState extends EntityState<WalletTransaction> {
  isFetched: boolean;
  filter: WalletTransactionsFilter;
}

export const walletTransactionsAdapter: EntityAdapter<WalletTransaction> = createEntityAdapter<WalletTransaction>({
  selectId: details => details.walletTransactionUid,
});
