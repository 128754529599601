import { OrderDetails } from '../../orders/lib/order-details';
import { OrderType } from '../../shared/lib/order-type.enum';
import { OrderCreateItem, OrderCreateShippingAddress, SaveOrderRequest } from '../types/save-order-request';
import { OrderAddress } from '../../orders/lib/order-address';
import { OrderItem } from '../../orders/lib/order-item';
import { OrderChannel } from '../../orders/lib/order-channel.enum';
import { Discount } from '@api-ui-app/src/app/discount/discounts';
import { format } from 'date-fns';

export function getOrderCreateRequest(
  order?: OrderDetails,
  defaultCurrency: string = null,
  defaultClientId: string = null,
  defaultCustomerRefId: string = null,
  discounts: Discount[] = null,
  catalogSelectedCountry: string = null,
  passRetail = true,
): SaveOrderRequest {
  order = order || new OrderDetails();
  const shippingRetailPrice = order.shipment ? order.shipment.retailShippingPriceInclVat : null;
  return {
    id: order.id,
    clientId: order.clientId || defaultClientId,
    orderReferenceId: order.orderReferenceId || generateOrderReferenceId(),
    customerReferenceId: order.customerReferenceId || defaultCustomerRefId,
    orderType: order.orderType || OrderType.DRAFT,
    currency: order.currency || defaultCurrency,
    retailCurrency: order.retailCurrency || order.currency || defaultCurrency,
    retailShippingPriceInclVat: passRetail ? shippingRetailPrice : null,
    channel: order.channel || OrderChannel.UI,
    storeId: order.storeId,
    shippingAddress: getOrderCreateShippingAddress(getAddress(order, catalogSelectedCountry)),
    items: getOrderCreateItems(order.items || [], passRetail),
    metadata: order.metadata,
    shippingMethodType: null,
    paymentMethodId: order.paymentMethodId,
    paymentMethodType: order.paymentMethodType === 'invoice' ? null : order.paymentMethodType,
    shipmentMethodUid: order.shipment ? order.shipment.shipmentMethodUid : null,
    discounts: discounts || order.discounts,
    returnAddress: order.returnAddress,
    iossNumber: order.iossNumber,
  } as SaveOrderRequest;
}

function getAddress(order?: OrderDetails, catalogSelectedCountry: string = null) {
  if (order?.shippingAddress?.country) {
    return order.shippingAddress;
  } else {
    const newAddress = new OrderAddress();
    newAddress.country = catalogSelectedCountry;
    return newAddress;
  }
}

export function getOrderCreateShippingAddress(address: OrderAddress): OrderCreateShippingAddress {
  return {
    country: address.country || null,
    firstName: address.firstName || null,
    lastName: address.lastName || null,
    addressLine1: address.addressLine1 || null,
    addressLine2: address.addressLine2 || null,
    city: address.city || null,
    postCode: address.postCode || null,
    state: address.state || null,
    email: address.email || null,
    phone: address.phone || null,
    companyName: address.companyName || null,
    isBusiness: address.isBusiness || null,
    federalTaxId: address.federalTaxId || null,
    stateTaxId: address.stateTaxId || null,
    registrationState: address.registrationState || null,
  } as OrderCreateShippingAddress;
}

export function getOrderCreateItems(items: OrderItem[], passRetail = true): OrderCreateItem[] {
  return items.map(item => getOrderCreateItem(item, passRetail));
}

export function getOrderCreateItem(item: OrderItem, passRetail = true) {
  return {
    id: item.id,
    itemReferenceId: item.itemReferenceId,
    itemReferenceName: item.itemReferenceName || null,
    productUid: item.productUid,
    designId: item.designId,
    files: item.files,
    quantity: item.quantity,
    pageCount: item.pageCount > 0 ? item.pageCount : null,
    options: item.options,
    retailPriceInclVat: passRetail ? item.retailPriceInclVat || null : null,
    metadata: item.metadata,
    isIgnored: item.isIgnored,
    customTrim: item.customTrim,
    storeProductId: item.storeProductId,
    storeProductVariantId: item.storeProductVariantId,
  } as OrderCreateItem;
}

function generateOrderReferenceId() {
  return `G-${format(new Date(), 'yyMMddHHmmss')}`;
}

export function isAutoGenerateOrderReferenceId(id: string) {
  return /^G-\d+/.test(id);
}
