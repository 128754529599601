import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import * as actions from '../../ngrx/layout.actions';
import { isFullScreenMode, isSidebarMinimised } from '../../ngrx/layout.selector';
import { ActivatedRoute, Router } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { LocaleCode } from '@gelato-api-ui/core/i18n/locales.constant';
import { updateLocale } from '@gelato-api-ui/sdk/src/lib/sdk.actions';
import { TranslateService } from '@ngx-translate/core';
import { getProductsList } from '@api-ui-app/src/app/ngrx/e-commerce-products.selector';
import { UserCurrencyService } from '@api-ui-app/src/app/shared/services/user-currency.service';
import { SubscriptionsFacade } from '@api-ui-app/src/app/subscriptions/+state/subscriptions.facade';
import { combineLatest, Observable } from 'rxjs';
import { isRouteMatch } from '@gelato-api-ui/utils/is-route-match';
import { ScrollingService } from '@gelato-api-ui/core/scrolling/scrolling.service';
import { NavigateToListService } from '../../shared/services/navigate-to-list.service';
import { getPaidOrdersCount } from '@api-ui-app/src/app/ngrx/account-activation-steps.selector';
import { VoucherService } from '../../dashboard/services/voucher.service';
import { isHelloBarVisible } from '@gelato-api-ui/sdk/src/lib/sanity/sanity.selector';

@Component({
  selector: 'gd-standard-layout',
  templateUrl: './standard-layout.component.html',
  styleUrls: ['./standard-layout.component.scss'],
})
export class StandardLayoutComponent implements OnInit {
  @ViewChild('layoutElRef', { static: true }) layoutElRef: ElementRef;
  sidebarMinimised$ = this.store.select(isSidebarMinimised);
  fullScreenMode$ = this.store.select(isFullScreenMode);
  headerComponent$ = this.route.data.pipe(map(data => data.header));
  noBottomPadding$ = this.route.data.pipe(map(data => data.noBottomPadding));
  noBackground$ = this.route.data.pipe(map(data => data.noBackground));
  isCheckout$ = this.route.data.pipe(map(data => data.isCheckout));
  userCurrency$ = this.userCurrencyService.get();
  list$ = this.store.select(getProductsList);
  publishingProducts$ = this.list$.pipe(map(products => products.filter(product => product && !product.id)));
  isGelatoPlusGoldActive$ = this.subscriptionsFacade.gelatoPlusGoldIsActive$;
  isGelatoPlusActive$ = combineLatest([
    this.subscriptionsFacade.gelatoPlusIsActive$,
    this.subscriptionsFacade.activePlansIsUpdating$,
  ]).pipe(
    map(([gelatoPlusOrEnterpriseIsActive, plansIsUpdating]) => gelatoPlusOrEnterpriseIsActive && !plansIsUpdating),
  );
  isNonRenewingSubscriptionEnding$ = this.subscriptionsFacade.isNonRenewingSubscriptionEnding$;
  nonRenewingGelatoPlusDaysLeft$ = this.subscriptionsFacade.nonRenewingGelatoPlusDaysLeft$;
  isGelatoPlusPage$: Observable<boolean>;
  userWithFirstOrder$: Observable<boolean> = this.store.pipe(select(getPaidOrdersCount), map(Boolean));
  discounts$ = this.voucherService.discounts$;
  isHelloBarVisible$ = this.store.select(isHelloBarVisible);

  constructor(
    private readonly store: Store<AppState>,
    private readonly route: ActivatedRoute,
    public readonly translations: TranslateService,
    private readonly userCurrencyService: UserCurrencyService,
    private readonly subscriptionsFacade: SubscriptionsFacade,
    private readonly router: Router,
    private readonly scrollingService: ScrollingService,
    private readonly voucherService: VoucherService,
    private readonly navigateToListService: NavigateToListService,
  ) {
    this.isGelatoPlusPage$ = isRouteMatch(router, '/growth-plans');
  }

  @HostListener('window:beforeunload', ['$event'])
  showMessage($event) {
    this.publishingProducts$
      .pipe(
        first(),
        map(list => {
          if (list && list.length) {
            $event.returnValue =
              "Warning: You have publishing products. Press Cancel to go back and wait it's being published, or OK to lose these changes.";
            return $event;
          } else {
            return true;
          }
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.scrollingService.scrollElement = this.layoutElRef;
  }

  toggleSidebar() {
    this.store.dispatch(new actions.PersistToggleState());
  }

  onUseLocale(locale: LocaleCode) {
    this.store.dispatch(updateLocale({ locale }));
  }

  onUseCurrency(currency: string) {
    this.userCurrencyService.set(currency);
  }

  onGelatoPlusLabelClick(): void {
    this.navigateToListService.onGelatoPlusLabelClick();
  }
}
