import { Component, OnInit } from '@angular/core';
import { ComponentModalConfig, ModalSize, SuiModal } from '@giomamaladze/ng2-semantic-ui';
import { toPriceNumber } from '@gelato-api-ui/core/product-prices/helpers/toPriceNumber';
import { calculateRecommendedPrice } from '@gelato-api-ui/core/e-commerce/helpers/calculateRecommendedPrice';
import { EProductVariantConnectionSettings } from '@gelato-api-ui/sdk/src/lib/shared/e-product-variant-connection-settings-modal/types/e-product-variant-connection-settings';
import { ECommerceProductGraphQlService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-graph-ql.service';
import { catchError, filter, first, map, tap } from 'rxjs/operators';
import { EProductValidationResponse } from '@gelato-api-ui/core/e-commerce/e-product-validation-response';
import { Observable, of } from 'rxjs';
import { ECommerceProductValidationErrorMessageService } from '@gelato-api-ui/core/e-commerce/services/e-commerce-product-validation-error-message.service';

interface EProductVariantConnectionSettingsModalContext {
  clientId: string;
  storeId: string;
  currency: string;
  price: number;
  oldCost: number;
  newCost: number;
}

enum PriceMode {
  EXISTING_PRICE = 'existing_price',
  YOUR_OWN_PRICE = 'your_own_price',
}

@Component({
  selector: 'gc-e-product-variant-connection-settings-modal',
  templateUrl: './e-product-variant-connection-settings-modal.component.html',
  styleUrls: ['./e-product-variant-connection-settings-modal.component.scss'],
})
export class EProductVariantConnectionSettingsModalComponent implements OnInit {
  settings: EProductVariantConnectionSettings = {
    updatePreview: false,
    updatePrice: false,
    currency: null,
    price: null,
    cost: null,
  };

  priceModeEnum = PriceMode;
  priceMode: PriceMode = PriceMode.EXISTING_PRICE;
  priceError: string = null;
  isValidatingPrice = false;

  constructor(
    private readonly modal: SuiModal<
      EProductVariantConnectionSettingsModalContext,
      EProductVariantConnectionSettings,
      void
    >,
    private readonly eCommerceProductGraphQlService: ECommerceProductGraphQlService,
    private readonly eCommerceProductValidationErrorMessageService: ECommerceProductValidationErrorMessageService,
  ) {}

  ngOnInit() {
    this.settings.currency = this.currency;
    this.settings.cost = this.newCost;

    if (this.existingPrice) {
      this.priceMode = PriceMode.EXISTING_PRICE;
      this.price = this.existingPrice;
    } else {
      this.priceMode = PriceMode.YOUR_OWN_PRICE;
      this.price = calculateRecommendedPrice(this.newCost || 0);
    }
  }

  get clientId(): string {
    return this.modal.context?.clientId;
  }

  get storeId(): string {
    return this.modal.context?.storeId;
  }

  get currency(): string {
    return this.modal.context?.currency;
  }

  get oldCost(): number {
    return this.modal.context?.oldCost || 0;
  }

  get newCost(): number {
    return this.modal.context?.newCost || 0;
  }

  get isCostChanged(): boolean {
    return toPriceNumber(this.newCost) !== toPriceNumber(this.oldCost);
  }

  get existingPrice(): number {
    return this.modal.context?.price || 0;
  }

  get price(): number {
    return this.settings.price;
  }

  set price(value: number) {
    this.settings = {
      ...this.settings,
      price: value,
    };
  }

  get isPositivePrice(): boolean {
    return this.price > 0;
  }

  onPriceModeChange(priceMode: PriceMode) {
    this.priceMode = priceMode;

    const updatePrice = priceMode !== PriceMode.EXISTING_PRICE;
    let price = this.settings.price;

    switch (priceMode) {
      case PriceMode.EXISTING_PRICE:
        price = this.existingPrice;
        break;
      default:
    }

    this.settings = {
      ...this.settings,
      updatePrice,
      price,
    };
  }

  onDenyButtonClick() {
    this.modal.deny();
  }

  onApproveButtonClick() {
    this.validatePrice()
      .pipe(
        first(),
        filter((isValidPrice: boolean): boolean => isValidPrice),
        tap((isValidPrice: boolean) => {
          this.modal.approve(this.settings);
        }),
      )
      .subscribe();
  }

  private validatePrice(): Observable<boolean> {
    this.isValidatingPrice = true;
    this.priceError = null;
    return this.eCommerceProductGraphQlService
      .validateProductVariantPrice(this.clientId, this.storeId, this.currency, this.price)
      .pipe(
        first(),
        catchError(() => of(false)),
        map((response: EProductValidationResponse): boolean => {
          this.isValidatingPrice = false;

          const isValidPrice = Boolean(response?.errors) && !response?.errors?.length;

          this.priceError = this.eCommerceProductValidationErrorMessageService.getFormattedErrorMessage(
            response?.errors || [],
          );

          if (!isValidPrice) {
            this.onPriceModeChange(PriceMode.YOUR_OWN_PRICE);
          }

          return isValidPrice;
        }),
      );
  }
}

export class EProductVariantConnectionSettingsModal extends ComponentModalConfig<
  EProductVariantConnectionSettingsModalContext,
  EProductVariantConnectionSettings,
  void
> {
  constructor(clientId: string, storeId: string, currency: string, price: number, oldCost: number, newCost: number) {
    super(EProductVariantConnectionSettingsModalComponent, {
      clientId,
      storeId,
      currency,
      price: toPriceNumber(price),
      oldCost: toPriceNumber(oldCost),
      newCost: toPriceNumber(newCost),
    });

    this.isClosable = true;
    this.transitionDuration = 200;
    this.size = ModalSize.Tiny;
  }
}
