import { Action, createReducer, on } from '@ngrx/store';
import { walletTransactionsAdapter, WalletTransactionsState } from './wallet-transactions.adapter';
import { fetchFail, fetchStart, fetchSuccess, setFilter } from './wallet-transactions.actions';
import { WalletTransactionsFilter } from '../../lib/api';

const defaultFilter: WalletTransactionsFilter = {
  limit: 50,
  offset: 0,
  total: 0,
};

const initialState: WalletTransactionsState = walletTransactionsAdapter.getInitialState({
  /**
   * undefined - not even started
   * false - in progress
   * true - fetched
   */
  isFetched: undefined,
  filter: defaultFilter,
});

const reducer = createReducer(
  initialState,
  on(setFilter, (state, action) => ({
    ...state,
    filter: {
      ...state.filter,
      ...action.filter,
    },
  })),
  on(fetchSuccess, (state, action) => ({
    ...walletTransactionsAdapter.setAll(action.list, state),
    isFetched: true,
    filter: {
      ...state.filter,
      total: action.filter.total,
      limit: action.filter.limit,
    },
  })),
  on(fetchStart, state => ({ ...state, isFetched: false })),
  on(fetchFail, state => ({ ...state, isFetched: undefined })),
);

export function walletTransactionsReducer(state: WalletTransactionsState, action: Action) {
  return reducer(state, action);
}
