import { AjaxRequest, AjaxResponse } from 'rxjs/ajax';
import * as Sentry from '@sentry/angular';
import { trackEvent } from '@gelato-api-ui/core/analytics/helpers/trackEvent';
import { HTTP_INTERNAL_SERVER_ERROR } from '../constants';
import { isRequestFailed } from './isRequestFailed';
import { getRequestIdFromAjaxResponse } from './getRequestIdFromAjaxResponse';

export const logAjaxRequest = (ajaxRequest: AjaxRequest, ajaxResponse: AjaxResponse) => {
  const endpoint = ajaxRequest.url;
  const method = ajaxRequest.method;
  const body = ajaxRequest.body;
  const requestId = getRequestIdFromAjaxResponse(ajaxResponse);
  const status = ajaxResponse ? ajaxResponse.status : null;
  let responseStringified: string = null;

  if (ajaxResponse) {
    responseStringified =
      typeof ajaxResponse.response === 'string' ? ajaxResponse.response : JSON.stringify(ajaxResponse.response);
  }

  const data = {
    requestId,
    endpoint,
    method,
    body,
    status,
    response: responseStringified,
  };

  if (isRequestFailed(ajaxResponse)) {
    trackEvent({
      event: 'logEvent',
      eventType: 'ajaxRequestFailed',
      eventProperties: data,
    });

    if (status >= HTTP_INTERNAL_SERVER_ERROR) {
      Sentry.captureException(new Error(`AjaxRequestFailed - ${endpoint}`), scope => {
        scope.setExtras(data);

        return scope;
      });
    } else {
      Sentry.addBreadcrumb({
        category: 'AjaxRequestFailed',
        message: endpoint,
        data,
        level: Sentry.Severity.Error,
      });
    }
  } else {
    Sentry.addBreadcrumb({
      category: 'AjaxRequest',
      message: endpoint,
      data,
      level: Sentry.Severity.Info,
    });
  }
};
