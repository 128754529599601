import { Injectable } from '@angular/core';
import * as R from 'ramda';
import { CustomTrim, EProductVariant } from '@gelato-api-ui/core/e-commerce/e-product-variant';
import { SanityProduct } from '@gelato-api-ui/core/sanity/sanity-product';
import { EVariantOption } from '@gelato-api-ui/core/e-commerce/e-variant-option';
import { SanityProductControl, SanityProductControlKey } from '@gelato-api-ui/core/sanity/sanity-product-control';
import { SanityProductControlOption } from '@gelato-api-ui/core/sanity/sanity-product-control-option';
import { SanityProductVariantWallpaperType } from '@gelato-api-ui/core/sanity/sanity-product-variant';
import { TranslateService } from '@ngx-translate/core';
import { getBaseUid } from '@gelato-api-ui/core/sanity/helpers/getBaseUid';
import { SanityProductTitleService } from '@gelato-api-ui/core/sanity/services/sanity-product-title.service';
import { CustomTrimTitleService } from '@gelato-api-ui/core/e-commerce/services/custom-trim-title.service';
import { EStoreType } from '@gelato-api-ui/core/e-commerce/e-store-type.enum';
import { EProductVariantOption } from '@gelato-api-ui/core/e-commerce/e-product-variant-option';
import { SanityProductVariation } from '@gelato-api-ui/core/sanity/sanity-product-variation';

@Injectable({ providedIn: 'root' })
export class ECommerceProductVariantOptionsService {
  private readonly MERGED_VARIANT_OPTIONS_DELIMITER = ' - ';
  private readonly OPTIONS_COUNT_LIMIT_DEFAULT = 3;
  private readonly OPTIONS_COUNT_LIMIT_ETSY = 2;

  constructor(
    private readonly translateService: TranslateService,
    private readonly sanityProductTitleService: SanityProductTitleService,
    private readonly customTrimTitleService: CustomTrimTitleService,
  ) {}

  getVariantOptionsFromSanityProduct(
    sanityProduct: SanityProduct,
    productUid: string,
    customTrim: CustomTrim,
  ): EVariantOption[] {
    const ignoredControlKeys = [SanityProductControlKey.wallpaperType, SanityProductControlKey.phoneBrand];
    const preparedUid = getBaseUid(productUid);
    const sanityVariation = sanityProduct.productVariations.find(
      (loopProductVariation: SanityProductVariation): boolean => loopProductVariation.productUid === preparedUid,
    );

    let variantOptions: EVariantOption[];

    variantOptions = (sanityProduct.productControls || [])
      .map((loopControl: SanityProductControl): EVariantOption => {
        if (ignoredControlKeys.includes(loopControl.key)) {
          return null;
        }

        const value = sanityVariation[loopControl.key];

        if (!value) {
          return null;
        }

        const option: SanityProductControlOption = loopControl.options.find(
          (loopOption: SanityProductControlOption): boolean => loopOption.value === value,
        );

        if (!option) {
          return null;
        }

        return {
          name: loopControl.title,
          value: (option && option.title) || value.charAt(0).toUpperCase() + value.slice(1),
        };
      })
      .filter((loopVariantOption: EVariantOption): boolean => Boolean(loopVariantOption));

    const wallSizeOptionName = this.translateService.instant('txt_wall_sizes');

    if (customTrim?.width?.value && customTrim?.height?.value) {
      variantOptions = [
        {
          name: wallSizeOptionName,
          value: this.customTrimTitleService.get(customTrim),
        },
        ...variantOptions,
      ];
    } else if (sanityVariation.wallpaperType === SanityProductVariantWallpaperType.sample) {
      const wallPaperTypeProductControl = (sanityProduct.productControls || []).find(
        (loopProductControl: SanityProductControl): boolean =>
          loopProductControl.key === SanityProductControlKey.wallpaperType,
      );

      if (wallPaperTypeProductControl) {
        const sampleOption = wallPaperTypeProductControl.options.find(
          (loopOption: SanityProductControlOption): boolean =>
            loopOption.value === SanityProductVariantWallpaperType.sample,
        );

        if (sampleOption) {
          variantOptions = [
            {
              name: wallSizeOptionName,
              value: sampleOption.title,
            },
            ...variantOptions,
          ];
        }
      }
    }

    return variantOptions;
  }

  mergeVariantOptions(
    productVariantOptions: EProductVariantOption[],
    variantOptions: EVariantOption[],
    optionsCountLimit: number,
  ): EVariantOption[] {
    const sourceVariantOptions = this.getSourceVariantOptions(productVariantOptions, variantOptions);

    return this.mergeVariantOptionsToLimit(sourceVariantOptions, optionsCountLimit);
  }

  extractUsedOptionsFromVariants(variants: EProductVariant[]): EProductVariantOption[] {
    const productVariantOptions: EProductVariantOption[] = [];

    (variants || []).forEach((loopVariant: EProductVariant) => {
      (loopVariant.variantOptions || []).forEach((loopVariantOption: EVariantOption) => {
        const { name, value } = loopVariantOption;
        const matchingOption: EProductVariantOption = productVariantOptions.find(
          (o: EProductVariantOption): boolean => o.name === name,
        );

        if (matchingOption) {
          if (!matchingOption.values.includes(value)) {
            matchingOption.values.push(value);
          }
        } else {
          productVariantOptions.push({
            name,
            values: [value],
          });
        }
      });
    });

    return productVariantOptions.filter(
      (loopProductVariantOption: EProductVariantOption): boolean => loopProductVariantOption?.values?.length > 1,
    );
  }

  getProductVariantOptionsCountLimit(storeType: EStoreType): number {
    return storeType === EStoreType.ETSY ? this.OPTIONS_COUNT_LIMIT_ETSY : this.OPTIONS_COUNT_LIMIT_DEFAULT;
  }

  hasMissingVariantOptionsCombinations(productVariants: EProductVariant[]): boolean {
    const variantOptionsCollection = {};

    for (const loopProductVariant of productVariants) {
      if (!loopProductVariant?.variantOptions?.length) {
        continue;
      }

      for (const loopVariantOption of loopProductVariant.variantOptions) {
        const loopVariantOptionName = loopVariantOption.name;
        const loopVariantOptionValue = loopVariantOption.value;

        if (!variantOptionsCollection[loopVariantOptionName]) {
          variantOptionsCollection[loopVariantOptionName] = [];
        }

        if (!variantOptionsCollection[loopVariantOptionName].includes(loopVariantOptionValue)) {
          variantOptionsCollection[loopVariantOptionName].push(loopVariantOptionValue);
        }
      }
    }

    if (!R.isEmpty(variantOptionsCollection)) {
      const expectedVariantsCount = Object.keys(variantOptionsCollection).reduce(
        (acc: number, loopVariantOptionName: string): number => {
          const valuesCount: number = variantOptionsCollection[loopVariantOptionName]?.length;

          return acc * valuesCount;
        },
        1,
      );

      return productVariants?.length !== expectedVariantsCount;
    }

    return false;
  }

  private mergeVariantOptionsToLimit(variantOptions: EVariantOption[], optionsLimit: number): EVariantOption[] {
    if (variantOptions?.length <= optionsLimit) {
      return variantOptions;
    }

    const mergedVariantOption: EVariantOption = {
      name: [variantOptions[0].name, variantOptions[1].name].join(this.MERGED_VARIANT_OPTIONS_DELIMITER),
      value: [variantOptions[0].value, variantOptions[1].value].join(this.MERGED_VARIANT_OPTIONS_DELIMITER),
    };

    const variantOptionsUpdated = [mergedVariantOption, ...variantOptions.slice(2, variantOptions.length)];

    if (variantOptionsUpdated.length === optionsLimit) {
      return variantOptionsUpdated;
    } else {
      return this.mergeVariantOptionsToLimit(variantOptionsUpdated, optionsLimit);
    }
  }

  private getSourceVariantOptions(
    productVariantOptions: EProductVariantOption[],
    variantOptions: EVariantOption[],
  ): EVariantOption[] {
    const productVariantOptionNames = (productVariantOptions || []).map(
      (loopProductVariantOption: EProductVariantOption): string => loopProductVariantOption.name,
    );

    return (variantOptions || []).filter((loopVariantOption: EVariantOption): boolean =>
      productVariantOptionNames.includes(loopVariantOption.name),
    );
  }
}
